import { emailTemplateValidationConstants } from '../Constants/validationConstants';

class EmailTemplateValidation {

  version;
  errorMap;
  emailTemplateErrorMap;
  valid;

  constructor() {

    if (!EmailTemplateValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.emailTemplateErrorMap = new Map();
      EmailTemplateValidation.instance = this;
    }
    return EmailTemplateValidation.instance;
  }

  validateEditEmailTemplate(emailTemplate) {
    this.resetErrorMessages();
    this.handleValidateEditEmailTemplate(emailTemplate);
    this.version++;
  }

  validateCreateEmailTemplate(emailTemplate) {
    this.resetErrorMessages();
    this.handleValidateCreateEmailTemplate(emailTemplate);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.emailTemplateErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key, uniqueCode) {
    if (!uniqueCode) {
      return (this.emailTemplateErrorMap.has(key)) ? this.emailTemplateErrorMap.get(key) : [];
    }
  }

  handleValidateEditEmailTemplate(emailTemplate) {
    if (!emailTemplate.type) {
      this.setEmailTemplateErrorMapMessages(emailTemplateValidationConstants.KEY_TYPE, "Missing Type");
    }
    if (!emailTemplate.restUrlId) {
      this.setEmailTemplateErrorMapMessages(emailTemplateValidationConstants.KEY_REST_URL_ID, "Missing Rest Url Id");
    }
    if (!emailTemplate.htmlTcContent) {
      this.setEmailTemplateErrorMapMessages(emailTemplateValidationConstants.KEY_HTML_TC_CONTENT, "Missing HTML TC Content");
    }
    if (!emailTemplate.plainTextTcContent) {
      this.setEmailTemplateErrorMapMessages(emailTemplateValidationConstants.KEY_PLAIN_TEXT_TC_CONTENT, "Missing Plain Text TC Content");
    }
    if (!emailTemplate.status) {
      this.setEmailTemplateErrorMapMessages(emailTemplateValidationConstants.KEY_STATUS, "Missing Status");
    }
  }

  handleValidateCreateEmailTemplate(emailTemplate) {
    if (!emailTemplate.type) {
      this.setEmailTemplateErrorMapMessages(emailTemplateValidationConstants.KEY_TYPE, "Missing Type");
    }
    if (!emailTemplate.restUrlId) {
      this.setEmailTemplateErrorMapMessages(emailTemplateValidationConstants.KEY_REST_URL_ID, "Missing Rest Url Id");
    }
    if (!emailTemplate.htmlTcContent) {
      this.setEmailTemplateErrorMapMessages(emailTemplateValidationConstants.KEY_HTML_TC_CONTENT, "Missing HTML TC Content");
    }
    if (!emailTemplate.plainTextTcContent) {
      this.setEmailTemplateErrorMapMessages(emailTemplateValidationConstants.KEY_PLAIN_TEXT_TC_CONTENT, "Missing Plain Text TC Content");
    }
  }


  setEmailTemplateErrorMapMessages(key, errorMessages, valid = false) {
    this.emailTemplateErrorMap.set(key, errorMessages);
    // console.log("emailTemplateErrorMap", this.emailTemplateErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new EmailTemplateValidation();
export default validation;