
import { useState } from "react";
import _ from "lodash";

import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { Drawer } from "@mui/material";
import { Card } from '@mui/material';

import { constants } from '../../../../Constants/constants';
import { TempTextInput } from "../../../Input/TempTextInput";
import { TempBtn } from "../../../Input/TempBtn";
import { TempAutoSelectInput } from "../../../Input/TempAutoSelectInput";
import { RestaurantList } from "../../../../Page/Restaurant/RestaurantList";

const resource = "productCreateSystemGroupList";

export const AddRestaurantButton = ({addRestaurantMap, setAddRestaurantMap, onChangeInput, confirmRestList, setConfirmRestList}) =>{

  
  const [showPanel, setShowPanel] = useState(false);
  
  const handleAddRestaurant = () =>{
    setShowPanel(true);
  }

  const handeleDelete = (restUrlId) =>{
    const temptMap = _.cloneDeep(addRestaurantMap);
    temptMap.delete(restUrlId);
    setAddRestaurantMap(temptMap);
    setConfirmRestList(Array.from(temptMap, ([name, value]) => (value)));
  }

  const handleCloseClick = () =>{
    setShowPanel(false);
    setAddRestaurantMap(new Map(confirmRestList.map((item)=>[item.id, item])));
  }

  const handleRestUrlId = () =>{
    const confirmList = Array.from(addRestaurantMap, ([name, value]) => (value));
    setConfirmRestList([...confirmList]);
    setShowPanel(false);
  }

  return(
    <>
      <TempBtn
        btnSetting=
        {{
          onClick: handleAddRestaurant,
          variant: constants.STYLE.VARIANT.CONTAINED,
          color: constants.STYLE.PRIMARY,
          icon: <AddIcon />,
          label: { resource: resource, key: "addRestaurant" },
        }}
      >
      </TempBtn>
      {confirmRestList && confirmRestList.length > 0 && confirmRestList.map((item, index) => {
        const tempEditList = [
          {
            className: "edit-create-input",
            variant: constants.STYLE.VARIANT.STANDARD,
            size: constants.SIZE.SMALL,
            label: { resource: resource, key: "restUrlId" },
            value: item.restUrlId,
            name: "restUrlId",
            isEndOfLine: true,
            disabled: true,
          },
          {
            className: "edit-create-input",
            variant: constants.STYLE.VARIANT.STANDARD,
            size: constants.SIZE.SMALL,
            label: { resource: resource, key: "enName" },
            value: item.enName,
            name: "enName",
            isEndOfLine: false,
            disabled: true,
          },
          {
            className: "edit-create-input",
            variant: constants.STYLE.VARIANT.STANDARD,
            size: constants.SIZE.SMALL,
            label: { resource: resource, key: "tcName" },
            value: item.tcName,
            name: "tcName",
            isEndOfLine: false,
            disabled: true,
          },
          {
            className: "edit-create-input",
            variant: constants.STYLE.VARIANT.STANDARD,
            size: constants.SIZE.SMALL,
            label: { resource: resource, key: "scName" },
            value: item.scName,
            name: "scName",
            isEndOfLine: false,
            disabled: true,
          },
        ]
        return <Card key={index} className="image-list"> {
          <>
            <div className="delete-icon">
              <TempBtn btnSetting={{
                className: "edit-create-btn",
                variant: constants.STYLE.VARIANT.CONTAINED,
                color: constants.STYLE.PRIMARY,
                onClick: () => handeleDelete(item.restUrlId),
                label: { key: "deleteBtn" },
                icon: <DeleteIcon />,
              }} />
            </div>
            {tempEditList.map((inputSetting, index) => {
              let content = <></>;
              switch (inputSetting.type) {
                case constants.TYPE.SELECT:
                  content =
                    <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} index={index}/>
                  break;
                default:
                  content =
                    <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} index={index}/>
              }
              return <div className={inputSetting.backgroundColorClass ? "image-card-background card-border" : "edit-display-wrapper"} key={index}>
                {content}
                {inputSetting.isEndOfLine && <div></div>}
              </div>
            })
            }
          </>
        }
        </Card>
      })}
      <Drawer
        classes={{ paper: "drawer-margin-left" }}
        anchor="right"
        open={showPanel}
        onClose={handleCloseClick}
      >
        <RestaurantList
          editBtn={false}
          createBtn={false}
          btnList={false}
          mutipleSelect={true}
          selectMap={addRestaurantMap}
          setSelectMap={setAddRestaurantMap}
          newPath={constants.PATH.SHOP.RESTAURANT}
          newFilter={ addRestaurantMap && addRestaurantMap.size>0?{selected: { column: 'restUrlId', valueList: Array.from(addRestaurantMap, ([name, value]) => (name)) }}: null }
          // savedData={savedRestaurantTagMap}
          dialogFooterBtn={
            <div className="get-push-card-wrapper">
              <TempBtn
                btnSetting=
                {{
                  className: "get-push-card-cancel-btn margin-right-16",
                  variant: constants.STYLE.VARIANT.CONTAINED,
                  color: constants.STYLE.PRIMARY,
                  onClick: handleRestUrlId,
                  icon: <AddIcon />,
                  label: { key: "submit" },
                }}
              >
              </TempBtn>
              <TempBtn
                btnSetting=
                {{
                  className: "get-push-card-cancel-btn margin-right-16",
                  variant: constants.STYLE.VARIANT.CONTAINED,
                  color: constants.STYLE.PRIMARY,
                  onClick: handleCloseClick,
                  icon: <ClearIcon />,
                  label: { key: "close" },
                }}
              >
              </TempBtn>
            </div>}
        />,

      </Drawer>
    </>
  )
}