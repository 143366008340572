import { merchantUserValidationConstants } from '../Constants/validationConstants'
import { testMerchantUserPassword } from '../helper/passwordUtil'
import { validateEmail } from '../helper/util'

const LOCAL_CONSTANT = {
  SHOP: 'SHOP',
  GROUP: 'GROUP',
}

class MerchantUserValidation {
  version
  errorMap
  merchantUserErrorMap
  valid

  constructor() {
    if (!MerchantUserValidation.instance) {
      this.version = 0
      this.valid = true
      this.errorMap = new Map()
      this.merchantUserErrorMap = new Map()
      MerchantUserValidation.instance = this
    }
    return MerchantUserValidation.instance
  }

  validateEditMerchantUser(merchantUser) {
    this.resetErrorMessages()
    this.handleValidateEditMerchantUser(merchantUser)
    this.version++
  }

  validateCreateMerchantUser(merchantUser) {
    this.resetErrorMessages()
    this.handleValidateCreateMerchantUser(merchantUser)
    this.version++
  }

  resetErrorMessages() {
    this.errorMap = new Map()
    this.merchantUserErrorMap = new Map()
    this.valid = true
  }

  getVersion() {
    return this.version
  }

  isValid() {
    return this.valid
  }

  getErrorMessages(key) {
    return this.merchantUserErrorMap.get(key)
  }

  handleValidateEditMerchantUser(merchantUser) {
    const id = merchantUser.id

    if (merchantUser.email && !validateEmail(merchantUser.email)) {
      this.setMerchantUserErrorMapMessages(`${id}-${merchantUserValidationConstants.KEY_EMAIL}`, 'Please enter correct email format!')
    }

    if (!merchantUser.userRole) {
      this.setMerchantUserErrorMapMessages(`${id}-${merchantUserValidationConstants.KEY_USER_ROLE}`, 'Missing User Role')
    }

    if (!merchantUser.enabled && merchantUser.enabled !== false) {
      this.setMerchantUserErrorMapMessages(`${id}-${merchantUserValidationConstants.KEY_ENABLED}`, 'Missing Enabled')
    }

    if (!merchantUser.enableMultiLogin && merchantUser.enableMultiLogin !== false) {
      this.setMerchantUserErrorMapMessages(`${id}-${merchantUserValidationConstants.KEY_ENABLED_MULTI_LOGIN}`, 'Missing EnableMultiLogin')
    }

    if (!merchantUser.privilegeAttribute) {
      this.setMerchantUserErrorMapMessages(`${id}-${merchantUserValidationConstants.KEY_PRIVILEGE_ATTRIBUTE}`, 'Missing Privilege Attribute')
    }

    if (merchantUser.privilegeAttribute && merchantUser.privilegeAttribute === LOCAL_CONSTANT.SHOP && !merchantUser.privilegeRestUrlId) {
      this.setMerchantUserErrorMapMessages(`${id}-${merchantUserValidationConstants.KEY_PRIVILEGE_RESTURLID}`, 'Missing Privilege Rest Url Id')
    }

    if (!merchantUser.merchantRole) {
      this.setMerchantUserErrorMapMessages(`${id}-${merchantUserValidationConstants.KEY_MERCHANT_ROLE}`, 'Missing Merchant Role')
    }
  }

  handleValidateCreateMerchantUser(merchantUser) {
    if (!Array.isArray(merchantUser)) {
      const id = merchantUser.customId ? merchantUser.customId : merchantUser.id

      let pattern = new RegExp('^(?=.*[A-Z])')

      if (!merchantUser.username) {
        this.setMerchantUserErrorMapMessages(`${id}-${merchantUserValidationConstants.KEY_USERNAME}`, 'Missing Username')
      } else if (pattern.test(merchantUser.username)) {
        this.setMerchantUserErrorMapMessages(`${id}-${merchantUserValidationConstants.KEY_USERNAME}`, 'Upper case letter is not support!')
      } else if (merchantUser.username.length > 45) {
        this.setMerchantUserErrorMapMessages(
          `${id}-${merchantUserValidationConstants.KEY_USERNAME}`,
          'Username length should be smaller than 45 letter!'
        )
      }

      // if (!merchantUser.password) {
      //   this.setMerchantUserErrorMapMessages(`${id}-${merchantUserValidationConstants.KEY_PASSWORD}`, 'Missing Password')
      // }

      if (!testMerchantUserPassword(merchantUser.password)) {
        this.setMerchantUserErrorMapMessages(
          `${id}-${merchantUserValidationConstants.KEY_PASSWORD}`,
          'The password should be at least 6 characters long and contain at least one letter and one digit.'
        )
      }

      if (merchantUser.email && !validateEmail(merchantUser.email)) {
        this.setMerchantUserErrorMapMessages(`${id}-${merchantUserValidationConstants.KEY_EMAIL}`, 'Please enter correct email format!')
      }

      if (!merchantUser.userRole) {
        this.setMerchantUserErrorMapMessages(`${id}-${merchantUserValidationConstants.KEY_USER_ROLE}`, 'Missing User Role')
      }

      if (!merchantUser.privilegeAttribute) {
        this.setMerchantUserErrorMapMessages(`${id}-${merchantUserValidationConstants.KEY_PRIVILEGE_ATTRIBUTE}`, 'Missing Privilege Attribute')
      }

      if (merchantUser.privilegeAttribute && merchantUser.privilegeAttribute === LOCAL_CONSTANT.SHOP && !merchantUser.privilegeRestUrlId) {
        this.setMerchantUserErrorMapMessages(`${id}-${merchantUserValidationConstants.KEY_PRIVILEGE_RESTURLID}`, 'Missing Privilege Rest Url Id')
      }

      if (!merchantUser.merchantRole) {
        this.setMerchantUserErrorMapMessages(`${id}-${merchantUserValidationConstants.KEY_MERCHANT_ROLE}`, 'Missing Merchant Role')
      }

      if (!this.valid) {
        this.setMerchantUserErrorMapMessages(`${id}`, 'Has Error')
      }
    } else {
      for (const user of merchantUser) {
        const id = user.customId ? user.customId : user.id

        let pattern = new RegExp('^(?=.*[A-Z])')
        if (!user.username) {
          this.setMerchantUserErrorMapMessages(`${id}-${merchantUserValidationConstants.KEY_USERNAME}`, 'Missing Username')
        } else if (pattern.test(user.username)) {
          this.setMerchantUserErrorMapMessages(`${id}-${merchantUserValidationConstants.KEY_USERNAME}`, 'Upper case letter is not support!')
        } else if (user.username.length > 45) {
          this.setMerchantUserErrorMapMessages(
            `${id}-${merchantUserValidationConstants.KEY_USERNAME}`,
            'Username length should be smaller than 45 letter!'
          )
        }

        if (!user.password) {
          this.setMerchantUserErrorMapMessages(`${id}-${merchantUserValidationConstants.KEY_PASSWORD}`, 'Missing Password')
        }

        if (user.email && !validateEmail(user.email)) {
          this.setMerchantUserErrorMapMessages(`${id}-${merchantUserValidationConstants.KEY_EMAIL}`, 'Please enter correct email format!')
        }

        if (!user.userRole) {
          this.setMerchantUserErrorMapMessages(`${id}-${merchantUserValidationConstants.KEY_USER_ROLE}`, 'Missing User Role')
        }

        if (!user.privilegeAttribute) {
          this.setMerchantUserErrorMapMessages(`${id}-${merchantUserValidationConstants.KEY_PRIVILEGE_ATTRIBUTE}`, 'Missing Privilege Attribute')
        }

        if (user.privilegeAttribute && user.privilegeAttribute === LOCAL_CONSTANT.SHOP && !user.privilegeRestUrlId) {
          this.setMerchantUserErrorMapMessages(`${id}-${merchantUserValidationConstants.KEY_PRIVILEGE_RESTURLID}`, 'Missing Privilege Rest Url Id')
        }

        if (!user.merchantRole) {
          this.setMerchantUserErrorMapMessages(`${id}-${merchantUserValidationConstants.KEY_MERCHANT_ROLE}`, 'Missing Merchant Role')
        }

        if (!this.valid) {
          this.setMerchantUserErrorMapMessages(merchantUserValidationConstants.KEY_HAS_ERROR, 'Has Error')
          this.setMerchantUserErrorMapMessages(`${id}`, 'Has Error')
        }
      }
    }
  }

  setMerchantUserErrorMapMessages(key, errorMessages, valid = false) {
    this.merchantUserErrorMap.set(key, errorMessages)
    // console.log("merchantUserErrorMap", this.merchantUserErrorMap)
    if (!valid) {
      this.valid = false
    }
  }
}

const validation = new MerchantUserValidation()
export default validation
