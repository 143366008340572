import { useState, useEffect } from "react";
import { useStore } from "react-redux";
import _ from "lodash";
import moment from 'moment';

import { Card } from '@mui/material';

import { ListDisplay } from '../../Component/ListDisplay/ListDisplay';

import { constants } from '../../Constants/constants';
import { getList } from "../../helper/baseFetch";
import { permissionsCheck } from "../../helper/util";

export const ProductCreateDraftList = ({ newPath, newFilter, newListTitles, btnList, setSelectedIdList, dialogFooterBtn, onClickListRow, pagenName, savedData }) => {
  const store = useStore();
  const [productCreateDraftList, setProductCreateDraftList] = useState([]);
  const [ready, setReady] = useState(false);
  const resource = "productCreateDraft";

  const productCreateDraftTitleList = newListTitles ? newListTitles :
    [
      {
        key: constants.LIST_DISPLAY_TYPE.STRING,
        value: "draftName",
        sort: true
      }, {
        key: constants.LIST_DISPLAY_TYPE.STRING,
        value: "draftVersion",
        sort: true
      }, {
        key: constants.LIST_DISPLAY_TYPE.STRING,
        value: "status",
        sort: true
      }
    ];
  const listFilters = [
    {
      className: "filter-input",
      label: { resource: resource, key: "draftName" },
      clear: true,
      value: '',
      name: "draftName",
      size: constants.SIZE.SMALL
    },
    {
      className: "filter-input",
      label: { resource: resource, key: "status" },
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.PRODUCT_CREATE.CREATE_PRODUCT.STATUS,
      name: "status",
      size: constants.SIZE.SMALL
    },
  ];

  const [param, setParam] = useState({
    path: "",
    page: 0,
    rowsPerPage: 10,
    sort: 'updateTimestamp',
    direction: 'DESC',
    listFilters: listFilters,
    count: 0,
  })

  function onChangeParam(value) {
    value.count = value.count + 1;
    setParam(value);
  }

  function onChangeListFilters(list) {
    let tempParam = _.cloneDeep(param);
    tempParam.listFilters = list;
    tempParam.page = 0;
    onChangeParam(tempParam);
  }

  function onChangeListSort(sortItem) {
    let tempParam = _.cloneDeep(param);
    tempParam.sort = sortItem;
    tempParam.direction = tempParam.direction === "DESC" ? "ASC" : "DESC";
    onChangeParam(tempParam);
  }

  function onChangeListPage(page) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = page;
    onChangeParam(tempParam);
  }

  function onChangeListRowsPerPage(rowsPerPage) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = 0;
    tempParam.rowsPerPage = rowsPerPage;
    onChangeParam(tempParam);
  }

  useEffect(() => {
    let tempParam = _.cloneDeep(param);
    const filter = newFilter ? _.cloneDeep(newFilter) : {};
    tempParam.listFilters.forEach((filterItem) => {
      if (filterItem.value !== '' && filterItem.value !== undefined) {
        if (filterItem.type === constants.TYPE.DATETIME_LOCAL) {
          filter[filterItem.name] = moment(filterItem.value).format('x')
        } else {
          filter[filterItem.name] = filterItem.value
        };
      }
    })

    getList(constants.PATH.PRODUCT.DRAFT, { sort: { field: tempParam.sort, order: tempParam.direction }, filter: filter, pagination: { page: tempParam.page + 1, perPage: tempParam.rowsPerPage } }, (payload) => {
      setProductCreateDraftList(payload);
      setReady(true)
    }, undefined, store);

  }, [param.count]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <Card className="card-wrapper overflow">
      <ListDisplay
        onClickListRow={onClickListRow}
        page={param.page}
        rowsPerPage={param.rowsPerPage}
        onChangeListSort={onChangeListSort}
        resource={resource}
        dataList={productCreateDraftList}
        sort={param.sort}
        direction={param.direction}
        listTitles={productCreateDraftTitleList}
        listFilters={param.listFilters}
        onChangeListPage={onChangeListPage}
        onChangeListRowsPerPage={onChangeListRowsPerPage}
        onChangeListFilters={onChangeListFilters}
        editBtn={true}
        btnFilter="ProductDraft"
        createBtn={permissionsCheck(constants.PERMISSION_KEY.PRODUCT_DRAFT_LIST_SYSTEM_ADMIN) ? true : false}
        btnList={permissionsCheck(constants.PERMISSION_KEY.PRODUCT_DRAFT_LIST_SYSTEM_ADMIN) ? ["productClone"] : false}
        clonePath={constants.PATH.PRODUCT.DRAFT + "/clone="}
        dialogFooterBtn={dialogFooterBtn}
        redirectPath={"/" + constants.PATH.PRODUCT.DRAFT + "/"}
      />
    </Card>
  )
};
