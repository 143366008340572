import { useHistory, useLocation, useParams } from 'react-router-dom'
import { constants } from '../../Constants/constants'
import { useSelector } from 'react-redux'

import { Card } from '@mui/material'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'

import { TempBtn } from '../Input/TempBtn'
import { BaseWrapper } from '../BaseWrapper'
import { getString } from '../../helper/util'

import '../../styles/uploadPayment.scss'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box p={4}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export const EditCreateDisplay = ({ display, resource, tabs, content, bottomBtns, code, mapName, mapCode }) => {
  const history = useHistory()
  const location = useLocation()
  const params = useParams()
  const lang = useSelector((state) => state.lang.ui.lang)
  const theme = useSelector((state) => state.theme.ui.theme)

  const onChangeTab = (e, value) => {
    let path = location.pathname.split('/').map((item, idx) => {
      let content = ''
      if (location.pathname.includes(constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT)) {
        if (idx === location.pathname.split('/').length - 1) {
          content = 0
        } else if (idx !== location.pathname.split('/').length - 2) {
          content = item
        } else {
          content = value
        }
      } else {
        if (idx !== location.pathname.split('/').length - 1) {
          content = item
        } else {
          content = value
        }
      }
      return content
    })
    history.push(path.join('/'))
  }

  // useEffect(() => {
  //   let path = location.pathname.split("/").map((item, index) => {
  //     let content = "";
  //     if (location.pathname.includes(constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT)) {
  //       if (index === location.pathname.split("/").length - 1) {
  //         content = params.subTab ? params.subTab : 0;
  //       } else if (index !== location.pathname.split("/").length - 2) {
  //         content = item;
  //       } else {
  //         content = params.tab;
  //       }
  //     } else {
  //       if (index !== location.pathname.split("/").length - 1) {
  //         content = item;
  //       } else {
  //         content = params.tab;
  //       }
  //     }
  //     return content;
  //   })
  //   let newPath = path.join("/");
  //   if (location.pathname !== newPath) {
  //     history.push(newPath);
  //   }
  // }, [params.tab])// eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   window.scrollTo({ top: 0, behavior: 'smooth' });
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BaseWrapper>
      <HeaderTitle resource={resource} display={display} code={code} mapName={mapName} mapCode={mapCode} lang={lang} theme={theme} />
      {tabs && (
        <Paper position="static" color="default">
          <Tabs value={Number(params.tab)} onChange={onChangeTab} aria-label="simple tabs example">
            {tabs.map((tab, index) => {
              return (
                <Tab
                  key={index}
                  label={getString(lang, tab.label.resource ? tab.label.resource : '', tab.label.key ? tab.label.key : '', theme)}
                  {...a11yProps(tab.tabIndex)}
                />
              )
            })}
          </Tabs>
        </Paper>
      )}
      <Card>
        {content.map((item, index) => {
          return tabs ? (
            <TabPanel className="edit-tab-wrapper" value={Number(params.tab)} key={index} index={index}>
              {item.tab}
              {item.btn && (
                <div className="edit-footer-wrapper">
                  {bottomBtns &&
                    bottomBtns.map((btnSetting, btnIndex) => {
                      let content = <></>
                      if (btnSetting.type === constants.TYPE.TEXT) {
                        content = (
                          <div key={'btn_' + btnIndex} className={btnSetting.className}>
                            {getString(
                              lang,
                              btnSetting.label.resource ? btnSetting.label.resource : '',
                              btnSetting.label.key ? btnSetting.label.key : '',
                              theme
                            ) + btnSetting.value}
                          </div>
                        )
                      } else {
                        content = <TempBtn key={'btn_' + btnIndex} btnSetting={btnSetting} />
                      }
                      return content
                    })}
                </div>
              )}
            </TabPanel>
          ) : (
            <div className="card-wrapper" key={index}>
              {item.tab}
              {item.btn && (
                <div className="edit-footer-wrapper padding-16">
                  {bottomBtns &&
                    bottomBtns.map((btnSetting, btnIndex) => {
                      let content = <></>
                      if (btnSetting.type === constants.TYPE.TEXT) {
                        content = (
                          <div key={'btn_' + btnIndex} className={btnSetting.className}>
                            {getString(
                              lang,
                              btnSetting.label.resource ? btnSetting.label.resource : '',
                              btnSetting.label.key ? btnSetting.label.key : '',
                              theme
                            ) + btnSetting.value}
                          </div>
                        )
                      } else {
                        content = <TempBtn key={'btn_' + btnIndex} btnSetting={btnSetting} />
                      }
                      return content
                    })}
                </div>
              )}
            </div>
          )
        })}
      </Card>
    </BaseWrapper>
  )
}

const HeaderTitle = ({ resource, display, code, mapName, mapCode, lang, theme }) => {
  return (
    <div className="margin-left-16">
      {display === 'none' ? (
        ''
      ) : mapCode ? (
        mapName ? (
          <>
            <div className="sub-title">
              {getString(lang, mapName.resource, mapName.key, theme)}: <div className="code-title">{mapCode}</div>
            </div>
            <div className="sub-title">
              {getString(lang, resource, `${display}Title`, theme)}: <div className="code-title">{code}</div>
            </div>{' '}
          </>
        ) : (
          <>
            <div className="code-title">{mapCode}</div>
            <div className="sub-title">
              {getString(lang, resource, `${display}Title`, theme)}: <div className="code-title">{code}</div>
            </div>{' '}
          </>
        )
      ) : (
        <div className="sub-title">
          {getString(lang, resource, `${display}Title`, theme)}: <div className="code-title">{code}</div>
        </div>
      )}
    </div>
  )
}
