import _ from "lodash";
import { RESTAURANT_MEDIA } from '../actions/types';
import { constants } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        restaurantMedia: _.cloneDeep(constants.RESTAURANT_MEDIA_INPUT)
    }
}

const restaurantMediaReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RESTAURANT_MEDIA.SET_RESTAURANT_MEDIA_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case RESTAURANT_MEDIA.SET_RESTAURANT_MEDIA:
        case RESTAURANT_MEDIA.SET_RESTAURANT_MEDIA_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    restaurantMedia: action.restaurantMedia
                }
            })
        default:
            return state
    }
}

export default restaurantMediaReducer