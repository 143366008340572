import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector, useStore } from "react-redux";
import _ from "lodash";
import moment from "moment";

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import { makeStyles } from '@mui/styles';

import { constants, IMAGE_TYPE, PICTURE_SIZE, UPLOAD_TYPE } from '../../Constants/constants';
import { getOne, put } from "../../helper/baseFetch";
import { setRestaurantSlideshow, setRestaurantSlideshowValue } from "../../actions/restaurantSlideshowAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempAutoSelectInput } from "../../Component/Input/TempAutoSelectInput";
import { UploadImage } from "../../Component/UploadImage/UploadImage";
import { DialogWrapper } from "../../Component/DialogWrapper";
import RestaurantSlideshowValidation from "../../validations/restaurantSlideshowValidation";
import { restaurantSlideshowValidationConstants } from '../../Constants/validationConstants';
import { setLoading } from "../../actions/systemAction";

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const RestaurantSlideshowEdit = ({newRedirectPath}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const store = useStore();
  const history = useHistory();
  const [ready, setReady] = useState(false);
  const [clear, setClear] = useState(true);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const resource = "RestaurantSlideshow";
  const savedRestaurantSlideshow = useSelector(state => state.restaurantSlideshow.ui.restaurantSlideshow);
  const helperTextStylesClasses = helperTextStyles();
  const [, setErrorVersion] = useState(RestaurantSlideshowValidation.getVersion());  

  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.RESTAURANT_SLIDESHOW_TAB.INFO
    },
  ]
  const tempRestaurantMediaEditList = [
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "restUrlId" },
      value: savedRestaurantSlideshow.restUrlId,
      name: "restUrlId",
      disabled: true,
      variant: constants.STYLE.VARIANT.STANDARD,
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: RestaurantSlideshowValidation.getErrorMessages(restaurantSlideshowValidationConstants.KEY_REFCODE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "type" },
      value: savedRestaurantSlideshow.type,
      disabled: true,
      variant: constants.STYLE.VARIANT.STANDARD,
      name: "type",
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: RestaurantSlideshowValidation.getErrorMessages(restaurantSlideshowValidationConstants.KEY_TYPE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "sequence" },
      value: savedRestaurantSlideshow.sequence,
      name: "sequence",
      type: constants.TYPE.NUMBER,
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: RestaurantSlideshowValidation.getErrorMessages(restaurantSlideshowValidationConstants.KEY_SEQUENCE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      type: constants.TYPE.DATETIME_LOCAL,
      size: constants.SIZE.SMALL,
      name: "startDisplayTimestamp",
      label: { resource: resource, key: "startDisplayTimestamp" },
      value: savedRestaurantSlideshow.startDisplayTimestamp,
      step: 1,
      helperTextIcon: true,
      isEndOfLine: false,
      helperText: RestaurantSlideshowValidation.getErrorMessages(restaurantSlideshowValidationConstants.KEY_START_DISPLAY_TIMESTAMP),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      type: constants.TYPE.DATETIME_LOCAL,
      size: constants.SIZE.SMALL,
      name: "endDisplayTimestamp",
      label: { resource: resource, key: "endDisplayTimestamp" },
      value: savedRestaurantSlideshow.endDisplayTimestamp,
      step: 1,
      helperTextIcon: true,
      isEndOfLine: true,
      helperText: RestaurantSlideshowValidation.getErrorMessages(restaurantSlideshowValidationConstants.KEY_END_DISPLAY_TIMESTAMP),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "status" },
      value: savedRestaurantSlideshow.status,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.RESTAURANT_MEDIA.STATUS,
      disableClearable: true,
      name: "status",
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: RestaurantSlideshowValidation.getErrorMessages(restaurantSlideshowValidationConstants.KEY_STATUS),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      picture: savedRestaurantSlideshow.media?.cosFileUrl ? { cosFileUrl: savedRestaurantSlideshow.media.cosFileUrl } : null,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "media" },
      name: "media",
      type: constants.TYPE.IMAGE,
      uploadImagePath: constants.PATH.SHOP.UPLOAD_RESTAURANT_MEDIA,
      saveImage: handleSaveImage,
      isEndOfLine: false,
    },
  ]
  const content = [
    {
      tab: <>
        {RestaurantSlideshowValidation.getErrorMessages(restaurantSlideshowValidationConstants.KEY_MEDIA_KEY) &&
          RestaurantSlideshowValidation.getErrorMessages(restaurantSlideshowValidationConstants.KEY_MEDIA_KEY).length > 0 &&
          <div className="error-message">
            <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
            {RestaurantSlideshowValidation.getErrorMessages(restaurantSlideshowValidationConstants.KEY_MEDIA_KEY)}
          </div>
        }
        {tempRestaurantMediaEditList.map((inputSetting, index) => {
          let content;
          switch (inputSetting.type) {
            case constants.TYPE.IMAGE:
              content =
                <div className="image">
                  <UploadImage
                    picture={inputSetting.picture}
                    type={IMAGE_TYPE.SLIDESHOW}
                    title={inputSetting.label}
                    fileType={savedRestaurantSlideshow.type}
                    name={inputSetting.name}
                    uploadImagePath={inputSetting.uploadImagePath}
                    saveImage={inputSetting.saveImage}
                    uploadType={UPLOAD_TYPE.FORMDATA}
                    required={inputSetting.required} 
                    allowedFileExtensionList={['image/*', 'video/mp4']}
                    size = {PICTURE_SIZE.MID}/>
                </div>
              break;
            case constants.TYPE.SELECT:
              content =
                <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
              break;
            default:
              content =
                <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
          }
          return <div className="edit-display-wrapper" key={index}>
            {inputSetting.name === "media" &&
              RestaurantSlideshowValidation.getErrorMessages(restaurantSlideshowValidationConstants.KEY_COS_FILE_URL) &&
              RestaurantSlideshowValidation.getErrorMessages(restaurantSlideshowValidationConstants.KEY_COS_FILE_URL).length > 0 &&
              <div className="error-message">
                <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                {RestaurantSlideshowValidation.getErrorMessages(restaurantSlideshowValidationConstants.KEY_COS_FILE_URL)}
              </div>
            }
            {content}
            {inputSetting.isEndOfLine && <div></div>}
          </div>
        })}
      </>,
      btn: true
    },
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  function handleSaveImage(name, image) {
    // console.log('showditimage', image)
    dispatch(setRestaurantSlideshowValue("MEDIA", savedRestaurantSlideshow, "", image));
  }

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  }

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    const defaultLink = "/" + constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT + "/" + savedRestaurantSlideshow.restUrlId + "/" + constants.RESTAURANT_TAB.MEDIA + "/" + constants.RESTAURANT_MEDIA_SUB_TAB.SLIDESHOW;
    history.push(newRedirectPath?newRedirectPath:defaultLink);
  };

  function onChangeInput(name, e) {
    switch (name) {
      case "sequence":
        dispatch(setRestaurantSlideshowValue(name, savedRestaurantSlideshow, "", Number.isInteger(Number(e)) ? Number(e) : 0));
        break;
      case "startDisplayTimestamp":
      case "endDisplayTimestamp":
        const date = Number(moment(e).format("x"));
        dispatch(setRestaurantSlideshowValue(name, savedRestaurantSlideshow, "", date));
        break;
      default:
        dispatch(setRestaurantSlideshowValue(name, savedRestaurantSlideshow, "", e));
    }
  }

  function onClickSave() {
    let tempSavedRestaurantSlideshow = _.cloneDeep(savedRestaurantSlideshow);
    // console.log('edit', tempSavedRestaurantSlideshow)
    RestaurantSlideshowValidation.validateEditRestaurantSlideshow(tempSavedRestaurantSlideshow);
    if (RestaurantSlideshowValidation.isValid()) {
      dispatch(setLoading(true));
      put(constants.PATH.SHOP.RESTAURANT_SLIDESHOW + "/" + params.id, tempSavedRestaurantSlideshow, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToEdit,
          dialogContentText: { key: "editSuccess" },
          dialogActions: [
            { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false);
        dispatch(setLoading(false));
      }, undefined, store);
      // console.log('in post')
      RestaurantSlideshowValidation.resetErrorMessages();
    } else {
      setErrorVersion(RestaurantSlideshowValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    if (clear === true) {
      getOne(constants.PATH.SHOP.RESTAURANT_SLIDESHOW + "/" + params.id, undefined, (payload) => {
        dispatch(setRestaurantSlideshow(payload));
        setReady(true)
        setClear(false)
      }, undefined, store);
    }
    RestaurantSlideshowValidation.resetErrorMessages();
  }, [clear === true]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <>
      <EditCreateDisplay
        code={savedRestaurantSlideshow && savedRestaurantSlideshow.restaurantMediaCode ? savedRestaurantSlideshow.restaurantMediaCode : ""}
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        mapName={{ resource: "restaurant", key: "restUrlId" }}
        mapCode={savedRestaurantSlideshow.mediaKey || ''}
        display="edit"
      />
      {dialog && dialog.open &&
        <DialogWrapper dialogInfo={dialog} />
      }
    </>
  )
};