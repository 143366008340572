export const getMeta = (url, image) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = function () {
      image.imageWidth = this.width;
      image.imageHeight = this.height;
      resolve(image);
    }
  })
}

export function getMetaAsync(url, type) {
  return new Promise((resolve, reject) => {
    if(type === 'video/mp4' || type === 'VIDEO'){
      const video = document.createElement('video');
      video.src = url;
      video.onloadedmetadata = function () { resolve({ width: this.videoWidth, height: this.videoHeight, duration: this.duration }); }
    }else{
      const img = new Image();
      img.src = url;
      img.onload = function () { resolve({ width: this.width, height: this.height }); }
    }
  })
}

// export function getMeta(url, callback) {
//   const img = new Image();
//   img.src = url;
//   img.onload = function () { callback({ width: this.width, height: this.height }); }
// }