export const scMyMessages = {
  //CustomizenName
  nameCustomize: "名称（自动将名称翻译成简体）",

  resource: {
    restaurant: {
      tcName: "餐厅名称",
      tcSearchingName: "搜索名称",
      weChatTcName: "微信餐厅名称",
      tcAddress: "地址",
      tcTicketMessage: "Ticket Message",
      tcOpening: "营业时间",
      tcLastOrderTime: "最后订单时间",
      tcDescription: "详细资料",
      nameCustomize: "名称（自动将名称翻译成简体）",
      searchingNameCustomize: "搜索名称（自动将搜索名称翻译成简体）",
      ticketMessageCustomize: "Ticket Message（自动将繁Ticket Message翻译成简体）",
      openingCustomize: "营业时间（自动将营业时间翻译成简体）",
      lastOrderTimeCustomize: "最后订单时间（自动将最后订单时间翻译成简体）",
      descriptionCustomize: "详细资料（自动将详细资料翻译成简体）",
      addressCustomize: "地址（自动将地址翻译成简体）",
    },

    //RestaurantMedia
    RestaurantMedia: {
      cosFileTcUrl: "图像",
      tcImage: "图像",
    },

    TimeSection: {
      timeSectionTcLabel: "时间段标签",
      isAutoTranslateTimeSectionLabel: "时间段标签（自动将标签翻译成简体）",
    },

    TableType: {
      tableTypeTcLabel: "Table Type 标签",
      isAutoTranslateTableTypeScLabel: "Table Type 标签（自动将Table Type标签翻译成简体）",
      tableTypeMobileTcLabel: "Table Type Mobile 标签",
      isAutoTranslateTableTypeMobileScLabel: "Table Type Mobile 标签（自动将Table Type Mobile标签翻译成简体）",
      ticketTypeTcLabel: "Ticket Type 标签",
    },

    TicketType: {
      tcName: "名称",
    },

    ReservationConfig: {
      remarksTcMessage: "备注信息",
      remarksMessageCustomize: "备注信息（自动将备注信息翻译成简体）",
      disableTcMessage: "禁用消息",
      disableMessageCustomize: "禁用消息（自动将禁用消息翻译成简体）",
      reminderTcMessage: "提醒消息",
      reminderMessageCustomize: "提醒消息（自动将提醒消息翻译成简体）",
      noTimeSectionTcMessage: "无时间段消息",
      noTimeSectionMessageCustomize: "无时间段消息（自动将无时间段消息翻译成简体）",
      chargeTcDescription: "收费描述",
      chargeDescriptionCustomize: "收费描述(自动将收费描述翻译成简体)",
    },

    CampaignDescription: {
      tcTitle: "标题",
      tcMessage: "信息",
      titleCustomize: "标题（自动将标题翻译成简体）",
      messageCustomize: "信息（自动将信息翻译成简体）",
    },

    TnC: {
      tcDescription: "描述",
      descriptionCustomize: "描述（自动将描述翻译成简体）",
    },

    restaurant_map: {
      tcName: "餐厅名称",
      restTcName: "餐厅名称",
      nameCustomize: "名称（自动将名称翻译成简体）",
      tcPictures: "图片",
    },

    restaurant_category: {
      tcName: "名称",
      restTcName: "餐厅名称",
      nameCustomize: "名称（自动将名称翻译成简体）",
      tcPictures: "图片",
    },

    restaurant_cuisine: {
      tcName: "名称",
      restTcName: "餐厅名称",
      nameCustomize: "名称（自动将名称翻译成简体）",
      tcPictures: "图片",
    },

    restaurant_keyword: {
      tcName: "名称",
      restTcName: "餐厅名称",
      nameCustomize: "名称（自动将名称翻译成简体）",
      tcPictures: "图片",
    },

    ConfigMap: {
      restTcName: "餐厅名称",
    },

    Banner: {
      tcImage: "横幅图像",
    },

    Area: {
      tcName: "区域名称",
    },

    RestaurantDistrict: {
      tcName: "地区名称",
      districtTcName: "地区名称",
      areaTcName: "区域名称",
      nameCustomize: "名称（自动将名称翻译成简体）",
    },

    //RestaurantDistrictRestMap
    RestaurantDistrictRestMap: {
      restTcName: "餐厅名称",
    },

    //RestaurantLandmark
    RestaurantLandmark: {
      tcName: "地标名称",
      landmarkTcName: "地标名称",
    },

    //RestaurantDistrictMap
    RestaurantDistrictMap: {
      tcName: "地区名称",
    },

  }
}