import _ from 'lodash'
import { useState, useEffect } from 'react'
import { useStore } from 'react-redux'
import { useParams } from 'react-router-dom'
import { constants as co } from '../../Constants/constants'
import { getList } from '../../helper/baseFetch'
import { Card } from '@mui/material'
import { ListDisplay } from '../../Component/ListDisplay/ListDisplay'

const resource = 'Staff'

export const StaffList = ({ createBtn, editBtn, newFilter }) => {
  const store = useStore()
  const params = useParams()

  const [staffList, setStaffList] = useState([])
  const [ready, setReady] = useState(false)
  const [clear, setClear] = useState(false)

  const listTitles = [
    {
      key: co.LIST_DISPLAY_TYPE.STRING,
      value: 'id',
      sort: false,
    },
    {
      key: co.LIST_DISPLAY_TYPE.STRING,
      value: 'username',
      sort: true,
    },
  ]

  const listFilters = [
    {
      className: 'filter-input',
      label: { resource: resource, key: 'enabled' },
      clear: true,
      value: true,
      name: 'enabled',
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.STAFF.ENABLED,
      disableClearable: true,
    },
    {
      className: 'filter-input',
      label: { resource: resource, key: 'id' },
      clear: true,
      value: '',
      name: 'id',
    },
    {
      className: 'filter-input',
      label: { resource: resource, key: 'username' },
      clear: true,
      value: '',
      name: 'username',
    },
  ]

  const [filterOption, setFilterOption] = useState({
    path: '',
    page: 0,
    rowsPerPage: 10,
    sort: 'updateTimestamp',
    direction: 'DESC',
    listFilters: listFilters,
    count: 0,
  })

  const onChangeFilterOption = (filterOption) => {
    setFilterOption({ ...filterOption, count: filterOption.count + 1 })
  }

  const onChangeListFilters = (listFilters) => {
    onChangeFilterOption({ ...filterOption, listFilters: listFilters, page: 0 })
  }

  const onChangeListSort = (sort) => {
    onChangeFilterOption({ ...filterOption, sort: sort, direction: filterOption.direction === 'DESC' ? 'ASC' : 'DESC' })
  }

  const onChangeListPage = (page) => {
    onChangeFilterOption({ ...filterOption, page: page })
  }

  const onChangeListRowsPerPage = (rowsPerPage) => {
    onChangeFilterOption({ ...filterOption, page: 0, rowsPerPage: rowsPerPage })
  }

  useEffect(() => {
    console.log('a')
    let newFilterOption = _.cloneDeep(filterOption)
    const filter = newFilter || { id: params.id }
    newFilterOption.listFilters.forEach((item) => {
      if (item.value !== '' && item.value !== undefined) {
        filter[item.name] = item.value
      }
    })

    getList(
      co.PATH.MERCHANT.STAFF,
      {
        sort: { field: newFilterOption.sort, order: newFilterOption.direction },
        filter: filter,
        pagination: { page: newFilterOption.page + 1, perPage: newFilterOption.rowsPerPage },
      },
      (payload) => {
        setStaffList(payload)
        setReady(true)
        setClear(false)
      },
      undefined,
      store
    )
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOption.count, clear === true])

  return (
    ready && (
      <Card className="card-wrapper overflow">
        <ListDisplay
          page={filterOption.page}
          rowsPerPage={filterOption.rowsPerPage}
          onChangeListSort={onChangeListSort}
          resource={resource}
          dataList={staffList}
          sort={filterOption.sort}
          direction={filterOption.direction}
          listTitles={listTitles}
          listFilters={filterOption.listFilters}
          onChangeListPage={onChangeListPage}
          onChangeListRowsPerPage={onChangeListRowsPerPage}
          idParam={params.id}
          onChangeListFilters={onChangeListFilters}
          redirectPath={`/${co.PATH.MERCHANT.STAFF}/`}
          editRedirectPath={`/${co.PATH.MERCHANT.STAFF}/edit/${params.id}/`}
          createBtn={createBtn}
          editBtn={editBtn}
        />
      </Card>
    )
  )
}
