import { SYSTEM_USER } from './types';
import _ from "lodash";

const LOCAL_CONSTANT = {
    privilegeAttribute: "privilegeAttribute",
    REST_URL_ID: "restUrlId",
}

export const setSystemUserDefault = () => {
    return {
        type: SYSTEM_USER.SET_SYSTEM_USER_DEFAULT,
    }
}

export const setSystemUser = (systemUser) => {
    return {
        type: SYSTEM_USER.SET_SYSTEM_USER,
        systemUser: systemUser
    }
}

export const setSystemUserValue = (draftType, orignalProduct, key, value, index) => {
    const systemUser = _.cloneDeep(orignalProduct);
    switch (draftType) {
        case LOCAL_CONSTANT.privilegeAttribute:
            systemUser[draftType] = value;
            delete systemUser[LOCAL_CONSTANT.REST_URL_ID];
        break;
        default:
            systemUser[draftType] = value;
    }
    return {
        type: SYSTEM_USER.SET_SYSTEM_USER_VALUE,
        systemUser: systemUser
    }
}