import { useState, useEffect } from 'react'
import { useStore, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import _ from 'lodash'

import { Card } from '@mui/material'

import { ListDisplay } from '../../Component/ListDisplay/ListDisplay'

import { constants } from '../../Constants/constants'
import { getList } from '../../helper/baseFetch'
import { generateTableCellToMultiLanguageName, getParamQueryString, getQuery, getQueryParamObject } from '../../helper/util'

export const DistrictList = ({
  newPath,
  newFilter,
  newListTitles,
  editBtn,
  createBtn,
  mutipleSelect,
  setPreviewSelectedList,
  previewSelectedList,
  selectedIdList,
  setSelectedIdList,
  dialogFooterBtn,
  onClickListRow,
  changeUrlQueryString,
}) => {
  const store = useStore()
  const [areaList, setAreaList] = useState()
  const [districtList, setDistrictList] = useState([])
  const [ready, setReady] = useState(false)
  const [selectedAllCheck, setSelectedAllCheck] = useState(false)
  const total = useSelector((state) => state.system.ui.total)
  const resource = 'RestaurantDistrict'
  const redirectPath = newPath ? newPath : constants.PATH.SHOP.RESTAURANT_DISTRICT
  const history = useHistory()
  const location = useLocation()
  const filterParams = getQuery()
  const districtTitleList = newListTitles
    ? newListTitles
    : [
        {
          key: constants.LIST_DISPLAY_TYPE.STRING,
          value: 'districtId',
          sort: true,
        },
        {
          key: constants.LIST_DISPLAY_TYPE.STRING,
          value: 'name',
          sort: false,
          minSize: true,
        },
        {
          key: constants.LIST_DISPLAY_TYPE.STRING,
          value: 'status',
          sort: true,
        },
        {
          key: constants.LIST_DISPLAY_TYPE.STRING,
          value: 'areaId',
          sort: true,
        },
        {
          key: constants.LIST_DISPLAY_TYPE.STRING,
          value: 'area',
          sort: false,
        },
      ]
  const listFilters = [
    {
      className: 'filter-input',
      label: { resource: resource, key: 'districtId' },
      clear: true,
      value: '',
      name: 'districtId',
      size: constants.SIZE.SMALL,
    },
    {
      className: 'filter-input',
      label: { resource: resource, key: 'name' },
      clear: true,
      value: '',
      name: 'name',
      size: constants.SIZE.SMALL,
    },
    {
      className: `filter-input ${newPath ? 'display-none' : ''}`,
      label: { resource: resource, key: 'area' },
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: [],
      name: 'areaId',
      size: constants.SIZE.SMALL,
    },
    {
      className: 'filter-input',
      label: { resource: resource, key: 'status' },
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.RESTAURANT_AREA.STATUS,
      name: 'status',
      size: constants.SIZE.SMALL,
    },
  ]

  const [param, setParam] = useState({
    path: newPath ? newPath : '',
    page: 0,
    rowsPerPage: 10,
    sort: 'updateTimestamp',
    direction: 'DESC',
    listFilters: listFilters,
    count: 0,
  })

  useEffect(() => {
    if (!changeUrlQueryString || !ready) {
      return
    }

    const querySyring = getParamQueryString(
      {
        path: newPath ? newPath : '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: param.count,
      },
      param
    )

    const currentQueryString = window.location.search?.substring(1)

    if (querySyring === currentQueryString) {
      return
    }

    const newParam = getQueryParamObject(
      {
        path: newPath ? newPath : '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: param.count + 1,
      },
      param.listFilters.map((filter) => ({ ...filter, value: '' })),
      districtTitleList
    )

    setParam(newParam)

    const newQuery = getParamQueryString(
      {
        path: newPath ? newPath : '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: 0,
      },
      newParam
    )

    history.replace({ pathname: location.pathname, search: newQuery })
  }, [filterParams, ready])

  function onChangeParam(value) {
    value.count = value.count + 1
    if (!changeUrlQueryString) {
      setParam(value)
      return
    }

    const querySyring = getParamQueryString(
      {
        path: newPath ? newPath : '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: 0,
      },
      value
    )

    history.push({ pathname: location.pathname, search: querySyring })
  }

  function onChangeListFilters(list) {
    let tempParam = _.cloneDeep(param)
    tempParam.listFilters = list
    tempParam.page = 0
    onChangeParam(tempParam)
  }

  function onChangeListSort(sortItem) {
    let tempParam = _.cloneDeep(param)
    tempParam.sort = sortItem
    tempParam.direction = tempParam.direction === 'DESC' ? 'ASC' : 'DESC'
    onChangeParam(tempParam)
  }

  function onChangeListPage(page) {
    let tempParam = _.cloneDeep(param)
    tempParam.page = page
    onChangeParam(tempParam)
  }

  function onChangeListRowsPerPage(rowsPerPage) {
    let tempParam = _.cloneDeep(param)
    tempParam.page = 0
    tempParam.rowsPerPage = rowsPerPage
    onChangeParam(tempParam)
  }

  function onMultiSelectList(districtId, dataList) {
    let selectedList = _.cloneDeep(selectedIdList)
    let newSelectedDistrictList = _.cloneDeep(previewSelectedList)
    if (districtId === 'all') {
      if (!selectedAllCheck) {
        districtList.map((item) => {
          let selected = newSelectedDistrictList
            .map((item) => {
              return item.districtId
            })
            .includes(item.districtId)
          if (!selected) {
            newSelectedDistrictList.push(item)
          }
          return !selected && selectedList.push(item.districtId)
        })
      } else {
        selectedList = selectedList.filter((item) => {
          let selectedDistrict = districtList.map((district) => {
            newSelectedDistrictList = newSelectedDistrictList.filter((newSelectedDistrict) => newSelectedDistrict.districtId !== district.districtId)
            return district.districtId === item && district.districtId
          })
          return !selectedDistrict.includes(item)
        })
      }
      setSelectedAllCheck(!selectedAllCheck)
    } else {
      if (selectedList.includes(districtId)) {
        selectedList = selectedList.filter((item) => item !== districtId)
        newSelectedDistrictList = newSelectedDistrictList.filter((item) => item.districtId !== districtId)
      } else {
        selectedList.push(districtId)
        newSelectedDistrictList.push.apply(
          newSelectedDistrictList,
          dataList.filter((item) => item.districtId === districtId)
        )
      }

      let selectedPerPage = districtList.filter((district) => {
        return selectedList.includes(district.districtId)
      })
      if (selectedPerPage.length === param.rowsPerPage && selectedPerPage.length !== 0) {
        setSelectedAllCheck(true)
      } else {
        setSelectedAllCheck(false)
      }
    }

    setSelectedIdList(selectedList)

    setPreviewSelectedList(newSelectedDistrictList)
  }

  useEffect(() => {
    let tempParam = _.cloneDeep(param)
    const filter = newFilter
      ? _.cloneDeep(newFilter)
      : selectedIdList && selectedIdList.length > 0
      ? { selected: { column: 'districtId', valueList: selectedIdList } }
      : {}
    tempParam.listFilters.forEach((filterItem) => {
      if (filterItem.value !== '' && filterItem.value !== undefined) {
        filter[filterItem.name] = filterItem.value
      }
    })

    if (tempParam.path) {
      getList(
        tempParam.path,
        {
          sort: { field: tempParam.sort, order: tempParam.direction },
          filter: filter,
          pagination: { page: tempParam.page + 1, perPage: tempParam.rowsPerPage },
        },
        (payload) => {
          if (tempParam.path === constants.PATH.SHOP.RESTAURANT_LANDMARK_DISTRICT_LIST) {
            payload.map((item) => {
              item.name = generateTableCellToMultiLanguageName(item.tcName, item.enName, item.scName)
              return item
            })
          } else {
            payload.map((item) => {
              item.name = generateTableCellToMultiLanguageName(item.districtTcName, item.districtEnName, item.districtScName)
              return item
            })
          }
          let selectedPerPage = payload.filter((district) => {
            return selectedIdList && selectedIdList.includes(district.districtId)
          })
          if (selectedPerPage.length === (tempParam.rowsPerPage < total ? tempParam.rowsPerPage : total) && selectedPerPage.length !== 0) {
            setSelectedAllCheck(true)
          } else {
            setSelectedAllCheck(false)
          }
          setDistrictList(payload)
          setReady(true)
        },
        undefined,
        store
      )
    } else {
      getList(
        constants.PATH.SHOP.RESTAURANT_DISTRICT_WRAPPER,
        {
          sort: { field: tempParam.sort, order: tempParam.direction },
          filter: filter,
          pagination: { page: tempParam.page + 1, perPage: tempParam.rowsPerPage },
        },
        (payload) => {
          if (!areaList) {
            tempParam.listFilters.forEach((filterItem) => {
              if (filterItem.name === 'areaId') {
                filterItem.selectValue = payload.areaList.map((item, index) => {
                  return { name: item.tcName, value: item.areaId }
                })
              }
            })

            setParam(
              !changeUrlQueryString
                ? { ...tempParam }
                : getQueryParamObject(
                    { ...tempParam },
                    tempParam.listFilters.map((filter) => ({ ...filter, value: '' })),
                    districtTitleList
                  )
            )
          }
          payload.districtList.list.map((item) => {
            item.name = generateTableCellToMultiLanguageName(item.tcName, item.enName, item.scName)
            item.area = generateTableCellToMultiLanguageName(item.areaTcName, item.areaEnName, item.areaScName)
            return item
          })
          setAreaList(payload.areaList)
          setDistrictList(payload.districtList.list)
          let selectedPerPage = payload.districtList.list.filter((district) => {
            return selectedIdList && selectedIdList.includes(district.districtId)
          })
          if (
            selectedPerPage.length === (tempParam.rowsPerPage < payload.districtList.total ? tempParam.rowsPerPage : payload.districtList.total) &&
            selectedPerPage.length !== 0
          ) {
            setSelectedAllCheck(true)
          } else {
            setSelectedAllCheck(false)
          }
          setReady(true)
        },
        undefined,
        store
      )
    }
  }, [param.count]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready && (
      <Card className="card-wrapper overflow">
        <ListDisplay
          onClickListRow={onClickListRow}
          page={param.page}
          rowsPerPage={param.rowsPerPage}
          onChangeListSort={onChangeListSort}
          resource={resource}
          dataList={districtList}
          idName="districtId"
          sort={param.sort}
          direction={param.direction}
          listTitles={districtTitleList}
          listFilters={param.listFilters}
          onChangeListPage={onChangeListPage}
          onChangeListRowsPerPage={onChangeListRowsPerPage}
          onChangeListFilters={onChangeListFilters}
          editBtn={editBtn}
          createBtn={createBtn}
          mutipleSelect={mutipleSelect}
          onMultiSelectList={onMultiSelectList}
          selectedAllCheck={selectedAllCheck}
          previewSelectedIdList={selectedIdList}
          dialogFooterBtn={dialogFooterBtn}
          redirectPath={'/' + redirectPath + '/'}
        />
      </Card>
    )
  )
}
