import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import { setProductValue } from "../../../actions/productCreateAction";
import { constants } from '../../../Constants/constants';
import ProductCreateValidation from "../../../validations/productCreateValidation";
import { productValidationConstants } from '../../../Constants/validationConstants';
import { setLoading } from "../../../actions/systemAction";
import { TempAutoSelectInput } from "../../Input/TempAutoSelectInput";
import { TempTextInput } from "../../Input/TempTextInput";
import { DialogWrapper } from "../../DialogWrapper";

export const ProductType = ({ disableInput, helperTextStylesClasses }) => {
  const dispatch = useDispatch();
  const savedProduct = useSelector(state => state.product.ui.product)

  const [open, setOpen] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState(" ");
  const [type, setType] = useState(" ");
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });

  const getTypeSelectValue = ()=>{
    switch(savedProduct.draft.category.type){
      case constants.CHOICES.PRODUCT_CREATE.SELECT_CATEGORY_TYPE.FREE_ECOUPON:
        return constants.CHOICES.PRODUCT_CREATE.CREATE_PRODUCT.TYPE.filter((item)=> item.value === constants.PRODUCT_TYPE.FREE_ECOUPON_ITEM);
      case constants.CHOICES.PRODUCT_CREATE.SELECT_CATEGORY_TYPE.PRODUCT:
        return constants.CHOICES.PRODUCT_CREATE.CREATE_PRODUCT.TYPE.filter((item)=> item.value !== constants.PRODUCT_TYPE.FREE_ECOUPON_ITEM);
      default:
        return constants.CHOICES.PRODUCT_CREATE.CREATE_PRODUCT.TYPE;
    }
  }

  function typeChange(type) {
    let productCode = savedProduct.draft.product.productCode;
    let tempProduct = _.cloneDeep(savedProduct.draft.product);

    tempProduct.type = type;
    tempProduct.subProductMapList = [];
    tempProduct.redeemGroupCode = "";
    tempProduct.productCode = productCode;
    tempProduct.packageCashCoupon = false;
    tempProduct.attributeList = [];
    tempProduct.lotInventoryList = [];

    if (type === constants.PRODUCT_TYPE.PACKAGE_SUB_ITEM) {
      tempProduct.searchable = false;
      tempProduct.shareable = "";
    } else if (type === constants.PRODUCT_TYPE.PACKAGE_ITEM) {
      tempProduct.searchable = "";
      tempProduct.shareable = false;
    } else if (type === constants.PRODUCT_TYPE.FREE_ECOUPON_ITEM){
      tempProduct.originalPrice = 0;
      tempProduct.sellingPrice = 0;
    }else {
      tempProduct.searchable = "";
      tempProduct.shareable = "";
    }
    
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.DRAFT, savedProduct, "product", tempProduct));
  }

  const handleTypeChangeDialogClose = () => {
    dispatch(setLoading(true));
    setOpen(false);
    dispatch(setLoading(false));
  };
  const handleTypeChangeDialogConfirm = () => {
    dispatch(setLoading(true));
    setOpen(false);
    typeChange(type)
    dispatch(setLoading(false));
  };

  function handleType(name, type) {
    if (savedProduct.draft.product.type) {
      setOpen(true);
      setPopUpMessage({ resource: "productCreateProductList", key: "typeChangeMessage" })
      setType(type);
    } else {
      typeChange(type);
    }

  }

  useEffect(() => {
    setDialog({
      open: open,
      onClose: handleTypeChangeDialogClose,
      dialogTitle: { resource: "productCreateProductList", key: "typeChange" },
      dialogContentText: popUpMessage,
      dialogActions: [
        { label: { key: "close" }, onClick: handleTypeChangeDialogClose, icon: <CloseIcon /> },
        { label: { key: "comfirm" }, onClick: handleTypeChangeDialogConfirm, icon: <CheckIcon /> }
      ],
    });
  }, [open]);// eslint-disable-line react-hooks/exhaustive-deps
  

  return (
    <>
      {savedProduct.draft.product.id || disableInput ?
        <TempTextInput
          inputSetting={{
            className: "edit-create-input",
            variant: constants.STYLE.VARIANT.STANDARD,
            size: constants.SIZE.SMALL,
            label: { resource: "productCreateProductList", key: "type" },
            value: savedProduct.draft.product.type,
            disabled: savedProduct.draft.product.id ? true : disableInput,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_TYPE, savedProduct.draft.product.productCode).map((error) => { return error }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
          }}
          handleChange={handleType}
        />
        :
        <TempAutoSelectInput
          inputSetting={{
            className: "edit-create-input",
            size: constants.SIZE.SMALL,
            variant: constants.STYLE.VARIANT.OUTLINED,
            type: constants.TYPE.SELECT,
            selectValue: getTypeSelectValue(),//constants.CHOICES.PRODUCT_CREATE.CREATE_PRODUCT.TYPE,
            label: { resource: "productCreateProductList", key: "type" },
            value: savedProduct.draft.product.type,
            disabled: disableInput,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_TYPE, savedProduct.draft.product.productCode).map((error) => { return error }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
          }}
          handleChange={handleType}
        />
      }
      {dialog && dialog.open &&
        <DialogWrapper dialogInfo={dialog} />
      }
    </>
  )
};