import _ from 'lodash'
import { MERCHANT_DEVICE } from '../actions/types'
import { constants } from '../Constants/constants'

const INITIAL_STATE = {
  ui: {
    merchantDevice: _.cloneDeep(constants.MERCHANT_DEVICE_INPUT),
  },
}

const merchantDeviceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MERCHANT_DEVICE.SET_MERCHANT_DEVICE_DEFAULT:
      return Object.assign({}, INITIAL_STATE)
    case MERCHANT_DEVICE.SET_MERCHANT_DEVICE:
    case MERCHANT_DEVICE.SET_MERCHANT_DEVICE_VALUE:
      return Object.assign(
        {},
        {
          ...state,
          ui: {
            ...state.ui,
            merchantDevice: action.merchantDevice,
          },
        }
      )
    default:
      return state
  }
}

export default merchantDeviceReducer
