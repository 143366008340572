import { constants } from '../../Constants/constants'

export const getClonedProduct = (product) => {
  return {
    ...constants.PRODUCT,
    type: product.type,
    channel: product.channel,
    redeemGroupCode: product.redeemGroupCode,
    systemGroupId: product.systemGroupId,
    systemGroupName: product.systemGroupName,
    brandCode: product.brandCode,
    brandName: product.brandName,
    brandTcName: product.brandTcName,
    tcTac: product.tcTac,
    enTac: product.enTac,
    scTac: product.scTac,
    scTacDisable: product.scTacDisable,
    tcRedeemDescription: product.tcRedeemDescription,
    enRedeemDescription: product.enRedeemDescription,
    scRedeemDescription: product.scRedeemDescription,
    startDisplayTimestamp: product.startDisplayTimestamp,
    endDisplayTimestamp: product.endDisplayTimestamp,
    personLimit: product.personLimit,
    bufferRedeemDay: product.bufferRedeemDay,
    searchable: product.searchable,
    selfRedeem: product.selfRedeem,
    selfRefund: product.selfRefund,
    shareable: product.shareable,
    packageCashCoupon: product.packageCashCoupon,
    requireFillInContactPerson: product.requireFillInContactPerson,
    requireFillInMobileNumber: product.requireFillInMobileNumber,
    requireFillInEmail: product.requireFillInEmail,
    displaySequence: product.displaySequence,
    originalPrice: product.type === constants.PRODUCT_TYPE.FREE_ECOUPON_ITEM ? 0:  -1,
    sellingPrice: product.type === constants.PRODUCT_TYPE.FREE_ECOUPON_ITEM ? 0:  -1,
  }
}
