import { QUEUE_TEMPLATE_MAP } from './types';
import _ from "lodash";

export const setQueueTemplateMapDefault = () => {
    return {
        type: QUEUE_TEMPLATE_MAP.SET_QUEUE_TEMPLATE_MAP_DEFAULT,
    }
}

export const setQueueTemplateMap = (queueTemplateMap) => {
    return {
        type: QUEUE_TEMPLATE_MAP.SET_QUEUE_TEMPLATE_MAP,
        queueTemplateMap: queueTemplateMap
    }
}

export const setQueueTemplateMapValue = (draftType, orignalProduct, key, value, index) => {
    const queueTemplateMap = _.cloneDeep(orignalProduct);
    switch (draftType) {
        default:
            queueTemplateMap[draftType] = value;
    }
    return {
        type: QUEUE_TEMPLATE_MAP.SET_QUEUE_TEMPLATE_MAP_VALUE,
        queueTemplateMap: queueTemplateMap
    }
}

export const setQueueTemplateMapList = (queueTemplateMapList) => {
    return {
        type: QUEUE_TEMPLATE_MAP.SET_QUEUE_TEMPLATE_MAP_LIST,
        queueTemplateMapList: queueTemplateMapList
    }
}
