import { SYSTEM } from '../actions/types';

const INITIAL_STATE = {
    ui: {
        loading: false,
        message: "",
        messageType: "",
        total: 0,
    }
}

const systemReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SYSTEM.SET_LOADING:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    loading: action.loading
                }
            })
        case SYSTEM.SET_MESSAGE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    message: action.message,
                    messageType: action.messageType
                }
            })
        case SYSTEM.SET_TOTAL:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    total: action.total
                }
            })
        default:
            return state
    }
}

export default systemReducer