import { MERCHANT_CONFIG } from './types';
import _ from "lodash";

export const setMerchantConfigDefault = () => {
    return {
        type: MERCHANT_CONFIG.SET_MERCHANT_CONFIG_DEFAULT,
    }
}

export const setMerchantConfig = (merchantConfig) => {
    return {
        type: MERCHANT_CONFIG.SET_MERCHANT_CONFIG,
        merchantConfig: merchantConfig
    }
}

export const setMerchantConfigValue = (draftType, orignalProduct, key, value, index) => {
    const merchantConfig = _.cloneDeep(orignalProduct);
    merchantConfig[draftType] = value;
    return {
        type: MERCHANT_CONFIG.SET_MERCHANT_CONFIG_VALUE,
        merchantConfig: merchantConfig
    }
}
