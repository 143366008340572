import _ from "lodash";

export function handleBannerDisableSaveButton(savedConfigMap) {
  let disableButton = [];
  const tempBanner = _.cloneDeep(savedConfigMap);
  tempBanner.forEach((savedConfigMapValue) => {
    if(!savedConfigMapValue.serviceType || !savedConfigMapValue.ticketStatus
      || !savedConfigMapValue.startDisplayDate || !savedConfigMapValue.endDisplayDate ) {
        disableButton.push(true);
    }
  });
  return disableButton.includes(true);
}