import { COUNTER } from './types'
import _ from 'lodash'
import { sify } from 'chinese-conv'

export const setCounterDefault = () => {
  return {
    type: COUNTER.SET_COUNTER_DEFAULT,
  }
}

export const setCounter = (counter) => {
  return {
    type: COUNTER.SET_COUNTER,
    counter: counter,
  }
}

export const setCounterValue = (draftType, orignalProduct, key, value, index) => {
  const counter = _.cloneDeep(orignalProduct)
  switch (draftType) {
    case 'tcName':
        counter.tcName = value
        counter.scName = counter.scNameDisable ? sify(value) : counter.scName
      break
    case 'scNameDisable':
        counter.scNameDisable = value
      if (value) {
        counter.scName = !counter.tcName ? counter.scName : sify(counter.tcName)
      }
      break
    default:
        counter[draftType] = value
  }
  return {
    type: COUNTER.SET_COUNTER_VALUE,
    counter: counter,
  }
}
