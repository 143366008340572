import { SELECT_TAG_CONFIG } from './types';
import _ from "lodash";

export const setSelectTagConfigDefault = () => {
    return {
        type: SELECT_TAG_CONFIG.SET_SELECT_TAG_CONFIG_DEFAULT,
    }
}

export const setSelectTagConfig = (selectTagConfig) => {
    return {
        type: SELECT_TAG_CONFIG.SET_SELECT_TAG_CONFIG,
        selectTagConfig: selectTagConfig
    }
}

export const setSelectTagConfigValue = (draftType, orignalProduct, key, value, index) => {
    const selectTagConfig = _.cloneDeep(orignalProduct);

    switch(draftType){
        case "DRAFT":
            selectTagConfig.draft = value;
            break;
        default:
            selectTagConfig[draftType] = value;
    }
    return {
        type: SELECT_TAG_CONFIG.SET_SELECT_TAG_CONFIG_VALUE,
        selectTagConfig: selectTagConfig
    }
}
