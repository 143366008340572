import { ADMIN_DEVICE } from './types';
import _ from "lodash";

export const setAdminDeviceDefault = () => {
    return {
        type: ADMIN_DEVICE.SET_ADMIN_DEVICE_DEFAULT,
    }
}

export const setAdminDevice = (adminDevice) => {
    return {
        type: ADMIN_DEVICE.SET_ADMIN_DEVICE,
        adminDevice: adminDevice
    }
}

export const setAdminDeviceValue = (draftType, orignalProduct, key, value, index) => {
    const adminDevice = _.cloneDeep(orignalProduct);
    switch (draftType) {
        default:
            adminDevice[draftType] = value;
    }
    return {
        type: ADMIN_DEVICE.SET_ADMIN_DEVICE_VALUE,
        adminDevice: adminDevice
    }
}
