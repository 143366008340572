import { merchantUserGroupValidationConstants } from '../Constants/validationConstants';
import { validateEmail } from "../helper/util";

const LOCAL_CONSTANT = {
  EMAIL: "EMAIL",
  SETTLEMENT_CONFIG: "SETTLEMENT_CONFIG",
}

class MerchantUserGroupValidation {

  version;
  errorMap;
  merchantUserGroupErrorMap;
  valid;

  constructor() {

    if (!MerchantUserGroupValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.merchantUserGroupErrorMap = new Map();
      MerchantUserGroupValidation.instance = this;
    }
    return MerchantUserGroupValidation.instance;
  }

  validateEditMerchantUserGroup(merchantUserGroup) {
    this.resetErrorMessages();
    this.handleValidateEditMerchantUserGroup(merchantUserGroup);
    this.version++;
  }

  validateCreateMerchantUserGroup(merchantUserGroup) {
    this.resetErrorMessages();
    this.handleValidateCreateMerchantUserGroup(merchantUserGroup);
    this.version++;
  }

  resetErrorMessages(option, arrayLength, removeIndex) {
    switch(option){
      case LOCAL_CONSTANT.EMAIL:

        for(let i=removeIndex; i<arrayLength; i++){
          const key = `${i}-${merchantUserGroupValidationConstants.KEY_EMAIL}`;
          const nextKey = `${i+1}-${merchantUserGroupValidationConstants.KEY_EMAIL}`;
          if(this.merchantUserGroupErrorMap.has(key)){
            this.merchantUserGroupErrorMap.delete(key);
          }
          if(this.merchantUserGroupErrorMap.has(nextKey)){
            this.merchantUserGroupErrorMap.set(key, this.merchantUserGroupErrorMap.get(nextKey));
          }
        }

        break;
      case LOCAL_CONSTANT.SETTLEMENT_CONFIG:

        for(const [key, ] of this.merchantUserGroupErrorMap){
          if(key.indexOf("-") === -1){
            continue;
          }

          let [index, description] = key.split("-");

          index = parseInt(index);

          if(description === merchantUserGroupValidationConstants.KEY_EMAIL){
            continue;
          }

          if(index === removeIndex){
            this.merchantUserGroupErrorMap.delete(key);
          }
        }
        

        for(const [key, value] of this.merchantUserGroupErrorMap){

          if(key.indexOf("-") === -1){
            continue;
          }

          let [index, description] = key.split("-");

          index = parseInt(index);
          let prevIndex = index-1;

          if(description === merchantUserGroupValidationConstants.KEY_EMAIL){
            continue;
          }


          if(index <= removeIndex){
            continue;
          }

          this.merchantUserGroupErrorMap.set(`${prevIndex}-${description}`, value);
          this.merchantUserGroupErrorMap.delete(key);
        }

        break;
      default:
        this.errorMap = new Map();
        this.merchantUserGroupErrorMap = new Map();
        this.valid = true;
        break;
    }
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key) {
      return this.merchantUserGroupErrorMap.get(key);
  }

  handleValidateEditMerchantUserGroup(merchantUserGroup) {
    if(!merchantUserGroup.groupDesc){
      this.setMerchantUserGroupErrorMapMessages(`${merchantUserGroupValidationConstants.KEY_GROUP_DESCRIPTION}`, "Missing Group Description");
    }
  }

  handleValidateCreateMerchantUserGroup(merchantUserGroup) {

    if(!merchantUserGroup.groupDesc){
      this.setMerchantUserGroupErrorMapMessages(`${merchantUserGroupValidationConstants.KEY_NAME}`, "Missing Group Description");
    }

    if(merchantUserGroup.emailList){
      for(let i=0; i<merchantUserGroup.emailList.length; i++){
        if(!validateEmail(merchantUserGroup.emailList[i])){
          this.setMerchantUserGroupErrorMapMessages(`${i}-${merchantUserGroupValidationConstants.KEY_EMAIL}`, "Invalid Email");
        }
      }
    }

    if(merchantUserGroup.restUrlIdList && merchantUserGroup.restUrlIdList.length === 0){
      this.setMerchantUserGroupErrorMapMessages(`${merchantUserGroupValidationConstants.KEY_REST_URL_ID}`, "At least One Restaurant is required");
    }

    if(merchantUserGroup.settlementConfigList){
      for(let i=0; i<merchantUserGroup.settlementConfigList.length; i++){
        if(!merchantUserGroup.settlementConfigList[i].serviceType){
          this.setMerchantUserGroupErrorMapMessages(`${i}-${merchantUserGroupValidationConstants.KEY_SERVICE_TYPE}`, "Missing Service Type");
        }

        if(!merchantUserGroup.settlementConfigList[i].description){
          this.setMerchantUserGroupErrorMapMessages(`${i}-${merchantUserGroupValidationConstants.KEY_DESCRIPTION}`, "Missing Description");
        }

        if(!merchantUserGroup.settlementConfigList[i].profile){
          this.setMerchantUserGroupErrorMapMessages(`${i}-${merchantUserGroupValidationConstants.KEY_PROFILE}`, "Missing Profile");
        }

        if(!merchantUserGroup.settlementConfigList[i].alipayHkRate){
          this.setMerchantUserGroupErrorMapMessages(`${i}-${merchantUserGroupValidationConstants.KEY_ALIPAYHK_RATE}`, "Missing AlipayHk Rate");
        }

        if(merchantUserGroup.settlementConfigList[i].alipayHkRate && merchantUserGroup.settlementConfigList[i].alipayHkRate <0){
          this.setMerchantUserGroupErrorMapMessages(`${i}-${merchantUserGroupValidationConstants.KEY_ALIPAYHK_RATE}`, "Invalid AlipayHk Rate");
        }

        if(!merchantUserGroup.settlementConfigList[i].wechatPayRate){
          this.setMerchantUserGroupErrorMapMessages(`${i}-${merchantUserGroupValidationConstants.KEY_WECHATPAY_RATE}`, "Missing Wechat Pay Rate");
        }

        if(merchantUserGroup.settlementConfigList[i].wechatPayRate && merchantUserGroup.settlementConfigList[i].wechatPayRate <0){
          this.setMerchantUserGroupErrorMapMessages(`${i}-${merchantUserGroupValidationConstants.KEY_WECHATPAY_RATE}`, "Invalid Wechat Pay Rate");
        }

        if(!merchantUserGroup.settlementConfigList[i].stripeRate){
          this.setMerchantUserGroupErrorMapMessages(`${i}-${merchantUserGroupValidationConstants.KEY_STRIPE_RATE}`, "Missing Stripe Rate");
        }

        if(merchantUserGroup.settlementConfigList[i].stripeRate && merchantUserGroup.settlementConfigList[i].stripeRate <0){
          this.setMerchantUserGroupErrorMapMessages(`${i}-${merchantUserGroupValidationConstants.KEY_STRIPE_RATE}`, "Invalid Stripe Rate");
        }

        if(!merchantUserGroup.settlementConfigList[i].yedRate){
          this.setMerchantUserGroupErrorMapMessages(`${i}-${merchantUserGroupValidationConstants.KEY_YED_RATE}`, "Missing Yed Rate");
        }

        if(merchantUserGroup.settlementConfigList[i].yedRate && merchantUserGroup.settlementConfigList[i].yedRate <0){
          this.setMerchantUserGroupErrorMapMessages(`${i}-${merchantUserGroupValidationConstants.KEY_YED_RATE}`, "Invalid Yed Rate");
        }

        if(!merchantUserGroup.settlementConfigList[i].mpayRate){
          this.setMerchantUserGroupErrorMapMessages(`${i}-${merchantUserGroupValidationConstants.KEY_MPAY_RATE}`, "Missing Mpay Rate");
        }

        if(merchantUserGroup.settlementConfigList[i].mpayRate && merchantUserGroup.settlementConfigList[i].mpayRate <0){
          this.setMerchantUserGroupErrorMapMessages(`${i}-${merchantUserGroupValidationConstants.KEY_MPAY_RATE}`, "Invalid Mpay Rate");
        }

        if(!merchantUserGroup.settlementConfigList[i].guluRate){
          this.setMerchantUserGroupErrorMapMessages(`${i}-${merchantUserGroupValidationConstants.KEY_GULU_RATE}`, "Missing Gulu Rate");
        }

        if(merchantUserGroup.settlementConfigList[i].guluRate && merchantUserGroup.settlementConfigList[i].guluRate <0){
          this.setMerchantUserGroupErrorMapMessages(`${i}-${merchantUserGroupValidationConstants.KEY_GULU_RATE}`, "Invalid Gulu Rate");
        }

        if(!merchantUserGroup.settlementConfigList[i].saleSettlementProportion){
          this.setMerchantUserGroupErrorMapMessages(`${i}-${merchantUserGroupValidationConstants.KEY_SALE_SETTLEMENT_PROPORTION}`, "Missing Sale Settlement Proportion");
        }

        if(merchantUserGroup.settlementConfigList[i].saleSettlementProportion && merchantUserGroup.settlementConfigList[i].saleSettlementProportion <0){
          this.setMerchantUserGroupErrorMapMessages(`${i}-${merchantUserGroupValidationConstants.KEY_SALE_SETTLEMENT_PROPORTION}`, "Invalid Sale Settlement Proportion");
        }

        if(!merchantUserGroup.settlementConfigList[i].redemptionSettlementProportion){
          this.setMerchantUserGroupErrorMapMessages(`${i}-${merchantUserGroupValidationConstants.KEY_REDEMPTION_SETTLEMENT_PROPORTION}`, "Missing Redemption Settlement Proportion");
        }

        if(merchantUserGroup.settlementConfigList[i].redemptionSettlementProportion && merchantUserGroup.settlementConfigList[i].redemptionSettlementProportion <0){
          this.setMerchantUserGroupErrorMapMessages(`${i}-${merchantUserGroupValidationConstants.KEY_REDEMPTION_SETTLEMENT_PROPORTION}`, "Invalid Redemption Settlement Proportion");
        }

        if(!merchantUserGroup.settlementConfigList[i].expiredSettlementRate){
          this.setMerchantUserGroupErrorMapMessages(`${i}-${merchantUserGroupValidationConstants.KEY_EXPIRED_SETTLEMENT_RATE}`, "Missing Expired Settlement Rate");
        }

        if(merchantUserGroup.settlementConfigList[i].expiredSettlementRate && merchantUserGroup.settlementConfigList[i].expiredSettlementRate <0){
          this.setMerchantUserGroupErrorMapMessages(`${i}-${merchantUserGroupValidationConstants.KEY_EXPIRED_SETTLEMENT_RATE}`, "Invalid Expired Settlement Rate");
        }

        const startTime = Number(merchantUserGroup.settlementConfigList[i].startTimestamp);
        const endTime = Number(merchantUserGroup.settlementConfigList[i].endTimestamp);

        if (isNaN(startTime)) {
          this.setMerchantUserGroupErrorMapMessages(`${i}-${merchantUserGroupValidationConstants.KEY_START_TIMESTAMP}`,'Invalid start timestamp');
        }

        if (isNaN(endTime)) {
          this.setMerchantUserGroupErrorMapMessages(`${i}-${merchantUserGroupValidationConstants.KEY_END_TIMESTAMP}`,'Invalid end timestamp');
        }

        if (!isNaN(startTime) && !isNaN(endTime) && startTime > endTime) {
          this.setMerchantUserGroupErrorMapMessages(`${i}-${merchantUserGroupValidationConstants.KEY_START_TIMESTAMP}`,'Start time is larger than End time');
        }

      }
    }
    
  }




  setMerchantUserGroupErrorMapMessages(key, errorMessages, valid = false) {
    this.merchantUserGroupErrorMap.set(key, errorMessages);
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new MerchantUserGroupValidation();
export default validation;