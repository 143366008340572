import { RestaurantTagEdit } from "../../Component/RestaurantTag/RestaurantTagEdit";
import { constants } from "../../Constants/constants";

import "../../styles/map.css";

export const RestaurantKeywordEdit = () => {
  return (
    <RestaurantTagEdit resource={constants.PATH.RESTAURANT_TAG.RESTAURANT_KEYWORD} resourceName="restaurant_keyword"/>
  );
};
