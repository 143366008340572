import { STATUS } from './types';
import _ from "lodash";

export const setStatusDefault = () => {
    return {
        type: STATUS.SET_STATUS_DEFAULT,
    }
}

export const setStatus = (status) => {
    return {
        type: STATUS.SET_STATUS,
        status: status
    }
}

export const setStatusValue = (draftType, orignalProduct, key, value, index) => {
    const status = _.cloneDeep(orignalProduct);
    switch (draftType) {
        default:
            status[draftType] = value;
    }
    return {
        type: STATUS.SET_STATUS_VALUE,
        status: status
    }
}
