import _ from "lodash";

import { constants } from '../../Constants/constants';
import { getList } from "../../helper/baseFetch";

export const getSalesName = (param, store) => {
  return new Promise((resolve) => {
    getList(constants.PATH.SALES.SALES_USER, undefined, (payload) => {
      let tempParam = _.cloneDeep(param);
      tempParam.listFilters.forEach((filterItem) => {
        if (filterItem.name === 'salesName') {
          filterItem.selectValue =
            payload.map((item, index) => {
              return { value: item.username, name: item.username }
            })
        };
      })
      resolve(tempParam);
    }, undefined, store);
  })
}