import { useState, useEffect } from 'react'
import { useStore } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'

import { Card } from '@mui/material'

import { ListDisplay } from '../../Component/ListDisplay/ListDisplay'

import { constants } from '../../Constants/constants'
import { getList } from '../../helper/baseFetch'
import { getParamQueryString, getQuery, getQueryParamObject } from '../../helper/util'

export const SelectTagList = ({
  editBtn,
  createBtn,
  mutipleSelect,
  dialogFooterBtn,
  selectMap,
  setSelectMap,
  newFilter,
  newListFilters,
  screenList,
  changeUrlQueryString,
}) => {
  const store = useStore()
  const [selectedTagList, setSeletedTagList] = useState([])
  const [selectedAllCheck, setSelectedAllCheck] = useState(false)
  // const [selectedIdList, setSelectedIdList] = useState([]);
  const [ready, setReady] = useState(false)
  const resource = 'SelectTag'
  const history = useHistory()
  const location = useLocation()
  const filterParams = getQuery()

  const listTitles = [
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: 'tagCode',
      sort: true,
    },
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: 'enName',
      sort: true,
    },
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: 'tcName',
      sort: true,
    },
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: 'scName',
      sort: true,
    },
  ]

  const listFilters = newListFilters
    ? newListFilters
    : [
        {
          className: 'filter-input',
          label: { resource: resource, key: 'tag_code' },
          clear: true,
          value: '',
          name: 'tagCode',
          size: constants.SIZE.SMALL,
        },
        {
          className: 'filter-input',
          label: { resource: resource, key: 'select_tag_name' },
          clear: true,
          value: '',
          name: 'name',
          size: constants.SIZE.SMALL,
        },
      ]
  const addNewEntryToMap = (map, array) => {
    for (const item of array) {
      map.set(item.tagCode, item)
    }
    return map
  }

  const checkAllSelected = (map) => {
    let selectedPerPage = selectedTagList.filter((item) => {
      return map.has(item.tagCode)
    }).length

    let currentPageEntry = selectedTagList.length

    if (selectedPerPage === currentPageEntry && selectedPerPage !== 0) {
      setSelectedAllCheck(true)
    } else {
      setSelectedAllCheck(false)
    }
  }

  const onMultiSelectList = (id, dataList, row) => {
    let tempMap = _.cloneDeep(selectMap)
    if (id === 'all') {
      if (!selectedAllCheck) {
        setSelectMap(addNewEntryToMap(tempMap, selectedTagList))
        return
      }

      selectedTagList.forEach((item) => tempMap.delete(item.tagCode))
      setSelectMap(tempMap)
      return
    }

    if (selectMap.has(id)) {
      tempMap.delete(id)
    } else {
      tempMap.set(id, row)
    }

    setSelectMap(tempMap)
  }

  const [param, setParam] = useState(
    !changeUrlQueryString
      ? {
          path: '',
          page: 0,
          rowsPerPage: 10,
          sort: 'update_timestamp',
          direction: 'DESC',
          listFilters: _.cloneDeep(listFilters),
          count: 0,
        }
      : getQueryParamObject(
          {
            path: '',
            page: 0,
            rowsPerPage: 10,
            sort: 'updateTimestamp',
            direction: 'DESC',
            listFilters: _.cloneDeep(listFilters),
            count: 0,
          },
          _.cloneDeep(listFilters),
          listTitles
        )
  )

  useEffect(() => {
    if (!changeUrlQueryString) {
      return
    }

    const querySyring = getParamQueryString(
      {
        path: '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: param.count,
      },
      param
    )

    const currentQueryString = window.location.search?.substring(1)

    if (querySyring === currentQueryString) {
      return
    }

    const newParam = getQueryParamObject(
      {
        path: '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: param.count + 1,
      },
      _.cloneDeep(listFilters),
      listTitles
    )

    setParam(newParam)

    const newQuery = getParamQueryString(
      {
        path: '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: 0,
      },
      newParam
    )

    history.replace({ pathname: location.pathname, search: newQuery })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams])

  function onChangeParam(value) {
    value.count = value.count + 1

    if (!changeUrlQueryString) {
      setParam(value)
      return
    }

    const querySyring = getParamQueryString(
      {
        path: '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: 0,
      },
      value
    )

    history.push({ pathname: location.pathname, search: querySyring })
  }

  function onChangeListFilters(list) {
    let tempParam = _.cloneDeep(param)
    tempParam.listFilters = list
    tempParam.page = 0
    onChangeParam(tempParam)
  }

  function onChangeListSort(sortItem) {
    let tempParam = _.cloneDeep(param)
    tempParam.sort = sortItem
    tempParam.direction = tempParam.direction === 'DESC' ? 'ASC' : 'DESC'
    onChangeParam(tempParam)
  }

  function onChangeListPage(page) {
    let tempParam = _.cloneDeep(param)
    tempParam.page = page
    onChangeParam(tempParam)
  }

  function onChangeListRowsPerPage(rowsPerPage) {
    let tempParam = _.cloneDeep(param)
    tempParam.page = 0
    tempParam.rowsPerPage = rowsPerPage
    onChangeParam(tempParam)
  }

  useEffect(() => {
    let tempParam = _.cloneDeep(param)
    const filter = newFilter ? _.cloneDeep(newFilter) : {}
    tempParam.listFilters.forEach((filterItem) => {
      if (filterItem.value !== '' && filterItem.value !== undefined) {
        if (filterItem.type === constants.TYPE.DATETIME_LOCAL) {
          filter[filterItem.name] = moment(filterItem.value).format('x')
        } else {
          filter[filterItem.name] = filterItem.value
        }
      }
    })

    getList(
      constants.PATH.SELECTED_TAG.SELECTED_TAG,
      {
        sort: { field: tempParam.sort, order: tempParam.direction },
        filter: filter,
        pagination: { page: tempParam.page + 1, perPage: tempParam.rowsPerPage },
      },
      (payload) => {
        if (screenList) {
          payload = payload.filter((item) => !screenList.has(item.tagCode))
        }
        setSeletedTagList(payload)
        setReady(true)
      },
      undefined,
      store
    )
  }, [param.count]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!selectMap) {
      return
    }
    checkAllSelected(selectMap)
  }, [selectedTagList, selectMap])

  return (
    ready && (
      <Card className="card-wrapper overflow">
        <ListDisplay
          idName="tagCode"
          page={param.page}
          rowsPerPage={param.rowsPerPage}
          onChangeListSort={onChangeListSort}
          resource={resource}
          dataList={selectedTagList}
          sort={param.sort}
          direction={param.direction}
          listTitles={listTitles}
          listFilters={param.listFilters}
          onChangeListPage={onChangeListPage}
          onChangeListRowsPerPage={onChangeListRowsPerPage}
          editBtn={editBtn}
          createBtn={createBtn}
          selectMap={selectMap}
          onChangeListFilters={onChangeListFilters}
          onMultiSelectList={onMultiSelectList}
          mutipleSelect={mutipleSelect}
          selectedAllCheck={selectedAllCheck}
          dialogFooterBtn={dialogFooterBtn}
          redirectPath={'/' + constants.PATH.SELECTED_TAG.SELECTED_TAG + '/'}
        />
      </Card>
    )
  )
}
