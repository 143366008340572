import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { Card } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { constants } from '../../Constants/constants';
import { setReservationTemplate, setReservationTemplateTimeSectionValue, setReservationTemplateTableTypeValue } from "../../actions/reservationTemplateAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempAutoSelectInput } from "../../Component/Input/TempAutoSelectInput";
import ReservationTemplateValidation from "../../validations/reservationTemplateValidation";
import { reservationTemplateValidationConstants } from '../../Constants/validationConstants';

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const ReservationTemplateTableType = ({ setCustomIdNumber, customIdNumber }) => {
  const dispatch = useDispatch();
  const savedReservationTemplate = useSelector(state => state.reservationTemplate.ui.reservationTemplate);
  const savedTableType = useSelector(state => state.reservationTemplate.ui.tableType);
  const helperTextStylesClasses = helperTextStyles();

  const [, setErrorVersion] = useState(ReservationTemplateValidation.getVersion());

  const resource = "TableType";

  let tempTableTypeList = [
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "tableType" },
      value: savedTableType.tableType,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.RESERVATION_TEMPLATE.TIME_SECTION_ID,
      name: "tableType",
      isEndOfLine: true,
      helperTextIcon: true,
      helperText: ReservationTemplateValidation.getErrorMessages(reservationTemplateValidationConstants.KEY_TABLE_TYPE, savedTableType.customId).map((error) => { return error }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "reservationType" },
      value: savedTableType.reservationType,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.RESERVATION_TEMPLATE.RESERVATION_TYPE,
      name: "reservationType",
      isEndOfLine: true,
      helperTextIcon: true,
      helperText: ReservationTemplateValidation.getErrorMessages(reservationTemplateValidationConstants.KEY_RESERVATION_TYPE, savedTableType.customId).map((error) => { return error }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "minSize" },
      value: savedTableType.minSize,
      name: "minSize",
      isEndOfLine: false,
      type: constants.TYPE.NUMBER,
      helperTextIcon: true,
      helperText: ReservationTemplateValidation.getErrorMessages(reservationTemplateValidationConstants.KEY_MIN_SIZE, savedTableType.customId).map((error) => { return error }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "maxSize" },
      value: savedTableType.maxSize,
      name: "maxSize",
      isEndOfLine: true,
      type: constants.TYPE.NUMBER,
      helperTextIcon: true,
      helperText: ReservationTemplateValidation.getErrorMessages(reservationTemplateValidationConstants.KEY_MAX_SIZE, savedTableType.customId).map((error) => { return error }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "autoAcceptFlag" },
      value: savedTableType.autoAcceptFlag,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.RESERVATION_TEMPLATE.AUTO_ACCEPT_FLAG,
      name: "autoAcceptFlag",
      disableClearable: true,
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: ReservationTemplateValidation.getErrorMessages(reservationTemplateValidationConstants.KEY_AUTO_ACCEPT_FLAG, savedTableType.customId).map((error) => { return error }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "autoRejectFlag" },
      value: savedTableType.autoRejectFlag,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.RESERVATION_TEMPLATE.AUTO_REJECT_FLAG,
      name: "autoRejectFlag",
      disableClearable: true,
      isEndOfLine: true,
      helperTextIcon: true,
      helperText: ReservationTemplateValidation.getErrorMessages(reservationTemplateValidationConstants.KEY_AUTO_REJECT_FLAG, savedTableType.customId).map((error) => { return error }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "limitMinute" },
      value: savedTableType.limitMinute,
      name: "limitMinute",
      type: constants.TYPE.NUMBER,
      isEndOfLine: false,
      clear: true,
      helperTextIcon: true,
      helperText: ReservationTemplateValidation.getErrorMessages(reservationTemplateValidationConstants.KEY_LIMIT_MINUTE, savedTableType.customId).map((error) => { return error }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "reminderMinute" },
      value: savedTableType.reminderMinute,
      name: "reminderMinute",
      type: constants.TYPE.NUMBER,
      clear: true,
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: ReservationTemplateValidation.getErrorMessages(reservationTemplateValidationConstants.KEY_REMINDER_MINUTE, savedTableType.customId).map((error) => { return error }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "quota" },
      value: savedTableType.quota,
      name: "quota",
      type: constants.TYPE.NUMBER,
      isEndOfLine: true,
      helperTextIcon: true,
      helperText: ReservationTemplateValidation.getErrorMessages(reservationTemplateValidationConstants.KEY_QUOTA, savedTableType.customId).map((error) => { return error }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "cutOffHour" },
      value: savedTableType.cutOffHour,
      type: constants.TYPE.NUMBER,
      name: "cutOffHour",
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: ReservationTemplateValidation.getErrorMessages(reservationTemplateValidationConstants.KEY_CUT_OFF_HOUR, savedTableType.customId).map((error) => { return error }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "cutOffMinute" },
      value: savedTableType.cutOffMinute,
      type: constants.TYPE.NUMBER,
      name: "cutOffMinute",
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: ReservationTemplateValidation.getErrorMessages(reservationTemplateValidationConstants.KEY_CUT_OFF_MINUTE, savedTableType.customId).map((error) => { return error }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "defaultStatus" },
      value: savedTableType.defaultStatus,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.RESERVATION_TEMPLATE.DEFAULT_STATUS,
      name: "defaultStatus",
      disableClearable: true,
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: ReservationTemplateValidation.getErrorMessages(reservationTemplateValidationConstants.KEY_DEFAULT_STATUS, savedTableType.customId).map((error) => { return error }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
  ]

  const content = [
    {
      tab: <>
        <Card className="card-wrapper card">
          {savedTableType.id &&
            <TempAutoSelectInput
              inputSetting={{
                className: "edit-create-input",
                size: constants.SIZE.SMALL,
                label: { resource: resource, key: "status" },
                value: savedTableType.status,
                type: constants.TYPE.SELECT,
                selectValue: constants.CHOICES.RESERVATION_TEMPLATE.TICKET_TYPE_STATUS,
                name: "status",
                disableClearable: true,
                helperTextIcon: true,
                helperText: ReservationTemplateValidation.getErrorMessages(reservationTemplateValidationConstants.KEY_TABLE_TYPE_STATUS, savedTableType.customId).map((error) => { return error }),
                FormHelperTextProps: { className: helperTextStylesClasses.root },
                isEndOfLine: false,
              }}
              handleChange={handleTableTypeInputChange}
            />
          }
          {tempTableTypeList.map((inputSetting, index) => {
            let content;
            switch (inputSetting.type) {
              case constants.TYPE.SELECT:
                content =
                  <TempAutoSelectInput inputSetting={inputSetting} handleChange={handleTableTypeInputChange} />
                break;
              default:
                content =
                  <TempTextInput inputSetting={inputSetting} handleChange={handleTableTypeInputChange} />
            }
            return <div className="edit-display-wrapper" key={index}>
              {content}
              {inputSetting.isEndOfLine && <div></div>}
            </div>
          })}
        </Card>
      </>,
      btn: false,
    }
  ]

  function handleTableTypeInputChange(name, e) {
    const tempSavedReservationTemplate = _.cloneDeep(savedReservationTemplate);
    const newTableType = _.cloneDeep(savedTableType);
    switch (name) {
      case "minSize":
      case "maxSize":
      case "cutOffHour":
      case "cutOffMinute":
      case "quota":
        newTableType[`${name}`] = Number.isInteger(Number(e)) ? Number(e) : 0;
        break;
      case "limitMinute":
      case "reminderMinute":
        newTableType[`${name}`] = e && Number.isInteger(Number(e)) ? Number(e) : null;
        break;
      default:
        newTableType[`${name}`] = e;
    }

    let tempReservationTemplateIndex = tempSavedReservationTemplate.findIndex((tempReservationTemplateValue) => tempReservationTemplateValue.customId === newTableType.timeSectionCustomId);
    let tempReservationTemplate = tempSavedReservationTemplate.find((tempReservationTemplateValue) => tempReservationTemplateValue.customId === newTableType.timeSectionCustomId);

    let tempTableTypeIndex = tempReservationTemplate.tableTypeList.findIndex((tempReservationTemplateValue) => tempReservationTemplateValue.customId === newTableType.customId);
    let tempTableType = tempReservationTemplate.tableTypeList.find((tempReservationTemplateValue) => tempReservationTemplateValue.customId === newTableType.customId);

    tempTableType = newTableType;

    if (tempTableTypeIndex >= 0) {
      tempSavedReservationTemplate[tempReservationTemplateIndex].tableTypeList[tempTableTypeIndex] = tempTableType;
      dispatch(setReservationTemplateTableTypeValue(tempTableType));
    } else {
      tempTableType.customId = "table_" + customIdNumber;
      setCustomIdNumber(customIdNumber + 1);
      tempSavedReservationTemplate[tempReservationTemplateIndex].tableTypeList.push(tempTableType);
      dispatch(setReservationTemplateTableTypeValue(tempTableType));
    }

    dispatch(setReservationTemplate(tempSavedReservationTemplate));
    const newReservationTemplateInput = _.cloneDeep(constants.RESERVATION_TEMPLATE_INPUT);
    dispatch(setReservationTemplateTimeSectionValue(newReservationTemplateInput));

    ReservationTemplateValidation.validateTemplateTableType(tempSavedReservationTemplate);
    setErrorVersion(ReservationTemplateValidation.getVersion());
  }

  return (
    <div className="sub-tab-wrapper-margin-32">
      <EditCreateDisplay
        code={savedTableType && savedTableType.customId ? savedTableType.customId : ""}
        resource={resource}
        tabs={false}
        content={content}
        saveDialog={{
          open: false,
          dialogActions: []
        }}
        display={savedTableType.customId ? "edit" : "create"}
      />
    </div>
  )
};