import { useParams } from "react-router-dom";

import { constants } from '../../Constants/constants';
import { BannerEdit } from "../Banner/BannerEdit";

export const LandingBannerEdit = () => {
  const params = useParams();

  return <BannerEdit
    newPath={constants.PATH.LANDING.LANDING_BANNER}
    dataId={params.id}
    redirectPath={constants.PATH.LANDING.LANDING_BANNER}
    bannerType="LANDING"
    tab={0} />
};