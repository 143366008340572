import _ from "lodash";
import { MERCHANT_SALES_USER } from '../actions/types';
import { constants } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        merchantSalesUser: _.cloneDeep(constants.MERCHANT_SALES_USER_INPUT)
    }
}

const merchantSalesUserReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MERCHANT_SALES_USER.SET_MERCHANT_SALES_USER_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case MERCHANT_SALES_USER.SET_MERCHANT_SALES_USER:
        case MERCHANT_SALES_USER.SET_MERCHANT_SALES_USER_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    merchantSalesUser: action.merchantSalesUser
                }
            })
        default:
            return state
    }
}

export default merchantSalesUserReducer