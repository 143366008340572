import { printConfigValidationConstants } from '../Constants/validationConstants'

class PrintConfigValidation {
  version
  errorMap
  printConfigErrorMap
  valid

  constructor() {
    if (!PrintConfigValidation.instance) {
      this.version = 0
      this.valid = true
      this.errorMap = new Map()
      this.printConfigErrorMap = new Map()
      PrintConfigValidation.instance = this
    }
    return PrintConfigValidation.instance
  }

  validateEditPrintConfig(printConfig) {
    this.resetErrorMessages()
    this.handleValidateEditPrintConfig(printConfig)
    this.version++
  }

  validateCreatePrintConfig(printConfig) {
    this.resetErrorMessages()
    this.handleValidateCreatePrintConfig(printConfig)
    this.version++
  }

  resetErrorMessages() {
    this.errorMap = new Map()
    this.printConfigErrorMap = new Map()
    this.valid = true
  }

  getVersion() {
    return this.version
  }

  isValid() {
    return this.valid
  }

  getErrorMessages(key) {
    return this.printConfigErrorMap.has(key) ? this.printConfigErrorMap.get(key) : []
  }

  handleValidateEditPrintConfig(printConfig) {
    if (!printConfig.deviceType) {
      this.setPrintConfigErrorMapMessages(printConfigValidationConstants.KEY_DEVICE_TYPE, 'Missing Device Type')
    }
    if (printConfig.shortTicketTnc?.length > 500) {
      this.setPrintConfigErrorMapMessages(printConfigValidationConstants.KEY_SHORT_TICKET_TNC, 'Short Ticket Tnc should not exceed 500 characters')
    }
  }

  handleValidateCreatePrintConfig(printConfig) {
    if (!printConfig.deviceType) {
      this.setPrintConfigErrorMapMessages(printConfigValidationConstants.KEY_DEVICE_TYPE, 'Missing Device Type')
    }
    if (printConfig.shortTicketTnc?.length > 500) {
      this.setPrintConfigErrorMapMessages(printConfigValidationConstants.KEY_SHORT_TICKET_TNC, 'Short Ticket Tnc should not exceed 500 characters')
    }
  }

  setPrintConfigErrorMapMessages(key, errorMessages, valid = false) {
    this.printConfigErrorMap.set(key, errorMessages)
    if (!valid) {
      this.valid = false
    }
  }

  deletePrintConfigErrorMapMessages(key) {
    this.printConfigErrorMap.delete(key)
    if (this.printConfigErrorMap.length === 0) {
      this.valid = true
    }
  }
}

const validation = new PrintConfigValidation()
export default validation
