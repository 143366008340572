import _ from "lodash";

export const getPayloadWithHyperLink = (payload) => {

  const result = _.cloneDeep(payload);
  for(const item of result) {
    item.templateCodeHyperLink = '/shop/queue/queue_template/' + item.templateId + '/0';
  }

  return result;

}