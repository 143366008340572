import { merchantShopValidationConstants } from '../Constants/validationConstants';

class merchantShopCreateValidation {

  version;
  errorMap;
  shopInfoErrorMap;
  valid;

  constructor() {

    if (!merchantShopCreateValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.shopInfoErrorMap = new Map();
      merchantShopCreateValidation.instance = this;
    }
    return merchantShopCreateValidation.instance;
  }

  validateDraft(draft) {
    this.resetErrorMessages();
    this.validateShopInfo(draft);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.shopInfoErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key) {
    return this.shopInfoErrorMap.get(key);
  }

  validateShopInfo( shopInfo ) {

    if (!shopInfo.shopTcName) {
      this.setErrorMapMessages(merchantShopValidationConstants.KEY_SHOP_TC_NAME, 'Missing TC Name');
    }

    if (!shopInfo.shopScName) {
      this.setErrorMapMessages(merchantShopValidationConstants.KEY_SHOP_SC_NAME, 'Missing SC Name');
    }

    if (!shopInfo.shopEnName) {
      this.setErrorMapMessages(merchantShopValidationConstants.KEY_SHOP_EN_NAME, 'Missing EN Name');
    }

    if (!shopInfo.shopTcAddress) {
      this.setErrorMapMessages(merchantShopValidationConstants.KEY_ADDRESS_TC_NAME, 'Missing TC Address');
    }

    if (!shopInfo.shopScAddress) {
      this.setErrorMapMessages(merchantShopValidationConstants.KEY_ADDRESS_SC_NAME, 'Missing SC Address');
    }

    if (!shopInfo.shopEnAddress) {
      this.setErrorMapMessages(merchantShopValidationConstants.KEY_ADDRESS_EN_NAME, 'Missing EN Address');
    }

    if (!shopInfo.shopContactNumber) {
      this.setErrorMapMessages(merchantShopValidationConstants.KEY_SHOP_CONTACT_NUMBER, 'Missing Phone Number');
    }

    if (!shopInfo.shopLogo || (shopInfo.shopLogo && !shopInfo.shopLogo.cosFileUrl)) {
      this.setErrorMapMessages(merchantShopValidationConstants.KEY_SHOP_LOGO, 'Missing Shop Logo');
    }

    if (!shopInfo.brImage || (shopInfo.brImage && !shopInfo.brImage.cosFileUrl)) {
      this.setErrorMapMessages(merchantShopValidationConstants.KEY_BR_IMAGE, 'Missing BR IMAGE');
    }

    if (!shopInfo.planCode) {
      this.setErrorMapMessages(merchantShopValidationConstants.KEY_PLAN_CODE, 'Missing PLAN CODE');
    }

    if (!shopInfo.shopFrontImageList || (shopInfo.shopFrontImageList && shopInfo.shopFrontImageList.length ===0)) {
      this.setErrorMapMessages(merchantShopValidationConstants.KEY_SHOP_FRONT_IMAGE, 'Missing Shop Front Image');
    }

    if (!shopInfo.merchantUserList || (shopInfo.merchantUserList && shopInfo.merchantUserList.length ===0)) {
      this.setErrorMapMessages(merchantShopValidationConstants.KEY_MERCHANT_USER_LIST, 'At Least One Merchant User is required');
    }

    if(!this.valid){
      this.setErrorMapMessages(merchantShopValidationConstants.KEY_SHOP_INFO, true);
    }

  }


  setErrorMapMessages(key, errorMessages, valid = false) {
    this.shopInfoErrorMap.set(key, errorMessages);
    // console.log("errorMessages", this.errorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new merchantShopCreateValidation();
export default validation;