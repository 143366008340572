export function handleFilterParameters(tempsavedPushValue) {
  if (tempsavedPushValue && tempsavedPushValue.filterParameters) {
    let tempFilterParameters = JSON.parse(tempsavedPushValue.filterParameters)
    Object.keys(tempFilterParameters).forEach((item) => {
      tempsavedPushValue[item] = tempFilterParameters[item];
    })
  }
  if (!tempsavedPushValue.gender) {
    tempsavedPushValue.gender = "all";
  }
  if (!tempsavedPushValue.os) {
    tempsavedPushValue.os = "all";
  }
  if (!tempsavedPushValue.ageGroupList) {
    tempsavedPushValue.ageGroupList = [];
  }
  return tempsavedPushValue;
}