import { useDispatch, useSelector } from "react-redux";

import { setProductValue } from "../../../actions/productCreateAction";
import { constants } from '../../../Constants/constants';
import { GetProductBrandCode } from "../../Brand/GetProductBrandCode";

export const ProductBrandCode = ({ disableInput, helperTextStylesClasses }) => {
  const dispatch = useDispatch();
  const savedProduct = useSelector(state => state.product.ui.product)

  function handleBrandCode(brandCode) {
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, "brandCode",
      {
        brandCode: brandCode.brandCode,
        brandTcName: brandCode.tcName
      }));
  }

  function handleClearBrandCode() {
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, "brandCode",
      {
        brandCode: "",
        brandTcName: "",
      }));
  }

  return (
    <>
      <GetProductBrandCode disableInput={disableInput} savedData={savedProduct.draft.product} sendBrandCode={handleBrandCode} clearBrandCode={handleClearBrandCode}/>
    </>
  )
};