import { TempCheckBox } from "../Input/TempCheckBox";

export const TempCustomCheckBox = ({ customName, onSelectCheckbox, index, value }) => {
  function onChangeInput(name, e, newIndex) {
    if (!e) {
      onSelectCheckbox(true, name, newIndex);
    } else {
      onSelectCheckbox(false, name, newIndex);
    };
  }
  return (
    <div className="customize-btn">
      <TempCheckBox
        checkBoxSetting={{
          name: customName,
          value: value,
          label: customName,
        }}
        handleChange={onChangeInput}
        index={index} />
    </div>
  )
};