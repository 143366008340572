import _ from "lodash";
import { PAYMENT } from '../actions/types';
import { constants } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        payment: _.cloneDeep(constants.PAYMENT_INPUT),
        paymentList: [],
    }
}

const paymentReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PAYMENT.SET_PAYMENT_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case PAYMENT.SET_PAYMENT:
        case PAYMENT.SET_PAYMENT_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    payment: action.payment
                }
            })
        case PAYMENT.SET_UPLOAD_PAYMENT_LIST:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    paymentList: action.paymentList
                }
            })
        default:
            return state
    }
}

export default paymentReducer