import { RESTAURANT_URL_TYPE } from "./constants"

export const STATUS_OPTIONS = [
  { value: 'A', name: { key: 'active' } },
  { value: 'D', name: { key: 'deactive' } },
]

export const RESTAURANT_STATUS_OPTIONS = [
  { value: 'A', name: { key: 'active' } },
  { value: 'P', name: { key: 'pending' } },
  { value: 'M', name: { key: 'merchant' } },
]

export const BOOLEAN_OPTIONS = [
  { value: true, name: 'true' },
  { value: false, name: 'false' },
]

export const RESTAURANT_URL_OPTIONS = [
  { value: RESTAURANT_URL_TYPE.UNIVERSAL , name: { key : 'universalLink'} },
  { value: RESTAURANT_URL_TYPE.DOWNLOAD_HTML, name: { key : 'downloadHtml'} },
]