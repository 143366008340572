import _ from "lodash";
import { SYSTEM_GROUP } from '../actions/types';
import { constants } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        systemGroup: _.cloneDeep(constants.SYSTEM_GROUP_INPUT)
    }
}

const systemGroupReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SYSTEM_GROUP.SET_SYSTEM_GROUP_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case SYSTEM_GROUP.SET_SYSTEM_GROUP:
        case SYSTEM_GROUP.SET_SYSTEM_GROUP_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    systemGroup: action.systemGroup
                }
            })
        default:
            return state
    }
}

export default systemGroupReducer