import React, { useEffect, useState } from 'react'
import { constants } from '../../Constants/constants'
import { getOne } from '../../helper/baseFetch'
import { useStore } from 'react-redux'
import { TempAutoSelectInput } from '../../Component/Input/TempAutoSelectInput'

export const SoundInfoVoiceDropdown = ({ value, resource, onChangeInput, disableClearable, soundVoiceList }) => {
  const [soundVoice, setSoundVoice] = useState(soundVoiceList || [])
  const [ready, setReady] = useState(!!soundVoiceList)
  const store = useStore()

  useEffect(() => {
    if (soundVoiceList) {
      return
    }

    getOne(
      constants.PATH.MERCHANT.SOUND_INFO_VOICE,
      undefined,
      (payload) => {
        setSoundVoice(payload.map((voiceSound) => ({ name: voiceSound.name, value: voiceSound.code })))
        setReady(true)
      },
      undefined,
      store
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    ready && (
      <TempAutoSelectInput
        inputSetting={{
          label: { resource: resource, key: 'voiceCode' },
          value: value,
          selectValue: soundVoice,
          name: 'voiceCode',
          disableClearable: disableClearable,
        }}
        handleChange={onChangeInput}
      />
    )
  )
}
