import { useSelector } from 'react-redux'
import { constants as co } from '../../Constants/constants'

export default function useProduct() {
  const savedProduct = useSelector((state) => state.product.ui.product.draft.product)
  const savedProductCategory = useSelector((state) => state.product.ui.product.draft.category)
  const lang = useSelector((state) => state.lang.ui.lang)

  const getProductCategoryName = () => {
    switch (lang) {
      case co.LANG.EN:
        return savedProductCategory?.enName || ''
      case co.LANG.SC:
        return savedProductCategory?.scName || ''
      default:
        return savedProductCategory?.tcName || ''
    }
  }

  const getProductName = () => {
    switch (lang) {
      case co.LANG.EN:
        return savedProduct?.enName || ''
      case co.LANG.SC:
        return savedProduct?.scName || ''
      default:
        return savedProduct?.tcName || ''
    }
  }

  return { getProductCategoryName, getProductName }
}
