import { RestaurantTagEdit } from "../../Component/RestaurantTag/RestaurantTagEdit";
import { constants } from "../../Constants/constants";

import "../../styles/map.css";

export const RestaurantCategoryEdit = () => {
  return (
    <RestaurantTagEdit resource={constants.PATH.RESTAURANT_TAG.RESTAURANT_CATEGORY} resourceName="restaurant_category" />
  );
};
