
import { useState, useEffect } from "react";
import { useSelector, useStore } from "react-redux";
import _ from "lodash";

import { Card } from '@mui/material';

import { ListDisplay } from '../../../Component/ListDisplay/ListDisplay';
import { generateTableCellToMultiLanguageName } from "../../../helper/util";

import { constants } from '../../../Constants/constants';
import { getList } from "../../../helper/baseFetch";

export const RestaurantPaymentTypeList = ({ editBtn=true, createBtn=true, onClickListRow, dialogFooterBtn, initialList=[] }) => {
  const store = useStore();
  const [paymentTypeList, setPaymentTypeList] = useState(initialList.length>0?initialList:[]);
  const [ready, setReady] = useState(initialList.length>0?true:false);
  const savedRestaurantPayment = useSelector(state => state.restaurantPayment.ui.restaurantPayment);
  const resource = "RestaurantPaymentTypeList";

  const restaurantPaymentTypeTitleList = [
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "paymentCode",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "cardType",
      sort: false
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "name",
      sort: false
    },
  ];
  const listFilters = [
    // {
    //   className: "filter-input",
    //   label: {resource: resource, key: "cardType"},
    //   value: '',
    //   name: "cardType",
    //   size: constants.SIZE.SMALL
    // },
  ];

  const [param, setParam] = useState({
    path: "",
    page: 0,
    rowsPerPage: 10,
    sort: 'updateTimestamp',
    direction: 'DESC',
    listFilters: listFilters,
    count: initialList.length>0?-1:0,
  })

  function onChangeParam(value) {
    value.count = value.count +1;
    setParam(value);
  }

  function onChangeListFilters(list) {
    let tempParam = _.cloneDeep(param);
    tempParam.listFilters = list;
    tempParam.page = 0;
    onChangeParam(tempParam);
  }

  function onChangeListSort(sortItem) {
    let tempParam = _.cloneDeep(param);
    tempParam.sort = sortItem;
    tempParam.direction = tempParam.direction === "DESC" ? "ASC" : "DESC";
    onChangeParam(tempParam);
  }

  function onChangeListPage(page) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = page;
    onChangeParam(tempParam);
  }

  function onChangeListRowsPerPage(rowsPerPage) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = 0;
    tempParam.rowsPerPage = rowsPerPage;
    onChangeParam(tempParam);
  }

  useEffect(() => {

    if(param.count<0){
      return;
    }

    let tempParam = _.cloneDeep(param);
    const filter = {};
    tempParam.listFilters.forEach((filterItem) => {
      if (filterItem.value !== '' && filterItem.value !== undefined) {
        filter[filterItem.name] = filterItem.value;
      }
    })

    getList(constants.PATH.SHOP.PAYMENT.PAYMENT_TYPE, { sort: { field: tempParam.sort, order: tempParam.direction }, filter: {...filter, paymentCode: savedRestaurantPayment.paymentCode}, pagination: { page: tempParam.page + 1, perPage: tempParam.rowsPerPage } }, (payload) => {
        payload.forEach((item)=> item.name = generateTableCellToMultiLanguageName(item.tcName, item.enName, item.scName))
        setPaymentTypeList(payload);
        setReady(true)
      }, undefined, store);

  }, [param.count]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <Card className="card-wrapper overflow">
      <ListDisplay
        page={param.page}
        rowsPerPage={param.rowsPerPage}
        onChangeListSort={onChangeListSort}
        resource={resource}
        dataList={paymentTypeList}
        sort={param.sort}
        direction={param.direction}
        listTitles={restaurantPaymentTypeTitleList}
        listFilters={param.listFilters}
        idName= "id"
        onChangeListPage={onChangeListPage}
        onChangeListRowsPerPage={onChangeListRowsPerPage}
        onChangeListFilters={onChangeListFilters}
        redirectPath={"/" + constants.PATH.SHOP.PAYMENT.PAYMENT_CONFIG_MAP + "/"}
        createBtn={createBtn}
        editBtn={editBtn}
        onClickListRow={onClickListRow}
        dialogFooterBtn={dialogFooterBtn}
      />
    </Card>
  )
};