import { useState, useEffect } from "react";
import { useSelector, useStore } from "react-redux";
import { useHistory, useLocation } from 'react-router-dom';
import _ from "lodash";

import { Card } from '@mui/material';

import { ListDisplay } from '../../ListDisplay/ListDisplay';
import { constants } from '../../../Constants/constants';
import { getList } from "../../../helper/baseFetch";
import { generateTableCellToMultiLanguageName, getParamQueryString, getQuery, getQueryParamObject } from "../../../helper/util";

export const ProductList = ({ editBtn, createBtn, mutipleSelect, dialogFooterBtn, previewSelectedList, setPreviewSelectedList, selectedIdList, setSelectedIdList, newPath, newFilter, newListFilters, newTitleList, onClickListRow, selectValue, changeUrlQueryString }) => {
  const store = useStore();
  const total = useSelector(state => state.system.ui.total);
  const [productList, setProductList] = useState([]);
  const [ready, setReady] = useState(false);
  const [selectedAllCheck, setSelectedAllCheck] = useState(false);
  const resource = "productCreateProductList";
  const history = useHistory()
  const location = useLocation()
  const filterParams = getQuery()


  const productTitleList = newTitleList || [
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "productCode",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "type",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "name",
      sort: false,
      minSize: true,
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "channel",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.BOOLEAN,
      value: "merchantProduct",
      sort: false
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "status",
      sort: true
    }
  ];

  const listFilters = newListFilters ? newListFilters : [
    {
      className: "filter-input",
      label: { resource: resource, key: "productCode" },
      clear: true,
      value: '',
      name: "productCode",
      size: constants.SIZE.SMALL
    },
    {
      className: "filter-input",
      label: { resource: resource, key: "name" },
      clear: true,
      value: '',
      name: "name",
      size: constants.SIZE.SMALL
    },
    {
      className: "filter-input",
      label: { resource: resource, key: "type" },
      value: selectValue?selectValue:'',
      type: constants.TYPE.SELECT,
      selectValue: (newFilter ? constants.CHOICES.PRODUCT_CREATE.EDIT_PRODUCT.TYPE.filter((item, index) => {
        let excludedTypeList = Object.keys(newFilter).includes("excludedTypeList") ?
          newFilter["excludedTypeList"].map((excludedType) => {
            return excludedType
          }) : [];
        return !excludedTypeList.includes(item.name)
      }) : constants.CHOICES.PRODUCT_CREATE.EDIT_PRODUCT.TYPE),
      name: "type",
      size: constants.SIZE.SMALL,
      disabled: selectValue?true:false,
    },
    {
      className: "filter-input",
      label: { resource: resource, key: "channel" },
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.PRODUCT_CREATE.EDIT_PRODUCT.CHANNEL,
      name: "channel",
      size: constants.SIZE.SMALL
    },
    {
      className: "filter-input",
      label: { resource: resource, key: "merchantProduct" },
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.PRODUCT_CREATE.EDIT_PRODUCT.MERCHANT_PRODUCT,
      name: "merchantProduct",
      size: constants.SIZE.SMALL
    },
    {
      className: "filter-input",
      label: { resource: resource, key: "status" },
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.PRODUCT_CREATE.EDIT_PRODUCT.PRODUCT_STATUS_FILTER,
      name: "status",
      size: constants.SIZE.SMALL
    },
    {
      className: "filter-input",
      label: { resource: resource, key: "publishStatus" },
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.PRODUCT_CREATE.EDIT_PRODUCT.PUBLISHED,
      name: "publishStatus",
      size: constants.SIZE.SMALL
    },
  ];

  const [param, setParam] = useState(!changeUrlQueryString
    ? {
    path: newPath ? newPath : "",
    page: 0,
    rowsPerPage: 10,
    sort: 'updateTimestamp',
    direction: 'DESC',
    listFilters: _.cloneDeep(listFilters),
    count: 0,
  }: getQueryParamObject(
    {
      path: newPath ? newPath : '',
      page: 0,
      rowsPerPage: 10,
      sort: 'updateTimestamp',
      direction: 'DESC',
      listFilters: _.cloneDeep(listFilters),
      count: 0,
    },
    _.cloneDeep(listFilters),
    productTitleList
  ))

  useEffect(() => {

    if(!changeUrlQueryString) {
      return;
    }

    const querySyring = getParamQueryString(
      {
        path: newPath ? newPath : '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: param.count,
      },
      param
    )

    const currentQueryString = window.location.search?.substring(1)

    if (querySyring === currentQueryString) {
      return
    }

    const newParam = getQueryParamObject(
      {
        path: newPath ? newPath : '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: param.count + 1,
      },
      _.cloneDeep(listFilters),
      productTitleList
    )

    setParam(newParam)

    const newQuery = getParamQueryString(
      {
        path: newPath ? newPath : '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: 0,
      },
      newParam
    )

    history.replace({ pathname: location.pathname, search: newQuery })
    
  }, [filterParams])

  function onChangeParam(value) {
    value.count = value.count + 1;
    
    if (!changeUrlQueryString) {
      setParam(value)
      return
    }

    const querySyring = getParamQueryString(
      {
        path: newPath ? newPath : '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: 0,
      },
      value
    )

    history.push({ pathname: location.pathname, search: querySyring })
  }

  function onChangeListFilters(list) {
    let tempParam = _.cloneDeep(param);
    tempParam.listFilters = list;
    tempParam.page = 0;
    onChangeParam(tempParam);
  }

  function onChangeListSort(sortItem) {
    let tempParam = _.cloneDeep(param);
    tempParam.sort = sortItem;
    tempParam.direction = tempParam.direction === "DESC" ? "ASC" : "DESC";
    onChangeParam(tempParam);
  }

  function onChangeListPage(page) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = page;
    onChangeParam(tempParam);
  }

  function onChangeListRowsPerPage(rowsPerPage) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = 0;
    tempParam.rowsPerPage = rowsPerPage;
    onChangeParam(tempParam);
  }

  function onMultiSelectList(productId, dataList) {
    let selectedList = selectedIdList ? _.cloneDeep(selectedIdList) : [];
    let newSelectedproductList = _.cloneDeep(previewSelectedList);
    if (productId === "all") {
      if (!selectedAllCheck) {
        productList.map((item) => {
          let selected = newSelectedproductList.map((item) => { return item.id }).includes(item.id)
          if (!selected) {
            newSelectedproductList.push(item);
          }
          return !selected && selectedList.push(item.id);
        });
      } else {
        selectedList = selectedList.filter((item) => {
          let selectedproduct = productList.map((product) => {
            newSelectedproductList = newSelectedproductList.filter((newSelectedproduct) => newSelectedproduct.id !== product.id);
            return product.id === item && product.id
          })
          return !selectedproduct.includes(item)
        });
      }
      setSelectedAllCheck(!selectedAllCheck);
    } else {
      if (selectedList.includes(productId)) {
        selectedList = selectedList.filter((item) => item !== productId);
        newSelectedproductList = newSelectedproductList.filter((item) => item.id !== productId);
      } else {
        selectedList.push(productId);
        newSelectedproductList.push.apply(newSelectedproductList, dataList.filter((item) => item.id === productId));
      }

      let selectedPerPage =
        productList.filter((product) => {
          return selectedList.includes(product.id)
        })
      if (selectedPerPage.length === (productList.length) && selectedPerPage.length !== 0) {
        setSelectedAllCheck(true);
      } else {
        setSelectedAllCheck(false);
      }
    }

    let newPreviewSelectedList = newSelectedproductList.map((product) => {
      product.sequence = 0;
      return product
    })

    setSelectedIdList(selectedList);
    setPreviewSelectedList(newPreviewSelectedList);
  }

  useEffect(() => {
    let tempParam = _.cloneDeep(param);
    const filter = newFilter ? _.cloneDeep(newFilter) : selectedIdList && selectedIdList.length > 0 ? { selected: { column: 'id', valueList: selectedIdList } } : {};
    tempParam.listFilters.forEach((filterItem) => {
      if (filterItem.value !== '' && filterItem.value !== undefined) {
        filter[filterItem.name] = filterItem.value;
      }
    })
    getList(tempParam.path ? tempParam.path : constants.PATH.PRODUCT.PRODUCT, { sort: { field: tempParam.sort, order: tempParam.direction }, filter: filter, pagination: { page: tempParam.page + 1, perPage: tempParam.rowsPerPage } }, (payload) => {
      payload.map((item) => {
        item.name = generateTableCellToMultiLanguageName(item.tcName, item.enName, item.scName);
        return item
      });
      setProductList(payload);
      let selectedPerPage =
        payload.filter((product) => {
          return selectedIdList && selectedIdList.map((selectedId) => { return selectedId }).includes(product.id)
        })
      if (selectedPerPage.length === Number(tempParam.rowsPerPage < total ? tempParam.rowsPerPage : total) && selectedPerPage.length !== 0) {
        setSelectedAllCheck(true);
      } else {
        setSelectedAllCheck(false);
      }
      setReady(true)
    }, undefined, store);

  }, [param.count]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <Card className="card-wrapper overflow">
      <ListDisplay
        onClickListRow={onClickListRow}
        page={param.page}
        rowsPerPage={param.rowsPerPage}
        onChangeListSort={onChangeListSort}
        resource={resource}
        dataList={productList}
        idName="id"
        sort={param.sort}
        direction={param.direction}
        listTitles={productTitleList}
        listFilters={param.listFilters}
        onChangeListPage={onChangeListPage}
        onChangeListRowsPerPage={onChangeListRowsPerPage}
        onChangeListFilters={onChangeListFilters}
        editBtn={editBtn}
        createBtn={createBtn ? createBtn : false}
        mutipleSelect={mutipleSelect}
        onMultiSelectList={onMultiSelectList}
        selectedAllCheck={selectedAllCheck}
        previewSelectedIdList={selectedIdList}
        dialogFooterBtn={dialogFooterBtn}
        redirectPath={"/" + constants.PATH.PRODUCT.PRODUCT + "/"}
      />
    </Card>
  )
};