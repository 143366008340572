import { useState, useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import SplitPane from 'react-split-pane';
import { Allotment } from 'allotment'
import 'allotment/dist/style.css'

import { constants } from '../../Constants/constants';
import { post } from "../../helper/baseFetch";
import { setRestaurantSlideshow } from "../../actions/restaurantSlideshowAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { RestaurantSlideshowCreateTemp } from "./RestaurantSlideshowCreateTemp";
import { RestaurantSlideshowCreateList } from "./RestaurantSlideshowCreateList";
import RestaurantSlideshowValidation from "../../validations/restaurantSlideshowValidation";
import { DialogWrapper } from "../../Component/DialogWrapper";
import useRestaurant from "../../hooks/Restaurant/useRestaurant";

export const RestaurantSlideshowCreate = ({mapCode, newRedirectPath}) => {
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();
  const params = useParams();
  const [clear, setClear] = useState(false);
  const [slideshowList, setSlideshowList] = useState([]);
  const savedRestaurantSlideshow = useSelector(state => state.restaurantSlideshow.ui.restaurantSlideshow);
  const [customIdNumber, setCustomIdNumber] = useState(0);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const [, setErrorVersion] = useState(RestaurantSlideshowValidation.getVersion());
  const resource = "RestaurantSlideshow";
  

  const content = [
    {
      tab: <>
      <Allotment defaultSizes={[100, 200]}>
          <RestaurantSlideshowCreateList resource={resource} setCustomIdNumber={setCustomIdNumber} customIdNumber={customIdNumber} mapCode={mapCode} slideshowList={slideshowList} setSlideshowList={setSlideshowList} />
          <div className="queue-template-flex-right" style={{height: '75vh', minHeight: '590px'}}>
            {savedRestaurantSlideshow.customId && slideshowList?.length>0 &&
              <RestaurantSlideshowCreateTemp create={true} customIdNumber={customIdNumber} dataReady={true} slideshowList={slideshowList} setSlideshowList={setSlideshowList}/>
            }
          </div>
        </Allotment>
      </>,
      btn: true,
    },
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />,
      disabled: !slideshowList || slideshowList.length === 0,
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  const onClickBackToCreate = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    onClickClear();
  };

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    const defaultLink = "/" + constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT + "/" + params.restUrlId + "/" + constants.RESTAURANT_TAB.MEDIA + "/" + constants.RESTAURANT_MEDIA_SUB_TAB.SLIDESHOW;
    history.push(newRedirectPath?newRedirectPath:defaultLink);

  };

  function onClickSave() {
    let tempsavedSlideshowList = _.cloneDeep(slideshowList);

    RestaurantSlideshowValidation.validateCreateRestaurantSlideshow(tempsavedSlideshowList);
    // console.log(tempsavedSlideshowList)
    if (RestaurantSlideshowValidation.isValid()) {

      post(constants.PATH.SHOP.RESTAURANT_SLIDESHOW, tempsavedSlideshowList, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToCreate,
          dialogContentText: { key: "createSuccess" },
          dialogActions: [
            { label: { key: "backCreate" }, onClick: onClickBackToCreate, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);

      RestaurantSlideshowValidation.resetErrorMessages();
    } else {
      setErrorVersion(RestaurantSlideshowValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    let tempSlideshow = _.cloneDeep(constants.BANNER_INPUT);
    setSlideshowList([]);
    dispatch(setRestaurantSlideshow(tempSlideshow));
    RestaurantSlideshowValidation.resetErrorMessages();
    setClear(false)
  }, [clear === true]); // eslint-disable-line react-hooks/exhaustive-deps

  const { getRestaurantIdAndName } = useRestaurant(params.restUrlId)
  const displayMapCode = getRestaurantIdAndName()

  return (
    <>
      <EditCreateDisplay
        code={""}
        resource={resource}
        tabs={false}
        content={content}
        bottomBtns={bottomBtns}
        mapName={{ resource: "RestaurantSlideshow", key: "restUrlId" }}
        mapCode={displayMapCode}
        display="create"
      />
      {dialog && dialog.open &&
        <DialogWrapper dialogInfo={dialog} />
      }
    </>
  )
};