import { useState } from "react";
import { useParams } from "react-router-dom";
import { useStore } from "react-redux";

import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ClearIcon from '@mui/icons-material/Clear';
import { Drawer } from "@mui/material";

import { constants } from '../../Constants/constants';
import { TempBtn } from "../../Component/Input/TempBtn";
import { RestaurantList } from "../../Page/Restaurant/RestaurantList";
import { put, post } from "../../helper/baseFetch";
import { DialogWrapper } from "../../Component/DialogWrapper";

const resource = "productCreateSystemGroupList";

const newListTitles = [
  {
    key: constants.LIST_DISPLAY_TYPE.STRING,
    value: "restUrlId",
    sort: true
  }, {
    key: constants.LIST_DISPLAY_TYPE.STRING,
    value: "restRefId",
    sort: true
  },{
    key: constants.LIST_DISPLAY_TYPE.STRING,
    value: "name",
    sort: false
  }, {
    key: constants.LIST_DISPLAY_TYPE.STRING,
    value: "address",
    sort: false
  }
]

const newListFilters = [
  {
    className: "filter-input",
    label: { resource: resource, key: "restUrlId" },
    clear: true,
    value: '',
    name: "restUrlId",
    size: constants.SIZE.SMALL
  },
  {
    className: "filter-input",
    label: { resource: resource, key: "restRefId" },
    clear: true,
    value: '',
    name: "restRefId",
    size: constants.SIZE.SMALL
  },
  {
    className: "filter-input",
    label: { resource: resource, key: "name" },
    clear: true,
    value: '',
    name: "name",
    size: constants.SIZE.SMALL
  },
  {
    className: "filter-input",
    label: { resource: resource, key: "address" },
    clear: true,
    value: '',
    name: "address",
    size: constants.SIZE.SMALL
  },
]

export const RestaurantMapTabContent = () =>{

  const params = useParams();
  const store = useStore();
  const [addRestaurantMap, setAddRestaurantMap] = useState(new Map());
  const [showPanel, setShowPanel] = useState(false);
  const [removeRestaurantMap, setRemoveRestaurantMap] = useState(new Map());
  const [getDataDependency, setGetDataDependency] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    setGetDataDependency(prev=>!prev);
  };

  const handleRemoveRestId = () =>{

    if(removeRestaurantMap.size<=0){
      return;
    }
  
    const removeList = Array.from(Array.from(removeRestaurantMap, ([name, value]) => (value.id)));

    put(constants.PATH.BRAND.RESTAURANT_MAP_REMOVE , removeList, (payload) => {
      setDialog({
        open: true,
        onClose: onClickBackToEdit,
        dialogContentText: { key: "editSuccess" },
        dialogActions: [
          { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
        ]
      })
    }, undefined, store);
  
    setRemoveRestaurantMap(new Map());
  }

  const handleRestUrlId = () =>{
    setShowPanel(false);
    const selectedIdList = Array.from(addRestaurantMap, ([name, value]) => (value.id));

    post(constants.PATH.BRAND.RESTAURANT_MAP , {brandId: params.id, restUrlIdList: selectedIdList}, (payload) => {
      setDialog({
        open: true,
        onClose: onClickBackToEdit,
        dialogContentText: { key: "addSuccess" },
        dialogActions: [
          { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
        ]
      })
    }, undefined, store);

    setAddRestaurantMap(new Map());
  }

  const handleCloseClick = () =>{
    setShowPanel(false);
    setAddRestaurantMap(new Map());
  }

  const handleAddRestaurant = () =>{
    setShowPanel(true);
  }
  

  return (
    <div>
      <div className="title title-end">
        <div className="create-btn" >
          <TempBtn btnSetting={{
            onClick: handleAddRestaurant,
            color: constants.STYLE.PRIMARY,
            label: { key: "create" },
            icon: <AddIcon />,
          }}>
          </TempBtn>
        </div>
      </div>
    <RestaurantList
        getDataDependency={getDataDependency}
        editBtn={false}
        createBtn={false}
        btnList={false}
        mutipleSelect={true}
        selectMap={removeRestaurantMap}
        setSelectMap={setRemoveRestaurantMap}
        newPath={constants.PATH.BRAND.RESTAURANT_MAP}
        newListTitles={newListTitles}
        newListFilters={newListFilters}
        newFilter={ {brandId: params.id} }
        // savedData={savedRestaurantTagMap}
        dialogFooterBtn={
          removeRestaurantMap?.size>0?
          <div className="card-sub-button-wrapper">
            <TempBtn
              btnSetting=
              {{
                className: "card-sub-button-wrapper-btn",
                variant: constants.STYLE.VARIANT.CONTAINED,
                color: constants.STYLE.PRIMARY,
                onClick: handleRemoveRestId,
                icon: <DeleteIcon />,
                label: { key: "remove" },
              }}
            >
            </TempBtn>
          </div>
          :<></>}
      />
        <Drawer
          classes={{ paper: "drawer-margin-left" }}
          anchor="right"
          open={showPanel}
          onClose={handleCloseClick}
        >
          <RestaurantList
            editBtn={false}
            createBtn={false}
            btnList={false}
            mutipleSelect={true}
            selectMap={addRestaurantMap}
            setSelectMap={setAddRestaurantMap}
            newPath={constants.PATH.BRAND.BRAND_RESTAURANT_MAP}
            newListTitles={newListTitles}
            newListFilters={newListFilters}
            newFilter={ addRestaurantMap && addRestaurantMap.size>0?{
              brandId:params.id, 
              selected: { column: 'restUrlId', valueList: Array.from(addRestaurantMap, ([name, value]) => (name)) }
            }: {brandId:params.id} }
            dialogFooterBtn={
              <div className="get-push-card-wrapper">
                <TempBtn
                  btnSetting=
                  {{
                    className: "get-push-card-cancel-btn margin-right-16",
                    variant: constants.STYLE.VARIANT.CONTAINED,
                    color: constants.STYLE.PRIMARY,
                    onClick: handleRestUrlId,
                    icon: <AddIcon />,
                    label: { key: "add" },
                  }}
                >
                </TempBtn>
                <TempBtn
                  btnSetting=
                  {{
                    className: "get-push-card-cancel-btn",
                    variant: constants.STYLE.VARIANT.CONTAINED,
                    color: constants.STYLE.PRIMARY,
                    onClick: handleCloseClick,
                    icon: <ClearIcon />,
                    label: { key: "close" },
                  }}
                >
                </TempBtn>
              </div>}
          />,
        </Drawer>
        {dialog && dialog.open &&
          <DialogWrapper dialogInfo={dialog} />
        }
        </div>
  )
}