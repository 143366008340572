import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { constants } from '../../Constants/constants';
import { generateTableCellToMultiLanguageName } from '../../helper/util';

const useStyles = makeStyles(theme => ({
    gridList: {
        margin: 0,
        width: "100%",
    },
    gridListTile: {
        width: "300px !important",
        height: "300px !important",
    },
    tileBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)',
        height: "fit-content",
        padding: "4px 0",
    },
    link: {
        color: '#fff',
    },
    image: {
        maxWidth: "100%",
        height: "auto",
    },
}));

function LoadedGridList(dataList) {
    const classes = useStyles();

    if (!dataList) return null;

    return (
        <ImageList
            className={classes.gridList}
            cols={5}
        >
            {dataList.map((data) => (
                <ImageListItem
                    component={Link}
                    key={data.restUrlId}
                    to={'/' + constants.PATH.APPROVAL.RESTAURANT + "/" + data.restUrlId + "/0"}
                    classes={{
                        root: classes.gridListTile,
                    }}
                >
                    {data.logoImage &&
                        <img src={data.logoImage} alt="" className={classes.image} />
                    }
                    {data.logoImage &&
                        <div className={classes.image} />
                    }
                    <ImageListItemBar
                        className={classes.tileBar}
                        title={generateTableCellToMultiLanguageName(data.tcName, data.enName, data.scName)}
                    />
                </ImageListItem>
            ))}
        </ImageList>
    );
};

export const GridList = (dataList) => {
    return LoadedGridList(dataList.dataList);
};
