import { PUSH } from './types';
import _ from "lodash";

export const setPushDefault = () => {
    return {
        type: PUSH.SET_PUSH_DEFAULT,
    }
}

export const setPush = (push) => {
    return {
        type: PUSH.SET_PUSH,
        push: push
    }
}

export const setPushValue = (draftType, orignalProduct, key, value, index) => {
    const push = _.cloneDeep(orignalProduct);
    switch (draftType) {
        default:
            push[draftType] = value;
    }
    return {
        type: PUSH.SET_PUSH_VALUE,
        push: push
    }
}