import { useState, useEffect } from 'react'
import { useSelector, useStore } from 'react-redux'
import _ from 'lodash'

import { Card } from '@mui/material'

import { ListDisplay } from '../../ListDisplay/ListDisplay'

import { constants, BANNER_ACTION } from '../../../Constants/constants'
import { getList } from '../../../helper/baseFetch'
import { generateTableCellToMultiLanguageName } from '../../../helper/util'

export const ProductMapList = ({ productCode, savedData, onClickListRow, drawerIndex, disableInput, dialogFooterBtn }) => {
  const store = useStore()
  const [ProductMapList, setProductMapList] = useState([])
  const [ready, setReady] = useState(false)
  const resource = 'ProductMapList'
  const savedProduct = useSelector((state) => state.product.ui.product)

  const ProductMapTitleList = [
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: 'productCode',
      sort: true,
    },
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: 'productType',
      sort: true,
    },
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: 'productName',
      sort: false,
      minSize: true,
    },
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: 'productChannel',
      sort: true,
    },
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: 'displaySequence',
      sort: true,
    },
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: 'productStatus',
      sort: true,
    },
  ]
  const listFilters = [
    {
      className: 'filter-input',
      label: { resource: resource, key: 'productCode' },
      clear: true,
      value: '',
      name: 'productCode',
      size: constants.SIZE.SMALL,
    },
    {
      className: 'filter-input',
      label: { resource: resource, key: 'productType' },
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.PRODUCT_CREATE.EDIT_PRODUCT.TYPE,
      name: 'productType',
      size: constants.SIZE.SMALL,
    },
    {
      className: 'filter-input',
      label: { resource: resource, key: 'productName' },
      clear: true,
      value: '',
      name: 'productName',
      size: constants.SIZE.SMALL,
    },
    {
      className: 'filter-input',
      label: { resource: resource, key: 'productChannel' },
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.PRODUCT_CREATE.CREATE_PRODUCT.CHANNEL,
      name: 'productChannel',
      size: constants.SIZE.SMALL,
    },
    {
      className: 'filter-input',
      label: { resource: resource, key: 'productStatus' },
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.PRODUCT_CREATE.CREATE_CATEGORY.STATUS,
      name: 'productStatus',
      size: constants.SIZE.SMALL,
    },
  ]

  const [param, setParam] = useState({
    path: '',
    page: 0,
    rowsPerPage: 10,
    sort: 'updateTimestamp',
    direction: 'DESC',
    listFilters: listFilters,
    count: 0,
  })

  function onChangeParam(value) {
    value.count = value.count + 1
    setParam(value)
  }

  function onChangeListFilters(list) {
    let tempParam = _.cloneDeep(param)
    tempParam.listFilters = list
    tempParam.page = 0
    onChangeParam(tempParam)
  }

  function onChangeListSort(sortItem) {
    let tempParam = _.cloneDeep(param)
    tempParam.sort = sortItem
    tempParam.direction = tempParam.direction === 'DESC' ? 'ASC' : 'DESC'
    onChangeParam(tempParam)
  }

  function onChangeListPage(page) {
    let tempParam = _.cloneDeep(param)
    tempParam.page = page
    onChangeParam(tempParam)
  }

  function onChangeListRowsPerPage(rowsPerPage) {
    let tempParam = _.cloneDeep(param)
    tempParam.page = 0
    tempParam.rowsPerPage = rowsPerPage
    onChangeParam(tempParam)
  }

  useEffect(() => {
    let tempParam = _.cloneDeep(param)
    const filter = productCode
      ? { productCode: productCode }
      : savedData
      ? { categoryCode: savedData }
      : { categoryId: savedProduct.draft.category.id }
    tempParam.listFilters.forEach((filterItem) => {
      if (filterItem.value !== '' && filterItem.value !== undefined) {
        filter[filterItem.name] = filterItem.value
      }
    })

    getList(
      constants.PATH.PRODUCT.CATEGORY_PRODUCT_MAP,
      {
        sort: { field: tempParam.sort, order: tempParam.direction },
        filter: filter,
        pagination: { page: tempParam.page + 1, perPage: tempParam.rowsPerPage },
      },
      (payload) => {
        payload.map((item) => {
          item.productName = generateTableCellToMultiLanguageName(item.productTcName, item.productEnName, item.productScName)
          return item
        })
        setProductMapList(payload)
        setReady(true)
      },
      undefined,
      store
    )
  }, [param.count]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready && (
      <Card className="card-wrapper overflow">
        <ListDisplay
          onClickListRow={onClickListRow && ((item) => onClickListRow(item, drawerIndex, BANNER_ACTION.CATEGORY_PRODUCT_MAP))}
          page={param.page}
          rowsPerPage={param.rowsPerPage}
          onChangeListSort={onChangeListSort}
          resource={resource}
          dataList={ProductMapList}
          sort={param.sort}
          direction={param.direction}
          listTitles={ProductMapTitleList}
          listFilters={param.listFilters}
          editBtn={onClickListRow ? false : !disableInput}
          createBtn={onClickListRow ? false : !disableInput}
          onChangeListPage={onChangeListPage}
          onChangeListRowsPerPage={onChangeListRowsPerPage}
          onChangeListFilters={onChangeListFilters}
          redirectPath={'/' + constants.PATH.PRODUCT.CATEGORY_PRODUCT_MAP + '/'}
          dialogFooterBtn={dialogFooterBtn}
        />
      </Card>
    )
  )
}
