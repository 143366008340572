import { KeyboardArrowDown } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { useState } from 'react'

export default function MuiAccordion({ children, title }) {
  const [expanded, setExpanded] = useState(true)

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary
        expandIcon={<KeyboardArrowDown />}
        sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 700, height: '30px' }}
        onClick={() => setExpanded(!expanded)}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
}
