import { getTableTypeBackgroundColor, getTableTypeForegroundColor } from '../util'

export const mergePayloadSubmitTimeSectionList = (savedData) => {
  // merge timeSectionList and refTemplateTimeSectionList from getOne

  const profileList = savedData.timeSectionList || []
  const refTemplateList = savedData.refTemplateTimeSectionList || []

  const list = []

  if (!refTemplateList || refTemplateList.length === 0) {
    for (const obj of profileList) {
      list.push({ ...obj, selected: true, warning: false })
    }
  } else {
    for (const obj of refTemplateList) {
      profileList.forEach((profileObj) => {
        if (profileObj.timeSectionId === obj.timeSectionId && profileObj.tableType === obj.tableType) {
          list.push({ ...obj, id: profileObj.id, selected: true, warning: false })
        }
      })

      const existInProfileList = profileList.some(
        (profileObj) => profileObj.timeSectionId === obj.timeSectionId && profileObj.tableType === obj.tableType
      )

      if (!existInProfileList) {
        list.push({ ...obj, selected: false, warning: false })
      }
    }

    for (const obj of list) {
      const existInRefList = refTemplateList.some((refObj) => refObj.timeSectionId === obj.timeSectionId && refObj.tableType === obj.tableType)
      const existInProfileList = profileList.some(
        (profileObj) => profileObj.timeSectionId === obj.timeSectionId && profileObj.tableType === obj.tableType
      )
      if (!existInRefList && existInProfileList) {
        obj.warning = true
      }
    }
  }

  return list
}

export const mergeSubmitTimeSectionList = (submitList, restUrlId) => {
  let idList = submitList.filter((item) => item.id) || []
  idList = idList.map((item) => {
    item.status = item.selected ? 'A' : 'D'
    return item
  })

  let noIdList = submitList.filter((item) => !item.id && item.selected) || []

  const fullList = [...idList, ...noIdList]

  const list = fullList.map(({ timeSectionId, tableType, status, id }) => {
    const item = {
      restUrlId: restUrlId,
      timeSectionId,
      tableType,
    }

    if (id) {
      item.id = id
      item.status = status
    }

    return item
  })

  return list
}

export const mergeMapList = (savedData) => {
  // merge timeSectionList and refTemplateTimeSectionList from getOne

  const profileList = savedData.timeSectionList || []
  const refTemplateList = savedData.refTemplateTimeSectionList || []

  const list = []

  if (!refTemplateList || refTemplateList.length === 0) {
    for (const obj of profileList) {
      list.push({ ...obj, selected: true, warning: false })
    }
  } else {
    for (const obj of refTemplateList) {
      profileList.forEach((profileObj) => {
        if (profileObj.timeSectionId === obj.timeSectionId && profileObj.tableType === obj.tableType) {
          list.push({ ...obj, id: profileObj.id, selected: true, warning: false })
        }
      })

      const existInProfileList = profileList.some(
        (profileObj) => profileObj.timeSectionId === obj.timeSectionId && profileObj.tableType === obj.tableType
      )

      if (!existInProfileList) {
        list.push({ ...obj, selected: false, warning: false })
      }
    }

    for (const obj of list) {
      const existInRefList = refTemplateList.some((refObj) => refObj.timeSectionId === obj.timeSectionId && refObj.tableType === obj.tableType)
      const existInProfileList = profileList.some(
        (profileObj) => profileObj.timeSectionId === obj.timeSectionId && profileObj.tableType === obj.tableType
      )
      if (!existInRefList && existInProfileList) {
        obj.warning = true
      }
    }
  }

  const listWithTicketTypeSection = getTicketTypeSection(list)

  const timeSectionGroupedlist = Object.values(
    listWithTicketTypeSection.reduce((result, item) => {
      if (!result[item.timeSectionId]) {
        result[item.timeSectionId] = []
      }

      item.tableTypeList = item.tableTypeList?.map((tableType, idx) => ({
        ...tableType,
        displayTableTypeBackgroundColor: getTableTypeBackgroundColor(
          tableType.tableTypeBackgroundColor,
          getTableTypeIndexInTimeSection(result[item.timeSectionId], idx),
          savedData?.tableTypeColorConfigList || savedData?.defaultTableTypeColorConfigList
        ),
        displayTableTypeForegroundColor: getTableTypeForegroundColor(
          tableType.tableTypeForegroundColor,
          getTableTypeIndexInTimeSection(result[item.timeSectionId], idx),
          savedData?.tableTypeColorConfigList || savedData?.defaultTableTypeColorConfigList
        ),
      }))

      result[item.timeSectionId].push(item)

      return result
    }, {})
  )

  return timeSectionGroupedlist
}

const getTicketTypeSection = (list) => {
  if (!list || !list.length) {
    return []
  }

  const displayList = []
  let tempSection = {}
  let prev = undefined

  for (const tableType of list) {

    const key = tableType.timeSectionId + '-' + tableType.ticketType

    if (prev === undefined) {
      tempSection.ticketType = tableType.ticketType
      tempSection.ticketTypeEnLabel = tableType.ticketTypeEnLabel
      tempSection.ticketTypeScLabel = tableType.ticketTypeScLabel
      tempSection.ticketTypeTcLabel = tableType.ticketTypeTcLabel
      tempSection.ticketTypeBackgroundColor = tableType.ticketTypeBackgroundColor
      tempSection.ticketTypeTextColor = tableType.ticketTypeForegroundColor
      tempSection.timeSectionId = tableType.timeSectionId
      tempSection.timeSectionEnLabel = tableType.timeSectionEnLabel
      tempSection.timeSectionScLabel = tableType.timeSectionScLabel
      tempSection.timeSectionTcLabel = tableType.timeSectionTcLabel
      tempSection.timeSectionDescription = tableType.timeSectionDescription
      tempSection.tableTypeList = [tableType]
      prev = key

      continue
    }

    if (prev !== undefined && key !== prev) {
      displayList.push({ ...tempSection })

      prev = key
      tempSection = {}
      tempSection.ticketType = tableType.ticketType
      tempSection.ticketTypeEnLabel = tableType.ticketTypeEnLabel
      tempSection.ticketTypeScLabel = tableType.ticketTypeScLabel
      tempSection.ticketTypeTcLabel = tableType.ticketTypeTcLabel
      tempSection.ticketTypeBackgroundColor = tableType.ticketTypeBackgroundColor
      tempSection.ticketTypeTextColor = tableType.ticketTypeForegroundColor
      tempSection.timeSectionId = tableType.timeSectionId
      tempSection.timeSectionEnLabel = tableType.timeSectionEnLabel
      tempSection.timeSectionScLabel = tableType.timeSectionScLabel
      tempSection.timeSectionTcLabel = tableType.timeSectionTcLabel
      tempSection.timeSectionDescription = tableType.timeSectionDescription
      tempSection.tableTypeList = [tableType]

      continue
    }

    tempSection.tableTypeList = [...tempSection.tableTypeList, tableType]
  }
  displayList.push({ ...tempSection })
  return displayList
}

const getTableTypeIndexInTimeSection = (ticketTypeGroupBeforeCurrentTableType, currentIndex) => {

  const tableTypeLengthInPrevTicketTypeGroup = ticketTypeGroupBeforeCurrentTableType?.map((ticketType) => ticketType.tableTypeList)?.flat()?.length

  if (!tableTypeLengthInPrevTicketTypeGroup) {
    return currentIndex
  }

  return tableTypeLengthInPrevTicketTypeGroup + currentIndex
}
