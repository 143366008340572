import { areaValidationConstants } from '../Constants/validationConstants';

class AreaValidation {

  version;
  errorMap;
  areaErrorMap;
  valid;

  constructor() {

    if (!AreaValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.areaErrorMap = new Map();
      AreaValidation.instance = this;
    }
    return AreaValidation.instance;
  }

  validateEditArea(area) {
    this.resetErrorMessages();
    this.handleValidateEditArea(area);
    this.version++;
  }

  validateCreateArea(area) {
    this.resetErrorMessages();
    this.handleValidateCreateArea(area);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.areaErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key, uniqueCode) {
    if (!uniqueCode) {
      return (this.areaErrorMap.has(key)) ? this.areaErrorMap.get(key) : [];
    }
  }

  handleValidateEditArea(area) {
    if (!area.enName) {
      this.setAreaErrorMapMessages(areaValidationConstants.KEY_EN_NAME, "Missing EN Name");
    }
    if (!area.tcName) {
      this.setAreaErrorMapMessages(areaValidationConstants.KEY_TC_NAME, "Missing TC Name");
    }
    if (!area.scName) {
      this.setAreaErrorMapMessages(areaValidationConstants.KEY_SC_NAME, "Missing SC Name");
    }
    if (!area.landmarkOrder && area.landmarkOrder !== 0) {
      this.setAreaErrorMapMessages(areaValidationConstants.KEY_LANDMARK_ORDER, "Missing Landmark Order");
    } else if (area.landmarkOrder < 0 || !Number.isInteger(Number(area.landmarkOrder))) {
      this.setAreaErrorMapMessages(areaValidationConstants.KEY_LANDMARK_ORDER, "Invalid Landmark Order");
    }
    if (!area.status) {
      this.setAreaErrorMapMessages(areaValidationConstants.KEY_STATUS, "Missing Status");
    }
  }

  handleValidateCreateArea(area) {
    if (!area.enName) {
      this.setAreaErrorMapMessages(areaValidationConstants.KEY_EN_NAME, "Missing EN Name");
    }
    if (!area.tcName) {
      this.setAreaErrorMapMessages(areaValidationConstants.KEY_TC_NAME, "Missing TC Name");
    }
    if (!area.scName) {
      this.setAreaErrorMapMessages(areaValidationConstants.KEY_SC_NAME, "Missing SC Name");
    }
    if (!area.landmarkOrder && area.landmarkOrder !== 0) {
      this.setAreaErrorMapMessages(areaValidationConstants.KEY_LANDMARK_ORDER, "Missing Landmark Order");
    } else if (area.landmarkOrder < 0 || !Number.isInteger(Number(area.landmarkOrder))) {
      this.setAreaErrorMapMessages(areaValidationConstants.KEY_LANDMARK_ORDER, "Invalid Landmark Order");
    }
  }


  setAreaErrorMapMessages(key, errorMessages, valid = false) {
    this.areaErrorMap.set(key, errorMessages);
    // console.log("areaErrorMap", this.areaErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new AreaValidation();
export default validation;