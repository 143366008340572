import { MERCHANT_SALES_USER } from './types';
import _ from "lodash";

export const setMerchantSalesUserDefault = () => {
    return {
        type: MERCHANT_SALES_USER.SET_MERCHANT_SALES_USER_DEFAULT,
    }
}

export const setMerchantSalesUser = (merchantSalesUser) => {
    return {
        type: MERCHANT_SALES_USER.SET_MERCHANT_SALES_USER,
        merchantSalesUser: merchantSalesUser
    }
}

export const setMerchantSalesUserValue = (draftType, orignalProduct, key, value, index) => {
    const merchantSalesUser = _.cloneDeep(orignalProduct);
    merchantSalesUser[draftType] = value;
    return {
        type: MERCHANT_SALES_USER.SET_MERCHANT_SALES_USER_VALUE,
        merchantSalesUser: merchantSalesUser
    }
}
