import { TICKET_TYPE } from './types';
import { sify } from "chinese-conv";
import _ from "lodash";

export const setTicketTypeDefault = () => {
    return {
        type: TICKET_TYPE.SET_TICKET_TYPE_DEFAULT,
    }
}

export const setTicketType = (ticketType) => {
    return {
        type: TICKET_TYPE.SET_TICKET_TYPE,
        ticketType: ticketType
    }
}

export const setTicketTypeValue = (draftType, orignalProduct, key, value, index) => {
    const ticketType = _.cloneDeep(orignalProduct);
    switch (draftType) {
        case "CHANGE_TC_NAME_AND_SC_NAME":
            ticketType.tcName = value;
            ticketType.scName = sify(value);
            break;
        case "scNameDisable":
            ticketType[draftType] = value;
            if(value) {
                ticketType.scName = ticketType.tcName ? sify(ticketType.tcName) : ticketType.tcName;
            }
            break;
        default:
            ticketType[draftType] = value;
    }
    return {
        type: TICKET_TYPE.SET_TICKET_TYPE_VALUE,
        ticketType: ticketType
    }
}
