
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Drawer } from "@mui/material";
import Paper from '@mui/material/Paper';
import ClearIcon from '@mui/icons-material/Clear';

import { setMerchantShopValue } from "../../../actions/merchantShopCreateAction";
import { SubTabEditCreateDisplay } from "../../EditCreateDisplay/SubTabEditCreateDisplay";
import { constants } from "../../../Constants/constants";
import { TempBtn } from "../../Input/TempBtn";
import { UserGroupList } from "../../ProductCreate/UserGroup/UserGroupList";
import { getContent } from "../../EditCreateDisplay/EditUtil";

const LOCAL_CONSTANT = {
  USER_GROUP_CODE: "userGroupCode",
  MERCHANT_USER_LIST: "merchantUserList",
}

export const GroupCode = () =>{
  const resource = "MerchantDraft";
  const dispatch = useDispatch();
  const savedMerchantShop = useSelector(state => state.merchantShop.ui.merchantShop);
  const [drawerType, setDrawerType] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  function onClickCloseDrawer() {
    setDrawerOpen(false);
  }

  const handleOnClickDrawer=(type)=>{
    setDrawerType(type);
    setDrawerOpen(true);
    handleDrawer();
  }

  const onClickListRow=(data, type)=> {
    onChangeInput(type, data)    
    onClickCloseDrawer();
  }

  const onChangeInput=(name, e)=>{
    dispatch(setMerchantShopValue("DRAFT", savedMerchantShop, name, e));
  }

  const handleDrawer=()=> {
    let drawer;
    switch (drawerType) {
      case LOCAL_CONSTANT.USER_GROUP_CODE:
        drawer =
          <Paper>
            <UserGroupList
              editBtn={false}
              createBtn={false}
              onClickListRow={(item) => onClickListRow(item.groupCode, drawerType)}
              dialogFooterBtn={<>
                <TempBtn btnSetting={{
                  variant: constants.STYLE.VARIANT.CONTAINED,
                  color: constants.STYLE.PRIMARY,
                  onClick: onClickCloseDrawer,
                  icon: <ClearIcon />,
                  label: { key: "close" },
                }} />
              </>
              }
            />
          </Paper>
        break;
        default:
          break;
  }
  return drawer;
}

  const tempEditList = [
    {
      className: `edit-create-input`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "userGroupCode" },
      value: savedMerchantShop?.draft?.userGroupCode,
      name: "userGroupCode",
      disabled: true,
      isEndOfLine: true,
      required: false,
      onClickEndAdornmentIconButton: ()=>handleOnClickDrawer(LOCAL_CONSTANT.USER_GROUP_CODE),
      clear: true,
    }
  ]

  const content = [
    {
      tab: tempEditList.map((inputSetting, index) => {
        let content = getContent(inputSetting, index, onChangeInput)
        return <div className="edit-display-wrapper" key={index}>
          {content}
          {inputSetting.isEndOfLine && <div></div>}
        </div>
      }),
      btn: false
    },
  ]

  return (
    <>
      <SubTabEditCreateDisplay
        code={""}
        resource={resource}
        tabs={false}
        content={content}
        onChangeInput={onChangeInput}
        display={"none"}
      />
      <Drawer
        classes={{ paper: "drawer-margin-left" }}
        anchor="right"
        open={drawerOpen}
        onClose={onClickCloseDrawer}
      >
        {handleDrawer()}
      </Drawer>
    </>  
  )
}