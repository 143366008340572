
import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector, useStore } from "react-redux";
import _ from "lodash";

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import { makeStyles } from '@mui/styles';

import { constants } from '../../Constants/constants';
import { put, getOne } from "../../helper/baseFetch";
import { getScDisabled } from "../../helper/util";
import { setCampaignDescription, setCampaignDescriptionValue } from "../../actions/campaignDescriptionAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempCustomCheckBox } from "../../Component/Custom/TempCustomCheckBox";
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempAutoSelectInput } from "../../Component/Input/TempAutoSelectInput";
import { DialogWrapper } from "../../Component/DialogWrapper";
import CampaignDescriptionValidation from "../../validations/campaignDescriptionValidation";
import { campaignDescriptionValidationConstants } from '../../Constants/validationConstants';
import useRestaurant from "../../hooks/Restaurant/useRestaurant";

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const DisplayMessageEdit = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const store = useStore();
  const history = useHistory();
  const [ready, setReady] = useState(false);
  const [clear, setClear] = useState(true);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const savedRestaurant = useSelector(state => state.restaurant.ui.restaurant);
  const savedCampaignDescription = useSelector(state => state.campaignDescription.ui.campaignDescription);
  const [campaignDescriptionInputList, setCampaignDescriptionInputList] = useState(_.cloneDeep(savedCampaignDescription));
  const resource = "CampaignDescription";
  const helperTextStylesClasses = helperTextStyles();
  const [, setErrorVersion] = useState(CampaignDescriptionValidation.getVersion());

  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.CAMPAIGN_DESCRIPTION_EDIT_TAB.INFO
    },
  ]
  const tempCampaignDescriptionEditList = [
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "type" },
      value: savedCampaignDescription.type,
      // type: constants.TYPE.SELECT,
      // selectValue: constants.CHOICES.CAMPAIGN_DESCRIPTION.TYPE,
      name: "type",
      isEndOfLine: false,
      disabled: true,
      required: true,
      helperTextIcon: true,
      helperText: CampaignDescriptionValidation.getErrorMessages(campaignDescriptionValidationConstants.KEY_TYPE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "messageCode" },
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.CAMPAIGN_DESCRIPTION.MESSAGE_CODE,
      freeSolo: true,
      value: savedCampaignDescription.messageCode,
      name: "messageCode",
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "sequence" },
      value: savedCampaignDescription.sequence,
      name: "sequence",
      isEndOfLine: false,
      min: 0,
      //eslint-disable-next-line
      pattern: "^\d*$",
      helperTextIcon: true,
      helperText: CampaignDescriptionValidation.getErrorMessages(campaignDescriptionValidationConstants.KEY_SEQUENCE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "status" },
      value: savedCampaignDescription.status,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.CAMPAIGN_DESCRIPTION.STATUS,
      name: "status",
      isEndOfLine: true,
      disableClearable: true,
      required: true,
      helperTextIcon: true,
      helperText: CampaignDescriptionValidation.getErrorMessages(campaignDescriptionValidationConstants.KEY_STATUS),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "enTitle" },
      value: savedCampaignDescription.enTitle,
      name: "enTitle",
      custom: true,
      customName: { resource: resource, key: "titleCustomize" },
      customValue: savedCampaignDescription.scTitleDisable,
      isEndOfLine: false,
      multiline: true,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "tcTitle" },
      value: savedCampaignDescription.tcTitle,
      name: "tcTitle",
      translateName: savedCampaignDescription.scTitleDisable ? ["scTitle"] : null,
      inputList: campaignDescriptionInputList,
      setInputList: setCampaignDescriptionInputList,
      isEndOfLine: false,
      multiline: true,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "scTitle" },
      value: savedCampaignDescription.scTitle,
      name: "scTitle",
      inputList: campaignDescriptionInputList,
      setInputList: setCampaignDescriptionInputList,
      isEndOfLine: true,
      disabled: savedCampaignDescription.scTitleDisable,
      multiline: true,
    },
    {
      className: `edit-create-input ${savedCampaignDescription.type === constants.CAMPAIGN_DESCRIPTION_TYPE.WEB_CAMPAIGN_TITLE ? "display-none" : ""}`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "enMessage" },
      value: savedCampaignDescription.enMessage,
      name: "enMessage",
      custom: savedCampaignDescription.type === constants.CAMPAIGN_DESCRIPTION_TYPE.WEB_CAMPAIGN_TITLE ? false : true,
      customName: { resource: resource, key: "messageCustomize" },
      customValue: savedCampaignDescription.scMessageDisable,
      isEndOfLine: false,
      multiline: true,
    },
    {
      className: `edit-create-input ${savedCampaignDescription.type === constants.CAMPAIGN_DESCRIPTION_TYPE.WEB_CAMPAIGN_TITLE ? "display-none" : ""}`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "tcMessage" },
      value: savedCampaignDescription.tcMessage,
      name: "tcMessage",
      translateName: savedCampaignDescription.scMessageDisable ? ["scMessage"] : null,
      inputList: campaignDescriptionInputList,
      setInputList: setCampaignDescriptionInputList,
      isEndOfLine: false,
      multiline: true,
    },
    {
      className: `edit-create-input ${savedCampaignDescription.type === constants.CAMPAIGN_DESCRIPTION_TYPE.WEB_CAMPAIGN_TITLE ? "display-none" : ""}`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "scMessage" },
      value: savedCampaignDescription.scMessage,
      name: "scMessage",
      inputList: campaignDescriptionInputList,
      setInputList: setCampaignDescriptionInputList,
      isEndOfLine: true,
      disabled: savedCampaignDescription.scMessageDisable,
      multiline: true,
    },
  ]
  const content = [
    {
      tab:
        <>
          {CampaignDescriptionValidation.getErrorMessages(campaignDescriptionValidationConstants.KEY_REST_URL_ID) &&
            CampaignDescriptionValidation.getErrorMessages(campaignDescriptionValidationConstants.KEY_REST_URL_ID).length > 0 &&
            <div className="error-message">
              <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
              {CampaignDescriptionValidation.getErrorMessages(campaignDescriptionValidationConstants.KEY_REST_URL_ID)}
            </div>
          }
          {tempCampaignDescriptionEditList.map((inputSetting, index) => {
            let content;
            switch (inputSetting.type) {
              case constants.TYPE.SELECT:
                content =
                  <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
                break;
              default:
                content =
                  <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
            }
            return <div className="edit-display-wrapper" key={index}>
              {inputSetting.custom && <TempCustomCheckBox className="customize-btn" value={inputSetting.customValue} customName={inputSetting.customName} onSelectCheckbox={onSelectCheckbox} />}
              {content}
              {inputSetting.isEndOfLine && <div></div>}
            </div>
          })}
        </>,
      btn: true
    }
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      disabled: !savedCampaignDescription.type,
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  function onSelectCheckbox(value, customName) {
    switch (customName.key) {
      case "titleCustomize":
        dispatch(setCampaignDescriptionValue("scTitleDisable", savedCampaignDescription, "", value));
        break;
      case "messageCustomize":
        dispatch(setCampaignDescriptionValue("scMessageDisable", savedCampaignDescription, "", value));
        break;
      default:
    }
  }

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    onClickClear();
  };
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT + "/" + savedCampaignDescription.restUrlId + "/" + constants.RESTAURANT_TAB.INFO + "/" + constants.RESTAURANT_SUB_TAB.CAMPAIGN_DESCRIPTION);
  };

  function onChangeInput(name, e) {
    let changedValue = e ? e : null;
    if (name === "tcTitle" && savedCampaignDescription.scTitleDisable === true) {
      dispatch(setCampaignDescriptionValue("CHANGE_TC_TITLE_AND_SC_TITLE", savedCampaignDescription, "", changedValue));
    } else if (name === "tcMessage" && savedCampaignDescription.scMessageDisable === true) {
      dispatch(setCampaignDescriptionValue("CHANGE_TC_MESSAGE_AND_SC_MESSAGE", savedCampaignDescription, "", changedValue));
    } else if (name === "sequence") {
      dispatch(setCampaignDescriptionValue(name, savedCampaignDescription, "", Number.isInteger(Number(e)) ? Number(e) : 0));
    } else {
      dispatch(setCampaignDescriptionValue(name, savedCampaignDescription, "", changedValue));
    }
  }

  function onClickSave() {
    CampaignDescriptionValidation.validateEditCampaignDescription(savedCampaignDescription);
    if (CampaignDescriptionValidation.isValid()) {
      put(constants.PATH.SHOP.CAMPAIGN_DESCRIPTION + "/" + params.id, savedCampaignDescription, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToEdit,
          dialogContentText: { key: "editSuccess" },
          dialogActions: [
            { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      CampaignDescriptionValidation.resetErrorMessages();
    } else {
      setErrorVersion(CampaignDescriptionValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    CampaignDescriptionValidation.resetErrorMessages();
    if (clear === true) {
      getOne(constants.PATH.SHOP.CAMPAIGN_DESCRIPTION + "/" + params.id, undefined, (payload) => {
        payload.scTitleDisable = getScDisabled(payload.tcTitle, payload.scTitle);
        payload.scMessageDisable = getScDisabled(payload.tcMessage, payload.scMessage);
        dispatch(setCampaignDescription(_.cloneDeep(payload)));
        setCampaignDescriptionInputList(_.cloneDeep(payload));
        setReady(true)
        setClear(false)
      }, undefined, store);
    }
  }, [clear]); // eslint-disable-line react-hooks/exhaustive-deps

  const { getRestaurantIdAndName } = useRestaurant(savedRestaurant.restUrlId)
  const mapCode = getRestaurantIdAndName()

  return (
    ready &&
    <>
      <EditCreateDisplay
        code={savedCampaignDescription && savedCampaignDescription.id ? savedCampaignDescription.id : ""}
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        mapName={{ resource: resource, key: "restUrlId" }}

        mapCode={mapCode}
        display="edit"
      />
      {dialog && dialog.open &&
        <DialogWrapper dialogInfo={dialog} />
      }
    </>
  )
};