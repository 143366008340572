import { useState, useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import { Card } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { constants } from '../../Constants/constants';
import { post } from "../../helper/baseFetch";
import { setSelectTag, setSelectTagValue } from "../../actions/selectTagAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempCustomCheckBox } from "../../Component/Custom/TempCustomCheckBox";
import { DialogWrapper } from "../../Component/DialogWrapper";
import { setLoading } from "../../actions/systemAction";
// import { BrandGroupEditBtn } from "../../Component/Brand/BrandGroupEditBtn";
// import { UploadImage } from "../../Component/UploadImage/UploadImage";
// import { ImageList } from "../../Component/UploadImage/ImageList";
import SelectTagValidation from "../../validations/selectTagValidation";
import { selectTagValidationConstants } from '../../Constants/validationConstants';
import { TempBtn } from "../../Component/Input/TempBtn";

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const SelectTagCreate = ({ onCancel }) => {
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();
  const [clear, setClear] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const resource = "SelectTag";
  const savedSelectTag = useSelector(state => state.selectTag.ui.selectTag);
  //const [selectTagInputList, setSelectTagInputList] = useState([_.cloneDeep(constants.SELECT_TAG_INPUT)]);
  const helperTextStylesClasses = helperTextStyles();
  const [, setErrorVersion] = useState(SelectTagValidation.getVersion());
  

  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.SELECT_TAG_TAB.INFO
    },
  ]

  function handleAddSelectTag() {
    dispatch(setLoading(true));
    let temptList = _.cloneDeep(savedSelectTag);
    temptList.push(_.cloneDeep(constants.SELECT_TAG_INPUT));
    dispatch(setSelectTag(temptList));
    dispatch(setLoading(false));
  }

  function handeleDelete(deleteIndex) {
    let temptList = _.cloneDeep(savedSelectTag);
    temptList = temptList.filter((_,index)=> index!==deleteIndex);
    dispatch(setSelectTag(temptList));
    SelectTagValidation.resetErrorMessages();
  }

  const content = [
    {
      tab: 
      <>
      {
          SelectTagValidation.getErrorMessages(selectTagValidationConstants.KEY_SELECT_TAG_ERROR) &&
            SelectTagValidation.getErrorMessages(selectTagValidationConstants.KEY_SELECT_TAG_ERROR).length > 0 &&
            <div className="error-message">
              <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
              {SelectTagValidation.getErrorMessages(selectTagValidationConstants.KEY_SELECT_TAG_ERROR)}
            </div>
          }
          <TempBtn
            btnSetting=
            {{
              onClick: handleAddSelectTag,
              variant: constants.STYLE.VARIANT.CONTAINED,
              color: constants.STYLE.PRIMARY,
              icon: <AddIcon />,
              label: { resource: resource, key: "addSelectTag" },
            }}
          >
          </TempBtn>
          {savedSelectTag && savedSelectTag.length > 0 && savedSelectTag.map((savedSelectTagValue, savedSelectTagIndex) => {
            //console.log(savedSelectTagValue.enName)
            const tempSelectTagCreateList = [
              {
                className: "edit-create-input",
                size: constants.SIZE.SMALL,
                label: { resource: resource, key: "enName" },
                value: savedSelectTagValue.enName,
                name: "enName",
                custom: true,
                customName: { key: "nameCustomize" },
                customValue: savedSelectTagValue.scNameDisable,
                isEndOfLine: false,
                required: true,
                helperTextIcon: true,
                helperText: SelectTagValidation.getErrorMessages(`${savedSelectTagIndex}-${selectTagValidationConstants.KEY_EN_NAME}`),
                FormHelperTextProps: { className: helperTextStylesClasses.root },
              },
              {
                className: "edit-create-input",
                size: constants.SIZE.SMALL,
                label: { resource: resource, key: "tcName" },
                value: savedSelectTagValue.tcName,
                name: "tcName",
                translateName: savedSelectTagValue.scNameDisable ? ["scName"] : null,
                isEndOfLine: false,
                required: true,
                helperTextIcon: true,
                helperText: SelectTagValidation.getErrorMessages(`${savedSelectTagIndex}-${selectTagValidationConstants.KEY_TC_NAME}`),
                FormHelperTextProps: { className: helperTextStylesClasses.root },
              },
              {
                className: "edit-create-input",
                size: constants.SIZE.SMALL,
                label: { resource: resource, key: "scName" },
                value: savedSelectTagValue.scName,
                name: "scName",
                isEndOfLine: true,
                disabled: savedSelectTagValue.scNameDisable,
                required: true,
                helperTextIcon: true,
                helperText: SelectTagValidation.getErrorMessages(`${savedSelectTagIndex}-${selectTagValidationConstants.KEY_SC_NAME}`),
                FormHelperTextProps: { className: helperTextStylesClasses.root },
              },
            ]
            return <Card key={savedSelectTagIndex} className="image-list"> {
              <>
                <div className="delete-icon">
                  <TempBtn btnSetting={{
                    className: "edit-create-btn",
                    variant: constants.STYLE.VARIANT.CONTAINED,
                    color: constants.STYLE.PRIMARY,
                    onClick: () => handeleDelete(savedSelectTagIndex),
                    label: { key: "deleteBtn" },
                    icon: <DeleteIcon />,
                  }} />
                </div>
                {tempSelectTagCreateList.map((inputSetting, index) => {
                  let content = <></>;
                  switch (inputSetting.type) {
                    default:
                      content =
                        <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} index={savedSelectTagIndex}/>
                  }
                  return <div className={inputSetting.backgroundColorClass ? "image-card-background card-border" : "edit-display-wrapper"} key={index}>
                    {inputSetting.custom && <TempCustomCheckBox className="customize-btn" value={inputSetting.customValue} customName={inputSetting.customName} onSelectCheckbox={onSelectCheckbox} index={savedSelectTagIndex}/>}
                    {content}
                    {inputSetting.isEndOfLine && <div></div>}
                  </div>
                })
                }
              </>
            }
            </Card>
          }
        )}

      </>,
      btn: true
    }
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  function onSelectCheckbox(value, customName, index) {
    //cusomeName.key --> 
    if (customName.key === "nameCustomize") {
      //let temptList = _.cloneDeep(savedSelectTag);
      dispatch(setSelectTagValue("scNameDisable", savedSelectTag, "", value, index));
    } 
  }

  const onClickBackToCreate = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    onClickClear();
  };

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.SELECTED_TAG.SELECTED_TAG);
  };

  function onChangeInput(name, e, index) {

    let tempSelectTag = _.cloneDeep(savedSelectTag);

    if (tempSelectTag[index].scNameDisable === true && name === "tcName") {
        dispatch(setSelectTagValue("CHANGE_TC_NAME_AND_SC_NAME", savedSelectTag, "", e, index));
      } else {
        tempSelectTag[index][name] = e;
        dispatch(setSelectTag(tempSelectTag));
      }
  }

  function onClickSave() {

    SelectTagValidation.validateCreateSelectTag(savedSelectTag);
    if (SelectTagValidation.isValid()) {
      post(constants.PATH.SELECTED_TAG.SELECTED_TAG, savedSelectTag, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToCreate,
          dialogContentText: { key: "createSuccess" },
          dialogActions: onCancel ? [
            { label: { key: "backCreate" }, onClick: onClickBackToCreate, icon: <ArrowBackIcon /> },
            { label: { key: "backEdit" }, onClick: onCancel, icon: <LowPriorityIcon /> }
          ] : [
            { label: { key: "backCreate" }, onClick: onClickBackToCreate, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      SelectTagValidation.resetErrorMessages();
    } else {
      setErrorVersion(SelectTagValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    let tempInput = [_.cloneDeep(constants.SELECT_TAG_INPUT)];
    dispatch(setSelectTag(tempInput));
    setClear(false)
    SelectTagValidation.resetErrorMessages();
  }, [clear === true]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <EditCreateDisplay
        code=""
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        display="create"
      />
      {dialog && dialog.open &&
        <DialogWrapper dialogInfo={dialog} />
      }
    </>
  )
};
