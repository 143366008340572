import { restaurantMapValidationConstants } from '../Constants/validationConstants';

class RestaurantMapValidation {

  version;
  errorMap;
  restaurantMapErrorMap;
  valid;

  constructor() {

    if (!RestaurantMapValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.restaurantMapErrorMap = new Map();
      RestaurantMapValidation.instance = this;
    }
    return RestaurantMapValidation.instance;
  }

  validateEditRestaurantMap(restaurantMap) {
    this.resetErrorMessages();
    this.handleValidateEditRestaurantMap(restaurantMap);
    this.version++;
  }

  validateCreateRestaurantMap(restaurantMap) {
    this.resetErrorMessages();
    this.handleValidateCreateRestaurantMap(restaurantMap);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.restaurantMapErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key, uniqueCode) {
    if (!uniqueCode) {
      return (this.restaurantMapErrorMap.has(key)) ? this.restaurantMapErrorMap.get(key) : [];
    }
  }

  handleValidateEditRestaurantMap(restaurantMap) {
    if (!restaurantMap.restUrlId) {
      this.setRestaurantMapErrorMapMessages(restaurantMapValidationConstants.KEY_REST_URL_ID, "Missing Rest Url Id");
    }
    if (!restaurantMap.status) {
      this.setRestaurantMapErrorMapMessages(restaurantMapValidationConstants.KEY_STATUS, "Missing Status");
    }
  }

  handleValidateCreateRestaurantMap(restaurantMap) {
    if (restaurantMap.length === 0) {
      this.setRestaurantMapErrorMapMessages(restaurantMapValidationConstants.KEY_REST_MAP_ERROR, "Please add at least one retaurant!");
    }
  }


  setRestaurantMapErrorMapMessages(key, errorMessages, valid = false) {
    this.restaurantMapErrorMap.set(key, errorMessages);
    // console.log("restaurantMapErrorMap", this.restaurantMapErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new RestaurantMapValidation();
export default validation;