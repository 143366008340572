import { constants } from '../../../Constants/constants';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CancelIcon from '@mui/icons-material/Cancel';

import { BaseWrapper } from "../../../Component/BaseWrapper";
import { setRestaurantSlideshow } from "../../../actions/restaurantSlideshowAction";
import { setLoading } from "../../../actions/systemAction";
import { TempBtn } from "../../../Component/Input/TempBtn";
// import { restaurantSlideshowValidationConstants } from '../../Constants/validationConstants';
import SlideshowBulkCreateValidation from "../../../validations/SlideshowBulkCreateValidation";
import { getString } from "../../../helper/util";

export const RestaurantSlideshowCreateListForBulk = ({ resource, setCustomIdNumber, customIdNumber, mapCode, slideshowList, setSlideshowList }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const savedRestaurantSlideshow = useSelector(state => state.restaurantSlideshow.ui.restaurantSlideshow);
  const lang = useSelector(state => state.lang.ui.lang);
  const theme = useSelector(state => state.theme.ui.theme);

  function handleAddSlideshowList() {
    const tempSlideshow = _.cloneDeep(slideshowList);
    const newSlideshowInput = _.cloneDeep(constants.RESTAURANT_SLIDESHOW_INPUT);
    let newCustomIdNumber = _.cloneDeep(customIdNumber);
    newSlideshowInput.customId = "slideshow_" + newCustomIdNumber;
    newSlideshowInput.restUrlId = params.restUrlId;
    // console.log('crelist', tempSlideshow)

    tempSlideshow.push(newSlideshowInput);
    dispatch(setRestaurantSlideshow(newSlideshowInput));
    setSlideshowList(tempSlideshow);
    setCustomIdNumber(prev=> prev + 1);
    //BannerValidation.validateCreateBanner(tempBanner);
  }

  function handleCloneSlideshow(item) {
    const tempSlideshow = _.cloneDeep(slideshowList);
    const newItem = _.cloneDeep(item);
    let newCustomIdNumber = _.cloneDeep(customIdNumber);
    newItem.id = null;
    newItem.customId = "slideshow_" + newCustomIdNumber;
    newCustomIdNumber = newCustomIdNumber + 1;
    tempSlideshow.push(newItem);
    dispatch(setRestaurantSlideshow(newItem));
    setSlideshowList(tempSlideshow);
    //setCustomIdNumber(newCustomIdNumber + 1);
    setCustomIdNumber(prev=> prev + 1);
    //BannerValidation.validateCreateBanner(tempBanner);
  }

  function handleDeleteSlideshowList(item) {
    const newItem = _.cloneDeep(item);
    const tempSlideshowList = _.cloneDeep(slideshowList);
    setSlideshowList(tempSlideshowList.filter((slideshow) => newItem.customId !== slideshow.customId));
    //BannerValidation.validateCreateBanner(tempBannerList.filter((banner) => newItem.customId !== banner.customId));
    const tempSlideshow = _.cloneDeep(savedRestaurantSlideshow);
    if (tempSlideshow.customId === newItem.customId) {
      const newSlideshowInput = _.cloneDeep(constants.RESTAURANT_SLIDESHOW_INPUT);
      dispatch(setRestaurantSlideshow(newSlideshowInput));
    }
  }

  return (
    <div className="queue-template-content-wrapper">
      <BaseWrapper>
        <div className="queue-template-array-list-wrapper">
          <div className="sub-title">{getString(lang, resource, "mainTitle", theme)} : </div>
          <>
            <TempBtn
              btnSetting=
              {{
                className: "margin-left-16",
                onClick: () => { handleAddSlideshowList(); },
                variant: constants.STYLE.VARIANT.CONTAINED,
                color: constants.STYLE.PRIMARY,
                label: { resource: resource, key: "addBanner" },
                icon: <AddIcon />,
              }}
            >
            </TempBtn>
          </>
        </div>
        <div className="queue-template-content-content">
          <div className="queue-template-tab-background">
            {/* {RestaurantSlideshowValidation.getErrorMessages(restaurantSlideshowValidationConstants.KEY_BANNER_ERROR) &&
              RestaurantSlideshowValidation.getErrorMessages(restaurantSlideshowValidationConstants.KEY_BANNER_ERROR).length > 0 &&
              <div className="error-message">
                <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                {RestaurantSlideshowValidation.getErrorMessages(restaurantSlideshowValidationConstants.KEY_BANNER_ERROR)}
              </div>
            } */}
            {slideshowList?.map((item, index) => {
              let error = false;
              if (SlideshowBulkCreateValidation.getErrorMessages(item.customId)) {
                // console.log('createList error', RestaurantSlideshowValidation.getErrorMessages(item.customId))
                error = true;
              }
              let list = "";
              list =
                <div
                  key={item.customId}
                  className={item.customId === savedRestaurantSlideshow.customId
                    ? "queue-template-list-item queue-template-selected-product"
                    : error
                      ? "queue-template-list-item queue-template-error"
                      : "queue-template-list-item"}
                  onClick={(e) => {
                    dispatch(setLoading(true));
                    e.stopPropagation();
                    dispatch(setRestaurantSlideshow(item));
                    dispatch(setLoading(false));
                  }}>
                  <div className="code">
                    <div>{getString(lang, resource, "id", theme) + ": " + item.customId}</div>
                    <div className="type">{getString(lang, resource, "type", theme) + ": " + item.type}</div>
                  </div>
                  <div className="content">
                    <div className="top">
                      <div className="name">{item.tcName}</div>
                      <div className="delete-button">
                        <TempBtn
                          btnSetting=
                          {{
                            className: "delete-button delete-button-margin",
                            onClick: (e) => {
                              dispatch(setLoading(true));
                              handleCloneSlideshow(item);
                              dispatch(setLoading(false));
                            },
                            color: constants.STYLE.PRIMARY,
                            variant: constants.STYLE.VARIANT.CONTAINED,
                            label: { key: "Clone" },
                            icon: <FileCopyIcon />,
                          }}
                        >
                        </TempBtn>
                        {!item.id &&
                          <TempBtn
                            btnSetting=
                            {{
                              className: "delete-button",
                              onClick: (e) => {
                                dispatch(setLoading(true));
                                handleDeleteSlideshowList(item);
                                dispatch(setLoading(false));
                              },
                              color: constants.STYLE.PRIMARY,
                              variant: constants.STYLE.VARIANT.CONTAINED,
                              label: { key: "deleteBtn" },
                              icon: <DeleteIcon />,
                            }}
                          >
                          </TempBtn>
                        }
                      </div>
                    </div>
                    {SlideshowBulkCreateValidation.getErrorMessages(item.customId) &&
                      <div className="error-message">
                        <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                        {SlideshowBulkCreateValidation.getErrorMessages(item.customId)}
                      </div>
                    }
                  </div>
                </div>
              return list;
            })}
          </div>
        </div>
      </BaseWrapper>
    </div>
  )
};