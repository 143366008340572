import { useParams } from "react-router-dom";

import { constants, BANNER_TYPE, GET_ID_FROM_PAYLOAD } from '../../Constants/constants';
import { BannerEdit } from "../Banner/BannerEdit";

export const RestaurantBannerEdit = () => {
  const resource = "restaurant";
  const params = useParams();


  return <BannerEdit
    newPath={constants.PATH.SHOP.RESTAURANT_BANNER}
    dataId={params.id}
    mapId={GET_ID_FROM_PAYLOAD}
    mapName={{ resource: resource, key: "restUrlId" }}
    mapCode={GET_ID_FROM_PAYLOAD}
    redirectPath={constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT}
    bannerType={BANNER_TYPE.RESTAURANT}
    tab={constants.RESTAURANT_MEDIA_SUB_TAB.BANNER_LIST} />
};