import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sify } from 'chinese-conv'
import _ from 'lodash'

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import CachedIcon from '@mui/icons-material/Cached'
import { constants as co, TABLE_TYPE_DEFAULT_VALUE } from '../../Constants/constants'
import { setQueueTemplate, setQueueTemplateTimeSectionValue, setQueueTemplateTableTypeValue } from '../../actions/queueTemplateAction'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { TempTextInput } from '../../Component/Input/TempTextInput'
import { TempAutoSelectInput } from '../../Component/Input/TempAutoSelectInput'
import { TempCustomCheckBox } from '../../Component/Custom/TempCustomCheckBox'
import QueueTemplateValidation from '../../validations/queueTemplateValidation'
import { queueTemplateValidationConstants } from '../../Constants/validationConstants'
import { DialogWrapper } from '../../Component/DialogWrapper'
import { Clear } from '@mui/icons-material'
import MuiCollapse from '../../Component/collapse/MuiCollapse'

export const QueueTemplateTimeSection = ({ setCustomIdNumber, customIdNumber }) => {
  const dispatch = useDispatch()
  const savedQueueTemplate = useSelector((state) => state.queueTemplate.ui.queueTemplate)
  const savedTimeSection = useSelector((state) => state.queueTemplate.ui.timeSection)
  const [timeSectionInputList, setTimeSectionInputList] = useState(_.cloneDeep(savedTimeSection))
  // eslint-disable-next-line no-unused-vars
  const [errorVersion, setErrorVersion] = useState(QueueTemplateValidation.getVersion())

  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })

  const resource = 'TimeSection'

  const generateTimeSectionDescription = () => {
    let label = ''

    label += savedTimeSection.startTime.substring(0, 5) + ' - '

    if (savedTimeSection.endTime && savedTimeSection.endTime.substring(3, 5) === '59' && savedTimeSection.endTime.substring(6, 8) === '59') {
      label += Number(savedTimeSection.endTime.substring(0, 2)) + 1 + ':00'
    } else if (savedTimeSection.endTime && savedTimeSection.endTime.substring(6, 8) === '59') {
      let endTime = Number(savedTimeSection.endTime.substring(3, 5)) + 1

      if (endTime < 10) {
        endTime = '0' + endTime
      }

      label += savedTimeSection.endTime.substring(0, 2) + ':' + endTime
    } else {
      label += savedTimeSection.endTime.substring(0, 5)
    }

    onChangeInput('timeSectionDescription', label)
  }

  const contents = [
    {
      tab: (
        <>
          <TempAutoSelectInput
            inputSetting={{
              label: { resource: resource, key: 'timeSectionId' },
              value: savedTimeSection.timeSectionId,
              selectValue: co.CHOICES.QUEUE_TEMPLATE.TIME_SECTION_ID,
              name: 'timeSectionId',
              freeSolo: true,
              disableStatusThemeColor: true,
              helperTextIcon: true,
              helperText: QueueTemplateValidation.getErrorMessages(
                queueTemplateValidationConstants.KEY_TIME_SECTION_ID,
                savedTimeSection.customId
              ).map((error) => {
                return error
              }),
            }}
            handleChange={onChangeInput}
          />
          {savedTimeSection.id && (
            <TempAutoSelectInput
              inputSetting={{
                label: { resource: resource, key: 'status' },
                value: savedTimeSection.status,
                selectValue: co.CHOICES.QUEUE_TEMPLATE.STATUS,
                name: 'status',
                disableClearable: true,
                helperTextIcon: true,
                helperText: QueueTemplateValidation.getErrorMessages(
                  queueTemplateValidationConstants.KEY_TIME_SECTION_STATUS,
                  savedTimeSection.customId
                ).map((error) => {
                  return error
                }),
              }}
              handleChange={onChangeInput}
            />
          )}
          <TempCustomCheckBox
            className="customize-btn"
            value={savedTimeSection.disableLabel}
            customName={{ resource: resource, key: 'isAutoTranslateTimeSectionLabel' }}
            onSelectCheckbox={onSelectCheckbox}
          />
          <TempTextInput
            inputSetting={{
              label: { resource: resource, key: 'timeSectionEnLabel' },
              value: savedTimeSection.timeSectionEnLabel,
              name: 'timeSectionEnLabel',
              multiline: true,
              helperTextIcon: true,
              helperText: QueueTemplateValidation.getErrorMessages(
                queueTemplateValidationConstants.KEY_TIME_SECTION_EN_LABEL,
                savedTimeSection.customId
              ).map((error) => {
                return error
              }),
            }}
            handleChange={onChangeInput}
          />
          <TempTextInput
            inputSetting={{
              label: { resource: resource, key: 'timeSectionTcLabel' },
              value: savedTimeSection.timeSectionTcLabel,
              name: 'timeSectionTcLabel',
              translateName: savedTimeSection.disableLabel ? ['timeSectionScLabel'] : null,
              inputList: timeSectionInputList,
              setInputList: setTimeSectionInputList,
              multiline: true,
              helperTextIcon: true,
              helperText: QueueTemplateValidation.getErrorMessages(
                queueTemplateValidationConstants.KEY_TIME_SECTION_TC_LABEL,
                savedTimeSection.customId
              ).map((error) => {
                return error
              }),
            }}
            handleChange={onChangeInput}
          />
          <TempTextInput
            inputSetting={{
              label: { resource: resource, key: 'timeSectionScLabel' },
              value: savedTimeSection.timeSectionScLabel,
              name: 'timeSectionScLabel',
              inputList: timeSectionInputList,
              setInputList: setTimeSectionInputList,
              disabled: savedTimeSection.disableLabel,
              multiline: true,
              helperTextIcon: true,
              helperText: QueueTemplateValidation.getErrorMessages(
                queueTemplateValidationConstants.KEY_TIME_SECTION_SC_LABEL,
                savedTimeSection.customId
              ).map((error) => {
                return error
              }),
            }}
            handleChange={onChangeInput}
          />
          <TempCustomCheckBox
            className="customize-btn"
            value={savedTimeSection.isSyncTimeFieldsWithAllTableTypes}
            customName={{ resource: resource, key: 'isSyncTimeFieldsWithAllTableTypes' }}
            onSelectCheckbox={onSelectCheckbox}
          />
          <TempTextInput
            inputSetting={{
              label: { resource: resource, key: 'startTime' },
              value: savedTimeSection.startTime,
              name: 'startTime',
              step: '2',
              disabled: !savedTimeSection.isSyncTimeFieldsWithAllTableTypes,
              helperText: QueueTemplateValidation.getErrorMessages(
                queueTemplateValidationConstants.KEY_TIME_SECTION_START_TIME,
                savedTimeSection.customId
              ).map((error) => {
                return error
              }),
              type: co.TYPE.TIME,
            }}
            handleChange={onChangeInput}
          />
          <TempTextInput
            inputSetting={{
              label: { resource: resource, key: 'endTime' },
              value: savedTimeSection.endTime,
              name: 'endTime',
              step: '2',
              disabled: !savedTimeSection.isSyncTimeFieldsWithAllTableTypes,
              helperText: QueueTemplateValidation.getErrorMessages(
                queueTemplateValidationConstants.KEY_TIME_SECTION_END_TIME,
                savedTimeSection.customId
              ).map((error) => {
                return error
              }),
              type: co.TYPE.TIME,
            }}
            handleChange={onChangeInput}
          />
          <TempTextInput
            inputSetting={{
              label: { resource: resource, key: 'timeSectionDescription' },
              value: savedTimeSection.timeSectionDescription,
              name: 'timeSectionDescription',
              disabled: !savedTimeSection.isSyncTimeFieldsWithAllTableTypes,
              onClickEndAdornmentIconButton: () => generateTimeSectionDescription(),
              endAdornmentIconButton: <CachedIcon />,
              disabledEndAdornmentIconButton: !!(!savedTimeSection.startTime || !savedTimeSection.endTime),
              disabledOnClickInputField: true,
              disabledStyle: true,
              multiline: true,
              clear: true,
            }}
            handleChange={onChangeInput}
          />
          <MuiCollapse title="AUTO TASK">
            <TempCustomCheckBox
              className="customize-btn"
              value={savedTimeSection.isSyncRequestTicketTimeFieldsWithAllTableTypes}
              customName={{ resource: resource, key: 'isSyncRequestTicketTimeFieldsWithAllTableTypes' }}
              onSelectCheckbox={onSelectCheckbox}
            />
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'kioskRequestTicketStartTime' },
                value: savedTimeSection.kioskRequestTicketStartTime,
                name: 'kioskRequestTicketStartTime',
                helperTextIcon: true,
                step: '2',
                endAdornmentIconButton: <Clear fontSize={co.SIZE.SMALL} />,
                onClickEndAdornmentIconButton: () => {
                  onChangeInput('kioskRequestTicketStartTime', null)
                },
                disabledStyle: true,
                disabled: !savedTimeSection.isSyncRequestTicketTimeFieldsWithAllTableTypes,
                type: co.TYPE.TIME,
                disabledEndAdornmentIconButton: !savedTimeSection.isSyncRequestTicketTimeFieldsWithAllTableTypes,
              }}
              handleChange={onChangeInput}
            />
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'kioskRequestTicketEndTime' },
                value: savedTimeSection.kioskRequestTicketEndTime,
                name: 'kioskRequestTicketEndTime',
                helperTextIcon: true,
                step: '2',
                endAdornmentIconButton: <Clear fontSize={co.SIZE.SMALL} />,
                onClickEndAdornmentIconButton: () => {
                  onChangeInput('kioskRequestTicketEndTime', null)
                },
                disabledStyle: true,
                disabled: !savedTimeSection.isSyncRequestTicketTimeFieldsWithAllTableTypes,
                type: co.TYPE.TIME,
                disabledEndAdornmentIconButton: !savedTimeSection.isSyncRequestTicketTimeFieldsWithAllTableTypes,
              }}
              handleChange={onChangeInput}
            />
            <div></div>
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'mobileRequestTicketStartTime' },
                value: savedTimeSection.mobileRequestTicketStartTime,
                name: 'mobileRequestTicketStartTime',
                helperTextIcon: true,
                step: '2',
                endAdornmentIconButton: <Clear fontSize={co.SIZE.SMALL} />,
                onClickEndAdornmentIconButton: () => {
                  onChangeInput('mobileRequestTicketStartTime', null)
                },
                disabledStyle: true,
                disabled: !savedTimeSection.isSyncRequestTicketTimeFieldsWithAllTableTypes,
                type: co.TYPE.TIME,
                disabledEndAdornmentIconButton: !savedTimeSection.isSyncRequestTicketTimeFieldsWithAllTableTypes,
              }}
              handleChange={onChangeInput}
            />
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'mobileRequestTicketEndTime' },
                value: savedTimeSection.mobileRequestTicketEndTime,
                name: 'mobileRequestTicketEndTime',
                helperTextIcon: true,
                step: '2',
                endAdornmentIconButton: <Clear fontSize={co.SIZE.SMALL} />,
                onClickEndAdornmentIconButton: () => {
                  onChangeInput('mobileRequestTicketEndTime', null)
                },
                disabledStyle: true,
                disabled: !savedTimeSection.isSyncRequestTicketTimeFieldsWithAllTableTypes,
                type: co.TYPE.TIME,
                disabledEndAdornmentIconButton: !savedTimeSection.isSyncRequestTicketTimeFieldsWithAllTableTypes,
              }}
              handleChange={onChangeInput}
            />
          </MuiCollapse>
        </>
      ),
      btn: false,
    },
  ]

  function onSelectCheckbox(e, name) {
    const clonedSavedQueueTemplate = _.cloneDeep(savedQueueTemplate)
    const clonedSavedTimeSection = _.cloneDeep(savedTimeSection)
    switch (name.key) {
      case 'isAutoTranslateTimeSectionLabel':
        clonedSavedTimeSection.disableLabel = e

        if (e) {
          clonedSavedTimeSection.timeSectionScLabel = clonedSavedTimeSection.timeSectionTcLabel
            ? sify(clonedSavedTimeSection.timeSectionTcLabel)
            : clonedSavedTimeSection.timeSectionTcLabel
        }

        break
      case 'isSyncTimeFieldsWithAllTableTypes':
        clonedSavedTimeSection.isSyncTimeFieldsWithAllTableTypes = e
        if (!e) {
          clonedSavedTimeSection.startTime = null
          clonedSavedTimeSection.endTime = null
          clonedSavedTimeSection.timeSectionDescription = ''
        } else {
          for (const tableType of clonedSavedTimeSection.tableTypeList) {
            tableType.startTime = clonedSavedTimeSection.startTime
            tableType.endTime = clonedSavedTimeSection.endTime
            tableType.timeSectionDescription = clonedSavedTimeSection.timeSectionDescription
          }
        }
        break
      case 'isSyncRequestTicketTimeFieldsWithAllTableTypes':
        clonedSavedTimeSection.isSyncRequestTicketTimeFieldsWithAllTableTypes = e
        if (!e) {
          clonedSavedTimeSection.kioskRequestTicketStartTime = null
          clonedSavedTimeSection.kioskRequestTicketEndTime = null
          clonedSavedTimeSection.mobileRequestTicketStartTime = null
          clonedSavedTimeSection.mobileRequestTicketEndTime = null
        } else {
          for (const tableType of clonedSavedTimeSection.tableTypeList) {
            tableType.kioskRequestTicketStartTime = clonedSavedTimeSection.kioskRequestTicketStartTime
            tableType.kioskRequestTicketEndTime = clonedSavedTimeSection.kioskRequestTicketEndTime
            tableType.mobileRequestTicketStartTime = clonedSavedTimeSection.mobileRequestTicketStartTime
            tableType.mobileRequestTicketEndTime = clonedSavedTimeSection.mobileRequestTicketEndTime
          }
        }
        break
      default:
    }
    let newTimeSectionIdx = clonedSavedQueueTemplate.findIndex((timeSection) => savedTimeSection.customId === timeSection.customId)
    if (newTimeSectionIdx >= 0) {
      clonedSavedQueueTemplate[newTimeSectionIdx] = clonedSavedTimeSection
    } else {
      clonedSavedTimeSection.customId = 'section_' + customIdNumber
      setCustomIdNumber(customIdNumber + 1)
      clonedSavedQueueTemplate.push(clonedSavedTimeSection)
    }
    dispatch(setQueueTemplateTimeSectionValue(clonedSavedTimeSection))
    dispatch(setQueueTemplate(clonedSavedQueueTemplate))

    const newTableTypeInput = _.cloneDeep(TABLE_TYPE_DEFAULT_VALUE)
    dispatch(setQueueTemplateTableTypeValue(newTableTypeInput))

    QueueTemplateValidation.validateTemplateTimeSection(clonedSavedQueueTemplate)
    setErrorVersion(QueueTemplateValidation.getVersion())
  }

  function onChangeTimeSectionStatus(value) {
    const newSavedQueueTemplate = _.cloneDeep(savedQueueTemplate)
    const newSavedTimeSection = _.cloneDeep(savedTimeSection)
    newSavedTimeSection['status'] = value
    newSavedTimeSection.tableTypeList = newSavedTimeSection.tableTypeList.filter((tableType) => {
      if (tableType.id) {
        tableType.status = 'D'
      }
      return tableType.id
    })
    let newTimeSectionIdx = newSavedQueueTemplate.findIndex((queueTemplate) => savedTimeSection.customId === queueTemplate.customId)
    if (newTimeSectionIdx >= 0) {
      newSavedQueueTemplate[newTimeSectionIdx] = newSavedTimeSection
    } else {
      newSavedTimeSection.customId = 'section_' + customIdNumber
      setCustomIdNumber(customIdNumber + 1)
      newSavedQueueTemplate.push(newSavedTimeSection)
    }
    dispatch(setQueueTemplateTimeSectionValue(newSavedTimeSection))
    dispatch(setQueueTemplate(newSavedQueueTemplate))

    const newTableTypeInput = _.cloneDeep(TABLE_TYPE_DEFAULT_VALUE)
    dispatch(setQueueTemplateTableTypeValue(newTableTypeInput))

    QueueTemplateValidation.validateTemplateTimeSection(newSavedQueueTemplate)
    setErrorVersion(QueueTemplateValidation.getVersion())
    onCloseTimeSectionStatusDialog()
  }

  function onCloseTimeSectionStatusDialog() {
    setDialog({
      open: false,
      dialogActions: [],
    })
  }

  function onCloseDialog() {
    setDialog({
      open: false,
      dialogActions: [],
    })
  }

  function onChangeInput(name, e) {
    const newSavedQueueTemplate = _.cloneDeep(savedQueueTemplate)
    const newSavedTimeSection = _.cloneDeep(savedTimeSection)

    switch (name) {
      case 'timeSectionTcLabel':
        if (savedTimeSection.disableLabel === true) {
          newSavedTimeSection[`${name}`] = e
          newSavedTimeSection['timeSectionScLabel'] = sify(e)
        } else {
          newSavedTimeSection[`${name}`] = e
        }
        break
      case 'status':
        if (e === 'D') {
          setDialog({
            open: true,
            onClose: onCloseDialog,
            dialogContent:
              'After Time Section saves, all the Table Type Status will change to D (Deactive) and new created Table Type will be deleted. Please press Yes if you confirm!',
            dialogActions: [
              { label: { key: 'yes' }, onClick: () => onChangeTimeSectionStatus(e), icon: <SaveIcon /> },
              { label: { key: 'no' }, onClick: () => onCloseDialog(), icon: <ClearIcon /> },
            ],
          })
        } else {
          newSavedTimeSection[`${name}`] = e
          newSavedTimeSection.tableTypeList.map((tableType) => {
            tableType.status = 'A'
            return tableType
          })
        }
        break
      case 'endTime':
      case 'startTime':
      case 'timeSectionDescription':
        newSavedTimeSection[`${name}`] = e
        if (newSavedTimeSection.isSyncTimeFieldsWithAllTableTypes) {
          for (const tableType of newSavedTimeSection.tableTypeList) {
            tableType.startTime = newSavedTimeSection.startTime
            tableType.endTime = newSavedTimeSection.endTime
            tableType.timeSectionDescription = newSavedTimeSection.timeSectionDescription
          }
        }
        break
      case 'kioskRequestTicketStartTime':
      case 'kioskRequestTicketEndTime':
      case 'mobileRequestTicketStartTime':
      case 'mobileRequestTicketEndTime':
        newSavedTimeSection[name] = e
        if (newSavedTimeSection.isSyncRequestTicketTimeFieldsWithAllTableTypes) {
          for (const tableType of newSavedTimeSection.tableTypeList) {
            tableType.kioskRequestTicketStartTime = newSavedTimeSection.kioskRequestTicketStartTime
            tableType.kioskRequestTicketEndTime = newSavedTimeSection.kioskRequestTicketEndTime
            tableType.mobileRequestTicketStartTime = newSavedTimeSection.mobileRequestTicketStartTime
            tableType.mobileRequestTicketEndTime = newSavedTimeSection.mobileRequestTicketEndTime
          }
        }
        break
      default:
        newSavedTimeSection[`${name}`] = e
    }

    let newTimeSectionIdx = newSavedQueueTemplate.findIndex((timeSection) => savedTimeSection.customId === timeSection.customId)
    if (newTimeSectionIdx >= 0) {
      newSavedQueueTemplate[newTimeSectionIdx] = newSavedTimeSection
    } else {
      newSavedTimeSection.customId = 'section_' + customIdNumber
      setCustomIdNumber(customIdNumber + 1)
      newSavedQueueTemplate.push(newSavedTimeSection)
    }
    dispatch(setQueueTemplateTimeSectionValue(newSavedTimeSection))
    dispatch(setQueueTemplate(newSavedQueueTemplate))

    const newTableTypeInput = _.cloneDeep(TABLE_TYPE_DEFAULT_VALUE)
    dispatch(setQueueTemplateTableTypeValue(newTableTypeInput))

    QueueTemplateValidation.validateTemplateTimeSection(newSavedQueueTemplate)
    setErrorVersion(QueueTemplateValidation.getVersion())
  }

  // useEffect(() => {
  //   console.log('savedTimeSection', savedTimeSection)
  // }, [savedTimeSection])

  return (
    <div className="sub-tab-wrapper-margin-32">
      <EditCreateDisplay
        code={savedTimeSection && savedTimeSection.customId ? savedTimeSection.customId : ''}
        resource={resource}
        tabs={false}
        content={contents}
        onChangeInput={onChangeInput}
        display={savedTimeSection.customId ? 'edit' : 'create'}
      />
      {dialog && dialog.open && <DialogWrapper dialogInfo={dialog} />}
    </div>
  )
}
