import { BANNER } from './types';
import _ from "lodash";

export const setBannerDefault = () => {
    return {
        type: BANNER.SET_BANNER_DEFAULT,
    }
}

export const setBannerList = (bannerList) => {
    return {
        type: BANNER.SET_BANNER_LIST,
        bannerList: bannerList
    }
}

export const setBanner = (banner) => {
    return {
        type: BANNER.SET_BANNER,
        banner: banner
    }
}

export const setBannerValue = (draftType, orignalProduct, key, value, index) => {
    const banner = _.cloneDeep(orignalProduct);
    switch (draftType) {
        case "CHANGE_IMAGE_SIZE":
            banner["imageWidth"] = value.imageWidth;
            banner["imageHeight"] = value.imageHeight;
            break;
        default:
            banner[draftType] = value;
    }
    return {
        type: BANNER.SET_BANNER_VALUE,
        banner: banner
    }
}