import { useEffect, useState } from 'react'
import { SubTabEditCreateDisplay } from '../../Component/EditCreateDisplay/SubTabEditCreateDisplay'
import { constants } from '../../Constants/constants'
import { getContent } from '../../Component/EditCreateDisplay/EditUtil'
import { Box } from '@mui/material'

const resource = 'restaurant'
const GULU_WEB = 'GULU_Web'
const GULU_EVENT = 'Event_Web'
const DOMAIN_LIST = [
  { value: 'GULU_Web', name: 'GULU Web' },
  { value: 'Event_Web', name: 'Event Web' },
]
const SPECIFIC_LANG_LIST = [
  { value: 'EN', name: 'EN' },
  { value: 'TC', name: 'TC' },
  { value: 'SC', name: 'SC' },
  { value: 'Detect', name: 'Browser Detected Language' },
]
const UNIVERSAL_LINK_LIST = [
  { value: 'true', name: 'App&website' },
  { value: 'false', name: 'Website' },
]

export const DEFAULT_SERVICE_LIST = [
  { value: 'default', name: 'Default' },
  { value: 'RESERVE', name: 'Reservation' },
  { value: 'QUEUE', name: 'Queue' },
  { value: 'PICKUP', name: 'Pick Up' },
  { value: 'TV', name: 'TV' },
]

const GULU_WEB_BASE = process.env.REACT_APP_GULU_WEB_BASE
const GULU_WEB_SUFFIX = 'shop/'
const GULU_WEB_EN = 'en/'
const GULU_WEB_TC = 'zh-HK/'
const GULU_WEB_SC = 'zh-CN/'
const GULU_WEB_UNIVERSAL_LINK = 'app/'

const GULU_EVENT_BASE = process.env.REACT_APP_GULU_EVENT_BASE
const GULU_EVENT_SUFFIX = 'site_detail/'
const GULU_EVENT_EN = 'EN/'
const GULU_EVENT_TC = 'TC/'
const GULU_EVENT_SC = 'SC/'
const GULU_EVENT_UNIVERSAL_LINK = 'app/site/'

export const UrlGenerator = ({ restUrlId, handleSetting }) => {
  const [domain, setDomain] = useState(GULU_WEB)
  const [specificLang, setSpecificLang] = useState('Detect')
  const [universalLink, setUniversalLink] = useState('true')
  const [defaultService, setDefaultService] = useState('default')

  const onChangeInput = (name, e) => {
    switch (name) {
      case 'website':
        setDomain(e)
        break
      case 'specificLang':
        setSpecificLang(e)
        break
      case 'universalLink':
        setUniversalLink(e)
        break
      case 'defaultService':
        setDefaultService(e)
        break
      default:
    }
  }

  const generateUrl = () => {
    let tempUrl = ''

    switch (domain) {
      case GULU_WEB:
        tempUrl += GULU_WEB_BASE
        switch (specificLang) {
          case 'EN':
            tempUrl += GULU_WEB_EN
            break
          case 'TC':
            tempUrl += GULU_WEB_TC
            break
          case 'SC':
            tempUrl += GULU_WEB_SC
            break
          case 'Detect':
            break
          default:
        }
        switch (universalLink) {
          case 'true':
            tempUrl += GULU_WEB_UNIVERSAL_LINK
            break
          case 'false':
            break
          default:
        }
        tempUrl += GULU_WEB_SUFFIX + restUrlId
        switch (defaultService) {
          case 'RESERVE':
          case 'QUEUE':
          case 'PICKUP':
          case 'TV':
            tempUrl += `?service=${defaultService}`
            break
          default:
        }
        break
      case GULU_EVENT:
        tempUrl += GULU_EVENT_BASE
        switch (specificLang) {
          case 'EN':
            tempUrl += GULU_EVENT_EN
            break
          case 'TC':
            tempUrl += GULU_EVENT_TC
            break
          case 'SC':
            tempUrl += GULU_EVENT_SC
            break
          case 'Detect':
            break
          default:
        }
        switch (universalLink) {
          case 'true':
            tempUrl += GULU_EVENT_UNIVERSAL_LINK
            break
          case 'false':
            tempUrl += GULU_EVENT_SUFFIX
            break
          default:
        }
        tempUrl += restUrlId
        break
      default:
    }

    if (handleSetting) {
      handleSetting({ domain, specificLang, universalLink, defaultService, url: tempUrl })
    }
  }

  const fields = [
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'website' },
      value: domain,
      type: constants.TYPE.RADIO_BUTTON,
      radioArray: DOMAIN_LIST,
      name: 'website',
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'specificLang' },
      value: specificLang,
      type: constants.TYPE.RADIO_BUTTON,
      radioArray: SPECIFIC_LANG_LIST,
      name: 'specificLang',
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'universalLink' },
      value: universalLink,
      type: constants.TYPE.RADIO_BUTTON,
      radioArray: UNIVERSAL_LINK_LIST,
      name: 'universalLink',
      isEndOfLine: true,
    },
    {
      className: `edit-create-input ${domain !== GULU_WEB ? 'display-none' : ''}`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'defaultService' },
      value: defaultService,
      type: constants.TYPE.RADIO_BUTTON,
      radioArray: DEFAULT_SERVICE_LIST,
      name: 'defaultService',
      isEndOfLine: true,
    },
  ]

  const content = [
    {
      tab: (
        <>
          {fields.map((inputSetting, index) => {
            let content = getContent(inputSetting, index, onChangeInput)
            return (
              <div className={`edit-display-wrapper ${inputSetting.className}`} key={index}>
                {content}
                {inputSetting.isEndOfLine && <div></div>}
              </div>
            )
          })}
        </>
      ),
      btn: false,
    },
  ]

  useEffect(() => {
    generateUrl()
  }, [domain, specificLang, universalLink, defaultService])

  return (
    <Box sx={{ width: '270px', overflow: 'hidden' }}>
      <SubTabEditCreateDisplay code={''} resource={resource} tabs={false} content={content} onChangeInput={onChangeInput} display={'none'} />
    </Box>
  )
}
