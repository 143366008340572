import { RestaurantTagCreate } from "../../Component/RestaurantTag/RestaurantTagCreate";
import { constants } from "../../Constants/constants";

import "../../styles/map.css";

export const RestaurantCuisineCreate = () => {
  return (
    <RestaurantTagCreate resource={constants.PATH.RESTAURANT_TAG.RESTAURANT_CUISINE} resourceName="restaurant_cuisine" />
  );
};
