import { campaignDescriptionValidationConstants } from '../Constants/validationConstants';

class CampaignDescriptionValidation {

  version;
  errorMap;
  campaignDescriptionErrorMap;
  valid;

  constructor() {

    if (!CampaignDescriptionValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.campaignDescriptionErrorMap = new Map();
      CampaignDescriptionValidation.instance = this;
    }
    return CampaignDescriptionValidation.instance;
  }

  validateEditCampaignDescription(campaignDescription) {
    this.resetErrorMessages();
    this.handleValidateEditCampaignDescription(campaignDescription);
    this.version++;
  }

  validateCreateCampaignDescription(campaignDescription) {
    this.resetErrorMessages();
    this.handleValidateCreateCampaignDescription(campaignDescription);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.campaignDescriptionErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key, uniqueCode) {
    if (!uniqueCode) {
      return (this.campaignDescriptionErrorMap.has(key)) ? this.campaignDescriptionErrorMap.get(key) : [];
    }
  }

  handleValidateCreateCampaignDescription(campaignDescription) {
    if (!campaignDescription.restUrlId) {
      this.setCampaignDescriptionErrorMapMessages(campaignDescriptionValidationConstants.KEY_REST_URL_ID, "Missing Rest Url Id");
    }
    if (!campaignDescription.type) {
      this.setCampaignDescriptionErrorMapMessages(campaignDescriptionValidationConstants.KEY_TYPE, "Missing Type");
    }
    if (!campaignDescription.sequence && campaignDescription.sequence !== 0) {
      this.setCampaignDescriptionErrorMapMessages(campaignDescriptionValidationConstants.KEY_SEQUENCE, "Missing Sequence");
    } else if (campaignDescription.sequence < 0 || !Number.isInteger(Number(campaignDescription.sequence))) {
      this.setCampaignDescriptionErrorMapMessages(campaignDescriptionValidationConstants.KEY_SEQUENCE, "Invalid Sequence");
    }
  }

  handleValidateEditCampaignDescription(campaignDescription) {
    if (!campaignDescription.restUrlId) {
      this.setCampaignDescriptionErrorMapMessages(campaignDescriptionValidationConstants.KEY_REST_URL_ID, "Missing Rest Url Id");
    }
    if (!campaignDescription.type) {
      this.setCampaignDescriptionErrorMapMessages(campaignDescriptionValidationConstants.KEY_TYPE, "Missing Type");
    }
    if (!campaignDescription.sequence && campaignDescription.sequence !== 0) {
      this.setCampaignDescriptionErrorMapMessages(campaignDescriptionValidationConstants.KEY_SEQUENCE, "Missing Sequence");
    } else if (campaignDescription.sequence < 0 || !Number.isInteger(Number(campaignDescription.sequence))) {
      this.setCampaignDescriptionErrorMapMessages(campaignDescriptionValidationConstants.KEY_SEQUENCE, "Invalid Sequence");
    }
    if (!campaignDescription.status) {
      this.setCampaignDescriptionErrorMapMessages(campaignDescriptionValidationConstants.KEY_STATUS, "Missing Status");
    }
  }

  setCampaignDescriptionErrorMapMessages(key, errorMessages, valid = false) {
    this.campaignDescriptionErrorMap.set(key, errorMessages);
    // console.log("campaignDescriptionErrorMap", this.campaignDescriptionErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new CampaignDescriptionValidation();
export default validation;