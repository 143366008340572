import { URL_MAP_ACTION_TYPE } from '../../Constants/constants'

export function getUrlObjParameters(urlObj) {
  function assignUrlObjParameters(queryParamStr, actionType) {
    let queryParamObj = {}

    // const keyValuePairs = queryParamStr.split('&') || queryParamStr
    const keyValuePairs = queryParamStr.split('&') || []

    keyValuePairs.forEach((pair) => {
      const key = pair.split('=')[0]

      if (key === 'action' && actionType !== URL_MAP_ACTION_TYPE.REST) {
        let value = pair.split('=')[1] || ''
        queryParamObj = Object.assign(queryParamObj, { [key]: decodeURIComponent(value) })
      } else if (key === 'action' && actionType === URL_MAP_ACTION_TYPE.REST) {
        let value = pair.split('=')[1]

        switch (value) {
          case 'getRestInfo':
          case 'INFO':
            value = 'INFO'
            break
          case 'getRestWall':
          case 'WALL':
            value = 'WALL'
            break
          case 'NEW_REVIEW':
            value = 'NEW_REVIEW'
            break
          case 'getRestMenu':
          case 'MENU':
            value = 'MENU'
            break
          case 'getRestQueue':
          case 'TICKET':
          case 'queue':
          case 'QUEUE':
            value = 'QUEUE'
            break
          case 'getRestReserve':
          case 'RESERVE':
            value = 'RESERVE'
            break
          case 'APPOINTMENT':
            value = 'APPOINTMENT'
            break
          case 'appointmentContactInPerson':
          case 'callback':
            value = 'callback'
            break
          case 'appointmentContactShop':
            value = 'appointmentContactShop'
            break
          case 'getRestTakeaway':
          case 'TAKEAWAY':
            value = 'TAKEAWAY'
            break
          default:
        }

        queryParamObj = Object.assign(queryParamObj, { [key]: value })
      } else if (pair.split('=')[1] === 'true') {
        queryParamObj = Object.assign(queryParamObj, { [key]: true })
      } else if (pair.split('=')[1] === 'false') {
        queryParamObj = Object.assign(queryParamObj, { [key]: false })
      } else {
        queryParamObj = Object.assign(queryParamObj, { [key]: pair.split('=')[1] })
      }
    })

    if (
      queryParamObj.brandCode !== undefined &&
      (urlObj.actionType === URL_MAP_ACTION_TYPE.PRODUCT || urlObj.actionType === URL_MAP_ACTION_TYPE.E_COUPON)
    ) {
      queryParamObj.productParent = 'BRAND'
    } else if (
      queryParamObj.categoryCode !== undefined &&
      (urlObj.actionType === URL_MAP_ACTION_TYPE.PRODUCT || urlObj.actionType === URL_MAP_ACTION_TYPE.E_COUPON)
    ) {
      queryParamObj.productParent = 'CATEGORY'
    }

    urlObj.parameters = queryParamObj
  }

  let queryParamStr = urlObj.url
    .replace(process.env.REACT_APP_QRCODE_LINK_START, '')
    .replace(process.env.REACT_APP_QRCODE_LINK_START.replace('https', 'http'), '')
    .replace('produt=', '')
    .replace('e-coupon=', '')
    .replace('ecoupon=', '')

  if (urlObj.agentType === 'weChat') {
    let weChatQueryParamObj = {}
    let weChatQueryParamStr = urlObj.url.replace(process.env.REACT_APP_QRCODE_LINK_WECHAT_START, '')

    const weChatKeyValuePairs = weChatQueryParamStr.split('&') || weChatQueryParamStr

    weChatKeyValuePairs.forEach((pair, idx) => {
      if (idx === 0) {
        weChatQueryParamObj = Object.assign(weChatQueryParamObj, { weChat: pair })
      } else {
        let key = pair.split('=')[0]
        let value = pair.split('=')[1]
        weChatQueryParamObj = Object.assign(weChatQueryParamObj, { [key]: value })
      }
    })
    urlObj.parameters = weChatQueryParamObj
  } else {
    switch (urlObj.actionType) {
      case URL_MAP_ACTION_TYPE.WEB_VIEW:
      case URL_MAP_ACTION_TYPE.VIDEO:
        assignUrlObjParameters(queryParamStr.replace('web=', ''))
        break
      case URL_MAP_ACTION_TYPE.SPRINGBOARD:
        urlObj.parameters = { action: queryParamStr.replace(process.env.REACT_APP_SPRINGBOARD, '') }
        break
      case URL_MAP_ACTION_TYPE.SEARCH:
        assignUrlObjParameters(queryParamStr.replace('search=', ''))
        break
      case URL_MAP_ACTION_TYPE.LINK:
        assignUrlObjParameters(queryParamStr.replace('link=', ''))
        break
      case URL_MAP_ACTION_TYPE.PIXEL:
        assignUrlObjParameters(queryParamStr.replace('pixel=', ''))
        break
      case URL_MAP_ACTION_TYPE.PRODUCT_BRAND:
      case URL_MAP_ACTION_TYPE.PRODUCT_CATEGORY:
      case URL_MAP_ACTION_TYPE.PRODUCT:
        assignUrlObjParameters(queryParamStr.replace('product=', ''))
        urlObj.mapValue = urlObj.parameters[urlObj.customFieldName]
        break
      case URL_MAP_ACTION_TYPE.E_COUPON:
      case URL_MAP_ACTION_TYPE.E_COUPON_BRAND:
      case URL_MAP_ACTION_TYPE.E_COUPON_CATEGORY:
        assignUrlObjParameters(queryParamStr.replace('e-coupon=', ''))
        urlObj.mapValue = urlObj.parameters[urlObj.customFieldName]
        break
      case URL_MAP_ACTION_TYPE.CATEGORY_PRODUCT:
      case URL_MAP_ACTION_TYPE.CATEGORY_PRODUCT_MAP:
      case URL_MAP_ACTION_TYPE.BRAND_PRODUCT:
      case URL_MAP_ACTION_TYPE.BRAND_PRODUCT_MAP:
        assignUrlObjParameters(queryParamStr.replace('product=', ''))
        urlObj.mapValue = urlObj.parameters.productCode
        break
      case URL_MAP_ACTION_TYPE.FOLDER:
        assignUrlObjParameters(queryParamStr.replace('redirect_folder=', ''))
        urlObj.mapValue = urlObj.parameters[urlObj.customFieldName]
        break
      case URL_MAP_ACTION_TYPE.REST:
        assignUrlObjParameters(queryParamStr.replace('redirect_rest=', ''), urlObj.actionType)
        urlObj.mapValue = urlObj.parameters[urlObj.customFieldName]
        break
      case URL_MAP_ACTION_TYPE.MY_TICKET:
        assignUrlObjParameters(queryParamStr.replace('myticket=', ''))
        urlObj.mapValue = urlObj.parameters[urlObj.customFieldName]
        break
      case URL_MAP_ACTION_TYPE.TRANSFORM_TICKET_FROM_PAPER:
        assignUrlObjParameters(queryParamStr.replace('transformticketfrompaper=', ''))
        urlObj.mapValue = urlObj.parameters[urlObj.customFieldName]
        break
      case URL_MAP_ACTION_TYPE.TRANSFORM_ECOUPON_FROM_PAPER:
        assignUrlObjParameters(queryParamStr.replace('transformecouponfrompaper=', ''))
        urlObj.mapValue = urlObj.parameters[urlObj.customFieldName]
        break
      case URL_MAP_ACTION_TYPE.EXPRESS_TICKET_VOUCHER:
        assignUrlObjParameters(queryParamStr.replace('expressticketvoucher=', ''))
        urlObj.mapValue = urlObj.parameters[urlObj.customFieldName]
        break
      case URL_MAP_ACTION_TYPE.SHARE:
        assignUrlObjParameters(queryParamStr.replace('share=', ''))
        urlObj.mapValue = urlObj.parameters[urlObj.customFieldName]
        break
      case URL_MAP_ACTION_TYPE.GIFT:
        assignUrlObjParameters(queryParamStr.replace('gift=', ''))
        urlObj.mapValue = urlObj.parameters[urlObj.customFieldName]
        break
      case URL_MAP_ACTION_TYPE.BOOKMARK:
        assignUrlObjParameters(queryParamStr.replace('bookmark=', ''))
        break
      case URL_MAP_ACTION_TYPE.SEARCH_NEAR_BY:
        assignUrlObjParameters(queryParamStr.replace('searchnearby=', ''))
        break
      case URL_MAP_ACTION_TYPE.SCAN_QRCODE:
        assignUrlObjParameters(queryParamStr.replace('scanqrcode=', ''))
        break
      case URL_MAP_ACTION_TYPE.LANDING:
        assignUrlObjParameters(queryParamStr.replace('landing=', ''))
        break
      case URL_MAP_ACTION_TYPE.DOWNLOAD:
        assignUrlObjParameters(queryParamStr.replace('download=', ''))
        break
      default:
        assignUrlObjParameters(queryParamStr)
        break
    }
  }

  return urlObj
}
