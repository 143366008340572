import { useState, useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";
import { sify } from "chinese-conv";

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityIcon from '@mui/icons-material/Visibility';
// import SplitPane from 'react-split-pane';
import { Allotment } from 'allotment'
import 'allotment/dist/style.css'
import { makeStyles } from '@mui/styles';

import { constants, PRINT_TEMPLATE_TYPE } from '../../Constants/constants';
import { getOne, post } from "../../helper/baseFetch";
import { setLoading } from "../../actions/systemAction";
import { setPrintTemplate, setPrintTemplateValue, setTemplateList, setTemplateLabelList, setTemplate, setTemplateLabel } from "../../actions/printTemplateAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempAutoSelectInput } from "../../Component/Input/TempAutoSelectInput";
import { TemplateAndTemplateLabelList } from "./TemplateAndTemplateLabelList";
import { Template } from "./Template";
import { TemplateLabel } from "./TemplateLabel";
import PrintTemplateValidation from "../../validations/printTemplateValidation";
import { printTemplateValidationConstants } from "../../Constants/validationConstants";
import { PrintTemplatePreview } from "./PrintTemplatePreview";
import { DialogWrapper } from "../../Component/DialogWrapper";
import { handleValue, handleDateFormate } from "./PrintTemplateFormat";

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const PrintTemplateCreate = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const params = useParams();
  const history = useHistory();
  const [clear, setClear] = useState(false);
  const [ready, setReady] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const savedPrintTemplate = useSelector(state => state.printTemplate.ui.printTemplate);
  const savedTemplateList = useSelector(state => state.printTemplate.ui.templateList);
  const savedTemplateLabelList = useSelector(state => state.printTemplate.ui.templateLabelList);
  const [, setErrorVersion] = useState(PrintTemplateValidation.getVersion());
  const helperTextStylesClasses = helperTextStyles();
  const resource = "PrintTemplate";
  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.PRINT_TEMPLATE_EDIT_TAB.INFO
    }
  ]

  const content = [
    {
      tab: <>
        <div>
          <TempTextInput
            inputSetting={{
              className: "edit-create-input",
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "code" },
              value: savedPrintTemplate.code,
              name: "code",
              helperTextIcon: true,
              helperText: PrintTemplateValidation.getErrorMessages(printTemplateValidationConstants.KEY_CODE),
              FormHelperTextProps: { className: helperTextStylesClasses.root },
            }}
            handleChange={onChangeInput}
          />
          <TempAutoSelectInput
            inputSetting={{
              className: "edit-create-input",
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "type" },
              value: savedPrintTemplate.type,
              type: constants.TYPE.SELECT,
              selectValue: constants.CHOICES.PRINT_TEMPLATE.TYPE,
              name: "type",
              disableClearable: true,
              helperTextIcon: true,
              helperText: PrintTemplateValidation.getErrorMessages(printTemplateValidationConstants.KEY_TYPE),
              FormHelperTextProps: { className: helperTextStylesClasses.root },
            }}
            handleChange={onChangeInput}
          />
        </div>
        <Allotment defaultSizes={[100, 200]}>
        {/* <SplitPane
          split="vertical"
          size={splitPane}
          style={{ position: 'initial' }}
          onDragFinished={(newSize) => {
            handlePanelSize(newSize);
          }}
        > */}
          <TemplateAndTemplateLabelList resource={resource} setSelectedType={setSelectedType} savedTemplateList={savedTemplateList} />
          <div className="queue-template-flex-right">
            {selectedType === "template" ?
              <Template selectedType={selectedType} />
              : selectedType === "templateLabel" ?
                <TemplateLabel selectedType={selectedType} />
                : <></>}
          </div>
        {/* </SplitPane> */}
        </Allotment>
      </>,
      btn: true,
    },
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />,
      disabled: !savedPrintTemplate || savedPrintTemplate.length === 0,
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    },
    {
      className: "special-button special-button-margin-left",
      variant: constants.STYLE.VARIANT.CONTAINED,
      icon: <VisibilityIcon />,
      label: { key: "preview" },
      onClick: handlePreviewDialog,
    },
  ]

  function onChangeInput(name, e) {
    dispatch(setPrintTemplateValue(name, savedPrintTemplate, "", e));
  }

  function handlePreviewDialog() {
    setDialog({
      open: true,
      onClose: handleClose,
      dialogContent: <PrintTemplatePreview />,
      dialogActions: [
        { label: { key: "backEdit" }, onClick: handleClose, icon: <ArrowBackIcon /> },
      ]
    })
  }

  const onClickBackToCreate = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  }

  const handleClose = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
  }

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.SHOP.QUEUE.PRINT_TEMPLATE);
  };

  function onClickSave() {
    PrintTemplateValidation.validatePrintTemp(savedPrintTemplate, savedTemplateList, savedTemplateLabelList);
    if (PrintTemplateValidation.isValid()) {
      let tempsavedPrintTemplate = _.cloneDeep(savedPrintTemplate);
      let tempsavedTemplateList = _.cloneDeep(savedTemplateList);
      let tempsavedTemplateLabelList = _.cloneDeep(savedTemplateLabelList);
      let tempTemplate = [];

      tempsavedTemplateList = tempsavedTemplateList.map((tempsavedTemplate) => {

        if(tempsavedTemplate.type === PRINT_TEMPLATE_TYPE.TERMS_AND_CONDITIONS) {
          tempsavedTemplate.type = PRINT_TEMPLATE_TYPE.PRINT_TEXT_AS_IMAGE;
        }

        if(tempsavedTemplate.type === PRINT_TEMPLATE_TYPE.LOGO
          || tempsavedTemplate.type === PRINT_TEMPLATE_TYPE.HEADER
          || tempsavedTemplate.type === PRINT_TEMPLATE_TYPE.FOOTER
          ) {
          tempsavedTemplate.type = PRINT_TEMPLATE_TYPE.PRINT_IMAGE;
        }

        if (typeof tempsavedTemplate.value === "object") {
          let newTemplate = "";
          tempsavedTemplate.value.map((template) => {
            if (tempsavedTemplate.type === PRINT_TEMPLATE_TYPE.DATE_FORMAT) {
              // if (template.type === "date") {
              //   template.value.map((value, index) => {
              //     newTemplate = newTemplate + value.value;
              //     if (template.value.length - 1 !== index) {
              //       if (value.value) {
              //         newTemplate = newTemplate + template.middleSymbol;
              //       }
              //     } else {
              //       if (template.middleSymbol) {
              //         newTemplate = newTemplate.slice(0, -1) + " ";
              //       } else {
              //         newTemplate = newTemplate + " ";
              //       }
              //     }
              //     return value;
              //   })
              if (template.type === "date") {
                template.value.map((value, index) => {
                  if (template.value.length - 1 !== index) {
                    // console.log('not last')
                    if (template.middleSymbol) {
                      newTemplate = newTemplate + value.value + template.middleSymbol
                    } else {
                      newTemplate = newTemplate + value.value
                    }
                  } else if (template.value.length - 1 === index) {
                    // console.log('last')
                    newTemplate = newTemplate + value.value + ' '
                  }
                  return value;
                })
              } else if (template.type === "time") {
                template.value.map((value, index) => {
                  if (template.bracket) {
                    if (index === 0) {
                      newTemplate = newTemplate + "(" + value.value;
                      if (value.value) {
                        newTemplate = newTemplate + ":";
                      }
                    } else if (index === template.value.length - 1) {
                      if (!value.value) {
                        newTemplate = newTemplate.substring(0, newTemplate.length - 1);
                      } else if (value.type === "timeFormat") {
                        if (newTemplate.charAt(newTemplate.length - 1) === ":") {
                          newTemplate = newTemplate.substring(0, newTemplate.length - 1);
                        }
                        newTemplate = newTemplate + " "
                      }
                      newTemplate = newTemplate + value.value + ") ";
                    } else if (value.type === "timeFormat") {
                      newTemplate = newTemplate.substring(0, newTemplate.length - 1);
                      newTemplate = newTemplate + value.value + " ";
                    } else {
                      newTemplate = newTemplate + value.value;
                      if (template.value.length - 1 !== index) {
                        if (value.value) {
                          newTemplate = newTemplate + ":";
                        }
                      } else {
                        newTemplate = newTemplate + " ";
                      }
                    }
                  } else {
                    if (value.value && value.type === "timeFormat") {
                      newTemplate = newTemplate.substring(0, newTemplate.length - 1);
                      newTemplate = newTemplate + " " + value.value + " ";
                    } else {
                      newTemplate = newTemplate + value.value;
                      if (template.value.length - 1 !== index) {
                        if (value.value) {
                          newTemplate = newTemplate + ":";
                        }
                      } else {
                        newTemplate = newTemplate.substring(0, newTemplate.length - 1);
                        newTemplate = newTemplate + " ";
                      }
                    }
                  }
                  return value;
                })
              } else {
                template.value.map((value) => {
                  if (value.value) {
                    newTemplate = newTemplate + value.value + " ";
                  }
                  return value;
                })
              }
            } else {
              if (template === "New Line") {
                template = "\n";
              }
              newTemplate = newTemplate + template;
            }
            return template;
          })
          tempsavedTemplate.value = newTemplate;
        }
        delete tempsavedTemplate.customId

        let tempTemplateValue = _.cloneDeep(tempsavedTemplate);
        if (tempTemplateValue.type === PRINT_TEMPLATE_TYPE.PRINT_TEXT_AS_IMAGE_NO_LINE_WRAP || tempTemplateValue.type === PRINT_TEMPLATE_TYPE.TERMS_AND_CONDITIONS) {
          tempTemplateValue.type = PRINT_TEMPLATE_TYPE.PRINT_TEXT_AS_IMAGE
        };
        if (tempTemplateValue.type !== PRINT_TEMPLATE_TYPE.LOCALE && tempTemplateValue.type !== PRINT_TEMPLATE_TYPE.LINE_FEED) {
          tempTemplate.push(tempTemplateValue);
        };
        return tempsavedTemplate;
      })
      tempsavedPrintTemplate.merchantTemplate = JSON.stringify(tempsavedTemplateList);
      tempsavedPrintTemplate.template = JSON.stringify(tempTemplate);

      let newTemplateLabel = {};
      tempsavedTemplateLabelList.map((tempsavedTemplateLabel) => {
        Object.assign(newTemplateLabel, {
          ["label_" + tempsavedTemplateLabel.labelTitle]: {
            EN: tempsavedTemplateLabel.labelEn,
            TC: tempsavedTemplateLabel.labelTc,
            SC: tempsavedTemplateLabel.labelSc,
          }
        })
        return tempsavedTemplateLabel;
      })
      tempsavedPrintTemplate.templateLabels = JSON.stringify(newTemplateLabel);
      post(constants.PATH.SHOP.QUEUE.PRINT_TEMPLATE, tempsavedPrintTemplate, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToCreate,
          dialogContentText: { key: "editSuccess" },
          dialogActions: [
            { label: { key: "backCreate" }, onClick: onClickBackToCreate, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        dispatch(setTemplateList([]))
        dispatch(setTemplateLabelList([]))
        dispatch(setTemplate({}))
        dispatch(setTemplateLabel({}))
        setClear(false)
      }, undefined, store);
      PrintTemplateValidation.resetErrorMessages();

    } else {
      setErrorVersion(PrintTemplateValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    dispatch(setLoading(true));
    if (params.id) {
      getOne(constants.PATH.SHOP.QUEUE.PRINT_TEMPLATE + "/" + params.id, undefined, (payload) => {
        delete payload.id;
        delete payload.code;
        delete payload.status;
        payload.template = payload.template.replaceAll("'", '"');
        payload.merchantTemplate = payload.merchantTemplate.replaceAll("'", '"');
        const tempTemplateLabelsList = JSON.parse(_.cloneDeep(payload.templateLabels));
        let newTemplateList = payload.merchantTemplate ? JSON.parse(payload.merchantTemplate) : [];

        newTemplateList = newTemplateList ? newTemplateList.map((template, templateIndex) => {
          return {
            customId: "Template_" + templateIndex,
            type: template.type,
            value:
              template.type === PRINT_TEMPLATE_TYPE.FONT_SIZE || template.type === PRINT_TEMPLATE_TYPE.ALIGNMENT || template.type === PRINT_TEMPLATE_TYPE.FONT_SIZE_DIRECT || template.type === PRINT_TEMPLATE_TYPE.LINE_FEED || template.type === PRINT_TEMPLATE_TYPE.FONT_STYLE || template.type === PRINT_TEMPLATE_TYPE.LOCALE
                ? template.value
                : template.type === PRINT_TEMPLATE_TYPE.DATE_FORMAT
                  ? handleDateFormate(template.value)
                  : handleValue(template.value)
          }
        }) : [];
        let newTemplateLabelsList = [];
        newTemplateLabelsList = tempTemplateLabelsList ? Object.keys(tempTemplateLabelsList).map((templateLabels, templateLabelsIndex) => {
          let newTemplateLabels = templateLabels.replace("label_", "");
          if ((!tempTemplateLabelsList[templateLabels].TC && !tempTemplateLabelsList[templateLabels].SC) || (tempTemplateLabelsList[templateLabels].TC && (sify(tempTemplateLabelsList[templateLabels].TC) === tempTemplateLabelsList[templateLabels].SC))) {
            tempTemplateLabelsList[templateLabels].disableScName = true;
          } else {
            tempTemplateLabelsList[templateLabels].disableScName = false;
          };
          return {
            customId: "Template_Label_" + templateLabelsIndex,
            labelTitle: newTemplateLabels,
            labelEn: tempTemplateLabelsList[templateLabels].EN,
            labelTc: tempTemplateLabelsList[templateLabels].TC,
            labelSc: tempTemplateLabelsList[templateLabels].SC,
            disableScName: tempTemplateLabelsList[templateLabels].disableScName,
          }
        }) : [];
        dispatch(setPrintTemplate(_.cloneDeep(payload)));
        dispatch(setTemplateList(newTemplateList))
        dispatch(setTemplateLabelList(newTemplateLabelsList))
        setReady(true)
        setClear(false)
      }, undefined, store);
    } else {
      let newPrintTemplate = _.cloneDeep(constants.PRINT_TEMPLATE_INPUT);
      let newTemplateList = _.cloneDeep(constants.TEMPLATE_LIST);
      let newTemplateLabelList = _.cloneDeep(constants.TEMPLATE_LABEL_LIST);
      let newTemplate = _.cloneDeep(constants.TEMPLATE_LIST_INPUT);
      let newTemplateLabel = _.cloneDeep(constants.TEMPLATE_LABEL_LIST_INPUT);
      dispatch(setPrintTemplate(newPrintTemplate));
      dispatch(setTemplateList(newTemplateList))
      dispatch(setTemplateLabelList(newTemplateLabelList))
      dispatch(setTemplate(newTemplate))
      dispatch(setTemplateLabel(newTemplateLabel))
      dispatch(setLoading(false));
      setClear(false)
      setReady(true)
    }
    PrintTemplateValidation.resetErrorMessages();
  }, [clear]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <>
      <EditCreateDisplay
        code={""}
        resource={resource}
        tabs={tabs}
        content={content}
        bottomBtns={bottomBtns}
        display="create"
      />
      {dialog && dialog.open &&
        <DialogWrapper dialogInfo={dialog} />
      }
    </>
  )
};