import { merchantInfoValidationConstants } from '../Constants/validationConstants';

class MerchantInfoValidation {

  version;
  errorMap;
  merchantInfoErrorMap;
  valid;

  constructor() {

    if (!MerchantInfoValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.merchantInfoErrorMap = new Map();
      MerchantInfoValidation.instance = this;
    }
    return MerchantInfoValidation.instance;
  }

  validateEditMerchantInfo(merchantInfo) {
    this.resetErrorMessages();
    this.handleValidateEditMerchantInfo(merchantInfo);
    this.version++;
  }

  validateCreateMerchantInfo(merchantInfo) {
    this.resetErrorMessages();
    this.handleValidateCreateMerchantInfo(merchantInfo);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.merchantInfoErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key, uniqueCode) {
    if (!uniqueCode) {
      return (this.merchantInfoErrorMap.has(key)) ? this.merchantInfoErrorMap.get(key) : [];
    }
  }

  handleValidateEditMerchantInfo(merchantInfo) {
    if (!merchantInfo.contactPerson) {
      this.setMerchantInfoErrorMapMessages(merchantInfoValidationConstants.KEY_CONTACT_PERSON, "Missing Contact Person");
    }
    if (!merchantInfo.contact) {
      this.setMerchantInfoErrorMapMessages(merchantInfoValidationConstants.KEY_CONTACT, "Missing Contact");
    }
    if (!merchantInfo.jobTitle) {
      this.setMerchantInfoErrorMapMessages(merchantInfoValidationConstants.KEY_JOB_TITLE, "Missing Job Title");
    }
    if (!merchantInfo.bankAccount) {
      this.setMerchantInfoErrorMapMessages(merchantInfoValidationConstants.KEY_BANK_ACCOUNT, "Missing Bank Account");
    }
    if (!merchantInfo.bankAccountName) {
      this.setMerchantInfoErrorMapMessages(merchantInfoValidationConstants.KEY_BANK_ACCOUNT_NAME, "Missing Bank Account Name");
    }
    if (!merchantInfo.bankCode) {
      this.setMerchantInfoErrorMapMessages(merchantInfoValidationConstants.KEY_BANK_CODE, "Missing Bank Code");
    }
    if (!merchantInfo.bankContactPerson) {
      this.setMerchantInfoErrorMapMessages(merchantInfoValidationConstants.KEY_BANK_CONTACT_PERSON, "Missing Bank Contact Person");
    }
    if (!merchantInfo.bankContact) {
      this.setMerchantInfoErrorMapMessages(merchantInfoValidationConstants.KEY_BANK_CONTACT, "Missing Bank Contact");
    }
  }

  handleValidateCreateMerchantInfo(merchantInfo) {
  }


  setMerchantInfoErrorMapMessages(key, errorMessages, valid = false) {
    this.merchantInfoErrorMap.set(key, errorMessages);
    // console.log("merchantInfoErrorMap", this.merchantInfoErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new MerchantInfoValidation();
export default validation;