import _ from "lodash";
import { MERCHANT_SHOP } from '../actions/types';
import { constants } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        merchantShop: _.cloneDeep(constants.MERCHANT_DRAFT),
        merchantShopStepError: [],
    }
}

const merchantShopReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MERCHANT_SHOP.SET_MERCHANT_SHOP_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case MERCHANT_SHOP.SET_MERCHANT_SHOP:
        case MERCHANT_SHOP.SET_MERCHANT_SHOP_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    merchantShop: action.merchantShop
                }
            })
        case MERCHANT_SHOP.SET_MERCHANT_SHOP_STEP_ERROR_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    merchantShopStepError: action.merchantShopStepError
                }
            })
        default:
            return state
    }
}

export default merchantShopReducer