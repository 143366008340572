import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { constants } from '../../Constants/constants'
import moment from 'moment'

import { makeStyles } from '@mui/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TablePagination from '@mui/material/TablePagination'
import TableSortLabel from '@mui/material/TableSortLabel'
import Checkbox from '@mui/material/Checkbox'

import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import FileCopyIcon from '@mui/icons-material/FileCopy'

import { ListFilter } from './ListFilter'
import { TempBtn } from '../Input/TempBtn'
import { getString, permissionsCheck } from '../../helper/util'

import '../../styles/uploadPayment.scss'
import { Box } from '@mui/material'

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: '65vh',
  },
  table: {
    minWidth: 650,
  },
  tableHeader: {
    minWidth: 200,
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'lightgrey',
    },
  },
  tableCell: {
    padding: '6px 24px 6px 16px',
    whiteSpace: 'pre-wrap',
  },
})

export const ListDisplay = ({
  page,
  rowsPerPage,
  rowsPerPageOptions,
  dataList,
  idName,
  sort,
  direction,
  listFilters,
  onChangeListFilters,
  handleFilterExtraChange,
  onChangeListPage,
  onChangeListRowsPerPage,
  listTitles,
  otherContent,
  resource,
  onChangeListSort,
  redirectPath,
  editBtn,
  btnFilter,
  createBtn,
  btnList,
  clonePath,
  newListMainTitle,
  mutipleSelect,
  hideMultiselectCheckbox,
  onMultiSelectList,
  previewSelectedIdList,
  selectedAllCheck,
  dialogFooterBtn,
  onClickListRow,
  selectMap,
  idParam,
  newTotal,
  hideBtnLabel,
  useRedirectPathStraight,
  redirectCreatePath,
  editRedirectPath,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const lang = useSelector((state) => state.lang.ui.lang)
  const theme = useSelector((state) => state.theme.ui.theme)
  const total = useSelector((state) => state.system.ui.total)

  const handleChangePage = (e, newPage) => {
    onChangeListPage(newPage)
  }

  const handleChangeRowsPerPage = (e) => {
    onChangeListRowsPerPage(parseInt(e.target.value))
  }

  const handleTableBodyDataShow = (title, data) => {
    switch (title.key) {
      case constants.LIST_DISPLAY_TYPE.ICON:
        return data[title.value] ? title.icon : null
      case constants.LIST_DISPLAY_TYPE.IMAGE:
        return data[title.value] ? <img alt={title.key} src={data[title.value]} width="200" /> : ''
      case constants.LIST_DISPLAY_TYPE.DATE_TIME:
        return data[title.value] ? moment(data[title.value]).format('YYYY-MM-DD HH:mm:ss') : ''
      case constants.LIST_DISPLAY_TYPE.DATE:
        return data[title.value] ? moment(data[title.value]).format('YYYY-MM-DD') : ''
      case constants.LIST_DISPLAY_TYPE.BOOLEAN:
        return data[title.value].toString()
      case constants.LIST_DISPLAY_TYPE.MEDIA:
        let content = ''
        if (data[title.type] === constants.FILE_TYPE.IMAGE) {
          content = data[title.value] ? <img alt={title.key} src={data[title.value]} width="200" /> : ''
        }
        if (data[title.type] === constants.FILE_TYPE.VIDEO) {
          content = data[title.value] ? (
            <video controls width="200">
              <source src={data[title.value]} type="video/mp4" />
            </video>
          ) : (
            ''
          )
        }
        return content
      default:
        if (title.hyperLink) {
          return <a href={data[`${title.value}HyperLink`]}>{data[title.value]}</a>
        }
        if (title.newTabHyperLink) {
          return (
            <a href={data[`${title.value}NewTabHyperLink`]} target="_blank" rel="noopener noreferrer">
              {data[title.value]}
            </a>
          )
        }
        return data[title.value]
    }
  }

  // useEffect(() => {
  //   window.scrollTo({ top: 0, behavior: 'smooth' });
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box sx={{ p: 3 }}>
      <HeaderButton
        newListMainTitle={newListMainTitle}
        resource={resource}
        redirectPath={redirectPath}
        idParam={idParam}
        createBtn={createBtn}
        lang={lang}
        theme={theme}
        useRedirectPathStraight={useRedirectPathStraight}
        redirectCreatePath={redirectCreatePath}
      />
      <ListFilter onChangeListFilters={onChangeListFilters} handleFilterExtraChange={handleFilterExtraChange} listFilters={listFilters} />
      <TableContainer component={Paper} sx={{ overflow: 'auto !important' }}>
        {listTitles ? (
          <Table aria-label="simple table" sx={{ overflow: 'auto !important' }}>
            <TableHead sx={{ position: 'sticky', top: 0, background: '#FFF', zIndex: 100 }}>
              <TableRow>
                {mutipleSelect && (
                  <TableCell>
                    <Checkbox onClick={() => onMultiSelectList('all')} checked={selectedAllCheck} />
                  </TableCell>
                )}
                {listTitles.map((item, index) => {
                  return (
                    <TableCell className={item.minSize ? classes.tableHeader : ''} key={index}>
                      {item.sort ? (
                        <TableSortLabel
                          onClick={(e) => onChangeListSort(item.value, e)}
                          active={item.value === sort}
                          direction={item.value === sort ? direction.toLowerCase() : 'desc'}
                        >
                          {getString(lang, resource, item.value, theme)}
                        </TableSortLabel>
                      ) : item.key === constants.LIST_DISPLAY_TYPE.ICON ? null : (
                        getString(lang, resource, item.value, theme)
                      )}
                    </TableCell>
                  )
                })}
                {editBtn !== false && <TableCell></TableCell>}
                {btnList &&
                  btnList.map((item, index) => {
                    return <TableCell key={index}></TableCell>
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataList.length > 0 ? (
                dataList.map((data, index) => {
                  return (
                    <TableRow className={onClickListRow && classes.tableRow} key={index} onClick={() => onClickListRow && onClickListRow(data)}>
                      {mutipleSelect && (
                        <MultiselectCheckBox
                          onMultiSelectList={onMultiSelectList}
                          data={data}
                          dataList={dataList}
                          idName={idName}
                          previewSelectedIdList={previewSelectedIdList}
                          hideMultiselectCheckbox={hideMultiselectCheckbox}
                          selectMap={selectMap}
                        />
                      )}
                      {listTitles.map((title, index) => {
                        return (
                          <TableCell className={classes.tableCell} key={title.value + '_' + data.id}>
                            {handleTableBodyDataShow(title, data)}
                          </TableCell>
                        )
                      })}
                      {!data.customizeBth && editBtn !== false ? (
                        (btnFilter === 'ProductDraft' &&
                          ((permissionsCheck(constants.PERMISSION_KEY.LIST_DISPLAY_SYSTEM_ADMIN) &&
                            (data.status === constants.PRODUCT_CREATE_STATUS.COMPLETE || data.draftVersion !== constants.DRAFT_VER)) ||
                            (permissionsCheck(constants.PERMISSION_KEY.LIST_DISPLAY_FINANCE) &&
                              (data.status !== constants.PRODUCT_CREATE_STATUS.TO_BE_APPROVE || data.status === constants.DRAFT_VER)))) ||
                        (btnFilter === 'PrintTemplate' && data.type !== 'NORMAL') ? (
                          <TableCell className="list-edit-btn"></TableCell>
                        ) : (
                          <TableCell className="list-edit-btn">
                            <TempBtn
                              btnSetting={{
                                onClick: () => {
                                  redirectPath === '/' + constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT + '/'
                                    ? history.push(`${redirectPath}${data.id}/0/0`)
                                    : editRedirectPath
                                    ? history.push(`${editRedirectPath}${data.id}/0`)
                                    : history.push(`${redirectPath}${data.id}/0`)
                                },
                                color: constants.STYLE.PRIMARY,
                                label: hideBtnLabel ? '' : { key: 'edit' },
                                icon: <EditIcon />,
                              }}
                            ></TempBtn>
                          </TableCell>
                        )
                      ) : (
                        <></>
                      )}
                      {!data.customizeBth && btnList && (
                        <ExtraButton btnList={btnList} btnFilter={btnFilter} data={data} clonePath={clonePath} history={history} />
                      )}
                      {data.customizeBth && (
                        <ExtraButton btnList={data.customizeBtnList} btnFilter={btnFilter} data={data} clonePath={clonePath} history={history} />
                      )}
                    </TableRow>
                  )
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={listTitles.length + 1}>No Data</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        ) : (
          otherContent
        )}
        <TablePagination
          component="div"
          count={newTotal ? Number(newTotal) : Number(total)}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ position: 'sticky', bottom: 0, background: '#FFF', zIndex: 100 }}
        />
      </TableContainer>
      {dialogFooterBtn}
    </Box>
  )
}

const HeaderButton = ({ resource, redirectPath, idParam, newListMainTitle, createBtn, lang, theme, useRedirectPathStraight, redirectCreatePath }) => {
  const history = useHistory()

  const redirect = () => {
    if (useRedirectPathStraight) {
      history.push(redirectCreatePath)
      return
    }

    history.push(`${redirectPath}create/${idParam ? idParam + '/' : ''}0`)
  }

  return (
    <div className="title">
      {newListMainTitle
        ? newListMainTitle === ' '
          ? ''
          : newListMainTitle.key
          ? getString(lang, newListMainTitle.resource, newListMainTitle.key, theme)
          : newListMainTitle
        : getString(lang, resource, 'mainTitle', theme)}{' '}
      {createBtn !== false && (
        <div className="create-btn">
          <TempBtn
            btnSetting={{
              onClick: redirect,
              color: constants.STYLE.PRIMARY,
              label: { key: 'create' },
              icon: <AddIcon />,
            }}
          ></TempBtn>
        </div>
      )}
    </div>
  )
}

const ExtraButton = ({ btnList, btnFilter, data, clonePath, history }) => {
  if (btnFilter === 'ProductDraft' && data.draftVersion !== constants.DRAFT_VER) {
    return <TableCell className="list-edit-btn"></TableCell>
  }

  return btnList.map((item, index) => {
    switch (item) {
      case 'productClone':
      case 'restaurantClone':
        return (
          <TableCell key={index} className="list-edit-btn">
            <TempBtn
              btnSetting={{
                onClick: () => {
                  history.push(`/${clonePath}${data.id}/0`)
                },
                color: constants.STYLE.PRIMARY,
                label: { key: 'Clone' },
                icon: <FileCopyIcon />,
              }}
            ></TempBtn>
          </TableCell>
        )
      case 'printTemplateClone':
        switch (data.type) {
          case 'NORMAL':
            return (
              <TableCell key={index} className="list-edit-btn">
                <TempBtn
                  btnSetting={{
                    onClick: () => {
                      history.push(`/${clonePath}${data.id}/0`)
                    },
                    color: constants.STYLE.PRIMARY,
                    label: { key: 'Clone' },
                    icon: <FileCopyIcon />,
                  }}
                ></TempBtn>
              </TableCell>
            )
          default:
            return <TableCell key={index} className="list-edit-btn"></TableCell>
        }
      default:
        return (
          <TableCell key={index} className="list-edit-btn">
            <TempBtn
              btnSetting={{
                onClick: () => item.handleChange(data.id, data),
                color: item.color,
                label: item.label,
                icon: item.icon,
              }}
            ></TempBtn>
          </TableCell>
        )
    }
  })
}

const MultiselectCheckBox = ({ onMultiSelectList, data, dataList, idName, previewSelectedIdList, hideMultiselectCheckbox, selectMap }) => {
  return (
    <TableCell>
      <Checkbox
        onClick={() => onMultiSelectList(data[`${idName}`], dataList, data)}
        checked={!!(previewSelectedIdList && previewSelectedIdList.includes(data[`${idName}`])) || !!(selectMap && selectMap.has(data[`${idName}`]))}
        disabled={hideMultiselectCheckbox ? hideMultiselectCheckbox(data) : false}
      />
    </TableCell>
  )
}
