import { useState, useEffect } from "react";
import { useStore } from "react-redux";

import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import { put } from "../../helper/baseFetch";
import { constants } from '../../Constants/constants';
import { TempBtn } from "../Input/TempBtn";
import { DialogWrapper } from "../DialogWrapper";

export const Deactivate = ({ record, handleResetData }) => {
  const store = useStore();
  const [deactivateDisable, setDeactivateDisable] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });

  const handleClose = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
  };

  const handleAdminDeviceDeactivateClose = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    handleResetData(true);
  };

  function handleConfirmDialog() {
    setDialog({
      open: true,
      onClose: () => handleClose(),
      dialogTitle: { resource: "AdminDevice", key: "activationCode" },
      dialogSubTitle: ": " + record.activationCode + " (" + record.deviceType + ") ",
      dialogContentText: { resource: "AdminDevice", key: "confirmDeactivate" },
      dialogActions: [
        { label: { key: "confirm" }, onClick: () => deactivateFunction(), icon: <CheckIcon /> },
        { label: { key: "cancelBtn" }, onClick: () => handleClose(), icon: <CloseIcon /> }
      ],
    });
  }

  function deactivateFunction() {
    put(constants.PATH.SHOP.ADMIN_DEVICE_DEACTIVATE + "/" + record.id, undefined, (payload) => {
      handleDialog(true);
    }, undefined, store);
  };

  function handleDialog() {
    setDialog({
      open: true,
      onClose: () => handleAdminDeviceDeactivateClose(),
      dialogTitle: { key: "dialogTitle" },
      dialogContentText: { resource: "AdminDevice", key: "deactivateSuccess" },
      dialogActions: [{ label: { key: "close" }, onClick: () => handleAdminDeviceDeactivateClose(), icon: <CloseIcon /> }],
    });
  }

  useEffect(() => {
    if (record.activationStatus === "P") {
      setDeactivateDisable(true);
    };
  }, [record.activationStatus]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <TempBtn btnSetting={{
        onClick: () => handleConfirmDialog(),
        variant: constants.STYLE.VARIANT.CONTAINED,
        color: constants.STYLE.PRIMARY,
        label: { resource: "AdminDevice", key: "deactivate" },
        icon: <DeleteIcon />,
        disabled: deactivateDisable,
      }}>
      </TempBtn>
      {dialog && dialog.open &&
        <DialogWrapper dialogInfo={dialog} />
      }
    </div>
  )
};