
import { useDispatch, useSelector } from "react-redux";

import CancelIcon from '@mui/icons-material/Cancel';

import { constants } from '../../../Constants/constants';
import { setMerchantShopValue } from "../../../actions/merchantShopCreateAction";
import { EditCreateDisplay } from '../../EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Input/TempTextInput";
import { TempCustomCheckBox } from "../../Custom/TempCustomCheckBox";
import { UploadImage } from "../../UploadImage/UploadImage";
import MerchantShopCreateValidation from "../../../validations/merchantShopCreateValidation";
import { merchantShopValidationConstants } from "../../../Constants/validationConstants";

export const Image = () => {
  const dispatch = useDispatch();
  const resource = "MerchantDraft";
  const savedMerchantShop = useSelector(state => state.merchantShop.ui.merchantShop);

  const tempRestaurantEditList = [
    {
      picture: savedMerchantShop.draft.brImage,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "br" },
      name: "brImage",
      type: constants.TYPE.IMAGE,
      mediaType: "REST_BUSINESS_REGISTRATION",
      uploadImagePath: constants.PATH.MERCHANT.UPLOAD_MERCHANT_IMAGE,
      saveImage: onChangeInput,
      isEndOfLine: false,
      required: true,
    },
  ]
  const content = [
    {
      tab: <>{tempRestaurantEditList.map((inputSetting, index) => {
        let content = "";
        switch (inputSetting.type) {
          case constants.TYPE.IMAGE:
            content =
              <div className="image">
                <UploadImage
                  mediaType={inputSetting.mediaType}
                  picture={inputSetting.picture}
                  title={inputSetting.label}
                  name={inputSetting.name}
                  uploadImagePath={inputSetting.uploadImagePath}
                  saveImage={inputSetting.saveImage}
                  type={inputSetting.imageType}
                  required={inputSetting.required} />
              </div>
            break;
          default:
            content = <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />;
        }
        return <div className="edit-display-wrapper" key={index}>
          {inputSetting.name === "brImage" &&
              <>
                {MerchantShopCreateValidation.getErrorMessages(merchantShopValidationConstants.KEY_BR_IMAGE) &&
                  <div className="error-message">
                    <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                    {MerchantShopCreateValidation.getErrorMessages(merchantShopValidationConstants.KEY_BR_IMAGE)}
                  </div>
                }
              </>
            }
          {inputSetting.custom && <TempCustomCheckBox className="customize-btn" value={inputSetting.customValue} customName={inputSetting.customName} />}
          {content}
          {inputSetting.isEndOfLine && <div></div>}
        </div>
      })}</>,
      btn: false
    },
  ]

  function onChangeInput(name, e) {
    dispatch(setMerchantShopValue("DRAFT", savedMerchantShop, name, e));
  }

  return (
    <>
      <EditCreateDisplay
        code=""
        resource={resource}
        tabs={false}
        content={content}
        onChangeInput={onChangeInput}
        display="none"
      />
    </>
  )
};
