import { TNC } from './types';
import { sify } from "chinese-conv";
import _ from "lodash";

export const setTnCDefault = () => {
    return {
        type: TNC.SET_TNC_DEFAULT,
    }
}

export const setTnC = (tnc) => {
    return {
        type: TNC.SET_TNC,
        tnc: tnc
    }
}

export const setTnCValue = (draftType, orignalProduct, key, value, index) => {
    const tnc = _.cloneDeep(orignalProduct);
    switch (draftType) {
        case "CHANGE_TC_DESCRIPTION_AND_SC_DESCRIPTION":
            if (value) {
                tnc.tcDescription = value;
                tnc.scDescription = sify(value);
            } else {
                tnc.tcDescription = null;
                tnc.scDescription = null;
            }
            break;
        case "scDescriptionDisable":
            tnc[draftType] = value;
            if(value) {
                tnc.scDescription = tnc.tcDescription? sify(tnc.tcDescription): tnc.tcDescription;
            }
            break;
        default:
            tnc[draftType] = value;
    }
    return {
        type: TNC.SET_TNC_VALUE,
        tnc: tnc
    }
}
