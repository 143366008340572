import { SYSTEM_USER } from '../Constants/constants';
import { systemUserValidationConstants } from '../Constants/validationConstants';
import { validateEmail } from '../helper/util';

class SystemUserValidation {

  version;
  errorMap;
  systemUserErrorMap;
  valid;

  constructor() {

    if (!SystemUserValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.systemUserErrorMap = new Map();
      SystemUserValidation.instance = this;
    }
    return SystemUserValidation.instance;
  }

  validateEditSystemUser(systemUser) {
    this.resetErrorMessages();
    this.handleValidateEditSystemUser(systemUser);
    this.version++;
  }

  validateCreateSystemUser(systemUser) {
    this.resetErrorMessages();
    this.handleValidateCreateSystemUser(systemUser);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.systemUserErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key) {
    return this.systemUserErrorMap.get(key)
  }

  handleValidateEditSystemUser(systemUser) {

    if (systemUser.email) {
      if(!validateEmail(systemUser.email)){
        this.setSystemUserErrorMapMessages(systemUserValidationConstants.KEY_EMAIL, "Invalid Email");
      }
    }
    if (!systemUser.userRole) {
      this.setSystemUserErrorMapMessages(systemUserValidationConstants.KEY_USER_ROLE, "Missing Role");
    }
    
    if (!systemUser.privilegeAttribute) {
      this.setSystemUserErrorMapMessages(systemUserValidationConstants.KEY_PRIVILEGE_ATTRIBUTE, "Privilege Attribute");
    }

    if (systemUser.privilegeAttribute && systemUser.privilegeAttribute === SYSTEM_USER.PRIVILEGE_ATTRIBUTE.S && !systemUser.restUrlId){
      this.setSystemUserErrorMapMessages(systemUserValidationConstants.KEY_REST_URL_ID, "Missing RestUrlId");
    } 

    if (!systemUser.status){
      this.setSystemUserErrorMapMessages(systemUserValidationConstants.KEY_STATUS, "Missing Status");
    } 
  }

  handleValidateCreateSystemUser(systemUser) {

    if (!systemUser.username){
      this.setSystemUserErrorMapMessages(systemUserValidationConstants.KEY_USERNAME, "Missing Username");
    } 

    if (!systemUser.password){
      this.setSystemUserErrorMapMessages(systemUserValidationConstants.KEY_PASSWORD, "Missing Password");
    } 

    if (systemUser.email) {
      if(!validateEmail(systemUser.email)){
        this.setSystemUserErrorMapMessages(systemUserValidationConstants.KEY_EMAIL, "Invalid Email");
      }
    }
    if (!systemUser.userRole) {
      this.setSystemUserErrorMapMessages(systemUserValidationConstants.KEY_USER_ROLE, "Missing Role");
    }
    
    if (!systemUser.privilegeAttribute) {
      this.setSystemUserErrorMapMessages(systemUserValidationConstants.KEY_PRIVILEGE_ATTRIBUTE, "Privilege Attribute");
    }

    if (systemUser.privilegeAttribute && systemUser.privilegeAttribute === SYSTEM_USER.PRIVILEGE_ATTRIBUTE.S && !systemUser.restUrlId){
      this.setSystemUserErrorMapMessages(systemUserValidationConstants.KEY_REST_URL_ID, "Missing RestUrlId");
    } 

  }

  setSystemUserErrorMapMessages(key, errorMessages, valid = false) {
    this.systemUserErrorMap.set(key, errorMessages);
    // console.log("brandErrorMap", this.brandErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new SystemUserValidation();
export default validation;