import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector, useStore } from 'react-redux'
import _ from 'lodash'
import { sify } from 'chinese-conv'

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import VisibilityIcon from '@mui/icons-material/Visibility'
// import SplitPane from 'react-split-pane';
import { Allotment } from 'allotment'
import 'allotment/dist/style.css'
import { makeStyles } from '@mui/styles'
import { Drawer } from '@mui/material'

import { constants, PRINT_TEMPLATE_TYPE } from '../../Constants/constants'
import { getOne, put, getList } from '../../helper/baseFetch'
import { generateTableCellToMultiLanguageName } from '../../helper/util'
import {
  setPrintTemplate,
  setPrintTemplateValue,
  setTemplateList,
  setTemplateLabelList,
  setTemplate,
  setTemplateLabel,
} from '../../actions/printTemplateAction'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { TempTextInput } from '../../Component/Input/TempTextInput'
import { TempAutoSelectInput } from '../../Component/Input/TempAutoSelectInput'
import { TemplateAndTemplateLabelList } from './TemplateAndTemplateLabelList'
import { Template } from './Template'
import { TemplateLabel } from './TemplateLabel'
import { TempBtn } from '../../Component/Input/TempBtn'
import PrintTemplateValidation from '../../validations/printTemplateValidation'
import { printTemplateValidationConstants } from '../../Constants/validationConstants'
import { PrintTemplatePreview } from './PrintTemplatePreview'
import { DialogWrapper } from '../../Component/DialogWrapper'
import { handleValue, handleDateFormate } from './PrintTemplateFormat'
import { RestaurantList } from '../Restaurant/RestaurantList'

const helperTextStyles = makeStyles((theme) => ({
  root: {
    color: 'red !important',
  },
}))

const initialParam = {
  path: '',
  page: 0,
  rowsPerPage: 10,
  sort: 'updateTimestamp',
  direction: 'DESC',
  count: 0,
}

export const PrintTemplateEdit = () => {
  const dispatch = useDispatch()
  const store = useStore()
  const params = useParams()
  const history = useHistory()
  const [ready, setReady] = useState(false)
  const [clear, setClear] = useState(true)
  const [selectedType, setSelectedType] = useState('')
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  const savedPrintTemplate = useSelector((state) => state.printTemplate.ui.printTemplate)
  const savedTemplateList = useSelector((state) => state.printTemplate.ui.templateList)
  const savedTemplateLabelList = useSelector((state) => state.printTemplate.ui.templateLabelList)
  const [, setErrorVersion] = useState(PrintTemplateValidation.getVersion())
  const helperTextStylesClasses = helperTextStyles()
  const [openDrawer, setOpenDrawer] = useState(false)
  const [addRestaurantMap, setAddRestaurantMap] = useState(new Map())
  const [initialRestaurantList, setInitialRestaurantList] = useState([])
  const resource = 'PrintTemplate'

  const onClickCloseDrawer = () => {
    setAddRestaurantMap(new Map())
    setOpenDrawer(false)
  }

  const content = [
    {
      tab: (
        <>
          <div>
            <TempTextInput
              inputSetting={{
                className: 'edit-create-input',
                size: constants.SIZE.SMALL,
                label: { resource: resource, key: 'code' },
                value: savedPrintTemplate.code,
                variant: constants.STYLE.VARIANT.STANDARD,
                disabled: true,
                name: 'code',
                helperTextIcon: true,
                helperText: PrintTemplateValidation.getErrorMessages(printTemplateValidationConstants.KEY_CODE),
                FormHelperTextProps: { className: helperTextStylesClasses.root },
              }}
              handleChange={onChangeInput}
            />
            <TempAutoSelectInput
              inputSetting={{
                className: 'edit-create-input',
                size: constants.SIZE.SMALL,
                label: { resource: resource, key: 'type' },
                value: savedPrintTemplate.type,
                type: constants.TYPE.SELECT,
                selectValue: constants.CHOICES.PRINT_TEMPLATE.TYPE,
                name: 'type',
                disableClearable: true,
                helperTextIcon: true,
                helperText: PrintTemplateValidation.getErrorMessages(printTemplateValidationConstants.KEY_TYPE),
                FormHelperTextProps: { className: helperTextStylesClasses.root },
              }}
              handleChange={onChangeInput}
            />
            <TempAutoSelectInput
              inputSetting={{
                className: 'edit-create-input',
                size: constants.SIZE.SMALL,
                label: { resource: resource, key: 'status' },
                value: savedPrintTemplate.status,
                type: constants.TYPE.SELECT,
                selectValue: constants.CHOICES.PRINT_TEMPLATE.STATUS,
                disableClearable: true,
                name: 'status',
              }}
              handleChange={onChangeInput}
            />
          </div>
          <Allotment defaultSizes={[100, 200]}>
            <TemplateAndTemplateLabelList resource={resource} setSelectedType={setSelectedType} savedTemplateList={savedTemplateList} />
            <div className="queue-template-flex-right">
              {selectedType === 'template' ? (
                <Template selectedType={selectedType} />
              ) : selectedType === 'templateLabel' ? (
                <TemplateLabel selectedType={selectedType} />
              ) : (
                <></>
              )}
            </div>
          </Allotment>
        </>
      ),
      btn: true,
    },
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'save' },
      onClick: handleSaveClick,
      className: 'margin-left-16',
      icon: <SaveIcon />,
      disabled: !savedPrintTemplate || savedPrintTemplate.length === 0,
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'clear' },
      disabled: false,
      onClick: onClickClear,
      className: 'margin-left-16',
      icon: <ClearIcon />,
    },
    {
      className: 'special-button special-button-margin-left',
      variant: constants.STYLE.VARIANT.CONTAINED,
      icon: <VisibilityIcon />,
      label: { key: 'preview' },
      onClick: handlePreviewDialog,
    },
  ]

  function onChangeInput(name, e) {
    let tempsavedPrintTemplate = _.cloneDeep(savedPrintTemplate)
    dispatch(setPrintTemplateValue(name, tempsavedPrintTemplate, '', e))
  }

  function handlePreviewDialog() {
    setDialog({
      open: true,
      onClose: handleClose,
      dialogContent: <PrintTemplatePreview />,
      dialogActions: [{ label: { key: 'backEdit' }, onClick: handleClose, icon: <ArrowBackIcon /> }],
    })
  }

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }

  const handleClose = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
  }

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push('/' + constants.PATH.SHOP.QUEUE.PRINT_TEMPLATE)
  }

  function handleSaveClick() {
    //open drawer let user apply template to selected shop
    getList(
      constants.PATH.SHOP.QUEUE.PRINT_TEMPLATE_MAP,
      {
        sort: { field: initialParam.sort, order: initialParam.direction },
        filter: { printTemplateCode: savedPrintTemplate.code },
        pagination: { page: initialParam.page + 1, perPage: initialParam.rowsPerPage },
      },
      (payload) => {
        payload.map((item) => {
          item.name = generateTableCellToMultiLanguageName(item.tcName, item.enName, item.scName)
          item.address = generateTableCellToMultiLanguageName(item.tcAddress, item.enAddress, item.scAddress)
          return item
        })
        setInitialRestaurantList(payload)

        if (!payload || payload.length === 0) {
          onClickSave()
          return
        }

        setOpenDrawer(true)
      },
      undefined,
      store
    )
  }

  function onClickSave(addAllRelatedShop) {
    PrintTemplateValidation.validatePrintTemp(savedPrintTemplate, savedTemplateList, savedTemplateLabelList)
    if (PrintTemplateValidation.isValid()) {
      let tempsavedPrintTemplate = _.cloneDeep(savedPrintTemplate)
      let tempsavedTemplateList = _.cloneDeep(savedTemplateList)
      let tempsavedTemplateLabelList = _.cloneDeep(savedTemplateLabelList)
      let tempTemplate = []

      tempsavedTemplateList = tempsavedTemplateList.map((tempsavedTemplate) => {
        if (tempsavedTemplate.type === PRINT_TEMPLATE_TYPE.TERMS_AND_CONDITIONS) {
          tempsavedTemplate.type = PRINT_TEMPLATE_TYPE.PRINT_TEXT_AS_IMAGE
        }

        if (
          tempsavedTemplate.type === PRINT_TEMPLATE_TYPE.LOGO ||
          tempsavedTemplate.type === PRINT_TEMPLATE_TYPE.HEADER ||
          tempsavedTemplate.type === PRINT_TEMPLATE_TYPE.FOOTER
        ) {
          tempsavedTemplate.type = PRINT_TEMPLATE_TYPE.PRINT_IMAGE
        }

        if (typeof tempsavedTemplate.value === 'object') {
          let newTemplate = ''
          tempsavedTemplate.value.map((template) => {
            if (tempsavedTemplate.type === PRINT_TEMPLATE_TYPE.DATE_FORMAT) {
              // if (template.type === "date") {
              //   template.value.map((value, index) => {
              //     newTemplate = newTemplate + value.value;
              //     if (template.value.length - 1 !== index) {
              //       if (value.value) {
              //         newTemplate = newTemplate + template.middleSymbol;
              //       }
              //     } else {
              //       if (template.middleSymbol) {
              //         newTemplate = newTemplate.slice(0, -1) + " ";
              //       } else {
              //         newTemplate = newTemplate + " ";
              //       }
              //     }
              //     // console.log('newTemplate', newTemplate)
              //     // console.log('value', value)
              //     return value;
              //   })
              if (template.type === "date") {
                // console.log('template', template)
                template.value.map((value, index) => {
                  if (template.value.length - 1 !== index) {
                    // console.log('not last')
                    if (template.middleSymbol) {
                      newTemplate = newTemplate + value.value + template.middleSymbol
                    } else {
                      newTemplate = newTemplate + value.value
                    }
                  } else if (template.value.length - 1 === index) {
                    // console.log('last')
                    newTemplate = newTemplate + value.value + ' '
                  }
                  return value;
                })
              } else if (template.type === 'time') {
                template.value.map((value, index) => {
                  if (template.bracket) {
                    if (index === 0) {
                      newTemplate = newTemplate + '(' + value.value
                      if (value.value) {
                        newTemplate = newTemplate + ':'
                      }
                    } else if (index === template.value.length - 1) {
                      if (!value.value) {
                        newTemplate = newTemplate.substring(0, newTemplate.length - 1)
                      } else if (value.type === 'timeFormat') {
                        if (newTemplate.charAt(newTemplate.length - 1) === ':') {
                          newTemplate = newTemplate.substring(0, newTemplate.length - 1)
                        }
                        newTemplate = newTemplate + ' '
                      }
                      newTemplate = newTemplate + value.value + ') '
                    } else if (value.type === 'timeFormat') {
                      newTemplate = newTemplate.substring(0, newTemplate.length - 1)
                      newTemplate = newTemplate + value.value + ' '
                    } else {
                      newTemplate = newTemplate + value.value
                      if (template.value.length - 1 !== index) {
                        if (value.value) {
                          newTemplate = newTemplate + ':'
                        }
                      } else {
                        newTemplate = newTemplate + ' '
                      }
                    }
                  } else {
                    if (value.value && value.type === 'timeFormat') {
                      newTemplate = newTemplate.substring(0, newTemplate.length - 1)
                      newTemplate = newTemplate + ' ' + value.value + ' '
                    } else {
                      newTemplate = newTemplate + value.value
                      if (template.value.length - 1 !== index) {
                        if (value.value) {
                          newTemplate = newTemplate + ':'
                        }
                      } else {
                        newTemplate = newTemplate.substring(0, newTemplate.length - 1)
                        newTemplate = newTemplate + ' '
                      }
                    }
                  }
                  return value
                })
              } else {
                template.value.map((value) => {
                  if (value.value) {
                    newTemplate = newTemplate + value.value + ' '
                  }
                  return value
                })
              }
            } else {
              if (template === 'New Line') {
                template = '\n'
              }
              newTemplate = newTemplate + template
            }
            return template
          })
          tempsavedTemplate.value = newTemplate
        }
        delete tempsavedTemplate.customId

        let tempTemplateValue = _.cloneDeep(tempsavedTemplate)
        if (
          tempTemplateValue.type === PRINT_TEMPLATE_TYPE.PRINT_TEXT_AS_IMAGE_NO_LINE_WRAP ||
          tempTemplateValue.type === PRINT_TEMPLATE_TYPE.TERMS_AND_CONDITIONS
        ) {
          tempTemplateValue.type = PRINT_TEMPLATE_TYPE.PRINT_TEXT_AS_IMAGE
        }
        if (tempTemplateValue.type !== PRINT_TEMPLATE_TYPE.LOCALE && tempTemplateValue.type !== PRINT_TEMPLATE_TYPE.LINE_FEED) {
          tempTemplate.push(tempTemplateValue)
        }
        return tempsavedTemplate
      })
      tempsavedPrintTemplate.merchantTemplate = JSON.stringify(tempsavedTemplateList)
      tempsavedPrintTemplate.template = JSON.stringify(tempTemplate)

      let newTemplateLabel = {}
      tempsavedTemplateLabelList.map((tempsavedTemplateLabel) => {
        Object.assign(newTemplateLabel, {
          ['label_' + tempsavedTemplateLabel.labelTitle]: {
            EN: tempsavedTemplateLabel.labelEn,
            TC: tempsavedTemplateLabel.labelTc,
            SC: tempsavedTemplateLabel.labelSc,
          },
        })
        return tempsavedTemplateLabel
      })
      tempsavedPrintTemplate.templateLabels = JSON.stringify(newTemplateLabel)

      PrintTemplateValidation.resetErrorMessages()
      tempsavedPrintTemplate.restUrlIdList = Array.from(addRestaurantMap, ([name, value]) => name)
      tempsavedPrintTemplate.updateAllRelatedShop = addAllRelatedShop

      put(
        constants.PATH.SHOP.QUEUE.PRINT_TEMPLATE + '/' + params.id,
        tempsavedPrintTemplate,
        (payload) => {
          setDialog({
            open: true,
            onClose: onClickBackToEdit,
            dialogContentText: { key: 'editSuccess' },
            dialogActions: [
              { label: { key: 'backEdit' }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
              { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
            ],
          })
          dispatch(setTemplateList([]))
          dispatch(setTemplateLabelList([]))
          dispatch(setTemplate({}))
          dispatch(setTemplateLabel({}))
          setClear(false)
        },
        undefined,
        store
      )
    } else {
      setErrorVersion(PrintTemplateValidation.getVersion())
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    if (clear === true) {
      getOne(
        constants.PATH.SHOP.QUEUE.PRINT_TEMPLATE + '/' + params.id,
        undefined,
        (payload) => {
          payload.template = payload.template
            ? payload.template.charAt(1) === ' '
              ? payload.template.replaceAll("\\\\'", '\\\\"').replace(/\n/g,'')
              : payload.template
            : '{}'
          payload.merchantTemplate = payload.merchantTemplate
            ? payload.merchantTemplate.charAt(1) === ' '
              ? payload.merchantTemplate.replaceAll("'", '"').replace(/\n/g,'')
              : payload.merchantTemplate
            : '{}'
          payload.templateLabels = payload.templateLabels
            ? payload.templateLabels.charAt(1) === ' '
              ? payload.templateLabels.replaceAll("\\\\'", '\\\\"').replace(/\n/g,'')
              : payload.templateLabels
            : '{}'

          const tempTemplateLabelsList = JSON.parse(_.cloneDeep(payload.templateLabels))
          let newTemplateList = payload.merchantTemplate ? JSON.parse(payload.merchantTemplate) : []

          newTemplateList = newTemplateList
            ? newTemplateList.map((template, templateIndex) => {
                return {
                  customId: 'Template_' + templateIndex,
                  type: getTemplateType(template.type, template.value),
                  value:
                    template.type === PRINT_TEMPLATE_TYPE.FONT_SIZE ||
                    template.type === PRINT_TEMPLATE_TYPE.ALIGNMENT ||
                    template.type === PRINT_TEMPLATE_TYPE.FONT_SIZE_DIRECT ||
                    template.type === PRINT_TEMPLATE_TYPE.LINE_FEED ||
                    template.type === PRINT_TEMPLATE_TYPE.FONT_STYLE ||
                    template.type === PRINT_TEMPLATE_TYPE.LOCALE
                      ? template.value
                      : template.type === PRINT_TEMPLATE_TYPE.DATE_FORMAT
                      ? handleDateFormate(template.value)
                      : handleValue(template.value),
                }
              })
            : []
          let newTemplateLabelsList = []
          newTemplateLabelsList = tempTemplateLabelsList
            ? Object.keys(tempTemplateLabelsList).map((templateLabels, templateLabelsIndex) => {
                let newTemplateLabels = templateLabels.replace('label_', '')
                if (
                  (!tempTemplateLabelsList[templateLabels].TC && !tempTemplateLabelsList[templateLabels].SC) ||
                  (tempTemplateLabelsList[templateLabels].TC &&
                    sify(tempTemplateLabelsList[templateLabels].TC) === tempTemplateLabelsList[templateLabels].SC)
                ) {
                  tempTemplateLabelsList[templateLabels].disableScName = true
                } else {
                  tempTemplateLabelsList[templateLabels].disableScName = false
                }
                return {
                  customId: 'Template_Label_' + templateLabelsIndex,
                  labelTitle: newTemplateLabels,
                  labelEn: tempTemplateLabelsList[templateLabels].EN,
                  labelTc: tempTemplateLabelsList[templateLabels].TC,
                  labelSc: tempTemplateLabelsList[templateLabels].SC,
                  disableScName: tempTemplateLabelsList[templateLabels].disableScName,
                }
              })
            : []
          dispatch(setPrintTemplate(_.cloneDeep(payload)))
          dispatch(setTemplateList(newTemplateList))
          dispatch(setTemplateLabelList(newTemplateLabelsList))
          setReady(true)
          setClear(false)
          setAddRestaurantMap(new Map())
        },
        undefined,
        store
      )
    }
    PrintTemplateValidation.resetErrorMessages()
  }, [clear]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleRestUrlId = () => {
    setOpenDrawer(false)
    onClickSave(false)
  }

  const handleAddAllRelatedShop = () => {
    setOpenDrawer(false)
    onClickSave(true)
  }

  // useEffect(() => {console.log('savedPrintTemplate', savedPrintTemplate)}, [savedPrintTemplate])
  // useEffect(() => {console.log('savedTemplateList', savedTemplateList)}, [savedTemplateList])
  // useEffect(() => {console.log('savedTemplateLabelList', savedTemplateLabelList)}, [savedTemplateLabelList])

  return (
    ready && (
      <>
        <EditCreateDisplay
          code={savedPrintTemplate && savedPrintTemplate.id ? savedPrintTemplate.id : ''}
          resource={resource}
          // tabs={tabs}
          content={content}
          bottomBtns={bottomBtns}
          display="edit"
        />
        <Drawer classes={{ paper: 'drawer-margin-left' }} anchor="right" open={openDrawer} onClose={onClickCloseDrawer}>
          <RestaurantList
            editBtn={false}
            createBtn={false}
            btnList={false}
            mutipleSelect={true}
            selectMap={addRestaurantMap}
            setSelectMap={setAddRestaurantMap}
            newPath={constants.PATH.SHOP.QUEUE.PRINT_TEMPLATE_MAP}
            initialList={initialRestaurantList}
            newFilter={
              addRestaurantMap && addRestaurantMap.size > 0
                ? {
                    printTemplateCode: savedPrintTemplate.code,
                    selected: { column: 'restUrlId', valueList: Array.from(addRestaurantMap, ([name, value]) => name) },
                  }
                : { printTemplateCode: savedPrintTemplate.code }
            }
            dialogFooterBtn={
              <div className="get-push-card-wrapper">
                <TempBtn
                  btnSetting={{
                    className: 'get-push-card-cancel-btn margin-right-16',
                    variant: constants.STYLE.VARIANT.CONTAINED,
                    color: constants.STYLE.PRIMARY,
                    onClick: handleRestUrlId,
                    icon: <SaveIcon />,
                    label: { key: 'submit' },
                  }}
                ></TempBtn>
                <TempBtn
                  btnSetting={{
                    className: 'get-push-card-cancel-btn margin-right-16',
                    variant: constants.STYLE.VARIANT.CONTAINED,
                    color: constants.STYLE.PRIMARY,
                    onClick: handleAddAllRelatedShop,
                    icon: <SaveIcon />,
                    label: { key: 'applyToAllRelatedShop' },
                  }}
                ></TempBtn>
                <TempBtn
                  btnSetting={{
                    className: 'get-push-card-cancel-btn margin-right-16',
                    variant: constants.STYLE.VARIANT.CONTAINED,
                    color: constants.STYLE.PRIMARY,
                    onClick: onClickCloseDrawer,
                    icon: <ClearIcon />,
                    label: { key: 'close' },
                  }}
                ></TempBtn>
              </div>
            }
          />
        </Drawer>
        {dialog && dialog.open && <DialogWrapper dialogInfo={dialog} />}
      </>
    )
  )
}

const getTemplateType = (type, value) => {
  if (type === PRINT_TEMPLATE_TYPE.PRINT_TEXT_AS_IMAGE) {
    value = handleValue(value)

    if (value.includes('${remarks}')) {
      return PRINT_TEMPLATE_TYPE.TERMS_AND_CONDITIONS
    }
  }

  if (type === PRINT_TEMPLATE_TYPE.PRINT_IMAGE) {
    if (value.includes('${logo}')) {
      return PRINT_TEMPLATE_TYPE.LOGO
    }

    if (value.includes('${header}')) {
      return PRINT_TEMPLATE_TYPE.HEADER
    }

    if (value.includes('${footer}')) {
      return PRINT_TEMPLATE_TYPE.FOOTER
    }
  }

  return type
}
