import { restaurantMediaValidationConstants } from '../Constants/validationConstants';

class RestaurantMediaValidation {

  version;
  errorMap;
  restaurantMediaErrorMap;
  valid;

  constructor() {

    if (!RestaurantMediaValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.restaurantMediaErrorMap = new Map();
      RestaurantMediaValidation.instance = this;
    }
    return RestaurantMediaValidation.instance;
  }

  validateEditRestaurantMedia(restaurantMedia) {
    this.resetErrorMessages();
    this.handleValidateEditRestaurantMedia(restaurantMedia);
    this.version++;
  }

  validateCreateRestaurantMedia(restaurantMedia) {
    this.resetErrorMessages();
    this.handleValidateCreateRestaurantMedia(restaurantMedia);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.restaurantMediaErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key) {
      return this.restaurantMediaErrorMap.get(key);
  }

  handleValidateEditRestaurantMedia(restaurantMedia) {
    if (!restaurantMedia.cosFileTcUrl) {
      this.setRestaurantMediaErrorMapMessages(restaurantMediaValidationConstants.KEY_COS_FILE_TC_URL, "Missing TC Image");
    }
    // if(!restaurantMedia.cosFileTcKey) {
    //   this.setRestaurantMediaErrorMapMessages(restaurantMediaValidationConstants.KEY_COS_FILE_TC_KEY, "Missing TC Image KEY");
    // }
    if (!restaurantMedia.cosFileEnUrl) {
      this.setRestaurantMediaErrorMapMessages(restaurantMediaValidationConstants.KEY_COS_FILE_EN_URL, "Missing EN Image");
    }
    // if(!restaurantMedia.cosFileEnKey) {
    //   this.setRestaurantMediaErrorMapMessages(restaurantMediaValidationConstants.KEY_COS_FILE_EN_KEY, "Missing EN Image KEY");
    // }
    if (!restaurantMedia.cosFileScUrl) {
      this.setRestaurantMediaErrorMapMessages(restaurantMediaValidationConstants.KEY_COS_FILE_SC_URL, "Missing SC Image");
    }
    // if(!restaurantMedia.cosFileScKey) {
    //   this.setRestaurantMediaErrorMapMessages(restaurantMediaValidationConstants.KEY_COS_FILE_SC_KEY, "Missing SC Image KEY");
    // }
    if (!restaurantMedia.fileType) {
      this.setRestaurantMediaErrorMapMessages(restaurantMediaValidationConstants.KEY_FILE_TYPE, "Missing File Type");
    }
    
    if (!restaurantMedia.displaySequence && restaurantMedia.displaySequence !== 0) {
      this.setRestaurantMediaErrorMapMessages(restaurantMediaValidationConstants.KEY_DISPLAY_SEQUENCE, "Missing Display Sequence");
    }
    if (!restaurantMedia.status) {
      this.setRestaurantMediaErrorMapMessages(restaurantMediaValidationConstants.KEY_STATUS, "Missing Status");
    }
    if (!restaurantMedia.mediaKey) {
      this.setRestaurantMediaErrorMapMessages(restaurantMediaValidationConstants.KEY_MEDIA_KEY, "Missing Media Key");
    }
    if (!restaurantMedia.type) {
      this.setRestaurantMediaErrorMapMessages(restaurantMediaValidationConstants.KEY_TYPE, "Missing Type");
    }
  }

  handleValidateCreateRestaurantMedia(restaurantMedia) {
    if (!restaurantMedia.mediaKey) {
      this.setRestaurantMediaErrorMapMessages(restaurantMediaValidationConstants.KEY_MEDIA_KEY, "Missing Media Key");
    }
    if (!restaurantMedia.cosFileTcUrl) {
      this.setRestaurantMediaErrorMapMessages(restaurantMediaValidationConstants.KEY_COS_FILE_TC_URL, "Missing TC Image");
    }
    if(!restaurantMedia.cosFileTcKey) {
      this.setRestaurantMediaErrorMapMessages(restaurantMediaValidationConstants.KEY_COS_FILE_TC_KEY, "Missing TC Image KEY");
    }
    if (!restaurantMedia.cosFileEnUrl) {
      this.setRestaurantMediaErrorMapMessages(restaurantMediaValidationConstants.KEY_COS_FILE_EN_URL, "Missing EN Image");
    }
    if(!restaurantMedia.cosFileEnKey) {
      this.setRestaurantMediaErrorMapMessages(restaurantMediaValidationConstants.KEY_COS_FILE_EN_KEY, "Missing EN Image KEY");
    }
    if (!restaurantMedia.cosFileScUrl) {
      this.setRestaurantMediaErrorMapMessages(restaurantMediaValidationConstants.KEY_COS_FILE_SC_URL, "Missing SC Image");
    }
    if(!restaurantMedia.cosFileScKey) {
      this.setRestaurantMediaErrorMapMessages(restaurantMediaValidationConstants.KEY_COS_FILE_SC_KEY, "Missing SC Image KEY");
    }
    if (!restaurantMedia.fileType) {
      this.setRestaurantMediaErrorMapMessages(restaurantMediaValidationConstants.KEY_FILE_TYPE, "Missing File Type");
    }
    if (!restaurantMedia.displaySequence && restaurantMedia.displaySequence !== 0) {
      this.setRestaurantMediaErrorMapMessages(restaurantMediaValidationConstants.KEY_DISPLAY_SEQUENCE, "Missing Display Sequence");
    }
    if (!restaurantMedia.type) {
      this.setRestaurantMediaErrorMapMessages(restaurantMediaValidationConstants.KEY_TYPE, "Missing Type");
    }
  }


  setRestaurantMediaErrorMapMessages(key, errorMessages, valid = false) {
    this.restaurantMediaErrorMap.set(key, errorMessages);
    // console.log("restaurantMediaErrorMap", this.restaurantMediaErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new RestaurantMediaValidation();
export default validation;