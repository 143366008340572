import { RESTAURANT_TAG_MAP } from './types';
import _ from "lodash";

export const setRestaurantTagMapDefault = () => {
    return {
        type: RESTAURANT_TAG_MAP.SET_RESTAURANT_TAG_MAP_DEFAULT,
    }
}

export const setRestaurantTagMap = (restaurantTagMap) => {
    return {
        type: RESTAURANT_TAG_MAP.SET_RESTAURANT_TAG_MAP,
        restaurantTagMap: restaurantTagMap
    }
}

export const setRestaurantTagMapValue = (draftType, orignalProduct, key, value, index) => {
    const restaurantTagMap = _.cloneDeep(orignalProduct);
    switch (draftType) {
        default:
            restaurantTagMap[draftType] = value;
    }
    return {
        type: RESTAURANT_TAG_MAP.SET_RESTAURANT_TAG_MAP_VALUE,
        restaurantTagMap: restaurantTagMap
    }
}