export const enMyMessages = {
  //CustomizenName
  nameCustomize: "Name (Auto Translate Name to SC)",

  resource: {
    restaurant: {
      tcName: "Name",
      tcSearchingName: "Searching Name",
      weChatTcName: "WeChat Name",
      tcAddress: "Address",
      tcTicketMessage: "Ticket Message",
      tcOpening: "Opening",
      tcLastOrderTime: "LastOrderTime",
      tcDescription: "Description",
      nameCustomize: "Name (Auto Translate Name to SC)",
      searchingNameCustomize: "Searching Name (Auto Translate Searching to SC)",
      ticketMessageCustomize: "Ticket Message (Auto Translate Ticket to SC)",
      openingCustomize: "Opening (Auto Translate Opening to SC)",
      lastOrderTimeCustomize: "Last Order Time (Auto Translate Last Order Time to SC)",
      descriptionCustomize: "Description (Auto Translate Description to SC)",
      addressCustomize: "Address (Auto Translate Address to SC)",
    },

    //RestaurantMedia
    RestaurantMedia: {
      cosFileTcUrl: "Image",
      tcImage: "Image",
    },

    TimeSection: {
      timeSectionTcLabel: "Time Section Label",
      isAutoTranslateTimeSectionLabel: "Time Section Label (Auto Translate Label to SC)",
    },

    TableType: {
      tableTypeTcLabel: "Table Type Label",
      isAutoTranslateTableTypeScLabel: "Table Type Label(Auto Translate Table Type Label to SC)",
      tableTypeMobileTcLabel: "Table Type Mobile Label",
      isAutoTranslateTableTypeMobileScLabel: "Table Type Mobile Label(Auto Translate Table Type Mobile Label to SC)",
      ticketTypeTcLabel: "Ticket Type Label",
    },

    TicketType: {
      tcName: "Name",
    },

    ReservationConfig: {
      remarksTcMessage: "Remarks Message",
      remarksMessageCustomize: "Remarks Message (Auto Translate Remarks Message to SC)",
      disableTcMessage: "Disable Message",
      disableMessageCustomize: "Disable Message (Auto Translate Disable Message to SC)",
      reminderTcMessage: "Reminder Message",
      reminderMessageCustomize: "Reminder Message (Auto Translate Reminder Message to SC)",
      noTimeSectionTcMessage: "No Time Section Message",
      noTimeSectionMessageCustomize: "No Time Section Message (Auto Translate No Time Section Message to SC)",
      chargeTcDescription: "Charge Description",
      chargeDescriptionCustomize: "Charge Description (Auto Translate Charge Description to SC)",
    },

    CampaignDescription: {
      tcTitle: "Title",
      tcMessage: "Message",
      titleCustomize: "Title (Auto Translate Title to SC)",
      messageCustomize: "Message (Auto Translate Message to SC)",
    },

    TnC: {
      tcDescription: "Description",
      descriptionCustomize: "Description (Auto Translate Description to SC)",
    },

    restaurant_map: {
      tcName: "Restaurant Name",
      restTcName: "Restaurant Name",
      nameCustomize: "Name (Auto Translate Name to SC)",
      tcPictures: "Pictures",
    },

    restaurant_category: {
      tcName: "Name",
      restTcName: "Restaurant Name",
      nameCustomize: "Name (Auto Translate Name to SC)",
      tcPictures: "Pictures",
    },

    restaurant_cuisine: {
      tcName: "Name",
      restTcName: "Restaurant Name",
      nameCustomize: "Name (Auto Translate Name to SC)",
      tcPictures: "Pictures",
    },

    restaurant_keyword: {
      tcName: "Name",
      restTcName: "Restaurant Name",
      nameCustomize: "Name (Auto Translate Name to SC)",
      tcPictures: "Pictures",
    },

    ConfigMap: {
      restTcName: "Restaurant Name",
    },

    Banner: {
      tcImage: "Banner Image",
    },

    Area: {
      tcName: "Area Name",
    },

    RestaurantDistrict: {
      tcName: "District Name",
      districtTcName: "District Name",
      areaTcName: "Area Name",
      nameCustomize: "Name (Auto Translate Name to SC)",
    },

    //RestaurantDistrictRestMap
    RestaurantDistrictRestMap: {
      restTcName: "Restaurant Name",
    },

    //RestaurantLandmark
    RestaurantLandmark: {
      tcName: "Landmark Name",
      landmarkTcName: "Landmark Name",
    },

    //RestaurantDistrictMap
    RestaurantDistrictMap: {
      tcName: "District Name",
    },

  }
}