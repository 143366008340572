import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import _ from "lodash";

import { Card } from '@mui/material';
import MTextField from '@mui/material/TextField';

import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';

import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';

import { setProductValue } from "../../../actions/productCreateAction";
import { constants } from '../../../Constants/constants';
import ProductCreateValidation from "../../../validations/productCreateValidation";
import { productValidationConstants } from '../../../Constants/validationConstants';
import { setLoading } from "../../../actions/systemAction";
import { TempAutoSelectInput } from "../../Input/TempAutoSelectInput";
import { TempTextInput } from "../../Input/TempTextInput";
import { TempBtn } from "../../Input/TempBtn";
import { getString } from "../../../helper/util";

const normalTextStyle = makeStyles(theme => ({
  label: {
    "& .MuiFormLabel-root":
    {
      color: "#121212"
    },
    "& .MuiOutlinedInput-notchedOutline":
    {
      borderColor: "#121212"
    },
    "& .MuiFormLabel-root.Mui-disabled":
    {
      color: "rgba(18, 18, 18, 0.54)",
    },
    "& .MuiIconButton-root":
    {
      color: "#3f51b5",
    },
  }
}));

export const ProductBlackoutPeriod = ({ hiddenInput, disableInput, selectableRedeemGroupRedeemLocationList, helperTextStylesClasses }) => {
  const dispatch = useDispatch();
  const savedProduct = useSelector(state => state.product.ui.product);
  const lang = useSelector(state => state.lang.ui.lang);
  const theme = useSelector(state => state.theme.ui.theme);
  const normalTextStyleClasses = normalTextStyle();

  const handleAddBlackoutPeriod = async () => {
    let tempCodeNumber = _.cloneDeep(savedProduct.draft.codeNumber);
    const tempBlackoutPeriodList = await processAddBlackoutPeriod(tempCodeNumber);
    let tempDraft = _.cloneDeep(savedProduct.draft);
    tempDraft.product.blackoutPeriodList = tempBlackoutPeriodList.tempBlackoutPeriodList;
    tempDraft.codeNumber = tempBlackoutPeriodList.tempCodeNumber + 1;
    dispatch(setProductValue("", savedProduct, "", tempDraft));
  }

  const processAddBlackoutPeriod = (tempCodeNumber) => {
    return new Promise((resolve) => {
      let tempBlackoutPeriodList = _.cloneDeep(savedProduct.draft.product.blackoutPeriodList);
      tempBlackoutPeriodList.push({
        blackoutPeriodValiCode: "Blackout_Period_" + Number(tempCodeNumber),
        refContent: "",
        type: "",
        startTimestamp: 0,
        endTimestamp: 0
      })
      tempCodeNumber = tempCodeNumber + 1;
      resolve({ tempBlackoutPeriodList: tempBlackoutPeriodList, tempCodeNumber: tempCodeNumber });
    })
  }

  const handleDeleteBlackoutPeriod = (newBlackoutPeriodIndex) => {
    dispatch(setLoading(true));
    let tempBlackoutPeriodList = _.cloneDeep(savedProduct.draft.product.blackoutPeriodList);
    tempBlackoutPeriodList.splice(newBlackoutPeriodIndex, 1);
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, "blackoutPeriodList", tempBlackoutPeriodList));
    dispatch(setLoading(false));
  }

  function handleRestUrlId(event, value, reason, blackoutPeriodListIndex) {
    let tempBlackoutPeriodList = _.cloneDeep(savedProduct.draft.product.blackoutPeriodList);
    tempBlackoutPeriodList.map((item, index) => {
      if (blackoutPeriodListIndex === index) {
        item.refContent = JSON.stringify(value)
      }
      return {};
    })
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, "blackoutPeriodList", tempBlackoutPeriodList));
  }

  function handleBlackoutPeriodListType(name, blackoutPeriodListType, blackoutPeriodListTypeIndex) {
    let tempBlackoutPeriodList = _.cloneDeep(savedProduct.draft.product.blackoutPeriodList);
    tempBlackoutPeriodList.map((item, index) => {
      if (blackoutPeriodListTypeIndex === index) {
        item.type = blackoutPeriodListType
      }
      return {};
    })
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, "blackoutPeriodList", tempBlackoutPeriodList));

  }

  function handleStartTimestamp(name, startTimestamp, startTimestampIndex) {
    let tempBlackoutPeriodList = _.cloneDeep(savedProduct.draft.product.blackoutPeriodList);
    tempBlackoutPeriodList.map((item, index) => {
      if (startTimestampIndex === index) {
        item.startTimestamp = Number(moment(startTimestamp).format("x"))
      }
      return {};
    })
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, "blackoutPeriodList", tempBlackoutPeriodList));
  }

  function handleEndTimestamp(name, endTimestamp, endTimestampIndex) {
    let tempBlackoutPeriodList = _.cloneDeep(savedProduct.draft.product.blackoutPeriodList);
    tempBlackoutPeriodList.map((item, index) => {
      if (endTimestampIndex === index) {
        item.endTimestamp = Number(moment(endTimestamp).format("x"))
      }
      return {};
    })
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, "blackoutPeriodList", tempBlackoutPeriodList));
  }

  function handleBlackoutPeriodListStatus(name, status, blackoutPeriodListTypeIndex) {
    let tempBlackoutPeriodList = _.cloneDeep(savedProduct.draft.product.blackoutPeriodList);
    tempBlackoutPeriodList.map((item, index) => {
      if (blackoutPeriodListTypeIndex === index) {
        item.status = status
      }
      return {};
    })
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, "blackoutPeriodList", tempBlackoutPeriodList));

  }

  const getBlackoutPeriodRestUrlIdOption = (option) => {
    const redeemLocation = selectableRedeemGroupRedeemLocationList.find((item) => { return item.restUrlId === option });
    return option + ' ' + ((redeemLocation) ? redeemLocation.restTcName : '');
  }

  return (
    <>
      {ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_BLACKOUT_PERIOD_LIST, savedProduct.draft.product.productCode).map((error) => { return error }) &&
        ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_BLACKOUT_PERIOD_LIST, savedProduct.draft.product.productCode).map((error) => { return error }).length > 0 &&
        <div className="error-message">
          <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
          {ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_BLACKOUT_PERIOD_LIST, savedProduct.draft.product.productCode).map((error) => { return error })}
        </div>
      }
      {!hiddenInput &&
        <TempBtn btnSetting={{
          className: "button-on-top",
          variant: constants.STYLE.VARIANT.CONTAINED,
          color: constants.STYLE.PRIMARY,
          onClick: handleAddBlackoutPeriod,
          label: { resource: "productCreateProductList", key: "addBlackoutPeriod" },
          icon: <AddIcon />,
          disabled: disableInput
        }} />
      }
      {savedProduct.draft.product.blackoutPeriodList.map((item, blackoutPeriodListIndex) => (
        <Card key={blackoutPeriodListIndex} className="image-list">
          {!item.id ?
            !hiddenInput &&
            <div className="deleteIcon">
              <TempBtn btnSetting={{
                className: "edit-create-btn",
                variant: constants.STYLE.VARIANT.CONTAINED,
                color: constants.STYLE.PRIMARY,
                onClick: () => handleDeleteBlackoutPeriod(blackoutPeriodListIndex),
                label: { key: "deleteBtn" },
                icon: <DeleteIcon />,
                disabled: disableInput,
              }} />
            </div> :
            disableInput ?
              <TempTextInput
                inputSetting={{
                  className: "edit-create-input",
                  variant: disableInput ? constants.STYLE.VARIANT.STANDARD : null,
                  size: constants.SIZE.SMALL,
                  label: { resource: "productCreateProductList", key: "status" },
                  value: item.status ? item.status : "",
                  disabled: disableInput,
                }}
                handleChange={handleBlackoutPeriodListStatus}
                index={blackoutPeriodListIndex}
              />
              :
              <TempAutoSelectInput
                inputSetting={{
                  className: "edit-create-input",
                  size: constants.SIZE.SMALL,
                  variant: constants.STYLE.VARIANT.OUTLINED,
                  type: constants.TYPE.SELECT,
                  selectValue: constants.CHOICES.PRODUCT_CREATE.CREATE_CATEGORY.STATUS,
                  label: { resource: "productCreateProductList", key: "status" },
                  value: item.status ? item.status : "",
                  disabled: disableInput,
                }}
                handleChange={handleBlackoutPeriodListStatus}
                index={blackoutPeriodListIndex}
              />
          }
          <div>{disableInput ?
            <TempTextInput
              inputSetting={{
                className: "edit-create-input",
                variant: disableInput ? constants.STYLE.VARIANT.STANDARD : null,
                size: constants.SIZE.SMALL,
                label: { resource: "productCreateProductList", key: "type" },
                value: item.type,
                disabled: disableInput,
                helperTextIcon: true,
                helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_BLACKOUT_PERIOD_LIST_TYPE, savedProduct.draft.product.productCode, item.blackoutPeriodValiCode).map((error) => { return error }),
                FormHelperTextProps: { className: helperTextStylesClasses.root },
              }}
              handleChange={handleBlackoutPeriodListType}
              index={blackoutPeriodListIndex}
            />
            :
            <TempAutoSelectInput
              inputSetting={{
                className: "edit-create-input",
                size: constants.SIZE.SMALL,
                variant: constants.STYLE.VARIANT.OUTLINED,
                type: constants.TYPE.SELECT,
                selectValue: constants.CHOICES.PRODUCT_CREATE.CREATE_PRODUCT.PRODUCT_BLACKOUT_PERIOD_TYPE,
                label: { resource: "productCreateProductList", key: "type" },
                value: item.type,
                disabled: disableInput,
                helperTextIcon: true,
                helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_BLACKOUT_PERIOD_LIST_TYPE, savedProduct.draft.product.productCode, item.blackoutPeriodValiCode).map((error) => { return error }),
                FormHelperTextProps: { className: helperTextStylesClasses.root },
              }}
              handleChange={handleBlackoutPeriodListType}
              index={blackoutPeriodListIndex}
            />
          }
          </div>
          <div>
            {item.type === "PICKUP_ITEM" &&
              disableInput ?
              <TempTextInput
                inputSetting={{
                  className: "edit-create-input",
                  variant: disableInput ? constants.STYLE.VARIANT.STANDARD : null,
                  size: constants.SIZE.SMALL,
                  label: { resource: "productCreateProductList", key: "restUrlId" },
                  value: item.refContent,
                  disabled: disableInput,
                }}
                handleChange={handleRestUrlId}
                index={blackoutPeriodListIndex}
              /> :
              <Autocomplete
                className="edit-create-input"
                size={constants.SIZE.SMALL}
                multiple
                options={selectableRedeemGroupRedeemLocationList.map((item) => item.restUrlId)}
                getOptionLabel={(option) => getBlackoutPeriodRestUrlIdOption(option)}
                filterSelectedOptions
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip key={index} variant="outlined" label={getBlackoutPeriodRestUrlIdOption(option)} {...getTagProps({ index })} />
                  ))
                }
                renderInput={(params) => (
                  <MTextField
                    {...params}
                    className={normalTextStyleClasses.label}
                    variant={constants.STYLE.VARIANT.OUTLINED}
                    label={getString(lang, "productCreateProductList", "restUrlId", theme)}
                  />
                )}
                onChange={(chip, value, reason) => handleRestUrlId(chip, value, reason, blackoutPeriodListIndex)}
                value={(item.refContent) ? JSON.parse(item.refContent) : []}
                disabled={disableInput}
              />
            }
            <TempTextInput
              inputSetting={{
                className: "edit-create-input",
                type: constants.TYPE.DATETIME_LOCAL,
                step: 1,
                size: constants.SIZE.SMALL,
                variant: disableInput ? constants.STYLE.VARIANT.STANDARD : null,
                label: { resource: "productCreateProductList", key: "startTimestamp" },
                value: item.startTimestamp,
                disabled: disableInput,
                helperTextIcon: true,
                helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_BLACKOUT_PERIOD_LIST_START_TIMESTAMP, savedProduct.draft.product.productCode, item.blackoutPeriodValiCode).map((error) => { return error }),
                FormHelperTextProps: { className: helperTextStylesClasses.root },
              }}
              handleChange={handleStartTimestamp}
              index={blackoutPeriodListIndex}
            />
            <TempTextInput
              inputSetting={{
                className: "edit-create-input",
                type: constants.TYPE.DATETIME_LOCAL,
                step: 1,
                size: constants.SIZE.SMALL,
                variant: disableInput ? constants.STYLE.VARIANT.STANDARD : null,
                label: { resource: "productCreateProductList", key: "endTimestamp" },
                value: item.endTimestamp,
                disabled: disableInput,
                helperTextIcon: true,
                helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_BLACKOUT_PERIOD_LIST_END_TIMESTAMP, savedProduct.draft.product.productCode, item.blackoutPeriodValiCode).map((error) => { return error }),
                FormHelperTextProps: { className: helperTextStylesClasses.root },
              }}
              handleChange={handleEndTimestamp}
              index={blackoutPeriodListIndex}
            />
          </div>
        </Card>
      ))}
    </>
  );
};