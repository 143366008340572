import { getContent } from "../../../Component/EditCreateDisplay/EditUtil";

export const createContentList = (displayList, onChangeInput, btnList) =>{
  return (
    [
      {
        tab: displayList.map((inputSetting, index) => {
          let content = getContent(inputSetting, index, onChangeInput);
          return <div className="edit-display-wrapper" key={index}>
            {content}
            {inputSetting.isEndOfLine && <div></div>}
          </div>
        }),
        btn: btnList
      },
    ]
  )
}