import { useState, useEffect } from "react";
import { useDispatch, useStore } from "react-redux";


import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import PublishIcon from "@mui/icons-material/Publish";

import { setLoading } from "../../../actions/systemAction";
import { constants } from "../../../Constants/constants";
import { TempBtn } from "../../Input/TempBtn";
import { uploadFile } from "../../../helper/baseFetch";
import { DialogWrapper } from "../../DialogWrapper";
import { DropzoneArea } from "mui-file-dropzone";

export const UploadRedeemCodeFile = ({ disabled, type, setFileKey, lotInventoryListIndex, subProductLotInventoryIndex }) => {
  const [popUpMessage, setPopUpMessage] = useState(" ");
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const dispatch = useDispatch();
  const store = useStore();
  const handleUploadFileClose = () => {
    setOpen(false);
  };

  const handleEmptyErrorDialogClose = () => {
    dispatch(setLoading(true));
    handleEmptyErrorDialog(false);
    setOpen(false);
    dispatch(setLoading(false));
  };


  const processFiles = (newFile) => {
    setOpen(false);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(newFile);
      reader.onload = function readerOnLoad(event) {
        resolve(event.target.result)
      }
    })
  }

  const handleFileContent = async (uri) => {
    return new Promise((resolve, reject) => {
      let firstRow = "";
      let lastRow = "";
      let tempFileContent = uri.split(/\r?\n/g).map((row, index) => {
        let empty = false;
        if (row === "") {
          empty = true
        }
        if (index === 0) {
          firstRow = row;
        } else if (index === uri.split(/\r?\n/g).length - 1) {
          lastRow = row;
        }
        return empty;
      })
      resolve({
        empty: tempFileContent.includes(true) ? true : false,
        firstRow: firstRow,
        lastRow: lastRow
      })
    })
  }

  const handleUploadFileConfirm = async () => {
    dispatch(setLoading(true));
    const uri = await processFiles(file[0]);
    const checkRow = await handleFileContent(uri);
    if (!checkRow.empty && uri.split(/\r?\n/g).length !== 0) {
      uploadFile(constants.PATH.PRODUCT.UPLOAD_REDEEM_CODE_FILE + "/", { redeemCodeFile: file[0] }, (payload) => {
        setFileKey(uri.split(/\r?\n/g).length, payload, lotInventoryListIndex, subProductLotInventoryIndex, checkRow.firstRow, checkRow.lastRow)
      }, undefined, store);
      dispatch(setLoading(false));
    } else {
      handleEmptyErrorDialog(true);
      setPopUpMessage("File contains Empty Row(s)!")
      dispatch(setLoading(false));
    }
  };
  function uploadFileDialogOpen() {
    setOpen(true);
  };
  function redeemUploadFile(e) {
    setFile(e);
  };

  function handleEmptyErrorDialog(open) {
    setDialog({
      open: open,
      onClose: handleEmptyErrorDialogClose,
      dialogTitle: { key: "dialogTitle" },
      dialogContent: popUpMessage,
      dialogActions: [
        { label: { key: "close" }, onClick: handleEmptyErrorDialogClose, icon: <CloseIcon /> },
      ],
    });
  }

  useEffect(() => {
    setDialog({
      open: open,
      onClose: handleUploadFileClose,
      dialogTitle: { key: "dialogTitle" },
      dialogContent: <DropzoneArea
        showPreviews={true}
        showPreviewsInDropzone={false}
        useChipsForPreview
        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
        previewText="Selected files"
        filesLimit={1}
        onChange={file => redeemUploadFile(file)}
      />,
      dialogActions:
        [
          { onClick: () => handleUploadFileConfirm(file), icon: <DoneIcon />, disabled: (file && file.length === 0) },
          { onClick: handleUploadFileClose, icon: <CloseIcon /> }
        ],
    });
  }, [open, file]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {type === "productLot" ?
        <>
          <TempBtn btnSetting={{
            variant: constants.STYLE.VARIANT.CONTAINED,
            color: constants.STYLE.PRIMARY,
            onClick: uploadFileDialogOpen,
            label: { resource: "productCreateRedeemGroupList", key: "uploadLotFile" },
            disabled: disabled,
            icon: <PublishIcon />
          }} />
        </>
        :
        <>
          <TempBtn btnSetting={{
            className: "import-btn",
            variant: constants.STYLE.VARIANT.CONTAINED,
            color: constants.STYLE.PRIMARY,
            onClick: uploadFileDialogOpen,
            label: { resource: "uploadPayment", key: "name" },
            icon: <PublishIcon />
          }} />
        </>
      }
      {dialog && dialog.open &&
        <DialogWrapper dialogInfo={dialog} />
      }
    </>
  )
};