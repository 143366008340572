import { useState } from "react";

import AddIcon from '@mui/icons-material/Add';
import { Drawer } from "@mui/material";

import { BrandCreate } from "../../Page/Brand/BrandCreate";
import { constants } from '../../Constants/constants';
import { TempBtn } from "../Input/TempBtn"

import "../../styles/getBrandCode.scss";

export const BrandCodeCreateBtn = ({ formData, disableInput }) => {
  const [showBrandButtonPanel, setShowBrandButtonPanel] = useState(false);

  const handleBrandButtonClick = () => {
    setShowBrandButtonPanel(true);
  };
  const handleBrandButtonCloseClick = () => {
    setShowBrandButtonPanel(false);
  };

  return (
    <>
      <TempBtn
        btnSetting={{
          color: constants.STYLE.PRIMARY,
          className: "brand-btn margin-16-4",
          label: { resource: "restaurant", key: "addBrandCode" },
          disabled: disableInput,
          onClick: handleBrandButtonClick,
          icon: <AddIcon />,
        }}
      />
      <Drawer
        classes={{ paper: "drawer-margin-left" }}
        anchor="right"
        open={showBrandButtonPanel}
        onClose={handleBrandButtonCloseClick}
      >
        <BrandCreate onCancel={handleBrandButtonCloseClick} />
      </Drawer>
    </>
  );
};