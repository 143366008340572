import { useState, useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import { constants as co, IMAGE_TYPE, PICTURE_SIZE, UPLOAD_TYPE } from '../../Constants/constants'
import { post } from '../../helper/baseFetch'
import { setTicketType, setTicketTypeValue } from '../../actions/ticketTypeAction'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { TempTextInput } from '../../Component/Input/TempTextInput'
import { TempCustomCheckBox } from '../../Component/Custom/TempCustomCheckBox'
import { DialogWrapper } from '../../Component/DialogWrapper'
import { QueueTemplateColorPicker } from '../QueueTemplate/QueueTemplateColorPicker'
import { UploadImage } from '../../Component/UploadImage/UploadImage'

const ticketTypeRegex = /^[A-Z_]{1,}$/

export const TicketTypeCreate = ({ newDialogBtn }) => {
  const dispatch = useDispatch()
  const store = useStore()
  const history = useHistory()
  const [clear, setClear] = useState(false)
  const [ticketTypeError, setTicketTypeError] = useState(null)
  const [submitError, setSubmitError] = useState(true)

  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  const resource = 'TicketType'
  const savedTicketType = useSelector((state) => state.ticketType.ui.ticketType)

  const tabs = [
    {
      label: { resource: resource, key: 'info' },
      tabIndex: co.TICKET_TYPE.INFO,
    },
  ]

  const fields = [
    {
      label: { resource: resource, key: 'ticketType' },
      value: savedTicketType.ticketType,
      name: 'ticketType',
      isEndOfLine: true,
      required: true,
      helperText: ticketTypeError,
    },
    {
      label: { resource: resource, key: 'enName' },
      value: savedTicketType.enName,
      name: 'enName',
      custom: true,
      customName: { key: 'nameCustomize' },
      customValue: savedTicketType.scNameDisable,
      isEndOfLine: false,
      required: true,
    },
    {
      label: { resource: resource, key: 'tcName' },
      value: savedTicketType.tcName,
      name: 'tcName',
      translateName: savedTicketType.scNameDisable ? ['scName'] : null,
      isEndOfLine: false,
      required: true,
    },
    {
      label: { resource: resource, key: 'scName' },
      value: savedTicketType.scName,
      name: 'scName',
      isEndOfLine: true,
      disabled: savedTicketType.scNameDisable,
      required: true,
    },
    {
      label: { resource: resource, key: 'foregroundColor' },
      value: savedTicketType.foregroundColor,
      name: 'foregroundColor',
      isEndOfLine: false,
      disabled: savedTicketType.foregroundColor,
      freeSolo: true,
      endAdornmentIconButton: (
        <QueueTemplateColorPicker color={savedTicketType.foregroundColor} setColor={(color) => onChangeInput('foregroundColor', color)} />
      ),
      onClickEndAdornmentIconButton: () => {},
      freeSoloUppercase: true,
      isColorFieldSpecialCase: true,
      clear: true,
    },
    {
      label: { resource: resource, key: 'backgroundColor' },
      value: savedTicketType.backgroundColor,
      name: 'backgroundColor',
      isEndOfLine: true,
      disabled: savedTicketType.backgroundColor,
      freeSolo: true,
      endAdornmentIconButton: (
        <QueueTemplateColorPicker color={savedTicketType.backgroundColor} setColor={(color) => onChangeInput('backgroundColor', color)} />
      ),
      onClickEndAdornmentIconButton: () => {},
      freeSoloUppercase: true,
      isColorFieldSpecialCase: true,
      clear: true,
    },
  ]

  const content = [
    {
      tab: (
        <>
          {fields.map((inputSetting, index) => {
            return (
              <div className="edit-display-wrapper" key={index}>
                {inputSetting.custom && (
                  <TempCustomCheckBox
                    className="customize-btn"
                    value={inputSetting.customValue}
                    customName={inputSetting.customName}
                    onSelectCheckbox={onSelectCheckbox}
                  />
                )}
                <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
                {inputSetting.isEndOfLine && <div></div>}
              </div>
            )
          })}
          <div className="image">
            <UploadImage
              type={IMAGE_TYPE.RESTAURANT_IMAGE}
              title={{ resource: resource, key: 'mobileImage' }}
              name="mobileImage"
              saveImage={(e) => {
                dispatch(setTicketTypeValue('mobileImage', savedTicketType, '', e.picture))
              }}
              picture={savedTicketType.mobileImage}
              uploadType={UPLOAD_TYPE.FORMDATA}
              uploadImagePath={co.PATH.SHOP.UPLOAD_RESTAURANT_MEDIA}
              size={PICTURE_SIZE.MID}
              allowedFileExtensionList={['image/jpeg', 'image/jpg']}
            />
          </div>
          <div className="image">
            <UploadImage
              type={IMAGE_TYPE.RESTAURANT_IMAGE}
              title={{ resource: resource, key: 'kioskImage' }}
              name="kioskImage"
              saveImage={(e) => {
                dispatch(setTicketTypeValue('kioskImage', savedTicketType, '', e.picture))
              }}
              picture={savedTicketType.kioskImage}
              uploadType={UPLOAD_TYPE.FORMDATA}
              uploadImagePath={co.PATH.SHOP.UPLOAD_RESTAURANT_MEDIA}
              size={PICTURE_SIZE.MID}
              allowedFileExtensionList={['image/jpeg', 'image/jpg']}
            />
          </div>
        </>
      ),
      btn: true,
    },
  ]

  const bottomBtns = [
    {
      variant: co.STYLE.VARIANT.CONTAINED,
      color: co.STYLE.PRIMARY,
      label: { key: 'save' },
      disabled: submitError,
      onClick: onClickSave,
      className: 'margin-left-16',
      icon: <SaveIcon />,
    },
    {
      variant: co.STYLE.VARIANT.CONTAINED,
      color: co.STYLE.PRIMARY,
      label: { key: 'clear' },
      disabled: false,
      onClick: onClickClear,
      className: 'margin-left-16',
      icon: <ClearIcon />,
    },
  ]

  function onSelectCheckbox(value) {
    dispatch(setTicketTypeValue('scNameDisable', savedTicketType, '', value))
  }

  const onClickBackToCreate = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push('/' + co.PATH.SHOP.QUEUE.TICKET_TYPE)
  }

  function onChangeInput(name, e) {
    if (savedTicketType.scNameDisable === true && name === 'tcName') {
      dispatch(setTicketTypeValue('CHANGE_TC_NAME_AND_SC_NAME', savedTicketType, '', e))
    } else {
      dispatch(setTicketTypeValue(name, savedTicketType, '', e))
    }
  }

  function onClickSave() {
    post(
      co.PATH.SHOP.QUEUE.TICKET_TYPE,
      savedTicketType,
      () => {
        setDialog({
          open: true,
          onClose: onClickBackToCreate,
          dialogContentText: { key: 'createSuccess' },
          dialogActions: newDialogBtn
            ? newDialogBtn
            : [
                { label: { key: 'backCreate' }, onClick: onClickBackToCreate, icon: <ArrowBackIcon /> },
                { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
              ],
        })
        setClear(false)
      },
      undefined,
      store
    )
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    let newTicketTypeInput = _.cloneDeep(co.TICKET_TYPE_INPUT)
    dispatch(setTicketType(newTicketTypeInput))
    setClear(false)
  }, [clear === true]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let newTicketTypeError = ''

    if (!ticketTypeRegex.test(savedTicketType.ticketType)) {
      newTicketTypeError = 'restUrlId includes invalid characters. Valid Character: A-Z and _'
    }

    setTicketTypeError(newTicketTypeError)

    const newSubmitError = !!(
      !Object.values({
        ticketType: savedTicketType.ticketType,
        enName: savedTicketType.enName,
        tcName: savedTicketType.tcName,
        scName: savedTicketType.scName,
      }).every((value) => value) || newTicketTypeError
    )

    setSubmitError(newSubmitError)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedTicketType])

  return (
    <>
      <EditCreateDisplay
        code=""
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        display="create"
      />
      {dialog && dialog.open && <DialogWrapper dialogInfo={dialog} />}
    </>
  )
}
