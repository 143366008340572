import { FOLDER_MAP } from './types';
import _ from "lodash";

export const setFolderMapDefault = () => {
    return {
        type: FOLDER_MAP.SET_FOLDER_MAP_DEFAULT,
    }
}

export const setFolderMap = (folderMap) => {
    return {
        type: FOLDER_MAP.SET_FOLDER_MAP,
        folderMap: folderMap
    }
}

export const setFolderMapValue = (draftType, orignalProduct, key, value, index) => {
    const folderMap = _.cloneDeep(orignalProduct);
    switch (draftType) {
        default:
            folderMap[draftType] = value;
    }
    return {
        type: FOLDER_MAP.SET_FOLDER_MAP_VALUE,
        folderMap: folderMap
    }
}