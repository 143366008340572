import { reservationConfigValidationConstants as validationCo } from '../Constants/validationConstants'
import { validateEmail } from '../helper/util'

class ReservationConfigValidation {
  version
  errorMap
  reservationConfigErrorMap
  valid

  constructor() {
    if (!ReservationConfigValidation.instance) {
      this.version = 0
      this.valid = true
      this.errorMap = new Map()
      this.reservationConfigErrorMap = new Map()
      ReservationConfigValidation.instance = this
    }
    return ReservationConfigValidation.instance
  }

  validateReservationConfig(reservationConfig) {
    this.resetErrorMessages()
    this.handleValidateReservationConfig(reservationConfig)
    this.version++
  }

  resetErrorMessages() {
    this.errorMap = new Map()
    this.reservationConfigErrorMap = new Map()
    this.valid = true
  }

  getVersion() {
    return this.version
  }

  isValid() {
    return this.valid
  }

  getErrorMessages(key, uniqueCode) {
    if (!uniqueCode) {
      return this.reservationConfigErrorMap.has(key) ? this.reservationConfigErrorMap.get(key) : []
    }
  }

  handleValidateReservationConfig(reservationConfig) {
    if (reservationConfig.defaultCharge && !parseFloat(reservationConfig.defaultCharge)) {
      this.setErrMsg(validationCo.KEY_DEFAULT_CHARGE, 'Please enter correct default charge format!')
    }
    if (reservationConfig.notificationEmail && !validateEmail(reservationConfig.notificationEmail)) {
      this.setErrMsg(validationCo.KEY_EMAIL, 'Please enter correct email format!')
    }
    if (reservationConfig.groupedSectionLimit && Number(reservationConfig.groupedSectionLimit) <= 0) {
      this.setErrMsg(validationCo.KEY_GROUPED_SECTION_LIMIT, 'Please insert positive number')
    }
  }

  setErrMsg(key, errorMessages, valid = false) {
    this.reservationConfigErrorMap.set(key, errorMessages)
    // console.log("reservationConfigErrorMap", this.reservationConfigErrorMap)
    if (!valid) {
      this.valid = false
    }
  }
}

const validation = new ReservationConfigValidation()
export default validation
