
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import CancelIcon from '@mui/icons-material/Cancel';

import { constants, IMAGE_TYPE } from '../../../Constants/constants';
import { setMerchantShopValue } from "../../../actions/merchantShopCreateAction";
import { EditCreateDisplay } from '../../EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Input/TempTextInput";
import { TempCustomCheckBox } from "../../Custom/TempCustomCheckBox";
import { getString, helperTextStyles } from "../../../helper/util";
import { TempBtn } from "../../Input/TempBtn";
import { UploadImage } from "../../UploadImage/UploadImage";
import { ImageList } from "../../UploadImage/ImageList";
import { GroupCode } from "./GroupCode";
import MerchantShopCreateValidation from "../../../validations/merchantShopCreateValidation";
import { merchantShopValidationConstants } from "../../../Constants/validationConstants";

export const ShopInfo = () => {
  const dispatch = useDispatch();
  const resource = "MerchantDraft";
  const savedMerchantShop = useSelector(state => state.merchantShop.ui.merchantShop);
  const [shopInputList, setShopInputList] = useState(_.cloneDeep(savedMerchantShop.draft));
  const lang = useSelector(state => state.lang.ui.lang);
  const theme = useSelector(state => state.theme.ui.theme);
  const helperTextStylesClasses = helperTextStyles();

  const tempShopEditList = [
    {
      type: constants.TYPE.TITLE,
      value: { resource: resource, key: "name" },
      className: "first-sub-title",
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "enName" },
      value: savedMerchantShop.draft.shopEnName,
      name: "shopEnName",
      custom: true,
      customName: { resource: resource, key: "nameCustomize" },
      customValue: savedMerchantShop.draft.scNameDisable,
      copyName: savedMerchantShop.draft.scSNameDisable ? ["enSName"] : null,
      inputList: shopInputList,
      setInputList: setShopInputList,
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: MerchantShopCreateValidation.getErrorMessages(merchantShopValidationConstants.KEY_SHOP_EN_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "tcName" },
      value: savedMerchantShop.draft.shopTcName,
      name: "shopTcName",
      translateName: savedMerchantShop.draft.scNameDisable ? ["shopScName"] : null,
      copyName: savedMerchantShop.draft.scSNameDisable ? ["tcSName"] : null,
      inputList: shopInputList,
      setInputList: setShopInputList,
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: MerchantShopCreateValidation.getErrorMessages(merchantShopValidationConstants.KEY_SHOP_TC_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "scName" },
      value: savedMerchantShop.draft.shopScName,
      name: "shopScName",
      inputList: shopInputList,
      setInputList: setShopInputList,
      isEndOfLine: true,
      disabled: savedMerchantShop.draft.scNameDisable,
      helperTextIcon: true,
      helperText: MerchantShopCreateValidation.getErrorMessages(merchantShopValidationConstants.KEY_SHOP_SC_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      type: constants.TYPE.TITLE,
      value: { resource: resource, key: "phoneAddress" },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "enAddress" },
      value: savedMerchantShop.draft.shopEnAddress,
      name: "shopEnAddress",
      custom: true,
      customName: { resource: resource, key: "addressCustomize" },
      customValue: savedMerchantShop.draft.scAddressDisable,
      multiline: true,
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: MerchantShopCreateValidation.getErrorMessages(merchantShopValidationConstants.KEY_ADDRESS_EN_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "tcAddress" },
      value: savedMerchantShop.draft.shopTcAddress,
      name: "shopTcAddress",
      translateName: savedMerchantShop.draft.scAddressDisable ? ["shopScAddress"] : null,
      inputList: shopInputList,
      setInputList: setShopInputList,
      multiline: true,
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: MerchantShopCreateValidation.getErrorMessages(merchantShopValidationConstants.KEY_ADDRESS_TC_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "scAddress" },
      value: savedMerchantShop.draft.shopScAddress,
      name: "shopScAddress",
      inputList: shopInputList,
      setInputList: setShopInputList,
      multiline: true,
      isEndOfLine: true,
      disabled: savedMerchantShop.draft.scAddressDisable,
      helperTextIcon: true,
      helperText: MerchantShopCreateValidation.getErrorMessages(merchantShopValidationConstants.KEY_ADDRESS_SC_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "phone" },
      value: savedMerchantShop.draft.shopContactNumber,
      name: "shopContactNumber",
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: MerchantShopCreateValidation.getErrorMessages(merchantShopValidationConstants.KEY_SHOP_CONTACT_NUMBER),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      type: constants.TYPE.TITLE,
      value: { resource: resource, key: "userGroupCode" },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      type: constants.TYPE.FUNCTION,
      value: <GroupCode />
    },
    {
      type: constants.TYPE.TITLE,
      value: { resource: resource, key: "shopLogo" },
    },
    {
      picture: savedMerchantShop.draft.shopLogo,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "shopLogo" },
      name: "shopLogo",
      mediaType: "REST_LOGO_IMAGE",
      type: constants.TYPE.IMAGE,
      uploadImagePath: constants.PATH.MERCHANT.UPLOAD_MERCHANT_IMAGE,
      saveImage: onChangeInput,
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: MerchantShopCreateValidation.getErrorMessages(merchantShopValidationConstants.KEY_SHOP_LOGO),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      type: constants.TYPE.TITLE,
      value: { resource: resource, key: "shopFrontImage" },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      name: "shopFrontImage",
      type: constants.TYPE.FUNCTION,
      value:
        <>
          <ImageList uploadPath={constants.PATH.MERCHANT.UPLOAD_MERCHANT_IMAGE} resource={resource} type={IMAGE_TYPE.MERCHANT_SHOP} mediaType="REST_SHOP_FRONT" savedData={savedMerchantShop} />
        </>,
      isEndOfLine: true,
      disabled: true,
    },
  ]

  const content = [
    {
      tab: <>{tempShopEditList.map((inputSetting, index) => {
        let content = "";
        switch (inputSetting.type) {
          case constants.TYPE.TITLE:
            content = <div className={`${inputSetting.className + " sub-title"}`}>{getString(lang, inputSetting.value.resource, inputSetting.value.key, theme)}</div>;
            break;
          case constants.TYPE.BUTTON:
            content = <TempBtn btnSetting={inputSetting} />
            break;
          case constants.TYPE.IMAGE:
            content =
              <div className="image">
                <UploadImage
                  mediaType={inputSetting.mediaType}
                  picture={inputSetting.picture}
                  title={inputSetting.label}
                  name={inputSetting.name}
                  uploadImagePath={inputSetting.uploadImagePath}
                  saveImage={inputSetting.saveImage}
                  required={inputSetting.required} />
              </div>
            break;
          case constants.TYPE.FUNCTION:
            content = inputSetting.value;
            break;
          default:
            content = <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />;
        }
        return (
        <div className="edit-display-wrapper" key={index}>
          {inputSetting.name === "shopLogo" &&
            <>
              {MerchantShopCreateValidation.getErrorMessages(merchantShopValidationConstants.KEY_SHOP_LOGO) &&
                <div className="error-message">
                  <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                  {MerchantShopCreateValidation.getErrorMessages(merchantShopValidationConstants.KEY_SHOP_LOGO)}
                </div>
              }
            </>
          }
          {inputSetting.name === "shopFrontImage" &&
            <>
              {MerchantShopCreateValidation.getErrorMessages(merchantShopValidationConstants.KEY_SHOP_FRONT_IMAGE) &&
                <div className="error-message">
                  <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                  {MerchantShopCreateValidation.getErrorMessages(merchantShopValidationConstants.KEY_SHOP_FRONT_IMAGE)}
                </div>
              }
            </>
          }
          {inputSetting.custom && <TempCustomCheckBox className="customize-btn" value={inputSetting.customValue} customName={inputSetting.customName} onSelectCheckbox={onSelectCheckbox} />}
          {content}
          {inputSetting.isEndOfLine && <div></div>}
        </div>)
      })}</>,
      btn: false
    },
  ]

  function onSelectCheckbox(value, customName) {
    switch (customName.key) {
      case "nameCustomize":
        dispatch(setMerchantShopValue("DRAFT", savedMerchantShop, "scNameDisable", value));
        dispatch(setMerchantShopValue("CHANGE_TC_NAME_AND_SC_NAME", savedMerchantShop, "shopTcName", savedMerchantShop.shopTcName));
        break;
      case "addressCustomize":
        dispatch(setMerchantShopValue("DRAFT", savedMerchantShop, "scAddressDisable", value));
        dispatch(setMerchantShopValue("CHANGE_TC_ADDRESS_AND_SC_ADDRESS", savedMerchantShop, "shopTcAddress", savedMerchantShop.shopTcAddress));
        break;
      default:
    }
  }

  function onChangeInput(name, e) {
    if (name === "shopTcName" && savedMerchantShop.draft.scNameDisable === true) {
      dispatch(setMerchantShopValue("CHANGE_TC_NAME_AND_SC_NAME", savedMerchantShop, name, e));
    } else if (name === "shopTcAddress" && savedMerchantShop.draft.scAddressDisable === true) {
      dispatch(setMerchantShopValue("CHANGE_TC_ADDRESS_AND_SC_ADDRESS", savedMerchantShop, name, e));
    } else {
      dispatch(setMerchantShopValue("DRAFT", savedMerchantShop, name, e));
    }
  }

  return (
    <>
      <EditCreateDisplay
        code=""
        resource={resource}
        tabs={false}
        content={content}
        onChangeInput={onChangeInput}
        display="none"
      />
    </>
  )
};
