import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector, useStore } from "react-redux";
import moment from 'moment';
import _ from "lodash";
import { sify } from "chinese-conv";

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Drawer } from "@mui/material";
import Paper from '@mui/material/Paper';
import { Card } from '@mui/material';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import SendIcon from '@mui/icons-material/Send';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import { makeStyles } from '@mui/styles';

import { constants, BANNER_ACTION } from '../../Constants/constants';
import { getOne, put, post } from "../../helper/baseFetch";
import { setPush } from "../../actions/pushAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempAutoSelectInput } from "../../Component/Input/TempAutoSelectInput";
import { TempBtn } from "../../Component/Input/TempBtn"
import { TempCustomCheckBox } from "../../Component/Custom/TempCustomCheckBox";
import { getActionParameters } from "../../Component/ActionParameters/getActionParameters";
import { setParametersInputBox } from "../../Component/Banner/setParametersInputBox";
import { handleActionParameters } from "../../Component/ActionParameters/handleActionParameters";
import { FolderList } from "../Folder/FolderList";
import { RestaurantList } from "../Restaurant/RestaurantList";
import { ProductList } from "../../Component/ProductCreate/Product/ProductList";
import { ProductCategoryList } from "../../Component/ProductCreate/Category/ProductCategoryList";
import { setFilterParameters } from "./setFilterParameters"
import { handleFilterParameters } from "./handleFilterParameters"
import { getString, getScDisabled, formatMultipleSelectArray } from "../../helper/util";
import { DialogWrapper } from "../../Component/DialogWrapper";
import PushValidation from "../../validations/pushValidation";
import { pushValidationConstants } from '../../Constants/validationConstants';
import { BrandList } from "../Brand/BrandList";

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const PushEdit = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const params = useParams();
  const history = useHistory();
  const helperTextStylesClasses = helperTextStyles();
  const [clear, setClear] = useState(true);
  const [ready, setReady] = useState(false);
  const [drawerType, setDrawerType] = useState("");
  const [drawerIndex, setDrawerIndex] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [statusDisabled, setStatusDisabled] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const savedPush = useSelector(state => state.push.ui.push);
  const lang = useSelector(state => state.lang.ui.lang);
  const theme = useSelector(state => state.theme.ui.theme);
  const [pushInputList, setPushInputList] = useState(_.cloneDeep(savedPush[0]));
  const resource = "Push";
  const [, setErrorVersion] = useState(PushValidation.getVersion());

  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.PUSH_TAB.INFO
    }
  ]
  const content = [
    {
      tab: <>
        {savedPush && savedPush.length > 0 && savedPush.map((savedPushValue, savedPushIndex) => {
          const tempPushEditList = [
            {
              type: constants.TYPE.TITLE,
              value: { resource: resource, key: "name" },
              className: "first-sub-title",
            },
            {
              className: "edit-create-input",
              variant: statusDisabled ? constants.STYLE.VARIANT.STANDARD : null,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "name" },
              value: savedPushValue && savedPushValue.name,
              name: "name",
              isEndOfLine: true,
              disabled: statusDisabled,
              helperTextIcon: true,
              helperText: PushValidation.getErrorMessages(pushValidationConstants.KEY_NAME),
              FormHelperTextProps: { className: helperTextStylesClasses.root },
              required: true,
            },
            {
              className: "edit-create-input",
              variant: statusDisabled ? constants.STYLE.VARIANT.STANDARD : null,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "enMessageTitle" },
              value: savedPushValue && savedPushValue.enMessageTitle,
              name: "enMessageTitle",
              custom: true,
              customName: { resource: resource, key: "messageTitleCustomize" },
              customValue: savedPushValue.messageTitleScDisable,
              isEndOfLine: false,
              disabled: statusDisabled,
            },
            {
              className: "edit-create-input",
              variant: statusDisabled ? constants.STYLE.VARIANT.STANDARD : null,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "tcMessageTitle" },
              value: savedPushValue && savedPushValue.tcMessageTitle,
              name: "tcMessageTitle",
              translateName: savedPushValue.messageTitleScDisable ? ["scMessageTitle"] : null,
              inputList: pushInputList,
              setInputList: setPushInputList,
              isEndOfLine: false,
              disabled: statusDisabled,
            },
            {
              className: "edit-create-input",
              variant: statusDisabled ? constants.STYLE.VARIANT.STANDARD : null,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "scMessageTitle" },
              value: savedPushValue && savedPushValue.scMessageTitle,
              name: "scMessageTitle",
              inputList: pushInputList,
              setInputList: setPushInputList,
              isEndOfLine: true,
              disabled: statusDisabled ? statusDisabled : savedPushValue.messageTitleScDisable,
            },
            {
              className: "edit-create-input",
              variant: statusDisabled ? constants.STYLE.VARIANT.STANDARD : null,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "enMessage" },
              value: savedPushValue && savedPushValue.enMessage,
              name: "enMessage",
              custom: true,
              customName: { resource: resource, key: "messageCustomize" },
              customValue: savedPushValue.messageScDisable,
              isEndOfLine: false,
              disabled: statusDisabled,
              helperTextIcon: true,
              helperText: PushValidation.getErrorMessages(pushValidationConstants.KEY_EN_MESSAGE),
              FormHelperTextProps: { className: helperTextStylesClasses.root },
              required: true,
            },
            {
              className: "edit-create-input",
              variant: statusDisabled ? constants.STYLE.VARIANT.STANDARD : null,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "tcMessage" },
              value: savedPushValue && savedPushValue.tcMessage,
              name: "tcMessage",
              translateName: savedPushValue.messageScDisable ? ["scMessage"] : null,
              inputList: pushInputList,
              setInputList: setPushInputList,
              isEndOfLine: false,
              disabled: statusDisabled,
              helperTextIcon: true,
              helperText: PushValidation.getErrorMessages(pushValidationConstants.KEY_TC_MESSAGE),
              FormHelperTextProps: { className: helperTextStylesClasses.root },
              required: true,
            },
            {
              className: "edit-create-input",
              variant: statusDisabled ? constants.STYLE.VARIANT.STANDARD : null,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "scMessage" },
              value: savedPushValue && savedPushValue.scMessage,
              name: "scMessage",
              inputList: pushInputList,
              setInputList: setPushInputList,
              isEndOfLine: true,
              disabled: statusDisabled ? statusDisabled : savedPushValue.messageScDisable,
              helperTextIcon: true,
              helperText: PushValidation.getErrorMessages(pushValidationConstants.KEY_SC_MESSAGE),
              FormHelperTextProps: { className: helperTextStylesClasses.root },
              required: true,
            },
            {
              className: "edit-create-input",
              variant: constants.STYLE.VARIANT.STANDARD,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "status" },
              value: savedPushValue && savedPushValue.status,
              name: "status",
              isEndOfLine: false,
              disabled: true,
              disableClearable: true,
              required: true,
              helperTextIcon: true,
              helperText: PushValidation.getErrorMessages(pushValidationConstants.KEY_STATUS),
              FormHelperTextProps: { className: helperTextStylesClasses.root },
            },
            {
              className: "edit-create-input",
              variant: statusDisabled ? constants.STYLE.VARIANT.STANDARD : null,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "deliveryDate" },
              value: savedPushValue.deliveryDate,
              name: "deliveryDate",
              type: constants.TYPE.DATETIME_LOCAL,
              step: 1,
              isEndOfLine: true,
              disabled: statusDisabled,
              helperTextIcon: true,
              helperText: PushValidation.getErrorMessages(pushValidationConstants.KEY_DELIVERY_DATE),
              FormHelperTextProps: { className: helperTextStylesClasses.root },
              required: true,
            },
            {
              className: "edit-create-input",
              variant: statusDisabled ? constants.STYLE.VARIANT.STANDARD : null,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "inbox" },
              value: savedPushValue.inbox,
              type: statusDisabled ? "" : constants.TYPE.SELECT,
              selectValue: constants.CHOICES.PUSH.TO_INBOX,
              name: "inbox",
              isEndOfLine: false,
              disabled: statusDisabled,
              helperTextIcon: true,
              helperText: PushValidation.getErrorMessages(pushValidationConstants.KEY_INBOX),
              FormHelperTextProps: { className: helperTextStylesClasses.root },
              required: true,
            },
            {
              className: "edit-create-input",
              variant: statusDisabled ? constants.STYLE.VARIANT.STANDARD : null,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "notification" },
              value: savedPushValue.notification,
              type: statusDisabled ? "" : constants.TYPE.SELECT,
              selectValue: constants.CHOICES.PUSH.TO_NOTIF,
              name: "notification",
              isEndOfLine: false,
              disabled: statusDisabled,
              helperTextIcon: true,
              helperText: PushValidation.getErrorMessages(pushValidationConstants.KEY_NOTIFICATION),
              FormHelperTextProps: { className: helperTextStylesClasses.root },
              required: true,
            },
            {
              className: "edit-create-input",
              variant: statusDisabled ? constants.STYLE.VARIANT.STANDARD : null,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "systemMessage" },
              value: savedPushValue.systemMessage,
              type: statusDisabled ? "" : constants.TYPE.SELECT,
              selectValue: constants.CHOICES.PUSH.SYSTEM_MESSAGE,
              name: "systemMessage",
              isEndOfLine: true,
              disabled: statusDisabled,
              helperTextIcon: true,
              helperText: PushValidation.getErrorMessages(pushValidationConstants.KEY_SYSTEM_MESSAGE),
              FormHelperTextProps: { className: helperTextStylesClasses.root },
              required: true,
            },
            {
              type: constants.TYPE.TITLE,
              value: { resource: resource, key: "actionParameters" },
            },
            {
              className: `full-size-input ${savedPushValue.parametersHidden ? "display-none" : ""}`,
              variant: constants.STYLE.VARIANT.STANDARD,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "actionParameters" },
              value: savedPushValue.actionParameters,
              name: "actionParameters",
              multiline: true,
              isEndOfLine: false,
              disabled: true,
              required: true,
            },
            {
              className: "edit-create-input",
              variant: statusDisabled ? constants.STYLE.VARIANT.STANDARD : null,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "actionType" },
              value: savedPushValue.actionType,
              type: statusDisabled ? "" : constants.TYPE.SELECT,
              selectValue: constants.CHOICES.PUSH.ACTION_TYPE,
              name: "actionType",
              isEndOfLine: false,
              disabled: statusDisabled,
              helperTextIcon: true,
              helperText: PushValidation.getErrorMessages(pushValidationConstants.KEY_ACTION_TYPE),
              FormHelperTextProps: { className: helperTextStylesClasses.root },
              required: true,
            },
            {
              className: `edit-create-input ${savedPushValue.parametersHidden || savedPushValue.actionType === BANNER_ACTION.SEARCH ? "display-none" : ""}`,
              variant: statusDisabled ? constants.STYLE.VARIANT.STANDARD : null,
              size: constants.SIZE.SMALL,
              label: savedPushValue.inputName && { resource: resource, key: savedPushValue.inputName },
              value: savedPushValue.parameters && savedPushValue.parameters[savedPushValue.inputName],
              onClickEndAdornmentIconButton: savedPushValue.parametersDisable && !statusDisabled ? () => handleOnClickPushDrawer(savedPushValue.actionType, savedPushIndex) : null,
              name: "url",
              isEndOfLine: true,
              disabled: savedPushValue.parametersDisable,
              helperTextIcon: true,
              helperText: PushValidation.getErrorMessages(pushValidationConstants.KEY_PARAMETERS),
              FormHelperTextProps: { className: helperTextStylesClasses.root },
              required: true,
            },
            //restaurant
            {
              className: `edit-create-input ${savedPushValue.actionType !== BANNER_ACTION.RESTAURANT ? "display-none" : ""}`,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "action" },
              value: savedPushValue.parameters && savedPushValue.parameters["action"],
              type: constants.TYPE.SELECT,
              selectValue: constants.CHOICES.BANNER.RESTAURANT_ACTION,
              name: "action",
              isEndOfLine: false,
            },
            //PRODUCT && E_COUPON
            {
              className: `edit-create-input ${((savedPushValue.actionType !== BANNER_ACTION.PRODUCT && savedPushValue.actionType !== BANNER_ACTION.E_COUPON) || !savedPushValue.parameters[savedPushValue.inputName]) ? "display-none" : ""}`,
              variant: statusDisabled ? constants.STYLE.VARIANT.STANDARD : null,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "productParent" },
              value: savedPushValue.parameters?.productParent,
              name: "productParent",
              type: constants.TYPE.SELECT,
              selectValue: constants.CHOICES.BANNER.PRODUCT_PARENT_TYPE,
              isEndOfLine: false,
              required: false,
            },
            {
              className: `edit-create-input ${((savedPushValue.actionType !== BANNER_ACTION.PRODUCT && savedPushValue.actionType !== BANNER_ACTION.E_COUPON) || !savedPushValue.parameters?.productParent|| savedPushValue.parameters?.productParent!=="CATEGORY") ? "display-none" : ""}`,
              variant: statusDisabled ? constants.STYLE.VARIANT.STANDARD : null,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "categoryCode" },
              value: savedPushValue.parameters?.categoryCode,
              name: "categoryCode",
              //to-do
              onClickEndAdornmentIconButton: savedPushValue.parametersDisable ? () => handleOnClickPushDrawer(BANNER_ACTION.PRODUCT_COUPON_CATEGORY_MAP, savedPushIndex) : null,
              isEndOfLine: false,
              disabled: savedPushValue.parametersDisable,
              required: savedPushValue.parameters?.productParent?true:false,
              helperText: PushValidation.getErrorMessages(pushValidationConstants.KEY_CHILDCODE),
              FormHelperTextProps: { className: helperTextStylesClasses.root },
            },
            {
              className: `edit-create-input ${((savedPushValue.actionType !== BANNER_ACTION.PRODUCT && savedPushValue.actionType !== BANNER_ACTION.E_COUPON) || !savedPushValue.parameters?.productParent|| savedPushValue.parameters?.productParent!=="BRAND") ? "display-none" : ""}`,
              variant: statusDisabled ? constants.STYLE.VARIANT.STANDARD : null,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "brandCode" },
              value: savedPushValue.parameters?.brandCode,
              name: "brandCode",
              //to-do
              onClickEndAdornmentIconButton: savedPushValue.parametersDisable ? () => handleOnClickPushDrawer(BANNER_ACTION.PRODUCT_COUPON_BRAND_MAP, savedPushIndex) : null,
              isEndOfLine: false,
              disabled: savedPushValue.parametersDisable,
              required: savedPushValue.parameters?.productParent?true:false,
              helperText: PushValidation.getErrorMessages(pushValidationConstants.KEY_CHILDCODE),
              FormHelperTextProps: { className: helperTextStylesClasses.root },
            },
            // //pixel
            // {
            //   className: `edit-create-input ${savedPushValue.actionType !== BANNER_ACTION.PIXEL ? "display-none" : ""}`,
            //   variant: statusDisabled ? constants.STYLE.VARIANT.STANDARD : null,
            //   size: constants.SIZE.SMALL,
            //   label: { resource: resource, key: "pixelName" },
            //   value: savedPushValue.parameters && savedPushValue.parameters.name,
            //   name: "pixelName",
            //   isEndOfLine: false,
            //   disabled: statusDisabled,
            //   helperTextIcon: true,
            //   helperText: PushValidation.getErrorMessages(pushValidationConstants.KEY_PIXEL_NAME),
            //   FormHelperTextProps: { className: helperTextStylesClasses.root },
            //   required: true,
            // },
            // {
            //   className: `edit-create-input ${savedPushValue.actionType !== BANNER_ACTION.PIXEL ? "display-none" : ""}`,
            //   variant: statusDisabled ? constants.STYLE.VARIANT.STANDARD : null,
            //   size: constants.SIZE.SMALL,
            //   label: { resource: resource, key: "haveRef" },
            //   value: savedPushValue.parameters && savedPushValue.parameters.haveRef,
            //   type: statusDisabled ? "" : constants.TYPE.SELECT,
            //   selectValue: constants.CHOICES.BANNER.HAVE_REF,
            //   name: "haveRef",
            //   isEndOfLine: false,
            //   disabled: statusDisabled,
            //   helperTextIcon: true,
            //   helperText: PushValidation.getErrorMessages(pushValidationConstants.KEY_PIXEL_HAVE_REF),
            //   FormHelperTextProps: { className: helperTextStylesClasses.root },
            //   required: true,
            // },
            //folder
            {
              className: `edit-create-input ${savedPushValue.actionType !== BANNER_ACTION.FOLDER ? "display-none" : ""}`,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "folderType" },
              value: savedPushValue.parameters && savedPushValue.parameters["folderType"],
              type: constants.TYPE.SELECT,
              selectValue: constants.CHOICES.BANNER.FOLDER_TYPE,
              name: "folderType",
              isEndOfLine: false,
              // helperTextIcon: true,
              // helperText: PushValidation.getErrorMessages(pushValidationConstants.KEY_FOLDER_TYPE),
              // FormHelperTextProps: { className: helperTextStylesClasses.root },
              // required: true,
            },
            //search
            {
              className: `edit-create-input ${savedPushValue.actionType !== BANNER_ACTION.SEARCH ? "display-none" : ""}`,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "name" },
              value: savedPushValue.parameters && savedPushValue.parameters["name"],
              name: "name",
              isEndOfLine: false,
            },
            {
              className: `edit-create-input ${savedPushValue.actionType !== BANNER_ACTION.SEARCH ? "display-none" : ""}`,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "query" },
              value: savedPushValue.parameters && savedPushValue.parameters["query"],
              type: constants.TYPE.SELECT,
              selectValue: constants.CHOICES.BANNER.QUERY,
              name: "query",
              isEndOfLine: false,
            },
            //web-view
            {
              className: `edit-create-input ${savedPushValue.actionType !== BANNER_ACTION.WEB_VIEW ? "display-none" : ""}`,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "title" },
              value: savedPushValue.parameters && savedPushValue.parameters["title"],
              name: "title",
              clear: true,
              isEndOfLine: false
            },
            {
              className: `edit-create-input ${savedPushValue.actionType !== BANNER_ACTION.WEB_VIEW ? "display-none" : ""}`,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "type" },
              value: savedPushValue.parameters && savedPushValue.parameters.type,
              type: constants.TYPE.SELECT,
              selectValue: constants.CHOICES.URL_MAP.WEB_TYPE,
              name: "type",
              isEndOfLine: false,
            },
            {
              variant: constants.STYLE.VARIANT.CONTAINED,
              color: constants.STYLE.PRIMARY,
              label: { resource: resource, key: "addParameter" },
              onClick: () => handleAddParameter(savedPushIndex),
              name: "handleAddParameter",
              type: constants.TYPE.BUTTON,
              icon: <AddIcon />,
              isEndOfLine: true,
              disabled: statusDisabled,
              className: "edit-create-btn",
            },
            {
              type: constants.TYPE.TITLE,
              value: { resource: resource, key: "filterParameters" },
            },
            {
              className: "edit-create-input",
              variant: statusDisabled ? constants.STYLE.VARIANT.STANDARD : null,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "gender" },
              value: savedPushValue.gender,
              type: statusDisabled ? "" : constants.TYPE.SELECT,
              selectValue: constants.CHOICES.PARAMETER.GENDER,
              name: "gender",
              isEndOfLine: false,
              disabled: statusDisabled,
            },
            {
              className: "edit-create-input",
              variant: statusDisabled ? constants.STYLE.VARIANT.STANDARD : null,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "os" },
              value: savedPushValue.os,
              type: statusDisabled ? "" : constants.TYPE.SELECT,
              selectValue: constants.CHOICES.PARAMETER.OS,
              name: "os",
              isEndOfLine: false,
              disabled: statusDisabled,
            },
            {
              className: "edit-create-input",
              variant: statusDisabled ? constants.STYLE.VARIANT.STANDARD : null,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "ageGroupList" },
              value: formatMultipleSelectArray(savedPushValue.ageGroupList, constants.CHOICES.PARAMETER.AGE_GROUP),
              type: statusDisabled ? "" : constants.TYPE.SELECT,
              multiple: true,
              selectValue: constants.CHOICES.PARAMETER.AGE_GROUP,
              name: "ageGroupList",
              isEndOfLine: true,
              disabled: statusDisabled,
            },
            {
              variant: constants.STYLE.VARIANT.CONTAINED,
              color: constants.STYLE.PRIMARY,
              label: { resource: resource, key: "addMobile" },
              onClick: () => handleMobileList(savedPushIndex),
              name: "handleMobileList",
              type: constants.TYPE.BUTTON,
              icon: <AddIcon />,
              isEndOfLine: true,
              disabled: statusDisabled,
              className: "edit-create-btn",
            },
            {
              className: "edit-create-input",
              value: <>
                {getString(lang, resource, "total", theme) + ": " + savedPushValue.total}
                <TempBtn btnSetting={{
                  className: "margin-left-16",
                  variant: constants.STYLE.VARIANT.CONTAINED,
                  color: constants.STYLE.PRIMARY,
                  label: { resource: resource, key: "sqlButton" },
                  onClick: () => handleTotal(savedPushIndex),
                  name: "handleTotal",
                  icon: <VisibilityIcon />,
                  disabled: statusDisabled,
                }} />
              </>,
              name: "total",
              type: constants.TYPE.FUNCTION,
            },
          ]

          return <div key={savedPushIndex}>
            {tempPushEditList.map((inputSetting, index) => {
              let content;
              switch (inputSetting.type) {
                case constants.TYPE.SELECT:
                  content =
                    <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} index={savedPushIndex} />
                  break;
                case constants.TYPE.FUNCTION:
                  content = inputSetting.value
                  break;
                case constants.TYPE.BUTTON:
                  content =
                    <>
                      {
                        inputSetting.name === "handleMobileList" ?
                          <><TempBtn btnSetting={inputSetting} />
                            {savedPushValue.mobileList &&
                              savedPushValue.mobileList.map((item, mobileIndex) => {
                                let tempMobileList = [
                                  {
                                    className: `edit-create-input`,
                                    size: constants.SIZE.SMALL,
                                    label: { resource: resource, key: "mobileList" },
                                    value: item,
                                    name: "mobile",
                                    inputMode: 'numeric',
                                    pattern: '[0-9]+',
                                    type: constants.TYPE.NUMBER,
                                    isEndOfLine: false,
                                    // helperTextIcon: true,
                                    // helperText: !item.match("[0-9]+") && "Mobile should be in number only!",
                                    // FormHelperTextProps: { className: helperTextStylesClasses.root },
                                  },
                                ]
                                return <Card key={mobileIndex} className="card">
                                  {tempMobileList.map((tempMobile, index) => {
                                    return <TempTextInput key={index} inputSetting={tempMobile} handleChange={handleNewMobileChange} index={{ mobileIndex: mobileIndex, savedPushIndex: savedPushIndex }} />
                                  })}
                                  <div className="delete-icon-row">
                                    <TempBtn btnSetting={{
                                      className: "edit-create-btn",
                                      variant: constants.STYLE.VARIANT.CONTAINED,
                                      color: constants.STYLE.PRIMARY,
                                      onClick: () => handleMobileListDelete(mobileIndex, savedPushIndex),
                                      label: { key: "deleteBtn" },
                                      icon: <DeleteIcon />,
                                    }} />
                                  </div>
                                </Card>
                              })
                            }</>
                          :
                          <div className={savedPushValue.parametersHidden ? "display-none" : ""}>
                            <TempBtn btnSetting={inputSetting} />
                            {
                              savedPushValue.otherParameters.map((otherParameter, otherParameterIndex) => {
                                let tempParameterList = [
                                  {
                                    className: `edit-create-input`,
                                    size: constants.SIZE.SMALL,
                                    label: { resource: resource, key: "otherParameterName" },
                                    value: otherParameter.key,
                                    name: "key",
                                    isEndOfLine: false,
                                    // helperTextIcon: true,
                                    // helperText: !otherParameter.key && getString(lang, "", "pleaseEnter", theme),
                                    // FormHelperTextProps: { className: helperTextStylesClasses.root },
                                  },
                                  {
                                    className: `edit-create-input`,
                                    size: constants.SIZE.SMALL,
                                    label: { resource: resource, key: "otherParameterValue" },
                                    value: otherParameter.value,
                                    name: "value",
                                    isEndOfLine: false,
                                  },
                                ]
                                return <Card key={otherParameterIndex}>
                                  {tempParameterList.map((tempParameter, index) => {
                                    return <TempTextInput key={index} inputSetting={tempParameter} handleChange={handleNewParameterChange} index={{ otherParameterIndex: otherParameterIndex, savedPushIndex: savedPushIndex }} />
                                  })}
                                  <div className="delete-icon-row">
                                    <TempBtn btnSetting={{
                                      className: "edit-create-btn",
                                      variant: constants.STYLE.VARIANT.CONTAINED,
                                      color: constants.STYLE.PRIMARY,
                                      onClick: () => handleOtherParameterDelete(otherParameterIndex, savedPushIndex),
                                      label: { key: "deleteBtn" },
                                      icon: <DeleteIcon />,
                                    }} />
                                  </div>
                                </Card>
                              })
                            }
                          </div>
                      }
                    </>
                  break;
                case constants.TYPE.TITLE:
                  content = inputSetting.value ? <div className={`${inputSetting.className + " sub-title"}`}>{getString(lang, inputSetting.value.resource, inputSetting.value.key, theme)}</div> : <></>
                  break;
                default:
                  content =
                    <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} index={savedPushIndex} />
              }
              return <div className="edit-display-wrapper" key={index}>
                {inputSetting.custom && !statusDisabled && <TempCustomCheckBox className="customize-btn" value={inputSetting.customValue} customName={inputSetting.customName} onSelectCheckbox={onSelectCheckbox} index={savedPushIndex} />}
                {content}
                {inputSetting.isEndOfLine && <div></div>}
              </div>
            })}
          </div>
        })}
      </>,
      btn: statusDisabled ? false : true
    },
  ]

  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />,
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    },
    {
      className: "special-button special-button-margin-left",
      variant: constants.STYLE.VARIANT.CONTAINED,
      icon: savedPush && savedPush.length > 0 && savedPush[0].status === "draft" ? <SendIcon /> : <CancelScheduleSendIcon />,
      label: savedPush && savedPush.length > 0 && savedPush[0].status === "draft" ? { key: "publish" } : { key: "unpublish" },
      disabled: savedPush && savedPush.length > 0 && handleChangeDisable(),
      onClick: savedPush && savedPush.length > 0 && savedPush[0].status === "draft" ? () => checkDeliveryDate(savedPush) : handleChangeStatus,
    }
  ]

  function handleChangeDisable() {
    return (savedPush[0].inbox === false
      && savedPush[0].notification === false
      && savedPush[0].systemMessage === false)
      || ((savedPush[0].actionType === BANNER_ACTION.WEB_VIEW || savedPush[0].actionType === BANNER_ACTION.LINK) && !savedPush[0].parameters[savedPush[0].inputName].match("^https?://+?[-a-zA-Z0-9@:%_+.~#?&//=]+"))
      ? true
      : savedPush[0].change;
  }

  function handleMobileList(index, newSavedPush) {
    let tempsavedPush = _.cloneDeep(newSavedPush);
    tempsavedPush = tempsavedPush ? tempsavedPush : _.cloneDeep(savedPush);
    tempsavedPush.forEach((tempsavedPushValue, tempsavedPushIndex) => {
      if (index === tempsavedPushIndex) {
        let newMobileList = tempsavedPushValue.mobileList && tempsavedPushValue.mobileList ? _.cloneDeep(tempsavedPushValue.mobileList) : [];
        newMobileList.push("");
        tempsavedPushValue.mobileList = newMobileList;
        tempsavedPushValue.change = true;
      } else if (!index && index !== 0) {
        let newMobileList = tempsavedPushValue.testMobileList && tempsavedPushValue.testMobileList ? _.cloneDeep(tempsavedPushValue.testMobileList) : [];
        newMobileList.push("");
        tempsavedPushValue.testMobileList = newMobileList;
      }
    })
    dispatch(setPush(tempsavedPush));
    if (tempsavedPush[0].testMobileList) {
      handleTesingDialog(tempsavedPush);
    }
  }

  function handleNewMobileChange(name, e, index, newSavedPush) {
    let tempsavedPush = _.cloneDeep(newSavedPush);
    tempsavedPush = tempsavedPush ? tempsavedPush : _.cloneDeep(savedPush);
    tempsavedPush.forEach((tempsavedPushValue, tempsavedPushIndex) => {
      if (index.savedPushIndex === tempsavedPushIndex) {
        if (name === "mobile") {
          tempsavedPushValue.change = true;
          tempsavedPushValue.mobileList[index.mobileIndex] = e;
        } else if (name === "testMobile") {
          tempsavedPushValue.testMobileList[index.mobileIndex] = e;
        }
      }
    });
    dispatch(setPush(tempsavedPush));
    if (name === "testMobile") {
      handleTesingDialog(tempsavedPush);
    }
  }

  function handleMobileListDelete(deleteIndex, savedPushIndex, newSavedPush) {
    let tempsavedPush = _.cloneDeep(newSavedPush);
    tempsavedPush = tempsavedPush ? tempsavedPush : _.cloneDeep(savedPush);
    tempsavedPush.forEach((tempsavedPushValue, tempsavedPushIndex) => {
      if (tempsavedPushIndex === savedPushIndex) {
        tempsavedPushValue.change = true;
        tempsavedPushValue.mobileList = tempsavedPushValue.mobileList.filter((item, index) => index !== deleteIndex);
        tempsavedPushValue = handleParameters(tempsavedPushValue);
      } else if (!savedPushIndex && savedPushIndex !== 0 && tempsavedPushIndex === 0) {
        tempsavedPushValue.testMobileList = tempsavedPushValue.testMobileList.filter((item, index) => index !== deleteIndex);
        tempsavedPushValue = handleParameters(tempsavedPushValue);
      }
    })
    dispatch(setPush(tempsavedPush));
    if (tempsavedPush[0].testMobileList) {
      handleTesingDialog(tempsavedPush);
    }
  }

  function onSelectCheckbox(value, customName, index) {
    let tempPush = _.cloneDeep(savedPush);
    switch (customName.key) {
      case "messageTitleCustomize":
        tempPush[index].messageTitleScDisable = value;
        if(value) {
          tempPush[index].scMessageTitle = tempPush[index].tcMessageTitle? sify(tempPush[index].tcMessageTitle) : tempPush[index].tcMessageTitle;
        }
        break;
      case "messageCustomize":
        tempPush[index].messageScDisable = value;
        if(value) {
          tempPush[index].scMessage = tempPush[index].tcMessage? sify(tempPush[index].tcMessage) : tempPush[index].tcMessage;
        }
        break;
      default:
    }
    dispatch(setPush(tempPush));
  }

  let previewPushContent =
    <> {savedPush[0] ?
      <div className="font-color-black">
        <h5> {getString(lang, resource, "name", theme)}: <div className="font-color-blue">{savedPush[0].name}</div></h5>
        <h5> {getString(lang, resource, "enMessageTitle", theme)}: <div className="font-color-blue">{savedPush[0].enMessageTitle}</div></h5>
        <h5> {getString(lang, resource, "tcMessageTitle", theme)}: <div className="font-color-blue">{savedPush[0].tcMessageTitle}</div></h5>
        <h5> {getString(lang, resource, "scMessageTitle", theme)}: <div className="font-color-blue">{savedPush[0].scMessageTitle}</div></h5>
        <h5> {getString(lang, resource, "enMessage", theme)}: <div className="font-color-blue">{savedPush[0].enMessage}</div></h5>
        <h5> {getString(lang, resource, "tcMessage", theme)}: <div className="font-color-blue">{savedPush[0].tcMessage}</div></h5>
        <h5> {getString(lang, resource, "scMessage", theme)}: <div className="font-color-blue">{savedPush[0].scMessage}</div></h5>
        <h5> {getString(lang, resource, "status", theme)}: <div className="font-color-blue">{savedPush[0].status}</div></h5>
        <h5> {getString(lang, resource, "deliveryDate", theme)}: <div className="font-color-blue">{moment(savedPush[0].deliveryDate).format("DD-MM-YYYY HH:MM")}</div></h5>
        <h5> {getString(lang, resource, "inbox", theme)}: <div className="font-color-blue">{String(savedPush[0].inbox)}</div></h5>
        <h5> {getString(lang, resource, "notification", theme)}: <div className="font-color-blue">{String(savedPush[0].notification)}</div></h5>
        <h5> {getString(lang, resource, "systemMessage", theme)}: <div className="font-color-blue">{String(savedPush[0].systemMessage)}</div></h5>
        <h3> {getString(lang, resource, "actionParameters", theme)}</h3>
        <h5> {getString(lang, resource, "actionType", theme)}: <div className="font-color-blue">{savedPush[0].actionType}</div></h5>
        <h5> {getString(lang, resource, savedPush[0].inputName ? savedPush[0].inputName : "action", theme)}: <div className="font-color-blue">{savedPush[0].parameters[savedPush[0].inputName]}</div></h5>
        <h5> {getString(lang, resource, "productCode", theme)}: <div className="font-color-blue">{savedPush[0].parameters.catProductCode}</div></h5>
        <h5> {getString(lang, resource, "pixelName", theme)}: <div className="font-color-blue">{savedPush[0].parameters.pixelName}</div></h5>
        <h5> {getString(lang, resource, "haveRef", theme)}: <div className="font-color-blue">{(savedPush[0].parameters.haveRef || savedPush[0].parameters.haveRef === false) && String(savedPush[0].parameters.haveRef)}</div></h5>
        <h5> {getString(lang, resource, "folderType", theme)}: <div className="font-color-blue">{savedPush[0].parameters.folderType}</div></h5>
        <h5> {getString(lang, resource, "type", theme)}: <div className="font-color-blue">{savedPush[0].parameters.type}</div></h5>
        <h5> {getString(lang, resource, "title", theme)}: <div className="font-color-blue">{savedPush[0].parameters.title}</div></h5>
        <h5> {getString(lang, resource, "actionParameters", theme)}: <div className="font-color-blue">{savedPush[0].actionParameters}</div></h5>
        <h3> {getString(lang, resource, "filterParameters", theme)}</h3>
        <h5> {getString(lang, resource, "gender", theme)}: <div className="font-color-blue">{savedPush[0].gender}</div></h5>
        <h5> {getString(lang, resource, "os", theme)}: <div className="font-color-blue">{savedPush[0].os}</div></h5>
        <h5> {getString(lang, resource, "ageGroupList", theme)}: <div className="font-color-blue">{JSON.stringify(savedPush[0].ageGroupList)}</div></h5>
        <h5> {getString(lang, resource, "mobileList", theme)}: <div className="font-color-blue">{JSON.stringify(savedPush[0].mobileList)}</div></h5>
        <h3> {getString(lang, resource, "total", theme)}: <div className="font-color-blue">{savedPush[0].total}</div></h3>
      </div >
      : <></>
    }</>

  function handleTesingDialog(newSavePush) {
    let tempSavePush = _.cloneDeep(newSavePush);
    setDialog({
      open: true,
      onClose: handleClose,
      dialogContent: <>
        {previewPushContent}
        <TempBtn btnSetting={{
          variant: constants.STYLE.VARIANT.CONTAINED,
          color: constants.STYLE.PRIMARY,
          label: { resource: resource, key: "addMobile" },
          onClick: () => handleMobileList(null, tempSavePush),
          name: "handleMobileList",
          type: constants.TYPE.BUTTON,
          icon: <AddIcon />,
          isEndOfLine: true,
          disabled: statusDisabled,
          className: "edit-create-btn",
        }} />
        {tempSavePush[0] && tempSavePush[0].testMobileList && tempSavePush[0].testMobileList.map((item, mobileIndex) => {
          let tempMobileList = [
            {
              className: `full-size-input`,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "mobileList" },
              value: item,
              name: "testMobile",
              inputMode: 'numeric',
              pattern: '[0-9]+',
              type: constants.TYPE.NUMBER,
              isEndOfLine: false,
              // helperTextIcon: true,
              // helperText: !item.match("[0-9]+") && "Mobile should be in number only!",
              // FormHelperTextProps: { className: helperTextStylesClasses.root },
            },
          ]
          return <Card key={mobileIndex} className="card">
            {tempMobileList.map((tempMobile, index) => {
              return <TempTextInput key={index} inputSetting={tempMobile} handleChange={handleNewMobileChange} index={{ mobileIndex: mobileIndex, savedPushIndex: 0 }} dataList={tempSavePush} />
            })}
            <div className="delete-icon-row">
              <TempBtn btnSetting={{
                className: "edit-create-btn",
                variant: constants.STYLE.VARIANT.CONTAINED,
                color: constants.STYLE.PRIMARY,
                onClick: () => handleMobileListDelete(mobileIndex, null, tempSavePush),
                label: { key: "deleteBtn" },
                icon: <DeleteIcon />,
              }} />
            </div>
          </Card>
        })}
      </>,
      dialogActions: [
        {
          label: { resource: resource, key: "testing" },
          onClick: () => handleTesting(tempSavePush),
          icon: <SlideshowIcon />,
          disabled: !tempSavePush[0].testMobileList ||
            (tempSavePush[0].testMobileList && tempSavePush[0].testMobileList.length === 0) ||
            (tempSavePush[0].testMobileList && tempSavePush[0].testMobileList.filter((mobile) => mobile.trim()).length !== tempSavePush[0].testMobileList.length)
        },
        { label: { key: "backEdit" }, onClick: handleClose, icon: <ArrowBackIcon /> }
      ]
    })
  }

  function checkDeliveryDate(push) {
    let tempPush = setFilterParameters(push);
    if (tempPush[0].deliveryDate <= Date.now()) {
      setDialog({
        open: true,
        onClose: handleClose,
        dialogContentText: { resource: resource, key: "deliveryDateWarning" },
        dialogActions: [
          { label: { key: "confirm" }, onClick: () => handleTotal(0, "changeStatus"), icon: <CheckIcon /> },
          { label: { key: "backEdit" }, onClick: handleClose, icon: <ArrowBackIcon /> }
        ]
      })
    } else {
      handleTotal(0, "changeStatus")
    }
  }

  function handleTotal(index, item) {
    let tempPush = setFilterParameters(savedPush);
    post(constants.PATH.PUSH.ESTIMATE_TARGET_USER_AMOUNT, tempPush[0], (payload) => {
      if (item === "changeStatus") {
        onChangeInput("total", payload, index);
        handleTesingDialog(tempPush);
      } else {
        setDialog({
          open: true,
          onClose: handleClose,
          dialogContentText: { key: "previewSuccess" },
          dialogActions: [
            { label: { key: "backEdit" }, onClick: handleClose, icon: <ArrowBackIcon /> }
          ]
        })
        onChangeInput("total", payload, index)
      }
    }, undefined, store);
  }

  function handleChangeStatus() {
    let newStatus = savedPush && savedPush.length > 0 && savedPush[0].status === "draft" ? "scheduled" : "draft"
    put(constants.PATH.PUSH.UPDATE_PUSH_TASK_STATUS + "/" + savedPush[0].id, { status: newStatus }, (payload) => {
      setDialog({
        open: true,
        onClose: onClickBackToEdit,
        dialogContentText: { key: "previewSuccess" },
        dialogActions: [
          { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
          { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
        ]
      })
      setClear(true)
    }, undefined, store);
  }

  const handleClose = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
  };

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  };

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.PUSH.PUSH);
  };

  const handleOnClickPushDrawer = (actionType, index) => {
    setDrawerType(actionType);
    switch (actionType) {
      case BANNER_ACTION.PRODUCT:
      case BANNER_ACTION.PRODUCT_CATEGORY:
      case BANNER_ACTION.PRODUCT_BRAND:
      case BANNER_ACTION.E_COUPON:
      case BANNER_ACTION.PRODUCT_COUPON_CATEGORY_MAP:
      case BANNER_ACTION.PRODUCT_COUPON_BRAND_MAP:
      case BANNER_ACTION.E_COUPON_CATEGORY:
      case BANNER_ACTION.E_COUPON_BRAND:
      case BANNER_ACTION.FOLDER:
      case BANNER_ACTION.RESTAURANT:
        setDrawerOpen(true);
        setDrawerIndex(index);
        break;
      default:
        setDrawerOpen(false);
        break;
    }
  }

  function onClickCloseDrawer() {
    setDrawerOpen(false)
  }

  function onChangeInput(name, e, index, updateType) {
    const tempPush = _.cloneDeep(savedPush);
    //PushValidation.resetErrorMessages();
    if (name !== "total" && name !== "testMobileList") {
      tempPush[0].change = true;
    }
    switch (name) {
      case "tcMessageTitle":
        if (tempPush[index].messageTitleScDisable === true) {
          tempPush[index]["scMessageTitle"] = sify(e);
        }
        tempPush[index][`${name}`] = e;
        break;
      case "tcMessage":
        if (tempPush[index].messageScDisable === true) {
          tempPush[index]["scMessage"] = sify(e);
        }
        tempPush[index][`${name}`] = e;
        break;
      case "deliveryDate":
        tempPush[index][`${name}`] = Number(moment(e).format("x"));
        break;
      case "actionType":
        PushValidation.resetErrorMessages(pushValidationConstants.KEY_PARAMETERS);
        tempPush[index][`${name}`] = e;
        tempPush[index].otherParameters = [];
        tempPush[index]["actionParameters"] = handleActionParameters({ url: "", from: "push" }, tempPush[index]);
        tempPush[index] = handleParametersInputBox(tempPush[index]);
        tempPush[index] = handleParameters(tempPush[index]);
        break;
      case "url":
        PushValidation.resetErrorMessages(pushValidationConstants.KEY_PARAMETERS);
        PushValidation.resetErrorMessages(pushValidationConstants.KEY_CHILDCODE);
        switch (tempPush[index].actionType) {
          case BANNER_ACTION.PRODUCT_CATEGORY:
          case BANNER_ACTION.E_COUPON_CATEGORY:
            tempPush[index]["actionParameters"] = handleActionParameters({ url: e.categoryCode, from: "push" }, tempPush[index]);
            break;
          case BANNER_ACTION.PRODUCT:
            if(updateType===BANNER_ACTION.PRODUCT_COUPON_CATEGORY_MAP){
              tempPush[index]["actionParameters"] = handleActionParameters({ url: tempPush[index]?.parameters?.productCode, productParent: tempPush[index]?.parameters?.productParent,categoryCode: e.categoryCode, from: "push" }, tempPush[index]);
            }else if(updateType===BANNER_ACTION.PRODUCT_COUPON_BRAND_MAP){
              tempPush[index]["actionParameters"] = handleActionParameters({ url: tempPush[index]?.parameters?.productCode, productParent: tempPush[index]?.parameters?.productParent,brandCode: e.brandCode, from: "push" }, tempPush[index]);
            }else{
              tempPush[index]["actionParameters"] = handleActionParameters({ url: e.productCode, from: "push" }, tempPush[index]);              
            }
          tempPush[index] = handleParameters(tempPush[index]);
            break;
          case BANNER_ACTION.E_COUPON:
            e.ecouponCode = e.productCode;
            if(updateType===BANNER_ACTION.PRODUCT_COUPON_CATEGORY_MAP){
              tempPush[index]["actionParameters"] = handleActionParameters({ url: tempPush[index]?.parameters?.ecouponCode, productParent: tempPush[index]?.parameters?.productParent,categoryCode: e.categoryCode, from: "push" }, tempPush[index]);
            }else if(updateType===BANNER_ACTION.PRODUCT_COUPON_BRAND_MAP){
              tempPush[index]["actionParameters"] = handleActionParameters({ url: tempPush[index]?.parameters?.ecouponCode, productParent: tempPush[index]?.parameters?.productParent,brandCode: e.brandCode, from: "push" }, tempPush[index]);
            }else{
              tempPush[index]["actionParameters"] = handleActionParameters({ url: e.ecouponCode, from: "push" }, tempPush[index]);              
            }
            break;
          case BANNER_ACTION.PRODUCT_BRAND:
          case BANNER_ACTION.E_COUPON_BRAND:
            tempPush[index]["actionParameters"] = handleActionParameters({ url: e.brandCode, from: "push" }, tempPush[index]);
            break;
          case BANNER_ACTION.FOLDER:
            tempPush[index]["actionParameters"] = handleActionParameters({ url: e.folderCode, folderType: tempPush[index].parameters.folderType, from: "push" }, tempPush[index]);
            break;
          case BANNER_ACTION.RESTAURANT:
            tempPush[index]["actionParameters"] = handleActionParameters({ url: e.restUrlId, action: tempPush[index].parameters.action, from: "push" }, tempPush[index]);
            break;
          case BANNER_ACTION.PIXEL:
            tempPush[index]["actionParameters"] = handleActionParameters({ url: e, name: tempPush[index].parameters["name"], haveRef: tempPush[index].parameters["haveRef"], from: "push" }, tempPush[index]);
            break;
          default:
            tempPush[index]["actionParameters"] = handleActionParameters({ url: e, from: "push", code: tempPush[index].parameters["code"], title: tempPush[index].parameters["title"], serviceType: tempPush[index].parameters["serviceType"], type: tempPush[index].parameters["type"] }, tempPush[index]);
            break;
        }
        tempPush[index] = handleParameters(tempPush[index]);
        break;
      case "folderType":
        tempPush[index]["actionParameters"] = handleActionParameters({ url: tempPush[index].parameters[tempPush[index].inputName], folderType: e, from: "push" }, tempPush[index]);
        tempPush[index] = handleParameters(tempPush[index]);
        break;
      case "pixelName":
        tempPush[index]["actionParameters"] = handleActionParameters({ url: tempPush[index].parameters[tempPush[index].inputName], name: e, haveRef: tempPush[index].parameters["haveRef"], from: "push" }, tempPush[index]);
        tempPush[index] = handleParameters(tempPush[index]);
        break;
      case "haveRef":
        tempPush[index]["actionParameters"] = handleActionParameters({ url: tempPush[index].parameters[tempPush[index].inputName], name: tempPush[index].parameters["name"], haveRef: e, from: "push" }, tempPush[index]);
        tempPush[index] = handleParameters(tempPush[index]);
        break;
      case "title":
        tempPush[index]["actionParameters"] = handleActionParameters({ url: tempPush[index].parameters[tempPush[index].inputName], type: tempPush[index].parameters["type"], from: "push", title: e }, tempPush[index]);
        tempPush[index] = handleParameters(tempPush[index]);
        break;
      case "type":
        tempPush[index]["actionParameters"] = handleActionParameters({ url: tempPush[index].parameters[tempPush[index].inputName], from: "push", title: tempPush[index].parameters["title"], type: e }, tempPush[index]);
        tempPush[index] = handleParameters(tempPush[index]);
        break;
      // case "name":
      //   tempPush[index]["actionParameters"] = handleActionParameters({ url: tempPush[index].parameters[tempPush[index].inputName], from: "push", name: e, query: tempPush[index].parameters["query"] }, tempPush[index]);
      //   tempPush[index] = handleParameters(tempPush[index]);
      //   break;
      case "query":
        tempPush[index]["actionParameters"] = handleActionParameters({ url: tempPush[index].parameters[tempPush[index].inputName], from: "push", name: tempPush[index].parameters["name"], query: e }, tempPush[index]);
        tempPush[index] = handleParameters(tempPush[index]);
        break;
      case "action":
        tempPush[index]["actionParameters"] = handleActionParameters({ url: tempPush[index].parameters[tempPush[index].inputName], from: "push", action: e }, tempPush[index]);
        tempPush[index] = handleParameters(tempPush[index]);
        break;
      case "sequence":
        tempPush[index][`${name}`] = Number.isInteger(Number(e)) ? Number(e) : 0;
        break;
      case "productParent":
        PushValidation.resetErrorMessages(pushValidationConstants.KEY_CHILDCODE);
        tempPush[index]["parameters"][`${name}`] = e;
        tempPush[index]["actionParameters"]  = handleActionParameters({ url: tempPush[index].parameters[tempPush[index].inputName], productParent: e, from: "push", action: e }, tempPush[index]);
        tempPush[index] = handleParameters(tempPush[index]);
        break;
      default:
        tempPush[index][`${name}`] = e;
    }
    dispatch(setPush(tempPush));
  }

  function onClickListRow(data, index, type) {
    if (type === BANNER_ACTION.CATEGORY_PRODUCT_MAP) {
      onChangeInput(BANNER_ACTION.CATEGORY_PRODUCT_MAP, data, index);
    } else if (type === BANNER_ACTION.BRAND_PRODUCT_MAP) {
      onChangeInput(BANNER_ACTION.BRAND_PRODUCT_MAP, data, index);
    } else {
      onChangeInput("url", data, index, type);
    }
    onClickCloseDrawer();
  }

  function handleDrawer() {
    let drawer;
    switch (drawerType) {
      case BANNER_ACTION.PRODUCT_CATEGORY:
        drawer =
          <Paper>
            <ProductCategoryList
              newFilter={{ typeList: [constants.CHOICES.PRODUCT_CREATE.SELECT_CATEGORY_TYPE.PRODUCT, constants.CHOICES.PRODUCT_CREATE.SELECT_CATEGORY_TYPE.ALL] }}
              editBtn={false}
              createBtn={false}
              onClickListRow={(item) => onClickListRow(item, drawerIndex)}
              dialogFooterBtn={<>
                <TempBtn btnSetting={{
                  variant: constants.STYLE.VARIANT.CONTAINED,
                  color: constants.STYLE.PRIMARY,
                  onClick: onClickCloseDrawer,
                  icon: <ClearIcon />,
                  label: { key: "close" },
                }} />
              </>
              }
            />
          </Paper>
        break;
      case BANNER_ACTION.E_COUPON_CATEGORY:
        drawer =
          <Paper>
            <ProductCategoryList
              newFilter={{ typeList: [constants.CHOICES.PRODUCT_CREATE.SELECT_CATEGORY_TYPE.FREE_ECOUPON, constants.CHOICES.PRODUCT_CREATE.SELECT_CATEGORY_TYPE.ALL] }}
              editBtn={false}
              createBtn={false}
              onClickListRow={(item) => onClickListRow(item, drawerIndex)}
              dialogFooterBtn={<>
                <TempBtn btnSetting={{
                  variant: constants.STYLE.VARIANT.CONTAINED,
                  color: constants.STYLE.PRIMARY,
                  onClick: onClickCloseDrawer,
                  icon: <ClearIcon />,
                  label: { key: "close" },
                }} />
              </>
              }
            />
          </Paper>
        break;
      case BANNER_ACTION.PRODUCT:
        drawer =
          <Paper>
            <ProductList
              newFilter={{ excludedTypeList: [constants.PRODUCT_TYPE.PACKAGE_SUB_ITEM, constants.PRODUCT_TYPE.FREE_ECOUPON_ITEM] }}
              editBtn={false}
              createBtn={false}
              onClickListRow={(item) => onClickListRow(item, drawerIndex)}
              dialogFooterBtn={<>
                <TempBtn btnSetting={{
                  variant: constants.STYLE.VARIANT.CONTAINED,
                  color: constants.STYLE.PRIMARY,
                  onClick: onClickCloseDrawer,
                  icon: <ClearIcon />,
                  label: { key: "close" },
                }} />
              </>
              }
            />
          </Paper>
        break;
      case BANNER_ACTION.E_COUPON:
        drawer =
          <Paper>
            <ProductList
              newFilter={{ type: constants.PRODUCT_TYPE.FREE_ECOUPON_ITEM, excludedTypeList: [constants.PRODUCT_TYPE.PACKAGE_SUB_ITEM] }}
              editBtn={false}
              createBtn={false}
              onClickListRow={(item) => onClickListRow(item, drawerIndex)}
              dialogFooterBtn={<>
                <TempBtn btnSetting={{
                  variant: constants.STYLE.VARIANT.CONTAINED,
                  color: constants.STYLE.PRIMARY,
                  onClick: onClickCloseDrawer,
                  icon: <ClearIcon />,
                  label: { key: "close" },
                }} />
              </>
              }
            />
          </Paper>
        break;
      case BANNER_ACTION.PRODUCT_BRAND:
      case BANNER_ACTION.E_COUPON_BRAND:
        drawer =
          <Paper>
            <BrandList
              editBtn={false}
              createBtn={false}
              onClickListRow={(item) => onClickListRow(item, drawerIndex)}
              dialogFooterBtn={<>
                <TempBtn btnSetting={{
                  variant: constants.STYLE.VARIANT.CONTAINED,
                  color: constants.STYLE.PRIMARY,
                  onClick: onClickCloseDrawer,
                  icon: <ClearIcon />,
                  label: { key: "close" },
                }} />
              </>
              }
            />
          </Paper>
        break;
      case BANNER_ACTION.PRODUCT_COUPON_CATEGORY_MAP:
        drawer =
          <Paper>
             <ProductCategoryList 
              newFilter={ {productCode : (savedPush[drawerIndex]?.parameters?.productCode)?savedPush[drawerIndex]?.parameters?.productCode:savedPush[drawerIndex]?.parameters?.ecouponCode} }
              newPath={constants.PATH.PRODUCT.PRODUCT_CATEGORY_MAP}
              drawerIndex={drawerIndex}
              editBtn={false}
              createBtn={false}
              onClickListRow={(item) => onClickListRow(item, drawerIndex, BANNER_ACTION.PRODUCT_COUPON_CATEGORY_MAP)}
              resource={resource}
              dialogFooterBtn={<>
                <TempBtn btnSetting={{
                  variant: constants.STYLE.VARIANT.CONTAINED,
                  color: constants.STYLE.PRIMARY,
                  onClick: onClickCloseDrawer,
                  icon: <ClearIcon />,
                  label: { key: "close" },
                }} />
              </>
              }
            /> 
          </Paper>
        break;
      case BANNER_ACTION.PRODUCT_COUPON_BRAND_MAP: 
        drawer =
          <Paper>
            <BrandList
              editBtn={false}
              createBtn={false}
              newPath={constants.PATH.PRODUCT.PRODUCT_BRAND_MAP}
              newFilter={ {productCode : (savedPush[drawerIndex]?.parameters?.productCode)?savedPush[drawerIndex]?.parameters?.productCode:savedPush[drawerIndex]?.parameters?.ecouponCode} }
              onClickListRow={(item) => onClickListRow(item, drawerIndex, BANNER_ACTION.PRODUCT_COUPON_BRAND_MAP)}
              dialogFooterBtn={<>
                <TempBtn btnSetting={{
                  variant: constants.STYLE.VARIANT.CONTAINED,
                  color: constants.STYLE.PRIMARY,
                  onClick: onClickCloseDrawer,
                  icon: <ClearIcon />,
                  label: { key: "close" },
                }} />
              </>
              }
            />
          </Paper>
        break;
      case BANNER_ACTION.FOLDER:
        drawer =
          <Paper>
            <FolderList
              editBtn={false}
              createBtn={false}
              onClickListRow={(item) => onClickListRow(item, drawerIndex)}
              dialogFooterBtn={<>
                <TempBtn btnSetting={{
                  variant: constants.STYLE.VARIANT.CONTAINED,
                  color: constants.STYLE.PRIMARY,
                  onClick: onClickCloseDrawer,
                  icon: <ClearIcon />,
                  label: { key: "close" },
                }} />
              </>
              }
            />
          </Paper>
        break;
      case BANNER_ACTION.RESTAURANT:
        drawer =
          <Paper>
            <RestaurantList
              editBtn={false}
              createBtn={false}
              btnList={false}
              onClickListRow={(item) => onClickListRow(item, drawerIndex)}
              dialogFooterBtn={<>
                <TempBtn btnSetting={{
                  variant: constants.STYLE.VARIANT.CONTAINED,
                  color: constants.STYLE.PRIMARY,
                  onClick: onClickCloseDrawer,
                  icon: <ClearIcon />,
                  label: { key: "close" },
                }} />
              </>
              }
            />
          </Paper>
        break;
      default:
        drawer = <></>;
        break;
    }
    return drawer;
  }

  function handleAddParameter(index) {
    let tempsavedPush = _.cloneDeep(savedPush);
    tempsavedPush.forEach((tempsavedPushValue, tempsavedPushIndex) => {
      if (index === tempsavedPushIndex) {
        let newParameter = _.cloneDeep(tempsavedPushValue.otherParameters);
        newParameter.push({
          key: "",
          value: ""
        });
        tempsavedPushValue.otherParameters = newParameter;
      }
    })
    dispatch(setPush(tempsavedPush));
  }

  function handleNewParameterChange(name, e, index) {
    let tempsavedPush = _.cloneDeep(savedPush);
    tempsavedPush.forEach((tempsavedPushValue, tempsavedPushIndex) => {
      if (index.savedPushIndex === tempsavedPushIndex) {
        tempsavedPushValue.otherParameters[index.otherParameterIndex][`${name}`] = e;
      }
    });
    dispatch(setPush(tempsavedPush));
  }

  function handleOtherParameterDelete(deleteIndex, savedPushIndex) {
    let tempsavedPush = _.cloneDeep(savedPush);
    tempsavedPush.forEach((tempsavedPushValue, tempsavedPushIndex) => {
      if (tempsavedPushIndex === savedPushIndex) {
        tempsavedPushValue.otherParameters = tempsavedPushValue.otherParameters.filter((item, index) => index !== deleteIndex);
        tempsavedPushValue = handleParameters(tempsavedPushValue);
      }
    })
    dispatch(setPush(tempsavedPush));
  }

  function handleApiCallBack(data) {
    data = handleParametersInputBox(data);
    data = handleParameters(data);
    data = handleFilterParameters(data);
    if (data.actionType !== BANNER_ACTION.NONE) {
      let tempActionParameters = JSON.parse(data.actionParameters);
      let tempOtherParameters = data.otherParameters ? JSON.parse(data.otherParameters) : [];
      delete tempActionParameters.url;
      const objectArray = Object.entries(tempActionParameters);
      objectArray.forEach(([key, value]) => {
        tempOtherParameters.push(
          {
            key: key,
            value: value
          }
        );
      });
      data.otherParameters = tempOtherParameters ? tempOtherParameters : [];
    } else {
      data.otherParameters = [];
    }

    return data;
  }

  function onClickSave() {
    let tempsavedPush = _.cloneDeep(savedPush);
    PushValidation.validateEditPush(tempsavedPush[0]);
    //console.log(tempsavedPush)
    if (PushValidation.isValid()) {
      tempsavedPush = setFilterParameters(tempsavedPush);
      tempsavedPush.forEach((tempsavedPushValue) => {
        if (tempsavedPushValue.actionType === BANNER_ACTION.NONE) {
          tempsavedPushValue.actionParameters = null;
        } else {
          let tempActionParameters = JSON.parse(tempsavedPushValue.actionParameters);
          if (tempsavedPushValue.otherParameters.length > 0) {
            tempsavedPushValue.otherParameters.forEach((otherParameter) => {
              if (otherParameter.key && otherParameter.value) {
                let key = {};
                key[otherParameter.key] = otherParameter.value;
                tempActionParameters = Object.assign(tempActionParameters, key)
              }
            })
          }
          tempsavedPushValue.actionParameters = JSON.stringify(tempActionParameters)
        }
      })

      //console.log("IN", tempsavedPush)

      put(constants.PATH.PUSH.PUSH + "/" + tempsavedPush[0].id, tempsavedPush[0], (payload) => {
        let newPush = [];
        setDialog({
          open: true,
          onClose: onClickBackToEdit,
          dialogContentText: { key: "editSuccess" },
          dialogActions: [
            { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        payload = handleApiCallBack(payload)
        newPush.push(payload);
        dispatch(setPush(newPush));
        setClear(false)
      }, undefined, store);
      PushValidation.resetErrorMessages();
    } else {
      setErrorVersion(PushValidation.getVersion());
    }
  }

  function handleTesting(tempPush) {
    tempPush.forEach((tempPushValue) => {
      tempPushValue.testMobileList = tempPushValue.testMobileList.filter((testMobile) => testMobile.trim());
    });
    post(constants.PATH.PUSH.TEST_PUSH_TASK, tempPush[0], (payload) => {
      setDialog({
        open: true,
        onClose: handleClose,
        dialogContent: <>
          {getString(lang, "", "testingSuccess", theme)}
          {previewPushContent}
          {tempPush[0].total === 0 && <div className="error-message error-large">You are not sending to anyone!</div>}
        </>,
        dialogActions: [
          { label: { key: "publish" }, onClick: handleChangeStatus, icon: <SendIcon />, disabled: tempPush[0].total === 0 },
          { label: { key: "backEdit" }, onClick: handleClose, icon: <ArrowBackIcon /> }
        ]
      })
    }, undefined, store);
  }

  function onClickClear() {
    setClear(true)
  }

  function handleParametersInputBox(value) {
    if (value) {
      value = setParametersInputBox(value);
    }
    return value;
  }

  function handleParameters(tempsavedPushValue) {
    if (tempsavedPushValue && tempsavedPushValue.actionParameters) {
      tempsavedPushValue = getActionParameters(tempsavedPushValue);
    }
    return tempsavedPushValue;
  }

  useEffect(() => {
    dispatch(setPush(constants.URL_MAP_INPUT_LIST));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (clear === true) {

      getOne(constants.PATH.PUSH.PUSH + "/" + params.id, undefined, (payload) => {

        if(payload.actionType===BANNER_ACTION.CATEGORY_PRODUCT){
          payload.actionType = BANNER_ACTION.PRODUCT;
        }

        if(payload.actionType===BANNER_ACTION.BRAND_PRODUCT){
          payload.actionType = BANNER_ACTION.PRODUCT;
        }

        if(payload.actionType===BANNER_ACTION.CATEGORY){
          payload.actionType = BANNER_ACTION.PRODUCT_CATEGORY;
        }

        if(payload.actionType===BANNER_ACTION.BRAND){
          payload.actionType = BANNER_ACTION.PRODUCT_BRAND;
        }

        let newPush = [];
        payload.messageTitleScDisable = getScDisabled(payload.tcMessageTitle, payload.scMessageTitle);
        payload.messageScDisable = getScDisabled(payload.tcMessage, payload.scMessage);
        payload = handleApiCallBack(payload)
        newPush.push(payload);
        if (payload.status === "sending" || payload.status === "finished") {
          setStatusDisabled(true);
        }

        dispatch(setPush(_.cloneDeep(newPush)));
        setPushInputList(_.cloneDeep(newPush[0]))
        setReady(true)
        setClear(false)
      }, undefined, store);
    }
    PushValidation.resetErrorMessages();
  }, [clear === true]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <>
      <EditCreateDisplay
        code={savedPush && (savedPush[0] && savedPush[0].id ? savedPush[0].id : "")}
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        display="edit"
      />
      {!statusDisabled &&
        <Drawer
          classes={{ paper: "drawer-margin-left" }}
          anchor="right"
          open={drawerOpen}
          onClose={onClickCloseDrawer}
        >
          {handleDrawer()}
        </Drawer>
      }
      {dialog && dialog.open &&
        <DialogWrapper dialogInfo={dialog} />
      }
    </>
  )
};
