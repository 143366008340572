import { useState, useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
// import SplitPane from 'react-split-pane';
import { Allotment } from 'allotment'
import 'allotment/dist/style.css'

import { constants as co, BANNER_ACTION, BANNER_TYPE } from '../../Constants/constants'
import { post } from '../../helper/baseFetch'
import { setBannerList, setBanner } from '../../actions/bannerAction'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { BannerCreateTemp } from './BannerCreateTemp'
import { BannerCreateList } from './BannerCreateList'
import BannerValidation from '../../validations/bannerValidation'
import { DialogWrapper } from '../../Component/DialogWrapper'
import { formatTicketBanner } from './BannerUtil'

export const BannerCreate = ({ toolBar, newPath, bannerType, mapName, mapCode, mapId, redirectPath, tab }) => {
  const dispatch = useDispatch()
  const store = useStore()
  const history = useHistory()
  const [clear, setClear] = useState(false)
  const savedBannerList = useSelector((state) => state.banner.ui.bannerList)
  const savedBanner = useSelector((state) => state.banner.ui.banner)
  const [customIdNumber, setCustomIdNumber] = useState(0)
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  // eslint-disable-next-line no-unused-vars
  const [errorVersion, setErrorVersion] = useState(BannerValidation.getVersion())
  const resource = 'Banner'

  const content = [
    {
      tab: (
        <>
          <Allotment defaultSizes={[100, 200]}>
            <BannerCreateList
              bannerType={bannerType}
              resource={resource}
              setCustomIdNumber={setCustomIdNumber}
              customIdNumber={customIdNumber}
              mapCode={mapCode}
            />
            <div className="queue-template-flex-right" style={{ height: '75vh', minHeight: '590px' }}>
              {savedBanner.customId && savedBannerList.length > 0 && (
                <BannerCreateTemp
                  create={true}
                  bannerType={bannerType}
                  setCustomIdNumber={setCustomIdNumber}
                  customIdNumber={customIdNumber}
                  ready={true}
                />
              )}
            </div>
          </Allotment>
        </>
      ),
      btn: toolBar === false ? false : true,
    },
  ]

  const bottomBtns = [
    {
      variant: co.STYLE.VARIANT.CONTAINED,
      color: co.STYLE.PRIMARY,
      label: { key: 'save' },
      onClick: onClickSave,
      className: 'margin-left-16',
      icon: <SaveIcon />,
      disabled: !savedBanner || savedBanner.length === 0,
    },
    {
      variant: co.STYLE.VARIANT.CONTAINED,
      color: co.STYLE.PRIMARY,
      label: { key: 'clear' },
      disabled: false,
      onClick: onClickClear,
      className: 'margin-left-16',
      icon: <ClearIcon />,
    },
  ]

  const onClickBackToCreate = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    if (redirectPath === co.PATH.SHOP.SYSTEM_GROUP_RESTAURANT) {
      history.push(mapId ? '/' + redirectPath + '/' + mapId + '/' + co.RESTAURANT_TAB.MEDIA + '/' + tab : '/' + redirectPath)
    } else if (!redirectPath) {
      history.push('/' + co.PATH.BANNER.BANNER)
    } else {
      history.push(mapId ? '/' + redirectPath + '/' + mapId + '/' + tab : '/' + redirectPath)
    }
  }

  function onClickSave() {
    let tempsavedBannerList = _.cloneDeep(savedBannerList)

    tempsavedBannerList.forEach((tempsavedBanner) => {
      if (tempsavedBanner.type === BANNER_TYPE.MERCHANT_LANDING || tempsavedBanner.type === BANNER_TYPE.MERCHANT_LOGIN) {
        if (tempsavedBanner.actionType === BANNER_ACTION.MERCHANT_WEB_VIEW) {
          tempsavedBanner.actionType = BANNER_ACTION.WEB_VIEW
        }
        if (tempsavedBanner.actionType === BANNER_ACTION.MERCHANT_LINK) {
          tempsavedBanner.actionType = BANNER_ACTION.LINK
        }
      }
    })

    BannerValidation.validateCreateBanner(tempsavedBannerList)
    if (BannerValidation.isValid()) {
      tempsavedBannerList.forEach((tempsavedBannerValue) => {
        if (tempsavedBannerValue.actionType === BANNER_ACTION.NONE || tempsavedBannerValue.actionType === BANNER_ACTION.DOWNLOAD) {
          tempsavedBannerValue.actionParameters = null
        } else {
          let tempActionParameters = JSON.parse(tempsavedBannerValue.actionParameters)
          if (tempsavedBannerValue.otherParameters.length > 0) {
            tempsavedBannerValue.otherParameters.forEach((otherParameter) => {
              if (otherParameter.key && otherParameter.value) {
                let key = {}
                key[otherParameter.key] = otherParameter.value
                tempActionParameters = Object.assign(tempActionParameters, key)
              }
            })
          }
          tempsavedBannerValue.actionParameters = JSON.stringify(tempActionParameters)
        }
      })

      tempsavedBannerList = formatTicketBanner(tempsavedBannerList)

      post(
        newPath ? newPath : co.PATH.BANNER.BANNER_LIST,
        { id: mapId, bannerList: tempsavedBannerList },
        (payload) => {
          setDialog({
            open: true,
            onClose: onClickBackToCreate,
            dialogContentText: { key: 'createSuccess' },
            dialogActions: [
              { label: { key: 'backCreate' }, onClick: onClickBackToCreate, icon: <ArrowBackIcon /> },
              { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
            ],
          })
          setClear(false)
        },
        undefined,
        store
      )
      // console.log("IN", tempsavedBannerList)
      BannerValidation.resetErrorMessages()
    } else {
      setErrorVersion(BannerValidation.getVersion())
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    let tempBannerList = _.cloneDeep(co.BANNER_INPUT_LIST)
    let tempBanner = _.cloneDeep(co.BANNER_INPUT)
    if (mapCode) {
      tempBanner.refCode = mapCode
    } else {
      tempBanner.refCode = null
    }
    dispatch(setBannerList(tempBannerList))
    dispatch(setBanner(tempBanner))
    BannerValidation.resetErrorMessages()
    setClear(false)
  }, [clear === true]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log('savedBanner', savedBanner)
  }, [savedBanner])

  return (
    <>
      <EditCreateDisplay
        code={''}
        resource={resource}
        tabs={false}
        content={content}
        bottomBtns={toolBar === false ? false : bottomBtns}
        mapName={mapName}
        mapCode={mapCode}
        display="create"
      />
      {dialog && dialog.open && <DialogWrapper dialogInfo={dialog} />}
    </>
  )
}
