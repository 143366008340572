import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useStore } from 'react-redux'
import moment from 'moment'
import { sify } from 'chinese-conv'
import _ from 'lodash'

import { Drawer } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CancelIcon from '@mui/icons-material/Cancel'
import { makeStyles } from '@mui/styles'

import { constants as co, IMAGE_TYPE } from '../../../Constants/constants'
import { post } from '../../../helper/baseFetch'
import { EditCreateDisplay } from '../../EditCreateDisplay/EditCreateDisplay'
import { TempBtn } from '../../Input/TempBtn'
import { TempCustomCheckBox } from '../../Custom/TempCustomCheckBox'
import { ProductCategoryList } from './ProductCategoryList'
import ProductCreateValidation from '../../../validations/productCreateValidation'
import { productValidationConstants } from '../../../Constants/validationConstants'
import { getString } from '../../../helper/util'
import { DialogWrapper } from '../../DialogWrapper'
import { ImageList } from '../../UploadImage/ImageList'
import { ProductMapCreate } from './ProductMapCreate'
import { getContent, getDefaultBottomBtnList } from '../../EditCreateDisplay/EditUtil'
import { Launch } from '@mui/icons-material'

const helperTextStyles = makeStyles((theme) => ({
  root: {
    color: 'red !important',
  },
}))

export const ProductCategoryCreate = ({ listOverflow }) => {
  const store = useStore()
  const history = useHistory()
  const helperTextStylesClasses = helperTextStyles()
  const lang = useSelector((state) => state.lang.ui.lang)
  const theme = useSelector((state) => state.theme.ui.theme)
  // eslint-disable-next-line no-unused-vars
  const [errorVersion, setErrorVersion] = useState(ProductCreateValidation.getVersion())

  const [ready, setReady] = useState(false)
  const [clear, setClear] = useState(false)
  const [showCategoryDrawer, setShowCategoryDrawer] = useState(false)
  const [selectedRefCategory, setSelectedRefCategory] = useState({})
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  const resource = 'productCreateProductCategoryList'
  const tabs = [
    {
      label: { resource: resource, key: 'info' },
      tabIndex: co.PRODUCT_CAT_TAB.INFO,
    },
    // {
    //   label: { resource: resource, key: 'productMapList' },
    //   tabIndex: constants.PRODUCT_CAT_TAB.PRODUCT_MAP_LIST,
    // },
  ]

  const onClickRefCategoryCodeEndAdornmentIconButton = () => {
    setShowCategoryDrawer(true)
  }
  const onCloseCategoryDrawer = () => {
    setShowCategoryDrawer(false)
  }

  function onSelectCheckbox(value, customName) {
    const newSelectedRefCategory = _.cloneDeep(selectedRefCategory)
    switch (customName.key) {
      case 'nameCustomize':
        newSelectedRefCategory.scNameDisable = value
        if (value) {
          newSelectedRefCategory.scName = newSelectedRefCategory.tcName ? sify(newSelectedRefCategory.tcName) : null
        }
        break
      case 'descriptionCustomize':
        newSelectedRefCategory.scDescriptionDisable = value
        if (value) {
          newSelectedRefCategory.scDescription = newSelectedRefCategory.tcDescription ? sify(newSelectedRefCategory.tcDescription) : null
        }
        break
      default:
    }
    setSelectedRefCategory(newSelectedRefCategory)
  }

  const onClickBackToCreate = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push('/' + co.PATH.PRODUCT.PRODUCT_CATEGORY)
  }

  function onChangeInput(name, e) {
    if (e === '') {
      e = null
    }
    const newSelectedRefCategory = _.cloneDeep(selectedRefCategory)
    if (selectedRefCategory.scNameDisable && name === 'tcName') {
      newSelectedRefCategory.tcName = e
      newSelectedRefCategory.scName = e ? sify(e) : null
    } else if (selectedRefCategory.scDescriptionDisable && name === 'tcDescription') {
      newSelectedRefCategory.tcDescription = e
      newSelectedRefCategory.scDescription = e ? sify(e) : null
    } else if (name === 'startDisplayTimestamp' || name === 'endDisplayTimestamp') {
      newSelectedRefCategory[`${name}`] = Number(moment(e).format('x'))
    } else if (name === 'displaySequence') {
      newSelectedRefCategory.displaySequence = e ? Number(e) : 0
    } else if (name === 'channel') {
      newSelectedRefCategory.channel = e
    } else {
      newSelectedRefCategory[`${name}`] = e
    }
    setSelectedRefCategory(newSelectedRefCategory)
  }

  function onClearRefCategoryCode() {
    onChangeInput('refCategoryCode', '')
  }

  const fields = [
    {
      type: co.TYPE.TITLE,
      value: { resource: resource, key: 'name' },
      className: 'first-sub-title',
    },
    {
      className: 'edit-create-input',
      variant: co.STYLE.VARIANT.STANDARD,
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'type' },
      value: selectedRefCategory.type,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.PRODUCT_CREATE.EDIT_PRODUCT.CATEGORY_TYPE,
      name: 'type',
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_CATEGORY_TYPE, 'category').map((error) => {
        return error
      }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'codeDescription' },
      value: selectedRefCategory.codeDescription,
      name: 'codeDescription',
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_CATEGORY_DESCRIPTION, 'category').map((error) => {
        return error
      }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      variant: null,
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'channel' },
      value: selectedRefCategory.channel,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.PRODUCT_CREATE.EDIT_PRODUCT.CHANNEL,
      name: 'channel',
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_CATEGORY_CHANNEL, 'category').map((error) => {
        return error
      }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      variant: null,
      size: co.SIZE.SMALL,
      label: { resource: 'productCreateProductCategoryList', key: 'refCategoryCode' },
      onClickEndAdornmentIconButton: onClickRefCategoryCodeEndAdornmentIconButton,
      value: selectedRefCategory.refCategoryCode ? selectedRefCategory.refCategoryCode + '    ' + selectedRefCategory.refCategoryTcName : '',
      name: 'refCategoryCode',
      clear: onClearRefCategoryCode,
      disabled: true,
      isEndOfLine: true,
      endAdornmentIconButtonList: selectedRefCategory.id
        ? [
            <Launch
              fontSize={co.SIZE.SMALL}
              onClick={() => window.open(`${window.location.origin}/rack_product/category/${selectedRefCategory.id}/0`, '_blank')}
            />,
          ]
        : [],
    },
    {
      className: 'edit-create-input',
      variant: null,
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'enName' },
      value: selectedRefCategory.enName,
      name: 'enName',
      custom: true,
      customName: { key: 'nameCustomize' },
      customValue: selectedRefCategory.scNameDisable,
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_CATEGORY_NAME_EN, 'category').map((error) => {
        return error
      }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'tcName' },
      value: selectedRefCategory.tcName,
      name: 'tcName',
      translateName: selectedRefCategory.scNameDisable ? ['scName'] : null,
      inputList: selectedRefCategory,
      setInputList: setSelectedRefCategory,
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_CATEGORY_NAME_TC, 'category').map((error) => {
        return error
      }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'scName' },
      value: selectedRefCategory.scName,
      name: 'scName',
      inputList: selectedRefCategory,
      setInputList: setSelectedRefCategory,
      isEndOfLine: true,
      disabled: selectedRefCategory.scNameDisable,
      helperTextIcon: true,
      helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_CATEGORY_NAME_SC, 'category').map((error) => {
        return error
      }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'enDescription' },
      value: selectedRefCategory.enDescription,
      name: 'enDescription',
      custom: true,
      customName: { key: 'descriptionCustomize' },
      customValue: selectedRefCategory.scDescriptionDisable,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'tcDescription' },
      value: selectedRefCategory.tcDescription,
      name: 'tcDescription',
      translateName: selectedRefCategory.scDescriptionDisable ? ['scDescription'] : null,
      inputList: selectedRefCategory,
      setInputList: setSelectedRefCategory,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'scDescription' },
      value: selectedRefCategory.scDescription,
      name: 'scDescription',
      inputList: selectedRefCategory,
      setInputList: setSelectedRefCategory,
      isEndOfLine: true,
      disabled: selectedRefCategory.scDescriptionDisable,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'startDisplayTimestamp' },
      value: selectedRefCategory.startDisplayTimestamp === 0 ? '' : selectedRefCategory.startDisplayTimestamp,
      name: 'startDisplayTimestamp',
      type: co.TYPE.DATETIME_LOCAL,
      step: 1,
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_CATEGORY_START_DISPLAY_TIMESTAMP, 'category').map(
        (error) => {
          return error
        }
      ),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'endDisplayTimestamp' },
      value: selectedRefCategory.endDisplayTimestamp === 0 ? '' : selectedRefCategory.endDisplayTimestamp,
      name: 'endDisplayTimestamp',
      type: co.TYPE.DATETIME_LOCAL,
      step: 1,
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_CATEGORY_END_DISPLAY_TIMESTAMP, 'category').map((error) => {
        return error
      }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'displaySequence' },
      value: selectedRefCategory.displaySequence,
      type: co.TYPE.NUMBER,
      min: 0,
      name: 'displaySequence',
      isEndOfLine: true,
      helperTextIcon: true,
      helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_CATEGORY_DISPLAY_SEQUENCE, 'category').map((error) => {
        return error
      }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: `edit-create-input`,
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'status' },
      value: selectedRefCategory.status,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.PRODUCT_CREATE.CREATE_CATEGORY.STATUS,
      name: 'status',
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      type: co.TYPE.FUNCTION,
      value: (
        <>
          <h3 variant={co.STYLE.VARIANT.H6}>{getString(lang, 'productCreateProductCategoryList', 'imageList', theme)}</h3>
          {ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_CATEGORY_IMAGE_LIST, 'category').map((error) => {
            return error
          }) &&
            ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_CATEGORY_IMAGE_LIST, 'category').map((error) => {
              return error
            }).length > 0 && (
              <div className="error-message">
                <CancelIcon className="error-message-icon" fontSize={co.SIZE.SMALL} />
                {ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_CATEGORY_IMAGE_LIST, 'category').map((error) => {
                  return error
                })}
              </div>
            )}
          {
            <ImageList
              uploadPath={co.PATH.PRODUCT.UPLOAD_PRODUCT_IMAGE}
              resource={'productCreateProductCategoryList'}
              type={IMAGE_TYPE.CATEGORY_CREATE}
              typeList={co.CHOICES.PRODUCT_CREATE.CREATE_CATEGORY.CAT_IMAGE_TYPE}
              savedData={selectedRefCategory}
              helperTextStylesClasses={helperTextStylesClasses}
              validation={ProductCreateValidation}
              validationConstants={productValidationConstants}
              hideTitle={true}
              onUploadImageList={onUploadImageList}
            />
          }
        </>
      ),
      isEndOfLine: true,
    },
  ]

  const content = [
    {
      tab: (
        <div className={listOverflow}>
          {fields.map((inputSetting, index) => {
            let content = getContent(inputSetting, index, onChangeInput)
            return (
              <div className="edit-display-wrapper" key={index}>
                {inputSetting.custom && (
                  <TempCustomCheckBox
                    className="customize-btn"
                    value={inputSetting.customValue}
                    customName={inputSetting.customName}
                    onSelectCheckbox={onSelectCheckbox}
                  />
                )}
                {content}
                {inputSetting.isEndOfLine && <div></div>}
              </div>
            )
          })}
        </div>
      ),
      btn: true,
    },
    {
      tab: <ProductMapCreate helperTextStylesClasses={helperTextStylesClasses} />,
      btn: false,
    },
  ]
  const bottomBtns = getDefaultBottomBtnList(onClickSave, onClickClear)

  function onClickListRow(e) {
    const newSelectedRefCategory = _.cloneDeep(selectedRefCategory)
    newSelectedRefCategory.refCategoryCode = e.categoryCode
    newSelectedRefCategory.refCategoryTcName = e.tcName
    newSelectedRefCategory.id = e.id
    setSelectedRefCategory(newSelectedRefCategory)
    onCloseCategoryDrawer()
  }

  function onClickSave() {
    const productCreateForValidation = {
      category: selectedRefCategory,
    }
    ProductCreateValidation.validateCategoryOnly(productCreateForValidation)

    if (ProductCreateValidation.isValid()) {
      post(
        co.PATH.PRODUCT.PRODUCT_CATEGORY,
        selectedRefCategory,
        (payload) => {
          setDialog({
            open: true,
            onClose: onClickBackToCreate,
            dialogContentText: { key: 'createSuccess' },
            dialogActions: [
              { label: { key: 'backCreate' }, onClick: onClickBackToCreate, icon: <ArrowBackIcon /> },
              { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
            ],
          })
          setClear(false)
        },
        undefined,
        store
      )
      setErrorVersion(ProductCreateValidation.getVersion())
    } else {
      setErrorVersion(ProductCreateValidation.getVersion())
    }
  }

  function onUploadImageList(imageList) {
    const temptCategoryInputList = _.cloneDeep(selectedRefCategory)
    temptCategoryInputList.imageList = imageList
    setSelectedRefCategory(temptCategoryInputList)
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    setSelectedRefCategory(co.CATEGORY_INPUT)
    setReady(true)
    setClear(false)
  }, [clear === true]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready && (
      <div className="step-content-content-wrapper">
        <EditCreateDisplay
          code={selectedRefCategory.categoryCode ? selectedRefCategory.categoryCode : ''}
          resource={resource}
          tabs={tabs}
          content={content}
          onChangeInput={onChangeInput}
          bottomBtns={bottomBtns}
          display={'create'}
        />
        <Drawer classes={{ paper: 'drawer-margin-left' }} anchor="right" open={showCategoryDrawer} onClose={onCloseCategoryDrawer}>
          <ProductCategoryList
            editBtn={false}
            createBtn={false}
            onClickListRow={(item) => onClickListRow(item)}
            newFilter={{ excludedId: '4bf617a1-28f7-4018-a257-f93253698d38' }}
            dialogFooterBtn={
              <TempBtn
                btnSetting={{
                  variant: co.STYLE.VARIANT.CONTAINED,
                  color: co.STYLE.PRIMARY,
                  onClick: onCloseCategoryDrawer,
                  icon: <ClearIcon />,
                  label: { key: 'close' },
                }}
              />
            }
          />
        </Drawer>
        {dialog && dialog.open && <DialogWrapper dialogInfo={dialog} />}
      </div>
    )
  )
}
