import { useState, useEffect } from 'react'
import { useStore } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import _ from 'lodash'

import { Card } from '@mui/material'

import { ListDisplay } from '../../Component/ListDisplay/ListDisplay'

import { constants } from '../../Constants/constants'
import { getList } from '../../helper/baseFetch'
import { getParamQueryString, getQuery, getQueryParamObject } from '../../helper/util'

export const MerchantUserList = ({ mutipleSelect, selectMap = new Map(), setSelectMap, dialogFooterBtn, changeUrlQueryString }) => {
  const store = useStore()
  const [merchantUserList, setMerchantUserList] = useState([])
  const [ready, setReady] = useState(false)
  const [selectedAllCheck, setSelectedAllCheck] = useState(false)
  const resource = 'MerchantUser'
  const history = useHistory()
  const location = useLocation()
  const filterParams = getQuery()

  const merchantUserTitleList = [
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: 'userRole',
      sort: true,
    },
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: 'username',
      sort: true,
    },
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: 'email',
      sort: true,
    },
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: 'channel',
      sort: true,
    },
  ]
  const listFilters = [
    {
      className: 'filter-input',
      label: { resource: resource, key: 'userRole' },
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.MERCHANT_USER.USER_ROLE_FILTER,
      name: 'userRole',
      size: constants.SIZE.SMALL,
    },
    {
      className: 'filter-input',
      label: { resource: resource, key: 'username' },
      clear: true,
      value: '',
      name: 'username',
      size: constants.SIZE.SMALL,
    },
    {
      className: 'filter-input',
      label: { resource: resource, key: 'email' },
      clear: true,
      value: '',
      name: 'email',
      size: constants.SIZE.SMALL,
    },
    {
      className: 'filter-input',
      label: { resource: resource, key: 'channel' },
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.MERCHANT_USER.CHANNEL,
      name: 'channel',
      size: constants.SIZE.SMALL,
    },
  ]

  const [param, setParam] = useState(
    !changeUrlQueryString
      ? {
          path: '',
          page: 0,
          rowsPerPage: 10,
          sort: 'updateTimestamp',
          direction: 'DESC',
          listFilters: _.cloneDeep(listFilters),
          count: 0,
        }
      : getQueryParamObject(
          {
            path: '',
            page: 0,
            rowsPerPage: 10,
            sort: 'updateTimestamp',
            direction: 'DESC',
            listFilters: _.cloneDeep(listFilters),
            count: 0,
          },
          _.cloneDeep(listFilters),
          merchantUserTitleList
        )
  )

  useEffect(() => {
    if (!changeUrlQueryString) {
      return
    }

    const querySyring = getParamQueryString(
      {
        path: '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: param.count,
      },
      param
    )

    const currentQueryString = window.location.search?.substring(1)

    if (querySyring === currentQueryString) {
      return
    }

    const newParam = getQueryParamObject(
      {
        path: '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: param.count + 1,
      },
      _.cloneDeep(listFilters),
      merchantUserTitleList
    )

    setParam(newParam)

    const newQuery = getParamQueryString(
      {
        path: '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: 0,
      },
      newParam
    )

    history.replace({ pathname: location.pathname, search: newQuery })
  }, [filterParams])

  function onChangeParam(value) {
    value.count = value.count + 1

    if (!changeUrlQueryString) {
      setParam(value)
      return
    }

    const querySyring = getParamQueryString(
      {
        path: '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: 0,
      },
      value
    )

    history.push({ pathname: location.pathname, search: querySyring })
  }

  function onChangeListFilters(list) {
    let tempParam = _.cloneDeep(param)
    tempParam.listFilters = list
    tempParam.page = 0
    onChangeParam(tempParam)
  }

  function onChangeListSort(sortItem) {
    let tempParam = _.cloneDeep(param)
    tempParam.sort = sortItem
    tempParam.direction = tempParam.direction === 'DESC' ? 'ASC' : 'DESC'
    onChangeParam(tempParam)
  }

  function onChangeListPage(page) {
    let tempParam = _.cloneDeep(param)
    tempParam.page = page
    onChangeParam(tempParam)
  }

  function onChangeListRowsPerPage(rowsPerPage) {
    let tempParam = _.cloneDeep(param)
    tempParam.page = 0
    tempParam.rowsPerPage = rowsPerPage
    onChangeParam(tempParam)
  }

  const addNewEntryToMap = (map, array) => {
    for (const item of array) {
      map.set(item.id, item)
    }
    return map
  }

  const checkAllSelected = (set) => {
    let selectedPerPage = merchantUserList.filter((item) => {
      return set.has(item.id)
    }).length

    let currentPageEntry = merchantUserList.length

    if (selectedPerPage === currentPageEntry && selectedPerPage !== 0) {
      setSelectedAllCheck(true)
    } else {
      setSelectedAllCheck(false)
    }
  }

  const onMultiSelectList = (id, dataList, row) => {
    let tempMap = _.cloneDeep(selectMap)
    if (id === 'all') {
      if (!selectedAllCheck) {
        setSelectMap(addNewEntryToMap(tempMap, merchantUserList))
        //setSelectedAllCheck(true);
        return
      }

      merchantUserList.forEach((item) => tempMap.delete(item.id))
      setSelectMap(tempMap)
      //setSelectedAllCheck(false);
      return
    }

    if (selectMap.has(id)) {
      tempMap.delete(id)
    } else {
      tempMap.set(id, row)
    }

    setSelectMap(tempMap)
  }

  useEffect(() => {
    let tempParam = _.cloneDeep(param)
    const filter = {}
    tempParam.listFilters.forEach((filterItem) => {
      if (filterItem.value !== '' && filterItem.value !== undefined) {
        filter[filterItem.name] = filterItem.value
      }
    })

    getList(
      constants.PATH.MERCHANT.MERCHANT_USER,
      {
        sort: { field: tempParam.sort, order: tempParam.direction },
        filter: filter,
        pagination: { page: tempParam.page + 1, perPage: tempParam.rowsPerPage },
      },
      (payload) => {
        setMerchantUserList(payload)
        setReady(true)
      },
      undefined,
      store
    )
  }, [param.count]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!selectMap) {
      return
    }

    checkAllSelected(selectMap)
  }, [merchantUserList, selectMap])

  return (
    ready && (
      <Card className="card-wrapper overflow">
        <ListDisplay
          page={param.page}
          rowsPerPage={param.rowsPerPage}
          onChangeListSort={onChangeListSort}
          resource={resource}
          dataList={merchantUserList}
          sort={param.sort}
          direction={param.direction}
          listTitles={merchantUserTitleList}
          listFilters={param.listFilters}
          mutipleSelect={mutipleSelect}
          idName="id"
          onMultiSelectList={onMultiSelectList}
          selectedAllCheck={selectedAllCheck}
          selectMap={selectMap}
          dialogFooterBtn={dialogFooterBtn}
          onChangeListPage={onChangeListPage}
          onChangeListRowsPerPage={onChangeListRowsPerPage}
          onChangeListFilters={onChangeListFilters}
          redirectPath={'/' + constants.PATH.MERCHANT.MERCHANT_USER + '/'}
          createBtn={true}
          editBtn={true}
        />
      </Card>
    )
  )
}
