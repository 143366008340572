import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Drawer } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

import { setProductValue } from "../../../actions/productCreateAction";
import { constants } from '../../../Constants/constants';
import { ProductSystemGroupList } from "./ProductSystemGroupList/ProductSystemGroupList";
import { setLoading } from "../../../actions/systemAction";
import ProductCreateValidation from "../../../validations/productCreateValidation";
import { productValidationConstants } from '../../../Constants/validationConstants';
import { TempBtn } from "../../Input/TempBtn"
import { TempTextInput } from "../../Input/TempTextInput"

export const ProductSystemGroupId = ({ disableInput, helperTextStylesClasses }) => {
  const dispatch = useDispatch();
  const savedProduct = useSelector(state => state.product.ui.product)

  const [showSystemGroupTextPanel, setShowSystemGroupTextPanel] = useState(false);

  function handleSystemGroupId(name, systemGroupId) {
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, "systemGroupId",
      {
        systemGroupId: systemGroupId.id,
        systemGroupName: systemGroupId.name
      }));
    handleSystemGroupTextCloseClick();
  }

  const handleSystemGroupTextClick = () => {
    dispatch(setLoading(true));
    setShowSystemGroupTextPanel(true);
    dispatch(setLoading(false));
  };

  const handleSystemGroupTextCloseClick = () => {
    dispatch(setLoading(true));
    setShowSystemGroupTextPanel(false);
    dispatch(setLoading(false));
  };

  return (
    <>
      <TempTextInput
        inputSetting={{
          className: "edit-create-input",
          variant: disableInput ? constants.STYLE.VARIANT.STANDARD: null,
          size: constants.SIZE.SMALL,
          label: {resource: "productCreateProductList", key: "systemGroup"},
          value: savedProduct.draft.product.systemGroupId ? savedProduct.draft.product.systemGroupName : "",
          onClickEndAdornmentIconButton: disableInput ? null : () => !disableInput ? handleSystemGroupTextClick() : "",
          helperTextIcon: true,
          helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_SYSTEM_GROUP, savedProduct.draft.product.productCode).map((error) => { return error }),
          FormHelperTextProps: { className: helperTextStylesClasses.root },
          clear: disableInput ? false : true,
          disabled: true,
          multiline: true,
        }}
        handleChange={handleSystemGroupId}
      />
      <Drawer
        classes={{ paper: "drawer-margin-left" }}
        anchor="right"
        open={showSystemGroupTextPanel}
        onClose={handleSystemGroupTextCloseClick}
      >
        <ProductSystemGroupList
          editBtn={false}
          createBtn={false}
          onClickListRow={(item) => handleSystemGroupId("systemGroupId", item)}
          dialogFooterBtn={<>
            <TempBtn btnSetting={{
              variant: constants.STYLE.VARIANT.CONTAINED,
              color: constants.STYLE.PRIMARY,
              onClick: handleSystemGroupTextCloseClick,
              icon: <ClearIcon />,
              label: {key: "close"},
            }} />
          </>
          }
        />
      </Drawer>
    </>
  )
};