import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useStore } from "react-redux";
import _ from "lodash";

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { makeStyles } from '@mui/styles';

import { constants } from '../../Constants/constants';
import { getOne, put } from "../../helper/baseFetch";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempAutoSelectInput } from "../../Component/Input/TempAutoSelectInput";
import { DialogWrapper } from "../../Component/DialogWrapper";
import LandmarkDistrictMapValidation from "../../validations/landmarkDistrictMapValidation";
import { landmarkDistrictMapValidationConstants } from '../../Constants/validationConstants';
import useLandmark from "../../hooks/Landmark/useLandmark";

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const LandMarkDistrictMapEdit = () => {
  const store = useStore();
  const params = useParams();
  const history = useHistory();
  const [ready, setReady] = useState(false);
  const [clear, setClear] = useState(true);
  const [landmarkDistrictMap, setLandmarkDistrictMap] = useState([]);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const savedLandmark = useSelector(state => state.landmark.ui.landmark)
  const resource = "RestaurantDistrictMap";
  const helperTextStylesClasses = helperTextStyles();
  const [, setErrorVersion] = useState(LandmarkDistrictMapValidation.getVersion());

  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.LANDMARK_DISTRICT_MAP_TAB.INFO
    }
  ]
  const tempLandmarkDistrictMapEditList = [
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "enName" },
      value: landmarkDistrictMap.districtEnName,
      name: "enName",
      isEndOfLine: false,
      disabled: true,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "tcName" },
      value: landmarkDistrictMap.districtTcName,
      name: "tcName",
      isEndOfLine: false,
      disabled: true,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "scName" },
      value: landmarkDistrictMap.districtScName,
      name: "scName",
      isEndOfLine: true,
      disabled: true,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "status" },
      value: landmarkDistrictMap.status,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.RESTAURANT_DISTRICT.STATUS,
      name: "status",
      isEndOfLine: false,
      disableClearable: true,
      required: true,
      helperTextIcon: true,
      helperText: LandmarkDistrictMapValidation.getErrorMessages(landmarkDistrictMapValidationConstants.KEY_STATUS),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
  ]
  const content = [
    {
      tab: tempLandmarkDistrictMapEditList.map((inputSetting, index) => {
        let content;
        switch (inputSetting.type) {
          case constants.TYPE.SELECT:
            content =
              <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
            break;
          default:
            content =
              <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
        }
        return <div className="edit-display-wrapper" key={index}>
          {content}
          {inputSetting.isEndOfLine && <div></div>}
        </div>
      }),
      btn: true
    }
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  };
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.SHOP.RESTAURANT_LANDMARK + "/" + savedLandmark.id + "/" + constants.LANDMARK_TAB.DISTRICT_LIST);
  };

  function onChangeInput(name, e) {
    let tempLandmarkDistrictMap = _.cloneDeep(landmarkDistrictMap);
    tempLandmarkDistrictMap[`${name}`] = e;
    setLandmarkDistrictMap(tempLandmarkDistrictMap)
  }

  function onClickSave() {
    LandmarkDistrictMapValidation.validateEditLandmarkDistrictMap(landmarkDistrictMap);
    if (LandmarkDistrictMapValidation.isValid()) {
      put(constants.PATH.SHOP.RESTAURANT_LANDMARK_DISTRICT_MAP + "/" + params.id, landmarkDistrictMap, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToEdit,
          dialogContentText: { key: "editSuccess" },
          dialogActions: [
            { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false);
      }, undefined, store);
      LandmarkDistrictMapValidation.resetErrorMessages();
    } else {
      setErrorVersion(LandmarkDistrictMapValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    if (clear === true) {
      getOne(constants.PATH.SHOP.RESTAURANT_LANDMARK_DISTRICT_MAP + "/" + params.id, undefined, (payload) => {
        setLandmarkDistrictMap(payload)
        setReady(true)
        setClear(false)
      }, undefined, store);
    }
    LandmarkDistrictMapValidation.resetErrorMessages();
  }, [clear === true]); // eslint-disable-line react-hooks/exhaustive-deps

  const { getLandmarkIdAndName } = useLandmark()
  const mapCode = getLandmarkIdAndName()

  return (
    ready &&
    <>
      <EditCreateDisplay
        code={landmarkDistrictMap && landmarkDistrictMap.districtId ? landmarkDistrictMap.districtId : ""}
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        mapName={{ resource: "RestaurantLandmark", key: "landmarkId" }}
        mapCode={mapCode}
        display="edit"
      />
      {dialog && dialog.open &&
        <DialogWrapper dialogInfo={dialog} />
      }
    </>
  )
};