import _ from "lodash";

export function setFilterParameters(savedPush) {
  let tempPush = _.cloneDeep(savedPush);
  tempPush.forEach((push) => {
    let tempFilterParameters = {}
    if (push.gender && push.gender !== "all") {
      tempFilterParameters = Object.assign({}, {
        ...tempFilterParameters,
        gender: push.gender,
      });
    }
    if (push.os && push.os !== "all") {
      tempFilterParameters = Object.assign({}, {
        ...tempFilterParameters,
        os: push.os,
      });
    }
    if (push.ageGroupList && push.ageGroupList.length > 0) {
      tempFilterParameters = Object.assign({}, {
        ...tempFilterParameters,
        ageGroupList: push.ageGroupList,
      });
    }
    if (push.mobileList && push.mobileList.length > 0) {
      push.mobileList = push.mobileList.filter((mobile) => mobile.trim());
      tempFilterParameters = Object.assign({}, {
        ...tempFilterParameters,
        mobileList: push.mobileList,
      });
    }
    push.filterParameters = JSON.stringify(tempFilterParameters);
  })
  return tempPush;
}