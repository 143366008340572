import _ from 'lodash'
import { useState, useEffect } from 'react'
import { useStore } from 'react-redux'
import { useParams } from 'react-router-dom'
import { constants as co } from '../../Constants/constants'
import { getList } from '../../helper/baseFetch'
import { Card } from '@mui/material'
import { ListDisplay } from '../../Component/ListDisplay/ListDisplay'
import { generateTableCellToMultiLanguageName } from '../../helper/util'

export default function CounterList({ multipleSelect, createBtn, editBtn, idList, setIdList, objList, setObjList, newFilter, dialogFooterBtn }) {
  const store = useStore()
  const params = useParams()

  const resource = 'Counter'

  const [counterList, setCounterList] = useState([])
  const [ready, setReady] = useState(false)
  const [clear, setClear] = useState(false)
  const [isAllRowsSelected, setIsAllRowsSelected] = useState(false)

  const listTitles = [
    {
      key: co.LIST_DISPLAY_TYPE.STRING,
      value: 'name',
      sort: false,
    },
    {
      key: co.LIST_DISPLAY_TYPE.STRING,
      value: 'status',
      sort: true,
    },
  ]

  const listFilters = [
    {
      className: 'filter-input',
      label: { resource: resource, key: 'name' },
      clear: true,
      value: '',
      name: 'name',
    },
    {
      className: 'filter-input',
      label: { resource: resource, key: 'status' },
      clear: true,
      value: '',
      name: 'status',
    },
  ]

  const [filterOption, setFilterOption] = useState({
    path: '',
    page: 0,
    rowsPerPage: 10,
    sort: 'displayTcName',
    direction: 'ASC',
    listFilters: listFilters,
    count: 0,
  })

  const onChangeFilterOption = (filterOption) => {
    setFilterOption({ ...filterOption, count: filterOption.count + 1 })
  }

  const onChangeListFilters = (listFilters) => {
    onChangeFilterOption({ ...filterOption, listFilters: listFilters, page: 0 })
  }

  const onChangeListSort = (sort) => {
    onChangeFilterOption({ ...filterOption, sort: sort, direction: filterOption.direction === 'DESC' ? 'ASC' : 'DESC' })
  }

  const onChangeListPage = (page) => {
    onChangeFilterOption({ ...filterOption, page: page })
  }

  const onChangeListRowsPerPage = (rowsPerPage) => {
    onChangeFilterOption({ ...filterOption, page: 0, rowsPerPage: rowsPerPage })
  }

  const setSelectAllRows = (thisPageList, selectedIdList) => {
    let selectedRowNum = thisPageList.filter((item) => {
      return selectedIdList.includes(item.id)
    }).length
    let rowNum = thisPageList.length
    if (selectedRowNum === rowNum && selectedRowNum !== 0 && rowNum !== 0) {
      setIsAllRowsSelected(true)
    } else {
      setIsAllRowsSelected(false)
    }
  }

  const onMultiSelectList = (id) => {
    let newIdList = idList ? _.cloneDeep(idList) : []
    let newObjList = _.cloneDeep(objList)
    if (id === 'all') {
      if (isAllRowsSelected === true) {
        const counterIdList = counterList.map((item) => item.id)
        newIdList = newIdList.filter((item) => !counterIdList.includes(item))
        newObjList = newObjList.filter((itemA) => !counterList.some((itemB) => itemB.id === itemA.id))
      } else {
        counterList.map((item) => {
          newObjList.push(item)
          return newIdList.push(item.id)
        })
        newObjList = newObjList.filter((item) => newIdList.includes(item.id))
      }
      setIsAllRowsSelected(!isAllRowsSelected)
    } else {
      if (newIdList.includes(id)) {
        newIdList = newIdList.filter((item) => item !== id)
        newObjList = newObjList.filter((item) => item.id !== id)
      } else {
        newIdList.push(id)
        newObjList.push.apply(
          newObjList,
          counterList.filter((item) => item.id === id)
        )
      }
    }
    setSelectAllRows(counterList, newIdList)
    const uniqueIdList = newIdList.filter((item, i) => {
      return newIdList.indexOf(item) === i
    })
    const uniqueObjList = newObjList.filter((item, i, arr) => arr.findIndex((el) => el.id === item.id) === i)
    setIdList(uniqueIdList)
    setObjList(uniqueObjList)
  }

  useEffect(() => {
    console.log('a')
    let newFilterOption = _.cloneDeep(filterOption)
    const filter = newFilter || { id: params.id }
    newFilterOption.listFilters.forEach((item) => {
      if (item.value !== '' && item.value !== undefined) {
        filter[item.name] = item.value
      }
    })

    getList(
      co.PATH.MERCHANT.COUNTER,
      {
        sort: { field: newFilterOption.sort, order: newFilterOption.direction },
        filter: filter,
        pagination: { page: newFilterOption.page + 1, perPage: newFilterOption.rowsPerPage },
      },
      (payload) => {
        payload.forEach((item) => {
          item.name = generateTableCellToMultiLanguageName(item.displayTcName, item.displayEnName, item.displayScName)
        })
        setCounterList(payload)
        multipleSelect && setSelectAllRows(payload, idList)
        setReady(true)
        setClear(false)
      },
      undefined,
      store
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOption.count, clear === true, isAllRowsSelected])

  return (
    ready && (
      <Card className="card-wrapper overflow">
        <ListDisplay
          page={filterOption.page}
          rowsPerPage={filterOption.rowsPerPage}
          onChangeListSort={onChangeListSort}
          resource={resource}
          dataList={counterList}
          sort={filterOption.sort}
          direction={filterOption.direction}
          listTitles={listTitles}
          listFilters={filterOption.listFilters}
          onChangeListPage={onChangeListPage}
          onChangeListRowsPerPage={onChangeListRowsPerPage}
          idParam={params.id}
          onChangeListFilters={onChangeListFilters}
          redirectPath={`/${co.PATH.MERCHANT.COUNTER}/`}
          editRedirectPath={`/${co.PATH.MERCHANT.COUNTER}/edit/${params.id}/`}
          createBtn={createBtn}
          editBtn={editBtn}
          mutipleSelect={multipleSelect}
          onMultiSelectList={onMultiSelectList}
          selectedAllCheck={isAllRowsSelected}
          idName="id"
          previewSelectedIdList={idList ? idList : []}
          dialogFooterBtn={dialogFooterBtn}
        />
      </Card>
    )
  )
}
