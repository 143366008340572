import { restaurantBlackoutValidationConstants } from '../Constants/validationConstants';

class RestaurantBlackoutValidation {

  version;
  errorMap;
  restaurantBlackoutErrorMap;
  valid;

  constructor() {

    if (!RestaurantBlackoutValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.restaurantBlackoutErrorMap = new Map();
      RestaurantBlackoutValidation.instance = this;
    }
    return RestaurantBlackoutValidation.instance;
  }

  validateEditRestaurantBlackout(restaurantBlackout) {
    this.resetErrorMessages();
    this.handleValidateEditRestaurantBlackout(restaurantBlackout);
    this.version++;
  }

  validateCreateRestaurantBlackout(restaurantBlackout) {
    this.resetErrorMessages();
    this.handleValidateCreateRestaurantBlackout(restaurantBlackout);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.restaurantBlackoutErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key, uniqueCode) {
    if (!uniqueCode) {
      return (this.restaurantBlackoutErrorMap.has(key)) ? this.restaurantBlackoutErrorMap.get(key) : [];
    }
  }

  handleValidateEditRestaurantBlackout(restaurantBlackout) {
    // start end timestamp
    const blackoutPeriodStartTimestamp = Number(restaurantBlackout.startTimestamp);
    const blackoutPeriodEndTimestamp = Number(restaurantBlackout.endTimestamp);
    if ( !blackoutPeriodStartTimestamp || isNaN(blackoutPeriodStartTimestamp) || blackoutPeriodStartTimestamp === 0) {
      this.setRestaurantBlackoutErrorMapMessages(restaurantBlackoutValidationConstants.KEY_START_TIMESTAMP, "Invalid blackout period start timestamp");
    }
    if ( !blackoutPeriodEndTimestamp || isNaN(blackoutPeriodEndTimestamp) || blackoutPeriodEndTimestamp === 0) {
      this.setRestaurantBlackoutErrorMapMessages(restaurantBlackoutValidationConstants.KEY_END_TIMESTAMP, "Invalid blackout period end timestamp");
    }
    if (!isNaN(blackoutPeriodStartTimestamp) && !isNaN(blackoutPeriodEndTimestamp) && blackoutPeriodStartTimestamp > blackoutPeriodEndTimestamp) {
      this.setRestaurantBlackoutErrorMapMessages(restaurantBlackoutValidationConstants.KEY_END_TIMESTAMP, "Blackout period start timestamp is larger than blackout period end timestamp");
    }

    if (!restaurantBlackout.refCode) {
      this.setRestaurantBlackoutErrorMapMessages(restaurantBlackoutValidationConstants.KEY_REFCODE, "Missing RefCode");
    }

    if (!restaurantBlackout.status) {
      this.setRestaurantBlackoutErrorMapMessages(restaurantBlackoutValidationConstants.KEY_STATUS, "Missing Status");
    }
    if (!restaurantBlackout.type) {
      this.setRestaurantBlackoutErrorMapMessages(restaurantBlackoutValidationConstants.KEY_TYPE, "Missing Type");
    }
  }

  handleValidateCreateRestaurantBlackout(restaurantBlackoutList) {

    for(let i=0; i<restaurantBlackoutList.length; i++){

      const restaurantBlackout = restaurantBlackoutList[i];
      const temptId = restaurantBlackout.temptId;

      const blackoutPeriodStartTimestamp = Number(restaurantBlackout.startTimestamp);
      const blackoutPeriodEndTimestamp = Number(restaurantBlackout.endTimestamp);
      if ( !blackoutPeriodStartTimestamp || isNaN(blackoutPeriodStartTimestamp) || blackoutPeriodStartTimestamp === 0) {
        this.setRestaurantBlackoutErrorMapMessages(`${temptId}-${restaurantBlackoutValidationConstants.KEY_START_TIMESTAMP}`, "Invalid blackout period start timestamp");
      }
      if ( !blackoutPeriodEndTimestamp || isNaN(blackoutPeriodEndTimestamp) || blackoutPeriodEndTimestamp === 0) {
        this.setRestaurantBlackoutErrorMapMessages(`${temptId}-${restaurantBlackoutValidationConstants.KEY_END_TIMESTAMP}`, "Invalid blackout period end timestamp");
      }
      if (!isNaN(blackoutPeriodStartTimestamp) && !isNaN(blackoutPeriodEndTimestamp) && blackoutPeriodStartTimestamp > blackoutPeriodEndTimestamp) {
        this.setRestaurantBlackoutErrorMapMessages(`${temptId}-${restaurantBlackoutValidationConstants.KEY_END_TIMESTAMP}`, "Blackout period start timestamp is larger than blackout period end timestamp");
      }

      if (!restaurantBlackout.refCode) {
        this.setRestaurantBlackoutErrorMapMessages(`${temptId}-${restaurantBlackoutValidationConstants.KEY_REFCODE}`, "Missing RefCode");
      }

      if (!restaurantBlackout.type) {
        this.setRestaurantBlackoutErrorMapMessages(`${temptId}-${restaurantBlackoutValidationConstants.KEY_TYPE}`, "Missing Type");
      }

    }
  }


  setRestaurantBlackoutErrorMapMessages(key, errorMessages, valid = false) {
    this.restaurantBlackoutErrorMap.set(key, errorMessages);
    // console.log("restaurantMediaErrorMap", this.restaurantMediaErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new RestaurantBlackoutValidation();
export default validation;