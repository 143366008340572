import _ from "lodash";
import { CHARGE_DATE } from '../actions/types';
import { constants } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        chargeDateList: _.cloneDeep(constants.CHARGE_DATE_LIST),
        chargeDate: _.cloneDeep(constants.CHARGE_DATE_EDIT_INPUT),
    }
}

const chargeDateReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CHARGE_DATE.SET_CHARGE_DATE_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case CHARGE_DATE.SET_CHARGE_DATE:
        case CHARGE_DATE.SET_CHARGE_DATE_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    chargeDate: action.chargeDate
                }
            })
        case CHARGE_DATE.SET_CHARGE_DATE_LIST:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    chargeDateList: action.chargeDateList
                }
            })
        default:
            return state
    }
}

export default chargeDateReducer