import { LANDING_ITEM_TYPE } from '../../../Constants/constants';
import { useSelector } from "react-redux";
import { getString } from '../../../helper/util';
import { Image } from '@mui/icons-material';

// const textPreview = (text) =>{
//   if(text.length<=3) return text;
//   const preview = text.substring(0,4)+"...";
//   return preview;
// }

const PromotionTag = ({src}) =>{
  return (
    <div className="grid-tile-promotion-tag">
      <img src={src} alt="promotion-tag"/>
    </div>
  )
}

const GuluProductGridTileHorizontal = ({item, previewMode, hasMinWidth}) =>{

  return(
    <div className={`gulu-production-grid-tile-horizontal ${hasMinWidth?"large-img":""}`}>
      <div className="gulu-production-grid-tile-horizontal-image">
      {
        item?.tcImage?.cosFileUrl? 
          <img className="gulu-img" src={item.tcImage.cosFileUrl} title="Image" alt="img"/>
          : <Image className="gulu-img" />
      }
      {
        item.promotionTagImage && <PromotionTag src={item.promotionTagImage}/>
      }
      </div>
      <div className="gulu-production-grid-tile-horizontal-description">
      {
      item.tcTitle &&
      <div><strong className={`${previewMode?'text-preview': ''}`}>{item.tcTitle}</strong></div>
      }
      {
        item.tcSubTitle &&
        <div className={`${previewMode?'text-preview': ''}`}>{item.tcSubTitle}</div>
      }
      <div>
        <div className="gulu-production-grid-tile-horizontal-original-price"><s>$xx</s></div>
        <div className="gulu-production-grid-tile-horizontal-selling-price"><strong>$xx</strong></div>
      </div>
      </div>
    </div>
  )
}

const GuluEcouponGridTile = ({item, previewMode, hasMinWidth}) =>{

  const lang = useSelector(state => state.lang.ui.lang);
  const theme = useSelector(state => state.theme.ui.theme);
  const resource = 'LandingSection'

  return(
    <div className={`gulu-ecoupon-grid-tile ${hasMinWidth?"large-img":""}`}>
      {
        <div className="gulu-ecoupon-grid-tile-img">
        {item?.tcImage?.cosFileUrl? 
          <img className="gulu-img" src={item.tcImage.cosFileUrl} title="Image" alt="img"/>
          : <Image className="gulu-img" />}
          {
            item.promotionTagImage && <PromotionTag src={item.promotionTagImage}/>
          }
        </div>
      }
      <div className="gulu-ecoupon-grid-tile-description">
      {
      item.tcTitle &&
      <div><strong className={`${previewMode?'text-preview': ''}`}>{item.tcTitle}</strong></div>
      }
      {
        item.tcSubTitle &&
        <div className={`${previewMode?'text-preview': ''}`}>{item.tcSubTitle}</div>
      }
      <div className='gulu-ecoupon-grid-tile-button'>
        {getString(lang, resource, "freeCollect", theme)}
      </div>
      </div>
    </div>
  )
}

const GuluGridTile = ({item, previewMode, hideTitle, hasMinWidth}) =>{

  return(
    <div className={`gulu-grid-tile ${hasMinWidth?"large-img":""}`}>
      {
        <div className="gulu-grid-tile-img">
        {item?.tcImage?.cosFileUrl? 
          <img className="gulu-img" src={item.tcImage.cosFileUrl} title="Image" alt="img"/>
          : <Image className="gulu-img" />}
          {
            item.promotionTagImage && <PromotionTag src={item.promotionTagImage}/>
          }
        </div>
      }
      {
        !hideTitle &&
        <div className="gulu-grid-tile-description">
      {
      item.tcTitle &&
      <div><strong className={`${previewMode?'text-preview': ''}`}>{item.tcTitle}</strong></div>
      }
      {
        item.tcSubTitle &&
        <div className={`${previewMode?'text-preview': ''}`}>{item.tcSubTitle}</div>
      }
      </div>
      }
    </div>
  )
}

const GuluBanner = ({item, previewMode, hasMinWidth}) =>{

  return(
    <div className="gulu-banner">
    {
      item?.tcImage?.cosFileUrl? 
      <img className="gulu-img" src={item.tcImage.cosFileUrl} title="Image" alt="img"/>
      : <Image className="gulu-img" />
    }
    {
      item.promotionTagImage && <PromotionTag src={item.promotionTagImage}/>
    }
    </div>
  )
}

const GuluCardGridTile = ({item, previewMode, hasMinWidth}) =>{

  return(
    <div className={`gulu-card-grid-tile ${hasMinWidth?"large-img":""}`}>
      <div className="gulu-card-grid-tile-img">
      {
        item?.tcImage?.cosFileUrl? 
          <img className="gulu-img" src={item.tcImage.cosFileUrl} title="Image" alt="img"/>
          : <Image className="gulu-img" />
      }
      {
        item.promotionTagImage && <PromotionTag src={item.promotionTagImage}/>
      }
      </div>
      <div className="gulu-card-grid-tile-description">
      {
      item.tcTitle &&
      <div><strong className={`${previewMode?'text-preview': ''}`}>{item.tcTitle}</strong></div>
      }
      {
        item.tcSubTitle &&
        <div className={`${previewMode?'text-preview': ''}`}>{item.tcSubTitle}</div>
      }
      </div>
    </div>
  )
}

const GuluFramedGridTile = ({item, previewMode, hasMinWidth}) =>{

  return(
    <div className={`gulu-framed-grid-tile ${hasMinWidth?"large-img":""}`}>
      {
      item.borderColor && item.color?
      (
          <div className="landing-image-list-content-foreground" style={{background: `${item.color}`, border: `5px solid ${item.borderColor}`}}>
            <div className="gulu-framed-grid-tile-img">
            {
              item?.tcImage?.cosFileUrl? 
              <img className="gulu-img" src={item.tcImage.cosFileUrl} title="Image" alt="img"/>
              : <Image className="gulu-img" />
            }
            {
              item.promotionTagImage && <PromotionTag src={item.promotionTagImage}/>
            }
            </div>
          </div>
      ):
      (
        <div className="landing-image-list-content-foreground" style={{background: "#F2B500", border: "5px solid #FFE900"}}>
          <div className="gulu-framed-grid-tile-img">
          {
            item?.tcImage?.cosFileUrl? 
            <img className="gulu-img" src={item.tcImage.cosFileUrl} title="Image" alt="img"/>
            : <Image className="gulu-img" />
          }
          {
            item.promotionTagImage && <PromotionTag src={item.promotionTagImage}/>
          }
          </div>
        </div>
      )
    }
      <div className="gulu-framed-grid-tile-description">
      {
      item.tcTitle &&
      <div><strong className={`${previewMode?'text-preview': ''}`}>{item.tcTitle}</strong></div>
      }
      {
        item.tcSubTitle &&
        <div className={`${previewMode?'text-preview': ''}`}>{item.tcSubTitle}</div>
      }
      </div>
    </div>
  )
}

const RecommendByLocationShopTile = ({item, previewMode, hasMinWidth}) =>{

  return(
    <div className="recommend-by-location-shop-tile">
      <div className="recommend-by-location-shop-tile-img-wrapper">
      <div className="recommend-by-location-shop-tile-img">
      {
        item?.tcImage?.cosFileUrl? 
          <img className="gulu-img" src={item.tcImage.cosFileUrl} title="Image" alt="img"/>
          : <Image className="gulu-img" />
      }
      {
        item.promotionTagImage && <PromotionTag src={item.promotionTagImage}/>
      }
      </div>
      </div>
      <div className="gulu-card-grid-tile-description">
      {
      item.tcTitle &&
      <div><strong className={`${previewMode?'text-preview': ''}`}>{item.tcTitle}</strong></div>
      }
      {
        item.tcSubTitle &&
        <div className={`${previewMode?'text-preview': ''}`}>{item.tcSubTitle}</div>
      }
      </div>
    </div>
  )
}

export const LandingItem=({item, previewMode, hasMinWidth})=>{

  switch(item.itemType){
    case LANDING_ITEM_TYPE.BANNER_IMAGE:
      return <GuluBanner item={item} previewMode={previewMode} hasMinWidth={hasMinWidth}/>
    case LANDING_ITEM_TYPE.COUPON:
      return <GuluEcouponGridTile item={item} previewMode={previewMode} hasMinWidth={hasMinWidth}/>
    case LANDING_ITEM_TYPE.PRODUCT:
    case LANDING_ITEM_TYPE.PRODUCT_LARGE:
    case LANDING_ITEM_TYPE.VALUE_UP_ECOUPON:
      return <GuluProductGridTileHorizontal item={item} previewMode={previewMode} hasMinWidth={hasMinWidth}/>
    case LANDING_ITEM_TYPE.IMAGE:
    case LANDING_ITEM_TYPE.IMAGE_AD:
    case LANDING_ITEM_TYPE.IMAGE_TEXT:
    case LANDING_ITEM_TYPE.IMAGE_TEXT_AD:
      return <GuluCardGridTile item={item} previewMode={previewMode} hasMinWidth={hasMinWidth}/>
    case LANDING_ITEM_TYPE.TICKET_IMAGE:
    case LANDING_ITEM_TYPE.TICKET_IMAGE_AD:
    case LANDING_ITEM_TYPE.TICKET_IMAGE_TEXT:
    case LANDING_ITEM_TYPE.TICKET_IMAGE_TEXT_AD:
      return <GuluFramedGridTile item={item} previewMode={previewMode} hasMinWidth={hasMinWidth}/>
    case LANDING_ITEM_TYPE.LARGE_ROUND_IMAGE_TEXT:
    case LANDING_ITEM_TYPE.LARGE_ROUND_IMAGE_TEXT_AD:
      return <GuluGridTile item={item} previewMode={previewMode} hasMinWidth={hasMinWidth}/>
    case LANDING_ITEM_TYPE.RECOMMEND_BY_LOCATION:
      return <RecommendByLocationShopTile item={item}/>
    case LANDING_ITEM_TYPE.LARGE_ROUND_IMAGE:
    case LANDING_ITEM_TYPE.LARGE_ROUND_IMAGE_AD:
    case LANDING_ITEM_TYPE.SQUARE_ROUND_IMAGE:
    case LANDING_ITEM_TYPE.SQUARE_ROUND_IMAGE_AD:
    default:
      return <GuluGridTile item={item} previewMode={previewMode} hideTitle={true} hasMinWidth={hasMinWidth}/>
  }
  
}