import { RESTAURANT_SLIDESHOW } from './types';
import _ from "lodash";
import { SLIDESHOW_TYPE } from '../Constants/constants';

export const setRestaurantSlideshowDefault = () => {
    return {
        type: RESTAURANT_SLIDESHOW.SET_RESTAURANT_SLIDESHOW_DEFAULT,
    }
}

export const setRestaurantSlideshow = (restaurantSlideshow) => {
    return {
        type: RESTAURANT_SLIDESHOW.SET_RESTAURANT_SLIDESHOW,
        restaurantSlideshow: restaurantSlideshow
    }
}

export const setRestaurantSlideshowValue = (draftType, orignalProduct, key, value, index) => {
    const restaurantSlideshow = _.cloneDeep(orignalProduct);
    switch (draftType) {
        case "MEDIA":
            if(!restaurantSlideshow.media){
                restaurantSlideshow.media = {};
            }
            restaurantSlideshow.media.cosFileKey = value.cosFileKey || null;
            restaurantSlideshow.media.cosFileUrl = value.cosFileUrl;
            restaurantSlideshow.type = value.type === 'video/mp4'? 'VIDEO': 'IMAGE';

            if(restaurantSlideshow.type === SLIDESHOW_TYPE.VIDEO) {
                restaurantSlideshow.media.mime = value.mime;
                restaurantSlideshow.media.duration = value.duration;
            }

            // console.log('action', value, restaurantSlideshow)
            break;
        default:
            restaurantSlideshow[draftType] = value;
    }
    return {
        type: RESTAURANT_SLIDESHOW.SET_RESTAURANT_SLIDESHOW_VALUE,
        restaurantSlideshow: restaurantSlideshow
    }
}
