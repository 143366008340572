import { DISTRICT } from './types';
import { sify } from "chinese-conv";
import _ from "lodash";

export const setDistrictDefault = () => {
    return {
        type: DISTRICT.SET_DISTRICT_DEFAULT,
    }
}

export const setDistrict = (district) => {
    return {
        type: DISTRICT.SET_DISTRICT,
        district: district
    }
}

export const setDistrictValue = (draftType, orignalProduct, key, value, index) => {
    const district = _.cloneDeep(orignalProduct);
    switch (draftType) {
        case "CHANGE_TC_NAME_AND_SC_NAME":
            district.tcName = value;
            district.scName = sify(value);
            break;
        case "scNameDisable":
            district[draftType] = value;
            if(value) {
                district.scName = district.tcName? sify(district.tcName): district.tcName;
            }
            break;
        default:
            district[draftType] = value;
    }
    return {
        type: DISTRICT.SET_DISTRICT_VALUE,
        district: district
    }
}
