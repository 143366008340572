
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
 
export const Loading = ({ openLoading }) => {
  const classes = useStyles();

return(
  <Backdrop 
    className={classes.backdrop} 
    style={{zIndex: 1400}}
    open={openLoading} 
  >
    <CircularProgress color="inherit" />
  </Backdrop>
)};
 