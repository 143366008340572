import { selectTagValidationConstants } from '../Constants/validationConstants';

class SelectTagValidation {

  version;
  errorMap;
  selectTagErrorMap;
  valid;

  constructor() {

    if (!SelectTagValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.selectTagErrorMap = new Map();
      SelectTagValidation.instance = this;
    }
    return SelectTagValidation.instance;
  }

  validateEditSelectTag(selectTag) {
    this.resetErrorMessages();
    this.handleValidateEditSelectTag(selectTag);
    this.version++;
  }

  validateEditSelectTagConfig(selectTag) {
    this.resetErrorMessages();
    this.handleValidateSelectTagConfig(selectTag);
    this.version++;
  }

  validateCreateSelectTag(selectTag) {
    this.resetErrorMessages();
    this.handleValidateSelectTag(selectTag);
    this.version++;
  }

  validateCreateSelectTagConfigOptions(selectTag) {
    this.resetErrorMessages();
    this.handleValidateSelectTagConfigOptions(selectTag);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.selectTagErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key, uniqueCode) {
    if (!uniqueCode) {
      return (this.selectTagErrorMap.has(key)) ? this.selectTagErrorMap.get(key) : [];
    }
  }

  handleValidateEditSelectTag(selectTag) { //to-do not being used

    if (!selectTag.enName && !selectTag.tagEnName) {
      this.setSelectTagErrorMapMessages(selectTagValidationConstants.KEY_EN_NAME, "Missing EN Name");
    }
    if (!selectTag.tcName && !selectTag.tagTcName) {
      this.setSelectTagErrorMapMessages(selectTagValidationConstants.KEY_TC_NAME, "Missing TC Name");
    }
    if (!selectTag.scName && !selectTag.tagScName) {
      this.setSelectTagErrorMapMessages(selectTagValidationConstants.KEY_SC_NAME, "Missing SC Name");
    }

  }

  handleValidateSelectTag(selectTagList) {

    let existSelectTagSet = new Set();

    for(let i=0; i<selectTagList.length;i++){
      if (!selectTagList[i].enName && !selectTagList[i].tagEnName) {
        this.setSelectTagErrorMapMessages(`${i}-${selectTagValidationConstants.KEY_EN_NAME}`, "Missing EN Name");
      }
      if (!selectTagList[i].tcName && !selectTagList[i].tagTcName) {
        this.setSelectTagErrorMapMessages(`${i}-${selectTagValidationConstants.KEY_TC_NAME}`, "Missing TC Name");
      }
      if (!selectTagList[i].scName && !selectTagList[i].tagScName) {
        this.setSelectTagErrorMapMessages(`${i}-${selectTagValidationConstants.KEY_SC_NAME}`, "Missing SC Name");
      }

      let key = `${selectTagList[i].enName}-${selectTagList[i].tcName}-${selectTagList[i].scName}`;

      if(existSelectTagSet.has(key)){
        this.setSelectTagErrorMapMessages(`${i}-${selectTagValidationConstants.KEY_EN_NAME}`, "Same Name Already Exist");
        this.setSelectTagErrorMapMessages(`${i}-${selectTagValidationConstants.KEY_TC_NAME}`, "Same Name Already Exist");
        this.setSelectTagErrorMapMessages(`${i}-${selectTagValidationConstants.KEY_SC_NAME}`, "Same Name Already Exist");
      }

      existSelectTagSet.add(key);
    }
    
  }

  handleValidateSelectTagConfig(selectTag) {

    if (!selectTag.enName && !selectTag.tagEnName) {
      this.setSelectTagErrorMapMessages(selectTagValidationConstants.KEY_EN_NAME, "Missing EN Name");
    }
    if (!selectTag.tcName && !selectTag.tagTcName) {
      this.setSelectTagErrorMapMessages(selectTagValidationConstants.KEY_TC_NAME, "Missing TC Name");
    }
    if (!selectTag.scName && !selectTag.tagScName) {
      this.setSelectTagErrorMapMessages(selectTagValidationConstants.KEY_SC_NAME, "Missing SC Name");
    }
    if(!selectTag.type) {
      this.setSelectTagErrorMapMessages(selectTagValidationConstants.KEY_TYPE, "Missing Type");
    }
    if(!selectTag.status) {
      this.setSelectTagErrorMapMessages(selectTagValidationConstants.KEY_STATUS, "Missing Status");
    }
    if(selectTag.sequence===null) {
      this.setSelectTagErrorMapMessages(selectTagValidationConstants.KEY_DISPLAY_SEQUENCE, "Missing Sequence");
    }
    if(selectTag.sequence<0) {
      this.setSelectTagErrorMapMessages(selectTagValidationConstants.KEY_DISPLAY_SEQUENCE, "Positive Number Required");
    }

  }

  handleValidateSelectTagConfigOptions(selectTagOptions) {
    
    let existSelectTagSet = new Set();

    for(let i=0; i<selectTagOptions.length; i++){
      if (!selectTagOptions[i].enName && !selectTagOptions[i].tagEnName) {
        this.setSelectTagErrorMapMessages(`${i}-${selectTagValidationConstants.KEY_EN_NAME}`, "Missing EN Name");
      }
      if (!selectTagOptions[i].tcName && !selectTagOptions[i].tagTcName) {
        this.setSelectTagErrorMapMessages(`${i}-${selectTagValidationConstants.KEY_TC_NAME}`, "Missing TC Name");
      }
      if (!selectTagOptions[i].scName && !selectTagOptions[i].tagScName) {
        this.setSelectTagErrorMapMessages(`${i}-${selectTagValidationConstants.KEY_SC_NAME}`, "Missing SC Name");
      }
      if(!selectTagOptions[i].type) {
        this.setSelectTagErrorMapMessages(`${i}-${selectTagValidationConstants.KEY_TYPE}`, "Missing Type");
      }
      if(!selectTagOptions[i].status) {
        this.setSelectTagErrorMapMessages(`${i}-${selectTagValidationConstants.KEY_STATUS}`, "Missing Status");
      }
      if(selectTagOptions[i].sequence===null) {
        this.setSelectTagErrorMapMessages(`${i}-${selectTagValidationConstants.KEY_DISPLAY_SEQUENCE}`, "Missing Sequence");
      }
      if(selectTagOptions[i].sequence<0) {
        this.setSelectTagErrorMapMessages(`${i}-${selectTagValidationConstants.KEY_DISPLAY_SEQUENCE}`, "Positive Number Required");
      }
      if(selectTagOptions[i].sequence && !Number.isInteger(selectTagOptions[i].sequence)){
        this.setSelectTagErrorMapMessages(`${i}-${selectTagValidationConstants.KEY_DISPLAY_SEQUENCE}`, "Number Required");
      }
      let key = `${selectTagOptions[i].tagCode}-${selectTagOptions[i].type}`;
      if(existSelectTagSet.has(key)){
        this.setSelectTagErrorMapMessages(`${i}-${selectTagValidationConstants.KEY_TYPE}`, "Select Tag of Same Type Already Exists");
      }
      existSelectTagSet.add(key);
    }

  }



  setSelectTagErrorMapMessages(key, errorMessages, valid = false) {
    this.selectTagErrorMap.set(key, errorMessages);
    // console.log("ErrorMap", this.selectTagErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new SelectTagValidation();
export default validation;