import { useState, useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import { Drawer } from '@mui/material'
import { Card } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { constants, RESERVATION_TEMPLATE_MAP_TYPE } from '../../Constants/constants'
import { post } from '../../helper/baseFetch'
import { setReservationTemplateMapList } from '../../actions/reservationTemplateMapAction'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { TempTextInput } from '../../Component/Input/TempTextInput'
import { TempAutoSelectInput } from '../../Component/Input/TempAutoSelectInput'
import { TempBtn } from '../../Component/Input/TempBtn'
import { ReservationTemplateList } from '../ReservationTemplate/ReservationTemplateList'
import ReservationTemplateMapValidation from '../../validations/reservationTemplateMapValidation'
import { reservationTemplateMapValidationConstants } from '../../Constants/validationConstants'
import { DialogWrapper } from '../../Component/DialogWrapper'

const helperTextStyles = makeStyles((theme) => ({
  root: {
    color: 'red !important',
  },
}))

export const ReservationTemplateMapCreate = ({ toolBar, mapName, mapCode, tab }) => {
  const dispatch = useDispatch()
  const store = useStore()
  const history = useHistory()
  const [clear, setClear] = useState(false)
  const savedReservationTemplateMapList = useSelector((state) => state.reservationTemplateMap.ui.reservationTemplateMapList)
  const params = useParams()
  const [showTemplateCodePanel, setShowTemplateCodePanel] = useState(false)
  const [drawerIndex, setDrawerIndex] = useState('')
  const [customIdIndex, setCustomIdIndex] = useState(0)
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  const helperTextStylesClasses = helperTextStyles()
  const [, setErrorVersion] = useState(ReservationTemplateMapValidation.getVersion())

  const resource = 'ReservationTemplateMap'
  const tabs = [
    {
      label: { resource: resource, key: 'info' },
      tabIndex: constants.RESERVATION_TEMPLATE_MAP_TAB.INFO,
    },
  ]

  const content = [
    {
      tab: (
        <>
          <TempBtn
            btnSetting={{
              variant: constants.STYLE.VARIANT.CONTAINED,
              color: constants.STYLE.PRIMARY,
              label: { resource: resource, key: 'addReservationTemplateMap' },
              disabled: false,
              onClick: () => handleAddReservationTemplateMap(),
              name: 'handleAddReservationTemplateMap',
              type: constants.TYPE.BUTTON,
              icon: <AddIcon />,
            }}
          />
          {savedReservationTemplateMapList.map((item, index) => {
            return (
              <Card className="card-wrapper card" key={index}>
                <div className="delete-icon-row">
                  <TempBtn
                    btnSetting={{
                      className: 'edit-create-btn',
                      variant: constants.STYLE.VARIANT.CONTAINED,
                      color: constants.STYLE.PRIMARY,
                      onClick: () => handleDelete(index),
                      label: { key: 'deleteBtn' },
                      icon: <DeleteIcon />,
                    }}
                  />
                </div>
                <div className="delete-icon-row">
                  <TempBtn
                    btnSetting={{
                      className: 'edit-create-btn',
                      variant: constants.STYLE.VARIANT.CONTAINED,
                      color: constants.STYLE.PRIMARY,
                      onClick: () => handleClone(index),
                      label: { key: 'Clone' },
                      icon: <FileCopyIcon />,
                    }}
                  />
                </div>
                <TempTextInput
                  inputSetting={{
                    className: 'edit-create-input',
                    size: constants.SIZE.SMALL,
                    label: { resource: resource, key: 'description' },
                    value: item.description,
                    name: 'description',
                    multiline: true,
                  }}
                  handleChange={onChangeInput}
                  index={index}
                />
                <div>
                  <TempAutoSelectInput
                    inputSetting={{
                      className: 'edit-create-input',
                      size: constants.SIZE.SMALL,
                      label: { resource: resource, key: 'type' },
                      value: item.type,
                      type: constants.TYPE.SELECT,
                      selectValue: constants.CHOICES.RESERVATION_TEMPLATE_MAP.TYPE,
                      name: 'type',
                      disableClearable: true,
                      helperTextIcon: true,
                      helperText: ReservationTemplateMapValidation.getErrorMessages(
                        reservationTemplateMapValidationConstants.KEY_TYPE,
                        item.customId
                      ).map((error) => {
                        return error
                      }),
                      FormHelperTextProps: { className: helperTextStylesClasses.root },
                    }}
                    handleChange={onChangeInput}
                    index={index}
                  />
                  {item.type === RESERVATION_TEMPLATE_MAP_TYPE.DAY_OF_WEEK && (
                    <TempAutoSelectInput
                      inputSetting={{
                        className: 'edit-create-input',
                        size: constants.SIZE.SMALL,
                        label: { resource: resource, key: 'dayOfWeek' },
                        value: item.dayOfWeek,
                        type: constants.TYPE.SELECT,
                        selectValue: constants.CHOICES.RESERVATION_TEMPLATE_MAP.DAY_OF_WEEK,
                        disableClearable: true,
                        name: 'dayOfWeek',
                        helperTextIcon: true,
                        helperText: ReservationTemplateMapValidation.getErrorMessages(
                          reservationTemplateMapValidationConstants.KEY_DAY_OF_WEEK,
                          item.customId
                        ).map((error) => {
                          return error
                        }),
                        FormHelperTextProps: { className: helperTextStylesClasses.root },
                      }}
                      handleChange={onChangeInput}
                      index={index}
                    />
                  )}
                  {item.type === RESERVATION_TEMPLATE_MAP_TYPE.DATE_RANGE && (
                    <>
                      <TempTextInput
                        inputSetting={{
                          className: 'edit-create-input',
                          label: { resource: resource, key: 'startDate' },
                          value: item.startDate ? moment(item.startDate).format('YYYY-MM-DD') : '',
                          name: 'startDate',
                          type: constants.TYPE.DATE,
                          size: constants.SIZE.SMALL,
                          helperTextIcon: true,
                          helperText: ReservationTemplateMapValidation.getErrorMessages(
                            reservationTemplateMapValidationConstants.KEY_START_DATE,
                            item.customId
                          ).map((error) => {
                            return error
                          }),
                          FormHelperTextProps: { className: helperTextStylesClasses.root },
                        }}
                        handleChange={onChangeInput}
                        index={index}
                      />
                      <TempTextInput
                        inputSetting={{
                          className: 'edit-create-input',
                          label: { resource: resource, key: 'endDate' },
                          value: item.endDate ? moment(item.endDate).format('YYYY-MM-DD') : '',
                          name: 'endDate',
                          type: constants.TYPE.DATE,
                          size: constants.SIZE.SMALL,
                          helperTextIcon: true,
                          helperText: ReservationTemplateMapValidation.getErrorMessages(
                            reservationTemplateMapValidationConstants.KEY_END_DATE,
                            item.customId
                          ).map((error) => {
                            return error
                          }),
                          FormHelperTextProps: { className: helperTextStylesClasses.root },
                        }}
                        handleChange={onChangeInput}
                        index={index}
                      />
                    </>
                  )}
                </div>
                <div>
                  <TempTextInput
                    inputSetting={{
                      className: 'edit-create-input',
                      size: constants.SIZE.SMALL,
                      label: { resource: resource, key: 'templateCode' },
                      value: item.templateCode,
                      onClickEndAdornmentIconButton: () => {
                        handleTemplateCodeClick()
                        setDrawerIndex(index)
                      },
                      name: 'templateCode',
                      disabled: true,
                      multiline: true,
                      helperTextIcon: true,
                      helperText: ReservationTemplateMapValidation.getErrorMessages(
                        reservationTemplateMapValidationConstants.KEY_TEMPLATE_CODE,
                        item.customId
                      ).map((error) => {
                        return error
                      }),
                      FormHelperTextProps: { className: helperTextStylesClasses.root },
                    }}
                    handleChange={onChangeInput}
                    index={index}
                  />
                  <TempTextInput
                    inputSetting={{
                      className: 'edit-create-input',
                      variant: constants.STYLE.VARIANT.STANDARD,
                      size: constants.SIZE.SMALL,
                      label: { resource: resource, key: 'templateDescription' },
                      value: item.templateDescription,
                      name: 'templateDescription',
                      disabled: true,
                      multiline: true,
                    }}
                    handleChange={onChangeInput}
                    index={index}
                  />
                </div>
              </Card>
            )
          })}
        </>
      ),
      btn: true,
    },
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'save' },
      onClick: onClickSave,
      className: 'margin-left-16',
      icon: <SaveIcon />,
      disabled: !params.id || savedReservationTemplateMapList.length === 0,
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'clear' },
      disabled: false,
      onClick: onClickClear,
      className: 'margin-left-16',
      icon: <ClearIcon />,
    },
  ]

  const onClickListRow = (data) => {
    onChangeInput('templateCode', data, drawerIndex)
    setShowTemplateCodePanel(false)
  }

  const handleTemplateCodeClick = () => {
    setShowTemplateCodePanel(true)
  }

  const handleTemplateCodeCloseClick = () => {
    setShowTemplateCodePanel(false)
  }

  function handleAddReservationTemplateMap() {
    const tempReservationTemplateMap = _.cloneDeep(savedReservationTemplateMapList)
    const newReservationTemplateMap = _.cloneDeep(constants.RESERVATION_TEMPLATE_MAP_INPUT)
    newReservationTemplateMap.customId = 'RESERVATION_TEMPLATE_MAP_' + customIdIndex
    setCustomIdIndex(customIdIndex + 1)
    tempReservationTemplateMap.push(newReservationTemplateMap)
    dispatch(setReservationTemplateMapList(tempReservationTemplateMap))
  }

  function handleDelete(index) {
    let tempReservationTemplateMap = _.cloneDeep(savedReservationTemplateMapList)
    tempReservationTemplateMap = tempReservationTemplateMap.filter(
      (tempReservationTemplateMapValue, tempReservationTemplateMapIndex) => tempReservationTemplateMapIndex !== index
    )
    dispatch(setReservationTemplateMapList(tempReservationTemplateMap))
  }

  function handleClone(index) {
    let tempReservationTemplateMap = _.cloneDeep(savedReservationTemplateMapList)
    let cloneReservationTemplateMap = _.cloneDeep(
      tempReservationTemplateMap.find((tempReservationTemplateMapValue, tempReservationTemplateMapIndex) => tempReservationTemplateMapIndex === index)
    )
    cloneReservationTemplateMap.customId = 'RESERVATION_TEMPLATE_MAP_' + customIdIndex
    setCustomIdIndex(customIdIndex + 1)
    tempReservationTemplateMap.push(cloneReservationTemplateMap)
    dispatch(setReservationTemplateMapList(tempReservationTemplateMap))
  }

  function onChangeInput(name, e, index) {
    const tempReservationTemplateMap = _.cloneDeep(savedReservationTemplateMapList)
    tempReservationTemplateMap.map((tempReservationTemplateMapValue, tempReservationTemplateMapIndex) => {
      if (index === tempReservationTemplateMapIndex) {
        switch (name) {
          case 'startDate':
          case 'endDate':
            tempReservationTemplateMapValue[`${name}`] = e ? Number(moment(e).format('x')) : null
            break
          case 'type':
            tempReservationTemplateMapValue[`${name}`] = e
            tempReservationTemplateMapValue['dayOfWeek'] = null
            tempReservationTemplateMapValue['startDate'] = null
            tempReservationTemplateMapValue['endDate'] = null
            break
          case 'templateCode':
            tempReservationTemplateMapValue[`${name}`] = e.templateCode
            tempReservationTemplateMapValue['templateDescription'] = e.description
            break
          default:
            tempReservationTemplateMapValue[`${name}`] = e
        }
      }
      return tempReservationTemplateMapValue
    })
    dispatch(setReservationTemplateMapList(tempReservationTemplateMap))
  }

  const onClickBackToCreate = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push(
      '/' +
        constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT +
        '/' +
        params.id +
        '/' +
        constants.RESTAURANT_TAB.RESERVATION +
        '/' +
        constants.RESTAURANT_RESERVATION_SUB_TAB.RESERVATION_TEMPLATE_MAP
    )
  }

  function onClickSave() {
    let tempsavedReservationTemplateMapList = _.cloneDeep(savedReservationTemplateMapList)
    let newSavedReservationTemplateMapList = {
      restUrlId: params.id,
      reservationTemplateMapList: tempsavedReservationTemplateMapList,
    }
    ReservationTemplateMapValidation.validateTemplate(tempsavedReservationTemplateMapList)
    if (ReservationTemplateMapValidation.isValid()) {
      post(
        constants.PATH.SHOP.RESERVATION.RESERVATION_TEMPLATE_MAP_LIST,
        newSavedReservationTemplateMapList,
        (payload) => {
          setDialog({
            open: true,
            onClose: onClickBackToCreate,
            dialogContentText: { key: 'createSuccess' },
            dialogActions: [
              { label: { key: 'backCreate' }, onClick: onClickBackToCreate, icon: <ArrowBackIcon /> },
              { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
            ],
          })
          setClear(false)
        },
        undefined,
        store
      )
      ReservationTemplateMapValidation.resetErrorMessages()
    } else {
      setErrorVersion(ReservationTemplateMapValidation.getVersion())
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    dispatch(setReservationTemplateMapList(constants.RESERVATION_TEMPLATE_MAP_LIST))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(setReservationTemplateMapList(constants.RESERVATION_TEMPLATE_MAP_LIST))
    setClear(false)
    ReservationTemplateMapValidation.resetErrorMessages()
  }, [clear === true]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <EditCreateDisplay
        code={''}
        resource={resource}
        tabs={toolBar !== false ? tabs : ''}
        content={content}
        bottomBtns={bottomBtns}
        mapName={{ resource: 'restaurant', key: 'restUrlId' }}
        mapCode={params.id}
        display="create"
      />
      <Drawer classes={{ paper: 'drawer-margin-left' }} anchor="right" open={showTemplateCodePanel} onClose={handleTemplateCodeCloseClick}>
        <ReservationTemplateList
          editBtn={false}
          createBtn={false}
          btnList={false}
          onClickListRow={(item) => onClickListRow(item)}
          dialogFooterBtn={
            <>
              <TempBtn
                btnSetting={{
                  variant: constants.STYLE.VARIANT.CONTAINED,
                  color: constants.STYLE.PRIMARY,
                  onClick: handleTemplateCodeCloseClick,
                  icon: <ClearIcon />,
                  label: { key: 'close' },
                }}
              />
            </>
          }
        />
      </Drawer>
      {dialog && dialog.open && <DialogWrapper dialogInfo={dialog} />}
    </>
  )
}
