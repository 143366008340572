import _ from 'lodash'
import { QUEUE_TEMPLATE } from '../actions/types'
import { constants, TABLE_TYPE_DEFAULT_VALUE } from '../Constants/constants'

const INITIAL_STATE = {
  ui: {
    queueTemplate: _.cloneDeep(constants.QUEUE_TEMPLATE_INPUT_LIST),
    timeSection: _.cloneDeep(constants.TIME_SECTION_DEFAULT_VALUE),
    tableType: _.cloneDeep(TABLE_TYPE_DEFAULT_VALUE),
    groupedSection: _.cloneDeep(constants.QUEUE_TEMPLATE_GROUPED_SECTION),
    tableTypeColorList: null,
    defaultTableTypeColorList: null,
  },
}

const queueTemplateReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case QUEUE_TEMPLATE.SET_QUEUE_TEMPLATE_DEFAULT:
      return Object.assign({}, INITIAL_STATE)
    case QUEUE_TEMPLATE.SET_QUEUE_TEMPLATE:
    case QUEUE_TEMPLATE.SET_QUEUE_TEMPLATE_VALUE:
      return Object.assign(
        {},
        {
          ...state,
          ui: {
            ...state.ui,
            queueTemplate: action.queueTemplate,
          },
        }
      )
    case QUEUE_TEMPLATE.SET_QUEUE_TEMPLATE_TIME_SECTION_VALUE:
      return Object.assign(
        {},
        {
          ...state,
          ui: {
            ...state.ui,
            timeSection: action.timeSection,
          },
        }
      )
    case QUEUE_TEMPLATE.SET_QUEUE_TEMPLATE_TABLE_TYPE_VALUE:
      return Object.assign(
        {},
        {
          ...state,
          ui: {
            ...state.ui,
            tableType: action.tableType,
          },
        }
      )
    case QUEUE_TEMPLATE.SET_QUEUE_TEMPLATE_GROUPED_SECTION:
      return Object.assign(
        {},
        {
          ...state,
          ui: {
            ...state.ui,
            groupedSection: action.groupedSection,
          },
        }
      )
    case QUEUE_TEMPLATE.SET_QUEUE_TEMPLATE_TABLE_TYPE_COLOR_LIST:
      return Object.assign(
        {},
        {
          ...state,
          ui: {
            ...state.ui,
            tableTypeColorList: action.tableTypeColorList,
          },
        }
      )
    case QUEUE_TEMPLATE.SET_QUEUE_TEMPLATE_DEFAULT_TABLE_TYPE_COLOR_LIST:
      return Object.assign(
        {},
        {
          ...state,
          ui: {
            ...state.ui,
            defaultTableTypeColorList: action.defaultTableTypeColorList,
          },
        }
      )
    default:
      return state
  }
}

export default queueTemplateReducer
