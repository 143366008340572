import { useState, useEffect } from "react";
import { useStore } from "react-redux";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { Card } from '@mui/material';

import { ListDisplay } from '../../Component/ListDisplay/ListDisplay';

import { constants } from '../../Constants/constants';
import { getList } from "../../helper/baseFetch";
import { generateTableCellToMultiLanguageName } from "../../helper/util";

export const ReservationRejectReasonList = ({ editBtn, createBtn, onClickListRow, btnList, dialogFooterBtn, newFilter }) => {

  const store = useStore();
  const params = useParams();
  const [rejectReasonList, setRejectReasonList] = useState([]);
  const [ready, setReady] = useState(false);
  const resource = "ReservationRejectReason";


  const queueRejectReasonTitleList = [
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "reasonCode",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "reason",
      sort: false
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "status",
      sort: true
    }, 
  ];
  const listFilters = [
  ];

  const [param, setParam] = useState({
    path: "",
    page: 0,
    rowsPerPage: 10,
    sort: 'updateTimestamp',
    direction: 'DESC',
    listFilters: listFilters,
    count: 0,
  })

  function onChangeParam(value) {
    value.count = value.count + 1;
    setParam(value);
  }

  function onChangeListFilters(list) {
    let tempParam = _.cloneDeep(param);
    tempParam.listFilters = list;
    tempParam.page = 0;
    onChangeParam(tempParam);
  }

  function onChangeListSort(sortItem) {
    let tempParam = _.cloneDeep(param);
    tempParam.sort = sortItem;
    tempParam.direction = tempParam.direction === "DESC" ? "ASC" : "DESC";
    onChangeParam(tempParam);
  }

  function onChangeListPage(page) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = page;
    onChangeParam(tempParam);
  }

  function onChangeListRowsPerPage(rowsPerPage) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = 0;
    tempParam.rowsPerPage = rowsPerPage;
    onChangeParam(tempParam);
  }

  useEffect(() => {
    let tempParam = _.cloneDeep(param);
    const filter = newFilter? newFilter: { id: params.id };
    tempParam.listFilters.forEach((filterItem) => {
      if (filterItem.value !== '' && filterItem.value !== undefined) {
        filter[filterItem.name] = filterItem.value;
      }
    })

    getList(constants.PATH.SHOP.RESERVATION.RESERVATION_REJECT_REASON, { sort: { field: tempParam.sort, order: tempParam.direction }, filter: filter, pagination: { page: tempParam.page + 1, perPage: tempParam.rowsPerPage } }, (payload) => {
      payload.forEach((item)=>item.reason =  generateTableCellToMultiLanguageName(item.tcReason, item.enReason, item.scReason));
      setRejectReasonList(payload);
      setReady(true);
    }, undefined, store);

  }, [param.count]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <>
      <Card className="card-wrapper overflow">
        <ListDisplay
          onClickListRow={onClickListRow}
          page={param.page}
          rowsPerPage={param.rowsPerPage}
          onChangeListSort={onChangeListSort}
          resource={resource}
          dataList={rejectReasonList}
          sort={param.sort}
          direction={param.direction}
          listTitles={queueRejectReasonTitleList}
          listFilters={param.listFilters}
          onChangeListPage={onChangeListPage}
          onChangeListRowsPerPage={onChangeListRowsPerPage}
          idParam={params.id}
          editBtn={editBtn}
          createBtn={createBtn}
          dialogFooterBtn={dialogFooterBtn}
          onChangeListFilters={onChangeListFilters}
          redirectPath={"/" + constants.PATH.SHOP.RESERVATION.RESERVATION_REJECT_REASON + "/"}
        />
      </Card>
    </>
  )
};