import _ from "lodash";
import { MERCHANT_INFO } from '../actions/types';
import { constants } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        merchantInfo: _.cloneDeep(constants.MERCHANT_INFO_INPUT)
    }
}

const merchantInfoReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MERCHANT_INFO.SET_MERCHANT_INFO_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case MERCHANT_INFO.SET_MERCHANT_INFO:
        case MERCHANT_INFO.SET_MERCHANT_INFO_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    merchantInfo: action.merchantInfo
                }
            })
        default:
            return state
    }
}

export default merchantInfoReducer