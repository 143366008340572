import { useDispatch, useSelector } from "react-redux";

import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';

import SystemGroupValidation from "../../../../../validations/systemGroupValidation";
import { systemGroupValidationConstants } from '../../../../../Constants/validationConstants';
import { constants } from '../../../../../Constants/constants';
import { TempBtn } from "../../../../Input/TempBtn";
import { TempTextInput } from "../../../../Input/TempTextInput";
import { removeSystemGroupEmailList } from "../../../../../actions/SystemGroupAction";

export const EmailField =({item, index, onChangeInput})=>{

  const dispatch = useDispatch();
  const savedSystemGroup = useSelector(state => state.systemGroup.ui.systemGroup);
  const resource = "productCreateSystemGroupList";

  const handleRemoveEmail = (index) =>{
    dispatch(removeSystemGroupEmailList(savedSystemGroup, index));
  }

  return (
  <>
    {
      SystemGroupValidation.getErrorMessages(`${index}-${systemGroupValidationConstants.KEY_GROUP_EMAILS}`) &&
      <div className="error-message">
        <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
        {SystemGroupValidation.getErrorMessages(`${index}-${systemGroupValidationConstants.KEY_GROUP_EMAILS}`)}
      </div>
    }
    <div>
      <TempTextInput inputSetting={
        {
          className: "edit-create-input",
          size: constants.SIZE.SMALL,
          label: { resource: resource, key: "groupEmails" },
          name: `groupEmails-${index}`,
          required: false,
          value: item,
          index: index,
        }
      } 
      handleChange={onChangeInput} />
      <TempBtn btnSetting={{
        className: "edit-create-btn",
        variant: constants.STYLE.VARIANT.CONTAINED,
        color: constants.STYLE.PRIMARY,
        disabled: false,
        label: { key: "deleteBtn" },
        onClick: () => handleRemoveEmail(index),
        icon: <DeleteIcon />,
        isEndOfLine: true,
      }}>
      </TempBtn>
    </div>
  </>
  )
}