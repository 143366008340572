import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import TranslateIcon from '@mui/icons-material/Translate';

import { constants } from '../../Constants/constants';
import { TempBtn } from "../../Component/Input/TempBtn";
import { setLang } from "../../actions/langAction";
import '../../styles/configuration.scss';

export const ConfigurationList = props => {
  const dispatch = useDispatch();
  const savedLang = useSelector(state => state.lang.ui.lang);
  useEffect(() => {
    // componentDidMount is here!
    // console.log("RestaurantList componentDidMount");
    return () => {
      // componentWillUnmount is here!
      // console.log("RestaurantList componentWillUnmount");
    };
  }, []);

  return (
    <div>
      <TempBtn btnSetting={{
        className: "btn",
        variant: constants.STYLE.VARIANT.CONTAINED,
        color: constants.STYLE.PRIMARY,
        onClick: () => {
          dispatch(setLang(constants.LANG.EN));
        },
        disabled: savedLang === constants.LANG.EN,
        label: { key: "en" },
        icon: <TranslateIcon />,
      }} />

      <TempBtn btnSetting={{
        className: "btn",
        variant: constants.STYLE.VARIANT.CONTAINED,
        color: constants.STYLE.PRIMARY,
        onClick: () => {
          dispatch(setLang(constants.LANG.TC));
        },
        disabled: savedLang === constants.LANG.TC,
        label: { key: "zhHK" },
        icon: <TranslateIcon />,
      }} />

      <TempBtn btnSetting={{
        className: "btn",
        variant: constants.STYLE.VARIANT.CONTAINED,
        color: constants.STYLE.PRIMARY,
        onClick: () => {
          dispatch(setLang(constants.LANG.SC));
        },
        disabled: savedLang === constants.LANG.SC,
        label: { key: "sc" },
        icon: <TranslateIcon />,
      }} />
    </div>
  );
};
