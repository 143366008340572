import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector, useStore } from "react-redux";
import _ from "lodash";

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CachedIcon from '@mui/icons-material/Cached';
import CancelIcon from '@mui/icons-material/Cancel';
import { makeStyles } from '@mui/styles';

import { constants, PICTURE_SIZE } from '../../Constants/constants';
import { getOne, put } from "../../helper/baseFetch";
import { getScDisabled } from "../../helper/util";
import { setFolder, setFolderValue } from "../../actions/folderAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempAutoSelectInput } from "../../Component/Input/TempAutoSelectInput";
import { TempCustomCheckBox } from "../../Component/Custom/TempCustomCheckBox";
import { UploadImage } from "../../Component/UploadImage/UploadImage";
import { FolderMapList } from './FolderMapList';
import { BannerList } from '../Banner/BannerList';
import { DialogWrapper } from "../../Component/DialogWrapper";
import FolderValidation from "../../validations/folderValidation";
import { folderValidationConstants } from '../../Constants/validationConstants';

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

const LOCAL_CONSTANT = {
  EN_IMAGE: 'enImage',
  TC_IMAGE: 'tcImage',
  SC_IMAGE: 'scImage',
}

export const FolderEdit = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const store = useStore();
  const history = useHistory();
  const [ready, setReady] = useState(false);
  const [clear, setClear] = useState(true);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const resource = "Folder";
  const savedFolder = useSelector(state => state.folder.ui.folder)
  const [folderInputList, setFolderInputList] = useState(_.cloneDeep(savedFolder));
  const helperTextStylesClasses = helperTextStyles();
  const [, setErrorVersion] = useState(FolderValidation.getVersion());

  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.FOLDER_TAB.INFO
    },
    {
      label: { resource: resource, key: "folderMapList" },
      tabIndex: constants.FOLDER_TAB.FOLDER_MAP_LIST
    },
    {
      label: { resource: resource, key: "bannerList" },
      tabIndex: constants.FOLDER_TAB.BANNER_LIST
    }
  ]
  const tempFolderEditList = [
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "type" },
      value: savedFolder.type,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.FOLDER.TYPE,
      name: "type",
      isEndOfLine: true,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "showHidden" },
      value: savedFolder.showHidden,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.FOLDER.SHOW_HIDDEN,
      name: "showHidden",
      isEndOfLine: true,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "enName" },
      value: savedFolder.enName,
      name: "enName",
      custom: true,
      customName: { key: "nameCustomize" },
      customValue: savedFolder.scNameDisable,
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: FolderValidation.getErrorMessages(folderValidationConstants.KEY_EN_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "tcName" },
      value: savedFolder.tcName,
      name: "tcName",
      translateName: savedFolder.scNameDisable ? ["scName"] : null,
      inputList: folderInputList,
      setInputList: setFolderInputList,
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: FolderValidation.getErrorMessages(folderValidationConstants.KEY_TC_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "scName" },
      value: savedFolder.scName,
      name: "scName",
      inputList: folderInputList,
      setInputList: setFolderInputList,
      isEndOfLine: true,
      disabled: savedFolder.scNameDisable,
      required: true,
      helperTextIcon: true,
      helperText: FolderValidation.getErrorMessages(folderValidationConstants.KEY_SC_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "enDescriptionHtml" },
      value: savedFolder.enDescriptionHtml,
      name: "enDescriptionHtml",
      custom: true,
      customName: { resource: resource, key: "descriptionCustomize" },
      customValue: savedFolder.scDescriptionDisable,
      multiline: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "tcDescriptionHtml" },
      value: savedFolder.tcDescriptionHtml,
      name: "tcDescriptionHtml",
      translateName: savedFolder.scDescriptionDisable ? ["scDescriptionHtml"] : null,
      inputList: folderInputList,
      setInputList: setFolderInputList,
      multiline: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "scDescriptionHtml" },
      value: savedFolder.scDescriptionHtml,
      name: "scDescriptionHtml",
      inputList: folderInputList,
      setInputList: setFolderInputList,
      multiline: true,
      isEndOfLine: true,
      disabled: savedFolder.scDescriptionDisable,
    },
    {
      picture: savedFolder.enImage,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "enImage" },
      name: "enImage",
      type: constants.TYPE.IMAGE,
      uploadImagePath: constants.PATH.FOLDER.UPLOAD_FOLDER_IMAGE,
      saveImage: handleSaveImage,
      isEndOfLine: false,
      required: true,
    },
    {
      picture: savedFolder.tcImage,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "tcImage" },
      name: "tcImage",
      type: constants.TYPE.IMAGE,
      uploadImagePath: constants.PATH.FOLDER.UPLOAD_FOLDER_IMAGE,
      saveImage: handleSaveImage,
      isEndOfLine: false,
      required: true,
    },
    {
      picture: savedFolder.scImage,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "scImage" },
      name: "scImage",
      type: constants.TYPE.IMAGE,
      uploadImagePath: constants.PATH.FOLDER.UPLOAD_FOLDER_IMAGE,
      saveImage: handleSaveImage,
      isEndOfLine: true,
      required: true,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "status" },
      value: savedFolder.status,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.FOLDER.STATUS,
      name: "status",
      isEndOfLine: true,
      disableClearable: true,
      required: true,
      helperTextIcon: true,
      helperText: FolderValidation.getErrorMessages(folderValidationConstants.KEY_STATUS),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
  ]
  const content = [
    {
      tab: <>
        {tempFolderEditList.map((inputSetting, index) => {
          let content;
          switch (inputSetting.type) {
            case constants.TYPE.IMAGE:
              content =
                <div className="image">
                  <UploadImage
                    picture={inputSetting.picture}
                    title={inputSetting.label}
                    name={inputSetting.name}
                    uploadImagePath={inputSetting.uploadImagePath}
                    saveImage={inputSetting.saveImage}
                    required={inputSetting.required} 
                    showApplyToAll={true}
                    size= {PICTURE_SIZE.MID}/>
                </div>
              break;
            case constants.TYPE.SELECT:
              content =
                <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
              break;
            default:
              content =
                <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
          }
          return <div className="edit-display-wrapper" key={index}>
            {inputSetting.custom && <TempCustomCheckBox className="customize-btn" value={inputSetting.customValue} customName={inputSetting.customName} onSelectCheckbox={onSelectCheckbox} />}
            {inputSetting.name === "enImage" &&
              (
                <>
                  <>
                    {FolderValidation.getErrorMessages(folderValidationConstants.KEY_TC_IMAGE) &&
                      FolderValidation.getErrorMessages(folderValidationConstants.KEY_TC_IMAGE).length > 0 &&
                      <div className="error-message">
                        <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                        {FolderValidation.getErrorMessages(folderValidationConstants.KEY_TC_IMAGE)}
                      </div>
                    }
                  </>
                  <>
                    {FolderValidation.getErrorMessages(folderValidationConstants.KEY_SC_IMAGE) &&
                      FolderValidation.getErrorMessages(folderValidationConstants.KEY_SC_IMAGE).length > 0 &&
                      <div className="error-message">
                        <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                        {FolderValidation.getErrorMessages(folderValidationConstants.KEY_SC_IMAGE)}
                      </div>
                    }
                  </>
                  <>
                    {FolderValidation.getErrorMessages(folderValidationConstants.KEY_EN_IMAGE) &&
                      FolderValidation.getErrorMessages(folderValidationConstants.KEY_EN_IMAGE).length > 0 &&
                      <div className="error-message">
                        <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                        {FolderValidation.getErrorMessages(folderValidationConstants.KEY_EN_IMAGE)}
                      </div>
                    }
                  </>
                </>
              )
            }
            {content}
            {inputSetting.isEndOfLine && <div></div>}
          </div>
        })}
      </>,
      btn: true
    },
    {
      tab: <FolderMapList
        newFilter={{ folderId: params.id }}
        editBtn={true}
        createBtn={true} />,
      btn: false
    },
    {
      tab: <BannerList
        newListMainTitle={{ resource: resource, key: "folderBannerList" }}
        newFilter={{ id: params.id }}
        newPath={constants.PATH.FOLDER.FOLDER_BANNER}
        newListTitles={[
          {
            key: constants.LIST_DISPLAY_TYPE.IMAGE,
            value: "enImage",
            sort: false
          }, {
            key: constants.LIST_DISPLAY_TYPE.IMAGE,
            value: "tcImage",
            sort: false
          }, {
            key: constants.LIST_DISPLAY_TYPE.IMAGE,
            value: "scImage",
            sort: false
          }, 
          // {
          //   key: constants.LIST_DISPLAY_TYPE.STRING,
          //   value: "name",
          //   sort: false
          // }, 
          {
            key: constants.LIST_DISPLAY_TYPE.STRING,
            value: "actionType",
            sort: true
          }, {
            key: constants.LIST_DISPLAY_TYPE.DATE_TIME,
            value: "startDisplayDate",
            sort: false
          }, {
            key: constants.LIST_DISPLAY_TYPE.DATE_TIME,
            value: "endDisplayDate",
            sort: false
          }, {
            key: constants.LIST_DISPLAY_TYPE.STRING,
            value: "sequence",
            sort: true
          }, {
            key: constants.LIST_DISPLAY_TYPE.STRING,
            value: "status",
            sort: true
          },
        ]}
        editBtn={true}
        createBtn={true} />,
      btn: false
    }
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    },
    {
      className: "special-button special-button-margin-left",
      variant: constants.STYLE.VARIANT.CONTAINED,
      icon: <CachedIcon />,
      label: { key: "clearRedisCache" },
      onClick: handleRadisCache,
    }
  ]

  function handleSaveImage(name, image, index, applyToAll) {

    const tempFolder = _.cloneDeep(savedFolder);

    if(applyToAll){
      tempFolder[LOCAL_CONSTANT.EN_IMAGE] = image;
      tempFolder[LOCAL_CONSTANT.SC_IMAGE] = image;
      tempFolder[LOCAL_CONSTANT.TC_IMAGE] = image;
      dispatch(setFolder(tempFolder));
      return;
    }

    dispatch(setFolderValue(name, savedFolder, "", image));
  }

  function onSelectCheckbox(value, customName) {
    switch (customName.key) {
      case "nameCustomize":
        dispatch(setFolderValue("scNameDisable", savedFolder, "", value));
        break;
      case "descriptionCustomize":
        dispatch(setFolderValue("scDescriptionDisable", savedFolder, "", value));
        break;
      default:
    }
  }

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  };

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.FOLDER.FOLDER);
  };

  function onChangeInput(name, e) {
    if (savedFolder.scNameDisable === true && name === "tcName") {
      dispatch(setFolderValue("CHANGE_TC_NAME_AND_SC_NAME", savedFolder, "", e));
    } else if (savedFolder.scDescriptionDisable === true && name === "tcDescriptionHtml") {
      dispatch(setFolderValue("CHANGE_TC_NAME_AND_SC_DESCRIPTION", savedFolder, "", e));
    } else {
      dispatch(setFolderValue(name, savedFolder, "", e));
    }
  }

  function handleRadisCache() {
    put(constants.PATH.FOLDER.CLEAR_REDIS_CACHE + "/" + savedFolder.folderCode, {}, (payload) => {
      setDialog({
        open: true,
        onClose: onClickBackToEdit,
        dialogContentText: { key: "clearCacheSuccess" },
        dialogActions: [
          { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
          { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
        ]
      })
      setClear(false)
    }, undefined, store);
  }

  function onClickSave() {
    FolderValidation.validateEditFolder(savedFolder);
    if (FolderValidation.isValid()) {
      put(constants.PATH.FOLDER.FOLDER + "/" + params.id, savedFolder, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToEdit,
          dialogContentText: { key: "editSuccess" },
          dialogActions: [
            { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      FolderValidation.resetErrorMessages();
    } else {
      setErrorVersion(FolderValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    if (clear === true) {
      getOne(constants.PATH.FOLDER.FOLDER + "/" + params.id, undefined, (payload) => {
        payload.scNameDisable = getScDisabled(payload.tcName, payload.scName);
        payload.scDescriptionDisable = getScDisabled(payload.tcDescriptionHtml, payload.scDescriptionHtml);
        dispatch(setFolder(_.cloneDeep(payload)));
        setFolderInputList(_.cloneDeep(payload))
        setReady(true)
        setClear(false)
      }, undefined, store);
    }
    FolderValidation.resetErrorMessages();
  }, [clear === true]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <>
      <EditCreateDisplay
        code={savedFolder && savedFolder.folderCode ? savedFolder.folderCode : ""}
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        display="edit"
      />
      {dialog && dialog.open &&
        <DialogWrapper dialogInfo={dialog} />
      }
    </>
  )
};