import { RESERVATION_TEMPLATE } from './types';
import { sify } from "chinese-conv";
import _ from "lodash";

export const setReservationTemplateDefault = () => {
    return {
        type: RESERVATION_TEMPLATE.SET_RESERVATION_TEMPLATE_DEFAULT,
    }
}

export const setReservationTemplate = (reservationTemplate) => {
    return {
        type: RESERVATION_TEMPLATE.SET_RESERVATION_TEMPLATE,
        reservationTemplate: reservationTemplate
    }
}

export const setReservationTemplateValue = (draftType, orignalProduct, key, value, index) => {
    const reservationTemplate = _.cloneDeep(orignalProduct);
    switch (draftType) {
        case "CHANGE_TC_TITLE_AND_SC_TITLE":
            if (value) {
                reservationTemplate.tcTitle = value;
                reservationTemplate.scTitle = sify(value);
            } else {
                reservationTemplate.tcTitle = null;
                reservationTemplate.scTitle = null;
            }
            break;
        case "CHANGE_TC_MESSAGE_AND_SC_MESSAGE":
            if (value) {
                reservationTemplate.tcMessage = value;
                reservationTemplate.scMessage = sify(value);
            } else {
                reservationTemplate.tcMessage = null;
                reservationTemplate.tcMessage = null;
            }
            break;
        case "CHANGE_TYPE":
            reservationTemplate.type = value;
            reservationTemplate.enMessage = null;
            reservationTemplate.tcMessage = null;
            reservationTemplate.scMessage = null;
            break;
        default:
            reservationTemplate[draftType] = value;
    }
    return {
        type: RESERVATION_TEMPLATE.SET_RESERVATION_TEMPLATE_VALUE,
        reservationTemplate: reservationTemplate
    }
}

export const setReservationTemplateTimeSectionValue = (timeSection) => {
    return {
        type: RESERVATION_TEMPLATE.SET_RESERVATION_TEMPLATE_TIME_SECTION_VALUE,
        timeSection: timeSection
    }
}

export const setReservationTemplateTableTypeValue = (tableType) => {
    return {
        type: RESERVATION_TEMPLATE.SET_RESERVATION_TEMPLATE_TABLE_TYPE_VALUE,
        tableType: tableType
    }
}

