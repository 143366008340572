
import { useState } from 'react'
import { Drawer } from '@mui/material'
import { constants as co } from '../../Constants/constants'
import { useParams } from 'react-router-dom'
import CounterList from '../Counter/CounterList'
import { TempBtn } from '../../Component/Input/TempBtn'
import { Add, Clear, Edit } from '@mui/icons-material'
import GenericDragAndDrop from './GenericDragAndDrop'
import { getString } from '../../helper/util'
import useLang from '../../hooks/Lang/useLang'
import useTheme from '../../hooks/Theme/useTheme'
import useRestaurant from '../../hooks/Restaurant/useRestaurant'

export default function CounterProfileDragAndDrop({ createEditType, idList, setIdList, objList, setObjList, onDeleteDragItem, onDragEnd }) {
  const lang = useLang()
  const theme = useTheme()

  const [openDrawer, setOpenDrawer] = useState(false)
  const resource = 'CounterProfile'
  const params = useParams()
  const { getRestUrlId } = useRestaurant(params.restUrlId)
  const restUrlId = getRestUrlId()

  const onCloseDrawer = () => {
    setOpenDrawer(false)
  }

  const onOpenDrawer = () => {
    setOpenDrawer(true)
  }

  return (
    <>
      <GenericDragAndDrop
        direction="vertical"
        name="counterSequence"
        title={getString(lang, resource, 'counterSequence', theme)}
        dragList={objList}
        onDragEnd={onDragEnd}
        enNameKey={'displayEnName'}
        tcNameKey={'displayTcName'}
        scNameKey={'displayScName'}
        onDelete={onDeleteDragItem}
        buttonContent={
          <>
            <TempBtn
              btnSetting={{
                label: { resource: resource, key: objList.length ? 'selectCounter' : 'addCounter' },
                onClick: () => onOpenDrawer(),
                icon: objList.length ? <Edit fontSize="small" /> : <Add fontSize="small" />,
              }}
            />
          </>
        }
      />
      {openDrawer && (
        <Drawer classes={{ paper: 'drawer-margin-left' }} anchor="right" open={openDrawer} onClose={onCloseDrawer}>
          <CounterList
            editBtn={false}
            createBtn={false}
            btnList={false}
            newFilter={
              idList.length
                ? {
                    id: createEditType === 'create' ? params.restUrlId : restUrlId,
                    selected: {
                      column: 'id',
                      valueList: idList
                        .map((item) => {
                          return item
                        })
                        .reverse(),
                    },
                  }
                : { id: createEditType === 'create' ? params.restUrlId : restUrlId }
            }
            idList={idList}
            setIdList={setIdList}
            objList={objList}
            setObjList={setObjList}
            newPath={co.PATH.SHOP.RESTAURANT}
            dialogFooterBtn={
              <>
                <TempBtn
                  btnSetting={{
                    className: 'get-push-card-cancel-btn margin-left-16',
                    variant: co.STYLE.VARIANT.CONTAINED,
                    color: co.STYLE.PRIMARY,
                    onClick: () => setOpenDrawer(false),
                    icon: <Clear />,
                    label: { key: 'close' },
                  }}
                />
              </>
            }
            multipleSelect={true}
          />
        </Drawer>
      )}
    </>
  )
}
