import { useState } from 'react'
import _ from 'lodash'

import ClearIcon from '@mui/icons-material/Clear'
import AddIcon from '@mui/icons-material/Add'
import { Box, Drawer } from '@mui/material'
import Paper from '@mui/material/Paper'
import { TempBtn } from '../../../Component/Input/TempBtn'
import { constants } from '../../../Constants/constants'

import { RestaurantList } from '../../Restaurant/RestaurantList'
import { RestaurantInfoCard } from './RestaurantInfoCard'
import SlideshowBulkCreateValidation from '../../../validations/SlideshowBulkCreateValidation'
import { slideshowBulkCreateValidationConstants } from '../../../Constants/validationConstants'

const resource = 'RestaurantSlideshow'

const newListTitles = [
  {
    key: constants.LIST_DISPLAY_TYPE.STRING,
    value: 'restUrlId',
    sort: true,
  },
  {
    key: constants.LIST_DISPLAY_TYPE.STRING,
    value: 'restRefId',
    sort: true,
  },
  {
    key: constants.LIST_DISPLAY_TYPE.STRING,
    value: 'name',
    sort: false,
  },
  {
    key: constants.LIST_DISPLAY_TYPE.STRING,
    value: 'address',
    sort: false,
  },
]

const newListFilters = [
  {
    className: 'filter-input',
    label: { resource: resource, key: 'restUrlId' },
    clear: true,
    value: '',
    name: 'restUrlId',
    size: constants.SIZE.SMALL,
  },
  {
    className: 'filter-input',
    label: { resource: resource, key: 'restRefId' },
    clear: true,
    value: '',
    name: 'restRefId',
    size: constants.SIZE.SMALL,
  },
  {
    className: 'filter-input',
    label: { resource: resource, key: 'name' },
    clear: true,
    value: '',
    name: 'name',
    size: constants.SIZE.SMALL,
  },
  {
    className: 'filter-input',
    label: { resource: resource, key: 'address' },
    clear: true,
    value: '',
    name: 'address',
    size: constants.SIZE.SMALL,
  },
]

export const AllSlideshowCreateRestaurantMapCreate = ({ addRestaurantMap, setAddRestaurantMap, selectedRestaurantMap, setSelectedRestaurantMap }) => {
  const [open, setOpen] = useState(false)

  function onDeleteRestaurantMapCard(restUrlId) {
    const newAddRestaurantMap = _.cloneDeep(addRestaurantMap)
    newAddRestaurantMap.delete(restUrlId)
    setAddRestaurantMap(newAddRestaurantMap)
    setSelectedRestaurantMap(newAddRestaurantMap)
  }

  function onClickAddRestaurantMapList() {
    setOpen(true)
  }

  function onClickCloseRestaurantMapDrawer() {
    setOpen(false)
  }

  function handleRestUrlId() {
    setAddRestaurantMap(selectedRestaurantMap)
    onClickCloseRestaurantMapDrawer()
  }

  function handleRestaurantTextCloseClick() {
    setSelectedRestaurantMap(addRestaurantMap)
    onClickCloseRestaurantMapDrawer()
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', padding: '20px', minHeight: '500px' }}>
        <Box>
          <TempBtn
            btnSetting={{
              onClick: onClickAddRestaurantMapList,
              variant: constants.STYLE.VARIANT.CONTAINED,
              color: constants.STYLE.PRIMARY,
              label: { resource: resource, key: 'addRestaurantMap' },
              icon: <AddIcon />,
            }}
          />
          {SlideshowBulkCreateValidation.getErrorMessages(slideshowBulkCreateValidationConstants.KEY_RESTURLID) && (
            <div className="dialog-remarks">
              {SlideshowBulkCreateValidation.getErrorMessages(slideshowBulkCreateValidationConstants.KEY_RESTURLID)}
            </div>
          )}
          {addRestaurantMap.size > 0 &&
            Array.from(selectedRestaurantMap, ([name, value]) => value).map((item, i) => (
              <RestaurantInfoCard shop={item} key={item.restUrlId} onDeleteRestaurantMapCard={onDeleteRestaurantMapCard} resource={resource} />
            ))}
        </Box>
      </Box>
      <Drawer classes={{ paper: 'drawer-margin-left' }} anchor="right" open={open} onClose={onClickCloseRestaurantMapDrawer}>
        <Paper>
          <RestaurantList
            editBtn={false}
            createBtn={false}
            btnList={false}
            mutipleSelect={true}
            selectMap={selectedRestaurantMap}
            setSelectMap={setSelectedRestaurantMap}
            newListTitles={newListTitles}
            newListFilters={newListFilters}
            newFilter={
              selectedRestaurantMap && selectedRestaurantMap.size > 0
                ? {
                    selected: { column: 'restUrlId', valueList: Array.from(selectedRestaurantMap, ([name, value]) => name) },
                  }
                : {}
            }
            dialogFooterBtn={
              <>
                <TempBtn
                  btnSetting={{
                    className: 'get-push-card-cancel-btn',
                    variant: constants.STYLE.VARIANT.CONTAINED,
                    color: constants.STYLE.PRIMARY,
                    onClick: handleRestUrlId,
                    icon: <AddIcon />,
                    label: { key: 'submit' },
                  }}
                />
                <TempBtn
                  btnSetting={{
                    className: 'get-push-card-cancel-btn margin-left-16',
                    variant: constants.STYLE.VARIANT.CONTAINED,
                    color: constants.STYLE.PRIMARY,
                    onClick: handleRestaurantTextCloseClick,
                    icon: <ClearIcon />,
                    label: { key: 'close' },
                  }}
                />
              </>
            }
          />
        </Paper>
      </Drawer>
    </>
  )
}
