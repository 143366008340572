import { useState, useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { Card } from '@mui/material';
import { ListDisplay } from '../../Component/ListDisplay/ListDisplay';

import { constants } from '../../Constants/constants';
import { getList } from "../../helper/baseFetch";
import { generateTableCellToMultiLanguageName } from "../../helper/util";
import { setSelectTagConfigValue  } from "../../actions/selectTagConfigAction";

export const SelectTagConfigEdit = (editBtn, createBtn, newFilter, newPath, newListTitles, newResourcenName) => {
  const dispatch = useDispatch();
  const store = useStore();
  const params = useParams();
  const [ready, setReady] = useState(false);
  const [selectTagList, setSelectTagList] = useState([]);
  const resource = "SelectTagConfig";
  const resourceName = "SelectTagConfig";
  const savedSelectTagConfig= useSelector(state => state.selectTagConfig.ui.selectTagConfig);
  
  const listTitles = newListTitles ? newListTitles : [
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "tagCode",
      sort: true
    }, 
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "tag_name",
      sort: true
    }, 
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "type",
      sort: true
    }, 
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "sequence",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "status",
      sort: true
    }
  ];

  const listFilters = [
    {
      className: "filter-input",
      label: { resource: resource, key: "tag_code" },
      clear: true,
      value: '',
      name: "tagCode",
      size: constants.SIZE.SMALL
    },
    {
      className: "filter-input",
      label: { resource: resource, key: "select_tag_name" },
      clear: true,
      value: '',
      name: "name",
      size: constants.SIZE.SMALL
    },
    {
      className: "filter-input",
      label: { resource: resource, key: "type" },
      clear: true,
      value: '',
      name: "type",
      size: constants.SIZE.SMALL,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.SELECT_TAG_CONFIG.TYPE,
    },
    {
      className: "filter-input",
      label: { resource: resource, key: "status" },
      clear: true,
      value: '',
      name: "status",
      size: constants.SIZE.SMALL,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.SELECT_TAG_CONFIG.STATUS,
    },
  ];

  const [param, setParam] = useState({
    path: newPath ? newPath : "",
    page: 0,
    rowsPerPage: 10,
    sort: 'updateTimestamp',
    direction: 'DESC',
    listFilters: listFilters,
    count: 0,
  })

  function onChangeParam(value) {
    value.count = value.count + 1;
    setParam(value);
  }

  function onChangeListFilters(list) {
    let tempParam = _.cloneDeep(param);
    tempParam.listFilters = list;
    tempParam.page = 0;
    onChangeParam(tempParam);
  }

  function onChangeListSort(sortItem) {
    let tempParam = _.cloneDeep(param);
    tempParam.sort = sortItem;
    tempParam.direction = tempParam.direction === "DESC" ? "ASC" : "DESC";
    onChangeParam(tempParam);
  }

  function onChangeListPage(page) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = page;
    onChangeParam(tempParam);
  }

  function onChangeListRowsPerPage(rowsPerPage) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = 0;
    tempParam.rowsPerPage = rowsPerPage;
    onChangeParam(tempParam);
  }

  useEffect(() => {
    let tempParam = _.cloneDeep(param);
    const filter = newFilter ? _.cloneDeep(newFilter) : { id: params.id };
    tempParam.listFilters.forEach((filterItem) => {
      if (filterItem.value !== '' && filterItem.value !== undefined) {
        filter[filterItem.name] = filterItem.value;
      }
    })

    getList(tempParam.path ? tempParam.path : constants.PATH.SELECTED_TAG.SELECT_TAG_CONFIG, { sort: { field: tempParam.sort, order: tempParam.direction }, filter: filter, pagination: { page: tempParam.page + 1, perPage: tempParam.rowsPerPage } }, (payload) => {
      dispatch(setSelectTagConfigValue(constants.ACTION_KEY.SELECT_TAG.DRAFT, _.cloneDeep(savedSelectTagConfig), "", _.cloneDeep(payload)));
      payload.map((item) => {
        item.tag_name = generateTableCellToMultiLanguageName(item.tagTcName, item.tagEnName, item.tagScName);
        return item;
      });
      setSelectTagList(payload);
      setReady(true)
    }, undefined, store);

  }, [param.count]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <>
      <Card className="card-wrapper overflow">
      <ListDisplay
        page={param.page}
        rowsPerPage={param.rowsPerPage}
        onChangeListSort={onChangeListSort}
        resource={resourceName}
        dataList={selectTagList}
        sort={param.sort}
        direction={param.direction}
        listTitles={listTitles}
        listFilters={param.listFilters}
        onChangeListPage={onChangeListPage}
        onChangeListRowsPerPage={onChangeListRowsPerPage}
        editBtn={editBtn}
        idParam= {params.id}
        createBtn={createBtn}
        onChangeListFilters={onChangeListFilters}
        redirectPath={"/" + (newPath ? newPath : constants.PATH.SELECTED_TAG.SELECT_TAG_CONFIG) + "/"}
      />
    </Card>
    </>
  )
};
