import { useState, useEffect } from 'react'
import { useStore } from 'react-redux'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import _ from 'lodash'

import { Card } from '@mui/material'

import { ListDisplay } from '../../Component/ListDisplay/ListDisplay'

import { constants } from '../../Constants/constants'
import { getList } from '../../helper/baseFetch'
import { getParamQueryString, getQuery, getQueryParamObject } from '../../helper/util'

const resource = 'DeviceLog'

export const MerchantDeviceLogList = ({ changeUrlQueryString }) => {
  const store = useStore()
  const [deviceLogList, setDeviceList] = useState([])
  const [ready, setReady] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const filterParams = getQuery()
  const params = useParams()

  const merchantUserTitleList = [
    {
      key: constants.LIST_DISPLAY_TYPE.DATE,
      value: 'uploadTimestamp',
      sort: true,
    },
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: 'appVersion',
      sort: true,
    },
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: 'osVersion',
      sort: true,
    },
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: 'status',
      sort: true,
    },
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: 'fileUrl',
      sort: true,
    },
  ]
  const listFilters = [
  ]

  const [param, setParam] = useState(
    !changeUrlQueryString
      ? {
          path: '',
          page: 0,
          rowsPerPage: 10,
          sort: 'updateTimestamp',
          direction: 'DESC',
          listFilters: _.cloneDeep(listFilters),
          count: 0,
        }
      : getQueryParamObject(
          {
            path: '',
            page: 0,
            rowsPerPage: 10,
            sort: 'updateTimestamp',
            direction: 'DESC',
            listFilters: _.cloneDeep(listFilters),
            count: 0,
          },
          _.cloneDeep(listFilters),
          merchantUserTitleList
        )
  )

  useEffect(() => {
    if (!changeUrlQueryString) {
      return
    }

    const querySyring = getParamQueryString(
      {
        path: '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: param.count,
      },
      param
    )

    const currentQueryString = window.location.search?.substring(1)

    if (querySyring === currentQueryString) {
      return
    }

    const newParam = getQueryParamObject(
      {
        path: '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: param.count + 1,
      },
      _.cloneDeep(listFilters),
      merchantUserTitleList
    )

    setParam(newParam)

    const newQuery = getParamQueryString(
      {
        path: '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: 0,
      },
      newParam
    )

    history.replace({ pathname: location.pathname, search: newQuery })
  }, [filterParams])

  function onChangeParam(value) {
    value.count = value.count + 1

    if (!changeUrlQueryString) {
      setParam(value)
      return
    }

    const querySyring = getParamQueryString(
      {
        path: '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: 0,
      },
      value
    )

    history.push({ pathname: location.pathname, search: querySyring })
  }

  function onChangeListFilters(list) {
    let tempParam = _.cloneDeep(param)
    tempParam.listFilters = list
    tempParam.page = 0
    onChangeParam(tempParam)
  }

  function onChangeListSort(sortItem) {
    let tempParam = _.cloneDeep(param)
    tempParam.sort = sortItem
    tempParam.direction = tempParam.direction === 'DESC' ? 'ASC' : 'DESC'
    onChangeParam(tempParam)
  }

  function onChangeListPage(page) {
    let tempParam = _.cloneDeep(param)
    tempParam.page = page
    onChangeParam(tempParam)
  }

  function onChangeListRowsPerPage(rowsPerPage) {
    let tempParam = _.cloneDeep(param)
    tempParam.page = 0
    tempParam.rowsPerPage = rowsPerPage
    onChangeParam(tempParam)
  }

  useEffect(() => {
    let tempParam = _.cloneDeep(param)
    const filter = { id: params.id }
    tempParam.listFilters.forEach((filterItem) => {
      if (filterItem.value !== '' && filterItem.value !== undefined) {
        filter[filterItem.name] = filterItem.value
      }
    })

    getList(
      constants.PATH.MERCHANT.DEVICE_LOG,
      {
        sort: { field: tempParam.sort, order: tempParam.direction },
        filter: filter,
        pagination: { page: tempParam.page + 1, perPage: tempParam.rowsPerPage },
      },
      (payload) => {
        setDeviceList(payload)
        setReady(true)
      },
      undefined,
      store
    )
  }, [param.count]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready && (
      <Card className="card-wrapper overflow">
        <ListDisplay
          page={param.page}
          rowsPerPage={param.rowsPerPage}
          onChangeListSort={onChangeListSort}
          resource={resource}
          dataList={deviceLogList}
          sort={param.sort}
          direction={param.direction}
          listTitles={merchantUserTitleList}
          listFilters={param.listFilters}
          idName="id"
          id={params.id}
          onChangeListPage={onChangeListPage}
          onChangeListRowsPerPage={onChangeListRowsPerPage}
          onChangeListFilters={onChangeListFilters}
          editRedirectPath={`/${constants.PATH.MERCHANT.DEVICE_LOG}/edit/`}
          createBtn={false}
          editBtn={true}
        />
      </Card>
    )
  )
}
