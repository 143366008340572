import _ from "lodash";
import { DISTRICT } from '../actions/types';
import { constants } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        district: _.cloneDeep(constants.DISTRICT_INPUT)
    }
}

const districtReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DISTRICT.SET_DISTRICT_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case DISTRICT.SET_DISTRICT:
        case DISTRICT.SET_DISTRICT_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    district: action.district
                }
            })
        default:
            return state
    }
}

export default districtReducer