import _ from "lodash";
import { SYSTEM_USER } from '../actions/types';
import { constants } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        systemUser: _.cloneDeep(constants.SYSTEM_USER_INPUT)
    }
}

const systemUserReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SYSTEM_USER.SET_SYSTEM_USER_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case SYSTEM_USER.SET_SYSTEM_USER:
        case SYSTEM_USER.SET_SYSTEM_USER_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    systemUser: action.systemUser
                }
            })
        default:
            return state
    }
}

export default systemUserReducer