import { useState, useEffect } from "react";
import { useStore } from "react-redux";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { Card } from '@mui/material';

import { ListDisplay } from '../../Component/ListDisplay/ListDisplay';

import { constants } from '../../Constants/constants';
import { getList } from "../../helper/baseFetch";

export const RestaurantBlackoutList = ({ editBtn, createBtn, newFilter, newPath, newListTitles, newResourcenName }) => {
  const store = useStore();
  const params = useParams();
  const [restaurantBlackoutList, setRestaurantBlackoutList] = useState([]);
  const [ready, setReady] = useState(false);
  const resourceName = "RestaurantBlackout";

  const listTitles = [
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "type",
    }, {
      key: constants.LIST_DISPLAY_TYPE.DATE_TIME,
      value: "startTimestamp",
      sort: true,
    }, {
      key: constants.LIST_DISPLAY_TYPE.DATE_TIME,
      value: "endTimestamp",
      sort: true,
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "status",
      sort: true
    }
  ];
  const listFilters = [
    // {
    //   className: "filter-input",
    //   label: { resource: resourceName, key: "type" },
    //   value: '',
    //   type: constants.TYPE.SELECT,
    //   selectValue: constants.CHOICES.RESTAURANT_BLACKOUT.TYPE,
    //   name: "type",
    //   size: constants.SIZE.SMALL
    // },
    {
      className: "filter-input",
      label: { resource: resourceName, key: "status" },
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.RESTAURANT_BLACKOUT.STATUS,
      name: "status",
      size: constants.SIZE.SMALL
    }
  ];

  const [param, setParam] = useState({
    path: newPath ? newPath : "",
    page: 0,
    rowsPerPage: 10,
    sort: 'updateTimestamp',
    direction: 'DESC',
    listFilters: listFilters,
    count: 0,
  })

  function onChangeParam(value) {
    value.count = value.count + 1;
    setParam(value);
  }

  function onChangeListFilters(list) {
    let tempParam = _.cloneDeep(param);
    tempParam.listFilters = list;
    tempParam.page = 0;
    onChangeParam(tempParam);
  }

  function onChangeListSort(sortItem) {
    let tempParam = _.cloneDeep(param);
    tempParam.sort = sortItem;
    tempParam.direction = tempParam.direction === "DESC" ? "ASC" : "DESC";
    onChangeParam(tempParam);
  }

  function onChangeListPage(page) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = page;
    onChangeParam(tempParam);
  }

  function onChangeListRowsPerPage(rowsPerPage) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = 0;
    tempParam.rowsPerPage = rowsPerPage;
    onChangeParam(tempParam);
  }

  useEffect(() => {
    let tempParam = _.cloneDeep(param);
    const filter = newFilter ? _.cloneDeep(newFilter) : { id: params.id };
    tempParam.listFilters.forEach((filterItem) => {
      if (filterItem.value !== '' && filterItem.value !== undefined) {
        filter[filterItem.name] = filterItem.value;
      }
    })

    getList(tempParam.path ? tempParam.path : constants.PATH.SHOP.RESTAURANT_BLACKOUT, { sort: { field: tempParam.sort, order: tempParam.direction }, filter: filter, pagination: { page: tempParam.page + 1, perPage: tempParam.rowsPerPage } }, (payload) => {
      setRestaurantBlackoutList(payload);
      setReady(true)
    }, undefined, store);

  }, [param.count]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <Card className="card-wrapper overflow">
      <ListDisplay
        page={param.page}
        rowsPerPage={param.rowsPerPage}
        onChangeListSort={onChangeListSort}
        resource={resourceName}
        dataList={restaurantBlackoutList}
        sort={param.sort}
        direction={param.direction}
        listTitles={listTitles}
        listFilters={param.listFilters}
        onChangeListPage={onChangeListPage}
        onChangeListRowsPerPage={onChangeListRowsPerPage}
        editBtn={editBtn}
        createBtn={createBtn}
        idParam= {params.id}
        onChangeListFilters={onChangeListFilters}
        redirectPath={"/" + (newPath ? newPath : constants.PATH.SHOP.RESTAURANT_BLACKOUT) + "/"}
      />
    </Card>
  )
};