import { rejectReasonValidationConstants } from '../Constants/validationConstants';

class QueueRejectReasonValidation {

  version;
  errorMap;
  queueRejectReasonErrorMap;
  valid;

  constructor() {

    if (!QueueRejectReasonValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.queueRejectReasonErrorMap = new Map();
      QueueRejectReasonValidation.instance = this;
    }
    return QueueRejectReasonValidation.instance;
  }

  validateEditQueueRejectReason(queueRejectReason) {
    this.resetErrorMessages();
    this.handleValidateEditQueueRejectReason(queueRejectReason);
    this.version++;
  }

  validateCreateQueueRejectReason(queueRejectReason) {
    this.resetErrorMessages();
    this.handleValidateCreateQueueRejectReason(queueRejectReason);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.queueRejectReasonErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key, uniqueCode) {
    if (!uniqueCode) {
      return (this.queueRejectReasonErrorMap.has(key)) ? this.queueRejectReasonErrorMap.get(key) : [];
    }
  }

  handleValidateCreateQueueRejectReason(queueRejectReason) {
    if (!queueRejectReason.restUrlId) {
      this.setQueueRejectReasonErrorMapMessages(rejectReasonValidationConstants.KEY_REST_URL_ID, "Missing Rest Url Id");
    }
    if (!queueRejectReason.status) {
      this.setQueueRejectReasonErrorMapMessages(rejectReasonValidationConstants.KEY_STATUS, "Missing Status");
    }
    if(!queueRejectReason.enReason && !queueRejectReason.tcReason && !queueRejectReason.scReason) {
      this.setQueueRejectReasonErrorMapMessages(rejectReasonValidationConstants.KEY_EN_REASON, "At least One Reason is required");
    }
  }

  handleValidateEditQueueRejectReason(queueRejectReason) {
    if (!queueRejectReason.restUrlId) {
      this.setQueueRejectReasonErrorMapMessages(rejectReasonValidationConstants.KEY_REST_URL_ID, "Missing Rest Url Id");
    }
    if (!queueRejectReason.status) {
      this.setQueueRejectReasonErrorMapMessages(rejectReasonValidationConstants.KEY_STATUS, "Missing Status");
    }
    if(!queueRejectReason.enReason && !queueRejectReason.tcReason && !queueRejectReason.scReason) {
      this.setQueueRejectReasonErrorMapMessages(rejectReasonValidationConstants.KEY_EN_REASON, "At least One Reason is required");
    }
  }

  setQueueRejectReasonErrorMapMessages(key, errorMessages, valid = false) {
    this.queueRejectReasonErrorMap.set(key, errorMessages);
    // console.log("tncErrorMap", this.tncErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new QueueRejectReasonValidation();
export default validation;