import React from 'react'
import { TempBtn } from '../../Component/Input/TempBtn'
import { constants } from '../../Constants/constants'
import { TempTextInput } from '../../Component/Input/TempTextInput'
import { Card } from '@mui/material'
import Delete from '@mui/icons-material/Delete'

export const RestaurantField = ({ resource, restaurant, handleDelete, onChangeInput }) => {
  return (
    <Card className="image-list">
      <div className="delete-icon">
        <TempBtn
          btnSetting={{
            className: 'edit-create-btn',
            variant: constants.STYLE.VARIANT.CONTAINED,
            color: constants.STYLE.PRIMARY,
            label: { key: 'deleteBtn' },
            onClick: () => handleDelete(restaurant.restUrlId),
            icon: <Delete />,
          }}
        />
      </div>
      <div className="edit-display-wrapper">
        <div>
          <TempTextInput
            inputSetting={{
              className: 'edit-create-input',
              variant: constants.STYLE.VARIANT.STANDARD,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: 'restUrlId' },
              value: restaurant.restUrlId,
              name: 'restUrlId',
              isEndOfLine: false,
              disabled: true,
            }}
          />
        </div>
        <TempTextInput
          inputSetting={{
            className: 'edit-create-input',
            variant: constants.STYLE.VARIANT.STANDARD,
            size: constants.SIZE.SMALL,
            label: { resource: resource, key: 'restEnName' },
            value: restaurant.enName,
            name: 'restEnName',
            isEndOfLine: false,
            disabled: true,
          }}
        />
        <TempTextInput
          inputSetting={{
            className: 'edit-create-input',
            variant: constants.STYLE.VARIANT.STANDARD,
            size: constants.SIZE.SMALL,
            label: { resource: resource, key: 'restTcName' },
            value: restaurant.tcName,
            name: 'restTcName',
            isEndOfLine: false,
            disabled: true,
          }}
        />
        <TempTextInput
          inputSetting={{
            className: 'edit-create-input',
            variant: constants.STYLE.VARIANT.STANDARD,
            size: constants.SIZE.SMALL,
            label: { resource: resource, key: 'restScName' },
            value: restaurant.scName,
            name: 'restScName',
            isEndOfLine: true,
            disabled: true,
          }}
        />
      </div>
    </Card>
  )
}
