import { useState, useEffect } from "react";
import { useStore } from "react-redux";
import _ from "lodash";

import { Card } from '@mui/material';

import { ListDisplay } from '../../Component/ListDisplay/ListDisplay';

import { constants } from '../../Constants/constants';
import { getList } from "../../helper/baseFetch";
import { GridList } from './GridList';

export const SalesShopFrontImageList = ({ newListTitles, newFilter }) => {
  const store = useStore();
  const [restaurantApproveListList, setRestaurantApproveListList] = useState([]);
  const [ready, setReady] = useState(false);
  const resource = "salesFrontImage";

  const listFilters = newFilter ? newFilter : [
    {
      className: "filter-input",
      label: {resource: resource , key: "name"},
      clear: true,
      value: '',
      name: "name",
      size: constants.SIZE.SMALL
    },
  ];

  const [param, setParam] = useState({
    path: "",
    page: 0,
    rowsPerPage: 50,
    rowsPerPageOptions: [50, 100, 150, 200],
    sort: 'updateTimestamp',
    direction: 'DESC',
    listFilters: listFilters,
    count: 0,
  })

  function onChangeParam(value) {
    value.count = value.count + 1;
    setParam(value);
  }

  function onChangeListFilters(list) {
    let tempParam = _.cloneDeep(param);
    tempParam.listFilters = list;
    tempParam.page = 0;
    onChangeParam(tempParam);
  }

  function onChangeListSort(sortItem) {
    let tempParam = _.cloneDeep(param);
    tempParam.sort = sortItem;
    tempParam.direction = tempParam.direction === "DESC" ? "ASC" : "DESC";
    onChangeParam(tempParam);
  }

  function onChangeListPage(page) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = page;
    onChangeParam(tempParam);
  }

  function onChangeListRowsPerPage(rowsPerPage) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = 0;
    tempParam.rowsPerPage = rowsPerPage;
    onChangeParam(tempParam);
  }

  useEffect(() => {
    let tempParam = _.cloneDeep(param);
    const filter = {};
    tempParam.listFilters.forEach((filterItem) => {
      if (filterItem.value !== '' && filterItem.value !== undefined) {
        filter[filterItem.name] = filterItem.value;
      }
    })

    getList(constants.PATH.SALES.SALES_SHOP_FRONT_IMAGE, { sort: { field: tempParam.sort, order: tempParam.direction }, filter: filter, pagination: { page: tempParam.page + 1, perPage: tempParam.rowsPerPage } }, (payload) => {
        setRestaurantApproveListList(payload);
        setReady(true)
      }, undefined, store);

  }, [param.count]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <Card className="card-wrapper overflow">
      <ListDisplay
        page={param.page}
        rowsPerPage={param.rowsPerPage}
        rowsPerPageOptions={param.rowsPerPageOptions}
        onChangeListSort={onChangeListSort}
        resource={resource}
        dataList={restaurantApproveListList}
        sort={param.sort}
        direction={param.direction}
        listTitles={null}
        otherContent={<GridList dataList={restaurantApproveListList} />}
        listFilters={param.listFilters}
        onChangeListPage={onChangeListPage}
        onChangeListRowsPerPage={onChangeListRowsPerPage}
        editBtn={false}
        createBtn={false}
        onChangeListFilters={onChangeListFilters}
        redirectPath={"/" + constants.PATH.SALES.SALES_SHOP_FRONT_IMAGE + "/"}
      />
    </Card>
  )
};