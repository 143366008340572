import ReactGA from 'react-ga';
import queryString from "query-string";

import { setLoading, setMessage, setTotal } from "../actions/systemAction";
import { constants } from '../Constants/constants';
import { getString } from './util';

export const getList = (path, dataParam, successCallback, failCallback, store) => {
  const dispatch = store.dispatch;
  const lang = store.getState().lang.ui.lang;
  const token = localStorage.getItem(constants.LOCAL_STORAGE_KEY.TOKEN);
  dispatch(setLoading(true));

  const apiDomain = constants.APIURL.URL_ADMIN + '/';

  const headers = {
    'Content-Type': 'application/json;charset=UTF-8',
    'Accept-Language': lang ? lang : constants.LANG.EN,
    'X-LANG': lang ? lang : constants.LANG.EN,
  };
  if (token) {
    headers['X-AUTH-TOKEN'] = token;
  }

  const { field, order } = dataParam ? dataParam.sort ? dataParam.sort : {} : {};

  let queryObject = dataParam ? {
    sort: field,
    order,
    filter: JSON.stringify(dataParam.filter),
    lang: "EN"
  } : dataParam;

  if (dataParam && dataParam.pagination) {
    const { page, perPage } = dataParam.pagination;

    queryObject = Object.assign(queryObject, {
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      page: page - 1,
      perPage
    });
  }

  fetch(apiDomain + path + `?${queryString.stringify(queryObject)}`, {
    method: 'GET',
    headers: headers,
  }).then((res) => {
      
    if (path !== constants.PATH.SALES.SALES_USER) {
      dispatch(setTotal(res.headers.get('X-Total-Count')));
    }
    if (res.status === 200) {
      return res.json().then((data)=>({
        ...data,
        xTotal: res.headers.get('X-Total-Count')
      }));
    } else if(res.status === 401) {
      handleErrorDialog(getString(lang, null, "loginError", null), dispatch, 401);
    } else {
      ReactGA.event({
        category: 'API Call',
        action: apiDomain + path,
        label: JSON.stringify({
          statusCode: res.status,
          id: (dataParam && dataParam.id) ? dataParam.id : ''
        })
      })
      return Promise.reject('status code !== 200');
    }
  }).then((data) => {
    if (data.returnCode === 0) {
      successCallback(data.payload, data.xTotal);
    } else if (data.returnCode > 0) {
      ReactGA.event({
        category: 'API Call',
        action: apiDomain + path,
        label: JSON.stringify({
          returnCode: data.returnCode,
          id: (dataParam && dataParam.id) ? dataParam.id : ''
        })
      })
      if (failCallback) {
        failCallback(data.message);
      } else {
        handleErrorDialog(data.message, dispatch);
      }
    } else {
      ReactGA.event({
        category: 'API Call',
        action: apiDomain + path,
        label: JSON.stringify({
          returnCode: data.returnCode,
          id: (dataParam && dataParam.id) ? dataParam.id : ''
        })
      })
      handleErrorDialog('serverError', dispatch);
    }
  }).catch((error) => {
    console.log('error', error);
    ReactGA.event({
      category: 'API Call',
      action: apiDomain + path,
      label: JSON.stringify({
        error: 'Unexpected error',
        id: (dataParam && dataParam.id) ? dataParam.id : ''
      })
    })
  }).finally(() => {
    dispatch(setLoading(false));
  });
}

export const getOne = (path, dataParam, successCallback, failCallback, store) => {
  const dispatch = store.dispatch;
  const lang = store.getState().lang.ui.lang;
  const token = localStorage.getItem(constants.LOCAL_STORAGE_KEY.TOKEN);
  dispatch(setLoading(true));

  const apiDomain = constants.APIURL.URL_ADMIN + '/';

  const headers = {
    'Content-Type': 'application/json;charset=UTF-8',
    'Accept-Language': lang ? lang : constants.LANG.EN,
    'X-LANG': lang ? lang : constants.LANG.EN,
  };
  if (token) {
    headers['X-AUTH-TOKEN'] = token;
  }

  let queryString = '';
  if (dataParam) {
    const keys = Object.keys(dataParam);
    if (keys.length > 0) {
      queryString = '?' + keys.map(key => key + '=' + dataParam[key]).join('&');
    }
  }

  fetch(apiDomain + path + queryString, {
    method: 'GET',
    headers: headers,
  }).then((res) => {
    
    if (res.status === 200) {
      return res.json();
    } else if(res.status === 401) {
      handleErrorDialog(getString(lang, null, "loginError", null), dispatch, 401);
    } else {
      ReactGA.event({
        category: 'API Call',
        action: apiDomain + path,
        label: JSON.stringify({
          statusCode: res.status,
          id: (dataParam && dataParam.id) ? dataParam.id : ''
        })
      })
      return Promise.reject('status code !== 200');
    }
  }).then((data) => {
    if (data.returnCode === 0) {
      successCallback(data.payload);
    } else if (data.returnCode > 0) {
      ReactGA.event({
        category: 'API Call',
        action: apiDomain + path,
        label: JSON.stringify({
          returnCode: data.returnCode,
          id: (dataParam && dataParam.id) ? dataParam.id : ''
        })
      })
      if (failCallback) {
        failCallback(data.message);
      } else {
        handleErrorDialog(data.message, dispatch);
      }
    } else {
      ReactGA.event({
        category: 'API Call',
        action: apiDomain + path,
        label: JSON.stringify({
          returnCode: data.returnCode,
          id: (dataParam && dataParam.id) ? dataParam.id : ''
        })
      })
      handleErrorDialog('serverError', dispatch);
    }
  }).catch((error) => {
    console.log('error', error);
    ReactGA.event({
      category: 'API Call',
      action: apiDomain + path,
      label: JSON.stringify({
        error: 'Unexpected error',
        id: (dataParam && dataParam.id) ? dataParam.id : ''
      })
    })
  }).finally(() => {
    dispatch(setLoading(false));
  });
}

export const put = (path, dataParam, successCallback, failCallback, store) => {
  const dispatch = store.dispatch;
  const lang = store.getState().lang.ui.lang;
  const token = localStorage.getItem(constants.LOCAL_STORAGE_KEY.TOKEN);
  dispatch(setLoading(true));

  const apiDomain = constants.APIURL.URL_ADMIN + '/';

  const headers = {
    'Content-Type': 'application/json',
    'Accept-Language': lang ? lang : constants.LANG.EN,
    'X-LANG': lang ? lang : constants.LANG.EN,
  };
  if (token) {
    headers['X-AUTH-TOKEN'] = token;
  }

  fetch(apiDomain + path, {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(dataParam)
  }).then((res) => {
    if (res.status === 200) {
      return res.json();
    } else if(res.status === 401) {
      handleErrorDialog(getString(lang, null, "loginError", null), dispatch, 401);
    } else {
      ReactGA.event({
        category: 'API Call',
        action: apiDomain + path,
        label: JSON.stringify({
          statusCode: res.status,
          id: (dataParam && dataParam.id) ? dataParam.id : ''
        })
      })
      handleErrorDialog(`Error : status code "${res.status}"`, dispatch);
      return Promise.reject('status code !== 200');
    }
  }).then((data) => {
    // console.log("data", data);
    if (data.returnCode === 0) {
      successCallback(data.payload);
    } else if (data.returnCode > 0) {
      ReactGA.event({
        category: 'API Call',
        action: apiDomain + path,
        label: JSON.stringify({
          returnCode: data.returnCode,
          id: (dataParam && dataParam.id) ? dataParam.id : ''
        })
      })
      if (failCallback) {
        failCallback(data.message);
      } else {
        handleErrorDialog(data.message, dispatch);
      }
    } else {
      ReactGA.event({
        category: 'API Call',
        action: apiDomain + path,
        label: JSON.stringify({
          returnCode: data.returnCode,
          id: (dataParam && dataParam.id) ? dataParam.id : ''
        })
      })
      handleErrorDialog('serverError', dispatch);
    }
  }).catch((error) => {
    console.log('error', error);
    ReactGA.event({
      category: 'API Call',
      action: apiDomain + path,
      label: JSON.stringify({
        error: 'Unexpected error',
        id: (dataParam && dataParam.id) ? dataParam.id : ''
      })
    })
  }).finally(() => {
    dispatch(setLoading(false));
  });
}

export const post = (path, dataParam, successCallback, failCallback, store) => {
  const dispatch = store.dispatch;
  const lang = store.getState().lang.ui.lang;
  const token = localStorage.getItem(constants.LOCAL_STORAGE_KEY.TOKEN);
  dispatch(setLoading(true));

  const apiDomain = constants.APIURL.URL_ADMIN + '/';

  const headers = {
    'Content-Type': 'application/json',
    'Accept-Language': lang ? lang : constants.LANG.EN,
    'X-LANG': lang ? lang : constants.LANG.EN,
  };
  if (token) {
    headers['X-AUTH-TOKEN'] = token;
  }

  fetch(apiDomain + path, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(dataParam)
  }).then((res) => {
    if (res.status === 200) {
      return res.json();
    } else if(res.status === 401) {
      handleErrorDialog(getString(lang, null, "loginError", null), dispatch, 401);
    } else {
      ReactGA.event({
        category: 'API Call',
        action: apiDomain + path,
        label: JSON.stringify({
          statusCode: res.status,
          id: (dataParam && dataParam.id) ? dataParam.id : ''
        })
      })
      return Promise.reject('status code !== 200');
    }
  }).then((data) => {
    // console.log("data", data);
    if (data.returnCode === 0) {
      successCallback(data.payload);
    } else if (data.returnCode > 0) {
      ReactGA.event({
        category: 'API Call',
        action: apiDomain + path,
        label: JSON.stringify({
          returnCode: data.returnCode,
          id: (dataParam && dataParam.id) ? dataParam.id : ''
        })
      })
      if (failCallback) {
        failCallback(data.message);
      } else {
        handleErrorDialog(data.message, dispatch);
      }
    } else {
      ReactGA.event({
        category: 'API Call',
        action: apiDomain + path,
        label: JSON.stringify({
          returnCode: data.returnCode,
          id: (dataParam && dataParam.id) ? dataParam.id : ''
        })
      })
      handleErrorDialog('serverError', dispatch);
    }
  }).catch((error) => {
    console.log('error', error);
    ReactGA.event({
      category: 'API Call',
      action: apiDomain + path,
      label: JSON.stringify({
        error: 'Unexpected error',
        id: (dataParam && dataParam.id) ? dataParam.id : ''
      })
    })
  }).finally(() => {
    dispatch(setLoading(false));
  });
}

export const postFormData = (path, formData, successCallback, failCallback, store) => {
  const dispatch = store.dispatch;
  const lang = store.getState().lang.ui.lang;
  const token = localStorage.getItem(constants.LOCAL_STORAGE_KEY.TOKEN);
  dispatch(setLoading(true));

  const apiDomain = constants.APIURL.URL_ADMIN + '/';

  const headers = {
    // 'Content-Type': 'application/json',
    'Accept-Language': lang ? lang : constants.LANG.EN,
    'X-LANG': lang ? lang : constants.LANG.EN,
  };
  if (token) {
    headers['X-AUTH-TOKEN'] = token;
  }

  fetch(apiDomain + path, {
    method: 'POST',
    headers: headers,
    body: formData
  }).then((res) => {
    if (res.status === 200) {
      return res.json();
    } else if(res.status === 401) {
      handleErrorDialog(getString(lang, null, "loginError", null), dispatch, 401);
    } else {
      ReactGA.event({
        category: 'API Call',
        action: apiDomain + path,
        label: JSON.stringify({
          statusCode: res.status,
        })
      })
      return Promise.reject('status code !== 200');
    }
  }).then((data) => {
    if (data.returnCode === 0) {
      successCallback(data.payload);
    } else if (data.returnCode > 0) {
      ReactGA.event({
        category: 'API Call',
        action: apiDomain + path,
        label: JSON.stringify({
          returnCode: data.returnCode,
        })
      })
      if (failCallback) {
        failCallback(data.message);
      } else {
        handleErrorDialog(data.message, dispatch);
      }
    } else {
      ReactGA.event({
        category: 'API Call',
        action: apiDomain + path,
        label: JSON.stringify({
          returnCode: data.returnCode,
        })
      })
      handleErrorDialog('serverError', dispatch);
    }
  }).catch((error) => {
    console.log('error', error);
    ReactGA.event({
      category: 'API Call',
      action: apiDomain + path,
      label: JSON.stringify({
        error: 'Unexpected error',
      })
    })
  }).finally(() => {
    dispatch(setLoading(false));
  });
}

export const deleteFetch = (path, dataParam, successCallback, failCallback, store) => {
  const dispatch = store.dispatch;
  const lang = store.getState().lang.ui.lang;
  const token = localStorage.getItem(constants.LOCAL_STORAGE_KEY.TOKEN);
  dispatch(setLoading(true));

  const apiDomain = constants.APIURL.URL_ADMIN + '/';

  const headers = {
    'Content-Type': 'application/json',
    'Accept-Language': lang ? lang : constants.LANG.EN,
    'X-LANG': lang ? lang : constants.LANG.EN,
  };
  if (token) {
    headers['X-AUTH-TOKEN'] = token;
  }

  fetch(apiDomain + path, {
    method: 'DELETE',
    headers: headers,
  }).then((res) => {
    if (res.status === 200) {
      return res.json();
    } else if(res.status === 401) {
      handleErrorDialog(getString(lang, null, "loginError", null), dispatch, 401);
    } else {
      ReactGA.event({
        category: 'API Call',
        action: apiDomain + path,
        label: JSON.stringify({
          statusCode: res.status,
          id: (dataParam && dataParam.id) ? dataParam.id : ''
        })
      })
      return Promise.reject('status code !== 200');
    }
  }).then((data) => {
    // console.log("data", data);
    if (data.returnCode === 0) {
      successCallback(data.payload);
    } else if (data.returnCode > 0) {
      ReactGA.event({
        category: 'API Call',
        action: apiDomain + path,
        label: JSON.stringify({
          returnCode: data.returnCode,
          id: (dataParam && dataParam.id) ? dataParam.id : ''
        })
      })
      if (failCallback) {
        failCallback(data.message);
      } else {
        handleErrorDialog(data.message, dispatch);
      }
    } else {
      ReactGA.event({
        category: 'API Call',
        action: apiDomain + path,
        label: JSON.stringify({
          returnCode: data.returnCode,
          id: (dataParam && dataParam.id) ? dataParam.id : ''
        })
      })
      handleErrorDialog('serverError', dispatch);
    }
  }).catch((error) => {
    console.log('error', error);
    ReactGA.event({
      category: 'API Call',
      action: apiDomain + path,
      label: JSON.stringify({
        error: 'Unexpected error',
        id: (dataParam && dataParam.id) ? dataParam.id : ''
      })
    })
  }).finally(() => {
    dispatch(setLoading(false));
  });
}

export const uploadFile = (path, dataParam, successCallback, failCallback, store) => {
  const dispatch = store.dispatch;
  const lang = store.getState().lang.ui.lang;
  const token = localStorage.getItem(constants.LOCAL_STORAGE_KEY.TOKEN);
  dispatch(setLoading(true));

  const apiDomain = constants.APIURL.URL_ADMIN + '/';

  const formBody = new FormData();
  for (const property in dataParam) {
    if (property !== null) {
      const encodedKey = property
      const encodedValue = dataParam[property]
      if (encodedKey === 'files') {
        encodedValue.forEach(function process(item) {
          item.files.forEach(function appendFiles(imageFile) {
            formBody.append(item.name, imageFile);
          })
        });
      } else {
        formBody.append(encodedKey, encodedValue);
      }
    }
  }

  const headers = {
    'Accept-Language': lang ? lang : constants.LANG.EN,
    'X-LANG': lang ? lang : constants.LANG.EN,
  };
  if (token) {
    headers['X-AUTH-TOKEN'] = token;
  }

  fetch(apiDomain + path, {
    method: 'POST',
    headers: headers,
    body: formBody,
  }).then((res) => {
    if (res.status === 200) {
      return res.json();
    } else if(res.status === 401) {
      handleErrorDialog(getString(lang, null, "loginError", null), dispatch, 401);
    } else {
      ReactGA.event({
        category: 'API Call',
        action: apiDomain + path,
        label: JSON.stringify({
          statusCode: res.status,
          id: (dataParam && dataParam.id) ? dataParam.id : ''
        })
      })
      return Promise.reject('status code !== 200');
    }
  }).then((data) => {
    // console.log("data", data);
    if (data.returnCode === 0) {
      successCallback(data.payload);
    } else if (data.returnCode > 0) {
      ReactGA.event({
        category: 'API Call',
        action: apiDomain + path,
        label: JSON.stringify({
          returnCode: data.returnCode,
          id: (dataParam && dataParam.id) ? dataParam.id : ''
        })
      })
      if (failCallback) {
        failCallback(data.message);
      } else {
        handleErrorDialog(data.message, dispatch);
      }
    } else {
      ReactGA.event({
        category: 'API Call',
        action: apiDomain + path,
        label: JSON.stringify({
          returnCode: data.returnCode,
          id: (dataParam && dataParam.id) ? dataParam.id : ''
        })
      })
      handleErrorDialog('serverError', dispatch);
    }
  }).catch((error) => {
    console.log('error', error);
    ReactGA.event({
      category: 'API Call',
      action: apiDomain + path,
      label: JSON.stringify({
        error: 'Unexpected error',
        id: (dataParam && dataParam.id) ? dataParam.id : ''
      })
    })
  }).finally(() => {
    dispatch(setLoading(false));
  });
}

const handleErrorDialog = (errorMessage, dispatch, messageType) => {
  dispatch(setMessage(errorMessage, messageType))
}