import React from 'react'
import _ from 'lodash'
import { constants } from '../../../Constants/constants'
import { TableType } from './TableType'
import { SortableItem } from './SortableItem'
import { TableTypeSection } from './TableTypeSection'
import { SortableContext } from '@dnd-kit/sortable'
import { setQueueTemplateTableTypeValue, setQueueTemplateTimeSectionValue } from '../../../actions'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from '../../../actions/systemAction'

export const SortableContent = ({
  idList,
  displayList,
  timesectionIdx,
  resource,
  item,
  handleCloneTableType,
  handleDeleteTableTypeList,
  setSelectedType,
  tableTypeMap,
}) => {
  const dispatch = useDispatch()
  const savedTableType = useSelector((state) => state.queueTemplate.ui.tableType)

  const handleTableTypeOnClick = (e, tableType) => {
    dispatch(setLoading(true))
    e.stopPropagation()
    const newQueueTemplateInput = _.cloneDeep(constants.TIME_SECTION_DEFAULT_VALUE)
    dispatch(setQueueTemplateTimeSectionValue(newQueueTemplateInput))
    dispatch(setQueueTemplateTableTypeValue(tableType))
    setSelectedType('tableType')
    dispatch(setLoading(false))
  }

  return (
    <div className="bottom drag-and-drop-section">
      <SortableContext droppableId={'large'} items={idList} strategy={() => {}}>
        {displayList.map((tableTypeId, idx) => {
          if (tableTypeId.group) {
            return (
              <TableTypeSection
                key={`grouped-section-${tableTypeId.group}`}
                timesectionIdx={timesectionIdx}
                sectionList={tableTypeId.list}
                tableTypeMap={tableTypeMap}
                droppableId={`grouped-section-${tableTypeId.group}`}
                groupLabel={tableTypeId.group}
                resource={resource}
                item={item}
                handleCloneTableType={handleCloneTableType}
                handleDeleteTableTypeList={handleDeleteTableTypeList}
                setSelectedType={setSelectedType}
                idList={idList}
              />
            )
          }
          return (
            <SortableItem key={tableTypeId} id={tableTypeId}>
              <div
                className={tableTypeId === savedTableType.customId ? `sub-item queue-table-type queue-template-selected-product` : 'sub-item queue-table-type'}
                onClick={(e) => handleTableTypeOnClick(e, tableTypeMap.get(tableTypeId))}
              >
                <TableType
                  resource={resource}
                  item={item}
                  tableType={tableTypeMap.get(tableTypeId)}
                  tableTypeForegroundColor={tableTypeMap.get(tableTypeId).displayTableTypeForegroundColor}
                  tableTypeBackgroundColor={tableTypeMap.get(tableTypeId).displayTableTypeBackgroundColor}
                  handleCloneTableType={handleCloneTableType}
                  handleDeleteTableTypeList={handleDeleteTableTypeList}
                />
              </div>
            </SortableItem>
          )
        })}
      </SortableContext>
    </div>
  )
}
