import { useState, useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import _ from 'lodash'

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CachedIcon from '@mui/icons-material/Cached'

import { constants as co } from '../../Constants/constants'
import { getOne, put, post } from '../../helper/baseFetch'
import { setMerchantConfig, setMerchantConfigValue } from '../../actions/merchantConfigAction'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { DialogWrapper } from '../../Component/DialogWrapper'
import { ShopAppSoundSlider } from './ShopAppSoundSlider'
import { getContent } from '../../Component/EditCreateDisplay/EditUtil'
import { SoundInfoVoiceDropdown } from './SoundInfoVoiceDropdown'
import { BOOLEAN_OPTIONS } from '../../Constants/type'

const resource = 'MerchantConfig'

export const MerchantConfigEdit = () => {
  const dispatch = useDispatch()
  const store = useStore()
  const history = useHistory()
  const params = useParams()
  const [ready, setReady] = useState(false)
  const [clear, setClear] = useState(true)
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  const savedMerchantConfig = useSelector((state) => state.merchantConfig.ui.merchantConfig)
  const tempMerchantConfigEditList = [
    {
      type: co.TYPE.TITLE,
      value: { resource: resource, key: 'queue' },
      className: 'first-sub-title',
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'queueRemindResetQuota' },
      value: savedMerchantConfig.queueRemindResetQuota,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.MERCHANT_CONFIG.QUEUE_REMIND_RESET_QUOTA,
      name: 'queueRemindResetQuota',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'queueChannelStatusControl' },
      value: savedMerchantConfig.queueChannelStatusControl,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.MERCHANT_CONFIG.QUEUE_CHANNEL_STATUS_CONTROL,
      name: 'queueChannelStatusControl',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'queueQuickCheckIn' },
      value: savedMerchantConfig.queueQuickCheckIn,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.MERCHANT_CONFIG.QUEUE_QUICK_CHECK_IN,
      name: 'queueQuickCheckIn',
      disableClearable: true,
      isEndOfLine: false,
    },
    // {
    //   className: "edit-create-input",
    //   size: constants.SIZE.SMALL,
    //   label: { resource: resource, key: "counterCallEnable" },
    //   value: savedMerchantConfig.counterCallEnable,
    //   type: constants.TYPE.SELECT,
    //   selectValue: constants.CHOICES.MERCHANT_CONFIG.COUNTER_CALL_ENABLE,
    //   name: "counterCallEnable",
    //   disableClearable: true,
    //   isEndOfLine: false
    // },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'queueRejectTicketEnable' },
      value: savedMerchantConfig.queueRejectTicketEnable,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.MERCHANT_CONFIG.QUEUE_REJECT_TICKET_ENABLE,
      name: 'queueRejectTicketEnable',
      disableClearable: true,
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'queueStatusPerQueueControl' },
      value: savedMerchantConfig.queueStatusPerQueueControl,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.MERCHANT_CONFIG.QUEUE_REJECT_TICKET_ENABLE,
      name: 'queueStatusPerQueueControl',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'queueMultipleStatusControl' },
      value: savedMerchantConfig.queueMultipleStatusControl,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.MERCHANT_CONFIG.QUEUE_REJECT_TICKET_ENABLE,
      name: 'queueMultipleStatusControl',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'queueDisplayControl' },
      value: savedMerchantConfig.queueDisplayControl,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.MERCHANT_CONFIG.QUEUE_REJECT_TICKET_ENABLE,
      name: 'queueDisplayControl',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      type: co.TYPE.TITLE,
      value: { resource: resource, key: 'product' },
      className: 'sub-title',
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'publishProduct' },
      value: savedMerchantConfig.publishProduct,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.MERCHANT_CONFIG.PUBLISH_PRODUCT,
      name: 'publishProduct',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'createProduct' },
      value: savedMerchantConfig.createProduct,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.MERCHANT_CONFIG.CREATE_PRODUCT,
      name: 'createProduct',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'sendProduct' },
      value: savedMerchantConfig.sendProduct,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.MERCHANT_CONFIG.SEND_PRODUCT,
      name: 'sendProduct',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'preorderProduct' },
      value: savedMerchantConfig.preorderProduct,
      type: co.TYPE.SELECT,
      selectValue: BOOLEAN_OPTIONS,
      name: 'preorderProduct',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      type: co.TYPE.TITLE,
      value: { resource: resource, key: 'slideshow' },
      className: 'sub-title',
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'guluSlideshowEnable' },
      value: savedMerchantConfig.guluSlideshowEnable,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.MERCHANT_CONFIG.GULU_SLIDESHOW_ENABLE,
      name: 'guluSlideshowEnable',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      type: co.TYPE.TITLE,
      value: { resource: resource, key: 'shopAppSoundSpeed' },
      className: 'sub-title',
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      type: co.TYPE.FUNCTION,
      value: <ShopAppSoundSlider soundSpeed={savedMerchantConfig.soundSpeed} onChangeInput={onChangeInput} />,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      type: co.TYPE.FUNCTION,
      value: savedMerchantConfig.voiceCode && (
        <SoundInfoVoiceDropdown value={savedMerchantConfig.voiceCode} resource={resource} onChangeInput={onChangeInput} disableClearable />
      ),
    },
    {
      type: co.TYPE.TITLE,
      value: { resource: resource, key: 'device' },
      className: 'sub-title',
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'customPassword' },
      value: savedMerchantConfig.customPassword,
      name: 'customPassword',
      isEndOfLine: false,
    },
  ]
  const content = [
    {
      tab: tempMerchantConfigEditList.map((inputSetting, index) => {
        let content = getContent(inputSetting, index, onChangeInput)
        return (
          <div className="edit-display-wrapper" key={index}>
            {content}
            {inputSetting.isEndOfLine && <div></div>}
          </div>
        )
      }),
      btn: true,
    },
  ]

  const bottomBtns = [
    {
      variant: co.STYLE.VARIANT.CONTAINED,
      color: co.STYLE.PRIMARY,
      label: { key: 'save' },
      onClick: onClickSave,
      className: 'margin-left-16',
      icon: <SaveIcon />,
    },
    {
      variant: co.STYLE.VARIANT.CONTAINED,
      color: co.STYLE.PRIMARY,
      label: { key: 'clear' },
      disabled: false,
      onClick: onClickClear,
      className: 'margin-left-16',
      icon: <ClearIcon />,
    },
    {
      className: 'special-button special-button-margin-left',
      variant: co.STYLE.VARIANT.CONTAINED,
      icon: <CachedIcon />,
      label: { resource ,key: 'clearSoundFiles' },
      onClick: handleSoundClear,
    },
  ]

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push('/' + co.PATH.SHOP.SYSTEM_GROUP_RESTAURANT)
  }

  function handleSoundClear() {
    put(
      co.PATH.MERCHANT.CLEAR_SOUND_FILES + '/' + params.id,
      null,
      (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToEdit,
          dialogContentText: { key: 'editSuccess' },
          dialogActions: [
            { label: { key: 'backEdit' }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
            { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
          ],
        })
        setClear(false)
      },
      undefined,
      store
    )
  }

  function onChangeInput(name, e, index) {
    if (e === '') {
      e = null
    }
    dispatch(setMerchantConfigValue(name, savedMerchantConfig, '', e))
  }

  function onClickSave() {
    let tempSavedMerchantConfig = _.cloneDeep(savedMerchantConfig)
    if (tempSavedMerchantConfig.id) {
      put(
        co.PATH.MERCHANT.MERCHANT_CONFIG + '/' + params.id,
        tempSavedMerchantConfig,
        (payload) => {
          setDialog({
            open: true,
            onClose: onClickBackToEdit,
            dialogContentText: { key: 'editSuccess' },
            dialogActions: [
              { label: { key: 'backEdit' }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
              { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
            ],
          })
          setClear(false)
        },
        undefined,
        store
      )
    } else {
      tempSavedMerchantConfig.restUrlId = params.id
      post(
        co.PATH.MERCHANT.MERCHANT_CONFIG,
        tempSavedMerchantConfig,
        (payload) => {
          setDialog({
            open: true,
            onClose: onClickBackToEdit,
            dialogContentText: { key: 'editSuccess' },
            dialogActions: [
              { label: { key: 'backEdit' }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
              { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
            ],
          })
          setClear(false)
        },
        undefined,
        store
      )
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    if (clear === true) {
      getOne(
        co.PATH.MERCHANT.MERCHANT_CONFIG + '/' + params.id,
        undefined,
        (payload) => {
          dispatch(setMerchantConfig(payload))
          setClear(false)
          setReady(true)
        },
        undefined,
        store
      )
    }
  }, [clear]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready && (
      <>
        <EditCreateDisplay
          code={params.id ? params.id : ''}
          resource={resource}
          tabs={false}
          content={content}
          onChangeInput={onChangeInput}
          bottomBtns={bottomBtns}
          display="none"
        />
        {dialog && dialog.open && <DialogWrapper dialogInfo={dialog} />}
      </>
    )
  )
}
