import { Clear, SwapHoriz, SwapVert, Warning } from '@mui/icons-material'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import '../../styles/genericDragAndDrop.scss'
import { Card, IconButton, Tooltip } from '@mui/material'
import { constants as co } from '../../Constants/constants'

import variables from '../../styles/globalVariables.scss'
const { middleGrayColor } = variables

const cardStyle = { display: 'flex', flexDirection: 'column', padding: '15px', marginBottom: '20px', width: '100%', gap: '10px' }
const titieStyle = { fontSize: '20px', fontWeight: 'bold' }
const buttonContentStyle = {}
const dashStyle = {
  color: `${middleGrayColor}`,
  paddingLeft: '5px',
  paddingRight: '5px',
}

export default function GenericDragAndDrop({
  name,
  title,
  dragList,
  onDragEnd,
  onDelete,
  direction,
  enNameKey,
  tcNameKey,
  scNameKey,
  numberKey,
  buttonContent,
}) {
  let vertical = direction === 'vertical' ? true : false

  return (
    <>
      <Card sx={cardStyle}>
        <div style={titieStyle}>{title}</div>
        {buttonContent && <div style={buttonContentStyle}>{buttonContent}</div>}
        <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
          <Droppable droppableId={name} direction={direction}>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={vertical ? 'vertical-droppable-content' : 'horizontal-droppable-content'}
              >
                {dragList?.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={vertical ? 'vertical-item' : 'horizontal-item'}
                      >
                        <div className="sub-content">
                          {vertical ? (
                            <SwapVert color={co.STYLE.PRIMARY} fontSize={co.SIZE.LARGE} />
                          ) : (
                            <SwapHoriz color={co.STYLE.PRIMARY} fontSize={co.SIZE.LARGE} />
                          )}
                          {tcNameKey && item[tcNameKey]}
                          {scNameKey && <span style={dashStyle}>/</span>}
                          {scNameKey && item[scNameKey]}
                          {enNameKey && <span style={dashStyle}>/</span>}
                          {enNameKey && item[enNameKey]}
                          {numberKey && <span style={dashStyle}>/</span>}
                          {numberKey && item[numberKey]}
                          {onDelete && (
                            <IconButton
                              className="delete-button--clear"
                              fontSize={co.SIZE.LARGE}
                              size={co.SIZE.SMALL}
                              onClick={() => onDelete(name, item.id)}
                            >
                              <Clear />
                            </IconButton>
                          )}
                        </div>
                        {item.warning && (
                          <Tooltip title="Counter not exist in ref counter list, please delete for this profile." placement="right" arrow>
                            <div>
                              <Warning />
                            </div>
                          </Tooltip>
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Card>
    </>
  )
}
