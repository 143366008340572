import { Cancel } from '@mui/icons-material'
import { constants } from '../../Constants/constants'

export const ErrorMsg = ({ errorMsg, showErrorIcon }) => {
  return (
    <span className="error-message">
      {showErrorIcon && <Cancel className="error-message-icon" fontSize={constants.SIZE.SMALL} />}
      {errorMsg}
    </span>
  )
}
