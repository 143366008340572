import { PAYMENT } from './types';
import _ from "lodash";

export const setPaymentDefault = () => {
    return {
        type: PAYMENT.SET_PAYMENT_DEFAULT,
    }
}

export const setPayment = (payment) => {
    return {
        type: PAYMENT.SET_PAYMENT,
        payment: payment
    }
}

export const setPaymentValue = (draftType, orignalProduct, key, value, index) => {
    const payment = _.cloneDeep(orignalProduct);
    payment[draftType] = value;
    return {
        type: PAYMENT.SET_PAYMENT_VALUE,
        payment: payment
    }
}

export const setUploadPaymentList = (paymentList) => {
    return {
        type: PAYMENT.SET_UPLOAD_PAYMENT_LIST,
        paymentList: paymentList
    }
}
