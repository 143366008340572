import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { setProductValue } from "../../../actions/productCreateAction";
import { PRODUCT_TYPE } from '../../../Constants/constants';
import { getString } from "../../../helper/util";

export const ProductListCheckBox = ({ listCheckBox }) => {
  const dispatch = useDispatch();
  const savedProduct = useSelector(state => state.product.ui.product);
  const lang = useSelector(state => state.lang.ui.lang);
  const theme = useSelector(state => state.theme.ui.theme);

  const handleListChange = (event) => {
    let tempDraft = _.cloneDeep(savedProduct.draft);
    if (event.target.name === "ALL") {
      Object.keys(PRODUCT_TYPE).forEach((key) => tempDraft[listCheckBox][key] = event.target.checked);
    } else {
      tempDraft[listCheckBox] = { ...savedProduct.draft[listCheckBox], [event.target.name]: event.target.checked };
      if (!event.target.checked) {
        tempDraft[listCheckBox] = { ...tempDraft[listCheckBox], "ALL": event.target.checked };
      } else {
        if (Object.keys(PRODUCT_TYPE).filter((key) => tempDraft[listCheckBox][key] !== tempDraft[listCheckBox]["ALL"]).length === Object.keys(PRODUCT_TYPE).length - 1) {
          tempDraft[listCheckBox] = { ...tempDraft[listCheckBox], "ALL": true };
        }
      }
    }
    dispatch(setProductValue("", savedProduct, "", tempDraft));
  };

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={savedProduct.draft[listCheckBox].ALL}
            onChange={handleListChange}
            name="ALL"
          />
        }
        label={getString(lang, "productCreateProductList", "all", theme)}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={savedProduct.draft[listCheckBox].ECOUPON_ITEM}
            onChange={handleListChange}
            name="ECOUPON_ITEM"
          />
        }
        label={getString(lang, "productCreateProductList", "eCoupon", theme)}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={savedProduct.draft[listCheckBox].PREORDER_ITEM}
            onChange={handleListChange}
            name="PREORDER_ITEM"
          />
        }
        label={getString(lang, "productCreateProductList", "preOrder", theme)}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={savedProduct.draft[listCheckBox].PACKAGE_ITEM}
            onChange={handleListChange}
            name="PACKAGE_ITEM"
          />
        }
        label={getString(lang, "productCreateProductList", "package", theme)}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={savedProduct.draft[listCheckBox].FREE_ECOUPON_ITEM}
            onChange={handleListChange}
            name="FREE_ECOUPON_ITEM"
          />
        }
        label={getString(lang, "productCreateProductList", "freeEcouponItem", theme)}
      />
      {listCheckBox !== "productLotListCheckBox" &&
        <FormControlLabel
          control={
            <Checkbox
              checked={savedProduct.draft[listCheckBox].PACKAGE_SUB_ITEM}
              onChange={handleListChange}
              name="PACKAGE_SUB_ITEM"
            />
          }
          label={getString(lang, "productCreateProductList", "packageSubItem", theme)}
        />
      }
    </div>
  )
};