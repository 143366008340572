import { useState, useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import _ from 'lodash'
import QRCode from 'qrcode.react'

import ClearIcon from '@mui/icons-material/Clear'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, Card, Drawer, IconButton } from '@mui/material'

import { constants as co, IMAGE_TYPE, PICTURE_SIZE } from '../../Constants/constants'
import { getOne, put, post } from '../../helper/baseFetch'
import { setPrintConfig, setPrintConfigValue } from '../../actions/printConfigAction'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { TempTextInput } from '../../Component/Input/TempTextInput'
import { TempBtn } from '../../Component/Input/TempBtn'
import { TempAutoSelectInput } from '../../Component/Input/TempAutoSelectInput'
import { DialogWrapper } from '../../Component/DialogWrapper'
import { PrintTemplateList } from '../PrintTemplate/PrintTemplateList'
import { UploadImage } from '../../Component/UploadImage/UploadImage'
import PrintConfigValidation from '../../validations/printConfigValidation'
import { printConfigValidationConstants } from '../../Constants/validationConstants'
import { getString } from '../../helper/util'
import logoPositionGuide from '../../pictures/print_template_logo_position_guide.jpg'
import headerPositionGuide from '../../pictures/print_template_header_position_guide.jpg'
import footerPositionGuide from '../../pictures/print_template_footer_position_guide.jpg'
import RightLargeAndSmallPositionGuide from '../../pictures/print_template_right_large_and_small_position_guide.png'
import portraitRectanglePositionGuide from '../../pictures/print_template_portrait_rectangle_position_guide.jpg'
import landscapeRectanglePositionGuide from '../../pictures/print_template_landscape_rectangle_position_guide.png'
import landscapeSquarePositionGuide from '../../pictures/print_template_landscape_square_position_guide.png'
import mallLogoPositionGuide from '../../pictures/print_template_mall_logo_position_guide.png'
import mallKeypadButtonImagePositionGuide from '../../pictures/print_template_key_pad_button_image_position_guide.png'
import { getDefaultBottomBtnList } from '../../Component/EditCreateDisplay/EditUtil'
import { DEFAULT_SERVICE_LIST } from '../Restaurant/UrlGenerator'
import { Add, Clear } from '@mui/icons-material'

export const PrintConfigEdit = () => {
  const dispatch = useDispatch()
  const store = useStore()
  const history = useHistory()
  const params = useParams()
  const [ready, setReady] = useState(false)
  const [clear, setClear] = useState(true)
  const [showPrintTemplatePanel, setShowPrintTemplatePanel] = useState(false)
  const [showShortPrintTemplatePanel, setShowShortPrintTemplatePanel] = useState(false)
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  // eslint-disable-next-line no-unused-vars
  const [errorVersion, setErrorVersion] = useState(PrintConfigValidation.getVersion())
  const resource = IMAGE_TYPE.PRINT_CONFIG
  const savedPrintConfig = useSelector((state) => state.printConfig.ui.printConfig)
  const lang = useSelector((state) => state.lang.ui.lang)
  const theme = useSelector((state) => state.theme.ui.theme)

  const [paramObj, setParamObj] = useState({})
  const [moreParamObj, setMoreParamObj] = useState({})

  const isServiceParamDefault = (obj) => {
    return Object.entries(obj).some(([key, value]) => key === 'service' && value === 'default')
  }

  const onSelectParamKey = (key, value) => {
    // console.log('onChangeParamKeySelect')
    // console.log('key', key)
    // console.log('value', value)

    setParamObjAndUrl({ obj: { ...paramObj, [key]: value }, key: key, value: value })
  }

  const onChangeParamKeyInput = (key, value) => {
    let newParamObj = { ...paramObj, [value]: paramObj[key] }
    delete newParamObj[key]
    setParamObjAndUrl({ obj: newParamObj, key: key, value: value })
  }

  const onChangeParamValueInput = (key, value) => {
    const newKey = key.split('-')[0]
    setParamObjAndUrl({ obj: { ...paramObj, [newKey]: value }, key: newKey, value: value })
  }

  const setParamObjAndUrl = ({ obj, key, value }) => {
    const { service, from, ...rest } = obj
    let newParamObj = {}

    if (service) {
      newParamObj.service = service
    }

    if (from) {
      newParamObj.from = from
    }

    newParamObj = { ...newParamObj, ...rest }

    setParamObj(newParamObj)
    let paramStr = ''

    let forEachObj = {...newParamObj}

    if (isServiceParamDefault(forEachObj)) {
      delete forEachObj.service
    }

    Object.entries(forEachObj).forEach(([pairKey, pairValue], i) => {
      const newPairValue = value && pairKey === key ? value : pairValue;
      paramStr += `${i === 0 ? '?' : '&'}${pairKey}=${newPairValue}`;
    });

    const urlStr = savedPrintConfig.displayQrcodeUrl.split('?')[0]

    dispatch(setPrintConfigValue('displayQrcodeUrl', savedPrintConfig, '', urlStr + paramStr))
  }

  const onDeleteParamPair = (key) => {
    const newObj = { ...paramObj }
    delete newObj[key]
    setParamObjAndUrl({ obj: newObj, key: key })
  }

  const onClickAddMoreParamToUrl = () => {
    let i = 1
    let newKey = `key${i}`

    for (const key in paramObj) {
      if (key === newKey) {
        i++
        newKey = `key${i}`
      }
    }

    setParamObjAndUrl({
      obj: {
        ...paramObj,
        [newKey]: '',
      },
      key: newKey,
    })
  }

  const onChangeDisplayQrCodeUrl = (key, value) => {
    value = value || null

    const paramStr = value.split('?')[1]

    if (paramStr?.length) {
      setParamObj(paramStrToObj(paramStr))
    }

    dispatch(setPrintConfigValue(key, savedPrintConfig, '', value))
  }

  const onClickSave = () => {
    let newSavedPrintConfig = _.cloneDeep(savedPrintConfig)

    if (savedPrintConfig.id) {
      PrintConfigValidation.validateEditPrintConfig(newSavedPrintConfig)

      if (!PrintConfigValidation.isValid()) {
        setErrorVersion(PrintConfigValidation.getVersion())
        return
      }

      put(
        co.PATH.SHOP.QUEUE.PRINT_CONFIG + '/' + params.id,
        newSavedPrintConfig,
        () => {
          setDialog({
            open: true,
            onClose: onClickBackToEdit,
            dialogContentText: { key: 'editSuccess' },
            dialogActions: [
              { label: { key: 'backEdit' }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
              { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
            ],
          })
          setClear(false)
        },
        undefined,
        store
      )
    } else {
      newSavedPrintConfig.restUrlId = params.id

      PrintConfigValidation.validateCreatePrintConfig(newSavedPrintConfig)

      if (!PrintConfigValidation.isValid()) {
        setErrorVersion(PrintConfigValidation.getVersion())
        return
      }

      post(
        co.PATH.SHOP.QUEUE.PRINT_CONFIG,
        newSavedPrintConfig,
        () => {
          setDialog({
            open: true,
            onClose: onClickBackToEdit,
            dialogContentText: { key: 'editSuccess' },
            dialogActions: [
              { label: { key: 'backEdit' }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
              { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
            ],
          })
          setClear(false)
        },
        undefined,
        store
      )
    }

    PrintConfigValidation.resetErrorMessages()
  }

  const onSaveImage = (name, image) => {
    dispatch(setPrintConfigValue(name, savedPrintConfig, '', image))
  }

  const onClickClear = () => {
    setClear(true)
  }

  const onChangeInput = (key, value) => {
    if (!value) {
      value = null
    }

    dispatch(setPrintConfigValue(key, savedPrintConfig, '', value))
  }

  const onChangePrintTemplateCode = () => {
    setShowPrintTemplatePanel(true)
  }

  const onClosePrintTemplateDrawer = () => {
    setShowPrintTemplatePanel(false)
  }

  const onClickListRow = (e) => {
    setShowPrintTemplatePanel(false)
    dispatch(setPrintConfigValue('printTemplateCode', savedPrintConfig, '', e.code))
  }

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push('/' + co.PATH.SHOP.SYSTEM_GROUP_RESTAURANT)
  }

  const paramStrToObj = (str) => {
    return str.split('&').reduce((result, item) => {
      let paramPair = item.split('=')
      result[paramPair[0]] = paramPair[1]
      return result
    }, {})
  }

  const DisplayQrCodePreviewCard = () => {
    return (
      <Card sx={{ p: '20px', mb: '20px' }}>
        <div className="first-sub-title sub-title">{getString(lang, resource, 'qrCodePreview', theme)}</div>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'displayQrcodeUrl' },
                value: savedPrintConfig.displayQrcodeUrl,
                name: 'displayQrcodeUrl',
                custom: true,
                customName: { resource: resource, key: 'displayQrcodeUrl' },
                customValue: savedPrintConfig.displayQrcodeUrl,
                multiline: true,
                className: 'full-size-input',
              }}
              handleChange={onChangeDisplayQrCodeUrl}
            />
            <TempAutoSelectInput
              key="service"
              inputSetting={{
                label: { resource: resource, key: 'service' },
                value: paramObj.service,
                selectValue: DEFAULT_SERVICE_LIST,
                name: 'service',
                disableClearable: false,
                freeSolo: true,
              }}
              handleChange={onSelectParamKey}
            />
            <>
              <TempAutoSelectInput
                key="from"
                inputSetting={{
                  label: { resource: resource, key: 'from' },
                  value: paramObj.from,
                  selectValue: [
                    { value: 'KIOSK', name: 'kiosk' },
                    { value: 'qrcode', name: 'qrcode' },
                    { value: 'campaign_qrcode', name: 'campaign_qrcode' },
                    { value: 'edm', name: 'edm' },
                    { value: 'fb', name: 'fb' },
                    { value: 'urlMap', name: 'urlMap' },
                    { value: 'web', name: 'web' },
                    { value: 'SPLASHAD', name: 'SPLASHAD' },
                  ],
                  name: 'from',
                  disableClearable: false,
                  freeSolo: true,
                }}
                handleChange={onSelectParamKey}
              />
              <TempBtn
                btnSetting={{
                  onClick: onClickAddMoreParamToUrl,
                  variant: co.STYLE.VARIANT.CONTAINED,
                  color: co.STYLE.PRIMARY,
                  label: { resource: resource, key: 'addMoreParam' },
                  icon: <Add />,
                  className: 'full-size-input',
                }}
              />
            </>
            {moreParamObj &&
              Object.entries(moreParamObj).map(([key, value]) => (
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <TempTextInput
                    key={key}
                    inputSetting={{
                      label: 'Key',
                      value: key,
                      name: key,
                    }}
                    handleChange={onChangeParamKeyInput}
                  />
                  <TempTextInput
                    key={`${key}-value`}
                    inputSetting={{
                      label: 'Value',
                      value: value,
                      name: `${key}-value`,
                    }}
                    handleChange={onChangeParamValueInput}
                  />
                  <IconButton
                    sx={{ height: '35px', width: '35px' }}
                    className="delete-button--clear"
                    fontSize={co.SIZE.LARGE}
                    size={co.SIZE.SMALL}
                    onClick={() => onDeleteParamPair(key)}
                  >
                    <Clear />
                  </IconButton>
                </Box>
              ))}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
            {savedPrintConfig.displayQrcodeUrl && (
              <QRCode id="qrCode" size={150} value={savedPrintConfig.displayQrcodeUrl} level={'L'} renderAs={'svg'} />
            )}
          </Box>
        </Box>
      </Card>
    )
  }

  const content = [
    {
      tab: (
        <>
          <div>
            <TempAutoSelectInput
              inputSetting={{
                label: { resource: resource, key: 'deviceType' },
                value: savedPrintConfig.deviceType,
                name: 'deviceType',
                selectValue: co.CHOICES.PRINT_CONFIG.DEVICE_TYPE,
                helperText: PrintConfigValidation.getErrorMessages(printConfigValidationConstants.KEY_DEVICE_TYPE),
              }}
              handleChange={onChangeInput}
            />
          </div>
          <TempAutoSelectInput
            inputSetting={{
              label: { resource: resource, key: 'printTemplateType' },
              value: savedPrintConfig.printTemplateType,
              name: 'printTemplateType',
              selectValue: co.CHOICES.PRINT_CONFIG.PRINT_TEMPLATE_TYPE,
              required: true,
            }}
            handleChange={onChangeInput}
          />
          <TempAutoSelectInput
            inputSetting={{
              label: { resource: resource, key: 'printQrcodeType' },
              value: savedPrintConfig.printQrcodeType,
              selectValue: co.CHOICES.PRINT_CONFIG.PRINT_QRCODE_TYPE,
              name: 'printQrcodeType',
              disableClearable: true,
            }}
            handleChange={onChangeInput}
          />
          <div>
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'ticketRemarks' },
                value: savedPrintConfig.ticketRemarks,
                name: 'ticketRemarks',
                multiline: true,
              }}
              handleChange={onChangeInput}
            />
          </div>
          <TempTextInput
            inputSetting={{
              label: { resource: resource, key: 'printTemplateCode' },
              value: savedPrintConfig.printTemplateCode,
              name: 'printTemplateCode',
              onClickEndAdornmentIconButton: onChangePrintTemplateCode,
              disabled: true,
            }}
            handleChange={onChangeInput}
          />
          <TempTextInput
            inputSetting={{
              label: { resource: resource, key: 'shortPrintTemplateCode' },
              value: savedPrintConfig.shortPrintTemplateCode,
              name: 'shortPrintTemplateCode',
              onClickEndAdornmentIconButton: () => setShowShortPrintTemplatePanel(true),
              disabled: true,
            }}
            handleChange={onChangeInput}
          />
          <div>
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'shortTicketTnc' },
                value: savedPrintConfig.shortTicketTnc,
                name: 'shortTicketTnc',
                custom: true,
                customName: { resource: resource, key: 'shortTicketTnc' },
                customValue: savedPrintConfig.shortTicketTnc,
                multiline: true,
                helperText: PrintConfigValidation.getErrorMessages(printConfigValidationConstants.KEY_SHORT_TICKET_TNC),
              }}
              handleChange={onChangeInput}
            />
          </div>
          <div className="first-sub-title sub-title">{getString(lang, resource, 'printTemplateSection', theme)}</div>
          <div className="image">
            <UploadImage
              picture={savedPrintConfig.logoImage}
              title={{ resource: resource, key: 'logoImage' }}
              name="logoImage"
              uploadImagePath={co.PATH.SHOP.QUEUE.UPLOAD_PRINT_CONFIG_IMAGE}
              saveImage={onSaveImage}
              helperText="Size Requirement: 570W x unlimited H"
              helperDialog={
                <div className="printe-template-preview">
                  <img src={logoPositionGuide} alt="print_template_logo_position_guide" />
                </div>
              }
              removeImage={() => onChangeInput('logoImage')}
              type={resource}
              size={PICTURE_SIZE.MID}
            />
          </div>
          <div className="image">
            <UploadImage
              picture={savedPrintConfig.headerImage}
              title={{ resource: resource, key: 'headerImage' }}
              name="headerImage"
              uploadImagePath={co.PATH.SHOP.QUEUE.UPLOAD_PRINT_CONFIG_IMAGE}
              saveImage={onSaveImage}
              helperText="Size Requirement: 570W x unlimited H"
              helperDialog={
                <div className="printe-template-preview">
                  <img src={headerPositionGuide} alt="print_template_header_position_guide" />
                </div>
              }
              removeImage={() => onChangeInput('headerImage')}
              type={resource}
              allowedFileExtensionList={['image/jpeg', 'image/jpg']}
              size={PICTURE_SIZE.MID}
            />
          </div>
          <div className="image">
            <UploadImage
              picture={savedPrintConfig.footerImage}
              title={{ resource: resource, key: 'footerImage' }}
              name="footerImage"
              uploadImagePath={co.PATH.SHOP.QUEUE.UPLOAD_PRINT_CONFIG_IMAGE}
              saveImage={onSaveImage}
              helperText="Size Requirement: 570W x unlimited H"
              helperDialog={
                <div className="printe-template-preview">
                  <img src={footerPositionGuide} alt="print_template_footer_position_guide" />
                </div>
              }
              removeImage={() => onChangeInput('footerImage')}
              type={resource}
              allowedFileExtensionList={['image/jpeg', 'image/jpg']}
              size={PICTURE_SIZE.MID}
            />
          </div>
          <div className="image">
            <UploadImage
              picture={savedPrintConfig.qrcodeImage}
              title={{ resource: resource, key: 'qrcodeImage' }}
              name="qrcodeImage"
              uploadImagePath={co.PATH.SHOP.QUEUE.UPLOAD_PRINT_CONFIG_IMAGE}
              saveImage={onSaveImage}
              removeImage={() => onChangeInput('qrcodeImage')}
              type={resource}
              size={PICTURE_SIZE.MID}
            />
          </div>
          <div className="first-sub-title sub-title">{getString(lang, resource, 'kioskSection', theme)}</div>
          <DisplayQrCodePreviewCard />
          <div className="image">
            <UploadImage
              picture={savedPrintConfig.mallLogoImage}
              title={{ resource: resource, key: 'mallLogoImage' }}
              name="mallLogoImage"
              uploadImagePath={co.PATH.SHOP.QUEUE.UPLOAD_PRINT_CONFIG_IMAGE}
              saveImage={onSaveImage}
              removeImage={() => onChangeInput('mallLogoImage')}
              type={resource}
              helperText="Size Requirement: 500W x 200H (5:2)"
              helperDialog={
                <div className="printe-template-preview">
                  <img src={mallLogoPositionGuide} alt="print_template_footer_position_guide" />
                </div>
              }
              size={PICTURE_SIZE.MID}
            />
          </div>
          <div className="image">
            <UploadImage
              picture={savedPrintConfig.mallKeypadButtonImage}
              title={{ resource: resource, key: 'mallKeypadButtonImage' }}
              name="mallLogoImage"
              uploadImagePath={co.PATH.SHOP.QUEUE.UPLOAD_PRINT_CONFIG_IMAGE}
              saveImage={onSaveImage}
              removeImage={() => onChangeInput('mallKeypadButtonImage')}
              type={resource}
              helperText="Size Requirement: 720W x 300H (12:5)"
              helperDialog={
                <div className="printe-template-preview">
                  <img src={mallKeypadButtonImagePositionGuide} alt="print_template_key_pad_button_image_position_guide" />
                </div>
              }
              size={PICTURE_SIZE.MID}
            />
          </div>
          <div className="image">
            <UploadImage
              picture={savedPrintConfig.rightLargeImage}
              title={{ resource: resource, key: 'rightLargeImage' }}
              name="rightLargeImage"
              uploadImagePath={co.PATH.SHOP.QUEUE.UPLOAD_PRINT_CONFIG_IMAGE}
              saveImage={onSaveImage}
              removeImage={() => onChangeInput('rightLargeImage')}
              type={resource}
              helperText="Size Requirement: 500W x 500H"
              helperDialog={
                <div className="printe-template-preview">
                  <img src={landscapeSquarePositionGuide} alt="print_template_footer_position_guide" />
                </div>
              }
              size={PICTURE_SIZE.MID}
            />
          </div>
          <div className="image">
            <UploadImage
              picture={savedPrintConfig.rightSmallImage}
              title={{ resource: resource, key: 'rightSmallImage' }}
              name="rightSmallImage"
              uploadImagePath={co.PATH.SHOP.QUEUE.UPLOAD_PRINT_CONFIG_IMAGE}
              saveImage={onSaveImage}
              removeImage={() => onChangeInput('rightSmallImage')}
              type={resource}
              helperText="Size Requirement: 500W x 500H"
              helperDialog={
                <div className="printe-template-preview">
                  <img src={landscapeSquarePositionGuide} alt="print_template_footer_position_guide" />
                </div>
              }
              size={PICTURE_SIZE.MID}
            />
          </div>
          <div className="first-sub-title sub-title">{getString(lang, resource, 'shopAppSection', theme)}</div>
          <div className="image">
            <UploadImage
              picture={savedPrintConfig.portraitRectangleImage}
              title={{ resource: resource, key: 'portraitRectangleImage' }}
              name="portraitRectangleImage"
              uploadImagePath={co.PATH.SHOP.QUEUE.UPLOAD_PRINT_CONFIG_IMAGE}
              saveImage={onSaveImage}
              removeImage={() => onChangeInput('portraitRectangleImage')}
              type={resource}
              helperText="Size Requirement: 1080W x 182H"
              helperDialog={
                <div className="printe-template-preview">
                  <img src={portraitRectanglePositionGuide} alt="print_template_footer_position_guide" />
                </div>
              }
              size={PICTURE_SIZE.MID}
            />
          </div>
          <div className="image">
            <UploadImage
              picture={savedPrintConfig.portraitSquareImage}
              title={{ resource: resource, key: 'portraitSquareImage' }}
              name="portraitSquareImage"
              uploadImagePath={co.PATH.SHOP.QUEUE.UPLOAD_PRINT_CONFIG_IMAGE}
              saveImage={onSaveImage}
              removeImage={() => onChangeInput('portraitSquareImage')}
              type={resource}
              helperText="Size Requirement: 500W x 500H"
              helperDialog={
                <div className="printe-template-preview">
                  <img src={RightLargeAndSmallPositionGuide} alt="print_template_footer_position_guide" />
                </div>
              }
              size={PICTURE_SIZE.MID}
            />
          </div>
          <div className="image">
            <UploadImage
              picture={savedPrintConfig.landscapeRectangleImage}
              title={{ resource: resource, key: 'landscapeRectangleImage' }}
              name="landscapeRectangleImage"
              uploadImagePath={co.PATH.SHOP.QUEUE.UPLOAD_PRINT_CONFIG_IMAGE}
              saveImage={onSaveImage}
              removeImage={() => onChangeInput('landscapeRectangleImage')}
              type={resource}
              helperText="Size Requirement: 1920W x 188H"
              helperDialog={
                <div className="printe-template-preview">
                  <img src={landscapeRectanglePositionGuide} alt="print_template_footer_position_guide" />
                </div>
              }
              size={PICTURE_SIZE.MID}
            />
          </div>
          <div className="image">
            <UploadImage
              picture={savedPrintConfig.landscapeSquareImage}
              title={{ resource: resource, key: 'landscapeSquareImage' }}
              name="landscapeSquareImage"
              uploadImagePath={co.PATH.SHOP.QUEUE.UPLOAD_PRINT_CONFIG_IMAGE}
              saveImage={onSaveImage}
              removeImage={() => onChangeInput('landscapeSquareImage')}
              type={resource}
              helperText="Size Requirement: 500W x 500H"
              helperDialog={
                <div className="printe-template-preview">
                  <img src={landscapeSquarePositionGuide} alt="print_template_footer_position_guide" />
                </div>
              }
              size={PICTURE_SIZE.MID}
            />
          </div>
          <div className="image">
            <UploadImage
              picture={savedPrintConfig.portraitHeaderImage}
              title={{ resource: resource, key: 'portraitHeaderImage' }}
              name="portraitHeaderImage"
              uploadImagePath={co.PATH.SHOP.QUEUE.UPLOAD_PRINT_CONFIG_IMAGE}
              saveImage={onSaveImage}
              removeImage={() => onChangeInput('portraitHeaderImage')}
              type={resource}
              size={PICTURE_SIZE.MID}
            />
          </div>
          <div className="image">
            <UploadImage
              picture={savedPrintConfig.portraitFooterImage}
              title={{ resource: resource, key: 'portraitFooterImage' }}
              name="portraitFooterImage"
              uploadImagePath={co.PATH.SHOP.QUEUE.UPLOAD_PRINT_CONFIG_IMAGE}
              saveImage={onSaveImage}
              removeImage={() => onChangeInput('portraitFooterImage')}
              type={resource}
              size={PICTURE_SIZE.MID}
            />
          </div>
          <div className="image">
            <UploadImage
              picture={savedPrintConfig.landscapeHeaderImage}
              title={{ resource: resource, key: 'landscapeHeaderImage' }}
              name="landscapeHeaderImage"
              uploadImagePath={co.PATH.SHOP.QUEUE.UPLOAD_PRINT_CONFIG_IMAGE}
              saveImage={onSaveImage}
              removeImage={() => onChangeInput('landscapeHeaderImage')}
              type={resource}
              size={PICTURE_SIZE.MID}
            />
          </div>
          <div className="image">
            <UploadImage
              picture={savedPrintConfig.landscapeFooterImage}
              title={{ resource: resource, key: 'landscapeFooterImage' }}
              name="landscapeFooterImage"
              uploadImagePath={co.PATH.SHOP.QUEUE.UPLOAD_PRINT_CONFIG_IMAGE}
              saveImage={onSaveImage}
              removeImage={() => onChangeInput('landscapeFooterImage')}
              type={resource}
              size={PICTURE_SIZE.MID}
            />
          </div>
        </>
      ),
      btn: true,
    },
  ]

  useEffect(() => {
    if (clear === true) {
      getOne(
        co.PATH.SHOP.QUEUE.PRINT_CONFIG + '/' + params.id,
        undefined,
        (payload) => {
          let data = _.cloneDeep(payload)
          data.printTemplateType = payload.printTemplateType || 'NORMAL'
          data.shortPrintTemplateCode = payload.shortPrintTemplateCode || 'DEFAULT_SHORT_TEMPLATE'
          dispatch(setPrintConfig(data))
          setReady(true)
          setClear(false)

          const paramStr = payload.displayQrcodeUrl?.split('?')[1]

          if (paramStr?.length) {
            setParamObj(paramStrToObj(paramStr))
          }
        },
        undefined,
        store
      )
    }
    PrintConfigValidation.resetErrorMessages()
  }, [clear]) // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   console.log('savedPrintConfig', savedPrintConfig)
  // }, [savedPrintConfig])

  // useEffect(() => {
  //   console.log('paramObj', paramObj)
  // }, [paramObj])

  // useEffect(() => {
  //   console.log('moreParamObj', moreParamObj)
  // }, [moreParamObj])

  useEffect(() => {
    const newMoreParamObj = { ...paramObj }
    delete newMoreParamObj.service
    delete newMoreParamObj.from
    setMoreParamObj(newMoreParamObj)
  }, [paramObj])

  return (
    ready && (
      <>
        <EditCreateDisplay
          code={params.id || ''}
          resource={resource}
          tabs={false}
          content={content}
          onChangeInput={onChangeInput}
          bottomBtns={getDefaultBottomBtnList(onClickSave, onClickClear)}
          display="none"
        />
        <Drawer classes={{ paper: 'drawer-margin-left' }} anchor="right" open={showPrintTemplatePanel} onClose={onClosePrintTemplateDrawer}>
          <PrintTemplateList
            editBtn={false}
            createBtn={false}
            btnList={false}
            onClickListRow={onClickListRow}
            dialogFooterBtn={
              <TempBtn
                btnSetting={{
                  variant: co.STYLE.VARIANT.CONTAINED,
                  color: co.STYLE.PRIMARY,
                  onClick: onClosePrintTemplateDrawer,
                  icon: <ClearIcon />,
                  label: { key: 'close' },
                }}
              />
            }
          />
        </Drawer>
        <Drawer
          classes={{ paper: 'drawer-margin-left' }}
          anchor="right"
          open={showShortPrintTemplatePanel}
          onClose={() => setShowShortPrintTemplatePanel(false)}
        >
          <PrintTemplateList
            editBtn={false}
            createBtn={false}
            btnList={false}
            onClickListRow={(e) => {
              setShowShortPrintTemplatePanel(false)
              dispatch(setPrintConfigValue('shortPrintTemplateCode', savedPrintConfig, '', e.code))
            }}
            newFilter={{ type: 'SHORT' }}
            dialogFooterBtn={
              <TempBtn
                btnSetting={{
                  variant: co.STYLE.VARIANT.CONTAINED,
                  color: co.STYLE.PRIMARY,
                  onClick: () => setShowShortPrintTemplatePanel(false),
                  icon: <ClearIcon />,
                  label: { key: 'close' },
                }}
              />
            }
          />
        </Drawer>
        {dialog.open && <DialogWrapper dialogInfo={dialog} />}
      </>
    )
  )
}
