import { staffValidationConstants as constants } from '../Constants/validationConstants'
import { validateEmail } from '../helper/util'
class CounterValidation {
  version
  errMap
  valid

  constructor() {
    if (!CounterValidation.instance) {
      this.version = 0
      this.valid = true
      this.errMap = new Map()
      CounterValidation.instance = this
    }
    return CounterValidation.instance
  }

  validateSavedData(savedData, createMode) {
    this.resetErrorMessages()
    this.validateObj(savedData, createMode)
    this.version++
  }

  validateObj(savedData, createMode) {

    if (!savedData.username) {
      this.setErrMsgMap(constants.KEY_NAME, 'Missing Name')
    }

    if (savedData.username && savedData.username !== savedData.username.toLowerCase()) {
      this.setErrMsgMap(constants.KEY_NAME, 'No Uppercase is Allowed')
    }

    if(createMode && !savedData.password) {
      this.setErrMsgMap(constants.KEY_PASSWORD, 'Missing Password')
    }

    if (!savedData.staffRole) {
      this.setErrMsgMap(constants.KEY_STAFF_ROLE, 'Missing Staff Role')
    }

    if (!savedData.surname) {
      this.setErrMsgMap(constants.KEY_SURNAME, 'Missing Surname')
    }

    if (!savedData.firstName) {
      this.setErrMsgMap(constants.KEY_FIRST_NAME, 'Missing First Name')
    }

    if (!savedData.displayName) {
      this.setErrMsgMap(constants.KEY_DISPLAY_NAME, 'Missing Display Name')
    }

    if (savedData.email && !validateEmail(savedData.email)) {
      this.setErrMsgMap(constants.KEY_EMAIL, 'Invalid Email')
    }

    const numbersOnly = /^\d+$/

    if (savedData.countryCode && !numbersOnly.test(savedData.countryCode)) {
      this.setErrMsgMap(constants.KEY_COUNTRY_CODE, 'Invalid Country Code')
    }

    if (savedData.mobile && !numbersOnly.test(savedData.mobile)) {
      this.setErrMsgMap(constants.KEY_MOBILE, 'Invalid Mobile')
    }

  }

  resetErrorMessages() {
    this.errMap = new Map()
    this.valid = true
  }

  getVersion() {
    return this.version
  }

  isValid() {
    return this.valid
  }

  getErrorMessages(key, uniqueKey) {
    if (!uniqueKey) {
      return this.errMap.has(key) ? this.errMap.get(key) : []
    } else {
      return []
    }
  }

  setErrMsgMap(key, errMsg, valid = false) {
    this.errMap.set(key, errMsg)
    if (!valid) {
      this.valid = false
    }
  }
}

const validation = new CounterValidation()
export default validation
