import { constants } from './Constants/constants';
import { setMessage } from "./actions/systemAction";

const authProvider = {
  // called when the user attempts to log in
  login: params => {
    var formData = new FormData();
    const dispatch = params.store.dispatch;

    formData.append("username", params.username);
    formData.append("password", params.password);

    const API_URL_AUTH = process.env.REACT_APP_API_URL_AUTH_MERCHANT;

    const request = new Request(API_URL_AUTH, {
      method: "POST",
      body: formData
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(function(data) {
        if (data.returnCode === 0) {
          let token = data.payload.token;
          let userRoleList = data.payload.adminUser.userRoleList;
          let permissions = userRoleList;

          localStorage.setItem(constants.LOCAL_STORAGE_KEY.USERNAME, data.payload.adminUser.username);
          localStorage.setItem(constants.LOCAL_STORAGE_KEY.TOKEN, token);
          localStorage.setItem(constants.LOCAL_STORAGE_KEY.ROLE, permissions);

          window.location.reload();
        } else {
          handleErrorDialog(data.message, dispatch);
          // throw new Error(data.message);
        }
      });
    // localStorage.setItem('token', token);
    // accept all token/password combinations
    // return Promise.resolve();
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem(constants.LOCAL_STORAGE_KEY.TOKEN);
    localStorage.removeItem(constants.LOCAL_STORAGE_KEY.ROLE);
    localStorage.removeItem(constants.LOCAL_STORAGE_KEY.UPLOAD_PAYMENT_FILE);
    window.location.reload();
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem(constants.LOCAL_STORAGE_KEY.TOKEN);
      localStorage.removeItem(constants.LOCAL_STORAGE_KEY.ROLE);
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem(constants.LOCAL_STORAGE_KEY.TOKEN) ? Promise.resolve() : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const role = localStorage.getItem(constants.LOCAL_STORAGE_KEY.ROLE);
    return role ? Promise.resolve(role) : Promise.reject();
  }
};

const handleErrorDialog = (errorMessage, dispatch) => {
  dispatch(setMessage(errorMessage))
}

export default authProvider;