import { BANNER_ACTION } from '../../Constants/constants'
import _ from 'lodash'

export function getActionParameters(savedDataValue, actionParametersLang) {
  let tempSavedDataValue = _.cloneDeep(savedDataValue)
  let tempActionParameters = tempSavedDataValue.actionParameters
  switch (actionParametersLang) {
    case 'en':
      tempActionParameters = tempSavedDataValue.enActionParameters
      break
    case 'tc':
      tempActionParameters = tempSavedDataValue.tcActionParameters
      break
    case 'sc':
      tempActionParameters = tempSavedDataValue.scActionParameters
      break
    default:
  }
  let actionParameters = tempActionParameters ? JSON.parse(tempActionParameters).url.replace(process.env.REACT_APP_ACTION_LINK_START, '') : ''
  function handleActionParametersList(actionParametersList, type, action) {
    let newActionParameters = {}
    actionParametersList = actionParametersList.split('&') ? actionParametersList.split('&') : actionParametersList
    actionParametersList &&
      actionParametersList.forEach((actionParameter) => {
        let name = actionParameter.split('=')[0]
        if (name === 'action' && type !== BANNER_ACTION.RESTAURANT) {
          newActionParameters = Object.assign(newActionParameters, {
            [name]: decodeURIComponent(actionParameter.substring(actionParameter.indexOf('=') + 1)),
          })
        } else if (name === 'action' && type === BANNER_ACTION.RESTAURANT) {
          let tempActionParameter = actionParameter.split('=')[1]
          switch (tempActionParameter) {
            case 'getRestInfo':
            case 'INFO':
              tempActionParameter = 'INFO'
              break
            case 'getRestWall':
            case 'WALL':
              tempActionParameter = 'WALL'
              break
            case 'NEW_REVIEW':
              tempActionParameter = 'NEW_REVIEW'
              break
            case 'getRestMenu':
            case 'MENU':
              tempActionParameter = 'MENU'
              break
            case 'getRestQueue':
            case 'TICKET':
            case 'queue':
            case 'QUEUE':
              tempActionParameter = 'QUEUE'
              break
            case 'getRestReserve':
            case 'RESERVE':
              tempActionParameter = 'RESERVE'
              break
            case 'APPOINTMENT':
              tempActionParameter = 'APPOINTMENT'
              break
            case 'appointmentContactInPerson':
            case 'callback':
              tempActionParameter = 'callback'
              break
            case 'appointmentContactShop':
              tempActionParameter = 'appointmentContactShop'
              break
            case 'getRestTakeaway':
            case 'TAKEAWAY':
              tempActionParameter = 'TAKEAWAY'
              break
            default:
          }
          newActionParameters = Object.assign(newActionParameters, { [name]: tempActionParameter })
        }
        // else if (actionParameter.split('=')[1] === "true") {
        //   newActionParameters = Object.assign(newActionParameters, { [name]: true });
        // } else if (actionParameter.split('=')[1] === "false") {
        //   newActionParameters = Object.assign(newActionParameters, { [name]: false });
        // }
        else {
          newActionParameters = Object.assign(newActionParameters, { [name]: actionParameter.split('=')[1] })
        }
      })

    if (
      newActionParameters.brandCode !== undefined &&
      (savedDataValue.actionType === BANNER_ACTION.PRODUCT || savedDataValue.actionType === BANNER_ACTION.E_COUPON)
    ) {
      newActionParameters.productParent = 'BRAND'
    } else if (
      newActionParameters.categoryCode !== undefined &&
      (savedDataValue.actionType === BANNER_ACTION.PRODUCT || savedDataValue.actionType === BANNER_ACTION.E_COUPON)
    ) {
      newActionParameters.productParent = 'CATEGORY'
    }

    switch (actionParametersLang) {
      case 'en':
        tempSavedDataValue.enParameters = newActionParameters
        break
      case 'tc':
        tempSavedDataValue.tcParameters = newActionParameters
        break
      case 'sc':
        tempSavedDataValue.scParameters = newActionParameters
        break
      default:
        tempSavedDataValue.parameters = newActionParameters
    }

    return tempSavedDataValue
  }

  switch (tempSavedDataValue.actionType) {
    case BANNER_ACTION.WEB_VIEW:
    case BANNER_ACTION.VIDEO:
      tempSavedDataValue = handleActionParametersList(actionParameters.replace('web?', ''))
      break
    case BANNER_ACTION.MERCHANT_WEB_VIEW:
    case BANNER_ACTION.MERCHANT_LINK:
      tempSavedDataValue = handleActionParametersList('action=' + actionParameters.replace('?', ''))
      break
    case BANNER_ACTION.SEARCH:
      tempSavedDataValue = handleActionParametersList(actionParameters.replace('search?', ''))
      break
    case BANNER_ACTION.LINK:
      tempSavedDataValue = handleActionParametersList(actionParameters.replace('link?', ''))
      break
    case BANNER_ACTION.PIXEL:
      tempSavedDataValue = handleActionParametersList(actionParameters.replace('pixel?', ''))
      break
    case BANNER_ACTION.PRODUCT_BRAND:
    case BANNER_ACTION.PRODUCT_CATEGORY:
      //case BANNER_ACTION.PRODUCT:
      tempSavedDataValue = handleActionParametersList(actionParameters.replace('product?', ''))
      if (tempSavedDataValue.parameters) {
        tempSavedDataValue.mapValue = tempSavedDataValue.parameters[tempSavedDataValue.inputName]
      }
      break
    case BANNER_ACTION.PRODUCT:
      tempSavedDataValue = handleActionParametersList(actionParameters.replace('product?', ''))
      if (tempSavedDataValue.parameters) {
        tempSavedDataValue.mapValue = tempSavedDataValue.parameters[tempSavedDataValue.inputName]
      }
      break
    case BANNER_ACTION.E_COUPON:
    case BANNER_ACTION.E_COUPON_BRAND:
    case BANNER_ACTION.E_COUPON_CATEGORY:
      tempSavedDataValue = handleActionParametersList(actionParameters.replace('e-coupon?', ''))
      if (tempSavedDataValue.parameters) {
        tempSavedDataValue.mapValue = tempSavedDataValue.parameters[tempSavedDataValue.inputName]
      }
      break
    // case BANNER_ACTION.CATEGORY_PRODUCT:
    // case BANNER_ACTION.CATEGORY_PRODUCT_MAP:
    // case BANNER_ACTION.BRAND_PRODUCT:
    // case BANNER_ACTION.BRAND_PRODUCT_MAP:
    //   tempSavedDataValue = handleActionParametersList(actionParameters.replace("product?", ""));
    //   if (tempSavedDataValue.parameters) {
    //     tempSavedDataValue.mapValue = tempSavedDataValue.parameters["productCode"];
    //   }
    //   break;
    case BANNER_ACTION.FOLDER:
      tempSavedDataValue = handleActionParametersList(actionParameters.replace('folder?', ''))
      if (tempSavedDataValue.parameters) {
        tempSavedDataValue.mapValue = tempSavedDataValue.parameters[tempSavedDataValue.inputName]
      }
      break
    case BANNER_ACTION.RESTAURANT:
      tempSavedDataValue = handleActionParametersList(actionParameters.replace('rest?', ''), tempSavedDataValue.actionType)
      if (tempSavedDataValue.parameters) {
        tempSavedDataValue.mapValue = tempSavedDataValue.parameters[tempSavedDataValue.inputName]
      }
      break
    default:
      tempSavedDataValue = handleActionParametersList(actionParameters)
      break
  }
  return tempSavedDataValue
}
