import { useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { constants } from '../../Constants/constants'
import { useSelector } from 'react-redux'
import { useState } from 'react'

import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'

import { TempBtn } from '../Input/TempBtn'
import { BaseWrapper } from '../BaseWrapper'
import { getString } from '../../helper/util'

// import { makeStyles } from '@mui/styles'
import '../../styles/uploadPayment.scss'

const tabPaperStyle = {
  backgroundColor: 'darkgray',
  boxShadow: 'none',
  margin: '0px 32px',
}

const tabPanelStyle = {
  padding: '0px 32px',
  '& .MuiBox-root': {
    padding: '0px 32px 32px 32px',
  },
}

const tabPanelBoxStyle = { padding: '0px 0px 64px 64px' }

// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: 'darkgray',
//     boxShadow: 'none',
//     margin: '0px 32px',
//   },
//   tab: {
//     color: '#ffffff',
//   },
//   tabPanel: {
//     padding: '0px 32px',
//     '& .MuiBox-root': {
//       padding: '0px 32px 32px 32px',
//     },
//   },
// }))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={tabPanelBoxStyle}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export const SubTabEditCreateDisplay = ({
  display,
  resource,
  tabs,
  content,
  onChangeInput,
  bottomBtns,
  saveDialog,
  tempImageList,
  code,
  mapName,
  mapCode,
}) => {
  const history = useHistory()
  const location = useLocation()
  const params = useParams()
  const lang = useSelector((state) => state.lang.ui.lang)
  const theme = useSelector((state) => state.theme.ui.theme)
  // const classes = useStyles()
  const [tabValue, setTabValue] = useState(Number(params.subTab) ? Number(params.subTab) : 0)

  const handleTabChange = async (event, newValue) => {
    if (params && params.subTab) {
      let path = location.pathname.split('/').map((item, index) => {
        let content = ''
        if (index !== location.pathname.split('/').length - 1) {
          content = item
        } else {
          content = newValue
        }
        return content
      })
      setTabValue(newValue)
      history.push(path.join('/'))
    } else {
      setTabValue(newValue)
    }
  }

  useEffect(() => {
    setTabValue(Number(params.subTab) ? Number(params.subTab) : 0)
  }, [params.subTab])

  // useEffect(() => {
  //   let path = location.pathname.split("/").map((item, index) => {
  //     let content = "";
  //     if (index !== location.pathname.split("/").length - 1) {
  //       content = item;
  //     } else {
  //       content = params.subTab;
  //     }
  //     return content;
  //   })
  //   let newPath = path.join("/");
  //   if (location.pathname !== newPath) {
  //     history.push(newPath);
  //   }
  // }, [params.subTab])// eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   window.scrollTo({ top: 0, behavior: 'smooth' });
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BaseWrapper className="sub-tab-wrapper">
      <HeaderTitle resource={resource} display={display} code={code} mapName={mapName} mapCode={mapCode} lang={lang} theme={theme} />
      {tabs && (
        // <Paper className={classes.root} position="static" >
        <Paper position="static" sx={tabPaperStyle}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="simple tabs example">
            {tabs.map((tab, index) => {
              return (
                <Tab
                  key={index}
                  label={getString(lang, tab.label.resource ? tab.label.resource : '', tab.label.key ? tab.label.key : '', theme)}
                  {...a11yProps(tab.tabIndex)}
                />
              )
            })}
          </Tabs>
        </Paper>
      )}
      {content.map((item, index) => {
        return tabs ? (
          // <TabPanel className={classes.tabPanel} value={tabValue} key={index} index={index}>
          <TabPanel value={tabValue} key={index} index={index} sx={tabPanelStyle}>
            {item.tab}
            {item.btn && (
              <div className="edit-footer-wrapper">
                {bottomBtns &&
                  bottomBtns.map((btnSetting, btnIndex) => {
                    let content = <></>
                    if (btnSetting.type === constants.TYPE.TEXT) {
                      content = (
                        <div key={'btn_' + btnIndex} className={btnSetting.className}>
                          {getString(
                            lang,
                            btnSetting.label.resource ? btnSetting.label.resource : '',
                            btnSetting.label.key ? btnSetting.label.key : '',
                            theme
                          ) + btnSetting.value}
                        </div>
                      )
                    } else {
                      content = <TempBtn key={'btn_' + btnIndex} btnSetting={btnSetting} />
                    }
                    return content
                  })}
              </div>
            )}
          </TabPanel>
        ) : (
          <div className="card-wrapper" key={index}>
            {item.tab}
            {item.btn && (
              <div className="edit-footer-wrapper padding-16">
                {bottomBtns &&
                  bottomBtns.map((btnSetting, btnIndex) => {
                    let content = <></>
                    if (btnSetting.type === constants.TYPE.TEXT) {
                      content = (
                        <div key={'btn_' + btnIndex} className={btnSetting.className}>
                          {getString(
                            lang,
                            btnSetting.label.resource ? btnSetting.label.resource : '',
                            btnSetting.label.key ? btnSetting.label.key : '',
                            theme
                          ) + btnSetting.value}
                        </div>
                      )
                    } else {
                      content = <TempBtn key={'btn_' + btnIndex} btnSetting={btnSetting} />
                    }
                    return content
                  })}
              </div>
            )}
          </div>
        )
      })}
    </BaseWrapper>
  )
}

const HeaderTitle = ({ resource, display, code, mapName, mapCode, lang, theme }) => {
  return (
    <>
      {display === 'none' ? (
        ''
      ) : mapCode ? (
        <>
          <div className="sub-title">
            {getString(lang, mapName.resource, mapName.key, theme)}: <div className="code-title">{mapCode}</div>
          </div>
          <div className="sub-title">
            {getString(lang, resource, `${display}Title`, theme)}: <div className="code-title">{code}</div>
          </div>{' '}
        </>
      ) : (
        <div className="sub-title">
          {getString(lang, resource, `${display}Title`, theme)}: <div className="code-title">{code}</div>
        </div>
      )}
    </>
  )
}
