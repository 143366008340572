import { useState, useEffect } from "react";
import { useSelector, useStore } from "react-redux";
import _ from "lodash";

import { Card } from '@mui/material';

import { ListDisplay } from '../../Component/ListDisplay/ListDisplay';

import { constants } from '../../Constants/constants';
import { getList } from "../../helper/baseFetch";
import { generateTableCellToMultiLanguageName } from "../../helper/util";

export const DistrictRestaurantMapList = ({ editBtn, createBtn }) => {
  const store = useStore();
  const [districtMapList, setDistrictMapList] = useState([]);
  const [ready, setReady] = useState(false);
  const resource = "RestaurantDistrictRestMap";

  const savedDistrict = useSelector(state => state.district.ui.district)
  const districtMapTitleList = [
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "restUrlId",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "restRefId",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "name",
      sort: false,
      minSize: true,
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "address",
      sort: false,
      minSize: true,
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "restStatus",
      sort: true
    }
  ];
  const listFilters = [
    {
      className: "filter-input",
      label: { resource: resource, key: "restUrlId" },
      clear: true,
      value: '',
      name: "restUrlId",
      size: constants.SIZE.SMALL
    },
    {
      className: "filter-input",
      label: { resource: resource, key: "restRefId" },
      clear: true,
      value: '',
      name: "restRefId",
      size: constants.SIZE.SMALL
    },
    {
      className: "filter-input",
      label: { resource: resource, key: "name" },
      clear: true,
      value: '',
      name: "name",
      size: constants.SIZE.SMALL
    },
    {
      className: "filter-input",
      label: { resource: resource, key: "address" },
      clear: true,
      value: '',
      name: "address",
      size: constants.SIZE.SMALL
    },
    {
      className: "filter-input",
      label: { resource: resource, key: "restStatus" },
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.RESTAURANT_DISTRICT_MAP.STATUS,
      name: "status",
      size: constants.SIZE.SMALL
    },
  ];

  const [param, setParam] = useState({
    path: "",
    page: 0,
    rowsPerPage: 10,
    sort: 'updateTimestamp',
    direction: 'DESC',
    listFilters: listFilters,
    count: 0,
  })

  function onChangeParam(value) {
    value.count = value.count + 1;
    setParam(value);
  }

  function onChangeListFilters(list) {
    let tempParam = _.cloneDeep(param);
    tempParam.listFilters = list;
    tempParam.page = 0;
    onChangeParam(tempParam);
  }

  function onChangeListSort(sortItem) {
    let tempParam = _.cloneDeep(param);
    tempParam.sort = sortItem;
    tempParam.direction = tempParam.direction === "DESC" ? "ASC" : "DESC";
    onChangeParam(tempParam);
  }

  function onChangeListPage(page) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = page;
    onChangeParam(tempParam);
  }

  function onChangeListRowsPerPage(rowsPerPage) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = 0;
    tempParam.rowsPerPage = rowsPerPage;
    onChangeParam(tempParam);
  }

  useEffect(() => {
    let tempParam = _.cloneDeep(param);
    const filter = { id: savedDistrict.id };
    tempParam.listFilters.forEach((filterItem) => {
      if (filterItem.value !== '' && filterItem.value !== undefined) {
        filter[filterItem.name] = filterItem.value;
      }
    })
    getList(constants.PATH.SHOP.RESTAURANT_DISTRICT_MAP_REST_LIST, { sort: { field: tempParam.sort, order: tempParam.direction }, filter: filter, pagination: { page: tempParam.page + 1, perPage: tempParam.rowsPerPage } }, (payload) => {
      payload.map((item) => {
        item.name = generateTableCellToMultiLanguageName(item.restTcName, item.restEnName, item.restScName);
        item.address = generateTableCellToMultiLanguageName(item.restTcAddress, item.restEnAddress, item.restScAddress);
        return item
      });
      setDistrictMapList(payload);
      setReady(true)
    }, undefined, store);

  }, [param.count]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <Card className="card-wrapper overflow">
      <ListDisplay
        page={param.page}
        rowsPerPage={param.rowsPerPage}
        onChangeListSort={onChangeListSort}
        resource={resource}
        dataList={districtMapList}
        sort={param.sort}
        direction={param.direction}
        listTitles={districtMapTitleList}
        listFilters={param.listFilters}
        onChangeListPage={onChangeListPage}
        onChangeListRowsPerPage={onChangeListRowsPerPage}
        onChangeListFilters={onChangeListFilters}
        editBtn={editBtn}
        createBtn={createBtn}
        redirectPath={"/" + constants.PATH.SHOP.RESTAURANT_DISTRICT + "/"}
      />
    </Card>
  )
};