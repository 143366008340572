import { RESTAURANT } from './types';
import { sify } from "chinese-conv";
import _ from "lodash";

export const setRestaurantDefault = () => {
    return {
        type: RESTAURANT.SET_RESTAURANT_DEFAULT,
    }
}

export const setRestaurant = (restaurant) => {
    return {
        type: RESTAURANT.SET_RESTAURANT,
        restaurant: restaurant
    }
}

export const setRestaurantValue = (draftType, orignalProduct, key, value, index) => {
    const restaurant = _.cloneDeep(orignalProduct);
    switch (draftType) {
        case "CHANGE_TC_NAME_AND_SC_NAME":
            restaurant.tcName = value;
            restaurant.tcSName = value;
            restaurant.scName = sify(value);
            restaurant.scSName = sify(value);
            break;
        case "CHANGE_TC_WECHAT_NAME_AND_SC_WECHAT_NAME":
            restaurant.tcName = value;
            restaurant.tcSName = value;
            restaurant.scSName = restaurant.scName;
            break;
        case "CHANGE_TC_NAME_AND_SC_NAME_WITHOUT_WECHAT":
            restaurant.tcName = value;
            restaurant.scName = sify(value);
            break;
        case "CHANGE_EN_NAME":
            restaurant.enName = value;
            restaurant.enSName = value;
            break;
        case "CHANGE_TC_SEARCHING_NAME_AND_SC_SEARCHING_NAME":
            restaurant.tcSearchName = value;
            restaurant.scSearchName = sify(value);
            break;
        case "CHANGE_TC_TICKET_MESSAGE_AND_SC_TICKET_MESSAGE":
            restaurant.tcTicketMessage = value;
            restaurant.scTicketMessage = sify(value);
            break;
        case "CHANGE_TC_OPENING_AND_SC_OPENING":
            restaurant.tcOpening = value;
            restaurant.scOpening = sify(value);
            break;
        case "CHANGE_TC_LAST_ORDER_TIME_AND_SC_LAST_ORDER_TIME":
            restaurant.tcLastOrderTime = value;
            restaurant.scLastOrderTime = sify(value);
            break;
        case "CHANGE_TC_DESCRIPTION_AND_SC_DESCRIPTION":
            restaurant.tcDescription = value;
            restaurant.scDescription = sify(value);
            break;
        case "CHANGE_TC_ADDRESS_AND_SC_ADDRESS":
            restaurant.tcAddress = value;
            restaurant.scAddress = sify(value);
            break;
        case "CHANGE_BRAND":
            restaurant.brandCode = value.brandCode;
            restaurant.brandTcName = value.brandTcName;
            restaurant.brandEnName = value.brandEnName;
            restaurant.brandScName = value.brandScName;
            break;
        case "CHANGE_DISTRICT":
            restaurant.districtId = value.districtId;
            restaurant.districtTcName = value.districtTcName;
            restaurant.districtEnName = value.districtEnName;
            restaurant.districtScName = value.districtScName;
            break;
        case "CHANGE_ADDRESS":
            restaurant.coorX = value.coorX;
            restaurant.coorY = value.coorY;
            restaurant.coorZ = value.coorZ;
            restaurant.tcAddress = value.tcAddress;
            restaurant.enAddress = value.enAddress;
            restaurant.scAddress = sify(value.tcAddress);
            break;
        case "CHANGE_IMAGE":
            restaurant.picture = value.picture;
            restaurant.thumbnail = value.thumbnail;
            break;
        case "scNameDisable":
            restaurant[draftType] = value;
            if(value) {
                restaurant.scName = restaurant.tcName? sify(restaurant.tcName): restaurant.tcName;
            }
            break;
        case "scSNameDisable":
            restaurant[draftType] = value;
            if(value) {
                restaurant.enSName = restaurant.enName;
                restaurant.tcSName = restaurant.tcName; 
                restaurant.scSName = restaurant.scName; 
            }
            break;
        case "scSearchNameDisable":
            restaurant[draftType] = value;
            if(value) {
                restaurant.scSearchName = restaurant.tcSearchName? sify(restaurant.tcSearchName): restaurant.tcSearchName;
            }
            break;
        case "scTicketMessageDisable":
            restaurant[draftType] = value;
            if(value) {
                restaurant.scTicketMessage = restaurant.tcTicketMessage? sify(restaurant.tcTicketMessage): restaurant.tcTicketMessage;
            }
            break;
        case "scOpeningDisable":
            restaurant[draftType] = value;
            if(value) {
                restaurant.scOpening = restaurant.tcOpening? sify(restaurant.tcOpening): restaurant.tcOpening;
            }
            break;
        case "scLastOrderTimeDisable":
            restaurant[draftType] = value;
            if(value) {
                restaurant.scLastOrderTime = restaurant.tcLastOrderTime? sify(restaurant.tcLastOrderTime): restaurant.tcLastOrderTime;
            }
            break;
        case "scDescriptionDisable":
            restaurant[draftType] = value;
            if(value) {
                restaurant.scDescription = restaurant.tcDescription? sify(restaurant.tcDescription): restaurant.tcDescription;
            }
            break;
        case "scAddressDisable":
            restaurant[draftType] = value;
            if(value) {
                restaurant.scAddress = restaurant.tcAddress? sify(restaurant.tcAddress): restaurant.tcAddress;
            }
            break;
        default:
            restaurant[draftType] = value;
            if(value) {
                restaurant.scName = restaurant.tcName? sify(restaurant.tcName): restaurant.tcName;
            }
    }
    return {
        type: RESTAURANT.SET_RESTAURANT_VALUE,
        restaurant: restaurant
    }
}

export const setRestaurantClone = (restaurantClone) => {
    return {
        type: RESTAURANT.SET_RESTAURANT_CLONE,
        restaurantClone: restaurantClone
    }
}

export const setRestaurantCloneValue = (draftType, orignalProduct, key, value, index) => {
    const restaurantClone = _.cloneDeep(orignalProduct);
    restaurantClone[draftType] = value;
    return {
        type: RESTAURANT.SET_RESTAURANT_CLONE_VALUE,
        restaurantClone: restaurantClone
    }
}
