import { RESTAURANT_TAG } from './types';
import { sify } from "chinese-conv";
import _ from "lodash";

export const setRestaurantTagDefault = () => {
    return {
        type: RESTAURANT_TAG.SET_RESTAURANT_TAG_DEFAULT,
    }
}

export const setRestaurantTag = (restaurantTag) => {
    return {
        type: RESTAURANT_TAG.SET_RESTAURANT_TAG,
        restaurantTag: restaurantTag
    }
}

export const setRestaurantTagValue = (draftType, orignalProduct, key, value, index) => {
    const restaurantTag = _.cloneDeep(orignalProduct);
    switch (draftType) {
        case "CHANGE_TC_NAME_AND_SC_NAME":
            restaurantTag.tcName = value;
            restaurantTag.scName = sify(value);
            break;
        case "scNameDisable":
            restaurantTag[draftType] = value;
            if(value) {
                restaurantTag.scName = restaurantTag.tcName?sify(restaurantTag.tcName):restaurantTag.tcName;
            }
            break;
        default:
            restaurantTag[draftType] = value;
    }
    return {
        type: RESTAURANT_TAG.SET_RESTAURANT_TAG_VALUE,
        restaurantTag: restaurantTag
    }
}