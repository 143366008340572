import { SLIDESHOW_TYPE, SLIDESHOW_VIDEO_FORMAT } from '../Constants/constants';
import { slideshowBulkCreateValidationConstants } from '../Constants/validationConstants';

class SlideshowBulkCreateValidation {

  version;
  errorMap;
  restaurantSlideshowErrorMap;
  valid;

  constructor() {

    if (!SlideshowBulkCreateValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.restaurantSlideshowErrorMap = new Map();
      SlideshowBulkCreateValidation.instance = this;
    }
    return SlideshowBulkCreateValidation.instance;
  }

  validateSlideshowBulkCreate(slideshowBulk) {

    this.resetErrorMessages();
    this.handleValidateCreateRestaurantSlideshow(slideshowBulk.slideshowList);
    this.validateRestaurantList(slideshowBulk.restUrlIdList);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.restaurantSlideshowErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key, uniqueCode) {
    return this.restaurantSlideshowErrorMap.get(key) ;
  }

  handleValidateCreateRestaurantSlideshow(restaurantSlideshowList) {

    if(!restaurantSlideshowList || restaurantSlideshowList.length === 0) {
      this.setRestaurantSlideshowErrorMapMessages(slideshowBulkCreateValidationConstants.KEY_SLIDESHOW, "Has Error(s)");
    }
    
    for(const slideshow of restaurantSlideshowList){

      const id = slideshow.customId;

      if(!slideshow.media){
        this.setRestaurantSlideshowErrorMapMessages(`${id}-${slideshowBulkCreateValidationConstants.KEY_COS_FILE_URL}`, "Missing Media");
      }
  
      if(slideshow.media && !slideshow.media.cosFileUrl){
        this.setRestaurantSlideshowErrorMapMessages(`${id}-${slideshowBulkCreateValidationConstants.KEY_COS_FILE_URL}`, "Missing Media");
      }

      if(slideshow.media && slideshow.type === SLIDESHOW_TYPE.VIDEO ){

        if(slideshow.media.mime && !is264H(slideshow.media.mime)) {
          this.setRestaurantSlideshowErrorMapMessages(`${id}-${slideshowBulkCreateValidationConstants.KEY_COS_FILE_URL}`, "H264 Format is required");
        }
  
        if(slideshow.media.duration < 10) {
          this.setRestaurantSlideshowErrorMapMessages(`${id}-${slideshowBulkCreateValidationConstants.KEY_COS_FILE_URL}`, "At least 10s is required");
        }
  
      }
  
      //date
      const slideshowStartTimestamp = Number(slideshow.startDisplayTimestamp);
      const slideshowEndTimestamp = Number(slideshow.endDisplayTimestamp);

      if(!isNaN(slideshowStartTimestamp) && !isNaN(slideshowEndTimestamp) 
      && slideshowStartTimestamp!==0
      && slideshowEndTimestamp!==0
      && slideshowStartTimestamp> slideshowEndTimestamp) {
        this.setRestaurantSlideshowErrorMapMessages(`${id}-${slideshowBulkCreateValidationConstants.KEY_END_DISPLAY_TIMESTAMP}`, "Slideshow start timestamp is larger than slideshow end timestamp");
      }
  
      if (!slideshow.type) {
        this.setRestaurantSlideshowErrorMapMessages(`${id}-${slideshowBulkCreateValidationConstants.KEY_TYPE}`, "Missing Type");
      }
      if (!slideshow.sequence && slideshow.sequence !== 0) {
        this.setRestaurantSlideshowErrorMapMessages(`${id}-${slideshowBulkCreateValidationConstants.KEY_SEQUENCE}`, "Missing Display Sequence");
      }
      if (!slideshow.status) {
        this.setRestaurantSlideshowErrorMapMessages(`${id}-${slideshowBulkCreateValidationConstants.KEY_STATUS}`, "Missing Status");
      }

      if(!this.valid) {
        this.setRestaurantSlideshowErrorMapMessages(`${id}`, "Has Error(s)");
        this.setRestaurantSlideshowErrorMapMessages(slideshowBulkCreateValidationConstants.KEY_SLIDESHOW, "Has Error(s)");
      }

    }

  }

  validateRestaurantList(restUrlIdList) {
    if(!restUrlIdList || restUrlIdList.length <= 0) {
      this.setRestaurantSlideshowErrorMapMessages(slideshowBulkCreateValidationConstants.KEY_RESTURLID, "At least one Restaurant is required");
    }
  }

  setRestaurantSlideshowErrorMapMessages(key, errorMessages, valid = false) {
    this.restaurantSlideshowErrorMap.set(key, errorMessages);
    // console.log("restaurantMediaErrorMap", this.restaurantMediaErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new SlideshowBulkCreateValidation();
export default validation;


const is264H = (mime) => {

  mime = mime.replace(/\s/g,'');
  const parts = mime.split(';');
  if(parts.length<2) {
    return false;
  }
  const codecs = parts[1];
  const prefix = 'codecs="';
  if(codecs.startsWith(`${prefix}${SLIDESHOW_VIDEO_FORMAT.H264_BASELINE}`) 
      || codecs.startsWith(`${prefix}${SLIDESHOW_VIDEO_FORMAT.H264_BASELINE_LOWERCASE}`) 
      || codecs.startsWith(`${prefix}${SLIDESHOW_VIDEO_FORMAT.H264_MAIN}`) 
      || codecs.startsWith(`${prefix}${SLIDESHOW_VIDEO_FORMAT.H264_MAIN_LOWERCASE}`) 
      || codecs.startsWith(`${prefix}${SLIDESHOW_VIDEO_FORMAT.H264_HIGH}`) 
      || codecs.startsWith(`${prefix}${SLIDESHOW_VIDEO_FORMAT.AVC1}`) 
    ) {
      return true;
    }
  return false;
}