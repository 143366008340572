import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'

import AccountCircle from '@mui/icons-material/AccountCircle'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import { useSelector } from 'react-redux'

import authProvider from './authProvider'
import { constants, ENV } from './Constants/constants'
import { getString, getTodayShopAppGeneralCode, getTodayShopAppSettingCode } from './helper/util'

const appbarStyle = {
  height: '48px',
}

const toolbarStyle = {
  backgroundColor: process.env.REACT_APP_ENV === ENV.DEVELOPMENT ? '#0096FF' : process.env.REACT_APP_ENV === ENV.PRODUCTION ? '#00008B' : '#89CFF0',
  alignItems: 'center',
}

const titleStyle = {
  flex: 1,
}

const menuButtonStyle = {
  marginRight: '10px',
}

// const useStyles = makeStyles((theme) => ({
// root: {
//   flexGrow: 1,
// },
// menuButton: {
//   marginRight: theme.spacing(2),
// },
// toolbar: {
//   minHeight: 48,
//   backgroundColor:
//     process.env.REACT_APP_ENV === ENV.DEVELOPMENT
//       ? "#0096FF"
//       : process.env.REACT_APP_ENV === ENV.PRODUCTION ? "00008B"
//         : "#89CFF0",
// },
// title: {
//   flexGrow: 1,
// },
// }));

const TempAppBar = ({ handleOpenMenu }) => {
  // const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const username = localStorage.getItem(constants.LOCAL_STORAGE_KEY.USERNAME)
  const lang = useSelector((state) => state.lang.ui.lang)
  const history = useHistory()

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    authProvider.logout()
  }

  const handleResetPassword = () => {
    history.push('/' + constants.PATH.PASSWORD.LANDING)
  }

  return (
    // <div className={classes.root}>
    <AppBar position="static" sx={appbarStyle}>
      {/* <Toolbar className={classes.toolbar}> */}
      <Toolbar sx={toolbarStyle} variant="dense">
        {/* <IconButton onClick={handleOpenMenu} edge="start" className={classes.menuButton} color="inherit" aria-label="menu"> */}
        <IconButton onClick={handleOpenMenu} edge="start" sx={menuButtonStyle} color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton>
        {/* <Typography variant="h6" className={classes.title}> */}
        <Typography variant="h6" sx={titleStyle}>
          <span>{process.env.REACT_APP_ENV}</span>
        </Typography>
        <Typography variant="h6" sx={{ ...titleStyle }}>
          <span style={{color: 'red'}}>{getString(lang,  '', 'warning', '')} </span> {getString(lang,  '', 'warningContent', '')}
        </Typography>
        <div className="app-shop-password">GENERAL: {getTodayShopAppGeneralCode()}</div>
        <div className="app-shop-password">SETTTING: {getTodayShopAppSettingCode()}</div>
        <div>
          <span>{username ? username : ''}</span>
          <IconButton aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu} color="inherit">
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            getcontentanchorel={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            keepMounted
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
            <MenuItem onClick={handleResetPassword}>{getString(lang, '', 'changePassword')}</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default TempAppBar
