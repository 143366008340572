import { useState } from 'react'
import { getContent } from '../../Component/EditCreateDisplay/EditUtil'
import { constants } from '../../Constants/constants'
import { Drawer, Paper } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { SubTabEditCreateDisplay } from '../../Component/EditCreateDisplay/SubTabEditCreateDisplay'
import { TempBtn } from '../../Component/Input/TempBtn'
import CounterProfileList from '../CounterProfile/CounterProfileList'
import GenericQueueProfileList from '../GenericQueueProfile/GenericQueueProfileList'

const LOCAL_CONSTANT = {
  QUEUE_PROFILE: 'queueProfileCode',
  QUEUE_TRANSFER_PROFILE: 'queueTransferProfileCode',
  COUNTER_PROFILE: 'counterProfileCode',
}

const resource = 'Staff'

export const ProfileCodeSection = ({ onChangeInput, restUrlId, displayData, showQueueTransferProfileCode }) => {
  const [drawerType, setDrawerType] = useState(null)
  const [drawerOpen, setDrawerOpen] = useState(false)

  function onClickCloseDrawer() {
    setDrawerOpen(false)
  }

  const handleOnClickDrawer = (type) => {
    setDrawerType(type)
    setDrawerOpen(true)
    handleDrawer()
  }

  const onClickListRow = (data, type) => {
    onChangeInput(type, data)
    onClickCloseDrawer()
  }

  const handleDrawer = () => {
    let drawer
    switch (drawerType) {
      case LOCAL_CONSTANT.QUEUE_PROFILE:
        drawer = (
          <Paper>
            <GenericQueueProfileList
              resource="QueueProfile"
              editBtn={false}
              createBtn={false}
              btnList={false}
              onClickListRow={(item) => onClickListRow(item.profileCode, drawerType)}
              newFilter={{ id: restUrlId }}
              dialogFooterBtn={
                <>
                  <TempBtn
                    btnSetting={{
                      variant: constants.STYLE.VARIANT.CONTAINED,
                      color: constants.STYLE.PRIMARY,
                      onClick: onClickCloseDrawer,
                      icon: <ClearIcon />,
                      label: { key: 'close' },
                    }}
                  />
                </>
              }
            />
          </Paper>
        )
        break
      case LOCAL_CONSTANT.QUEUE_TRANSFER_PROFILE:
        drawer = (
          <Paper>
            <GenericQueueProfileList
              resource="QueueTransferProfile"
              editBtn={false}
              createBtn={false}
              btnList={false}
              onClickListRow={(item) => onClickListRow(item.profileCode, drawerType)}
              newFilter={{ id: restUrlId }}
              dialogFooterBtn={
                <>
                  <TempBtn
                    btnSetting={{
                      variant: constants.STYLE.VARIANT.CONTAINED,
                      color: constants.STYLE.PRIMARY,
                      onClick: onClickCloseDrawer,
                      icon: <ClearIcon />,
                      label: { key: 'close' },
                    }}
                  />
                </>
              }
            />
          </Paper>
        )
        break
      case LOCAL_CONSTANT.COUNTER_PROFILE:
        drawer = (
          <Paper>
            <CounterProfileList
              editBtn={false}
              createBtn={false}
              onClickListRow={(item) => onClickListRow(item.profileCode, drawerType)}
              newFilter={{ id: restUrlId }}
              dialogFooterBtn={
                <>
                  <TempBtn
                    btnSetting={{
                      variant: constants.STYLE.VARIANT.CONTAINED,
                      color: constants.STYLE.PRIMARY,
                      onClick: onClickCloseDrawer,
                      icon: <ClearIcon />,
                      label: { key: 'close' },
                    }}
                  />
                </>
              }
            />
          </Paper>
        )
        break
      default:
        break
    }
    return drawer
  }

  const queueProfileCodeObj = {
    className: 'edit-create-input',
    label: { resource: resource, key: 'queueProfileCode' },
    value: displayData.queueProfileCode,
    name: 'queueProfileCode',
    isEndOfLine: false,
    disabled: true,
    clear: true,
    onClickEndAdornmentIconButton: () => handleOnClickDrawer(LOCAL_CONSTANT.QUEUE_PROFILE),
  }

  const queueTransferProfileCodeObj = {
    className: 'edit-create-input',
    label: { resource: resource, key: 'queueTransferProfileCode' },
    value: displayData.queueTransferProfileCode,
    name: 'queueTransferProfileCode',
    isEndOfLine: false,
    disabled: true,
    clear: true,
    onClickEndAdornmentIconButton: () => handleOnClickDrawer(LOCAL_CONSTANT.QUEUE_TRANSFER_PROFILE),
  }

  const counterProfileCodeObj = {
    className: 'edit-create-input',
    label: { resource: resource, key: 'counterProfileCode' },
    value: displayData.counterProfileCode,
    name: 'counterProfileCode',
    isEndOfLine: true,
    disabled: true,
    clear: true,
    onClickEndAdornmentIconButton: () => handleOnClickDrawer(LOCAL_CONSTANT.COUNTER_PROFILE),
  }

  const tempEditList = showQueueTransferProfileCode ? [queueProfileCodeObj, queueTransferProfileCodeObj, counterProfileCodeObj] : [queueProfileCodeObj, counterProfileCodeObj]

  const content = [
    {
      tab: tempEditList.map((inputSetting, index) => {
        let content = getContent(inputSetting, index, onChangeInput)
        return (
          <div className="edit-display-wrapper" key={index}>
            {content}
            {inputSetting.isEndOfLine && <div></div>}
          </div>
        )
      }),
      btn: false,
    },
  ]

  return (
    <>
      <SubTabEditCreateDisplay
        code={''}
        resource={resource}
        tabs={false}
        content={content}
        onChangeInput={onChangeInput}
        display={'none'}
      />
      <Drawer classes={{ paper: 'drawer-margin-left' }} anchor="right" open={drawerOpen} onClose={onClickCloseDrawer}>
        {handleDrawer()}
      </Drawer>
    </>
  )
}
