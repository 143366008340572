import React, { forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { sify } from 'chinese-conv'
import {
  setQueueTemplateTimeSectionValue,
  setQueueTemplateTableTypeValue,
  setQueueTemplateTableTypeGroupedSection,
} from '../../../actions/queueTemplateAction'
import { setLoading } from '../../../actions/systemAction'
import { constants } from '../../../Constants/constants'
import { TableType } from './TableType'
import { SortableContext } from '@dnd-kit/sortable'
import { SortableItem } from './SortableItem'

export const TableTypeSection = forwardRef(
  (
    {
      sectionList = [],
      droppableId,
      groupLabel,
      resource,
      item,
      handleCloneTableType,
      handleDeleteTableTypeList,
      setSelectedType,
      timesectionIdx,
      idList,
      tableTypeMap,
      ...props
    },
    ref
  ) => {
    const dispatch = useDispatch()
    const savedTableType = useSelector((state) => state.queueTemplate.ui.tableType)

    const handleGroupedSectionOnClick = (e) => {
      if (groupLabel) {
        dispatch(setLoading(true))
        e.stopPropagation()
        const newQueueTemplateInput = _.cloneDeep(constants.TIME_SECTION_DEFAULT_VALUE)
        dispatch(setQueueTemplateTimeSectionValue(newQueueTemplateInput))
        const currentGroupedSection = tableTypeMap.get(sectionList[0])
        dispatch(
          setQueueTemplateTableTypeGroupedSection({
            currentTimeSectionIndex: timesectionIdx,
            groupedSection: currentGroupedSection.groupedSection,
            groupedSectionEnDescription: currentGroupedSection.groupedSectionEnDescription,
            groupedSectionEnLabel: currentGroupedSection.groupedSectionEnLabel,
            groupedSectionScDescription: currentGroupedSection.groupedSectionScDescription,
            groupedSectionScLabel: currentGroupedSection.groupedSectionScLabel,
            groupedSectionTcDescription: currentGroupedSection.groupedSectionTcDescription,
            groupedSectionTcLabel: currentGroupedSection.groupedSectionTcLabel,
            disableGroupedSectionDescription:
              (!currentGroupedSection.groupedSectionTcDescription && !currentGroupedSection.groupedSectionScDescription) ||
              (currentGroupedSection.groupedSectionTcDescription &&
                sify(currentGroupedSection.groupedSectionTcDescription) === currentGroupedSection.groupedSectionScDescription),
            disableGroupedSection:
              (!currentGroupedSection.groupedSectionTcLabel && !currentGroupedSection.groupedSectionScLabel) ||
              (currentGroupedSection.groupedSectionTcLabel &&
                sify(currentGroupedSection.groupedSectionTcLabel) === currentGroupedSection.groupedSectionScLabel),
          })
        )
        setSelectedType('groupedSection')
        dispatch(setLoading(false))
      }
    }

    const handleTableTypeOnClick = (e, tableType) => {
      dispatch(setLoading(true))
      e.stopPropagation()
      const newQueueTemplateInput = _.cloneDeep(constants.TIME_SECTION_DEFAULT_VALUE)
      dispatch(setQueueTemplateTimeSectionValue(newQueueTemplateInput))
      dispatch(setQueueTemplateTableTypeValue(tableType))
      setSelectedType('tableType')
      dispatch(setLoading(false))
    }

    return (
      <SortableItem id={droppableId} handlePosition="top">
        <div ref={ref}>
          <SortableContext items={sectionList} strategy={() => {}}>
            <div className={`${groupLabel ? 'grouped-section' : ''}`} onClick={handleGroupedSectionOnClick}>
              <div>{groupLabel ? `Group: ${groupLabel}` : null}</div>
              {sectionList.map((tableTypeId) => (
                <SortableItem key={tableTypeId} id={tableTypeId} handlePosition="top">
                  <div
                    className={tableTypeId === savedTableType.customId ? `sub-item queue-template-selected-product` : 'sub-item'}
                    onClick={(e) => handleTableTypeOnClick(e, tableTypeMap.get(tableTypeId))}
                  >
                    <TableType
                      resource={resource}
                      item={item}
                      tableType={tableTypeMap.get(tableTypeId)}
                      tableTypeForegroundColor={tableTypeMap.get(tableTypeId).displayTableTypeForegroundColor}
                      tableTypeBackgroundColor={tableTypeMap.get(tableTypeId).displayTableTypeBackgroundColor}
                      handleCloneTableType={handleCloneTableType}
                      handleDeleteTableTypeList={handleDeleteTableTypeList}
                    />
                  </div>
                </SortableItem>
              ))}
            </div>
          </SortableContext>
        </div>
      </SortableItem>
    )
  }
)
