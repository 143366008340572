import _ from 'lodash'

import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Drawer } from '@mui/material'
import { TempTextInput } from '../../Component/Input/TempTextInput'
import { setCounter } from '../../actions/counterAction'
import SoundTemplateList from './SoundTemplateList'

export default function GetSoundTemplateCode() {
  const dispatch = useDispatch()
  const [openDrawer, setOpenDrawer] = useState(false)

  const resource = 'Counter'

  const savedData = useSelector((state) => state.counter.ui.counter)
  let setDispatch = (counter) => dispatch(setCounter(counter))

  const onCloseDrawer = () => {
    setOpenDrawer(false)
  }

  const onOpenDrawer = () => {
    setOpenDrawer(true)
  }

  const onClearField = () => {
    setOpenDrawer(false)
    let newSavedData = _.cloneDeep(savedData)
    newSavedData.templateCode = null
    setDispatch(newSavedData)
  }

  const onClickListRow = (obj) => {
    setOpenDrawer(false)
    let newSavedData = _.cloneDeep(savedData)
    newSavedData.templateCode = obj.code
    newSavedData.restUrlId = obj.restUrlId
    newSavedData.enDescription = obj.enDescription
    newSavedData.scDescription = obj.scDescription
    newSavedData.tcDescription = obj.tcDescription
    setDispatch(newSavedData)
  }

  return (
    <>
      <TempTextInput
        inputSetting={{
          InputLabelProps: { shrink: true },
          className: 'edit-create-input',
          label: { resource: resource, key: 'soundTemplateCode' },
          value: savedData.templateCode,
          onClickEndAdornmentIconButton: onOpenDrawer,
          clear: false,
          helperTextIcon: true,
        }}
        handleChange={onClearField}
      />
      {savedData.restUrlId && (
        <TempTextInput
          inputSetting={{
            label: { resource: resource, key: 'soundTemplateCodeRestUrlId' },
            value: savedData.restUrlId,
            name: 'soundTemplateCodeRestUrlId',
            disabled: true,
          }}
        />
      )}
      {openDrawer && (
        <Drawer classes={{ paper: 'drawer-margin-left' }} anchor="right" open={openDrawer} onClose={onCloseDrawer}>
          <SoundTemplateList editBtn={false} createBtn={false} btnList={false} onClickListRow={(item) => onClickListRow(item)} />
        </Drawer>
      )}
    </>
  )
}
