import { BANNER_ACTION } from '../../Constants/constants';

export function setParametersInputBox(savedBannerValue) {

  switch (savedBannerValue.actionType) {
    case BANNER_ACTION.WEB_VIEW:
    case BANNER_ACTION.MERCHANT_WEB_VIEW:
    case BANNER_ACTION.LINK:
    case BANNER_ACTION.MERCHANT_LINK:
    case BANNER_ACTION.PIXEL:
    case BANNER_ACTION.VIDEO:
      savedBannerValue.inputName = "action";
      savedBannerValue.parametersHidden = false;
      savedBannerValue.parametersDisable = false;
      break;
    case BANNER_ACTION.SEARCH:
      savedBannerValue.inputName = "name";
      savedBannerValue.parametersHidden = false;
      savedBannerValue.parametersDisable = true;
      break;
    case BANNER_ACTION.PRODUCT_CATEGORY:
    case BANNER_ACTION.E_COUPON_CATEGORY:
      savedBannerValue.inputName = "categoryCode";
      savedBannerValue.parametersHidden = false;
      savedBannerValue.parametersDisable = true;
      break;
    case BANNER_ACTION.PRODUCT:
      savedBannerValue.inputName = "productCode";
      savedBannerValue.parametersHidden = false;
      savedBannerValue.parametersDisable = true;
      break;
    case BANNER_ACTION.E_COUPON:
      savedBannerValue.inputName = "ecouponCode";
      savedBannerValue.parametersHidden = false;
      savedBannerValue.parametersDisable = true;
      break;
    case BANNER_ACTION.PRODUCT_BRAND:
    case BANNER_ACTION.E_COUPON_BRAND:
      savedBannerValue.inputName = "brandCode";
      savedBannerValue.parametersHidden = false;
      savedBannerValue.parametersDisable = true;
      break;
    case BANNER_ACTION.FOLDER:
      savedBannerValue.inputName = "folderCode";
      savedBannerValue.parametersHidden = false;
      savedBannerValue.parametersDisable = true;
      break;
    case BANNER_ACTION.RESTAURANT:
      savedBannerValue.inputName = "restUrlId";
      savedBannerValue.parametersHidden = false;
      savedBannerValue.parametersDisable = true;
      break;
    default:
      savedBannerValue.inputName = "";
      savedBannerValue.parametersHidden = true;
      savedBannerValue.parametersDisable = false;
      savedBannerValue.parameters = { url: "" };
      savedBannerValue.actionParameters = null;
      break;
  }

  return savedBannerValue;
}