import { useEffect } from "react";

import "../../styles/takeaway.scss";

export const TakeawayList = props => {
  useEffect(() => {
    // componentDidMount is here!
    //console.log("RestaurantList componentDidMount");
    return () => {
      // componentWillUnmount is here!
      //console.log("RestaurantList componentWillUnmount");
    };
  }, []);

  return (
    <iframe
      src={process.env.REACT_APP_API_TAKEAWAY}
      title="The Gulu Restaurant Menu List"
      allowFullScreen={true}
      className="iframe"
    ></iframe>
  );
};
