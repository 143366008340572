import { useState, useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useHistory } from "react-router-dom";

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { makeStyles } from '@mui/styles';

import { constants } from '../../Constants/constants';
import { post } from "../../helper/baseFetch";
import { setMerchantSalesUser, setMerchantSalesUserValue } from "../../actions/merchantSalesUserAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempAutoSelectInput } from "../../Component/Input/TempAutoSelectInput";
import { DialogWrapper } from "../../Component/DialogWrapper";
import MerchantSalesUserValidation from "../../validations/merchantSalesUserValidation";
import { merchantSalesUserValidationConstants } from '../../Constants/validationConstants';

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const MerchantSalesUserCreate = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();
  const helperTextStylesClasses = helperTextStyles();
  const [clear, setClear] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const resource = "MerchantSalesUser";
  const savedMerchantSalesUser = useSelector(state => state.merchantSalesUser.ui.merchantSalesUser);
  const [, setErrorVersion] = useState(MerchantSalesUserValidation.getVersion());

  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.MERCHANT_SALES_USER_EDIT_TAB.INFO
    }
  ]
  const tempMerchantSalesUserEditList = [
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "username" },
      value: savedMerchantSalesUser.username,
      name: "username",
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: MerchantSalesUserValidation.getErrorMessages(merchantSalesUserValidationConstants.KEY_USERNAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "password" },
      value: savedMerchantSalesUser.password,
      name: "password",
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: MerchantSalesUserValidation.getErrorMessages(merchantSalesUserValidationConstants.KEY_PASSWORD),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
  ]
  const content = [
    {
      tab: tempMerchantSalesUserEditList.map((inputSetting, index) => {
        let content;
        switch (inputSetting.type) {
          case constants.TYPE.SELECT:
            content =
              <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
            break;
          default:
            content =
              <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
        }
        return <div className="edit-display-wrapper" key={index}>
          {content}
          {inputSetting.isEndOfLine && <div></div>}
        </div>
      }),
      btn: true
    }
  ]

  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  const onClickBackToCreate = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  };
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.MERCHANT.MERCHANT_SALES_USER);
  };

  function onChangeInput(name, e) {
    dispatch(setMerchantSalesUserValue(name, savedMerchantSalesUser, "", e));
  }

  function onClickSave() {
    MerchantSalesUserValidation.validateCreateMerchantSalesUser(savedMerchantSalesUser);
    if (MerchantSalesUserValidation.isValid()) {
      post(constants.PATH.MERCHANT.MERCHANT_SALES_USER, savedMerchantSalesUser, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToCreate,
          dialogContentText: { key: "createSuccess" },
          dialogActions: [
            { label: { key: "backCreate" }, onClick: onClickBackToCreate, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      MerchantSalesUserValidation.resetErrorMessages();
    } else {
      setErrorVersion(MerchantSalesUserValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    dispatch(setMerchantSalesUser(constants.MERCHANT_SALES_USER_INPUT));
    setClear(false)
    MerchantSalesUserValidation.resetErrorMessages();
  }, [clear === true]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <EditCreateDisplay
        code=""
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        display="create"
      />
      {dialog && dialog.open &&
        <DialogWrapper dialogInfo={dialog} />
      }
    </>
  )
};