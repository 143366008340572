import { SELECT_TAG } from './types';
import { sify } from "chinese-conv";
import _ from "lodash";

export const setContactUsDefault = () => {
    return {
        type: SELECT_TAG.SET_SELECT_TAG_DEFAULT,
    }
}

export const setSelectTag = (selectTag) => {
    return {
        type: SELECT_TAG.SET_SELECT_TAG,
        selectTag: selectTag
    }
}

export const setSelectTagValue = (draftType, orignalProduct, key, value, index) => {
    const selectTag = _.cloneDeep(orignalProduct);
    switch (draftType) {
        case "CHANGE_TC_NAME_AND_SC_NAME":
            selectTag[index].tcName = value;
            selectTag[index].scName = sify(value);
            break;
        case "scNameDisable":
            selectTag[index].scNameDisable = value;
            if(selectTag[index].scNameDisable){
                selectTag[index].scName = selectTag[index].tcName? sify(selectTag[index].tcName): selectTag[index].tcName;
            }
            break;
        default:
            selectTag[draftType] = value;
    }
    return {
        type: SELECT_TAG.SET_SELECT_TAG_VALUE,
        selectTag: selectTag
    }
}

export const setSelectTagEditValue = (draftType, orignalProduct, key, value, index) => {
    const selectTag = _.cloneDeep(orignalProduct);
    switch (draftType) {
        case "CHANGE_TC_NAME_AND_SC_NAME":
            selectTag.tcName = value;
            selectTag.scName = sify(value);
            break;
        case "scNameDisable":
            selectTag.scNameDisable = value;
            if(selectTag.scNameDisable){
                selectTag.scName = sify(selectTag.tcName);
            }
            break;
        default:
            selectTag[draftType] = value;
    }
    return {
        type: SELECT_TAG.SET_SELECT_TAG_VALUE,
        selectTag: selectTag
    }
}
