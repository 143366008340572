import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

export const SortableItem = (props) => {
  const { children, id } = props

  const { attributes, listeners, isDragging, setNodeRef, transform, transition } = useSortable({ id })

  const style = {
    position: 'relative',
    display: 'inline-block',
    opacity: isDragging ? 0.5 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </div>
  )
}
