import _ from "lodash";
import { SELECT_TAG } from '../actions/types';
import { constants } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        selectTag: _.cloneDeep(constants.SELECT_TAG_INPUT),
    }
}

const selectTagReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SELECT_TAG.SET_SELECT_TAG_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case SELECT_TAG.SET_SELECT_TAG:
        case SELECT_TAG.SET_SELECT_TAG_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    selectTag: action.selectTag
                }
            })
        default:
            return state
    }
}

export default selectTagReducer