import { useEffect, useState } from 'react'
import { useStore } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { helperTextStyles } from '../Landing/LandingSection/landingUtil'
import pickupValidation from '../../validations/pickupValidation'
import { pickupValidationConstants } from '../../Constants/validationConstants'
import { constants as co } from '../../Constants/constants'
import { getContent } from '../../Component/EditCreateDisplay/EditUtil'
import _ from 'lodash'

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CancelIcon from '@mui/icons-material/Cancel'
import { post } from '../../helper/baseFetch'
import useRestaurant from '../../hooks/Restaurant/useRestaurant'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { DialogWrapper } from '../../Component/DialogWrapper'
import { QueueTemplateColorPicker } from '../QueueTemplate/QueueTemplateColorPicker'
import { TypeCodeField } from './TypeCodeField'
import { SoundInfoVoiceDropdown } from '../MerchantConfig/SoundInfoVoiceDropdown'

export const PickupCreate = () => {
  const store = useStore()
  const params = useParams()
  const history = useHistory()
  const [clear, setClear] = useState(true)
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  const resource = 'Pickup'
  const [savedPickup, setSavedPickup] = useState({})
  const helperTextStylesClasses = helperTextStyles()
  // eslint-disable-next-line no-unused-vars
  const [errorVersion, setErrorVersion] = useState(pickupValidation.getVersion())

  const tabs = [
    {
      label: { resource: resource, key: 'info' },
      tabIndex: co.ADMIN_DEVICE_EDIT_TAB.INFO,
    },
  ]
  const fields = [
    {
      className: 'edit-create-input',
      type: co.TYPE.FUNCTION,
      value: (
        <TypeCodeField
          value={savedPickup?.typeCode}
          resource={resource}
          clear
          name={'typeCode'}
          onChangeInput={onChangeInput}
          filter={{ type: 'PICKUP' }}
        />
      ),
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'enTypeName' },
      value: savedPickup.enTypeName,
      name: 'enTypeName',
      isEndOfLine: false,
      clear: true,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'tcTypeName' },
      value: savedPickup.tcTypeName,
      name: 'tcTypeName',
      isEndOfLine: false,
      clear: true,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'scTypeName' },
      value: savedPickup.scTypeName,
      name: 'scTypeName',
      isEndOfLine: true,
      clear: true,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'soundTemplateCode' },
      value: savedPickup.templateCode,
      name: 'soundTemplateCode',
      isEndOfLine: true,
      disabled: true,
      variant: co.STYLE.VARIANT.STANDARD,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'sequence' },
      value: savedPickup.sequence,
      name: 'sequence',
      type: co.TYPE.NUMBER,
      isEndOfLine: false,
      disabled: false,
      required: true,
      disableClearable: true,
      helperTextIcon: true,
      helperText: pickupValidation.getErrMsg(pickupValidationConstants.KEY_SEQUENCE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'reverseSequence' },
      value: savedPickup.reverseSequence,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.PICKUP.REVERSE_SEQUENCE,
      name: 'reverseSequence',
      isEndOfLine: true,
      disableClearable: true,
      required: true,
      helperTextIcon: true,
      helperText: pickupValidation.getErrMsg(pickupValidationConstants.KEY_STATUS),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      type: co.TYPE.FUNCTION,
      value: <SoundInfoVoiceDropdown value={savedPickup.voiceCode} resource={resource} onChangeInput={onChangeInput} disableClearable />,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'foregroundColor' },
      value: savedPickup.foregroundColor,
      name: 'foregroundColor',
      freeSolo: true,
      customEndAdornmentClearIcon: true,
      endAdornmentIconButton: (
        <QueueTemplateColorPicker color={savedPickup.foregroundColor} setColor={(color) => onChangeInput('foregroundColor', color)} />
      ),
      onClickEndAdornmentIconButton: () => {},
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.QUEUE_TEMPLATE.DEFAULT_TABLE_TYPE_FOREGROUND_COLOR,
      helperTextIcon: true,
      helperText: pickupValidation.getErrMsg(pickupValidationConstants.KEY_FOREGROUND_COLOR),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
      freeSoloUppercase: true,
      isColorFieldSpecialCase: true,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'backgroundColor' },
      value: savedPickup.backgroundColor,
      name: 'backgroundColor',
      freeSolo: true,
      customEndAdornmentClearIcon: true,
      endAdornmentIconButton: (
        <QueueTemplateColorPicker color={savedPickup.backgroundColor} setColor={(color) => onChangeInput('backgroundColor', color)} />
      ),
      onClickEndAdornmentIconButton: () => {},
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.QUEUE_TEMPLATE.DEFAULT_TABLE_TYPE_FOREGROUND_COLOR,
      helperTextIcon: true,
      helperText: pickupValidation.getErrMsg(pickupValidationConstants.KEY_BACKGROUND_COLOR),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
      freeSoloUppercase: true,
      isEndOfLine: true,
      isColorFieldSpecialCase: true,
    },
  ]
  const content = [
    {
      tab: (
        <>
          {pickupValidation.getErrMsg(pickupValidationConstants.KEY_REST_URL_ID) &&
            pickupValidation.getErrMsg(pickupValidationConstants.KEY_REST_URL_ID).length > 0 && (
              <div className="error-message">
                <CancelIcon className="error-message-icon" fontSize={co.SIZE.SMALL} />
                {pickupValidation.getErrMsg(pickupValidationConstants.KEY_REST_URL_ID)}
              </div>
            )}
          {fields.map((inputSetting, index) => {
            let content = getContent(inputSetting, index, onChangeInput)
            return (
              <div className="edit-display-wrapper" key={index}>
                {content}
                {inputSetting.isEndOfLine && <div></div>}
              </div>
            )
          })}
        </>
      ),
      btn: true,
    },
  ]
  const bottomBtns = [
    {
      variant: co.STYLE.VARIANT.CONTAINED,
      color: co.STYLE.PRIMARY,
      label: { key: 'save' },
      onClick: onClickSave,
      className: 'margin-left-16',
      icon: <SaveIcon />,
    },
    {
      variant: co.STYLE.VARIANT.CONTAINED,
      color: co.STYLE.PRIMARY,
      label: { key: 'clear' },
      disabled: false,
      onClick: onClickClear,
      className: 'margin-left-16',
      icon: <ClearIcon />,
    },
  ]

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push('/' + co.PATH.SHOP.SYSTEM_GROUP_RESTAURANT + '/' + params.restUrlId + '/' + co.RESTAURANT_TAB.PICKUP + '/' + co.PICKUP_TAB.INFO)
  }

  function onChangeInput(key, e) {
    const newSavedPickup = _.cloneDeep(savedPickup)

    if (e === '') {
      e = null
    }

    switch (key) {
      case 'sequence':
        newSavedPickup.sequence = Number.isInteger(Number(e)) ? (Number(e) > 0 ? Number(e) : 0) : 0
        break
      case 'typeCode':
        if (e) {
          newSavedPickup.typeCode = e.code
          newSavedPickup.templateCode = e.soundTemplateCode
          newSavedPickup.enTypeName = e.enName
          newSavedPickup.tcTypeName = e.tcName
          newSavedPickup.scTypeName = e.scName
        } else {
          newSavedPickup.typeCode = 'DEFAULT'
          newSavedPickup.templateCode = null
          newSavedPickup.enTypeName = null
          newSavedPickup.tcTypeName = null
          newSavedPickup.scTypeName = null
        }
        break
      default:
        newSavedPickup[`${key}`] = e
    }

    setSavedPickup(newSavedPickup)
  }

  function onClickSave() {
    pickupValidation.validateEditAdminDevice(savedPickup)
    if (pickupValidation.isValid()) {
      post(
        co.PATH.SHOP.PICKUP.PICKUP_TAG_LIST + '/',
        savedPickup,
        () => {
          setDialog({
            open: true,
            onClose: onClickBackToEdit,
            dialogContentText: { key: 'editSuccess' },
            dialogActions: [
              { label: { key: 'backEdit' }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
              { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
            ],
          })
          setClear(false)
        },
        undefined,
        store
      )
      pickupValidation.resetErrMsg()
    } else {
      setErrorVersion(pickupValidation.getVersion())
    }
  }
  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    pickupValidation.resetErrMsg()
    if (clear === true) {
      setSavedPickup({ ...co.PICKUP_TAG, restUrlId: params.restUrlId })
    }
  }, [clear]) // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   console.log('savedPickup', savedPickup)
  // }, [savedPickup])

  const { getRestaurantIdAndName } = useRestaurant(params.restUrlId)
  const mapCode = getRestaurantIdAndName()

  return (
    <>
      <EditCreateDisplay
        code={savedPickup && savedPickup.id ? savedPickup.id : ''}
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        mapName={{ resource: 'restaurant', key: 'restUrlId' }}
        mapCode={mapCode}
        display="create"
      />
      {dialog && dialog.open && <DialogWrapper dialogInfo={dialog} />}
    </>
  )
}
