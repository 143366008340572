import { queueProfileValidationConstants as constants } from '../Constants/validationConstants'
class QueueProfileValidation {
  version
  errorMap
  profileErrorMap
  valid

  constructor() {
    if (!QueueProfileValidation.instance) {
      this.version = 0
      this.valid = true
      this.errorMap = new Map()
      this.profileErrorMap = new Map()
      QueueProfileValidation.instance = this
    }
    return QueueProfileValidation.instance
  }

  validateProfile(profile) {
    this.resetErrorMessages()
    this.validateProfileObj(profile)
    this.version++
  }

  validateProfileObj(profile) {
    if (!profile.enName) {
      this.setErrorMapMessages(constants.KEY_EN_NAME, 'Missing En Name')
    }
    if (!profile.scName) {
      this.setErrorMapMessages(constants.KEY_SC_NAME, 'Missing Sc Name')
    }
    if (!profile.tcName) {
      this.setErrorMapMessages(constants.KEY_TC_NAME, 'Missing Tc Name')
    }
    if (!profile.refTemplateCode) {
      this.setErrorMapMessages(constants.KEY_REF_TEMPLATE_CODE, 'Missing Ref Template Code')
    }
  }

  resetErrorMessages() {
    this.errorMap = new Map()
    this.profileErrorMap = new Map()
    this.valid = true
  }

  getVersion() {
    return this.version
  }

  isValid() {
    return this.valid
  }

  getErrorMessages(key, uniqueCode) {
    if (!uniqueCode) {
      return this.profileErrorMap.has(key) ? this.profileErrorMap.get(key) : []
    } else {
      return []
    }
  }

  setErrorMapMessages(key, errorMessages, valid = false) {
    this.profileErrorMap.set(key, errorMessages)
    if (!valid) {
      this.valid = false
    }
  }
}

const validation = new QueueProfileValidation()
export default validation
