import { QUEUE_CONFIG } from './types';
import _ from "lodash";

export const setQueueConfigDefault = () => {
    return {
        type: QUEUE_CONFIG.SET_QUEUE_CONFIG_DEFAULT,
    }
}

export const setQueueConfig = (queueConfig) => {
    return {
        type: QUEUE_CONFIG.SET_QUEUE_CONFIG,
        queueConfig: queueConfig
    }
}

export const setQueueConfigValue = (draftType, orignalProduct, key, value, index) => {
    const queueConfig = _.cloneDeep(orignalProduct);
    switch (draftType) {
        default:
            queueConfig[draftType] = value;
    }
    return {
        type: QUEUE_CONFIG.SET_QUEUE_CONFIG_VALUE,
        queueConfig: queueConfig
    }
}

export const setQueueConfigResourcesStepsValue =  (draftType, orignalProduct, key, value, index) => {

    const queueConfig = _.cloneDeep(orignalProduct);

    switch (draftType) {
        default:
            queueConfig.resourcesSteps[index] = value;
    }
    return {
        type: QUEUE_CONFIG.SET_QUEUE_CONFIG_VALUE,
        queueConfig: queueConfig
    }
}