import { restaurantPaymentValidationConstants } from '../Constants/validationConstants';

class RestaurantPaymentValidation {

  version;
  errorMap;
  restaurantPaymentErrorMap;
  valid;

  constructor() {

    if (!RestaurantPaymentValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.restaurantPaymentErrorMap = new Map();
      RestaurantPaymentValidation.instance = this;
    }
    return RestaurantPaymentValidation.instance;
  }

  validateEditRestaurantPayment(restaurantPayment) {
    this.resetErrorMessages();
    this.handleValidateEditRestaurantPayment(restaurantPayment);
    this.version++;
  }

  validateCreateRestaurantPayment(restaurantPayment) {
    this.resetErrorMessages();
    this.handleValidateCreateRestaurantPayment(restaurantPayment);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.restaurantPaymentErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key) {
    return this.restaurantPaymentErrorMap.get(key)
  }

  handleValidateEditRestaurantPayment(restaurantPayment) {

    if (!restaurantPayment.serviceType) {
      this.setRestaurantPaymentErrorMapMessages(restaurantPaymentValidationConstants.KEY_SERVICE_TYPE, "Missing Service Type");
    }

    if (!restaurantPayment.systemGroupId) {
      this.setRestaurantPaymentErrorMapMessages(restaurantPaymentValidationConstants.KEY_SYSTEM_GROUP_NAME, "Missing System Group");
    }
    
    if (!restaurantPayment.sequence && restaurantPayment.sequence!==0) {
      this.setRestaurantPaymentErrorMapMessages(restaurantPaymentValidationConstants.KEY_SEQUENCE, "Missing Sequence");
    }

    if ( restaurantPayment.sequence && 
      ( isNaN(restaurantPayment.sequence) || Number(restaurantPayment.sequence) < 0 || !Number.isInteger(Number(restaurantPayment.sequence)))
      ){
      this.setRestaurantPaymentErrorMapMessages(restaurantPaymentValidationConstants.KEY_SEQUENCE, "Invalid Sequence");
    }

    if (!restaurantPayment.status) {
      this.setRestaurantPaymentErrorMapMessages(restaurantPaymentValidationConstants.KEY_STATUS, "Missing Status");
    }

    if (!restaurantPayment.paymentCode) {
      this.setRestaurantPaymentErrorMapMessages(restaurantPaymentValidationConstants.KEY_PAYMENT_CODE, "Missing Payment Code");
      return;
    }
    
  }

  handleValidateCreateRestaurantPayment(restaurantPayment) {

    if (!restaurantPayment.serviceType) {
      this.setRestaurantPaymentErrorMapMessages(restaurantPaymentValidationConstants.KEY_SERVICE_TYPE, "Missing Service Type");
    }

    if (!restaurantPayment.systemGroupId) {
      this.setRestaurantPaymentErrorMapMessages(restaurantPaymentValidationConstants.KEY_SYSTEM_GROUP_NAME, "Missing System Group");
    }
    
    if (!restaurantPayment.sequence && restaurantPayment.sequence!==0) {
      this.setRestaurantPaymentErrorMapMessages(restaurantPaymentValidationConstants.KEY_SEQUENCE, "Missing Sequence");
    }

    if ( restaurantPayment.sequence && 
      ( isNaN(restaurantPayment.sequence) || Number(restaurantPayment.sequence) < 0 || !Number.isInteger(Number(restaurantPayment.sequence)))
      ) {
      this.setRestaurantPaymentErrorMapMessages(restaurantPaymentValidationConstants.KEY_SEQUENCE, "Invalid Sequence");
    }

    if (!restaurantPayment.status) {
      this.setRestaurantPaymentErrorMapMessages(restaurantPaymentValidationConstants.KEY_STATUS, "Missing Status");
    }

    if (!restaurantPayment.paymentCode) {
      this.setRestaurantPaymentErrorMapMessages(restaurantPaymentValidationConstants.KEY_PAYMENT_CODE, "Missing Payment Code");
      return;
    }
    
  }

  setRestaurantPaymentErrorMapMessages(key, errorMessages, valid = false) {
    this.restaurantPaymentErrorMap.set(key, errorMessages);
    // console.log("brandErrorMap", this.brandErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new RestaurantPaymentValidation();
export default validation;