import { useEffect, useRef } from 'react'
import QRCodeStyling from 'qr-code-styling'
import logoImage from '../../pictures/logo-white-border.svg'

const qrCode = new QRCodeStyling({
  width: 200,
  height: 200,
  type: "svg",
  data: 'Hey this is a QR code. Change this value in the main_screen.dart file.',
  dotsOptions: {
    type: 'dots',
  },
  image: logoImage,
  cornersDotOptions: {
    type: 'square',
  },
  cornersSquareOptions: {
    type: 'zig-zag',
  },
  qrOptions: {
    errorCorrectionLevel: 'L',
  },
  imageOptions: {
    crossOrigin: 'anonymous',
    imageSize: 0.5,
  },
})

export const ZigZagQRCode = ({
  value,
  dotsOptions = 'dots',
  image = logoImage,
  cornersDotOptions = 'square',
  cornersSquareOptions = 'zig-zag',
  errorCorrectionLevel = 'L',
  width = 200,
  height = 200,
  wholeQrCodeColor = '#000000'
}) => {
  const canvasRef = useRef(null)

  useEffect(() => {
    canvasRef.current.innerHTML = ''
    qrCode.update({
      data: value,
      dotsOptions: {
        type: dotsOptions,
        color: wholeQrCodeColor,
      },
      image: image,
      cornersDotOptions: {
        type: cornersDotOptions
      },
      cornersSquareOptions: {
        type: cornersSquareOptions
      },
      qrOptions: {
        errorCorrectionLevel: errorCorrectionLevel,
      },
      width: width,
      height: height,
    })
    qrCode.append(canvasRef.current)
  }, [value, dotsOptions, image, cornersDotOptions, cornersSquareOptions, errorCorrectionLevel, wholeQrCodeColor])

  return <div ref={canvasRef} />
}
