import { useStore } from "react-redux";

import DescriptionIcon from '@mui/icons-material/Description';

import { getOne } from "../../helper/baseFetch";
import { constants } from '../../Constants/constants';
import { TempBtn } from "../Input/TempBtn";

export const ContractToPdf = ({ restUrlId }) => {
  const store = useStore();

  function letterChecked() {
    getOne("approval/generateContact/restUrlId", undefined, (payload) => {
      const linkSource = `data:application/pdf;base64,${payload}`;
      const downloadLink = document.createElement("a");
      const filenName = "contract.pdf";
      downloadLink.href = linkSource;
      downloadLink.download = filenName;
      downloadLink.click();
    }, undefined, store);
  }

  return (
    <TempBtn btnSetting={{
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      onClick: letterChecked,
      label: { resource: "payment", key: "contractToPdf" },
      icon: <DescriptionIcon />
    }} />
  );
};