import { useSelector } from "react-redux";

import { Loading } from './loading';

export const BaseWrapper = ({ children, className }) => {
    const loading = useSelector(state => state.system.ui.loading);

    return (
        <div className={className ? className : "list-wrapper"}>
            {children}
            <Loading openLoading={loading} />
        </div>
    )
}