import { useState, useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import _ from 'lodash'

import { Card } from '@mui/material'
import { makeStyles } from '@mui/styles'

import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import CancelIcon from '@mui/icons-material/Cancel'

import { setProductValue } from "../../../actions/productCreateAction";
import { constants } from '../../../Constants/constants';
import { UploadRedeemCodeFile } from './UploadRedeemCodeFile';
import ProductCreateValidation from "../../../validations/productCreateValidation";
import { productValidationConstants } from '../../../Constants/validationConstants';
import { setLoading } from "../../../actions/systemAction";
import { BaseWrapper } from "../../BaseWrapper";
import { TempAutoSelectInput } from "../../Input/TempAutoSelectInput";
import { TempTextInput } from "../../Input/TempTextInput";
import { TempBtn } from "../../Input/TempBtn";
import { getProductStepError } from "../getProductStepError";
import { getString, permissionsCheck, getScDisabled } from "../../../helper/util";
import { getProduct } from "../Product/getProduct";
import useProduct from "../../../hooks/Product/useProduct";

const helperTextStyles = makeStyles((theme) => ({
  root: {
    color: 'red !important',
  },
}))

export const ProductCreateProductLotCreateEdit = () => {
  const dispatch = useDispatch()
  const store = useStore()
  const savedProduct = useSelector((state) => state.product.ui.product)
  const lang = useSelector((state) => state.lang.ui.lang)
  const theme = useSelector((state) => state.theme.ui.theme)
  const helperTextStylesClasses = helperTextStyles()

  const { getProductName } = useProduct()

  const [disableInput, setDisableInput] = useState(false);
  const [hiddenInput, setHiddenInput] = useState(false);

  const permission = localStorage.getItem(constants.LOCAL_STORAGE_KEY.ROLE)

  const [errorVersion, setErrorVersion] = useState(ProductCreateValidation.getVersion())

  useEffect(() => {
    if (permissionsCheck(constants.PERMISSION_KEY.PRODUCT_LOT_CREATE_EDIT_FINANCE)) {
      setDisableInput(true)
      setHiddenInput(true)
    } else if (savedProduct.draft.product.status === constants.PRODUCT_EDIT_STATUS.ACTIVE) {
      setDisableInput(true)
      setHiddenInput(false)
    }
    // else if (savedProduct.draft.product.type === constants.PRODUCT_TYPE.VALUE_UP_ECOUPON_ITEM || savedProduct.draft.product.type === constants.PRODUCT_TYPE.FREE_ECOUPON_ITEM) {
    //   setDisableInput(true);
    //   setHiddenInput(false)
    // }
    else if (savedProduct.draft.redeemCodeApiType === constants.LOT_INVENTORY_TYPE.MEZZOFY) {
      setDisableInput(true)
      setHiddenInput(false)
    } else {
      setDisableInput(false)
      setHiddenInput(false)
    }
  }, [permission, savedProduct.draft.product.status, savedProduct.draft.redeemCodeApiType]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getProductStepError(dispatch)
  }, [errorVersion]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleAddLotInventory = async () => {
    let tempCodeNumber = _.cloneDeep(savedProduct.draft.codeNumber)
    const tempLotInventoryList = await processAddLotInventory(tempCodeNumber)
    let tempDraft = _.cloneDeep(savedProduct.draft)
    tempDraft.lotInventory = tempLotInventoryList.templotInventoryList
    tempDraft.codeNumber = tempLotInventoryList.tempCodeNumber + 1
    dispatch(setProductValue('', savedProduct, '', tempDraft))
  }

  const processAddLotInventory = (tempCodeNumber) => {
    return new Promise((resolve) => {
      let templotInventoryList = _.cloneDeep(savedProduct.draft.lotInventory)
      let subProductLotInventoryList = []
      let productList = _.cloneDeep(savedProduct.draft.productList)
      if (!savedProduct.draft.product.id) {
        productList.map((item, index) => {
          if (savedProduct.draft.productCode === item.productCode) {
            if (item.subProductMapList && item.subProductMapList.length > 0) {
              let newSubProductMapList = item.subProductMapList.map((subProductMapList) => subProductMapList.productCode)
              let tempSubProductMapList = newSubProductMapList.filter(function (item, pos) {
                return newSubProductMapList.indexOf(item) === pos
              })
              tempSubProductMapList.map((subProductMapList) => {
                subProductLotInventoryList.push({
                  subProductLotInventoryValiCode: 'Sub_ProductLot_Inventory_' + Number(tempCodeNumber),
                  productCode: subProductMapList,
                  quantity: 0,
                  cosFileKey: '',
                })
                tempCodeNumber = tempCodeNumber + 1
                return {}
              })
            }
          }
          return {}
        })
      } else {
        if (savedProduct.draft.product.subProductMapList && savedProduct.draft.product.subProductMapList.length > 0) {
          let newSubProductMapList = savedProduct.draft.product.subProductMapList.map((subProductMapList) => subProductMapList.productCode)
          let tempSubProductMapList = newSubProductMapList.filter(function (item, pos) {
            return newSubProductMapList.indexOf(item) === pos
          })
          tempSubProductMapList.map((subProductMapList) => {
            subProductLotInventoryList.push({
              subProductLotInventoryValiCode: 'Sub_ProductLot_Inventory_' + Number(tempCodeNumber),
              productCode: subProductMapList,
              quantity: 0,
              cosFileKey: '',
            })
            tempCodeNumber = tempCodeNumber + 1
            return {}
          })
        }
      }

      templotInventoryList.push({
        lotInventoryValiCode: 'Lot_Inventory_' + Number(tempCodeNumber),
        type: '',
        quantity: 0,
        cosFileKey: '',
        mezzofyCouponID: '',
        mezzofyCouponCode: '',
        mezzofyCouponQuantitySync: '',
        massCode: '',
        subProductLotInventoryList: subProductLotInventoryList,
      })
      tempCodeNumber = tempCodeNumber + 1
      resolve({ templotInventoryList: templotInventoryList, tempCodeNumber: tempCodeNumber })
    })
  }

  const handleDeleteLotInventory = (lotInventoryListIndex) => {
    dispatch(setLoading(true))
    let templotInventoryList = _.cloneDeep(savedProduct.draft.lotInventory)
    templotInventoryList.splice(lotInventoryListIndex, 1)
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.DRAFT, savedProduct, 'lotInventory', templotInventoryList))
    dispatch(setLoading(false))
  }

  const handleDeleteFile = (lotInventoryListIndex) => {
    dispatch(setLoading(true))
    let templotInventoryList = _.cloneDeep(savedProduct.draft.lotInventory)
    templotInventoryList.map((item, index) => {
      if (lotInventoryListIndex === index) {
        item.cosFileKey = ''
      }
      return {}
    })
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.DRAFT, savedProduct, 'lotInventory', templotInventoryList))
    dispatch(setLoading(false))
  }

  function handleLotInventoryListType(name, lotInventoryListType, lotInventoryListIndex) {
    let templotInventoryList = _.cloneDeep(savedProduct.draft.lotInventory)
    templotInventoryList.map((item, index) => {
      if (lotInventoryListIndex === index) {
        item.type = lotInventoryListType
        if (lotInventoryListType !== constants.LOT_INVENTORY_TYPE.CUSTOMIZE || lotInventoryListType !== constants.LOT_INVENTORY_TYPE.CUSTOMIZE_TGR) {
          item.cosFileKey = ''
        }
        if (lotInventoryListType !== constants.LOT_INVENTORY_TYPE.AUTO_GENERATE_MEZZOFY) {
          item.mezzofyCouponID = ''
          item.mezzofyCouponCode = ''
          item.mezzofyCouponQuantitySync = ''
        }
        if (lotInventoryListType !== constants.LOT_INVENTORY_TYPE.CUSTOMIZE_MASS_CODE) {
          item.massCode = ''
        }
      }
      return {}
    })
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.DRAFT, savedProduct, 'lotInventory', templotInventoryList))
  }

  function handleQuantity(name, quantity, lotInventoryListIndex) {
    let templotInventoryList = _.cloneDeep(savedProduct.draft.lotInventory)
    templotInventoryList.map((item, index) => {
      if (lotInventoryListIndex === index) {
        item.quantity = Number(quantity)
        item.subProductLotInventoryList.map((subProductLotInventoryList, subProductLotInventoryListIndex) => {
          if (!savedProduct.draft.product.id) {
            savedProduct.draft.productList.map((product) => {
              if (product.productCode === savedProduct.draft.productCode) {
                let totalQuantity = 0
                product.subProductMapList.map((subProductMap) => {
                  if (subProductMap.productCode === subProductLotInventoryList.productCode) {
                    totalQuantity += subProductMap.quantity
                  }
                  return {}
                })
                subProductLotInventoryList.quantity = Number(quantity) * totalQuantity
              }
              return {}
            })
          } else {
            let totalQuantity = 0
            savedProduct.draft.product.subProductMapList.map((subProductMap) => {
              if (subProductMap.productCode === subProductLotInventoryList.productCode) {
                totalQuantity += subProductMap.quantity
              }
              return {}
            })
            subProductLotInventoryList.quantity = Number(quantity) * totalQuantity
          }
          return {}
        })
      }
      return {}
    })
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.DRAFT, savedProduct, 'lotInventory', templotInventoryList))
  }

  function handleMassCode(name, massCode, lotInventoryListIndex) {
    let templotInventoryList = _.cloneDeep(savedProduct.draft.lotInventory)
    templotInventoryList.map((item, index) => {
      if (lotInventoryListIndex === index) {
        item.massCode = massCode
      }
      return {}
    })
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.DRAFT, savedProduct, 'lotInventory', templotInventoryList))
  }

  function handleSubProductMassCode(name, massCode, lotInventoryIndex) {
    let templotInventoryList = _.cloneDeep(savedProduct.draft.lotInventory)
    templotInventoryList.map((item, index) => {
      if (lotInventoryIndex.lotInventoryListIndex === index) {
        item.subProductLotInventoryList.map((subProductLotInventoryList, subProductLotInventoryListIndex) => {
          if (lotInventoryIndex.subProductLotInventoryIndex === subProductLotInventoryListIndex) {
            subProductLotInventoryList.massCode = massCode
          }
          return {}
        })
      }
      return {}
    })
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.DRAFT, savedProduct, 'lotInventory', templotInventoryList))
  }

  function handleMezzofyCouponID(name, mezzofyCouponID, lotInventoryListIndex) {
    let templotInventoryList = _.cloneDeep(savedProduct.draft.lotInventory)
    templotInventoryList.map((item, index) => {
      if (lotInventoryListIndex === index) {
        item.mezzofyCouponID = mezzofyCouponID
      }
      return {}
    })
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.DRAFT, savedProduct, 'lotInventory', templotInventoryList))
  }

  function handleMezzofyCouponCode(name, mezzofyCouponCode, lotInventoryListIndex) {
    let templotInventoryList = _.cloneDeep(savedProduct.draft.lotInventory)
    templotInventoryList.map((item, index) => {
      if (lotInventoryListIndex === index) {
        item.mezzofyCouponCode = mezzofyCouponCode
      }
      return {}
    })
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.DRAFT, savedProduct, 'lotInventory', templotInventoryList))
  }

  function handleMezzofyCouponQuantitySync(name, mezzofyCouponQuantitySync, lotInventoryListIndex) {
    let templotInventoryList = _.cloneDeep(savedProduct.draft.lotInventory)
    templotInventoryList.map((item, index) => {
      if (lotInventoryListIndex === index) {
        item.mezzofyCouponQuantitySync = mezzofyCouponQuantitySync
      }
      return {}
    })
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.DRAFT, savedProduct, 'lotInventory', templotInventoryList))
  }

  function setFileKey(rowNumber, fileKey, lotInventoryListIndex, subProductLotInventoryIndex, firstRow, lastRow) {
    let templotInventoryList = _.cloneDeep(savedProduct.draft.lotInventory)
    templotInventoryList.map((item, index) => {
      if (lotInventoryListIndex === index) {
        item.cosFileKey = fileKey
        item.rowNumber = rowNumber
        item.firstRow = firstRow
        item.lastRow = lastRow
      }
      return item
    })
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.DRAFT, savedProduct, 'lotInventory', templotInventoryList))
  }

  function setSubProductFileKey(rowNumber, fileKey, lotInventoryListIndex, subProductLotInventoryIndex, firstRow, lastRow) {
    let templotInventoryList = _.cloneDeep(savedProduct.draft.lotInventory)
    templotInventoryList.map((item, index) => {
      if (lotInventoryListIndex === index) {
        item.subProductLotInventoryList.map((subProductLotInventoryList, subProductLotInventoryListIndex) => {
          if (subProductLotInventoryIndex === subProductLotInventoryListIndex) {
            subProductLotInventoryList.cosFileKey = fileKey
            subProductLotInventoryList.rowNumber = rowNumber
            subProductLotInventoryList.firstRow = firstRow
            subProductLotInventoryList.lastRow = lastRow
          }
          return {}
        })
      }
      return {}
    })
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.DRAFT, savedProduct, 'lotInventory', templotInventoryList))
  }

  const handleDeleteSubProductFile = (subProductLotInventoryIndex, lotInventoryListIndex) => {
    dispatch(setLoading(true))
    let templotInventoryList = _.cloneDeep(savedProduct.draft.lotInventory)
    templotInventoryList.map((item, index) => {
      if (lotInventoryListIndex === index) {
        item.subProductLotInventoryList.map((subProductLotInventoryList, subProductLotInventoryListIndex) => {
          if (subProductLotInventoryIndex === subProductLotInventoryListIndex) {
            subProductLotInventoryList.cosFileKey = ''
          }
          return {}
        })
      }
      return {}
    })
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.DRAFT, savedProduct, 'lotInventory', templotInventoryList))
    dispatch(setLoading(false))
  }

  function handleSubmit() {
    dispatch(setLoading(true))
    let tempDraft = _.cloneDeep(savedProduct.draft)
    let templotInventoryList = _.cloneDeep(savedProduct.draft.lotInventory)
    let productList = _.cloneDeep(savedProduct.draft.productList)
    productList.map((item, index) => {
      if (savedProduct.draft.productCode === item.productCode) {
        item.lotInventoryList = templotInventoryList
      }
      return {}
    })
    dispatch(
      setProductValue(constants.ACTION_KEY.PRODUCT.LOT_INVENTORY, savedProduct, '', {
        lotInventory: [],
        lotInventorySet: false,
        productList: productList,
        productCode: '',
        productType: '',
      })
    )
    dispatch(setLoading(false))

    tempDraft.productList = productList
    ProductCreateValidation.validateDraft(tempDraft)
    setErrorVersion(ProductCreateValidation.getVersion())
  }

  function onClickClear() {
    if (!savedProduct.draft.product.id) {
      let orignalProductCode
      if (savedProduct.draft.product.type === constants.PRODUCT_TYPE.PACKAGE_SUB_ITEM) {
        orignalProductCode = savedProduct.draft.subProductList.find((item) => item.productCode === savedProduct.draft.product.productCode)
      } else {
        orignalProductCode = savedProduct.draft.productList.find((item) => item.productCode === savedProduct.draft.product.productCode)
      }
      if (orignalProductCode === undefined) {
        dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.DRAFT, savedProduct, 'product', _.cloneDeep(constants.PRODUCT)))
      } else {
        dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.DRAFT, savedProduct, 'product', orignalProductCode))
      }
    } else {
      edit(savedProduct.draft.product.id)
    }
  }

  const edit = async (productCode) => {
    const data = await getProduct(productCode, store)
    let tempSavedProduct = _.cloneDeep(savedProduct.draft)
    tempSavedProduct.product = data
    tempSavedProduct.lotInventory = data.lotInventoryList
    tempSavedProduct.lotInventorySet = true
    if (tempSavedProduct.product.attributeList.length > 0) {
      tempSavedProduct.product.attributeList.map((attribute, attributeIndex) => {
        if (attribute.optionList && attribute.optionList.length > 0) {
          attribute.optionList.map((optionItem, optionIndex) => {
            optionItem.optionValiCode = 'Existing_Option_' + optionIndex
            optionItem.scValueDisable = getScDisabled(optionItem.tcValue, optionItem.scValue)
            return optionItem
          })
        }
        return {}
      })
    }
    dispatch(setProductValue('', savedProduct, '', tempSavedProduct))
  }

  const productName = getProductName()

  return (
    <BaseWrapper>
      <div className="step-content-content-wrapper">
        <div className="sub-title margin-left-16">
          {getString(lang, 'productCreateProductLotList', 'mainTitle', theme) + ': '}
          <div className="code-title">
            {savedProduct.draft.productCode} - {productName}
          </div>
        </div>
        {savedProduct.draft.lotInventorySet && (
          <Card className="step-content-content">
            <div className="card-padding">
              {savedProduct.draft.product.id && (
                <div>
                  <TempTextInput
                    inputSetting={{
                      className: 'edit-create-input',
                      variant: constants.STYLE.VARIANT.STANDARD,
                      size: constants.SIZE.SMALL,
                      label: { resource: 'productCreateProductLotList', key: 'enName' },
                      value: savedProduct.draft.product.enName,
                      disabled: true,
                    }}
                  />
                  <TempTextInput
                    inputSetting={{
                      className: 'edit-create-input',
                      variant: constants.STYLE.VARIANT.STANDARD,
                      size: constants.SIZE.SMALL,
                      label: { resource: 'productCreateProductLotList', key: 'tcName' },
                      value: savedProduct.draft.product.tcName,
                      disabled: true,
                    }}
                  />
                  <TempTextInput
                    inputSetting={{
                      className: 'edit-create-input',
                      variant: constants.STYLE.VARIANT.STANDARD,
                      size: constants.SIZE.SMALL,
                      label: { resource: 'productCreateProductLotList', key: 'scName' },
                      value: savedProduct.draft.product.scName,
                      disabled: true,
                    }}
                  />
                  <TempTextInput
                    inputSetting={{
                      className: 'edit-create-input',
                      variant: constants.STYLE.VARIANT.STANDARD,
                      size: constants.SIZE.SMALL,
                      label: { resource: 'productCreateProductLotList', key: 'totalRemainingQuota' },
                      value:
                        savedProduct.draft.product.remainingQuota || savedProduct.draft.product.remainingQuota === 0
                          ? savedProduct.draft.product.remainingQuota
                          : 'N/A',
                      disabled: true,
                    }}
                  />
                </div>
              )}
              {!hiddenInput && (
                <TempBtn
                  btnSetting={{
                    className: 'button-on-top',
                    variant: constants.STYLE.VARIANT.CONTAINED,
                    color: constants.STYLE.PRIMARY,
                    onClick: handleAddLotInventory,
                    label: { resource: 'productCreateProductLotList', key: 'addLotInventory' },
                    disabled: disableInput,
                    icon: <AddIcon />,
                  }}
                />
              )}
              {savedProduct.draft.lotInventory.map((item, lotInventoryListIndex) => (
                <Card key={lotInventoryListIndex} className="image-list">
                  {!item.id && !hiddenInput && (
                    <div className="deleteIcon">
                      <TempBtn
                        btnSetting={{
                          className: 'edit-create-btn',
                          variant: constants.STYLE.VARIANT.CONTAINED,
                          color: constants.STYLE.PRIMARY,
                          onClick: () => handleDeleteLotInventory(lotInventoryListIndex),
                          label: { key: 'deleteBtn' },
                          icon: <DeleteIcon />,
                          disabled: disableInput,
                        }}
                      />
                    </div>
                  )}
                  <div>
                    {item.lotCode && (
                      <TempTextInput
                        inputSetting={{
                          className: 'edit-create-input',
                          variant: constants.STYLE.VARIANT.STANDARD,
                          size: constants.SIZE.SMALL,
                          label: { resource: 'productCreateProductLotList', key: 'lotCode' },
                          value: item.lotCode,
                          disabled: true,
                          FormHelperTextProps: { className: helperTextStylesClasses.root },
                        }}
                      />
                    )}
                    {item.id || disableInput ? (
                      <TempTextInput
                        inputSetting={{
                          className: 'edit-create-input',
                          variant: item.id || disableInput ? constants.STYLE.VARIANT.STANDARD : null,
                          size: constants.SIZE.SMALL,
                          label: { resource: 'productCreateProductLotList', key: 'type' },
                          value: item.type,
                          disabled: item.id ? true : disableInput,
                          helperTextIcon: true,
                          helperText: ProductCreateValidation.getErrorMessages(
                            productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_LIST_TYPE,
                            savedProduct.draft.productCode,
                            item.lotInventoryValiCode
                          ).map((error) => {
                            return error
                          }),
                          FormHelperTextProps: { className: helperTextStylesClasses.root },
                        }}
                        handleChange={handleLotInventoryListType}
                        index={lotInventoryListIndex}
                      />
                    ) : (
                      <TempAutoSelectInput
                        inputSetting={{
                          className: 'edit-create-input',
                          size: constants.SIZE.SMALL,
                          variant: constants.STYLE.VARIANT.OUTLINED,
                          type: constants.TYPE.SELECT,
                          selectValue:
                            savedProduct.draft.redeemCodeApiType === constants.LOT_INVENTORY_TYPE.MEZZOFY
                              ? constants.CHOICES.PRODUCT_CREATE.LOT_INVENTORY.TYPE.filter(
                                  (item, index) => item.value === constants.LOT_INVENTORY_TYPE.AUTO_GENERATE_MEZZOFY
                                )
                              : constants.CHOICES.PRODUCT_CREATE.LOT_INVENTORY.TYPE.filter(
                                  (item, index) => item.value !== constants.LOT_INVENTORY_TYPE.AUTO_GENERATE_MEZZOFY
                                ),
                          label: { resource: 'productCreateProductLotList', key: 'type' },
                          value: item.type,
                          disabled: item.id ? true : disableInput,
                          helperTextIcon: true,
                          helperText: ProductCreateValidation.getErrorMessages(
                            productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_LIST_TYPE,
                            savedProduct.draft.productCode,
                            item.lotInventoryValiCode
                          ).map((error) => {
                            return error
                          }),
                          FormHelperTextProps: { className: helperTextStylesClasses.root },
                        }}
                        handleChange={handleLotInventoryListType}
                        index={lotInventoryListIndex}
                      />
                    )}
                    <TempTextInput
                      inputSetting={{
                        className: 'edit-create-input',
                        variant: item.id || disableInput ? constants.STYLE.VARIANT.STANDARD : null,
                        type: constants.TYPE.NUMBER,
                        size: constants.SIZE.SMALL,
                        label: { resource: 'productCreateProductLotList', key: 'quantity' },
                        value: item.quantity,
                        disabled: item.id ? true : disableInput,
                        helperTextIcon: true,
                        helperText: ProductCreateValidation.getErrorMessages(
                          productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_LIST_QUANTITY,
                          savedProduct.draft.productCode,
                          item.lotInventoryValiCode
                        ).map((error) => {
                          return error
                        }),
                        FormHelperTextProps: { className: helperTextStylesClasses.root },
                      }}
                      handleChange={handleQuantity}
                      index={lotInventoryListIndex}
                    />
                    {savedProduct.draft.productType !== constants.PRODUCT_TYPE.PACKAGE_ITEM &&
                      item.type === constants.LOT_INVENTORY_TYPE.CUSTOMIZE_MASS_CODE && (
                        <TempTextInput
                          inputSetting={{
                            className: 'edit-create-input',
                            variant: item.id || disableInput ? constants.STYLE.VARIANT.STANDARD : null,
                            size: constants.SIZE.SMALL,
                            label: { resource: 'productCreateProductLotList', key: 'massCode' },
                            value: item.massCode,
                            disabled: item.id ? true : disableInput,
                            helperTextIcon: true,
                            helperText: ProductCreateValidation.getErrorMessages(
                              productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_LIST_MASS_CODE,
                              savedProduct.draft.productCode,
                              item.lotInventoryValiCode
                            ).map((error) => {
                              return error
                            }),
                            FormHelperTextProps: { className: helperTextStylesClasses.root },
                          }}
                          handleChange={handleMassCode}
                          index={lotInventoryListIndex}
                        />
                      )}
                    {item.id && (
                      <TempTextInput
                        inputSetting={{
                          className: 'edit-create-input',
                          variant: constants.STYLE.VARIANT.STANDARD,
                          size: constants.SIZE.SMALL,
                          label: { resource: 'productCreateProductLotList', key: 'remainingQuota' },
                          value: item.remainingQuota || item.remainingQuota === 0 ? item.remainingQuota : 'N/A',
                          disabled: true,
                        }}
                      />
                    )}
                    {item.type === constants.LOT_INVENTORY_TYPE.AUTO_GENERATE_MEZZOFY && (
                      <div>
                        <TempTextInput
                          inputSetting={{
                            className: 'edit-create-input',
                            variant: item.id || disableInput ? constants.STYLE.VARIANT.STANDARD : null,
                            size: constants.SIZE.SMALL,
                            label: { resource: 'productCreateProductLotList', key: 'mezzofyCouponID' },
                            value: item.mezzofyCouponID,
                            disabled: item.id ? true : disableInput,
                            helperTextIcon: true,
                            helperText: ProductCreateValidation.getErrorMessages(
                              productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_LIST_MEZZOFY_COUPON_ID,
                              savedProduct.draft.productCode,
                              item.lotInventoryValiCode
                            ).map((error) => {
                              return error
                            }),
                            FormHelperTextProps: { className: helperTextStylesClasses.root },
                          }}
                          handleChange={handleMezzofyCouponID}
                          index={lotInventoryListIndex}
                        />
                        <TempTextInput
                          inputSetting={{
                            className: 'edit-create-input',
                            variant: item.id || disableInput ? constants.STYLE.VARIANT.STANDARD : null,
                            size: constants.SIZE.SMALL,
                            label: { resource: 'productCreateProductLotList', key: 'mezzofyCouponCode' },
                            value: item.mezzofyCouponCode,
                            disabled: item.id ? true : disableInput,
                            helperTextIcon: true,
                            helperText: ProductCreateValidation.getErrorMessages(
                              productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_LIST_MEZZOFY_COUPON_CODE,
                              savedProduct.draft.productCode,
                              item.lotInventoryValiCode
                            ).map((error) => {
                              return error
                            }),
                            FormHelperTextProps: { className: helperTextStylesClasses.root },
                          }}
                          handleChange={handleMezzofyCouponCode}
                          index={lotInventoryListIndex}
                        />
                        {disableInput ? (
                          <TempTextInput
                            inputSetting={{
                              className: 'edit-create-input',
                              variant: disableInput ? constants.STYLE.VARIANT.STANDARD : null,
                              size: constants.SIZE.SMALL,
                              label: { resource: 'productCreateProductLotList', key: 'mezzofyCouponQuantitySync' },
                              value: item.mezzofyCouponQuantitySync,
                              disabled: item.id ? true : disableInput,
                              helperTextIcon: true,
                              helperText: ProductCreateValidation.getErrorMessages(
                                productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_LIST_MEZZOFY_COUPON_QUANTITY_SYNC,
                                savedProduct.draft.productCode,
                                item.lotInventoryValiCode
                              ).map((error) => {
                                return error
                              }),
                              FormHelperTextProps: { className: helperTextStylesClasses.root },
                            }}
                            handleChange={handleMezzofyCouponQuantitySync}
                            index={lotInventoryListIndex}
                          />
                        ) : (
                          <TempAutoSelectInput
                            inputSetting={{
                              className: 'edit-create-input',
                              size: constants.SIZE.SMALL,
                              variant: constants.STYLE.VARIANT.OUTLINED,
                              type: constants.TYPE.SELECT,
                              selectValue: constants.CHOICES.PRODUCT_CREATE.LOT_INVENTORY.MEZZOFY_COUPON_QUANTITY_SYNC,
                              label: { resource: 'productCreateProductLotList', key: 'mezzofyCouponQuantitySync' },
                              value: item.mezzofyCouponQuantitySync,
                              disabled: item.id ? true : disableInput,
                              helperTextIcon: true,
                              helperText: ProductCreateValidation.getErrorMessages(
                                productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_LIST_MEZZOFY_COUPON_QUANTITY_SYNC,
                                savedProduct.draft.productCode,
                                item.lotInventoryValiCode
                              ).map((error) => {
                                return error
                              }),
                              FormHelperTextProps: { className: helperTextStylesClasses.root },
                            }}
                            handleChange={handleMezzofyCouponQuantitySync}
                            index={lotInventoryListIndex}
                          />
                        )}
                        {savedProduct.draft.productType === constants.PRODUCT_TYPE.PACKAGE_ITEM && (
                          <Card className="image-list">
                            <div className="tips-message">
                              <div className="sub-title">{getString(lang, 'productCreateProductLotList', 'mezzofySubItemWarning', theme)}</div>
                            </div>
                          </Card>
                        )}
                      </div>
                    )}
                    {!item.id &&
                      savedProduct.draft.product.status !== constants.PRODUCT_EDIT_STATUS.ACTIVE &&
                      savedProduct.draft.productType !== constants.PRODUCT_TYPE.PACKAGE_ITEM && (
                        <>
                          {(item.type === constants.LOT_INVENTORY_TYPE.CUSTOMIZE || item.type === constants.LOT_INVENTORY_TYPE.CUSTOMIZE_TGR) && (
                            <>
                              {ProductCreateValidation.getErrorMessages(
                                productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_LIST_COS_FILE_KEY,
                                savedProduct.draft.productCode,
                                item.lotInventoryValiCode
                              ).map((error) => {
                                return error
                              }) &&
                                ProductCreateValidation.getErrorMessages(
                                  productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_LIST_COS_FILE_KEY,
                                  savedProduct.draft.productCode,
                                  item.lotInventoryValiCode
                                ).map((error) => {
                                  return error
                                }).length > 0 && (
                                  <div className="error-message">
                                    <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                                    {ProductCreateValidation.getErrorMessages(
                                      productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_LIST_COS_FILE_KEY,
                                      savedProduct.draft.productCode,
                                      item.lotInventoryValiCode
                                    ).map((error) => {
                                      return error
                                    })}
                                  </div>
                                )}
                              <UploadRedeemCodeFile
                                disabled={disableInput}
                                type="productLot"
                                setFileKey={setFileKey}
                                lotInventoryListIndex={lotInventoryListIndex}
                              />
                              <div>
                                {item.cosFileKey ? (
                                  <>
                                    <div className="attachment-item">
                                      <AttachFileIcon /> {item.cosFileKey}
                                      {!hiddenInput && (
                                        <div className="deleteIcon">
                                          <TempBtn
                                            btnSetting={{
                                              className: 'edit-create-btn',
                                              variant: constants.STYLE.VARIANT.CONTAINED,
                                              color: constants.STYLE.PRIMARY,
                                              onClick: () => handleDeleteFile(lotInventoryListIndex),
                                              label: { key: 'deleteBtn' },
                                              icon: <DeleteIcon />,
                                              disabled: disableInput,
                                            }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                    <div>First Row: {item.firstRow}</div>
                                    <div>Last Row: {item.lastRow}</div>
                                  </>
                                ) : (
                                  ''
                                )}
                              </div>
                            </>
                          )}
                        </>
                      )}
                    {item.type !== constants.LOT_INVENTORY_TYPE.AUTO_GENERATE_MEZZOFY &&
                      item.subProductLotInventoryList.map((subProductLotInventory, subProductLotInventoryIndex) => {
                        return (
                          <Card key={subProductLotInventoryIndex} className="image-list">
                            <div className="sub-title">{subProductLotInventory.productCode}</div>
                            {ProductCreateValidation.getErrorMessages(
                              productValidationConstants.KEY_SUB_PRODUCT_LOT_INVENTORY_LIST,
                              savedProduct.draft.productCode,
                              item.lotInventoryValiCode,
                              subProductLotInventory.subProductLotInventoryValiCode
                            ).map((error) => {
                              return error
                            }) &&
                              ProductCreateValidation.getErrorMessages(
                                productValidationConstants.KEY_SUB_PRODUCT_LOT_INVENTORY_LIST,
                                savedProduct.draft.productCode,
                                item.lotInventoryValiCode,
                                subProductLotInventory.subProductLotInventoryValiCode
                              ).map((error) => {
                                return error
                              }).length > 0 && (
                                <div className="error-message">
                                  <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                                  {ProductCreateValidation.getErrorMessages(
                                    productValidationConstants.KEY_SUB_PRODUCT_LOT_INVENTORY_LIST,
                                    savedProduct.draft.productCode,
                                    item.lotInventoryValiCode,
                                    subProductLotInventory.subProductLotInventoryValiCode
                                  ).map((error) => {
                                    return error
                                  })}
                                </div>
                              )}
                            {subProductLotInventory.id && (
                              <>
                                <TempTextInput
                                  inputSetting={{
                                    className: 'edit-create-input',
                                    variant: constants.STYLE.VARIANT.STANDARD,
                                    size: constants.SIZE.SMALL,
                                    label: { resource: 'productCreateProductLotList', key: 'enName' },
                                    value: subProductLotInventory.enName,
                                    disabled: true,
                                  }}
                                />
                                <TempTextInput
                                  inputSetting={{
                                    className: 'edit-create-input',
                                    variant: constants.STYLE.VARIANT.STANDARD,
                                    size: constants.SIZE.SMALL,
                                    label: { resource: 'productCreateProductLotList', key: 'tcName' },
                                    value: subProductLotInventory.tcName,
                                    disabled: true,
                                  }}
                                />
                                <TempTextInput
                                  inputSetting={{
                                    className: 'edit-create-input',
                                    variant: constants.STYLE.VARIANT.STANDARD,
                                    size: constants.SIZE.SMALL,
                                    label: { resource: 'productCreateProductLotList', key: 'scName' },
                                    value: subProductLotInventory.scName,
                                    disabled: true,
                                  }}
                                />
                              </>
                            )}
                            <TempTextInput
                              inputSetting={{
                                className: 'edit-create-input',
                                variant: constants.STYLE.VARIANT.STANDARD,
                                type: constants.TYPE.NUMBER,
                                size: constants.SIZE.SMALL,
                                label: { resource: 'productCreateProductLotList', key: 'quantity' },
                                value: subProductLotInventory.quantity,
                                helperTextIcon: true,
                                helperText: ProductCreateValidation.getErrorMessages(
                                  productValidationConstants.KEY_SUB_PRODUCT_LOT_INVENTORY_LIST_QUANTITY,
                                  savedProduct.draft.productCode,
                                  item.lotInventoryValiCode,
                                  subProductLotInventory.subProductLotInventoryValiCode
                                ).map((error) => {
                                  return error
                                }),
                                FormHelperTextProps: { className: helperTextStylesClasses.root },
                                disabled: true,
                              }}
                            />
                            {!item.id && savedProduct.draft.product.status !== constants.PRODUCT_EDIT_STATUS.ACTIVE && (
                              <>
                                {(item.type === constants.LOT_INVENTORY_TYPE.CUSTOMIZE ||
                                  item.type === constants.LOT_INVENTORY_TYPE.CUSTOMIZE_TGR) && (
                                  <>
                                    {ProductCreateValidation.getErrorMessages(
                                      productValidationConstants.KEY_SUB_PRODUCT_LOT_INVENTORY_LIST_COS_FILE_KEY,
                                      savedProduct.draft.productCode,
                                      item.lotInventoryValiCode,
                                      subProductLotInventory.subProductLotInventoryValiCode
                                    ).map((error) => {
                                      return error
                                    }) &&
                                      ProductCreateValidation.getErrorMessages(
                                        productValidationConstants.KEY_SUB_PRODUCT_LOT_INVENTORY_LIST_COS_FILE_KEY,
                                        savedProduct.draft.productCode,
                                        item.lotInventoryValiCode,
                                        subProductLotInventory.subProductLotInventoryValiCode
                                      ).map((error) => {
                                        return error
                                      }).length > 0 && (
                                        <div className="error-message">
                                          <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                                          {ProductCreateValidation.getErrorMessages(
                                            productValidationConstants.KEY_SUB_PRODUCT_LOT_INVENTORY_LIST_COS_FILE_KEY,
                                            savedProduct.draft.productCode,
                                            item.lotInventoryValiCode,
                                            subProductLotInventory.subProductLotInventoryValiCode
                                          ).map((error) => {
                                            return error
                                          })}
                                        </div>
                                      )}
                                    {!permissionsCheck(constants.PERMISSION_KEY.PRODUCT_LOT_CREATE_EDIT_FINANCE) && (
                                      <UploadRedeemCodeFile
                                        type="productLot"
                                        setFileKey={setSubProductFileKey}
                                        lotInventoryListIndex={lotInventoryListIndex}
                                        subProductLotInventoryIndex={subProductLotInventoryIndex}
                                      />
                                    )}
                                    <div>
                                      {subProductLotInventory.cosFileKey ? (
                                        <>
                                          <div className="attachment-item">
                                            <AttachFileIcon /> {subProductLotInventory.cosFileKey}
                                            {!hiddenInput && (
                                              <div className="deleteIcon">
                                                {!permissionsCheck(constants.PERMISSION_KEY.PRODUCT_LOT_CREATE_EDIT_FINANCE) && (
                                                  <TempBtn
                                                    btnSetting={{
                                                      className: 'edit-create-btn',
                                                      variant: constants.STYLE.VARIANT.CONTAINED,
                                                      color: constants.STYLE.PRIMARY,
                                                      onClick: () => handleDeleteSubProductFile(subProductLotInventoryIndex, lotInventoryListIndex),
                                                      label: { key: 'deleteBtn' },
                                                      icon: <DeleteIcon />,
                                                      disabled: disableInput,
                                                    }}
                                                  />
                                                )}
                                              </div>
                                            )}
                                          </div>
                                          <div>First Row: {subProductLotInventory.firstRow}</div>
                                          <div>Last Row: {subProductLotInventory.lastRow}</div>
                                        </>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                            {item.type === constants.LOT_INVENTORY_TYPE.CUSTOMIZE_MASS_CODE && (
                              <TempTextInput
                                inputSetting={{
                                  className: 'edit-create-input',
                                  variant: item.id || disableInput ? constants.STYLE.VARIANT.STANDARD : '',
                                  size: constants.SIZE.SMALL,
                                  label: { resource: 'productCreateProductLotList', key: 'massCode' },
                                  value: subProductLotInventory.massCode,
                                  helperTextIcon: true,
                                  helperText: ProductCreateValidation.getErrorMessages(
                                    productValidationConstants.KEY_SUB_PRODUCT_LOT_INVENTORY_LIST_MASS_CODE,
                                    savedProduct.draft.productCode,
                                    item.lotInventoryValiCode,
                                    subProductLotInventory.subProductLotInventoryValiCode
                                  ).map((error) => {
                                    return error
                                  }),
                                  FormHelperTextProps: { className: helperTextStylesClasses.root },
                                  disabled: item.id ? true : disableInput,
                                }}
                                handleChange={handleSubProductMassCode}
                                index={{ lotInventoryListIndex: lotInventoryListIndex, subProductLotInventoryIndex: subProductLotInventoryIndex }}
                              />
                            )}
                            {subProductLotInventory.id && (
                              <TempTextInput
                                inputSetting={{
                                  className: 'edit-create-input',
                                  variant: constants.STYLE.VARIANT.STANDARD,
                                  size: constants.SIZE.SMALL,
                                  label: { resource: 'productCreateProductLotList', key: 'remainingQuota' },
                                  value:
                                    subProductLotInventory.remainingQuota || subProductLotInventory.remainingQuota === 0
                                      ? subProductLotInventory.remainingQuota
                                      : 'N/A',
                                  disabled: true,
                                }}
                              />
                            )}
                          </Card>
                        )
                      })}
                  </div>
                </Card>
              ))}
            </div>
            {savedProduct.draft.product.id && !hiddenInput && (
              <div className="table-head">
                {!savedProduct.draft.product.id && (
                  <TempBtn
                    btnSetting={{
                      variant: constants.STYLE.VARIANT.CONTAINED,
                      color: constants.STYLE.PRIMARY,
                      onClick: handleSubmit,
                      label: { key: 'save' },
                      disabled: disableInput,
                      icon: <SaveIcon />,
                    }}
                  />
                )}
                <TempBtn
                  btnSetting={{
                    className: 'btn-margin',
                    variant: constants.STYLE.VARIANT.CONTAINED,
                    color: constants.STYLE.PRIMARY,
                    onClick: onClickClear,
                    label: { key: 'clear' },
                    disabled: disableInput,
                    icon: <ClearIcon />,
                  }}
                />
              </div>
            )}
          </Card>
        )}
      </div>
    </BaseWrapper>
  )
}
