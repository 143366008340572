import { PRODUCT } from './types'
import { sify } from 'chinese-conv'
import _ from 'lodash'

import { productSave } from '../Component/ProductCreate/Product/productSave'

export const setProductDefault = () => {
  return {
    type: PRODUCT.SET_PRODUCT_DEFAULT,
  }
}

export const setProduct = (product) => {
  return {
    type: PRODUCT.SET_PRODUCT,
    product: product,
  }
}

export const setProductStepError = (productStepError) => {
  return {
    type: PRODUCT.SET_PRODUCT_STEP_ERROR_VALUE,
    productStepError: productStepError,
  }
}

export const setProductValue = (draftType, orignalProduct, key, value, index, type) => {
  let product = _.cloneDeep(orignalProduct)

  switch (draftType) {
    case 'CAT':
      product.draft.category = setProductCategory(product.draft.category, key, value)
      break
    case 'REDEEM':
      product = setProductRedeem(product, key, value)
      break
    case 'PRODUCT':
      product = setProductProduct(product, key, value, index)
      break
    case 'REDEEM_INFO':
      product.draft.redeemGroup = value.redeemGroup
      product.draft.redeemGroupList = value.redeemGroupList
      break
    case 'EXISTING_REDEEM_GROUP':
      product.draft.redeemGroup = value.redeemGroup
      product.draft.existingRedeemGroupList = value.existingRedeemGroupList
      break
    case 'PRODUCT_INFO':
      product.draft.product = value.product
      product.draft.productList = value.productList
      product.draft.subProductList = value.subProductList
      product.draft.productCode = ''
      product.draft.lotInventorySet = false
      product.draft.lotInventory = null
      break
    case 'LOT_INVENTORY':
      product.draft.productList = value.productList
      product.draft.productCode = value.productCode
      product.draft.productType = value.productType
      product.draft.lotInventory = value.lotInventory
      product.draft.lotInventorySet = value.lotInventorySet
      product.draft.redeemCodeApiType = value.redeemCodeApiType
      break
    case 'LOT_INVENTORY_SUB':
      product.draft.subProductList = value.subProductList
      product.draft.productCode = value.productCode
      product.draft.productType = value.productType
      product.draft.lotInventory = value.lotInventory
      product.draft.lotInventorySet = value.lotInventorySet
      break
    case 'DRAFT':
      product.draft[key] = value
      break
    default:
      product.draft = value
  }
  if (type !== 'delete' && product.draft.product && product.draft.product.productCode) {
    product.draft = productSave(_.cloneDeep(product))
  }

  return {
    type: PRODUCT.SET_PRODUCT_VALUE,
    product: product,
  }
}

const setProductCategory = (originalCat, key, value) => {
  const category = _.cloneDeep(originalCat)
  switch (key) {
    case 'channelNameTranslate':
      category.tcName = value.tcName
      category.scName = value.scName
      break
    case 'descriptionTranslate':
      category.tcDescription = value.tcDescription
      category.scDescription = value.scDescription
      break
    case 'scNameDisable':
      category[key] = value
      if (value) {
        category.scName = category.tcName ? sify(category.tcName) : category.tcName
      }
      break
    case 'scDescriptionDisable':
      category[key] = value
      if (value) {
        category.scDescription = category.tcDescription ? sify(category.tcDescription) : category.tcDescription
      }
      break
    case 'channelSelect':
      category.categoryCode = value.categoryCode
      category.channelSave = value.channelSave
      break
    case 'channelCreate':
      category.channel = value.channel
      category.channelSave = value.channelSave
      break
    case 'refCategory':
      category.refCategoryCode = value.refCategoryCode
      category.refCategoryTcName = value.refCategoryTcName
      category.refCategoryId = value.refCategoryId;
      break
    default:
      category[key] = value
  }

  return category
}

const setProductRedeem = (product, key, value) => {
  const originalProduct = _.cloneDeep(product)
  const redeem = _.cloneDeep(originalProduct.draft.redeemGroup)

  switch (key) {
    // case "changeRedeemType":
    //     redeem.type = value.type;
    //     redeem.startRedeemTimestamp = value.startRedeemTimestamp;
    //     redeem.endRedeemTimestamp = value.endRedeemTimestamp;
    //     redeem.startAfterDay = value.startAfterDay;
    //     redeem.endAfterDay = value.endAfterDay;
    //     break;
    case 'changeRedeemType':
      redeem.type = value.type
      redeem.startRedeemTimestamp = value.startRedeemTimestamp
      redeem.endRedeemTimestamp = value.endRedeemTimestamp
      redeem.startAfterDay = value.startAfterDay
      redeem.endAfterDay = value.endAfterDay
      redeem.selectLocation = value.selectLocation
      redeem.selectDate = value.selectDate
      redeem.selectTime = value.selectTime
      redeem.redeemLocationList = value.redeemLocationList
      break
    // case "changeSelectLocation":
    //     redeem.selectLocation = value.selectLocation;
    //     redeem.selectDate = value.selectDate;
    //     redeem.selectTime = value.selectTime;
    //     redeem.redeemLocationList = value.redeemLocationList;
    //     break;
    default:
      redeem[key] = value
  }
  originalProduct.draft.redeemGroup = redeem

  originalProduct.draft.redeemGroupList = originalProduct.draft.redeemGroupList.map((tempRedeemGroup) => {
    if (tempRedeemGroup.redeemGroupCode === redeem.redeemGroupCode) {
      tempRedeemGroup = redeem
    }

    return tempRedeemGroup
  })

  return originalProduct
}

const setProductProduct = (product, key, value, index) => {
  const originalProduct = _.cloneDeep(product)
  const tempProductDraft = _.cloneDeep(product.draft.product)
  switch (key) {
    case 'brandCode':
      tempProductDraft.brandCode = value.brandCode
      tempProductDraft.brandTcName = value.brandTcName
      tempProductDraft.brandName = value.brandTcName
      break
    case 'systemGroupId':
      tempProductDraft.systemGroupId = value.systemGroupId
      tempProductDraft.systemGroupName = value.systemGroupName
      break
    case 'packageType':
      tempProductDraft.type = value.type
      tempProductDraft.subProductMapList = value.subProductMapList
      tempProductDraft.redeemGroupCode = value.redeemGroupCode
      tempProductDraft.searchable = value.searchable
      tempProductDraft.shareable = value.shareable
      tempProductDraft.productCode = value.productCode
      tempProductDraft.packageCashCoupon = value.packageCashCoupon
      break
    case 'productNameTranslate':
      tempProductDraft.tcName = value
      tempProductDraft.scName = sify(value)
      break
    case 'productDescriptionTranslate':
      tempProductDraft.tcDescription = value
      tempProductDraft.scDescription = sify(value)
      break
    case 'productTacTranslate':
      tempProductDraft.tcTac = value
      tempProductDraft.scTac = sify(value)
      break
    case 'productRedeemDescriptionTranslate':
      tempProductDraft.tcRedeemDescription = value
      tempProductDraft.scRedeemDescription = sify(value)
      break
    case 'productDiscountDescriptionTranslate':
      tempProductDraft.tcDiscountDescription = value
      tempProductDraft.scDiscountDescription = sify(value)
      break
    case 'scNameDisable':
      tempProductDraft[key] = value
      if (value) {
        tempProductDraft.scName = tempProductDraft.tcName ? sify(tempProductDraft.tcName) : tempProductDraft.tcName
      }
      break
    case 'scDescriptionDisable':
      tempProductDraft[key] = value
      if (value) {
        tempProductDraft.scDescription = tempProductDraft.tcDescription ? sify(tempProductDraft.tcDescription) : tempProductDraft.tcDescription
      }
      break
    case 'scTacDisable':
      tempProductDraft[key] = value
      if (value) {
        tempProductDraft.scTac = tempProductDraft.tcTac ? sify(tempProductDraft.tcTac) : tempProductDraft.tcTac
      }
      break
    case 'scRedeemDescriptionDisable':
      tempProductDraft[key] = value
      if (value) {
        tempProductDraft.scRedeemDescription = tempProductDraft.tcRedeemDescription
          ? sify(tempProductDraft.tcRedeemDescription)
          : tempProductDraft.tcRedeemDescription
      }
      break
    case 'scDiscountDescriptionDisable':
      tempProductDraft[key] = value
      if (value) {
        tempProductDraft.scDiscountDescription = tempProductDraft.tcDiscountDescription
          ? sify(tempProductDraft.tcDiscountDescription)
          : tempProductDraft.tcDiscountDescription
      }
      break
    default:
      tempProductDraft[key] = value
  }

  originalProduct.draft.product = tempProductDraft

  originalProduct.draft.productList = originalProduct.draft.productList.map((tempProduct) => {
    if (tempProduct.productCode === tempProductDraft.productCode) {
      tempProduct = tempProductDraft
    }

    return tempProduct
  })

  originalProduct.draft.subProductList = originalProduct.draft.subProductList.map((tempSubProduct) => {
    if (tempSubProduct.productCode === tempProductDraft.productCode) {
      tempSubProduct = tempProductDraft
    }

    return tempSubProduct
  })

  return originalProduct
}
