import { FOLDER } from './types';
import { sify } from "chinese-conv";
import _ from "lodash";

export const setFolderDefault = () => {
    return {
        type: FOLDER.SET_FOLDER_DEFAULT,
    }
}

export const setFolder = (folder) => {
    return {
        type: FOLDER.SET_FOLDER,
        folder: folder
    }
}

export const setFolderValue = (draftType, orignalProduct, key, value, index) => {
    const folder = _.cloneDeep(orignalProduct);
    switch (draftType) {
        case "CHANGE_TC_NAME_AND_SC_NAME":
            folder.tcName = value;
            folder.scName = sify(value);
            break;
        case "CHANGE_TC_NAME_AND_SC_DESCRIPTION":
            folder.tcDescriptionHtml = value;
            folder.scDescriptionHtml = sify(value);
            break;
        case "scNameDisable":
            folder[draftType] = value;
            folder.scName = folder.tcName? sify(folder.tcName): folder.tcName;
            break;
        case "scDescriptionDisable":
            folder[draftType] = value;
            folder.scDescriptionHtml = folder.tcDescriptionHtml? sify(folder.tcDescriptionHtml): folder.tcDescriptionHtml;
            break;
        default:
            folder[draftType] = value;
    }
    return {
        type: FOLDER.SET_FOLDER_VALUE,
        folder: folder
    }
}
