import { CONFIG_MAP } from './types';
import _ from "lodash";

export const setConfigMapDefault = () => {
    return {
        type: CONFIG_MAP.SET_CONFIG_MAP_DEFAULT,
    }
}

export const setConfigMap = (configMap) => {
    return {
        type: CONFIG_MAP.SET_CONFIG_MAP,
        configMap: configMap
    }
}

export const setConfigMapValue = (draftType, orignalProduct, key, value, index) => {
    const configMap = _.cloneDeep(orignalProduct);
    switch (draftType) {
        default:
            configMap[draftType] = value;
    }
    return {
        type: CONFIG_MAP.SET_CONFIG_MAP_VALUE,
        configMap: configMap
    }
}