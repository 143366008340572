import { SELECT_TAG_CONFIG_OPTIONS } from './types';
import _ from "lodash";

export const setSelectTagConfigOptionsDefault = () => {
    return {
        type: SELECT_TAG_CONFIG_OPTIONS.SET_SELECT_TAG_CONFIG_OPTIONS_DEFAULT,
    }
}

export const setSelectTagConfigOptions = (selectTagConfigOptions) => {
    return {
        type: SELECT_TAG_CONFIG_OPTIONS.SET_SELECT_TAG_CONFIG_OPTIONS,
        selectTagConfigOptions: selectTagConfigOptions
    }
}

export const setSelectTagConfigOptionsValue = (draftType, orignalProduct, key, value, index) => {
    const selectTagConfigOptions = _.cloneDeep(orignalProduct);
    switch (draftType) {
        default:
            selectTagConfigOptions[draftType] = value;
    }
    return {
        type: SELECT_TAG_CONFIG_OPTIONS.SET_SELECT_TAG_CONFIG_OPTIONS_VALUE,
        selectTagConfigOptions: selectTagConfigOptions
    }
}
