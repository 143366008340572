import { MERCHANT_SHOP } from './types';
import { sify } from "chinese-conv";
import _ from "lodash";

// import { merchantShopSave } from "../Component/MerchantShopCreate/MerchantShop/merchantShopSave";

const LOCAL_CONSTANT = {
    PRIVILEGE_ATTERIBUTE: "privilegeAttribute",
    GROUP: "GROUP",
}


export const setMerchantShopDefault = () => {
    return {
        type: MERCHANT_SHOP.SET_MERCHANT_SHOP_DEFAULT,
    }
}

export const setMerchantShop = (merchantShop) => {
    return {
        type: MERCHANT_SHOP.SET_MERCHANT_SHOP,
        merchantShop: merchantShop
    }
}

export const setMerchantShopStepError = (merchantShopStepError) => {
    return {
        type: MERCHANT_SHOP.SET_MERCHANT_SHOP_STEP_ERROR_VALUE,
        merchantShopStepError: merchantShopStepError
    }
}

export const setMerchantShopValue = (draftType, orignalMerchantShop, key, value, index, type) => {
    let merchantShop = _.cloneDeep(orignalMerchantShop);
    switch (draftType) {
        case "CHANGE_TC_NAME_AND_SC_NAME":
            merchantShop.draft[key] = value;
            merchantShop.draft["shopScName"] = sify(value);
            break;
        case "CHANGE_TC_ADDRESS_AND_SC_ADDRESS":
            merchantShop.draft[key] = value;
            merchantShop.draft["shopScAddress"] = sify(value);
            break;
        case "DRAFT":
            merchantShop.draft[key] = value;
            break;
        default:
            merchantShop.draft = value;
    }
    // if (type !== "delete" && merchantShop.draft.merchantShop && merchantShop.draft.merchantShop.merchantShopCode) {
    //     merchantShop.draft = merchantShopSave(_.cloneDeep(merchantShop));
    // };

    return {
        type: MERCHANT_SHOP.SET_MERCHANT_SHOP_VALUE,
        merchantShop: merchantShop
    }
}

export const setMerchantShopUserListValue = (draftType, orignalMerchantShop, key, value, index, type) => {

    let merchantShop = _.cloneDeep(orignalMerchantShop);

    for(let i=0; i<merchantShop?.draft?.merchantUserList?.length; i++){
        if(merchantShop.draft.merchantUserList[i].customId === value.customId){
            merchantShop.draft.merchantUserList[i] = value;
            if(merchantShop.draft.merchantUserList[i].privilegeAttribute === LOCAL_CONSTANT.GROUP){
                merchantShop.draft.merchantUserList[i].privilegeRestUrlId = null;
            }
            break;
        }
    }

    return {
        type: MERCHANT_SHOP.SET_MERCHANT_SHOP_VALUE,
        merchantShop: merchantShop
    }
}

export const removeMerchantShopUser = (orignalMerchantShop, value) => {

    let merchantShop = _.cloneDeep(orignalMerchantShop);

    merchantShop.draft.merchantUserList = merchantShop.draft.merchantUserList.filter((user)=> user.customId!==value);

    return {
        type: MERCHANT_SHOP.SET_MERCHANT_SHOP_VALUE,
        merchantShop: merchantShop
    }
}