import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector, useStore } from 'react-redux'
import moment from 'moment'
import { sify } from 'chinese-conv'
import _ from 'lodash'

import { Drawer } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CancelIcon from '@mui/icons-material/Cancel'
import { makeStyles } from '@mui/styles'

import { constants as co, IMAGE_TYPE } from '../../../Constants/constants'
import { put } from '../../../helper/baseFetch'
import { setProductValue } from '../../../actions/productCreateAction'
import { EditCreateDisplay } from '../../EditCreateDisplay/EditCreateDisplay'
import { TempTextInput } from '../../Input/TempTextInput'
import { TempAutoSelectInput } from '../../Input/TempAutoSelectInput'
import { TempBtn } from '../../Input/TempBtn'
import { TempCustomCheckBox } from '../../Custom/TempCustomCheckBox'
import { ProductCategoryList } from './ProductCategoryList'
import { getCetegory } from './getCetegory'
import ProductCreateValidation from '../../../validations/productCreateValidation'
import { productValidationConstants } from '../../../Constants/validationConstants'
import { ProductMapList } from './ProductMapList'
import { getString, permissionsCheck } from '../../../helper/util'
import { DialogWrapper } from '../../DialogWrapper'
import { ImageList } from '../../UploadImage/ImageList'
import useProduct from '../../../hooks/Product/useProduct'
import { Launch } from '@mui/icons-material'

const helperTextStyles = makeStyles((theme) => ({
  root: {
    color: 'red !important',
  },
}))

export const ProductCategoryCreateEdit = ({ listOverflow }) => {
  const dispatch = useDispatch()
  const store = useStore()
  const params = useParams()
  const history = useHistory()
  const helperTextStylesClasses = helperTextStyles()
  const savedProduct = useSelector((state) => state.product.ui.product)
  const lang = useSelector((state) => state.lang.ui.lang)
  const theme = useSelector((state) => state.theme.ui.theme)
  const [, setErrorVersion] = useState(ProductCreateValidation.getVersion())

  const [ready, setReady] = useState(false)
  const [clear, setClear] = useState(false)

  const { getProductCategoryName } = useProduct()

  const [disableInput, setDisableInput] = useState(false)
  const [hiddenInput, setHiddenInput] = useState(false)
  const [showCategoryTextPanel, setShowCategoryTextPanel] = useState(false)
  const [productCategoryInputList, setProductCategoryInputList] = useState(_.cloneDeep(savedProduct.draft.category))
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  const resource = 'productCreateProductCategoryList'
  const tabs = [
    {
      label: { resource: resource, key: 'info' },
      tabIndex: co.PRODUCT_CAT_TAB.INFO,
    },
    savedProduct.draft.category.id && {
      label: { resource: 'productCreateProductCategoryList', key: 'productMapList' },
      tabIndex: co.PRODUCT_CAT_TAB.PRODUCT_MAP_LIST,
    },
  ].filter((tabTitle) => tabTitle)

  const permission = localStorage.getItem(co.LOCAL_STORAGE_KEY.ROLE)

  const handleCategoryTextClick = () => {
    setShowCategoryTextPanel(true)
  }
  const handleCategoryTextCloseClick = () => {
    setShowCategoryTextPanel(false)
  }

  const edit = async (categoryCode) => {
    setReady(false)
    const data = await getCetegory(categoryCode, store)
    let tempSavedCategory = data
    dispatch(setProductValue(co.ACTION_KEY.PRODUCT.DRAFT, savedProduct, 'category', tempSavedCategory))
    if (data) {
      setReady(true)
    } else {
      history.push('/' + co.PATH.PRODUCT.PRODUCT_CATEGORY)
    }
    setClear(false)
  }

  function onSelectCheckbox(value, customName) {
    switch (customName.key) {
      case 'nameCustomize':
        dispatch(setProductValue(co.ACTION_KEY.PRODUCT.CAT, savedProduct, 'scNameDisable', value))
        break
      case 'descriptionCustomize':
        dispatch(setProductValue(co.ACTION_KEY.PRODUCT.CAT, savedProduct, 'scDescriptionDisable', value))
        break
      default:
    }
  }

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push('/' + co.PATH.PRODUCT.PRODUCT_CATEGORY)
  }

  function onChangeInput(name, e) {
    if (savedProduct.draft.category.scNameDisable === true && name === 'tcName') {
      dispatch(
        setProductValue(co.ACTION_KEY.PRODUCT.CAT, savedProduct, 'channelNameTranslate', {
          tcName: e,
          scName: sify(e),
        })
      )
    } else if (savedProduct.draft.category.scDescriptionDisable === true && name === 'tcDescription') {
      dispatch(
        setProductValue(co.ACTION_KEY.PRODUCT.CAT, savedProduct, 'descriptionTranslate', {
          tcDescription: e,
          scDescription: sify(e),
        })
      )
    } else if (name === 'startDisplayTimestamp' || name === 'endDisplayTimestamp') {
      dispatch(setProductValue(co.ACTION_KEY.PRODUCT.CAT, savedProduct, name, Number(moment(e).format('x'))))
    } else if (name === 'displaySequence') {
      if (e) {
        dispatch(setProductValue(co.ACTION_KEY.PRODUCT.CAT, savedProduct, name, Number(e)))
      } else {
        dispatch(setProductValue(co.ACTION_KEY.PRODUCT.CAT, savedProduct, name, e))
      }
    } else if (name === 'channel') {
      dispatch(
        setProductValue(co.ACTION_KEY.PRODUCT.CAT, savedProduct, 'channelCreate', {
          channel: e,
          channelSave: e,
        })
      )
    } else {
      dispatch(setProductValue(co.ACTION_KEY.PRODUCT.CAT, savedProduct, name, e))
    }
  }

  function handleClearRefCategoryCode() {
    onChangeInput('refCategoryCode', '')
  }

  const fields = [
    {
      type: co.TYPE.TITLE,
      value: { resource: resource, key: 'name' },
      className: 'first-sub-title',
    },
    {
      className: 'edit-create-input',
      variant: co.STYLE.VARIANT.STANDARD,
      label: { resource: resource, key: 'categoryCode' },
      value: savedProduct.draft.category.categoryCode,
      name: 'categoryCode',
      isEndOfLine: true,
      disabled: true,
      required: true,
    },
    {
      className: 'edit-create-input',
      variant: disableInput ? co.STYLE.VARIANT.STANDARD : null,
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'type' },
      value: savedProduct.draft.category.type,
      type: disableInput ? null : co.TYPE.SELECT,
      selectValue: co.CHOICES.PRODUCT_CREATE.EDIT_PRODUCT.CATEGORY_TYPE,
      name: 'type',
      isEndOfLine: true,
      disabled: disableInput,
      required: true,
      helperTextIcon: true,
      helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_CATEGORY_TYPE, 'category').map((error) => {
        return error
      }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      variant: disableInput ? co.STYLE.VARIANT.STANDARD : null,
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'codeDescription' },
      value: savedProduct.draft.category.codeDescription,
      name: 'codeDescription',
      isEndOfLine: false,
      disabled: disableInput,
      helperTextIcon: true,
      helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_CATEGORY_DESCRIPTION, 'category').map((error) => {
        return error
      }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      variant: disableInput ? co.STYLE.VARIANT.STANDARD : null,
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'channel' },
      value: savedProduct.draft.category.channel,
      type: disableInput ? null : co.TYPE.SELECT,
      selectValue: co.CHOICES.PRODUCT_CREATE.EDIT_PRODUCT.CHANNEL,
      name: 'channel',
      isEndOfLine: false,
      disabled: disableInput,
      helperTextIcon: true,
      helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_CATEGORY_CHANNEL, 'category').map((error) => {
        return error
      }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      variant: disableInput ? co.STYLE.VARIANT.STANDARD : null,
      size: co.SIZE.SMALL,
      label: { resource: 'productCreateProductCategoryList', key: 'refCategoryCode' },
      onClickEndAdornmentIconButton: disableInput ? null : handleCategoryTextClick,
      value: savedProduct.draft.category.refCategoryCode
        ? savedProduct.draft.category.refCategoryCode + '    ' + savedProduct.draft.category.refCategoryTcName
        : '',
      name: 'refCategoryCode',
      clear: disableInput ? false : handleClearRefCategoryCode,
      disabled: true,
      isEndOfLine: true,
      multiline: true,
      endAdornmentIconButtonList: savedProduct.draft.category.refCategoryId
        ? [
            <Launch
              fontSize={co.SIZE.SMALL}
              onClick={() => window.open(`${window.location.origin}/rack_product/category/${savedProduct.draft.category.refCategoryId}/0`, '_blank')}
            />,
          ]
        : [],
    },
    {
      className: 'edit-create-input',
      variant: disableInput ? co.STYLE.VARIANT.STANDARD : null,
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'enName' },
      value: savedProduct.draft.category.enName,
      name: 'enName',
      custom: true,
      customName: { key: 'nameCustomize' },
      customValue: savedProduct.draft.category.scNameDisable,
      isEndOfLine: false,
      disabled: disableInput,
      helperTextIcon: true,
      helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_CATEGORY_NAME_EN, 'category').map((error) => {
        return error
      }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      variant: disableInput ? co.STYLE.VARIANT.STANDARD : null,
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'tcName' },
      value: savedProduct.draft.category.tcName,
      name: 'tcName',
      translateName: savedProduct.draft.category.scNameDisable ? ['scName'] : null,
      inputList: productCategoryInputList,
      setInputList: setProductCategoryInputList,
      isEndOfLine: false,
      disabled: disableInput,
      helperTextIcon: true,
      helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_CATEGORY_NAME_TC, 'category').map((error) => {
        return error
      }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      variant: disableInput ? co.STYLE.VARIANT.STANDARD : null,
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'scName' },
      value: savedProduct.draft.category.scName,
      name: 'scName',
      inputList: productCategoryInputList,
      setInputList: setProductCategoryInputList,
      isEndOfLine: true,
      disabled: disableInput ? disableInput : savedProduct.draft.category.scNameDisable,
      helperTextIcon: true,
      helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_CATEGORY_NAME_SC, 'category').map((error) => {
        return error
      }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      variant: disableInput ? co.STYLE.VARIANT.STANDARD : null,
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'enDescription' },
      value: savedProduct.draft.category.enDescription,
      name: 'enDescription',
      custom: true,
      customName: { key: 'descriptionCustomize' },
      customValue: savedProduct.draft.category.scDescriptionDisable,
      isEndOfLine: false,
      disabled: disableInput,
    },
    {
      className: 'edit-create-input',
      variant: disableInput ? co.STYLE.VARIANT.STANDARD : null,
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'tcDescription' },
      value: savedProduct.draft.category.tcDescription,
      name: 'tcDescription',
      translateName: savedProduct.draft.category.scDescriptionDisable ? ['scDescription'] : null,
      inputList: productCategoryInputList,
      setInputList: setProductCategoryInputList,
      isEndOfLine: false,
      disabled: disableInput,
    },
    {
      className: 'edit-create-input',
      variant: disableInput ? co.STYLE.VARIANT.STANDARD : null,
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'scDescription' },
      value: savedProduct.draft.category.scDescription,
      name: 'scDescription',
      inputList: productCategoryInputList,
      setInputList: setProductCategoryInputList,
      isEndOfLine: true,
      disabled: disableInput ? disableInput : savedProduct.draft.category.scDescriptionDisable,
    },
    {
      className: 'edit-create-input',
      variant: disableInput ? co.STYLE.VARIANT.STANDARD : null,
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'startDisplayTimestamp' },
      value: savedProduct.draft.category.startDisplayTimestamp === 0 ? '' : savedProduct.draft.category.startDisplayTimestamp,
      name: 'startDisplayTimestamp',
      type: co.TYPE.DATETIME_LOCAL,
      step: 1,
      isEndOfLine: false,
      disabled: disableInput,
      helperTextIcon: true,
      helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_CATEGORY_START_DISPLAY_TIMESTAMP, 'category').map(
        (error) => {
          return error
        }
      ),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      variant: disableInput ? co.STYLE.VARIANT.STANDARD : null,
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'endDisplayTimestamp' },
      value: savedProduct.draft.category.endDisplayTimestamp === 0 ? '' : savedProduct.draft.category.endDisplayTimestamp,
      name: 'endDisplayTimestamp',
      type: co.TYPE.DATETIME_LOCAL,
      step: 1,
      isEndOfLine: false,
      disabled: disableInput,
      helperTextIcon: true,
      helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_CATEGORY_END_DISPLAY_TIMESTAMP, 'category').map((error) => {
        return error
      }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      variant: disableInput ? co.STYLE.VARIANT.STANDARD : null,
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'displaySequence' },
      value: savedProduct.draft.category.displaySequence,
      type: co.TYPE.NUMBER,
      min: 0,
      name: 'displaySequence',
      isEndOfLine: true,
      disabled: disableInput,
      helperTextIcon: true,
      helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_CATEGORY_DISPLAY_SEQUENCE, 'category').map((error) => {
        return error
      }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: `edit-create-input ${!savedProduct.draft.category.id ? 'display-none' : ''}`,
      variant: disableInput ? co.STYLE.VARIANT.STANDARD : null,
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'status' },
      value: savedProduct.draft.category.status,
      type: disableInput ? '' : co.TYPE.SELECT,
      selectValue: co.CHOICES.PRODUCT_CREATE.CREATE_CATEGORY.STATUS,
      name: 'status',
      isEndOfLine: true,
      disabled: disableInput,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      type: co.TYPE.FUNCTION,
      value: (
        <>
          <h3 variant={co.STYLE.VARIANT.H6}>{getString(lang, 'productCreateProductCategoryList', 'imageList', theme)}</h3>
          {ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_CATEGORY_IMAGE_LIST, 'category').map((error) => {
            return error
          }) &&
            ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_CATEGORY_IMAGE_LIST, 'category').map((error) => {
              return error
            }).length > 0 && (
              <div className="error-message">
                <CancelIcon className="error-message-icon" fontSize={co.SIZE.SMALL} />
                {ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_CATEGORY_IMAGE_LIST, 'category').map((error) => {
                  return error
                })}
              </div>
            )}
          {!hiddenInput && (
            <ImageList
              uploadPath={co.PATH.PRODUCT.UPLOAD_PRODUCT_IMAGE}
              resource={'productCreateProductCategoryList'}
              type={IMAGE_TYPE.CATEGORY}
              typeList={co.CHOICES.PRODUCT_CREATE.CREATE_CATEGORY.CAT_IMAGE_TYPE}
              savedData={savedProduct}
              hiddenInput={hiddenInput}
              disableInput={disableInput}
              helperTextStylesClasses={helperTextStylesClasses}
              validation={ProductCreateValidation}
              validationConstants={productValidationConstants}
              hideTitle={true}
            />
          )}
        </>
      ),
      isEndOfLine: true,
    },
  ]

  const content = [
    {
      tab: (
        <div className={listOverflow}>
          {fields.map((inputSetting, index) => {
            let content
            switch (inputSetting.type) {
              case co.TYPE.SELECT:
                content = <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} index={index} />
                break
              case co.TYPE.FUNCTION:
                content = inputSetting.value
                break
              case co.TYPE.BUTTON:
                content = <TempBtn btnSetting={inputSetting} />
                break
              case co.TYPE.TITLE:
                content = (
                  <div className={`${inputSetting.className + ' sub-title'}`}>
                    {getString(lang, inputSetting.value.resource, inputSetting.value.key, theme)}
                  </div>
                )
                break
              default:
                content = <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
            }
            return (
              <div className="edit-display-wrapper" key={index}>
                {!disableInput && inputSetting.custom && (
                  <TempCustomCheckBox
                    className="customize-btn"
                    value={inputSetting.customValue}
                    customName={inputSetting.customName}
                    onSelectCheckbox={onSelectCheckbox}
                  />
                )}
                {content}
                {inputSetting.isEndOfLine && <div></div>}
              </div>
            )
          })}
        </div>
      ),
      btn: savedProduct.draft.category.id ? true : false,
    },
    {
      tab: <ProductMapList hiddenInput={hiddenInput} disableInput={disableInput} helperTextStylesClasses={helperTextStylesClasses} />,
      btn: false,
    },
  ]
  const bottomBtns = [
    {
      variant: co.STYLE.VARIANT.CONTAINED,
      color: co.STYLE.PRIMARY,
      label: { key: 'save' },
      onClick: onClickSave,
      className: `margin-left-16`,
      icon: <SaveIcon />,
      disabled: disableInput ? disableInput : false,
    },
    {
      variant: co.STYLE.VARIANT.CONTAINED,
      color: co.STYLE.PRIMARY,
      label: { key: 'clear' },
      disabled: disableInput ? disableInput : false,
      onClick: onClickClear,
      className: 'margin-left-16',
      icon: <ClearIcon />,
    },
  ]

  function onClickListRow(data) {
    dispatch(
      setProductValue(co.ACTION_KEY.PRODUCT.CAT, savedProduct, 'refCategory', {
        refCategoryCode: data.categoryCode,
        refCategoryTcName: data.tcName,
        refCategoryId: data.id,
      })
    )
    handleCategoryTextCloseClick()
  }

  function onClickSave() {
    ProductCreateValidation.validateCategoryOnly(savedProduct.draft, true)
    if (savedProduct.draft.category.id) {
      if (ProductCreateValidation.isValid()) {
        put(
          co.PATH.PRODUCT.PRODUCT_CATEGORY + '/' + savedProduct.draft.category.id,
          savedProduct.draft.category,
          (payload) => {
            setDialog({
              open: true,
              onClose: onClickBackToEdit,
              dialogContentText: { key: 'editSuccess' },
              dialogActions: [
                { label: { key: 'backEdit' }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
                { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
              ],
            })
            setClear(false)
          },
          undefined,
          store
        )
      } else {
        setErrorVersion(ProductCreateValidation.getVersion())
      }
    } else {
      setErrorVersion(ProductCreateValidation.getVersion())
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    if (params.id || savedProduct.draft.category.id) {
      ProductCreateValidation.resetErrorMessages()
      edit(params.id ? params.id : savedProduct.draft.category.id)
    } else {
      if (clear === true) {
        dispatch(setProductValue(co.ACTION_KEY.PRODUCT.DRAFT, savedProduct, 'category', co.CATEGORY))
      }
      setReady(true)
      setClear(false)
    }
  }, [clear]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (permissionsCheck(co.PERMISSION_KEY.CAT_CREATE_FINANCE)) {
      setDisableInput(true)
      setHiddenInput(true)
    } else {
      setDisableInput(savedProduct.draft.categoryDisable)
      setHiddenInput(false)
    }
  }, [permission, savedProduct.draft.categoryDisable]) // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   console.log('savedProduct', savedProduct)
  // }, [savedProduct])

  const productCategoryName = getProductCategoryName()

  // useEffect(() => {
  //   console.log('savedProduct.draft', savedProduct.draft)
  // }, [savedProduct.draft])

  return (
    ready && (
      <div className="step-content-content-wrapper">
        <EditCreateDisplay
          code={savedProduct.draft.category.categoryCode ? `${savedProduct.draft.category.categoryCode} - ${productCategoryName}` : ''}
          resource={resource}
          tabs={tabs}
          content={content}
          onChangeInput={onChangeInput}
          bottomBtns={bottomBtns}
          display={!savedProduct.draft.id ? 'create' : 'edit'}
        />
        <Drawer classes={{ paper: 'drawer-margin-left' }} anchor="right" open={showCategoryTextPanel} onClose={handleCategoryTextCloseClick}>
          <ProductCategoryList
            editBtn={false}
            createBtn={false}
            onClickListRow={(item) => onClickListRow(item)}
            newFilter={{ excludedId: '4bf617a1-28f7-4018-a257-f93253698d38' }}
            dialogFooterBtn={
              <>
                <TempBtn
                  btnSetting={{
                    variant: co.STYLE.VARIANT.CONTAINED,
                    color: co.STYLE.PRIMARY,
                    onClick: handleCategoryTextCloseClick,
                    icon: <ClearIcon />,
                    label: { key: 'close' },
                  }}
                />
              </>
            }
          />
        </Drawer>
        {dialog && dialog.open && <DialogWrapper dialogInfo={dialog} />}
      </div>
    )
  )
}
