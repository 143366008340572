import { merchantSalesUserValidationConstants } from '../Constants/validationConstants';

class MerchantSalesUserValidation {

  version;
  errorMap;
  merchantSalesUserErrorMap;
  valid;

  constructor() {

    if (!MerchantSalesUserValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.merchantSalesUserErrorMap = new Map();
      MerchantSalesUserValidation.instance = this;
    }
    return MerchantSalesUserValidation.instance;
  }

  // validateEditMerchantSalesUser(merchantSalesUser) {
  //   this.resetErrorMessages();
  //   this.handleValidateEditMerchantSalesUser(merchantSalesUser);
  //   this.version++;
  // }

  validateCreateMerchantSalesUser(merchantSalesUser) {
    this.resetErrorMessages();
    this.handleValidateCreateMerchantSalesUser(merchantSalesUser);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.merchantSalesUserErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key, uniqueCode) {
    if (!uniqueCode) {
      return (this.merchantSalesUserErrorMap.has(key)) ? this.merchantSalesUserErrorMap.get(key) : [];
    }
  }

  // handleValidateEditMerchantSalesUser(merchantSalesUser) {
  //   if (!merchantSalesUser.email) {
  //     this.setMerchantSalesUserErrorMapMessages(merchantSalesUserValidationConstants.KEY_EMAIL, "Missing Email");
  //   } else if (!validateEmail(merchantSalesUser.email)) {
  //     this.setMerchantSalesUserErrorMapMessages(merchantSalesUserValidationConstants.KEY_EMAIL, "Please enter correct email format!");
  //   }
  //   if (!merchantSalesUser.userRole) {
  //     this.setMerchantSalesUserErrorMapMessages(merchantSalesUserValidationConstants.KEY_USER_ROLE, "Missing User Role");
  //   }
  //   if (!merchantSalesUser.enabled && merchantSalesUser.enabled !== false) {
  //     this.setMerchantSalesUserErrorMapMessages(merchantSalesUserValidationConstants.KEY_ENABLED, "Missing Enabled");
  //   }
  //   if (!merchantSalesUser.enableMultiLogin && merchantSalesUser.enableMultiLogin !== false) {
  //     this.setMerchantSalesUserErrorMapMessages(merchantSalesUserValidationConstants.KEY_ENABLED_MULTI_LOGIN, "Missing EnableMultiLogin");
  //   }
  // }

  handleValidateCreateMerchantSalesUser(merchantSalesUser) {
    let pattern = new RegExp(
      "^(?=.*[A-Z])"
    );
    if (!merchantSalesUser.username) {
      this.setMerchantSalesUserErrorMapMessages(merchantSalesUserValidationConstants.KEY_USERNAME, "Missing Username");
    } else if (pattern.test(merchantSalesUser.username)){
      this.setMerchantSalesUserErrorMapMessages(merchantSalesUserValidationConstants.KEY_USERNAME, "Upper case letter is not support!");
    } else if (merchantSalesUser.username.length > 45){
      this.setMerchantSalesUserErrorMapMessages(merchantSalesUserValidationConstants.KEY_USERNAME, "Username length should be smaller than 45 letter!");
    }
    if (!merchantSalesUser.password) {
      this.setMerchantSalesUserErrorMapMessages(merchantSalesUserValidationConstants.KEY_PASSWORD, "Missing Password");
    }
  }


  setMerchantSalesUserErrorMapMessages(key, errorMessages, valid = false) {
    this.merchantSalesUserErrorMap.set(key, errorMessages);
    // console.log("merchantSalesUserErrorMap", this.merchantSalesUserErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new MerchantSalesUserValidation();
export default validation;