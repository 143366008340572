import { QUEUE_TEMPLATE_MAP_TYPE } from '../Constants/constants';
import { queueTemplateMapValidationConstants } from '../Constants/validationConstants';

class QueueTemplateValidation {

  version;
  errorMap;
  queueTemplateMapErrorMap;
  valid;

  constructor() {

    if (!QueueTemplateValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.queueTemplateMapErrorMap = new Map();
      QueueTemplateValidation.instance = this;
    }
    return QueueTemplateValidation.instance;
  }

  validateTemplate(template) {
    this.resetErrorMessages();
    this.validateQueueTemplate(template);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.queueTemplateMapErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key, uniqueCode) {
    if (!uniqueCode) {
      return (this.queueTemplateMapErrorMap.has(key)) ? this.queueTemplateMapErrorMap.get(key) : [];
    } else {
      switch (key) {
        case queueTemplateMapValidationConstants.KEY_QUEUE_TEMPLATE_MAP_ERROR:
        case queueTemplateMapValidationConstants.KEY_REST_URL_ID:
        case queueTemplateMapValidationConstants.KEY_TYPE:
        case queueTemplateMapValidationConstants.KEY_DAY_OF_WEEK:
        case queueTemplateMapValidationConstants.KEY_START_DATE:
        case queueTemplateMapValidationConstants.KEY_END_DATE:
        case queueTemplateMapValidationConstants.KEY_TEMPLATE_CODE:
          if (this.queueTemplateMapErrorMap.has(uniqueCode) && this.queueTemplateMapErrorMap.get(uniqueCode).has(key)) {
            return this.queueTemplateMapErrorMap.get(uniqueCode).get(key);
          }
          return [];
        default:
          return [];
      }
    }
  }

  validateQueueTemplate(queueTemplateMap) {
    let dateList = [];
    let dayOfWeekList = [];
    queueTemplateMap.map((item) => {
      const queueTemplateErrorMap = new Map();
      if (!item.type) {
        queueTemplateErrorMap.set(queueTemplateMapValidationConstants.KEY_TYPE, ['Missing Type']);
      } else if (item.type === QUEUE_TEMPLATE_MAP_TYPE.DAY_OF_WEEK) {
        if (!item.dayOfWeek) {
          queueTemplateErrorMap.set(queueTemplateMapValidationConstants.KEY_DAY_OF_WEEK, ['Missing Day Of Week']);
        } else if (dayOfWeekList.includes(item.dayOfWeek)) {
          queueTemplateErrorMap.set(queueTemplateMapValidationConstants.KEY_DAY_OF_WEEK, ['Duplicate Day Of Week']);
        } else {
          dayOfWeekList.push(item.dayOfWeek)
        }
      } else if (item.type === QUEUE_TEMPLATE_MAP_TYPE.DATE_RANGE) {
        const startDate = Number(item.startDate);
        const endDate = Number(item.endDate);
        if (isNaN(startDate) || startDate === 0) {
          queueTemplateErrorMap.set(queueTemplateMapValidationConstants.KEY_START_DATE, ['Missing Start Date']);
        } else if (!isNaN(startDate) && !isNaN(endDate)) {
          if (startDate > endDate) {
            queueTemplateErrorMap.set(queueTemplateMapValidationConstants.KEY_START_DATE, ['Start Date is larger than End Date']);
            queueTemplateErrorMap.set(queueTemplateMapValidationConstants.KEY_END_DATE, ['Start Date is larger than End Date']);
          } else {
            let error = [];
            dateList.map((dateValue) => {
              if (dateValue.startDate <= endDate && dateValue.endDate >= startDate) {
                queueTemplateErrorMap.set(queueTemplateMapValidationConstants.KEY_START_DATE, ['Duplicate Date']);
                queueTemplateErrorMap.set(queueTemplateMapValidationConstants.KEY_END_DATE, ['Duplicate Date']);
                error.push(true)
              }
              return dateValue
            })
            if (!error.includes(true)) {
              dateList.push({ startDate: startDate, endDate: endDate })
            }
          }
        }
        if (isNaN(endDate) || endDate === 0) {
          queueTemplateErrorMap.set(queueTemplateMapValidationConstants.KEY_END_DATE, ['Missing End Date']);
        }
      }
      if (!item.templateCode) {
        queueTemplateErrorMap.set(queueTemplateMapValidationConstants.KEY_TEMPLATE_CODE, ['Missing Template Code']);
      }
      if (queueTemplateErrorMap.size > 0) {
        queueTemplateErrorMap.set(queueTemplateMapValidationConstants.KEY_QUEUE_TEMPLATE_MAP_ERROR, ["Has error(s)"]);
        this.setQueueTemplateErrorMapMessages(item.id ? item.id : item.customId, queueTemplateErrorMap);
      }
      return item
    })
  }


  setQueueTemplateErrorMapMessages(key, errorMessages, valid = false) {
    this.queueTemplateMapErrorMap.set(key, errorMessages);
    // console.log("queueTemplateMapErrorMap", this.queueTemplateMapErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new QueueTemplateValidation();
export default validation;