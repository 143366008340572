import { useState, useEffect } from "react";
import { useSelector, useStore } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import _ from "lodash";
import moment from 'moment';

import DeleteIcon from '@mui/icons-material/Delete';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import AddIcon from '@mui/icons-material/Add';

import { ListDisplay } from '../../Component/ListDisplay/ListDisplay';

import { constants, QUEUE_TEMPLATE_MAP_TYPE } from '../../Constants/constants';
import { getList, deleteFetch } from "../../helper/baseFetch";
import { DialogWrapper } from "../../Component/DialogWrapper";
import { getPayloadWithHyperLink } from "./queueTemplateUtil";

export const QueueTemplateMapWeekdayList = ({ newFilter, editBtn, createBtn, btnList, mutipleSelect, setSelectedIdList, selectedIdList, setPreviewSelectedList, previewSelectedList, dialogFooterBtn, savedData }) => {
  const store = useStore();
  const params = useParams();
  const history = useHistory();
  const [queueTemplateMapList, setQueueTemplateMapList] = useState([]);
  const total = useSelector(state => state.system.ui.total);
  const [ready, setReady] = useState(false);
  const [clear, setClear] = useState(false);
  const [selectedAllCheck, setSelectedAllCheck] = useState(false);
  const [ xtotal, setXTotal] = useState(0);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const resource = "QueueTemplateMap";


  const queueTemplateMapTitleList = [
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "templateCode",
      hyperLink: true,
      sort: true
    },
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "dayOfWeek",
      sort: false
    }, 
  ];

  const listFilters = [
    {
      className: "filter-input",
      label: { resource: resource, key: "type" },
      clear: true,
      value: QUEUE_TEMPLATE_MAP_TYPE.DAY_OF_WEEK,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.QUEUE_TEMPLATE_MAP.TYPE,
      name: "type",
      size: constants.SIZE.SMALL
    },
  ];

  const [param, setParam] = useState({
    path: "",
    page: 0,
    rowsPerPage: 10,
    sort: 'updateTimestamp',
    direction: 'DESC',
    listFilters: listFilters,
    count: 0,
  })

  function onChangeParam(value) {
    value.count = value.count + 1;
    setParam(value);
  }

  function onChangeListFilters(list) {
    let tempParam = _.cloneDeep(param);
    tempParam.listFilters = list;
    tempParam.page = 0;
    onChangeParam(tempParam);
  }

  function onChangeListSort(sortItem) {
    let tempParam = _.cloneDeep(param);
    tempParam.sort = sortItem;
    tempParam.direction = tempParam.direction === "DESC" ? "ASC" : "DESC";
    onChangeParam(tempParam);
  }

  function onChangeListPage(page) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = page;
    onChangeParam(tempParam);
  }

  function onChangeListRowsPerPage(rowsPerPage) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = 0;
    tempParam.rowsPerPage = rowsPerPage;
    onChangeParam(tempParam);
  }

  function handleDeleteDialog(id, data) {
    setDialog({
      open: true,
      onClose: onClickBackToList,
      dialogContent:
        <>
          Delete {data.description ? data.description : data.dayOfWeek ? data.dayOfWeek : "Confirm"} ?
        </>,
      dialogActions: [
        { label: { key: "deleteBtn" }, onClick: () => handleDelete(id), icon: <DeleteIcon /> },
        { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
      ]
    })
  }

  function handleDelete(id) {
    deleteFetch(constants.PATH.SHOP.QUEUE.QUEUE_TEMPLATE_MAP + "/" + id, undefined, (payload) => {
      setDialog({
        open: true,
        onClose: onClickBackToList,
        dialogContentText: { key: "deleteSuccess" },
        dialogActions: [
          { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
        ]
      })
    }, undefined, store);
  }

  const onClickBackToList = () => {
    setClear(true);
    setDialog({
      open: false,
      dialogActions: []
    });
  }

  function onMultiSelectList(id, dataList) {
    let selectedList = selectedIdList ? _.cloneDeep(selectedIdList) : [];
    let newSelectedQueueTemplateList = _.cloneDeep(previewSelectedList);
    let tempDataList = _.cloneDeep(dataList);
    if (id === "all") {
      if (!selectedAllCheck) {
        queueTemplateMapList.map((item) => {
          newSelectedQueueTemplateList.push(item);
          return selectedList.push(item.id);
        });
      } else {
        selectedList = selectedList.filter((item) => {
          let selectedQueueTemplate = queueTemplateMapList.map((queueTemplateMap) => {
            newSelectedQueueTemplateList = newSelectedQueueTemplateList.filter((newSelectedQueueTemplate) => newSelectedQueueTemplate.id !== queueTemplateMap.id);
            return queueTemplateMap.id === item && queueTemplateMap.id
          })
          return !selectedQueueTemplate.includes(item)
        });
      }
      setSelectedAllCheck(!selectedAllCheck);
    } else {
      if (selectedList.includes(id)) {
        selectedList = selectedList.filter((item) => item !== id);
        newSelectedQueueTemplateList = newSelectedQueueTemplateList.filter((item) => item.id !== id);
      } else {
        selectedList.push(id);
        newSelectedQueueTemplateList.push.apply(newSelectedQueueTemplateList, tempDataList.filter((item) => item.id === id));
      }

      let selectedPerPage =
        queueTemplateMapList.filter((queueTemplateMap) => {
          return selectedList.includes(queueTemplateMap.id)
        })
      if (selectedPerPage.length === (param.rowsPerPage) && selectedPerPage.length !== 0) {
        setSelectedAllCheck(true);
      } else {
        setSelectedAllCheck(false);
      }
    }

    setSelectedIdList(selectedList);

    setPreviewSelectedList(newSelectedQueueTemplateList.map((queueTemplateMap) => {
      return queueTemplateMap
    }));
  }

  const handleCreate = (dayOfWeek) => {
    history.push(`/shop/queue/restaurant_queue_template_map/create/${params.id}/0?dayOfWeek=${dayOfWeek}`)
  }

  useEffect(() => {
    let tempParam = _.cloneDeep(param);
    const filter = newFilter ? _.cloneDeep(newFilter) : { id: params.id };
    tempParam.listFilters.forEach((filterItem) => {
      if (filterItem.value !== '' && filterItem.value !== undefined) {
        if (filterItem.name === "date") {
          filter[filterItem.name] = moment(filterItem.value).format("x");
        } else {
          filter[filterItem.name] = filterItem.value;
        }
      }
    })

    getList(constants.PATH.SHOP.QUEUE.QUEUE_TEMPLATE_MAP, { sort: { field: tempParam.sort, order: tempParam.direction }, filter: filter, pagination: { page: tempParam.page + 1, perPage: tempParam.rowsPerPage } }, (payload, xTotal) => {
      const payloadWithHyperLink = getPayloadWithHyperLink(payload);
      const sortedPayload = getSortPayload(payloadWithHyperLink, handleCreate);
      setQueueTemplateMapList(sortedPayload);
      setXTotal(xTotal);
      let selectedPerPage =
        payload.filter((queueTemplate) => {
          return savedData && savedData.map((savedData) => { return savedData }).includes(queueTemplate.id)
        })
      if (selectedPerPage.length === Number(tempParam.rowsPerPage < total ? tempParam.rowsPerPage : total) && selectedPerPage.length !== 0) {
        setSelectedAllCheck(true);
      } else {
        setSelectedAllCheck(false);
      }
      setReady(true);
      setClear(false);
    }, undefined, store);

  }, [param.count, clear === true]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <>
      <ListDisplay
        page={param.page}
        rowsPerPage={param.rowsPerPage}
        hideBtnLabel={true}
        newTotal={xtotal}
        onChangeListSort={onChangeListSort}
        newListMainTitle={{key: 'dayOfWeekListTitle', resource: resource}}
        resource={resource}
        dataList={queueTemplateMapList}
        sort={param.sort}
        direction={param.direction}
        listTitles={queueTemplateMapTitleList}
        listFilters={[]}
        onChangeListPage={onChangeListPage}
        onChangeListRowsPerPage={onChangeListRowsPerPage}
        editBtn={editBtn}
        // createBtn={false}
        useRedirectPathStraight={true}
        redirectCreatePath={`/${constants.PATH.SHOP.QUEUE.QUEUE_TEMPLATE_MAP}/create/${params.id}/0?type=DAY_OF_WEEK`}
        mutipleSelect={mutipleSelect}
        idName="id"
        idParam={params.id}
        onMultiSelectList={onMultiSelectList}
        selectedAllCheck={selectedAllCheck}
        previewSelectedIdList={selectedIdList ? selectedIdList : []}
        dialogFooterBtn={dialogFooterBtn}
        btnList={btnList || btnList === false ? btnList : [
          {
            handleChange: handleDeleteDialog,
            color: constants.STYLE.PRIMARY,
            icon: <DeleteIcon />,
          },
        ]}
        onChangeListFilters={onChangeListFilters}
        redirectPath={"/" + constants.PATH.SHOP.QUEUE.QUEUE_TEMPLATE_MAP + "/"}
      />
      {dialog && dialog.open &&
        <DialogWrapper dialogInfo={dialog} />
      }
    </>
  )
}

const getSortPayload = (payload, handleCreate) => {

  const result = [];
  const dayMap = new Map(payload.map(i => [i.dayOfWeek, i]));

  for (let i=0; i<8; i++) {
    if(dayMap.has(DAY_OF_WEEK[i])) {
      result.push(dayMap.get(DAY_OF_WEEK[i]))
      continue;
    }

    result.push({
      dayOfWeek: DAY_OF_WEEK[i],
      customizeBth: true,
      customizeBtnList: [
        {
          handleChange: ()=>handleCreate(DAY_OF_WEEK[i]),
          color: constants.STYLE.PRIMARY,
          icon: <AddIcon />,
        },
      ]
    })
  }

  return result;
}

const DAY_OF_WEEK = [
  'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', 'PH'
]