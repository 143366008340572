import React from 'react'
import { constants as co } from '../../Constants/constants'
import { TempAutoSelectInput } from '../../Component/Input/TempAutoSelectInput'

export const DefaultColorSetDropDown = ({ resource, resourceKey, onChangeInput, value, savedDefaultTableTypeColorList }) => {
  return (
    <TempAutoSelectInput
      inputSetting={{
        name: resourceKey,
        label: { resource: resource, key: resourceKey },
        value: value,
        selectValue:
          savedDefaultTableTypeColorList?.map((tableTypeColor, idx) => ({
            value: `${tableTypeColor.foregroundColor}&${tableTypeColor.backgroundColor}`,
            name: `Default Color Set ${idx}`,
            hexList: [tableTypeColor.foregroundColor, tableTypeColor.backgroundColor],
          })) || co.CHOICES.QUEUE_TEMPLATE.DEFAULT_TABLE_TYPE_COLOR_SET,
      }}
      handleChange={onChangeInput}
    />
  )
}
