import { LANDMARK } from './types';
import { sify } from "chinese-conv";
import _ from "lodash";

export const setLandmarkDefault = () => {
    return {
        type: LANDMARK.SET_LANDMARK_DEFAULT,
    }
}

export const setLandmark = (landmark) => {
    return {
        type: LANDMARK.SET_LANDMARK,
        landmark: landmark
    }
}

export const setLandmarkValue = (draftType, orignalProduct, key, value, index) => {
    const landmark = _.cloneDeep(orignalProduct);
    switch (draftType) {
        case "CHANGE_TC_NAME_AND_SC_NAME":
            landmark.tcName = value;
            landmark.scName = sify(value);
            break;
        default:
            landmark[draftType] = value;
    }
    return {
        type: LANDMARK.SET_LANDMARK_VALUE,
        landmark: landmark
    }
}
