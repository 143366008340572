import _ from "lodash";
import { LANDMARK } from '../actions/types';
import { constants } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        landmark: _.cloneDeep(constants.LANDMARK_INPUT)
    }
}

const landmarkReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LANDMARK.SET_LANDMARK_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case LANDMARK.SET_LANDMARK:
        case LANDMARK.SET_LANDMARK_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    landmark: action.landmark
                }
            })
        default:
            return state
    }
}

export default landmarkReducer