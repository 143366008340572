import { STAFF } from './types'
import _ from 'lodash'
import { sify } from 'chinese-conv'

export const setStaffDefault = () => {
  return {
    type: STAFF.SET_STAFF_DEFAULT,
  }
}

export const setStaff = (staff) => {
  return {
    type: STAFF.SET_STAFF,
    staff: staff,
  }
}

export const setStaffValue = (draftType, orignalProduct, key, value, index) => {
  const staff = _.cloneDeep(orignalProduct)
  switch (draftType) {
    case 'username':
      staff.username = value
      if (staff.syncNameFieldsWithUsername) {
        staff.surname = staff.username
        staff.firstName = staff.username
        staff.displayName = staff.username
      }
      break
    case 'tcName':
      staff.tcName = value
      staff.scName = staff.scNameDisable ? sify(value) : staff.scName
      break
    case 'scNameDisable':
      staff.scNameDisable = value
      if (value) {
        staff.scName = !staff.tcName ? staff.scName : sify(staff.tcName)
      }
      break
    case 'syncNameFieldsWithUsername':
      staff.syncNameFieldsWithUsername = value
      if (value) {
        staff.surname = staff.username
        staff.firstName = staff.username
        staff.displayName = staff.username
      }
      break
    default:
      staff[draftType] = value
  }
  return {
    type: STAFF.SET_STAFF_VALUE,
    staff: staff,
  }
}
