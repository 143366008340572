import _ from 'lodash'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import store from '../../store'
import { constants as co } from '../../Constants/constants'
import { TempTextInput } from '../../Component/Input/TempTextInput'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { DialogWrapper } from '../../Component/DialogWrapper'
import { getList, post } from '../../helper/baseFetch'
import { ArrowBack, LowPriority } from '@mui/icons-material'
import { getDefaultBottomBtnList } from '../../Component/EditCreateDisplay/EditUtil'
import CounterValidation from '../../validations/counterValidation'
import { counterValidationConstants } from '../../Constants/validationConstants'
import { setCounter, setCounterDefault, setCounterValue } from '../../actions/counterAction'
import GetSoundTemplateCode from './GetSoundTemplateCode'
import useRestaurant from '../../hooks/Restaurant/useRestaurant'

export default function CounterCreate() {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const { getRestaurantName } = useRestaurant(params.restUrlId)
  const restName = getRestaurantName()
  const [clear, setClear] = useState(false)
  const [dialogInfo, setDialogInfo] = useState({
    open: false,
  })
  const [soundTemplateListNotRequireNameInput, setSoundTemplateListNotRequireNameInput] = useState([])

  const resource = 'Counter'
  const savedData = useSelector((state) => state.counter.ui.counter)

  let restUrlId = params.restUrlId || '',
    validation = CounterValidation,
    validationConstants = counterValidationConstants,
    setDispatchDefault = () => dispatch(setCounterDefault()),
    setDispatch = (counter) => dispatch(setCounter(counter)),
    setDispatchValue = (fieldName, originalObj, key, value) => dispatch(setCounterValue(fieldName, originalObj, key, value)),
    apiPath = co.PATH.MERCHANT.COUNTER,
    listPath = `/${co.PATH.SHOP.SYSTEM_GROUP_RESTAURANT}/${restUrlId}/${co.RESTAURANT_TAB.MERCHANT}/${co.RESTAURANT_MERCHANT_SUB_TAB.COUNTER}`

  // eslint-disable-next-line no-unused-vars
  const [errorVersion, setErrorVersion] = useState(validation.getVersion())

  const onCloseDialog = () => {
    setDialogInfo({
      open: false,
    })
  }

  const onChangeInput = (name, e) => {
    setDispatchValue(name, savedData, '', e)
  }

  const onRedirectToCreateEdit = () => {
    onCloseDialog()
    onClickClear()
  }

  const onRedirectToList = () => {
    setDialogInfo({
      open: false,
    })
    history.push(listPath)
  }

  const onClickSave = () => {
    validation.validateSavedData({ name: savedData.name, templateCode: savedData.templateCode })

    if (validation.isValid()) {
      post(
        apiPath,
        {
          restUrlId: restUrlId,
          name: savedData.name,
          templateCode: savedData.templateCode,
        },
        () => {
          setDialogInfo({
            open: true,
            onClose: onRedirectToCreateEdit,
            dialogContentText: { key: 'createSuccess' },
            maxWidth: 'md',
            dialogActions: [
              { label: { key: 'backCreate' }, onClick: onRedirectToCreateEdit, icon: <ArrowBack /> },
              {
                label: { key: 'backList' },
                onClick: onRedirectToList,
                icon: <LowPriority />,
              },
            ],
          })
          setClear(false)
        },
        undefined,
        store
      )
    } else {
      setErrorVersion(validation.getVersion())
    }
  }

  const onClickClear = () => {
    setClear(true)
  }

  const content = [
    {
      tab: (
        <div className="edit-display-wrapper">
          <GetSoundTemplateCode />
          {!soundTemplateListNotRequireNameInput.find((soundTemplate) => soundTemplate.code === savedData.templateCode) && (
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'name' },
                value: savedData.name,
                name: 'name',
                helperText: validation.getErrMsg(validationConstants.KEY_NAME),
              }}
              handleChange={onChangeInput}
            />
          )}
          {savedData.templateCode && (
            <div>
              <TempTextInput
                inputSetting={{
                  label: { resource: resource, key: 'enDescription' },
                  value: savedData.enDescription,
                  name: 'enDescription',
                  helperText: validation.getErrMsg(validationConstants.KEY_NAME),
                  disabled: true,
                  variant: co.STYLE.VARIANT.STANDARD,
                }}
              />
              <TempTextInput
                inputSetting={{
                  label: { resource: resource, key: 'tcDescription' },
                  value: savedData.tcDescription,
                  name: 'tcDescription',
                  helperText: validation.getErrMsg(validationConstants.KEY_NAME),
                  disabled: true,
                  variant: co.STYLE.VARIANT.STANDARD,
                }}
              />
              <TempTextInput
                inputSetting={{
                  label: { resource: resource, key: 'scDescription' },
                  value: savedData.scDescription,
                  name: 'scDescription',
                  helperText: validation.getErrMsg(validationConstants.KEY_NAME),
                  disabled: true,
                  variant: co.STYLE.VARIANT.STANDARD,
                }}
              />
            </div>
          )}
        </div>
      ),
      btn: true,
    },
  ]

  useEffect(() => {
    setDispatchDefault()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getList(
      co.PATH.MERCHANT.SOUND_TEMPLATE,
      {
        sort: { field: 'updateTimestamp', order: 'ASC' },
        filter: { type: 'COUNTER' },
        pagination: { page: 1, perPage: 10000 },
      },
      (payload) => {
        const newSoundTemplateListNotRequireNameInput = payload.filter((soundTemplate) => !soundTemplate.enDescription.includes('{0}'))
        setSoundTemplateListNotRequireNameInput(newSoundTemplateListNotRequireNameInput)
      },
      undefined,
      store
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setDispatchDefault()
    setClear(false)
    validation.resetErrMsg()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear === true])

  // useEffect(() => {
  //   console.log('savedData', savedData)
  // }, [savedData])

  useEffect(() => {
    if (soundTemplateListNotRequireNameInput.find((soundTemplate) => soundTemplate.code === savedData.templateCode)) {
      let newSavedData = _.cloneDeep(savedData)
      newSavedData.name = ''
      setDispatch(newSavedData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedData.templateCode])

  useEffect(() => {
    setDispatchDefault()
    validation.resetErrMsg()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <EditCreateDisplay
        resource={resource}
        content={content}
        mapName={{ resource: 'restaurant', key: 'restUrlId' }}
        mapCode={`${restUrlId} - ${restName}`}
        display="create"
        bottomBtns={getDefaultBottomBtnList(onClickSave, onClickClear)}
      />
      {dialogInfo.open && <DialogWrapper dialogInfo={dialogInfo} />}
    </>
  )
}
