
import { useState, useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from 'moment';

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';

import { constants } from '../../../Constants/constants';
import { post } from "../../../helper/baseFetch";
import { setMerchantUserGroup, setMerchantUserGroupValue, addUserGroupEmail, addUserGroupSettlementConfig } from "../../../actions/merchantUserGroupAction";
import { helperTextStyles } from "../../../helper/util";
import { getContent } from "../../../Component/EditCreateDisplay/EditUtil";
import { EditCreateDisplay } from '../../../Component/EditCreateDisplay/EditCreateDisplay';
import { DialogWrapper } from "../../../Component/DialogWrapper";
import { EmailField } from "./EmailField";
import { AddRestaurantButton } from "./AddRestaurantButton";
import { SettlementConfigList } from "./SettlementConfigList";
import MerchantUserGroupValidation from "../../../validations/merchantUserGroupValidation"
import { merchantUserGroupValidationConstants } from "../../../Constants/validationConstants";


export const MerchantUserGroupCreate = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();
  const helperTextStylesClasses = helperTextStyles();
  const [clear, setClear] = useState(true);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const resource = "MerchantUserGroup";
  const savedMerchantUserGroup = useSelector(state => state.merchantUserGroup.ui.merchantUserGroup);
  const lang = useSelector(state => state.lang.ui.lang);
  const theme = useSelector(state => state.theme.ui.theme);
  const [addRestaurantMap, setAddRestaurantMap] = useState(new Map());
  const [confirmRestList, setConfirmRestList] = useState([]);
  const [, setErrorVersion] = useState(MerchantUserGroupValidation.getVersion());

  const handleAddEmail= () =>{
    dispatch(addUserGroupEmail(savedMerchantUserGroup));
  }

  const handleAddSettlementConfigList = () =>{
    dispatch(addUserGroupSettlementConfig(savedMerchantUserGroup));
  }

  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.MERCHANT_USER_GROUP_EDIT_TAB.INFO
    },
  ]
  const tempList = [
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "groupDesc" },
      value: savedMerchantUserGroup.groupDesc,
      name: "groupDesc",
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: MerchantUserGroupValidation.getErrorMessages(`${merchantUserGroupValidationConstants.KEY_NAME}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { resource: resource, key: "addEmail" },
      disabled: false,
      onClickEndAdornmentIconButton: () => handleAddEmail(),
      name: "addEmail",
      type: constants.TYPE.BUTTON,
      icon: <AddIcon />,
      isEndOfLine: true,
    },
  ]

  savedMerchantUserGroup?.emailList?.forEach((item, index)=>{
    tempList.push(
      {
        className: "edit-create-input",
        size: constants.SIZE.SMALL,
        type: constants.TYPE.FUNCTION,
        isEndOfLine: true,
        value: <EmailField item={item} index={index} onChangeInput={onChangeInput}/>
      }
    )
  })

  tempList.push(
    {
      type: constants.TYPE.TITLE,
      value: { resource: resource, key: "SettlementConfigList" },
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { resource: resource, key: "addSettlementConfigList" },
      disabled: false,
      onClick: () => handleAddSettlementConfigList(),
      name: "addSettlementConfigList",
      type: constants.TYPE.BUTTON,
      icon: <AddIcon />,
      isEndOfLine: true,
    },
  )

  savedMerchantUserGroup?.settlementConfigList?.forEach((item, index)=>{
    tempList.push(
      {
        className: "edit-create-input",
        size: constants.SIZE.SMALL,
        type: constants.TYPE.FUNCTION,
        isEndOfLine: true,
        value: <SettlementConfigList item={item} index={index} onChangeInput={onChangeInput}/>
      }
    )
  })

  tempList.push(
    {
      type: constants.TYPE.TITLE,
      value: { resource: resource, key: "RestaurantList" },
      helperTextIcon: true,
      helperText: MerchantUserGroupValidation.getErrorMessages(`${merchantUserGroupValidationConstants.KEY_REST_URL_ID}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      type: constants.TYPE.FUNCTION,
      value: <AddRestaurantButton onChangeInput={onChangeInput} 
            addRestaurantMap={addRestaurantMap}
            setAddRestaurantMap={setAddRestaurantMap}
            confirmRestList={confirmRestList} 
            setConfirmRestList={setConfirmRestList}/>,
      isEndOfLine: true,
    },
  )


  
  const content = [
    {
      tab: tempList.map((inputSetting, index) => {
        let content = getContent(inputSetting, index, onChangeInput, lang, theme);
        return <div className="edit-display-wrapper" key={index}>
          {content}
          {inputSetting.isEndOfLine && <div></div>}
        </div>
      }),
      btn: true
    }
  ]

  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    },
  ]

  // const handleClose = () => {
  //   setDialog({
  //     open: false,
  //     dialogActions: []
  //   });
  // };

  const onClickBackToCreate = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  };
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.MERCHANT.MERCHANT_USER_GROUP);
  };

  function onChangeInput(name, e) {

    if(name.startsWith("emails")){
      const index = name.split("-")[1];
      dispatch(setMerchantUserGroupValue("emails", savedMerchantUserGroup, index, e));
      return;
    }

    if(name.startsWith("settlementConfig")){
      const index = name.split("-")[1];
      const key = name.split("-")[2];
      if (key === "startTimestamp" || key === "endTimestamp") {
        if (e === "") {
          e = null
        }
        e = e ? Number(moment(e).format("x")) : e;
      }
      
      dispatch(setMerchantUserGroupValue("settlementConfig", savedMerchantUserGroup, key, e, index));
      return;
    }

    switch(name){
      default:
        dispatch(setMerchantUserGroupValue(name, savedMerchantUserGroup, "", e));
    }
  }

  function onClickSave() {
    MerchantUserGroupValidation.validateCreateMerchantUserGroup({...savedMerchantUserGroup, restUrlIdList:confirmRestList.map((item)=>item.restUrlId)});
    if (MerchantUserGroupValidation.isValid()) {
      post(constants.PATH.MERCHANT.MERCHANT_USER_GROUP + "/", {...savedMerchantUserGroup, restUrlIdList:confirmRestList.map((item)=>item.restUrlId)}, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToCreate,
          dialogContentText: { key: "createSuccess" },
          dialogActions: [
            { label: { key: "backCreate" }, onClick: onClickBackToCreate, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      MerchantUserGroupValidation.resetErrorMessages();
    } 
    else {
      setErrorVersion(MerchantUserGroupValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {

    dispatch(setMerchantUserGroup(constants.MERCHANT_USER_GROUP_INPUT));
    setClear(false)
    MerchantUserGroupValidation.resetErrorMessages();
    
  }, [clear === true]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <EditCreateDisplay
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        display="create"
      />
      {dialog && dialog.open &&
        <DialogWrapper dialogInfo={dialog} />
      }
    </>
  )
};