import { useState, useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import _ from 'lodash'

import { constants } from '../../../Constants/constants'
import { getOne, put } from '../../../helper/baseFetch'
import { setLandingSection } from '../../../actions/landingSectionAction'
import { LandingSectionPreview } from './LandingSectionPreview/LandingSectionPreview'
import { EditCreateDisplay } from '../../../Component/EditCreateDisplay/EditCreateDisplay'
import landingSectionValidation from '../../../validations/landingSectionValidation'
import { setMessage } from '../../../actions/systemAction'
import { getString } from '../../../helper/util'
import '../../../styles/landingSection.scss'
import { Clear, Save } from '@mui/icons-material'

const resource = 'LandingSection'

export const LandingSectionList = () => {
  const [currentEdit, setCurrentEdit] = useState(null)
  const [onlyPublishing, setOnlyPublishing] = useState(false)
  const [clear, setClear] = useState(true)
  const dispatch = useDispatch()
  const savedLandingSection = useSelector((state) => state.landingSection.ui.landingSection)
  const lang = useSelector((state) => state.lang.ui.lang)

  const store = useStore()

  useEffect(() => {
    if (!clear) {
      return
    }
    getOne(
      constants.PATH.LANDING.LANDING_SECTION + '/',
      {
        publishing: onlyPublishing
      },
      (payload) => {
        for (const section of payload) {
          if (section.actionParameters) {
            section.parameters = getJson(section.actionParameters)
          }
          for (const item of section.itemList) {
            if (item.actionParameters) {
              item.parameters = JSON.parse(item.actionParameters)
            }
            item.scTitleDisable = true
            item.scSubTitleDisable = true
          }
          section.scNameDisable = true
        }

        dispatch(setLandingSection(payload))
        setClear(false)
        setCurrentEdit(null)
        landingSectionValidation.resetErrorMessages()
      },
      undefined,
      store
    )
  }, [clear])

  const onClickSave = () => {
    let sectionList = savedLandingSection.length

    const tempt = _.cloneDeep(savedLandingSection)

    tempt.forEach((item) => {
      item.sequence = sectionList * 10
      sectionList--
    })

    const updateSequence = tempt.map((item) => ({ id: item.id, sequence: item.sequence }))

    put(
      constants.PATH.LANDING.LANDING_SECTION_UPDATE_SEQUENCE,
      updateSequence,
      (payload) => {
        dispatch(setMessage(getString(lang, '', 'editSuccess')))
        setClear(true)
      },
      undefined,
      store
    )
  }

  const onClickClear = () => {
    setClear(true)
  }

  const content = [
    {
      tab: <LandingSectionPreview setCurrentEdit={setCurrentEdit} currentEdit={currentEdit} onlyPublishing={onlyPublishing} setOnlyPublishing={setOnlyPublishing} onClickClear={onClickClear}/>,
      btn: true,
    },
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'save' },
      onClick: onClickSave,
      className: 'margin-left-16',
      icon: <Save />,
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'clear' },
      disabled: false,
      onClick: onClickClear,
      className: 'margin-left-16',
      icon: <Clear />,
    },
  ]

  return (
    <>
      <EditCreateDisplay
        code={''}
        resource={resource}
        tabs={false}
        content={content}
        bottomBtns={bottomBtns}
        // display="none"
        display="edit"
        mapName={{ resource: 'LandingSection', key: 'editTitle' }}
      />
    </>
  )
}

const getJson = (str) => {
  try {
    str = JSON.parse(str)
  } catch (e) {
    return {}
  }
  return str
}
