import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'
import { BaseWrapper } from '../../Component/BaseWrapper'
import { getString, tableColor } from '../../helper/util'

import { constants as co } from '../../Constants/constants'
import Tooltip from '@mui/material/Tooltip'

import '../../styles/queueTemplate.scss'
import { mergeMapList } from '../../helper/GenericQueueProfile/mergeListUtil'

import { setQueueProfile } from '../../actions/queueProfileAction'
import { setQueueTransferProfile } from '../../actions/queueTransferProfileAction'
import { Warning } from '@mui/icons-material'
import { TicketTypeSection } from '../../Component/GenericQueueProfile/TicketTypeSection'
import { Checkbox } from '@mui/material'

const GenericQueueProfileTimeSectionList = ({ resource }) => {
  const dispatch = useDispatch()
  const lang = useSelector((state) => state.lang.ui.lang)
  const theme = useSelector((state) => state.theme.ui.theme)
  const [clickedTableTypeButton, setClickedTableTypeButton] = useState(false)

  let isQueueTransferProfile = resource === 'QueueTransferProfile'

  const savedData = useSelector((state) => {
    if (isQueueTransferProfile) {
      return state.queueTransferProfile.ui.queueTransferProfile
    } else {
      return state.queueProfile.ui.queueProfile
    }
  })

  const [mapList, setMapList] = useState(mergeMapList(savedData))

  let setDispatch = isQueueTransferProfile ? (profile) => dispatch(setQueueTransferProfile(profile)) : (profile) => dispatch(setQueueProfile(profile))

  //   const [profileList, setProfileList] = useState(savedData.timeSectionList || [])
  //   const [refTemplateList, setRefTemplateList] = useState(savedData.refTemplateTimeSectionList || [])

  const onClickTableTypeButton = (tableTypeObj) => {
    const newMapList = mapList.map((timeSectionArr) =>
      timeSectionArr.map((ticketTypeItem) => ({
        ...ticketTypeItem,
        tableTypeList: ticketTypeItem.tableTypeList.map((item) =>
          item.timeSectionId === tableTypeObj.timeSectionId && item.tableType === tableTypeObj.tableType
            ? { ...item, selected: !item.selected }
            : item
        ),
      }))
    )

    const newQueueProfile = _.cloneDeep(savedData)
    newQueueProfile.submitTimeSectionList = newMapList
      ?.flat()
      ?.map((ticketTypeSection) => ticketTypeSection.tableTypeList)
      ?.flat()

    setDispatch(newQueueProfile)
    setMapList(newMapList)
    setClickedTableTypeButton(true)
  }

  const onClickTimeSectionButton = (timeSectionId) => {
    const newMapList = _.cloneDeep(mapList)
    const i = newMapList.findIndex((arr) => arr.some((item) => item.timeSectionId === timeSectionId))

    if (i !== -1) {
      const allTableTypeSelected = newMapList[i]
        .map((ticketType) => ticketType.tableTypeList)
        ?.flat()
        ?.every((tableType) => tableType.selected === true)

      newMapList[i] = newMapList[i].map((ticketType) => ({
        ...ticketType,
        tableTypeList: ticketType.tableTypeList?.map((item) => ({ ...item, selected: !allTableTypeSelected })),
      }))
    }

    const newQueueProfile = _.cloneDeep(savedData)
    newQueueProfile.submitTimeSectionList = newMapList
      ?.flat()
      ?.map((ticketTypeSection) => ticketTypeSection.tableTypeList)
      ?.flat()

    setDispatch(newQueueProfile)
    setMapList(newMapList)
    setClickedTableTypeButton(true)
  }

  const onClickTicketTypeSection = (tickeyTypeObj) => {
    if (!savedData.refTemplateCode || !tickeyTypeObj) {
      return
    }

    const newMapList = _.cloneDeep(mapList)
    const i = newMapList.findIndex((arr) => arr.some((item) => item.timeSectionId === tickeyTypeObj.timeSectionId))
    const selectedTableTypeList = tickeyTypeObj.tableTypeList.map((item) => item)

    if (i !== -1) {
      const allTableTypeSelected = selectedTableTypeList.every((tableType) => tableType.selected === true)

      newMapList[i] = newMapList[i].map((ticketType) => ({
        ...ticketType,
        tableTypeList: ticketType.tableTypeList?.map((item) => ({
          ...item,
          selected:
            item.timeSectionId === tickeyTypeObj.timeSectionId && selectedTableTypeList.find((tableType) => tableType.tableType === item.tableType)
              ? !allTableTypeSelected
              : item.selected,
        })),
      }))
    }

    const newQueueProfile = _.cloneDeep(savedData)
    newQueueProfile.submitTimeSectionList = newMapList
      ?.flat()
      ?.map((ticketTypeSection) => ticketTypeSection.tableTypeList)
      ?.flat()

    setDispatch(newQueueProfile)
    setMapList(newMapList)
    setClickedTableTypeButton(true)
  }

  useEffect(() => {
    if (!clickedTableTypeButton) {
      const newMapList = mergeMapList(savedData)
      setMapList(newMapList)
    } else {
      setClickedTableTypeButton(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedData.timeSectionList])

  useEffect(() => {
    const newMapList = mergeMapList(savedData)
    setMapList(newMapList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedData.refTemplateCode])

  useEffect(() => {
    const newMapList = mergeMapList(savedData)
    setMapList(newMapList)
    const newSubmitTimeSectionList = newMapList
      ?.flat()
      ?.map((ticketTypeSection) => ticketTypeSection.tableTypeList)
      ?.flat()
    const newProfile = _.cloneDeep(savedData)
    newProfile.submitTimeSectionList = newSubmitTimeSectionList
    setDispatch(newProfile)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="queue-template-content-wrapper">
        <BaseWrapper>
          {/* TIME SECTION TITLE */}
          <div className="queue-template-array-list-wrapper">
            <div className="sub-title">{getString(lang, resource, 'timeSectionList', theme)} : </div>
            {/* theme */}
          </div>
          {/* TIME SECTION TITLE */}

          {/* TIME SECTION GROUPS */}
          <div className="queue-template-content-content">
            <div className="queue-template-tab-background">
              {mapList.map((timeSectionArr) => (
                <div
                  key={timeSectionArr[0].timeSectionId}
                  className={'queue-template-list-item'}
                  onClick={() => {
                    if (savedData.refTemplateCode) {
                      onClickTimeSectionButton(timeSectionArr[0].timeSectionId)
                    }
                  }}
                >
                  {/* TIME SECTION DESC */}

                  <div className="code">
                    <div className="type">{getString(lang, resource, 'timeSectionId') + ': ' + timeSectionArr[0].timeSectionId}</div>
                  </div>

                  {/* TIME SECTION DESC */}

                  <div className="content">
                    {/* TIME SECTION NAME */}
                    <div className="top">
                      <div className="name">
                        {/* use system lang for string*/}
                        {lang === co.LANG.EN ? timeSectionArr[0].timeSectionEnLabel : timeSectionArr[0].timeSectionTcLabel}
                      </div>
                    </div>
                    {/* TIME SECTION NAME */}

                    {timeSectionArr.map((tickeyTypeObj, idx) => {
                      const {
                        timeSectionId,
                        ticketType,
                        ticketTypeBackgroundColor,
                        ticketTypeTextColor,
                        ticketTypeEnLabel,
                        ticketTypeScLabel,
                        ticketTypeTcLabel,
                      } = tickeyTypeObj

                      return (
                        <TicketTypeSection
                          key={`${timeSectionId}-${idx}`}
                          ticketType={ticketType}
                          ticketTypeLabel={{ en: ticketTypeEnLabel, sc: ticketTypeScLabel, tc: ticketTypeTcLabel }}
                          ticketTypeBackgroundColor={ticketTypeBackgroundColor}
                          ticketTypeTextColor={ticketTypeTextColor}
                          tickeyTypeObj={tickeyTypeObj}
                          onClickTicketTypeSection={onClickTicketTypeSection}
                        >
                          {tickeyTypeObj.tableTypeList?.map((tableTypeObj, idx) => {
                            const {
                              warning,
                              selected,
                              tableType,
                              timeSectionId,
                              tableTypeEnLabel,
                              tableTypeTcLabel,
                              displayTableTypeForegroundColor,
                              displayTableTypeBackgroundColor,
                            } = tableTypeObj

                            return (
                              <div key={`${timeSectionId}-${tableType}`}>
                                {/* TABLE TYPE BUTTON */}
                                <div className="bottom">
                                  <div
                                    className={
                                      selected
                                        ? `sub-item queue-table-type queue-template-selected-product${'-' + tableColor(tableType)}`
                                        : 'sub-item queue-table-type'
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      if (savedData.refTemplateCode) {
                                        onClickTableTypeButton(tableTypeObj)
                                      }
                                    }}
                                  >
                                    <div className="sub-content">
                                      <Checkbox checked={selected} />
                                      <div className="sub-code">
                                        <div
                                          className="sub-type-selected table-type-label"
                                          style={{
                                            backgroundColor: displayTableTypeForegroundColor || 'white',
                                            color: displayTableTypeBackgroundColor || 'black',
                                          }}
                                        >
                                          {tableType}
                                        </div>
                                      </div>
                                      <div className="sub-div">
                                        {tableType && tableTypeEnLabel && (
                                          <div className="sub-item-name list-content en">
                                            <div className="list-label-tab">EN</div> {tableTypeEnLabel}
                                          </div>
                                        )}
                                        {tableType && tableTypeTcLabel && (
                                          <div className="sub-item-name list-content en">
                                            <div className="list-label-tab">TC</div> {tableTypeTcLabel}
                                          </div>
                                        )}
                                      </div>

                                      {/* warning = tableType exist in profile but not exist in ref template  */}
                                      {warning && (
                                        <Tooltip
                                          title="Table type not exist in ref queue template, please switch off for this profile."
                                          placement="right"
                                          arrow
                                        >
                                          <div>
                                            <Warning />
                                          </div>
                                        </Tooltip>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {/* TABLE TYPE BUTTON */}
                              </div>
                            )
                          })}
                        </TicketTypeSection>
                      )
                    })}
                  </div>
                </div>
              ))}
              {/* TIME SECTION GROUPS */}
            </div>
          </div>
        </BaseWrapper>
      </div>
    </>
  )
}

export default GenericQueueProfileTimeSectionList
