import { CAMPAIGN_DESCRIPTION } from './types';
import { sify } from "chinese-conv";
import _ from "lodash";

export const setCampaignDescriptionDefault = () => {
    return {
        type: CAMPAIGN_DESCRIPTION.SET_CAMPAIGN_DESCRIPTION_DEFAULT,
    }
}

export const setCampaignDescription = (campaignDescription) => {
    return {
        type: CAMPAIGN_DESCRIPTION.SET_CAMPAIGN_DESCRIPTION,
        campaignDescription: campaignDescription
    }
}

export const setCampaignDescriptionValue = (draftType, orignalProduct, key, value, index) => {
    const campaignDescription = _.cloneDeep(orignalProduct);
    switch (draftType) {
        case "CHANGE_TC_TITLE_AND_SC_TITLE":
            if (value) {
                campaignDescription.tcTitle = value;
                campaignDescription.scTitle = sify(value);
            } else {
                campaignDescription.tcTitle = null;
                campaignDescription.scTitle = null;
            }
            break;
        case "CHANGE_TC_MESSAGE_AND_SC_MESSAGE":
            if (value) {
                campaignDescription.tcMessage = value;
                campaignDescription.scMessage = sify(value);
            } else {
                campaignDescription.tcMessage = null;
                campaignDescription.tcMessage = null;
            }
            break;
        case "scTitleDisable":
            campaignDescription[draftType] = value;
            if(value) {
                campaignDescription.scTitle = campaignDescription.tcTitle? sify(campaignDescription.tcTitle): campaignDescription.tcTitle;
            }
            break;
        case "scMessageDisable":
            campaignDescription[draftType] = value;
            if(value) {
                campaignDescription.scMessage = campaignDescription.tcMessage? sify(campaignDescription.tcMessage): campaignDescription.tcMessage;
            }
            break;    
        case "CHANGE_TYPE":
            campaignDescription.type = value;
            campaignDescription.enMessage = null;
            campaignDescription.tcMessage = null;
            campaignDescription.scMessage = null;
            break;
        default:
            campaignDescription[draftType] = value;
    }
    return {
        type: CAMPAIGN_DESCRIPTION.SET_CAMPAIGN_DESCRIPTION_VALUE,
        campaignDescription: campaignDescription
    }
}
