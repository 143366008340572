import { useState } from "react";

import CloseIcon from '@mui/icons-material/Close';

import { BrandList } from "../../Page/Brand/BrandList";
import { constants } from '../../Constants/constants';
import { TempTextInput } from "../Input/TempTextInput";
import { DialogWrapper } from "../DialogWrapper";

import "../../styles/getBrandCode.scss";

export const BrandEditBtn = ({ savedData, sendBrand }) => {
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });

  const resource = "restaurant";

  const handleBrand = () => {
    handleListDialog(true);
  }
  const handleBrandRowClick = (data) => {
    handleListDialog(false);
    sendBrand({
      brandCode: data.brandCode,
      brandTcName: data.tcName,
      brandEnName: data.enName,
      brandScName: data.scName,
    });
  }

  const handleBrandClear = () => {
    sendBrand({
      brandCode: null,
      brandTcName: null,
      brandEnName: null,
      brandScName: null,
    });
  }

  const handleListCloseClick = () => {
    handleListDialog(false);
  };

  function handleListDialog(openPanel) {
    setDialog({
      maxWidth: 'lg',
      open: openPanel,
      onClose: handleListCloseClick,
      dialogContent:
        <>
          <BrandList
            editBtn={false}
            createBtn={false}
            onClickListRow={handleBrandRowClick} />
        </>,
      dialogActions: [
        { label: { key: "close" }, onClick: () => handleListCloseClick(), icon: <CloseIcon /> }
      ],
    });
  }

  return (
    <>
      <div className="brand-id-wrapper">
        <TempTextInput
          inputSetting={{
            className: "edit-create-input",
            size: constants.SIZE.SMALL,
            label: { resource: resource, key: "brandCode" },
            value: savedData.brandTcName,
            variant: savedData.brandEditable === false ? constants.STYLE.VARIANT.STANDARD : constants.STYLE.VARIANT.OUTLINED,
            color: constants.STYLE.PRIMARY,
            onClickEndAdornmentIconButton: savedData.brandEditable === false ? null : () => handleBrand(),
            clear: savedData.brandEditable === false ? false : true,
            disabled: true
          }}
          handleChange={handleBrandClear}
        />
      </div>
      {dialog && dialog.open &&
        <DialogWrapper dialogInfo={dialog} />
      }
    </>
  );
};