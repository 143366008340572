import * as React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Slider from '@mui/material/Slider'
import FastRewindIcon from '@mui/icons-material/FastRewind';
import FastForwardIcon from '@mui/icons-material/FastForward';

export const ShopAppSoundSlider = ({ soundSpeed, onChangeInput }) => {

  const handleChange = (event, newValue) => {
    onChangeInput('soundSpeed', newValue)
  }

  return (
    <Box sx={{ width: 200 }}>
      <Stack spacing={2} direction="row" sx={{ mb: 1, mt: 5 }} alignItems="center">
        <FastRewindIcon />
        <Slider aria-label="Volume" value={soundSpeed} onChange={handleChange} step={0.1} min={0.5} max={1.5} valueLabelDisplay="on" />
        <FastForwardIcon />
      </Stack>
    </Box>
  )
}
