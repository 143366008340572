import _ from "lodash";
import { SELECT_TAG_CONFIG_OPTIONS } from '../actions/types';
import { constants } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        selectTagConfigOptions: _.cloneDeep(constants.SELECT_TAG_CONFIG_OPTIONS_INPUT)
    }
}

const selectTagConfigOptionsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SELECT_TAG_CONFIG_OPTIONS.SET_SELECT_TAG_CONFIG_OPTIONS_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case SELECT_TAG_CONFIG_OPTIONS.SET_SELECT_TAG_CONFIG_OPTIONS:
        case SELECT_TAG_CONFIG_OPTIONS.SET_SELECT_TAG_CONFIG_OPTIONS_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    selectTagConfigOptions: action.selectTagConfigOptions
                }
            })
        default:
            return state
    }
}

export default selectTagConfigOptionsReducer