import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useStore } from 'react-redux'

import { Card, CardHeader } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'

import { TempTextInput } from './Component/Input/TempTextInput'
import { TempBtn } from './Component/Input/TempBtn'
import { constants } from './Constants/constants'
import { getString } from './helper/util'
import authProvider from './authProvider'

const loginCardClass = makeStyles((theme) => ({
  login: {
    '&.MuiCard-root': {
      padding: '16px',
      width: '500px',
      alignItems: 'center',
    },
  },
}))

export const Login = () => {
  const history = useHistory()
  const store = useStore()
  const loginCardClasses = loginCardClass()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const token = localStorage.getItem(constants.LOCAL_STORAGE_KEY.TOKEN)

  function onChangeInput(name, value) {
    if (name === 'username') {
      setUsername(value)
    } else if (name === 'password') {
      setPassword(value)
    }
  }

  function handleLogin() {
    authProvider.login({ username: username, password: password, store })
  }

  useEffect(() => {
    if (token) {
      history.push('/')
    }
  }, [token]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="login-center-card">
      <Typography variant="h6" sx={{ textAlign: 'center', color: 'white' }}>
        <div style={{ color: 'red' }}>{getString('', '', 'warning', '')} </div> {getString('', '', 'warningContent', '')}
      </Typography>
      <Card className={`${loginCardClasses.login} flex-center`}>
        <CardHeader title={getString('', 'login', 'title')} />
        <TempTextInput
          inputSetting={{
            className: `full-size-input`,
            size: constants.SIZE.SMALL,
            label: { resource: 'login', key: 'username' },
            value: username,
            name: 'username',
          }}
          handleChange={onChangeInput}
        />
        <TempTextInput
          inputSetting={{
            className: `full-size-input`,
            size: constants.SIZE.SMALL,
            label: { resource: 'login', key: 'password' },
            type: constants.TYPE.PASSWORD,
            value: password,
            name: 'password',
          }}
          handleChange={onChangeInput}
        />
        <TempBtn
          btnSetting={{
            className: 'full-size-input',
            onClick: () => {
              handleLogin()
            },
            variant: constants.STYLE.VARIANT.CONTAINED,
            color: constants.STYLE.PRIMARY,
            label: { key: 'login' },
          }}
        />
      </Card>
    </div>
  )
}
