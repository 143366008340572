import { useState, useEffect } from 'react'
import { useSelector, useDispatch, useStore } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import _ from 'lodash'

import { Card } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'

import { ListDisplay } from '../../Component/ListDisplay/ListDisplay'

import { STATUS, constants } from '../../Constants/constants'
import { getList, put } from '../../helper/baseFetch'
import { setBannerList } from '../../actions/bannerAction'
import { generateTableCellToMultiLanguageName, getParamQueryString, getQuery, getQueryParamObject, getString } from '../../helper/util'
import { TempBtn } from '../../Component/Input/TempBtn'
import { setLoading, setMessage } from '../../actions/systemAction'

export const BannerList = ({ editBtn, createBtn, newFilter, newPath, newListTitles, newListMainTitle, idParam, changeUrlQueryString }) => {
  const dispatch = useDispatch()
  const store = useStore()
  const [tempBannerList, setTempBannerList] = useState([])
  const [ready, setReady] = useState(false)
  const [selectedAllCheck, setSelectedAllCheck] = useState(false)
  const [bannerMap, setBannerMap] = useState(new Map())
  const lang = useSelector((state) => state.lang.ui.lang)
  const resourceName = 'Banner'
  const history = useHistory()
  const location = useLocation()
  const filterParams = getQuery()

  const listTitles = newListTitles || [
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: 'type',
      sort: true,
    },
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: 'refCode',
      sort: true,
    },
    {
      key: constants.LIST_DISPLAY_TYPE.IMAGE,
      value: 'enImage',
      sort: false,
    },
    {
      key: constants.LIST_DISPLAY_TYPE.IMAGE,
      value: 'tcImage',
      sort: false,
    },
    {
      key: constants.LIST_DISPLAY_TYPE.IMAGE,
      value: 'scImage',
      sort: false,
    },
    // {
    //   key: constants.LIST_DISPLAY_TYPE.STRING,
    //   value: "name",
    //   sort: false
    // },
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: 'actionType',
      sort: true,
    },
    {
      key: constants.LIST_DISPLAY_TYPE.DATE_TIME,
      value: 'startDisplayDate',
      sort: false,
    },
    {
      key: constants.LIST_DISPLAY_TYPE.DATE_TIME,
      value: 'endDisplayDate',
      sort: false,
    },
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: 'sequence',
      sort: true,
    },
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: 'status',
      sort: true,
    },
  ]
  const listFilters = [
    {
      className: newListTitles ? 'display-none' : 'filter-input',
      label: { resource: resourceName, key: 'type' },
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.BANNER.TYPE,
      name: 'type',
      size: constants.SIZE.SMALL,
    },
    {
      className: 'filter-input',
      label: { resource: resourceName, key: 'refCode' },
      value: '',
      name: 'refCode',
      size: constants.SIZE.SMALL,
    },
    {
      className: 'filter-input',
      label: { resource: resourceName, key: 'actionType' },
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.BANNER.ACTION_TYPE,
      name: 'actionType',
      size: constants.SIZE.SMALL,
    },
    {
      className: 'filter-input',
      label: { resource: resourceName, key: 'status' },
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.BANNER.STATUS,
      name: 'status',
      size: constants.SIZE.SMALL,
    },
  ]

  const [param, setParam] = useState(
    !changeUrlQueryString
      ? {
          path: newPath ? newPath : '',
          page: 0,
          rowsPerPage: 10,
          sort: 'updateTimestamp',
          direction: 'DESC',
          listFilters: _.cloneDeep(listFilters),
          count: 0,
        }
      : getQueryParamObject(
          {
            path: newPath ? newPath : '',
            page: 0,
            rowsPerPage: 10,
            sort: 'updateTimestamp',
            direction: 'DESC',
            listFilters: _.cloneDeep(listFilters),
            count: 0,
          },
          _.cloneDeep(listFilters),
          listTitles
        )
  )

  useEffect(() => {
    if (!changeUrlQueryString) {
      return
    }

    const querySyring = getParamQueryString(
      {
        path: newPath ? newPath : '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: param.count,
      },
      param
    )

    const currentQueryString = window.location.search?.substring(1)

    if (querySyring === currentQueryString) {
      return
    }

    const newParam = getQueryParamObject(
      {
        path: newPath ? newPath : '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: param.count + 1,
      },
      _.cloneDeep(listFilters),
      listTitles
    )

    setParam(newParam)

    const newQuery = getParamQueryString(
      {
        path: newPath ? newPath : '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: 0,
      },
      newParam
    )

    history.replace({ pathname: location.pathname, search: newQuery })
  }, [filterParams])

  function onChangeParam(value) {
    value.count = value.count + 1
    if (!changeUrlQueryString) {
      setParam(value)
      return
    }

    const querySyring = getParamQueryString(
      {
        path: newPath ? newPath : '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: 0,
      },
      value
    )

    history.push({ pathname: location.pathname, search: querySyring })
  }

  function onChangeListFilters(list) {
    let tempParam = _.cloneDeep(param)
    tempParam.listFilters = list
    tempParam.page = 0
    onChangeParam(tempParam)
  }

  function onChangeListSort(sortItem) {
    let tempParam = _.cloneDeep(param)
    tempParam.sort = sortItem
    tempParam.direction = tempParam.direction === 'DESC' ? 'ASC' : 'DESC'
    onChangeParam(tempParam)
  }

  function onChangeListPage(page) {
    let tempParam = _.cloneDeep(param)
    tempParam.page = page
    onChangeParam(tempParam)
  }

  function onChangeListRowsPerPage(rowsPerPage) {
    let tempParam = _.cloneDeep(param)
    tempParam.page = 0
    tempParam.rowsPerPage = rowsPerPage
    onChangeParam(tempParam)
  }

  const addNewEntryToMap = (map, array) => {
    for (const item of array) {
      map.set(item.id, item)
    }
    return map
  }

  const checkAllSelected = (map) => {
    let selectedPerPage = tempBannerList.filter((item) => {
      return map.has(item.id)
    }).length

    let currentPageEntry = tempBannerList.length

    if (selectedPerPage === currentPageEntry && selectedPerPage !== 0) {
      setSelectedAllCheck(true)
    } else {
      setSelectedAllCheck(false)
    }
  }

  const handleMutiSelectMap = (id, dataList, row) => {
    // id = row.id;

    let tempMap = _.cloneDeep(bannerMap)
    if (id === 'all') {
      if (!selectedAllCheck) {
        setBannerMap(addNewEntryToMap(tempMap, tempBannerList))
        //setSelectedAllCheck(true);
        return
      }

      tempBannerList.forEach((item) => tempMap.delete(item.id))
      setBannerMap(tempMap)
      //setSelectedAllCheck(false);
      return
    }

    if (bannerMap.has(id)) {
      tempMap.delete(id)
    } else {
      tempMap.set(id, row)
    }

    setBannerMap(tempMap)
    checkAllSelected(tempMap)
  }

  useEffect(() => {
    dispatch(setBannerList([]))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let tempParam = _.cloneDeep(param)
    const filter = newFilter ? _.cloneDeep(newFilter) : {}
    tempParam.listFilters.forEach((filterItem) => {
      if (filterItem.value !== '' && filterItem.value !== undefined) {
        filter[filterItem.name] = filterItem.value
      }
    })

    getList(
      tempParam.path ? tempParam.path : constants.PATH.BANNER.BANNER,
      {
        sort: { field: tempParam.sort, order: tempParam.direction },
        filter: filter,
        pagination: { page: tempParam.page + 1, perPage: tempParam.rowsPerPage },
      },
      (payload) => {
        payload.map((item) => {
          item.name = generateTableCellToMultiLanguageName(item.tcName, item.enName, item.scName)
          return item
        })
        setTempBannerList(payload)
        setBannerMap(new Map())
        setSelectedAllCheck(false)
        setReady(true)
      },
      undefined,
      store
    )
  }, [param.count]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!bannerMap) {
      return
    }

    checkAllSelected(bannerMap)
  }, [tempBannerList, bannerMap])

  const handleBannerOnSubmit = (status) => {
    const modifiedBannerList = Array.from(bannerMap, ([name, value]) => name)

    dispatch(setLoading(true))

    put(
      constants.PATH.BANNER.UPDATE_BANNER_STATUS,
      { idList: modifiedBannerList, status: status },
      (payload) => {
        dispatch(setLoading(false))

        let tempParam = _.cloneDeep(param)
        tempParam.count++
        setParam(tempParam)
        dispatch(setMessage(getString(lang, '', 'editSuccess')))
      },
      undefined,
      store
    )
  }

  return (
    ready && (
      <>
        <Card className="card-wrapper overflow">
          <ListDisplay
            newListMainTitle={newListMainTitle}
            page={param.page}
            rowsPerPage={param.rowsPerPage}
            onChangeListSort={onChangeListSort}
            resource={resourceName}
            dataList={tempBannerList}
            sort={param.sort}
            direction={param.direction}
            listTitles={listTitles}
            listFilters={param.listFilters}
            idParam={idParam}
            onChangeListPage={onChangeListPage}
            onChangeListRowsPerPage={onChangeListRowsPerPage}
            editBtn={editBtn}
            createBtn={createBtn}
            onChangeListFilters={onChangeListFilters}
            redirectPath={'/' + (newPath ? newPath : constants.PATH.BANNER.BANNER) + '/'}
            mutipleSelect={true}
            onMultiSelectList={handleMutiSelectMap}
            selectMap={bannerMap}
            selectedAllCheck={selectedAllCheck}
            idName="id"
            dialogFooterBtn={
              bannerMap?.size > 0 ? (
                <div className="get-push-card-wrapper">
                  <TempBtn
                    btnSetting={{
                      className: 'get-push-card-cancel-btn margin-right-16',
                      variant: constants.STYLE.VARIANT.CONTAINED,
                      color: constants.STYLE.PRIMARY,
                      onClick: () => handleBannerOnSubmit(STATUS.DELETE),
                      icon: <DeleteIcon />,
                      label: { resource: resourceName, key: 'deactivate' },
                    }}
                  ></TempBtn>
                  <TempBtn
                    btnSetting={{
                      className: 'get-push-card-cancel-btn margin-right-16',
                      variant: constants.STYLE.VARIANT.CONTAINED,
                      color: constants.STYLE.PRIMARY,
                      onClick: () => handleBannerOnSubmit(STATUS.PENDING),
                      icon: <VisibilityOffIcon />,
                      label: { resource: resourceName, key: 'pending' },
                    }}
                  ></TempBtn>
                  <TempBtn
                    btnSetting={{
                      className: 'get-push-card-cancel-btn',
                      variant: constants.STYLE.VARIANT.CONTAINED,
                      color: constants.STYLE.PRIMARY,
                      onClick: () => handleBannerOnSubmit(STATUS.ACTIVE),
                      icon: <VisibilityIcon />,
                      label: { resource: resourceName, key: 'active' },
                    }}
                  ></TempBtn>
                </div>
              ) : (
                <></>
              )
            }
          />
        </Card>
      </>
    )
  )
}
