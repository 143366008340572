import { useState, useEffect } from "react";
import { useStore } from "react-redux";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { Card } from '@mui/material';

import { constants } from "../../../../../../Constants/constants";
import { ListDisplay } from "../../../../../ListDisplay/ListDisplay";
import { getList } from "../../../../../../helper/baseFetch";

export const SystemUserList = ({ newFilter,  editBtn, createBtn, btnList, dialogFooterBtn}) =>{

  const resource = "systemUser";
  const store = useStore();
  const params = useParams();
  const [systemUserList, setSystemUserList] = useState([]);
  const [ready, setReady] = useState(false);

  const listTitles = [
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "username",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "userRole",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "restUrlId",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "privilegeAttribute",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "status",
      sort: true
    }
  ];

  const listFilters = [
    // {
    //   className: "filter-input",
    //   label: { resource: resource, key: "username" },
    //   clear: true,
    //   value: '',
    //   name: "username",
    //   size: constants.SIZE.SMALL,
    // },
    // {
    //   className: "filter-input",
    //   label: { resource: resource, key: "status" },
    //   clear: true,
    //   type: constants.TYPE.SELECT,
    //   selectValue: constants.CHOICES.SYSTEM_USER.STATUS_DISPLAY,
    //   value: '',
    //   name: "status",
    //   size: constants.SIZE.SMALL
    // },
  ];

  const [param, setParam] = useState({
    path: "",
    page: 0,
    rowsPerPage: 10,
    sort: 'update_timestamp',
    direction: 'DESC',
    listFilters: listFilters,
    count: 0,
  })

  function onChangeParam(value) {
    value.count = value.count + 1;
    setParam(value);
  }

  function onChangeListFilters(list) {
    let tempParam = _.cloneDeep(param);
    tempParam.listFilters = list;
    tempParam.page = 0;
    onChangeParam(tempParam);
  }

  function onChangeListSort(sortItem) {
    let tempParam = _.cloneDeep(param);
    tempParam.sort = sortItem;
    tempParam.direction = tempParam.direction === "DESC" ? "ASC" : "DESC";
    onChangeParam(tempParam);
  }

  function onChangeListPage(page) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = page;
    onChangeParam(tempParam);
  }

  function onChangeListRowsPerPage(rowsPerPage) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = 0;
    tempParam.rowsPerPage = rowsPerPage;
    onChangeParam(tempParam);
  }

  useEffect(() => {
    let tempParam = _.cloneDeep(param);
    const filter = newFilter ? _.cloneDeep(newFilter) : {};
    filter.systemGroupId = params.id;

    getList(constants.PATH.SYSTEM_GROUP.SYSTEM_USER, { sort: { field: tempParam.sort, order: tempParam.direction }, filter: filter, pagination: { page: tempParam.page + 1, perPage: tempParam.rowsPerPage } }, (payload) => {
      setSystemUserList(payload);
      setReady(true)
    }, undefined, store);

  }, [param.count]); // eslint-disable-line react-hooks/exhaustive-deps  

  return (
    ready &&
    <Card className="card-wrapper overflow">
      <ListDisplay
        page={param.page}
        rowsPerPage={param.rowsPerPage}
        onChangeListSort={onChangeListSort}
        resource={resource}
        dataList={systemUserList}
        sort={param.sort}
        direction={param.direction}
        listTitles={listTitles}
        listFilters={param.listFilters}
        onChangeListPage={onChangeListPage}
        onChangeListRowsPerPage={onChangeListRowsPerPage}
        onChangeListFilters={onChangeListFilters}
        editBtn={editBtn}
        createBtn={createBtn}
        dialogFooterBtn={dialogFooterBtn}
        redirectPath={"/" + constants.PATH.SYSTEM_GROUP.SYSTEM_USER + "/"}
      />
    </Card>
  )
}