import { Card, CardHeader } from '@mui/material';

const dashboard = () => (
    <>
        <Card>
            <CardHeader title="Welcome to the administration" />
        </Card>
    </>
);

export default dashboard;