
import { constants } from '../../Constants/constants';
import { useSelector } from "react-redux";

import { getString } from "../../helper/util";

export const TempCheckBox = ({ checkBoxSetting, index, handleChange }) => {
  const lang = useSelector(state => state.lang.ui.lang);
  const theme = useSelector(state => state.theme.ui.theme);

  return (
    <div className={checkBoxSetting.className} onClick={() => handleChange(checkBoxSetting.name, checkBoxSetting.value, index, checkBoxSetting.key)}>
      <input
        className="cursor"
        id={checkBoxSetting.name}
        name={checkBoxSetting.name}
        type={constants.TYPE.CHECKBOX}
        checked={checkBoxSetting.value}
        value="Edit"
        onChange={()=>{}}
      />
      <label className="cursor">{getString(lang, checkBoxSetting.label.resource ? checkBoxSetting.label.resource : "", checkBoxSetting.label.key ? checkBoxSetting.label.key : "", theme)}</label>
    </div>
  )
};