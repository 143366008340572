import { useState, useEffect } from "react";
import { useStore } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { makeStyles } from '@mui/styles';
import { Card } from '@mui/material';

import { constants } from '../../Constants/constants';
import { post } from "../../helper/baseFetch";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempBtn } from "../../Component/Input/TempBtn";
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { DialogWrapper } from "../../Component/DialogWrapper";
import RestaurantBlackoutValidation from "../../validations/restaurantBlackoutValidation";
import { restaurantBlackoutValidationConstants } from '../../Constants/validationConstants';
import useRestaurant from "../../hooks/Restaurant/useRestaurant";

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const RestaurantBlackoutCreate = () => {
  const store = useStore();
  const history = useHistory();
  const params = useParams();
  const [clear, setClear] = useState(false);
  const [ready, setReady] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const resource = "RestaurantBlackout";
  const [blackoutList, setBlackoutList] = useState([]);
  const helperTextStylesClasses = helperTextStyles();
  const [, setErrorVersion] = useState(RestaurantBlackoutValidation.getVersion());

  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.RESTAURANT_BLACKOUT_TAB.INFO
    },
  ]

  const AddBlackoutPeriod = () => {
      let tempBlackoutPeriod = _.cloneDeep(blackoutList);
      tempBlackoutPeriod.push({...constants.RESTAURANT_BLACKOUT_INPUT, refCode: params.restUrlId, temptId: uuidv4()});
      setBlackoutList(tempBlackoutPeriod);
  }

  const handleDeleteBlackoutPeriod = (newBlackoutPeriodIndex) => {
    let tempBlackoutPeriodList = _.cloneDeep(blackoutList);
    tempBlackoutPeriodList.splice(newBlackoutPeriodIndex, 1);
    setBlackoutList(tempBlackoutPeriodList);
  }

  const content = [
    {
      tab: <>
      <TempBtn btnSetting={{
          className: "button-on-top",
          variant: constants.STYLE.VARIANT.CONTAINED,
          color: constants.STYLE.PRIMARY,
          onClick: AddBlackoutPeriod,
          label: { resource: "productCreateProductList", key: "addBlackoutPeriod" },
          icon: <AddIcon />,
        }} />
        {
          blackoutList.map((item, blackoutPeriodListIndex) => (
            <Card key={blackoutPeriodListIndex} className="image-list">
              {
                <>
                <div className="deleteIcon">
                  <TempBtn btnSetting={{
                    className: "edit-create-btn",
                    label: { key: "deleteBtn" },
                    variant: constants.STYLE.VARIANT.CONTAINED,
                    color: constants.STYLE.PRIMARY,
                    onClick: () => handleDeleteBlackoutPeriod(blackoutPeriodListIndex),
                    icon: <DeleteIcon />,
                  }} />
                </div>
                <TempTextInput
                  inputSetting={{
                    className: "edit-create-input",
                    size: constants.SIZE.SMALL,
                    label: { resource: resource, key: "type" },
                    value: item.type,
                    name: "type",
                    disabled: true,
                    variant: constants.STYLE.VARIANT.STANDARD,
                    isEndOfLine: true,
                    required: true,
                    helperTextIcon: true,
                    helperText: RestaurantBlackoutValidation.getErrorMessages(`${item.temptId}-${restaurantBlackoutValidationConstants.KEY_TYPE}`),
                    FormHelperTextProps: { className: helperTextStylesClasses.root },
                  }}
                  handleChange={onChangeInput}
                  index={blackoutPeriodListIndex}
                />
                <TempTextInput
                  inputSetting={{
                    className: "edit-create-input",
                    size: constants.SIZE.SMALL,
                    label: { resource: resource, key: "refCode" },
                    value: item.refCode,
                    name: "refCode",
                    disabled: true,
                    variant: constants.STYLE.VARIANT.STANDARD,
                    required: true,
                    helperTextIcon: true,
                    helperText: RestaurantBlackoutValidation.getErrorMessages(`${item.temptId}-${restaurantBlackoutValidationConstants.KEY_REFCODE}`),
                    FormHelperTextProps: { className: helperTextStylesClasses.root },
                  }}
                  handleChange={onChangeInput}
                  index={blackoutPeriodListIndex}
                />
                <div></div>
                <TempTextInput
                  inputSetting={{
                    className: "edit-create-input",
                    type: constants.TYPE.DATETIME_LOCAL,
                    step: 1,
                    size: constants.SIZE.SMALL,
                    name: "startTimestamp",
                    label: { resource: resource, key: "startTimestamp" },
                    value: item.startTimestamp,
                    helperTextIcon: true,
                    isEndOfLine: false,
                    helperText: RestaurantBlackoutValidation.getErrorMessages(`${item.temptId}-${restaurantBlackoutValidationConstants.KEY_START_TIMESTAMP}`),
                    FormHelperTextProps: { className: helperTextStylesClasses.root },
                  }}
                  handleChange={onChangeInput}
                  index={blackoutPeriodListIndex}
                />
                <TempTextInput
                  inputSetting={{
                    className: "edit-create-input",
                    type: constants.TYPE.DATETIME_LOCAL,
                    step: 1,
                    size: constants.SIZE.SMALL,
                    name: "endTimestamp",
                    label: { resource: resource, key: "endTimestamp" },
                    value: item.endTimestamp,
                    helperTextIcon: true,
                    isEndOfLine: true,
                    helperText: RestaurantBlackoutValidation.getErrorMessages(`${item.temptId}-${restaurantBlackoutValidationConstants.KEY_END_TIMESTAMP}`),
                    FormHelperTextProps: { className: helperTextStylesClasses.root },
                  }}
                  handleChange={onChangeInput}
                  index={blackoutPeriodListIndex}
                />
                </>
              }
            </Card>
          ))
        }
      </>,
      btn: true
    }
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  const onClickBackToCreate = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  }

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT + "/" + params.restUrlId + "/" + constants.RESTAURANT_TAB.PRODUCT + "/" + constants.RESTAURANT_PRODUCT_SUB_TAB.BLACKOUT);
  };

  function onChangeInput(name, e, blackoutPeriodListTypeIndex) {
    let temptBlackoutList = _.cloneDeep(blackoutList);
    let temptBlackoutPeriod = blackoutList[blackoutPeriodListTypeIndex];
    switch (name) {
      case "startTimestamp":
      case "endTimestamp":
        const date = Number(moment(e).format("x"));
        temptBlackoutPeriod[name] = date;
        break;
      default:
        temptBlackoutPeriod[name] = e;
    }

    temptBlackoutList[blackoutPeriodListTypeIndex] = temptBlackoutPeriod;
    setBlackoutList(temptBlackoutList);

  }

  function onClickSave() {
    let tempSavedRestaurantBlackoutList = _.cloneDeep(blackoutList);
    RestaurantBlackoutValidation.validateCreateRestaurantBlackout(tempSavedRestaurantBlackoutList);
    if (RestaurantBlackoutValidation.isValid()) {
      post(constants.PATH.SHOP.RESTAURANT_BLACKOUT, tempSavedRestaurantBlackoutList, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToCreate,
          dialogContentText: { key: "createSuccess" },
          dialogActions: [
            { label: { key: "backCreate" }, onClick: onClickBackToCreate, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      RestaurantBlackoutValidation.resetErrorMessages();
    } else {
      setErrorVersion(RestaurantBlackoutValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    setBlackoutList([]);
    setClear(false);
    setReady(true);
    RestaurantBlackoutValidation.resetErrorMessages();
  }, [clear === true]); // eslint-disable-line react-hooks/exhaustive-deps

  
  const { getRestaurantIdAndName } = useRestaurant(params.restUrlId)
  const mapCode = getRestaurantIdAndName()

  return (
    ready &&
    <>
      <EditCreateDisplay
        code=""
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        mapName={{ resource: "restaurant", key: "restUrlId" }}
        mapCode={mapCode}
        display="create"
      />
      {dialog && dialog.open &&
        <DialogWrapper dialogInfo={dialog} />
      }
    </>
  )
};