import { RESTAURANT_URL_TYPE, URL_MAP_ACTION_TYPE } from '../../Constants/constants'

export function getUrlObjUrl(e, urlObj, inputObj) {
  let newUrlValue = ''
  const customField = e.customField || ''

  if (urlObj.agentType === 'weChat') {
    newUrlValue = `${process.env.REACT_APP_QRCODE_LINK_WECHAT_START}${customField}${inputObj.from ? '&from=' + inputObj.from : ''}`
  } else {
    switch (urlObj.actionType) {
      case URL_MAP_ACTION_TYPE.WEB_VIEW:
      case URL_MAP_ACTION_TYPE.VIDEO:
        newUrlValue = `${process.env.REACT_APP_QRCODE_LINK_START}web=action=${customField}${e.type ? '&type=' + e.type : ''}${
          e.title ? '&title=' + e.title : ''
        }${inputObj.from ? '&from=' + inputObj.from : ''}`
        break
      case URL_MAP_ACTION_TYPE.SPRINGBOARD:
        newUrlValue = process.env.REACT_APP_SPRINGBOARD + customField
        break
      case URL_MAP_ACTION_TYPE.SEARCH:
        newUrlValue = `${process.env.REACT_APP_QRCODE_LINK_START}search=${e.name ? 'name=' + e.name : ''}${
          e.name && e.query ? '&query=' + e.query : !e.name && e.query ? 'query=' + e.query : ''
        }${inputObj.from ? '&from=' + inputObj.from : ''}`
        break
      case URL_MAP_ACTION_TYPE.LINK:
        newUrlValue = `${process.env.REACT_APP_QRCODE_LINK_START}link=action=${customField}${inputObj.from ? '&from=' + inputObj.from : ''}`
        break
      case URL_MAP_ACTION_TYPE.PIXEL:
        newUrlValue = `${process.env.REACT_APP_QRCODE_LINK_START}pixel=action=${customField}${e.name ? '&name=' + e.name : ''}${
          e.haveRef || e.haveRef === false ? '&haveRef=' + e.haveRef : ''
        }${inputObj.from ? '&from=' + inputObj.from : ''}`
        break
      case URL_MAP_ACTION_TYPE.PRODUCT_CATEGORY:
        newUrlValue = `${process.env.REACT_APP_QRCODE_LINK_START}product=categoryCode=${customField}${inputObj.from ? '&from=' + inputObj.from : ''}`
        break
      case URL_MAP_ACTION_TYPE.PRODUCT:
        newUrlValue = `${process.env.REACT_APP_QRCODE_LINK_START}product=productCode=${customField}${
          e.productParent ? (e.productParent === 'BRAND' ? '&brandCode=' : e.productParent === 'CATEGORY' ? '&categoryCode=' : '') : ''
        }${e.productParent === 'BRAND' && e.brandCode ? e.brandCode + '' : ''}${
          e.productParent === 'CATEGORY' && e.categoryCode ? e.categoryCode + '' : ''
        }${inputObj.from ? '&from=' + inputObj.from : ''}`
        break
      case URL_MAP_ACTION_TYPE.E_COUPON:
        newUrlValue = `${process.env.REACT_APP_QRCODE_LINK_START}e-coupon=ecouponCode=${customField}${
          e.productParent ? (e.productParent === 'BRAND' ? '&brandCode=' : e.productParent === 'CATEGORY' ? '&categoryCode=' : '') : ''
        }${e.productParent === 'BRAND' && e.brandCode ? e.brandCode + '' : ''}${
          e.productParent === 'CATEGORY' && e.categoryCode ? e.categoryCode + '' : ''
        }${inputObj.from ? '&from=' + inputObj.from : ''}`
        break
      case URL_MAP_ACTION_TYPE.E_COUPON_CATEGORY:
        newUrlValue = `${process.env.REACT_APP_QRCODE_LINK_START}e-coupon=categoryCode=${customField}${inputObj.from ? '&from=' + inputObj.from : ''}`
        break
      case URL_MAP_ACTION_TYPE.PRODUCT_BRAND:
        newUrlValue = `${process.env.REACT_APP_QRCODE_LINK_START}product=brandCode=${customField}${inputObj.from ? '&from=' + inputObj.from : ''}`
        break
      case URL_MAP_ACTION_TYPE.E_COUPON_BRAND:
        newUrlValue = `${process.env.REACT_APP_QRCODE_LINK_START}e-coupon=brandCode=${customField}${inputObj.from ? '&from=' + inputObj.from : ''}`
        break
      case URL_MAP_ACTION_TYPE.CATEGORY_PRODUCT:
      case URL_MAP_ACTION_TYPE.CATEGORY_PRODUCT_MAP:
        newUrlValue = `${process.env.REACT_APP_QRCODE_LINK_START}product=categoryCode=${customField}&productCode=${
          e.catProductCode ? e.catProductCode : ''
        }${inputObj.from ? '&from=' + inputObj.from : ''}`
        break
      case URL_MAP_ACTION_TYPE.BRAND_PRODUCT:
      case URL_MAP_ACTION_TYPE.BRAND_PRODUCT_MAP:
        newUrlValue = `${process.env.REACT_APP_QRCODE_LINK_START}product=brandCode=${customField}&productCode=${
          e.brandProductCode ? e.brandProductCode : ''
        }${inputObj.from ? '&from=' + inputObj.from : ''}`
        break
      case URL_MAP_ACTION_TYPE.FOLDER:
        newUrlValue = `${process.env.REACT_APP_QRCODE_LINK_START}redirect_folder=folderCode=${customField}${
          e.folderType ? '&folderType=' + e.folderType : ''
        }${inputObj.from ? '&from=' + inputObj.from : ''}`
        break
      case URL_MAP_ACTION_TYPE.REST:
        const realRestUrlId = inputObj.realRestUrlId || ''
        if (urlObj.restaurantUrlType === RESTAURANT_URL_TYPE.UNIVERSAL) {
          newUrlValue = `${process.env.REACT_APP_GULU_WEB_BASE}app/shop/${realRestUrlId}`
        } else if (urlObj.restaurantUrlType === RESTAURANT_URL_TYPE.DOWNLOAD_HTML) {
          newUrlValue = `${process.env.REACT_APP_QRCODE_LINK_START}redirect_rest=restUrlId=${realRestUrlId}${
            inputObj.from ? '&from=' + inputObj.from : ''
          }${e.action ? '&action=' + e.action : ''}`
        } else {
          newUrlValue = ''
        }
        break
      case URL_MAP_ACTION_TYPE.MY_TICKET:
        newUrlValue = `${process.env.REACT_APP_QRCODE_LINK_START}myticket=refId=${customField}&serviceType=${e.serviceType ? e.serviceType : ''}${
          e.type ? '&type=' + e.type : ''
        }${inputObj.from ? '&from=' + inputObj.from : ''}`
        break
      case URL_MAP_ACTION_TYPE.TRANSFORM_TICKET_FROM_PAPER:
        newUrlValue = `${process.env.REACT_APP_QRCODE_LINK_START}transformticketfrompaper=ticketId=${customField}${
          inputObj.from ? '&from=' + inputObj.from : ''
        }`
        break
      case URL_MAP_ACTION_TYPE.TRANSFORM_ECOUPON_FROM_PAPER:
        newUrlValue = `${process.env.REACT_APP_QRCODE_LINK_START}transformecouponfrompaper=infoId=${customField}${
          inputObj.from ? '&from=' + inputObj.from : ''
        }`
        break
      case URL_MAP_ACTION_TYPE.EXPRESS_TICKET_VOUCHER:
        newUrlValue = `${process.env.REACT_APP_QRCODE_LINK_START}expressticketvoucher=groupCode=${customField}${
          inputObj.from ? '&from=' + inputObj.from : ''
        }`
        break
      case URL_MAP_ACTION_TYPE.SHARE:
        newUrlValue = `${process.env.REACT_APP_QRCODE_LINK_START}share=refId=${customField}&serviceType=${e.serviceType ? e.serviceType : ''}${
          inputObj.from ? '&from=' + inputObj.from : ''
        }`
        break
      case URL_MAP_ACTION_TYPE.GIFT:
        newUrlValue = `${process.env.REACT_APP_QRCODE_LINK_START}gift=refId=${customField}&code=${e.code ? e.code : ''}&serviceType=${
          e.serviceType ? e.serviceType : ''
        }${e.type ? '&type=' + e.type : ''}${inputObj.from ? '&from=' + inputObj.from : ''}`
        break
      case URL_MAP_ACTION_TYPE.BOOKMARK:
        newUrlValue = `${process.env.REACT_APP_QRCODE_LINK_START}bookmark=${inputObj.from ? 'from=' + inputObj.from : ''}`
        break
      case URL_MAP_ACTION_TYPE.SEARCH_NEAR_BY:
        newUrlValue = `${process.env.REACT_APP_QRCODE_LINK_START}searchnearby=${inputObj.from ? 'from=' + inputObj.from : ''}`
        break
      case URL_MAP_ACTION_TYPE.SCAN_QRCODE:
        newUrlValue = `${process.env.REACT_APP_QRCODE_LINK_START}scanqrcode=${inputObj.from ? 'from=' + inputObj.from : ''}`
        break
      case URL_MAP_ACTION_TYPE.LANDING:
        newUrlValue = `${process.env.REACT_APP_QRCODE_LINK_START}landing=${inputObj.from ? 'from=' + inputObj.from : ''}`
        break
      case URL_MAP_ACTION_TYPE.DOWNLOAD:
        newUrlValue = `${process.env.REACT_APP_QRCODE_LINK_START}download=${inputObj.from ? 'from=' + inputObj.from : ''}`
        break
      default:
        newUrlValue = ''
        break
    }
  }
  return newUrlValue
}
