import { useState, useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { constants as co } from '../../../Constants/constants'
import { getOne, put } from '../../../helper/baseFetch'
import { ArrowBack, LowPriority } from '@mui/icons-material'
import { getDefaultBottomBtnList } from '../../../Component/EditCreateDisplay/EditUtil'
import { setRestaurantMap, setRestaurantMapValue } from '../../../actions/landmarkRestaurantMapAction'
import { EditCreateDisplay } from '../../../Component/EditCreateDisplay/EditCreateDisplay'
import { DialogWrapper } from '../../../Component/DialogWrapper'
import useLandmark from '../../../hooks/Landmark/useLandmark'
import RestaurantCard from './RestaurantCard'

export default function RestaurantMapEdit() {
  const resource = 'RestaurantMap'

  const dispatch = useDispatch()
  const history = useHistory()
  const store = useStore()
  const { id } = useParams()
  const [clear, setClear] = useState(false)
  const [dialogInfo, setDialogInfo] = useState({
    open: false,
  })
  const savedData = useSelector((state) => state.landmarkRestaurantMap.ui.restaurantMap)
  const [ready, setReady] = useState(false)

  const { getLandmarkIdAndName, getLandmark } = useLandmark()
  const mapCode = getLandmarkIdAndName()
  const { id: landmarkId } = getLandmark()

  let setDispatch = (counter) => dispatch(setRestaurantMap(counter)),
    setDispatchValue = (fieldName, originalObj, key, value) => dispatch(setRestaurantMapValue(fieldName, originalObj, key, value)),
    apiPath = co.PATH.SHOP.RESTAURANT_LANDMARK_RESTAURANT_MAP + '/' + id,
    listPath = '/' + co.PATH.SHOP.RESTAURANT_LANDMARK + '/' + landmarkId + '/' + co.LANDMARK_TAB.RESTAURANT_LIST

  const onCloseDialog = () => {
    setDialogInfo({
      open: false,
    })
  }

  const onChangeInput = (name, e) => {
    setDispatchValue(name, savedData, '', e)
  }

  const onRedirectToCreateEdit = () => {
    onCloseDialog()
    onClickClear()
  }

  const onRedirectToList = () => {
    setDialogInfo({
      open: false,
    })
    history.push(listPath)
  }

  const onClickSave = () => {
    put(
      apiPath,
      {
        status: savedData.status,
      },
      () => {
        setDialogInfo({
          open: true,
          onClose: onRedirectToCreateEdit,
          dialogContentText: { key: 'editSuccess' },
          maxWidth: 'md',
          dialogActions: [
            { label: { key: 'backEdit' }, onClick: onRedirectToCreateEdit, icon: <ArrowBack /> },
            {
              label: { key: 'backList' },
              onClick: onRedirectToList,
              icon: <LowPriority />,
            },
          ],
        })
        setClear(false)
      },
      undefined,
      store
    )
  }

  const onClickClear = () => {
    setClear(true)
  }

  const content = [
    {
      tab: <RestaurantCard restaurant={savedData} contentType="edit" onChangeInput={onChangeInput} />,
      btn: true,
    },
  ]

  useEffect(() => {
    getOne(
      apiPath,
      undefined,
      (payload) => {
        setDispatch({
          restUrlId: payload.restUrlId,
          enName: payload.restaurantEnName,
          tcName: payload.restaurantTcName,
          scName: payload.restaurantScName,
          status: payload.restaurantStatus,
        })
        setClear(false)
        setReady(true)
      },
      undefined,
      store
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear === true])

  return (
    <>
      {ready && (
        <EditCreateDisplay
          resource={resource}
          content={content}
          display="edit"
          mapName={{ resource: resource, key: 'landmarkId' }}
          mapCode={mapCode}
          bottomBtns={getDefaultBottomBtnList(onClickSave, onClickClear)}
        />
      )}
      {dialogInfo.open && <DialogWrapper dialogInfo={dialogInfo} />}
    </>
  )
}
