
import { useState, useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";

import { constants } from '../../../Constants/constants';
import { getList } from "../../../helper/baseFetch";
import { setMerchantShopValue } from "../../../actions/merchantShopCreateAction";
import { helperTextStyles } from "../../../helper/util";
import { EditCreateDisplay } from '../../EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Input/TempTextInput";
import { TempCustomCheckBox } from "../../Custom/TempCustomCheckBox";
import { TempAutoSelectInput } from "../../Input/TempAutoSelectInput";

import MerchantShopCreateValidation from "../../../validations/merchantShopCreateValidation";
import { merchantShopValidationConstants } from "../../../Constants/validationConstants";

export const ServiceAndPlan = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const [ready, setReady] = useState(false);
  const [paymentRecordOptions, setPaymentRecordOptions] = useState([]);
  const resource = "MerchantDraft";
  const savedMerchantShop = useSelector(state => state.merchantShop.ui.merchantShop);
  const helperTextStylesClasses = helperTextStyles();

  const tempRestaurantEditList = [
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "servicePlan" },
      value: savedMerchantShop.draft.planCode,
      type: constants.TYPE.SELECT,
      selectValue: paymentRecordOptions,
      name: "planCode",
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: MerchantShopCreateValidation.getErrorMessages(merchantShopValidationConstants.KEY_PLAN_CODE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
  ]
  const content = [
    {
      tab: <>{tempRestaurantEditList.map((inputSetting, index) => {
        let content = "";
        switch (inputSetting.type) {
          case constants.TYPE.SELECT:
            content =
              <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
            break;
          default:
            content = <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />;
        }
        return <div className="edit-display-wrapper" key={index}>
          {inputSetting.custom && <TempCustomCheckBox className="customize-btn" value={inputSetting.customValue} customName={inputSetting.customName} />}
          {content}
          {inputSetting.isEndOfLine && <div></div>}
        </div>
      })}</>,
      btn: false
    },
  ]

  function onChangeInput(name, e) {
    dispatch(setMerchantShopValue("DRAFT", savedMerchantShop, name, e));
  }

  useEffect(() => {
    setReady(false)
    getList(constants.PATH.MERCHANT.PAYMENT_RECORD_OPTIONS, undefined, (payload) => {
      let newPaymentRecordOptions = [];
      for (let i = 0; i < payload.servicePlanList.length; i++) {
        newPaymentRecordOptions.push({ value: payload.servicePlanList[i].planCode, name: payload.servicePlanList[i].name })
      }
      setPaymentRecordOptions(newPaymentRecordOptions)
      setReady(true)
    }, undefined, store);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    ready &&
    <>
      <EditCreateDisplay
        code=""
        resource={resource}
        tabs={false}
        content={content}
        onChangeInput={onChangeInput}
        // bottomBtns={bottomBtns}
        display="none"
      />
    </>
  )
};
