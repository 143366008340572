import { getActionParameters } from "../../Component/ActionParameters/getActionParameters";
import { setParametersInputBox } from "../../Component/Banner/setParametersInputBox";
import _ from "lodash";

export function handleParameters(tempsavedBannerValue, actionParametersLang) {
  let tempBanner = _.cloneDeep(tempsavedBannerValue);
  if (tempBanner) {
    tempBanner = getActionParameters(tempBanner, actionParametersLang);
    if (tempBanner.length === 0) {
      let tempOtherParameters = JSON.parse(tempBanner.actionParameters);
      delete tempOtherParameters.url;
      const objectArray = Object.entries(tempOtherParameters);
      objectArray.forEach(([key, value]) => {
        tempBanner.otherParameters.push(
          {
            key: key,
            value: value
          }
        );
      });
    }
  }
  return tempBanner;
}

export function handleParametersInputBox(value) {
  if (value) {
    value = setParametersInputBox(value);
  }
  return value;
}