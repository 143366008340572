import { useState } from "react";

import { Drawer } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

import { BrandList } from "../../Page/Brand/BrandList";
import { BrandCodeCreateBtn } from "./BrandCodeCreateBtn";
import { constants } from '../../Constants/constants';
import { TempTextInput } from "../Input/TempTextInput";
import { TempBtn } from "../Input/TempBtn";

import "../../styles/getBrandCode.scss";

export const GetProductBrandCode = ({ savedData, sendBrandCode, clearBrandCode, disableInput, hiddenInput }) => {
  const [showBrandTextPanel, setShowBrandTextPanel] = useState(false);
  const resource = "restaurant";

  const handleBrandTextClear = () => {
    clearBrandCode();
    setShowBrandTextPanel(false);
  };

  const handleBrandTextClick = () => {
    setShowBrandTextPanel(true);
  };

  const handleBrandTextCloseClick = () => {
    setShowBrandTextPanel(false);
  };

  const onClickListRow = (data) => {
    setShowBrandTextPanel(false);
    sendBrandCode(data);
  }

  return (
    <>
      <TempTextInput
        inputSetting={{
          className: "edit-create-input",
          variant: disableInput ? constants.STYLE.VARIANT.STANDARD : null,
          size: constants.SIZE.SMALL,
          label: {resource: resource, key: "brandCode"},
          value: savedData.brandTcName,
          color: constants.STYLE.PRIMARY,
          onClickEndAdornmentIconButton: disableInput ? null :  handleBrandTextClick,
          clear: disableInput ? false : true,
          disabled: true,
        }}
        handleChange={handleBrandTextClear}
      />
      {!hiddenInput &&
        <BrandCodeCreateBtn disableInput={disableInput} />
      }
      <Drawer
        classes={{ paper: "drawer-margin-left" }}
        anchor="right"
        open={showBrandTextPanel}
        onClose={handleBrandTextCloseClick}
      >
        <BrandList
          editBtn={false}
          createBtn={false}
          onClickListRow={onClickListRow} 
          dialogFooterBtn={<>
            <TempBtn btnSetting={{
              variant: constants.STYLE.VARIANT.CONTAINED,
              color: constants.STYLE.PRIMARY,
              onClick: handleBrandTextCloseClick,
              icon: <ClearIcon />,
              label: {key: "close"},
            }} />
          </>
          }/>
      </Drawer>
    </>
  );
};