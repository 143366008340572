import _ from "lodash";
import { CONTACT_US } from '../actions/types';
import { constants } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        contactUs: _.cloneDeep(constants.CONTACT_US_INPUT),
    }
}

const contactUsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONTACT_US.SET_CONTACT_US_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case CONTACT_US.SET_CONTACT_US:
        case CONTACT_US.SET_CONTACT_US_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    contactUs: action.contactUs
                }
            })
        default:
            return state
    }
}

export default contactUsReducer