import { useState, useEffect } from 'react'
import { useStore } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import _ from 'lodash'

import { Card } from '@mui/material'

import { ListDisplay } from '../../Component/ListDisplay/ListDisplay'

import { constants } from '../../Constants/constants'
import { getList } from '../../helper/baseFetch'
import { addSpace, generateTableCellToMultiLanguageName, getParamQueryString, getQuery, getQueryParamObject } from '../../helper/util'

export const EmailTemplateList = ({ changeUrlQueryString }) => {
  const store = useStore()
  const [emailTemplateList, setEmailTemplateList] = useState([])
  const [ready, setReady] = useState(false)
  const [clear, setClear] = useState(false)
  const resource = 'EmailTemplate'
  const history = useHistory()
  const location = useLocation()
  const filterParams = getQuery()

  const emailTemplateTitleList = [
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: 'restUrlId',
      sort: true,
    },
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: 'type',
      sort: true,
    },
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: 'subject',
      sort: false,
      minSize: true,
    },
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: 'receiver',
      sort: true,
    },
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: 'status',
      sort: true,
    },
  ]
  const listFilters = [
    {
      className: 'filter-input',
      label: { resource: resource, key: 'restUrlId' },
      clear: true,
      value: '',
      name: 'restUrlId',
      size: constants.SIZE.SMALL,
    },
    {
      className: 'filter-input',
      label: { resource: resource, key: 'type' },
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.EMAIL_TEMPLATE.TYPE,
      name: 'type',
      size: constants.SIZE.SMALL,
    },
    {
      className: 'filter-input',
      label: { resource: resource, key: 'subject' },
      clear: false,
      value: '',
      name: 'subject',
      size: constants.SIZE.SMALL,
    },
    {
      className: 'filter-input',
      label: { resource: resource, key: 'receiver' },
      clear: true,
      value: '',
      name: 'receiver',
      size: constants.SIZE.SMALL,
    },
    {
      className: 'filter-input',
      label: { resource: resource, key: 'status' },
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.EMAIL_TEMPLATE.STATUS,
      name: 'status',
      size: constants.SIZE.SMALL,
    },
  ]

  const [param, setParam] = useState(
    !changeUrlQueryString
      ? {
          path: '',
          page: 0,
          rowsPerPage: 10,
          sort: 'updateTimestamp',
          direction: 'DESC',
          listFilters: _.cloneDeep(listFilters),
          count: 0,
        }
      : getQueryParamObject(
          {
            path: '',
            page: 0,
            rowsPerPage: 10,
            sort: 'updateTimestamp',
            direction: 'DESC',
            listFilters: _.cloneDeep(listFilters),
            count: 0,
          },
          _.cloneDeep(listFilters),
          emailTemplateTitleList
        )
  )

  useEffect(() => {
    if (!changeUrlQueryString) {
      return
    }

    const querySyring = getParamQueryString(
      {
        path: '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: param.count,
      },
      param
    )

    const currentQueryString = window.location.search?.substring(1)

    if (querySyring === currentQueryString) {
      return
    }

    const newParam = getQueryParamObject(
      {
        path: '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: param.count + 1,
      },
      _.cloneDeep(listFilters),
      emailTemplateTitleList
    )

    setParam(newParam)

    const newQuery = getParamQueryString(
      {
        path: '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: 0,
      },
      newParam
    )

    history.replace({ pathname: location.pathname, search: newQuery })
  }, [filterParams])

  function onChangeParam(value) {
    value.count = value.count + 1
    if (!changeUrlQueryString) {
      setParam(value)
      return
    }

    const querySyring = getParamQueryString(
      {
        path: '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: 0,
      },
      value
    )

    history.push({ pathname: location.pathname, search: querySyring })
  }

  function onChangeListFilters(list) {
    let tempParam = _.cloneDeep(param)
    tempParam.listFilters = list
    tempParam.page = 0
    onChangeParam(tempParam)
  }

  function onChangeListSort(sortItem) {
    let tempParam = _.cloneDeep(param)
    tempParam.sort = sortItem
    tempParam.direction = tempParam.direction === 'DESC' ? 'ASC' : 'DESC'
    onChangeParam(tempParam)
  }

  function onChangeListPage(page) {
    let tempParam = _.cloneDeep(param)
    tempParam.page = page
    onChangeParam(tempParam)
  }

  function onChangeListRowsPerPage(rowsPerPage) {
    let tempParam = _.cloneDeep(param)
    tempParam.page = 0
    tempParam.rowsPerPage = rowsPerPage
    onChangeParam(tempParam)
  }

  useEffect(() => {
    let tempParam = _.cloneDeep(param)
    const filter = {}
    tempParam.listFilters.forEach((filterItem) => {
      if (filterItem.value !== '' && filterItem.value !== undefined) {
        filter[filterItem.name] = filterItem.value
      }
    })
    getList(
      constants.PATH.SHOP.EMAIL_TEMPLATE,
      {
        sort: { field: tempParam.sort, order: tempParam.direction },
        filter: filter,
        pagination: { page: tempParam.page + 1, perPage: tempParam.rowsPerPage },
      },
      (payload) => {
        let tempData = _.cloneDeep(payload)
        tempData.map((item) => {
          return (item.receiver = item.receiver ? addSpace(item.receiver, ',') : item.receiver)
        })
        tempData.map((item) => {
          item.subject = generateTableCellToMultiLanguageName(item.tcSubject, item.enSubject, item.scSubject)
          return item
        })
        setEmailTemplateList(tempData)
        setReady(true)
        setClear(false)
      },
      undefined,
      store
    )
  }, [param.count, clear === true]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready && (
      <Card className="card-wrapper overflow">
        <ListDisplay
          page={param.page}
          rowsPerPage={param.rowsPerPage}
          onChangeListSort={onChangeListSort}
          resource={resource}
          dataList={emailTemplateList}
          sort={param.sort}
          direction={param.direction}
          listTitles={emailTemplateTitleList}
          listFilters={param.listFilters}
          onChangeListPage={onChangeListPage}
          onChangeListRowsPerPage={onChangeListRowsPerPage}
          onChangeListFilters={onChangeListFilters}
          redirectPath={'/' + constants.PATH.SHOP.EMAIL_TEMPLATE + '/'}
        />
      </Card>
    )
  )
}
