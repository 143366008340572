import _ from 'lodash'
import { COUNTER } from '../actions/types'
import { constants } from '../Constants/constants'

const INITIAL_STATE = {
  ui: {
    counter: _.cloneDeep(constants.COUNTER_INPUT),
  },
}

const counterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case COUNTER.SET_COUNTER_DEFAULT:
      return Object.assign({}, INITIAL_STATE)
    case COUNTER.SET_COUNTER:
    case COUNTER.SET_COUNTER_VALUE:
      return Object.assign(
        {},
        {
          ...state,
          ui: {
            ...state.ui,
            counter: action.counter,
          },
        }
      )
    default:
      return state
  }
}

export default counterReducer
