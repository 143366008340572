import { SYSTEM } from './types';

export const setLoading = (loading) => {
    return {
        type: SYSTEM.SET_LOADING,
        loading: loading
    }
}

export const setMessage = (message, messageType) => {
    return {
        type: SYSTEM.SET_MESSAGE,
        message: message,
        messageType: messageType
    }
}

export const setTotal = (total) => {
    return {
        type: SYSTEM.SET_TOTAL,
        total: total
    }
}