import { useDispatch, useSelector } from "react-redux";

import DeleteIcon from '@mui/icons-material/Delete';

import { constants } from '../../../Constants/constants';
import { TempBtn } from "../../../Component/Input/TempBtn";
import { TempTextInput } from "../../../Component/Input/TempTextInput";
import { helperTextStyles } from "../../../helper/util";

import { removeUserGroupEmail } from "../../../actions/merchantUserGroupAction";
import MerchantUserGroupValidation from "../../../validations/merchantUserGroupValidation"
import { merchantUserGroupValidationConstants } from "../../../Constants/validationConstants";

const LOCAL_CONSTANT = {
  EMAIL: "EMAIL",
}

export const EmailField =({item, index, onChangeInput})=>{

  const dispatch = useDispatch();
  const savedMerchantUserGroup = useSelector(state => state.merchantUserGroup.ui.merchantUserGroup);
  const resource = "MerchantUserGroup";
  const helperTextStylesClasses = helperTextStyles();

  const handleRemoveEmail = (index) =>{
    MerchantUserGroupValidation.resetErrorMessages(LOCAL_CONSTANT.EMAIL, savedMerchantUserGroup?.emailList?.length, index)
    dispatch(removeUserGroupEmail(savedMerchantUserGroup, index));
  }

  return (
  <>
    <div>
      <TempTextInput inputSetting={
        {
          className: "edit-create-input",
          size: constants.SIZE.SMALL,
          label: { resource: resource, key: "emails" },
          name: `emails-${index}`,
          required: false,
          value: item,
          index: index,
          helperTextIcon: true,
          helperText: MerchantUserGroupValidation.getErrorMessages(`${index}-${merchantUserGroupValidationConstants.KEY_EMAIL}`),
          FormHelperTextProps: { className: helperTextStylesClasses.root },
        }
      } 
      handleChange={onChangeInput} />
      <TempBtn btnSetting={{
        className: "edit-create-btn",
        variant: constants.STYLE.VARIANT.CONTAINED,
        color: constants.STYLE.PRIMARY,
        disabled: false,
        onClick: () => handleRemoveEmail(index),
        label: { key: "deleteBtn" },
        icon: <DeleteIcon />,
        isEndOfLine: true,
      }}>
      </TempBtn>
    </div>
  </>
  )
}