import _ from "lodash";
import { CAMPAIGN_DESCRIPTION } from '../actions/types';
import { constants } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        campaignDescription: _.cloneDeep(constants.CAMPAIGN_DESCRIPTION_INPUT)
    }
}

const campaignDescriptionReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CAMPAIGN_DESCRIPTION.SET_CAMPAIGN_DESCRIPTION_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case CAMPAIGN_DESCRIPTION.SET_CAMPAIGN_DESCRIPTION:
        case CAMPAIGN_DESCRIPTION.SET_CAMPAIGN_DESCRIPTION_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    campaignDescription: action.campaignDescription
                }
            })
        default:
            return state
    }
}

export default campaignDescriptionReducer