import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { constants } from '../../Constants/constants';
import { UploadFile } from '../../Component/Payment/UploadFile';

import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';

import moment from 'moment';

import { BaseWrapper } from "../../Component/BaseWrapper";
import { TempBtn } from "../../Component/Input/TempBtn";
import { getString } from "../../helper/util";
import { setUploadPaymentList } from "../../actions/paymentAction";

import "../../styles/uploadPayment.scss";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export const UploadPaymentList = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const lang = useSelector(state => state.lang.ui.lang);
  const theme = useSelector(state => state.theme.ui.theme);
  const fileList = useSelector(state => state.payment.ui.paymentList);

  function sendList(paymentList) {
    dispatch(setUploadPaymentList(paymentList));
  }

  return (
    <Card className="card-wrapper overflow">
      <BaseWrapper>
        <UploadPaymentTitle lang={lang} sendList={sendList} theme={theme} />
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{getString(lang, "uploadPayment", "tranDate", theme)}</TableCell>
                <TableCell>{getString(lang, "uploadPayment", "tranRef", theme)}</TableCell>
                <TableCell>{getString(lang, "uploadPayment", "chequeNumber", theme)}</TableCell>
                <TableCell>{getString(lang, "uploadPayment", "restUrlId", theme)}</TableCell>
                <TableCell>{getString(lang, "uploadPayment", "restTcName", theme)}</TableCell>
                <TableCell>{getString(lang, "uploadPayment", "approvalStatus", theme)}</TableCell>
                <TableCell>{getString(lang, "uploadPayment", "matchStatus", theme)}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fileList && (fileList.length > 0 ? fileList.map((item, index) =>
                <TableRow key={index}>
                  <TableCell>{item.tranDate === null || item.tranDate === undefined ? "" : moment(item.tranDate).format('YYYY-MM-DD')}</TableCell>
                  <TableCell>{item.tranRef}</TableCell>
                  <TableCell>{item.chequeNumber}</TableCell>
                  <TableCell>{item.restUrlId}</TableCell>
                  <TableCell><div className="list-content"><div className="list-label-tab">TC</div>{item.restTcName}</div><div className="list-content"><div className="list-label-tab">EN</div>{item.restEnName}</div><div className="list-content"><div className="list-label-tab">SC</div>{item.restScName}</div></TableCell>
                  <TableCell>{item.approvalStatus}</TableCell>
                  <TableCell>{item.matchStatus}</TableCell>
                  <TableCell>
                    {item.matchStatus && item.matchStatus === "NOT_MATCHED" ?
                      "" :
                      <TempBtn btnSetting={{
                        variant: constants.STYLE.VARIANT.CONTAINED,
                        color: constants.STYLE.PRIMARY,
                        onClick: () => {
                          localStorage.setItem(constants.LOCAL_STORAGE_KEY.UPLOAD_PAYMENT_CHOSEN_TRANDATE, item.tranDate === null || item.tranDate === undefined ? "" : moment(item.tranDate).format('YYYY-MM-DD'));
                          localStorage.setItem(constants.LOCAL_STORAGE_KEY.UPLOAD_PAYMENT_CHOSEN_MATCHSTATUS, item.matchStatus);
                          history.push('/' + constants.PATH.APPROVAL.PAYMENT + "/" + item.tranRef + "/" + constants.PAYMENT_EDIT_TAB.INFO);
                        },
                        label: { key: "edit" },
                        icon: <EditIcon />,
                      }} />
                    }
                  </TableCell>
                </TableRow>
              )
                : <TableRow><TableCell colSpan={6}>No Data</TableCell></TableRow>)}
            </TableBody>
          </Table>
        </TableContainer>
      </BaseWrapper>
    </Card>
  )
};

const UploadPaymentTitle = ({ lang, sendList, theme }) => {
  return (
    <div className="title">
      {getString(lang, "uploadPayment", "mainTitle", theme)}{" "}
      <UploadFile sendList={sendList} />
    </div>
  );
};