import { getOne } from "../../../helper/baseFetch";
import { constants } from '../../../Constants/constants';
import { getScDisabled } from "../../../helper/util";

export const getProduct = (productCode, store) => {
  return new Promise((resolve, reject) => {
    getOne(constants.PATH.PRODUCT.PRODUCT + "/" + productCode, undefined, (payload) => {
      payload.scNameDisable = getScDisabled(payload.tcName, payload.scName);
      payload.scDescriptionDisable = getScDisabled(payload.tcDescription, payload.scDescription);
      payload.scTacDisable = getScDisabled(payload.tcTac, payload.scTac);
      payload.scRedeemDescriptionDisable = getScDisabled(payload.tcRedeemDescription, payload.scRedeemDescription);
      payload.scDiscountDescriptionDisable = getScDisabled(payload.tcDiscountDescription, payload.scDiscountDescription);

      if (payload.attributeList && payload.attributeList.length > 0) {
        payload.attributeList.map((attribute, attributeIndex) => {
          if (attribute.optionList && attribute.optionList.length > 0) {
            attribute.optionList.map((optionItem, optionIndex) => {
              optionItem.scValueDisable = getScDisabled(optionItem.tcValue, optionItem.scValue);
              return optionItem;
            })
          }
          return attribute;
        })
      }

      resolve(payload);
    }, undefined, store);
  })
}