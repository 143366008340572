import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import GoogleMapReact from "google-map-react";

import RoomIcon from "@mui/icons-material/Room";

import { constants } from '../../../Constants/constants';
import { getString } from "../../../helper/util";
import '../../../styles/smallGoogleMap.scss'

const MyPositionMarker = ({ text }) => (
  <div>
    <RoomIcon />
    {text}
  </div>
);

const SmallGoogleMap = ({ ...props }) => {
  const [center, setCenter] = useState({
    lat: props.getPosition.lat,
    lng: props.getPosition.lng
  });
  const [zoom, setZoom] = useState();
  const savedRestaurant = useSelector(state => state.restaurant.ui.restaurant);
  const lang = useSelector(state => state.lang.ui.lang);
  const theme = useSelector(state => state.theme.ui.theme);

  useEffect(() => {

    if (savedRestaurant.coorX === null || savedRestaurant.coorY === null) {
      setCenter({
        lat: constants.MAP.LAT,
        lng: constants.MAP.LNG
      });
    } else {
      setCenter({
        lat: props.getPosition.lat,
        lng: props.getPosition.lng
      });
    };

    if (savedRestaurant.coorZ === null) {
      setZoom(constants.MAP.ZOOM);
    } else {
      setZoom(props.getPosition.z);
    };

    return () => { };
  }, [props.getPosition.lat, props.getPosition.lng, props.getPosition.z]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className="small-map">
        <GoogleMapReact
          distanceToMouse={() => { }}
          bootstrapURLKeys={{
            key: process.env.REACT_APP_MAP_KEY,
            libraries: ["places"]
          }}
          center={center}
          defaultZoom={props.zoom}
          zoom={zoom}
          options={{
            scrollwheel: false,
            gestureHandling: "none",
            fullscreenControl: false,
            zoomControl: false,
            clickableIcons: false
          }}
          yesIWantToUseGoogleMapApiInternals
        >
          <MyPositionMarker center />
        </GoogleMapReact>
      </div>
      <div>{getString(lang, "Map", "chineseAddress", theme)} {props.getPosition.tcAddress}</div>
      <div>{getString(lang, "Map", "engAddress", theme)}  {props.getPosition.enAddress}</div>
      <div>
        {center.lat}, {center.lng}
      </div>
    </div>
  );
};

SmallGoogleMap.defaultProps = {
  myPosition: {
    lat: 22.28,
    lng: 114.15
  },
  zoom: 17
};

export default SmallGoogleMap;
