import { getOne } from "../../../helper/baseFetch";
import { constants } from '../../../Constants/constants';
import { getScDisabled } from "../../../helper/util";

export const getCetegory = (categoryCode, store) => {
  return new Promise((resolve, reject) => {
    getOne(constants.PATH.PRODUCT.PRODUCT_CATEGORY + "/" + categoryCode, undefined, (payload) => {
      payload.scNameDisable = getScDisabled(payload.tcName, payload.scName);
      payload.scDescriptionDisable = getScDisabled(payload.tcDescription, payload.scDescription);
      resolve(payload);
    }, undefined, store);
  })
}