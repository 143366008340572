import _ from "lodash";
import { PUSH } from '../actions/types';
import { constants } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        push: _.cloneDeep(constants.PUSH_INPUT_LIST)
    },
    imageWidth: 0
}

const pushReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PUSH.SET_PUSH_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case PUSH.SET_PUSH:
        case PUSH.SET_PUSH_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    push: action.push
                }
            })
        default:
            return state
    }
}

export default pushReducer