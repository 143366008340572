import _ from "lodash";
import { BANNER } from '../actions/types';
import { constants } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        bannerList: _.cloneDeep(constants.BANNER_INPUT_LIST),
        banner: _.cloneDeep(constants.BANNER_INPUT),
    },
    imageWidth: 0
}

const bannerReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case BANNER.SET_BANNER_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case BANNER.SET_BANNER_LIST:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    bannerList: action.bannerList,
                }
            })
        case BANNER.SET_BANNER:
        case BANNER.SET_BANNER_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    banner: action.banner
                }
            })
        default:
            return state
    }
}

export default bannerReducer