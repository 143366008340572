import { RESERVATION_TEMPLATE_MAP } from './types';
import _ from "lodash";

export const setReservationTemplateMapDefault = () => {
    return {
        type: RESERVATION_TEMPLATE_MAP.SET_RESERVATION_TEMPLATE_MAP_DEFAULT,
    }
}

export const setReservationTemplateMap = (reservationTemplateMap) => {
    return {
        type: RESERVATION_TEMPLATE_MAP.SET_RESERVATION_TEMPLATE_MAP,
        reservationTemplateMap: reservationTemplateMap
    }
}

export const setReservationTemplateMapValue = (draftType, orignalProduct, key, value, index) => {
    const reservationTemplateMap = _.cloneDeep(orignalProduct);
    switch (draftType) {
        default:
            reservationTemplateMap[draftType] = value;
    }
    return {
        type: RESERVATION_TEMPLATE_MAP.SET_RESERVATION_TEMPLATE_MAP_VALUE,
        reservationTemplateMap: reservationTemplateMap
    }
}

export const setReservationTemplateMapList = (reservationTemplateMapList) => {
    return {
        type: RESERVATION_TEMPLATE_MAP.SET_RESERVATION_TEMPLATE_MAP_LIST,
        reservationTemplateMapList: reservationTemplateMapList
    }
}
