import _ from "lodash";
import { RESTAURANT } from '../actions/types';
import { constants } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        restaurant: _.cloneDeep(constants.RESTAURANT_INPUT),
        restaurantClone: _.cloneDeep(constants.RESTAURANT_CLONE_INPUT)
    }
}

const restaurantReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RESTAURANT.RESET:
            return Object.assign({}, INITIAL_STATE)
        case RESTAURANT.SET_RESTAURANT:
        case RESTAURANT.SET_RESTAURANT_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    restaurant: action.restaurant
                }
            })
        case RESTAURANT.SET_RESTAURANT_CLONE:
        case RESTAURANT.SET_RESTAURANT_CLONE_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    restaurantClone: action.restaurantClone
                }
            })
        default:
            return state
    }
}

export default restaurantReducer