import { constants, TABLE_TYPE_DEFAULT_VALUE } from '../../Constants/constants'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'

import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import CancelIcon from '@mui/icons-material/Cancel'
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid'

import { BaseWrapper } from '../../Component/BaseWrapper'
import { setQueueTemplate, setQueueTemplateTimeSectionValue, setQueueTemplateTableTypeValue } from '../../actions/queueTemplateAction'
import { setLoading } from '../../actions/systemAction'
import { TempBtn } from '../../Component/Input/TempBtn'
import QueueTemplateValidation from '../../validations/queueTemplateValidation'
import { queueTemplateValidationConstants } from '../../Constants/validationConstants'
import { getString } from '../../helper/util'

import '../../styles/queueTemplate.scss'
import {
  getDefaultTableType,
  getReAssignDefaultColorTableTypeList,
  getTableTypeID,
  getTimeSectionID,
  reorderTableTypeList,
  reorderTimeSectionList,
  sortList,
} from './queueTemplateUtil'
import { QueueTemplateTimeSectionListDragAndDropSection } from './QueueTemplateTimeSectionListDragNDrop/QueueTemplateTimeSectionListDragAndDropSection'

export const QueueTemplateTimeSectionList = ({
  resource,
  setSelectedType,
  setCustomIdNumber,
  customIdNumber,
  tableTypeColorList,
  defaultTableTypeColorList,
}) => {
  const dispatch = useDispatch()
  const savedTimeSection = useSelector((state) => state.queueTemplate.ui.timeSection)
  const savedTableType = useSelector((state) => state.queueTemplate.ui.tableType)
  const savedQueueTemplate = useSelector((state) => state.queueTemplate.ui.queueTemplate)
  const savedTableTypeColorList = useSelector((state) => state.queueTemplate.ui.tableTypeColorList)
  const savedDefaultTableTypeColorList = useSelector((state) => state.queueTemplate.ui.defaultTableTypeColorList)
  const lang = useSelector((state) => state.lang.ui.lang)
  const theme = useSelector((state) => state.theme.ui.theme)

  function handleAddQueueTemplateList() {
    const tempQueueTemplate = _.cloneDeep(savedQueueTemplate)
    const newQueueTemplateInput = _.cloneDeep(constants.TIME_SECTION_DEFAULT_VALUE)
    const newTableTypeInput = _.cloneDeep(TABLE_TYPE_DEFAULT_VALUE)
    let newCustomIdNumber = _.cloneDeep(customIdNumber)
    newQueueTemplateInput.customId = 'section_' + newCustomIdNumber
    newQueueTemplateInput.timeSectionId = getTimeSectionID(tempQueueTemplate)
    newCustomIdNumber = newCustomIdNumber + 1
    tempQueueTemplate.push(newQueueTemplateInput)
    dispatch(setQueueTemplateTableTypeValue(newTableTypeInput))
    dispatch(setQueueTemplateTimeSectionValue(newQueueTemplateInput))
    dispatch(setQueueTemplate(tempQueueTemplate))
    setSelectedType('timeSection')
    setCustomIdNumber(newCustomIdNumber + 1)
    QueueTemplateValidation.validateTemplateTimeSection(tempQueueTemplate)
  }

  function handleAddTableTypeList(item) {
    const newItem = _.cloneDeep(item)
    const tempQueueTemplate = _.cloneDeep(savedQueueTemplate)
    let tempTableTypeIndex = tempQueueTemplate.findIndex((tempQueueTemplateValue) => tempQueueTemplateValue.customId === newItem.customId)
    let tempTableType = tempQueueTemplate.find((tempQueueTemplateValue) => tempQueueTemplateValue.customId === newItem.customId)
    const newTableType = _.cloneDeep(
      getDefaultTableType(
        tempQueueTemplate[tempTableTypeIndex],
        tempQueueTemplate[tempTableTypeIndex].tableTypeList?.length,
        savedTableTypeColorList || defaultTableTypeColorList
      )
    )
    const newQueueTemplateInput = _.cloneDeep(constants.TIME_SECTION_DEFAULT_VALUE)
    newTableType.id = null
    newTableType.timeSectionCustomId = tempTableType.customId
    newTableType.customId = 'table_' + customIdNumber
    const sortedList = sortList(tempQueueTemplate[tempTableTypeIndex].tableTypeList)
    newTableType.tableType = getTableTypeID(sortedList)

    tempQueueTemplate[tempTableTypeIndex].tableTypeList.push(newTableType)
    dispatch(setQueueTemplateTableTypeValue(newTableType))
    dispatch(setQueueTemplateTimeSectionValue(newQueueTemplateInput))
    dispatch(setQueueTemplate(tempQueueTemplate))
    setSelectedType('tableType')
    setCustomIdNumber(customIdNumber + 1)
    QueueTemplateValidation.validateTemplateTableType(tempQueueTemplate)
  }

  function handleCloneQueueTemplate(item) {
    const tempQueueTemplate = _.cloneDeep(savedQueueTemplate)
    const newTableTypeInput = _.cloneDeep(TABLE_TYPE_DEFAULT_VALUE)
    const newItem = _.cloneDeep(item)
    let newCustomIdNumber = _.cloneDeep(customIdNumber)
    newItem.id = null
    newItem.customId = 'section_' + newCustomIdNumber
    newItem.timeSectionId = ''
    newCustomIdNumber = newCustomIdNumber + 1
    newItem.tableTypeList.forEach((tableType) => {
      tableType.id = null
      tableType.timeSectionCustomId = newItem.customId
      tableType.customId = 'table_' + newCustomIdNumber
      newCustomIdNumber = newCustomIdNumber + 1
    })
    tempQueueTemplate.push(newItem)
    dispatch(setQueueTemplateTableTypeValue(newTableTypeInput))
    dispatch(setQueueTemplateTimeSectionValue(newItem))
    dispatch(setQueueTemplate(tempQueueTemplate))
    setSelectedType('timeSection')
    setCustomIdNumber(newCustomIdNumber + 1)
    QueueTemplateValidation.validateTemplateTimeSection(tempQueueTemplate)
  }

  function handleCloneTableType(item, tableType) {
    const newItem = _.cloneDeep(item)
    const newTableType = _.cloneDeep(tableType)
    const tempQueueTemplate = _.cloneDeep(savedQueueTemplate)
    let tempTableTypeIndex = tempQueueTemplate.findIndex((tempQueueTemplateValue) => tempQueueTemplateValue.customId === newItem.customId)
    let tempTableType = tempQueueTemplate.find((tempQueueTemplateValue) => tempQueueTemplateValue.customId === newItem.customId)
    const newQueueTemplateInput = _.cloneDeep(constants.TIME_SECTION_DEFAULT_VALUE)
    newTableType.id = null
    newTableType.timeSectionCustomId = tempTableType.customId
    newTableType.customId = 'table_' + customIdNumber
    newTableType.tableType = ''
    tempQueueTemplate[tempTableTypeIndex].tableTypeList.push(newTableType)
    tempQueueTemplate[tempTableTypeIndex].tableTypeList = [
      ...getReAssignDefaultColorTableTypeList({
        tableTypeList: tempQueueTemplate[tempTableTypeIndex].tableTypeList,
        startIndex: 0,
        endIndex: tempTableType.tableTypeList.length,
        colorList: savedTableTypeColorList || savedDefaultTableTypeColorList,
      }),
    ]
    dispatch(setQueueTemplateTableTypeValue(newTableType))
    dispatch(setQueueTemplateTimeSectionValue(newQueueTemplateInput))
    dispatch(setQueueTemplate(tempQueueTemplate))
    setSelectedType('tableType')
    setCustomIdNumber((prev) => prev + 1)
    QueueTemplateValidation.validateTemplateTableType(tempQueueTemplate)
  }

  function handleDeleteQueueTemplateList(e, item) {
    e.stopPropagation()
    const newItem = _.cloneDeep(item)
    const tempQueueTemplate = _.cloneDeep(savedQueueTemplate)
    dispatch(setQueueTemplate(tempQueueTemplate.filter((queueTemplate) => newItem.customId !== queueTemplate.customId)))
    QueueTemplateValidation.validateTemplateTimeSection(tempQueueTemplate.filter((queueTemplate) => newItem.customId !== queueTemplate.customId))
    const tempTimeSection = _.cloneDeep(savedTimeSection)
    const tempTableType = _.cloneDeep(savedTableType)
    if (tempTimeSection.customId === newItem.customId) {
      const newQueueTemplateInput = _.cloneDeep(constants.TIME_SECTION_DEFAULT_VALUE)
      dispatch(setQueueTemplateTimeSectionValue(newQueueTemplateInput))
    } else if (tempTableType.timeSectionCustomId === newItem.customId) {
      const newTableTypeInput = _.cloneDeep(TABLE_TYPE_DEFAULT_VALUE)
      dispatch(setQueueTemplateTableTypeValue(newTableTypeInput))
    }
    setSelectedType('')
  }

  function handleDeleteTableTypeList(item, tableType) {
    const newItem = _.cloneDeep(item)
    const newTableType = _.cloneDeep(tableType)
    const tempQueueTemplate = _.cloneDeep(savedQueueTemplate)
    let tempTableTypeIndex = tempQueueTemplate.findIndex((tempQueueTemplateValue) => tempQueueTemplateValue.customId === newItem.customId)
    let tempTableType = tempQueueTemplate.find((tempQueueTemplateValue) => tempQueueTemplateValue.customId === newItem.customId)
    const tempSavedTableType = _.cloneDeep(savedTableType)
    if (tempSavedTableType.customId === newTableType.customId) {
      const newTableTypeInput = _.cloneDeep(TABLE_TYPE_DEFAULT_VALUE)
      dispatch(setQueueTemplateTableTypeValue(newTableTypeInput))
    }
    tempTableType.tableTypeList = tempTableType.tableTypeList.filter((tempTableTypeValue) => newTableType.customId !== tempTableTypeValue.customId)
    tempTableType.tableTypeList = [
      ...getReAssignDefaultColorTableTypeList({
        tableTypeList: tempTableType.tableTypeList,
        startIndex: 0,
        endIndex: tempTableType.tableTypeList.length,
        colorList: savedTableTypeColorList || savedDefaultTableTypeColorList,
      }),
    ]
    tempQueueTemplate[tempTableTypeIndex] = tempTableType
    dispatch(setQueueTemplate(tempQueueTemplate))
    setSelectedType('')
    QueueTemplateValidation.validateTemplateTableType(tempQueueTemplate)
  }

  const handleAutoOrderTableType = (item) => {
    const temptItem = _.cloneDeep(item)
    const tempQueueTemplate = _.cloneDeep(savedQueueTemplate)
    const targetTimesection = tempQueueTemplate.find(
      (timesection) => (timesection.id || timesection.customId) === (temptItem.id || temptItem.customId)
    )
    const targetTimesectionIdx = tempQueueTemplate.findIndex(
      (timesection) => (timesection.id || timesection.customId) === (temptItem.id || temptItem.customId)
    )
    const newTableTypeList = reorderTableTypeList(targetTimesection.tableTypeList, savedTableTypeColorList || savedDefaultTableTypeColorList)

    if (!newTableTypeList) {
      return
    }

    targetTimesection.tableTypeList = newTableTypeList
    tempQueueTemplate[targetTimesectionIdx] = targetTimesection

    const tempSavedTableType = _.cloneDeep(savedTableType)
    tempSavedTableType.tableType = newTableTypeList.find(
      (tableType) => (tableType.id || tableType.customId) === (tempSavedTableType.id || tempSavedTableType.customId)
    )?.tableType
    dispatch(setQueueTemplate(tempQueueTemplate))
    dispatch(setQueueTemplateTableTypeValue(tempSavedTableType))
  }

  const handleAutoOrderTimeSection = () => {
    const tempQueueTemplate = _.cloneDeep(savedQueueTemplate)

    const reorderedQueueTemplate = reorderTimeSectionList(tempQueueTemplate)

    if (!reorderedQueueTemplate) {
      return
    }

    const tempTimeSection = _.cloneDeep(savedTimeSection)
    tempTimeSection.timeSectionId = reorderedQueueTemplate.find(
      (timeSection) => (timeSection.id || timeSection.customId) === (tempTimeSection.id || tempTimeSection.customId)
    )?.timeSectionId

    dispatch(setQueueTemplate(reorderedQueueTemplate))
    dispatch(setQueueTemplateTimeSectionValue(tempTimeSection))
  }

  return (
    <div className="queue-template-content-wrapper">
      <BaseWrapper>
        <div className="queue-template-array-list-wrapper no-flex ">
          <div className="sub-title">{getString(lang, resource, 'timeSectionList', theme)} : </div>
          <div className="time-section-button">
            <TempBtn
              btnSetting={{
                className: 'delete-button delete-button-margin',
                onClick: (e) => {
                  dispatch(setLoading(true))
                  handleAutoOrderTimeSection()
                  dispatch(setLoading(false))
                },
                color: constants.STYLE.PRIMARY,
                variant: constants.STYLE.VARIANT.CONTAINED,
                label: { resource: resource, key: 'autoOrderTimeSection' },
                icon: <FlipCameraAndroidIcon />,
              }}
            ></TempBtn>
            <TempBtn
              btnSetting={{
                className: 'margin-left-16',
                onClick: () => {
                  handleAddQueueTemplateList()
                },
                variant: constants.STYLE.VARIANT.CONTAINED,
                color: constants.STYLE.PRIMARY,
                label: { resource: resource, key: 'addQueueTemplate' },
                icon: <AddIcon />,
              }}
            ></TempBtn>
          </div>
        </div>
        {QueueTemplateValidation.getErrorMessages('timeSection') && QueueTemplateValidation.getErrorMessages('timeSection').length > 0 && (
          <div className="error-message">
            <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
            {QueueTemplateValidation.getErrorMessages('timeSection')}
          </div>
        )}
        <div className="queue-template-content-content">
          <div className="queue-template-tab-background">
            {savedQueueTemplate.map((item, index) => {
              let error = false
              if (
                QueueTemplateValidation.getErrorMessages(queueTemplateValidationConstants.KEY_QUEUE_TEMPLATE_ERROR, item.customId).map((error) => {
                  return error
                }).length > 0 ||
                QueueTemplateValidation.getErrorMessages(queueTemplateValidationConstants.KEY_TABLE_TYPE_LIST, item.customId).map((error) => {
                  return error
                }).length > 0
              ) {
                error = true
              }
              item.tableTypeList.map((tableType) => {
                if (
                  QueueTemplateValidation.getErrorMessages(queueTemplateValidationConstants.KEY_TABLE_TYPE_ERROR, tableType.customId).map((error) => {
                    return error
                  }).length > 0
                ) {
                  error = true
                }
                return tableType
              })
              let list = ''
              list = (
                <div
                  key={item.customId}
                  className={
                    item.customId === savedTimeSection.customId
                      ? 'queue-template-list-item queue-template-selected-product'
                      : error
                      ? 'queue-template-list-item queue-template-error'
                      : 'queue-template-list-item'
                  }
                  onClick={(e) => {
                    dispatch(setLoading(true))
                    e.stopPropagation()
                    dispatch(setQueueTemplateTimeSectionValue(item))
                    const newTableTypeInput = _.cloneDeep(TABLE_TYPE_DEFAULT_VALUE)
                    dispatch(setQueueTemplateTableTypeValue(newTableTypeInput))
                    setSelectedType('timeSection')
                    dispatch(setLoading(false))
                  }}
                >
                  <div className="code">
                    <div>{getString(lang, resource, 'id', theme, theme) + ': ' + item.customId}</div>
                    <div className="type">{getString(lang, resource, 'timeSectionId') + ': ' + item.timeSectionId}</div>
                  </div>
                  <div className="content">
                    <div className="top">
                      <div className="name">
                        {item.timeSectionEnLabel && <div> EN: {item.timeSectionEnLabel}</div>}
                        {item.timeSectionTcLabel && <div> TC: {item.timeSectionTcLabel}</div>}
                      </div>
                      <div className="delete-button">
                        <TempBtn
                          btnSetting={{
                            className: 'delete-button delete-button-margin',
                            onClick: (e) => {
                              dispatch(setLoading(true))
                              handleAutoOrderTableType(item)
                              dispatch(setLoading(false))
                            },
                            color: constants.STYLE.PRIMARY,
                            variant: constants.STYLE.VARIANT.CONTAINED,
                            label: { resource: resource, key: 'autoOrderTableType' },
                            icon: <FlipCameraAndroidIcon />,
                          }}
                        ></TempBtn>
                        <TempBtn
                          btnSetting={{
                            className: 'delete-button delete-button-margin',
                            onClick: (e) => {
                              dispatch(setLoading(true))
                              handleCloneQueueTemplate(item)
                              dispatch(setLoading(false))
                            },
                            color: constants.STYLE.PRIMARY,
                            variant: constants.STYLE.VARIANT.CONTAINED,
                            label: { key: 'Clone' },
                            icon: <FileCopyIcon />,
                          }}
                        ></TempBtn>
                        {!item.id && (
                          <TempBtn
                            btnSetting={{
                              className: 'delete-button',
                              onClick: (e) => {
                                dispatch(setLoading(true))
                                handleDeleteQueueTemplateList(e, item)
                                dispatch(setLoading(false))
                              },
                              color: constants.STYLE.PRIMARY,
                              variant: constants.STYLE.VARIANT.CONTAINED,
                              label: { key: 'deleteBtn' },
                              icon: <DeleteIcon />,
                            }}
                          ></TempBtn>
                        )}
                      </div>
                    </div>
                    <div className="bottom">
                      <QueueTemplateTimeSectionListDragAndDropSection
                        index={index}
                        item={item}
                        setSelectedType={setSelectedType}
                        handleCloneTableType={handleCloneTableType}
                        resource={resource}
                        handleDeleteTableTypeList={handleDeleteTableTypeList}
                      />
                      {(item.status === 'A' || !item.id) && (
                        <TempBtn
                          btnSetting={{
                            className: 'queue-template-button-on-top sub-item-add-button',
                            onClick: (e) => {
                              e.stopPropagation()
                              handleAddTableTypeList(item)
                            },
                            color: constants.STYLE.PRIMARY,
                            variant: constants.STYLE.VARIANT.CONTAINED,
                            label: { resource: resource, key: 'addTableType' },
                            icon: <AddIcon />,
                          }}
                        ></TempBtn>
                      )}
                    </div>
                    {QueueTemplateValidation.getErrorMessages(queueTemplateValidationConstants.KEY_TABLE_TYPE_LIST, item.customId).map((error) => {
                      return error
                    }) &&
                      QueueTemplateValidation.getErrorMessages(queueTemplateValidationConstants.KEY_TABLE_TYPE_LIST, item.customId).map((error) => {
                        return error
                      }).length > 0 && (
                        <div className="error-message">
                          <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                          {QueueTemplateValidation.getErrorMessages(queueTemplateValidationConstants.KEY_TABLE_TYPE_LIST, item.customId).map(
                            (error) => {
                              return error
                            }
                          )}
                        </div>
                      )}
                    {QueueTemplateValidation.getErrorMessages(queueTemplateValidationConstants.KEY_QUEUE_TEMPLATE_ERROR, item.customId).map(
                      (error) => {
                        return error
                      }
                    ) &&
                      QueueTemplateValidation.getErrorMessages(queueTemplateValidationConstants.KEY_QUEUE_TEMPLATE_ERROR, item.customId).map(
                        (error) => {
                          return error
                        }
                      ).length > 0 && (
                        <div className="error-message">
                          <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                          {QueueTemplateValidation.getErrorMessages(queueTemplateValidationConstants.KEY_QUEUE_TEMPLATE_ERROR, item.customId).map(
                            (error) => {
                              return error
                            }
                          )}
                        </div>
                      )}
                  </div>
                </div>
              )
              return list
            })}
          </div>
        </div>
      </BaseWrapper>
    </div>
  )
}
