import React, { useEffect, useState } from 'react'
import { getOne, put } from '../../../helper/baseFetch'
import { useParams, useHistory } from 'react-router-dom'
import { LANDING_ACTION, LANDING_ACTION_PARAMETER, LANDING_SECTION_SECTION_TYPE, STATUS, constants } from '../../../Constants/constants'
import { useSelector, useStore } from 'react-redux'
import landingSectionValidation from '../../../validations/landingSectionValidation'
import { TempBtn } from '../../../Component/Input/TempBtn'

import { LandingHeader } from './LandingHeader'
import { DragnDropList, SectionCreate } from './LandingSectionCreate'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'
import { sify } from 'chinese-conv'
import { DialogWrapper } from '../../../Component/DialogWrapper'
import '../../../styles/landingSection.scss'
import { Card, FormControlLabel, Switch } from '@mui/material'
import { Add, ArrowBack, LowPriority } from '@mui/icons-material'
import { getDisplayActionType } from './landingUtil'
import { parseLandingParameters } from './handleLandingActionParameters'
import { getString } from '../../../helper/util'

const resource = 'LandingSection'

export const LandingSectionEdit = () => {
  const [landingSection, setLandingSection] = useState(null)
  const [onlyPublishing, setOnlyPublishing] = useState(false)
  const lang = useSelector((state) => state.lang.ui.lang)
  const theme = useSelector((state) => state.theme.ui.theme)
  const params = useParams()
  const store = useStore()
  const history = useHistory()
  const [clear, setClear] = useState(true)
  const [ready, setReady] = useState(false)
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  const [, setErrorVersion] = useState(landingSectionValidation.getVersion())

  useEffect(() => {
    if (!clear) return

    getOne(
      constants.PATH.LANDING.LANDING_SECTION + '/' + params.id,
      {
        publishing: onlyPublishing,
      },
      (payload) => {
        const temptPayload = {
          ...payload,
          parameters: payload.actionParameters ? parseLandingParameters(payload.actionParameters) : payload.actionParameters,
          displayActionType:
            payload?.action && payload?.action === LANDING_ACTION.WEB_VIEW
              ? getDisplayActionType(payload.actionParameters ? JSON.parse(payload.actionParameters) : LANDING_ACTION.WEB_VIEW)
              : payload?.action,
          scNameDisable: (payload.tcTitle ? sify(payload.tcTitle) : null) === payload.scTitle,
          itemList: payload.itemList?.map((item) => ({
            ...item,
            scTitleDisable: (item.tcTitle ? sify(item.tcTitle) : null) === item.scTitle,
            scSubTitleDisable: (item.tcSubTitle ? sify(item.tcSubTitle) : null) === item.scSubTitle,
            parameters: item.actionParameters ? parseLandingParameters(item.actionParameters) : item.actionParameters,
            displayActionType: item?.action === LANDING_ACTION.WEB_VIEW ? getDisplayActionType(JSON.parse(item.actionParameters)) : item?.action,
          })),
          originalItemList: payload.itemList,
        }

        if (temptPayload.action === LANDING_ACTION_PARAMETER.RESTAURANT_CATEGORY && temptPayload.parameters?.tagCode) {
          temptPayload.parameters.categoryCode = temptPayload.parameters.tagCode
        }

        if (temptPayload.action === LANDING_ACTION_PARAMETER.COUPON) {
          temptPayload.displayActionType = LANDING_ACTION_PARAMETER.E_COUPON
          if (temptPayload.parameters) temptPayload.parameters.ecouponCode = temptPayload.parameters.couponCode
        }

        if (temptPayload.itemList) {
          temptPayload.itemList.forEach((item) => {
            if (item.action === LANDING_ACTION_PARAMETER.RESTAURANT_CATEGORY && item.parameters.tagCode) {
              item.parameters.categoryCode = item.parameters.tagCode
            }

            if (item.action === LANDING_ACTION_PARAMETER.COUPON) {
              item.displayActionType = LANDING_ACTION_PARAMETER.E_COUPON
              if (item.parameters) {
                item.parameters.ecouponCode = item.parameters.couponCode || item.parameters.ecouponCode
              }
            }
          })
        }
        setLandingSection(temptPayload)
        setReady(true)
        setClear(false)
      },
      undefined,
      store
    )
  }, [clear])

  const handleAddItem = () => {
    const tempt = _.cloneDeep(constants.LANDING_ITEM_INPUT)
    tempt.customId = uuidv4()
    tempt.sectionId = landingSection.id
    tempt.itemType = landingSection.itemList && landingSection.itemList.length > 0 ? landingSection.itemList[0].itemType : landingSection.itemType
    const temptLandingSection = _.cloneDeep(landingSection)
    temptLandingSection.itemList.unshift(tempt)
    setLandingSection(temptLandingSection)
  }

  const onClickClear = () => {
    setClear(true)
  }

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push('/' + constants.PATH.LANDING.LANDING_SECTION + '/0')
  }

  const onClickSave = () => {
    const tempLandingSection = _.cloneDeep(landingSection)
    let itemListLength = tempLandingSection.itemList ? tempLandingSection.itemList.length : 0
    tempLandingSection.itemList?.forEach((item) => {
      const sequence = itemListLength * 10
      itemListLength--
      item.sequence = sequence
    })
    landingSectionValidation.validateLandingSection(tempLandingSection)
    tempLandingSection.itemList = getLandingSectionItemList(tempLandingSection)
    delete tempLandingSection.originalItemList
    delete tempLandingSection.itemType
    if (landingSectionValidation.isValid()) {
      put(
        constants.PATH.LANDING.LANDING_SECTION + '/' + params.id,
        tempLandingSection,
        (payload) => {
          setDialog({
            open: true,
            onClose: onClickBackToEdit,
            dialogContentText: { key: 'editSuccess' },
            dialogActions: [
              { label: { key: 'backEdit' }, onClick: onClickBackToEdit, icon: <ArrowBack /> },
              { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriority /> },
            ],
          })
          setClear(false)
        },
        undefined,
        store
      )
      landingSectionValidation.resetErrorMessages()
    } else {
      setErrorVersion(landingSectionValidation.getVersion())
    }
  }

  const handleTogglePublishing = () => {
    setOnlyPublishing((prev) => !prev)
    onClickClear()
  }

  return (
    ready && (
      <>
        <Card className="card-wrapper overflow">
          <div className="landing-section-preview-wrapper">
            <div className="title">
              <FormControlLabel
                control={<Switch checked={onlyPublishing} onChange={handleTogglePublishing} />}
                label={getString(lang, resource, 'publishing', theme)}
              />
              <TempBtn
                btnSetting={{
                  color: constants.STYLE.PRIMARY,
                  className: 'brand-btn margin-16-4',
                  label: { resource: 'LandingSection', key: 'addItem' },
                  //disabled: disableInput,
                  onClick: handleAddItem,
                  icon: <Add />,
                }}
              />
            </div>
          </div>
          <div className="landing-item-list-preview-main">
            {landingSection.sectionType === LANDING_SECTION_SECTION_TYPE.HEADER_ITEMS && <LandingHeader item={landingSection} isPreview={false} />}
            <div className="landing-item-list-preview">
              <DragnDropList currentEdit={landingSection} setCurrentEdit={setLandingSection} setSectionEdit={setLandingSection} />
            </div>
          </div>
          <SectionCreate
            item={landingSection}
            setItem={setLandingSection}
            setSectionEdit={setLandingSection}
            setErrorVersion={setErrorVersion}
            onClickSave={onClickSave}
            onClickClear={onClickClear}
          />
        </Card>
        {dialog && dialog.open && <DialogWrapper dialogInfo={dialog} />}
      </>
    )
  )
}

const getLandingSectionItemList = (tempLandingSection) => {
  if (!tempLandingSection.originalItemList || tempLandingSection.originalItemList.length === 0) {
    return tempLandingSection.itemList
  }
  const itemListChosenSet = new Set(tempLandingSection.itemList.map((item) => item.id))

  for (const item of tempLandingSection.originalItemList) {
    if (item.id && !itemListChosenSet.has(item.id)) {
      item.status = STATUS.DELETE
      tempLandingSection.itemList.push(item)
    }
  }

  return tempLandingSection.itemList
}
