import { useState, useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'

import { Card } from '@mui/material';

import { ListDisplay } from '../../Component/ListDisplay/ListDisplay'

import { constants } from '../../Constants/constants'
import { getList } from '../../helper/baseFetch'
import { setLoading } from '../../actions/systemAction'
import { generateTableCellToMultiLanguageName, getQueryParamObject, getParamQueryString, getQuery} from '../../helper/util'

export const RestaurantList = ({
  newPath,
  newFilter,
  newListTitles,
  newListFilters,
  editBtn,
  createBtn,
  btnList,
  mutipleSelect,
  setPreviewSelectedList,
  previewSelectedList,
  selectedIdList,
  setSelectedIdList,
  dialogFooterBtn,
  onClickListRow,
  pagenName,
  savedData,
  getDataDependency,
  selectMap = new Map(),
  setSelectMap,
  initialList = [],
  changeUrlQueryString,
}) => {
  const dispatch = useDispatch()
  const store = useStore()
  const [restaurantList, setRestaurantList] = useState(initialList.length > 0 ? initialList : [])
  const total = useSelector((state) => state.system.ui.total)
  const [ready, setReady] = useState(initialList.length > 0 ? true : false)
  const [selectedAllCheck, setSelectedAllCheck] = useState(false)
  const resource = 'restaurant'
  const redirectPath = newPath ? newPath : constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT
  const history = useHistory()
  const location = useLocation()
  const filterParams = getQuery()

  const restaurantTitleList = newListTitles
    ? newListTitles
    : [
        {
          key: constants.LIST_DISPLAY_TYPE.STRING,
          value: 'restUrlId',
          sort: true,
        },
        {
          key: constants.LIST_DISPLAY_TYPE.STRING,
          value: 'restRefId',
          sort: true,
        },
        {
          key: constants.LIST_DISPLAY_TYPE.STRING,
          value: 'name',
          sort: false,
          minSize: true,
        },
        {
          key: constants.LIST_DISPLAY_TYPE.STRING,
          value: 'address',
          sort: false,
          minSize: true,
        },
        {
          key: constants.LIST_DISPLAY_TYPE.STRING,
          value: 'salesName',
          sort: true,
        },
        {
          key: constants.LIST_DISPLAY_TYPE.DATE_TIME,
          value: 'salesCreateTimestamp',
          sort: true,
        },
        {
          key: constants.LIST_DISPLAY_TYPE.STRING,
          value: 'status',
          sort: true,
        },
      ]
  const listFilters = newListFilters
    ? newListFilters
    : [
        {
          className: 'filter-input',
          label: { resource: resource, key: 'restUrlId' },
          clear: true,
          value: '',
          name: 'restUrlId',
          size: constants.SIZE.SMALL,
        },
        {
          className: 'filter-input',
          label: { resource: resource, key: 'restRefId' },
          clear: true,
          value: '',
          name: 'restRefId',
          size: constants.SIZE.SMALL,
        },
        {
          className: 'filter-input',
          label: { resource: resource, key: 'name' },
          clear: true,
          value: '',
          name: 'name',
          size: constants.SIZE.SMALL,
        },
        {
          className: 'filter-input',
          label: { resource: resource, key: 'address' },
          clear: true,
          value: '',
          name: 'address',
          size: constants.SIZE.SMALL,
        },
        {
          className: 'filter-input',
          label: { resource: resource, key: 'salesName' },
          clear: true,
          value: '',
          name: 'salesName',
          size: constants.SIZE.SMALL,
        },
        {
          className: 'filter-input',
          label: { resource: resource, key: 'salesCreateStartDate' },
          clear: true,
          value: '',
          type: constants.TYPE.DATETIME_LOCAL,
          name: 'salesCreateStartDate',
          size: constants.SIZE.SMALL,
        },
        {
          className: 'filter-input',
          label: { resource: resource, key: 'salesCreateEndDate' },
          clear: true,
          value: '',
          type: constants.TYPE.DATETIME_LOCAL,
          name: 'salesCreateEndDate',
          size: constants.SIZE.SMALL,
        },
        {
          className: 'filter-input',
          label: { resource: resource, key: 'status' },
          value: '',
          type: constants.TYPE.SELECT,
          selectValue: constants.CHOICES.RESTAURANT.FILTER_STATUS,
          name: 'status',
          size: constants.SIZE.SMALL,
        },
      ]

  const [param, setParam] = useState(
    !changeUrlQueryString
      ? {
          path: newPath ? newPath : '',
          page: 0,
          rowsPerPage: 10,
          sort: 'updateTimestamp',
          direction: 'DESC',
          listFilters: _.cloneDeep(listFilters),
          count: initialList.length > 0 ? -1 : 0,
        }
      : getQueryParamObject(
          {
            path: newPath ? newPath : '',
            page: 0,
            rowsPerPage: 10,
            sort: 'updateTimestamp',
            direction: 'DESC',
            listFilters: _.cloneDeep(listFilters),
            count: initialList.length > 0 ? -1 : 0,
          },
          _.cloneDeep(listFilters),
          restaurantTitleList
        )
  )

  useEffect(() => {

    if(!changeUrlQueryString) {
      return;
    }

    const querySyring = getParamQueryString(
      {
        path: newPath ? newPath : '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: param.count,
      },
      param
    )

    const currentQueryString = window.location.search?.substring(1)

    if (querySyring === currentQueryString) {
      return
    }

    const newParam = getQueryParamObject(
      {
        path: newPath ? newPath : '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: param.count + 1,
      },
      _.cloneDeep(listFilters),
      restaurantTitleList
    )

    setParam(newParam)

    const newQuery = getParamQueryString(
      {
        path: newPath ? newPath : '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: initialList.length > 0 ? -1 : 0,
      },
      newParam
    )

    history.replace({ pathname: location.pathname, search: newQuery })
    
  }, [filterParams])

  function onChangeParam(value) {
    value.count = value.count + 1

    if (!changeUrlQueryString) {
      setParam(value)
      return
    }

    const querySyring = getParamQueryString(
      {
        path: newPath ? newPath : '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: initialList.length > 0 ? -1 : 0,
      },
      value
    )

    history.push({ pathname: location.pathname, search: querySyring })
  }

  function onChangeListFilters(list) {
    let tempParam = _.cloneDeep(param)
    tempParam.listFilters = list
    tempParam.page = 0
    onChangeParam(tempParam)
  }

  function onChangeListSort(sortItem) {
    let tempParam = _.cloneDeep(param)
    tempParam.sort = sortItem
    tempParam.direction = tempParam.direction === 'DESC' ? 'ASC' : 'DESC'
    onChangeParam(tempParam)
  }

  function onChangeListPage(page) {
    let tempParam = _.cloneDeep(param)
    tempParam.page = page
    onChangeParam(tempParam)
  }

  function onChangeListRowsPerPage(rowsPerPage) {
    let tempParam = _.cloneDeep(param)
    tempParam.page = 0
    tempParam.rowsPerPage = rowsPerPage
    onChangeParam(tempParam)
  }

  function onMultiSelectList(restUrlId, dataList) {
    let selectedList = selectedIdList ? _.cloneDeep(selectedIdList) : []
    let newSelectedRestaurantList = _.cloneDeep(previewSelectedList)
    let tempDataList = _.cloneDeep(dataList)
    if (restUrlId === 'all') {
      if (!selectedAllCheck) {
        restaurantList.map((item) => {
          newSelectedRestaurantList.push(item)
          return selectedList.push(item.restUrlId)
        })
      } else {
        selectedList = selectedList.filter((item) => {
          let selectedRestaurant = restaurantList.map((restaurant) => {
            newSelectedRestaurantList = newSelectedRestaurantList.filter(
              (newSelectedRestaurant) => newSelectedRestaurant.restUrlId !== restaurant.restUrlId
            )
            return restaurant.restUrlId === item && restaurant.restUrlId
          })
          return !selectedRestaurant.includes(item)
        })
      }
      setSelectedAllCheck(!selectedAllCheck)
    } else {
      if (selectedList.includes(restUrlId)) {
        selectedList = selectedList.filter((item) => item !== restUrlId)
        newSelectedRestaurantList = newSelectedRestaurantList.filter((item) => item.restUrlId !== restUrlId)
      } else {
        selectedList.push(restUrlId)
        newSelectedRestaurantList.push.apply(
          newSelectedRestaurantList,
          tempDataList.filter((item) => item.restUrlId === restUrlId)
        )
      }

      let selectedPerPage = restaurantList.filter((restaurant) => {
        return selectedList.includes(restaurant.restUrlId)
      })

      let availableRow = restaurantList.length

      if (selectedPerPage.length === availableRow && selectedPerPage.length !== 0) {
        //param.rowsPerPage
        setSelectedAllCheck(true)
      } else {
        setSelectedAllCheck(false)
      }
    }

    setSelectedIdList(selectedList)

    pagenName === 'FolderMap'
      ? setPreviewSelectedList(
          newSelectedRestaurantList.map((restaurant) => {
            restaurant.refCode = restaurant.restUrlId
            restaurant.sequence = 0
            restaurant.type = 'RESTAURANT'
            return restaurant
          })
        )
      : setPreviewSelectedList(
          newSelectedRestaurantList.map((restaurant) => {
            return restaurant
          })
        )
  }

  const addNewEntryToMap = (map, array) => {
    for (const item of array) {
      map.set(item.restUrlId, item)
    }
    return map
  }

  const checkAllSelected = (map) => {
    let selectedPerPage = restaurantList.filter((item) => {
      return map.has(item.restUrlId)
    }).length

    let currentPageEntry = restaurantList.length

    if (selectedPerPage === currentPageEntry && selectedPerPage !== 0) {
      setSelectedAllCheck(true)
    } else {
      setSelectedAllCheck(false)
    }
  }

  const handleMutiSelectMap = (id, dataList, row) => {
    let tempMap = _.cloneDeep(selectMap)
    if (id === 'all') {
      if (!selectedAllCheck) {
        setSelectMap(addNewEntryToMap(tempMap, restaurantList))
        //setSelectedAllCheck(true);
        return
      }

      restaurantList.forEach((item) => tempMap.delete(item.restUrlId))
      setSelectMap(tempMap)
      //setSelectedAllCheck(false);
      return
    }

    if (selectMap.has(id)) {
      tempMap.delete(id)
    } else {
      tempMap.set(id, row)
    }

    setSelectMap(tempMap)
    checkAllSelected(tempMap)
  }

  useEffect(() => {
    if (!selectMap || selectMap.size === 0) {
      return
    }

    checkAllSelected(selectMap)
  }, [restaurantList, selectMap])

  useEffect(() => {
    if (param.count < 0) {
      return
    }

    let tempParam = _.cloneDeep(param)
    const filter = newFilter
      ? _.cloneDeep(newFilter)
      : selectedIdList && selectedIdList.length > 0
      ? { selected: { column: 'restUrlId', valueList: selectedIdList } }
      : {}
    tempParam.listFilters.forEach((filterItem) => {
      if (filterItem.value !== '' && filterItem.value !== undefined) {
        if (filterItem.type === constants.TYPE.DATETIME_LOCAL) {
          filter[filterItem.name] = moment(filterItem.value).format('x')
        } else {
          filter[filterItem.name] = filterItem.value
        }
      }
    })

    pagenName === 'FolderMap'
      ? getList(
          tempParam.path ? tempParam.path : constants.PATH.SHOP.RESTAURANT,
          {
            sort: { field: tempParam.sort, order: tempParam.direction },
            filter: filter,
            pagination: { page: tempParam.page + 1, perPage: tempParam.rowsPerPage },
          },
          (payload) => {
            payload.map((item) => {
              item.name = generateTableCellToMultiLanguageName(item.tcName, item.enName, item.scName)
              item.address = generateTableCellToMultiLanguageName(item.tcAddress, item.enAddress, item.scAddress)
              return item
            })
            setRestaurantList(payload)
            let selectedPerPage = payload.filter((restaurant) => {
              return (
                savedData &&
                savedData
                  .map((savedDataValue) => {
                    return savedDataValue.refCode
                  })
                  .includes(restaurant.restUrlId)
              )
            })
            if (selectedPerPage.length === Number(tempParam.rowsPerPage < total ? tempParam.rowsPerPage : total) && selectedPerPage.length !== 0) {
              setSelectedAllCheck(true)
            } else {
              setSelectedAllCheck(false)
            }
            dispatch(setLoading(false))
            setReady(true)
          },
          undefined,
          store
        )
      : getList(
          tempParam.path ? tempParam.path : constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT,
          {
            sort: { field: tempParam.sort, order: tempParam.direction },
            filter: filter,
            pagination: { page: tempParam.page + 1, perPage: tempParam.rowsPerPage },
          },
          (payload) => {
            payload.map((item) => {
              item.name = generateTableCellToMultiLanguageName(item.tcName, item.enName, item.scName)
              item.address = generateTableCellToMultiLanguageName(item.tcAddress, item.enAddress, item.scAddress)
              return item
            })
            setRestaurantList(payload)
            let selectedPerPage = payload.filter((restaurant) => {
              return (
                savedData &&
                savedData
                  .map((savedData) => {
                    return savedData.restUrlId ? savedData.restUrlId : savedData
                  })
                  .includes(restaurant.restUrlId ? restaurant.restUrlId : restaurant)
              )
            })
            if (selectedPerPage.length === Number(tempParam.rowsPerPage < total ? tempParam.rowsPerPage : total) && selectedPerPage.length !== 0) {
              setSelectedAllCheck(true)
            } else {
              setSelectedAllCheck(false)
            }
            setReady(true)
          },
          undefined,
          store
        )
  }, [param.count, getDataDependency]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready && (
      <Card className="card-wrapper overflow">
        <ListDisplay
          onClickListRow={onClickListRow}
          page={param.page}
          rowsPerPage={param.rowsPerPage}
          onChangeListSort={onChangeListSort}
          resource={resource}
          dataList={restaurantList}
          idName="restUrlId"
          sort={param.sort}
          direction={param.direction}
          listTitles={restaurantTitleList}
          listFilters={param.listFilters}
          onChangeListPage={onChangeListPage}
          onChangeListRowsPerPage={onChangeListRowsPerPage}
          onChangeListFilters={onChangeListFilters}
          editBtn={editBtn}
          createBtn={createBtn ? createBtn : false}
          btnList={btnList === false ? false : ['restaurantClone']}
          clonePath={constants.PATH.SHOP.RESTAURANT_CLONE + '/'}
          mutipleSelect={mutipleSelect}
          onMultiSelectList={selectedIdList ? onMultiSelectList : handleMutiSelectMap}
          selectMap={selectMap}
          selectedAllCheck={selectedAllCheck}
          previewSelectedIdList={selectedIdList ? selectedIdList : []}
          dialogFooterBtn={dialogFooterBtn}
          redirectPath={'/' + redirectPath + '/'}
        />
      </Card>
    )
  )
}
