import { productMapValidationConstants } from '../Constants/validationConstants';

class ProductMapValidation {

  version;
  errorMap;
  productMapErrorMap;
  valid;

  constructor() {

    if (!ProductMapValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.productMapErrorMap = new Map();
      ProductMapValidation.instance = this;
    }
    return ProductMapValidation.instance;
  }

  validateEditProductMap(productMap) {
    this.resetErrorMessages();
    this.handleValidateEditProductMap(productMap);
    this.version++;
  }

  validateCreateProductMap(productMap) {
    this.resetErrorMessages();
    this.handleValidateCreateProductMap(productMap);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.productMapErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key, uniqueCode) {
    if (!uniqueCode) {
      return (this.productMapErrorMap.has(key)) ? this.productMapErrorMap.get(key) : [];
    } else {
      if (this.productMapErrorMap.has(uniqueCode) && this.productMapErrorMap.get(uniqueCode).has(key)) {
        return this.productMapErrorMap.get(uniqueCode).get(key);
      }
      return [];
    }
  }

  handleValidateEditProductMap(productMap) {
    if (!productMap.displaySequence && productMap.displaySequence !== 0) {
      this.setProductMapErrorMapMessages(productMapValidationConstants.KEY_DISPLAY_SEQUENCE, "Missing Display Sequence");
    } else if (!Number.isInteger(Number(productMap.displaySequence)) || Number(productMap.displaySequence) < 0) {
      this.setProductMapErrorMapMessages(productMapValidationConstants.KEY_DISPLAY_SEQUENCE, ["Invalid display sequence"]);
    }
    if (!productMap.status) {
      this.setProductMapErrorMapMessages(productMapValidationConstants.KEY_STATUS, "Missing Status");
    }
  }

  handleValidateCreateProductMap(productMap) {
    if (productMap.length === 0) {
      this.setProductMapErrorMapMessages(productMapValidationConstants.KEY_PRODUCT_MAP_ERROR, "Please add at least one product map!");
    } else {
      productMap.map((item) => {
        const productMapErrorMap = new Map();
        if (!item.displaySequence && item.displaySequence !== 0) {
          productMapErrorMap.set(productMapValidationConstants.KEY_DISPLAY_SEQUENCE, "Missing Display Sequence");
        } else if (!Number.isInteger(Number(item.displaySequence)) || Number(item.displaySequence) < 0) {
          productMapErrorMap.set(productMapValidationConstants.KEY_DISPLAY_SEQUENCE, ["Invalid display sequence"]);
        }
        if (productMapErrorMap.size > 0) {
          productMapErrorMap.set(productMapValidationConstants.KEY_BANNER_ERROR, ["Has error(s)"]);
          this.setProductMapErrorMapMessages(item.productCode, productMapErrorMap);
        }
        return item
      })
    }
  }


  setProductMapErrorMapMessages(key, errorMessages, valid = false) {
    this.productMapErrorMap.set(key, errorMessages);
    // console.log("productMapErrorMap", this.productMapErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new ProductMapValidation();
export default validation;