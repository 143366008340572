import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import { DndContext, DragOverlay, closestCorners, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import { useDispatch, useSelector } from 'react-redux'
import { setQueueTemplate, setQueueTemplateTableTypeValue } from '../../../actions/queueTemplateAction'
import { TableTypeSection } from './TableTypeSection'
import { TableType } from './TableType'
import { SortableContent } from './SortableContent'
import { customCollisionDetectionAlgorithm } from './customCollisionDetectionAlgorithm'
import { getReAssignDefaultColorTableTypeList } from '../queueTemplateUtil'

export const QueueTemplateTimeSectionListDragAndDropSection = ({
  index: timesectionIdx,
  item,
  setSelectedType,
  handleCloneTableType,
  resource,
  handleDeleteTableTypeList,
}) => {
  const dispatch = useDispatch()
  const savedQueueTemplate = useSelector((state) => state.queueTemplate.ui.queueTemplate)
  const savedTableType = useSelector((state) => state.queueTemplate.ui.tableType)
  const savedTableTypeColorList = useSelector((state) => state.queueTemplate.ui.tableTypeColorList)
  const savedDefaultTableTypeColorList = useSelector((state) => state.queueTemplate.ui.defaultTableTypeColorList)
  const [tableTypeMap, setTableTypeMap] = useState(new Map())
  const [idList, setIdList] = useState([])
  const [displayList, setDisplayList] = useState([])
  const [activeId, setActiveId] = useState()
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 8 },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
      activationConstraint: { distance: 8 },
    })
  )

  useEffect(() => {
    const temptDisplayList = []
    const tempTableTypeMap = new Map()
    const temptIndexMap = new Map()
    const temptIdList = []

    for (const tableType of item.tableTypeList) {
      tempTableTypeMap.set(tableType.id || tableType.customId, { ...tableType })
      temptIdList.push(tableType.id || tableType.customId)

      if (tableType.groupedSection) {
        if (!temptIndexMap.has(tableType.groupedSection)) {
          temptDisplayList.push({ group: tableType.groupedSection, list: [tableType.id || tableType.customId] })
          temptIndexMap.set(tableType.groupedSection, temptDisplayList.length - 1)
          continue
        }

        temptDisplayList[temptIndexMap.get(tableType.groupedSection)].list = [
          ...temptDisplayList[temptIndexMap.get(tableType.groupedSection)].list,
          tableType.id || tableType.customId,
        ]
      } else {
        temptDisplayList.push(tableType.id || tableType.customId)
      }
    }

    setTableTypeMap(tempTableTypeMap)
    setDisplayList(temptDisplayList)
    setIdList(temptIdList)
  }, [item])

  const handleDragEnd = (event) => {
    const tempTableTypeList = _.flatten(
      displayList.map((tableType) =>
        tableType.group ? tableType.list.map((tableTypeId) => tableTypeMap.get(tableTypeId)) : tableTypeMap.get(tableType)
      )
    )

    const temptQueueTemplate = _.cloneDeep(savedQueueTemplate)
    temptQueueTemplate[timesectionIdx].tableTypeList = tempTableTypeList
    temptQueueTemplate[timesectionIdx].tableTypeList = getReAssignDefaultColorTableTypeList({
      tableTypeList: temptQueueTemplate[timesectionIdx].tableTypeList,
      startIndex: 0,
      endIndex: temptQueueTemplate[timesectionIdx].tableTypeList.length,
      colorList: savedTableTypeColorList || savedDefaultTableTypeColorList,
    })

    if (savedTableType?.id || savedTableType?.customId) {
      const updatedTableType = tempTableTypeList.find(
        (tableType) => (tableType.id || tableType.customId) === (savedTableType.id || savedTableType.customId)
      )
      if (updatedTableType) {
        dispatch(setQueueTemplateTableTypeValue(updatedTableType))
      }
    }

    dispatch(setQueueTemplate(temptQueueTemplate))

    setActiveId(null)
  }

  const handleDragStart = (event) => {
    const { active } = event
    const { id } = active

    setActiveId(id)
  }

  function handleDragOver(event) {
    const { active, over } = event

    if (over && active.id !== over.id) {
      let oldIndex = null
      let newIndex = null

      const temptDisplayList = _.cloneDeep(displayList)
      const temptTableTypeMap = _.cloneDeep(tableTypeMap)
      const activeItem = active.id.startsWith('grouped-section-') ? null : temptTableTypeMap.get(active.id)
      const overItem = over.id.startsWith('grouped-section-') ? null : temptTableTypeMap.get(over.id)
      const tempFlattenDisplayList = _.flatten(temptDisplayList.map((tableType) => (tableType.group ? tableType.list : tableType)))
      const overGroup = over.id.replace('grouped-section-', '')
      const activeGroup = active.id.replace('grouped-section-', '')

      const newItem = {
        ...activeItem,
        groupedSection: overItem?.groupedSection || null,
        groupedSectionEnDescription: overItem?.groupedSectionEnDescription || null,
        groupedSectionEnLabel: overItem?.groupedSectionEnLabel || null,
        groupedSectionScDescription: overItem?.groupedSectionScDescription || null,
        groupedSectionScLabel: overItem?.groupedSectionScLabel || null,
        groupedSectionTcDescription: overItem?.groupedSectionTcDescription || null,
        groupedSectionTcLabel: overItem?.groupedSectionTcLabel || null,
      }

      if (activeItem && overItem) {
        oldIndex = tempFlattenDisplayList.findIndex((tableType) => tableType === active.id)
        newIndex = tempFlattenDisplayList.findIndex((tableType) => tableType === over.id)

        if (oldIndex === -1 || newIndex === -1 || oldIndex === null || newIndex === null) {
          return
        }

        temptTableTypeMap.set(active.id, { ...newItem })

        const newTableTypeList = arrayMove(tempFlattenDisplayList, oldIndex, newIndex)
        const newDisplayList = getDisplayList(newTableTypeList, temptTableTypeMap)

        setDisplayList(newDisplayList)
        setTableTypeMap(temptTableTypeMap)

        return
      }

      if (activeItem && !overItem) {
        const flattenListWithGroup = tempFlattenDisplayList.map((tableType) => ({
          group: temptTableTypeMap.get(tableType).groupedSection,
          id: tableType,
        }))
        oldIndex = flattenListWithGroup.findIndex((tableType) => tableType.id === active.id)
        newIndex = flattenListWithGroup.findIndex((tableType) => tableType.group === overGroup)

        if (oldIndex === -1 || newIndex === -1 || oldIndex === null || newIndex === null) {
          return
        }

        temptTableTypeMap.set(active.id, { ...newItem })
        const newTableTypeList = arrayMove(flattenListWithGroup, oldIndex, newIndex)
        const newDisplayList = getDisplayList(
          newTableTypeList.map((tableType) => tableType.id),
          temptTableTypeMap
        )

        setDisplayList(newDisplayList)
        setTableTypeMap(temptTableTypeMap)

        return
      }

      if (!activeItem && overItem) {
        if (overItem.groupedSection) {
          return
        }

        oldIndex = temptDisplayList.findIndex((tableType) => tableType.group === activeGroup)
        newIndex = temptDisplayList.findIndex((tableType) => tableType === over.id)

        if (oldIndex === -1 || newIndex === -1 || oldIndex === null || newIndex === null) {
          return
        }

        const newTableTypeList = arrayMove(temptDisplayList, oldIndex, newIndex)

        setDisplayList(newTableTypeList)
        setTableTypeMap(temptTableTypeMap)

        return
      }

      if (!activeItem && !overItem) {
        oldIndex = temptDisplayList.findIndex((tableType) => tableType.group === activeGroup)
        newIndex = temptDisplayList.findIndex((tableType) => tableType.group === overGroup)

        if (oldIndex === -1 || newIndex === -1 || oldIndex === null || newIndex === null) {
          return
        }

        const newTableTypeList = arrayMove(temptDisplayList, oldIndex, newIndex)

        setDisplayList(newTableTypeList)
        setTableTypeMap(temptTableTypeMap)

        return
      }
    }
  }

  const getDragOverlay = useCallback(() => {
    if (!activeId) {
      return null
    }

    const group = activeId.startsWith('grouped-section-') ? activeId.replace('grouped-section-', '') : ''
    if (group) {
      const list = displayList.find((tableType) => tableType.group === group)?.list
      return (
        <div className="bottom">
          <TableTypeSection
            timesectionIdx={''}
            sectionList={list}
            droppableId={`grouped-section-${group}`}
            groupLabel={group}
            resource={resource}
            item={item}
            tableTypeMap={tableTypeMap}
            handleCloneTableType={() => {}}
            handleDeleteTableTypeList={() => {}}
            setSelectedType={() => {}}
            idList={idList}
          />
        </div>
      )
    }

    const draggingItem = tableTypeMap.get(activeId)

    return (
      <>
        <div className={'sub-item queue-table-type'}>
          <TableType
            resource={resource}
            item={item}
            tableType={draggingItem}
            tableTypeForegroundColor={tableTypeMap.get(activeId).displayTableTypeForegroundColor}
            tableTypeBackgroundColor={tableTypeMap.get(activeId).displayTableTypeBackgroundColor}
            handleCloneTableType={() => {}}
            handleDeleteTableTypeList={() => {}}
          />
        </div>
      </>
    )
  }, [activeId])

  return (
    <DndContext
      collisionDetection={customCollisionDetectionAlgorithm}
      strategy={closestCorners}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragOver={_.debounce(handleDragOver, 10)}
      sensors={sensors}
    >
      <SortableContent
        idList={idList}
        displayList={displayList}
        tableTypeMap={tableTypeMap}
        timesectionIdx={timesectionIdx}
        resource={resource}
        item={item}
        handleCloneTableType={handleCloneTableType}
        handleDeleteTableTypeList={handleDeleteTableTypeList}
        setSelectedType={setSelectedType}
      />

      <DragOverlay>{getDragOverlay()}</DragOverlay>
    </DndContext>
  )
}

const getDisplayList = (newTableTypeList, tableTypeMap) => {
  const temptIndexMap = new Map()
  const temptNewDisplayList = []

  for (const tableType of newTableTypeList) {
    const group = tableTypeMap.get(tableType).groupedSection
    if (group) {
      if (!temptIndexMap.has(group)) {
        temptNewDisplayList.push({ group: group, list: [tableType] })
        temptIndexMap.set(group, temptNewDisplayList.length - 1)
        continue
      }

      temptNewDisplayList[temptIndexMap.get(group)].list = [...temptNewDisplayList[temptIndexMap.get(group)].list, tableType]
    } else {
      temptNewDisplayList.push(tableType)
    }
  }
  return temptNewDisplayList
}
