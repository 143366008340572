import _ from "lodash";
import { ADMIN_DEVICE } from '../actions/types';
import { constants } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        adminDevice: _.cloneDeep(constants.ADMIN_DEVICE_INPUT)
    }
}

const adminDeviceReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADMIN_DEVICE.SET_ADMIN_DEVICE_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case ADMIN_DEVICE.SET_ADMIN_DEVICE:
        case ADMIN_DEVICE.SET_ADMIN_DEVICE_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    adminDevice: action.adminDevice
                }
            })
        default:
            return state
    }
}

export default adminDeviceReducer