import { CHARGE_DATE } from './types';
import _ from "lodash";

export const setChargeDateDefault = () => {
    return {
        type: CHARGE_DATE.SET_CHARGE_DATE_DEFAULT,
    }
}

export const setChargeDate = (chargeDate) => {
    return {
        type: CHARGE_DATE.SET_CHARGE_DATE,
        chargeDate: chargeDate
    }
}

export const setChargeDateValue = (draftType, orignalProduct, key, value, index) => {
    const chargeDate = _.cloneDeep(orignalProduct);
    switch (draftType) {
        default:
            chargeDate[draftType] = value;
    }
    return {
        type: CHARGE_DATE.SET_CHARGE_DATE_VALUE,
        chargeDate: chargeDate
    }
}

export const setChargeDateList = (chargeDateList) => {
    return {
        type: CHARGE_DATE.SET_CHARGE_DATE_LIST,
        chargeDateList: chargeDateList
    }
}
