import { constants, RESERVATION_TABLE_TYPE_LIST_INPUT } from '../../Constants/constants'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'

import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import CancelIcon from '@mui/icons-material/Cancel'

import { BaseWrapper } from '../../Component/BaseWrapper'
import {
  setReservationTemplate,
  setReservationTemplateTimeSectionValue,
  setReservationTemplateTableTypeValue,
} from '../../actions/reservationTemplateAction'
import { setLoading } from '../../actions/systemAction'
import { TempBtn } from '../../Component/Input/TempBtn'
import { tableColor } from '../../helper/util'
import ReservationTemplateValidation from '../../validations/reservationTemplateValidation'
import { reservationTemplateValidationConstants } from '../../Constants/validationConstants'
import { getString } from '../../helper/util'

export const ReservationTemplateTimeSectionList = ({ resource, setSelectedType, setCustomIdNumber, customIdNumber }) => {
  const dispatch = useDispatch()
  const savedTimeSection = useSelector((state) => state.reservationTemplate.ui.timeSection)
  const savedTableType = useSelector((state) => state.reservationTemplate.ui.tableType)
  const savedReservationTemplate = useSelector((state) => state.reservationTemplate.ui.reservationTemplate)
  const lang = useSelector((state) => state.lang.ui.lang)
  const theme = useSelector((state) => state.theme.ui.theme)

  function handleAddReservationTemplateList() {
    const tempReservationTemplate = _.cloneDeep(savedReservationTemplate)
    const newReservationTemplateInput = _.cloneDeep(constants.RESERVATION_TEMPLATE_INPUT)
    const newTableTypeInput = _.cloneDeep(RESERVATION_TABLE_TYPE_LIST_INPUT)
    let newCustomIdNumber = _.cloneDeep(customIdNumber)
    newReservationTemplateInput.customId = 'section_' + newCustomIdNumber
    newCustomIdNumber = newCustomIdNumber + 1
    tempReservationTemplate.push(newReservationTemplateInput)
    dispatch(setReservationTemplateTableTypeValue(newTableTypeInput))
    dispatch(setReservationTemplateTimeSectionValue(newReservationTemplateInput))
    dispatch(setReservationTemplate(tempReservationTemplate))
    setSelectedType('timeSection')
    setCustomIdNumber(newCustomIdNumber + 1)
    ReservationTemplateValidation.validateTemplateTimeSection(tempReservationTemplate)
  }

  function handleAddTableTypeList(item) {
    const newTableType = _.cloneDeep(RESERVATION_TABLE_TYPE_LIST_INPUT)
    const newReservationTemplateInput = _.cloneDeep(constants.RESERVATION_TEMPLATE_INPUT)
    const newItem = _.cloneDeep(item)
    const tempReservationTemplate = _.cloneDeep(savedReservationTemplate)
    let tempTableTypeIndex = tempReservationTemplate.findIndex(
      (tempReservationTemplateValue) => tempReservationTemplateValue.customId === newItem.customId
    )
    let tempTableType = tempReservationTemplate.find((tempReservationTemplateValue) => tempReservationTemplateValue.customId === newItem.customId)
    newTableType.id = null
    newTableType.timeSectionCustomId = tempTableType.customId
    newTableType.customId = 'table_' + customIdNumber
    tempReservationTemplate[tempTableTypeIndex].tableTypeList.push(newTableType)
    dispatch(setReservationTemplateTableTypeValue(newTableType))
    dispatch(setReservationTemplateTimeSectionValue(newReservationTemplateInput))
    dispatch(setReservationTemplate(tempReservationTemplate))
    setSelectedType('tableType')
    setCustomIdNumber(customIdNumber + 1)
    ReservationTemplateValidation.validateTemplateTableType(tempReservationTemplate)
  }

  function handleCloneReservationTemplate(item) {
    const tempReservationTemplate = _.cloneDeep(savedReservationTemplate)
    const newTableTypeInput = _.cloneDeep(RESERVATION_TABLE_TYPE_LIST_INPUT)
    const newItem = _.cloneDeep(item)
    let newCustomIdNumber = _.cloneDeep(customIdNumber)
    newItem.id = null
    newItem.customId = 'section_' + newCustomIdNumber
    newItem.timeSectionId = ''
    newCustomIdNumber = newCustomIdNumber + 1
    newItem.tableTypeList.forEach((tableType) => {
      tableType.id = null
      tableType.timeSectionCustomId = newItem.customId
      tableType.customId = 'table_' + newCustomIdNumber
      newCustomIdNumber = newCustomIdNumber + 1
    })
    tempReservationTemplate.push(newItem)
    dispatch(setReservationTemplateTableTypeValue(newTableTypeInput))
    dispatch(setReservationTemplateTimeSectionValue(newItem))
    dispatch(setReservationTemplate(tempReservationTemplate))
    setSelectedType('timeSection')
    setCustomIdNumber(newCustomIdNumber + 1)
    ReservationTemplateValidation.validateTemplateTimeSection(tempReservationTemplate)
  }

  function handleCloneTableType(item, tableType) {
    const newItem = _.cloneDeep(item)
    const newTableType = _.cloneDeep(tableType)
    const tempReservationTemplate = _.cloneDeep(savedReservationTemplate)
    let tempTableTypeIndex = tempReservationTemplate.findIndex(
      (tempReservationTemplateValue) => tempReservationTemplateValue.customId === newItem.customId
    )
    let tempTableType = tempReservationTemplate.find((tempReservationTemplateValue) => tempReservationTemplateValue.customId === newItem.customId)
    const newReservationTemplateInput = _.cloneDeep(constants.RESERVATION_TEMPLATE_INPUT)
    newTableType.id = null
    newTableType.timeSectionCustomId = tempTableType.customId
    newTableType.customId = 'table_' + customIdNumber
    newTableType.tableType = ''
    tempReservationTemplate[tempTableTypeIndex].tableTypeList.push(newTableType)
    dispatch(setReservationTemplateTableTypeValue(newTableType))
    dispatch(setReservationTemplateTimeSectionValue(newReservationTemplateInput))
    dispatch(setReservationTemplate(tempReservationTemplate))
    setSelectedType('tableType')
    setCustomIdNumber(customIdNumber + 1)
    ReservationTemplateValidation.validateTemplateTableType(tempReservationTemplate)
  }

  function handleDeleteReservationTemplateList(item) {
    const newItem = _.cloneDeep(item)
    const tempReservationTemplate = _.cloneDeep(savedReservationTemplate)
    dispatch(setReservationTemplate(tempReservationTemplate.filter((reservationTemplate) => newItem.customId !== reservationTemplate.customId)))
    ReservationTemplateValidation.validateTemplateTimeSection(
      tempReservationTemplate.filter((reservationTemplate) => newItem.customId !== reservationTemplate.customId)
    )
    const tempTimeSection = _.cloneDeep(savedTimeSection)
    const tempTableType = _.cloneDeep(savedTableType)
    if (tempTimeSection.customId === newItem.customId) {
      const newReservationTemplateInput = _.cloneDeep(constants.RESERVATION_TEMPLATE_INPUT)
      dispatch(setReservationTemplateTimeSectionValue(newReservationTemplateInput))
    } else if (tempTableType.timeSectionCustomId === newItem.customId) {
      const newTableTypeInput = _.cloneDeep(RESERVATION_TABLE_TYPE_LIST_INPUT)
      dispatch(setReservationTemplateTableTypeValue(newTableTypeInput))
    }
    setSelectedType('')
  }

  function handleDeleteTableTypeList(item, tableType) {
    const newItem = _.cloneDeep(item)
    const newTableType = _.cloneDeep(tableType)
    const tempReservationTemplate = _.cloneDeep(savedReservationTemplate)
    let tempTableTypeIndex = tempReservationTemplate.findIndex(
      (tempReservationTemplateValue) => tempReservationTemplateValue.customId === newItem.customId
    )
    let tempTableType = tempReservationTemplate.find((tempReservationTemplateValue) => tempReservationTemplateValue.customId === newItem.customId)
    const tempSavedTableType = _.cloneDeep(savedTableType)
    if (tempSavedTableType.customId === newTableType.customId) {
      const newTableTypeInput = _.cloneDeep(RESERVATION_TABLE_TYPE_LIST_INPUT)
      dispatch(setReservationTemplateTableTypeValue(newTableTypeInput))
    }

    tempTableType.tableTypeList = tempTableType.tableTypeList.filter((tempTableTypeValue) => newTableType.customId !== tempTableTypeValue.customId)
    tempReservationTemplate[tempTableTypeIndex] = tempTableType
    dispatch(setReservationTemplate(tempReservationTemplate))
    setSelectedType('')
    ReservationTemplateValidation.validateTemplateTableType(tempReservationTemplate)
  }

  return (
    <div className="queue-template-content-wrapper">
      <BaseWrapper>
        <div className="queue-template-array-list-wrapper">
          <div className="sub-title">{getString(lang, resource, 'timeSectionList', theme)} : </div>
          <>
            <TempBtn
              btnSetting={{
                className: 'margin-left-16',
                onClick: () => {
                  handleAddReservationTemplateList()
                },
                variant: constants.STYLE.VARIANT.CONTAINED,
                color: constants.STYLE.PRIMARY,
                label: { resource: resource, key: 'addReservationTemplate' },
                icon: <AddIcon />,
              }}
            ></TempBtn>
          </>
        </div>
        {ReservationTemplateValidation.getErrorMessages('timeSection') &&
          ReservationTemplateValidation.getErrorMessages('timeSection').length > 0 && (
            <div className="error-message">
              <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
              {ReservationTemplateValidation.getErrorMessages('timeSection')}
            </div>
          )}
        <div className="queue-template-content-content">
          <div className="queue-template-tab-background">
            {savedReservationTemplate.map((item, index) => {
              let error = false
              if (
                ReservationTemplateValidation.getErrorMessages(
                  reservationTemplateValidationConstants.KEY_RESERVATION_TEMPLATE_ERROR,
                  item.customId
                ).map((error) => {
                  return error
                }).length > 0 ||
                ReservationTemplateValidation.getErrorMessages(reservationTemplateValidationConstants.KEY_TABLE_TYPE_LIST, item.customId).map(
                  (error) => {
                    return error
                  }
                ).length > 0
              ) {
                error = true
              }
              item.tableTypeList.map((tableType) => {
                if (
                  ReservationTemplateValidation.getErrorMessages(reservationTemplateValidationConstants.KEY_TABLE_TYPE_ERROR, tableType.customId).map(
                    (error) => {
                      return error
                    }
                  ).length > 0
                ) {
                  error = true
                }
                return tableType
              })
              let list = ''
              list = (
                <div
                  key={item.customId}
                  className={
                    item.customId === savedTimeSection.customId
                      ? 'queue-template-list-item queue-template-selected-product'
                      : error
                      ? 'queue-template-list-item queue-template-error'
                      : 'queue-template-list-item'
                  }
                  onClick={(e) => {
                    dispatch(setLoading(true))
                    e.stopPropagation()
                    dispatch(setReservationTemplateTimeSectionValue(item))
                    const newTableTypeInput = _.cloneDeep(RESERVATION_TABLE_TYPE_LIST_INPUT)
                    dispatch(setReservationTemplateTableTypeValue(newTableTypeInput))
                    setSelectedType('timeSection')
                    dispatch(setLoading(false))
                  }}
                >
                  <div className="code">
                    <div>{getString(lang, resource, 'id', theme) + ': ' + item.customId}</div>
                    <div className="type">{getString(lang, resource, 'timeSectionId', theme) + ': ' + item.timeSectionId}</div>
                  </div>
                  <div className="content">
                    <div className="top">
                      <div className="name">{item.timeSectionTcLabel}</div>
                      <div className="delete-button">
                        <TempBtn
                          btnSetting={{
                            className: 'delete-button delete-button-margin',
                            onClick: (e) => {
                              dispatch(setLoading(true))
                              handleCloneReservationTemplate(item)
                              dispatch(setLoading(false))
                            },
                            color: constants.STYLE.PRIMARY,
                            variant: constants.STYLE.VARIANT.CONTAINED,
                            label: { key: 'Clone' },
                            icon: <FileCopyIcon />,
                          }}
                        ></TempBtn>
                        {!item.id && (
                          <TempBtn
                            btnSetting={{
                              className: 'delete-button',
                              onClick: (e) => {
                                dispatch(setLoading(true))
                                handleDeleteReservationTemplateList(item)
                                dispatch(setLoading(false))
                              },
                              color: constants.STYLE.PRIMARY,
                              variant: constants.STYLE.VARIANT.CONTAINED,
                              label: { key: 'deleteBtn' },
                              icon: <DeleteIcon />,
                            }}
                          ></TempBtn>
                        )}
                      </div>
                    </div>
                    <div className="bottom">
                      {item.tableTypeList.map((tableType, tableTypeIndex) => {
                        return (
                          <div
                            key={tableType.tableType + ' ' + tableTypeIndex}
                            className={
                              tableType.customId === savedTableType.customId
                                ? `sub-item queue-template-selected-product${'-' + tableColor(tableType.tableType)}`
                                : 'sub-item'
                            }
                            style={{ backgroundColor: tableType.customId === savedTableType.customId ? '#BC67F3' : 'rgba(25, 0, 255, 0.1)' }}
                            onClick={(e) => {
                              dispatch(setLoading(true))
                              e.stopPropagation()
                              const newReservationTemplateInput = _.cloneDeep(constants.RESERVATION_TEMPLATE_INPUT)
                              dispatch(setReservationTemplateTimeSectionValue(newReservationTemplateInput))
                              dispatch(setReservationTemplateTableTypeValue(tableType))
                              setSelectedType('tableType')
                              dispatch(setLoading(false))
                            }}
                          >
                            <div className="sub-content">
                              <div className="sub-code">
                                <div
                                  className={
                                    tableType.customId === savedTableType.customId
                                      ? 'sub-type-selected'
                                      : `sub-type${'-' + tableColor(tableType.tableType)}`
                                  }
                                  style={{ backgroundColor: tableType.customId === savedTableType.customId ? 'black' : '#BC67F3' }}
                                >
                                  {getString(lang, resource, 'tableType', theme) + ': ' + tableType.tableType}
                                </div>
                              </div>
                              <div className="sub-item-name">{tableType ? tableType.tableTypeTcLabel : ''}</div>
                              <div
                                className="queue-template-button-on-top"
                                onClick={(e) => {
                                  dispatch(setLoading(true))
                                  e.stopPropagation()
                                  handleCloneTableType(item, tableType)
                                  dispatch(setLoading(false))
                                }}
                              >
                                <FileCopyIcon fontSize="small" />
                              </div>
                              {!tableType.id && (
                                <div
                                  className="queue-template-button-on-top"
                                  onClick={(e) => {
                                    dispatch(setLoading(true))
                                    e.stopPropagation()
                                    handleDeleteTableTypeList(item, tableType)
                                    dispatch(setLoading(false))
                                  }}
                                >
                                  <DeleteIcon fontSize="small" />
                                </div>
                              )}
                            </div>
                            {ReservationTemplateValidation.getErrorMessages(
                              reservationTemplateValidationConstants.KEY_TABLE_TYPE_ERROR,
                              tableType.customId
                            ).map((error) => {
                              return error
                            }) &&
                              ReservationTemplateValidation.getErrorMessages(
                                reservationTemplateValidationConstants.KEY_TABLE_TYPE_ERROR,
                                tableType.customId
                              ).map((error) => {
                                return error
                              }).length > 0 && (
                                <div className={tableType.customId === savedTableType.customId ? 'error-message-dark-red' : 'error-message'}>
                                  <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                                  {ReservationTemplateValidation.getErrorMessages(
                                    reservationTemplateValidationConstants.KEY_TABLE_TYPE_ERROR,
                                    tableType.customId
                                  ).map((error) => {
                                    return error
                                  })}
                                </div>
                              )}
                          </div>
                        )
                      })}
                      {(item.status === 'A' || !item.id) && (
                        <TempBtn
                          btnSetting={{
                            className: 'queue-template-button-on-top sub-item-add-button',
                            onClick: (e) => {
                              handleAddTableTypeList(item)
                            },
                            color: constants.STYLE.PRIMARY,
                            variant: constants.STYLE.VARIANT.CONTAINED,
                            label: { resource: resource, key: 'addTableType' },
                            icon: <AddIcon />,
                          }}
                        ></TempBtn>
                      )}
                    </div>
                    {ReservationTemplateValidation.getErrorMessages(reservationTemplateValidationConstants.KEY_TABLE_TYPE_LIST, item.customId).map(
                      (error) => {
                        return error
                      }
                    ) &&
                      ReservationTemplateValidation.getErrorMessages(reservationTemplateValidationConstants.KEY_TABLE_TYPE_LIST, item.customId).map(
                        (error) => {
                          return error
                        }
                      ).length > 0 && (
                        <div className="error-message">
                          <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                          {ReservationTemplateValidation.getErrorMessages(
                            reservationTemplateValidationConstants.KEY_TABLE_TYPE_LIST,
                            item.customId
                          ).map((error) => {
                            return error
                          })}
                        </div>
                      )}
                    {ReservationTemplateValidation.getErrorMessages(
                      reservationTemplateValidationConstants.KEY_RESERVATION_TEMPLATE_ERROR,
                      item.customId
                    ).map((error) => {
                      return error
                    }) &&
                      ReservationTemplateValidation.getErrorMessages(
                        reservationTemplateValidationConstants.KEY_RESERVATION_TEMPLATE_ERROR,
                        item.customId
                      ).map((error) => {
                        return error
                      }).length > 0 && (
                        <div className="error-message">
                          <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                          {ReservationTemplateValidation.getErrorMessages(
                            reservationTemplateValidationConstants.KEY_RESERVATION_TEMPLATE_ERROR,
                            item.customId
                          ).map((error) => {
                            return error
                          })}
                        </div>
                      )}
                  </div>
                </div>
              )
              return list
            })}
          </div>
        </div>
      </BaseWrapper>
    </div>
  )
}
