import { CHANGE_REMIND } from '../actions/types';

const INITIAL_STATE = {
    ui: {
        onChange: false,
        changeRemind: false,
    }
}

const changeRemindReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CHANGE_REMIND.SET_CHANGE_REMIND_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case CHANGE_REMIND.SET_ON_CHANGE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    onChange: action.onChange
                }
            })
        case CHANGE_REMIND.SET_CHANGE_REMIND_DIALOG:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    changeRemind: action.changeRemind
                }
            })
        default:
            return state
    }
}

export default changeRemindReducer