import { THEME } from '../actions/types';

const INITIAL_STATE = {
    ui: {
        theme: null,
    }
}

const themeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case THEME.SET_THEME:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    theme: action.theme,
                }
            })
        default:
            return state
    }
}

export default themeReducer