import { AREA } from './types';
import { sify } from "chinese-conv";
import _ from "lodash";

export const setAreaDefault = () => {
    return {
        type: AREA.SET_AREA_DEFAULT,
    }
}

export const setArea = (area) => {
    return {
        type: AREA.SET_AREA,
        area: area
    }
}

export const setAreaValue = (draftType, orignalProduct, key, value, index) => {
    const area = _.cloneDeep(orignalProduct);
    switch (draftType) {
        case "CHANGE_TC_NAME_AND_SC_NAME":
            area.tcName = value;
            area.scName = sify(value);
            break;
        case "scNameDisable":
            area[draftType] = value;
            if(value) {
                area.scName = area.tcName?sify(area.tcName):area.tcName;
            }
            break;
        default:
            area[draftType] = value;
    }
    return {
        type: AREA.SET_AREA_VALUE,
        area: area
    }
}
