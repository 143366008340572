import _ from "lodash";
import { MERCHANT_USER_GROUP } from '../actions/types';
import { constants } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        merchantUserGroup: _.cloneDeep(constants.MERCHANT_USER_GROUP_INPUT)
    }
}

const merchantUserGroupReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MERCHANT_USER_GROUP.SET_MERCHANT_USER_GROUP_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case MERCHANT_USER_GROUP.SET_MERCHANT_USER_GROUP:
        case MERCHANT_USER_GROUP.SET_MERCHANT_USER_GROUP_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    merchantUserGroup: action.merchantUserGroup
                }
            })
        default:
            return state
    }
}

export default merchantUserGroupReducer