import { constants } from '../../Constants/constants';
import ProductCreateValidation from "../../validations/productCreateValidation";
import { productValidationConstants } from '../../Constants/validationConstants';
import { setProductStepError } from  "../../actions/productCreateAction";

export const getProductStepError = (dispatch) => {
  let error = [];
  // if (ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_USER_GROUP_CODE).length > 0) {
  //   error.push(constants.PRODUCT_DRAFT_STEP.USER_GROUP)
  // }
  if (
    ProductCreateValidation.categoryErrorMap.size > 0 || ProductCreateValidation.categoryImageErrorMap.size > 0
  ) {
    // error.push(1)
    error.push(constants.PRODUCT_DRAFT_STEP.CATEGORY)
  }
  if (ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_REDEEM_GROUP_LIST).length > 0 ||
    ProductCreateValidation.redeemGroupErrorMap.size > 0) {
    // error.push(2)
    error.push(constants.PRODUCT_DRAFT_STEP.REDEEM_GROUP)
  }
  if (ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_LIST).length > 0 ||
    ProductCreateValidation.productErrorMap.size > 0) {
    // error.push(3)
    error.push(constants.PRODUCT_DRAFT_STEP.PRODUCT)
  }
  if (ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_LIST).length > 0 ||
    ProductCreateValidation.productLotErrorMap.size > 0) {
    // error.push(4)
    error.push(constants.PRODUCT_DRAFT_STEP.PRODUCT_LOT)
  }
  dispatch(setProductStepError(error));
}