import { useState, useEffect } from "react";
import { useDispatch, useStore } from "react-redux";
import _ from "lodash";

import { Card } from '@mui/material';

import { ListDisplay } from '../../Component/ListDisplay/ListDisplay';

import { constants } from '../../Constants/constants';
import { getList } from "../../helper/baseFetch";
import { setLoading } from "../../actions/systemAction";
import { getSalesName } from "../../Component/SalesName/getSalesName";
import { generateTableCellToMultiLanguageName } from "../../helper/util";

export const RestaurantApprovalSalesRackProductList = ({ newListTitles, newFilter }) => {
  const dispatch = useDispatch();
  const store = useStore();
  const [restaurantApproveListList, setRestaurantApproveListList] = useState([]);
  const [ready, setReady] = useState(false);
  const resource = "restaurantApprovalSalesRackProduct";

  const restaurantApproveListTitleList = newListTitles ? newListTitles : [
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "salesName",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "restUrlId",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "restName",
      sort: false,
      minSize: true,
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "productCode",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "productName",
      sort: false
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "status",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.DATE,
      value: "startDisplayTimestamp",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.DATE,
      value: "endDisplayTimestamp",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.DATE,
      value: "updateTimestamp",
      sort: true
    },
  ];

  const listFilters = newFilter ? newFilter : [
    {
      className: "filter-input",
      label: { resource: resource, key: "restUrlId" },
      clear: true,
      value: '',
      name: "restUrlId",
      size: constants.SIZE.SMALL
    },
    {
      className: "filter-input",
      label: { resource: resource, key: "restName" },
      clear: true,
      value: '',
      name: "restName",
      size: constants.SIZE.SMALL
    },
    {
      className: "filter-input",
      label: { resource: resource, key: "salesName" },
      value: "",
      type: constants.TYPE.SELECT,
      selectValue: [],
      name: "salesName",
      size: constants.SIZE.SMALL
    },
    {
      className: "filter-input",
      label: { resource: resource, key: "productName" },
      clear: true,
      value: '',
      name: "productName",
      size: constants.SIZE.SMALL
    },
    {
      className: "filter-input",
      label: { resource: resource, key: "status" },
      value: "",
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.RESTAURANT_APPROVE.APPROVAL_SALES_RACK_PRODUCT_STATUS,
      name: "status",
      size: constants.SIZE.SMALL
    },
  ];

  const [param, setParam] = useState({
    path: "",
    page: 0,
    rowsPerPage: 10,
    sort: 'updateTimestamp',
    direction: 'DESC',
    listFilters: listFilters,
    count: 0,
  })

  function onChangeParam(value) {
    value.count = value.count + 1;
    setParam(value);
  }

  function onChangeListFilters(list) {
    let tempParam = _.cloneDeep(param);
    tempParam.listFilters = list;
    tempParam.page = 0;
    onChangeParam(tempParam);
  }

  function onChangeListSort(sortItem) {
    let tempParam = _.cloneDeep(param);
    tempParam.sort = sortItem;
    tempParam.direction = tempParam.direction === "DESC" ? "ASC" : "DESC";
    onChangeParam(tempParam);
  }

  function onChangeListPage(page) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = page;
    onChangeParam(tempParam);
  }

  function onChangeListRowsPerPage(rowsPerPage) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = 0;
    tempParam.rowsPerPage = rowsPerPage;
    onChangeParam(tempParam);
  }

  const handleSalesName = async () => {
    let salesName = await getSalesName(param, store);
    setParam(salesName);
    setReady(true);
    return salesName;
  }

  useEffect(() => {
    let tempParam = _.cloneDeep(param);
    const filter = {};
    tempParam.listFilters.forEach((filterItem) => {
      if (filterItem.value !== '' && filterItem.value !== undefined) {
        filter[filterItem.name] = filterItem.value;
      }
    })

    getList(constants.PATH.APPROVAL.RESTAURANT_APPROVAL_SALES_RACK_PRODUCT, { sort: { field: tempParam.sort, order: tempParam.direction }, filter: filter, pagination: { page: tempParam.page + 1, perPage: tempParam.rowsPerPage } }, (payload) => {
      payload.map((item) => {
        item.restName = generateTableCellToMultiLanguageName(item.restTcName, item.restEnName, item.restScName);
        item.productName = generateTableCellToMultiLanguageName(item.productTcName, item.productEnName, item.productScName);
        return item
      });
      setRestaurantApproveListList(payload);
      dispatch(setLoading(false));
      if (!ready) {
        handleSalesName();
      }
    }, undefined, store);

  }, [param.count]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <Card className="card-wrapper overflow">
      <ListDisplay
        page={param.page}
        rowsPerPage={param.rowsPerPage}
        onChangeListSort={onChangeListSort}
        resource={resource}
        dataList={restaurantApproveListList}
        sort={param.sort}
        direction={param.direction}
        listTitles={restaurantApproveListTitleList}
        listFilters={param.listFilters}
        onChangeListPage={onChangeListPage}
        onChangeListRowsPerPage={onChangeListRowsPerPage}
        editBtn={false}
        createBtn={false}
        onChangeListFilters={onChangeListFilters}
        redirectPath={"/" + constants.PATH.APPROVAL.RESTAURANT_APPROVAL_SALES_RACK_PRODUCT + "/"}
      />
    </Card>
  )
};