import { QUEUE_PROFILE } from './types'
import _ from 'lodash'
import { sify } from 'chinese-conv'

export const setQueueProfileDefault = () => {
  return {
    type: QUEUE_PROFILE.SET_QUEUE_PROFILE_DEFAULT,
  }
}

export const setQueueProfile = (queueProfile) => {
  return {
    type: QUEUE_PROFILE.SET_QUEUE_PROFILE,
    queueProfile: queueProfile,
  }
}

export const setQueueProfileValue = (draftType, orignalProduct, key, value, index) => {
  const queueProfile = _.cloneDeep(orignalProduct)
  switch (draftType) {
    case 'tcName':
      queueProfile.tcName = value
      queueProfile.scName = queueProfile.scNameDisable ? sify(value) : queueProfile.scName
      break
    case 'scNameDisable':
      queueProfile.scNameDisable = value
      if (value) {
        queueProfile.scName = !queueProfile.tcName ? queueProfile.scName : sify(queueProfile.tcName)
      }
      break
    default:
      queueProfile[draftType] = value
  }
  return {
    type: QUEUE_PROFILE.SET_QUEUE_PROFILE_VALUE,
    queueProfile: queueProfile,
  }
}
