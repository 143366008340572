import { brandValidationConstants } from '../Constants/validationConstants';

class BrandValidation {

  version;
  errorMap;
  brandErrorMap;
  valid;

  constructor() {

    if (!BrandValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.brandErrorMap = new Map();
      BrandValidation.instance = this;
    }
    return BrandValidation.instance;
  }

  validateEditBrand(brand) {
    this.resetErrorMessages();
    this.handleValidateEditBrand(brand);
    this.version++;
  }

  validateCreateBrand(brand) {
    this.resetErrorMessages();
    this.handleValidateCreateBrand(brand);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.brandErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key, uniqueCode) {
    if (!uniqueCode) {
      return (this.brandErrorMap.has(key)) ? this.brandErrorMap.get(key) : [];
    }
  }

  handleValidateEditBrand(brand) {
    // if (!brand.groupId) {
    //   this.setBrandErrorMapMessages(brandValidationConstants.KEY_GROUP_ID, "Missing Group ID");
    // }
    if (!brand.enName) {
      this.setBrandErrorMapMessages(brandValidationConstants.KEY_EN_NAME, "Missing EN Name");
    }
    if (!brand.tcName) {
      this.setBrandErrorMapMessages(brandValidationConstants.KEY_TC_NAME, "Missing TC Name");
    }
    if (!brand.scName) {
      this.setBrandErrorMapMessages(brandValidationConstants.KEY_SC_NAME, "Missing SC Name");
    }
    if (!brand.logoImage || (brand.logoImage && !brand.logoImage.cosFileUrl)) {
      this.setBrandErrorMapMessages(brandValidationConstants.KEY_LOGO_IMAGE, "Missing Logo Image");
    }
    if (!brand.status) {
      this.setBrandErrorMapMessages(brandValidationConstants.KEY_STATUS, "Missing Status");
    }
  }

  handleValidateCreateBrand(brand) {
    // if (!brand.groupId) {
    //   this.setBrandErrorMapMessages(brandValidationConstants.KEY_GROUP_ID, "Missing Group ID");
    // }
    if (!brand.enName) {
      this.setBrandErrorMapMessages(brandValidationConstants.KEY_EN_NAME, "Missing EN Name");
    }
    if (!brand.tcName) {
      this.setBrandErrorMapMessages(brandValidationConstants.KEY_TC_NAME, "Missing TC Name");
    }
    if (!brand.scName) {
      this.setBrandErrorMapMessages(brandValidationConstants.KEY_SC_NAME, "Missing SC Name");
    }
    if (!brand.logoImage || (brand.logoImage && !brand.logoImage.cosFileUrl)) {
      this.setBrandErrorMapMessages(brandValidationConstants.KEY_LOGO_IMAGE, "Missing Logo Image");
    }
  }


  setBrandErrorMapMessages(key, errorMessages, valid = false) {
    this.brandErrorMap.set(key, errorMessages);
    // console.log("brandErrorMap", this.brandErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new BrandValidation();
export default validation;