import { BANNER_ACTION } from '../Constants/constants';
import { pushValidationConstants } from '../Constants/validationConstants';

class PushValidation {

  version;
  errorMap;
  pushErrorMap;
  valid;

  constructor() {

    if (!PushValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.pushErrorMap = new Map();
      PushValidation.instance = this;
    }
    return PushValidation.instance;
  }

  validateEditPush(push) {
    this.resetErrorMessages();
    this.handleValidateEditPush(push);
    this.version++;
  }

  validateCreatePush(push) {
    this.resetErrorMessages();
    this.handleValidateCreatePush(push);
    this.version++;
  }

  resetErrorMessages(type) {

    switch(type){
      case pushValidationConstants.KEY_PARAMETERS:
        this.pushErrorMap.delete(pushValidationConstants.KEY_PARAMETERS);
        break;
      case pushValidationConstants.KEY_CHILDCODE:
        this.pushErrorMap.delete(pushValidationConstants.KEY_CHILDCODE);
        break;
      default:
        this.errorMap = new Map();
        this.pushErrorMap = new Map();
        this.valid = true;
      break;
    }
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key, uniqueCode) {
    if (!uniqueCode) {
      return (this.pushErrorMap.has(key)) ? this.pushErrorMap.get(key) : [];
    }
  }

  handleValidateEditPush(push) {
    if (!push.name) {
      this.setPushErrorMapMessages(pushValidationConstants.KEY_NAME, "Missing Name");
    }
    if (!push.enMessage) {
      this.setPushErrorMapMessages(pushValidationConstants.KEY_EN_MESSAGE, "Missing EN Message");
    }
    if (!push.tcMessage) {
      this.setPushErrorMapMessages(pushValidationConstants.KEY_TC_MESSAGE, "Missing TC Message");
    }
    if (!push.scMessage) {
      this.setPushErrorMapMessages(pushValidationConstants.KEY_SC_MESSAGE, "Missing SC Message");
    }
    if (!push.status) {
      this.setPushErrorMapMessages(pushValidationConstants.KEY_STATUS, "Missing Status");
    }
    if (!push.deliveryDate) {
      this.setPushErrorMapMessages(pushValidationConstants.KEY_DELIVERY_DATE, "Missing Delivery Date");
    }
    if (!push.inbox && push.inbox !== false) {
      this.setPushErrorMapMessages(pushValidationConstants.KEY_INBOX, "Missing Inbox");
    }
    if (!push.notification && push.notification !== false) {
      this.setPushErrorMapMessages(pushValidationConstants.KEY_NOTIFICATION, "Missing Notification");
    }
    if (!push.systemMessage && push.systemMessage !== false) {
      this.setPushErrorMapMessages(pushValidationConstants.KEY_SYSTEM_MESSAGE, "Missing System Message");
    }
    if (push.inbox === false
      && push.notification === false
      && push.systemMessage === false) {
      this.setPushErrorMapMessages(pushValidationConstants.KEY_INBOX, "Inbox, Notification, System Message should not be all false!");
      this.setPushErrorMapMessages(pushValidationConstants.KEY_NOTIFICATION, "Inbox, Notification, System Message should not be all false!");
      this.setPushErrorMapMessages(pushValidationConstants.KEY_SYSTEM_MESSAGE, "Inbox, Notification, System Message should not be all false!");
    }
    if (!push.actionType) {
      this.setPushErrorMapMessages(pushValidationConstants.KEY_ACTION_TYPE, "Missing Action Type");
    }
    // if (push.mobileList &&
    //   push.mobileList.filter((mobile) => mobile.trim()).length !== push.mobileList.length) {
    //   this.setPushErrorMapMessages(pushValidationConstants.KEY_MOBILE_LIST, "Missing Mobile List");
    // }
    if (push.actionType !== BANNER_ACTION.NONE && !push.parameters[push.inputName]) {
      this.setPushErrorMapMessages(pushValidationConstants.KEY_PARAMETERS, "Missing Parameters");
    }

    switch (push.actionType) {
      // case BANNER_ACTION.CATEGORY_PRODUCT:
      // case BANNER_ACTION.CATEGORY_PRODUCT_MAP:
      // case BANNER_ACTION.BRAND_PRODUCT:
      // case BANNER_ACTION.BRAND_PRODUCT_MAP:
      //   if (!push.parameters[push.inputName] || !push.parameters["productCode"]) {
      //     this.setPushErrorMapMessages(pushValidationConstants.KEY_PRODUCT_CODE, "Missing Product Code");
      //   }
      //   break;
      // case BANNER_ACTION.PIXEL:
      //   if (!push.parameters[push.inputName] || !push.parameters["name"]) {
      //     this.setPushErrorMapMessages(pushValidationConstants.KEY_PIXEL_NAME, "Missing Pixel Name");
      //   }
      //   if (push.parameters["haveRef"] !== true && push.parameters["haveRef"] !== false) {
      //     this.setPushErrorMapMessages(pushValidationConstants.KEY_PIXEL_HAVE_REF, "Missing Pixel Have Ref");
      //   }
      //   break;
      // case BANNER_ACTION.FOLDER:
      //   if (!push.parameters[push.inputName] || !push.parameters["folderType"]) {
      //     this.setPushErrorMapMessages(pushValidationConstants.KEY_FOLDER_TYPE, "Missing Folder Type");
      //   }
      //   break;
      case BANNER_ACTION.WEB_VIEW:
      case BANNER_ACTION.LINK:
        if (!push.parameters[push.inputName] || !push.parameters[push.inputName].match("^https?://+?[-a-zA-Z0-9@:%_+.~#?&//=]+")) {
          this.setPushErrorMapMessages(pushValidationConstants.KEY_PARAMETERS, "Invalid Parameters");
        }
        break;

      case BANNER_ACTION.PRODUCT:
      case BANNER_ACTION.E_COUPON:
        //check all?
        if (!push.parameters[push.inputName]) {
          this.setPushErrorMapMessages(pushValidationConstants.KEY_PARAMETERS, "Missing Code");
        }
        //console.log(push)
        if(push.parameters.productParent){
          if(
            (push.parameters.productParent === "BRAND" && !push.parameters.brandCode) ||
          (push.parameters.productParent === "CATEGORY" && !push.parameters.categoryCode)){
            this.setPushErrorMapMessages(pushValidationConstants.KEY_CHILDCODE, "Missing Code");
          }
        }
        break;
      default:
    };
  }

  handleValidateCreatePush(push) {
    if (!push.name) {
      this.setPushErrorMapMessages(pushValidationConstants.KEY_NAME, "Missing Name");
    }
    if (!push.enMessage) {
      this.setPushErrorMapMessages(pushValidationConstants.KEY_EN_MESSAGE, "Missing EN Message");
    }
    if (!push.tcMessage) {
      this.setPushErrorMapMessages(pushValidationConstants.KEY_TC_MESSAGE, "Missing TC Message");
    }
    if (!push.scMessage) {
      this.setPushErrorMapMessages(pushValidationConstants.KEY_SC_MESSAGE, "Missing SC Message");
    }
    if (!push.status) {
      this.setPushErrorMapMessages(pushValidationConstants.KEY_STATUS, "Missing Status");
    }
    if (!push.deliveryDate) {
      this.setPushErrorMapMessages(pushValidationConstants.KEY_DELIVERY_DATE, "Missing Delivery Date");
    }
    if (!push.inbox && push.inbox !== false) {
      this.setPushErrorMapMessages(pushValidationConstants.KEY_INBOX, "Missing Inbox");
    }
    if (!push.notification && push.notification !== false) {
      this.setPushErrorMapMessages(pushValidationConstants.KEY_NOTIFICATION, "Missing Notification");
    }
    if (!push.systemMessage && push.systemMessage !== false) {
      this.setPushErrorMapMessages(pushValidationConstants.KEY_SYSTEM_MESSAGE, "Missing System Message");
    }
    if (push.inbox === false
      && push.notification === false
      && push.systemMessage === false) {
      this.setPushErrorMapMessages(pushValidationConstants.KEY_INBOX, "Inbox, Notification, System Message should not be all false!");
      this.setPushErrorMapMessages(pushValidationConstants.KEY_NOTIFICATION, "Inbox, Notification, System Message should not be all false!");
      this.setPushErrorMapMessages(pushValidationConstants.KEY_SYSTEM_MESSAGE, "Inbox, Notification, System Message should not be all false!");
    }
    if (!push.actionType) {
      this.setPushErrorMapMessages(pushValidationConstants.KEY_ACTION_TYPE, "Missing Action Type");
    }
    if (push.actionType !== BANNER_ACTION.NONE && !push.parameters[push.inputName]) {
      this.setPushErrorMapMessages(pushValidationConstants.KEY_PARAMETERS, "Missing Parameters");
    }
    if (push.mobileList &&
      push.mobileList.filter((mobile) => mobile.trim()).length !== push.mobileList.length) {
      this.setPushErrorMapMessages(pushValidationConstants.KEY_MOBILE_LIST, "Missing Mobile List");
    }
    switch (push.actionType) {
      // case BANNER_ACTION.CATEGORY_PRODUCT:
      // case BANNER_ACTION.CATEGORY_PRODUCT_MAP:
      // case BANNER_ACTION.BRAND_PRODUCT:
      // case BANNER_ACTION.BRAND_PRODUCT_MAP:
      //   if (!push.parameters[push.inputName] || !push.parameters["productCode"]) {
      //     this.setPushErrorMapMessages(pushValidationConstants.KEY_PRODUCT_CODE, "Missing Product Code");
      //   }
      //   break;
      // case BANNER_ACTION.PIXEL:
      //   if (!push.parameters[push.inputName] || !push.parameters["name"]) {
      //     this.setPushErrorMapMessages(pushValidationConstants.KEY_PIXEL_NAME, "Missing Pixel Name");
      //   }
      //   if (push.parameters["haveRef"] !== true && push.parameters["haveRef"] !== false) {
      //     this.setPushErrorMapMessages(pushValidationConstants.KEY_PIXEL_HAVE_REF, "Missing Pixel Have Ref");
      //   }
      //   break;
      // case BANNER_ACTION.FOLDER:
      //   if (!push.parameters[push.inputName] || !push.parameters["folderType"]) {
      //     this.setPushErrorMapMessages(pushValidationConstants.KEY_FOLDER_TYPE, "Missing Folder Type");
      //   }
      //   break;
      case BANNER_ACTION.WEB_VIEW:
      case BANNER_ACTION.LINK:
        if (!push.parameters[push.inputName] || !push.parameters[push.inputName].match("^https?://+?[-a-zA-Z0-9@:%_+.~#?&//=]+")) {
          this.setPushErrorMapMessages(pushValidationConstants.KEY_PARAMETERS, "Invalid Parameters");
        }
        break;
      case BANNER_ACTION.PRODUCT:
      case BANNER_ACTION.E_COUPON:
        if (!push.parameters[push.inputName]) {
          this.setPushErrorMapMessages(pushValidationConstants.KEY_PARAMETERS, "Missing Code");
        }

        if(push.parameters.productParent){
          if(
          (push.parameters.productParent === "BRAND" && !push.parameters.brandCode) ||
          (push.parameters.productParent === "CATEGORY" && !push.parameters.categoryCode)){
            this.setPushErrorMapMessages(pushValidationConstants.KEY_CHILDCODE, "Missing Code");
          }
        }
        break;
      default:
    };
  }


  setPushErrorMapMessages(key, errorMessages, valid = false) {
    this.pushErrorMap.set(key, errorMessages);
    // console.log("pushErrorMap", this.pushErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new PushValidation();
export default validation;