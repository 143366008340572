import { restaurantApproveValidationConstants } from '../Constants/validationConstants';

class RestaurantApproveValidation {

  version;
  errorMap;
  restaurantApproveErrorMap;
  valid;

  constructor() {

    if (!RestaurantApproveValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.restaurantApproveErrorMap = new Map();
      RestaurantApproveValidation.instance = this;
    }
    return RestaurantApproveValidation.instance;
  }

  validateEditRestaurantApprove(restaurantApprove) {
    this.resetErrorMessages();
    this.handleValidateEditRestaurantApprove(restaurantApprove);
    this.version++;
  }

  validateCreateRestaurantApprove(restaurantApprove) {
    this.resetErrorMessages();
    this.handleValidateCreateRestaurantApprove(restaurantApprove);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.restaurantApproveErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key, uniqueCode) {
    if (!uniqueCode) {
      return (this.restaurantApproveErrorMap.has(key)) ? this.restaurantApproveErrorMap.get(key) : [];
    }
  }

  handleValidateEditRestaurantApprove(restaurantApprove) {
    if (!restaurantApprove.rejectReason || restaurantApprove.rejectReason.trim() === "") {
      this.setRestaurantApproveErrorMapMessages(restaurantApproveValidationConstants.KEY_REJECT_REASON, "Missing Reject Reason");
    }
  }

  handleValidateCreateRestaurantApprove(restaurantApprove) {
  }


  setRestaurantApproveErrorMapMessages(key, errorMessages, valid = false) {
    this.restaurantApproveErrorMap.set(key, errorMessages);
    // console.log("restaurantApproveErrorMap", this.restaurantApproveErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new RestaurantApproveValidation();
export default validation;