import { brandApproveValidationConstants } from '../Constants/validationConstants';

class BrandApproveValidation {

  version;
  errorMap;
  brandApproveErrorMap;
  valid;

  constructor() {

    if (!BrandApproveValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.brandApproveErrorMap = new Map();
      BrandApproveValidation.instance = this;
    }
    return BrandApproveValidation.instance;
  }

  validateEditBrandApprove(brandApprove) {
    this.resetErrorMessages();
    this.handleValidateEditBrandApprove(brandApprove);
    this.version++;
  }

  validateCreateBrandApprove(brandApprove) {
    this.resetErrorMessages();
    this.handleValidateCreateBrandApprove(brandApprove);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.brandApproveErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key, uniqueCode) {
    if (!uniqueCode) {
      return (this.brandApproveErrorMap.has(key)) ? this.brandApproveErrorMap.get(key) : [];
    }
  }

  handleValidateEditBrandApprove(brandApprove) {
    if (!brandApprove.rejectReason || brandApprove.rejectReason.trim() === "") {
      this.setBrandApproveErrorMapMessages(brandApproveValidationConstants.KEY_REJECT_REASON, "Missing Reject Reason");
    }
  }

  handleValidateCreateBrandApprove(brandApprove) {
  }


  setBrandApproveErrorMapMessages(key, errorMessages, valid = false) {
    this.brandApproveErrorMap.set(key, errorMessages);
    // console.log("brandApproveErrorMap", this.brandApproveErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new BrandApproveValidation();
export default validation;