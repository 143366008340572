import { passwordValidationConstants } from '../Constants/validationConstants';

class passwordValidation {

  version;
  errorMap;
  passwordErrorMap;
  valid;

  constructor() {

    if (!passwordValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.passwordErrorMap = new Map();
      passwordValidation.instance = this;
    }
    return passwordValidation.instance;
  }

  validateEditPassword(password) {
    this.resetErrorMessages();
    this.handleValidateEditPassword(password);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.passwordErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key, uniqueCode) {
    if (!uniqueCode) {
      return (this.passwordErrorMap.has(key)) ? this.passwordErrorMap.get(key) : [];
    }
  }

  handleValidateEditPassword(password) {
    // if (!password.oldPassword) {
    //   this.setPasswordErrorMapMessages(passwordValidationConstants.KEY_OLD_PASSWORD, "Missing Value");
    // }
    if (!password.newPassword) {
      this.setPasswordErrorMapMessages(passwordValidationConstants.KEY_NEW_PASSWORD, "Missing Value");
    }
    if (!password.verifyNewPassword) {
      this.setPasswordErrorMapMessages(passwordValidationConstants.KEY_VERIFY_PASSWORD, "Missing Value");
    }
    if(password.verifyNewPassword !== password.newPassword) {
      this.setPasswordErrorMapMessages(passwordValidationConstants.KEY_VERIFY_PASSWORD, "The re-renter Password is not the same as the new Password");
    }
  }

  setPasswordErrorMapMessages(key, errorMessages, valid = false) {
    this.passwordErrorMap.set(key, errorMessages);
    // console.log("tncErrorMap", this.tncErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new passwordValidation();
export default validation;