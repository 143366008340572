import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { constants, BANNER_TYPE } from '../../Constants/constants';
import { BannerEdit } from "../Banner/BannerEdit";

export const FolderBannerEdit = () => {
  const savedFolder = useSelector(state => state.folder.ui.folder);
  const resource = "FolderBanner";
  const params = useParams();

  return <BannerEdit
    newPath={constants.PATH.FOLDER.FOLDER_BANNER}
    dataId={params.id}
    mapId={savedFolder.id}
    mapName={{ resource: resource, key: "folderCode" }}
    mapCode={savedFolder.folderCode}
    redirectPath={constants.PATH.FOLDER.FOLDER}
    bannerType={BANNER_TYPE.FOLDER}
    tab={constants.FOLDER_TAB.BANNER_LIST} />
};