import { RESERVATION_CONFIG } from './types';
import _ from 'lodash';
import { sify } from 'chinese-conv';

export const setReservationConfigDefault = () => {
  return {
    type: RESERVATION_CONFIG.SET_RESERVATION_CONFIG_DEFAULT,
  };
};

export const setReservationConfig = (reservationConfig) => {
  return {
    type: RESERVATION_CONFIG.SET_RESERVATION_CONFIG,
    reservationConfig: reservationConfig,
  };
};

export const setReservationConfigValue = (draftType, orignalProduct, key, value, index) => {
  const reservationConfig = _.cloneDeep(orignalProduct);
  switch (draftType) {
    case 'CHANGE_TC_REMARKS_MESSAGE_AND_SC_REMARKS_MESSAGE':
      reservationConfig.remarksTcMessage = value;
      reservationConfig.remarksScMessage = value ? sify(value) : null;
      break;
    case 'CHANGE_TC_DISABLE_MESSAGE_AND_SC_DISABLE_MESSAGE':
      reservationConfig.disableTcMessage = value;
      reservationConfig.disableScMessage = value ? sify(value) : null;
      break;
    case 'CHANGE_TC_REMINDER_MESSAGE_AND_SC_REMINDER_MESSAGE':
      reservationConfig.reminderTcMessage = value;
      reservationConfig.reminderScMessage = value ? sify(value) : null;
      break;
    case 'CHANGE_TC_NO_TIME_SECTION_MESSAGE_AND_SC_NO_TIME_SECTION_MESSAGE':
      reservationConfig.noTimeSectionTcMessage = value;
      reservationConfig.noTimeSectionScMessage = value ? sify(value) : null;
      break;
    case 'CHANGE_TC_CHARGE_DESCRIPTION_AND_SC_CHARGE_DESCRIPTION_MESSAGE':
      reservationConfig.chargeTcDescription = value;
      reservationConfig.chargeScDescription = value ? sify(value) : null;
      break;
    case 'remarksScMessageDisable':
      reservationConfig[draftType] = value;
      if (value) {
        reservationConfig.remarksScMessage = reservationConfig.remarksTcMessage ? sify(reservationConfig.remarksTcMessage) : reservationConfig.remarksTcMessage;
      }
      break;
    case 'disableScMessageDisable':
      reservationConfig[draftType] = value;
      if (value) {
        reservationConfig.disableScMessage = reservationConfig.disableTcMessage ? sify(reservationConfig.disableTcMessage) : reservationConfig.disableTcMessage;
      }
      break;
    case 'reminderScMessageDisable':
      reservationConfig[draftType] = value;
      if (value) {
        reservationConfig.reminderScMessage = reservationConfig.reminderTcMessage
          ? sify(reservationConfig.reminderTcMessage)
          : reservationConfig.reminderTcMessage;
      }
      break;
    case 'noTimeSectionScMessageDisable':
      reservationConfig[draftType] = value;
      if (value) {
        reservationConfig.noTimeSectionScMessage = reservationConfig.noTimeSectionTcMessage
          ? sify(reservationConfig.noTimeSectionTcMessage)
          : reservationConfig.noTimeSectionTcMessage;
      }
      break;
    case 'chargeScDescriptionDisable':
      reservationConfig[draftType] = value;
      if (value) {
        reservationConfig.chargeScDescription = reservationConfig.chargeTcDescription
          ? sify(reservationConfig.chargeTcDescription)
          : reservationConfig.chargeTcDescription;
      }
      break;
    default:
      reservationConfig[draftType] = value;
  }
  return {
    type: RESERVATION_CONFIG.SET_RESERVATION_CONFIG_VALUE,
    reservationConfig: reservationConfig,
  };
};
