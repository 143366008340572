import { useSelector } from 'react-redux'
import { constants as co } from '../../Constants/constants'

export default function useLandmark() {
  const savedLandmark = useSelector((state) => state.landmark.ui.landmark)
  const lang = useSelector((state) => state.lang.ui.lang)

  const getLandmarkName = () => {
    switch (lang) {
      case co.LANG.EN:
        return savedLandmark?.enName || ''
      case co.LANG.SC:
        return savedLandmark?.scName || ''
      default:
        return savedLandmark?.tcName || ''
    }
  }

  const getLandmarkId = () => {
    return savedLandmark?.landmarkId || ''
  }

  const getLandmark = () => {
    return savedLandmark
  }

  const getLandmarkIdAndName = () => {
    const landmarkId = getLandmarkId()
    const landmarkName = getLandmarkName()

    return `${landmarkId} - ${landmarkName}`
  }

  return { getLandmarkId, getLandmarkName, getLandmarkIdAndName, getLandmark }
}
