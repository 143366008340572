import { sify } from 'chinese-conv'
import { makeStyles } from '@mui/styles'
import { HOST, LANDING_ACTION_PARAMETER, LANDING_SECTION_ACTION } from '../../../Constants/constants'
import queryString from 'query-string'
import { landingSectionValidationConstants } from '../../../Constants/validationConstants'

export const textPreview = (text) => {
  if (text.length <= 3) return text
  const preview = text.substring(0, 4) + '...'
  return preview
}

export const translateName = (text) => {
  return sify(text)
}

export const helperTextStyles = makeStyles((theme) => ({
  root: {
    color: 'red !important',
  },
}))

export const getDisplayActionType = (actionParameter) => {
  const [host, paramString] = actionParameter.url ? actionParameter.url.replace('thegulu://', '')?.split('?') : [null, '']
  const param = queryString.parse(paramString)
  switch (host) {
    case HOST.FOLDER:
      return LANDING_ACTION_PARAMETER.FOLDER
    case HOST.ECOUPON:
      if (!param.ecouponCode && param.categoryCode) {
        return LANDING_ACTION_PARAMETER.E_COUPON_CATEGORY
      }

      if (!param.ecouponCode && param.brandCode) {
        return LANDING_ACTION_PARAMETER.E_COUPON_BRAND
      }
      return LANDING_ACTION_PARAMETER.E_COUPON
    case HOST.LINK:
      return LANDING_ACTION_PARAMETER.LINK
    case HOST.PRODUCT:
      if (!param.productCode && param.categoryCode) {
        return LANDING_ACTION_PARAMETER.PRODUCT_CATEGORY
      }

      if (!param.productCode && param.brandCode) {
        return LANDING_ACTION_PARAMETER.PRODUCT_BRAND
      }
      return LANDING_ACTION_PARAMETER.PRODUCT
    case HOST.REST:
      return LANDING_ACTION_PARAMETER.RESTAURANT
    case HOST.SEARCH:
      return LANDING_ACTION_PARAMETER.SEARCH
    case HOST.SHOP_CATEGORY:
      return LANDING_ACTION_PARAMETER.RESTAURANT_CATEGORY
    case HOST.WEB:
      return LANDING_ACTION_PARAMETER.WEB_VIEW
    case HOST.YOUTUBE:
      return LANDING_ACTION_PARAMETER.YOUTUBE
    default:
      return LANDING_ACTION_PARAMETER.WEB_VIEW
  }
}

export const isValidDeepLink = (url) => {
  return url.match('^thegulu://+?[-a-zA-Z0-9@:%_+.~#?&//=]+')
}

export const isWebViewParametersValid = (url) => {
  if (!url || !isValidDeepLink(url)) {
    return { isValid: false, errorKey: null, errorMessage: null }
  }

  const [host, paramString] = url.replace('thegulu://', '')?.split('?')
  const param = queryString.parse(paramString)

  switch (host) {
    case HOST.REST:
      if (!param.restUrlId) {
        return { isValid: false, errorKey: landingSectionValidationConstants.KEY_PARAMETERS, errorMessage: 'Missing Parameter' }
      }
      break
    case HOST.SHOP_CATEGORY:
      return LANDING_ACTION_PARAMETER.RESTAURANT_CATEGORY
    case HOST.WEB:
      if (!param.action) {
        return { isValid: false, errorKey: landingSectionValidationConstants.KEY_PARAMETERS, errorMessage: 'Missing Parameter' }
      }
      break
    case HOST.YOUTUBE:
      if (!param.videoId) {
        return { isValid: false, errorKey: landingSectionValidationConstants.KEY_PARAMETERS, errorMessage: 'Missing Parameter' }
      }
      break
    case HOST.FOLDER:
    case HOST.PRODUCT:
    case HOST.SEARCH:
    case HOST.ECOUPON:
    case HOST.LINK:
      break
    default:
      return { isValid: false, errorKey: null, errorMessage: null }
  }

  return { isValid: true, errorKey: null, errorMessage: null }
}

export const isDeepLinkApplicable = (action) => {
  switch (action) {
    case LANDING_SECTION_ACTION.RESTAURANT:
    case LANDING_SECTION_ACTION.PRODUCT:
    case LANDING_SECTION_ACTION.PRODUCT_CATEGORY:
    case LANDING_SECTION_ACTION.E_COUPON_CATEGORY:
    case LANDING_SECTION_ACTION.PRODUCT_BRAND:
    case LANDING_SECTION_ACTION.E_COUPON_BRAND:
    case LANDING_SECTION_ACTION.COUPON:
    case LANDING_SECTION_ACTION.E_COUPON:
    case LANDING_SECTION_ACTION.SEARCH:
    case LANDING_SECTION_ACTION.FOLDER:
    case LANDING_SECTION_ACTION.VIDEO:
    case LANDING_SECTION_ACTION.YOUTUBE:
    case LANDING_SECTION_ACTION.RESTAURANT_CATEGORY:
    case LANDING_SECTION_ACTION.LINK:
    case LANDING_SECTION_ACTION.WEB_VIEW:
      return true
    default:
      return false
  }
}
