import { useState } from "react";
import { useSelector, useStore } from "react-redux";
import { constants } from '../../Constants/constants';

import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { BaseWrapper } from "../../Component/BaseWrapper";
import { getList } from "../../helper/baseFetch";
import { TempBtn } from "../../Component/Input/TempBtn";
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { getString } from "../../helper/util";
import { Deactivate } from "../../Component/AdminDevice/Deactivate";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableCell: {
    padding: "6px 24px 6px 16px",
    whiteSpace: "pre-wrap",
  },
});

export const TroubleShootingList = () => {
  const classes = useStyles();
  const store = useStore();
  const lang = useSelector(state => state.lang.ui.lang);
  const theme = useSelector(state => state.theme.ui.theme);
  const [ready, setReady] = useState(false);
  const [troubleShootingList, setTroubleShootingList] = useState([]);
  const [deviceId, setDeviceId] = useState("");

  function handleGetList(deviceId) {
    getList(constants.PATH.TROUBLE_SHOOTING, { filter: { deviceId: deviceId }, }, (payload) => {
      setTroubleShootingList(payload);
      setReady(true);
    }, undefined, store);
  }

  function handleResetData() {
    handleGetList(deviceId);
  }

  return (
    <Card className="card-wrapper overflow">
      <BaseWrapper>
        <TroubleShootingTitle lang={lang} handleGetList={handleGetList} deviceId={deviceId} setDeviceId={setDeviceId} theme={theme} />
        {ready &&
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{getString(lang, "AdminDevice", "activationCode", theme)}</TableCell>
                  <TableCell>{getString(lang, "AdminDevice", "deviceType", theme)}</TableCell>
                  <TableCell>{getString(lang, "AdminDevice", "activationStatus", theme)}</TableCell>
                  <TableCell>{getString(lang, "AdminDevice", "deactivate", theme)}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {troubleShootingList && (troubleShootingList.length > 0 ? troubleShootingList.map((item, index) =>
                  <TableRow key={index}>
                    <TableCell className={classes.tableCell}>{item.activationCode}</TableCell>
                    <TableCell className={classes.tableCell}>{item.deviceType}</TableCell>
                    <TableCell className={classes.tableCell}>{item.activationStatus}</TableCell>
                    <TableCell className={classes.tableCell}><Deactivate record={item} handleResetData={handleResetData} /></TableCell>
                  </TableRow>
                )
                  : <TableRow><TableCell colSpan={6}>No Data</TableCell></TableRow>)}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </BaseWrapper>
    </Card>
  )
};

const TroubleShootingTitle = ({ lang, handleGetList, deviceId, setDeviceId, theme }) => {
  function onChangeInput(name, value) {
    setDeviceId(value);
  }
  return (
    <>
      <div className="title">
        {getString(lang, "AdminDevice", "troubleShooting", theme)}{" "}
      </div>
      <div>
        <TempTextInput
          inputSetting={{
            className: "edit-create-input",
            size: constants.SIZE.SMALL,
            label: { resource: "AdminDevice", key: "deviceId" },
            value: deviceId,
            name: "deviceId",
          }}
          handleChange={onChangeInput}
        />
        <TempBtn
          btnSetting={{
            className: "edit-create-btn",
            variant: constants.STYLE.VARIANT.CONTAINED,
            color: constants.STYLE.PRIMARY,
            onClick: () => handleGetList(deviceId),
            icon: <SearchIcon />,
            label: { key: "search" },
            disabled: !deviceId ? true : false,
          }}
        />
      </div>
    </>
  );
};