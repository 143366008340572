import _ from "lodash";
import { RESTAURANT_PAYMENT } from '../actions/types';
import { constants } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        restaurantPayment: _.cloneDeep(constants.RESTAURANT_PAYMENT)
    }
}

const restaurantPayment = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RESTAURANT_PAYMENT.SET_RESTAURANT_PAYMENT_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case RESTAURANT_PAYMENT.SET_RESTAURANT_PAYMENT:
        case RESTAURANT_PAYMENT.SET_RESTAURANT_PAYMENT_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    restaurantPayment: action.restaurantPayment
                }
            })
        default:
            return state
    }
}

export default restaurantPayment