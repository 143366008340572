import _ from 'lodash'
import { sify } from 'chinese-conv'
import { LANDMARK_RESTAURANT_MAP } from './types'

export const setRestaurantMapDefault = () => {
  return {
    type: LANDMARK_RESTAURANT_MAP.setRestaurantMapDefault,
  }
}

export const setRestaurantMap = (restaurantMap) => {
  return {
    type: LANDMARK_RESTAURANT_MAP.setRestaurantMap,
    restaurantMap: restaurantMap,
  }
}

export const setRestaurantMapValue = (draftType, orignalProduct, key, value, index) => {
  const restaurantMap = _.cloneDeep(orignalProduct)
  switch (draftType) {
    case 'tcName':
      restaurantMap.tcName = value
      restaurantMap.scName = restaurantMap.scNameDisable ? sify(value) : restaurantMap.scName
      break
    case 'scNameDisable':
      restaurantMap.scNameDisable = value
      if (value) {
        restaurantMap.scName = !restaurantMap.tcName ? restaurantMap.scName : sify(restaurantMap.tcName)
      }
      break
    default:
      restaurantMap[draftType] = value
  }
  return {
    type: LANDMARK_RESTAURANT_MAP.setRestaurantMapValue,
    restaurantMap: restaurantMap,
  }
}
