import { useState } from "react";
import { useStore } from 'react-redux';
import _ from "lodash";

import { Card } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { post } from "../../helper/baseFetch";
import { constants } from '../../Constants/constants';
import { BaseWrapper } from "../../Component/BaseWrapper";
import { TempTextInput } from "../../Component/Input/TempTextInput"
import { TempAutoSelectInput } from "../../Component/Input/TempAutoSelectInput"
import { TempBtn } from "../../Component/Input/TempBtn";
import { DialogWrapper } from "../../Component/DialogWrapper";
import { DropzoneArea } from "mui-file-dropzone";

export const UploadImageList = () => {
  const store = useStore();
  const [imageCosUrl, setimageCosUrl] = useState();
  const [savedUploadImage, setSavedUploadImage] = useState({
    recordId: "",
    tableName: "",
    fieldName: "",
    pictures: []
  });
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    onClickClear();
  };

  const uploadImageList = [
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: "UploadImage", key: "recordId" },
      value: savedUploadImage.recordId,
      name: "recordId",
      required: true,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: "UploadImage", key: "tableName" },
      value: savedUploadImage.tableName,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.UPLOAD_IMAGE.TABLE_NAME,
      name: "tableName",
      required: true,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: "UploadImage", key: "fieldName" },
      value: savedUploadImage.fieldName,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.UPLOAD_IMAGE.FIELD_NAME,
      name: "fieldName",
      required: true,
    },
  ]

  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      disabled: Object.values(savedUploadImage).filter((item) => { return item === "" || item === null }).length > 0 || !savedUploadImage.pictures[0].src ? true : false,
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  function onChangeInput(name, e) {
    let tempUploadImage = _.cloneDeep(savedUploadImage);
    tempUploadImage[`${name}`] = e;
    setSavedUploadImage(tempUploadImage);
  }

  const processImages = (pictures) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(pictures);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    })
  }

  const handleconverseImage = async (pictures) => {
    const uri = pictures && await processImages(pictures);
    let tempUploadImage = _.cloneDeep(savedUploadImage);
    tempUploadImage["pictures"] = [
      {
        src: uri,
        title: pictures && pictures.name,
      }
    ];
    setSavedUploadImage(tempUploadImage);
  }


  function copyToClipboard() {
    var range = document.createRange();
    range.selectNode(document.getElementById("copyPath"));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
  };

  function onClickSave() {
    post(constants.PATH.COS_IMAGE, savedUploadImage, (payload) => {
      setimageCosUrl(payload.imageCosUrl);
      setDialog({
        open: true,
        onClose: onClickBackToEdit,
        dialogContentText: { key: "editSuccess" },
        dialogActions: [
          { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
        ]
      })
    }, undefined, store);
  }

  function onClickClear() {
    setSavedUploadImage({
      recordId: "",
      tableName: "",
      fieldName: "",
      pictures: []
    });
  }

  return (
    <BaseWrapper>
      <Card className="image-list card-wrapper">
        {uploadImageList.map((item, index) => {
          let content;
          switch (item.type) {
            case constants.TYPE.SELECT:
              content =
                <TempAutoSelectInput inputSetting={item} handleChange={onChangeInput} key={index} index={index} />
              break;
            default:
              content =
                <TempTextInput inputSetting={item} handleChange={onChangeInput} key={index} index={index} />
          }
          return content
        })}
        <DropzoneArea
          showPreviews={false}
          showPreviewsInDropzone={false}
          filesLimit={1}
          maxFileSize={409600}
          onChange={file => handleconverseImage(file[0])}
        />
        {savedUploadImage.pictures.length > 0 &&
          <div>
            Preview:
            <div>
              <img alt={savedUploadImage.pictures[0].title} src={savedUploadImage.pictures[0].src} />
            </div>
          </div>
        }
        <div className="edit-footer-wrapper toolbar">
          {bottomBtns && bottomBtns.map((btnSetting, index) => {
            return <TempBtn key={index} btnSetting={btnSetting} />
          })}
        </div>
      </Card>
      <div>
        Path: <span id="copyPath" className="path">{imageCosUrl}</span>
        <TempBtn btnSetting={{
          variant: constants.STYLE.VARIANT.CONTAINED,
          color: constants.STYLE.PRIMARY,
          onClick: copyToClipboard,
          label: { key: "Copy" },
          icon: <FileCopyIcon />
        }} />
      </div>
      {dialog && dialog.open &&
        <DialogWrapper dialogInfo={dialog} />
      }
    </BaseWrapper>
  );
};
