import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { Card } from '@mui/material';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import CancelIcon from '@mui/icons-material/Cancel';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { constants, PRINT_TEMPLATE_TYPE } from '../../Constants/constants';
import { setLoading } from "../../actions/systemAction";
import { setTemplate, setTemplateList, setTemplateLabelList, setTemplateLabel, setCustomIdNumber } from "../../actions/printTemplateAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempAutoSelectInput } from "../../Component/Input/TempAutoSelectInput";
import { TempBtn } from "../../Component/Input/TempBtn"
import PrintTemplateValidation from "../../validations/printTemplateValidation";
import { printTemplateValidationConstants } from "../../Constants/validationConstants";
import { getString } from "../../helper/util";
import { DialogWrapper } from "../../Component/DialogWrapper";
import { DragnDrop } from "./DragnDrop";

import "../../styles/printTemplate.scss";
import { TemplateLabel } from "./TemplateLabel";

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

const resource = "PrintTemplate";

export const Template = () => {
  const dispatch = useDispatch();
  const [clear, setClear] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  
  const savedTemplateList = useSelector(state => state.printTemplate.ui.templateList);
  const savedTemplateLabelList = useSelector(state => state.printTemplate.ui.templateLabelList);
  const savedTemplate = useSelector(state => state.printTemplate.ui.template)
  const lang = useSelector(state => state.lang.ui.lang);
  const theme = useSelector(state => state.theme.ui.theme);
  const helperTextStylesClasses = helperTextStyles();
  const [, setErrorVersion] = useState(PrintTemplateValidation.getVersion());

  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.PRINT_TEMPLATE_EDIT_TAB.INFO
    },
  ]
  const content = [
    {
      tab: <>
        {savedTemplate.type === "PDF" ?
          Object.keys(savedTemplate.value).map((itemValue) => {
            return <TempTextInput inputSetting={{
              className: "edit-create-input",
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "" + itemValue },
              value: savedTemplate[{ itemValue }],
              name: itemValue,
              multiline: true,
            }}
              handleChange={onChangeInput} />
          })
          : <>
            <div className="text-type-full-width">
              <TempAutoSelectInput inputSetting={{
                className: "edit-create-input",
                size: constants.SIZE.SMALL,
                label: { resource: resource, key: "type" },
                value: savedTemplate.type,
                name: "type",
                selectValue: constants.CHOICES.PRINT_TEMPLATE.TEMPLATE_TYPE,
                multiline: true,
                helperTextIcon: true,
                helperText: PrintTemplateValidation.getErrorMessages(printTemplateValidationConstants.KEY_TEMPLATE_TYPE, savedTemplate.customId).map((error) => { return error }),
                FormHelperTextProps: { className: helperTextStylesClasses.root },
              }}
                handleChange={handleTemplateInputChange}
              />
              {(savedTemplate.type || savedTemplate.type === PRINT_TEMPLATE_TYPE.FONT_SIZE) && ((savedTemplate.type === PRINT_TEMPLATE_TYPE.FONT_SIZE || savedTemplate.type === PRINT_TEMPLATE_TYPE.ALIGNMENT || savedTemplate.type === PRINT_TEMPLATE_TYPE.FONT_STYLE || savedTemplate.type === PRINT_TEMPLATE_TYPE.LOCALE) ?
                <TempAutoSelectInput key={savedTemplate.customId} inputSetting={{
                  className: "edit-create-input",
                  size: constants.SIZE.SMALL,
                  label: { resource: resource, key: "value" },
                  value: savedTemplate.value,
                  name: "value",
                  selectValue:
                    savedTemplate.type === PRINT_TEMPLATE_TYPE.FONT_SIZE ? constants.CHOICES.PRINT_TEMPLATE.TEMPLATE_TYPE_0 :
                      savedTemplate.type === PRINT_TEMPLATE_TYPE.ALIGNMENT ? constants.CHOICES.PRINT_TEMPLATE.TEMPLATE_TYPE_2 :
                        savedTemplate.type === PRINT_TEMPLATE_TYPE.FONT_STYLE ? constants.CHOICES.PRINT_TEMPLATE.TEMPLATE_TYPE_11 :
                          savedTemplate.type === PRINT_TEMPLATE_TYPE.LOCALE ? constants.CHOICES.PRINT_TEMPLATE.TEMPLATE_TYPE_14 : [],
                  multiline: true,
                  helperTextIcon: true,
                  helperText: PrintTemplateValidation.getErrorMessages(printTemplateValidationConstants.KEY_TEMPLATE_VALUE, savedTemplate.customId).map((error) => { return error }),
                  FormHelperTextProps: { className: helperTextStylesClasses.root },
                }}
                  handleChange={handleTemplateInputChange}
                />
                : (savedTemplate.type === PRINT_TEMPLATE_TYPE.FONT_SIZE_DIRECT) ?
                  <TempTextInput inputSetting={{
                    className: "edit-create-input",
                    size: constants.SIZE.SMALL,
                    label: { resource: resource, key: "value" },
                    value: savedTemplate.value,
                    name: "value",
                    type: constants.TYPE.NUMBER,
                    endAdornment: "px",
                    helperTextIcon: true,
                    helperText: PrintTemplateValidation.getErrorMessages(printTemplateValidationConstants.KEY_TEMPLATE_VALUE, savedTemplate.customId).map((error) => { return error }),
                    FormHelperTextProps: { className: helperTextStylesClasses.root },
                  }}
                    handleChange={onChangeInput} />
                  : savedTemplate.type === PRINT_TEMPLATE_TYPE.DATE_FORMAT ? <></>
                  :(savedTemplate.type === PRINT_TEMPLATE_TYPE.LINE_FEED) ?
                  <TempTextInput inputSetting={{
                    className: "edit-create-input",
                    size: constants.SIZE.SMALL,
                    label: { resource: resource, key: "value" },
                    value: savedTemplate.value,
                    name: "value",
                    type: constants.TYPE.NUMBER,
                    helperTextIcon: true,
                    helperText: PrintTemplateValidation.getErrorMessages(printTemplateValidationConstants.KEY_TEMPLATE_VALUE, savedTemplate.customId).map((error) => { return error }),
                    FormHelperTextProps: { className: helperTextStylesClasses.root },
                  }}
                    handleChange={onChangeInput} />
                    : <div>
                      {PrintTemplateValidation.getErrorMessages(printTemplateValidationConstants.KEY_TEMPLATE_VALUE, savedTemplate.customId).map((error) => { return error }) &&
                        PrintTemplateValidation.getErrorMessages(printTemplateValidationConstants.KEY_TEMPLATE_VALUE, savedTemplate.customId).map((error) => { return error }).length > 0 &&
                        <div className="error-message">
                          <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                          {PrintTemplateValidation.getErrorMessages(printTemplateValidationConstants.KEY_TEMPLATE_VALUE, savedTemplate.customId).map((error) => { return error })}
                        </div>
                      }
                      <DragnDrop setErrorVersion={setErrorVersion} handleTemplateText={handleTemplateText} />
                    </div>
              )}
            </div>
            {savedTemplate.type === PRINT_TEMPLATE_TYPE.DATE_FORMAT &&
              <>
                {PrintTemplateValidation.getErrorMessages(printTemplateValidationConstants.KEY_TEMPLATE_VALUE_DATE_FORMAT, savedTemplate.customId).map((error) => { return error }) &&
                  PrintTemplateValidation.getErrorMessages(printTemplateValidationConstants.KEY_TEMPLATE_VALUE_DATE_FORMAT, savedTemplate.customId).map((error) => { return error }).length > 0 &&
                  <div className="error-message">
                    <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                    {PrintTemplateValidation.getErrorMessages(printTemplateValidationConstants.KEY_TEMPLATE_VALUE_DATE_FORMAT, savedTemplate.customId).map((error) => { return error })}
                  </div>
                }
                <DragDropContext onDragEnd={handleDateFormatOnDragEnd}>
                  <Droppable droppableId="templateDateFormatValue">
                    {(provided) => handleDataFormatShow(provided)}
                  </Droppable>
                </DragDropContext>
              </>
            }
          </>
        }
      </>,
      btn: false
    },
  ]

  function onChangeInput(name, e) {
    let tempSavedTemplate = _.cloneDeep(savedTemplate);
    let tempSavedTemplateList = _.cloneDeep(savedTemplateList);
    dispatch(setLoading(true));
    if (savedTemplate.type === PRINT_TEMPLATE_TYPE.FONT_SIZE_DIRECT || savedTemplate.type === PRINT_TEMPLATE_TYPE.LINE_FEED) {
      tempSavedTemplate[`${name}`] = e || e === 0 ? Number(e) : e;
    } else {
      tempSavedTemplate[`${name}`] = e;
    }
    tempSavedTemplateList = tempSavedTemplateList.map((item) => {
      if (item.customId === tempSavedTemplate.customId) {
        item = tempSavedTemplate;
      }
      return item;
    })
    dispatch(setTemplateList(tempSavedTemplateList));
    dispatch(setTemplate(tempSavedTemplate));
    dispatch(setLoading(false));
    PrintTemplateValidation.validatePrintTempTemplate(tempSavedTemplateList);
    setErrorVersion(PrintTemplateValidation.getVersion());
  }

  function handleTemplateInputChange(name, e) {
    let tempSavedTemplate = _.cloneDeep(savedTemplate);
    let tempSavedTemplateList = _.cloneDeep(savedTemplateList);
    dispatch(setLoading(true));
    if (name === "type") {
      let value = null;
      switch (e) {
        case PRINT_TEMPLATE_TYPE.FONT_SIZE:
        case PRINT_TEMPLATE_TYPE.ALIGNMENT:
        case PRINT_TEMPLATE_TYPE.FONT_STYLE:
        case PRINT_TEMPLATE_TYPE.LOCALE:
          value = "";
          break;
        case PRINT_TEMPLATE_TYPE.DATE_FORMAT:
          value = [
            {
              type: "date",
              value: [
                { type: 'year', value: '' },
                { type: 'month', value: '' },
                { type: 'date', value: '' },
              ],
              middleSymbol: ""
            },
            {
              type: "time",
              value: [
                { type: 'hour', value: '' },
                { type: 'minute', value: '' },
                { type: 'seconds', value: '' },
                { type: 'timeFormat', value: '' },
              ],
              bracket: false
            },
            {
              type: "week",
              value: [
                { type: 'week', value: '' },
              ],
            }
          ]
          break;
        case PRINT_TEMPLATE_TYPE.TERMS_AND_CONDITIONS:
          value = ['${remarks}'];
          break;
        case PRINT_TEMPLATE_TYPE.LOGO:
          value = ['${logo}'];
          break;
        case PRINT_TEMPLATE_TYPE.HEADER:
          value = ['${header}'];
          break;
        case PRINT_TEMPLATE_TYPE.FOOTER:
          value = ['${footer}'];
          break;
        default:
          value = [];
      }
      tempSavedTemplate.type = e;
      tempSavedTemplate.value = value;
    } else {
      tempSavedTemplate[`${name}`] = e;
    }
    tempSavedTemplateList = tempSavedTemplateList.map((item) => {
      if (item.customId === tempSavedTemplate.customId) {
        item = tempSavedTemplate;
      }
      return item;
    })
    dispatch(setTemplateList(tempSavedTemplateList));
    dispatch(setTemplate(tempSavedTemplate));
    dispatch(setLoading(false));
    PrintTemplateValidation.validatePrintTempTemplate(tempSavedTemplateList);
    setErrorVersion(PrintTemplateValidation.getVersion());
  }

  function handleTemplateText(type, value, index, newLabelList) {
    let newType = "";
    let newValue = value ? value : "";
    let textTypeList = constants.CHOICES.PRINT_TEMPLATE.TEMPLATE_PREDEFINED_TEXT.map((textType) => { return textType.value })
    if (value) {
      if (value === "New Line") {
        newType = "NEW_LINE";
        newValue = "New Line";
      } else if (value.includes("${")) {
        newValue = value.replace("${", "")
        newValue = newValue.replace("}", "")
        if (textTypeList.includes(newValue)) {
          newType = "PREDEFINED_TEXT";
        } else if (newValue.includes("label_")) {
          newType = "LABEL";
          newValue = newValue.replace("label_", "")
        }
      } else {
        newType = "TEXT";
      }
    }

    handleTemplateTextDialog();

    function handleTemplateTextInputChange(name, e) {
      if (name === "type") {
        newType = e;
        if (e === "NEW_LINE") {
          newValue = "New Line";
        } else {
          newValue = "";
        }
      } else if (name === "textValue") {
        newValue = e;
      }
      handleTemplateTextDialog();
    }

    function handleTemplateTextDialog() {
      let tempTextType = [];
      if (savedTemplate.type === PRINT_TEMPLATE_TYPE.PRINT_IMAGE) {
        tempTextType = constants.CHOICES.PRINT_TEMPLATE.TEMPLATE_TEXT_TYPE.filter((textType) => (textType.value === "PREDEFINED_TEXT") && textType)
      } else if (savedTemplate.type === PRINT_TEMPLATE_TYPE.PRINT_QR_CODE || savedTemplate.type === PRINT_TEMPLATE_TYPE.PRINT_BAR_CODE) {
        tempTextType = constants.CHOICES.PRINT_TEMPLATE.TEMPLATE_TEXT_TYPE.filter((textType) => (textType.value === "PREDEFINED_TEXT" || textType.value === "TEXT") && textType)
      } else {
        tempTextType = constants.CHOICES.PRINT_TEMPLATE.TEMPLATE_TEXT_TYPE
      }
      setDialog({
        open: true,
        onClose: handleCloseDialog,
        dialogTitle: type === "add" ? { resource: resource, key: "addText" } : { resource: resource, key: "Edit Text" },
        maxWidth: "md",
        dialogContent: <>
          <TempAutoSelectInput inputSetting={{
            className: "edit-create-input",
            size: constants.SIZE.SMALL,
            label: { resource: resource, key: "type" },
            value: newType,
            name: "type",
            selectValue: tempTextType,
            multiline: true,
          }}
            handleChange={handleTemplateTextInputChange} />
          {newType === "TEXT" &&
            <TempTextInput inputSetting={{
              className: "edit-create-input",
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "value" },
              value: newValue,
              name: "textValue",
              multiline: true,
            }}
              handleChange={handleTemplateTextInputChange} />
          }
          {newType === "PREDEFINED_TEXT" && (savedTemplate.type === PRINT_TEMPLATE_TYPE.PRINT_TEXT || savedTemplate.type === PRINT_TEMPLATE_TYPE.TERMS_AND_CONDITIONS || savedTemplate.type === PRINT_TEMPLATE_TYPE.PRINT_TEXT_AS_IMAGE || savedTemplate.type === PRINT_TEMPLATE_TYPE.PRINT_TEXT_AS_IMAGE_NO_LINE_WRAP) &&
            <TempAutoSelectInput inputSetting={{
              className: "edit-create-input",
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "value" },
              value: newValue,
              name: "textValue",
              selectValue: savedTemplate.type === PRINT_TEMPLATE_TYPE.TERMS_AND_CONDITIONS?constants.CHOICES.PRINT_TEMPLATE.TEMPLATE_PREDEFINED_TEXT.filter((item)=>item.value!=='remarks'):constants.CHOICES.PRINT_TEMPLATE.TEMPLATE_PREDEFINED_TEXT,
              multiline: true,
            }}
              handleChange={handleTemplateTextInputChange} />
          }
          {newType === "PREDEFINED_TEXT" && savedTemplate.type === PRINT_TEMPLATE_TYPE.PRINT_IMAGE &&
            <TempAutoSelectInput inputSetting={{
              className: "edit-create-input",
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "value" },
              value: newValue,
              name: "textValue",
              selectValue: constants.CHOICES.PRINT_TEMPLATE.TEMPLATE_PREDEFINED_TEXT_IMAGE_USE,
              multiline: true,
            }}
              handleChange={handleTemplateTextInputChange} />
          }
          {newType === "PREDEFINED_TEXT" && (savedTemplate.type === PRINT_TEMPLATE_TYPE.PRINT_QR_CODE || savedTemplate.type === PRINT_TEMPLATE_TYPE.PRINT_BAR_CODE) &&
            <TempAutoSelectInput inputSetting={{
              className: "edit-create-input",
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "value" },
              value: newValue,
              name: "textValue",
              selectValue: constants.CHOICES.PRINT_TEMPLATE.TEMPLATE_PREDEFINED_TEXT_QRCODE_USE,
              multiline: true,
            }}
              handleChange={handleTemplateTextInputChange} />
          }
          {newType === "LABEL" &&
            <div className="brand-id-wrapper">
              <TempAutoSelectInput inputSetting={{
                className: "edit-create-input",
                size: constants.SIZE.SMALL,
                label: { resource: resource, key: "value" },
                value: newValue,
                name: "textValue",
                selectValue:
                  newLabelList ? newLabelList.map((savedTemplateLabel) => { return { value: savedTemplateLabel.labelTitle, name: "label_" + savedTemplateLabel.labelTitle } })
                    : savedTemplateLabelList.map((savedTemplateLabel) => { return { value: savedTemplateLabel.labelTitle, name: "label_" + savedTemplateLabel.labelTitle } }),
                multiline: true,
              }}
                handleChange={handleTemplateTextInputChange} />
              <TempBtn btnSetting={{
                className: "center-item",
                variant: constants.STYLE.VARIANT.CONTAINED,
                color: constants.STYLE.PRIMARY,
                label: { resource: resource, key: "addTemplateLabel" },
                disabled: false,
                onClick: () => handleAddLabel(newType, newValue, index, type),
                name: "handleAddLabel",
                type: constants.TYPE.BUTTON,
                icon: <AddIcon />,
              }} />
            </div>
          }
        </>,
        dialogActions: [
          type === "add" ? { label: { key: "add" }, onClick: () => handleAddText(newType, newValue), icon: <AddIcon />, disabled: !newType || !newValue }
            : { label: { key: "save" }, onClick: () => handleEditText(newType, newValue, index), icon: <SaveIcon />, disabled: !newType || !newValue },
          { label: { key: "close" }, onClick: handleCloseDialog, icon: <CloseIcon /> }
        ]
      })
    }
  }

  function handleDateFormatInputChange(name, e) {
    let tempSavedTemplate = _.cloneDeep(savedTemplate);
    let tempSavedTemplateList = _.cloneDeep(savedTemplateList);
    let tempTemplate = _.cloneDeep(tempSavedTemplate.value);
    dispatch(setLoading(true));
    tempTemplate.map((template) => {
      if (name === "year" || name === "month" || name === "date" || name === "middleSymbol") {
        if (template.type === "date") {
          if (name === "middleSymbol") {
            template.middleSymbol = e;
          } else {
            template.value.map((item) => {
              if (item.type === name) {
                item.value = e;
              }
              return item;
            })
          }
        }
      }
      if (name === "hour" || name === "minute" || name === "seconds" || name === "timeFormat" || name === "withBracket") {
        if (template.type === "time") {
          if (name === "withBracket") {
            template.bracket = e;
          } else {
            template.value.map((item) => {
              if (item.type === name) {
                item.value = e;
              }
              return item;
            })
          }
        }
      }
      if (name === "week") {
        if (template.type === "week") {
          template.value.map((item) => {
            if (item.type === name) {
              item.value = e;
            }
            return item;
          })
        }
      }
      return template;
    })
    tempSavedTemplate.value = tempTemplate;
    tempSavedTemplateList = tempSavedTemplateList.map((item) => {
      if (item.customId === tempSavedTemplate.customId) {
        item = tempSavedTemplate;
      }
      return item;
    })
    dispatch(setTemplateList(tempSavedTemplateList));
    dispatch(setTemplate(tempSavedTemplate));
    dispatch(setLoading(false));
    PrintTemplateValidation.validatePrintTempTemplate(tempSavedTemplateList);
    setErrorVersion(PrintTemplateValidation.getVersion());
  }


  function handleCloseDialog() {
    setDialog({
      open: false,
      dialogActions: []
    })
  }

  function handleCloseAddLabelDialog(newType, newValue, index, type, newLabelList) {
    handleTemplateText(type, newValue, index, newLabelList);
  }

  function handleAddText(type, value) {
    let tempSavedTemplate = _.cloneDeep(savedTemplate);
    let tempSavedTemplateList = _.cloneDeep(savedTemplateList);
    let tempTemplate = _.cloneDeep(tempSavedTemplate.value ? tempSavedTemplate.value : []);
    dispatch(setLoading(true));
    if (type === "LABEL") {
      value = "label_" + value
    }
    if (type === "PREDEFINED_TEXT" || type === "LABEL") {
      value = "${" + value + "}"
    }
    tempTemplate.push(value);
    tempSavedTemplate.value = tempTemplate;
    tempSavedTemplateList = tempSavedTemplateList.map((item) => {
      if (item.customId === tempSavedTemplate.customId) {
        item = tempSavedTemplate;
      }
      return item;
    })
    dispatch(setTemplateList(tempSavedTemplateList));
    dispatch(setTemplate(tempSavedTemplate));
    dispatch(setLoading(false));
    PrintTemplateValidation.validatePrintTempTemplate(tempSavedTemplateList);
    setErrorVersion(PrintTemplateValidation.getVersion());

    handleCloseDialog();
  }

  function handleLabel(newType, newValue, index, type, newTemplateLabel, newCustomIdNumber, originalTemplateLabelList) {
    let tempSavedTemplateLabelList = _.cloneDeep(originalTemplateLabelList);
    let templateLabel = _.cloneDeep(constants.TEMPLATE_LABEL_LIST_INPUT);
    let tempNewCustomIdNumber = _.cloneDeep(newCustomIdNumber);
    let tempNewTemplateLabel = _.cloneDeep(newTemplateLabel);
    dispatch(setLoading(true));
    tempNewTemplateLabel.customId = "New_Template_" + tempNewCustomIdNumber;
    tempSavedTemplateLabelList.push(tempNewTemplateLabel);
    dispatch(setCustomIdNumber(tempNewCustomIdNumber + 1));
    dispatch(setTemplateLabelList(tempSavedTemplateLabelList));
    dispatch(setTemplateLabel(templateLabel));
    dispatch(setLoading(false));
    handleCloseAddLabelDialog(newType, newValue, index, type, tempSavedTemplateLabelList);
  }

  function handleAddLabel(newType, newValue, index, type) {
    let originalTemplateLabelList = {};
    let newTemplateLabel = {};
    let newCustomIdNumber = 0;
    newValue = "${label_" + newValue + "}"

    let tempSavedTemplateLabelList = _.cloneDeep(constants.TEMPLATE_LABEL_LIST_INPUT);
    dispatch(setTemplateLabel(tempSavedTemplateLabelList));

    function sendTemplateLabel(data, e, index) {
      originalTemplateLabelList = data;
      newTemplateLabel = e;
      newCustomIdNumber = index;
    }
    setDialog({
      open: true,
      onClose: () => handleCloseAddLabelDialog(newType, newValue, index, type),
      dialogTitle: { resource: resource, key: "addLabel" },
      maxWidth: "md",
      dialogContent: <TemplateLabel sendTemplateLabel={sendTemplateLabel} type="dialog" />,
      dialogActions: [
        { label: { key: "edit" }, onClick: () => handleLabel(newType, newValue, index, type, newTemplateLabel, newCustomIdNumber, originalTemplateLabelList), icon: <SaveIcon /> },
        { label: { key: "close" }, onClick: () => handleCloseAddLabelDialog(newType, newValue, index, type), icon: <CloseIcon /> }
      ]
    })
  }

  function handleEditText(type, value, index) {
    dispatch(setLoading(true));
    let tempSavedTemplate = _.cloneDeep(savedTemplate);
    let tempSavedTemplateList = _.cloneDeep(savedTemplateList);
    let tempTemplate = _.cloneDeep(tempSavedTemplate.value);
    if (type === "LABEL") {
      value = "label_" + value
    }
    if (type === "PREDEFINED_TEXT" || type === "LABEL") {
      value = "${" + value + "}"
    }
    tempTemplate[index] = value;
    tempSavedTemplate.value = tempTemplate;
    tempSavedTemplateList = tempSavedTemplateList.map((item) => {
      if (item.customId === tempSavedTemplate.customId) {
        item = tempSavedTemplate;
      }
      return item;
    })
    dispatch(setTemplateList(tempSavedTemplateList));
    dispatch(setTemplate(tempSavedTemplate));
    dispatch(setLoading(false));
    PrintTemplateValidation.validatePrintTempTemplate(tempSavedTemplateList);
    setErrorVersion(PrintTemplateValidation.getVersion());
    handleCloseDialog();
  }

  function handleDateOnDragEnd(result) {
    let tempSavedTemplate = _.cloneDeep(savedTemplate);
    let tempSavedTemplateList = _.cloneDeep(savedTemplateList);
    if (!result.destination) return;
    const tempTemplate = _.cloneDeep(tempSavedTemplate.value);
    tempTemplate.map((template) => {
      let tempDateValueList = _.cloneDeep(template.value);
      if (template.type === "date") {
        const [reorderedItem] = tempDateValueList.splice(result.source.index, 1);
        tempDateValueList.splice(result.destination.index, 0, reorderedItem);
      }
      template.value = tempDateValueList;
      return template
    })
    tempSavedTemplate.value = tempTemplate;
    tempSavedTemplateList = tempSavedTemplateList.map((item) => {
      if (item.customId === tempSavedTemplate.customId) {
        item = tempSavedTemplate;
      }
      return item;
    })
    dispatch(setTemplateList(tempSavedTemplateList));
    dispatch(setTemplate(tempSavedTemplate));
    PrintTemplateValidation.validatePrintTempTemplate(tempSavedTemplateList);
    setErrorVersion(PrintTemplateValidation.getVersion());
  }

  function handleTimeOnDragEnd(result) {
    let tempSavedTemplate = _.cloneDeep(savedTemplate);
    let tempSavedTemplateList = _.cloneDeep(savedTemplateList);
    if (!result.destination) return;
    const tempTemplate = _.cloneDeep(tempSavedTemplate.value);
    tempTemplate.map((template) => {
      let tempDateValueList = _.cloneDeep(template.value);
      if (template.type === "time") {
        const [reorderedItem] = tempDateValueList.splice(result.source.index, 1);
        tempDateValueList.splice(result.destination.index, 0, reorderedItem);
      }
      template.value = tempDateValueList;
      return template
    })
    tempSavedTemplate.value = tempTemplate;
    tempSavedTemplateList = tempSavedTemplateList.map((item) => {
      if (item.customId === tempSavedTemplate.customId) {
        item = tempSavedTemplate;
      }
      return item;
    })
    dispatch(setTemplateList(tempSavedTemplateList));
    dispatch(setTemplate(tempSavedTemplate));
    PrintTemplateValidation.validatePrintTempTemplate(tempSavedTemplateList);
    setErrorVersion(PrintTemplateValidation.getVersion());
  }

  function handleDateFormatOnDragEnd(result) {
    let tempSavedTemplate = _.cloneDeep(savedTemplate);
    let tempSavedTemplateList = _.cloneDeep(savedTemplateList);
    if (!result.destination) return;
    const tempTemplate = _.cloneDeep(tempSavedTemplate.value);
    const [reorderedItem] = tempTemplate.splice(result.source.index, 1);
    tempTemplate.splice(result.destination.index, 0, reorderedItem);
    tempSavedTemplate.value = tempTemplate;
    tempSavedTemplateList = tempSavedTemplateList.map((item) => {
      if (item.customId === tempSavedTemplate.customId) {
        item = tempSavedTemplate;
      }
      return item;
    })
    dispatch(setTemplateList(tempSavedTemplateList));
    dispatch(setTemplate(tempSavedTemplate));
    PrintTemplateValidation.validatePrintTempTemplate(tempSavedTemplateList);
    setErrorVersion(PrintTemplateValidation.getVersion());
  }

  function handleDataFormatShow(provided) {
    return <div {...provided.droppableProps} ref={provided.innerRef}>
      {savedTemplate.value && savedTemplate.value.map((dateFormat, index) => {
        let mainContent = <></>;
        if (dateFormat.type === "date") {
          mainContent = <Draggable key={index} draggableId={dateFormat.type + index} index={index}>
            {(provided) => {
              return <div ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}>
                <Card className="card date-formate-card">
                  <div className="left"><SwapVertIcon color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.LARGE} /></div>
                  <div className="right"><div className="date-formate-title">{getString(lang, resource, "date", theme)}</div>
                    <DragDropContext onDragEnd={handleDateOnDragEnd}>
                      <Droppable droppableId="dateValue" direction={"horizontal"}>
                        {(provided) => {
                          return <div className="date-format-bottom center-item" {...provided.droppableProps} ref={provided.innerRef}>
                            {dateFormat.value.map((dateFormatValue, dateFormatValueIndex) => {
                              let content = <></>
                              if (dateFormatValue.type === "year") {
                                content = <Draggable key={"year"} draggableId={"year"} index={dateFormatValueIndex}>
                                  {(provided) => {
                                    return <div className="sub-item"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div className="sub-content">
                                        <SwapHorizIcon color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.LARGE} />
                                        <div className="sub-item-name">
                                          <TempAutoSelectInput inputSetting={{
                                            className: "full-size-input",
                                            size: constants.SIZE.SMALL,
                                            label: { resource: resource, key: "year" },
                                            value: dateFormatValue.value,
                                            name: "year",
                                            selectValue: constants.CHOICES.PRINT_TEMPLATE.DATE_FORMAT_YEAR,
                                            multiline: true,
                                          }}
                                            handleChange={handleDateFormatInputChange}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  }}
                                </Draggable>
                              } else if (dateFormatValue.type === "month") {
                                content = <Draggable key={"month"} draggableId={"month"} index={dateFormatValueIndex}>
                                  {(provided) => {
                                    return <div className="sub-item"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div className="sub-content">
                                        <SwapHorizIcon color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.LARGE} />
                                        <div className="sub-item-name">
                                          <TempAutoSelectInput inputSetting={{
                                            className: "full-size-input",
                                            size: constants.SIZE.SMALL,
                                            label: { resource: resource, key: "month" },
                                            value: dateFormatValue.value,
                                            name: "month",
                                            selectValue: constants.CHOICES.PRINT_TEMPLATE.DATE_FORMAT_MONTH,
                                            multiline: true,
                                          }}
                                            handleChange={handleDateFormatInputChange}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  }}
                                </Draggable>
                              } else if (dateFormatValue.type === "date") {
                                content = <Draggable key={"date"} draggableId={"date"} index={dateFormatValueIndex}>
                                  {(provided) => {
                                    return <div className="sub-item"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div className="sub-content">
                                        <SwapHorizIcon color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.LARGE} />
                                        <div className="sub-item-name">
                                          <TempAutoSelectInput inputSetting={{
                                            className: "full-size-input",
                                            size: constants.SIZE.SMALL,
                                            label: { resource: resource, key: "date" },
                                            value: dateFormatValue.value,
                                            name: "date",
                                            selectValue: constants.CHOICES.PRINT_TEMPLATE.DATE_FORMAT_DATE,
                                            multiline: true,
                                          }}
                                            handleChange={handleDateFormatInputChange}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  }}
                                </Draggable>
                              }
                              return content;
                            })}
                          </div>
                        }}
                      </Droppable>
                    </DragDropContext>
                    <TempAutoSelectInput inputSetting={{
                      className: "edit-create-input",
                      size: constants.SIZE.SMALL,
                      label: { resource: resource, key: "middleSymbol" },
                      //eslint-disable-next-line
                      value: dateFormat.middleSymbol,
                      name: "middleSymbol",
                      selectValue: constants.CHOICES.PRINT_TEMPLATE.MIDDLE_SYMBOL,
                      multiline: true,
                      helperTextIcon: true,
                      helperText: PrintTemplateValidation.getErrorMessages(printTemplateValidationConstants.KEY_TEMPLATE_VALUE_DATE_MIDDLE_SYMBOL, savedTemplate.customId).map((error) => { return error }),
                      FormHelperTextProps: { className: helperTextStylesClasses.root },
                    }}
                      handleChange={handleDateFormatInputChange}
                    />
                  </div>
                </Card>
              </div>
            }}
          </Draggable>
        } else if (dateFormat.type === "time") {
          mainContent = <Draggable key={index} draggableId={dateFormat.type + index} index={index}>
            {(provided) => {
              return <div ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}>
                <Card className="card date-formate-card">
                  <div className="left"><SwapVertIcon color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.LARGE} /></div>
                  <div className="right"><div className="date-formate-title">{getString(lang, resource, "time", theme)}</div>
                    <DragDropContext onDragEnd={handleTimeOnDragEnd}>
                      <Droppable droppableId="timeValue" direction={"horizontal"}>
                        {(provided) => {
                          return <div className="date-format-bottom center-item" {...provided.droppableProps} ref={provided.innerRef}>
                            {dateFormat.value.map((dateFormatValue, dateFormatValueIndex) => {
                              let content = <></>
                              if (dateFormatValue.type === "hour") {
                                content = <Draggable key="h" draggableId="h" index={0}>
                                  {(provided) => {
                                    return <div className="sub-item"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div className="sub-content">
                                        <SwapHorizIcon color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.LARGE} />
                                        <div className="sub-item-name">
                                          <TempAutoSelectInput inputSetting={{
                                            className: "edit-create-input",
                                            size: constants.SIZE.SMALL,
                                            label: { resource: resource, key: "hour" },
                                            value: dateFormatValue.value,
                                            name: "hour",
                                            selectValue: constants.CHOICES.PRINT_TEMPLATE.DATE_FORMAT_HOUR,
                                            multiline: true,
                                          }}
                                            handleChange={handleDateFormatInputChange}
                                          />
                                          <TempAutoSelectInput inputSetting={{
                                            className: "edit-create-input",
                                            size: constants.SIZE.SMALL,
                                            label: { resource: resource, key: "minute" },
                                            value: dateFormat.value.find((dateFormatValue) => dateFormatValue.type === "minute").value,
                                            name: "minute",
                                            selectValue: constants.CHOICES.PRINT_TEMPLATE.DATE_FORMAT_MINUTE,
                                            multiline: true,
                                          }}
                                            handleChange={handleDateFormatInputChange}
                                          />
                                          <TempAutoSelectInput inputSetting={{
                                            className: "edit-create-input",
                                            size: constants.SIZE.SMALL,
                                            label: { resource: resource, key: "seconds" },
                                            value: dateFormat.value.find((dateFormatValue) => dateFormatValue.type === "seconds").value,
                                            name: "seconds",
                                            selectValue: constants.CHOICES.PRINT_TEMPLATE.DATE_FORMAT_SECOND,
                                            multiline: true,
                                          }}
                                            handleChange={handleDateFormatInputChange}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  }}
                                </Draggable>
                              } else if (dateFormatValue.type === "timeFormat") {
                                content = <Draggable key="a" draggableId="a" index={3}>
                                  {(provided) => {
                                    return <div className="sub-item"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div className="sub-content">
                                        <SwapHorizIcon color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.LARGE} />
                                        <div className="sub-item-name">
                                          <TempAutoSelectInput inputSetting={{
                                            className: "full-size-input",
                                            size: constants.SIZE.SMALL,
                                            label: { resource: resource, key: "timeFormat" },
                                            value: dateFormatValue.value,
                                            name: "timeFormat",
                                            selectValue: constants.CHOICES.PRINT_TEMPLATE.DATE_FORMAT_HOUR_FORMAT,
                                            multiline: true,
                                            helperTextIcon: true,
                                            helperText: PrintTemplateValidation.getErrorMessages(printTemplateValidationConstants.KEY_TEMPLATE_VALUE_TIME_FORMAT, savedTemplate.customId).map((error) => { return error }),
                                            FormHelperTextProps: { className: helperTextStylesClasses.root },
                                          }}
                                            handleChange={handleDateFormatInputChange}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  }}
                                </Draggable>
                              }
                              return content;
                            })}
                          </div>
                        }}
                      </Droppable>
                    </DragDropContext>
                    <TempAutoSelectInput inputSetting={{
                      className: "edit-create-input",
                      size: constants.SIZE.SMALL,
                      label: { resource: resource, key: "withBracket" },
                      value: dateFormat.bracket,
                      name: "withBracket",
                      disableClearable: true,
                      selectValue: constants.CHOICES.PRINT_TEMPLATE.WITH_BRACKET,
                      multiline: true,
                      helperTextIcon: true,
                      helperText: PrintTemplateValidation.getErrorMessages(printTemplateValidationConstants.KEY_TEMPLATE_VALUE_TIME_BRACKET, savedTemplate.customId).map((error) => { return error }),
                      FormHelperTextProps: { className: helperTextStylesClasses.root },
                    }}
                      handleChange={handleDateFormatInputChange}
                    />
                  </div>
                </Card>
              </div>
            }}
          </Draggable>
        } else if (dateFormat.type === "week") {
          mainContent = <Draggable key={index} draggableId={dateFormat.type + index} index={index}>
            {(provided) => {
              return <div ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}>
                <Card className="card date-formate-card">
                  <div className="left"><SwapVertIcon color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.LARGE} /></div>
                  <div className="right"><div className="date-formate-title">{getString(lang, resource, "week", theme)}</div>
                    <TempAutoSelectInput inputSetting={{
                      className: "edit-create-input",
                      size: constants.SIZE.SMALL,
                      label: { resource: resource, key: "week" },
                      value: dateFormat.value[0].value,
                      name: "week",
                      selectValue: constants.CHOICES.PRINT_TEMPLATE.DATE_FORMAT_WEEK,
                      multiline: true,
                    }}
                      handleChange={handleDateFormatInputChange}
                    />
                  </div>
                </Card>
              </div>
            }}
          </Draggable>
        }
        return mainContent;
      })}</div>
  }

  useEffect(() => {
    if (clear === true) {
      if (savedTemplate.customId) {
        let tempTemplate = savedTemplateList.find((template) => template.customId === savedTemplate.customId);
        dispatch(setTemplate(tempTemplate));
      } else {
        let newTemplate = _.cloneDeep(constants.TEMPLATE_LIST_INPUT);
        dispatch(setTemplate(newTemplate));
      }
    }
    setClear(false)
  }, [clear]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   console.log('savedTemplateList', savedTemplateList)
  // }, [savedTemplateList])

  // useEffect(() => {
  //   console.log('savedTemplateLabelList', savedTemplateLabelList)
  // }, [savedTemplateLabelList])

  // useEffect(() => {
  //   console.log('savedTemplate', savedTemplate)
  // }, [savedTemplate])

  return (
    <>
      <EditCreateDisplay
        code={savedTemplate && savedTemplate.customId ? savedTemplate.customId : ""}
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        display={savedTemplate.customId ? "edit" : "create"}
      />
      {dialog && dialog.open &&
        <DialogWrapper dialogInfo={dialog} />
      }
    </>
  )
};