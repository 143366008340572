import _ from 'lodash'
import { STAFF } from '../actions/types'
import { constants } from '../Constants/constants'

const INITIAL_STATE = {
  ui: {
    staff: _.cloneDeep(constants.STAFF_INPUT),
  },
}

const staffReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case STAFF.SET_STAFF_DEFAULT:
      return Object.assign({}, INITIAL_STATE)
    case STAFF.SET_STAFF:
    case STAFF.SET_STAFF_VALUE:
      return Object.assign(
        {},
        {
          ...state,
          ui: {
            ...state.ui,
            staff: action.staff,
          },
        }
      )
    default:
      return state
  }
}

export default staffReducer
