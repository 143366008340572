import _ from 'lodash'

import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import store from '../../store'

import { constants as co } from '../../Constants/constants'

import { TempTextInput } from '../../Component/Input/TempTextInput'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { DialogWrapper } from '../../Component/DialogWrapper'
import { post } from '../../helper/baseFetch'
import { ArrowBack, LowPriority } from '@mui/icons-material'
import { getDefaultBottomBtnList } from '../../Component/EditCreateDisplay/EditUtil'

import QueueProfileValidation from '../../validations/queueProfileValidation'
import QueueTransferProfileValidation from '../../validations/queueTransferProfileValidation'

import { queueProfileValidationConstants, queueTransferProfileValidationConstants } from '../../Constants/validationConstants'

import { setQueueProfileDefault, setQueueProfileValue } from '../../actions/queueProfileAction'
import { setQueueTransferProfileDefault, setQueueTransferProfileValue } from '../../actions/queueTransferProfileAction'

import GenericQueueProfileTimeSectionList from './GenericQueueProfileTimeSectionList'
import GenericGetRefTemplateCode from './GenericGetRefTemplateCode'
import { TempCustomCheckBox } from '../../Component/Custom/TempCustomCheckBox'
import useRestaurant from '../../hooks/Restaurant/useRestaurant'

export default function GenericQueueProfileCreate({ resource }) {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()

  const restUrlId = params.restUrlId || ''
  const { getRestaurantName } = useRestaurant(restUrlId)
  const restName = getRestaurantName()

  const [clear, setClear] = useState(false)
  const [dialogInfo, setDialogInfo] = useState({
    open: false,
  })

  let isQueueTransferProfile = resource === 'QueueTransferProfile'

  const savedData = useSelector((state) => {
    if (isQueueTransferProfile) {
      return state.queueTransferProfile.ui.queueTransferProfile
    } else {
      return state.queueProfile.ui.queueProfile
    }
  })

  let validation = isQueueTransferProfile ? QueueTransferProfileValidation : QueueProfileValidation,
    validationConstants = isQueueTransferProfile ? queueTransferProfileValidationConstants : queueProfileValidationConstants,
    setDispatchDefault = isQueueTransferProfile ? () => dispatch(setQueueTransferProfileDefault()) : () => dispatch(setQueueProfileDefault()),
    setDispatchValue = isQueueTransferProfile
      ? (fieldName, originalObj, key, value) => dispatch(setQueueTransferProfileValue(fieldName, originalObj, key, value))
      : (fieldName, originalObj, key, value) => dispatch(setQueueProfileValue(fieldName, originalObj, key, value))

  const baseListPath = '/' + co.PATH.SHOP.SYSTEM_GROUP_RESTAURANT + '/' + params.restUrlId + '/' + co.RESTAURANT_TAB.MERCHANT + '/'

  let apiPath = isQueueTransferProfile ? co.PATH.MERCHANT.QUEUE_TRANSFER_PROFILE : co.PATH.MERCHANT.QUEUE_PROFILE,
    listPath = isQueueTransferProfile
      ? baseListPath + co.RESTAURANT_MERCHANT_SUB_TAB.QUEUE_TRANSFER_PROFILE
      : baseListPath + co.RESTAURANT_MERCHANT_SUB_TAB.QUEUE_PROFILE

  // eslint-disable-next-line no-unused-vars
  const [errorVersion, setErrorVersion] = useState(validation.getVersion())

  const onCloseDialog = () => {
    setDialogInfo({
      open: false,
    })
  }

  const onChangeInput = (name, e) => {
    setDispatchValue(name, savedData, '', e)
  }

  const onChangeScNameDisable = (value) => {
    setDispatchValue('scNameDisable', savedData, '', value)
  }

  const onRedirectToCreateEdit = () => {
    onCloseDialog()
    onClickClear()
  }

  const onRedirectToList = () => {
    setDialogInfo({
      open: false,
    })
    history.push(listPath)
  }

  const onClickSave = () => {
    validation.validateProfile(savedData)
    if (validation.isValid()) {
      const submitList = _.cloneDeep(savedData.submitTimeSectionList)
      const filteredList = submitList.filter((item) => item.selected)
      post(
        apiPath,
        {
          enName: savedData.enName,
          tcName: savedData.tcName,
          scName: savedData.scName,
          refTemplateCode: savedData.refTemplateCode,
          timeSectionList: filteredList.map(({ timeSectionId, tableType, status }) => ({
            restUrlId: params.restUrlId,
            timeSectionId,
            tableType,
            status,
          })),
        },
        () => {
          setDialogInfo({
            open: true,
            onClose: onRedirectToCreateEdit,
            dialogContentText: { key: 'createSuccess' },
            maxWidth: 'md',
            dialogActions: [
              { label: { key: 'backCreate' }, onClick: onRedirectToCreateEdit, icon: <ArrowBack /> },
              {
                label: { key: 'backList' },
                onClick: onRedirectToList,
                icon: <LowPriority />,
              },
            ],
          })
          setClear(false)
        },
        undefined,
        store
      )
    } else {
      setErrorVersion(validation.getVersion())
    }
  }

  const onClickClear = () => {
    setClear(true)
  }

  const content = [
    {
      tab: (
        <>
          <div className="edit-display-wrapper">
            <div>
              <GenericGetRefTemplateCode resource={resource} createEditType={'create'} />
            </div>
            <TempCustomCheckBox
              className="customize-btn"
              value={savedData.scNameDisable}
              customName={{ resource: resource, key: 'nameCustomize' }}
              onSelectCheckbox={onChangeScNameDisable}
            />
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'enName' },
                value: savedData.enName,
                name: 'enName',
                helperText: validation.getErrorMessages(validationConstants.KEY_EN_NAME),
              }}
              handleChange={onChangeInput}
            />
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'tcName' },
                value: savedData.tcName,
                name: 'tcName',
                helperText: validation.getErrorMessages(validationConstants.KEY_TC_NAME),
              }}
              handleChange={onChangeInput}
            />
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'scName' },
                value: savedData.scName,
                name: 'scName',
                helperText: validation.getErrorMessages(validationConstants.KEY_SC_NAME),
                disabled: savedData.scNameDisable,
              }}
              handleChange={onChangeInput}
            />
            <>
              <GenericQueueProfileTimeSectionList resource={resource} />
            </>
          </div>
        </>
      ),
      btn: true,
    },
  ]

  useEffect(() => {
    setDispatchDefault()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setDispatchDefault()
    setClear(false)
    validation.resetErrorMessages()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear === true])

  // useEffect(() => {
  //   console.log('savedData', savedData)
  // }, [savedData])

  return (
    <>
      <EditCreateDisplay
        resource={resource}
        content={content}
        mapName={{ resource: 'restaurant', key: 'restUrlId' }}
        mapCode={`${restUrlId} - ${restName}`}
        display="create"
        bottomBtns={getDefaultBottomBtnList(onClickSave, onClickClear)}
      />
      {dialogInfo.open && <DialogWrapper dialogInfo={dialogInfo} />}
    </>
  )
}
