import { RESERVATION_TEMPLATE_MAP_TYPE } from '../Constants/constants';
import { reservationTemplateMapValidationConstants } from '../Constants/validationConstants';

class ReservationTemplateValidation {

  version;
  errorMap;
  reservationTemplateMapErrorMap;
  valid;

  constructor() {

    if (!ReservationTemplateValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.reservationTemplateMapErrorMap = new Map();
      ReservationTemplateValidation.instance = this;
    }
    return ReservationTemplateValidation.instance;
  }

  validateTemplate(template) {
    this.resetErrorMessages();
    this.validateReservationTemplate(template);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.reservationTemplateMapErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key, uniqueCode) {
    if (!uniqueCode) {
      return (this.reservationTemplateMapErrorMap.has(key)) ? this.reservationTemplateMapErrorMap.get(key) : [];
    } else {
      switch (key) {
        case reservationTemplateMapValidationConstants.KEY_RESERVATION_TEMPLATE_MAP_ERROR:
        case reservationTemplateMapValidationConstants.KEY_REST_URL_ID:
        case reservationTemplateMapValidationConstants.KEY_TYPE:
        case reservationTemplateMapValidationConstants.KEY_DAY_OF_WEEK:
        case reservationTemplateMapValidationConstants.KEY_START_DATE:
        case reservationTemplateMapValidationConstants.KEY_END_DATE:
        case reservationTemplateMapValidationConstants.KEY_TEMPLATE_CODE:
          if (this.reservationTemplateMapErrorMap.has(uniqueCode) && this.reservationTemplateMapErrorMap.get(uniqueCode).has(key)) {
            return this.reservationTemplateMapErrorMap.get(uniqueCode).get(key);
          }
          return [];
        default:
          return [];
      }
    }
  }

  validateReservationTemplate(reservationTemplateMap) {
    let dateList = [];
    let dayOfWeekList = [];
    reservationTemplateMap.map((item) => {
      const reservationTemplateErrorMap = new Map();
      if (!item.type) {
        reservationTemplateErrorMap.set(reservationTemplateMapValidationConstants.KEY_TYPE, ['Missing Type']);
      } else if (item.type === RESERVATION_TEMPLATE_MAP_TYPE.DAY_OF_WEEK) {
        if (!item.dayOfWeek) {
          reservationTemplateErrorMap.set(reservationTemplateMapValidationConstants.KEY_DAY_OF_WEEK, ['Missing Day Of Week']);
        } else if (dayOfWeekList.includes(item.dayOfWeek)) {
          reservationTemplateErrorMap.set(reservationTemplateMapValidationConstants.KEY_DAY_OF_WEEK, ['Duplicate Day Of Week']);
        } else {
          dayOfWeekList.push(item.dayOfWeek)
        }
      } else if (item.type === RESERVATION_TEMPLATE_MAP_TYPE.DATE_RANGE) {
        const startDate = Number(item.startDate);
        const endDate = Number(item.endDate);
        if (isNaN(startDate) || startDate === 0) {
          reservationTemplateErrorMap.set(reservationTemplateMapValidationConstants.KEY_START_DATE, ['Missing Start Date']);
        } else if (!isNaN(startDate) && !isNaN(endDate)) {
          if (startDate > endDate) {
            reservationTemplateErrorMap.set(reservationTemplateMapValidationConstants.KEY_START_DATE, ['Start Date is larger than End Date']);
            reservationTemplateErrorMap.set(reservationTemplateMapValidationConstants.KEY_END_DATE, ['Start Date is larger than End Date']);
          } else {
            let error = [];
            dateList.map((dateValue) => {
              if (dateValue.startDate <= endDate && dateValue.endDate >= startDate) {
                reservationTemplateErrorMap.set(reservationTemplateMapValidationConstants.KEY_START_DATE, ['Duplicate Date']);
                reservationTemplateErrorMap.set(reservationTemplateMapValidationConstants.KEY_END_DATE, ['Duplicate Date']);
                error.push(true)
              }
              return dateValue
            })
            if (!error.includes(true)) {
              dateList.push({ startDate: startDate, endDate: endDate })
            }
          }
        }
        if (isNaN(endDate) || endDate === 0) {
          reservationTemplateErrorMap.set(reservationTemplateMapValidationConstants.KEY_END_DATE, ['Missing End Date']);
        }
      }
      if (!item.templateCode) {
        reservationTemplateErrorMap.set(reservationTemplateMapValidationConstants.KEY_TEMPLATE_CODE, ['Missing Template Code']);
      }
      if (reservationTemplateErrorMap.size > 0) {
        reservationTemplateErrorMap.set(reservationTemplateMapValidationConstants.KEY_RESERVATION_TEMPLATE_MAP_ERROR, ["Has error(s)"]);
        this.setReservationTemplateErrorMapMessages(item.id ? item.id : item.customId, reservationTemplateErrorMap);
      }
      return item
    })
  }


  setReservationTemplateErrorMapMessages(key, errorMessages, valid = false) {
    this.reservationTemplateMapErrorMap.set(key, errorMessages);
    // console.log("reservationTemplateMapErrorMap", this.reservationTemplateMapErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new ReservationTemplateValidation();
export default validation;