import React, { useState } from 'react'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import { TempTextInput } from '../../Component/Input/TempTextInput'
import { constants } from '../../Constants/constants'
import { TempBtn } from '../../Component/Input/TempBtn'
import { Card } from '@mui/material'

export const TextInputWithChips = ({ handleDelete, handleAdd, chipList = [], resource }) => {
  const [text, setText] = useState('')

  const handleSubmit = () => {
    handleAdd(text)
    setText('')
  }

  return (
    <div className="chip-input-wrapper">
      <Card className="card brand-id-wrapper">
        <TempTextInput
          inputSetting={{
            label: { resource: resource, key: 'tagInputLabel' },
            value: text,
            name: 'tagInputLabel',
          }}
          handleChange={(name, e) => setText(e)}
        />
        <TempBtn
          btnSetting={{
            variant: constants.STYLE.VARIANT.CONTAINED,
            color: constants.STYLE.PRIMARY,
            onClick: handleSubmit,
            label: { key: 'add' },
            disabled: !text,
          }}
        />
      </Card>
      <Stack direction="row" spacing={1}>
        {chipList?.map((chip, idx) => (
          <Chip key={idx} label={chip.label} variant="outlined" onDelete={() => handleDelete(chip, idx)} />
        ))}
      </Stack>
    </div>
  )
}
