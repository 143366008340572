import _ from "lodash";
import { BRAND } from '../actions/types';
import { constants } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        Brand: _.cloneDeep(constants.BRAND_INPUT)
    }
}

const BrandReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case BRAND.SET_BRAND_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case BRAND.SET_BRAND:
        case BRAND.SET_BRAND_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    Brand: action.Brand
                }
            })
        default:
            return state
    }
}

export default BrandReducer