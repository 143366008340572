import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { sify } from "chinese-conv";

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';
import { makeStyles } from '@mui/styles';

import { constants } from '../../Constants/constants';
import { setLoading } from "../../actions/systemAction";
import { setTemplateLabelList, setTemplateLabel } from "../../actions/printTemplateAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempCustomCheckBox } from "../../Component/Custom/TempCustomCheckBox";
import PrintTemplateValidation from "../../validations/printTemplateValidation";
import { printTemplateValidationConstants } from "../../Constants/validationConstants";
import { getString } from "../../helper/util";

import "../../styles/printTemplate.scss";

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const TemplateLabel = ({ type, sendTemplateLabel, selectedType }) => {
  const dispatch = useDispatch();
  const [clear, setClear] = useState(false);
  const resource = "PrintTemplate";
  const savedTemplateLabel = useSelector(state => state.printTemplate.ui.templateLabel);
  const savedTemplateLabelList = useSelector(state => state.printTemplate.ui.templateLabelList);
  const [templateLabelInputList, setTemplateLabelInputList] = useState(_.cloneDeep(savedTemplateLabel));
  const customIdNumber = useSelector(state => state.printTemplate.ui.customIdNumber)
  const lang = useSelector(state => state.lang.ui.lang);
  const theme = useSelector(state => state.theme.ui.theme);
  const helperTextStylesClasses = helperTextStyles();
  const [, setErrorVersion] = useState(PrintTemplateValidation.getVersion());
  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.PRINT_TEMPLATE_EDIT_TAB.INFO
    },
  ]

  const content = [
    {
      tab: <>
        <TempTextInput inputSetting={{
          className: "edit-create-input",
          size: constants.SIZE.SMALL,
          label: { resource: resource, key: "labelTitle" },
          value: savedTemplateLabel.labelTitle,
          name: "labelTitle",
          startAdornment: <InputAdornment position="start">label_</InputAdornment>,
          multiline: true,
          helperTextIcon: true,
          helperText: PrintTemplateValidation.getErrorMessages(printTemplateValidationConstants.KEY_TEMPLATE_LABEL_TITLE, savedTemplateLabel.customId).map((error) => { return error }),
          FormHelperTextProps: { className: helperTextStylesClasses.root },
        }}
          handleChange={handleTemplateLabelsChange} />
        <div>
          <TempCustomCheckBox className="customize-btn" value={savedTemplateLabel.disableScName} customName={{ key: "nameCustomize" }} onSelectCheckbox={(item) => onSelectCheckbox(item)} />
          <TempTextInput inputSetting={{
            className: "edit-create-input",
            size: constants.SIZE.SMALL,
            label: { resource: resource, key: "labelEn" },
            value: savedTemplateLabel.labelEn,
            name: "labelEn",
            multiline: true,
            helperTextIcon: true,
            helperText: PrintTemplateValidation.getErrorMessages(printTemplateValidationConstants.KEY_TEMPLATE_LABEL_EN, savedTemplateLabel.customId).map((error) => { return error }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
          }}
            handleChange={handleTemplateLabelsChange} />
          <TempTextInput inputSetting={{
            className: "edit-create-input",
            size: constants.SIZE.SMALL,
            label: { resource: resource, key: "labelTc" },
            value: savedTemplateLabel.labelTc,
            name: "labelTc",
            translateName: savedTemplateLabel.disableScName ? ["labelSc"] : null,
            inputList: templateLabelInputList,
            setInputList: setTemplateLabelInputList,
            multiline: true,
            helperTextIcon: true,
            helperText: PrintTemplateValidation.getErrorMessages(printTemplateValidationConstants.KEY_TEMPLATE_LABEL_TC, savedTemplateLabel.customId).map((error) => { return error }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
          }}
            handleChange={handleTemplateLabelsChange} />
          <TempTextInput inputSetting={{
            className: "edit-create-input",
            size: constants.SIZE.SMALL,
            label: { resource: resource, key: "labelSc" },
            value: savedTemplateLabel.labelSc,
            name: "labelSc",
            inputList: templateLabelInputList,
            setInputList: setTemplateLabelInputList,
            multiline: true,
            disabled: savedTemplateLabel.disableScName,
            helperTextIcon: true,
            helperText: PrintTemplateValidation.getErrorMessages(printTemplateValidationConstants.KEY_TEMPLATE_LABEL_SC, savedTemplateLabel.customId).map((error) => { return error }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
          }}
            handleChange={handleTemplateLabelsChange} />
        </div>
      </>,
      btn: false
    },
  ]

  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      disabled: Object.values(savedTemplateLabel).filter((item) => { return item === "" || item === null }).length > 0 ? true : false,
      onClick: () => onClickSave(),
      className: "margin-left-16",
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  function onSelectCheckbox(value) {
    let tempSavedTemplateLabel = _.cloneDeep(savedTemplateLabel);
    let tempSavedTemplateLabelList = _.cloneDeep(savedTemplateLabelList);
    dispatch(setLoading(true));
    tempSavedTemplateLabel["disableScName"] = value;

    if(value) {
      tempSavedTemplateLabel.labelSc = tempSavedTemplateLabel.labelTc? sify(tempSavedTemplateLabel.labelTc): tempSavedTemplateLabel.labelTc;
    }
    
    tempSavedTemplateLabelList = tempSavedTemplateLabelList.map((item) => {
      if (item.customId === tempSavedTemplateLabel.customId) {
        item = tempSavedTemplateLabel;
      }
      return item;
    })
    dispatch(setTemplateLabelList(tempSavedTemplateLabelList));
    dispatch(setLoading(false));
    dispatch(setTemplateLabel(tempSavedTemplateLabel));
    PrintTemplateValidation.validatePrintTempTemplateLabel(tempSavedTemplateLabelList);
    setErrorVersion(PrintTemplateValidation.getVersion());
  }

  function onChangeInput(name, e) {
    let tempSavedTemplateLabel = _.cloneDeep(savedTemplateLabel);
    let tempSavedTemplateLabelList = _.cloneDeep(savedTemplateLabelList);
    dispatch(setLoading(true));
    tempSavedTemplateLabel[`${name}`] = e;
    tempSavedTemplateLabelList = tempSavedTemplateLabelList.map((item) => {
      if (item.customId === tempSavedTemplateLabel.customId) {
        item = tempSavedTemplateLabel;
      }
      return item;
    })
    dispatch(setTemplateLabelList(tempSavedTemplateLabelList));
    dispatch(setLoading(false));
    dispatch(setTemplateLabel(tempSavedTemplateLabel));
    PrintTemplateValidation.validatePrintTempTemplateLabel(tempSavedTemplateLabelList);
    setErrorVersion(PrintTemplateValidation.getVersion());
  }

  function handleTemplateLabelsChange(name, e) {
    let tempSavedTemplateLabel = _.cloneDeep(savedTemplateLabel);
    let tempSavedTemplateLabelList = _.cloneDeep(savedTemplateLabelList);
    dispatch(setLoading(true));
    tempSavedTemplateLabel[`${name}`] = e;
    tempSavedTemplateLabelList = tempSavedTemplateLabelList.map((item) => {
      if (item.customId === tempSavedTemplateLabel.customId) {
        item = tempSavedTemplateLabel;
      }
      return item;
    })
    dispatch(setTemplateLabelList(tempSavedTemplateLabelList));
    dispatch(setLoading(false));
    dispatch(setTemplateLabel(tempSavedTemplateLabel));
    PrintTemplateValidation.validatePrintTempTemplateLabel(tempSavedTemplateLabelList);
    setErrorVersion(PrintTemplateValidation.getVersion());
  }

  const saveTemplateLabel = () => {
    return new Promise((resolve, reject) => {
      let tempSavedTemplateLabel = _.cloneDeep(savedTemplateLabel);
      let tempSavedTemplateLabelList = _.cloneDeep(savedTemplateLabelList);
      dispatch(setLoading(true));
      tempSavedTemplateLabelList = tempSavedTemplateLabelList.map((item) => {
        if (item.customId === tempSavedTemplateLabel.customId) {
          item = tempSavedTemplateLabel;
        }
        return item;
      })
      dispatch(setTemplateLabelList(tempSavedTemplateLabelList));
      dispatch(setLoading(false));
      dispatch(setTemplateLabel(tempSavedTemplateLabel));
      PrintTemplateValidation.validatePrintTempTemplateLabel(tempSavedTemplateLabelList);
      setErrorVersion(PrintTemplateValidation.getVersion());
      resolve(tempSavedTemplateLabel);
    })
  }
  const onClickSave = async () => {
    await saveTemplateLabel();
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    if (savedTemplateLabel.customId) {
      let tempTemplateLabel = savedTemplateLabelList.find((templateLabel) => templateLabel.customId === savedTemplateLabel.customId);
      dispatch(setTemplateLabel(tempTemplateLabel));
    } else {
      let newTemplateLabel = _.cloneDeep(constants.TEMPLATE_LABEL_LIST_INPUT);
      dispatch(setTemplateLabel(newTemplateLabel));
    }
    setClear(false);
  }, [clear]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (sendTemplateLabel) {
      sendTemplateLabel(savedTemplateLabelList, savedTemplateLabel, customIdNumber);
    }
  }, [savedTemplateLabel]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    type === "dialog" ?
      content.map((item, index) => {
        return <div className="card-wrapper" key={index}>
          {item.tab}
          {item.btn &&
            <div className="edit-footer-wrapper padding-16">
              {bottomBtns && bottomBtns.map((btnSetting, btnIndex) => {
                return <div key={"btn_" + btnIndex} className={btnSetting.className}>{getString(lang, btnSetting.label.resource ? btnSetting.label.resource : "", btnSetting.label.key ? btnSetting.label.key : "", theme)}</div>
              })}
            </div>
          }
        </div>
      })
      : <EditCreateDisplay
        code={savedTemplateLabel && savedTemplateLabel.customId ? savedTemplateLabel.customId : ""}
        resource={resource}
        tabs={type === "dialog" ? "" : tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={type === "dialog" ? [] : bottomBtns}
        saveDialog={{
          open: false,
          dialogActions: []
        }}
        display={type === "dialog" ? "none" : savedTemplateLabel.customId ? "edit" : "create"}
      />
  )
};