import { RestaurantMapEdit } from "../../Component/RestaurantTag/RestaurantMapEdit";
import { constants } from '../../Constants/constants';

import "../../styles/map.css";

export const KeywordResturantMapEdit = () => {
  return (
    <RestaurantMapEdit resourceName="restaurant_map" pathName={constants.PATH.RESTAURANT_TAG.RESTAURANT_KEYWORD_MAP} backListName={constants.PATH.RESTAURANT_TAG.RESTAURANT_KEYWORD} />
  );
};
