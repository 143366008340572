import { LANDING_SECTION_ACTION } from '../Constants/constants'
import { landingSectionValidationConstants } from '../Constants/validationConstants'
import { isValidDeepLink, isWebViewParametersValid } from '../Page/Landing/LandingSection/landingUtil'
import { isHexCodeValidate } from '../Page/QueueTemplate/queueTemplateUtil'
import { isWebActionValidUrl } from '../helper/util'
import landingItemValidation from './landingItemValidation'

class LandingSectionValidation {
  version
  errorMap
  landingSectionErrorMap
  valid

  constructor() {
    if (!LandingSectionValidation.instance) {
      this.version = 0
      this.valid = true
      this.errorMap = new Map()
      this.landingSectionErrorMap = new Map()
      LandingSectionValidation.instance = this
    }
    return LandingSectionValidation.instance
  }

  validateLandingSection(landingSection) {
    this.resetErrorMessages()
    this.handleValidateLandingSection(landingSection)
    this.version++
  }

  resetErrorMessages(type, id) {
    switch (type) {
      case 'reset_param':
        this.landingSectionErrorMap.delete(`${landingSectionValidationConstants.KEY_PARAMETERS}`)
        this.landingSectionErrorMap.delete(`${landingSectionValidationConstants.KEY_CHILDCODE}`)
        this.landingSectionErrorMap.delete(`${landingSectionValidationConstants.KEY_SERVICE_TYPE}`)
        this.landingSectionErrorMap.delete(`${landingSectionValidationConstants.KEY_CODE}`)
        break
      default:
        this.errorMap = new Map()
        this.landingSectionErrorMap = new Map()
        this.valid = true
        break
    }
  }

  getVersion() {
    return this.version
  }

  isValid() {
    return this.valid
  }

  getErrorMessages(key) {
    return this.landingSectionErrorMap.get(key)
  }

  handleValidateLandingSection(landingSection) {
    if (!landingSection.channel) {
      this.setLandingSectionErrorMapMessages(`${landingSectionValidationConstants.KEY_CHANNEL}`, 'Missing Channel')
    }

    if (!landingSection.sectionType) {
      this.setLandingSectionErrorMapMessages(`${landingSectionValidationConstants.KEY_SECTION_TYPE}`, 'Missing Section Type')
    }

    //title
    if (
      !(landingSection.tcTitle && landingSection.enTitle && landingSection.scTitle) &&
      !(!landingSection.tcTitle && !landingSection.enTitle && !landingSection.scTitle)
    ) {
      if (!landingSection.tcTitle) {
        this.setLandingSectionErrorMapMessages(`${landingSectionValidationConstants.KEY_TC_TITLE}`, 'Missing TC title')
      }
      if (!landingSection.enTitle) {
        this.setLandingSectionErrorMapMessages(`${landingSectionValidationConstants.KEY_EN_TITLE}`, 'Missing EN title')
      }
      if (!landingSection.scTitle) {
        this.setLandingSectionErrorMapMessages(`${landingSectionValidationConstants.KEY_SC_TITLE}`, 'Missing SC title')
      }
    }

    //sequence
    if (landingSection.sequence) {
      //not number
      if (isNaN(landingSection.sequence) || landingSection.sequence < 0) {
        this.setLandingSectionErrorMapMessages(`${landingSectionValidationConstants.KEY_SEQUENCE}`, 'Invalid sequence')
      }
    }

    if (!landingSection.sequence && landingSection.sequence !== 0) {
      this.setLandingSectionErrorMapMessages(`${landingSectionValidationConstants.KEY_SEQUENCE}`, 'Missing sequence')
    }

    if (landingSection.titleColor && !isHexCodeValidate(landingSection.titleColor)) {
      this.setLandingSectionErrorMapMessages(landingSectionValidationConstants.KEY_TITLE_COLOR, 'Title Color Invalid')
    }

    //Display date

    if (landingSection.startDisplayTimestamp && landingSection.endDisplayTimestamp) {
      const startDisplayTimestamp = Number(landingSection.startDisplayTimestamp)
      const endDisplayTimestamp = Number(landingSection.endDisplayTimestamp)

      if (isNaN(startDisplayTimestamp)) {
        this.setLandingSectionErrorMapMessages(`${landingSectionValidationConstants.KEY_START_DISPLAY_TIMESTAMP}`, 'Invalid start display timestamp')
      } else if (!isNaN(startDisplayTimestamp) && !isNaN(endDisplayTimestamp) && startDisplayTimestamp >= endDisplayTimestamp) {
        this.setLandingSectionErrorMapMessages(
          `${landingSectionValidationConstants.KEY_START_DISPLAY_TIMESTAMP}`,
          'Start display timestamp is larger than end display timestamp'
        )
      }
      if (isNaN(endDisplayTimestamp)) {
        this.setLandingSectionErrorMapMessages(`${landingSectionValidationConstants.KEY_END_DISPLAY_TIMESTAMP}`, 'Invalid end display timestamp')
      }
    }

    //status
    if (!landingSection.status) {
      this.setLandingSectionErrorMapMessages(`${landingSectionValidationConstants.KEY_STATUS}`, 'Missing Status')
    }

    const id = landingSection.id || landingSection.customId

    switch (landingSection.displayActionType) {
      case LANDING_SECTION_ACTION.RESTAURANT:
        if (!landingSection.parameters?.restUrlId) {
          this.setLandingSectionErrorMapMessages(`${id}-${landingSectionValidationConstants.KEY_PARAMETERS}`, 'Missing Parameter')
        }
        break
      case LANDING_SECTION_ACTION.PRODUCT:
        break
      case LANDING_SECTION_ACTION.PRODUCT_CATEGORY:
      case LANDING_SECTION_ACTION.E_COUPON_CATEGORY:
        if (!landingSection.parameters?.categoryCode) {
          this.setLandingSectionErrorMapMessages(`${id}-${landingSectionValidationConstants.KEY_PARAMETERS}`, 'Missing Parameter')
        }
        break
      case LANDING_SECTION_ACTION.PRODUCT_BRAND:
      case LANDING_SECTION_ACTION.E_COUPON_BRAND:
        if (!landingSection.parameters?.brandCode) {
          this.setLandingSectionErrorMapMessages(`${id}-${landingSectionValidationConstants.KEY_PARAMETERS}`, 'Missing Parameter')
        }
        break
      case LANDING_SECTION_ACTION.COUPON:
        break
      case LANDING_SECTION_ACTION.SEARCH:
        break
      case LANDING_SECTION_ACTION.TRANSFORM_TICKET_FROM_PAPER:
        if (!landingSection.parameters?.ticketId) {
          this.setLandingSectionErrorMapMessages(`${id}-${landingSectionValidationConstants.KEY_PARAMETERS}`, 'Missing Parameter')
        }
        break
      case LANDING_SECTION_ACTION.TRANSFORM_ECOUPON_FROM_PAPER:
        if (!landingSection.parameters?.infoId) {
          this.setLandingSectionErrorMapMessages(`${id}-${landingSectionValidationConstants.KEY_PARAMETERS}`, 'Missing Parameter')
        }
        break
      case LANDING_SECTION_ACTION.FOLDER:
        // if (!landingSection.parameters?.folderCode) {
        //   this.setLandingSectionErrorMapMessages(`${id}-${landingSectionValidationConstants.KEY_PARAMETERS}`, 'Missing Parameter')
        // }
        break
      case LANDING_SECTION_ACTION.VIDEO:
        if (!landingSection.parameters?.displayActionType) {
          this.setLandingSectionErrorMapMessages(`${id}-${landingSectionValidationConstants.KEY_PARAMETERS}`, 'Missing Parameter')
        }
        break
      case LANDING_SECTION_ACTION.SPRINGBOARD:
        if (!landingSection.parameters?.displayActionType) {
          this.setLandingSectionErrorMapMessages(`${id}-${landingSectionValidationConstants.KEY_PARAMETERS}`, 'Missing Parameter')
        }
        break
      case LANDING_SECTION_ACTION.EXPRESS_TICKET_VOUCHER:
        if (!landingSection.parameters?.groupCode) {
          this.setLandingSectionErrorMapMessages(`${id}-${landingSectionValidationConstants.KEY_PARAMETERS}`, 'Missing Parameter')
        }
        break
      case LANDING_SECTION_ACTION.MY_TICKET:
        if (!landingSection.parameters?.refId) {
          this.setLandingSectionErrorMapMessages(`${id}-${landingSectionValidationConstants.KEY_PARAMETERS}`, 'Missing Parameter')
        }
        if (!landingSection.parameters?.serviceType) {
          this.setLandingSectionErrorMapMessages(`${id}-${landingSectionValidationConstants.KEY_SERVICE_TYPE}`, 'Missing Service Type')
        } else if (landingSection.parameters.serviceType === 'RACK_PRODUCT' && !landingSection.parameters.type) {
          this.setLandingSectionErrorMapMessages(`${id}-${landingSectionValidationConstants.KEY_TYPE}`, 'Missing Type')
        }
        break
      case LANDING_SECTION_ACTION.GIFT:
        if (!landingSection.parameters?.refId) {
          this.setLandingSectionErrorMapMessages(`${id}-${landingSectionValidationConstants.KEY_PARAMETERS}`, 'Missing Parameter')
        }
        if (!landingSection.parameters?.code) {
          this.setLandingSectionErrorMapMessages(`${id}-${landingSectionValidationConstants.KEY_CODE}`, 'Missing Code')
        }
        if (!landingSection.parameters?.serviceType) {
          this.setLandingSectionErrorMapMessages(`${id}-${landingSectionValidationConstants.KEY_SERVICE_TYPE}`, 'Missing Service Type')
        } else if (landingSection.parameters['serviceType'] === 'RACK_PRODUCT' && !landingSection.parameters['type']) {
          this.setLandingSectionErrorMapMessages(`${id}-${landingSectionValidationConstants.KEY_TYPE}`, 'Missing Type')
        }
        break
      case LANDING_SECTION_ACTION.SHARE:
        if (!landingSection.parameters?.serviceType) {
          this.setLandingSectionErrorMapMessages(`${id}-${landingSectionValidationConstants.KEY_SERVICE_TYPE}`, 'Missing Service Type')
        }
        break
      case LANDING_SECTION_ACTION.YOUTUBE:
        if (!landingSection.parameters?.videoId && !landingSection.parameters?.youtubeCode) {
          this.setLandingSectionErrorMapMessages(`${id}-${landingSectionValidationConstants.KEY_PARAMETERS}`, 'Missing Parameter')
        }
        break
      case LANDING_SECTION_ACTION.RESTAURANT_CATEGORY:
        if (!landingSection.parameters?.categoryCode) {
          this.setLandingSectionErrorMapMessages(`${id}-${landingSectionValidationConstants.KEY_PARAMETERS}`, 'Missing Parameter')
        }
        break
      case LANDING_SECTION_ACTION.LINK:
        if (!landingSection.parameters?.url) {
          this.setLandingSectionErrorMapMessages(`${id}-${landingSectionValidationConstants.KEY_PARAMETERS}`, 'Missing Parameters')
        } else if (!isWebActionValidUrl(landingSection.parameters?.url)) {
          this.setLandingSectionErrorMapMessages(`${id}-${landingSectionValidationConstants.KEY_PARAMETERS}`, 'Invalid Parameters')
        }
        break
      case LANDING_SECTION_ACTION.WEB_VIEW:
        if (!landingSection.parameters?.url) {
          this.setLandingSectionErrorMapMessages(`${id}-${landingSectionValidationConstants.KEY_PARAMETERS}`, 'Missing Parameters')
        } else if (!isWebActionValidUrl(landingSection.parameters?.url)) {
          this.setLandingSectionErrorMapMessages(`${id}-${landingSectionValidationConstants.KEY_PARAMETERS}`, 'Invalid Parameters')
        } else if (isValidDeepLink(landingSection.parameters?.url)) {
          const { isValid, errorKey, errorMessage } = isWebViewParametersValid(landingSection.parameters.url)
          if (!isValid) {
            this.setLandingSectionErrorMapMessages(`${id}-${errorKey}`, errorMessage)
          }
        }
        break
      default:
    }

    for (const landingItem of landingSection.itemList) {
      landingItemValidation.validateLandingItem(landingItem)
      const id = landingItem.id || landingItem.customId
      if (!landingItem.itemType) {
        this.setLandingSectionErrorMapMessages(`${landingSectionValidationConstants.KEY_ITEM_TYPE}`, 'Missing Item Type')
      }
      if (!landingItemValidation.isValid()) {
        this.setLandingSectionErrorMapMessages(`${id}-${landingSectionValidationConstants.KEY_ITEM_ERROR}`, 'Has Error')
      }
    }
  }

  setLandingSectionErrorMapMessages(key, errorMessages, valid = false) {
    this.landingSectionErrorMap.set(key, errorMessages)
    // console.log(key, errorMessages)
    if (!valid) {
      this.valid = false
    }
  }
}

const validation = new LandingSectionValidation()
export default validation
