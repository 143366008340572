import { useState, useEffect } from 'react'
import { useSelector, useStore } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import _ from 'lodash'

import { Card } from '@mui/material'

import { ListDisplay } from '../../ListDisplay/ListDisplay'
import { constants } from '../../../Constants/constants'
import { getList } from '../../../helper/baseFetch'
import { generateTableCellToMultiLanguageName, getParamQueryString, getQuery, getQueryParamObject } from '../../../helper/util'

export const ProductCategoryList = ({
  header,
  createBtn,
  editBtn,
  mutipleSelect,
  dialogFooterBtn,
  previewSelectedList,
  setPreviewSelectedList,
  selectedIdList,
  setSelectedIdList,
  newPath,
  newFilter,
  onClickListRow,
  listOverflow,
  changeUrlQueryString,
}) => {
  const store = useStore()
  const total = useSelector((state) => state.system.ui.total)
  const [productCategoryList, setProductCategoryList] = useState([])
  const [ready, setReady] = useState(false)
  const [selectedAllCheck, setSelectedAllCheck] = useState(false)
  const resource = 'productCreateProductCategoryList'
  const history = useHistory()
  const location = useLocation()
  const filterParams = getQuery()

  const productCategoryTitleList =
    newPath === constants.PATH.PRODUCT.PRODUCT_CATEGORY_MAP
      ? [
          {
            key: constants.LIST_DISPLAY_TYPE.STRING,
            value: 'categoryType',
            sort: true,
          },
          {
            key: constants.LIST_DISPLAY_TYPE.STRING,
            value: 'categoryCode',
            sort: true,
          },
          {
            key: constants.LIST_DISPLAY_TYPE.STRING,
            value: 'name',
            sort: false,
            minSize: true,
          },
          {
            key: constants.LIST_DISPLAY_TYPE.STRING,
            value: 'categoryCodeDescription',
            sort: true,
          },
          {
            key: constants.LIST_DISPLAY_TYPE.STRING,
            value: 'categoryChannel',
            sort: true,
          },
          {
            key: constants.LIST_DISPLAY_TYPE.STRING,
            value: 'status',
            sort: true,
          },
        ]
      : [
          {
            key: constants.LIST_DISPLAY_TYPE.STRING,
            value: 'type',
            sort: true,
          },
          {
            key: constants.LIST_DISPLAY_TYPE.STRING,
            value: 'categoryCode',
            sort: true,
          },
          {
            key: constants.LIST_DISPLAY_TYPE.STRING,
            value: 'name',
            sort: false,
            minSize: true,
          },
          {
            key: constants.LIST_DISPLAY_TYPE.STRING,
            value: 'codeDescription',
            sort: true,
          },
          {
            key: constants.LIST_DISPLAY_TYPE.STRING,
            value: 'channel',
            sort: true,
          },
          {
            key: constants.LIST_DISPLAY_TYPE.STRING,
            value: 'displaySequence',
            sort: true,
          },
          {
            key: constants.LIST_DISPLAY_TYPE.STRING,
            value: 'status',
            sort: true,
          },
        ]
  const listFilters =
    newPath === constants.PATH.PRODUCT.PRODUCT_CATEGORY_MAP
      ? [
          {
            className: 'filter-input',
            label: { resource: resource, key: 'categoryType' },
            clear: true,
            value: '',
            type: constants.TYPE.SELECT,
            selectValue: constants.CHOICES.PRODUCT_CREATE.EDIT_PRODUCT.CATEGORY_TYPE,
            name: 'categoryType',
            size: constants.SIZE.SMALL,
          },
          {
            className: 'filter-input',
            label: { resource: resource, key: 'categoryCode' },
            clear: true,
            value: '',
            name: 'categoryCode',
            size: constants.SIZE.SMALL,
          },
          {
            className: 'filter-input',
            label: { resource: resource, key: 'name' },
            clear: true,
            value: '',
            name: 'name',
            size: constants.SIZE.SMALL,
          },
          {
            className: 'filter-input',
            label: { resource: resource, key: 'codeDescription' },
            clear: true,
            value: '',
            name: 'codeDescription',
            size: constants.SIZE.SMALL,
          },
          {
            className: 'filter-input',
            label: { resource: resource, key: 'channel' },
            value: '',
            type: constants.TYPE.SELECT,
            selectValue: constants.CHOICES.PRODUCT_CREATE.EDIT_PRODUCT.CHANNEL,
            name: 'channel',
            size: constants.SIZE.SMALL,
          },
        ]
      : [
          {
            className: 'filter-input',
            label: { resource: resource, key: 'type' },
            clear: true,
            value: '',
            type: constants.TYPE.SELECT,
            selectValue: newFilter
              ? constants.CHOICES.PRODUCT_CREATE.EDIT_PRODUCT.CATEGORY_TYPE.filter((item) => newFilter?.typeList?.includes(item.value))
              : constants.CHOICES.PRODUCT_CREATE.EDIT_PRODUCT.CATEGORY_TYPE,
            name: 'type',
            size: constants.SIZE.SMALL,
          },
          {
            className: 'filter-input',
            label: { resource: resource, key: 'categoryCode' },
            clear: true,
            value: '',
            name: 'categoryCode',
            size: constants.SIZE.SMALL,
          },
          {
            className: 'filter-input',
            label: { resource: resource, key: 'name' },
            clear: true,
            value: '',
            name: 'name',
            size: constants.SIZE.SMALL,
          },
          {
            className: 'filter-input',
            label: { resource: resource, key: 'codeDescription' },
            clear: true,
            value: '',
            name: 'codeDescription',
            size: constants.SIZE.SMALL,
          },
          {
            className: 'filter-input',
            label: { resource: resource, key: 'channel' },
            value: '',
            type: constants.TYPE.SELECT,
            selectValue: constants.CHOICES.PRODUCT_CREATE.EDIT_PRODUCT.CHANNEL,
            name: 'channel',
            size: constants.SIZE.SMALL,
          },
        ]

  const [param, setParam] = useState(
    !changeUrlQueryString
      ? {
          path: newPath ? newPath : '',
          page: 0,
          rowsPerPage: 10,
          sort: 'updateTimestamp',
          direction: 'DESC',
          listFilters: _.cloneDeep(listFilters),
          count: 0,
        }
      : getQueryParamObject(
          {
            path: newPath ? newPath : '',
            page: 0,
            rowsPerPage: 10,
            sort: 'updateTimestamp',
            direction: 'DESC',
            listFilters: _.cloneDeep(listFilters),
            count: 0,
          },
          _.cloneDeep(listFilters),
          productCategoryTitleList
        )
  )

  useEffect(() => {
    if (!changeUrlQueryString) {
      return
    }

    const querySyring = getParamQueryString(
      {
        path: newPath ? newPath : '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: param.count,
      },
      param
    )

    const currentQueryString = window.location.search?.substring(1)

    if (querySyring === currentQueryString) {
      return
    }

    const newParam = getQueryParamObject(
      {
        path: newPath ? newPath : '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: param.count + 1,
      },
      _.cloneDeep(listFilters),
      productCategoryTitleList
    )

    setParam(newParam)

    const newQuery = getParamQueryString(
      {
        path: newPath ? newPath : '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: 0,
      },
      newParam
    )

    history.replace({ pathname: location.pathname, search: newQuery })
  }, [filterParams])

  function onChangeParam(value) {
    value.count = value.count + 1
    if (!changeUrlQueryString) {
      setParam(value)
      return
    }

    const querySyring = getParamQueryString(
      {
        path: newPath ? newPath : '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: 0,
      },
      value
    )

    history.push({ pathname: location.pathname, search: querySyring })
  }

  function onChangeListFilters(list) {
    let tempParam = _.cloneDeep(param)
    // list.forEach((item, index)=>{
    //   if(item.name === 'type'){
    //     item.value = [item.value];
    //   }
    // })
    tempParam.listFilters = list
    tempParam.page = 0
    onChangeParam(tempParam)
  }

  function onChangeListSort(sortItem) {
    let tempParam = _.cloneDeep(param)
    tempParam.sort = sortItem
    tempParam.direction = tempParam.direction === 'DESC' ? 'ASC' : 'DESC'
    onChangeParam(tempParam)
  }

  function onChangeListPage(page) {
    let tempParam = _.cloneDeep(param)
    tempParam.page = page
    onChangeParam(tempParam)
  }

  function onChangeListRowsPerPage(rowsPerPage) {
    let tempParam = _.cloneDeep(param)
    tempParam.page = 0
    tempParam.rowsPerPage = rowsPerPage
    onChangeParam(tempParam)
  }

  function onMultiSelectList(categoryCode, dataList) {
    let selectedList = _.cloneDeep(selectedIdList)
    let newSelectedProductCategoryList = _.cloneDeep(previewSelectedList)
    let tempDataList = _.cloneDeep(dataList)
    if (categoryCode === 'all') {
      if (!selectedAllCheck) {
        productCategoryList.map((item) => {
          newSelectedProductCategoryList.push(item)
          return selectedList.push(item.categoryCode)
        })
      } else {
        selectedList = selectedList.filter((item) => {
          let selectedProductCategory = productCategoryList.map((productCategory) => {
            newSelectedProductCategoryList = newSelectedProductCategoryList.filter(
              (newSelectedProductCategory) => newSelectedProductCategory.categoryCode !== productCategory.categoryCode
            )
            return productCategory.categoryCode === item && productCategory.categoryCode
          })
          return !selectedProductCategory.includes(item)
        })
      }
      setSelectedAllCheck(!selectedAllCheck)
    } else {
      if (selectedList.includes(categoryCode)) {
        selectedList = selectedList.filter((item) => item !== categoryCode)
        newSelectedProductCategoryList = newSelectedProductCategoryList.filter((item) => item.categoryCode !== categoryCode)
      } else {
        selectedList.push(categoryCode)
        newSelectedProductCategoryList.push.apply(
          newSelectedProductCategoryList,
          tempDataList.filter((item) => item.categoryCode === categoryCode)
        )
      }

      let selectedPerPage = productCategoryList.filter((productCategory) => {
        return selectedList.includes(productCategory.categoryCode)
      })
      if (selectedPerPage.length === param.rowsPerPage && selectedPerPage.length !== 0) {
        setSelectedAllCheck(true)
      } else {
        setSelectedAllCheck(false)
      }
    }

    setSelectedIdList(selectedList)

    setPreviewSelectedList(
      newSelectedProductCategoryList.map((productCategory) => {
        return productCategory
      })
    )
  }

  useEffect(() => {
    let tempParam = _.cloneDeep(param)
    const filter = newFilter
      ? _.cloneDeep(newFilter)
      : selectedIdList && selectedIdList.length > 0
      ? { selected: { column: 'categoryCode', valueList: selectedIdList } }
      : {}
    tempParam.listFilters.forEach((filterItem) => {
      if (filterItem.value !== '' && filterItem.value !== undefined) {
        filter[filterItem.name] = filterItem.value
      }
    })

    if (filter.type) filter.typeList = [filter.type]

    getList(
      tempParam.path ? tempParam.path : constants.PATH.PRODUCT.PRODUCT_CATEGORY,
      {
        sort: { field: tempParam.sort, order: tempParam.direction },
        filter: filter,
        pagination: { page: tempParam.page + 1, perPage: tempParam.rowsPerPage },
      },
      (payload) => {
        tempParam.path === constants.PATH.PRODUCT.PRODUCT_CATEGORY_MAP
          ? payload.forEach((item) => (item.name = generateTableCellToMultiLanguageName(item.categoryTcName, item.categoryEnName, item.categoryScName)))
          : payload.map((item) => {
              item.name = generateTableCellToMultiLanguageName(item.tcName, item.enName, item.scName)
              return item
            })
        setProductCategoryList(payload)
        let selectedPerPage = payload.filter((category) => {
          return (
            selectedIdList &&
            selectedIdList
              .map((selectedId) => {
                return selectedId
              })
              .includes(category.categoryCode)
          )
        })
        if (selectedPerPage.length === Number(tempParam.rowsPerPage < total ? tempParam.rowsPerPage : total) && selectedPerPage.length !== 0) {
          setSelectedAllCheck(true)
        } else {
          setSelectedAllCheck(false)
        }
        setReady(true)
      },
      undefined,
      store
    )
  }, [param.count]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready && (
      <Card className={'product-category-list-card card-wrapper overflow'}>
        <ListDisplay
          listOverflow={listOverflow}
          newListMainTitle={header}
          onClickListRow={onClickListRow}
          page={param.page}
          rowsPerPage={param.rowsPerPage}
          onChangeListSort={onChangeListSort}
          resource={resource}
          dataList={productCategoryList}
          idName="categoryCode"
          sort={param.sort}
          direction={param.direction}
          listTitles={productCategoryTitleList}
          listFilters={param.listFilters}
          onChangeListPage={onChangeListPage}
          onChangeListRowsPerPage={onChangeListRowsPerPage}
          onChangeListFilters={onChangeListFilters}
          editBtn={editBtn}
          createBtn={createBtn === false ? false : true}
          mutipleSelect={mutipleSelect}
          onMultiSelectList={onMultiSelectList}
          selectedAllCheck={selectedAllCheck}
          previewSelectedIdList={selectedIdList}
          dialogFooterBtn={dialogFooterBtn}
          redirectPath={'/' + constants.PATH.PRODUCT.PRODUCT_CATEGORY + '/'}
        />
      </Card>
    )
  )
}
