import { constants, BANNER_TYPE } from '../../Constants/constants';
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CancelIcon from '@mui/icons-material/Cancel';

import { BaseWrapper } from "../../Component/BaseWrapper";
import { setBannerList, setBanner } from "../../actions/bannerAction";
import { setLoading } from "../../actions/systemAction";
import { TempBtn } from "../../Component/Input/TempBtn";
import BannerValidation from "../../validations/bannerValidation";
import { bannerValidationConstants } from '../../Constants/validationConstants';
import { getString } from "../../helper/util";

export const BannerCreateList = ({ resource, setCustomIdNumber, customIdNumber, bannerType, mapCode }) => {
  const dispatch = useDispatch();
  const savedBannerList = useSelector(state => state.banner.ui.bannerList);
  const savedBanner = useSelector(state => state.banner.ui.banner);
  const lang = useSelector(state => state.lang.ui.lang);
  const theme = useSelector(state => state.theme.ui.theme);

  function handleAddBannerList() {
    const tempBanner = _.cloneDeep(savedBannerList);
    const newBannerInput = _.cloneDeep(constants.BANNER_INPUT);
    let newCustomIdNumber = _.cloneDeep(customIdNumber);
    newBannerInput.customId = "banner_" + newCustomIdNumber;
    if (bannerType && bannerType !== "LANDING") {
      newBannerInput.type = bannerType;
    } else {
      newBannerInput.type = "";
    }
    if (bannerType === BANNER_TYPE.TICKET) {
      Object.assign(newBannerInput, { configMapList: [] });
    }
    newCustomIdNumber = newCustomIdNumber + 1; //!?
    if (mapCode) {
      newBannerInput.refCode = mapCode;
    } else {
      newBannerInput.refCode = null;
    }
    tempBanner.push(newBannerInput);
    dispatch(setBanner(newBannerInput));
    dispatch(setBannerList(tempBanner));
    //setCustomIdNumber(newCustomIdNumber + 1); //!?
    setCustomIdNumber(prev=> prev + 1);
    //BannerValidation.validateCreateBanner(tempBanner);
  }

  function handleCloneBanner(item) {
    const tempBanner = _.cloneDeep(savedBannerList);
    const newItem = _.cloneDeep(item);
    let newCustomIdNumber = _.cloneDeep(customIdNumber);
    newItem.id = null;
    newItem.customId = "banner_" + newCustomIdNumber;
    newCustomIdNumber = newCustomIdNumber + 1;
    tempBanner.push(newItem);
    dispatch(setBanner(newItem));
    dispatch(setBannerList(tempBanner));
    //setCustomIdNumber(newCustomIdNumber + 1);
    setCustomIdNumber(prev=> prev + 1);
    //BannerValidation.validateCreateBanner(tempBanner);
  }

  function handleDeleteBannerList(e, item) {
    e.stopPropagation();
    const newItem = _.cloneDeep(item);
    const tempBannerList = _.cloneDeep(savedBannerList);
    dispatch(setBannerList(tempBannerList.filter((banner) => newItem.customId !== banner.customId)));
    //BannerValidation.validateCreateBanner(tempBannerList.filter((banner) => newItem.customId !== banner.customId));
    const tempBanner = _.cloneDeep(savedBanner);
    if (tempBanner.customId === newItem.customId) {
      const newBannerInput = _.cloneDeep(constants.TIME_SECTION_DEFAULT_VALUE);
      dispatch(setBanner(newBannerInput));
    }
  }

  return (
    <div className="queue-template-content-wrapper">
      <BaseWrapper>
        <div className="queue-template-array-list-wrapper">
          <div className="sub-title">{getString(lang, resource, "mainTitle", theme)} : </div>
          <>
            <TempBtn
              btnSetting=
              {{
                className: "margin-left-16",
                onClick: () => { handleAddBannerList(); },
                variant: constants.STYLE.VARIANT.CONTAINED,
                color: constants.STYLE.PRIMARY,
                label: { resource: resource, key: "addBanner" },
                icon: <AddIcon />,
              }}
            >
            </TempBtn>
          </>
        </div>
        <div className="queue-template-content-content">
          <div className="queue-template-tab-background">
            {BannerValidation.getErrorMessages(bannerValidationConstants.KEY_BANNER_ERROR) &&
              BannerValidation.getErrorMessages(bannerValidationConstants.KEY_BANNER_ERROR).length > 0 &&
              <div className="error-message">
                <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                {BannerValidation.getErrorMessages(bannerValidationConstants.KEY_BANNER_ERROR)}
              </div>
            }
            {savedBannerList.map((item, index) => {
              let error = false;
              if (BannerValidation.getErrorMessages(bannerValidationConstants.KEY_QUEUE_TEMPLATE_ERROR, item.customId).map((error) => { return error }).length > 0) {
                error = true;
              }
              let list = "";
              list =
                <div
                  key={item.customId}
                  className={item.customId === savedBanner.customId
                    ? "queue-template-list-item queue-template-selected-product"
                    : error
                      ? "queue-template-list-item queue-template-error"
                      : "queue-template-list-item"}
                  onClick={(e) => {
                    dispatch(setLoading(true));
                    e.stopPropagation();
                    dispatch(setBanner(item));
                    dispatch(setLoading(false));
                  }}>
                  <div className="code">
                    <div>{getString(lang, resource, "id", theme) + ": " + item.customId}</div>
                    <div className="type">{getString(lang, resource, "type", theme) + ": " + item.type}</div>
                  </div>
                  <div className="content">
                    <div className="top">
                      <div className="name">{item.tcName}</div>
                      <div className="delete-button">
                        <TempBtn
                          btnSetting=
                          {{
                            className: "delete-button delete-button-margin",
                            onClick: (e) => {
                              dispatch(setLoading(true));
                              handleCloneBanner(item);
                              dispatch(setLoading(false));
                            },
                            color: constants.STYLE.PRIMARY,
                            variant: constants.STYLE.VARIANT.CONTAINED,
                            label: { key: "Clone" },
                            icon: <FileCopyIcon />,
                          }}
                        >
                        </TempBtn>
                        {!item.id &&
                          <TempBtn
                            btnSetting=
                            {{
                              className: "delete-button",
                              onClick: (e) => {
                                dispatch(setLoading(true));
                                handleDeleteBannerList(e, item);
                                dispatch(setLoading(false));
                              },
                              color: constants.STYLE.PRIMARY,
                              variant: constants.STYLE.VARIANT.CONTAINED,
                              label: { key: "deleteBtn" },
                              icon: <DeleteIcon />,
                            }}
                          >
                          </TempBtn>
                        }
                      </div>
                    </div>
                    {BannerValidation.getErrorMessages(bannerValidationConstants.KEY_BANNER_ERROR, item.customId) &&
                      BannerValidation.getErrorMessages(bannerValidationConstants.KEY_BANNER_ERROR, item.customId).map((error) => { return error }).length > 0 &&
                      <div className="error-message">
                        <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                        {BannerValidation.getErrorMessages(bannerValidationConstants.KEY_BANNER_ERROR, item.customId).map((error) => { return error })}
                      </div>
                    }
                  </div>
                </div>
              return list;
            })}
          </div>
        </div>
      </BaseWrapper>
    </div>
  )
};