import _ from "lodash";
import { RESTAURANT_APPROVE } from '../actions/types';
import { constants } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        restaurantApprove: _.cloneDeep(constants.RESTAURANT_APPROVE_INPUT)
    }
}

const restaurantApproveReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RESTAURANT_APPROVE.SET_RESTAURANT_APPROVE_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case RESTAURANT_APPROVE.SET_RESTAURANT_APPROVE:
        case RESTAURANT_APPROVE.SET_RESTAURANT_APPROVE_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    restaurantApprove: action.restaurantApprove
                }
            })
        default:
            return state
    }
}

export default restaurantApproveReducer