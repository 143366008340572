import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import QRCode from "qrcode.react";
import Barcode from "react-barcode";
// import { Textfit } from 'react-textfit';
import moment from 'moment';
import 'moment/locale/zh-hk'
import 'moment/locale/zh-cn'

import "../../styles/printTemplatePreview.scss";
import { constants, PRINT_TEMPLATE_TYPE } from "../../Constants/constants";

export const PrintTemplatePreview = () => {

  const templateList = useSelector(state => state.printTemplate.ui.templateList);
  const templateLabelList = useSelector(state => state.printTemplate.ui.templateLabelList);
  const [preview, setPreview] = useState([]);

  useEffect(() => {
    if (templateList) {
      let alignment = 'left';
      let fontSize = 14;
      let locale = 'en';
      let fontStyle = 'normal';
      let dateFormat = 'YYYY/MM/DD';
      let tempString = "";
      let tempPrintText = <></>;

      const tempPreview = [];

      templateList.forEach((item, itemIndex) => {
        if (item.type !== PRINT_TEMPLATE_TYPE.PRINT_TEXT && tempString) {
          tempPreview.push(
            <div key={itemIndex}
              style={{ width: '100%', textAlign: alignment, fontSize: fontSize }}>
              {tempString}
            </div>
          );
          tempString = ""
        }

        if ((item.type === PRINT_TEMPLATE_TYPE.PRINT_IMAGE
          || item.type === PRINT_TEMPLATE_TYPE.PRINT_BAR_CODE
          || item.type === PRINT_TEMPLATE_TYPE.PRINT_QR_CODE
          || item.type === PRINT_TEMPLATE_TYPE.PRINT_TEXT_AS_IMAGE
          || item.type === PRINT_TEMPLATE_TYPE.PRINT_TEXT_AS_IMAGE_NO_LINE_WRAP
          || item.type === PRINT_TEMPLATE_TYPE.ALIGNMENT) && tempPrintText) {
          tempPreview.push(
            <div key={itemIndex}
              style={{ width: '100%', textAlign: alignment }}>
              {tempPrintText}
            </div>
          );
          tempPrintText = <></>;
          tempString = "";
        }

        switch (item.type) {
          case PRINT_TEMPLATE_TYPE.FONT_SIZE:
            switch (item.value) {
              case 101:
                fontSize = 10;
                break;
              case 102:
                fontSize = 12;
                break;
              default:
                fontSize = 14 * Number(item.value);
            }
            break;
          case PRINT_TEMPLATE_TYPE.FONT_SIZE_DIRECT:
            fontSize = Number(item.value);
            break;
          case PRINT_TEMPLATE_TYPE.ALIGNMENT:
            switch (item.value) {
              case 0:
                alignment = 'left';
                break;
              case 1:
                alignment = 'center';
                break;
              case 2:
                alignment = 'right';
                break;
              default:
            }
            break;
          case PRINT_TEMPLATE_TYPE.PRINT_TEXT:
            item.value.forEach((stringItem, stringItemIndex) => {
              switch (stringItem) {
                case "New Line":
                  tempString += "\n"
                  break;
                //eslint-disable-next-line
                case "${date}":
                  moment.locale(locale);
                  tempString += moment().format(dateFormat)
                  break;
                default:
                  if (stringItem.includes("${")) {
                    let tempExample = "";
                    let tempLabel = "";
                    stringItem = stringItem.slice(2, -1);
                    tempExample = constants.CHOICES.PRINT_TEMPLATE.TEMPLATE_PREDEFINED_TEXT.find((predefinedText) => predefinedText.value === stringItem);
                    tempLabel = templateLabelList.find((label) => "label_" + label.labelTitle === stringItem);
                    if (tempExample && tempExample.example) {
                      tempString += tempExample.example;
                    } else if (tempLabel) {

                      tempString += tempLabel.labelEn;
                    } else {
                      tempString += stringItem;
                    }
                  } else {
                    tempString += stringItem;
                  }
              }
            })
            if (itemIndex === templateList.length - 1) {
              tempPreview.push(
                <div key={itemIndex}
                  style={{ width: '100%', textAlign: alignment }}>
                  <>
                    {tempPrintText}
                    <span key={itemIndex}
                      style={{ width: '100%', fontSize: fontSize, lineBreak: "anywhere" }}>
                      {tempString}
                    </span>
                  </>
                </div>
              );
              tempPrintText = <></>;
              tempString = "";
            } else {
              tempPrintText = <>
                {tempPrintText}
                <span key={itemIndex}
                  style={{ width: '100%', fontSize: fontSize, lineBreak: "anywhere" }}>
                  {tempString}
                </span>
              </>
              tempString = "";
            }
            break;
          case PRINT_TEMPLATE_TYPE.PRINT_TEXT_AS_IMAGE:
            item.value.forEach((stringItem, stringItemIndex) => {
              switch (stringItem) {
                case "New Line":
                  tempString += "\n"
                  break;
                //eslint-disable-next-line
                case "${date}":
                  moment.locale(locale);
                  tempString += moment().format(dateFormat)
                  break;
                default:
                  if (stringItem.includes("${")) {
                    let tempExample = "";
                    let tempLabel = "";
                    stringItem = stringItem.slice(2, -1);
                    tempExample = constants.CHOICES.PRINT_TEMPLATE.TEMPLATE_PREDEFINED_TEXT.find((predefinedText) => predefinedText.value === stringItem);
                    tempLabel = templateLabelList.find((label) => "label_" + label.labelTitle === stringItem);
                    if (tempExample && tempExample.example) {
                      tempString += tempExample.example;
                    } else if (tempLabel) {

                      tempString += tempLabel.labelEn;
                    } else {
                      tempString += stringItem;
                    }
                  } else {
                    tempString += stringItem;
                  }
              }
            })
            tempPreview.push(
              <div key={itemIndex}
                style={{ width: '100%', textAlign: alignment, fontSize: fontSize, fontWeight: fontStyle }}>
                {tempString}
              </div>
            );
            tempString = "";
            break;
          case PRINT_TEMPLATE_TYPE.PRINT_TEXT_AS_IMAGE_NO_LINE_WRAP:
            item.value.forEach((stringItem, stringItemIndex) => {
              switch (stringItem) {
                case "New Line":
                  tempString += "\n"
                  break;
                //eslint-disable-next-line
                case "${date}":
                  moment.locale(locale);
                  tempString += moment().format(dateFormat)
                  break;
                default:
                  if (stringItem.includes("${")) {
                    let tempExample = "";
                    let tempLabel = "";
                    stringItem = stringItem.slice(2, -1);
                    tempExample = constants.CHOICES.PRINT_TEMPLATE.TEMPLATE_PREDEFINED_TEXT.find((predefinedText) => predefinedText.value === stringItem);
                    tempLabel = templateLabelList.find((label) => "label_" + label.labelTitle === stringItem);
                    if (tempExample && tempExample.example) {
                      tempString += tempExample.example;
                    } else if (tempLabel) {

                      tempString += tempLabel.labelEn;
                    } else {
                      tempString += stringItem;
                    }
                  } else {
                    tempString += stringItem;
                  }
              }
            })
            tempPreview.push(
              <div key={itemIndex}
                style={{ width: '100%', textAlign: alignment, fontSize: fontSize, fontWeight: fontStyle }}>
                {/* <Textfit mode="single" max={fontSize} > */}
                  {tempString}
                {/* </Textfit> */}
              </div>
            );
            tempString = "";
            break;
          case PRINT_TEMPLATE_TYPE.PRINT_IMAGE:
            let tempImage = [];
            item.value.forEach((stringItem, stringItemIndex) => {
              switch (stringItem) {
                default:
                  tempImage.push(<div className="preview-image">{stringItem}</div>);
              }
            })
            tempPreview.push(
              <div key={itemIndex}
                style={{ display: "flex", justifyContent: alignment }}>
                {tempImage}
              </div>
            );
            break;
          case PRINT_TEMPLATE_TYPE.PRINT_QR_CODE:
            item.value.forEach((stringItem, stringItemIndex) => {
              switch (stringItem) {
                default:
                  tempPreview.push(
                    <div style={{ textAlign: alignment }}>
                      <QRCode
                        style={{ textAlign: alignment }}
                        size={100} value={'qrcode'} level={'L'} renderAs={"svg"} />
                    </div>
                  )
              }
            })
            break;
          case PRINT_TEMPLATE_TYPE.PRINT_BAR_CODE:
            item.value.forEach((stringItem, stringItemIndex) => {
              tempPreview.push(
                <div style={{ width: '100%', overflow: 'hidden', textAlign: alignment }}>
                  <Barcode margin={0} width={1.5} height={60} style={{ textAlign: alignment }} displayValue={false} value={stringItem} />
                </div>
              )
              return stringItem;
            });
            break;
          case PRINT_TEMPLATE_TYPE.FONT_STYLE:
            switch (item.value) {
              case 0:
                fontStyle = 'normal';
                break;
              case 1:
                fontStyle = 'bold';
                break;
              default:
            }
            break;
          case PRINT_TEMPLATE_TYPE.DATE_FORMAT:
            let newTemplate = "";
            item.value.map((template) => {
              // if (template.type === "date") {
              //   template.value.map((value, index) => {
              //     newTemplate = newTemplate + value.value.toUpperCase();
              //     if (template.value.length - 1 !== index) {
              //       if (value.value) {
              //         newTemplate = newTemplate + template.middleSymbol;
              //       }
              //     } else {
              //       if (template.middleSymbol) {
              //         newTemplate = newTemplate.slice(0, -1) + " ";
              //       } else {
              //         newTemplate = newTemplate + " ";
              //       }
              //     }
              //     // console.log('newTemplate', newTemplate)
              //     return value;
              //   })
              if (template.type === "date") {
                template.value.map((value, index) => {
                  if (template.value.length - 1 !== index) {
                    // console.log('not last')
                    if (template.middleSymbol) {
                      newTemplate = newTemplate + value.value.toUpperCase() + template.middleSymbol
                    } else {
                      newTemplate = newTemplate + value.value.toUpperCase()
                    }
                  } else if (template.value.length - 1 === index) {
                    // console.log('last')
                    newTemplate = newTemplate + value.value.toUpperCase() + ' '
                  }
                  return value;
                })
              } else if (template.type === "time") {
                template.value.map((value, index) => {
                  if (template.bracket) {
                    if (index === 0) {
                      newTemplate = newTemplate + "(" + value.value;
                      if (value.value) {
                        newTemplate = newTemplate + ":";
                      }
                    } else if (index === template.value.length - 1) {
                      if (!value.value) {
                        newTemplate = newTemplate.substring(0, newTemplate.length - 1);
                      } else if (value.type === "timeFormat") {
                        if (newTemplate.charAt(newTemplate.length - 1) === ":") {
                          newTemplate = newTemplate.substring(0, newTemplate.length - 1);
                        }
                        newTemplate = newTemplate + " "
                      }
                      newTemplate = newTemplate + value.value + ") ";
                    } else if (value.type === "timeFormat") {
                      newTemplate = newTemplate.substring(0, newTemplate.length - 1);
                      newTemplate = newTemplate + value.value
                    } else {
                      newTemplate = newTemplate + value.value;
                      if (template.value.length - 1 !== index) {
                        if (value.value) {
                          newTemplate = newTemplate + ":";
                        }
                      } else {
                        newTemplate = newTemplate + " ";
                      }
                    }
                  } else {
                    if (value.value && value.type === "timeFormat") {
                      newTemplate = newTemplate.substring(0, newTemplate.length - 1);
                      newTemplate = newTemplate + " " + value.value + " ";
                    } else {
                      newTemplate = newTemplate + value.value;
                      if (template.value.length - 1 !== index) {
                        if (value.value) {
                          newTemplate = newTemplate + ":";
                        }
                      } else {
                        newTemplate = newTemplate.substring(0, newTemplate.length - 1);
                        newTemplate = newTemplate + " ";
                      }
                    }
                  }
                  return value;
                })
              } else if (template.type === "week") {
                template.value.map((value, index) => {
                  if (value.value === "E") {
                    newTemplate = newTemplate + "ddd "
                  } else if (value.value === "EEEE") {
                    newTemplate = newTemplate + "dddd "
                  }
                  return value;
                })
              }
              return template;
            })
            dateFormat = newTemplate;
            break;
          case PRINT_TEMPLATE_TYPE.LOCALE:
            locale = item.value;
            break;
          default:
        }
      })

      setPreview(tempPreview);
    }
  }, [templateList]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="printe-template-preview">
      {preview}
    </div>
  )
}