import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import SplitPane from 'react-split-pane';
import { Allotment } from 'allotment'
import 'allotment/dist/style.css'

import { MerchantShopCreateUserList } from "./MerchantShopCreateUserList/MerchantShopCreateUserList";
import { MerchantUserCreate } from "../../../Page/MerchantUser/MerchantUserCreate";
import { setMerchantShopUserListValue } from "../../../actions/merchantShopCreateAction";
import { setMerchantUser } from "../../../actions/merchantUserAction";

export const MerchantAccounts = ( ) =>{

  const dispatch = useDispatch();
  const savedMerchantShop = useSelector(state => state.merchantShop.ui.merchantShop);
  const [currentEditUser, setCurrentEditUser] = useState(null);
  const savedMerchantUser = useSelector(state => state.merchantUser.ui.merchantUser);

  useEffect(() => {
    if(currentEditUser){
      dispatch(setMerchantShopUserListValue(null, savedMerchantShop, "", currentEditUser));
      dispatch(setMerchantUser(currentEditUser))
    }
  }, [currentEditUser])
  
  return (
    <>
     <Allotment defaultSizes={[100, 200]}>
      <div className="split-pane-left">
        <MerchantShopCreateUserList currentEditUser={currentEditUser} setCurrentEditUser={setCurrentEditUser}/>
      </div>
      <div className="split-pane-right">
        {
        currentEditUser && savedMerchantUser &&
        < MerchantUserCreate 
          currentEditUser={currentEditUser} 
          setCurrentEditUser={setCurrentEditUser}
          hideTab={true} 
          hideBottomBtnList={true} 
          />
        }
      </div>
      </Allotment>
    </>
  )
}