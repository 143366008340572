import { counterProfileValidationConstants as constants } from '../Constants/validationConstants'
class CounterProfileValidation {
  version
  errMap
  valid

  constructor() {
    if (!CounterProfileValidation.instance) {
      this.version = 0
      this.valid = true
      this.errMap = new Map()
      CounterProfileValidation.instance = this
    }
    return CounterProfileValidation.instance
  }

  validateSavedData(savedData) {
    this.resetErrMsg()
    this.validateObj(savedData)
    this.version++
  }

  validateObj(savedData) {
    if (!savedData.enName) {
      this.setErrMap(constants.KEY_EN_NAME, 'Missing En Name')
    }
    if (!savedData.scName) {
      this.setErrMap(constants.KEY_SC_NAME, 'Missing Sc Name')
    }
    if (!savedData.tcName) {
      this.setErrMap(constants.KEY_TC_NAME, 'Missing Tc Name')
    }
    if (!savedData.counterList) {
      this.setErrMap(constants.KEY_COUNTER_LIST, 'Missing Counter Sequence, please select items from Counter')
    }
  }

  resetErrMsg() {
    this.errMap = new Map()
    this.valid = true
  }

  getVersion() {
    return this.version
  }

  isValid() {
    return this.valid
  }

  getErrMsg(key, uniqueKey) {
    if (!uniqueKey) {
      return this.errMap.has(key) ? this.errMap.get(key) : []
    } else {
      return []
    }
  }

  setErrMap(key, errMsg, valid = false) {
    this.errMap.set(key, errMsg)
    if (!valid) {
      this.valid = false
    }
  }
}

const validation = new CounterProfileValidation()
export default validation
