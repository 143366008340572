import { BRAND_GROUP } from './types';
import { sify } from "chinese-conv";
import _ from "lodash";

export const setBrandGroupDefault = () => {
    return {
        type: BRAND_GROUP.SET_BRAND_GROUP_DEFAULT,
    }
}

export const setBrandGroup = (BrandGroup) => {
    return {
        type: BRAND_GROUP.SET_BRAND_GROUP,
        BrandGroup: BrandGroup
    }
}

export const setBrandGroupValue = (draftType, orignalProduct, key, value, index) => {
    const BrandGroup = _.cloneDeep(orignalProduct);
    switch (draftType) {
        case "CHANGE_TC_NAME_AND_SC_NAME":
            BrandGroup.tcName = value;
            BrandGroup.scName = sify(value);
            break;
        case "scNameDisable":
            BrandGroup[draftType] = value;
            if(value) {
                BrandGroup.scName = BrandGroup.tcName? sify(BrandGroup.tcName): BrandGroup.tcName;
            }
            break;
        default:
            BrandGroup[draftType] = value;
    }
    return {
        type: BRAND_GROUP.SET_BRAND_GROUP_VALUE,
        BrandGroup: BrandGroup
    }
}
