import { CONTACT_US } from './types';
import _ from "lodash";

export const setContactUsDefault = () => {
    return {
        type: CONTACT_US.SET_CONTACT_US_DEFAULT,
    }
}

export const setContactUs = (contactUs) => {
    return {
        type: CONTACT_US.SET_CONTACT_US,
        contactUs: contactUs
    }
}

export const setContactUsValue = (draftType, orignalProduct, key, value, index) => {
    const contactUs = _.cloneDeep(orignalProduct);
    contactUs[draftType] = value;
    return {
        type: CONTACT_US.SET_CONTACT_US_VALUE,
        contactUs: contactUs
    }
}
