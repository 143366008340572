
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { v4 as uuidv4 } from 'uuid';

import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CancelIcon from '@mui/icons-material/Cancel';

import { constants } from "../../../../Constants/constants";
import { BaseWrapper } from "../../../BaseWrapper";
import { setMerchantShopValue, removeMerchantShopUser } from "../../../../actions/merchantShopCreateAction";
import MerchantUserValidation from "../../../../validations/merchantUserValidation";
import MerchantShopCreateValidation from "../../../../validations/merchantShopCreateValidation";
import { merchantShopValidationConstants } from "../../../../Constants/validationConstants";
import { getString } from "../../../../helper/util";
import { TempBtn } from "../../../Input/TempBtn";

const resource = "MerchantDraft";
export const MerchantShopCreateUserList = ({setCurrentEditUser, currentEditUser}) =>{
  const savedMerchantShop = useSelector(state => state.merchantShop.ui.merchantShop);
  const lang = useSelector(state => state.lang.ui.lang);
  const theme = useSelector(state => state.theme.ui.theme);

  return (
    <div className="merchant-shop-create-user-content-wrapper">
      <BaseWrapper>
        <div className="merchant-shop-create-user-list-wrapper flex-space-between-container">
          <div className="sub-title">{getString(lang, resource, "userCreate", theme)} : </div>
          <AddMerchantUserButton />
        </div>
        <div className="queue-template-content-content">
          <div className="queue-template-tab-background">
            {MerchantShopCreateValidation.getErrorMessages(merchantShopValidationConstants.KEY_MERCHANT_USER_LIST) &&
              <div className="error-message">
                <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                {MerchantShopCreateValidation.getErrorMessages(merchantShopValidationConstants.KEY_MERCHANT_USER_LIST)}
              </div>
            }
            {savedMerchantShop?.draft?.merchantUserList?.map((user, index) => <MerchantUser key={user.customId} user={user} setCurrentEditUser={setCurrentEditUser} currentEditUser={currentEditUser}/>)}
          </div>
        </div>
      </BaseWrapper>
    </div>
  )
}

const AddMerchantUserButton = () =>{

  const dispatch = useDispatch();
  const savedMerchantShop = useSelector(state => state.merchantShop.ui.merchantShop);

  function handleAddMerchantUserList() {
    dispatch(setMerchantShopValue("DRAFT", savedMerchantShop, "merchantUserList", [...savedMerchantShop?.draft?.merchantUserList,{...constants.MERCHANT_USER_INPUT, customId: uuidv4()}]));
  }
  
  return(
    <TempBtn
      btnSetting=
      {{
        className: "margin-left-16",
        onClick: () => { handleAddMerchantUserList(); },
        variant: constants.STYLE.VARIANT.CONTAINED,
        color: constants.STYLE.PRIMARY,
        label: { resource: resource, key: "addUser" },
        icon: <AddIcon />,
      }}
    >
    </TempBtn>
  )
}

const getTabClassName = (currentEditUserId, userId ) =>{

  if(currentEditUserId === userId){
    return "queue-template-list-item queue-template-selected-product"
  }

  if(MerchantUserValidation.getErrorMessages(`${userId}`)){
    return "queue-template-list-item queue-template-error"
  }
 
  return "queue-template-list-item"

}

const MerchantUser= ({user, setCurrentEditUser, currentEditUser}) =>{

  const dispatch = useDispatch();
  const savedMerchantShop = useSelector(state => state.merchantShop.ui.merchantShop);
  const lang = useSelector(state => state.lang.ui.lang);
  const theme = useSelector(state => state.theme.ui.theme);

  function handleCloneMerchantUser(user) {
    const cloneUser = _.cloneDeep(user);
    cloneUser.customId = uuidv4();
    dispatch(setMerchantShopValue("DRAFT", savedMerchantShop, "merchantUserList", [...savedMerchantShop?.draft?.merchantUserList, cloneUser]));
  }

  function handleDeleteMerchantUserList(user) {
    if(user.customId === currentEditUser?.customId){
      setCurrentEditUser(null);
    }
    dispatch(removeMerchantShopUser(savedMerchantShop, user.customId));
  }

  return(
    <>
    <div className={getTabClassName(currentEditUser?.customId, user?.customId)} 
          onClick={(e)=>{
            setCurrentEditUser(user);
            }}>
      <div className="code">
        <div>{getString(lang, resource, "id", theme) + ": " + user.customId}</div>
        <div className="type">{getString(lang, resource, "merchantRole", theme) + ": " + user.merchantRole}</div>
      </div>
      <div className="content">
        <div className="top">
          <div className="name">{user.username}</div>
          <div className="delete-button">
            <TempBtn
              btnSetting=
              {{
                className: "delete-button delete-button-margin",
                onClick: (e) => {
                  handleCloneMerchantUser(user);
                },
                color: constants.STYLE.PRIMARY,
                variant: constants.STYLE.VARIANT.CONTAINED,
                label: { key: "Clone" },
                icon: <FileCopyIcon />,
              }}
            >
            </TempBtn>
            {!user.id &&
              <TempBtn
                btnSetting=
                {{
                  className: "delete-button",
                  onClick: (e) => {
                    e.stopPropagation();
                    handleDeleteMerchantUserList(user);
                  },
                  label: { key: "deleteBtn" },
                  color: constants.STYLE.PRIMARY,
                  variant: constants.STYLE.VARIANT.CONTAINED,
                  icon: <DeleteIcon />,
                }}
              >
              </TempBtn>
            }
          </div>
        </div>
        {MerchantUserValidation.getErrorMessages(user.customId) &&
          <div className="error-message">
            <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
            {MerchantUserValidation.getErrorMessages(user.customId)}
          </div>
        }
      </div>
    </div>
    </>
  )
}