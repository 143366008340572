import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector, useStore } from "react-redux";
import moment from 'moment';
import _ from "lodash";

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Drawer } from "@mui/material";
import { Card } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { makeStyles } from '@mui/styles';

import { constants } from '../../Constants/constants';
import { post } from "../../helper/baseFetch";
import { setLoading } from "../../actions/systemAction";
import { setSelectTagConfigOptions } from "../../actions/selectTagConfigOptionsAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput"
import { TempBtn } from "../../Component/Input/TempBtn"
import { SelectTagList } from "../SelectTag/SelectTagList";
import { DialogWrapper } from "../../Component/DialogWrapper";
import SelectTagValidation from "../../validations/selectTagValidation";
import { selectTagValidationConstants } from '../../Constants/validationConstants';
import { TempAutoSelectInput } from "../../Component/Input/TempAutoSelectInput";
import useRestaurant from "../../hooks/Restaurant/useRestaurant";

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const SelectTagConfigCreate = ({ selectTagConfigCreate }) => {
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();
  const params = useParams();
  const [showSelectTagPanel, setShowSelectTagPanel] = useState(false);
  const [clear, setClear] = useState(false);
  const [selectTagMap, setSelectTagMap] = useState(new Map());
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const savedSelectTagConfigOptions = useSelector(state => state.selectTagConfigOptions.ui.selectTagConfigOptions);
  const resource = "SelectTagConfig";
  const helperTextStylesClasses = helperTextStyles();
  const [, setErrorVersion] = useState(SelectTagValidation.getVersion());

  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.AREA_EDIT_TAB.INFO
    },
  ]

  function handeleDelete(deleteIndex) {
    let tempSelectTagConfigOptionsList = _.cloneDeep(savedSelectTagConfigOptions);
    tempSelectTagConfigOptionsList = tempSelectTagConfigOptionsList.filter((item, index) => { return index !== deleteIndex });
    dispatch(setSelectTagConfigOptions(tempSelectTagConfigOptionsList));
    setSelectTagMap(new Map(tempSelectTagConfigOptionsList.map(item => [item.tagCode, item])));
    SelectTagValidation.resetErrorMessages();
  }

  function handleAddSelectTag() {
    dispatch(setLoading(true));
    setShowSelectTagPanel(true);
    dispatch(setLoading(false));
  }

  function handleSelectTagCloseClick() {
    dispatch(setLoading(true));
    setShowSelectTagPanel(false);
    dispatch(setLoading(false));
  }

  const handleSelectTagSubmit=()=>{
    setShowSelectTagPanel(false);
    dispatch(setSelectTagConfigOptions([...Array.from(selectTagMap, ([name, value]) => ({...value, status: 'A', restUrlId: params.restUrlId}))]));
  }

  const onClickBackToCreate = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    onClickClear();
  };
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT + "/" + params.restUrlId + "/" + constants.RESTAURANT_TAB.INFO + "/" + constants.RESTAURANT_SUB_TAB.SELECT_TAG);
  };

  function onChangeInput(name, e, index) {
    let tempSelectTagConfigOptions = _.cloneDeep(savedSelectTagConfigOptions);
    if (name === "startTimestamp" || name === "endTimestamp") {
      if (e === "") {
        e = null
      }
      tempSelectTagConfigOptions[index][`${name}`] = e ? Number(moment(e).format("x")) : e;
    } else if (name === "sequence") {
      tempSelectTagConfigOptions[index][`${name}`] = Number.isInteger(Number(e)) ? Number(e) : 0;
    } else {
      tempSelectTagConfigOptions[index][`${name}`] = e;
    }
    dispatch(setSelectTagConfigOptions(tempSelectTagConfigOptions));
  }

  function onClickSave() {
    SelectTagValidation.validateCreateSelectTagConfigOptions(savedSelectTagConfigOptions);
    if (SelectTagValidation.isValid()) {
      post(constants.PATH.SELECTED_TAG.CREATE_SELECT_TAG_CONFIG, savedSelectTagConfigOptions, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToCreate,
          dialogContentText: { key: "createSuccess" },
          dialogActions: [
            { label: { key: "backCreate" }, onClick: onClickBackToCreate, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      SelectTagValidation.resetErrorMessages();
    } else {
      setErrorVersion(SelectTagValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true);
  }

  useEffect(() => {
    if(!showSelectTagPanel){
      setSelectTagMap(new Map(savedSelectTagConfigOptions.map(item => [item.tagCode, item])));
    }
  }, [showSelectTagPanel])

  let content = [
    {
      tab:
        <>
          {
          SelectTagValidation.getErrorMessages(selectTagValidationConstants.KEY_SELECT_TAG_ERROR) &&
            SelectTagValidation.getErrorMessages(selectTagValidationConstants.KEY_SELECT_TAG_ERROR).length > 0 &&
            <div className="error-message">
              <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
              {SelectTagValidation.getErrorMessages(selectTagValidationConstants.KEY_SELECT_TAG_ERROR)}
            </div>
          }
          <TempBtn
            btnSetting=
            {{
              onClick: handleAddSelectTag,
              variant: constants.STYLE.VARIANT.CONTAINED,
              color: constants.STYLE.PRIMARY,
              icon: <AddIcon />,
              label: { resource: resource, key: "addSelectTag" },
            }}
          >
          </TempBtn>
          {savedSelectTagConfigOptions && savedSelectTagConfigOptions.length > 0 && savedSelectTagConfigOptions.map((savedSelectTagConfigOptionsValue, savedSelectTagConfigOptionsIndex) => {
            const tempSelectTagConfigOptionsEditList = [
              {
                className: "edit-create-input",
                variant: constants.STYLE.VARIANT.STANDARD,
                size: constants.SIZE.SMALL,
                label: { resource: resource, key: "tagCode" },
                value: savedSelectTagConfigOptionsValue.tagCode,
                name: "tagCode",
                isEndOfLine: true,
                disabled: true,
              },
              {
                className: "edit-create-input",
                variant: constants.STYLE.VARIANT.STANDARD,
                size: constants.SIZE.SMALL,
                label: { resource: resource, key: "tagEnName" },
                value: savedSelectTagConfigOptionsValue.enName,
                name: "enName",
                isEndOfLine: false,
                disabled: true,
              },
              {
                className: "edit-create-input",
                variant: constants.STYLE.VARIANT.STANDARD,
                size: constants.SIZE.SMALL,
                label: { resource: resource, key: "tagTcName" },
                value: savedSelectTagConfigOptionsValue.tcName,
                name: "tcName",
                isEndOfLine: false,
                disabled: true,
              },
              {
                className: "edit-create-input",
                variant: constants.STYLE.VARIANT.STANDARD,
                size: constants.SIZE.SMALL,
                label: { resource: resource, key: "tagScName" },
                value: savedSelectTagConfigOptionsValue.scName,
                name: "scName",
                isEndOfLine: true,
                disabled: true,
              },
              {
                className: "edit-create-input",
                variant: constants.STYLE.VARIANT.STANDARD,
                size: constants.SIZE.SMALL,
                label: { resource: resource, key: "status" },
                value: savedSelectTagConfigOptionsValue.status,
                name: "status",
                //type: constants.TYPE.SELECT,
                selectValue: constants.CHOICES.SELECT_TAG.STATUS,
                required: true,
                isEndOfLine: false,
                disabled: true,
              },
              {
                className: "edit-create-input",
                size: constants.SIZE.SMALL,
                label: { resource: resource, key: "type" },
                value: savedSelectTagConfigOptionsValue.type,
                name: "type",
                type: constants.TYPE.SELECT,
                selectValue: constants.CHOICES.SELECT_TAG.TYPE,
                isEndOfLine: false,
                required: true,
                disabled: false,
                helperTextIcon: true,
                helperText: SelectTagValidation.getErrorMessages(`${savedSelectTagConfigOptionsIndex}-${selectTagValidationConstants.KEY_TYPE}`),
                FormHelperTextProps: { className: helperTextStylesClasses.root },
              },{
                className: "edit-create-input",
                size: constants.SIZE.SMALL,
                label: { resource: resource, key: "sequence" },
                value: savedSelectTagConfigOptionsValue.sequence,
                name: "sequence",
                //eslint-disable-next-line
                pattern: "^\d*$",
                isEndOfLine: true,
                required: true,
                helperTextIcon: true,
                helperText: SelectTagValidation.getErrorMessages(`${savedSelectTagConfigOptionsIndex}-${selectTagValidationConstants.KEY_DISPLAY_SEQUENCE}`),
                FormHelperTextProps: { className: helperTextStylesClasses.root },
              },
            ]
            return <Card key={savedSelectTagConfigOptionsIndex} className="image-list"> {
              <>
                <div className="delete-icon">
                  <TempBtn btnSetting={{
                    className: "edit-create-btn",
                    variant: constants.STYLE.VARIANT.CONTAINED,
                    color: constants.STYLE.PRIMARY,
                    onClick: () => handeleDelete(savedSelectTagConfigOptionsIndex),
                    label: { key: "deleteBtn" },
                    icon: <DeleteIcon />,
                  }} />
                </div>
                {tempSelectTagConfigOptionsEditList.map((inputSetting, index) => {
                  let content = <></>;
                  switch (inputSetting.type) {
                    case constants.TYPE.SELECT:
                      content =
                        <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} index={savedSelectTagConfigOptionsIndex}/>
                      break;
                    default:
                      content =
                        <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} index={savedSelectTagConfigOptionsIndex}/>
                  }
                  return <div className={inputSetting.backgroundColorClass ? "image-card-background card-border" : "edit-display-wrapper"} key={index}>
                    {content}
                    {inputSetting.isEndOfLine && <div></div>}
                  </div>
                })
                }
              </>
            }
            </Card>
          })}
          <Drawer
            classes={{ paper: "drawer-margin-left" }}
            anchor="right"
            open={showSelectTagPanel}
            onClose={handleSelectTagCloseClick}
          >
            <SelectTagList
              editBtn={false}
              mutipleSelect={true}
              createBtn={false}
              btnList={false}
              selectMap={selectTagMap}
              setSelectMap={setSelectTagMap}
              newFilter={selectTagMap && selectTagMap.size > 0 ?{ selected: { column: 'tagCode', valueList:  Array.from(selectTagMap, ([name, value]) => (name)) } }: null}
              restUrlId={params.restUrlId}
              dialogFooterBtn={
                <div className="get-push-card-wrapper">
                  <TempBtn btnSetting={{
                    className: "edit-create-btn",
                    variant: constants.STYLE.VARIANT.CONTAINED,
                    color: constants.STYLE.PRIMARY,
                    onClick: handleSelectTagSubmit,
                    label: { key: "submit" },
                    icon: <AddIcon />
                  }} />
                  <TempBtn btnSetting={{
                    className: "edit-create-btn",
                    variant: constants.STYLE.VARIANT.CONTAINED,
                    color: constants.STYLE.PRIMARY,
                    onClick: handleSelectTagCloseClick,
                    label: { key: "close" },
                    icon: <ClearIcon />
                  }} />
                </div>
              }
            />

          </Drawer>
        </>,
      btn: !selectTagConfigCreate ? true : false
    },
  ]

  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(setSelectTagConfigOptions([]));
    //setSelectTagIdList([]);
    dispatch(setLoading(false));
    setSelectTagMap(new Map());
    setClear(false)
    SelectTagValidation.resetErrorMessages();
  }, [clear === true]); // eslint-disable-line react-hooks/exhaustive-deps

  const { getRestaurantIdAndName } = useRestaurant(params.restUrlId)
  const mapCode = getRestaurantIdAndName()

  return (
    <>
      <EditCreateDisplay
        code=""
        resource={resource}
        tabs={!selectTagConfigCreate ? tabs : ""}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        mapName={{ resource: "SelectTagConfig", key: "restUrlId" }}
        mapCode={mapCode}
        display={selectTagConfigCreate === "SelectTagConfigCreate" ? "none" : "create"}
      />
      {dialog && dialog.open &&
        <DialogWrapper dialogInfo={dialog} />
      }
    </>
  )
};