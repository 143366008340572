import { QUEUE_TEMPLATE } from './types';
import { sify } from "chinese-conv";
import _ from "lodash";

export const setQueueTemplateDefault = () => {
    return {
        type: QUEUE_TEMPLATE.SET_QUEUE_TEMPLATE_DEFAULT,
    }
}

export const setQueueTemplate = (queueTemplate) => {
    return {
        type: QUEUE_TEMPLATE.SET_QUEUE_TEMPLATE,
        queueTemplate: queueTemplate
    }
}

export const setQueueTemplateValue = (draftType, orignalProduct, key, value, index) => {
    const queueTemplate = _.cloneDeep(orignalProduct);
    switch (draftType) {
        case "CHANGE_TC_TITLE_AND_SC_TITLE":
            if (value) {
                queueTemplate.tcTitle = value;
                queueTemplate.scTitle = sify(value);
            } else {
                queueTemplate.tcTitle = null;
                queueTemplate.scTitle = null;
            }
            break;
        case "CHANGE_TC_MESSAGE_AND_SC_MESSAGE":
            if (value) {
                queueTemplate.tcMessage = value;
                queueTemplate.scMessage = sify(value);
            } else {
                queueTemplate.tcMessage = null;
                queueTemplate.tcMessage = null;
            }
            break;
        case "CHANGE_TYPE":
            queueTemplate.type = value;
            queueTemplate.enMessage = null;
            queueTemplate.tcMessage = null;
            queueTemplate.scMessage = null;
            break;
        default:
            queueTemplate[draftType] = value;
    }
    return {
        type: QUEUE_TEMPLATE.SET_QUEUE_TEMPLATE_VALUE,
        queueTemplate: queueTemplate
    }
}

export const setQueueTemplateTimeSectionValue = (timeSection) => {
    return {
        type: QUEUE_TEMPLATE.SET_QUEUE_TEMPLATE_TIME_SECTION_VALUE,
        timeSection: timeSection
    }
}

export const setQueueTemplateTableTypeValue = (tableType) => {
    return {
        type: QUEUE_TEMPLATE.SET_QUEUE_TEMPLATE_TABLE_TYPE_VALUE,
        tableType: tableType
    }
}

export const setQueueTemplateTableTypeGroupedSection = (groupedSection) => {
    return {
        type: QUEUE_TEMPLATE.SET_QUEUE_TEMPLATE_GROUPED_SECTION,
        groupedSection: groupedSection
    }
}

export const setQueueTemplateTableTypeColorList = (tableTypeColorList) => {
    return {
        type: QUEUE_TEMPLATE.SET_QUEUE_TEMPLATE_TABLE_TYPE_COLOR_LIST,
        tableTypeColorList: tableTypeColorList
    }
}

export const setQueueTemplateDefaultTableTypeColorList = (defaultTableTypeColorList) => {
    return {
        type: QUEUE_TEMPLATE.SET_QUEUE_TEMPLATE_DEFAULT_TABLE_TYPE_COLOR_LIST,
        defaultTableTypeColorList: defaultTableTypeColorList
    }
}