import { useState, useEffect } from "react";
import { useStore } from "react-redux";
import _ from "lodash";

import { Card } from '@mui/material';

import { ListDisplay } from '../../Component/ListDisplay/ListDisplay';

import { constants } from '../../Constants/constants';
import { getList } from "../../helper/baseFetch";
import { generateTableCellToMultiLanguageName } from "../../helper/util";

export const PaymentList = ({ newListTitles, newFilter, createBtn, restUrlId }) => {
  const store = useStore();
  const [paymentList, setPaymentList] = useState([]);
  const [ready, setReady] = useState(false);
  const resource = "payment";

  const paymentTitleList = newListTitles ? newListTitles : [
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "channel",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "restUrlId",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "name",
      sort: false,
      minSize: true,
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "email",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "tranRef",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.DATE_TIME,
      value: "tranDate",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "planCode",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "paymentCode",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "status",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "approvalStatus",
      sort: true
    },
  ];

  const listFilters = newFilter ? newFilter : [
    {
      className: "filter-input",
      label: { resource: resource, key: "channel" },
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.PAYMENT.CHANNEL,
      name: "channel",
      size: constants.SIZE.SMALL
    },
    {
      className: "filter-input",
      label: { resource: resource, key: "restUrlId" },
      clear: true,
      value: '',
      name: "restUrlId",
      size: constants.SIZE.SMALL
    },
    {
      className: "filter-input",
      label: { resource: resource, key: "name" },
      clear: true,
      value: '',
      name: "name",
      size: constants.SIZE.SMALL
    },
    {
      className: "filter-input",
      label: { resource: resource, key: "email" },
      clear: true,
      value: '',
      name: "email",
      size: constants.SIZE.SMALL
    },
    {
      className: "filter-input",
      label: { resource: resource, key: "tranRef" },
      clear: true,
      value: '',
      name: "tranRef",
      size: constants.SIZE.SMALL
    },
    {
      className: "filter-input",
      label: { resource: resource, key: "status" },
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.PAYMENT.TRAN_STATUS,
      name: "status",
      size: constants.SIZE.SMALL
    },
    {
      className: "filter-input",
      label: { resource: resource, key: "approvalStatus" },
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.PAYMENT.APPROVAL_STATUS,
      name: "approvalStatus",
      size: constants.SIZE.SMALL
    }
  ];

  const [param, setParam] = useState({
    path: "",
    page: 0,
    rowsPerPage: 10,
    sort: 'updateTimestamp',
    direction: 'DESC',
    listFilters: listFilters,
    count: 0,
  })

  function onChangeParam(value) {
    value.count = value.count + 1;
    setParam(value);
  }

  function onChangeListFilters(list) {
    let tempParam = _.cloneDeep(param);
    tempParam.listFilters = list;
    tempParam.page = 0;
    onChangeParam(tempParam);
  }

  function onChangeListSort(sortItem) {
    let tempParam = _.cloneDeep(param);
    tempParam.sort = sortItem;
    tempParam.direction = tempParam.direction === "DESC" ? "ASC" : "DESC";
    onChangeParam(tempParam);
  }

  function onChangeListPage(page) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = page;
    onChangeParam(tempParam);
  }

  function onChangeListRowsPerPage(rowsPerPage) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = 0;
    tempParam.rowsPerPage = rowsPerPage;
    onChangeParam(tempParam);
  }

  useEffect(() => {
    localStorage.removeItem(constants.LOCAL_STORAGE_KEY.UPLOAD_PAYMENT_CHOSEN_TRANDATE);
    localStorage.removeItem(constants.LOCAL_STORAGE_KEY.UPLOAD_PAYMENT_CHOSEN_MATCHSTATUS);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let tempParam = _.cloneDeep(param);
    const filter = restUrlId ? { id: restUrlId } : {};
    tempParam.listFilters.forEach((filterItem) => {
      if (filterItem.value !== '' && filterItem.value !== undefined) {
        filter[filterItem.name] = filterItem.value;
      }
    })

    getList(constants.PATH.APPROVAL.PAYMENT, { sort: { field: tempParam.sort, order: tempParam.direction }, filter: filter, pagination: { page: tempParam.page + 1, perPage: tempParam.rowsPerPage } }, (payload) => {
      payload.map((item) => {
        item.name = generateTableCellToMultiLanguageName(item.restTcName, item.restEnName, item.restScName);
        return item
      });
      setPaymentList(payload);
      setReady(true)
    }, undefined, store);

  }, [param.count]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <Card className="card-wrapper overflow">
      <ListDisplay
        page={param.page}
        rowsPerPage={param.rowsPerPage}
        onChangeListSort={onChangeListSort}
        resource={resource}
        dataList={paymentList}
        sort={param.sort}
        direction={param.direction}
        listTitles={paymentTitleList}
        listFilters={param.listFilters}
        onChangeListPage={onChangeListPage}
        onChangeListRowsPerPage={onChangeListRowsPerPage}
        createBtn={createBtn ? createBtn : false}
        onChangeListFilters={onChangeListFilters}
        redirectPath={"/" + constants.PATH.APPROVAL.PAYMENT + "/"}
      />
    </Card>
  )
};