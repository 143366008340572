import { useState, useEffect } from 'react'
import { useStore } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useHistory, useLocation } from 'react-router-dom'
import _ from 'lodash'

import { Card } from '@mui/material'

import { ListDisplay } from '../../Component/ListDisplay/ListDisplay'

import { constants } from '../../Constants/constants'
import { getList } from '../../helper/baseFetch'
import { getParamQueryString, getQuery, getQueryParamObject } from '../../helper/util'

export const RestaurantMediaList = ({ editBtn, createBtn, newFilter, newPath, newListTitles, newResourcenName, changeUrlQueryString }) => {
  const store = useStore()
  const params = useParams()
  const [restaurantMediaList, setRestaurantMediaList] = useState([])
  const [ready, setReady] = useState(false)
  const resourceName = 'RestaurantMedia'
  const history = useHistory()
  const location = useLocation()
  const filterParams = getQuery()

  const listTitles = newListTitles
    ? newListTitles
    : [
        {
          key: constants.LIST_DISPLAY_TYPE.STRING,
          value: 'type',
          sort: true,
        },
        {
          key: constants.LIST_DISPLAY_TYPE.MEDIA,
          value: 'cosFileEnUrl',
          sort: false,
          type: 'fileType',
        },
        {
          key: constants.LIST_DISPLAY_TYPE.MEDIA,
          value: 'cosFileTcUrl',
          sort: false,
          type: 'fileType',
        },
        {
          key: constants.LIST_DISPLAY_TYPE.MEDIA,
          value: 'cosFileScUrl',
          sort: false,
          type: 'fileType',
        },
        {
          key: constants.LIST_DISPLAY_TYPE.STRING,
          value: 'fileType',
          sort: true,
        },
        {
          key: constants.LIST_DISPLAY_TYPE.STRING,
          value: 'displaySequence',
          sort: true,
        },
        {
          key: constants.LIST_DISPLAY_TYPE.STRING,
          value: 'status',
          sort: true,
        },
      ]
  const listFilters = [
    {
      className: 'filter-input',
      label: { resource: resourceName, key: 'type' },
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.RESTAURANT_MEDIA.TYPE,
      name: 'type',
      size: constants.SIZE.SMALL,
    },
    {
      className: 'filter-input',
      label: { resource: resourceName, key: 'fileType' },
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.RESTAURANT_MEDIA.FILE_TYPE,
      name: 'fileType',
      size: constants.SIZE.SMALL,
    },
    {
      className: 'filter-input',
      label: { resource: resourceName, key: 'status' },
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.RESTAURANT_MEDIA.FILTER_STATUS,
      name: 'status',
      size: constants.SIZE.SMALL,
    },
  ]

  const [param, setParam] = useState(
    !changeUrlQueryString
      ? {
          path: newPath ? newPath : '',
          page: 0,
          rowsPerPage: 10,
          sort: 'updateTimestamp',
          direction: 'DESC',
          listFilters: listFilters,
          count: 0,
        }
      : getQueryParamObject(
          {
            path: newPath ? newPath : '',
            page: 0,
            rowsPerPage: 10,
            sort: 'updateTimestamp',
            direction: 'DESC',
            listFilters: _.cloneDeep(listFilters),
            count: 0,
          },
          _.cloneDeep(listFilters),
          listTitles
        )
  )

  useEffect(() => {
    if (!changeUrlQueryString) {
      return
    }

    const querySyring = getParamQueryString(
      {
        path: newPath ? newPath : '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: param.count,
      },
      param
    )

    const currentQueryString = window.location.search?.substring(1)

    if (querySyring === currentQueryString) {
      return
    }

    const newParam = getQueryParamObject(
      {
        path: newPath ? newPath : '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: param.count + 1,
      },
      _.cloneDeep(listFilters),
      listTitles
    )

    setParam(newParam)

    const newQuery = getParamQueryString(
      {
        path: newPath ? newPath : '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: 0,
      },
      newParam
    )

    history.replace({ pathname: location.pathname, search: newQuery })
  }, [filterParams])

  function onChangeParam(value) {
    value.count = value.count + 1

    if (!changeUrlQueryString) {
      setParam(value)
      return
    }

    const querySyring = getParamQueryString(
      {
        path: newPath ? newPath : '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: 0,
      },
      value
    )

    history.push({ pathname: location.pathname, search: querySyring })
  }

  function onChangeListFilters(list) {
    let tempParam = _.cloneDeep(param)
    tempParam.listFilters = list
    tempParam.page = 0
    onChangeParam(tempParam)
  }

  function onChangeListSort(sortItem) {
    let tempParam = _.cloneDeep(param)
    tempParam.sort = sortItem
    tempParam.direction = tempParam.direction === 'DESC' ? 'ASC' : 'DESC'
    onChangeParam(tempParam)
  }

  function onChangeListPage(page) {
    let tempParam = _.cloneDeep(param)
    tempParam.page = page
    onChangeParam(tempParam)
  }

  function onChangeListRowsPerPage(rowsPerPage) {
    let tempParam = _.cloneDeep(param)
    tempParam.page = 0
    tempParam.rowsPerPage = rowsPerPage
    onChangeParam(tempParam)
  }

  useEffect(() => {
    let tempParam = _.cloneDeep(param)
    const filter = newFilter ? _.cloneDeep(newFilter) : { id: params.id }
    tempParam.listFilters.forEach((filterItem) => {
      if (filterItem.value !== '' && filterItem.value !== undefined) {
        filter[filterItem.name] = filterItem.value
      }
    })

    getList(
      tempParam.path ? tempParam.path : constants.PATH.SHOP.RESTAURANT_MEDIA,
      {
        sort: { field: tempParam.sort, order: tempParam.direction },
        filter: filter,
        pagination: { page: tempParam.page + 1, perPage: tempParam.rowsPerPage },
      },
      (payload) => {
        setRestaurantMediaList(payload)
        setReady(true)
      },
      undefined,
      store
    )
  }, [param.count]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready && (
      <Card className="card-wrapper overflow">
        <ListDisplay
          page={param.page}
          rowsPerPage={param.rowsPerPage}
          onChangeListSort={onChangeListSort}
          resource={resourceName}
          dataList={restaurantMediaList}
          sort={param.sort}
          direction={param.direction}
          listTitles={listTitles}
          listFilters={param.listFilters}
          onChangeListPage={onChangeListPage}
          onChangeListRowsPerPage={onChangeListRowsPerPage}
          editBtn={editBtn}
          createBtn={createBtn}
          idParam={params.id}
          onChangeListFilters={onChangeListFilters}
          redirectPath={'/' + (newPath ? newPath : constants.PATH.SHOP.RESTAURANT_MEDIA) + '/'}
        />
      </Card>
    )
  )
}
