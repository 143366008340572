import { URL_MAP_ACTION_TYPE } from '../../Constants/constants'

export function getUrlObjValues(urlObj) {
  // get custom field related values
  if (urlObj.agentType === 'weChat') {
    urlObj.customFieldName = 'weChat'
    urlObj.isHideCustomField = false
    urlObj.isDisabledCustomField = false
    urlObj.mapValueDisable = false
    urlObj.parameters = { customField: '' }
    // urlObj.parameters = {}
  } else {
    switch (urlObj.actionType) {
      case URL_MAP_ACTION_TYPE.CUSTOM_URL:
        urlObj.customFieldName = ''
        urlObj.isHideCustomField = true
        urlObj.isDisabledCustomField = false
        urlObj.mapValueDisable = false
        urlObj.parameters = { customField: '' }
        // urlObj.parameters = {}
        break
      case URL_MAP_ACTION_TYPE.SEARCH_NEAR_BY:
        urlObj.customFieldName = ''
        urlObj.isHideCustomField = true
        urlObj.isDisabledCustomField = false
        urlObj.mapValueDisable = false
        urlObj.parameters = { customField: '' }
        // urlObj.parameters = {}

        break
      case URL_MAP_ACTION_TYPE.WEB_VIEW:
      case URL_MAP_ACTION_TYPE.VIDEO:
      case URL_MAP_ACTION_TYPE.LINK:
      case URL_MAP_ACTION_TYPE.PIXEL:
      case URL_MAP_ACTION_TYPE.SPRINGBOARD:
        urlObj.customFieldName = 'action'
        urlObj.isHideCustomField = false
        urlObj.isDisabledCustomField = false
        urlObj.mapValueDisable = false
        break
      case URL_MAP_ACTION_TYPE.SEARCH:
        urlObj.customFieldName = 'name'
        urlObj.isHideCustomField = true
        urlObj.isDisabledCustomField = true
        urlObj.mapValueDisable = false
        break
      case URL_MAP_ACTION_TYPE.PRODUCT_CATEGORY:
      case URL_MAP_ACTION_TYPE.E_COUPON_CATEGORY:
        urlObj.customFieldName = 'categoryCode'
        urlObj.isHideCustomField = false
        urlObj.isDisabledCustomField = true
        urlObj.mapValueDisable = true
        break
      case URL_MAP_ACTION_TYPE.PRODUCT:
        urlObj.customFieldName = 'productCode'
        urlObj.isHideCustomField = false
        urlObj.isDisabledCustomField = true
        urlObj.mapValueDisable = true
        break
      case URL_MAP_ACTION_TYPE.E_COUPON:
        urlObj.customFieldName = 'ecouponCode'
        urlObj.isHideCustomField = false
        urlObj.isDisabledCustomField = true
        urlObj.mapValueDisable = true
        break
      case URL_MAP_ACTION_TYPE.PRODUCT_BRAND:
      case URL_MAP_ACTION_TYPE.E_COUPON_BRAND:
        // case URL_MAP_ACTION.BRAND_PRODUCT:
        urlObj.customFieldName = 'brandCode'
        urlObj.isHideCustomField = false
        urlObj.isDisabledCustomField = true
        break
      case URL_MAP_ACTION_TYPE.FOLDER:
        urlObj.customFieldName = 'folderCode'
        urlObj.isHideCustomField = false
        urlObj.isDisabledCustomField = true
        urlObj.mapValueDisable = true
        break
      case URL_MAP_ACTION_TYPE.REST:
        // urlObj.customFieldName = 'restUrlId'
        urlObj.customFieldName = ''
        urlObj.isHideCustomField = true
        urlObj.isDisabledCustomField = true
        urlObj.mapValueDisable = true
        break
      case URL_MAP_ACTION_TYPE.MY_TICKET:
        urlObj.customFieldName = 'refId'
        urlObj.isHideCustomField = false
        urlObj.isDisabledCustomField = false
        urlObj.mapValueDisable = true
        break
      case URL_MAP_ACTION_TYPE.TRANSFORM_TICKET_FROM_PAPER:
        urlObj.customFieldName = 'ticketId'
        urlObj.isHideCustomField = false
        urlObj.isDisabledCustomField = false
        urlObj.mapValueDisable = true
        break
      case URL_MAP_ACTION_TYPE.TRANSFORM_ECOUPON_FROM_PAPER:
        urlObj.customFieldName = 'infoId'
        urlObj.isHideCustomField = false
        urlObj.isDisabledCustomField = false
        urlObj.mapValueDisable = true
        break
      case URL_MAP_ACTION_TYPE.EXPRESS_TICKET_VOUCHER:
        urlObj.customFieldName = 'groupCode'
        urlObj.isHideCustomField = false
        urlObj.isDisabledCustomField = false
        urlObj.mapValueDisable = true
        break
      case URL_MAP_ACTION_TYPE.SHARE:
        urlObj.customFieldName = 'refId'
        urlObj.isHideCustomField = false
        urlObj.isDisabledCustomField = false
        urlObj.mapValueDisable = true
        break
      case URL_MAP_ACTION_TYPE.GIFT:
        urlObj.customFieldName = 'refId'
        urlObj.isHideCustomField = false
        urlObj.isDisabledCustomField = false
        urlObj.mapValueDisable = true
        break
      // case URL_MAP_ACTION.ECOUPON:
      //   savedUrlMapValue.customFieldName = "ecouponId";
      //   savedUrlMapValue.isHideCustomField = false;
      //   savedUrlMapValue.isDisabledCustomField = false;
      //   savedUrlMapValue.mapValueDisable = true;
      //   break;
      default:
        urlObj.customFieldName = ''
        urlObj.isHideCustomField = true
        urlObj.isDisabledCustomField = false
        urlObj.mapValueDisable = false
        urlObj.parameters = { customField: '' }
        // urlObj.parameters = {}
        break
    }
  }

  return urlObj
}
