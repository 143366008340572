import _ from "lodash";
import { RESERVATION_TEMPLATE } from '../actions/types';
import { constants, RESERVATION_TABLE_TYPE_LIST_INPUT } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        reservationTemplate: _.cloneDeep(constants.RESERVATION_TEMPLATE_INPUT_LIST),
        timeSection: _.cloneDeep(constants.RESERVATION_TEMPLATE_INPUT),
        tableType: _.cloneDeep(RESERVATION_TABLE_TYPE_LIST_INPUT),
    }
}

const reservationTemplateReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RESERVATION_TEMPLATE.SET_RESERVATION_TEMPLATE_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case RESERVATION_TEMPLATE.SET_RESERVATION_TEMPLATE:
        case RESERVATION_TEMPLATE.SET_RESERVATION_TEMPLATE_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    reservationTemplate: action.reservationTemplate
                }
            })
        case RESERVATION_TEMPLATE.SET_RESERVATION_TEMPLATE_TIME_SECTION_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    timeSection: action.timeSection
                }
            })
        case RESERVATION_TEMPLATE.SET_RESERVATION_TEMPLATE_TABLE_TYPE_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    tableType: action.tableType
                }
            })
        default:
            return state
    }
}

export default reservationTemplateReducer