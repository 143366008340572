import { SYSTEM_GROUP } from './types';
import _ from "lodash";

export const setSystemGroupDefault = () => {
    return {
        type: SYSTEM_GROUP.SET_SYSTEM_GROUP_DEFAULT,
    }
}

export const setSystemGroup = (systemGroup) => {
    return {
        type: SYSTEM_GROUP.SET_SYSTEM_GROUP,
        systemGroup: systemGroup
    }
}

export const setSystemGroupValue = (draftType, orignalProduct, key, value, index) => {
    const systemGroup = _.cloneDeep(orignalProduct);
    switch (draftType) {
        case "groupEmails":
            systemGroup.groupEmailList[key] = value;
            break;
        default:
            systemGroup[draftType] = value;
    }
    return {
        type: SYSTEM_GROUP.SET_SYSTEM_GROUP_VALUE,
        systemGroup: systemGroup
    }
}

export const addSystemGroupEmailList = (orignalProduct) => {
    const systemGroup = _.cloneDeep(orignalProduct);
    systemGroup.groupEmailList? systemGroup.groupEmailList.push(null):systemGroup.groupEmailList=[null];
    return {
        type: SYSTEM_GROUP.SET_SYSTEM_GROUP_VALUE,
        systemGroup: systemGroup
    }
}

export const removeSystemGroupEmailList = (orignalProduct, removeIndex) => {
    const systemGroup = _.cloneDeep(orignalProduct);
    systemGroup.groupEmailList = systemGroup.groupEmailList.filter((_,index)=>index!==removeIndex);
    return {
        type: SYSTEM_GROUP.SET_SYSTEM_GROUP_VALUE,
        systemGroup: systemGroup
    }
}