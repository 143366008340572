import { BRAND } from './types';
import { sify } from "chinese-conv";
import _ from "lodash";

export const setBrandDefault = () => {
    return {
        type: BRAND.SET_BRAND_DEFAULT,
    }
}

export const setBrand = (Brand) => {
    return {
        type: BRAND.SET_BRAND,
        Brand: Brand
    }
}

export const setBrandValue = (draftType, orignalProduct, key, value, index) => {
    const Brand = _.cloneDeep(orignalProduct);
    switch (draftType) {
        case "CHANGE_TC_NAME_AND_SC_NAME":
            Brand.tcName = value;
            Brand.scName = sify(value);
            break;
        case "CHANGE_TC_DESCRIPTION_AND_SC_DESCRIPTION":
            Brand.tcDescription = value;
            Brand.scDescription = sify(value);
            break;
        // case "CHANGE_BRAND_GROUP":
        //     Brand.groupId = value.groupId;
        //     Brand.groupTcName = value.groupTcName;
        //     Brand.groupEnName = value.groupEnName;
        //     Brand.groupScName = value.groupScName;
        //     break;
        case "scNameDisable":
            Brand[draftType] = value;
            if(value) {
                Brand.scName = Brand.tcName? sify(Brand.tcName) : Brand.tcName;
            }
            break;
        case "scDescriptionDisable":
            Brand[draftType] = value;
            if(value) {
                Brand.scDescription = Brand.tcDescription? sify(Brand.tcDescription) : Brand.tcDescription;
            }
            break;
        default:
            Brand[draftType] = value;
    }
    return {
        type: BRAND.SET_BRAND_VALUE,
        Brand: Brand
    }
}
