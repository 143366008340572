import { tncValidationConstants } from '../Constants/validationConstants';

class TnCValidation {

  version;
  errorMap;
  tncErrorMap;
  valid;

  constructor() {

    if (!TnCValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.tncErrorMap = new Map();
      TnCValidation.instance = this;
    }
    return TnCValidation.instance;
  }

  validateEditTnC(tnc) {
    this.resetErrorMessages();
    this.handleValidateEditTnC(tnc);
    this.version++;
  }

  validateCreateTnC(tnc) {
    this.resetErrorMessages();
    this.handleValidateCreateTnC(tnc);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.tncErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key, uniqueCode) {
    if (!uniqueCode) {
      return (this.tncErrorMap.has(key)) ? this.tncErrorMap.get(key) : [];
    }
  }

  handleValidateCreateTnC(tnc) {
    if (!tnc.restUrlId) {
      this.setTnCErrorMapMessages(tncValidationConstants.KEY_REST_URL_ID, "Missing Rest Url Id");
    }
    if (!tnc.type) {
      this.setTnCErrorMapMessages(tncValidationConstants.KEY_TYPE, "Missing Type");
    }
  }

  handleValidateEditTnC(tnc) {
    if (!tnc.restUrlId) {
      this.setTnCErrorMapMessages(tncValidationConstants.KEY_REST_URL_ID, "Missing Rest Url Id");
    }
    if (!tnc.type) {
      this.setTnCErrorMapMessages(tncValidationConstants.KEY_TYPE, "Missing Type");
    }
    if (!tnc.status) {
      this.setTnCErrorMapMessages(tncValidationConstants.KEY_STATUS, "Missing Status");
    }
  }

  setTnCErrorMapMessages(key, errorMessages, valid = false) {
    this.tncErrorMap.set(key, errorMessages);
    // console.log("tncErrorMap", this.tncErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new TnCValidation();
export default validation;