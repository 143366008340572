import { pickupValidationConstants } from '../Constants/validationConstants'
import { isHexCodeValidate } from '../Page/QueueTemplate/queueTemplateUtil'

class PickupValidation {
  version
  errorMap
  pickupErrorMap
  valid

  constructor() {
    if (!PickupValidation.instance) {
      this.version = 0
      this.valid = true
      this.errorMap = new Map()
      this.pickupErrorMap = new Map()
      PickupValidation.instance = this
    }
    return PickupValidation.instance
  }

  validateEditAdminDevice(pickup) {
    this.resetErrMsg()
    this.validateEdit(pickup)
    this.version++
  }

  validateCreateAdminDevice(pickup) {
    this.resetErrMsg()
    this.validateCreate(pickup)
    this.version++
  }

  resetErrMsg() {
    this.errorMap = new Map()
    this.pickupErrorMap = new Map()
    this.valid = true
  }

  getVersion() {
    return this.version
  }

  isValid() {
    return this.valid
  }

  getErrMsg(key, uniqueCode) {
    if (!uniqueCode) {
      return this.pickupErrorMap.has(key) ? this.pickupErrorMap.get(key) : []
    }
  }

  validateEdit(pickup) {
    if (!pickup.restUrlId) {
      this.setErrMsg(pickupValidationConstants.KEY_REST_URL_ID, 'Missing Rest Url Id')
    }
    if (!pickup.status) {
      this.setErrMsg(pickupValidationConstants.KEY_STATUS, 'Missing Report Status')
    }
    if (typeof pickup.sequence !== 'number') {
      this.setErrMsg(pickupValidationConstants.KEY_SEQUENCE, 'Sequence is not a number')
    }

    if (pickup.foregroundColor && !isHexCodeValidate(pickup.foregroundColor)) {
      this.setErrMsg(pickupValidationConstants.KEY_FOREGROUND_COLOR, ['Background Color Invalid'])
    }
    if (pickup.backgroundColor && !isHexCodeValidate(pickup.backgroundColor)) {
      this.setErrMsg(pickupValidationConstants.KEY_BACKGROUND_COLOR, ['Foreground Color Invalid'])
    }
  }

  validateCreate(pickup) {
    if (!pickup.restUrlId) {
      this.setErrMsg(pickupValidationConstants.KEY_REST_URL_ID, 'Missing Rest Url Id')
    }
    if (!pickup.status) {
      this.setErrMsg(pickupValidationConstants.KEY_STATUS, 'Missing Report Status')
    }
    if (typeof pickup.sequence !== 'number') {
      this.setErrMsg(pickupValidationConstants.KEY_SEQUENCE, 'Sequence is not a number')
    }

    if (pickup.foregroundColor && !isHexCodeValidate(pickup.foregroundColor)) {
      this.setErrMsg(pickupValidationConstants.KEY_FOREGROUND_COLOR, ['Background Color Invalid'])
    }
    if (pickup.backgroundColor && !isHexCodeValidate(pickup.backgroundColor)) {
      this.setErrMsg(pickupValidationConstants.KEY_BACKGROUND_COLOR, ['Foreground Color Invalid'])
    }
  }

  setErrMsg(key, errorMessages, valid = false) {
    this.pickupErrorMap.set(key, errorMessages)
    // console.log("adminDeviceErrorMap", this.adminDeviceErrorMap)
    if (!valid) {
      this.valid = false
    }
  }
}

const validation = new PickupValidation()
export default validation
