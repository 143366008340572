import { useState, useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { makeStyles } from '@mui/styles';

import { constants } from '../../Constants/constants';
import { post } from "../../helper/baseFetch";
import { setLandmark, setLandmarkValue } from "../../actions/landmarkAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempAutoSelectInput } from "../../Component/Input/TempAutoSelectInput";
import { TempCustomCheckBox } from "../../Component/Custom/TempCustomCheckBox";
import { LandMarkDistrictMapCreate } from "./LandMarkDistrictMapCreate";
import { DialogWrapper } from "../../Component/DialogWrapper";
import LandmarkValidation from "../../validations/landmarkValidation";
import { landmarkValidationConstants } from '../../Constants/validationConstants';

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const LandMarkCreate = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();
  const [clear, setClear] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const resource = "RestaurantLandmark";
  const savedLandmark = useSelector(state => state.landmark.ui.landmark)
  const [landmarkInputList, setLandmarkInputList] = useState(_.cloneDeep(constants.LANDMARK_INPUT));
  const helperTextStylesClasses = helperTextStyles();
  const [, setErrorVersion] = useState(LandmarkValidation.getVersion());

  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.LANDMARK_TAB.INFO
    }
  ]
  const tempLandmarkCreateList = [
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "enName" },
      value: savedLandmark.enName,
      name: "enName",
      custom: true,
      customName: { key: "nameCustomize" },
      customValue: savedLandmark.scNameDisable,
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: LandmarkValidation.getErrorMessages(landmarkValidationConstants.KEY_EN_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "tcName" },
      value: savedLandmark.tcName,
      name: "tcName",
      translateName: savedLandmark.scNameDisable ? ["scName"] : null,
      inputList: landmarkInputList,
      setInputList: setLandmarkInputList,
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: LandmarkValidation.getErrorMessages(landmarkValidationConstants.KEY_TC_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "scName" },
      value: savedLandmark.scName,
      name: "scName",
      inputList: landmarkInputList,
      setInputList: setLandmarkInputList,
      isEndOfLine: true,
      disabled: savedLandmark.scNameDisable,
      required: true,
      helperTextIcon: true,
      helperText: LandmarkValidation.getErrorMessages(landmarkValidationConstants.KEY_SC_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "landmarkOrder" },
      value: savedLandmark.landmarkOrder,
      type: constants.TYPE.NUMBER,
      min: 0,
      name: "landmarkOrder",
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: LandmarkValidation.getErrorMessages(landmarkValidationConstants.KEY_LANDMARK_ORDER),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "favorite" },
      value: savedLandmark.favorite,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.RESTAURANT_LANDMARK.FAVORITE,
      name: "favorite",
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: LandmarkValidation.getErrorMessages(landmarkValidationConstants.KEY_FAVORITE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    }
  ]
  const content = [
    {
      tab: <>
        {tempLandmarkCreateList.map((inputSetting, index) => {
          let content;
          switch (inputSetting.type) {
            case constants.TYPE.SELECT:
              content =
                <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
              break;
            default:
              content =
                <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
          }
          return <div className="edit-display-wrapper" key={index}>
            {inputSetting.custom && <TempCustomCheckBox className="customize-btn" value={inputSetting.customValue} customName={inputSetting.customName} onSelectCheckbox={onSelectCheckbox} />}
            {content}
            {inputSetting.isEndOfLine && <div></div>}
          </div>
        })}
        <LandMarkDistrictMapCreate resourceName={resource} restaurantTagCreate="RestaurantTagCreate" sendDistrictIdList={sendDistrictIdList} landmarkCreateClear={clear} newShowRestaurantTextPanel={false} />
      </>,
      btn: true
    }
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  function onSelectCheckbox(value) {
    dispatch(setLandmarkValue("scNameDisable", savedLandmark, "", value));
  }

  function sendDistrictIdList(districtId) {
    let districtMapList = [];
    districtId.forEach((item) => districtMapList.push({ districtId: item }))
    dispatch(setLandmarkValue("districtMapList", savedLandmark, "", districtMapList));
  }

  const onClickBackToCreate = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  };
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.SHOP.RESTAURANT_LANDMARK);
  };

  function onChangeInput(name, e) {
    if (savedLandmark.scNameDisable === true && name === "tcName") {
      dispatch(setLandmarkValue("CHANGE_TC_NAME_AND_SC_NAME", savedLandmark, "", e));
    } else if (name === "landmarkOrder") {
      dispatch(setLandmarkValue(name, savedLandmark, "", Number.isInteger(Number(e)) ? Number(e) : 0));
    } else {
      dispatch(setLandmarkValue(name, savedLandmark, "", e));
    }
  }

  function onClickSave() {
    LandmarkValidation.validateCreateLandmark(savedLandmark);
    if (LandmarkValidation.isValid()) {
      post(constants.PATH.SHOP.RESTAURANT_LANDMARK, savedLandmark, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToCreate,
          dialogContentText: { key: "createSuccess" },
          dialogActions: [
            { label: { key: "backCreate" }, onClick: onClickBackToCreate, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      LandmarkValidation.resetErrorMessages();
    } else {
      setErrorVersion(LandmarkValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    dispatch(setLandmark(constants.LANDMARK_INPUT));
    setClear(false)
    LandmarkValidation.resetErrorMessages();
  }, [clear === true]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <EditCreateDisplay
        code={savedLandmark && savedLandmark.landmarkId ? savedLandmark.landmarkId : ""}
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        display="create"
      />
      {dialog && dialog.open &&
        <DialogWrapper dialogInfo={dialog} />
      }
    </>
  )
};