import { adminDeviceValidationConstants } from '../Constants/validationConstants';

class AdminDeviceValidation {

  version;
  errorMap;
  adminDeviceErrorMap;
  valid;

  constructor() {

    if (!AdminDeviceValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.adminDeviceErrorMap = new Map();
      AdminDeviceValidation.instance = this;
    }
    return AdminDeviceValidation.instance;
  }

  validateEditAdminDevice(adminDevice) {
    this.resetErrorMessages();
    this.handleValidateEditAdminDevice(adminDevice);
    this.version++;
  }

  validateCreateAdminDevice(adminDevice) {
    this.resetErrorMessages();
    this.handleValidateCreateAdminDevice(adminDevice);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.adminDeviceErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key, uniqueCode) {
    if (!uniqueCode) {
      return (this.adminDeviceErrorMap.has(key)) ? this.adminDeviceErrorMap.get(key) : [];
    }
  }

  handleValidateEditAdminDevice(adminDevice) {
    if (!adminDevice.restUrlId) {
      this.setAdminDeviceErrorMapMessages(adminDeviceValidationConstants.KEY_REST_URL_ID, "Missing Rest Url Id");
    }
    if (!adminDevice.reportStatus) {
      this.setAdminDeviceErrorMapMessages(adminDeviceValidationConstants.KEY_REPORT, "Missing Report Status");
    }
    if (!adminDevice.takeawayStatus) {
      this.setAdminDeviceErrorMapMessages(adminDeviceValidationConstants.KEY_TAKEAWAYS, "Missing Takeaway Status");
    }
    if (!adminDevice.queueStatus) {
      this.setAdminDeviceErrorMapMessages(adminDeviceValidationConstants.KEY_QUEUE, "Missing Queue Status");
    }
    if (!adminDevice.preorderStatus) {
      this.setAdminDeviceErrorMapMessages(adminDeviceValidationConstants.KEY_PRE_ORDER, "Missing Pre Order Status");
    }
    if (!adminDevice.tagStatus) {
      this.setAdminDeviceErrorMapMessages(adminDeviceValidationConstants.KEY_TAG, "Missing Tag Status");
    }
    if (!adminDevice.reservationStatus) {
      this.setAdminDeviceErrorMapMessages(adminDeviceValidationConstants.KEY_RESERVE, "Missing Reservation Status");
    }
    if (!adminDevice.banquetStatus) {
      this.setAdminDeviceErrorMapMessages(adminDeviceValidationConstants.KEY_BANQUET, "Missing Banquet Status");
    }
    if (!adminDevice.ecouponStatus) {
      this.setAdminDeviceErrorMapMessages(adminDeviceValidationConstants.KEY_ECOUPON, "Missing Ecoupon Status");
    }
    if (!adminDevice.menuStatus) {
      this.setAdminDeviceErrorMapMessages(adminDeviceValidationConstants.KEY_MENU, "Missing Menu Status");
    }
    if (!adminDevice.ecouponDistributeStatus) {
      this.setAdminDeviceErrorMapMessages(adminDeviceValidationConstants.KEY_ECOUPON_DISTRIBUTE, "Missing Ecoupon Distribute Status");
    }
  }

  handleValidateCreateAdminDevice(adminDevice) {
    if (!adminDevice.restUrlId) {
      this.setAdminDeviceErrorMapMessages(adminDeviceValidationConstants.KEY_REST_URL_ID, "Missing Rest Url Id");
    }
    if (!adminDevice.deviceType) {
      this.setAdminDeviceErrorMapMessages(adminDeviceValidationConstants.KEY_DEVICE_TYPE, "Missing Device Type");
    }
    if (!adminDevice.activationCode) {
      this.setAdminDeviceErrorMapMessages(adminDeviceValidationConstants.KEY_ACTIVATION_CODE, "Missing Activation Code");
    }
  }


  setAdminDeviceErrorMapMessages(key, errorMessages, valid = false) {
    this.adminDeviceErrorMap.set(key, errorMessages);
    // console.log("adminDeviceErrorMap", this.adminDeviceErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new AdminDeviceValidation();
export default validation;