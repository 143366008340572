import { useState, useEffect } from 'react'
import { useSelector, useStore } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import _ from 'lodash'

import { Card } from '@mui/material'

import { ListDisplay } from '../../Component/ListDisplay/ListDisplay'
import { constants } from '../../Constants/constants'
import { getList } from '../../helper/baseFetch'
import { generateTableCellToMultiLanguageName, getParamQueryString, getQuery, getQueryParamObject } from '../../helper/util'

export const FolderList = ({
  editBtn,
  createBtn,
  mutipleSelect,
  dialogFooterBtn,
  previewSelectedId,
  previewSelectedList,
  setPreviewSelectedList,
  selectedIdList,
  setSelectedIdList,
  newPath,
  newFilter,
  onClickListRow,
  savedData,
  changeUrlQueryString,
}) => {
  const store = useStore()
  const total = useSelector((state) => state.system.ui.total)
  const [folderList, setFolderList] = useState([])
  const [ready, setReady] = useState(false)
  const [selectedAllCheck, setSelectedAllCheck] = useState(false)
  const resource = 'Folder'
  const history = useHistory()
  const location = useLocation()
  const filterParams = getQuery()

  const folderTitleList = [
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: 'folderCode',
      sort: true,
    },
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: 'type',
      sort: true,
    },
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: 'name',
      sort: false,
      minSize: true,
    },
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: 'status',
      sort: true,
    },
  ]
  const listFilters = [
    {
      className: 'filter-input',
      label: { resource: resource, key: 'folderCode' },
      clear: true,
      value: '',
      name: 'folderCode',
      size: constants.SIZE.SMALL,
    },
    {
      className: 'filter-input',
      label: { resource: resource, key: 'type' },
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.FOLDER.TYPE,
      name: 'type',
      size: constants.SIZE.SMALL,
    },
    {
      className: 'filter-input',
      label: { resource: resource, key: 'name' },
      clear: true,
      value: '',
      name: 'name',
      size: constants.SIZE.SMALL,
    },
    {
      className: 'filter-input',
      label: { resource: resource, key: 'status' },
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.FOLDER.STATUS,
      name: 'status',
      size: constants.SIZE.SMALL,
    },
  ]

  const [param, setParam] = useState(
    !changeUrlQueryString
      ? {
          path: newPath ? newPath : '',
          page: 0,
          rowsPerPage: 10,
          sort: 'updateTimestamp',
          direction: 'DESC',
          listFilters: _.cloneDeep(listFilters),
          count: 0,
        }
      : getQueryParamObject(
          {
            path: newPath ? newPath : '',
            page: 0,
            rowsPerPage: 10,
            sort: 'updateTimestamp',
            direction: 'DESC',
            listFilters: _.cloneDeep(listFilters),
            count: 0,
          },
          _.cloneDeep(listFilters),
          folderTitleList
        )
  )

  useEffect(() => {
    if (!changeUrlQueryString) {
      return
    }

    const querySyring = getParamQueryString(
      {
        path: newPath ? newPath : '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: param.count,
      },
      param
    )

    const currentQueryString = window.location.search?.substring(1)

    if (querySyring === currentQueryString) {
      return
    }

    const newParam = getQueryParamObject(
      {
        path: newPath ? newPath : '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: param.count + 1,
      },
      _.cloneDeep(listFilters),
      folderTitleList
    )

    setParam(newParam)

    const newQuery = getParamQueryString(
      {
        path: newPath ? newPath : '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: 0,
      },
      newParam
    )

    history.replace({ pathname: location.pathname, search: newQuery })
  }, [filterParams])

  function onChangeParam(value) {
    value.count = value.count + 1

    if (!changeUrlQueryString) {
      setParam(value)
      return
    }

    const querySyring = getParamQueryString(
      {
        path: newPath ? newPath : '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: 0,
      },
      value
    )

    history.push({ pathname: location.pathname, search: querySyring })
  }

  function onChangeListFilters(list) {
    let tempParam = _.cloneDeep(param)
    tempParam.listFilters = list
    tempParam.page = 0
    onChangeParam(tempParam)
  }

  function onChangeListSort(sortItem) {
    let tempParam = _.cloneDeep(param)
    tempParam.sort = sortItem
    tempParam.direction = tempParam.direction === 'DESC' ? 'ASC' : 'DESC'
    onChangeParam(tempParam)
  }

  function onChangeListPage(page) {
    let tempParam = _.cloneDeep(param)
    tempParam.page = page
    onChangeParam(tempParam)
  }

  function onChangeListRowsPerPage(rowsPerPage) {
    let tempParam = _.cloneDeep(param)
    tempParam.page = 0
    tempParam.rowsPerPage = rowsPerPage
    onChangeParam(tempParam)
  }

  function onMultiSelectList(folderCode, dataList) {
    let selectedList = _.cloneDeep(selectedIdList)
    let newSelectedFolderList = _.cloneDeep(previewSelectedList)
    let tempDataList = _.cloneDeep(dataList)
    if (folderCode === 'all') {
      if (!selectedAllCheck) {
        folderList.map((item) => {
          newSelectedFolderList.push(item)
          return selectedList.push(item.folderCode)
        })
      } else {
        selectedList = selectedList.filter((item) => {
          let selectedFolder = folderList.map((folder) => {
            newSelectedFolderList = newSelectedFolderList.filter((newSelectedFolder) => newSelectedFolder.folderCode !== folder.folderCode)
            return folder.folderCode === item && folder.folderCode
          })
          return !selectedFolder.includes(item)
        })
      }
      setSelectedAllCheck(!selectedAllCheck)
    } else {
      if (selectedList.includes(folderCode)) {
        selectedList = selectedList.filter((item) => item !== folderCode)
        newSelectedFolderList = newSelectedFolderList.filter((item) => item.folderCode !== folderCode)
      } else {
        selectedList.push(folderCode)
        newSelectedFolderList.push.apply(
          newSelectedFolderList,
          tempDataList.filter((item) => item.folderCode === folderCode)
        )
      }

      let selectedPerPage = folderList.filter((folder) => {
        return selectedList.includes(folder.folderCode)
      })
      if (selectedPerPage.length === param.rowsPerPage && selectedPerPage.length !== 0) {
        setSelectedAllCheck(true)
      } else {
        setSelectedAllCheck(false)
      }
    }

    setSelectedIdList(selectedList)

    setPreviewSelectedList(
      newSelectedFolderList.map((folder) => {
        folder.type = 'FOLDER'
        folder.refCode = folder.folderCode
        folder.sequence = 0
        return folder
      })
    )
  }

  useEffect(() => {
    let tempParam = _.cloneDeep(param)
    const filter = newFilter
      ? _.cloneDeep(newFilter)
      : selectedIdList && selectedIdList.length > 0
      ? { selected: { column: 'folderCode', valueList: selectedIdList } }
      : {}
    tempParam.listFilters.forEach((filterItem) => {
      if (filterItem.value !== '' && filterItem.value !== undefined) {
        filter[filterItem.name] = filterItem.value
      }
    })
    getList(
      tempParam.path ? tempParam.path : constants.PATH.FOLDER.FOLDER,
      {
        sort: { field: tempParam.sort, order: tempParam.direction },
        filter: filter,
        pagination: { page: tempParam.page + 1, perPage: tempParam.rowsPerPage },
      },
      (payload) => {
        payload.map((item) => {
          item.name = generateTableCellToMultiLanguageName(item.tcName, item.enName, item.scName)
          return item
        })
        setFolderList(payload)
        let selectedPerPage = payload.filter((folder) => {
          return (
            savedData &&
            savedData
              .map((savedDataValue) => {
                return savedDataValue.refCode
              })
              .includes(folder.folderCode)
          )
        })
        if (selectedPerPage.length === Number(tempParam.rowsPerPage < total ? tempParam.rowsPerPage : total) && selectedPerPage.length !== 0) {
          setSelectedAllCheck(true)
        } else {
          setSelectedAllCheck(false)
        }
        setReady(true)
      },
      undefined,
      store
    )
  }, [param.count]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready && (
      <Card className="card-wrapper">
        <ListDisplay
          onClickListRow={onClickListRow}
          page={param.page}
          rowsPerPage={param.rowsPerPage}
          onChangeListSort={onChangeListSort}
          resource={resource}
          dataList={folderList}
          idName="folderCode"
          sort={param.sort}
          direction={param.direction}
          listTitles={folderTitleList}
          listFilters={param.listFilters}
          onChangeListPage={onChangeListPage}
          onChangeListRowsPerPage={onChangeListRowsPerPage}
          onChangeListFilters={onChangeListFilters}
          editBtn={editBtn}
          createBtn={createBtn}
          mutipleSelect={mutipleSelect}
          onMultiSelectList={onMultiSelectList}
          selectedAllCheck={selectedAllCheck}
          previewSelectedIdList={selectedIdList}
          dialogFooterBtn={dialogFooterBtn}
          redirectPath={'/' + constants.PATH.FOLDER.FOLDER + '/'}
        />
      </Card>
    )
  )
}
