import { restaurantTagValidationConstants } from '../Constants/validationConstants';

class RestaurantTagValidation {

  version;
  errorMap;
  restaurantTagErrorMap;
  valid;

  constructor() {

    if (!RestaurantTagValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.restaurantTagErrorMap = new Map();
      RestaurantTagValidation.instance = this;
    }
    return RestaurantTagValidation.instance;
  }

  validateEditRestaurantTag(restaurantTag) {
    this.resetErrorMessages();
    this.handleValidateEditRestaurantTag(restaurantTag);
    this.version++;
  }

  validateCreateRestaurantTag(restaurantTag) {
    this.resetErrorMessages();
    this.handleValidateCreateRestaurantTag(restaurantTag);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.restaurantTagErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key, uniqueCode) {
    if (!uniqueCode) {
      return (this.restaurantTagErrorMap.has(key)) ? this.restaurantTagErrorMap.get(key) : [];
    }
  }

  handleValidateEditRestaurantTag(restaurantTag) {
    if (!restaurantTag.enName) {
      this.setRestaurantTagErrorMapMessages(restaurantTagValidationConstants.KEY_EN_NAME, "Missing EN Name");
    }
    if (!restaurantTag.tcName) {
      this.setRestaurantTagErrorMapMessages(restaurantTagValidationConstants.KEY_TC_NAME, "Missing TC Name");
    }
    if (!restaurantTag.scName) {
      this.setRestaurantTagErrorMapMessages(restaurantTagValidationConstants.KEY_SC_NAME, "Missing SC Name");
    }
    if (!restaurantTag.orderSequence && restaurantTag.orderSequence !== 0) {
      this.setRestaurantTagErrorMapMessages(restaurantTagValidationConstants.KEY_ORDER_SEQUENCE, "Missing Order Sequence");
    } else if (restaurantTag.orderSequence < 0 || !Number.isInteger(Number(restaurantTag.orderSequence))) {
      this.setRestaurantTagErrorMapMessages(restaurantTagValidationConstants.KEY_ORDER_SEQUENCE, "Invalid Order Sequence");
    }
    if (!restaurantTag.enImage.cosFileUrl) {
      this.setRestaurantTagErrorMapMessages(restaurantTagValidationConstants.KEY_EN_IMAGE, "Missing EN Image");
    }
    if (!restaurantTag.tcImage.cosFileUrl) {
      this.setRestaurantTagErrorMapMessages(restaurantTagValidationConstants.KEY_TC_IMAGE, "Missing TC Image");
    }
    if (!restaurantTag.scImage.cosFileUrl) {
      this.setRestaurantTagErrorMapMessages(restaurantTagValidationConstants.KEY_SC_IMAGE, "Missing SC Image");
    }
    if (!restaurantTag.status) {
      this.setRestaurantTagErrorMapMessages(restaurantTagValidationConstants.KEY_STATUS, "Missing Status");
    }
  }

  handleValidateCreateRestaurantTag(restaurantTag) {
    if (!restaurantTag.enName) {
      this.setRestaurantTagErrorMapMessages(restaurantTagValidationConstants.KEY_EN_NAME, "Missing EN Name");
    }
    if (!restaurantTag.tcName) {
      this.setRestaurantTagErrorMapMessages(restaurantTagValidationConstants.KEY_TC_NAME, "Missing TC Name");
    }
    if (!restaurantTag.scName) {
      this.setRestaurantTagErrorMapMessages(restaurantTagValidationConstants.KEY_SC_NAME, "Missing SC Name");
    }
    if (!restaurantTag.orderSequence && restaurantTag.orderSequence !== 0) {
      this.setRestaurantTagErrorMapMessages(restaurantTagValidationConstants.KEY_ORDER_SEQUENCE, "Missing Order Sequence");
    } else if (restaurantTag.orderSequence < 0 || !Number.isInteger(Number(restaurantTag.orderSequence))) {
      this.setRestaurantTagErrorMapMessages(restaurantTagValidationConstants.KEY_ORDER_SEQUENCE, "Invalid Order Sequence");
    }
    if (!restaurantTag.enImage || !restaurantTag.enImage.cosFileUrl) {
      this.setRestaurantTagErrorMapMessages(restaurantTagValidationConstants.KEY_EN_IMAGE, "Missing EN Image");
    }
    if (!restaurantTag.tcImage || !restaurantTag.tcImage.cosFileUrl) {
      this.setRestaurantTagErrorMapMessages(restaurantTagValidationConstants.KEY_TC_IMAGE, "Missing TC Image");
    }
    if (!restaurantTag.scImage || !restaurantTag.scImage.cosFileUrl) {
      this.setRestaurantTagErrorMapMessages(restaurantTagValidationConstants.KEY_SC_IMAGE, "Missing SC Image");
    }
  }


  setRestaurantTagErrorMapMessages(key, errorMessages, valid = false) {
    this.restaurantTagErrorMap.set(key, errorMessages);
    // console.log("restaurantTagErrorMap", this.restaurantTagErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new RestaurantTagValidation();
export default validation;