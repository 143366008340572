import _ from "lodash";
import { LANDING_SECTION } from '../actions/types';
import { constants } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        landingSection: _.cloneDeep(constants.LANDING_SECTION_INPUT)
    }
}

const landingSectionReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LANDING_SECTION.SET_LANDING_SECTION_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case LANDING_SECTION.SET_LANDING_SECTION:
        case LANDING_SECTION.SET_LANDING_SECTION_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    landingSection: action.landingSection
                }
            })
        default:
            return state
    }
}

export default landingSectionReducer