import { useState, useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Drawer } from '@mui/material'
import { Card } from '@mui/material'
import Paper from '@mui/material/Paper'
import CancelIcon from '@mui/icons-material/Cancel'

import { constants as co } from '../../Constants/constants'
import { post } from '../../helper/baseFetch'
import { setLoading } from '../../actions/systemAction'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { TempTextInput } from '../../Component/Input/TempTextInput'
import { TempBtn } from '../../Component/Input/TempBtn'
import { DistrictList } from '../District/DistrictList'
import { DialogWrapper } from '../../Component/DialogWrapper'
import LandmarkDistrictMapValidation from '../../validations/landmarkDistrictMapValidation'
import { landmarkDistrictMapValidationConstants } from '../../Constants/validationConstants'
import useLandmark from '../../hooks/Landmark/useLandmark'

export const LandMarkDistrictMapCreate = ({ newShowRestaurantTextPanel, resourceName, sendDistrictIdList, landmarkCreateClear }) => {
  const dispatch = useDispatch()
  const store = useStore()
  const history = useHistory()
  const [clear, setClear] = useState(false)
  const [selectedList, setSelectedList] = useState()
  const [previewSelectedId, setPreviewSelectedId] = useState([])
  const [previewSelectedList, setPreviewSelectedList] = useState([])
  const [selectedDistrictList, setSelectedDistrictList] = useState([])
  const [selectedIdList, setSelectedIdList] = useState([])
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  const [showDistrictList, setShowDistrictList] = useState(newShowRestaurantTextPanel === false ? newShowRestaurantTextPanel : true)
  const resource = 'RestaurantDistrictMap'
  const savedLandmark = useSelector((state) => state.landmark.ui.landmark)
  // eslint-disable-next-line no-unused-vars
  const [errorVersion, setErrorVersion] = useState(LandmarkDistrictMapValidation.getVersion())

  const { getLandmarkIdAndName } = useLandmark()
  const mapCode = getLandmarkIdAndName()

  const tabs = [
    {
      label: { resource: resource, key: 'info' },
      tabIndex: co.LANDMARK_DISTRICT_MAP_TAB.INFO,
    },
  ]

  const onClickSaveDistrictListDrawer = () => {
    let districtId = _.cloneDeep(selectedIdList)
    dispatch(setLoading(true))
    let newTempDistrictMapList = []

    previewSelectedList.forEach((item) => {
      newTempDistrictMapList.push([
        {
          className: 'edit-create-input',
          variant: co.STYLE.VARIANT.STANDARD,
          size: co.SIZE.SMALL,
          label: { resource: resource, key: 'districtId' },
          value: item.districtId,
          name: 'districtId',
          isEndOfLine: false,
          disabled: true,
        },
        {
          className: 'edit-create-input',
          variant: co.STYLE.VARIANT.STANDARD,
          size: co.SIZE.SMALL,
          label: { resource: resource, key: 'enName' },
          value: item.enName,
          name: 'enName',
          isEndOfLine: false,
          disabled: true,
        },
        {
          className: 'edit-create-input',
          variant: co.STYLE.VARIANT.STANDARD,
          size: co.SIZE.SMALL,
          label: { resource: resource, key: 'tcName' },
          value: item.tcName,
          name: 'tcName',
          isEndOfLine: false,
          disabled: true,
        },
        {
          className: 'edit-create-input',
          variant: co.STYLE.VARIANT.STANDARD,
          size: co.SIZE.SMALL,
          label: { resource: resource, key: 'scName' },
          value: item.scName,
          name: 'scName',
          isEndOfLine: true,
          disabled: true,
        },
      ])
    })

    setSelectedList(newTempDistrictMapList)
    setSelectedDistrictList(previewSelectedList)
    if (resourceName) {
      sendDistrictIdList(districtId)
    }
    setShowDistrictList(false)
    dispatch(setLoading(false))
  }

  function onDeleteDistrictMapCard(deleteIndex) {
    setPreviewSelectedId(
      previewSelectedId.filter((item, index) => {
        return index !== deleteIndex
      })
    )
    setPreviewSelectedList(
      previewSelectedList.filter((item, index) => {
        return index !== deleteIndex
      })
    )
    setSelectedDistrictList(
      selectedDistrictList.filter((item, index) => {
        return index !== deleteIndex
      })
    )
    setSelectedList(
      selectedList.filter((item, index) => {
        return index !== deleteIndex
      })
    )
    setSelectedIdList(
      selectedIdList.filter((item, index) => {
        return index !== deleteIndex
      })
    )
  }

  const bottomBtns = [
    {
      variant: co.STYLE.VARIANT.CONTAINED,
      color: co.STYLE.PRIMARY,
      label: { key: 'save' },
      onClick: onClickSave,
      className: 'margin-left-16',
      icon: <SaveIcon />,
    },
    {
      variant: co.STYLE.VARIANT.CONTAINED,
      color: co.STYLE.PRIMARY,
      label: { key: 'clear' },
      disabled: false,
      onClick: onClickClear,
      className: 'margin-left-16',
      icon: <ClearIcon />,
    },
  ]

  const dialogFooterBtns = [
    {
      variant: co.STYLE.VARIANT.CONTAINED,
      color: co.STYLE.PRIMARY,
      label: { key: 'save' },
      disabled: false,
      onClick: onClickSaveDistrictListDrawer,
      className: 'margin-left-16',
      icon: <SaveIcon />,
    },
    {
      variant: co.STYLE.VARIANT.CONTAINED,
      color: co.STYLE.PRIMARY,
      label: { key: 'close' },
      disabled: false,
      onClick: onClickCloseDistrictListDrawer,
      className: 'margin-left-16',
      icon: <ClearIcon />,
    },
  ]

  const content = [
    {
      tab: (
        <>
          {LandmarkDistrictMapValidation.getErrorMessages(landmarkDistrictMapValidationConstants.KEY_LANDMARK_DISTRICT_MAP_ERROR) &&
            LandmarkDistrictMapValidation.getErrorMessages(landmarkDistrictMapValidationConstants.KEY_LANDMARK_DISTRICT_MAP_ERROR).length > 0 && (
              <div className="error-message">
                <CancelIcon className="error-message-icon" fontSize={co.SIZE.SMALL} />
                {LandmarkDistrictMapValidation.getErrorMessages(landmarkDistrictMapValidationConstants.KEY_LANDMARK_DISTRICT_MAP_ERROR)}
              </div>
            )}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', mb: '15px' }}>
            <Box>
              <TempBtn
                btnSetting={{
                  onClick: onClickAddDistrictMapList,
                  variant: co.STYLE.VARIANT.CONTAINED,
                  color: co.STYLE.PRIMARY,
                  label: { resource: resource, key: 'addDistrictMap' },
                  icon: <AddIcon />,
                }}
              />
              {selectedList &&
                selectedList.length > 0 &&
                selectedList.map((item, i) => {
                  return (
                    <Card key={i} className="image-list">
                      <>
                        <div className="delete-icon">
                          <TempBtn
                            btnSetting={{
                              className: 'edit-create-btn',
                              variant: co.STYLE.VARIANT.CONTAINED,
                              color: co.STYLE.PRIMARY,
                              label: { key: 'deleteBtn' },
                              onClick: () => onDeleteDistrictMapCard(i),
                              icon: <DeleteIcon />,
                            }}
                          />
                        </div>
                        {item.map((inputSetting, inputIndex) => {
                          return (
                            <div className="edit-display-wrapper" key={inputIndex}>
                              <TempTextInput inputSetting={inputSetting} />
                              {inputSetting.isEndOfLine && <div></div>}
                            </div>
                          )
                        })}
                      </>
                    </Card>
                  )
                })}
            </Box>
          </Box>
          <Drawer classes={{ paper: 'drawer-margin-left' }} anchor="right" open={showDistrictList} onClose={onClickCloseDistrictListDrawer}>
            <Paper>
              <DistrictList
                newListTitles={[
                  {
                    key: co.LIST_DISPLAY_TYPE.STRING,
                    value: 'districtId',
                    sort: true,
                  },
                  {
                    key: co.LIST_DISPLAY_TYPE.STRING,
                    value: 'name',
                    sort: false,
                  },
                  {
                    key: co.LIST_DISPLAY_TYPE.STRING,
                    value: 'status',
                    sort: true,
                  },
                ]}
                editBtn={false}
                createBtn={false}
                mutipleSelect={true}
                setSelectedIdList={setSelectedIdList}
                selectedIdList={selectedIdList}
                setPreviewSelectedList={setPreviewSelectedList}
                previewSelectedList={previewSelectedList}
                newPath={savedLandmark.id ? co.PATH.SHOP.RESTAURANT_LANDMARK_DISTRICT_LIST : null}
                newFilter={
                  selectedIdList && selectedIdList.length > 0
                    ? { landmarkId: savedLandmark.id, selected: { column: 'districtId', valueList: selectedIdList } }
                    : { landmarkId: savedLandmark.id }
                }
                dialogFooterBtn={
                  <div className="edit-footer-wrapper toolbar">
                    {dialogFooterBtns.map((btnSetting, index) => {
                      return <TempBtn key={index} btnSetting={btnSetting} />
                    })}
                  </div>
                }
              />
            </Paper>
          </Drawer>
        </>
      ),
      btn: !resourceName ? true : false,
    },
  ]

  function onClickAddDistrictMapList() {
    dispatch(setLoading(true))
    setShowDistrictList(true)
    dispatch(setLoading(false))
  }

  function onClickCloseDistrictListDrawer() {
    dispatch(setLoading(true))
    setSelectedIdList(selectedDistrictList.map((item) => item.districtId))
    setPreviewSelectedList(selectedDistrictList)
    setShowDistrictList(false)
    dispatch(setLoading(false))
  }

  const redirectToCreateEdit = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }
  const redirectToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push('/' + co.PATH.SHOP.RESTAURANT_LANDMARK + '/' + savedLandmark.id + '/' + co.LANDMARK_TAB.DISTRICT_LIST)
  }

  function onClickSave() {
    LandmarkDistrictMapValidation.validateCreateLandmarkDistrictMap(selectedDistrictList)
    if (LandmarkDistrictMapValidation.isValid()) {
      post(
        co.PATH.SHOP.RESTAURANT_LANDMARK_DISTRICT_MAP_LIST,
        { landmarkId: savedLandmark.id, districtMapList: selectedDistrictList },
        () => {
          setDialog({
            open: true,
            onClose: redirectToCreateEdit,
            dialogContentText: { key: 'editSuccess' },
            dialogActions: [
              { label: { key: 'backEdit' }, onClick: redirectToCreateEdit, icon: <ArrowBackIcon /> },
              { label: { key: 'backList' }, onClick: redirectToList, icon: <LowPriorityIcon /> },
            ],
          })
          setClear(false)
        },
        undefined,
        store
      )
      LandmarkDistrictMapValidation.resetErrorMessages()
    } else {
      setErrorVersion(LandmarkDistrictMapValidation.getVersion())
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    dispatch(setLoading(true))
    setSelectedList()
    setPreviewSelectedId([])
    setPreviewSelectedList([])
    setSelectedIdList([])
    setSelectedDistrictList([])
    setClear(false)
    dispatch(setLoading(false))
    LandmarkDistrictMapValidation.resetErrorMessages()
  }, [landmarkCreateClear === true || clear === true]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <EditCreateDisplay
        code={''}
        resource={resource}
        tabs={!resourceName ? tabs : ''}
        content={content}
        bottomBtns={bottomBtns}
        mapName={{ resource: 'RestaurantLandmark', key: 'landmarkId' }}
        mapCode={mapCode}
        display={resourceName === 'RestaurantLandmark' ? 'none' : 'create'}
      />
      {dialog && dialog.open && <DialogWrapper dialogInfo={dialog} />}
    </>
  )
}
