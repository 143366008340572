import { useState } from 'react'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { constants } from '../../Constants/constants'
import { getContent } from '../../Component/EditCreateDisplay/EditUtil'
import { ZigZagQRCode } from '../../Component/ZigZagQRCode/ZigZagQRCode'
import logoImage from '../../pictures/logo-white-border.svg'
import { QueueTemplateColorPicker } from '../QueueTemplate/QueueTemplateColorPicker'
import GetAppIcon from '@mui/icons-material/GetApp'
import QRCodeStyling from 'qr-code-styling'
import { toDataURL } from '../../helper/util'

const resource = 'tools'

const qrCode = new QRCodeStyling({
  width: 200,
  height: 200,
  type: 'svg',
  data: 'sample',
  dotsOptions: {
    type: 'dots',
  },
  image: logoImage,
  cornersDotOptions: {
    type: 'square',
  },
  cornersSquareOptions: {
    type: 'zig-zag',
  },
  qrOptions: {
    errorCorrectionLevel: 'L',
  },
  imageOptions: {
    crossOrigin: 'anonymous',
    imageSize: 0.5,
  },
})

export const ToolsList = () => {
  const [qrCodeSetting, setQrCodeSetting] = useState({
    url: 'sample',
    type: 'svg',
    errorCorrectionLevel: 'L',
    cornersSquareOptions: 'zig-zag',
    cornersDotOptions: 'square',
    image: logoImage,
    dotsOptions: 'dots',
    width: 1000,
    height: 1000,
    wholeQrCodeColor: '#000000',
    downloadFormat: 'png',
  })

  const downloadQrCode = async () => {
    const base64Image = qrCodeSetting.image? await toDataURL(qrCodeSetting.image) : ''
    qrCode.update({
      data: qrCodeSetting.url,
      dotsOptions: {
        type: qrCodeSetting.dotsOptions,
        color: qrCodeSetting.wholeQrCodeColor,
      },
      image: base64Image,
      cornersDotOptions: {
        type: qrCodeSetting.cornersDotOptions,
      },
      cornersSquareOptions: {
        type: qrCodeSetting.cornersSquareOptions,
      },
      qrOptions: {
        errorCorrectionLevel: qrCodeSetting.errorCorrectionLevel,
      },
      width: qrCodeSetting.width,
      height: qrCodeSetting.height,
    })

    if (qrCodeSetting.downloadFormat === 'svg') {
      qrCode.download({
        name: `qrCode`,
        extension: qrCodeSetting.downloadFormat,
      })
    } else {
      const blobFile = await qrCode.getRawData("svg");
      const img = document.createElement('img')
      img.src = URL.createObjectURL(blobFile);
      img.onload = () => {
        const canvas = document.createElement('canvas')
        canvas.width = qrCodeSetting.width
        canvas.height = qrCodeSetting.height
        const context = canvas.getContext('2d')
        context.drawImage(img, 0, 0, qrCodeSetting.width, qrCodeSetting.height)
        const ImgBase64 = canvas.toDataURL('image/png')
        let downloadLink = document.createElement('a')
        downloadLink.href = ImgBase64;
        downloadLink.download = `${'qrCode'}.png`
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
      }
    }
  }

  const editList = [
    {
      type: constants.TYPE.TITLE,
      value: { resource: resource, key: 'qrCodeGenerator' },
      className: 'first-sub-title',
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      type: constants.TYPE.FUNCTION,
      value: (
        <>
          <ZigZagQRCode
            value={qrCodeSetting.url}
            errorCorrectionLevel={qrCodeSetting.errorCorrectionLevel}
            cornersSquareOptions={qrCodeSetting.cornersSquareOptions}
            cornersDotOptions={qrCodeSetting.cornersDotOptions}
            image={qrCodeSetting.image}
            dotsOptions={qrCodeSetting.dotsOptions}
            width={250}
            height={250}
            wholeQrCodeColor={qrCodeSetting.wholeQrCodeColor}
          />
        </>
      ),
      isEndOfLine: true,
    },
    {
      className: 'full-size-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'qrCodeUrl' },
      value: qrCodeSetting.url,
      multiline: true,
      name: 'url',
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'cornersSquareOptions' },
      value: qrCodeSetting.cornersSquareOptions,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.TOOLS.CORNERS_SQAURE_OPTIONS,
      name: 'cornersSquareOptions',
      isEndOfLine: false,
      disableClearable: true,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'cornersDotOptions' },
      value: qrCodeSetting.cornersDotOptions,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.TOOLS.CORNERS_DOT_OPTIONS,
      name: 'cornersDotOptions',
      isEndOfLine: false,
      disableClearable: true,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'dotsOptions' },
      value: qrCodeSetting.dotsOptions,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.TOOLS.DOTS_OPTIONS,
      name: 'dotsOptions',
      isEndOfLine: true,
      disableClearable: true,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'width' },
      value: qrCodeSetting.width,
      type: constants.TYPE.NUMBER,
      min: 200,
      name: 'width',
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'height' },
      value: qrCodeSetting.height,
      type: constants.TYPE.NUMBER,
      min: 200,
      name: 'height',
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'wholeQrCodeColor' },
      value: qrCodeSetting.wholeQrCodeColor,
      name: 'wholeQrCodeColor',
      onClickEndAdornmentIconButton: () => {},
      endAdornmentIconButton: (
        <QueueTemplateColorPicker color={qrCodeSetting.wholeQrCodeColor} setColor={(color) => onChangeInput('wholeQrCodeColor', color)} />
      ),
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'logo' },
      value: !!qrCodeSetting.image,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.TOOLS.LOGO,
      name: 'image',
      isEndOfLine: false,
      disableClearable: true,
      disableStatusThemeColor: true,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'errorCorrectionLevel' },
      value: qrCodeSetting.errorCorrectionLevel,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.TOOLS.ERROR_CORRECTION_LEVEL,
      name: 'errorCorrectionLevel',
      isEndOfLine: true,
      disableClearable: true,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'downloadFormat' },
      value: qrCodeSetting.downloadFormat,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.TOOLS.DOWNLOAD_FORMAT,
      name: 'downloadFormat',
      isEndOfLine: true,
      disableClearable: true,
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { resource: resource, key: 'download' },
      disabled: false,
      onClick: () => downloadQrCode(),
      name: 'download',
      type: constants.TYPE.BUTTON,
      icon: <GetAppIcon />,
      isEndOfLine: true,
    },
  ]

  const onChangeInput = (name, e) => {
    switch (name) {
      case 'image':
        if (e) {
          setQrCodeSetting((prev) => ({ ...prev, image: logoImage }))
        } else {
          setQrCodeSetting((prev) => ({ ...prev, image: null }))
        }
        break
      default:
        const tempQrCodeSetting = { ...qrCodeSetting }
        tempQrCodeSetting[`${name}`] = e
        setQrCodeSetting(tempQrCodeSetting)
    }
  }

  const content = [
    {
      tab: editList.map((inputSetting, index) => {
        let content = getContent(inputSetting, index, onChangeInput)
        return (
          <div className="edit-display-wrapper" key={index}>
            {content}
            {inputSetting.isEndOfLine && <div></div>}
          </div>
        )
      }),
      btn: false,
    },
  ]

  return (
    <>
      <EditCreateDisplay
        resource={resource}
        tabs={false}
        content={content}
        onChangeInput={onChangeInput}
        display="none"
      />
    </>
  )
}
