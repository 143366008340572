import { reservationTemplateValidationConstants } from '../Constants/validationConstants';
import moment from 'moment';

class ReservationTemplateValidation {

  version;
  errorMap;
  reservationTemplateErrorMap;
  timeSectionErrorMap;
  tableTypeErrorMap;
  valid;

  constructor() {

    if (!ReservationTemplateValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.reservationTemplateErrorMap = new Map();
      this.timeSectionErrorMap = new Map();
      this.tableTypeErrorMap = new Map();
      ReservationTemplateValidation.instance = this;
    }
    return ReservationTemplateValidation.instance;
  }

  validateTemplate(template, description) {
    this.resetErrorMessages();
    this.validateReservationTemplate(description);
    this.validateTimeSection(template);
    this.validateTableType(template);
    this.version++;
  }

  validateTemplateTimeSection(template) {
    this.timeSectionErrorMap = new Map();
    this.valid = true;
    this.validateTimeSection(template);
    this.version++;
  }

  validateTemplateTableType(template) {
    this.tableTypeErrorMap = new Map();
    this.valid = true;
    this.validateTableType(template);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.reservationTemplateErrorMap = new Map();
    this.timeSectionErrorMap = new Map();
    this.tableTypeErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key, uniqueCode, subUniqueCode) {
    if (!uniqueCode) {
      return (this.reservationTemplateErrorMap.has(key)) ? this.reservationTemplateErrorMap.get(key) : [];
    } else {
      switch (key) {
        case reservationTemplateValidationConstants.KEY_RESERVATION_TEMPLATE_ERROR:
        case reservationTemplateValidationConstants.KEY_TIME_SECTION_STATUS:
        case reservationTemplateValidationConstants.KEY_TIME_SECTION_ID:
        case reservationTemplateValidationConstants.KEY_GROUPED_SECTION:
        case reservationTemplateValidationConstants.KEY_TIME_SECTION_EN_LABEL:
        case reservationTemplateValidationConstants.KEY_TIME_SECTION_TC_LABEL:
        case reservationTemplateValidationConstants.KEY_TIME_SECTION_SC_LABEL:
        case reservationTemplateValidationConstants.KEY_TIME_SECTION_START_TIME:
        case reservationTemplateValidationConstants.KEY_TIME_SECTION_END_TIME:
          if (this.timeSectionErrorMap.has(uniqueCode) && this.timeSectionErrorMap.get(uniqueCode).has(key)) {
            return this.timeSectionErrorMap.get(uniqueCode).get(key);
          }
          return [];
        case reservationTemplateValidationConstants.KEY_TABLE_TYPE_LIST:
          if (this.reservationTemplateErrorMap.has(uniqueCode) && this.reservationTemplateErrorMap.get(uniqueCode).has(key)) {
            return this.reservationTemplateErrorMap.get(uniqueCode).get(key);
          }
          return [];
        case reservationTemplateValidationConstants.KEY_TABLE_TYPE_ERROR:
        case reservationTemplateValidationConstants.KEY_TABLE_TYPE:
        case reservationTemplateValidationConstants.KEY_RESERVATION_TYPE:
        case reservationTemplateValidationConstants.KEY_MIN_SIZE:
        case reservationTemplateValidationConstants.KEY_MAX_SIZE:
        case reservationTemplateValidationConstants.KEY_AUTO_ACCEPT_FLAG:
        case reservationTemplateValidationConstants.KEY_AUTO_REJECT_FLAG:
        case reservationTemplateValidationConstants.KEY_LIMIT_MINUTE:
        case reservationTemplateValidationConstants.KEY_REMINDER_MINUTE:
        case reservationTemplateValidationConstants.KEY_QUOTA:
        case reservationTemplateValidationConstants.KEY_CUT_OFF_HOUR:
        case reservationTemplateValidationConstants.KEY_CUT_OFF_MINUTE:
        case reservationTemplateValidationConstants.KEY_DEFAULT_STATUS:
        case reservationTemplateValidationConstants.KEY_TABLE_TYPE_STATUS:
          if (this.tableTypeErrorMap.has(uniqueCode) && this.tableTypeErrorMap.get(uniqueCode).has(key)) {
            return this.tableTypeErrorMap.get(uniqueCode).get(key);
          }
          return [];
        default:
          return [];
      }
    }
  }

  validateReservationTemplate(description) {
    if (!description) {
      this.setReservationTemplateErrorMapMessages("description", "Missing Description");
    } else {
      this.setReservationTemplateDeleteErrorMapMessages("description");
    }
  }

  validateTimeSection(reservationTemplate) {
    let timeList = [];
    let timeSectionList = [];
    let timeSectionStatusList = [];
    reservationTemplate.map((item) => {
      const reservationTemplateMap = new Map();
      if (item.id && !item.status) {
        reservationTemplateMap.set(reservationTemplateValidationConstants.KEY_TIME_SECTION_STATUS, ['Missing Status']);
      }
      if (!item.timeSectionId) {
        reservationTemplateMap.set(reservationTemplateValidationConstants.KEY_TIME_SECTION_ID, ['Missing Time Section Id']);
      } else if (timeSectionList.includes(item.timeSectionId)) {
        reservationTemplateMap.set(reservationTemplateValidationConstants.KEY_TIME_SECTION_ID, ['Duplicate Time Section Id']);
      } else {
        timeSectionList.push(item.timeSectionId)
      }
      if (!item.groupedSection) {
        reservationTemplateMap.set(reservationTemplateValidationConstants.KEY_GROUPED_SECTION, ['Missing Grouped Section']);
      }
      if (!item.timeSectionEnLabel) {
        reservationTemplateMap.set(reservationTemplateValidationConstants.KEY_TIME_SECTION_EN_LABEL, ['Missing Time Section En Label']);
      }
      if (!item.timeSectionTcLabel) {
        reservationTemplateMap.set(reservationTemplateValidationConstants.KEY_TIME_SECTION_TC_LABEL, ['Missing Time Section Tc Label']);
      }
      if (!item.timeSectionScLabel) {
        reservationTemplateMap.set(reservationTemplateValidationConstants.KEY_TIME_SECTION_SC_LABEL, ['Missing Time Section Sc Label']);
      }
      const startTime = item.startTime;
      const endTime = item.endTime;
      if (!startTime) {
        reservationTemplateMap.set(reservationTemplateValidationConstants.KEY_TIME_SECTION_START_TIME, ['Missing Time Section Start Time']);
      }
      if (!endTime) {
        reservationTemplateMap.set(reservationTemplateValidationConstants.KEY_TIME_SECTION_END_TIME, ['Missing Time Section End Time']);
      }
      if ((startTime && endTime) && (moment.duration(startTime).asSeconds() > moment.duration(endTime).asSeconds())) {
        reservationTemplateMap.set(reservationTemplateValidationConstants.KEY_TIME_SECTION_START_TIME, ['Time Section Start Time should not be later than End Time']);
        reservationTemplateMap.set(reservationTemplateValidationConstants.KEY_TIME_SECTION_END_TIME, ['Time Section Start Time should not be later than End Time']);
      }
      let error = [];
      timeList.map((timeValue) => {
        if (timeValue.startTime && timeValue.endTime && timeValue.startTime <= endTime && timeValue.endTime >= startTime) {
          reservationTemplateMap.set(reservationTemplateValidationConstants.KEY_TIME_SECTION_START_TIME, ['Duplicate Time']);
          reservationTemplateMap.set(reservationTemplateValidationConstants.KEY_TIME_SECTION_END_TIME, ['Duplicate Time']);
          error.push(true)
        }
        return timeValue
      })
      if (!error.includes(true)) {
        timeList.push({ startTime: startTime, endTime: endTime })
      }

      if (item.status === "D") {
        timeSectionStatusList.push(item.status);
      }

      if (reservationTemplateMap.size > 0) {
        reservationTemplateMap.set(reservationTemplateValidationConstants.KEY_RESERVATION_TEMPLATE_ERROR, ["Has error(s)"]);
        this.setTimeSectionErrorMapMessages(item.customId, reservationTemplateMap);
      }

      if (reservationTemplate.length === 0) {
        this.setReservationTemplateErrorMapMessages("timeSection", "Missing Time Section");
      } else if (timeSectionStatusList.length === reservationTemplate.length) {
        this.setReservationTemplateErrorMapMessages("timeSection", "Error: All Time Sections are D (Deactive)");
      } else {
        this.setReservationTemplateDeleteErrorMapMessages("timeSection")
      }
      return item
    })
  }

  validateTableType(reservationTemplate) {
    reservationTemplate.map((item) => {
      const timeSectionMap = new Map();
      if (!item.tableTypeList || item.tableTypeList.length <= 0) {
        timeSectionMap.set(reservationTemplateValidationConstants.KEY_TABLE_TYPE_LIST, ['Error: Please add at least one Table Type!']);
      } else {
        let tableTypeList = [];
        let tableTypeStatusList = [];
        item.tableTypeList.map((tableType) => {
          const tableTypeMap = new Map();
          if (!tableType.tableType) {
            tableTypeMap.set(reservationTemplateValidationConstants.KEY_TABLE_TYPE, ['Missing Table Type']);
          } else if (tableTypeList.includes(tableType.tableType)) {
            tableTypeMap.set(reservationTemplateValidationConstants.KEY_TABLE_TYPE, ['Duplicate Table Type']);
          } else {
            tableTypeList.push(tableType.tableType)
          }
          if (!tableType.reservationType) {
            tableTypeMap.set(reservationTemplateValidationConstants.KEY_RESERVATION_TYPE, ['Missing Reservation Type']);
          }
          if ((!isNaN(Number(tableType.minSize)) && !isNaN(Number(tableType.maxSize))) && (Number(tableType.minSize) > Number(tableType.maxSize))) {
            tableTypeMap.set(reservationTemplateValidationConstants.KEY_MIN_SIZE, ['Table Type min size should not be later than max size']);
            tableTypeMap.set(reservationTemplateValidationConstants.KEY_MAX_SIZE, ['Table Type min size should not be later than max size']);
          } else if (isNaN(Number(tableType.minSize))) {
            tableTypeMap.set(reservationTemplateValidationConstants.KEY_MIN_SIZE, ['Missing Min Size']);
          } else if (Number(tableType.minSize) === 0) {
            tableTypeMap.set(reservationTemplateValidationConstants.KEY_MIN_SIZE, ['Invalid Min Size']);
          }
          if (isNaN(Number(tableType.maxSize))) {
            tableTypeMap.set(reservationTemplateValidationConstants.KEY_MAX_SIZE, ['Missing Max Size']);
          } else if (Number(tableType.maxSize) === 0) {
            tableTypeMap.set(reservationTemplateValidationConstants.KEY_MAX_SIZE, ['Invalid Max Size']);
          }
          if (!tableType.autoAcceptFlag) {
            tableTypeMap.set(reservationTemplateValidationConstants.KEY_AUTO_ACCEPT_FLAG, ['Missing Auto Accept Flag']);
          }
          if (!tableType.autoRejectFlag) {
            tableTypeMap.set(reservationTemplateValidationConstants.KEY_AUTO_REJECT_FLAG, ['Missing Auto Reject Flag']);
          }
          if (tableType.limitMinute && (Number(tableType.limitMinute) < 0 || !Number.isInteger(tableType.limitMinute))) {
            tableTypeMap.set(reservationTemplateValidationConstants.KEY_LIMIT_MINUTE, ['Invalid Limit Minute']);
          }
          if (tableType.reminderMinute && (Number(tableType.reminderMinute) < 0 || !Number.isInteger(tableType.reminderMinute))) {
            tableTypeMap.set(reservationTemplateValidationConstants.KEY_REMINDER_MINUTE, ['Invalid Reminder Minute']);
          }
          if ((isNaN(Number(tableType.quota)) && Number(tableType.quota) !== 0)) {
            tableTypeMap.set(reservationTemplateValidationConstants.KEY_QUOTA, ['Missing Quota']);
          } else if (tableType.quota && (Number(tableType.quota) < 0 || !Number.isInteger(tableType.quota))) {
            tableTypeMap.set(reservationTemplateValidationConstants.KEY_QUOTA, ['Invalid Quota']);
          }
          if ((isNaN(Number(tableType.cutOffHour)) && Number(tableType.cutOffHour) !== 0)) {
            tableTypeMap.set(reservationTemplateValidationConstants.KEY_CUT_OFF_HOUR, ['Missing Cut Off Hour']);
          } else if (tableType.cutOffHour && (Number(tableType.cutOffHour) < 0 || !Number.isInteger(tableType.cutOffHour))) {
            tableTypeMap.set(reservationTemplateValidationConstants.KEY_CUT_OFF_HOUR, ['Invalid Cut Off Hour']);
          }
          if ((isNaN(Number(tableType.cutOffMinute)) && Number(tableType.cutOffMinute) !== 0)) {
            tableTypeMap.set(reservationTemplateValidationConstants.KEY_CUT_OFF_MINUTE, ['Missing Cut Off Minute']);
          } else if (tableType.cutOffMinute && (Number(tableType.cutOffMinute) < 0 || !Number.isInteger(tableType.cutOffMinute))) {
            tableTypeMap.set(reservationTemplateValidationConstants.KEY_CUT_OFF_MINUTE, ['Invalid Cut Off Minute']);
          }
          if (!tableType.defaultStatus) {
            tableTypeMap.set(reservationTemplateValidationConstants.KEY_DEFAULT_STATUS, ['Missing Default Status']);
          }
          if (item.status === "D" && tableType.status === "A") {
            tableTypeMap.set(reservationTemplateValidationConstants.KEY_TABLE_TYPE_STATUS, ['Invalid Status, ther Time Section Status is D (deactive)']);
          }
          if (tableType.status === "D") {
            tableTypeStatusList.push(tableType.status);
          }
          if (tableTypeMap.size > 0) {
            tableTypeMap.set(reservationTemplateValidationConstants.KEY_TABLE_TYPE_ERROR, ["Has error(s)"]);
            this.setTableTypeErrorMapMessages(tableType.customId, tableTypeMap);
          }
          if (item.status === "A" && tableTypeStatusList.length === item.tableTypeList.length) {
            timeSectionMap.set(reservationTemplateValidationConstants.KEY_TABLE_TYPE_LIST, ['Error: All Table Types are D (Deactive)']);
          }
          return tableType
        })
      }
      if (timeSectionMap.size > 0) {
        this.setReservationTemplateErrorMapMessages(item.customId, timeSectionMap);
      } else {
        this.setReservationTemplateDeleteErrorMapMessages(item.customId, timeSectionMap);
      }
      return item
    })
  }


  setReservationTemplateErrorMapMessages(key, errorMessages, valid = false) {
    this.reservationTemplateErrorMap.set(key, errorMessages);
    // console.log("reservationTemplateErrorMap", this.reservationTemplateErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

  setReservationTemplateDeleteErrorMapMessages(key, errorMessages, valid = false) {
    this.reservationTemplateErrorMap.delete(key, errorMessages);
    // console.log("reservationTemplateErrorMap", this.reservationTemplateErrorMap)
  }

  setTimeSectionErrorMapMessages(key, errorMessages, valid = false) {
    this.timeSectionErrorMap.set(key, errorMessages);
    // console.log("timeSectionErrorMap", this.timeSectionErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

  setTimeSectionDeleteErrorMapMessages(key, errorMessages, valid = false) {
    this.timeSectionErrorMap.delete(key, errorMessages);
    // console.log("timeSectionErrorMap", this.timeSectionErrorMap)
  }

  setTableTypeErrorMapMessages(key, errorMessages, valid = false) {
    this.tableTypeErrorMap.set(key, errorMessages);
    // console.log("tableTypeErrorMap", this.tableTypeErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new ReservationTemplateValidation();
export default validation;