import { helperTextStyles } from "../../../helper/util";
import { constants } from "../../../Constants/constants";
import RestaurantPaymentValidation from "../../../validations/restaurantPaymentValidation";
import { restaurantPaymentValidationConstants } from "../../../Constants/validationConstants";
import { PaymentCodeField } from "../RestaurantPaymentEdit/PaymentCodeField";

const LOCAL_CONSTANT = {
  PAYMENT_CODE: 'paymentCode',
  CARD_TYPE: 'cardType',
  WECHAT: "WECHAT_PAY",
  CASH: "CASH",
  GULUFPS: "FPS",
  SYSTEM_GROUP_ID: 'systemGroupId',
}

export const createPaymentCodeFieldDisplaySetting = (resource, data, handleOnClickDrawer, initialPaymentTypeList) =>{

  const helperTextStylesClasses = helperTextStyles();
  const cardTypeSelectNotRequired = "No need to Choose Card Type";

  const displaySetting = [
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "paymentCode" },
      value: data.paymentCode,
      name: "paymentCode",
      disabled: true,
      required: true,
      onClickEndAdornmentIconButton: ()=>handleOnClickDrawer(LOCAL_CONSTANT.PAYMENT_CODE),
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: RestaurantPaymentValidation.getErrorMessages(restaurantPaymentValidationConstants.KEY_PAYMENT_CODE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: `edit-create-input`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "cardType" },
      value: initialPaymentTypeList.length>0?data.cardType:cardTypeSelectNotRequired,
      name: "cardType",
      disabled: true,
      isEndOfLine: false,
      required: true,
      onClickEndAdornmentIconButton: ()=>handleOnClickDrawer(LOCAL_CONSTANT.CARD_TYPE),
      clear: false,
      helperTextIcon: true,
      helperText: RestaurantPaymentValidation.getErrorMessages(restaurantPaymentValidationConstants.KEY_CARD_TYPE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
  ]

  if(initialPaymentTypeList<=0){
    delete displaySetting[1].onClick;
  }

  return displaySetting;
}

export const createRestaurantPaymentEditDisplaySetting = (resource, data, onChangeInput) =>{

  const helperTextStylesClasses = helperTextStyles();

  return (
    [
      {
        className: "edit-create-input",
        variant: constants.STYLE.VARIANT.STANDARD,
        size: constants.SIZE.SMALL,
        label: { resource: resource, key: "restUrlId" },
        value: data.restUrlId,
        name: "restUrlId",
        isEndOfLine: true,
        disabled: true,
      },
      {
        className: "edit-create-input",
        size: constants.SIZE.SMALL,
        label: { resource: resource, key: "serviceType" },
        value: data.serviceType,
        name: "serviceType",
        isEndOfLine: true,
        required: true,
        type: constants.TYPE.SELECT,
        selectValue: constants.CHOICES.RESTAURANT_PAYMENT.SERVICE_TYPE,
        helperTextIcon: true,
        helperText: RestaurantPaymentValidation.getErrorMessages(restaurantPaymentValidationConstants.KEY_SERVICE_TYPE),
        FormHelperTextProps: { className: helperTextStylesClasses.root },
      },
      {
        className: "edit-create-input",
        variant: constants.STYLE.VARIANT.STANDARD,
        size: constants.SIZE.SMALL,
        label: { resource: resource, key: "systemGroupName" },
        value: data.systemGroupName,
        name: "systemGroupName",
        isEndOfLine: true,
        helperTextIcon: true,
        disabled: true,
        helperText: RestaurantPaymentValidation.getErrorMessages(restaurantPaymentValidationConstants.KEY_SYSTEM_GROUP_NAME),
        FormHelperTextProps: { className: helperTextStylesClasses.root },
      },
      {
        className: "edit-create-input",
        size: constants.SIZE.SMALL,
        type: constants.TYPE.FUNCTION,
        value:
          <>
            <PaymentCodeField 
              onChangeInput={ onChangeInput }
            />
          </>,
        isEndOfLine: true,
        disabled: true,
      },
      {
        className: "edit-create-input",
        size: constants.SIZE.SMALL,
        label: { resource: resource, key: "sequence" },
        value: data.sequence,
        name: "sequence",
        isEndOfLine: true,
        required: true,
        type: constants.TYPE.NUMBER, 
        helperTextIcon: true,
        helperText: RestaurantPaymentValidation.getErrorMessages(restaurantPaymentValidationConstants.KEY_SEQUENCE),
        FormHelperTextProps: { className: helperTextStylesClasses.root },
      },
      {
        className: "edit-create-input",
        size: constants.SIZE.SMALL,
        label: { resource: resource, key: "status" },
        value: data.status,
        name: "status",
        isEndOfLine: true,
        required: true,
        type: constants.TYPE.SELECT,
        selectValue: constants.CHOICES.RESTAURANT_PAYMENT.STATUS,
        helperTextIcon: true,
        helperText: RestaurantPaymentValidation.getErrorMessages(restaurantPaymentValidationConstants.KEY_STATUS),
        FormHelperTextProps: { className: helperTextStylesClasses.root },
      },
    ]
  )

}

export const createRestaurantPaymentCreateDisplaySetting = (resource, data, onChangeInput, restUrlId) =>{

  const helperTextStylesClasses = helperTextStyles();
  
  return (
    [
      {
        className: "edit-create-input",
        variant: constants.STYLE.VARIANT.STANDARD,
        size: constants.SIZE.SMALL,
        label: { resource: resource, key: "restUrlId" },
        value: restUrlId,
        name: "restUrlId",
        isEndOfLine: true,
        disabled: true,
      },
      {
        className: "edit-create-input",
        size: constants.SIZE.SMALL,
        label: { resource: resource, key: "serviceType" },
        value: data.serviceType,
        name: "serviceType",
        isEndOfLine: true,
        required: true,
        type: constants.TYPE.SELECT,
        selectValue: constants.CHOICES.RESTAURANT_PAYMENT.SERVICE_TYPE,
        helperTextIcon: true,
        helperText: RestaurantPaymentValidation.getErrorMessages(restaurantPaymentValidationConstants.KEY_SERVICE_TYPE),
        FormHelperTextProps: { className: helperTextStylesClasses.root },
      },
      {
        className: "edit-create-input",
        variant: constants.STYLE.VARIANT.STANDARD,
        size: constants.SIZE.SMALL,
        label: { resource: resource, key: "systemGroupName" },
        value: data.systemGroupName,
        name: "systemGroupName",
        isEndOfLine: true,
        helperTextIcon: true,
        disabled: true,
        helperText: RestaurantPaymentValidation.getErrorMessages(restaurantPaymentValidationConstants.KEY_SYSTEM_GROUP_NAME),
        FormHelperTextProps: { className: helperTextStylesClasses.root },
      },
      {
        className: "edit-create-input",
        size: constants.SIZE.SMALL,
        type: constants.TYPE.FUNCTION,
        value:
          <>
            <PaymentCodeField 
              onChangeInput={ onChangeInput }
            />
          </>,
        isEndOfLine: true,
        disabled: true,
      },
      {
        className: "edit-create-input",
        size: constants.SIZE.SMALL,
        label: { resource: resource, key: "sequence" },
        value: data.sequence,
        name: "sequence",
        isEndOfLine: true,
        required: true,
        type: constants.TYPE.NUMBER, 
        helperTextIcon: true,
        helperText: RestaurantPaymentValidation.getErrorMessages(restaurantPaymentValidationConstants.KEY_SEQUENCE),
        FormHelperTextProps: { className: helperTextStylesClasses.root },
      },
      {
        className: "edit-create-input",
        variant: constants.STYLE.VARIANT.STANDARD,
        size: constants.SIZE.SMALL,
        label: { resource: resource, key: "status" },
        value: data.status,
        name: "status",
        isEndOfLine: true,
        required: true,
        disabled: true,
        helperTextIcon: true,
        helperText: RestaurantPaymentValidation.getErrorMessages(restaurantPaymentValidationConstants.KEY_STATUS),
        FormHelperTextProps: { className: helperTextStylesClasses.root },
      },
    ]
  )
}