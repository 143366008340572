import { paymentValidationConstants } from '../Constants/validationConstants';

class PaymentValidation {

  version;
  errorMap;
  paymentErrorMap;
  valid;

  constructor() {

    if (!PaymentValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.paymentErrorMap = new Map();
      PaymentValidation.instance = this;
    }
    return PaymentValidation.instance;
  }

  validateEditPayment(payment) {
    this.resetErrorMessages();
    this.handleValidateEditPayment(payment);
    this.version++;
  }

  validateCreatePayment(payment) {
    this.resetErrorMessages();
    this.handleValidateCreatePayment(payment);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.paymentErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key, uniqueCode) {
    if (!uniqueCode) {
      return (this.paymentErrorMap.has(key)) ? this.paymentErrorMap.get(key) : [];
    }
  }

  handleValidateEditPayment(payment) {
  }

  handleValidateCreatePayment(payment) {
    if (!payment.planCode) {
      this.setPaymentErrorMapMessages(paymentValidationConstants.KEY_PLAN_CODE, "Missing Plan Code");
    }
    if (!payment.paymentCode) {
      this.setPaymentErrorMapMessages(paymentValidationConstants.KEY_PAYMENT_CODE, "Missing Payment Code");
    } else if (payment.paymentCode === "CHEQUE") {
      if (!payment.chequeNumber) {
        this.setPaymentErrorMapMessages(paymentValidationConstants.KEY_CHEQUE_NUMBER, "Missing Cheque Number");
      }
      if (!payment.bankCode) {
        this.setPaymentErrorMapMessages(paymentValidationConstants.KEY_BANK_CODE, "Missing Bank Code");
      }
      if (!payment.bankAccountNumber) {
        this.setPaymentErrorMapMessages(paymentValidationConstants.KEY_BANK_ACCOUNT_NUMBER, "Missing Bank Account Number");
      }
      if (!payment.chequeImage) {
        this.setPaymentErrorMapMessages(paymentValidationConstants.KEY_CHEQUE_IMAGE, "Missing Cheque Image");
      }
    }
  }


  setPaymentErrorMapMessages(key, errorMessages, valid = false) {
    this.paymentErrorMap.set(key, errorMessages);
    // console.log("paymentErrorMap", this.paymentErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new PaymentValidation();
export default validation;