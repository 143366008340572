import { RESTAURANT_APPROVE } from './types';
import _ from "lodash";

export const setRestaurantApproveDefault = () => {
    return {
        type: RESTAURANT_APPROVE.SET_RESTAURANT_APPROVE_DEFAULT,
    }
}

export const setRestaurantApprove = (restaurantApprove) => {
    return {
        type: RESTAURANT_APPROVE.SET_RESTAURANT_APPROVE,
        restaurantApprove: restaurantApprove
    }
}

export const setRestaurantApproveValue = (draftType, orignalProduct, key, value, index) => {
    const restaurantApprove = _.cloneDeep(orignalProduct);
    restaurantApprove[draftType] = value;
    return {
        type: RESTAURANT_APPROVE.SET_RESTAURANT_APPROVE_VALUE,
        restaurantApprove: restaurantApprove
    }
}
