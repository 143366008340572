import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import Geocode from 'react-geocode';
import { debounce } from 'lodash';

import SaveIcon from '@mui/icons-material/Save';
import RoomIcon from '@mui/icons-material/Room';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

import { TempTextInput } from '../../../Component/Input/TempTextInput';
import { TempBtn } from '../../../Component/Input/TempBtn';
import { getString } from '../../../helper/util';

import { constants } from '../../../Constants/constants';

import '../../../styles/MapModal.css';

const MyPositionMarker = ({ text }) => (
  <div>
    <RoomIcon />
    {text}
  </div>
);

const GoogleMapPop = ({ ...props }) => {
  const lang = useSelector((state) => state.lang.ui.lang);
  const theme = useSelector((state) => state.theme.ui.theme);

  const [myPosition, setMyPosition] = useState({
    lat: props.myPosition.lat,
    lng: props.myPosition.lng,
  });

  const [zoomLv, setZoom] = useState(props.myPosition.z);
  const [addressZH, setAddressZH] = useState(props.myPosition.tcAddress);
  const [originalAddressZH, setOriginalAddressZH] = useState(
    props.myPosition.tcAddress
  );
  const [address, setAddress] = useState(props.myPosition.enAddress);
  const [originalAddress, setOriginalAddress] = useState(
    props.myPosition.enAddress
  );

  const [mapApiLoaded, setMapApiLoaded] = useState(false);

  const apiHasLoaded = () => {
    setMapApiLoaded(true);
  };

  const handleCenterChange = (center, zoom, bounds, marginBounds) => {
    setZoom(zoom);
  };

  let inputRef = useRef();

  const [inputText, setInputText] = useState('');

  const [inputLat, setInputLat] = useState(props.myPosition.lat);
  const [inputLng, setInputLng] = useState(props.myPosition.lng);

  const [currentCenter, setCurrentCenter] = useState({
    lat: parseFloat(props.myPosition.lat),
    lng: parseFloat(props.myPosition.lng),
  });

  const handleInput = () => {
    setInputText(inputRef.current.value);
  };

  const handlelat = (name, event) => {
    setInputLat(Number(event));
  };

  const handlelng = (name, event) => {
    setInputLng(Number(event));
  };

  const handlezoom = (name, event) => {
    if (event < 3) {
      event = 3;
    } else if (event > 22) {
      event = 22;
    }
    setZoom(Number(event));
  };

  const saveAddress_ZH = (name, event) => {
    setAddressZH(event);
  };

  const saveAddress = (name, event) => {
    setAddress(event);
  };

  const ChineseAddress = (response) => {
    var address_components = response.results[0].address_components;
    var i;
    var addressname = ' ';
    for (i = address_components.length - 3; i >= 0; i--) {
      addressname = addressname + address_components[i].long_name;
    }
    setAddressZH(addressname);
    setOriginalAddressZH(addressname);
  };

  const EnglishAddress = (response) => {
    var address_components = response.results[0].address_components;
    var i;
    var addressname = ' ';
    for (i = 0; i < address_components.length - 2; i++) {
      if (i === 0) {
        var long_name = address_components[i].long_name;
        var long_name_new = long_name.replace('號', '');
        addressname = addressname + ' ' + long_name_new;
      } else {
        addressname = addressname + ' ' + address_components[i].long_name;
      }
    }
    setAddress(addressname);
    setOriginalAddress(addressname);
  };

  const findLocation = () => {
    if (mapApiLoaded) {
      Geocode.setApiKey(process.env.REACT_APP_MAP_KEY);
      Geocode.fromAddress(inputText).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          const newPosition = {
            lat: lat,
            lng: lng,
          };
          setCurrentCenter(newPosition);
          setMyPosition(newPosition);
          setInputLat(lat);
          setInputLng(lng);
          Geocode.setLanguage('ZH-TW');
          Geocode.setRegion('ZH-TW');
          Geocode.fromLatLng(lat, lng).then(
            (response) => {
              ChineseAddress(response);
            },
            (error) => {
              // console.log('fromLatLng', error);
              // console.error(error);
            }
          );
          Geocode.setLanguage('en');
          Geocode.setRegion('ZH-TW');
          Geocode.fromLatLng(lat, lng).then(
            (response) => {
              EnglishAddress(response);
            },
            (error) => {
              // console.log('inside error', error);
              // console.error(error);
            }
          );
        },
        (error) => {
          // console.log('error', error);
          // console.error(error);
        }
      );
    }
  };

  const _onClick = (event) => {
    const newPosition = {
      lat: event.lat,
      lng: event.lng,
    };
    setInputLat(event.lat);
    setInputLng(event.lng);
    setCurrentCenter(newPosition);
    setMyPosition(newPosition);
    Geocode.setApiKey(process.env.REACT_APP_MAP_KEY);
    Geocode.setLanguage('ZH-TW');
    Geocode.setRegion('ZH-TW');
    Geocode.fromLatLng(event.lat, event.lng).then(
      (response) => {
        ChineseAddress(response);
      },
      (error) => {
        // console.log('_onClick fromLatLng zh-tw', error);
        // console.error(error);
      }
    );
    Geocode.setLanguage('en');
    Geocode.setRegion('ZH-TW');
    Geocode.fromLatLng(event.lat, event.lng).then(
      (response) => {
        EnglishAddress(response);
      },
      (error) => {
        // console.log('_onClick fromLatLng en', error);
        // console.error(error);
      }
    );
  };

  const findlatlng = () => {
    if (mapApiLoaded) {
      Geocode.setApiKey(process.env.REACT_APP_MAP_KEY);
      Geocode.setLanguage('ZH-TW');
      Geocode.setRegion('ZH-TW');
      Geocode.fromLatLng(inputLat, inputLng).then(
        (response) => {
          ChineseAddress(response);
        },
        (error) => {
          // console.log(error);
          // console.error(error);
        }
      );
      Geocode.setLanguage('en');
      Geocode.setRegion('ZH-TW');
      Geocode.fromLatLng(inputLat, inputLng).then(
        (response) => {
          EnglishAddress(response);
        },
        (error) => {
          // console.log('findlatlng', { lat: inputLat, lng: inputLng }, error);
          // console.error(error);
        }
      );
    }
  };

  const [hide] = useState(true);

  const save = () => {
    props.sendAddress(
      myPosition.lat,
      myPosition.lng,
      zoomLv,
      addressZH,
      address
    );
    props.hide(hide);
  };

  const reset = () => {
    const resetMyPosition = {
      lat: props.myPosition.lat,
      lng: props.myPosition.lng,
    };
    setCurrentCenter(resetMyPosition);
    setMyPosition(resetMyPosition);
    setInputLat(props.myPosition.lat);
    setInputLng(props.myPosition.lng);
    setZoom(props.myPosition.z);
    setAddressZH(props.myPosition.tcAddress);
    setAddress(props.myPosition.enAddress);
    setOriginalAddressZH(props.myPosition.tcAddress);
    setOriginalAddress(props.myPosition.enAddress);
  };

  useEffect(() => {
    if (inputText) {
      findLocation();
    }
  }, [inputText]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    var delayTimer;
    clearTimeout(delayTimer);
    delayTimer = setTimeout(function () {
      const newPosition = { lat: inputLat, lng: inputLng };
      setMyPosition(newPosition);
      setCurrentCenter(newPosition);
      findlatlng();
    }, 1000);
  }, [inputLat, inputLng]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   if (props.myPosition.lat === null || props.myPosition.lng === null) {
  //     setMyPosition({
  //       lat: constants.MAP.LAT,
  //       lng: constants.MAP.LNG
  //     });
  //     setCurrentCenter({
  //       lat: constants.MAP.LAT,
  //       lng: constants.MAP.LNG
  //     });
  //     setInputLat(constants.MAP.LAT);
  //     setInputLng(constants.MAP.LNG);
  //   } else {
  //     setMyPosition({
  //       lat: props.myPosition.lat,
  //       lng: props.myPosition.lng
  //     });
  //     setCurrentCenter({
  //       lat: props.myPosition.lat,
  //       lng: props.myPosition.lng
  //     });
  //     setInputLat(props.myPosition.lat);
  //     setInputLng(props.myPosition.lng);
  //   }

  //   if (props.myPosition.z === null) {
  //     setZoom(constants.MAP.ZOOM);
  //   } else {
  //     setZoom(props.myPosition.z);
  //   }

  //   return () => { };
  // }, []);// eslint-disable-line react-hooks/exhaustive-deps
  // }, [props.myPosition.lat, props.myPosition.lng, props.myPosition.z]);

  return (
    <form noValidate autoComplete="off">
      <div className="form-wrapper_modal modal-wrapper-change">
        <div className="sub-title">
          {getString(lang, 'Map', 'addressEdit', theme)}
        </div>
        <div>
          <TempTextInput
            inputSetting={{
              className: 'input_modal',
              size: constants.SIZE.SMALL,
              value: addressZH,
              name: 'address_ZH',
              label: { resource: 'Map', key: 'chineseAddressSave' },
              multiline: true,
            }}
            handleChange={saveAddress_ZH}
          />
        </div>
        <div>
          {getString(lang, 'Map', 'chineseAddress', theme)} {originalAddressZH}
        </div>
        <br />
        <div>
          <TempTextInput
            inputSetting={{
              className: 'input_modal',
              size: constants.SIZE.SMALL,
              value: address,
              name: 'address_eng',
              label: { resource: 'Map', key: 'engAddressSave' },
              multiline: true,
            }}
            handleChange={saveAddress}
          />
        </div>
        <div>
          {getString(lang, 'Map', 'engAddress', theme)} {originalAddress}
        </div>
        <br />
        <div>
          <TempTextInput
            inputSetting={{
              className: 'input_modal',
              size: constants.SIZE.SMALL,
              value: inputLat,
              name: 'lat',
              type: constants.TYPE.NUMBER,
              label: { resource: 'Map', key: 'lat' },
            }}
            handleChange={handlelat}
          />
          <TempTextInput
            inputSetting={{
              className: 'input_modal',
              size: constants.SIZE.SMALL,
              value: inputLng,
              name: 'lng',
              type: constants.TYPE.NUMBER,
              label: { resource: 'Map', key: 'lng' },
            }}
            handleChange={handlelng}
          />
          <TempTextInput
            inputSetting={{
              className: 'input_modal',
              size: constants.SIZE.SMALL,
              value: zoomLv,
              name: 'zoom',
              type: constants.TYPE.NUMBER,
              min: 3,
              max: 22,
              label: { resource: 'Map', key: 'zoom' },
            }}
            handleChange={handlezoom}
          />
        </div>
        <br />
        <br />
        <div>
          <TextField
            className="input_modal"
            size={constants.SIZE.SMALL}
            multiline
            label={getString(lang, 'Map', 'search', theme)}
            variant={constants.STYLE.VARIANT.OUTLINED}
            inputRef={inputRef}
            type={constants.TYPE.TEXT}
            onChange={debounce(handleInput, 1000)}
          />
        </div>
        <br />
        <div className="mapsize">
          <GoogleMapReact
            distanceToMouse={() => {}}
            bootstrapURLKeys={{
              key: process.env.REACT_APP_MAP_KEY,
              libraries: ['places'],
            }}
            onClick={_onClick}
            center={currentCenter}
            onBoundsChange={handleCenterChange}
            zoom={zoomLv}
            onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
            yesIWantToUseGoogleMapApiInternals
          >
            <MyPositionMarker lat={myPosition.lat} lng={myPosition.lng} />
          </GoogleMapReact>
        </div>
        <div className="card">
          <TempBtn
            btnSetting={{
              className: 'get-push-card-cancel-btn',
              variant: constants.STYLE.VARIANT.CONTAINED,
              color: constants.STYLE.PRIMARY,
              onClick: save,
              icon: <SaveIcon />,
              label: { key: 'save' },
            }}
          />
          <TempBtn
            btnSetting={{
              className: 'get-push-card-cancel-btn margin-left-16',
              variant: constants.STYLE.VARIANT.CONTAINED,
              color: constants.STYLE.PRIMARY,
              onClick: reset,
              icon: <SettingsBackupRestoreIcon />,
              label: { resource: 'Map', key: 'reset' },
            }}
          />
          <TempBtn
            btnSetting={{
              className: 'get-push-card-cancel-btn margin-left-16',
              variant: constants.STYLE.VARIANT.CONTAINED,
              color: constants.STYLE.PRIMARY,
              onClick: () => props.hide(hide),
              icon: <CloseIcon />,
              label: { key: 'close' },
            }}
          />
        </div>
      </div>
    </form>
  );
};

GoogleMapPop.defaultProps = {
  myPosition: {
    lat: 22.28,
    lng: 114.15,
  },
  zoom: 17,
};

const MapModal = ({ isShowing, hide, myPosition, sendAddress }) =>
  isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="hidden">
            {(GoogleMapPop.defaultProps.myPosition.lat = myPosition.lat)}
            {(GoogleMapPop.defaultProps.myPosition.lng = myPosition.lng)}
            {(GoogleMapPop.defaultProps.zoom = myPosition.z)}
          </div>
          <div className="modal-overlay" />
          <div
            className="modal-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className="modal">
              <div className="all">
                <GoogleMapPop
                  sendAddress={sendAddress}
                  hide={hide}
                  isShowing={isShowing}
                  myPosition={myPosition}
                />
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;

export default MapModal;
