import { BANNER_ACTION } from '../../Constants/constants';

export function handleActionParameters(e, savedBanner) {
  let actionParameters = "";
  switch (savedBanner.actionType) {
    case BANNER_ACTION.WEB_VIEW:
    case BANNER_ACTION.VIDEO:
      actionParameters = JSON.stringify({ url: `${process.env.REACT_APP_ACTION_LINK_START}web?action=${(e.url)}${e.type ? "&type=" + e.type : ""}${e.title ? "&title=" + e.title : ""}${e.from ? "&from=" + e.from : ""}` });
      break;
    case BANNER_ACTION.MERCHANT_WEB_VIEW:
      actionParameters = JSON.stringify({ url: `${(e.url)}${(e.type||e.title||e.from)?"?":""}${e.type ? "&type=" + e.type : ""}${e.title ? "&title=" + e.title : ""}${e.from ? "&from=" + e.from : ""}` });
      break;
    case BANNER_ACTION.MERCHANT_LINK:
      actionParameters = JSON.stringify({ url: `${(e.url)}${(e.from)?"?":""}${e.from ? "&from=" + e.from : ""}` });
      break;
    case BANNER_ACTION.SEARCH:
      actionParameters = JSON.stringify({ url: `${process.env.REACT_APP_ACTION_LINK_START}search?${e.name ? "name=" + e.name : ""}${e.name && e.query ? "&query=" + e.query : !e.name && e.query ? "query=" + e.query : ""}${e.from ? "&from=" + e.from : ""}` });
      break;
    case BANNER_ACTION.LINK:
      actionParameters = JSON.stringify({ url: `${process.env.REACT_APP_ACTION_LINK_START}link?action=${(e.url)}${e.from ? "&from=" + e.from : ""}` });
      break;
    case BANNER_ACTION.PIXEL:
      actionParameters = JSON.stringify({ url: `${process.env.REACT_APP_ACTION_LINK_START}pixel?action=${(e.url)}${e.name ? "&name=" + e.name : ""}${e.haveRef || e.haveRef === false ? "&haveRef=" + e.haveRef : ""}${e.from ? "&from=" + e.from : ""}` });
      break;
    case BANNER_ACTION.PRODUCT_CATEGORY:
      actionParameters = JSON.stringify({ url: `${process.env.REACT_APP_ACTION_LINK_START}product?categoryCode=${e.url}${e.from ? "&from=" + e.from : ""}` });
      break;
    case BANNER_ACTION.E_COUPON_CATEGORY:
      actionParameters = JSON.stringify({ url: `${process.env.REACT_APP_ACTION_LINK_START}e-coupon?categoryCode=${e.url}${e.from ? "&from=" + e.from : ""}` });
      break;
    // case BANNER_ACTION.PRODUCT:
    //   actionParameters = JSON.stringify({ url: `${process.env.REACT_APP_ACTION_LINK_START}product?${e.productParent?e.productParent==="BRAND"?"brandCode=":e.productParent==="CATEGORY"?"categoryCode=":"":""}${e.code?e.code+"":""}${e.productParent?"&":""}productCode=${e.url}${e.from ? "&from=" + e.from : ""}` });
    //   break;
    case BANNER_ACTION.PRODUCT:
      actionParameters = JSON.stringify({ url: `${process.env.REACT_APP_ACTION_LINK_START}product?productCode=${e.url}${e.productParent?e.productParent==="BRAND"?"&brandCode=":e.productParent==="CATEGORY"?"&categoryCode=":"":""}${e.productParent==="BRAND"&&e.brandCode?e.brandCode+"":""}${e.productParent==="CATEGORY"&&e.categoryCode?e.categoryCode+"":""}${e.from ? "&from=" + e.from : ""}` });
      break;
    case BANNER_ACTION.E_COUPON: 
      actionParameters = JSON.stringify({ url: `${process.env.REACT_APP_ACTION_LINK_START}e-coupon?ecouponCode=${e.url}${e.productParent?e.productParent==="BRAND"?"&brandCode=":e.productParent==="CATEGORY"?"&categoryCode=":"":""}${e.productParent==="BRAND"&&e.brandCode?e.brandCode+"":""}${e.productParent==="CATEGORY"&&e.categoryCode?e.categoryCode+"":""}${e.from ? "&from=" + e.from : ""}` });
      break;
    case BANNER_ACTION.PRODUCT_BRAND:
      actionParameters = JSON.stringify({ url: `${process.env.REACT_APP_ACTION_LINK_START}product?brandCode=${e.url}${e.from ? "&from=" + e.from : ""}` });
      break;
    case BANNER_ACTION.E_COUPON_BRAND:
      actionParameters = JSON.stringify({ url: `${process.env.REACT_APP_ACTION_LINK_START}e-coupon?brandCode=${e.url}${e.from ? "&from=" + e.from : ""}` });
      break;
    // case BANNER_ACTION.CATEGORY_PRODUCT:
    // case BANNER_ACTION.CATEGORY_PRODUCT_MAP:
    //   actionParameters = JSON.stringify({ url: `${process.env.REACT_APP_ACTION_LINK_START}product?categoryCode=${e.url}&productCode=${e.catProductCode ? e.catProductCode : ""}${e.from ? "&from=" + e.from : ""}` });
    //   break;
    // case BANNER_ACTION.BRAND_PRODUCT:
    // case BANNER_ACTION.BRAND_PRODUCT_MAP:
    //   actionParameters = JSON.stringify({ url: `${process.env.REACT_APP_ACTION_LINK_START}product?brandCode=${e.url}&productCode=${e.brandProductCode ? e.brandProductCode : ""}${e.from ? "&from=" + e.from : ""}` });
    //   break;
    case BANNER_ACTION.FOLDER:
      actionParameters = JSON.stringify({ url: `${process.env.REACT_APP_ACTION_LINK_START}folder?folderCode=${e.url}${e.folderType ? "&folderType=" + e.folderType : ""}${e.from ? "&from=" + e.from : ""}` });
      break;
    case BANNER_ACTION.RESTAURANT:
      actionParameters = JSON.stringify({ url: `${process.env.REACT_APP_ACTION_LINK_START}rest?restUrlId=${e.url}${e.from ? "&from=" + e.from : ""}${e.action ? "&action=" + e.action : ""}` });
      break;
    default:
      actionParameters = "";
      break;
  }
  return actionParameters;
}