import { QUEUE_TRANSFER_PROFILE } from './types'
import _ from 'lodash'
import { sify } from 'chinese-conv'

export const setQueueTransferProfileDefault = () => {
  return {
    type: QUEUE_TRANSFER_PROFILE.SET_QUEUE_TRANSFER_PROFILE_DEFAULT,
  }
}

export const setQueueTransferProfile = (queueTransferProfile) => {
  return {
    type: QUEUE_TRANSFER_PROFILE.SET_QUEUE_TRANSFER_PROFILE,
    queueTransferProfile: queueTransferProfile,
  }
}

export const setQueueTransferProfileValue = (fieldName, originalObj, key, value) => {
  const queueTransferProfile = _.cloneDeep(originalObj)
  switch (fieldName) {
    case 'tcName':
      queueTransferProfile.tcName = value
      queueTransferProfile.scName = queueTransferProfile.scNameDisable ? sify(value) : queueTransferProfile.scName
      break
    case 'scNameDisable':
      queueTransferProfile.scNameDisable = value
      if (value) {
        queueTransferProfile.scName = !queueTransferProfile.tcName ? queueTransferProfile.scName : sify(queueTransferProfile.tcName)
      }
      break
    default:
      queueTransferProfile[fieldName] = value
  }
  return {
    type: QUEUE_TRANSFER_PROFILE.SET_QUEUE_TRANSFER_PROFILE_VALUE,
    queueTransferProfile: queueTransferProfile,
  }
}
