import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector, useStore } from "react-redux";
import _ from "lodash";
import moment from 'moment';

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Drawer } from "@mui/material";
import { makeStyles } from '@mui/styles';

import { constants, QUEUE_TEMPLATE_MAP_TYPE } from '../../Constants/constants';
import { getOne, put } from "../../helper/baseFetch";
import { setQueueTemplateMap } from "../../actions/queueTemplateMapAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempAutoSelectInput } from "../../Component/Input/TempAutoSelectInput";
import { TempBtn } from "../../Component/Input/TempBtn"
import { QueueTemplateList } from "../QueueTemplate/QueueTemplateList";
import QueueTemplateMapValidation from "../../validations/queueTemplateMapValidation";
import { queueTemplateMapValidationConstants } from '../../Constants/validationConstants';
import { DialogWrapper } from "../../Component/DialogWrapper";
import useRestaurant from "../../hooks/Restaurant/useRestaurant";

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const QueueTemplateMapEdit = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const params = useParams();
  const history = useHistory();
  const [ready, setReady] = useState(false);
  const [clear, setClear] = useState(true);
  const savedQueueTemplateMap = useSelector(state => state.queueTemplateMap.ui.queueTemplateMap);
  const [showTemplateCodePanel, setShowTemplateCodePanel] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const helperTextStylesClasses = helperTextStyles();
  const [, setErrorVersion] = useState(QueueTemplateMapValidation.getVersion());

  const resource = "QueueTemplateMap";
  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.QUEUE_TEMPLATE_MAP_TAB.INFO
    }
  ]

  const content = [
    {
      tab: <>
        <TempTextInput
          inputSetting={{
            className: "edit-create-input",
            size: constants.SIZE.SMALL,
            label: { resource: resource, key: "description" },
            value: savedQueueTemplateMap.description,
            name: "description",
            multiline: true,
          }}
          handleChange={onChangeInput}
        />
        <div>
          <TempTextInput
            inputSetting={{
              className: "edit-create-input",
              variant: constants.STYLE.VARIANT.STANDARD,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "type" },
              value: savedQueueTemplateMap.type,
              name: "type",
              disabled: true,
              helperTextIcon: true,
              helperText: QueueTemplateMapValidation.getErrorMessages(queueTemplateMapValidationConstants.KEY_TYPE, savedQueueTemplateMap.id).map((error) => { return error }),
              FormHelperTextProps: { className: helperTextStylesClasses.root },
            }}
            handleChange={onChangeInput}
          />
          {savedQueueTemplateMap.type === QUEUE_TEMPLATE_MAP_TYPE.DAY_OF_WEEK &&
            <TempAutoSelectInput
              inputSetting={{
                className: "edit-create-input",
                size: constants.SIZE.SMALL,
                label: { resource: resource, key: "dayOfWeek" },
                value: savedQueueTemplateMap.dayOfWeek,
                type: constants.TYPE.SELECT,
                selectValue: constants.CHOICES.QUEUE_TEMPLATE_MAP.DAY_OF_WEEK,
                disableClearable: true,
                name: "dayOfWeek",
                helperTextIcon: true,
                helperText: QueueTemplateMapValidation.getErrorMessages(queueTemplateMapValidationConstants.KEY_DAY_OF_WEEK, savedQueueTemplateMap.id).map((error) => { return error }),
                FormHelperTextProps: { className: helperTextStylesClasses.root },
              }}
              handleChange={onChangeInput}
            />
          }
          {savedQueueTemplateMap.type === QUEUE_TEMPLATE_MAP_TYPE.DATE_RANGE &&
            <>
              <TempTextInput
                inputSetting={{
                  className: "edit-create-input",
                  label: { resource: resource, key: "startDate" },
                  value: savedQueueTemplateMap.startDate ? moment(savedQueueTemplateMap.startDate).format('YYYY-MM-DD') : "",
                  name: "startDate",
                  type: constants.TYPE.DATE,
                  size: constants.SIZE.SMALL,
                  helperTextIcon: true,
                  helperText: QueueTemplateMapValidation.getErrorMessages(queueTemplateMapValidationConstants.KEY_START_DATE, savedQueueTemplateMap.id).map((error) => { return error }),
                  FormHelperTextProps: { className: helperTextStylesClasses.root },
                }}
                handleChange={onChangeInput}
              />
              <TempTextInput
                inputSetting={{
                  className: "edit-create-input",
                  label: { resource: resource, key: "endDate" },
                  value: savedQueueTemplateMap.endDate ? moment(savedQueueTemplateMap.endDate).format('YYYY-MM-DD') : "",
                  name: "endDate",
                  type: constants.TYPE.DATE,
                  size: constants.SIZE.SMALL,
                  helperTextIcon: true,
                  helperText: QueueTemplateMapValidation.getErrorMessages(queueTemplateMapValidationConstants.KEY_END_DATE, savedQueueTemplateMap.id).map((error) => { return error }),
                  FormHelperTextProps: { className: helperTextStylesClasses.root },
                }}
                handleChange={onChangeInput}
              />
            </>
          }
        </div>
        <div>
          <TempTextInput
            inputSetting={{
              className: "edit-create-input",
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "templateCode" },
              value: savedQueueTemplateMap.templateCode,
              onClickEndAdornmentIconButton: () => { handleTemplateCodeClick(); },
              name: "templateCode",
              disabled: true,
              multiline: true,
              helperTextIcon: true,
              helperText: QueueTemplateMapValidation.getErrorMessages(queueTemplateMapValidationConstants.KEY_TEMPLATE_CODE, savedQueueTemplateMap.id).map((error) => { return error }),
              FormHelperTextProps: { className: helperTextStylesClasses.root },
            }}
            handleChange={onChangeInput}
          />
          <TempTextInput
            inputSetting={{
              className: "edit-create-input",
              variant: constants.STYLE.VARIANT.STANDARD,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "templateDescription" },
              value: savedQueueTemplateMap.templateDescription,
              name: "templateDescription",
              disabled: true,
              multiline: true,
            }}
            handleChange={onChangeInput}
          />
        </div>
      </>,
      btn: true,
    },
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />,
      disabled: !savedQueueTemplateMap.restUrlId,
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  const onClickListRow = (data) => {
    onChangeInput("templateCode", data)
    setShowTemplateCodePanel(false);
  }

  const handleTemplateCodeClick = () => {
    setShowTemplateCodePanel(true);
  };

  const handleTemplateCodeCloseClick = () => {
    setShowTemplateCodePanel(false);
  };

  function onChangeInput(name, e) {
    const tempQueueTemplateMap = _.cloneDeep(savedQueueTemplateMap);
    switch (name) {
      case "startDate":
      case "endDate":
        tempQueueTemplateMap[`${name}`] = e ? Number(moment(e).format('x')) : null;
        break;
      case "type":
        tempQueueTemplateMap[`${name}`] = e;
        tempQueueTemplateMap["dayOfWeek"] = null;
        tempQueueTemplateMap["startDate"] = null;
        tempQueueTemplateMap["endDate"] = null;
        break;
      case "templateCode":
        tempQueueTemplateMap[`${name}`] = e.templateCode;
        tempQueueTemplateMap["templateDescription"] = e.description;
        break;
      default:
        tempQueueTemplateMap[`${name}`] = e;
    }
    dispatch(setQueueTemplateMap(tempQueueTemplateMap));
  }

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  }

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT + "/" + savedQueueTemplateMap.restUrlId + "/" + constants.RESTAURANT_TAB.QUEUE + "/" + constants.RESTAURANT_QUEUE_SUB_TAB.QUEUE_TEMPLATE_MAP);
  };

  function onClickSave() {
    let tempsavedQueueTemplateMap = _.cloneDeep(savedQueueTemplateMap);
    tempsavedQueueTemplateMap.restUrlId = savedQueueTemplateMap.restUrlId;
    QueueTemplateMapValidation.validateTemplate([tempsavedQueueTemplateMap]);
    if (QueueTemplateMapValidation.isValid()) {
      put(constants.PATH.SHOP.QUEUE.QUEUE_TEMPLATE_MAP + "/" + params.id, tempsavedQueueTemplateMap, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToEdit,
          dialogContentText: { key: "editSuccess" },
          dialogActions: [
            { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      QueueTemplateMapValidation.resetErrorMessages();
    } else {
      setErrorVersion(QueueTemplateMapValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    if (clear === true) {
      getOne(constants.PATH.SHOP.QUEUE.QUEUE_TEMPLATE_MAP + "/" + params.id, undefined, (payload) => {
        dispatch(setQueueTemplateMap(payload));
        setReady(true)
        setClear(false)
      }, undefined, store);
    }
    QueueTemplateMapValidation.resetErrorMessages();
  }, [clear]); // eslint-disable-line react-hooks/exhaustive-deps

  const { getRestaurantIdAndName } = useRestaurant(savedQueueTemplateMap.restUrlId)
  const mapCode = getRestaurantIdAndName()

  return (
    ready &&
    <>
      <EditCreateDisplay
        code={savedQueueTemplateMap && savedQueueTemplateMap.id ? savedQueueTemplateMap.id : ""}
        resource={resource}
        tabs={tabs}
        content={content}
        bottomBtns={bottomBtns}
        mapName={{ resource: "restaurant", key: "restUrlId" }}
        mapCode={mapCode}
        display="edit"
      />
      <Drawer
        classes={{ paper: "drawer-margin-left" }}
        anchor="right"
        open={showTemplateCodePanel}
        onClose={handleTemplateCodeCloseClick}
      >
        <QueueTemplateList
          editBtn={false}
          createBtn={false}
          btnList={false}
          newFilter={{id: savedQueueTemplateMap.restUrlId}}
          onClickListRow={(item) => onClickListRow(item)}
          dialogFooterBtn={<>
            <TempBtn btnSetting={{
              variant: constants.STYLE.VARIANT.CONTAINED,
              color: constants.STYLE.PRIMARY,
              onClick: handleTemplateCodeCloseClick,
              icon: <ClearIcon />,
              label: { key: "close" },
            }} />
          </>
          } />
      </Drawer>
      {dialog && dialog.open &&
        <DialogWrapper dialogInfo={dialog} />
      }
    </>
  )
};