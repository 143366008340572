import { useState, useEffect } from "react";
import { useStore } from "react-redux";
import _ from "lodash";

import { Card } from '@mui/material';

import { ListDisplay } from '../../Component/ListDisplay/ListDisplay';

import { constants } from '../../Constants/constants';
import { getList } from "../../helper/baseFetch";
import { generateTableCellToMultiLanguageName } from "../../helper/util";

export const BrandGroupList = ({ onClickListRow, editBtn, createBtn }) => {
  const store = useStore();
  const [BrandGroupList, setBrandGroupList] = useState([]);
  const [ready, setReady] = useState(false);
  const resource = "BrandGroup";

  const BrandGroupTitleList = [
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "groupId",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "group_name",
      sort: false,
      minSize: true,
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "status",
      sort: true
    }
  ];
  const listFilters = [
    {
      className: "filter-input",
      label: { resource: resource, key: "groupId" },
      clear: true,
      value: '',
      name: "groupId",
      size: constants.SIZE.SMALL
    },
    {
      className: "filter-input",
      label: { resource: resource, key: "group_name" },
      clear: true,
      value: '',
      name: "name",
      size: constants.SIZE.SMALL
    },
    {
      className: "filter-input",
      label: { resource: resource, key: "status" },
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.BRAND_GROUP.STATUS,
      name: "status",
      size: constants.SIZE.SMALL
    }
  ];

  const [param, setParam] = useState({
    path: "",
    page: 0,
    rowsPerPage: 10,
    sort: 'updateTimestamp',
    direction: 'DESC',
    listFilters: listFilters,
    count: 0,
  })

  function onChangeParam(value) {
    value.count = value.count + 1;
    setParam(value);
  }

  function onChangeListFilters(list) {
    let tempParam = _.cloneDeep(param);
    tempParam.listFilters = list;
    tempParam.page = 0;
    onChangeParam(tempParam);
  }

  function onChangeListSort(sortItem) {
    let tempParam = _.cloneDeep(param);
    tempParam.sort = sortItem;
    tempParam.direction = tempParam.direction === "DESC" ? "ASC" : "DESC";
    onChangeParam(tempParam);
  }

  function onChangeListPage(page) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = page;
    onChangeParam(tempParam);
  }

  function onChangeListRowsPerPage(rowsPerPage) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = 0;
    tempParam.rowsPerPage = rowsPerPage;
    onChangeParam(tempParam);
  }

  useEffect(() => {
    let tempParam = _.cloneDeep(param);
    const filter = {};
    tempParam.listFilters.forEach((filterItem) => {
      if (filterItem.value !== '' && filterItem.value !== undefined) {
        filter[filterItem.name] = filterItem.value;
      }
    })

    getList(constants.PATH.SHOP.BRAND_GROUP, { sort: { field: tempParam.sort, order: tempParam.direction }, filter: filter, pagination: { page: tempParam.page + 1, perPage: tempParam.rowsPerPage } }, (payload) => {
      payload.map((item) => {
        item.group_name = generateTableCellToMultiLanguageName(item.tcName, item.enName, item.scName);
        return item
      });
      setBrandGroupList(payload);
      setReady(true)
    }, undefined, store);

  }, [param.count]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <Card className="card-wrapper overflow">
      <ListDisplay
        onClickListRow={onClickListRow}
        page={param.page}
        rowsPerPage={param.rowsPerPage}
        onChangeListSort={onChangeListSort}
        resource={resource}
        dataList={BrandGroupList}
        sort={param.sort}
        direction={param.direction}
        listTitles={BrandGroupTitleList}
        listFilters={param.listFilters}
        onChangeListPage={onChangeListPage}
        onChangeListRowsPerPage={onChangeListRowsPerPage}
        onChangeListFilters={onChangeListFilters}
        editBtn={editBtn}
        createBtn={createBtn}
        redirectPath={"/" + constants.PATH.SHOP.BRAND_GROUP + "/"}
      />
    </Card>
  )
};