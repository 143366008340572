// import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useHistory } from 'react-router-dom'

import AddIcon from '@mui/icons-material/Add'
import Card from '@mui/material/Card'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import EditIcon from '@mui/icons-material/Edit'
import CancelIcon from '@mui/icons-material/Cancel'

import { constants, LANDING_ACTION } from '../../../../Constants/constants'
import { setLandingSection } from '../../../../actions/landingSectionAction'
import { setLoading } from '../../../../actions/systemAction'
import { TempBtn } from '../../../../Component/Input/TempBtn'
import landingSectionValidation from '../../../../validations/landingSectionValidation'
import { landingSectionValidationConstants } from '../../../../Constants/validationConstants'
import { renderSection } from './landingSectionUtil'
import Switch from '@mui/material/Switch'
import { getString } from '../../../../helper/util'
import FormControlLabel from '@mui/material/FormControlLabel'

const resource = 'LandingSection'

const DragnDropList = ({ setCurrentEdit, currentEdit }) => {
  const dispatch = useDispatch()
  const savedLandingSection = useSelector((state) => state.landingSection.ui.landingSection)
  const history = useHistory()

  function handleOnDragEnd(result) {
    dispatch(setLoading(true))
    let tempList = _.cloneDeep(savedLandingSection)
    if (!result.destination) return
    const [reorderedItem] = tempList.splice(result.source.index, 1)
    tempList.splice(result.destination.index, 0, reorderedItem)
    dispatch(setLandingSection(tempList))
    dispatch(setLoading(false))
  }

  const handleClick = (item, index) => {
    // setCurrentEdit(item)
    history.push(`/${constants.PATH.LANDING.LANDING_SECTION}/${item.id}/0`)
  }

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="templateValue">
        {(provided) => {
          return (
            <div {...provided.droppableProps} ref={provided.innerRef} style={{ overflowY: 'scroll', maxHeight: '80vh' }}>
              {savedLandingSection &&
                savedLandingSection.length > 0 &&
                savedLandingSection.map((item, index) => {
                  const id = item.id ? item.id : item.customId
                  return (
                    <Draggable key={item.id} draggableId={'' + item.id} index={index}>
                      {(provided) => {
                        return (
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <Card key={index} className="landing-image-list image-card-background">
                              {landingSectionValidation.getErrorMessages(`${id}-${landingSectionValidationConstants.KEY_ERROR}`) && (
                                <div className="error-message">
                                  <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                                  {landingSectionValidation.getErrorMessages(`${id}-${landingSectionValidationConstants.KEY_ERROR}`)}
                                </div>
                              )}
                              <div className="landing-image-list-content">
                                <SwapVertIcon color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.LARGE} />

                                <div style={{ width: '100%' }}>{renderSection(item)}</div>
                                {item.action !== LANDING_ACTION.HISTORICAL_SHOP &&
                                  // item.action !== LANDING_ACTION.ROTATING_BANNER &&
                                  item.action !== LANDING_ACTION.SEARCH_SUGGESTION &&
                                  item.sectionType !== 'ADS' && (
                                    <div className="landing-image-btn">
                                      {/* <TempBtn
                                        btnSetting={{
                                          onClick: () => handleDeleteItem(item, id),
                                          color: constants.STYLE.PRIMARY,
                                          label: { key: 'deleteBtn' },
                                          icon: <DeleteIcon />,
                                        }}
                                      /> */}
                                      <TempBtn
                                        btnSetting={{
                                          onClick: () => handleClick(item, index),
                                          color: constants.STYLE.PRIMARY,
                                          label: { key: 'edit' },
                                          icon: <EditIcon />,
                                        }}
                                      />
                                    </div>
                                  )}
                              </div>
                            </Card>
                          </div>
                        )
                      }}
                    </Draggable>
                  )
                })}
              {provided.placeholder}
            </div>
          )
        }}
      </Droppable>
    </DragDropContext>
  )
}

export const LandingSectionPreview = ({ setCurrentEdit, currentEdit, onlyPublishing, setOnlyPublishing, onClickClear }) => {
  const history = useHistory()
  const lang = useSelector((state) => state.lang.ui.lang)
  const theme = useSelector((state) => state.theme.ui.theme)

  const handleAddItem = () => {
    history.push(`/${constants.PATH.LANDING.LANDING_SECTION}/create/0`)
  }

  const handleTogglePublishing = () => {
    setOnlyPublishing((prev) => !prev)
    onClickClear()
  }

  return (
    <>
      <div className="landing-section-preview-wrapper">
        <div className="title">
          <FormControlLabel
            control={<Switch checked={onlyPublishing} onChange={handleTogglePublishing} />}
            label={getString(lang, resource, 'publishing', theme)}
          />
          <TempBtn
            btnSetting={{
              color: constants.STYLE.PRIMARY,
              className: 'brand-btn margin-16-4',
              label: { key: 'create' },
              //disabled: disableInput,
              onClick: handleAddItem,
              icon: <AddIcon />,
            }}
          />
        </div>
        <DragnDropList setCurrentEdit={setCurrentEdit} currentEdit={currentEdit} />
      </div>
    </>
  )
}
