import { MERCHANT_DEVICE } from './types';
import _ from "lodash";

export const setMerchantDeviceDefault = () => {
    return {
        type: MERCHANT_DEVICE.SET_MERCHANT_DEVICE_DEFAULT,
    }
}

export const setMerchantDevice = (merchantDevice) => {
    return {
        type: MERCHANT_DEVICE.SET_MERCHANT_DEVICE,
        merchantDevice: merchantDevice
    }
}

export const setMerchantDeviceValue = (draftType, orignalProduct, key, value, index) => {
    const merchantDevice = _.cloneDeep(orignalProduct);
    merchantDevice[draftType] = value;
    
    return {
        type: MERCHANT_DEVICE.SET_MERCHANT_DEVICE_VALUE,
        merchantDevice: merchantDevice
    }
}
