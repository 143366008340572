import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useStore } from "react-redux";
import { sify } from "chinese-conv";
import _ from "lodash";

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import { makeStyles } from '@mui/styles';

import { constants } from '../../Constants/constants';
import { getOne, put } from "../../helper/baseFetch";
import { getScDisabled } from "../../helper/util";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempCustomCheckBox } from "../../Component/Custom/TempCustomCheckBox";
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempAutoSelectInput } from "../../Component/Input/TempAutoSelectInput";
import { DialogWrapper } from "../../Component/DialogWrapper";
import QueueRejectReasonValidation from "../../validations/queueRejectReasonValidation";
import { rejectReasonValidationConstants } from '../../Constants/validationConstants';
import useRestaurant from "../../hooks/Restaurant/useRestaurant";

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const QueueRejectReasonEdit = () => {
  const params = useParams();
  const store = useStore();
  const history = useHistory();
  const [ready, setReady] = useState(false);
  const [clear, setClear] = useState(true);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const resource = "QueueRejectReason";
  const [rejectReason, setRejectReason] = useState({});
  const helperTextStylesClasses = helperTextStyles();
  const [, setErrorVersion] = useState(QueueRejectReasonValidation.getVersion());

  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.QUEUE_REJECT_REASON_EDIT_TAB.INFO
    },
  ]
  const tempQueueRejectReasonEditList = [
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "reasonCode" },
      value: rejectReason.reasonCode,
      name: "reasonCode",
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "status" },
      value: rejectReason.status,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.QUEUE_REJECT_REASON.STATUS,
      name: "status",
      isEndOfLine: true,
      disableClearable: true,
      required: true,
      helperTextIcon: true,
      helperText: QueueRejectReasonValidation.getErrorMessages(rejectReasonValidationConstants.KEY_STATUS),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "enReason" },
      value: rejectReason.enReason,
      name: "enReason",
      custom: true,
      customName: { resource: resource, key: "reasonCustomize" },
      customValue: rejectReason.scReasonDisable,
      isEndOfLine: false,
      multiline: true,
      helperTextIcon: true,
      helperText: QueueRejectReasonValidation.getErrorMessages(rejectReasonValidationConstants.KEY_EN_REASON),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "tcReason" },
      value: rejectReason.tcReason,
      name: "tcReason",
      translateName: rejectReason.scReasonDisable ? ["scReason"] : null,
      isEndOfLine: false,
      multiline: true,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "scReason" },
      value: rejectReason.scReason,
      name: "scReason",
      isEndOfLine: true,
      disabled: rejectReason.scReasonDisable,
      multiline: true,
    },
  ]
  const content = [
    {
      tab:
        <>
          {QueueRejectReasonValidation.getErrorMessages(rejectReasonValidationConstants.KEY_REST_URL_ID) &&
            QueueRejectReasonValidation.getErrorMessages(rejectReasonValidationConstants.KEY_REST_URL_ID).length > 0 &&
            <div className="error-message">
              <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
              {QueueRejectReasonValidation.getErrorMessages(rejectReasonValidationConstants.KEY_REST_URL_ID)}
            </div>
          }
          {tempQueueRejectReasonEditList.map((inputSetting, index) => {
            let content;
            switch (inputSetting.type) {
              case constants.TYPE.SELECT:
                content =
                  <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
                break;
              default:
                content =
                  <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
            }
            return <div className="edit-display-wrapper" key={index}>
              {inputSetting.custom && <TempCustomCheckBox value={inputSetting.customValue} className="customize-btn" customName={inputSetting.customName} onSelectCheckbox={onSelectCheckbox} />}
              {content}
              {inputSetting.isEndOfLine && <div></div>}
            </div>
          })}
        </>,
      btn: true
    }
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  function onSelectCheckbox(value, customName) {
    switch (customName.key) {
      case "reasonCustomize":
        if(value) {
          setRejectReason(prev=>({...prev, scReason: prev.tcReason?sify(prev.tcReason):prev.tcReason,scReasonDisable: !!value}))
        } else {
          setRejectReason(prev=>({...prev, scReasonDisable: !!value}))
        }
        break;
      default:
    }
  }

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    onClickClear();
  };
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT + "/" + rejectReason.restUrlId + "/" + constants.RESTAURANT_TAB.QUEUE + "/" + constants.RESTAURANT_QUEUE_SUB_TAB.QUEUE_REJECT_REASON);
  };

  function onChangeInput(name, e) {
    let changedValue = e ? e : null;
    let tempRejectReason = _.cloneDeep(rejectReason);
    if (name === "tcReason" && tempRejectReason.scReasonDisable === true) {
      tempRejectReason.tcReason = changedValue;
      tempRejectReason.scReason =  changedValue? sify(changedValue): changedValue;
    } else {
      tempRejectReason[`${name}`] = e
    }

    setRejectReason(tempRejectReason);
  }

  function onClickSave() {
    QueueRejectReasonValidation.validateEditQueueRejectReason(rejectReason);
    if (QueueRejectReasonValidation.isValid()) {
      put(constants.PATH.SHOP.QUEUE.QUEUE_REJECT_REASON + "/" + params.id, rejectReason, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToEdit,
          dialogContentText: { key: "editSuccess" },
          dialogActions: [
            { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      QueueRejectReasonValidation.resetErrorMessages();
    } else {
      setErrorVersion(QueueRejectReasonValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    QueueRejectReasonValidation.resetErrorMessages();
    if (clear === true) {
      getOne(constants.PATH.SHOP.QUEUE.QUEUE_REJECT_REASON + "/" + params.id, undefined, (payload) => {
        payload.scReasonDisable = getScDisabled(payload.tcReason, payload.scReason);
        setRejectReason(payload)
        setReady(true)
        setClear(false)
      }, undefined, store);
    }
  }, [clear]); // eslint-disable-line react-hooks/exhaustive-deps

  const { getRestaurantIdAndName } = useRestaurant(rejectReason.restUrlId)
  const mapCode = getRestaurantIdAndName()

  return (
    ready &&
    <>
      <EditCreateDisplay
        code={rejectReason && rejectReason.id ? rejectReason.id : ""}
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        mapName={{ resource: "restaurant", key: "restUrlId" }}
        mapCode={mapCode}
        display="edit"
      />
      {dialog && dialog.open &&
        <DialogWrapper dialogInfo={dialog} />
      }
    </>
  )
};