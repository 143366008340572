import _ from "lodash";
import { BRAND_APPROVE } from '../actions/types';
import { constants } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        brandApprove: _.cloneDeep(constants.BRAND_APPROVE_INPUT)
    }
}

const brandApproveReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case BRAND_APPROVE.SET_BRAND_APPROVE_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case BRAND_APPROVE.SET_BRAND_APPROVE:
        case BRAND_APPROVE.SET_BRAND_APPROVE_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    brandApprove: action.brandApprove
                }
            })
        default:
            return state
    }
}

export default brandApproveReducer