import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector, useStore } from "react-redux";
import _ from "lodash";
import { sify } from "chinese-conv";

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@mui/styles';

import { constants } from '../../Constants/constants';
import { getOne, put } from "../../helper/baseFetch";
import { getScDisabled } from "../../helper/util";
import { setEmailTemplate, setEmailTemplateValue } from "../../actions/emailTemplateAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempBtn } from "../../Component/Input/TempBtn"
import { TempAutoSelectInput } from "../../Component/Input/TempAutoSelectInput";
import { TempCustomCheckBox } from "../../Component/Custom/TempCustomCheckBox";
import { DialogWrapper } from "../../Component/DialogWrapper";
import EmailTemplateValidation from "../../validations/emailTemplateValidation";
import { emailTemplateValidationConstants } from '../../Constants/validationConstants';

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const EmailTemplateEdit = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const params = useParams();
  const history = useHistory();
  const [ready, setReady] = useState(false);
  const [clear, setClear] = useState(true);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const resource = "EmailTemplate";
  const savedEmailTemplate = useSelector(state => state.emailTemplate.ui.emailTemplate)
  const [emailTemplateInputList, setEmailTemplateInputList] = useState(_.cloneDeep(savedEmailTemplate));
  const helperTextStylesClasses = helperTextStyles();
  const [, setErrorVersion] = useState(EmailTemplateValidation.getVersion());

  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.EMAIL_TEMPLATE_EDIT_TAB.INFO
    },
  ]
  const tempEmailTemplateEditList = [
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "restUrlId" },
      value: savedEmailTemplate.restUrlId,
      name: "restUrlId",
      isEndOfLine: false,
      disabled: true,
      required: true,
      helperTextIcon: true,
      helperText: EmailTemplateValidation.getErrorMessages(emailTemplateValidationConstants.KEY_REST_URL_ID),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "type" },
      value: savedEmailTemplate.type,
      // type: constants.TYPE.SELECT,
      // selectValue: constants.CHOICES.EMAIL_TEMPLATE.TYPE,
      name: "type",
      isEndOfLine: false,
      disabled: true,
      required: true,
      helperTextIcon: true,
      helperText: EmailTemplateValidation.getErrorMessages(emailTemplateValidationConstants.KEY_TYPE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "status" },
      value: savedEmailTemplate.status,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.EMAIL_TEMPLATE.STATUS,
      name: "status",
      isEndOfLine: true,
      disableClearable: true,
      required: true,
      helperTextIcon: true,
      helperText: EmailTemplateValidation.getErrorMessages(emailTemplateValidationConstants.KEY_STATUS),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "receiver" },
      value:
        <>
          <div>
            <TempBtn btnSetting={{
              onClick: addRecever,
              variant: constants.STYLE.VARIANT.CONTAINED,
              color: constants.STYLE.PRIMARY,
              label: { resource: resource, key: "addReceiver" },
              icon: <AddIcon />,
            }} />
          </div>
          {savedEmailTemplate.receiver && savedEmailTemplate.receiver.map((item, index) => {
            return <div key={index}>
              <TempTextInput
                inputSetting={{
                  className: "edit-create-input",
                  size: constants.SIZE.SMALL,
                  label: { resource: resource, key: "receiver" },
                  value: item,
                  name: "receiver",
                  isEndOfLine: false,
                  required: true,
                }}
                handleChange={onChangeInput}
                index={index} />
              <TempBtn btnSetting={{
                onClick: () => removeRecever(index),
                className: "edit-create-btn",
                variant: constants.STYLE.VARIANT.CONTAINED,
                color: constants.STYLE.PRIMARY,
                label: { resource: resource, key: "removeReceiver" },
                icon: <DeleteIcon />,
              }} />
            </div>
          })}
        </>,
      type: constants.TYPE.FUNCTION,
      name: "receiver",
      isEndOfLine: true,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "enSubject" },
      value: savedEmailTemplate.enSubject,
      name: "enSubject",
      custom: true,
      customName: { resource: resource, key: "subjectCustomize" },
      customValue: savedEmailTemplate.subjectScDisable,
      isEndOfLine: false,
      multiline: true,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "tcSubject" },
      value: savedEmailTemplate.tcSubject,
      name: "tcSubject",
      translateName: savedEmailTemplate.subjectScDisable ? ["scSubject"] : null,
      inputList: emailTemplateInputList,
      setInputList: setEmailTemplateInputList,
      isEndOfLine: false,
      multiline: true,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "scSubject" },
      value: savedEmailTemplate.scSubject,
      name: "scSubject",
      inputList: emailTemplateInputList,
      setInputList: setEmailTemplateInputList,
      isEndOfLine: true,
      multiline: true,
      disabled: savedEmailTemplate.subjectScDisable,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "htmlEnContent" },
      value: savedEmailTemplate.htmlEnContent,
      name: "htmlEnContent",
      custom: true,
      customName: { resource: resource, key: "htmlContentCustomize" },
      customValue: savedEmailTemplate.htmlContentScDisable,
      isEndOfLine: false,
      multiline: true,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "htmlTcContent" },
      value: savedEmailTemplate.htmlTcContent,
      name: "htmlTcContent",
      translateName: savedEmailTemplate.htmlContentScDisable ? ["htmlScContent"] : null,
      inputList: emailTemplateInputList,
      setInputList: setEmailTemplateInputList,
      isEndOfLine: false,
      multiline: true,
      required: true,
      helperTextIcon: true,
      helperText: EmailTemplateValidation.getErrorMessages(emailTemplateValidationConstants.KEY_HTML_TC_CONTENT),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "htmlScContent" },
      value: savedEmailTemplate.htmlScContent,
      name: "htmlScContent",
      inputList: emailTemplateInputList,
      setInputList: setEmailTemplateInputList,
      isEndOfLine: true,
      multiline: true,
      disabled: savedEmailTemplate.htmlContentScDisable,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "plainTextEnContent" },
      value: savedEmailTemplate.plainTextEnContent,
      name: "plainTextEnContent",
      custom: true,
      customName: { resource: resource, key: "plainTextContentCustomize" },
      customValue: savedEmailTemplate.plainTextContentScDisable,
      isEndOfLine: false,
      multiline: true,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "plainTextTcContent" },
      value: savedEmailTemplate.plainTextTcContent,
      name: "plainTextTcContent",
      translateName: savedEmailTemplate.plainTextContentScDisable ? ["plainTextScContent"] : null,
      inputList: emailTemplateInputList,
      setInputList: setEmailTemplateInputList,
      isEndOfLine: false,
      multiline: true,
      required: true,
      helperTextIcon: true,
      helperText: EmailTemplateValidation.getErrorMessages(emailTemplateValidationConstants.KEY_PLAIN_TEXT_TC_CONTENT),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "plainTextScContent" },
      value: savedEmailTemplate.plainTextScContent,
      name: "plainTextScContent",
      inputList: emailTemplateInputList,
      setInputList: setEmailTemplateInputList,
      isEndOfLine: true,
      multiline: true,
      disabled: savedEmailTemplate.plainTextContentScDisable,
    },
  ]
  const content = [
    {
      tab: tempEmailTemplateEditList.map((inputSetting, index) => {
        let content;
        switch (inputSetting.type) {
          case constants.TYPE.SELECT:
            content =
              <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
            break;
          case constants.TYPE.FUNCTION:
            content = inputSetting.value
            break;
          default:
            content =
              <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
        }
        return <div className="edit-display-wrapper" key={index}>
          {inputSetting.custom && <TempCustomCheckBox className="customize-btn" value={inputSetting.customValue} customName={inputSetting.customName} onSelectCheckbox={onSelectCheckbox} />}
          {content}
          {inputSetting.isEndOfLine && <div></div>}
        </div>
      }),
      btn: true
    }
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  function onSelectCheckbox(value, customName) {
    let tempEmailTemplate = _.cloneDeep(savedEmailTemplate);
    switch (customName.key) {
      case "subjectCustomize":
        tempEmailTemplate.subjectScDisable = value;
        if(value) {
          tempEmailTemplate.scSubject = tempEmailTemplate.tcSubject? sify(tempEmailTemplate.tcSubject): tempEmailTemplate.tcSubject;
        }
        break;
      case "htmlContentCustomize":
        tempEmailTemplate.htmlContentScDisable = value;
        if(value) {
          tempEmailTemplate.htmlScContent = tempEmailTemplate.htmlTcContent? sify(tempEmailTemplate.htmlTcContent): tempEmailTemplate.htmlTcContent;
        }
        break;
      case "plainTextContentCustomize":
        tempEmailTemplate.plainTextContentScDisable = value;
        if(value) {
          tempEmailTemplate.plainTextScContent = tempEmailTemplate.plainTextTcContent? sify(tempEmailTemplate.plainTextTcContent): tempEmailTemplate.plainTextTcContent;
        }
        break;
      default:
    }
    dispatch(setEmailTemplate(tempEmailTemplate));
  }

  function addRecever() {
    let tempData = _.cloneDeep(savedEmailTemplate);
    let tempReceiver = tempData.receiver ? _.cloneDeep(tempData.receiver) : [];
    tempReceiver.push("");
    tempData.receiver = tempReceiver;
    dispatch(setEmailTemplate(tempData));
  }

  function removeRecever(index) {
    let tempData = _.cloneDeep(savedEmailTemplate);
    tempData.receiver = tempData.receiver.filter((item, tempDataIndex) => tempDataIndex !== index)
    dispatch(setEmailTemplate(tempData));
  }

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    onClickClear();
  };
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.SHOP.EMAIL_TEMPLATE);
  };

  function onChangeInput(name, e, index) {
    let tempData = _.cloneDeep(savedEmailTemplate);
    if (name === "receiver") {
      tempData.receiver = tempData.receiver.map((item, tempDataIndex) => {
        return tempDataIndex === index ? item = e : item
      })
      dispatch(setEmailTemplate(tempData));
    } else if (name === "tcSubject" && tempData.subjectScDisable === true) {
      dispatch(setEmailTemplateValue("CHANGE_TC_NAME_AND_SC_SUBJECT", savedEmailTemplate, "", e));
    } else if (name === "htmlTcContent" && tempData.htmlContentScDisable === true) {
      dispatch(setEmailTemplateValue("CHANGE_TC_NAME_AND_SC_HTML_CONTENT", savedEmailTemplate, "", e));
    } else if (name === "plainTextTcContent" && tempData.plainTextContentScDisable === true) {
      dispatch(setEmailTemplateValue("CHANGE_TC_NAME_AND_SC_PLAIN_TEXT_CONTENT", savedEmailTemplate, "", e));
    } else {
      dispatch(setEmailTemplateValue(name, savedEmailTemplate, "", e));
    }
  }

  function onClickSave() {
    let tempData = _.cloneDeep(savedEmailTemplate);
    EmailTemplateValidation.validateEditEmailTemplate(tempData);
    if (EmailTemplateValidation.isValid()) {
      tempData.receiver = tempData.receiver && tempData.receiver.filter((item) => item).join();
      put(constants.PATH.SHOP.EMAIL_TEMPLATE + "/" + params.id, tempData, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToEdit,
          dialogContentText: { key: "editSuccess" },
          dialogActions: [
            { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      EmailTemplateValidation.resetErrorMessages();
    } else {
      setErrorVersion(EmailTemplateValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    if (clear === true) {
      getOne(constants.PATH.SHOP.EMAIL_TEMPLATE + "/" + params.id, undefined, (payload) => {
        let tempData = _.cloneDeep(payload);
        tempData.receiver = tempData.receiver ? tempData.receiver.split(',') : tempData.receiver;
        tempData.subjectScDisable = getScDisabled(tempData.tcSubject, tempData.scSubject);
        tempData.htmlContentScDisable = getScDisabled(tempData.htmlTcContent, tempData.htmlScContent);
        tempData.plainTextContentScDisable = getScDisabled(tempData.plainTextTcContent, tempData.plainTextScContent);
        dispatch(setEmailTemplate(_.cloneDeep(tempData)));
        setEmailTemplateInputList(_.cloneDeep(tempData))
        setReady(true)
        setClear(false)
      }, undefined, store);
    }
    EmailTemplateValidation.resetErrorMessages();
  }, [clear]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <>
      <EditCreateDisplay
        code={savedEmailTemplate && savedEmailTemplate.id ? savedEmailTemplate.id : ""}
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        display="edit"
      />
      {dialog && dialog.open &&
        <DialogWrapper dialogInfo={dialog} />
      }
    </>
  )
};