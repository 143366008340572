import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sify } from "chinese-conv";
import _ from "lodash";

import { Card } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';

import { setProductValue } from "../../../actions/productCreateAction";
import { constants } from '../../../Constants/constants';
import ProductCreateValidation from "../../../validations/productCreateValidation";
import { productValidationConstants } from '../../../Constants/validationConstants';
import { setLoading } from "../../../actions/systemAction";
import { TempAutoSelectInput } from "../../Input/TempAutoSelectInput";
import { TempTextInput } from "../../Input/TempTextInput";
import { TempBtn } from "../../Input/TempBtn";
import { TempCustomCheckBox } from "../../Custom/TempCustomCheckBox";
import { getString } from "../../../helper/util";

export const ProductAttribute = ({ hiddenInput, disableInput, helperTextStylesClasses }) => {
  const dispatch = useDispatch();
  const savedProduct = useSelector(state => state.product.ui.product);
  const lang = useSelector(state => state.lang.ui.lang);
  const theme = useSelector(state => state.theme.ui.theme);
  const [productAttributeInputList, setProductAttributeInputList] = useState(savedProduct.draft.product.attributeList);

  const handleAddAttributeList = async () => {
    let tempCodeNumber = _.cloneDeep(savedProduct.draft.codeNumber);
    const tempAttributeList = await processAddAttributeList(tempCodeNumber);
    let tempDraft = _.cloneDeep(savedProduct.draft);
    tempDraft.product.attributeList = tempAttributeList.tempAttributeList;
    tempDraft.codeNumber = tempAttributeList.tempCodeNumber + 1;
    dispatch(setProductValue("", savedProduct, "", tempDraft));
  }

  const processAddAttributeList = (tempCodeNumber) => {
    return new Promise((resolve) => {
      let tempAttributeList = _.cloneDeep(savedProduct.draft.product.attributeList);
      tempAttributeList.push({
        attributeValiCode: "Attribute_" + Number(tempCodeNumber),
        key: "",
        type: "",
        criteria: {
          min: 0,
          max: 0
        },
        optionList: [],
        tcName: "",
        enName: "",
        scName: "",
        scNameDisable: true,
        tcDescription: "",
        enDescription: "",
        scDescription: "",
        scDescriptionDisable: true,
        displaySequence: 0,
        display: "",
        defaultValue: ""
      })
      tempCodeNumber = tempCodeNumber + 1;
      resolve({ tempAttributeList: tempAttributeList, tempCodeNumber: tempCodeNumber });
    })
  }

  const handleDeleteAttribute = (attributeIndex) => {
    dispatch(setLoading(true));
    let tempAttributeList = _.cloneDeep(savedProduct.draft.product.attributeList);
    tempAttributeList.splice(attributeIndex, 1);
    setProductAttributeInputList(_.cloneDeep(tempAttributeList));
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, "attributeList", _.cloneDeep(tempAttributeList)));
    dispatch(setLoading(false));
  }

  const handleAddOptionList = async (attributeListIndex) => {
    let tempCodeNumber = _.cloneDeep(savedProduct.draft.codeNumber);
    const tempOptionList = await processAddOptionList(attributeListIndex, tempCodeNumber);
    let tempAttributeList = _.cloneDeep(savedProduct.draft.product.attributeList);
    tempAttributeList.map((item, index) => {
      if (attributeListIndex === index) {
        item.optionList = tempOptionList.tempOptionList
      }
      return item;
    })
    setProductAttributeInputList(_.cloneDeep(tempAttributeList));
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, "attributeList", _.cloneDeep(tempAttributeList)));
    // let tempDraft = _.cloneDeep(savedProduct.draft);
    // tempDraft.product.attributeList = tempAttributeList;
    // tempDraft.codeNumber = tempOptionList.tempCodeNumber + 1;
    // dispatch(setProductValue("", savedProduct, "", tempDraft));
  }

  const processAddOptionList = (attributeListIndex, tempCodeNumber) => {
    return new Promise((resolve) => {
      let tempAttributeList = _.cloneDeep(savedProduct.draft.product.attributeList);
      let tempOptionList = _.cloneDeep(tempAttributeList[attributeListIndex].optionList);
      tempOptionList.push({
        optionValiCode: "Option_" + Number(tempCodeNumber),
        code: "",
        tcValue: "",
        enValue: "",
        scValue: "",
        scValueDisable: true,
      })
      tempCodeNumber = tempCodeNumber + 1;
      resolve({ tempOptionList: tempOptionList, tempCodeNumber: tempCodeNumber });
    })
  }

  const handleDeleteOption = (optionIndex, attributeListIndex) => {
    dispatch(setLoading(true));
    let tempAttributeList = _.cloneDeep(savedProduct.draft.product.attributeList);
    tempAttributeList.map((item, index) => {
      if (attributeListIndex === index) {
        item.optionList.splice(optionIndex, 1)
      }
      return {};
    })
    setProductAttributeInputList(_.cloneDeep(tempAttributeList));
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, "attributeList", _.cloneDeep(tempAttributeList)));
    dispatch(setLoading(false));
  }

  function handleOptionCode(name, optionCode, optionIndex) {
    let tempAttributeList = _.cloneDeep(savedProduct.draft.product.attributeList);
    tempAttributeList.map((item, index) => {
      if (optionIndex.attributeListIndex === index) {
        item.optionList[optionIndex.optionListIndex].code = optionCode
      }
      return {};
    })
    setProductAttributeInputList(_.cloneDeep(tempAttributeList));
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, "attributeList", _.cloneDeep(tempAttributeList)));
  }

  function handleTcValue(name, tcValue, optionIndex) {
    let tempAttributeList = _.cloneDeep(savedProduct.draft.product.attributeList);
    let tempOptionList = _.cloneDeep(tempAttributeList[optionIndex.attributeListIndex].optionList);
    tempOptionList.map((item, index) => {
      if (optionIndex.optionListIndex === index) {
        if (item.scValueDisable === true) {
          item.tcValue = tcValue
          item.scValue = tcValue ? sify(tcValue) : tcValue
        } else {
          item.tcValue = tcValue
        }
      }
      return item;
    })
    tempAttributeList[optionIndex.attributeListIndex].optionList = tempOptionList
    setProductAttributeInputList(_.cloneDeep(tempAttributeList));
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, "attributeList", _.cloneDeep(tempAttributeList)));
  }

  function handleEnValue(name, enValue, optionIndex) {
    let tempAttributeList = _.cloneDeep(savedProduct.draft.product.attributeList);
    tempAttributeList.map((item, index) => {
      if (optionIndex.attributeListIndex === index) {
        item.optionList[optionIndex.optionListIndex].enValue = enValue
      }
      return {};
    })
    setProductAttributeInputList(_.cloneDeep(tempAttributeList));
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, "attributeList", _.cloneDeep(tempAttributeList)));
  }

  function handleScValue(name, scValue, optionIndex) {
    let tempAttributeList = _.cloneDeep(savedProduct.draft.product.attributeList);
    tempAttributeList.map((item, index) => {
      if (optionIndex.attributeListIndex === index) {
        item.optionList[optionIndex.optionListIndex].scValue = scValue
      }
      return {};
    })
    setProductAttributeInputList(_.cloneDeep(tempAttributeList));
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, "attributeList", _.cloneDeep(tempAttributeList)));
  }

  function handleAttributeKey(name, attributeKey, attributeListIndex) {
    let tempAttributeList = _.cloneDeep(savedProduct.draft.product.attributeList);
    tempAttributeList.map((item, index) => {
      if (attributeListIndex === index) {
        item.key = attributeKey
      }
      return {};
    })
    setProductAttributeInputList(_.cloneDeep(tempAttributeList));
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, "attributeList", _.cloneDeep(tempAttributeList)));
  }

  function handleAttributeType(name, type, attributeListIndex) {
    let tempAttributeList = _.cloneDeep(savedProduct.draft.product.attributeList);
    tempAttributeList.map((item, index) => {
      if (attributeListIndex === index) {
        item.type = type
        item.optionList = []
      }
      return {};
    })
    setProductAttributeInputList(_.cloneDeep(tempAttributeList));
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, "attributeList", _.cloneDeep(tempAttributeList)));
  }

  function handleMin(name, min, attributeListIndex) {
    let tempAttributeList = _.cloneDeep(savedProduct.draft.product.attributeList);
    tempAttributeList.map((item, index) => {
      if (attributeListIndex === index) {
        item.criteria.min = Number(min)
      }
      return {};
    })
    setProductAttributeInputList(_.cloneDeep(tempAttributeList));
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, "attributeList", _.cloneDeep(tempAttributeList)));
  }

  function handleMax(name, max, attributeListIndex) {
    let tempAttributeList = _.cloneDeep(savedProduct.draft.product.attributeList);
    tempAttributeList.map((item, index) => {
      if (attributeListIndex === index) {
        item.criteria.max = Number(max)
      }
      return {};
    })
    setProductAttributeInputList(_.cloneDeep(tempAttributeList));
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, "attributeList", _.cloneDeep(tempAttributeList)));
  }

  function handleAttributeTcName(name, attributeTcName, attributeListIndex) {
    let tempAttributeList = _.cloneDeep(savedProduct.draft.product.attributeList);
    tempAttributeList.map((item, index) => {
      if (attributeListIndex === index) {
        if (item.scNameDisable === true) {
          item.tcName = attributeTcName;
          item.scName = attributeTcName ? sify(attributeTcName) : attributeTcName;
        } else {
          item.tcName = attributeTcName
        }
      }
      return item;
    })
    setProductAttributeInputList(_.cloneDeep(tempAttributeList));
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, "attributeList", _.cloneDeep(tempAttributeList)));
  }

  function handleAttributeEnName(name, attributeEnName, attributeListIndex) {
    let tempAttributeList = _.cloneDeep(savedProduct.draft.product.attributeList);
    tempAttributeList.map((item, index) => {
      if (attributeListIndex === index) {
        item.enName = attributeEnName
      }
      return {};
    })
    setProductAttributeInputList(_.cloneDeep(tempAttributeList));
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, "attributeList", _.cloneDeep(tempAttributeList)));
  }

  function handleAttributeScName(name, attributeScName, attributeListIndex) {
    let tempAttributeList = _.cloneDeep(savedProduct.draft.product.attributeList);
    tempAttributeList.map((item, index) => {
      if (attributeListIndex === index) {
        item.scName = attributeScName
      }
      return {};
    })
    setProductAttributeInputList(_.cloneDeep(tempAttributeList));
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, "attributeList", _.cloneDeep(tempAttributeList)));
  }

  function handleAttributeTcDescription(name, attributeTcDescription, attributeListIndex) {
    let tempAttributeList = _.cloneDeep(savedProduct.draft.product.attributeList);
    tempAttributeList.map((item, index) => {
      if (attributeListIndex === index) {
        if (item.scDescriptionDisable === true) {
          item.tcDescription = _.cloneDeep(attributeTcDescription)
          item.scDescription = attributeTcDescription ? sify(_.cloneDeep(attributeTcDescription)) : attributeTcDescription
        } else {
          item.tcDescription = _.cloneDeep(attributeTcDescription)
        }
      }
      return {};
    })
    setProductAttributeInputList(_.cloneDeep(tempAttributeList));
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, "attributeList", _.cloneDeep(tempAttributeList)));
  }

  function handleAttributeEnDescription(name, attributeEnDescription, attributeListIndex) {
    let tempAttributeList = _.cloneDeep(savedProduct.draft.product.attributeList);
    tempAttributeList.map((item, index) => {
      if (attributeListIndex === index) {
        item.enDescription = attributeEnDescription
      }
      return {};
    })
    setProductAttributeInputList(_.cloneDeep(tempAttributeList));
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, "attributeList", _.cloneDeep(tempAttributeList)));
  }


  function handleAttributeScDescription(name, attributeScDescription, attributeListIndex) {
    let tempAttributeList = _.cloneDeep(savedProduct.draft.product.attributeList);
    tempAttributeList.map((item, index) => {
      if (attributeListIndex === index) {
        item.scDescription = attributeScDescription
      }
      return {};
    })
    setProductAttributeInputList(_.cloneDeep(tempAttributeList));
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, "attributeList", _.cloneDeep(tempAttributeList)));
  }

  function handleAttributeDisplaySequence(name, attributeDisplaySequence, attributeListIndex) {
    let tempAttributeList = _.cloneDeep(savedProduct.draft.product.attributeList);
    tempAttributeList.map((item, index) => {
      if (attributeListIndex === index) {
        item.displaySequence = Number(attributeDisplaySequence)
      }
      return {};
    })
    setProductAttributeInputList(_.cloneDeep(tempAttributeList));
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, "attributeList", _.cloneDeep(tempAttributeList)));
  }

  function handleAttributeDisplay(name, attributeDisplay, attributeListIndex) {
    let tempAttributeList = _.cloneDeep(savedProduct.draft.product.attributeList);
    tempAttributeList.map((item, index) => {
      if (attributeListIndex === index) {
        item.display = attributeDisplay
        if (attributeDisplay) {
          item.defaultValue = ""
        }
      }
      return {};
    })
    setProductAttributeInputList(_.cloneDeep(tempAttributeList));
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, "attributeList", _.cloneDeep(tempAttributeList)));
  }

  function handleAttributeDefaultValue(name, attributeDefaultValue, attributeListIndex) {
    let tempAttributeList = _.cloneDeep(savedProduct.draft.product.attributeList);
    tempAttributeList.map((item, index) => {
      if (attributeListIndex === index) {
        item.defaultValue = attributeDefaultValue
      }
      return {};
    })
    setProductAttributeInputList(_.cloneDeep(tempAttributeList));
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, "attributeList", _.cloneDeep(tempAttributeList)));
  }

  function handleAttributeStatus(name, status, attributeListIndex) {
    let tempAttributeList = _.cloneDeep(savedProduct.draft.product.attributeList);
    tempAttributeList.map((item, index) => {
      if (attributeListIndex === index) {
        item.status = status
      }
      return {};
    })
    setProductAttributeInputList(_.cloneDeep(tempAttributeList));
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, "attributeList", _.cloneDeep(tempAttributeList)));
  }

  function onSelectCheckbox(value, customName, index) {
    let tempAttributeList = _.cloneDeep(savedProduct.draft.product.attributeList);
    switch (customName.key) {
      case "nameCustomize":
        tempAttributeList.map((item, attributeIndex) => {
          if (index === attributeIndex) {
            item.scNameDisable = value
          }
          return item;
        })
        break;
      case "descriptionCustomize":
        tempAttributeList.map((item, attributeIndex) => {
          if (index === attributeIndex) {
            item.scDescriptionDisable = value
          }
          return item;
        })
        break;
      case "valueCustomize":
        tempAttributeList.map((item, optionIndex) => {
          if (index.attributeListIndex === optionIndex) {
            item.optionList[index.optionListIndex].scValueDisable = value
          }
          return item;
        })
        break;
      default:
    }
    setProductAttributeInputList(_.cloneDeep(tempAttributeList));
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, "attributeList", _.cloneDeep(tempAttributeList)));
  }

  return (
    <>
      {ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_ATTRIBUTE_LIST, savedProduct.draft.product.productCode).map((error) => { return error }) &&
        ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_ATTRIBUTE_LIST, savedProduct.draft.product.productCode).map((error) => { return error }).length > 0 &&
        <div className="error-message">
          <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
          {ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_ATTRIBUTE_LIST, savedProduct.draft.product.productCode).map((error) => { return error })}
        </div>
      }
      {!hiddenInput &&
        <TempBtn btnSetting={{
          className: "button-on-top",
          variant: constants.STYLE.VARIANT.CONTAINED,
          color: constants.STYLE.PRIMARY,
          onClick: handleAddAttributeList,
          label: { resource: "productCreateProductList", key: "addAttributeList" },
          icon: <AddIcon />,
          disabled: disableInput
        }} />
      }
      {savedProduct.draft.product.attributeList.map((item, attributeListIndex) => {

        function handleAttributeTcNameState(attribute) {
          let tempAttributeList = _.cloneDeep(savedProduct.draft.product.attributeList);
          tempAttributeList.map((item, index) => {
            if (attributeListIndex === index) {
              if (item.scNameDisable === true) {
                item.tcName = _.cloneDeep(attribute.tcName)
                item.scName = attribute.tcName ? sify(_.cloneDeep(attribute.tcName)) : attribute.tcName
              } else {
                item.tcName = _.cloneDeep(attribute.tcName)
              }
            }
            return item;
          })
          setProductAttributeInputList(_.cloneDeep(tempAttributeList));
        }

        function handleAttributeScNameState(attribute) {
          let tempAttributeList = _.cloneDeep(savedProduct.draft.product.attributeList);
          tempAttributeList.map((item, index) => {
            if (attributeListIndex === index) {
              item.scName = _.cloneDeep(attribute.scName)
            }
            return item;
          })
          setProductAttributeInputList(_.cloneDeep(tempAttributeList));
        }

        function handleAttributeTcDescriptionState(attribute) {
          let tempAttributeList = _.cloneDeep(savedProduct.draft.product.attributeList);
          tempAttributeList.map((item, index) => {
            if (attributeListIndex === index) {
              if (item.scDescriptionDisable === true) {
                item.tcDescription = _.cloneDeep(attribute.tcDescription)
                item.scDescription = attribute.tcDescription ? sify(_.cloneDeep(attribute.tcDescription)) : attribute.tcDescription
              } else {
                item.tcDescription = _.cloneDeep(attribute.tcDescription)
              }
            }
            return item;
          })
          setProductAttributeInputList(_.cloneDeep(tempAttributeList));
        }

        function handleAttributeScDescriptionState(attribute) {
          let tempAttributeList = _.cloneDeep(savedProduct.draft.product.attributeList);
          tempAttributeList.map((item, index) => {
            if (attributeListIndex === index) {
              item.scDescription = _.cloneDeep(attribute.scDescription)
            }
            return item;
          })
          setProductAttributeInputList(_.cloneDeep(tempAttributeList));
        }

        return <Card key={attributeListIndex} className="image-list">
          <div className="sub-title first-sub-title">{getString(lang, "productCreateProductList", "info", theme)}</div>
          {!item.id ?
            !hiddenInput &&
            <div className="deleteIcon">
              <TempBtn btnSetting={{
                className: "edit-create-btn",
                variant: constants.STYLE.VARIANT.CONTAINED,
                color: constants.STYLE.PRIMARY,
                onClick: () => handleDeleteAttribute(attributeListIndex),
                label: { key: "deleteBtn" },
                icon: <DeleteIcon />,
                disabled: disableInput,
              }} />
            </div> :
            disableInput ?
              <TempTextInput
                inputSetting={{
                  className: "edit-create-input",
                  variant: disableInput ? constants.STYLE.VARIANT.STANDARD : null,
                  size: constants.SIZE.SMALL,
                  label: { resource: "productCreateProductCategoryList", key: "status" },
                  name: "status",
                  value: item.status ? item.status : "",
                  disabled: disableInput,
                }}
                handleChange={handleAttributeStatus}
                index={attributeListIndex}
              />
              :
              <TempAutoSelectInput
                inputSetting={{
                  className: "edit-create-input",
                  size: constants.SIZE.SMALL,
                  type: constants.TYPE.SELECT,
                  selectValue: constants.CHOICES.PRODUCT_CREATE.CREATE_CATEGORY.STATUS,
                  label: { resource: "productCreateProductCategoryList", key: "status" },
                  name: "status",
                  value: item.status ? item.status : "",
                  disabled: disableInput,
                }}
                handleChange={handleAttributeStatus}
                index={attributeListIndex}
              />
          }
          <div>
            <TempTextInput
              inputSetting={{
                className: "edit-create-input",
                size: constants.SIZE.SMALL,
                variant: disableInput ? constants.STYLE.VARIANT.STANDARD : null,
                label: { resource: "productCreateProductList", key: "key" },
                name: "key",
                value: item.key,
                helperTextIcon: true,
                helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_ATTRIBUTE_KEY, savedProduct.draft.product.productCode, item.attributeValiCode).map((error) => { return error }),
                FormHelperTextProps: { className: helperTextStylesClasses.root },
                disabled: disableInput,
              }}
              handleChange={handleAttributeKey}
              index={attributeListIndex}
            />
            {disableInput ?
              <TempTextInput
                inputSetting={{
                  className: "edit-create-input",
                  variant: disableInput ? constants.STYLE.VARIANT.STANDARD : null,
                  size: constants.SIZE.SMALL,
                  label: { resource: "productCreateProductList", key: "attributeType" },
                  name: "type",
                  value: item.type,
                  disabled: disableInput,
                }}
                handleChange={handleAttributeType}
                index={attributeListIndex}
              />
              :
              <TempAutoSelectInput
                inputSetting={{
                  className: "edit-create-input",
                  size: constants.SIZE.SMALL,
                  type: constants.TYPE.SELECT,
                  selectValue: constants.CHOICES.PRODUCT_CREATE.CREATE_PRODUCT.ATTRIBUTE_TYPE,
                  label: { resource: "productCreateProductList", key: "attributeType" },
                  name: "type",
                  value: item.type,
                  disabled: disableInput,
                  helperTextIcon: true,
                  helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_ATTRIBUTE_TYPE, savedProduct.draft.product.productCode, item.attributeValiCode).map((error) => { return error }),
                  FormHelperTextProps: { className: helperTextStylesClasses.root },
                }}
                handleChange={handleAttributeType}
                index={attributeListIndex}
              />
            }
          </div>
          <div className="sub-title">{getString(lang, "productCreateProductList", "name", theme)}</div>
          <div>
            {!disableInput &&
              <TempCustomCheckBox
                className="customize-btn"
                customName={{ resource: "productCreateProductList", key: "nameCustomize" }}
                value={item.scNameDisable}
                onSelectCheckbox={onSelectCheckbox}
                index={attributeListIndex}
              />
            }
            <TempTextInput
              inputSetting={{
                className: "edit-create-input",
                size: constants.SIZE.SMALL,
                variant: disableInput ? constants.STYLE.VARIANT.STANDARD : null,
                label: { resource: "productCreateProductList", key: "enName" },
                name: "enName",
                value: item.enName,
                helperTextIcon: true,
                helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_ATTRIBUTE_NAME_EN, savedProduct.draft.product.productCode, item.attributeValiCode).map((error) => { return error }),
                FormHelperTextProps: { className: helperTextStylesClasses.root },
                disabled: disableInput,
                multiline: true
              }}
              handleChange={handleAttributeEnName}
              index={attributeListIndex}
            />
            <TempTextInput
              inputSetting={{
                className: "edit-create-input",
                size: constants.SIZE.SMALL,
                variant: disableInput ? constants.STYLE.VARIANT.STANDARD : null,
                label: { resource: "productCreateProductList", key: "tcName" },
                name: "tcName",
                value: item.tcName,
                inputList: productAttributeInputList[attributeListIndex],
                setInputList: handleAttributeTcNameState,
                helperTextIcon: true,
                helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_ATTRIBUTE_NAME_TC, savedProduct.draft.product.productCode, item.attributeValiCode).map((error) => { return error }),
                FormHelperTextProps: { className: helperTextStylesClasses.root },
                disabled: disableInput,
                multiline: true
              }}
              handleChange={handleAttributeTcName}
              index={attributeListIndex}
            />
            <TempTextInput
              inputSetting={{
                className: "edit-create-input",
                size: constants.SIZE.SMALL,
                variant: disableInput ? constants.STYLE.VARIANT.STANDARD : null,
                label: { resource: "productCreateProductList", key: "scName" },
                name: "scName",
                value: item.scName,
                inputList: productAttributeInputList[attributeListIndex],
                setInputList: handleAttributeScNameState,
                helperTextIcon: true,
                helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_ATTRIBUTE_NAME_SC, savedProduct.draft.product.productCode, item.attributeValiCode).map((error) => { return error }),
                FormHelperTextProps: { className: helperTextStylesClasses.root },
                disabled: disableInput ? disableInput : item.scNameDisable,
                multiline: true
              }}
              handleChange={handleAttributeScName}
              index={attributeListIndex}
            />
          </div>
          <div className="sub-title">{getString(lang, "productCreateProductList", "description", theme)}</div>
          <div>
            {!disableInput &&
              <TempCustomCheckBox
                className="customize-btn"
                customName={{ resource: "productCreateProductList", key: "descriptionCustomize" }}
                value={item.scDescriptionDisable}
                onSelectCheckbox={onSelectCheckbox}
                index={attributeListIndex}
              />
            }
            <TempTextInput
              inputSetting={{
                className: "edit-create-input",
                size: constants.SIZE.SMALL,
                variant: disableInput ? constants.STYLE.VARIANT.STANDARD : null,
                label: { resource: "productCreateProductList", key: "enDescription" },
                name: "enDescription",
                value: item.enDescription,
                disabled: disableInput,
                multiline: true
              }}
              handleChange={handleAttributeEnDescription}
              index={attributeListIndex}
            />
            <TempTextInput
              inputSetting={{
                className: "edit-create-input",
                size: constants.SIZE.SMALL,
                variant: disableInput ? constants.STYLE.VARIANT.STANDARD : null,
                label: { resource: "productCreateProductList", key: "tcDescription" },
                name: "tcDescription",
                value: item.tcDescription,
                inputList: productAttributeInputList[attributeListIndex],
                setInputList: handleAttributeTcDescriptionState,
                disabled: disableInput,
                multiline: true
              }}
              handleChange={handleAttributeTcDescription}
              index={attributeListIndex}
            />
            <TempTextInput
              inputSetting={{
                className: "edit-create-input",
                size: constants.SIZE.SMALL,
                variant: disableInput ? constants.STYLE.VARIANT.STANDARD : null,
                label: { resource: "productCreateProductList", key: "scDescription" },
                name: "scDescription",
                value: item.scDescription,
                inputList: productAttributeInputList[attributeListIndex],
                setInputList: handleAttributeScDescriptionState,
                disabled: disableInput ? disableInput : item.scDescriptionDisable,
                multiline: true
              }}
              handleChange={handleAttributeScDescription}
              index={attributeListIndex}
            />
          </div>
          <div className="sub-title">{getString(lang, "productCreateProductList", "display", theme)}</div>
          <div>
            <TempTextInput
              inputSetting={{
                className: "edit-create-input",
                size: constants.SIZE.SMALL,
                variant: disableInput ? constants.STYLE.VARIANT.STANDARD : null,
                label: { resource: "productCreateProductList", key: "displaySequence" },
                name: "displaySequence",
                value: item.displaySequence,
                disabled: disableInput,
                helperTextIcon: true,
                helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_ATTRIBUTE_DISPLAY_SEQUENCE, savedProduct.draft.product.productCode, item.attributeValiCode).map((error) => { return error }),
                FormHelperTextProps: { className: helperTextStylesClasses.root },
                multiline: true
              }}
              handleChange={handleAttributeDisplaySequence}
              index={attributeListIndex}
            />
            {disableInput ?
              <TempTextInput
                inputSetting={{
                  className: "edit-create-input",
                  variant: disableInput ? constants.STYLE.VARIANT.STANDARD : null,
                  size: constants.SIZE.SMALL,
                  label: { resource: "productCreateProductList", key: "display" },
                  name: "display",
                  value: item.display,
                  disabled: disableInput,
                }}
                handleChange={handleAttributeDisplay}
                index={attributeListIndex}
              />
              :
              <TempAutoSelectInput
                inputSetting={{
                  className: "edit-create-input",
                  size: constants.SIZE.SMALL,
                  type: constants.TYPE.SELECT,
                  selectValue: constants.CHOICES.PRODUCT_CREATE.CREATE_PRODUCT.DISPLAY,
                  label: { resource: "productCreateProductList", key: "display" },
                  name: "display",
                  value: item.display,
                  disabled: disableInput,
                  helperTextIcon: true,
                  helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_ATTRIBUTE_DISPLAY, savedProduct.draft.product.productCode, item.attributeValiCode).map((error) => { return error }),
                  FormHelperTextProps: { className: helperTextStylesClasses.root },
                }}
                handleChange={handleAttributeDisplay}
                index={attributeListIndex}
              />
            }
            {!item.display &&
              <TempTextInput
                inputSetting={{
                  className: "edit-create-input",
                  size: constants.SIZE.SMALL,
                  variant: disableInput ? constants.STYLE.VARIANT.STANDARD : null,
                  label: { resource: "productCreateProductList", key: "defaultValue" },
                  name: "defaultValue",
                  value: item.defaultValue,
                  disabled: disableInput,
                  helperTextIcon: true,
                  helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_ATTRIBUTE_DEFAULT_VALUE, savedProduct.draft.product.productCode, item.attributeValiCode).map((error) => { return error }),
                  FormHelperTextProps: { className: helperTextStylesClasses.root },
                  multiline: true
                }}
                handleChange={handleAttributeDefaultValue}
                index={attributeListIndex}
              />
            }
          </div>
          {item.display &&
            <>
              <div className="sub-title">{getString(lang, "productCreateProductList", "criteria", theme)}</div>
              <div>
                <TempTextInput
                  inputSetting={{
                    className: "edit-create-input",
                    type: constants.TYPE.NUMBER,
                    size: constants.SIZE.SMALL,
                    variant: disableInput ? constants.STYLE.VARIANT.STANDARD : null,
                    label: { resource: "productCreateProductList", key: "min" },
                    name: "min",
                    value: item.criteria.min,
                    min: 0,
                    disabled: disableInput,
                    helperTextIcon: true,
                    helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_ATTRIBUTE_CRITERIA_MIN, savedProduct.draft.product.productCode, item.attributeValiCode).map((error) => { return error }),
                    FormHelperTextProps: { className: helperTextStylesClasses.root },
                  }}
                  handleChange={handleMin}
                  index={attributeListIndex}
                />
                <TempTextInput
                  inputSetting={{
                    className: "edit-create-input",
                    type: constants.TYPE.NUMBER,
                    size: constants.SIZE.SMALL,
                    variant: disableInput ? constants.STYLE.VARIANT.STANDARD : null,
                    label: { resource: "productCreateProductList", key: "max" },
                    name: "max",
                    value: item.criteria.max,
                    disabled: disableInput,
                    helperTextIcon: true,
                    helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_ATTRIBUTE_CRITERIA_MAX, savedProduct.draft.product.productCode, item.attributeValiCode).map((error) => { return error }),
                    FormHelperTextProps: { className: helperTextStylesClasses.root },
                  }}
                  handleChange={handleMax}
                  index={attributeListIndex}
                />
              </div>
              {item.type === constants.ATTRIBUTE_TYPE.SELECT &&
                <>
                  <div className="array-list-wrapper product-array-list-wrapper">
                    <div className="sub-title">{getString(lang, "productCreateProductList", "optionList", theme)}</div>
                    {!hiddenInput &&
                      <TempBtn btnSetting={{
                        className: "button-on-top",
                        variant: constants.STYLE.VARIANT.CONTAINED,
                        color: constants.STYLE.PRIMARY,
                        onClick: () => handleAddOptionList(attributeListIndex),
                        label: { resource: "productCreateProductList", key: "addOptionList" },
                        icon: <AddIcon />,
                        disabled: disableInput
                      }} />
                    }
                  </div>
                  {ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_OPTION_LIST, savedProduct.draft.product.productCode, item.attributeValiCode).map((error) => { return error }) &&
                    ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_OPTION_LIST, savedProduct.draft.product.productCode, item.attributeValiCode).map((error) => { return error }).length > 0 &&
                    <div className="error-message">
                      <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                      {ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_OPTION_LIST, savedProduct.draft.product.productCode, item.attributeValiCode).map((error) => { return error })}
                    </div>
                  }
                  {item.optionList.map((optionListItem, optionListIndex) => {

                    function handleTcValueState(option) {
                      let tempAttributeList = _.cloneDeep(savedProduct.draft.product.attributeList);
                      let tempOptionList = _.cloneDeep(tempAttributeList[attributeListIndex].optionList);
                      tempOptionList.map((item, index) => {
                        if (optionListIndex === index) {
                          if (item.scValueDisable === true) {
                            item.tcValue = _.cloneDeep(option.tcValue)
                            item.scValue = option.tcValue ? sify(_.cloneDeep(option.tcValue)) : option.tcValue
                          } else {
                            item.tcValue = _.cloneDeep(option.tcValue)
                          }
                        }
                        return item;
                      })
                      tempAttributeList[attributeListIndex].optionList = _.cloneDeep(tempOptionList)
                      setProductAttributeInputList(_.cloneDeep(tempAttributeList));
                    }

                    function handleScValueState(option) {
                      let tempAttributeList = _.cloneDeep(savedProduct.draft.product.attributeList);
                      let tempOptionList = _.cloneDeep(tempAttributeList[attributeListIndex].optionList);
                      tempOptionList.map((item, index) => {
                        if (optionListIndex === index) {
                          item.scValue = option.scValue;
                        }
                        return item;
                      })
                      tempAttributeList[attributeListIndex].optionList = _.cloneDeep(tempOptionList)
                      setProductAttributeInputList(_.cloneDeep(tempAttributeList));
                    }

                    return <Card key={optionListIndex} className="image-list">
                      {!hiddenInput &&
                        <div className="deleteIcon">
                          <TempBtn btnSetting={{
                            className: "edit-create-btn",
                            variant: constants.STYLE.VARIANT.CONTAINED,
                            color: constants.STYLE.PRIMARY,
                            onClick: () => handleDeleteOption(optionListIndex, attributeListIndex),
                            label: { key: "deleteBtn" },
                            icon: <DeleteIcon />,
                            disabled: disableInput,
                          }} />
                        </div>
                      }
                      <div>
                        <TempTextInput
                          inputSetting={{
                            className: "edit-create-input",
                            size: constants.SIZE.SMALL,
                            variant: disableInput ? constants.STYLE.VARIANT.STANDARD : null,
                            label: { resource: "productCreateProductList", key: "code" },
                            name: "code",
                            value: optionListItem.code,
                            disabled: disableInput,
                            helperTextIcon: true,
                            helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_ATTRIBUTE_OPTION_CODE, savedProduct.draft.product.productCode, item.attributeValiCode, optionListItem.optionValiCode).map((error) => { return error }),
                            FormHelperTextProps: { className: helperTextStylesClasses.root },
                          }}
                          handleChange={handleOptionCode}
                          index={{ attributeListIndex: attributeListIndex, optionListIndex: optionListIndex }}
                        />
                      </div>
                      <div>
                        {!disableInput &&
                          <TempCustomCheckBox
                            className="customize-btn"
                            customName={{ resource: "productCreateProductList", key: "valueCustomize" }}
                            value={optionListItem.scValueDisable}
                            onSelectCheckbox={onSelectCheckbox}
                            index={{ attributeListIndex: attributeListIndex, optionListIndex: optionListIndex }}
                          />
                        }
                        <TempTextInput
                          inputSetting={{
                            className: "edit-create-input",
                            type: constants.TYPE.NUMBER,
                            size: constants.SIZE.SMALL,
                            variant: disableInput ? constants.STYLE.VARIANT.STANDARD : null,
                            label: { resource: "productCreateProductList", key: "enValue" },
                            name: "enValue",
                            value: optionListItem.enValue,
                            disabled: disableInput,
                            helperTextIcon: true,
                            helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_ATTRIBUTE_OPTION_EN_VALUE, savedProduct.draft.product.productCode, item.attributeValiCode, optionListItem.optionValiCode).map((error) => { return error }),
                            FormHelperTextProps: { className: helperTextStylesClasses.root },
                            multiline: true
                          }}
                          handleChange={handleEnValue}
                          index={{ attributeListIndex: attributeListIndex, optionListIndex: optionListIndex }}
                        />
                        <TempTextInput
                          inputSetting={{
                            className: "edit-create-input",
                            type: constants.TYPE.NUMBER,
                            size: constants.SIZE.SMALL,
                            variant: disableInput ? constants.STYLE.VARIANT.STANDARD : null,
                            label: { resource: "productCreateProductList", key: "tcValue" },
                            name: "tcValue",
                            value: optionListItem.tcValue,
                            inputList: productAttributeInputList[attributeListIndex].optionList[optionListIndex],
                            setInputList: handleTcValueState,
                            disabled: disableInput,
                            helperTextIcon: true,
                            helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_ATTRIBUTE_OPTION_TC_VALUE, savedProduct.draft.product.productCode, item.attributeValiCode, optionListItem.optionValiCode).map((error) => { return error }),
                            FormHelperTextProps: { className: helperTextStylesClasses.root },
                            multiline: true
                          }}
                          handleChange={handleTcValue}
                          index={{ attributeListIndex: attributeListIndex, optionListIndex: optionListIndex }}
                        />
                        <TempTextInput
                          inputSetting={{
                            className: "edit-create-input",
                            type: constants.TYPE.NUMBER,
                            size: constants.SIZE.SMALL,
                            variant: disableInput ? constants.STYLE.VARIANT.STANDARD : null,
                            label: { resource: "productCreateProductList", key: "scValue" },
                            name: "scValue",
                            value: optionListItem.scValue,
                            inputList: productAttributeInputList[attributeListIndex].optionList[optionListIndex],
                            setInputList: handleScValueState,
                            disabled: disableInput ? disableInput : optionListItem.scValueDisable,
                            helperTextIcon: true,
                            helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_ATTRIBUTE_OPTION_SC_VALUE, savedProduct.draft.product.productCode, item.attributeValiCode, optionListItem.optionValiCode).map((error) => { return error }),
                            FormHelperTextProps: { className: helperTextStylesClasses.root },
                            multiline: true
                          }}
                          handleChange={handleScValue}
                          index={{ attributeListIndex: attributeListIndex, optionListIndex: optionListIndex }}
                        />
                      </div>
                    </Card>
                  })}
                </>
              }
            </>
          }
        </Card>
      })}
    </>
  );
};