
import { useState, useEffect } from "react";
import { useStore } from "react-redux";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { Card } from '@mui/material';

import { ListDisplay } from '../../Component/ListDisplay/ListDisplay';

import { constants } from '../../Constants/constants';
import { getList } from "../../helper/baseFetch";
import { generateTableCellToMultiLanguageName, getPreviewText } from "../../helper/util";

export const DisplayMessageList = () => {
  const store = useStore();
  const params = useParams();
  const [campaignDescriptionList, setCampaignDescriptionList] = useState([]);
  const [ready, setReady] = useState(false);
  const [clear, setClear] = useState(false);
  const resource = "CampaignDescription";

  const campaignDescriptionTitleList = [
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "type",
      sort: true
    },{
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "messageCode",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "title",
      sort: false,
      minSize: true,
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "message",
      sort: false,
      minSize: true,
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "sequence",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "status",
      sort: true
    }
  ];
  const listFilters = [
    {
      className: "filter-input",
      label: { resource: resource, key: "type" },
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.CAMPAIGN_DESCRIPTION.TYPE,
      name: "type",
      size: constants.SIZE.SMALL
    },
    {
      className: "filter-input",
      label: { resource: resource, key: "title" },
      clear: true,
      value: '',
      name: "title",
      size: constants.SIZE.SMALL
    },
    {
      className: "filter-input",
      label: { resource: resource, key: "status" },
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.CAMPAIGN_DESCRIPTION.STATUS,
      name: "status",
      size: constants.SIZE.SMALL
    }
  ];

  const [param, setParam] = useState({
    path: "",
    page: 0,
    rowsPerPage: 10,
    sort: 'updateTimestamp',
    direction: 'DESC',
    listFilters: listFilters,
    count: 0,
  })

  function onChangeParam(value) {
    value.count = value.count + 1;
    setParam(value);
  }

  function onChangeListFilters(list) {
    let tempParam = _.cloneDeep(param);
    tempParam.listFilters = list;
    tempParam.page = 0;
    onChangeParam(tempParam);
  }

  function onChangeListSort(sortItem) {
    let tempParam = _.cloneDeep(param);
    tempParam.sort = sortItem;
    tempParam.direction = tempParam.direction === "DESC" ? "ASC" : "DESC";
    onChangeParam(tempParam);
  }

  function onChangeListPage(page) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = page;
    onChangeParam(tempParam);
  }

  function onChangeListRowsPerPage(rowsPerPage) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = 0;
    tempParam.rowsPerPage = rowsPerPage;
    onChangeParam(tempParam);
  }

  useEffect(() => {
    let tempParam = _.cloneDeep(param);
    const filter = { id: params.id };
    tempParam.listFilters.forEach((filterItem) => {
      if (filterItem.value !== '' && filterItem.value !== undefined) {
        filter[filterItem.name] = filterItem.value;
      }
    })

    getList(constants.PATH.SHOP.CAMPAIGN_DESCRIPTION, { sort: { field: tempParam.sort, order: tempParam.direction }, filter: filter, pagination: { page: tempParam.page + 1, perPage: tempParam.rowsPerPage } }, (payload) => {
      payload.map((item) => {
        item.title = generateTableCellToMultiLanguageName(item.tcTitle, item.enTitle, item.scTitle);
        return item
      });
      payload.forEach((item)=>{
        if(item.type === constants.CAMPAIGN_DESCRIPTION_TYPE.WEB_CAMPAIGN_DESCRIPTION){
          item.message = "HTML content";
        }else{
          item.message = generateTableCellToMultiLanguageName(getPreviewText(item.tcMessage), getPreviewText(item.enMessage), getPreviewText(item.scMessage));
        }
      })
      setCampaignDescriptionList(payload);
      setReady(true);
      setClear(false);
    }, undefined, store);

  }, [param.count, clear === true]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <Card className="card-wrapper overflow">
      <ListDisplay
        page={param.page}
        rowsPerPage={param.rowsPerPage}
        onChangeListSort={onChangeListSort}
        resource={resource}
        dataList={campaignDescriptionList}
        sort={param.sort}
        direction={param.direction}
        listTitles={campaignDescriptionTitleList}
        listFilters={param.listFilters}
        onChangeListPage={onChangeListPage}
        idParam= {params.id}
        onChangeListRowsPerPage={onChangeListRowsPerPage}
        onChangeListFilters={onChangeListFilters}
        redirectPath={"/" + constants.PATH.SHOP.CAMPAIGN_DESCRIPTION + "/"}
      />
    </Card>
  )
};