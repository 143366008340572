import { RESTAURANT_URL_TYPE } from '../Constants/constants'

export const parseQueryParamsStrToKeyValuePairs = (queryParamStr) => {
  const keyValuePairs = queryParamStr.split('&')

  const queryParams = keyValuePairs.map((pair) => {
    const [key, value] = pair.split('=')
    return { key: decodeURIComponent(key), value: decodeURIComponent(value) }
  })

  return queryParams
}

export const getRestaurantUrlType = (url) => {
  if (url) {
    const universalLinkRex = /^https:\/\/(dev-)?web\.thegulu\.((net)|(com)){1}\/app\/shop\/[a-zA-Z\d]+$/;
    const downloadHtmlRex = /^https:\/\/www\.thegulu\.com\/download\.html.*$/;
    if (url.match(universalLinkRex)) {
      return RESTAURANT_URL_TYPE.UNIVERSAL;
    } else if (url.match(downloadHtmlRex)) {
      return RESTAURANT_URL_TYPE.DOWNLOAD_HTML;
    }
  }
  return RESTAURANT_URL_TYPE.OTHER
}

export const getRestUrlIdFromUrl = (url) => {
  return getRestUrlIdFromUrlByType(getRestaurantUrlType(url), url);
}

export const getRestUrlIdFromUrlByType = (urlType, url) => {
  let index = -1;
  let keyPath = "";
  switch (urlType) {
    case RESTAURANT_URL_TYPE.UNIVERSAL:
      keyPath = "/app/shop/";
      index = url.indexOf(keyPath);
      const qIndex = url.indexOf("?")
      if (index !== -1) {
        return (qIndex !== -1)? url.substring(index + keyPath.length, qIndex) : url.substring(index + keyPath.length); 
      }
      return undefined;
    case RESTAURANT_URL_TYPE.DOWNLOAD_HTML:
      keyPath = "?redirect_rest=";
      index = url.indexOf(keyPath);
      if (index !== -1) {
        const queryParamsString = url.substring(index + keyPath.length);
        const queryParams = parseQueryParamsStrToKeyValuePairs(queryParamsString);
        return queryParams.find(qp => qp.key === "restUrlId")?.value;
      }
      return undefined;
    default:
      return undefined;
  }
}
