import _ from "lodash";
import { EMAIL_TEMPLATE } from '../actions/types';
import { constants } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        emailTemplate: _.cloneDeep(constants.EMAIL_TEMPLATE_INPUT)
    }
}

const emailTemplateReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case EMAIL_TEMPLATE.SET_EMAIL_TEMPLATE_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case EMAIL_TEMPLATE.SET_EMAIL_TEMPLATE:
        case EMAIL_TEMPLATE.SET_EMAIL_TEMPLATE_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    emailTemplate: action.emailTemplate
                }
            })
        default:
            return state
    }
}

export default emailTemplateReducer