import { useState, useEffect } from 'react'
import { useStore, useDispatch } from 'react-redux'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import _ from 'lodash'
import { setLoading } from '../../actions/systemAction'

import { Card } from '@mui/material'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import ClearIcon from '@mui/icons-material/Clear'
import AddIcon from '@mui/icons-material/Add'

import { ListDisplay } from '../../Component/ListDisplay/ListDisplay'

import { constants } from '../../Constants/constants'
import { getList, put } from '../../helper/baseFetch'
import Drawer from '@mui/material/Drawer'
import { TempBtn } from '../../Component/Input/TempBtn'
import { generateTableCellToMultiLanguageName, getParamQueryString, getQuery, getQueryParamObject } from '../../helper/util'

const resourceName = 'RestaurantSlideshow'

export const RestaurantSlideshowList = ({
  editBtn,
  createBtn,
  newFilter,
  newPath,
  newRedirectPath,
  newListTitles,
  idName,
  btnList,
  newListFilters,
  mutipleSelect,
  dialogFooterBtn,
  selectMap,
  setSelectMap,
  changeUrlQueryString,
}) => {
  const dispatch = useDispatch()
  const store = useStore()
  const params = useParams()
  const [restaurantSlideshowList, setRestauranttSlideshowList] = useState([])
  const [ready, setReady] = useState(false)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [sourceSlideshowId, setSourceSlideshowId] = useState('')
  const [selectedAllCheck, setSelectedAllCheck] = useState(false)
  const [addSlideshowMap, setAddSlideshowMap] = useState(new Map())
  const history = useHistory()
  const location = useLocation()
  const filterParams = getQuery()

  const listTitles = newListTitles
    ? newListTitles
    : [
        {
          key: constants.LIST_DISPLAY_TYPE.STRING,
          value: 'type',
          sort: true,
        },
        {
          key: constants.LIST_DISPLAY_TYPE.MEDIA,
          value: 'mediaUrl',
          sort: false,
          type: 'type',
        },
        {
          key: constants.LIST_DISPLAY_TYPE.DATE_TIME,
          value: 'startDisplayTimestamp',
          sort: true,
        },
        {
          key: constants.LIST_DISPLAY_TYPE.DATE_TIME,
          value: 'endDisplayTimestamp',
          sort: true,
        },
        {
          key: constants.LIST_DISPLAY_TYPE.STRING,
          value: 'sequence',
          sort: true,
        },
        {
          key: constants.LIST_DISPLAY_TYPE.STRING,
          value: 'status',
          sort: true,
        },
      ]
  const listFilters = newListFilters
    ? newListFilters
    : [
        {
          className: 'filter-input',
          label: { resource: resourceName, key: 'type' },
          value: '',
          type: constants.TYPE.SELECT,
          selectValue: constants.CHOICES.RESTAURANT_SLIDESHOW.TYPE,
          name: 'type',
          size: constants.SIZE.SMALL,
        },
        {
          className: 'filter-input',
          label: { resource: resourceName, key: 'status' },
          value: '',
          type: constants.TYPE.SELECT,
          selectValue: constants.CHOICES.RESTAURANT_SLIDESHOW.FILTER_STATUS,
          name: 'status',
          size: constants.SIZE.SMALL,
        },
        {
          className: 'filter-input',
          label: { resource: resourceName, key: 'showExpired' },
          value: false,
          type: constants.TYPE.SELECT,
          selectValue: constants.CHOICES.RESTAURANT_SLIDESHOW.SHOW_EXPIRED,
          name: 'showExpired',
          size: constants.SIZE.SMALL,
        },
      ]

  const [param, setParam] = useState(
    !changeUrlQueryString
      ? {
          path: newPath ? newPath : '',
          page: 0,
          rowsPerPage: 10,
          sort: 'updateTimestamp',
          direction: 'DESC',
          listFilters: _.cloneDeep(listFilters),
          count: 0,
        }
      : getQueryParamObject(
          {
            path: newPath ? newPath : '',
            page: 0,
            rowsPerPage: 10,
            sort: 'updateTimestamp',
            direction: 'DESC',
            listFilters: _.cloneDeep(listFilters),
            count: 0,
          },
          _.cloneDeep(listFilters),
          listTitles
        )
  )

  useEffect(() => {
    if (!changeUrlQueryString) {
      return
    }

    const querySyring = getParamQueryString(
      {
        path: newPath ? newPath : '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: param.count,
      },
      param
    )

    const currentQueryString = window.location.search?.substring(1)

    if (querySyring === currentQueryString) {
      return
    }

    const newParam = getQueryParamObject(
      {
        path: newPath ? newPath : '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: param.count + 1,
      },
      _.cloneDeep(listFilters),
      listTitles
    )

    setParam(newParam)

    const newQuery = getParamQueryString(
      {
        path: newPath ? newPath : '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: 0,
      },
      newParam
    )

    history.replace({ pathname: location.pathname, search: newQuery })
  }, [filterParams])

  function onChangeParam(value) {
    value.count = value.count + 1

    if (!changeUrlQueryString) {
      setParam(value)
      return
    }

    const querySyring = getParamQueryString(
      {
        path: newPath ? newPath : '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: 0,
      },
      value
    )

    history.push({ pathname: location.pathname, search: querySyring })
  }

  function onChangeListFilters(list) {
    let tempParam = _.cloneDeep(param)
    tempParam.listFilters = list
    tempParam.page = 0
    onChangeParam(tempParam)
  }

  function onChangeListSort(sortItem) {
    let tempParam = _.cloneDeep(param)
    tempParam.sort = sortItem
    tempParam.direction = tempParam.direction === 'DESC' ? 'ASC' : 'DESC'
    onChangeParam(tempParam)
  }

  function onChangeListPage(page) {
    let tempParam = _.cloneDeep(param)
    tempParam.page = page
    onChangeParam(tempParam)
  }

  function onChangeListRowsPerPage(rowsPerPage) {
    let tempParam = _.cloneDeep(param)
    tempParam.page = 0
    tempParam.rowsPerPage = rowsPerPage
    onChangeParam(tempParam)
  }

  useEffect(() => {
    let tempParam = _.cloneDeep(param)
    const filter = newFilter ? _.cloneDeep(newFilter) : { id: params.id }
    tempParam.listFilters.forEach((filterItem) => {
      if (filterItem.value !== '' && filterItem.value !== undefined) {
        filter[filterItem.name] = filterItem.value
      }
    })

    getList(
      tempParam.path ? tempParam.path : constants.PATH.SHOP.RESTAURANT_SLIDESHOW,
      {
        sort: { field: tempParam.sort, order: tempParam.direction },
        filter: filter,
        pagination: { page: tempParam.page + 1, perPage: tempParam.rowsPerPage },
      },
      (payload) => {
        payload.forEach((item) => (item.mediaUrl = item.media?.cosFileUrl))
        payload.forEach((item) => {
          if (!item.restEnName) {
            return
          }
          item.name = generateTableCellToMultiLanguageName(item.restTcName, item.restEnName, item.restScName)
        })
        setRestauranttSlideshowList(payload)
        setReady(true)
      },
      undefined,
      store
    )
  }, [param.count]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleApplySlideshow = (id) => {
    setSourceSlideshowId(id)
    setOpenDrawer(true)
  }

  function onClickCloseDrawer() {
    setAddSlideshowMap(new Map())
    setOpenDrawer(false)
  }

  const handleMutiSelectMap = (id, dataList, row) => {
    // id = row.id;

    let tempMap = _.cloneDeep(selectMap)
    if (id === 'all') {
      if (!selectedAllCheck) {
        setSelectMap(addNewEntryToMap(tempMap, restaurantSlideshowList))
        //setSelectedAllCheck(true);
        return
      }

      restaurantSlideshowList.forEach((item) => tempMap.delete(item.id))
      setSelectMap(tempMap)
      //setSelectedAllCheck(false);
      return
    }

    if (selectMap.has(id)) {
      tempMap.delete(id)
    } else {
      tempMap.set(id, row)
    }

    setSelectMap(tempMap)
    checkAllSelected(tempMap)
  }

  const addNewEntryToMap = (map, array) => {
    for (const item of array) {
      map.set(item.id, item)
    }
    return map
  }

  const checkAllSelected = (map) => {
    let selectedPerPage = restaurantSlideshowList.filter((item) => {
      return map.has(item.id)
    }).length

    let currentPageEntry = restaurantSlideshowList.length

    if (selectedPerPage === currentPageEntry && selectedPerPage !== 0) {
      setSelectedAllCheck(true)
    } else {
      setSelectedAllCheck(false)
    }
  }

  // const onClickBackToEdit = () => {
  //   setDialog({
  //     open: false,
  //     dialogActions: []
  //   });
  // }

  const handleSlideshowOnSubmit = () => {
    onClickCloseDrawer()
    const replaceSlideshowList = Array.from(addSlideshowMap, ([name, value]) => name)
    dispatch(setLoading(true))
    put(
      constants.PATH.SLIDESHOW.REPLACE_SLIDESHOW + '/' + sourceSlideshowId,
      replaceSlideshowList,
      (payload) => {
        dispatch(setLoading(false))

        let tempParam = _.cloneDeep(param)
        onChangeParam(tempParam)
      },
      undefined,
      store
    )
  }

  useEffect(() => {
    if (!selectMap) {
      return
    }

    checkAllSelected(selectMap)
  }, [restaurantSlideshowList, selectMap])

  return (
    ready && (
      <>
        <Card className="card-wrapper overflow">
          <ListDisplay
            page={param.page}
            rowsPerPage={param.rowsPerPage}
            onChangeListSort={onChangeListSort}
            resource={resourceName}
            dataList={restaurantSlideshowList}
            sort={param.sort}
            direction={param.direction}
            listTitles={listTitles}
            listFilters={param.listFilters}
            mutipleSelect={mutipleSelect}
            onMultiSelectList={handleMutiSelectMap}
            selectMap={selectMap}
            selectedAllCheck={selectedAllCheck}
            idName={idName}
            dialogFooterBtn={dialogFooterBtn}
            onChangeListPage={onChangeListPage}
            onChangeListRowsPerPage={onChangeListRowsPerPage}
            editBtn={editBtn}
            createBtn={createBtn}
            idParam={params.id}
            btnList={
              btnList
                ? btnList
                : [
                    {
                      handleChange: handleApplySlideshow,
                      color: constants.STYLE.PRIMARY,
                      label: { key: 'apply' },
                      icon: <PlaylistAddCheckIcon />,
                    },
                  ]
            }
            onChangeListFilters={onChangeListFilters}
            redirectPath={'/' + (newRedirectPath ? newRedirectPath : constants.PATH.SHOP.RESTAURANT_SLIDESHOW) + '/'}
          />
        </Card>
        <Drawer classes={{ paper: 'drawer-margin-left' }} anchor="right" open={openDrawer} onClose={onClickCloseDrawer}>
          <RestaurantSlideshowList
            newListTitles={drawerListTitle}
            newListFilters={drawerFilterList}
            newPath={constants.PATH.SLIDESHOW.SLIDESHOW_ALL_LIST}
            newFilter={{ excludedId: sourceSlideshowId }}
            btnList={[]}
            editBtn={false}
            createBtn={false}
            mutipleSelect={true}
            selectMap={addSlideshowMap}
            setSelectMap={setAddSlideshowMap}
            idName="id"
            dialogFooterBtn={
              <div className="get-push-card-wrapper">
                <TempBtn
                  btnSetting={{
                    className: 'edit-create-btn',
                    variant: constants.STYLE.VARIANT.CONTAINED,
                    color: constants.STYLE.PRIMARY,
                    onClick: handleSlideshowOnSubmit,
                    label: { key: 'submit' },
                    icon: <AddIcon />,
                  }}
                />
                <TempBtn
                  btnSetting={{
                    className: 'edit-create-btn',
                    variant: constants.STYLE.VARIANT.CONTAINED,
                    color: constants.STYLE.PRIMARY,
                    onClick: onClickCloseDrawer,
                    label: { key: 'close' },
                    icon: <ClearIcon />,
                  }}
                />
              </div>
            }
          />
        </Drawer>
      </>
    )
  )
}

const drawerListTitle = [
  {
    key: constants.LIST_DISPLAY_TYPE.STRING,
    value: 'restUrlId',
    sort: true,
  },
  {
    key: constants.LIST_DISPLAY_TYPE.STRING,
    value: 'name',
    sort: true,
  },
  {
    key: constants.LIST_DISPLAY_TYPE.STRING,
    value: 'type',
    sort: true,
  },
  {
    key: constants.LIST_DISPLAY_TYPE.MEDIA,
    value: 'mediaUrl',
    sort: false,
    type: 'type',
  },
  {
    key: constants.LIST_DISPLAY_TYPE.DATE_TIME,
    value: 'startDisplayTimestamp',
    sort: true,
  },
  {
    key: constants.LIST_DISPLAY_TYPE.DATE_TIME,
    value: 'endDisplayTimestamp',
    sort: true,
  },
  {
    key: constants.LIST_DISPLAY_TYPE.STRING,
    value: 'status',
    sort: true,
  },
]

const drawerFilterList = [
  {
    className: 'filter-input',
    label: { resource: resourceName, key: 'restUrlId' },
    clear: true,
    value: '',
    name: 'restUrlId',
    size: constants.SIZE.SMALL,
  },
  {
    className: 'filter-input',
    label: { resource: resourceName, key: 'type' },
    value: '',
    type: constants.TYPE.SELECT,
    selectValue: constants.CHOICES.RESTAURANT_SLIDESHOW.TYPE,
    name: 'type',
    size: constants.SIZE.SMALL,
  },
  {
    className: 'filter-input',
    label: { resource: resourceName, key: 'status' },
    value: '',
    type: constants.TYPE.SELECT,
    selectValue: constants.CHOICES.RESTAURANT_SLIDESHOW.FILTER_STATUS,
    name: 'status',
    size: constants.SIZE.SMALL,
  },
  {
    className: 'filter-input',
    label: { resource: resourceName, key: 'showExpired' },
    value: false,
    type: constants.TYPE.SELECT,
    selectValue: constants.CHOICES.RESTAURANT_SLIDESHOW.SHOW_EXPIRED,
    name: 'showExpired',
    size: constants.SIZE.SMALL,
  },
]
