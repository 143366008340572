import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useSelector } from 'react-redux';
import { getString } from '../../helper/util';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { TempBtn } from '../../Component/Input/TempBtn';
import moment from 'moment';
import { useState } from 'react';
import { TempTextInput } from '../../Component/Input/TempTextInput';
import { constants } from '../../Constants/constants';

const resource = "QueueConfig";

export const ResetDialog = ({open, setOpen, onClickReset, resetTimeStamp, shopName}) => {

  const lang = useSelector(state => state.lang.ui.lang);
  const theme = useSelector(state => state.theme.ui.theme);
  const [ passCode, setPassCode ] = useState('');
  const [ showCode, ] = useState(generatePassCode());

  function handleDialogClose() {
    setOpen(false);
  }

  const passCodeInputTextField = {
    className: "edit-create-input",
    size: constants.SIZE.SMALL,
    label: { resource: resource, key: "passCode" },
    value: passCode,
    name: "passCode",
    isEndOfLine: true,
    max: 4
  }

  const onChangeInput = (name, e) => {
    setPassCode(e);
  }

  return (
    <Dialog
          open={open}
          onClose={ ()=>handleDialogClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth={"sm"}
      > 
        <DialogTitle id="alert-dialog-title">
          {shopName}
        </DialogTitle>

        <DialogContent>
            <DialogContentText component="span" id="alert-dialog-description">
                {
                  <>
                    <div>
                      {getString(lang, resource, "pleaseEnter", theme)}
                      <span className='passcode'>
                        {showCode}
                      </span>
                      {getString(lang, resource, "enterPassCode", theme)} :
                    </div>
                  </>
                }
                <div className=''>
                  <div className="edit-display-wrapper">
                    <TempTextInput inputSetting={passCodeInputTextField} handleChange={onChangeInput} />
                  </div>
                </div>
                  {
                  resetTimeStamp &&
                  <div className='dialog-remarks'>
                    {getString(lang, resource, "resetUpdateTimestamp", theme)}: {resetTimeStamp? moment(resetTimeStamp).format('YYYY-MM-DD HH:mm:ss') : ""}
                  </div>
                }
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            {[
              { label: { key: "confirm" }, onClick: ()=>{onClickReset(); handleDialogClose();}, icon: <CheckIcon />, disabled: passCode !== showCode },
              { label: { key: "cancelBtn" }, onClick: handleDialogClose, icon: <CloseIcon /> }
            ].map((item, index) => (
                <TempBtn key={"btn_" + index} btnSetting={item} />
            ))}
        </DialogActions>

    </Dialog>
  )
}

const generatePassCode = () => {

  let string = '';

  for(let i=0; i<4; i++) {
    string += ''+ Math.floor(Math.random() * (10))
  }

  return string

}
