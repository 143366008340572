import { BRAND_APPROVE } from './types';
import _ from "lodash";

export const setBrandApproveDefault = () => {
    return {
        type: BRAND_APPROVE.SET_BRAND_APPROVE_DEFAULT,
    }
}

export const setBrandApprove = (brandApprove) => {
    return {
        type: BRAND_APPROVE.SET_BRAND_APPROVE,
        brandApprove: brandApprove
    }
}

export const setBrandApproveValue = (draftType, orignalProduct, key, value, index) => {
    const brandApprove = _.cloneDeep(orignalProduct);
    brandApprove[draftType] = value;
    return {
        type: BRAND_APPROVE.SET_BRAND_APPROVE_VALUE,
        brandApprove: brandApprove
    }
}
