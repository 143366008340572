
import { useState, Fragment } from "react";

import { Drawer } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempBtn } from "../../Component/Input/TempBtn";
import { constants } from '../../Constants/constants';
import { DistrictList } from '../../Page/District/DistrictList';

import "../../styles/getDistrictId.scss";

export const GetRestaurantDistrictId = ({ savedData, sendDistrictId }) => {
  const [showDistrictTextPanel, setShowDistrictTextPanel] = useState(false);

  const handleDistrictTextClear = () => {
    sendDistrictId({
      districtId: null,
      districtTcName: null,
      districtEnName: null,
      districtScName: null,
    });
    setShowDistrictTextPanel(false);
  };

  const handleDistrictTextClick = () => {
    setShowDistrictTextPanel(true);
  };

  const handleDistrictTextCloseClick = () => {
    setShowDistrictTextPanel(false);
  };

  const onClickListRow = (data) => {
    setShowDistrictTextPanel(false);
    sendDistrictId(data);
  }

  return (
    <Fragment>
      <TempTextInput
        inputSetting={{
          InputLabelProps: { shrink: true },
          className: "edit-create-input",
          size: constants.SIZE.SMALL,
          label: {resource: "restaurant", key: "district"},
          value: savedData.districtTcName,
          variant: constants.STYLE.VARIANT.OUTLINED,
          onClickEndAdornmentIconButton: handleDistrictTextClick,
          disabled: true,
          clear: true,
        }}
        handleChange={handleDistrictTextClear}
      />
      <Drawer
        classes={{ paper: "drawer-margin-left" }}
        anchor="right"
        open={showDistrictTextPanel}
        onClose={handleDistrictTextCloseClick}
      >
        <DistrictList
          newListTitles={[
            {
              key: constants.LIST_DISPLAY_TYPE.STRING,
              value: "districtId",
              sort: true
            }, {
              key: constants.LIST_DISPLAY_TYPE.STRING,
              value: "name",
              sort: false
            }, {
              key: constants.LIST_DISPLAY_TYPE.STRING,
              value: "status",
              sort: true
            }
          ]}
          editBtn={false}
          createBtn={false}
          onClickListRow={onClickListRow}
          dialogFooterBtn={<>
            <TempBtn btnSetting={{
              variant: constants.STYLE.VARIANT.CONTAINED,
              color: constants.STYLE.PRIMARY,
              onClick: handleDistrictTextCloseClick,
              icon: <ClearIcon />,
              label: {key: "close"},
            }} />
          </>
          } />
      </Drawer>
    </Fragment>
  );
};