import _ from "lodash";
import { MERCHANT_CONFIG } from '../actions/types';
import { constants } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        merchantConfig: _.cloneDeep(constants.MERCHANT_CONFIG_INPUT)
    }
}

const merchantConfigReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MERCHANT_CONFIG.SET_MERCHANT_CONFIG_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case MERCHANT_CONFIG.SET_MERCHANT_CONFIG:
        case MERCHANT_CONFIG.SET_MERCHANT_CONFIG_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    merchantConfig: action.merchantConfig
                }
            })
        default:
            return state
    }
}

export default merchantConfigReducer