import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import CancelIcon from '@mui/icons-material/Cancel';

import { constants, IMAGE_TYPE, PICTURE_SIZE, UPLOAD_TYPE } from '../../../Constants/constants';
import { setRestaurantSlideshowValue } from "../../../actions/restaurantSlideshowAction";
import { EditCreateDisplay } from '../../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../../Component/Input/TempTextInput";
import { TempAutoSelectInput } from "../../../Component/Input/TempAutoSelectInput";
import { UploadImage } from "../../../Component/UploadImage/UploadImage";
import SlideshowBulkCreateValidation from "../../../validations/SlideshowBulkCreateValidation";
import { slideshowBulkCreateValidationConstants } from '../../../Constants/validationConstants';
import { helperTextStyles } from "../../../helper/util";

export const RestaurantSlideshowCreateForBulk = ({setSlideshowList, slideshowList}) => {
  const dispatch = useDispatch();
  const resource = "RestaurantSlideshow";
  const savedRestaurantSlideshow = useSelector(state => state.restaurantSlideshow.ui.restaurantSlideshow);
  const helperTextStylesClasses = helperTextStyles();

  const tempRestaurantMediaEditList = [
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "type" },
      value: savedRestaurantSlideshow.type,
      name: "type",
      isEndOfLine: false,
      disabled: true,
      variant: constants.STYLE.VARIANT.STANDARD,
      required: true,
      helperTextIcon: true,
      helperText: SlideshowBulkCreateValidation.getErrorMessages(`${savedRestaurantSlideshow.customId}-${slideshowBulkCreateValidationConstants.KEY_TYPE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "sequence" },
      value: savedRestaurantSlideshow.sequence,
      name: "sequence",
      type: constants.TYPE.NUMBER,
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: SlideshowBulkCreateValidation.getErrorMessages(`${savedRestaurantSlideshow.customId}-${slideshowBulkCreateValidationConstants.KEY_DISPLAY_SEQUENCE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      type: constants.TYPE.DATETIME_LOCAL,
      size: constants.SIZE.SMALL,
      name: "startDisplayTimestamp",
      label: { resource: resource, key: "startDisplayTimestamp" },
      value: savedRestaurantSlideshow.startDisplayTimestamp,
      step: 1,
      helperTextIcon: true,
      isEndOfLine: false,
      helperText: SlideshowBulkCreateValidation.getErrorMessages(`${savedRestaurantSlideshow.customId}-${slideshowBulkCreateValidationConstants.KEY_START_DISPLAY_TIMESTAMP}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      type: constants.TYPE.DATETIME_LOCAL,
      size: constants.SIZE.SMALL,
      name: "endDisplayTimestamp",
      label: { resource: resource, key: "endDisplayTimestamp" },
      value: savedRestaurantSlideshow.endDisplayTimestamp,
      step: 1,
      helperTextIcon: true,
      isEndOfLine: true,
      helperText: SlideshowBulkCreateValidation.getErrorMessages(`${savedRestaurantSlideshow.customId}-${slideshowBulkCreateValidationConstants.KEY_END_DISPLAY_TIMESTAMP}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "status" },
      value: savedRestaurantSlideshow.status,
      variant: constants.STYLE.VARIANT.STANDARD,
      disabled: true,
      name: "status",
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: SlideshowBulkCreateValidation.getErrorMessages(`${savedRestaurantSlideshow.customId}-${slideshowBulkCreateValidationConstants.KEY_STATUS}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      picture: savedRestaurantSlideshow.media?.cosFileUrl ? { cosFileUrl: savedRestaurantSlideshow.media.cosFileUrl } : null,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "media" },
      name: "media",
      type: constants.TYPE.IMAGE,
      uploadImagePath: constants.PATH.SHOP.UPLOAD_RESTAURANT_MEDIA,
      saveImage: handleSaveImage,
      isEndOfLine: false,
    },
  ]
  const content = [
    {
      tab: <>
        {SlideshowBulkCreateValidation.getErrorMessages(slideshowBulkCreateValidationConstants.KEY_MEDIA_KEY) &&
          SlideshowBulkCreateValidation.getErrorMessages(slideshowBulkCreateValidationConstants.KEY_MEDIA_KEY).length > 0 &&
          <div className="error-message">
            <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
            {SlideshowBulkCreateValidation.getErrorMessages(slideshowBulkCreateValidationConstants.KEY_MEDIA_KEY)}
          </div>
        }
        {tempRestaurantMediaEditList.map((inputSetting, index) => {
          let content;
          switch (inputSetting.type) {
            case constants.TYPE.IMAGE:
              content =
                <div className="image">
                  <UploadImage
                    picture={inputSetting.picture}
                    type={IMAGE_TYPE.SLIDESHOW}
                    title={inputSetting.label}
                    fileType={savedRestaurantSlideshow.type}
                    name={inputSetting.name}
                    uploadImagePath={inputSetting.uploadImagePath}
                    saveImage={inputSetting.saveImage}
                    required={inputSetting.required} 
                    uploadType={UPLOAD_TYPE.FORMDATA}
                    allowedFileExtensionList={['image/*', 'video/mp4']}
                    size = {PICTURE_SIZE.MID}/>
                </div>
              break;
            case constants.TYPE.SELECT:
              content =
                <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
              break;
            default:
              content =
                <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
          }
          return <div className="edit-display-wrapper" key={index}>
            {inputSetting.name === "media" &&
              SlideshowBulkCreateValidation.getErrorMessages(`${savedRestaurantSlideshow.customId}-${slideshowBulkCreateValidationConstants.KEY_COS_FILE_URL}`) &&
              SlideshowBulkCreateValidation.getErrorMessages(`${savedRestaurantSlideshow.customId}-${slideshowBulkCreateValidationConstants.KEY_COS_FILE_URL}`).length > 0 &&
              <div className="error-message">
                <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                {SlideshowBulkCreateValidation.getErrorMessages(`${savedRestaurantSlideshow.customId}-${slideshowBulkCreateValidationConstants.KEY_COS_FILE_URL}`)}
              </div>
            }
            {content}
            {inputSetting.isEndOfLine && <div></div>}
          </div>
        })}
      </>,
      btn: false
    },
  ]

  function handleSaveImage(name, image) {
    dispatch(setRestaurantSlideshowValue("MEDIA", savedRestaurantSlideshow, "", image));
  }

  function onChangeInput(name, e) {
    switch (name) {
      case "sequence":
        dispatch(setRestaurantSlideshowValue(name, savedRestaurantSlideshow, "", Number.isInteger(Number(e)) ? Number(e) : 0));
        break;
      case "startDisplayTimestamp":
      case "endDisplayTimestamp":
        const date = Number(moment(e).format("x"));
        dispatch(setRestaurantSlideshowValue(name, savedRestaurantSlideshow, "", date));
        break;
      default:
        dispatch(setRestaurantSlideshowValue(name, savedRestaurantSlideshow, "", e));
    }
  }

  useEffect(() => {

    const tempSlideshowList = [...slideshowList];
    tempSlideshowList.forEach((slideshow, index)=>{
      if(slideshow.customId === savedRestaurantSlideshow.customId){
        tempSlideshowList[index] = savedRestaurantSlideshow;
      }
    })

    setSlideshowList(tempSlideshowList)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedRestaurantSlideshow])
  

  return (
    <>
      <EditCreateDisplay
        code={savedRestaurantSlideshow && savedRestaurantSlideshow.customId ? savedRestaurantSlideshow.customId : ""}
        resource={resource}
        tabs={false}
        content={content}
        onChangeInput={onChangeInput}
        display="create"
      />
    </>
  )
};