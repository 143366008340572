import { TABLE_TYPE_DEFAULT_VALUE } from '../../Constants/constants'
import { getTableTypeBackgroundColor, getTableTypeForegroundColor } from '../../helper/util'

export const getIsSyncMapForStartAndEndTimeFields = (tableTypeList) => {
  if (!tableTypeList || tableTypeList.length === 0) {
    return
  }

  const map = new Map()

  // Sort Table Type To A, AA, AB, B, BA, BB etc
  const sortedTableTypeList = tableTypeList.sort((a, b) => a.timeSectionId - b.timeSectionId)

  let startTimeSet = new Set()
  let endTimeSet = new Set()
  let timeSectionDescriptionSet = new Set()
  let prevTimeSectionId = sortedTableTypeList[0].timeSectionId

  for (const tableType of sortedTableTypeList) {
    /* Find is there value set in all table types */
    if (tableType.timeSectionId !== prevTimeSectionId) {
      map.set(prevTimeSectionId, Math.max(startTimeSet.size, endTimeSet.size, timeSectionDescriptionSet.size) === 1)

      startTimeSet = new Set()
      endTimeSet = new Set()
      timeSectionDescriptionSet = new Set()
      prevTimeSectionId = tableType.timeSectionId // Set To Previous timeSectionId
    }

    startTimeSet.add(tableType.startTime)
    endTimeSet.add(tableType.endTime)
    timeSectionDescriptionSet.add(tableType.timeSectionDescription)
  }

  map.set(prevTimeSectionId, Math.max(startTimeSet.size, endTimeSet.size, timeSectionDescriptionSet.size) === 1)

  return map
}

export const getIsSyncMapForRequestTicketTimeFields = (tableTypeList) => {
  if (!tableTypeList || tableTypeList.length === 0) {
    return
  }

  const isSyncMap = new Map()

  // Sort Table Type To A, AA, AB, B, BA, BB etc
  const sortedTableTypeList = tableTypeList.sort((a, b) => a.timeSectionId - b.timeSectionId)

  let kioskRequestTicketStartTimeSet = new Set()
  let kioskRequestTicketEndTimeSet = new Set()

  let mobileRequestTicketStartTimeSet = new Set()
  let mobileRequestTicketEndTimeSet = new Set()

  let prevTimeSectionId = sortedTableTypeList[0].timeSectionId

  for (const tableType of sortedTableTypeList) {
    /* Find is there value set in all table types */
    if (tableType.timeSectionId !== prevTimeSectionId) {
      isSyncMap.set(
        prevTimeSectionId,
        Math.max(
          kioskRequestTicketStartTimeSet.size,
          kioskRequestTicketEndTimeSet.size,
          mobileRequestTicketStartTimeSet.size,
          mobileRequestTicketEndTimeSet.size
        ) === 1
      )

      kioskRequestTicketStartTimeSet = new Set()
      kioskRequestTicketEndTimeSet = new Set()
      mobileRequestTicketStartTimeSet = new Set()
      mobileRequestTicketEndTimeSet = new Set()

      prevTimeSectionId = tableType.timeSectionId // Set To Previous timeSectionId
    }

    kioskRequestTicketStartTimeSet.add(tableType.kioskRequestTicketStartTime)
    kioskRequestTicketEndTimeSet.add(tableType.kioskRequestTicketEndTime)
    mobileRequestTicketStartTimeSet.add(tableType.mobileRequestTicketStartTime)
    mobileRequestTicketEndTimeSet.add(tableType.mobileRequestTicketEndTime)
  }

  isSyncMap.set(
    prevTimeSectionId,
    Math.max(
      kioskRequestTicketStartTimeSet.size,
      kioskRequestTicketEndTimeSet.size,
      mobileRequestTicketStartTimeSet.size,
      mobileRequestTicketEndTimeSet.size
    ) === 1
  )

  return isSyncMap
}

export const getIsSyncTimeFieldsWithAllTableTypes = (timeSectionCustomIdFromTableType, savedQueueTemplate) => {
  if (!savedQueueTemplate) {
    return false
  }

  let idx = savedQueueTemplate.findIndex((timeSection) => timeSection.customId === timeSectionCustomIdFromTableType)

  if (!savedQueueTemplate[idx]) {
    return false
  }

  return savedQueueTemplate[idx].isSyncTimeFieldsWithAllTableTypes
}

export const getIsSyncRequestTicketTimeFieldsWithAllTableTypes = (timeSectionCustomIdFromTableType, savedQueueTemplate) => {
  if (!savedQueueTemplate) {
    return false
  }

  let idx = savedQueueTemplate.findIndex((timeSection) => timeSection.customId === timeSectionCustomIdFromTableType)

  if (!savedQueueTemplate[idx]) {
    return false
  }

  return savedQueueTemplate[idx].isSyncRequestTicketTimeFieldsWithAllTableTypes
}

export const getDefaultTableType = (parentQueueTemplate, tableTypeListLength, colorList) => {
  if (!parentQueueTemplate || !parentQueueTemplate.isSyncTimeFieldsWithAllTableTypes) {
    return {
      ...TABLE_TYPE_DEFAULT_VALUE,
      displayTableTypeForegroundColor: getTableTypeForegroundColor(null, tableTypeListLength ? tableTypeListLength : 0, colorList),
      displayTableTypeBackgroundColor: getTableTypeBackgroundColor(null, tableTypeListLength ? tableTypeListLength : 0, colorList),
    }
  }

  return {
    ...TABLE_TYPE_DEFAULT_VALUE,
    displayTableTypeForegroundColor: getTableTypeForegroundColor(null, tableTypeListLength ? tableTypeListLength : 0, colorList),
    displayTableTypeBackgroundColor: getTableTypeBackgroundColor(null, tableTypeListLength ? tableTypeListLength : 0, colorList),
    startTime: parentQueueTemplate.startTime,
    endTime: parentQueueTemplate.endTime,
    timeSectionDescription: parentQueueTemplate.timeSectionDescription,
  }
}

export const getTableTypeID = (currentTableTypeList) => {
  if (!currentTableTypeList || currentTableTypeList.length === 0) {
    return 'A'
  }

  const prevTableType = currentTableTypeList[currentTableTypeList.length - 1].tableType

  if (!prevTableType) {
    return ''
  }

  return convertToNumberingScheme(toDecimal(prevTableType) + 1)
}

export const getTimeSectionID = (currentTimeSectionList) => {
  if (!currentTimeSectionList || currentTimeSectionList.length === 0) {
    return 'A'
  }

  const prevTimeSectionId = currentTimeSectionList[currentTimeSectionList.length - 1].timeSectionId

  if (!prevTimeSectionId) {
    return ''
  }

  return convertToNumberingScheme(toDecimal(prevTimeSectionId) + 1)
}

export const isHexCodeValidate = (code) => {
  if (!code) {
    return false
  }

  const regex = /^#[A-Fa-f0-9]{6}$/
  return regex.test(code)
}

export const sortList = (array) => {
  return array.sort(function (a, b) {
    return a.tableType.length - b.tableType.length || a.tableType.localeCompare(b.tableType)
  })
}

const convertToNumberingScheme = (number) => {
  var baseChar = 'A'.charCodeAt(0),
    letters = ''

  do {
    number -= 1
    letters = String.fromCharCode(baseChar + (number % 26)) + letters
    number = (number / 26) >> 0 // quick `floor`
  } while (number > 0)

  return letters
}

const toDecimal = (str) => {
  var decimal = 0
  var letters = str.split(new RegExp())

  for (var i = letters.length - 1; i >= 0; i--) {
    decimal += (letters[i].charCodeAt(0) - 64) * Math.pow(26, letters.length - (i + 1))
  }

  return decimal
}

export const reorderTableTypeList = (tableTypeList, colorList) => {
  if (!tableTypeList?.length) {
    return
  }

  let reorderedTableTypeList = [...tableTypeList]

  for (let i = 0; i < tableTypeList.length; i++) {
    reorderedTableTypeList[i].tableType = convertToNumberingScheme(i + 1)
  }

  reorderedTableTypeList = getReAssignDefaultColorTableTypeList({
    tableTypeList: reorderedTableTypeList,
    startIndex: 0,
    endIndex: reorderedTableTypeList.length,
    colorList: colorList,
  })

  return reorderedTableTypeList
}

export const reorderTimeSectionList = (timeSection) => {
  if (!timeSection?.length) {
    return
  }

  const reorderedTimeSectionList = [...timeSection]

  for (let i = 0; i < timeSection.length; i++) {
    reorderedTimeSectionList[i].timeSectionId = convertToNumberingScheme(i + 1)
  }

  return reorderedTimeSectionList
}

export const getReAssignDefaultColorTableTypeList = ({ tableTypeList, startIndex, endIndex, colorList }) => {
  if (!tableTypeList?.length) {
    return []
  }
  const reAssignedList = [...tableTypeList]

  for (let i = startIndex; i < endIndex; i++) {
    reAssignedList[i].displayTableTypeForegroundColor = getTableTypeForegroundColor(reAssignedList[i].tableTypeForegroundColor, i, colorList)
    reAssignedList[i].displayTableTypeBackgroundColor = getTableTypeBackgroundColor(reAssignedList[i].tableTypeBackgroundColor, i, colorList)
  }

  return reAssignedList
}
