
import { useState } from "react";
import { useSelector } from "react-redux";
// import _ from "lodash";

import { Drawer } from "@mui/material";
import Paper from '@mui/material/Paper';
import ClearIcon from '@mui/icons-material/Clear';

import { constants } from '../../../Constants/constants';
import { helperTextStyles } from "../../../helper/util";
import { TempTextInput } from "../../../Component/Input/TempTextInput";
import { TempAutoSelectInput } from "../../../Component/Input/TempAutoSelectInput";
import { TempBtn } from "../../../Component/Input/TempBtn";
import MerchantUserValidation from "../../../validations/merchantUserValidation";
import { merchantUserValidationConstants } from '../../../Constants/validationConstants';
import { SubTabEditCreateDisplay } from '../../../Component/EditCreateDisplay/SubTabEditCreateDisplay';
import { RestaurantList } from "../../Restaurant/RestaurantList";
import { UserGroupList } from "../../../Component/ProductCreate/UserGroup/UserGroupList";


const LOCAL_CONSTANT = {
  PRIVILEGE_ATTRIBUTE: {
    SHOP: "SHOP",
    GROUP: "GROUP",
  },
  PRIVILEGE_RESTURLID: "privilegeRestUrlId",
  PRIVILEGE_GROUP_CODE : "privilegeGroupCode",
}

export const PrivilegeSetting = ({ onChangeInput, id, hidePrivilegeRestUrlIdEditButton }) =>{

  const resource = "MerchantUser";
  const savedMerchantUser = useSelector(state => state.merchantUser.ui.merchantUser);
  // const lang = useSelector(state => state.lang.ui.lang);
  // const theme = useSelector(state => state.theme.ui.theme);
  // const [errorVersion, setErrorVersion] = useState(MerchantUserValidation.getVersion());
  const helperTextStylesClasses = helperTextStyles();
  const [drawerType, setDrawerType] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  function onClickCloseDrawer() {
    setDrawerOpen(false);
  }

  const handleOnClickDrawer=(type)=>{
    setDrawerType(type);
    setDrawerOpen(true);
    handleDrawer();
  }

  const onClickListRow=(data, type)=> {
    onChangeInput(type, data)    
    onClickCloseDrawer();
  }

  const handleDrawer=()=> {
    let drawer;
    switch (drawerType) {
      case LOCAL_CONSTANT.PRIVILEGE_RESTURLID:
        drawer =
          <Paper>
            <RestaurantList
              editBtn={false}
              createBtn={false}
              btnList={false}
              onClickListRow={(item) => onClickListRow(item.restUrlId, drawerType)}
              dialogFooterBtn={<>
                <TempBtn btnSetting={{
                  variant: constants.STYLE.VARIANT.CONTAINED,
                  color: constants.STYLE.PRIMARY,
                  onClick: onClickCloseDrawer,
                  icon: <ClearIcon />,
                  label: { key: "close" },
                }} />
              </>
              }
            />
          </Paper>
        break;
      case LOCAL_CONSTANT.PRIVILEGE_GROUP_CODE:
        drawer =
          <Paper>
            <UserGroupList
              editBtn={false}
              createBtn={false}
              onClickListRow={(item) => onClickListRow(item.groupCode, drawerType)}
              dialogFooterBtn={<>
                <TempBtn btnSetting={{
                  variant: constants.STYLE.VARIANT.CONTAINED,
                  color: constants.STYLE.PRIMARY,
                  onClick: onClickCloseDrawer,
                  icon: <ClearIcon />,
                  label: { key: "close" },
                }} />
              </>
              }
            />
          </Paper>
        break;
      default:
        break;
  }
  return drawer;
}

  const tempEditList = [
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "privilegeAttribute" },
      value: savedMerchantUser.privilegeAttribute,
      disableClearable: true,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.MERCHANT_USER.PRIVILEGE_ATTRIBUTE,
      name: "privilegeAttribute",
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: MerchantUserValidation.getErrorMessages(`${id}-${merchantUserValidationConstants.KEY_PRIVILEGE_ATTRIBUTE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: `edit-create-input ${savedMerchantUser.privilegeAttribute === LOCAL_CONSTANT.PRIVILEGE_ATTRIBUTE.SHOP? "": "display-none"}`, 
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "privilegeRestUrlId" },
      value: savedMerchantUser.privilegeRestUrlId,
      name: "privilegeRestUrlId",
      disabled: true,
      isEndOfLine: false,
      required: true,
      onClickEndAdornmentIconButton: hidePrivilegeRestUrlIdEditButton ? null : ()=>handleOnClickDrawer(LOCAL_CONSTANT.PRIVILEGE_RESTURLID),
      helperTextIcon: true,
      helperText: MerchantUserValidation.getErrorMessages(`${id}-${merchantUserValidationConstants.KEY_PRIVILEGE_RESTURLID}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: `edit-create-input`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "privilegeGroupCode" },
      value: savedMerchantUser.privilegeGroupCode,
      name: "privilegeGroupCode",
      disabled: true,
      isEndOfLine: true,
      required: false,
      onClickEndAdornmentIconButton: ()=>handleOnClickDrawer(LOCAL_CONSTANT.PRIVILEGE_GROUP_CODE),
      helperTextIcon: true,
      helperText: MerchantUserValidation.getErrorMessages(`${id}-${merchantUserValidationConstants.KEY_PRIVILEGE_GROUP_CODE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
  ]

  const content = [
    {
      tab: tempEditList.map((inputSetting, index) => {
        let content;
        switch (inputSetting.type) {
          case constants.TYPE.SELECT:
            content =
              <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
            break;
          default:
            content =
              <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
        }
        return <div className="edit-display-wrapper" key={index}>
          {content}
          {inputSetting.isEndOfLine && <div></div>}
        </div>
      }),
      btn: false
    },
  ]

  return (
    <>
      <SubTabEditCreateDisplay
        code={""}
        resource={resource}
        tabs={false}
        content={content}
        onChangeInput={onChangeInput}
        display={"none"}
      />
      <Drawer
        classes={{ paper: "drawer-margin-left" }}
        anchor="right"
        open={drawerOpen}
        onClose={onClickCloseDrawer}
      >
        {handleDrawer()}
      </Drawer>
    </>  
  )
}