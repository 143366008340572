import { useState, useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { makeStyles } from '@mui/styles';

import { constants } from '../../Constants/constants';
import { post } from "../../helper/baseFetch";
import { setBrandGroup, setBrandGroupValue } from "../../actions/brandGroupAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempCustomCheckBox } from "../../Component/Custom/TempCustomCheckBox";
import { DialogWrapper } from "../../Component/DialogWrapper";
import BrandGroupValidation from "../../validations/brandGroupValidation";
import { brandGroupValidationConstants } from '../../Constants/validationConstants';

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const BrandGroupCreate = ({ onCancel }) => {
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();
  const [clear, setClear] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const resource = "BrandGroup";
  const savedBrandGroup = useSelector(state => state.BrandGroup.ui.BrandGroup)
  const [brandGroupInputList, setBrandGroupInputList] = useState(_.cloneDeep(constants.BRAND_GROUP_INPUT));
  const helperTextStylesClasses = helperTextStyles();
  const [, setErrorVersion] = useState(BrandGroupValidation.getVersion());

  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.BRAND_GROUP.INFO
    },
  ]
  const tempBrandGroupEditList = [
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "enName" },
      value: savedBrandGroup.enName,
      name: "enName",
      custom: true,
      customName: { key: "nameCustomize" },
      customValue: savedBrandGroup.scNameDisable,
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: BrandGroupValidation.getErrorMessages(brandGroupValidationConstants.KEY_EN_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "tcName" },
      value: savedBrandGroup.tcName,
      name: "tcName",
      translateName: savedBrandGroup.scNameDisable ? ["scName"] : null,
      inputList: brandGroupInputList,
      setInputList: setBrandGroupInputList,
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: BrandGroupValidation.getErrorMessages(brandGroupValidationConstants.KEY_TC_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "scName" },
      value: savedBrandGroup.scName,
      name: "scName",
      inputList: brandGroupInputList,
      setInputList: setBrandGroupInputList,
      isEndOfLine: true,
      disabled: savedBrandGroup.scNameDisable,
      required: true,
      helperTextIcon: true,
      helperText: BrandGroupValidation.getErrorMessages(brandGroupValidationConstants.KEY_SC_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
  ]
  const content = [
    {
      tab: tempBrandGroupEditList.map((inputSetting, index) => {
        return <div className="edit-display-wrapper" key={index}>
          {inputSetting.custom && <TempCustomCheckBox className="customize-btn" value={inputSetting.customValue} customName={inputSetting.customName} onSelectCheckbox={onSelectCheckbox} />}
          <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
          {inputSetting.isEndOfLine && <div></div>}
        </div>
      }),
      btn: true
    }
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  function onSelectCheckbox(value) {
    dispatch(setBrandGroupValue("scNameDisable", savedBrandGroup, "", value));
  }

  const onClickBackToCreate = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    onClickClear();
  };

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.SHOP.BRAND_GROUP);
  };

  function onChangeInput(name, e) {
    if (savedBrandGroup.scNameDisable === true && name === "tcName") {
      dispatch(setBrandGroupValue("CHANGE_TC_NAME_AND_SC_NAME", savedBrandGroup, "", e));
    } else {
      dispatch(setBrandGroupValue(name, savedBrandGroup, "", e));
    }
  }

  function onClickSave() {
    BrandGroupValidation.validateCreateBrandGroup(savedBrandGroup);
    if (BrandGroupValidation.isValid()) {
      post(constants.PATH.SHOP.BRAND_GROUP, savedBrandGroup, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToCreate,
          dialogContentText: { key: "createSuccess" },
          dialogActions: onCancel ? [
            { label: { key: "backCreate" }, onClick: onClickBackToCreate, icon: <ArrowBackIcon /> },
            { label: { key: "backEdit" }, onClick: onCancel, icon: <LowPriorityIcon /> }
          ] : [
            { label: { key: "backCreate" }, onClick: onClickBackToCreate, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      BrandGroupValidation.resetErrorMessages();
    } else {
      setErrorVersion(BrandGroupValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    let tempInput = _.cloneDeep(constants.BRAND_GROUP_INPUT);
    delete tempInput.groupId;
    dispatch(setBrandGroup(tempInput));
    setClear(false)
    BrandGroupValidation.resetErrorMessages();
  }, [clear === true]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <EditCreateDisplay
        code=""
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        display="create"
      />
      {dialog && dialog.open &&
        <DialogWrapper dialogInfo={dialog} />
      }
    </>
  )
};
