import Popover from '@mui/material/Popover';
import { useState } from 'react';
import { HexColorPicker } from "react-colorful";
import _ from "lodash";
import { Lens } from '@mui/icons-material';

export const QueueTemplateColorPicker = ({color, setColor}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleColor = (color) => {
    setColor(color?.toUpperCase());
  }

  return (
    <>
      <Lens className="color-picker-shadow" style={{color: color || '#FFFFFF'}} onClick={handleOpen}/>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className='color-picker-wrapper'>
        <HexColorPicker color={color || '#FFFFFF' } onChange={_.debounce(handleColor, 150)} />
        </div>
      </Popover>
    </>
  )
}