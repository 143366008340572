import { counterValidationConstants as constants } from '../Constants/validationConstants'
class CounterValidation {
  version
  errMap
  valid

  constructor() {
    if (!CounterValidation.instance) {
      this.version = 0
      this.valid = true
      this.errMap = new Map()
      CounterValidation.instance = this
    }
    return CounterValidation.instance
  }

  validateSavedData(savedData) {
    this.resetErrMsg()
    this.validateObj(savedData)
    this.version++
  }

  validateObj(savedData) {
    if (savedData.name === null) {
      this.setErrMsgMap(constants.KEY_NAME, 'Missing Name')
    }
    if (!savedData.templateCode) {
      this.setErrMsgMap(constants.KEY_TEMPLATE_CODE, 'Missing Sound Template Code')
    }
  }

  resetErrMsg() {
    this.errMap = new Map()
    this.valid = true
  }

  getVersion() {
    return this.version
  }

  isValid() {
    return this.valid
  }

  getErrMsg(key, uniqueKey) {
    if (!uniqueKey) {
      return this.errMap.has(key) ? this.errMap.get(key) : []
    } else {
      return []
    }
  }

  setErrMsgMap(key, errMsg, valid = false) {
    this.errMap.set(key, errMsg)
    if (!valid) {
      this.valid = false
    }
  }
}

const validation = new CounterValidation()
export default validation
