import { constants } from '../../Constants/constants';
import { UploadImage } from '../UploadImage/UploadImage';
import { TempBtn } from '../Input/TempBtn';
import { TempAutoSelectInput } from '../Input/TempAutoSelectInput';
import { TempTextInput } from '../Input/TempTextInput';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import { getString } from '../../helper/util';
import CancelIcon from '@mui/icons-material/Cancel';
import { TempCheckBox } from '../Input/TempCheckBox';
import { TempRadioGroup } from '../Input/TempRadioGroup';

export const getContent = (inputSetting, index, onChangeInput, lang, theme) =>{
  
  switch (inputSetting.type) {
    case constants.TYPE.IMAGE_MULTI:
      function handleContent(newPicture, index) {
        return <div className="image" key={index}>
          <UploadImage
            picture={newPicture}
            title={inputSetting.label}
            name={inputSetting.name}
            uploadImagePath={inputSetting.uploadImagePath}
            saveImage={inputSetting.saveImage}
            disableInput={inputSetting.disableInput} 
            type = ""/>
        </div>
      }
      return inputSetting.picture && inputSetting.picture.length > 0
        ? inputSetting.picture.map((pictureValue, index) => {
          return handleContent(pictureValue, index);
        })
        : handleContent();
    case constants.TYPE.IMAGE:
      return(
      <div className="image">
        <UploadImage
          picture={inputSetting.picture}
          title={inputSetting.label}
          name={inputSetting.name}
          uploadImagePath={inputSetting.uploadImagePath}
          saveImage={inputSetting.saveImage}
          required={inputSetting.required} 
          index={index} />
      </div>
      )
    case constants.TYPE.CHECKBOX:
      return <span className="check-box-wrapper">
      <TempCheckBox
        checkBoxSetting={{
          className: inputSetting.className,
          name: inputSetting.name,
          label: inputSetting.label,
          value: inputSetting.value,
        }}
        handleChange={onChangeInput} />
    </span>;
    case constants.TYPE.SELECT:
      return <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} index={index} />
    case constants.TYPE.BUTTON:
      return <TempBtn btnSetting={inputSetting} index={index} />
    case constants.TYPE.FUNCTION:
      return inputSetting.value;
    case constants.TYPE.TITLE:
      return <SubTitle inputSetting={inputSetting} lang={lang} theme={theme}/>
    case constants.TYPE.RADIO_BUTTON:
      return <TempRadioGroup inputSetting={inputSetting} handleChange={onChangeInput}/>
    case constants.TYPE.DATETIME_LOCAL:
    case constants.TYPE.NUMBER:
    default:
      return <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} index={index} />
  }
}

export const getDefaultBottomBtnList = (onClickSave, onClickClear) =>{

  return [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]
}

export const onClickBackToList = (setDialog, path, history) => {
  setDialog({
    open: false,
    dialogActions: []
  })
  history.push("/" + path);
};

export const SubTitle = ({inputSetting, lang,  theme}) =>{
  return(
      <>
      <div className={`${inputSetting.className + " sub-title"}`}>{getString(lang, inputSetting.value.resource, inputSetting.value.key, theme)}</div>
      {inputSetting.helperText && inputSetting.helperText.length > 0 &&
        <div className="error-message">
          {inputSetting.helperTextIcon && <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />}
          {inputSetting.helperText}
        </div >}
      </>
  )
}