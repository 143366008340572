
import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector, useStore } from "react-redux";
import moment from 'moment';

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { makeStyles } from '@mui/styles';

import { constants } from '../../Constants/constants';
import { put } from "../../helper/baseFetch";
import { getOne } from "../../helper/baseFetch";
import { setRestaurantTagMap, setRestaurantTagMapValue } from "../../actions/restaurantTagMapAction";
import { EditCreateDisplay } from '../EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../Input/TempTextInput"
import { TempAutoSelectInput } from "../Input/TempAutoSelectInput"
import { DialogWrapper } from "../DialogWrapper";
import RestaurantMapValidation from "../../validations/restaurantMapValidation";
import { restaurantMapValidationConstants } from '../../Constants/validationConstants';

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const RestaurantMapEdit = ({ resourceName, pathName, backListName }) => {
  const dispatch = useDispatch();
  const store = useStore();
  const params = useParams();
  const history = useHistory();
  const [ready, setReady] = useState(false);
  const [clear, setClear] = useState(true);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const savedRestaurantTag = useSelector(state => state.restaurantTag.ui.restaurantTag)
  const savedRestaurantTagMap = useSelector(state => state.restaurantTagMap.ui.restaurantTagMap)
  const helperTextStylesClasses = helperTextStyles();
  const [, setErrorVersion] = useState(RestaurantMapValidation.getVersion());

  const tabs = [
    {
      label: { resource: resourceName, key: "info" },
      tabIndex: constants.AREA_EDIT_TAB.INFO
    },
  ]
  const tempAreaEditList = [
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resourceName, key: "restUrlId" },
      value: savedRestaurantTagMap.restUrlId,
      type: constants.TYPE.NUMBER,
      min: 0,
      name: "restUrlId",
      disabled: true,
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: RestaurantMapValidation.getErrorMessages(restaurantMapValidationConstants.KEY_REST_URL_ID),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resourceName, key: "restEnName" },
      value: savedRestaurantTagMap.restEnName,
      name: "restEnName",
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resourceName, key: "restTcName" },
      value: savedRestaurantTagMap.restTcName,
      name: "restTcName",
      disabled: true,
      isEndOfLine: false
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resourceName, key: "restScName" },
      value: savedRestaurantTagMap.restScName,
      name: "restScName",
      disabled: true,
      isEndOfLine: true
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resourceName, key: "startTimestamp" },
      value: savedRestaurantTagMap.startTimestamp ? moment(savedRestaurantTagMap.startTimestamp).format("YYYY-MM-DDTHH:mm") : "",
      type: constants.TYPE.DATETIME_LOCAL,
      step: 1,
      clear: true,
      name: "startTimestamp",
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resourceName, key: "endTimestamp" },
      value: savedRestaurantTagMap.endTimestamp ? moment(savedRestaurantTagMap.endTimestamp).format("YYYY-MM-DDTHH:mm") : "",
      type: constants.TYPE.DATETIME_LOCAL,
      step: 1,
      clear: true,
      name: "endTimestamp",
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resourceName, key: "sequence" },
      value: savedRestaurantTagMap.sequence,
      type: constants.TYPE.NUMBER,
      min: 0,
      name: "sequence",
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resourceName, key: "status" },
      value: savedRestaurantTagMap.status,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.RESTAURANT_TAG.STATUS,
      name: "status",
      isEndOfLine: true,
      disableClearable: true,
      required: true,
      helperTextIcon: true,
      helperText: RestaurantMapValidation.getErrorMessages(restaurantMapValidationConstants.KEY_STATUS),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
  ]
  const content = [
    {
      tab: tempAreaEditList.map((inputSetting, index) => {
        let content;
        switch (inputSetting.type) {
          case constants.TYPE.SELECT:
            content =
              <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
            break;
          default:
            content =
              <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
        }
        return <div className="edit-display-wrapper" key={index}>
          {content}
          {inputSetting.isEndOfLine && <div></div>}
        </div>
      }),
      btn: true
    },
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    onClickClear();
  };
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + backListName + "/" + savedRestaurantTag.id + "/" + constants.TAG_EDIT_TAB.RESTAURANT_MAP_LIST);
  };

  function onChangeInput(name, e) {
    if (name === "startTimestamp" || name === "endTimestamp") {
      dispatch(setRestaurantTagMapValue(name, savedRestaurantTagMap, "", Number(moment(e).format("x"))));
    } else if (name === "sequence") {
      dispatch(setRestaurantTagMapValue(name, savedRestaurantTagMap, "", Number.isInteger(Number(e)) ? Number(e) : 0));
    } else {
      dispatch(setRestaurantTagMapValue(name, savedRestaurantTagMap, "", e));
    }
  }

  function onClickSave() {
    RestaurantMapValidation.handleValidateEditRestaurantMap(savedRestaurantTagMap);
    if (RestaurantMapValidation.isValid()) {
      put(pathName + "/" + params.id, savedRestaurantTagMap, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToEdit,
          dialogContentText: { key: "editSuccess" },
          dialogActions: [
            { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      RestaurantMapValidation.resetErrorMessages();
    } else {
      setErrorVersion(RestaurantMapValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    if (clear === true) {
      getOne(pathName + "/" + params.id, undefined, (payload) => {
        dispatch(setRestaurantTagMap(payload));
        setReady(true)
        setClear(false)
      }, undefined, store);
    }
    RestaurantMapValidation.resetErrorMessages();
  }, [clear === true]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <>
      <EditCreateDisplay
        code=""
        resource={resourceName}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        mapName={{ resource: resourceName, key: "tagCode" }}
        mapCode={savedRestaurantTag.tagCode}
        display="edit"
      />
      {dialog && dialog.open &&
        <DialogWrapper dialogInfo={dialog} />
      }
    </>
  )
};