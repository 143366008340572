import { COUNTER_PROFILE } from './types'
import _ from 'lodash'
import { sify } from 'chinese-conv'

export const setCounterProfileDefault = () => {
  return {
    type: COUNTER_PROFILE.SET_COUNTER_PROFILE_DEFAULT,
  }
}

export const setCounterProfile = (counterProfile) => {
  return {
    type: COUNTER_PROFILE.SET_COUNTER_PROFILE,
    counterProfile: counterProfile,
  }
}

export const setCounterProfileValue = (draftType, orignalProduct, key, value, index) => {
  const counterProfile = _.cloneDeep(orignalProduct)
  switch (draftType) {
    case 'tcName':
      counterProfile.tcName = value
      counterProfile.scName = counterProfile.scNameDisable ? sify(value) : counterProfile.scName
      break
    case 'scNameDisable':
      counterProfile.scNameDisable = value
      if (value) {
        counterProfile.scName = !counterProfile.tcName ? counterProfile.scName : sify(counterProfile.tcName)
      }
      break
    default:
      counterProfile[draftType] = value
  }
  return {
    type: COUNTER_PROFILE.SET_COUNTER_PROFILE_VALUE,
    counterProfile: counterProfile,
  }
}
