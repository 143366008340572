import { PRINT_TEMPLATE } from './types';
import _ from "lodash";
import { sify } from "chinese-conv";

export const setPrintTemplateDefault = () => {
    return {
        type: PRINT_TEMPLATE.SET_PRINT_TEMPLATE_DEFAULT,
    }
}

export const setPrintTemplate = (printTemplate) => {
    return {
        type: PRINT_TEMPLATE.SET_PRINT_TEMPLATE,
        printTemplate: printTemplate
    }
}

export const setPrintTemplateValue = (draftType, orignalProduct, key, value, index) => {
    const printTemplate = _.cloneDeep(orignalProduct);
    printTemplate[draftType] = value;
    return {
        type: PRINT_TEMPLATE.SET_PRINT_TEMPLATE_VALUE,
        printTemplate: printTemplate
    }
}

export const setTemplateList = (templateList) => {
    return {
        type: PRINT_TEMPLATE.SET_PRINT_TEMPLATE_TEMPLATE,
        templateList: templateList
    }
}

export const setTemplateLabelList = (templateLabelList) => {
    return {
        type: PRINT_TEMPLATE.SET_PRINT_TEMPLATE_TEMPLATE_LABEL,
        templateLabelList: templateLabelList
    }
}

export const setTemplateListValue = (draftType, orignalProduct, key, value, index) => {
    const templateList = _.cloneDeep(orignalProduct);
    templateList[draftType] = value;
    return {
        type: PRINT_TEMPLATE.SET_PRINT_TEMPLATE_TEMPLATE_VALUE,
        templateList: templateList
    }
}

export const setTemplateLabelListValue = (draftType, orignalProduct, key, value, index) => {
    const templateLabelList = _.cloneDeep(orignalProduct);
    templateLabelList[draftType] = value;
    return {
        type: PRINT_TEMPLATE.SET_PRINT_TEMPLATE_TEMPLATE_LABEL_VALUE,
        templateLabelList: templateLabelList
    }
}

export const setTemplate = (template) => {
    return {
        type: PRINT_TEMPLATE.SET_TEMPLATE,
        template: template
    }
}

export const setTemplateLabel = (templateLabel) => {
    return {
        type: PRINT_TEMPLATE.SET_TEMPLATE_LABEL,
        templateLabel: templateLabel
    }
}

export const setTemplateValue = (draftType, orignalProduct, key, value, index) => {
    const template = _.cloneDeep(orignalProduct);
    template[draftType] = value;
    return {
        type: PRINT_TEMPLATE.SET_TEMPLATE_VALUE,
        template: template
    }
}

export const setTemplateLabelValue = (draftType, orignalProduct, key, value, index) => {
    const templateLabel = _.cloneDeep(orignalProduct);
    if(draftType==="labelTc" && templateLabel.disableScName){
        templateLabel[draftType] = value;
        templateLabel["labelSc"] = sify(value);
    } else {
        templateLabel[draftType] = value;
    }
    return {
        type: PRINT_TEMPLATE.SET_TEMPLATE_LABEL_VALUE,
        templateLabel: templateLabel
    }
}

export const setCustomIdNumber = (customIdNumber) => {
    return {
        type: PRINT_TEMPLATE.SET_CUSTOM_ID_NUMBER,
        customIdNumber: customIdNumber
    }
}
