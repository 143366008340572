export const handleValue = (tempValue) => {
  let value = [];
  if (tempValue && typeof tempValue === "string") {
    let text = "";
    let lastValue = "";
    for (let i = 0; i < tempValue.length; i++) {
      if (tempValue[i] === "$") {
        lastValue = "$";
        text = text + tempValue[i];
      } else if (tempValue[i] === "{") {
        if (lastValue === "$") {
          text = text.slice(0, -1);
          if (text) {
            value.push(text);
          }
          text = "$" + tempValue[i];
        } else {
          text = text + tempValue[i];
        }
      } else if (tempValue[i] === "}") {
        text = text + tempValue[i];
        value.push(text);
        text = "";
      } else if (tempValue[i] === "\n") {
        if (text) {
          value.push(text);
        }
        value.push("New Line");
        text = "";
      } else {
        if (i === tempValue.length - 1) {
          text = text + tempValue[i];
          value.push(text);
          text = "";
        } else {
          text = text + tempValue[i];
        }
      }
    }
  }
  return value;
}

export const handleDateFormate = (tempValue) => {
  let newDataList = [
    { type: "date", value: [], middleSymbol: "" },
    { type: "time", value: [], bracket: false },
    { type: "week", value: [] },
  ];
  let newValue = "";
  let lastCharaters = "";

  function handleDateTypeChange(value) {
    let type = "";
    if (value.match(/[yMd]/)) {
      type = "date";
    } else if (value.match(/[hHamsS]/)) {
      type = "time";
    } else if (value.match(/[E]/)) {
      type = "week";
    } else {
      type = ""
    }
    return { type: type, valueType: handleValueTypeChange(value) }
  }

  function handleValueTypeChange(value) {
    let type = "";
    if (value.match(/[y]/)) {
      type = "year";
    } else if (value.match(/[M]/)) {
      type = "month";
    } else if (value.match(/[d]/)) {
      type = "date";
    } else if (value.match(/[hH]/)) {
      type = "hour";
    } else if (value.match(/[m]/)) {
      type = "minute";
    } else if (value.match(/[sS]/)) {
      type = "seconds";
    } else if (value.match(/[a]/)) {
      type = "timeFormat";
    } else if (value.match(/[E]/)) {
      type = "week";
    }
    return type
  }

  for (let i = 0; i <= tempValue.length; i++) {
    let tempNewValue = newValue;
    let typeList = handleDateTypeChange(tempNewValue);
    if (i === tempValue.length) {
      if (newValue) {
        newDataList.map((newData) => {
          if (newData.type === typeList.type) {
            newData.value.push({ type: typeList.valueType, value: tempNewValue });
          }
          return newData;
        });
      }
      newValue = "";
      //eslint-disable-next-line
    } else if (tempValue[i].match(/[-\/.,]/)) {
      newDataList.map((newData) => {
        if (newData.type === typeList.type) {
          newData.value.push({ type: typeList.valueType, value: tempNewValue });
          newData.middleSymbol = tempValue[i];
        }
        return newData;
      });
      lastCharaters = tempValue[i];
      newValue = "";
    } else if (tempValue[i].match(/[()]/)) {
      newDataList.map((newData) => {
        if (newData.type === typeList.type) {
          newData.value.push({ type: typeList.valueType, value: tempNewValue });
        }
        if (newData.type === "time") {
          newData.bracket = true;
        }
        return newData;
      });
      lastCharaters = tempValue[i];
      newValue = "";
    } else if (tempValue[i] !== lastCharaters) {
      newDataList.map((newData) => {
        if (newData.type === typeList.type) {
          newData.value.push({ type: typeList.valueType, value: tempNewValue });
        }
        return newData;
      });
      newValue = "";
      newValue = newValue + tempValue[i];
      lastCharaters = tempValue[i];
    } else {
      newValue = newValue + tempValue[i];
      lastCharaters = tempValue[i];
    };
  };
  newDataList.map((newData) => {
    if (newData.type === "date") {
      if (!tempValue.match(/[y]/)) {
        newData.value.push({ type: handleValueTypeChange("y"), value: "" })
      }
      if (!tempValue.match(/[M]/)) {
        newData.value.push({ type: handleValueTypeChange("M"), value: "" })
      }
      if (!tempValue.match(/[d]/)) {
        newData.value.push({ type: handleValueTypeChange("d"), value: "" })
      }
    } else if (newData.type === "time") {
      if (!tempValue.match(/[hH]/)) {
        newData.value.push({ type: handleValueTypeChange("hH"), value: "" })
      }
      if (!tempValue.match(/[m]/)) {
        newData.value.push({ type: handleValueTypeChange("m"), value: "" })
      }
      if (!tempValue.match(/[sS]/)) {
        newData.value.push({ type: handleValueTypeChange("sS"), value: "" })
      }
      if (!tempValue.match(/[a]/)) {
        newData.value.push({ type: handleValueTypeChange("a"), value: "" })
      }
    } else if (newData.type === "week") {
      if (!tempValue.match(/[E]/)) {
        newData.value.push({ type: handleValueTypeChange("E"), value: "" })
      }
    }
    return newData;
  })
  return newDataList;
};