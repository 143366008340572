import _ from "lodash";
import { RESTAURANT_SLIDESHOW } from '../actions/types';
import { constants } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        restaurantSlideshow: _.cloneDeep(constants.RESTAURANT_SLIDESHOW_INPUT)
    }
}

const restaurantSlideshowReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RESTAURANT_SLIDESHOW.SET_RESTAURANT_SLIDESHOW_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case RESTAURANT_SLIDESHOW.SET_RESTAURANT_SLIDESHOW:
        case RESTAURANT_SLIDESHOW.SET_RESTAURANT_SLIDESHOW_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    restaurantSlideshow: action.restaurantSlideshow
                }
            })
        default:
            return state
    }
}

export default restaurantSlideshowReducer