
import { useState, useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import _ from "lodash";

import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SaveIcon from '@mui/icons-material/Save';
import DoneIcon from '@mui/icons-material/Done';
import Card from '@mui/material/Card';

import { constants } from '../../Constants/constants';
import { getOne, put, post } from "../../helper/baseFetch";
import { BaseWrapper } from "../BaseWrapper";
import { setLoading } from "../../actions/systemAction";
import { setMerchantShopValue, setMerchantShop, setMerchantShopDefault } from "../../actions/merchantShopCreateAction";
import { TempBtn } from "../Input/TempBtn";
import { getString, permissionsCheck, getScDisabled } from "../../helper/util";
import { DialogWrapper } from "../DialogWrapper";
import { ShopInfo } from './Shop/ShopInfo';
import { Image } from './Image/Image';
import { ServiceAndPlan } from './ServiceAndPlan/ServiceAndPlan';
import { BrandCreate } from './Brand/BrandCreate';
import { MerchantAccounts } from './Accounts/MerchantAccounts';

import MerchantShopCreateValidation from "../../validations/merchantShopCreateValidation";
import { merchantShopValidationConstants } from "../../Constants/validationConstants";
import merchantUserValidation from "../../validations/merchantUserValidation";
import MerchantUserValidation from "../../validations/merchantUserValidation";
import { merchantUserValidationConstants } from '../../Constants/validationConstants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100vh',
    overflow: 'none',

  },
  button: {
    margin: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  error: {
    display: 'flex',
    justifyContent: 'center',
  }
}));

function getSteps() {
  return ['Shop Info', 'Image', 'Accounts', 'Service And Plan', 'Brand'];
}

function getStepContent(step, savedMerchantShop, disableInput, handlePanelSize, dispatch, lang, theme) {
  switch (step) {
    case constants.MERCHANT_DRAFT_STEP.RESTAURANT:
      return (
        <div className="step-content-wrapper">
          <ShopInfo />
        </div>
      );
    case constants.MERCHANT_DRAFT_STEP.IMAGE:
      return (
        <div className="step-content-wrapper">
          <Image />
        </div>
      );
    case constants.MERCHANT_DRAFT_STEP.ACCOUNT:
      return (
        <div className="step-content-wrapper">
          <MerchantAccounts />
        </div >
      );
    case constants.MERCHANT_DRAFT_STEP.SERVICE_AND_PLAN:
      return (
        <div className="step-content-wrapper">
          <ServiceAndPlan />
        </div>
      );
    case constants.MERCHANT_DRAFT_STEP.BRAND:
      return (
        <div className="step-content-wrapper">
          <BrandCreate />
        </div>
      );
    default:
      return 'Unknown step';
  }
}


export const MerchantShopCreate = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState(new Set());
  const steps = getSteps();
  const [ready, setReady] = useState(false);
  const history = useHistory();
  const [, setErrorVersion] = useState(MerchantShopCreateValidation.getVersion());
  const savedMerchantShop = useSelector(state => state.merchantShop.ui.merchantShop);
  const lang = useSelector(state => state.lang.ui.lang);
  const theme = useSelector(state => state.theme.ui.theme);
  const permission = localStorage.getItem(constants.LOCAL_STORAGE_KEY.ROLE);
  const dispatch = useDispatch();
  const store = useStore();
  const params = useParams();
  const [disableInput, setDisableInput] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });

  useEffect(() => {
    MerchantShopCreateValidation.resetErrorMessages();
    setReady(false);
    dispatch(setMerchantShopDefault());
    let tempId = params.id && _.cloneDeep(params.id).split("=")
    let draftId = tempId && (tempId[0] !== "clone" ? params.id : tempId[1])
    if (draftId) {
      getOne(constants.PATH.MERCHANT.DRAFT + "/" + draftId, undefined, (payload) => {
        let tempSavedMerchantShop = payload;
        let draft = JSON.parse(tempSavedMerchantShop.draft)
        const newDraft = _.merge({}, constants.MERCHANT_DRAFT.draft, draft);
        newDraft.scNameDisable = getScDisabled(newDraft.shopTcName, newDraft.shopScName);
        newDraft.scAddressDisable = getScDisabled(newDraft.shopTcAddress, newDraft.shopScAddress);

        if (tempId[0] === "clone") {
          dispatch(setMerchantShop({
            draft: newDraft,
            draftName: tempSavedMerchantShop.draftName,
            id: "",
            status: tempSavedMerchantShop.status,
          }));
        } else {
          dispatch(setMerchantShop({
            draft: newDraft,
            draftName: tempSavedMerchantShop.draftName,
            id: tempSavedMerchantShop.id,
            status: tempSavedMerchantShop.status,
          }));
        }
        setReady(true)
      }, undefined, store);
    } else {
      dispatch(setMerchantShopDefault());
      setReady(true);
    }
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (permissionsCheck(constants.PERMISSION_KEY.MERCHANT_CREATE_FINANCE)) {
      setDisableInput(true);
    }

  }, [permission])// eslint-disable-line react-hooks/exhaustive-deps

  const totalSteps = () => {
    return getSteps().length;
  };

  const handlePanelSize = (newSize, index) => {
    let tempDraft = _.cloneDeep(savedMerchantShop.draft);
    tempDraft.panelSize[index] = newSize;
    dispatch(setLoading(true));
    dispatch(setMerchantShopValue("", savedMerchantShop, "", tempDraft));
    dispatch(setLoading(false));
  };

  const handleSaveDraft = (type) => {
    if (type === "finish") {
      MerchantShopCreateValidation.validateDraft(savedMerchantShop.draft);
      merchantUserValidation.validateCreateMerchantUser(savedMerchantShop.draft.merchantUserList);
      if (MerchantShopCreateValidation.isValid() && merchantUserValidation.isValid()) {
      handleNameDialog(true, type);
      } else {
        setErrorVersion(MerchantShopCreateValidation.getVersion());
      }
    } else {
      handleNameDialog(true, type);
    }
  };

  const handleSaveDraftDialogClose = () => {
    handleNameDialog(false);
  };

  const saveDraftDialogClose = () => {
    handleSaveDraftDialog(false);
    history.push("/" + constants.PATH.MERCHANT.DRAFT);
  };

  const saveDraftFailDialogClose = () => {
    handleSaveDraftDialog(false);
  };

  const onClickSave = (newDraftName, toBeApproved) => {
    saveDraft(newDraftName, toBeApproved);
  }

  const saveDraft = (newDraftName, toBeApproved) => {
    let tempSavedMerchantShop = _.cloneDeep(savedMerchantShop);

    const confirmedSaveDraft = ({
      draftName: newDraftName ? newDraftName : tempSavedMerchantShop.draftName,
      draft: JSON.stringify(tempSavedMerchantShop.draft),
      draftVersion: constants.DRAFT_VER,
      toBeApproved: toBeApproved
    })
    handleNameDialog(false);

    if (!savedMerchantShop.id) {
      post(constants.PATH.MERCHANT.DRAFT, confirmedSaveDraft, (payload) => {
        handleSaveDraftDialog(true, { key: "addSuccess" }, "saveDraftDialogClose");
      }, undefined, store);
    } else {
      put(constants.PATH.MERCHANT.DRAFT + "/" + savedMerchantShop.id, confirmedSaveDraft, (payload) => {
        handleSaveDraftDialog(true, { key: "editSuccess" }, "saveDraftDialogClose");
      }, undefined, store);
    }
  };

  const lastStep = totalSteps() - 1;

  const handleNext = () => {
    const newActiveStep =
      activeStep === lastStep
        ?
        steps.findIndex((step, i) => !completed.has(i))
        : activeStep + 1;

    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  function handleNameDialog(open, type) {
    let newDraftName = "";
    setDialog({
      open: open,
      onClose: handleSaveDraftDialogClose,
      dialogTitle: { key: "dialogTitle" },
      dialogContent: <div className="draft-name-wrapper">
        <h4>{getString(lang, "MerchantDraft", "enterDraftName", theme)}</h4>
        <TextField className="draft-name-input" defaultValue={savedMerchantShop.draftName ? savedMerchantShop.draftName : ""} required label="draftName" variant="outlined" onChange={(draftName) => newDraftName = draftName.target.value} />
      </div>,
      dialogActions: [
        { label: { key: "backEdit" }, onClick: () => handleSaveDraftDialogClose(), icon: <ArrowBackIcon /> },
        { label: { key: "confirm" }, onClick: () => { type === "save" ? onClickSave(newDraftName, false) : handleComplete(newDraftName, true) }, icon: <AddIcon /> }
      ],
    });
  }

  function handleSaveDraftDialog(open, message, close) {
    setDialog({
      open: open,
      onClose: close === "saveDraftDialogClose" ? () => saveDraftDialogClose() : () => saveDraftFailDialogClose(),
      dialogTitle: { key: "dialogTitle" },
      dialogContentText: message,
      dialogActions: [{
        label: { key: "backList" },
        onClick: close === "saveDraftDialogClose"
          ? () => saveDraftDialogClose()
          : () => saveDraftFailDialogClose(), icon: <LowPriorityIcon />
      }],
    });
  }

  const handleComplete = (newDraftName, toBeApproved) => {
    MerchantShopCreateValidation.validateDraft(savedMerchantShop.draft);
    merchantUserValidation.validateCreateMerchantUser(savedMerchantShop.draft.merchantUserList);

    let tempSavedMerchantShop = _.cloneDeep(savedMerchantShop);

    const confirmedSaveDraft = ({
      id: tempSavedMerchantShop.id,
      draftName: newDraftName ? newDraftName : tempSavedMerchantShop.draftName,
      draft: tempSavedMerchantShop.draft,
      draftVersion: constants.DRAFT_VER
    })

    if (permissionsCheck(constants.PERMISSION_KEY.MERCHANT_CREATE_SYSTEM_ADMIN)) {
      if (MerchantShopCreateValidation.isValid() && merchantUserValidation.isValid()) {
      const newCompleted = new Set(completed);
      newCompleted.add(activeStep);
      setCompleted(newCompleted);
      onClickSave("", toBeApproved);
      } else {
        setErrorVersion(MerchantShopCreateValidation.getVersion());
      }
    } else if (permissionsCheck(constants.PERMISSION_KEY.MERCHANT_CREATE_FINANCE)) {
      if (MerchantShopCreateValidation.isValid() && merchantUserValidation.isValid()) {
      const newCompleted = new Set(completed);
      newCompleted.add(activeStep);
      setCompleted(newCompleted);
      //confirm link
      post(constants.PATH.MERCHANT.CONFIRM_CREATE_MERCHANT, confirmedSaveDraft, (payload) => {
        handleSaveDraftDialog(true, { key: "editSuccess" }, "saveDraftDialogClose");
      }, undefined, store);
      } else {
        setErrorVersion(MerchantShopCreateValidation.getVersion());
      }
    }
  };

  useEffect(() => {
    MerchantShopCreateValidation.resetErrorMessages();
    MerchantUserValidation.resetErrorMessages();
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <BaseWrapper>
      <div className="stepper-wrapper">
        <div className="header margin-left-16">{getString(lang, "MerchantDraft", "draftName", theme)}: <span className="draft-name ">{savedMerchantShop.draftName}</span></div>
        <Stepper className="stepper" alternativeLabel nonLinear activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            switch(index){
              case 0:
                if(MerchantShopCreateValidation.getErrorMessages(merchantShopValidationConstants.KEY_SHOP_INFO)){
                  labelProps.optional = (
                    <Typography className={classes.error} variant="caption" color="error">
                      (Missing/Error Info)
                    </Typography>
                  );
                  if (index !== activeStep) {
                    labelProps.error = true;
                  }
                }
                break;
              case 1:
                if(MerchantShopCreateValidation.getErrorMessages(merchantShopValidationConstants.KEY_BR_IMAGE)){
                  labelProps.optional = (
                    <Typography className={classes.error} variant="caption" color="error">
                      (Missing/Error Info)
                    </Typography>
                  );
                  if (index !== activeStep) {
                    labelProps.error = true;
                  }
                }
                break;
              case 2:
                if(MerchantUserValidation.getErrorMessages(merchantUserValidationConstants.KEY_HAS_ERROR)||MerchantShopCreateValidation.getErrorMessages(merchantShopValidationConstants.KEY_MERCHANT_USER_LIST)){
                  labelProps.optional = (
                    <Typography className={classes.error} variant="caption" color="error">
                      (Missing/Error Info)
                    </Typography>
                  );
                  if (index !== activeStep) {
                    labelProps.error = true;
                  }
                }
                break;
              case 3:
                if(MerchantShopCreateValidation.getErrorMessages(merchantShopValidationConstants.KEY_PLAN_CODE)){
                  labelProps.optional = (
                    <Typography className={classes.error} variant="caption" color="error">
                      (Missing/Error Info)
                    </Typography>
                  );
                  if (index !== activeStep) {
                    labelProps.error = true;
                  }
                }
                break;
              case 4:
                break;
              default:
                break;
            }
            return (
              <Step key={label} {...stepProps} className="step-button">
                <StepLabel onClick={handleStep(index)} {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
      <div className="step-content-outer-wrapper">
        <Card>
          {getStepContent(activeStep, savedMerchantShop, disableInput, handlePanelSize, dispatch, lang, theme)}
        </Card>
        <div className="buttons">
          <TempBtn btnSetting={{
            className: classes.button,
            variant: constants.STYLE.VARIANT.CONTAINED,
            color: constants.STYLE.PRIMARY,
            onClick: handleBack,
            icon: <ArrowBackIcon />,
            label: { key: "back" },
            disabled: activeStep === 0,
          }} />
          <TempBtn btnSetting={{
            className: classes.button,
            variant: constants.STYLE.VARIANT.CONTAINED,
            color: constants.STYLE.PRIMARY,
            onClick: handleNext,
            icon: <ArrowForwardIcon />,
            label: { key: "next" },
            disabled: activeStep === lastStep,
          }} />
          {permissionsCheck(constants.PERMISSION_KEY.MERCHANT_CREATE_SYSTEM_ADMIN) &&
            <TempBtn btnSetting={{
              className: classes.button,
              variant: constants.STYLE.VARIANT.CONTAINED,
              color: constants.STYLE.PRIMARY,
              onClick: () => { handleSaveDraft("save"); },
              icon: <SaveIcon />,
              label: { key: "saveAsDraft" },
            }} />
          }
          {activeStep === lastStep &&
            <TempBtn btnSetting={{
              className: classes.button,
              variant: constants.STYLE.VARIANT.CONTAINED,
              color: constants.STYLE.PRIMARY,
              onClick: () => { permissionsCheck(constants.PERMISSION_KEY.MERCHANT_CREATE_SYSTEM_ADMIN) ? handleSaveDraft("finish") : handleComplete() },
              icon: <DoneIcon />,
              label: permissionsCheck(constants.PERMISSION_KEY.MERCHANT_CREATE_SYSTEM_ADMIN) ? { key: "finishBtn" } : permissionsCheck(constants.PERMISSION_KEY.MERCHANT_CREATE_FINANCE) ? { key: "approve" } : "",
            }} />
          }
        </div>
      </div>
      {dialog && dialog.open &&
        <DialogWrapper dialogInfo={dialog} />
      }
    </BaseWrapper>
  );
}