
import { constants } from '../../../Constants/constants';
import { RestaurantSlideshowEdit } from '../../RestaurantSlideshow/RestaurantSlideshowEdit';

export const AllSlideshowEdit = () => {
  return ( 
    <RestaurantSlideshowEdit
    newRedirectPath={`/${constants.PATH.SLIDESHOW.ALL_SLIDESHOW}`}
    />
  )
}
