import { LANDING_SECTION } from './types';
import _ from "lodash";

export const setLandingSectionDefault = () => {
    return {
        type: LANDING_SECTION.SET_LANDING_SECTION_DEFAULT,
    }
}

export const setLandingSection = (landingSection) => {
    return {
        type: LANDING_SECTION.SET_LANDING_SECTION,
        landingSection: landingSection
    }
}

export const deleteLandingSection = (landingSection, index) => {
    return {
        type: LANDING_SECTION.SET_LANDING_SECTION,
        landingSection: landingSection.splice(index, 1)
    }
}

export const setLandingSectionValue = (draftType, orignalProduct, key, value, index) => {
    const landingSection = _.cloneDeep(orignalProduct);
    switch (draftType) {
        // case "CHANGE_TC_NAME_AND_SC_NAME":
        //     landingSection.tcName = value;
        //     landingSection.scName = sify(value);
        //     break;
        default:
            landingSection[draftType] = value;
    }
    return {
        type: LANDING_SECTION.SET_LANDING_SECTION_VALUE,
        landingSection: landingSection
    }
}
