import { landmarkValidationConstants } from '../Constants/validationConstants';

class LandmarkValidation {

  version;
  errorMap;
  landmarkErrorMap;
  valid;

  constructor() {

    if (!LandmarkValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.landmarkErrorMap = new Map();
      LandmarkValidation.instance = this;
    }
    return LandmarkValidation.instance;
  }

  validateEditLandmark(landmark) {
    this.resetErrorMessages();
    this.handleValidateEditLandmark(landmark);
    this.version++;
  }

  validateCreateLandmark(landmark) {
    this.resetErrorMessages();
    this.handleValidateCreateLandmark(landmark);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.landmarkErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key, uniqueCode) {
    if (!uniqueCode) {
      return (this.landmarkErrorMap.has(key)) ? this.landmarkErrorMap.get(key) : [];
    }
  }

  handleValidateEditLandmark(landmark) {
    if (!landmark.enName) {
      this.setLandmarkErrorMapMessages(landmarkValidationConstants.KEY_EN_NAME, "Missing EN Name");
    }
    if (!landmark.tcName) {
      this.setLandmarkErrorMapMessages(landmarkValidationConstants.KEY_TC_NAME, "Missing TC Name");
    }
    if (!landmark.scName) {
      this.setLandmarkErrorMapMessages(landmarkValidationConstants.KEY_SC_NAME, "Missing SC Name");
    }
    if (!landmark.landmarkOrder && landmark.landmarkOrder !== 0) {
      this.setLandmarkErrorMapMessages(landmarkValidationConstants.KEY_LANDMARK_ORDER, "Missing Landmark Order");
    } else if (landmark.landmarkOrder < 0 || !Number.isInteger(Number(landmark.landmarkOrder))) {
      this.setLandmarkErrorMapMessages(landmarkValidationConstants.KEY_LANDMARK_ORDER, "Invalid Landmark Order");
    }
    if (!landmark.favorite && landmark.favorite !== false) {
      this.setLandmarkErrorMapMessages(landmarkValidationConstants.KEY_FAVORITE, "Missing Favorite");
    }
    if (!landmark.status) {
      this.setLandmarkErrorMapMessages(landmarkValidationConstants.KEY_STATUS, "Missing Status");
    }
  }

  handleValidateCreateLandmark(landmark) {
    if (!landmark.enName) {
      this.setLandmarkErrorMapMessages(landmarkValidationConstants.KEY_EN_NAME, "Missing EN Name");
    }
    if (!landmark.tcName) {
      this.setLandmarkErrorMapMessages(landmarkValidationConstants.KEY_TC_NAME, "Missing TC Name");
    }
    if (!landmark.scName) {
      this.setLandmarkErrorMapMessages(landmarkValidationConstants.KEY_SC_NAME, "Missing SC Name");
    }
    if (!landmark.landmarkOrder && landmark.landmarkOrder !== 0) {
      this.setLandmarkErrorMapMessages(landmarkValidationConstants.KEY_LANDMARK_ORDER, "Missing Landmark Order");
    } else if (landmark.landmarkOrder < 0 || !Number.isInteger(Number(landmark.landmarkOrder))) {
      this.setLandmarkErrorMapMessages(landmarkValidationConstants.KEY_LANDMARK_ORDER, "Invalid Landmark Order");
    }
    if (!landmark.favorite && landmark.favorite !== false) {
      this.setLandmarkErrorMapMessages(landmarkValidationConstants.KEY_FAVORITE, "Missing Favorite");
    }
  }


  setLandmarkErrorMapMessages(key, errorMessages, valid = false) {
    this.landmarkErrorMap.set(key, errorMessages);
    // console.log("landmarkErrorMap", this.landmarkErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new LandmarkValidation();
export default validation;