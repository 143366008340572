import { useSelector } from "react-redux";

import { constants, BANNER_TYPE } from '../../Constants/constants';
import { BannerCreate } from "../Banner/BannerCreate";

export const FolderBannerCreate = ({ toolBar }) => {
  const savedFolder = useSelector(state => state.folder.ui.folder);
  const resource = "FolderBanner";

  return <BannerCreate newPath={constants.PATH.FOLDER.FOLDER_BANNER_LIST}
    mapId={savedFolder.id}
    mapName={{ resource: resource, key: "folderCode" }}
    mapCode={toolBar !== false ? savedFolder.folderCode : ""}
    toolBar={toolBar}
    bannerType={BANNER_TYPE.FOLDER}
    redirectPath={constants.PATH.FOLDER.FOLDER}
    tab={constants.FOLDER_TAB.BANNER_LIST} />

};