import { useState, useEffect } from "react";
import { useSelector, useStore } from "react-redux";
import _ from "lodash";
import moment from 'moment';

import { Card } from '@mui/material';

import { constants } from '../../Constants/constants';
import { getList } from "../../helper/baseFetch";
import { ListDisplay } from '../../Component/ListDisplay/ListDisplay';

export const ConfigMapList = ({ editBtn, createBtn }) => {
  const store = useStore();
  const [configMapList, setConfigMapList] = useState([]);
  const [ready, setReady] = useState(false);
  const [serviceType, setServiceType] = useState("");
  const resourceName = "ConfigMap";
  const savedBanner = useSelector(state => state.banner.ui.banner);

  const listTitles = [
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "serviceType",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "ticketStatus",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "restUrlId",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "restEnName",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "restTcName",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "restScName",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "channel",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.DATE_TIME,
      value: "startDisplayDate",
      sort: false
    }, {
      key: constants.LIST_DISPLAY_TYPE.DATE_TIME,
      value: "endDisplayDate",
      sort: false
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "status",
      sort: true
    },
  ];
  const listFilters = [
    {
      className: "filter-input",
      label: {resource: resourceName, key: "serviceType"},
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.CONFIG_MAP.SERVICE_TYPE,
      name: "serviceType",
      size: constants.SIZE.SMALL
    },
    {
      className: "filter-input",
      label: {resource: resourceName, key: "ticketStatus"},
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: [],
      name: "ticketStatus",
      size: constants.SIZE.SMALL,
      disabled: true
    },
    {
      className: "filter-input",
      label: {resource: resourceName, key: "restUrlId"},
      clear: true,
      value: '',
      name: "restUrlId",
      size: constants.SIZE.SMALL
    },
    {
      className: "filter-input",
      label: {resource: resourceName, key: "restName"},
      clear: true,
      value: '',
      name: "restName",
      size: constants.SIZE.SMALL
    },
    {
      className: "filter-input",
      label: {resource: resourceName, key: "channel"},
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.CONFIG_MAP.CHANNEL,
      name: "channel",
      size: constants.SIZE.SMALL,
    },
    {
      className: "filter-input",
      label: {resource: resourceName, key: "status"},
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.CONFIG_MAP.STATUS,
      name: "status",
      size: constants.SIZE.SMALL
    },
  ];

  const [param, setParam] = useState({
    path: "",
    page: 0,
    rowsPerPage: 10,
    sort: 'updateTimestamp',
    direction: 'DESC',
    listFilters: listFilters,
    count: 0,
  })

  function onChangeParam(value) {
    value.count = value.count + 1;
    setParam(value);
  }

  function onChangeListFilters(list) {
    let tempParam = _.cloneDeep(param);
    tempParam.listFilters = list;
    tempParam.page = 0;
    onChangeParam(tempParam);
  }

  function onChangeListSort(sortItem) {
    let tempParam = _.cloneDeep(param);
    tempParam.sort = sortItem;
    tempParam.direction = tempParam.direction === "DESC" ? "ASC" : "DESC";
    onChangeParam(tempParam);
  }

  function onChangeListPage(page) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = page;
    onChangeParam(tempParam);
  }

  function onChangeListRowsPerPage(rowsPerPage) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = 0;
    tempParam.rowsPerPage = rowsPerPage;
    onChangeParam(tempParam);
  }

  function handleServiceTypeChange(tempList) {
    let ticketStatus;
    let newServiceType;
    tempList.forEach((item) => {
      if (item.name === "serviceType") {
        newServiceType = item.value;
        if (!item.value) {
          ticketStatus = true;
        } else {
          ticketStatus = false;
        }
      } else if (item.name === "ticketStatus") {
        item.disabled = ticketStatus;
        item.selectValue = newServiceType ? constants.CHOICES.CONFIG_MAP[newServiceType].TICKET_STATUS: [];
          item.value = (newServiceType && (serviceType === newServiceType)) ? item.value : "";
  
          setServiceType(newServiceType)
      }
    })
    param.listFilters = tempList;
  }

  useEffect(() => {
    let tempParam = _.cloneDeep(param);
    const filter = { bannerId: savedBanner.id };
    tempParam.listFilters.forEach((filterItem) => {
      if (filterItem.value !== '' && filterItem.value !== undefined) {
        if (filterItem.type === constants.TYPE.DATETIME_LOCAL) {
          filter[filterItem.name] = moment(filterItem.value).format('x')
        } else {
          filter[filterItem.name] = filterItem.value;
        };
      }
    })

    getList(constants.PATH.TICKET.TICKET_BANNER_MAP, { sort: { field: tempParam.sort, order: tempParam.direction }, filter: filter, pagination: { page: tempParam.page + 1, perPage: tempParam.rowsPerPage } }, (payload) => {
        setConfigMapList(payload);
        setReady(true)
      }, undefined, store);
  }, [param.count]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <Card className="card-wrapper overflow">
      <ListDisplay
        page={param.page}
        rowsPerPage={param.rowsPerPage}
        onChangeListSort={onChangeListSort}
        resource={resourceName}
        dataList={configMapList}
        sort={param.sort}
        direction={param.direction}
        listTitles={listTitles}
        listFilters={param.listFilters}
        onChangeListPage={onChangeListPage}
        onChangeListRowsPerPage={onChangeListRowsPerPage}
        editBtn={editBtn}
        createBtn={createBtn}
        onChangeListFilters={onChangeListFilters}
        handleFilterExtraChange={handleServiceTypeChange}
        redirectPath={"/" + (constants.PATH.TICKET.TICKET_BANNER_MAP) + "/"}
      />
    </Card>
  )
};