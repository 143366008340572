import { chargeDateValidationConstants } from '../Constants/validationConstants';

class ChargeDateValidation {

  version;
  errorMap;
  chargeDateErrorMap;
  valid;

  constructor() {

    if (!ChargeDateValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.chargeDateErrorMap = new Map();
      ChargeDateValidation.instance = this;
    }
    return ChargeDateValidation.instance;
  }

  validateChargeDateList(template, type) {
    this.resetErrorMessages();
    this.validateChargeDate(template, type);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.chargeDateErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key, uniqueCode) {
    if (!uniqueCode) {
      return (this.chargeDateErrorMap.has(key)) ? this.chargeDateErrorMap.get(key) : [];
    } else {
      switch (key) {
        case chargeDateValidationConstants.KEY_CHARGE_DATE_ERROR:
        case chargeDateValidationConstants.KEY_REST_URL_ID:
        case chargeDateValidationConstants.KEY_START_DATE:
        case chargeDateValidationConstants.KEY_END_DATE:
        case chargeDateValidationConstants.KEY_CHARGE_CODE:
          if (this.chargeDateErrorMap.has(uniqueCode) && this.chargeDateErrorMap.get(uniqueCode).has(key)) {
            return this.chargeDateErrorMap.get(uniqueCode).get(key);
          }
          return [];
        default:
          return [];
      }
    }
  }

  validateChargeDate(chargeDate, type) {
    let dateList = [];
    chargeDate.map((item) => {
      const chargeDateErrorMap = new Map();
      if (type === "create") {
        const startDate = Number(item.startDate);
        const endDate = Number(item.endDate);
        if (isNaN(startDate) || startDate === 0) {
          chargeDateErrorMap.set(chargeDateValidationConstants.KEY_START_DATE, ['Missing Start Date']);
        } else if (!isNaN(startDate) && !isNaN(endDate)) {
          if (startDate > endDate) {
            chargeDateErrorMap.set(chargeDateValidationConstants.KEY_START_DATE, ['Start Date is larger than End Date']);
            chargeDateErrorMap.set(chargeDateValidationConstants.KEY_END_DATE, ['Start Date is larger than End Date']);
          } else {
            let error = [];
            dateList.map((dateValue) => {
              if (dateValue.startDate <= endDate && dateValue.endDate >= startDate) {
                chargeDateErrorMap.set(chargeDateValidationConstants.KEY_START_DATE, ['Duplicate Date']);
                chargeDateErrorMap.set(chargeDateValidationConstants.KEY_END_DATE, ['Duplicate Date']);
                error.push(true)
              }
              return dateValue
            })
            if (!error.includes(true)) {
              dateList.push({ startDate: startDate, endDate: endDate })
            }
          }
        }
        if (isNaN(endDate) || endDate === 0) {
          chargeDateErrorMap.set(chargeDateValidationConstants.KEY_END_DATE, ['Missing End Date']);
        }
      }
      if (type === "edit") {
        if (isNaN(item.date) || item.date === 0) {
          chargeDateErrorMap.set(chargeDateValidationConstants.KEY_DATE, ['Missing Date']);
        }
      }
      if (!item.chargeCode) {
        chargeDateErrorMap.set(chargeDateValidationConstants.KEY_CHARGE_CODE, ['Missing Charge Code']);
      }
      if (chargeDateErrorMap.size > 0) {
        chargeDateErrorMap.set(chargeDateValidationConstants.KEY_CHARGE_DATE_ERROR, ["Has error(s)"]);
        this.setChargeDateErrorMapMessages(item.id ? item.id : item.customId, chargeDateErrorMap);
      }
      return item
    })
  }


  setChargeDateErrorMapMessages(key, errorMessages, valid = false) {
    this.chargeDateErrorMap.set(key, errorMessages);
    // console.log("chargeDateErrorMap", this.chargeDateErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new ChargeDateValidation();
export default validation;