import { PRINT_CONFIG } from './types';
import _ from "lodash";

export const setPrintConfigDefault = () => {
    return {
        type: PRINT_CONFIG.SET_PRINT_CONFIG_DEFAULT,
    }
}

export const setPrintConfig = (printConfig) => {
    return {
        type: PRINT_CONFIG.SET_PRINT_CONFIG,
        printConfig: printConfig
    }
}

export const setPrintConfigValue = (draftType, orignalProduct, key, value, index) => {
    const printConfig = _.cloneDeep(orignalProduct);
    switch (draftType) {
        default:
            printConfig[draftType] = value;
    }
    return {
        type: PRINT_CONFIG.SET_PRINT_CONFIG_VALUE,
        printConfig: printConfig
    }
}
