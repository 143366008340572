import { RestaurantMapCreate } from "../../Component/RestaurantTag/RestaurantMapCreate";
import { constants } from '../../Constants/constants';

import "../../styles/map.css";

export const CuisineResturantMapCreate = () => {
  return (
    <RestaurantMapCreate resourceName="restaurant_map" pathName={constants.PATH.RESTAURANT_TAG.RESTAURANT_CUISINE_MAP_LIST} backListName={constants.PATH.RESTAURANT_TAG.RESTAURANT_CUISINE} />
  );
};
