import { useEffect, useState } from 'react'
import { TempTextInput } from '../../../Component/Input/TempTextInput'
import { DialogWrapper } from '../../../Component/DialogWrapper'
import { constants } from '../../../Constants/constants'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CachedIcon from '@mui/icons-material/Cached'
import { TempCustomCheckBox } from '../../../Component/Custom/TempCustomCheckBox'
import { helperTextStyles } from '../../../helper/util'
import { testMerchantUserPassword } from '../../../helper/passwordUtil'

export const ResetUsernameAndPasswordDialog = ({ prevUsername, openResetDialog, setOpenResetDialog, onClickReset, resource }) => {
  const [username, setUsername] = useState(prevUsername)
  const [password, setPassword] = useState(null)
  const [passwordError, setPasswordError] = useState(null)
  const [usernameError, setUsernameError] = useState(null)
  const [updateUsername, setUpdateUsername] = useState(false)
  const helperTextStylesClasses = helperTextStyles()

  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })

  const handleClose = () => {
    setOpenResetDialog(false)
  }

  const onChangeInput = (name, e) => {
    e = e || null
    switch (name) {
      case 'password':
        setPassword(e)
        break
      case 'username':
        setUsername(e)
        break
      default:
    }
  }

  const handleCheckBoxToggle = (value, customName) => {
    switch (customName.key) {
      case 'changeUsername':
        setUpdateUsername(value)
        break
      default:
    }
  }

  const handleValidation = () => {
    setUsernameError(username ? null : 'Missing Username')
    setPasswordError(
      testMerchantUserPassword(password) ? null : 'The password should be at least 6 characters long and contain at least one letter and one digit.'
    )

    if (username && testMerchantUserPassword(password)) {
      onClickReset(username, password)
    }
  }

  useEffect(() => {
    if (!openResetDialog) {
      setPassword(null)
      setUsername(prevUsername)
      setUpdateUsername(false)
      setPasswordError(null)
      setUsernameError(null)
      setDialog({
        open: false,
        dialogActions: [],
      })
    }
  }, [openResetDialog, prevUsername])

  useEffect(() => {
    setDialog({
      open: openResetDialog,
      onClose: handleClose,
      dialogContent: (
        <>
          <TempCustomCheckBox
            className="customize-btn"
            value={updateUsername}
            onSelectCheckbox={handleCheckBoxToggle}
            customName={{ resource: resource, key: 'changeUsername' }}
          />
          <TempTextInput
            inputSetting={{
              className: 'full-size-input',
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: 'username' },
              value: username,
              name: 'username',
              disabled: !updateUsername,
              helperText: usernameError,
              FormHelperTextProps: { className: helperTextStylesClasses.root },
            }}
            handleChange={onChangeInput}
          />
          <br />
          <TempTextInput
            inputSetting={{
              className: 'full-size-input',
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: 'password' },
              value: password,
              name: 'password',
              helperText: passwordError,
              FormHelperTextProps: { className: helperTextStylesClasses.root },
            }}
            handleChange={onChangeInput}
          />
        </>
      ),
      dialogActions: [
        { label: { key: 'reset' }, onClick: handleValidation, icon: <CachedIcon /> },
        { label: { key: 'backEdit' }, onClick: handleClose, icon: <ArrowBackIcon /> },
      ],
    })
  }, [openResetDialog, password, username, updateUsername, resource, passwordError, usernameError])
  return dialog && dialog.open && <DialogWrapper dialogInfo={dialog} />
}
