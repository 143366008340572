import { RESTAURANT_MEDIA } from './types';
import _ from "lodash";

export const setRestaurantMediaDefault = () => {
    return {
        type: RESTAURANT_MEDIA.SET_RESTAURANT_MEDIA_DEFAULT,
    }
}

export const setRestaurantMedia = (restaurantMedia) => {
    return {
        type: RESTAURANT_MEDIA.SET_RESTAURANT_MEDIA,
        restaurantMedia: restaurantMedia
    }
}

export const setRestaurantMediaValue = (draftType, orignalProduct, key, value, index) => {
    const restaurantMedia = _.cloneDeep(orignalProduct);
    switch (draftType) {
        case "EN_IMAGE":
            restaurantMedia.cosFileEnKey = value.cosFileKey;
            restaurantMedia.cosFileEnUrl = value.cosFileUrl;
            break;
        case "TC_IMAGE":
            restaurantMedia.cosFileTcKey = value.cosFileKey;
            restaurantMedia.cosFileTcUrl = value.cosFileUrl;
            break;
        case "SC_IMAGE":
            restaurantMedia.cosFileScKey = value.cosFileKey;
            restaurantMedia.cosFileScUrl = value.cosFileUrl;
            break;
        default:
            restaurantMedia[draftType] = value;
    }
    return {
        type: RESTAURANT_MEDIA.SET_RESTAURANT_MEDIA_VALUE,
        restaurantMedia: restaurantMedia
    }
}
