export const tcMyMessages = {
  //CustomizenName
  nameCustomize: "名稱（自動將名稱翻譯成簡體）",

  resource: {
    restaurant: {
      tcName: "餐廳名稱",
      tcSearchingName: "搜索名稱",
      weChatTcName: "微信餐廳名稱",
      tcAddress: "地址",
      tcTicketMessage: "Ticket Message",
      tcOpening: "營業時間",
      tcLastOrderTime: "最後訂單時間",
      tcDescription: "詳細資料",
      nameCustomize: "名稱（自動將名稱翻譯成簡體）",
      searchingNameCustomize: "搜索名稱（自動將搜索名稱翻譯成簡體）",
      ticketMessageCustomize: "Ticket Message（自動將繁Ticket Message翻譯成簡體）",
      openingCustomize: "營業時間（自動將營業時間翻譯成簡體）",
      lastOrderTimeCustomize: "最後訂單時間（自動將最後訂單時間翻譯成簡體）",
      descriptionCustomize: "詳細資料（自動將詳細資料翻譯成簡體）",
      addressCustomize: "地址（自動將地址翻譯成簡體）",
    },

    //RestaurantMedia
    RestaurantMedia: {
      cosFileTcUrl: "圖像",
      tcImage: "圖像",
    },

    TimeSection: {
      timeSectionTcLabel: "時間段標籤",
      isAutoTranslateTimeSectionLabel: "時間段標籤（自動將標籤翻譯成簡體）",
    },

    TableType: {
      tableTypeTcLabel: "Table Type 標籤",
      isAutoTranslateTableTypeScLabel: "Table Type 標籤（自動將Table Type標籤翻譯成簡體）",
      tableTypeMobileTcLabel: "Table Type Mobile 標籤",
      isAutoTranslateTableTypeMobileScLabel: "Table Type Mobile 標籤（自動將Table Type Mobile標籤翻譯成簡體）",
      ticketTypeTcLabel: "Ticket Type 標籤",
    },

    TicketType: {
      tcName: "名稱",
    },

    ReservationConfig: {
      remarksTcMessage: "備註信息",
      remarksMessageCustomize: "備註信息（自動將備註信息翻譯成簡體）",
      disableTcMessage: "禁用消息",
      disableMessageCustomize: "禁用消息（自動將禁用消息翻譯成簡體）",
      reminderTcMessage: "提醒消息",
      reminderMessageCustomize: "提醒消息（自動將提醒消息翻譯成簡體）",
      noTimeSectionTcMessage: "無時間段消息",
      noTimeSectionMessageCustomize: "無時間段消息（自動將無時間段消息翻譯成簡體）",
      chargeTcDescription: "收費描述",
      chargeDescriptionCustomize: "收費描述(自動將收費描述翻譯成簡體)",
    },

    CampaignDescription: {
      tcTitle: "標題",
      tcMessage: "信息",
      titleCustomize: "標題（自動將標題翻譯成簡體）",
      messageCustomize: "信息（自動將信息翻譯成簡體）",
    },

    TnC: {
      tcDescription: "描述",
      descriptionCustomize: "描述（自動將描述翻譯成簡體）",
    },

    restaurant_map: {
      tcName: "餐廳名稱",
      restTcName: "餐廳名稱",
      nameCustomize: "名稱（自動將名稱翻譯成簡體）",
      tcPictures: "圖片",
    },

    restaurant_category: {
      tcName: "名稱",
      restTcName: "餐廳名稱",
      nameCustomize: "名稱（自動將名稱翻譯成簡體）",
      tcPictures: "圖片",
    },

    restaurant_cuisine: {
      tcName: "名稱",
      restTcName: "餐廳名稱",
      nameCustomize: "名稱（自動將名稱翻譯成簡體）",
      tcPictures: "圖片",
    },

    restaurant_keyword: {
      tcName: "名稱",
      restTcName: "餐廳名稱",
      nameCustomize: "名稱（自動將名稱翻譯成簡體）",
      tcPictures: "圖片",
    },

    ConfigMap: {
      restTcName: "餐廳名稱",
    },

    Banner: {
      tcImage: "橫幅圖像",
    },

    Area: {
      tcName: "區域名稱",
    },

    RestaurantDistrict: {
      tcName: "地區名稱",
      districtTcName: "地區名稱",
      areaTcName: "區域名稱",
      nameCustomize: "名稱（自動將名稱翻譯成簡體）",
    },

    //RestaurantDistrictRestMap
    RestaurantDistrictRestMap: {
      restTcName: "餐廳名稱",
    },

    //RestaurantLandmark
    RestaurantLandmark: {
      tcName: "地標名稱",
      landmarkTcName: "地標名稱",
    },

    //RestaurantDistrictMap
    RestaurantDistrictMap: {
      tcName: "地區名稱",
    },

  }
}