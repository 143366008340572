import _ from "lodash";
import { QUEUE_TEMPLATE_MAP } from '../actions/types';
import { constants } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        queueTemplateMapList: _.cloneDeep(constants.QUEUE_TEMPLATE_MAP_LIST),
        queueTemplateMap: _.cloneDeep(constants.QUEUE_TEMPLATE_MAP_INPUT),
    }
}

const queueTemplateMapReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case QUEUE_TEMPLATE_MAP.SET_QUEUE_TEMPLATE_MAP_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case QUEUE_TEMPLATE_MAP.SET_QUEUE_TEMPLATE_MAP:
        case QUEUE_TEMPLATE_MAP.SET_QUEUE_TEMPLATE_MAP_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    queueTemplateMap: action.queueTemplateMap
                }
            })
        case QUEUE_TEMPLATE_MAP.SET_QUEUE_TEMPLATE_MAP_LIST:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    queueTemplateMapList: action.queueTemplateMapList
                }
            })
        default:
            return state
    }
}

export default queueTemplateMapReducer