import { useParams } from "react-router-dom";

import { constants, BANNER_TYPE } from '../../Constants/constants';
import { BannerEdit } from "../Banner/BannerEdit";

export const TicketBannerEdit = () => {
  const params = useParams();

  return <BannerEdit newPath={constants.PATH.TICKET.TICKET_BANNER}
    redirectPath={constants.PATH.TICKET.TICKET_BANNER}
    dataId={params.id}
    bannerType={BANNER_TYPE.TICKET}
    tab={0} />
};