import _ from "lodash";
import { MERCHANT_USER } from '../actions/types';
import { constants } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        merchantUser: _.cloneDeep(constants.MERCHANT_USER_INPUT)
    }
}

const merchantUserReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MERCHANT_USER.SET_MERCHANT_USER_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case MERCHANT_USER.SET_MERCHANT_USER:
        case MERCHANT_USER.SET_MERCHANT_USER_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    merchantUser: action.merchantUser
                }
            })
        default:
            return state
    }
}

export default merchantUserReducer