import _ from "lodash";
import { STATUS } from '../actions/types';
import { constants } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        status: _.cloneDeep(constants.STATUS_INPUT)
    }
}

const statusReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case STATUS.SET_STATUS_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case STATUS.SET_STATUS:
        case STATUS.SET_STATUS_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    status: action.status
                }
            })
        default:
            return state
    }
}

export default statusReducer