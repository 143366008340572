import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { Card } from '@mui/material';

import { constants } from "../../Constants/constants";
import { setQueueConfigValue } from "../../actions/queueConfigAction";
import { TempBtn } from "../../Component/Input/TempBtn";
import { TempAutoSelectInput } from "../../Component/Input/TempAutoSelectInput";

export const ResourcesSteps = ({onChangeInput, resource}) =>{

  const dispatch = useDispatch();
  const savedQueueConfig = useSelector(state => state.queueConfig.ui.queueConfig);

  function handleAddResourcesSteps() {
    let newResourcesSteps = _.cloneDeep(savedQueueConfig.resourcesSteps);
    newResourcesSteps && newResourcesSteps.length > 0 ? newResourcesSteps.push("") : newResourcesSteps = [""];
    dispatch(setQueueConfigValue("resourcesSteps", savedQueueConfig, "", newResourcesSteps));
  }

  function handleRemoveResourcesSteps(index) {
    let newResourcesSteps = _.cloneDeep(savedQueueConfig.resourcesSteps);
    if (newResourcesSteps && newResourcesSteps.length > 0) {
      newResourcesSteps = newResourcesSteps.filter((item, newResourcesStepsIndex) => newResourcesStepsIndex !== index)
    }
    dispatch(setQueueConfigValue("resourcesSteps", savedQueueConfig, "", newResourcesSteps));
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    let newResourcesSteps = _.cloneDeep(savedQueueConfig.resourcesSteps);
    const [reorderedItem] = newResourcesSteps.splice(result.source.index, 1);
    newResourcesSteps.splice(result.destination.index, 0, reorderedItem);
    dispatch(setQueueConfigValue("resourcesSteps", savedQueueConfig, "", newResourcesSteps));
  }


  return (
    <>
      <div>
        <TempBtn btnSetting={{
          onClick: handleAddResourcesSteps,
          variant: constants.STYLE.VARIANT.CONTAINED,
          color: constants.STYLE.PRIMARY,
          label: { resource: resource, key: "addResourcesSteps" },
          icon: <AddIcon />,
          disabled: savedQueueConfig?.resourcesSteps?.length>=3
        }}>
        </TempBtn>
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="templateValue">
          {(provided) => {
            return <div {...provided.droppableProps} ref={provided.innerRef}>
              {savedQueueConfig.resourcesSteps && savedQueueConfig.resourcesSteps.length > 0 && savedQueueConfig.resourcesSteps.map((item, resourcesStepsIndex) => {
                return <Draggable key={item + resourcesStepsIndex} draggableId={item + resourcesStepsIndex} index={resourcesStepsIndex}>
                  {(provided) => {
                    return <Card className="card brand-id-wrapper" key={resourcesStepsIndex}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div className="content-icon"><SwapVertIcon color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.LARGE} /></div>
                      <TempAutoSelectInput
                        inputSetting={
                          {
                            className: "edit-create-input",
                            size: constants.SIZE.SMALL,
                            label: { resource: resource, key: "resourcesSteps" },
                            value: item,
                            type: constants.TYPE.SELECT,
                            selectValue: constants.CHOICES.QUEUE_CONFIG.RESOURCES_STEPS,
                            name: "resourcesSteps",
                            disableClearable: true,
                            isEndOfLine: true
                          }}
                        handleChange={onChangeInput}
                        index={resourcesStepsIndex}
                      />
                      <TempBtn btnSetting={{
                        className: "edit-create-btn",
                        onClick: () => handleRemoveResourcesSteps(resourcesStepsIndex),
                        variant: constants.STYLE.VARIANT.CONTAINED,
                        color: constants.STYLE.PRIMARY,
                        label: { key: "deleteBtn" },
                        icon: <DeleteIcon />,
                      }}>
                      </TempBtn>
                    </Card>
                  }}
                </Draggable>
              })}
              {provided.placeholder}
            </div>
          }}
        </Droppable>
      </DragDropContext>
    </>
  )
}