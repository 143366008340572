import { useDispatch, useSelector } from "react-redux";

import DeleteIcon from '@mui/icons-material/Delete';
import { Card } from '@mui/material';

import { constants } from '../../../Constants/constants';
import { TempBtn } from "../../../Component/Input/TempBtn";
import { helperTextStyles } from "../../../helper/util";

import { removeUserGroupSettlementConfig } from "../../../actions/merchantUserGroupAction";
import MerchantUserGroupValidation from "../../../validations/merchantUserGroupValidation"
import { merchantUserGroupValidationConstants } from "../../../Constants/validationConstants";
import { SubTabEditCreateDisplay } from "../../../Component/EditCreateDisplay/SubTabEditCreateDisplay";
import { getContent } from "../../../Component/EditCreateDisplay/EditUtil";

const LOCAL_CONSTANT = {
  SETTLEMENT_CONFIG: "SETTLEMENT_CONFIG",
}

export const SettlementConfigList =({item, index, onChangeInput})=>{

  const dispatch = useDispatch();
  const savedMerchantUserGroup = useSelector(state => state.merchantUserGroup.ui.merchantUserGroup);
  const resource = "MerchantUserGroup";
  const helperTextStylesClasses = helperTextStyles();

  const tempList = [
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "serviceType" },
      value: item.serviceType,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.MERCHANT_USER_GROUP.SERVICE_TYPE,
      name: `settlementConfig-${index}-serviceType`,
      isEndOfLine: true,
      helperTextIcon: true,
      helperText: MerchantUserGroupValidation.getErrorMessages(`${index}-${merchantUserGroupValidationConstants.KEY_SERVICE_TYPE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "description" },
      value: item.description,
      name: `settlementConfig-${index}-description`,
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: MerchantUserGroupValidation.getErrorMessages(`${index}-${merchantUserGroupValidationConstants.KEY_DESCRIPTION}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "profile" },
      value: item.profile,
      name: `settlementConfig-${index}-profile`,
      isEndOfLine: true,
      helperTextIcon: true,
      helperText: MerchantUserGroupValidation.getErrorMessages(`${index}-${merchantUserGroupValidationConstants.KEY_PROFILE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "alipayHkRate" },
      value: item.alipayHkRate,
      name: `settlementConfig-${index}-alipayHkRate`,
      isEndOfLine: false,
      type: constants.TYPE.NUMBER,
      endAdornment: "%",
      helperTextIcon: true,
      helperText: MerchantUserGroupValidation.getErrorMessages(`${index}-${merchantUserGroupValidationConstants.KEY_ALIPAYHK_RATE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "wechatPayRate" },
      value: item.wechatPayRate,
      name: `settlementConfig-${index}-wechatPayRate`,
      type: constants.TYPE.NUMBER,
      endAdornment: "%",
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: MerchantUserGroupValidation.getErrorMessages(`${index}-${merchantUserGroupValidationConstants.KEY_WECHATPAY_RATE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "stripeRate" },
      type: constants.TYPE.NUMBER,
      endAdornment: "%",
      value: item.stripeRate,
      name: `settlementConfig-${index}-stripeRate`,
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: MerchantUserGroupValidation.getErrorMessages(`${index}-${merchantUserGroupValidationConstants.KEY_STRIPE_RATE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "yedRate" },
      type: constants.TYPE.NUMBER,
      endAdornment: "%",
      value: item.yedRate,
      name: `settlementConfig-${index}-yedRate`,
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: MerchantUserGroupValidation.getErrorMessages(`${index}-${merchantUserGroupValidationConstants.KEY_YED_RATE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "mpayRate" },
      value: item.mpayRate,
      name: `settlementConfig-${index}-mpayRate`,
      type: constants.TYPE.NUMBER,
      endAdornment: "%",
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: MerchantUserGroupValidation.getErrorMessages(`${index}-${merchantUserGroupValidationConstants.KEY_MPAY_RATE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "guluRate" },
      value: item.guluRate,
      name: `settlementConfig-${index}-guluRate`,
      type: constants.TYPE.NUMBER,
      endAdornment: "%",
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: MerchantUserGroupValidation.getErrorMessages(`${index}-${merchantUserGroupValidationConstants.KEY_GULU_RATE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "saleSettlementProportion" },
      value: item.saleSettlementProportion,
      name: `settlementConfig-${index}-saleSettlementProportion`,
      type: constants.TYPE.NUMBER,
      endAdornment: "%",
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: MerchantUserGroupValidation.getErrorMessages(`${index}-${merchantUserGroupValidationConstants.KEY_SALE_SETTLEMENT_PROPORTION}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "redemptionSettlementProportion" },
      value: item.redemptionSettlementProportion,
      name: `settlementConfig-${index}-redemptionSettlementProportion`,
      type: constants.TYPE.NUMBER,
      endAdornment: "%",
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: MerchantUserGroupValidation.getErrorMessages(`${index}-${merchantUserGroupValidationConstants.KEY_REDEMPTION_SETTLEMENT_PROPORTION}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "expiredSettlementRate" },
      value: item.expiredSettlementRate,
      name: `settlementConfig-${index}-expiredSettlementRate`,
      type: constants.TYPE.NUMBER,
      endAdornment: "%",
      isEndOfLine: true,
      helperTextIcon: true,
      helperText: MerchantUserGroupValidation.getErrorMessages(`${index}-${merchantUserGroupValidationConstants.KEY_EXPIRED_SETTLEMENT_RATE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "startTimestamp" },
      value: item.startTimestamp,
      type: constants.TYPE.DATETIME_LOCAL,
      step: 1,
      name: `settlementConfig-${index}-startTimestamp`,
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: MerchantUserGroupValidation.getErrorMessages(`${index}-${merchantUserGroupValidationConstants.KEY_START_TIMESTAMP}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "endTimestamp" },
      type: constants.TYPE.DATETIME_LOCAL,
      step: 1,
      value: item.endTimestamp,
      name: `settlementConfig-${index}-endTimestamp`,
      isEndOfLine: true,
      helperTextIcon: true,
      helperText: MerchantUserGroupValidation.getErrorMessages(`${index}-${merchantUserGroupValidationConstants.KEY_END_TIMESTAMP}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
  ]

  const content = [
    {
      tab: tempList.map((inputSetting, index) => {
        let content = getContent(inputSetting, index, onChangeInput);
        return <div className="edit-display-wrapper" key={index}>
          {content}
          {inputSetting.isEndOfLine && <div></div>}
        </div>
      }),
      btn: false
    }
  ]

  const handeleDelete = (index) =>{
    MerchantUserGroupValidation.resetErrorMessages(LOCAL_CONSTANT.SETTLEMENT_CONFIG, savedMerchantUserGroup?.settlementConfigList?.length, index)
    dispatch(removeUserGroupSettlementConfig(savedMerchantUserGroup, index))
  }

  return (
    <Card>
      <div className="delete-icon">
        <TempBtn btnSetting={{
          className: "edit-create-btn",
          variant: constants.STYLE.VARIANT.CONTAINED,
          color: constants.STYLE.PRIMARY,
          onClick: () => handeleDelete(index),
          label: { key: "deleteBtn" },
          icon: <DeleteIcon />,
        }} />
      </div>
      <SubTabEditCreateDisplay
          resource={resource}
          tabs={false}
          content={content}
          onChangeInput={onChangeInput}
          bottomBtns={false}
          display="none"
        />
    </Card>
  )
}