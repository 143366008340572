import _ from 'lodash'
import { QUEUE_PROFILE } from '../actions/types'
import { constants } from '../Constants/constants'

const INITIAL_STATE = {
  ui: {
    queueProfile: _.cloneDeep(constants.QUEUE_PROFILE_INPUT),
  },
}

const queueProfileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case QUEUE_PROFILE.SET_QUEUE_PROFILE_DEFAULT:
      return Object.assign({}, INITIAL_STATE)
    case QUEUE_PROFILE.SET_QUEUE_PROFILE:
    case QUEUE_PROFILE.SET_QUEUE_PROFILE_VALUE:
      return Object.assign(
        {},
        {
          ...state,
          ui: {
            ...state.ui,
            queueProfile: action.queueProfile,
          },
        }
      )
    default:
      return state
  }
}

export default queueProfileReducer
