import { constants } from '../../../Constants/constants';
import _ from "lodash";

export const productSave = (savedProduct) => {
  let tempDraft = _.cloneDeep(savedProduct.draft);
  const tempProduct = _.cloneDeep(tempDraft.product);
  let templotInventoryList = _.cloneDeep(tempDraft.lotInventory);
  let productList = _.cloneDeep(tempDraft.productList);
  let tempProductCode = _.cloneDeep(tempDraft.codeNumber);
  const newProduct = _.cloneDeep(!tempProduct.productCode);

  if (tempProduct.productCode === tempDraft.productCode) {
    tempProduct.lotInventoryList = templotInventoryList
  } else {
    productList = productList.map((item, index) => {
      if (tempDraft.productCode === item.productCode) {
        item.lotInventoryList = templotInventoryList
      }
      return item;
    })
  }

  if (tempProduct.type === constants.PRODUCT_TYPE.PACKAGE_SUB_ITEM) {
    let tempProductList = _.cloneDeep(productList.filter((item) => { return tempProduct.productCode !== item.productCode }));
    const tempSubProductIndex = _.cloneDeep(tempDraft.subProductList.findIndex((item) => { return tempProduct.productCode === item.productCode }));
    const tempSubProductList = _.cloneDeep(tempDraft.subProductList);
    tempSubProductList[tempSubProductIndex] = _.cloneDeep(tempProduct);
    if (tempSubProductIndex < 0) {
      tempSubProductList.push(tempProduct);
    } else {
      tempSubProductList[tempSubProductIndex] = _.cloneDeep(tempProduct);
    }

    let newTempProductList = [];

    newTempProductList = tempProductList.map((item, index) => {
      if (item.redeemCodeApiType !== constants.LOT_INVENTORY_TYPE.MEZZOFY && item) {
        if (item.lotInventoryList) {
          item.lotInventoryList = item.lotInventoryList.filter((templotInventory) => {
            let subProductLotInventoryList = templotInventory.subProductLotInventoryList;

            if (item.subProductMapList && item.subProductMapList.length > 0) {
              let newSubProductMapList = item.subProductMapList.map((subProductMapList) => subProductMapList.productCode)
              let tempSubProductMapList = newSubProductMapList.filter(function (item, pos) {
                return newSubProductMapList.indexOf(item) === pos;
              })

              subProductLotInventoryList = subProductLotInventoryList.filter((item, index) => {
                return tempSubProductMapList.includes(item.productCode)
              })

              let newSubProductLotInventory = subProductLotInventoryList.map((subProductLotInventory) => subProductLotInventory.productCode)
              tempSubProductMapList.filter((item, index) => {
                if (!newSubProductLotInventory.includes(item)) {
                  subProductLotInventoryList.push(
                    {
                      subProductLotInventoryValiCode: "Sub_ProductLot_Inventory_" + Number(tempProductCode),
                      productCode: item,
                      quantity: 0,
                      cosFileKey: ""
                    }
                  )
                  tempProductCode = tempProductCode + 1;
                }
                return {};
              })

            } else {
              subProductLotInventoryList = [];
            }

            templotInventory.subProductLotInventoryList = subProductLotInventoryList;
            return templotInventory
          })
        }
      }
      return item;
    })

    tempDraft.productList = newTempProductList;
    tempDraft.subProductList = tempSubProductList;
    if (newProduct) {
      tempDraft.codeNumber = tempProductCode + 1;
    }
    tempDraft.product = tempProduct;
  } else {
    const tempSubProductList = _.cloneDeep(tempDraft.subProductList.filter((item) => { return tempProduct.productCode !== item.productCode }));
    const tempProductIndex = _.cloneDeep(productList.findIndex((item) => tempProduct.productCode === item.productCode));
    const tempProductList = _.cloneDeep(productList);
    if (tempProductIndex < 0) {
      tempProductList.push(tempProduct);
    } else {
      tempProductList[tempProductIndex] = _.cloneDeep(tempProduct);
    }

    let newTempProductList = [];

    newTempProductList = tempProductList.map((item, index) => {
      if (item.redeemCodeApiType !== constants.LOT_INVENTORY_TYPE.MEZZOFY && item) {
        if (item.lotInventoryList) {
          item.lotInventoryList = item.lotInventoryList.filter((templotInventory) => {
            let subProductLotInventoryList = templotInventory.subProductLotInventoryList;

            if (item.subProductMapList && item.subProductMapList.length > 0) {
              let newSubProductMapList = item.subProductMapList.map((subProductMapList) => subProductMapList.productCode)
              let tempSubProductMapList = newSubProductMapList.filter(function (item, pos) {
                return newSubProductMapList.indexOf(item) === pos;
              })

              subProductLotInventoryList = subProductLotInventoryList.filter((item, index) => {
                return tempSubProductMapList.includes(item.productCode)
              })

              let newSubProductLotInventory = subProductLotInventoryList.map((subProductLotInventory) => subProductLotInventory.productCode)
              tempSubProductMapList.filter((tempSubProductMap, index) => {
                if (!newSubProductLotInventory.includes(tempSubProductMap)) {
                  item.subProductMapList.map((subProductMap) => {
                    if (subProductMap.productCode === tempSubProductMap) {
                      subProductLotInventoryList.push(
                        {
                          subProductLotInventoryValiCode: "Sub_ProductLot_Inventory_" + Number(tempProductCode),
                          productCode: tempSubProductMap,
                          quantity: subProductMap.quantity * templotInventory.quantity,
                          cosFileKey: ""
                        }
                      )
                      tempProductCode = tempProductCode + 1;
                    }
                    return {};
                  })
                } else {
                  subProductLotInventoryList.map((subProductLotInventory) => {
                    let totalQuantity = 0;
                    item.subProductMapList.map((subProductMap) => {
                      if (subProductMap.productCode === subProductLotInventory.productCode) {
                        totalQuantity += subProductMap.quantity
                        subProductLotInventory.quantity = templotInventory.quantity * totalQuantity
                      }
                      return {};
                    })
                    return subProductLotInventoryList;
                  })
                }
                return {};
              })

            } else {
              subProductLotInventoryList = [];
            }

            templotInventory.subProductLotInventoryList = subProductLotInventoryList;
            return templotInventory
          })
        }
      }
      return item;
    })

    tempDraft.productList = newTempProductList;
    tempDraft.subProductList = tempSubProductList;
    tempDraft.product = tempProduct;
  }

  // ProductCreateValidation.validateDraft(tempDraft);

  return tempDraft;
}