import { useState, useEffect } from 'react'
import { useDispatch, useStore, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import _ from 'lodash'

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import { constants } from '../../Constants/constants'
import { post } from '../../helper/baseFetch'
import { setRestaurantClone, setRestaurantCloneValue } from '../../actions/restaurantAction'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { TempTextInput } from '../../Component/Input/TempTextInput'
import { TempCheckBox } from '../../Component/Input/TempCheckBox'
import { DialogWrapper } from '../../Component/DialogWrapper'
import { helperTextStyles } from '../../helper/util'
import { MerchantUserCreate } from '../MerchantUser/MerchantUserCreate'
import { Box } from '@mui/material'

import MerchantUserValidation from '../../validations/merchantUserValidation'
import { setMerchantUser } from '../../actions'

const restUrlIdRegex = /^[\d]{1,20}$/

export const RestaurantClone = () => {
  //----------------------Merchant User Create------------------------
  const savedMerchantUser = useSelector((state) => state.merchantUser.ui.merchantUser)
  // eslint-disable-next-line no-unused-vars
  const [errorVersion, setErrorVersion] = useState(MerchantUserValidation.getVersion())
  //----------------------End Of Merchant User Create------------------------
  const params = useParams()
  const dispatch = useDispatch()
  const store = useStore()
  const history = useHistory()
  const [clear, setClear] = useState(false)

  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  const [restUrlIdError, setRestUrlIdError] = useState(null)
  const savedRestaurantClone = useSelector((state) => state.restaurant.ui.restaurantClone)
  const helperTextStylesClasses = helperTextStyles()
  const resource = 'restaurant'
  const tabs = [
    {
      label: { resource: resource, key: 'info' },
      tabIndex: constants.AREA_EDIT_TAB.INFO,
    },
  ]

  const tempEditList = [
    {
      className: 'edit-create-input',
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'restUrlId' },
      value: savedRestaurantClone.restUrlIdSource,
      name: 'restUrlIdSource',
      isEndOfLine: true,
      disabled: true,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'newRestUrlID' },
      value: savedRestaurantClone.restUrlIdTarget,
      name: 'restUrlIdTarget',
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: restUrlIdError,
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input edit-display-wrapper',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'queue' },
      value: savedRestaurantClone.queue,
      name: 'queue',
      type: constants.TYPE.CHECKBOX,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input edit-display-wrapper',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'reservation' },
      value: savedRestaurantClone.reservation,
      name: 'reservation',
      type: constants.TYPE.CHECKBOX,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input edit-display-wrapper',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'slideshow' },
      value: savedRestaurantClone.slideshow,
      name: 'slideshow',
      type: constants.TYPE.CHECKBOX,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input edit-display-wrapper',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'banner' },
      value: savedRestaurantClone.banner,
      name: 'banner',
      type: constants.TYPE.CHECKBOX,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input edit-display-wrapper',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'systemGroup' },
      value: savedRestaurantClone.systemGroup,
      name: 'systemGroup',
      type: constants.TYPE.CHECKBOX,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input edit-display-wrapper',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'systemGroupGorilla' },
      value: savedRestaurantClone.systemGroupGorilla,
      name: 'systemGroupGorilla',
      type: constants.TYPE.CHECKBOX,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input edit-display-wrapper',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'payment' },
      value: savedRestaurantClone.payment,
      name: 'payment',
      type: constants.TYPE.CHECKBOX,
      isEndOfLine: false,
    },
  ]

  const content = [
    {
      tab: (
        <>
          {tempEditList.map((inputSetting, index) => {
            let content = ''
            switch (inputSetting.type) {
              case constants.TYPE.CHECKBOX:
                content = (
                  <TempCheckBox
                    checkBoxSetting={{
                      className: inputSetting.className,
                      name: inputSetting.name,
                      label: inputSetting.label,
                      value: inputSetting.value,
                    }}
                    handleChange={handleCheckBoxChange}
                  />
                )
                break
              default:
                content = <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
            }
            return (
              <div className="edit-display-wrapper" key={index}>
                {content}
                {inputSetting.isEndOfLine && <div></div>}
              </div>
            )
          })}
          <Box sx={{ pt: '30px', pb: '30px' }}>
            <TempCheckBox
              checkBoxSetting={{
                className: 'edit-create-input edit-display-wrapper',
                name: 'merchantUserCreate',
                label: { resource: resource, key: 'merchantUserCreate' },
                value: savedRestaurantClone.merchantUserCreate,
              }}
              handleChange={handleCheckBoxChange}
            />
            {savedRestaurantClone.merchantUserCreate && (
              <MerchantUserCreate hideTab={true} hideBottomBtnList={true} hidePrivilegeRestUrlIdEditButton={true} embeddedInRestaurantClone={true} />
            )}
          </Box>
        </>
      ),
      btn: true,
    },
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'save' },
      disabled:
        !savedRestaurantClone.restUrlIdSource ||
        !savedRestaurantClone.restUrlIdTarget ||
        (savedRestaurantClone.merchantUserCreate &&
          (!savedMerchantUser.privilegeRestUrlId || !savedMerchantUser.username || !savedMerchantUser.password)),
      onClick: onClickSave,
      className: 'margin-left-16',
      icon: <SaveIcon />,
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'clear' },
      disabled: false,
      onClick: onClickClear,
      className: 'margin-left-16',
      icon: <ClearIcon />,
    },
  ]

  const handleBackClone = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push('/' + constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT)
  }

  function onChangeInput(name, e) {
    dispatch(setRestaurantCloneValue(name, savedRestaurantClone, '', e))
    if (name === 'restUrlIdTarget') {
      let tempMerchantUser = _.cloneDeep(savedMerchantUser)
      tempMerchantUser.privilegeRestUrlId = e
      tempMerchantUser.username = 'shop' + e
      dispatch(setMerchantUser(tempMerchantUser))
    }
  }
  function handleCheckBoxChange(name, e) {
    dispatch(setRestaurantCloneValue(name, savedRestaurantClone, '', e ? false : true)) // e False When Switch On The Checkbox

    if (name === 'merchantUserCreate' && !e && savedRestaurantClone.restUrlIdTarget) {
      let tempMerchantUser = _.cloneDeep(savedMerchantUser)
      tempMerchantUser.privilegeRestUrlId = savedRestaurantClone.restUrlIdTarget
      tempMerchantUser.username = 'shop' + savedRestaurantClone.restUrlIdTarget
      dispatch(setMerchantUser(tempMerchantUser))
    }
  }

  async function onClickSave() {
    if (!restUrlIdRegex.test(savedRestaurantClone.restUrlIdTarget)) {
      setRestUrlIdError('restUrlId includes invalid characters or Too long . Valid Character: numbers. Possible length: <=20')
      return
    } else {
      setRestUrlIdError('')
    }

    const tempRestaurantClone = _.cloneDeep(savedRestaurantClone)
    let body = tempRestaurantClone

    //----------------------Merchant User Create------------------------

    if (savedRestaurantClone.merchantUserCreate) {
      MerchantUserValidation.validateCreateMerchantUser(savedMerchantUser)

      if (MerchantUserValidation.isValid()) {
        body = {
          ...tempRestaurantClone,
          merchantUser: {
            username: savedMerchantUser.username,
            email: savedMerchantUser.email,
            password: savedMerchantUser.password,
            userRole: savedMerchantUser.userRole,
            privilegeAttribute: savedMerchantUser.privilegeAttribute,
            privilegeRestUrlId: savedMerchantUser.privilegeRestUrlId,
            privilegeGroupCode: savedMerchantUser.privilegeGroupCode,
            merchantRole: savedMerchantUser.merchantRole,
            merchantUserList: savedMerchantUser.merchantUserList,
          },
        }
        MerchantUserValidation.resetErrorMessages()
      } else {
        setErrorVersion(MerchantUserValidation.getVersion())
        return
      }
    }

    //----------------------End Of Merchant User Create------------------------

    post(
      constants.PATH.SHOP.RESTAURANT_CLONE,
      body,
      () => {
        setDialog({
          open: true,
          onClose: handleBackClone,
          dialogContentText: { key: 'createSuccess' },
          dialogActions: [
            { label: { key: 'backCreate' }, onClick: handleBackClone, icon: <ArrowBackIcon /> },
            { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
          ],
        })
        setClear(false)
      },
      undefined,
      store
    )
  }

  function onClickClear() {
    setRestUrlIdError('')
    setClear(true)
  }

  useEffect(() => {
    let tempRestaurantClone = _.cloneDeep(constants.RESTAURANT_CLONE_INPUT)
    tempRestaurantClone.restUrlIdSource = params.id
    dispatch(setRestaurantClone(tempRestaurantClone))
    setClear(false)
    setRestUrlIdError('')

    dispatch(setMerchantUser(constants.MERCHANT_USER_INPUT))
    MerchantUserValidation.resetErrorMessages()
  }, [clear === true]) // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   console.log('savedRestaurantClone', savedRestaurantClone)
  // }, [savedRestaurantClone])

  // useEffect(() => {
  //   console.log('savedMerchantUser', savedMerchantUser)
  // }, [savedMerchantUser])

  return (
    <>
      <EditCreateDisplay
        code=""
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        display="clone"
      />
      {dialog && dialog.open && <DialogWrapper dialogInfo={dialog} />}
    </>
  )
}
