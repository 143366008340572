import React, {useState, useEffect, forwardRef} from 'react';
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';

import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { constants, PRINT_TEMPLATE_TYPE } from '../../Constants/constants';
import { setTemplate, setTemplateList } from "../../actions/printTemplateAction";
import { TempBtn } from "../../Component/Input/TempBtn";
import PrintTemplateValidation from "../../validations/printTemplateValidation";

const resource = "PrintTemplate";

export const DragnDrop = ({setErrorVersion, handleTemplateText}) =>{

  const dispatch = useDispatch();
  const savedTemplate = useSelector(state => state.printTemplate.ui.template);
  const savedTemplateList = useSelector(state => state.printTemplate.ui.templateList);
  const [activeId, setActiveId] = useState(null);
  const [items, setItems] = useState(savedTemplate.value?.map((item,idx)=>`${idx}*@*${item}`));

  const sensors = useSensors(
    useSensor(PointerSensor, {
        activationConstraint: { distance: 8, }
      }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
      activationConstraint: { distance: 8, },
    }),
  );

  useEffect(() => {
    setItems(savedTemplate.value?.map((item,idx)=>`${idx}*@*${item}`));
  }, [savedTemplate])
  
  

  return (
    
    <div className="bottom center-item">
      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragEnd}
      >
        
        <SortableContext items={items} strategy={() => {}}>
          {items.map((id) => (
            <SortableItem 
            key={id} 
            id={id} 
            setErrorVersion={setErrorVersion} 
            handleTemplateText={handleTemplateText} 
            type={savedTemplate.type}
            items={items} 
            setItems={setItems}/>
          ))}
        </SortableContext>
        <DragOverlay>{activeId ? <Item label={activeId} /> : null}</DragOverlay>
        <AddItemBtn handleTemplateText={handleTemplateText} />
        
      </DndContext>
      </div>
    
  );

  function handleDragOver(event) {
    const {active, over} = event;

    if (over && active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }

  function handleDragStart(event) {
    setActiveId(event.active.id);
  }

  function handleDragEnd(event) {

    
    let tempSavedTemplate = _.cloneDeep(savedTemplate);
    let tempSavedTemplateList = _.cloneDeep(savedTemplateList);

    tempSavedTemplate.value = items.map((item)=>item.split("*@*")[1]);
    tempSavedTemplateList = tempSavedTemplateList.map((item) => {
      if (item.customId === tempSavedTemplate.customId) {
        item = tempSavedTemplate;
      }
      return item;
    })

    dispatch(setTemplateList(tempSavedTemplateList));
    dispatch(setTemplate(tempSavedTemplate));
    setActiveId(null);
  }
}

function SortableItem({id, setErrorVersion, handleTemplateText, items, setItems, type}) {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: id});

  const style = {
    position: 'relative',
    display: 'inline-block',
    opacity: isDragging ? 0.5 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <Item
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      label={id}
      items={items}
      setItems={setItems}
      setErrorVersion={setErrorVersion} 
      handleTemplateText={handleTemplateText}
      type={type}
    />
  );
}

const Item = forwardRef(({label, style, setErrorVersion, handleTemplateText, items, setItems, type, ...props}, ref) => {

  const dispatch = useDispatch();
  const savedTemplate = useSelector(state => state.printTemplate.ui.template);
  const savedTemplateList = useSelector(state => state.printTemplate.ui.templateList);
  const value = label.split("*@*")[1];
  const index = label.split("*@*")[0];

  function handleDeleteTemplateText(removeIndex) {
    let tempSavedTemplate = _.cloneDeep(savedTemplate);
    let tempSavedTemplateList = _.cloneDeep(savedTemplateList);
    let tempItems = _.cloneDeep(items).filter((template, index) => index !== parseInt(removeIndex));
    tempSavedTemplate.value = tempItems.map((item)=>item.split("*@*")[1]);
    tempSavedTemplateList = tempSavedTemplateList.map((item) => {
      if (item.customId === tempSavedTemplate.customId) {
        item = tempSavedTemplate;
      }
      return item;
    })
    setItems(tempItems);
    dispatch(setTemplateList(tempSavedTemplateList));
    dispatch(setTemplate(tempSavedTemplate));
    PrintTemplateValidation.validatePrintTempTemplate(tempSavedTemplateList);
    setErrorVersion(PrintTemplateValidation.getVersion());
  }

  return (
    <div className={`${canOnclick(value, type)?'sub-item':'sub-item disable'}`}
      ref={ref}
      {...props}
      style={{...style}}
      onClick={
        canOnclick(value, type)?
        (e)=>{
        e.stopPropagation();
        handleTemplateText("edit", value, index)
      } : null
    }
    
    >
      <div className="sub-content">
        <SwapHorizIcon color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />
        <div className="sub-item-name">{getValueLabel(value)}</div>
        {
          <DeleteItemIcon handleDeleteTemplateText={handleDeleteTemplateText} index={index} type={type} value={value}/>
        }
      </div>
    </div>
  );
});

const AddItemBtn = ({handleTemplateText}) =>{

  const savedTemplate = useSelector(state => state.printTemplate.ui.template);

  return (
    !( 
      (
        savedTemplate.type === PRINT_TEMPLATE_TYPE.LOGO 
        || savedTemplate.type === PRINT_TEMPLATE_TYPE.HEADER
        || savedTemplate.type === PRINT_TEMPLATE_TYPE.FOOTER
        || savedTemplate.type === PRINT_TEMPLATE_TYPE.PRINT_IMAGE 
      )
      && savedTemplate.value 
      && savedTemplate.value.length > 0) &&
      <TempBtn btnSetting={{
        className: "center-item type-3-add-btn",
        variant: constants.STYLE.VARIANT.CONTAINED,
        color: constants.STYLE.PRIMARY,
        label: { resource: resource, key: "addText" },
        disabled: false,
        onClick: () => handleTemplateText("add"),
        name: "handleAddText",
        type: constants.TYPE.BUTTON,
        icon: <AddIcon />,
      }} />
  )
}

const DeleteItemIcon = ({handleDeleteTemplateText, index, type, value}) => {

  if(value === '${remarks}'
  || value === '${logo}'
  || value === '${header}'
  || value === '${footer}'
  ) {
    return null;
  }

  return (
    <div
    className="queue-template-button-on-top"
    onClick={(e) => {
      e.stopPropagation();
      handleDeleteTemplateText(index);
    }}
  >
    <DeleteIcon fontSize={constants.SIZE.SMALL} />
  </div>
  )
}

const getValueLabel = (value) => {
  if(value === '${remarks}') {
    return '${tnc}'
  }

  return value
}

const canOnclick = (value, type) => {
  if(type === PRINT_TEMPLATE_TYPE.LOGO
    || type === PRINT_TEMPLATE_TYPE.HEADER
    || type === PRINT_TEMPLATE_TYPE.FOOTER
    ) {
      return false;
  }

  if(type === PRINT_TEMPLATE_TYPE.TERMS_AND_CONDITIONS && value === '${remarks}') {
    return false;
  }

  return true;
}