import { useSelector } from "react-redux";

// import SplitPane from 'react-split-pane';
import { Allotment } from 'allotment';
import 'allotment/dist/style.css';

import { RestaurantSlideshowCreateForBulk } from "./RestaurantSlideshowCreateForBulk";
import { RestaurantSlideshowCreateListForBulk } from "./RestaurantSlideshowCreateListForBulk";

export const AllSlideshowCreateContent = ({slideshowList, setSlideshowList, customIdNumber, setCustomIdNumber}) => {

  const savedRestaurantSlideshow = useSelector(state => state.restaurantSlideshow.ui.restaurantSlideshow);
  const resource = "RestaurantSlideshow";

  return (
    <>
    <Allotment defaultSizes={[100, 200]}>
          <div className="split-pane-left" style={{height: '75vh', minHeight: '590px'}}>
            <RestaurantSlideshowCreateListForBulk resource={resource} setCustomIdNumber={setCustomIdNumber} customIdNumber={customIdNumber} slideshowList={slideshowList} setSlideshowList={setSlideshowList} />
          </div>
            
          <div className="split-pane-right" style={{height: '75vh', minHeight: '590px', overflow: "auto"}}>
              {savedRestaurantSlideshow.customId && slideshowList?.length>0 &&
                <RestaurantSlideshowCreateForBulk hideRestUrlId create={true} customIdNumber={customIdNumber} dataReady={true} slideshowList={slideshowList} setSlideshowList={setSlideshowList}/>
              }
          </div>
      </Allotment>
  </>
  )
}