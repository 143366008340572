import _ from "lodash";
import { CONFIG_MAP } from '../actions/types';
import { constants } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        configMap: _.cloneDeep(constants.CONFIG_MAP_INPUT)
    }
}

const configMapReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONFIG_MAP.SET_CONFIG_MAP_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case CONFIG_MAP.SET_CONFIG_MAP:
        case CONFIG_MAP.SET_CONFIG_MAP_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    configMap: action.configMap
                }
            })
        default:
            return state
    }
}

export default configMapReducer