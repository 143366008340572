import { constants } from '../Constants/constants';
import { productValidationConstants } from '../Constants/validationConstants';

class ProductCreateValidation {

  version;
  errorMap;
  categoryErrorMap;
  categoryImageErrorMap;
  redeemGroupErrorMap;
  productErrorMap;
  productLotErrorMap;
  valid;

  constructor() {

    if (!ProductCreateValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.categoryErrorMap = new Map();
      this.categoryImageErrorMap = new Map();
      this.redeemGroupErrorMap = new Map();
      this.productErrorMap = new Map();
      this.productLotErrorMap = new Map();
      ProductCreateValidation.instance = this;
    }
    return ProductCreateValidation.instance;
  }

  validateDraft(draft) {
    let allRedeemGroupList = [];
    if (draft.redeemGroupList.length > 0) {
      draft.redeemGroupList.forEach((item) => {
        allRedeemGroupList.push(item);
      })
    }
    if (draft.existingRedeemGroupList.length > 0) {
      draft.existingRedeemGroupList.forEach((item) => {
        allRedeemGroupList.push(item);
      })
    }
    this.resetErrorMessages();
    // this.validateUserGroup(draft.userGroupCode);
    this.validateCategory(draft.category, false);
    this.validateRedeemGroupList(draft.redeemGroupList, draft.existingRedeemGroupList);
    if (draft.productList.length > 0) {
      this.validateProductLists(draft.productList, allRedeemGroupList, draft.category.type)
      if (draft.subProductList.length > 0) {
        this.validateProductLists(draft.subProductList, allRedeemGroupList, draft.category.type)
      }
    } else {
      this.validateProductLists()
    }
    this.version++;
  }

  validateCategoryOnly(draft) {
    this.categoryErrorMap = new Map();
    this.categoryImageErrorMap = new Map();
    this.valid = true;
    this.validateCategory(draft.category);
    this.version++;
  }

  validateRedeemOnly(draft) {
    this.redeemGroupErrorMap = new Map();
    this.valid = true;
    this.validateRedeemGroupList([draft.redeemGroup], [draft.existingRedeemGroupList]);
    this.version++;
  }

  validateProductOnly(draft) {
    this.productErrorMap = new Map();
    this.productLotErrorMap = new Map();
    this.valid = true;
    this.validateProduct(draft.product);
    this.validateProductLot(draft.product);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.categoryErrorMap = new Map();
    this.categoryImageErrorMap = new Map();
    this.redeemGroupErrorMap = new Map();
    this.productErrorMap = new Map();
    this.productLotErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key, uniqueCode, subUniqueCode, subSubUniqueCode) {
    if (!uniqueCode) {
      return (this.errorMap.has(key)) ? this.errorMap.get(key) : [];
    } else {
      switch (key) {
        case productValidationConstants.KEY_CATEGORY_TYPE:
        case productValidationConstants.KEY_CATEGORY_CODE:
        case productValidationConstants.KEY_CATEGORY_DESCRIPTION:
        case productValidationConstants.KEY_CATEGORY_CHANNEL:
        case productValidationConstants.KEY_CATEGORY_NAME_TC:
        case productValidationConstants.KEY_CATEGORY_NAME_EN:
        case productValidationConstants.KEY_CATEGORY_NAME_SC:
        case productValidationConstants.KEY_CATEGORY_START_DISPLAY_TIMESTAMP:
        case productValidationConstants.KEY_CATEGORY_END_DISPLAY_TIMESTAMP:
        case productValidationConstants.KEY_CATEGORY_DISPLAY_SEQUENCE:
        case productValidationConstants.KEY_CATEGORY_IMAGE_LIST:
        case productValidationConstants.KEY_CATEGORY_IMAGE:
        case productValidationConstants.KEY_CATEGORY_PRODUCT_MAP_DISPLAY_SEQUENCE:
          if (this.categoryErrorMap.has(uniqueCode) && this.categoryErrorMap.get(uniqueCode).has(key)) {
            return this.categoryErrorMap.get(uniqueCode).get(key);
          }
          return [];
        case productValidationConstants.KEY_CATEGORY_IMAGE_VALI_CODE:
        case productValidationConstants.KEY_CATEGORY_IMAGE_TYPE:
        case productValidationConstants.KEY_CATEGORY_IMAGE_DISPLAY_SEQUENCE:
          if (this.categoryImageErrorMap.has(uniqueCode) && this.categoryImageErrorMap.get(uniqueCode).has(key)) {
            return this.categoryImageErrorMap.get(uniqueCode).get(key);
          }
          return [];
        case productValidationConstants.KEY_REDEEM_GROUP_ERROR:
        case productValidationConstants.KEY_REDEEM_GROUP_CODE:
        case productValidationConstants.KEY_REDEEM_GROUP_CODE_DESCRIPTION:
        case productValidationConstants.KEY_REDEEM_GROUP_SELECT_LOCATION:
        case productValidationConstants.KEY_REDEEM_GROUP_SELECT_DATE:
        case productValidationConstants.KEY_REDEEM_GROUP_SELECT_TIME:
        case productValidationConstants.KEY_REDEEM_GROUP_REDEEM_TYPE:
        case productValidationConstants.KEY_REDEEM_GROUP_START_REDEEM_TIMESTAMP:
        case productValidationConstants.KEY_REDEEM_GROUP_END_REDEEM_TIMESTAMP:
        case productValidationConstants.KEY_REDEEM_GROUP_START_AFTER_DAY:
        case productValidationConstants.KEY_REDEEM_GROUP_END_AFTER_DAY:
          // case productValidationConstants.KEY_REDEEM_GROUP_REDEEM_LOCATION_LIST:
          if (this.redeemGroupErrorMap.has(uniqueCode) && this.redeemGroupErrorMap.get(uniqueCode).has(key)) {
            return this.redeemGroupErrorMap.get(uniqueCode).get(key);
          }
          return [];
        case productValidationConstants.KEY_REDEEM_GROUP_REDEEM_LOCATION_REST_URL_ID:
        case productValidationConstants.KEY_REDEEM_GROUP_REDEEM_LOCATION_START_TIME:
        case productValidationConstants.KEY_REDEEM_GROUP_REDEEM_LOCATION_END_TIME:
        case productValidationConstants.KEY_REDEEM_GROUP_REDEEM_LOCATION_INTERVAL:
          if (this.redeemGroupErrorMap.has(uniqueCode) && this.redeemGroupErrorMap.get(uniqueCode).has(subUniqueCode) && this.redeemGroupErrorMap.get(uniqueCode).get(subUniqueCode).has(key)) {
            return this.redeemGroupErrorMap.get(uniqueCode).get(subUniqueCode).get(key);
          }
          return [];
        // case productValidationConstants.KEY_PRODUCT_BRAND_CODE:
        // case productValidationConstants.KEY_PRODUCT_SETTLEMENT_CONFIG:
        // case productValidationConstants.KEY_PRODUCT_TC_DESCRIPTION:
        // case productValidationConstants.KEY_PRODUCT_EN_DESCRIPTION:
        // case productValidationConstants.KEY_PRODUCT_SC_DESCRIPTION:
        // case productValidationConstants.KEY_PRODUCT_TC_TAC:
        // case productValidationConstants.KEY_PRODUCT_EN_TAC:
        // case productValidationConstants.KEY_PRODUCT_SC_TAC:
        // case productValidationConstants.KEY_PRODUCT_TC_REDEEM_DESCRIPTION:
        // case productValidationConstants.KEY_PRODUCT_EN_REDEEM_DESCRIPTION:
        // case productValidationConstants.KEY_PRODUCT_SC_REDEEM_DESCRIPTION:
        // case productValidationConstants.KEY_PRODUCT_TC_DISCOUNT_DESCRIPTION:
        // case productValidationConstants.KEY_PRODUCT_EN_DISCOUNT_DESCRIPTION:
        // case productValidationConstants.KEY_PRODUCT_SC_DISCOUNT_DESCRIPTION:
        case productValidationConstants.KEY_PRODUCT_ERROR:
        case productValidationConstants.KEY_PRODUCT_CODE:
        case productValidationConstants.KEY_PRODUCT_TYPE:
        case productValidationConstants.KEY_PRODUCT_CHANNEL:
        case productValidationConstants.KEY_PRODUCT_REDEEM_GROUP_CODE:
        case productValidationConstants.KEY_PRODUCT_SYSTEM_GROUP:
        case productValidationConstants.KEY_PRODUCT_TC_NAME:
        case productValidationConstants.KEY_PRODUCT_EN_NAME:
        case productValidationConstants.KEY_PRODUCT_SC_NAME:
        case productValidationConstants.KEY_PRODUCT_START_DISPLAY_TIMESTAMP:
        case productValidationConstants.KEY_PRODUCT_END_DISPLAY_TIMESTAMP:
        case productValidationConstants.KEY_PRODUCT_DISPLAY_SEQUENCE:
        case productValidationConstants.KEY_PRODUCT_ORIGINAL_PRICE:
        case productValidationConstants.KEY_PRODUCT_SELLING_PRICE:
        case productValidationConstants.KEY_PRODUCT_PERSON_LIMIT:
        case productValidationConstants.KEY_PRODUCT_QUANTITY_PER_ORDER:
        case productValidationConstants.KEY_PRODUCT_DAILY_QUOTA:
        case productValidationConstants.KEY_PRODUCT_BUFFER_REDEEM_DAY:
        case productValidationConstants.KEY_PRODUCT_SELF_REDEEM:
        case productValidationConstants.KEY_PRODUCT_SELF_REFUND:
        case productValidationConstants.KEY_PRODUCT_SEARCHABLE:
        case productValidationConstants.KEY_PRODUCT_SHAREABLE:
        case productValidationConstants.KEY_PRODUCT_PACKAGE_CASH_COUPON:
        case productValidationConstants.KEY_PRODUCT_REQUIRE_FILL_IN_CONTACT_PERSON:
        case productValidationConstants.KEY_PRODUCT_REQUIRE_FILL_IN_MOBILE_NUMBER:
        case productValidationConstants.KEY_PRODUCT_REQUIRE_FILL_IN_EMAIL:
        case productValidationConstants.KEY_PRODUCT_SUB_PRODUCT_MAP_LIST:
        case productValidationConstants.KEY_PRODUCT_BLACKOUT_PERIOD_LIST:
        case productValidationConstants.KEY_PRODUCT_ATTRIBUTE_LIST:
        case productValidationConstants.KEY_PRODUCT_IMAGE_LIST:
          if (this.productErrorMap.has(uniqueCode) && this.productErrorMap.get(uniqueCode).has(key)) {
            return this.productErrorMap.get(uniqueCode).get(key);
          }
          return [];
        case productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_ERROR:
          if (this.productLotErrorMap.has(uniqueCode) && this.productLotErrorMap.get(uniqueCode).has(key)) {
            return this.productLotErrorMap.get(uniqueCode).get(key);
          }
          return [];
        case productValidationConstants.KEY_SUB_PRODUCT_VALI_CODE:
        case productValidationConstants.KEY_PRODUCT_SUB_PRODUCT_MAP_LIST_PRODUCT_CODE:
        case productValidationConstants.KEY_PRODUCT_SUB_PRODUCT_MAP_LIST_QUANTITY:
        case productValidationConstants.KEY_PRODUCT_IMAGE_VALI_CODE:
        case productValidationConstants.KEY_PRODUCT_IMAGE_TYPE:
        case productValidationConstants.KEY_PRODUCT_IMAGE_DISPLAY_SEQUENCE:
        case productValidationConstants.KEY_PRODUCT_BLACKOUT_PERIOD_LIST_BLACKOUT_PERIOD_VALI_CODE:
        case productValidationConstants.KEY_PRODUCT_BLACKOUT_PERIOD_LIST_REST_URL_ID:
        case productValidationConstants.KEY_PRODUCT_BLACKOUT_PERIOD_LIST_TYPE:
        case productValidationConstants.KEY_PRODUCT_BLACKOUT_PERIOD_LIST_START_TIMESTAMP:
        case productValidationConstants.KEY_PRODUCT_BLACKOUT_PERIOD_LIST_END_TIMESTAMP:
        case productValidationConstants.KEY_PRODUCT_ATTRIBUTE_VALI_CODE:
        case productValidationConstants.KEY_PRODUCT_ATTRIBUTE_KEY:
        case productValidationConstants.KEY_PRODUCT_ATTRIBUTE_TYPE:
        case productValidationConstants.KEY_ATTRIBUTE_NAME_TC:
        case productValidationConstants.KEY_ATTRIBUTE_NAME_EN:
        case productValidationConstants.KEY_ATTRIBUTE_NAME_SC:
        case productValidationConstants.KEY_ATTRIBUTE_DISPLAY:
        case productValidationConstants.KEY_ATTRIBUTE_DISPLAY_SEQUENCE:
        case productValidationConstants.KEY_ATTRIBUTE_CRITERIA:
        case productValidationConstants.KEY_ATTRIBUTE_CRITERIA_MAX:
        case productValidationConstants.KEY_ATTRIBUTE_CRITERIA_MIN:
        case productValidationConstants.KEY_ATTRIBUTE_DEFAULT_VALUE:
        case productValidationConstants.KEY_PRODUCT_OPTION_LIST:
          if (this.productErrorMap.has(uniqueCode) && this.productErrorMap.get(uniqueCode).has(subUniqueCode) && this.productErrorMap.get(uniqueCode).get(subUniqueCode).has(key)) {
            return this.productErrorMap.get(uniqueCode).get(subUniqueCode).get(key);
          }
          return [];
        case productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_LIST:
        case productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_LIST_LOT_INVENTORY_VALI_CODE:
        case productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_LIST_TYPE:
        case productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_LIST_MEZZOFY_COUPON_ID:
        case productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_LIST_MEZZOFY_COUPON_CODE:
        case productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_LIST_MEZZOFY_COUPON_QUANTITY_SYNC:
        case productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_LIST_COS_FILE_KEY:
        case productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_LIST_MASS_CODE:
        case productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_LIST_QUANTITY:
        case productValidationConstants.KEY_SUB_PRODUCT_LOT_INVENTORY_LIST:
        case productValidationConstants.KEY_SUB_PRODUCT_LOT_INVENTORY_LIST_LOT_INVENTORY_VALI_CODE:
          if (this.productLotErrorMap.has(uniqueCode) && this.productLotErrorMap.get(uniqueCode).has(subUniqueCode) && this.productLotErrorMap.get(uniqueCode).get(subUniqueCode).has(key)) {
            return this.productLotErrorMap.get(uniqueCode).get(subUniqueCode).get(key);
          }
          return [];
        case productValidationConstants.KEY_ATTRIBUTE_OPTION_VALI_CODE:
        case productValidationConstants.KEY_ATTRIBUTE_OPTION_CODE:
        case productValidationConstants.KEY_ATTRIBUTE_OPTION_TC_VALUE:
        case productValidationConstants.KEY_ATTRIBUTE_OPTION_EN_VALUE:
        case productValidationConstants.KEY_ATTRIBUTE_OPTION_SC_VALUE:
          if (this.productErrorMap.has(uniqueCode) && this.productErrorMap.get(uniqueCode).has(subUniqueCode) && this.productErrorMap.get(uniqueCode).get(subUniqueCode).has(subSubUniqueCode) && this.productErrorMap.get(uniqueCode).get(subUniqueCode).get(subSubUniqueCode).has(key)) {
            return this.productErrorMap.get(uniqueCode).get(subUniqueCode).get(subSubUniqueCode).get(key);
          }
          return [];
        case productValidationConstants.KEY_SUB_PRODUCT_LOT_INVENTORY_LIST_PRODUCT_CODE:
        case productValidationConstants.KEY_SUB_PRODUCT_LOT_INVENTORY_LIST_QUANTITY:
        case productValidationConstants.KEY_SUB_PRODUCT_LOT_INVENTORY_LIST_COS_FILE_KEY:
        case productValidationConstants.KEY_SUB_PRODUCT_LOT_INVENTORY_LIST_MASS_CODE:
          if (this.productLotErrorMap.has(uniqueCode) && this.productLotErrorMap.get(uniqueCode).has(subUniqueCode) && this.productLotErrorMap.get(uniqueCode).get(subUniqueCode).has(subSubUniqueCode) && this.productLotErrorMap.get(uniqueCode).get(subUniqueCode).get(subSubUniqueCode).has(key)) {
            return this.productLotErrorMap.get(uniqueCode).get(subUniqueCode).get(subSubUniqueCode).get(key);
          }
          return [];
        default:
          return [];
      }
    }
  }

  validateUserGroup(userGroupCode) {
    if (!userGroupCode) {
      this.setErrorMapMessages(productValidationConstants.KEY_USER_GROUP_CODE, ['Missing user group code']);
    }
  }

  validateCategory(category = {}) {
    const categorySet = new Map();
    if (!category.type) {
      categorySet.set(productValidationConstants.KEY_CATEGORY_TYPE, ['Missing category type']);
    }
    if (!category.codeDescription) {
      categorySet.set(productValidationConstants.KEY_CATEGORY_DESCRIPTION, ['Missing code description']);
    }
    if (!category.channel) {
      categorySet.set(productValidationConstants.KEY_CATEGORY_CHANNEL, ['Missing channel']);
    }
    if (!category.tcName) {
      categorySet.set(productValidationConstants.KEY_CATEGORY_NAME_TC, ['Missing tc name']);
    }
    if (!category.enName) {
      categorySet.set(productValidationConstants.KEY_CATEGORY_NAME_EN, ['Missing en name']);
    }
    if (!category.scName) {
      categorySet.set(productValidationConstants.KEY_CATEGORY_NAME_SC, ['Missing sc name']);
    }
    const startDisplayTimestamp = Number(category.startDisplayTimestamp);
    const endDisplayTimestamp = Number(category.endDisplayTimestamp);
    if (isNaN(startDisplayTimestamp) || startDisplayTimestamp === 0) {
      categorySet.set(productValidationConstants.KEY_CATEGORY_START_DISPLAY_TIMESTAMP, ['Invalid start redeem timestamp']);
    } else if (!isNaN(startDisplayTimestamp) && !isNaN(endDisplayTimestamp) && startDisplayTimestamp >= endDisplayTimestamp) {
      categorySet.set(productValidationConstants.KEY_CATEGORY_START_DISPLAY_TIMESTAMP, ['Start redeem timestamp is larger than end redeem timestamp']);
    }
    if (isNaN(endDisplayTimestamp) || endDisplayTimestamp === 0) {
      categorySet.set(productValidationConstants.KEY_CATEGORY_END_DISPLAY_TIMESTAMP, ['Invalid end redeem timestamp']);
    }
    if (isNaN(Number(category.displaySequence)) || Number(category.displaySequence) < 0) {
      categorySet.set(productValidationConstants.KEY_CATEGORY_DISPLAY_SEQUENCE, ['Invalid display sequence']);
    } else if (!Number.isInteger(category.displaySequence)) {
      categorySet.set(productValidationConstants.KEY_CATEGORY_DISPLAY_SEQUENCE, ['Invalid display sequence']);
    }

    // images
    const categoryImageErrorMessages = [];
    if (!category.imageList || category.imageList.length <= 0) {
      categoryImageErrorMessages.push('Missing images');
    } else {
      // if (!category.imageList.some((image) => {
      //   return image.type === constants.CAT_IMAGE_TYPE.CATEGORY_DETAIL;
      // })) {
      //   categoryImageErrorMessages.push('Missing ' + constants.CAT_IMAGE_TYPE.CATEGORY_DETAIL);
      // }
      // if ((category.channel === constants.CHANNEL.MOBILE || category.channel === constants.CHANNEL.GLOBAL) &&
      //   !category.imageList.some((image) => {
      //     return image.type === constants.CAT_IMAGE_TYPE.CATEGORY_ICON;
      //   })) {
      //   categoryImageErrorMessages.push('Missing ' + constants.CAT_IMAGE_TYPE.CATEGORY_ICON);
      // }
      // if ((category.channel === constants.CHANNEL.WECHAT_MP || category.channel === constants.CHANNEL.GLOBAL) &&
      //   !category.imageList.some((image) => {
      //     return image.type === constants.CAT_IMAGE_TYPE.CATEGORY_4BY3;
      //   })) {
      //   categoryImageErrorMessages.push('Missing ' + constants.CAT_IMAGE_TYPE.CATEGORY_4BY3);
      // }
      const imageSet = new Set();
      category.imageList.map((image) => {
        const categoryImageMap = new Map();
        if (!image.id || image.orignalCosFileUrl) {
          if (!image.cosFileKey) {
            this.setErrorMapMessages(productValidationConstants.KEY_CATEGORY_IMAGE_VALI_CODE, ['Invalid cosFileKey']);
          } else {
            if (imageSet.has(image.cosFileKey)) {
              this.setErrorMapMessages(productValidationConstants.KEY_CATEGORY_IMAGE_VALI_CODE, ['Duplicate cosFileKey']);
            } else {
              imageSet.add(image.cosFileKey);
            }
          }
        }
        if (!image.type) {
          categoryImageMap.set(productValidationConstants.KEY_CATEGORY_IMAGE_TYPE, ['Missing image type']);
        }
        // if (category.channel === constants.CHANNEL.MOBILE && image.type === constants.CAT_IMAGE_TYPE.CATEGORY_4BY3) {
        //   categoryImageMap.set(productValidationConstants.KEY_CATEGORY_IMAGE_TYPE, ['Invalid image type']);
        // }
        // if (category.channel === constants.CHANNEL.WECHAT_MP && image.type === constants.CAT_IMAGE_TYPE.CATEGORY_ICON) {
        //   categoryImageMap.set(productValidationConstants.KEY_CATEGORY_IMAGE_TYPE, ['Invalid image type']);
        // }
        if (isNaN(Number(image.displaySequence))) {
          categoryImageMap.set(productValidationConstants.KEY_CATEGORY_IMAGE_DISPLAY_SEQUENCE, ['Missing image display sequence']);
        } else {
          if (Number(image.displaySequence) < 0 || !Number.isInteger(Number(image.displaySequence))) {
            categoryImageMap.set(productValidationConstants.KEY_CATEGORY_IMAGE_DISPLAY_SEQUENCE, ['Invalid image display sequence']);
          }

        }
        if (categoryImageMap.size > 0) {
          this.setCatImageErrorMapMessages(image.cosFileKey ? image.cosFileKey : image.id, categoryImageMap);
        }
        return image;
      });
    }

    if (categoryImageErrorMessages.length > 0) {
      categorySet.set(productValidationConstants.KEY_CATEGORY_IMAGE_LIST, categoryImageErrorMessages, categoryImageErrorMessages.length <= 0);
    }

    //ProductMap
    if (category.id) {
      category.productMapList.map((productMap) => {
        const categoryproductMapMap = new Map();
        if (isNaN(Number(productMap.displaySequence))) {
          categoryproductMapMap.set(productValidationConstants.KEY_CATEGORY_PRODUCT_MAP_DISPLAY_SEQUENCE, ['Missing product map display sequence']);
        } else {
          if (Number(productMap.displaySequence) < 0 || !Number.isInteger(Number(productMap.displaySequence))) {
            categoryproductMapMap.set(productValidationConstants.KEY_CATEGORY_PRODUCT_MAP_DISPLAY_SEQUENCE, ['Invalid product map display sequence']);
          }
        }
        if (categoryproductMapMap.size > 0) {
          categorySet.set(productMap.productCode, categoryproductMapMap);
        }
        return productMap;
      });
    }
    if (categorySet.size > 0) {
      this.setCatErrorMapMessages("category", categorySet);
    }
  }

  validateRedeemGroupList(redeemGroupList = [], existingRedeemGroupList = []) {
    if ((!redeemGroupList || redeemGroupList.length <= 0) && (!existingRedeemGroupList || existingRedeemGroupList.length <= 0)) {
      this.setErrorMapMessages(productValidationConstants.KEY_REDEEM_GROUP_LIST, ['Missing redeem group']);
    } else {
      this.errorMap.delete(productValidationConstants.KEY_REDEEM_GROUP_LIST);
      const redeemGroupSet = new Set();
      redeemGroupList.map((redeemGroup) => {
        const messageMap = new Map();
        if (!redeemGroup.redeemGroupCode) {
          messageMap.set(productValidationConstants.KEY_REDEEM_GROUP_CODE, ['Invalid redeem group code']);
        } else {
          if (redeemGroupSet.has(redeemGroup.redeemGroupCode)) {
            messageMap.set(productValidationConstants.KEY_REDEEM_GROUP_CODE, ['Duplicate redeem group code']);
          } else {
            redeemGroupSet.add(redeemGroup.redeemGroupCode);
          }
        }
        if (!redeemGroup.codeDescription) {
          messageMap.set(productValidationConstants.KEY_REDEEM_GROUP_CODE_DESCRIPTION, ['Invalid redeem group description']);
        }
        if (!redeemGroup.type) {
          messageMap.set(productValidationConstants.KEY_REDEEM_GROUP_REDEEM_TYPE, ['Missing redeem type']);
        }
        if (redeemGroup.selectLocation === ''
          // || (!redeemGroup.selectLocation && (redeemGroup.selectDate || redeemGroup.selectTime))
        ) {
          messageMap.set(productValidationConstants.KEY_REDEEM_GROUP_SELECT_LOCATION, ['Require redeem group select location']);
        }
        if (redeemGroup.selectDate === '') {
          messageMap.set(productValidationConstants.KEY_REDEEM_GROUP_SELECT_DATE, ['Require redeem group select date']);
        }
        if (redeemGroup.selectTime === '') {
          messageMap.set(productValidationConstants.KEY_REDEEM_GROUP_SELECT_TIME, ['Require redeem group select time']);
        }

        if (!redeemGroup.id) {
          if (redeemGroup.type === constants.REDEEM_TYPE.PREORDER) {
            if (redeemGroup.selectLocation !== true || redeemGroup.selectDate !== true || redeemGroup.selectTime !== true) {
              messageMap.set(productValidationConstants.KEY_REDEEM_GROUP_SELECT_LOCATION, ['Redeem group select location should be true']);
              messageMap.set(productValidationConstants.KEY_REDEEM_GROUP_SELECT_DATE, ['Redeem group select date should be true']);
              messageMap.set(productValidationConstants.KEY_REDEEM_GROUP_SELECT_TIME, ['Redeem group select time should be true']);
            }
          } else {
            if (redeemGroup.selectLocation !== false || redeemGroup.selectDate !== false || redeemGroup.selectTime !== false) {
              messageMap.set(productValidationConstants.KEY_REDEEM_GROUP_SELECT_LOCATION, ['Redeem group select location should be false']);
              messageMap.set(productValidationConstants.KEY_REDEEM_GROUP_SELECT_DATE, ['Redeem group select date should be false']);
              messageMap.set(productValidationConstants.KEY_REDEEM_GROUP_SELECT_TIME, ['Redeem group select time should be false']);
            }
          }
        }

        const startRedeemTimestamp = Number(redeemGroup.startRedeemTimestamp);
        const endRedeemTimestamp = Number(redeemGroup.endRedeemTimestamp);
        if (isNaN(startRedeemTimestamp) || startRedeemTimestamp === 0) {
          messageMap.set(productValidationConstants.KEY_REDEEM_GROUP_START_REDEEM_TIMESTAMP, ['Invalid start redeem timestamp']);
        } else if (!isNaN(startRedeemTimestamp) && !isNaN(endRedeemTimestamp) && startRedeemTimestamp >= endRedeemTimestamp) {
          messageMap.set(productValidationConstants.KEY_REDEEM_GROUP_START_REDEEM_TIMESTAMP, ['Start redeem timestamp is larger than end redeem timestamp']);
        }
        if (isNaN(endRedeemTimestamp) || endRedeemTimestamp === 0) {
          messageMap.set(productValidationConstants.KEY_REDEEM_GROUP_END_REDEEM_TIMESTAMP, ['Invalid end redeem timestamp']);
        }
        if (isNaN(redeemGroup.startAfterDay) || redeemGroup.startAfterDay < 0) {
          messageMap.set(productValidationConstants.KEY_REDEEM_GROUP_START_AFTER_DAY, ['Invalid start after day']);
        }
        if (isNaN(redeemGroup.endAfterDay) || redeemGroup.endAfterDay < 0) {
          messageMap.set(productValidationConstants.KEY_REDEEM_GROUP_END_AFTER_DAY, ['Invalid end after day']);
        }

        // if (!redeemGroup.redeemLocationList || redeemGroup.redeemLocationList.length <= 0) {
        //   messageMap.set(productValidationConstants.KEY_REDEEM_GROUP_REDEEM_LOCATION_LIST, ['Require redeem location']);
        // }
        if (redeemGroup.redeemLocationList) {
          const redeemGroupLocationRestUrlIdSet = new Set();
          redeemGroup.redeemLocationList.map((redeemLocation) => {
            const redeemLocationMessageMap = new Map();
            if (!redeemLocation.restUrlId) {
              redeemLocationMessageMap.set(productValidationConstants.KEY_REDEEM_GROUP_REDEEM_LOCATION_REST_URL_ID, ['Invalid redeem location restUrlId']);
            } else {
              if (redeemGroupLocationRestUrlIdSet.has(redeemLocation.restUrlId)) {
                redeemLocationMessageMap.set(productValidationConstants.KEY_REDEEM_GROUP_REDEEM_LOCATION_REST_URL_ID, ['Duplicate redeem location restUrlId']);
              } else {
                redeemGroupLocationRestUrlIdSet.add(redeemLocation.restUrlId);
              }
            }
            if (redeemGroup.selectTime) {
              if (!redeemLocation.timeSession.startTime) {
                redeemLocationMessageMap.set(productValidationConstants.KEY_REDEEM_GROUP_REDEEM_LOCATION_START_TIME, ['Require redeem location start time']);
              } else if (redeemLocation.timeSession.startTime && redeemLocation.timeSession.startTime.match(/\d{2}:\d{2}:\d{2}:/)) {
                redeemLocationMessageMap.set(productValidationConstants.KEY_REDEEM_GROUP_REDEEM_LOCATION_START_TIME, ['Invalid redeem location start time']);
              }
              if (!redeemLocation.timeSession.endTime) {
                redeemLocationMessageMap.set(productValidationConstants.KEY_REDEEM_GROUP_REDEEM_LOCATION_END_TIME, ['Invalid redeem location end time']);
              } else if (redeemLocation.timeSession.endTime && redeemLocation.timeSession.endTime.match(/\d{2}:\d{2}:\d{2}:/)) {
                redeemLocationMessageMap.set(productValidationConstants.KEY_REDEEM_GROUP_REDEEM_LOCATION_END_TIME, ['Invalid redeem location end time']);
              }
              let interval = Number(redeemLocation.timeSession.interval);
              if (isNaN(interval) || interval > 1440 || interval < 5) {
                redeemLocationMessageMap.set(productValidationConstants.KEY_REDEEM_GROUP_REDEEM_LOCATION_INTERVAL, ['Invalid redeem location interval (5 - 1440)']);
              }
            }
            if (redeemLocationMessageMap.size > 0) {
              messageMap.set(redeemLocation.restUrlId, redeemLocationMessageMap);
            }
            return redeemLocation;
          });
        }
        if (messageMap.size > 0) {
          messageMap.set(productValidationConstants.KEY_REDEEM_GROUP_ERROR, ["Has error(s)"]);
          this.setRedeemGroupErrorMapMessages(redeemGroup.redeemGroupCode, messageMap);
        }
        return redeemGroup;
      });
    }
  }

  validateProduct(product, allRedeemGroupList, categoryType) {
    const productSet = new Set();
    const messageMap = new Map();
    let redeemGroup = {};
    // if (!product.subProductMapValiCode) {
    //   messageMap.set(productValidationConstants.KEY_PRODUCT_CODE, ['Invalid product code']);
    // } else {
    //   if (productSet.has(product.subProductMapValiCode)) {
    //     messageMap.set(productValidationConstants.KEY_PRODUCT_CODE, ['Duplicate product code']);
    //   } else {
    //     productSet.add(product.subProductMapValiCode);
    //   }
    // }
    if (!product.productCode) {
      messageMap.set(productValidationConstants.KEY_SUB_PRODUCT_CODE, ['Invalid product code']);
    } else {
      if (productSet.has(product.productCode)) {
        messageMap.set(productValidationConstants.KEY_SUB_PRODUCT_CODE, ['Duplicate product code']);
      }
    }
    if (!product.type) {
      messageMap.set(productValidationConstants.KEY_PRODUCT_TYPE, ['Invalid product type']);
    }
    if (!product.channel) {
      messageMap.set(productValidationConstants.KEY_PRODUCT_CHANNEL, ['Invalid product channel']);
    }
    if (!product.redeemGroupCode) {
      messageMap.set(productValidationConstants.KEY_PRODUCT_REDEEM_GROUP_CODE, ['Missing redeem group code']);
    } else {
      if (!product.id) {
        redeemGroup = allRedeemGroupList.find((allRedeemGroup) => allRedeemGroup.redeemGroupCode === product.redeemGroupCode);
        if (redeemGroup.type !== constants.REDEEM_TYPE.PREORDER && product.type === constants.PRODUCT_TYPE.PREORDER_ITEM) {
          messageMap.set(productValidationConstants.KEY_PRODUCT_REDEEM_GROUP_CODE, ['Redeem group should be PREORDER']);
        }
      }
    }
    // if (!product.settlementConfig) {
    //   messageMap.set(productValidationConstants.KEY_PRODUCT_SETTLEMENT_CONFIG, ['Missing settlement config id']);
    // }
    if (!product.systemGroupId) {
      messageMap.set(productValidationConstants.KEY_PRODUCT_SYSTEM_GROUP, ['Missing settlement system group id']);
    }
    if (!product.tcName) {
      messageMap.set(productValidationConstants.KEY_PRODUCT_TC_NAME, ['Missing tc name']);
    }
    if (!product.enName) {
      messageMap.set(productValidationConstants.KEY_PRODUCT_EN_NAME, ['Missing en name']);
    }
    if (!product.scName) {
      messageMap.set(productValidationConstants.KEY_PRODUCT_SC_NAME, ['Missing sc name']);
    }
    const startDisplayTimestamp = Number(product.startDisplayTimestamp);
    const endDisplayTimestamp = Number(product.endDisplayTimestamp);
    if (isNaN(startDisplayTimestamp) || startDisplayTimestamp === 0) {
      messageMap.set(productValidationConstants.KEY_PRODUCT_START_DISPLAY_TIMESTAMP, ['Invalid start display redeem timestamp']);
    } else if (!isNaN(startDisplayTimestamp) && !isNaN(endDisplayTimestamp) && startDisplayTimestamp >= endDisplayTimestamp) {
      messageMap.set(productValidationConstants.KEY_PRODUCT_START_DISPLAY_TIMESTAMP, ['Start display redeem timestamp is larger than end display redeem timestamp']);
    }
    if (isNaN(endDisplayTimestamp) || endDisplayTimestamp === 0) {
      messageMap.set(productValidationConstants.KEY_PRODUCT_END_DISPLAY_TIMESTAMP, ['Invalid end display redeem timestamp']);
    }

    if (!product.id && redeemGroup && redeemGroup.type === constants.REDEEM_TYPE.FIXED_START_END) {
      if (redeemGroup.endRedeemTimestamp < startDisplayTimestamp) {
        messageMap.set(productValidationConstants.KEY_PRODUCT_START_DISPLAY_TIMESTAMP, ['End redeem timestamp(in redeem group) should be larger than start display redeem timestamp']);
      }
      if (redeemGroup && redeemGroup.endRedeemTimestamp < endDisplayTimestamp) {
        messageMap.set(productValidationConstants.KEY_PRODUCT_END_DISPLAY_TIMESTAMP, ['End redeem timestamp(in redeem group) should be larger than or equal to end display redeem timestamp']);
      }
    }

    if (!product.id && redeemGroup && redeemGroup.type === constants.REDEEM_TYPE.PREORDER) {
      let start = 0;
      let end = 0;
      if (startDisplayTimestamp + (1000 * 60 * 60 * 24 * redeemGroup.startAfterDay) > redeemGroup.startRedeemTimestamp) {
        start = startDisplayTimestamp + (1000 * 60 * 60 * 24 * redeemGroup.startAfterDay);
      } else {
        start = redeemGroup.startRedeemTimestamp;
      }

      if (endDisplayTimestamp + (1000 * 60 * 60 * 24 * redeemGroup.startAfterDay) + (1000 * 60 * 60 * 24 * redeemGroup.endAfterDay) < redeemGroup.endRedeemTimestamp) {
        end = endDisplayTimestamp + (1000 * 60 * 60 * 24 * redeemGroup.startAfterDay) + (1000 * 60 * 60 * 24 * redeemGroup.endAfterDay);
      } else {
        end = redeemGroup.endRedeemTimestamp;
        if (redeemGroup.endRedeemTimestamp < startDisplayTimestamp) {
          messageMap.set(productValidationConstants.KEY_PRODUCT_START_DISPLAY_TIMESTAMP, ['End redeem timestamp(in redeem group) should be larger than start display timestamp']);
        }
        if (redeemGroup.endRedeemTimestamp < endDisplayTimestamp) {
          messageMap.set(productValidationConstants.KEY_PRODUCT_END_DISPLAY_TIMESTAMP, ['End redeem timestamp(in redeem group) should be larger than or equal to end display timestamp']);
        }
      }

      if (start && end && (start > end)) {
        messageMap.set(productValidationConstants.KEY_PRODUCT_START_DISPLAY_TIMESTAMP, ['Invalid Redeem timestamp or Display timstamp']);
        messageMap.set(productValidationConstants.KEY_PRODUCT_END_DISPLAY_TIMESTAMP, ['Invalid Redeem timestamp or Display timstamp']);
      }

    }

    if (isNaN(Number(product.displaySequence)) || Number(product.displaySequence) < 0 || !Number.isInteger(product.displaySequence)) {
      messageMap.set(productValidationConstants.KEY_PRODUCT_DISPLAY_SEQUENCE, ['Invalid display sequence']);
    }
    if (isNaN(Number(product.originalPrice)) || Number(product.originalPrice) < 0) {
      messageMap.set(productValidationConstants.KEY_PRODUCT_ORIGINAL_PRICE, ['Invalid product original price']);
    }
    // if (product.type !== constants.PRODUCT_TYPE.PACKAGE_SUB_ITEM && Number(product.originalPrice) === 0) {
    //   messageMap.set(productValidationConstants.KEY_PRODUCT_ORIGINAL_PRICE, ['Product original price should not be 0']);
    // }
    if (isNaN(Number(product.sellingPrice)) || Number(product.sellingPrice) < 0) {
      messageMap.set(productValidationConstants.KEY_PRODUCT_SELLING_PRICE, ['Invalid product selling price']);
    }
    
    // if (product.type !== constants.PRODUCT_TYPE.PACKAGE_SUB_ITEM && Number(product.originalPrice) === 0) {
    //   messageMap.set(productValidationConstants.KEY_PRODUCT_SELLING_PRICE, ['Product selling price should not be 0']);
    // }
    if (Number(product.originalPrice) < Number(product.sellingPrice)) {
      messageMap.set(productValidationConstants.KEY_PRODUCT_SELLING_PRICE, ['Product selling price should not be larger than product original price']);
    }

    if (categoryType === constants.CHOICES.PRODUCT_CREATE.SELECT_CATEGORY_TYPE.FREE_ECOUPON&&Number(product.sellingPrice)!==0) {
      messageMap.set(productValidationConstants.KEY_PRODUCT_SELLING_PRICE, ['selling price should be 0']);
    }

    if (categoryType === constants.CHOICES.PRODUCT_CREATE.SELECT_CATEGORY_TYPE.FREE_ECOUPON&&Number(product.originalPrice)!==0) {
      messageMap.set(productValidationConstants.KEY_PRODUCT_SELLING_PRICE, ['original price should be 0']);
    }


    if (isNaN(Number(product.personLimit)) || Number(product.personLimit) <= 0 || !Number.isInteger(product.personLimit)) {
      messageMap.set(productValidationConstants.KEY_PRODUCT_PERSON_LIMIT, ['Invalid product person limit']);
    }
    if (isNaN(Number(product.quantityPerOrder)) || Number(product.quantityPerOrder) <= 0 || !Number.isInteger(product.quantityPerOrder)) {
      messageMap.set(productValidationConstants.KEY_PRODUCT_QUANTITY_PER_ORDER, ['Invalid product quantity per order']);
    }
    if (isNaN(Number(product.dailyQuota)) || Number(product.dailyQuota) < -1 || Number(product.dailyQuota) === 0 || !Number.isInteger(product.dailyQuota)) {
      messageMap.set(productValidationConstants.KEY_PRODUCT_DAILY_QUOTA, ['Invalid product daily quota']);
    }
    if (isNaN(Number(product.bufferRedeemDay)) || Number(product.bufferRedeemDay) < 0 || !Number.isInteger(product.bufferRedeemDay)) {
      messageMap.set(productValidationConstants.KEY_PRODUCT_BUFFER_REDEEM_DAY, ['Invalid product buffer redeem day']);
    }
    if (typeof product.selfRedeem != constants.TYPE.BOOLEAN) {
      messageMap.set(productValidationConstants.KEY_PRODUCT_SELF_REDEEM, ['Missing self redeem']);
    }
    if (typeof product.selfRefund != constants.TYPE.BOOLEAN) {
      messageMap.set(productValidationConstants.KEY_PRODUCT_SELF_REFUND, ['Missing self refund']);
    }
    if (product.searchable && product.type === constants.PRODUCT_TYPE.PACKAGE_SUB_ITEM && !product.searchable) { //to-do
      messageMap.set(productValidationConstants.KEY_PRODUCT_SEARCHABLE, ['Invalid searchable']);
    } else if (typeof product.searchable != constants.TYPE.BOOLEAN) {
      messageMap.set(productValidationConstants.KEY_PRODUCT_SEARCHABLE, ['Missing searchable']);
    }
    if (product.shareable && product.type === constants.PRODUCT_TYPE.PACKAGE_ITEM && !product.shareable) { //to-do
      messageMap.set(productValidationConstants.KEY_PRODUCT_SHAREABLE, ['Invalid shareable']);
    } else if (typeof product.shareable != constants.TYPE.BOOLEAN) {
      messageMap.set(productValidationConstants.KEY_PRODUCT_SHAREABLE, ['Missing shareable']);
    }
    if (product.packageCashCoupon && product.type !== constants.PRODUCT_TYPE.PACKAGE_ITEM && !product.packageCashCoupon) { //to-do
      messageMap.set(productValidationConstants.KEY_PRODUCT_PACKAGE_CASH_COUPON, ['Invalid package cash coupon']);
    } else if (typeof product.packageCashCoupon != constants.TYPE.BOOLEAN) {
      messageMap.set(productValidationConstants.KEY_PRODUCT_PACKAGE_CASH_COUPON, ['Missing package cash coupon']);
    }
    if (typeof product.requireFillInContactPerson != constants.TYPE.BOOLEAN) {
      messageMap.set(productValidationConstants.KEY_PRODUCT_REQUIRE_FILL_IN_CONTACT_PERSON, ['Missing require fill in contact person']);
    }
    if (typeof product.requireFillInMobileNumber != constants.TYPE.BOOLEAN) {
      messageMap.set(productValidationConstants.KEY_PRODUCT_REQUIRE_FILL_IN_MOBILE_NUMBER, ['Missing require fill in mobile number']);
    }
    if (typeof product.requireFillInEmail != constants.TYPE.BOOLEAN) {
      messageMap.set(productValidationConstants.KEY_PRODUCT_REQUIRE_FILL_IN_EMAIL, ['Missing require fill in email']);
    }

    if(product.type !== constants.PRODUCT_TYPE.FREE_ECOUPON_ITEM && categoryType === constants.CHOICES.PRODUCT_CREATE.SELECT_CATEGORY_TYPE.FREE_ECOUPON){
      messageMap.set(productValidationConstants.KEY_PRODUCT_TYPE, ['Only Free Ecoupon Item is allowed for Free Ecoupon Category']);
    }

    //SubProductMap
    if (product.type === constants.PRODUCT_TYPE.PACKAGE_ITEM) {
      if (product.subProductMapList.length <= 0) {
        messageMap.set(productValidationConstants.KEY_PRODUCT_SUB_PRODUCT_MAP_LIST, ['Missing sub product map list']);
      }
      if (product.subProductMapList) {
        const subProductSet = new Set();
        // const subProductCodeSet = new Set();
        product.subProductMapList.map((subProduct) => {
          const subProductMap = new Map();
          if (!subProduct.id) {
            if (!subProduct.subProductMapValiCode) {
              messageMap.set(productValidationConstants.KEY_SUB_PRODUCT_VALI_CODE, ['Invalid product code']);
            } else {
              if (subProductSet.has(subProduct.subProductMapValiCode)) {
                messageMap.set(productValidationConstants.KEY_SUB_PRODUCT_VALI_CODE, ['Duplicate product code']);
              } else {
                subProductSet.add(subProduct.subProductMapValiCode);
              }
            }
          }
          if (!subProduct.productCode) {
            subProductMap.set(productValidationConstants.KEY_PRODUCT_SUB_PRODUCT_MAP_LIST_PRODUCT_CODE, ['Invalid product code']);
          }

          // else {
          //   if (subProductCodeSet.has(subProduct.productCode)) {
          //     subProductMap.set(productValidationConstants.KEY_PRODUCT_SUB_PRODUCT_MAP_LIST_PRODUCT_CODE, ['Duplicate product code']);
          //   } else {
          //     subProductCodeSet.add(subProduct.productCode);
          //   }
          // }
          if (isNaN(Number(subProduct.quantity))) {
            subProductMap.set(productValidationConstants.KEY_PRODUCT_SUB_PRODUCT_MAP_LIST_QUANTITY, ['Require sub product quantity']);
          } else if (Number(subProduct.quantity) < 1 || !Number.isInteger(Number(subProduct.quantity))) {
            subProductMap.set(productValidationConstants.KEY_PRODUCT_SUB_PRODUCT_MAP_LIST_QUANTITY, ['Invalid sub product quantity']);
          }
          if (subProductMap.size > 0) {
            messageMap.set(subProduct.subProductMapValiCode, subProductMap);
          }
          return subProduct;
        });
      }
    }


    // images
    const productImageErrorMessages = [];
    if (!product.imageList || product.imageList.length <= 0) {
      productImageErrorMessages.push('Missing images');
    } else {
      // if (!product.imageList.some((image) => {
      //   return image.type === constants.PRODUCT_IMAGE_TYPE.ITEM_DETAIL;
      // })) {
      //   productImageErrorMessages.push('Missing ' + constants.PRODUCT_IMAGE_TYPE.ITEM_DETAIL);
      // }
      // if ((product.channel === constants.CHANNEL.MOBILE || product.channel === constants.CHANNEL.GLOBAL) &&
      //   !product.imageList.some((image) => {
      //     return image.type === constants.PRODUCT_IMAGE_TYPE.ITEM_ICON;
      //   })) {
      //   productImageErrorMessages.push('Missing ' + constants.PRODUCT_IMAGE_TYPE.ITEM_ICON);
      // }
      // if ((product.channel === constants.CHANNEL.WECHAT_MP || product.channel === constants.CHANNEL.GLOBAL) &&
      //   !product.imageList.some((image) => {
      //     return image.type === constants.PRODUCT_IMAGE_TYPE.ITEM_4BY3;
      //   })) {
      //   productImageErrorMessages.push('Missing ' + constants.PRODUCT_IMAGE_TYPE.ITEM_4BY3);
      // }
      const imageSet = new Set();
      product.imageList.map((image) => {
        const productImageMap = new Map();
        if (!image.id) {
          if (image.fileType !== constants.LOT_INVENTORY_TYPE.MEZZOFY) {
            if (!image.cosFileKey) {
              this.setErrorMapMessages(productValidationConstants.KEY_PRODUCT_IMAGE_VALI_CODE, ['Invalid cosFileKey']);
            } else {
              if (imageSet.has(image.cosFileKey)) {
                this.setErrorMapMessages(productValidationConstants.KEY_PRODUCT_IMAGE_VALI_CODE, ['Duplicate cosFileKey']);
              } else {
                imageSet.add(image.cosFileKey);
              }
            }
          }
        }
        if (!image.type) {
          productImageMap.set(productValidationConstants.KEY_PRODUCT_IMAGE_TYPE, ['Missing image type']);
        }
        // if ((product.channel === constants.CHANNEL.MOBILE) && image.type === constants.PRODUCT_IMAGE_TYPE.ITEM_4BY3) {
        //   productImageMap.set(productValidationConstants.KEY_PRODUCT_IMAGE_TYPE, ['Invalid image type']);
        // }
        // if ((product.channel === constants.CHANNEL.WECHAT_MP) && image.type === constants.PRODUCT_IMAGE_TYPE.ITEM_ICON) {
        //   productImageMap.set(productValidationConstants.KEY_PRODUCT_IMAGE_TYPE, ['Invalid image type']);
        // }
        if (isNaN(Number(image.displaySequence))) {
          productImageMap.set(productValidationConstants.KEY_PRODUCT_IMAGE_DISPLAY_SEQUENCE, ['Missing image display sequence']);
        } else {
          if (Number(image.displaySequence) < 0 || !Number.isInteger(Number(image.displaySequence))) {
            productImageMap.set(productValidationConstants.KEY_PRODUCT_IMAGE_DISPLAY_SEQUENCE, ['Invalid image display sequence']);
          }
        }
        if (productImageMap.size > 0) {
          messageMap.set(image.id ? image.id : image.cosFileKey, productImageMap);
        }
        return image;
      });
    }
    if (productImageErrorMessages.length > 0) {
      messageMap.set(productValidationConstants.KEY_PRODUCT_IMAGE_LIST, productImageErrorMessages);
    }

    //BlackoutPeriod
    if (product.blackoutPeriodList && product.blackoutPeriodList.length > 0) {
      const productBlackoutPeriodListSet = new Set();
      product.blackoutPeriodList.map((blackoutPeriod) => {
        const blackoutPeriodMap = new Map();
        if (!blackoutPeriod.id) {
          if (!blackoutPeriod.blackoutPeriodValiCode) {
            messageMap.set(productValidationConstants.KEY_PRODUCT_BLACKOUT_PERIOD_LIST_BLACKOUT_PERIOD_VALI_CODE, ['Invalid blackout period vali code']);
          } else {
            if (productBlackoutPeriodListSet.has(blackoutPeriod.blackoutPeriodValiCode)) {
              messageMap.set(productValidationConstants.KEY_PRODUCT_BLACKOUT_PERIOD_LIST_BLACKOUT_PERIOD_VALI_CODE, ['Duplicate blackout period vali code']);
            } else {
              productBlackoutPeriodListSet.add(blackoutPeriod.blackoutPeriodValiCode);
            }
          }
        }
        if (!blackoutPeriod.refContent) {
          blackoutPeriodMap.set(productValidationConstants.KEY_PRODUCT_BLACKOUT_PERIOD_LIST_REST_URL_ID, ['Missing restUrlId']);
        }
        if (!blackoutPeriod.type) {
          blackoutPeriodMap.set(productValidationConstants.KEY_PRODUCT_BLACKOUT_PERIOD_LIST_TYPE, ['Missing type']);
        }
        const blackoutPeriodStartTimestampErrorMessages = [];
        const blackoutPeriodStartTimestamp = Number(blackoutPeriod.startTimestamp);
        const blackoutPeriodEndTimestamp = Number(blackoutPeriod.endTimestamp);
        if (isNaN(blackoutPeriodStartTimestamp) || blackoutPeriodStartTimestamp === 0) {
          blackoutPeriodStartTimestampErrorMessages.push('Invalid blackout period start timestamp');
        }
        if (isNaN(blackoutPeriodEndTimestamp) || blackoutPeriodEndTimestamp === 0) {
          blackoutPeriodMap.set(productValidationConstants.KEY_PRODUCT_BLACKOUT_PERIOD_LIST_END_TIMESTAMP, ['Invalid blackout period end timestamp']);
        }
        if (!isNaN(blackoutPeriodStartTimestamp) && !isNaN(blackoutPeriodEndTimestamp) && blackoutPeriodStartTimestamp > blackoutPeriodEndTimestamp) {
          blackoutPeriodStartTimestampErrorMessages.push('Blackout period start timestamp is larger than blackout period end timestamp');
        }
        if (blackoutPeriodStartTimestampErrorMessages.length > 0) {
          blackoutPeriodMap.set(productValidationConstants.KEY_PRODUCT_BLACKOUT_PERIOD_LIST_START_TIMESTAMP, blackoutPeriodStartTimestampErrorMessages);
        }
        if (blackoutPeriodMap.size > 0) {
          messageMap.set(blackoutPeriod.blackoutPeriodValiCode, blackoutPeriodMap);
        }
        return blackoutPeriod;
      });
    }

    //AttributeList
    if (product.type === constants.PRODUCT_TYPE.PREORDER_ITEM) {
      if (redeemGroup.type === constants.REDEEM_TYPE.FIXED_START_END) {
        messageMap.set(productValidationConstants.KEY_PRODUCT_REDEEM_GROUP_CODE, ['Invalid redeem group code']);
      }
      if (product.attributeList) {
        const productAttributeListSet = new Set();
        const productAttributeKeySet = new Set();
        product.attributeList.map((attribute) => {
          const attributeMap = new Map();
          if (!attribute.id) {
            if (!attribute.attributeValiCode) {
              messageMap.set(productValidationConstants.KEY_PRODUCT_ATTRIBUTE_VALI_CODE, ['Missing attribute key']);
            } else {
              if (productAttributeListSet.has(attribute.attributeValiCode)) {
                attributeMap.set(productValidationConstants.KEY_PRODUCT_ATTRIBUTE_VALI_CODE, ['Duplicate attribute key']);
              } else {
                productAttributeListSet.add(attribute.attributeValiCode);
              }
            }
          }
          if (!attribute.key) {
            attributeMap.set(productValidationConstants.KEY_PRODUCT_ATTRIBUTE_KEY, ['Missing attribute key']);
          } else {
            if (productAttributeKeySet.has(attribute.key)) {
              attributeMap.set(productValidationConstants.KEY_PRODUCT_ATTRIBUTE_KEY, ['Duplicate attribute key']);
            } else {
              productAttributeKeySet.add(attribute.key);
            }
          }
          if (!attribute.type) {
            attributeMap.set(productValidationConstants.KEY_PRODUCT_ATTRIBUTE_TYPE, ['Missing attribute type']);
          }
          if (!attribute.tcName) {
            attributeMap.set(productValidationConstants.KEY_ATTRIBUTE_NAME_TC, ['Missing attribute tc name']);
          }
          if (!attribute.enName) {
            attributeMap.set(productValidationConstants.KEY_ATTRIBUTE_NAME_EN, ['Missing attribute en name']);
          }
          if (!attribute.scName) {
            attributeMap.set(productValidationConstants.KEY_ATTRIBUTE_NAME_SC, ['Missing attribute sc name']);
          }
          if (attribute.display === null || attribute.display === undefined) {
            attributeMap.set(productValidationConstants.KEY_ATTRIBUTE_DISPLAY, ['Missing attribute display config']);
          }
          if (isNaN(Number(attribute.displaySequence))) {
            attributeMap.set(productValidationConstants.KEY_ATTRIBUTE_DISPLAY_SEQUENCE, ['Missing attribute display sequence']);
          } else if (Number(attribute.displaySequence) < 0 || !Number.isInteger(attribute.displaySequence)) {
            attributeMap.set(productValidationConstants.KEY_ATTRIBUTE_DISPLAY_SEQUENCE, ['Invalid attribute display sequence']);
          }
          if (attribute.display) {
            if (isNaN(Number(attribute.criteria.max))) {
              attributeMap.set(productValidationConstants.KEY_ATTRIBUTE_CRITERIA_MAX, ['Missing attribute criteria max']);
            } else if (!Number.isInteger(attribute.criteria.max)) {
              attributeMap.set(productValidationConstants.KEY_ATTRIBUTE_CRITERIA_MAX, ['Invalid attribute criteria max']);
            } else {
              if (attribute.type === constants.ATTRIBUTE_TYPE.SELECT && attribute.criteria.max > attribute.optionList.length) {
                attributeMap.set(productValidationConstants.KEY_ATTRIBUTE_CRITERIA_MAX, ['Max value is larger than option amount']);
              }
            }
            if (isNaN(Number(attribute.criteria.min))) {
              attributeMap.set(productValidationConstants.KEY_ATTRIBUTE_CRITERIA_MIN, ['Missing attribute criteria min']);
            } else if (!Number.isInteger(attribute.criteria.min)) {
              attributeMap.set(productValidationConstants.KEY_ATTRIBUTE_CRITERIA_MIN, ['Invalid attribute criteria min']);
            } else {
              if (attribute.type === constants.ATTRIBUTE_TYPE.SELECT && attribute.criteria.min >= attribute.optionList.length) {
                attributeMap.set(productValidationConstants.KEY_ATTRIBUTE_CRITERIA_MIN, ['Min value is larger than or equal to option amount']);
              }
            }
            if (attribute.criteria.max && attribute.criteria.min && attribute.criteria.min > attribute.criteria.max) {
              attributeMap.set(productValidationConstants.KEY_ATTRIBUTE_CRITERIA, ['Min value is larger than max value']);
            }
            if (attribute.type === constants.ATTRIBUTE_TYPE.SELECT) {
              //Option List 
              if (attribute.optionList.length <= 0) {
                attributeMap.set(productValidationConstants.KEY_PRODUCT_OPTION_LIST, ['Missing option list']);
              }
              if (attribute.optionList) {
                const productOptionListSet = new Set();
                const productOptionCodeSet = new Set();
                attribute.optionList.map((option) => {
                  const optionMap = new Map();
                  if (!option.optionValiCode) {
                    optionMap.set(productValidationConstants.KEY_ATTRIBUTE_OPTION_VALI_CODE, ['Invalid option code']);
                  } else {
                    if (productOptionListSet.has(option.optionValiCode)) {
                      optionMap.set(productValidationConstants.KEY_ATTRIBUTE_OPTION_VALI_CODE, ['Duplicate option code']);
                    } else {
                      productOptionListSet.add(option.optionValiCode);
                    }
                  }
                  if (!option.code) {
                    optionMap.set(productValidationConstants.KEY_ATTRIBUTE_OPTION_CODE, ['Invalid option code']);
                  } else {
                    if (productOptionCodeSet.has(option.code)) {
                      optionMap.set(productValidationConstants.KEY_ATTRIBUTE_OPTION_CODE, ['Duplicate option code']);
                    } else {
                      productOptionCodeSet.add(option.code);
                    }
                  }
                  if (!option.tcValue) {
                    optionMap.set(productValidationConstants.KEY_ATTRIBUTE_OPTION_TC_VALUE, ['Missing option tcValue']);
                  }
                  if (!option.enValue) {
                    optionMap.set(productValidationConstants.KEY_ATTRIBUTE_OPTION_EN_VALUE, ['Missing option enValue']);
                  }
                  if (!option.scValue) {
                    optionMap.set(productValidationConstants.KEY_ATTRIBUTE_OPTION_SC_VALUE, ['Missing option scValue']);
                  }
                  if (optionMap.size > 0) {
                    attributeMap.set(option.optionValiCode, optionMap);
                  }
                  return option;
                });
              }

            }

          } else if (!attribute.display) {
            if (!attribute.defaultValue) {
              attributeMap.set(productValidationConstants.KEY_ATTRIBUTE_DEFAULT_VALUE, ['Missing attribute default value']);
            }
          }

          if (attributeMap.size > 0) {
            messageMap.set(attribute.attributeValiCode, attributeMap);
          }
          return attribute;
        });
      }
    }

    if (messageMap.size > 0) {
      messageMap.set(productValidationConstants.KEY_PRODUCT_ERROR, ["Has error(s)"]);
      this.setProductErrorMapMessages(product.productCode, messageMap);
    }
    return product;
  }

  validateProductLot(product) {
    const messageMap = new Map();
    if (product.type !== constants.PRODUCT_TYPE.PACKAGE_SUB_ITEM) {
      //lotInventory
      if (product.lotInventoryList.length <= 0) {
        messageMap.set(productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_LIST, ['Missing lot inventory list']);
      }
      if (product.lotInventoryList) {
        const productLotInventoryListSet = new Set();
        product.lotInventoryList.map((lotInventory) => {
          const lotInventoryMap = new Map();
          if (!lotInventory.id) {
            if (!lotInventory.lotInventoryValiCode) {
              messageMap.set(productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_LIST_LOT_INVENTORY_VALI_CODE, ['Invalid log inventory vali code']);
            } else {
              if (productLotInventoryListSet.has(lotInventory.lotInventoryValiCode)) {
                messageMap.set(productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_LIST_LOT_INVENTORY_VALI_CODE, ['Duplicate log inventory vali code']);
              } else {
                productLotInventoryListSet.add(lotInventory.lotInventoryValiCode);
              }
            }
            if (!lotInventory.type) {
              lotInventoryMap.set(productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_LIST_TYPE, ['Missing type']);
            } else {
              if (product.type === constants.PRODUCT_TYPE.ECOUPON_ITEM && lotInventory.type === constants.LOT_INVENTORY_TYPE.AUTO_GENERATE_MEZZOFY) {
                if (!lotInventory.mezzofyCouponID) {
                  lotInventoryMap.set(productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_LIST_MEZZOFY_COUPON_ID, ['Missing mezzofy coupon id']);
                }
                if (!lotInventory.mezzofyCouponCode) {
                  lotInventoryMap.set(productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_LIST_MEZZOFY_COUPON_CODE, ['Missing mezzofy coupon code']);
                }
                if (!lotInventory.mezzofyCouponQuantitySync) {
                  lotInventoryMap.set(productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_LIST_MEZZOFY_COUPON_QUANTITY_SYNC, ['Missing mezzofy coupon quantity sync']);
                }
              }
              if (product.type !== constants.PRODUCT_TYPE.PACKAGE_ITEM) {
                if (lotInventory.type === constants.LOT_INVENTORY_TYPE.CUSTOMIZE || lotInventory.type === constants.LOT_INVENTORY_TYPE.CUSTOMIZE_TGR) {
                  if (!lotInventory.cosFileKey) {
                    lotInventoryMap.set(productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_LIST_COS_FILE_KEY, ['Missing cosFileKey']);
                  } else if (lotInventory.rowNumber !== lotInventory.quantity) {
                    lotInventoryMap.set(productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_LIST_COS_FILE_KEY, ['Invalid number row of Lot Inventory in the uploading file']);
                  }
                }
                if (lotInventory.type === constants.LOT_INVENTORY_TYPE.CUSTOMIZE_MASS_CODE) {
                  if (!lotInventory.massCode) {
                    lotInventoryMap.set(productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_LIST_MASS_CODE, ['Missing mass code']);
                  }
                }
              }

            }
            if (isNaN(Number(lotInventory.quantity)) || Number(lotInventory.quantity) <= 0) {
              lotInventoryMap.set(productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_LIST_QUANTITY, ['Missing quantity']);
            } else if (!Number.isInteger(lotInventory.quantity)) {
              lotInventoryMap.set(productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_LIST_QUANTITY, ['Invalid quantity']);
            }
            if (product.type === constants.PRODUCT_TYPE.PACKAGE_ITEM && !product.redeemCodeApiType) {
              if (lotInventory.subProductLotInventoryList <= 0) {
                lotInventoryMap.set(productValidationConstants.KEY_SUB_PRODUCT_LOT_INVENTORY_LIST, ['Missing sub product lot inventory']);
              } else {
                const subProductLotInventoryListSet = new Set();
                lotInventory.subProductLotInventoryList.map((subProductLotInventory) => {
                  const subProductLotInventoryMap = new Map();

                  if (subProductLotInventoryListSet.has(subProductLotInventory.subProductLotInventoryValiCode)) {
                    messageMap.set(productValidationConstants.KEY_SUB_PRODUCT_LOT_INVENTORY_LIST_LOT_INVENTORY_VALI_CODE, ['Duplicate sub product log inventory vali code']);
                  } else {
                    subProductLotInventoryListSet.add(subProductLotInventory.subProductLotInventoryValiCode);
                  }
                  if (!subProductLotInventory.productCode) {
                    subProductLotInventoryMap.set(productValidationConstants.KEY_SUB_PRODUCT_LOT_INVENTORY_LIST_PRODUCT_CODE, ['Missing product code']);
                  }
                  if (isNaN(Number(subProductLotInventory.quantity)) || Number(subProductLotInventory.quantity) <= 0) {
                    subProductLotInventoryMap.set(productValidationConstants.KEY_SUB_PRODUCT_LOT_INVENTORY_LIST_QUANTITY, ['Missing quantity']);
                  } else if (!Number.isInteger(subProductLotInventory.quantity)) {
                    subProductLotInventoryMap.set(productValidationConstants.KEY_SUB_PRODUCT_LOT_INVENTORY_LIST_QUANTITY, ['Invalid quantity']);
                  }
                  if (lotInventory.type === constants.LOT_INVENTORY_TYPE.CUSTOMIZE || lotInventory.type === constants.LOT_INVENTORY_TYPE.CUSTOMIZE_TGR) {
                    if (!subProductLotInventory.cosFileKey) {
                      subProductLotInventoryMap.set(productValidationConstants.KEY_SUB_PRODUCT_LOT_INVENTORY_LIST_COS_FILE_KEY, ['Missing cosFileKey']);
                    } else if (subProductLotInventory.rowNumber !== subProductLotInventory.quantity) {
                      subProductLotInventoryMap.set(productValidationConstants.KEY_SUB_PRODUCT_LOT_INVENTORY_LIST_COS_FILE_KEY, ['Invalid number row of Lot Inventory in the uploading file']);
                    }
                  }
                  if (lotInventory.type === constants.LOT_INVENTORY_TYPE.CUSTOMIZE_MASS_CODE) {
                    if (!subProductLotInventory.massCode) {
                      subProductLotInventoryMap.set(productValidationConstants.KEY_SUB_PRODUCT_LOT_INVENTORY_LIST_MASS_CODE, ['Missing mass code']);
                    }
                  }
                  if (subProductLotInventoryMap.size > 0) {
                    lotInventoryMap.set(subProductLotInventory.subProductLotInventoryValiCode, subProductLotInventoryMap);
                  }
                  return subProductLotInventory;
                })
              }
            }
            if (lotInventoryMap.size > 0) {
              messageMap.set(productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_ERROR, ["Has error(s)"]);
              messageMap.set(lotInventory.lotInventoryValiCode, lotInventoryMap);
            }
          }
          return lotInventory;
        });
      }
    }

    if (messageMap.size > 0) {
      messageMap.set(productValidationConstants.KEY_PRODUCT_ERROR, ["Has error(s)"]);
      this.setProductLotErrorMapMessages(product.productCode, messageMap);
    }
    return product;
  }

  validateProductLists(productList = [], allRedeemGroupList, categoryType) {
    if (!productList || productList.length <= 0) {
      this.setErrorMapMessages(productValidationConstants.KEY_PRODUCT_LIST, ['Missing product']);
    } else {
      this.errorMap.delete(productValidationConstants.KEY_PRODUCT_LIST);
      productList.map((product) => {
        this.validateProduct(product, allRedeemGroupList, categoryType);
        this.validateProductLot(product);
        return {};
      });
    }
  }

  setErrorMapMessages(key, errorMessages, valid = false) {
    this.errorMap.set(key, errorMessages);
    // console.log("errorMessages", this.errorMap)
    if (!valid) {
      this.valid = false;
    }
  }

  setCatErrorMapMessages(key, errorMessages, valid = false) {
    this.categoryErrorMap.set(key, errorMessages);
    // console.log("categoryErrorMap", this.categoryErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

  setCatImageErrorMapMessages(key, errorMessages, valid = false) {
    this.categoryImageErrorMap.set(key, errorMessages);
    // console.log("categoryImageErrorMap", this.categoryImageErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

  setRedeemGroupErrorMapMessages(key, errorMessages, valid = false) {
    this.redeemGroupErrorMap.set(key, errorMessages);
    // console.log("redeemGroupErrorMap", this.redeemGroupErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

  setProductErrorMapMessages(key, errorMessages, valid = false) {
    this.productErrorMap.set(key, errorMessages);
    // console.log("productErrorMap", this.productErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

  setProductLotErrorMapMessages(key, errorMessages, valid = false) {
    this.productLotErrorMap.set(key, errorMessages);
    // console.log("productLotErrorMap", this.productLotErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new ProductCreateValidation();
export default validation;