import { useParams } from "react-router-dom";

import { constants, BANNER_TYPE } from '../../Constants/constants';
import { BannerCreate } from "../Banner/BannerCreate";
import useRestaurant from "../../hooks/Restaurant/useRestaurant";

export const RestaurantBannerCreate = () => {
  const params = useParams();
  const resource = "restaurant";

  const { getRestaurantIdAndName } = useRestaurant(params.restUrlId)
  const mapCode = getRestaurantIdAndName()

  return <BannerCreate newPath={constants.PATH.SHOP.RESTAURANT_BANNER_LIST}
    mapId={params.restUrlId || ''}
    mapName={{resource: resource , key: "restUrlId"}}
    mapCode={mapCode}
    redirectPath={constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT}
    bannerType={BANNER_TYPE.RESTAURANT}
    tab={constants.RESTAURANT_MEDIA_SUB_TAB.BANNER_LIST} />
};