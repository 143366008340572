import _ from 'lodash'
import { QUEUE_TRANSFER_PROFILE } from '../actions/types'
import { constants } from '../Constants/constants'

const INITIAL_STATE = {
  ui: {
    queueTransferProfile: _.cloneDeep(constants.QUEUE_TRANSFER_PROFILE_INPUT),
  },
}

const queueTransferProfileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case QUEUE_TRANSFER_PROFILE.SET_QUEUE_TRANSFER_PROFILE_DEFAULT:
      return Object.assign({}, INITIAL_STATE)
    case QUEUE_TRANSFER_PROFILE.SET_QUEUE_TRANSFER_PROFILE:
    case QUEUE_TRANSFER_PROFILE.SET_QUEUE_TRANSFER_PROFILE_VALUE:
      return Object.assign(
        {},
        {
          ...state,
          ui: {
            ...state.ui,
            queueTransferProfile: action.queueTransferProfile,
          },
        }
      )
    default:
      return state
  }
}

export default queueTransferProfileReducer
