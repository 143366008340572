import _ from "lodash";
import { PRINT_TEMPLATE } from '../actions/types';
import { constants } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        printTemplate: _.cloneDeep(constants.PRINT_TEMPLATE_INPUT),
        templateList: _.cloneDeep(constants.TEMPLATE_LIST),
        templateLabelList: _.cloneDeep(constants.TEMPLATE_LABEL_LIST),
        template: _.cloneDeep(constants.TEMPLATE_LIST_INPUT),
        templateLabel: _.cloneDeep(constants.TEMPLATE_LABEL_LIST_INPUT),
        customIdNumber: 0,
    }
}

const printTemplateReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PRINT_TEMPLATE.SET_PRINT_TEMPLATE_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case PRINT_TEMPLATE.SET_PRINT_TEMPLATE:
        case PRINT_TEMPLATE.SET_PRINT_TEMPLATE_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    printTemplate: action.printTemplate
                }
            })
        case PRINT_TEMPLATE.SET_PRINT_TEMPLATE_TEMPLATE:
        case PRINT_TEMPLATE.SET_PRINT_TEMPLATE_TEMPLATE_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    templateList: action.templateList
                }
            })
        case PRINT_TEMPLATE.SET_PRINT_TEMPLATE_TEMPLATE_LABEL:
        case PRINT_TEMPLATE.SET_PRINT_TEMPLATE_TEMPLATE_LABEL_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    templateLabelList: action.templateLabelList
                }
            })
        case PRINT_TEMPLATE.SET_TEMPLATE:
        case PRINT_TEMPLATE.SET_TEMPLATE_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    template: action.template
                }
            })
        case PRINT_TEMPLATE.SET_TEMPLATE_LABEL:
        case PRINT_TEMPLATE.SET_TEMPLATE_LABEL_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    templateLabel: action.templateLabel
                }
            })
        case PRINT_TEMPLATE.SET_CUSTOM_ID_NUMBER:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    customIdNumber: action.customIdNumber
                }
            })
        default:
            return state
    }
}

export default printTemplateReducer