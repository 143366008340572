import { constants } from '../../../Constants/constants';
import { useSelector, useDispatch } from "react-redux";

import CancelIcon from '@mui/icons-material/Cancel';

import { BaseWrapper } from "../../BaseWrapper";
import { setProductValue } from "../../../actions/productCreateAction";
import ProductCreateValidation from "../../../validations/productCreateValidation";
import { productValidationConstants } from '../../../Constants/validationConstants';
import { setLoading } from "../../../actions/systemAction";
import { ProductListCheckBox } from "../Product/ProductListCheckBox";
import { getString } from "../../../helper/util";

import "../../../styles/productDraftCreate.scss";

export const ProductCreateProductLotList = () => {
  const dispatch = useDispatch();
  const savedProduct = useSelector(state => state.product.ui.product);
  const lang = useSelector(state => state.lang.ui.lang);
  const theme = useSelector(state => state.theme.ui.theme);

  return (
    <BaseWrapper className="list-wrapper product-lot-list-wrapper">
      <div className="array-list-wrapper product-array-list-wrapper">
        <div className="sub-title">{getString(lang, "productCreateProductLotList", "listName", theme)} : </div>
      </div>
      <div className="step-content-content">
        <ProductListCheckBox listCheckBox="productLotListCheckBox" />
        {ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_LIST).map((error) => { return error }) &&
          ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_LIST).map((error) => { return error }).length > 0 &&
          <div className="error-message">
            <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
            {ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_LIST).map((error) => { return error })}
          </div>
        }
        <div className="tab-background">
          {savedProduct.draft.productList.map((item, index) => {
            let list = "";
            if (savedProduct.draft.productLotListCheckBox[item.type] || savedProduct.draft.productLotListCheckBox['ALL']) {
              list =
                item.type !== constants.PRODUCT_TYPE.PACKAGE_ITEM ?
                  <div key={item.productCode} className={savedProduct.draft.productCode === item.productCode ? "product-create-list-item selected-product" : "product-create-list-item"} onClick={(e) => {
                    dispatch(setLoading(true));
                    e.stopPropagation();
                    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.LOT_INVENTORY, savedProduct, "", { lotInventory: item.lotInventoryList, lotInventorySet: true, productList: savedProduct.draft.productList, productCode: item.productCode, productType: item.type, redeemCodeApiType: item.redeemCodeApiType }));
                    dispatch(setLoading(false));
                  }}>
                    <div className="code">
                      <div>{item.productCode}</div>
                      <div className="type">{item.type}</div>
                    </div>
                    <div className="content">
                      <div className="top">
                        <div className="name">{item.tcName}</div>
                        <div className="price">${item.sellingPrice}</div>
                      </div>
                    </div>
                    {ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_ERROR, item.productCode).map((error) => { return error }) &&
                      ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_ERROR, item.productCode).map((error) => { return error }).length > 0 &&
                      <div className="error-message">
                        <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                        {ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_ERROR, item.productCode).map((error) => { return error })}
                      </div>
                    }
                  </div>
                  :
                  <div key={item.productCode} className={savedProduct.draft.productCode === item.productCode ? "product-create-list-item selected-product" : "product-create-list-item"} onClick={(e) => {
                    dispatch(setLoading(true));
                    e.stopPropagation();
                    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.LOT_INVENTORY, savedProduct, "", { lotInventory: item.lotInventoryList, lotInventorySet: true, productList: savedProduct.draft.productList, productCode: item.productCode, productType: item.type, redeemCodeApiType: item.redeemCodeApiType }));
                    dispatch(setLoading(false));
                  }}>
                    <div className="code">
                      <div>{item.productCode}</div>
                      <div className="type">{item.type}</div>
                    </div>
                    <div className="content">
                      <div className="top">
                        <div className="name">{item.tcName}</div>
                        <div className="price">${item.sellingPrice}</div>
                      </div>
                      <div className="bottom">
                        {item.subProductMapList.map((subProductMapListItem, subProductMapListIndex) => {
                          const subItem = savedProduct.draft.subProductList.find((item) => { return item.productCode === subProductMapListItem.productCode });
                          return (
                            <div key={subProductMapListItem.productCode + ' ' + subProductMapListIndex} className="sub-item no-hover">
                              <div className="code">
                                {subItem.productCode}
                              </div>
                              <div className="sub-centent">
                                <div className="sub-content-item">
                                  <div className="sub-item-name">
                                    {
                                      (subItem) ? subItem.tcName : ''
                                    }
                                  </div>
                                  <div className="multiply-icon">x</div>
                                  <div className="quantity">{subProductMapListItem.quantity}</div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                      {ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_ERROR, item.productCode).map((error) => { return error }) &&
                        ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_ERROR, item.productCode).map((error) => { return error }).length > 0 &&
                        <div className="error-message">
                          <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                          {ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_LOT_INVENTORY_ERROR, item.productCode).map((error) => { return error })}
                        </div>
                      }
                    </div>
                  </div>
            }
            return list;
          })}
        </div>
      </div>
    </BaseWrapper >
  )
};