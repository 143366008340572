import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import { Card } from '@mui/material'
import { Drawer } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'

import { setProductValue } from '../../../actions/productCreateAction'
import { constants } from '../../../Constants/constants'
import { setLoading } from '../../../actions/systemAction'
import { ProductCategoryList } from '../Category/ProductCategoryList'
import { TempAutoSelectInput } from '../../Input/TempAutoSelectInput'
import { TempTextInput } from '../../Input/TempTextInput'
import { TempBtn } from '../../Input/TempBtn'
import { getString } from '../../../helper/util'
import { Launch } from '@mui/icons-material'

export const ProductEditCategory = ({ hiddenInput, disableInput, helperTextStylesClasses }) => {
  const dispatch = useDispatch()
  const savedProduct = useSelector((state) => state.product.ui.product)
  const [showCategoryPanel, setShowCategoryPanel] = useState(false)
  const [selectedCategoryId, setSelectedCategoryId] = useState(
    savedProduct.draft.product.categoryMapList && savedProduct.draft.product.categoryMapList.length > 0
      ? savedProduct.draft.product.categoryMapList
          .map((item) => {
            return item.status !== constants.PRODUCT_EDIT_STATUS.DEACTIVE && item.categoryCode
          })
          .filter(Boolean)
      : []
  )
  const [categoryIdList, setCategoryIdList] = useState([])
  const lang = useSelector((state) => state.lang.ui.lang)
  const theme = useSelector((state) => state.theme.ui.theme)

  const handleAddCategory = () => {
    dispatch(setLoading(true))
    setShowCategoryPanel(true)
    dispatch(setLoading(false))
  }

  const processAddCategory = (categoryId) => {
    setShowCategoryPanel(true)
    return new Promise((resolve) => {
      let tempCategoryList = _.cloneDeep(savedProduct.draft.product.categoryMapList)
      let oldCategoryList = tempCategoryList.map((item) => {
        return item.categoryCode
      })
      let newTempCategoryList = []

      categoryId = categoryId.filter((item) => {
        return item
      })

      let tempCategoryId = new Map(categoryId.map((item) => [item]))
      tempCategoryList.forEach((item) =>
        tempCategoryId.has(item.categoryCode)
          ? (item.status = constants.PRODUCT_EDIT_STATUS.ACTIVE)
          : (item.status = constants.PRODUCT_EDIT_STATUS.DEACTIVE)
      )

      newTempCategoryList = tempCategoryList

      newTempCategoryList = newTempCategoryList.filter((category) => {
        if (!category.id) {
          return categoryId.includes(category.categoryCode)
        } else {
          return category
        }
      })

      let oldCategory
      oldCategory = categoryId.filter((oldCategory) => {
        return !oldCategoryList.includes(oldCategory)
      })

      oldCategory.forEach((item, index) => {
        categoryIdList.forEach((categoryIdValue) => {
          if (item === categoryIdValue.categoryCode)
            newTempCategoryList.push({
              id: '',
              categoryId: categoryIdValue.id,
              categoryCode: categoryIdValue.categoryCode,
              categoryCodeDescription: categoryIdValue.codeDescription,
              categoryChannel: categoryIdValue.channel,
              categoryTcName: categoryIdValue.tcName,
              categoryEnName: categoryIdValue.enName,
              categoryScName: categoryIdValue.scName,
            })
        })
      })

      resolve(newTempCategoryList)
    })
  }

  const handleCategoryCode = async () => {
    const tempCategoryList = await processAddCategory(selectedCategoryId)
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, 'categoryMapList', tempCategoryList))
    setShowCategoryPanel(false)
  }

  const handleCategoryCodeSubmit = () => {
    handleCategoryCode()
  }

  const handleCategoryCloseClick = () => {
    dispatch(setLoading(true))
    setShowCategoryPanel(false)
    dispatch(setLoading(false))
  }

  function handleCategoryStatus(name, status, categoryIndex) {
    let tempCategoryList = _.cloneDeep(savedProduct.draft.product.categoryMapList)
    tempCategoryList.map((item, index) => {
      if (categoryIndex === index) {
        item.status = status
        setCategoryIdList(categoryIdList.filter((categoryId) => categoryId.categoryCode !== item.categoryCode))
        setSelectedCategoryId(selectedCategoryId.filter((selectedCategoryId) => selectedCategoryId !== item.categoryCode))
      }
      return {}
    })
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, 'categoryMapList', tempCategoryList))
  }

  const handleDeleteCategory = (code, categoryIndex) => {
    dispatch(setLoading(true))
    let tempCategoryCodeList = _.cloneDeep(savedProduct.draft.product.categoryMapList)
    tempCategoryCodeList.splice(categoryIndex, 1)
    setCategoryIdList(categoryIdList.filter((item) => item.categoryCode !== code))
    setSelectedCategoryId(selectedCategoryId.filter((item) => item !== code))
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, 'categoryMapList', tempCategoryCodeList))
    dispatch(setLoading(false))
  }

  const dalogBottomBtnList = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'save' },
      disabled: false,
      onClick: handleCategoryCodeSubmit,
      className: 'margin-left-16',
      icon: <SaveIcon />,
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'close' },
      disabled: false,
      onClick: handleCategoryCloseClick,
      className: 'margin-left-16',
      icon: <ClearIcon />,
    },
  ]

  return (
    <>
      {savedProduct.draft.product.id && savedProduct.draft.product.type !== constants.PRODUCT_TYPE.PACKAGE_SUB_ITEM && (
        <>
          <div className="array-list-wrapper">
            <h3>{getString(lang, 'productCreateProductList', 'categoryMapList', theme)}</h3>
            {!hiddenInput && (
              <TempBtn
                btnSetting={{
                  variant: constants.STYLE.VARIANT.CONTAINED,
                  color: constants.STYLE.PRIMARY,
                  onClick: handleAddCategory,
                  label: { resource: 'productCreateProductList', key: 'addCategory' },
                  disabled: disableInput,
                  icon: <AddIcon />,
                }}
              />
            )}
          </div>
          {savedProduct.draft.product.categoryMapList.map((item, categoryIndex) => (
            <Card key={categoryIndex} className="image-list">
              {!item.id ? (
                !hiddenInput && (
                  <div className="deleteIcon">
                    <TempBtn
                      btnSetting={{
                        className: 'edit-create-btn',
                        variant: constants.STYLE.VARIANT.CONTAINED,
                        label: { key: 'deleteBtn' },
                        color: constants.STYLE.PRIMARY,
                        onClick: () => handleDeleteCategory(item.categoryCode, categoryIndex),
                        icon: <DeleteIcon />,
                        disabled: disableInput,
                      }}
                    />
                  </div>
                )
              ) : disableInput ? (
                <TempTextInput
                  inputSetting={{
                    className: 'edit-create-input',
                    variant: disableInput ? constants.STYLE.VARIANT.STANDARD : null,
                    size: constants.SIZE.SMALL,
                    label: { resource: 'productCreateProductList', key: 'status' },
                    value: item.status ? item.status : '',
                    disabled: disableInput,
                  }}
                  handleChange={handleCategoryStatus}
                  index={categoryIndex}
                />
              ) : (
                <TempAutoSelectInput
                  inputSetting={{
                    className: 'edit-create-input',
                    size: constants.SIZE.SMALL,
                    variant: constants.STYLE.VARIANT.OUTLINED,
                    type: constants.TYPE.SELECT,
                    selectValue: constants.CHOICES.PRODUCT_CREATE.EDIT_PRODUCT.CATEGORY_STATUS,
                    label: { resource: 'productCreateProductList', key: 'status' },
                    value: item.status ? item.status : '',
                    disabled: disableInput,
                  }}
                  handleChange={handleCategoryStatus}
                  index={categoryIndex}
                />
              )}
              <div>
                <TempTextInput
                  inputSetting={{
                    className: 'edit-create-input',
                    variant: constants.STYLE.VARIANT.STANDARD,
                    size: constants.SIZE.SMALL,
                    label: { resource: 'productCreateProductList', key: 'categoryCode' },
                    value: item.categoryCode,
                    disabled: true,
                  }}
                />
                <TempTextInput
                  inputSetting={{
                    className: 'edit-create-input',
                    variant: constants.STYLE.VARIANT.STANDARD,
                    size: constants.SIZE.SMALL,
                    label: { resource: 'productCreateProductList', key: 'channel' },
                    value: item.categoryChannel,
                    disabled: true,
                  }}
                />
                <TempTextInput
                  inputSetting={{
                    className: 'edit-create-input',
                    variant: constants.STYLE.VARIANT.STANDARD,
                    label: { resource: 'productCreateProductList', key: 'codeDescription' },
                    value: item.categoryCodeDescription,
                    disabled: true,
                    endAdornmentIconButton: item.categoryCodeDescription ? (
                      <Launch
                        fontSize={constants.SIZE.SMALL}
                        onClick={() =>
                          window.open(
                            `${window.location.origin}/rack_product/redeem_group/$
                          }/0`,
                            '_blank'
                          )
                        }
                      />
                    ) : (
                      <></>
                    ),
                  }}
                />
              </div>
              <div>
                <TempTextInput
                  inputSetting={{
                    className: 'edit-create-input',
                    variant: constants.STYLE.VARIANT.STANDARD,
                    size: constants.SIZE.SMALL,
                    label: { resource: 'productCreateProductList', key: 'categoryEnName' },
                    value: item.categoryEnName,
                    disabled: true,
                  }}
                />
                <TempTextInput
                  inputSetting={{
                    className: 'edit-create-input',
                    variant: constants.STYLE.VARIANT.STANDARD,
                    size: constants.SIZE.SMALL,
                    label: { resource: 'productCreateProductList', key: 'categoryTcName' },
                    value: item.categoryTcName,
                    disabled: true,
                  }}
                />
                <TempTextInput
                  inputSetting={{
                    className: 'edit-create-input',
                    variant: constants.STYLE.VARIANT.STANDARD,
                    size: constants.SIZE.SMALL,
                    label: { resource: 'productCreateProductList', key: 'categoryScName' },
                    value: item.categoryScName,
                    disabled: true,
                  }}
                />
              </div>
            </Card>
          ))}
          <Drawer classes={{ paper: 'drawer-margin-left' }} anchor="right" open={showCategoryPanel} onClose={handleCategoryCloseClick}>
            <ProductCategoryList
              editBtn={false}
              createBtn={false}
              btnList={false}
              mutipleSelect={true}
              setSelectedIdList={setSelectedCategoryId}
              selectedIdList={selectedCategoryId}
              setPreviewSelectedList={setCategoryIdList}
              previewSelectedList={categoryIdList}
              newFilter={
                selectedCategoryId.length > 0
                  ? {
                      selected: {
                        column: 'categoryCode',
                        valueList: selectedCategoryId
                          .map((item) => {
                            return item
                          })
                          .reverse(),
                      },
                    }
                  : null
              }
              savedData={savedProduct}
              dialogFooterBtn={
                <div className="edit-footer-wrapper toolbar">
                  {dalogBottomBtnList.map((btnSetting, index) => {
                    return <TempBtn key={index} btnSetting={btnSetting} />
                  })}
                </div>
              }
            />
          </Drawer>
        </>
      )}
    </>
  )
}
