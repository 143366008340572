import React from 'react'

export const TicketTypeSection = ({
  ticketType,
  ticketTypeLabel,
  children,
  ticketTypeBackgroundColor,
  ticketTypeTextColor,
  tickeyTypeObj,
  onClickTicketTypeSection,
}) => {
  if (!ticketType) {
    return <>{children}</>
  }

  return (
    <div
      className="ticket-type-section"
      style={{
        backgroundColor: ticketTypeBackgroundColor || 'lightcyan',
        color: ticketTypeTextColor || 'black',
      }}
      onClick={(e) => {
        e.stopPropagation()
        onClickTicketTypeSection(tickeyTypeObj)
      }}
    >
      <div>{ticketTypeLabel.en}</div>
      <div>{ticketTypeLabel.tc}</div>
      {children}
    </div>
  )
}
