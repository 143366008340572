import _ from "lodash";
import { RESERVATION_TEMPLATE_MAP } from '../actions/types';
import { constants } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        reservationTemplateMapList: _.cloneDeep(constants.RESERVATION_TEMPLATE_MAP_LIST),
        reservationTemplateMap: _.cloneDeep(constants.RESERVATION_TEMPLATE_MAP_INPUT),
    }
}

const reservationTemplateMapReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RESERVATION_TEMPLATE_MAP.SET_RESERVATION_TEMPLATE_MAP_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case RESERVATION_TEMPLATE_MAP.SET_RESERVATION_TEMPLATE_MAP:
        case RESERVATION_TEMPLATE_MAP.SET_RESERVATION_TEMPLATE_MAP_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    reservationTemplateMap: action.reservationTemplateMap
                }
            })
        case RESERVATION_TEMPLATE_MAP.SET_RESERVATION_TEMPLATE_MAP_LIST:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    reservationTemplateMapList: action.reservationTemplateMapList
                }
            })
        default:
            return state
    }
}

export default reservationTemplateMapReducer