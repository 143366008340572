import { constants } from '../../../../../Constants/constants';

const resource = "productCreateSystemGroupList";
 
export const tabs = [
  {
  label: { resource: resource, key: "info" },
    tabIndex: constants.SYSTEM_GROUP.INFO
  },
  {
    label: { resource: resource, key: "restaurantList" },
    tabIndex: constants.SYSTEM_GROUP.RESTAURANT_LIST
  },
  {
    label: { resource: resource, key: "systemUser" },
    tabIndex: constants.SYSTEM_GROUP.SYSTEM_USER
  },
]