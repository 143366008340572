import _ from 'lodash'

import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Drawer } from '@mui/material'
import { TempTextInput } from '../../Component/Input/TempTextInput'
import { getOne } from '../../helper/baseFetch'
import { constants as co } from '../../Constants/constants'
import store from '../../store'

import { QueueTemplateList } from '../QueueTemplate/QueueTemplateList'
import { helperTextStyles } from '../../helper/util'

import { setQueueProfile } from '../../actions/queueProfileAction'
import { setQueueTransferProfile } from '../../actions/queueTransferProfileAction'

import QueueProfileValidation from '../../validations/queueProfileValidation'
import QueueTransferProfileValidation from '../../validations/queueTransferProfileValidation'

import { queueProfileValidationConstants, queueTransferProfileValidationConstants } from '../../Constants/validationConstants'
import useRestaurant from '../../hooks/Restaurant/useRestaurant'

const GenericGetRefTemplateCode = ({ resource, createEditType }) => {
  const helperTextStylesClasses = helperTextStyles()
  const dispatch = useDispatch()
  const [openDrawer, setOpenDrawer] = useState(false)
  const [queueTemplateId, setQueueTemplateId] = useState('')
  const { getRestUrlId } = useRestaurant()
  const restUrlId = getRestUrlId()

  let isQueueTransferProfile = resource === 'QueueTransferProfile',
    validation = isQueueTransferProfile ? QueueTransferProfileValidation : QueueProfileValidation,
    validationConstants = isQueueTransferProfile ? queueTransferProfileValidationConstants : queueProfileValidationConstants,
    setDispatchProfile = isQueueTransferProfile
      ? (profile) => dispatch(setQueueTransferProfile(profile))
      : (profile) => dispatch(setQueueProfile(profile))

  const savedData = useSelector((state) => {
    if (isQueueTransferProfile) {
      return state.queueTransferProfile.ui.queueTransferProfile
    } else {
      return state.queueProfile.ui.queueProfile
    }
  })

  const onCloseDrawer = () => {
    setOpenDrawer(false)
  }

  const onOpenDrawer = () => {
    setOpenDrawer(true)
  }

  const onChangeRefTemplateCode = () => {
    setOpenDrawer(false)
    setQueueTemplateId(null)
  }

  const onClickListRow = (obj) => {
    setOpenDrawer(false)
    setQueueTemplateId(obj.id)
  }

  useEffect(() => {
    setQueueTemplateId('')
  }, [savedData.refTemplateCode])

  useEffect(() => {
    if (queueTemplateId) {
      getOne(
        co.PATH.SHOP.QUEUE.QUEUE_TEMPLATE + '/' + queueTemplateId,
        undefined,
        (payload) => {
          const newTimeSectionList = payload.timeSectionList.map((obj, idx) => {
            return {
              timeSectionId: obj.timeSectionId,
              timeSectionTcLabel: obj.timeSectionTcLabel,
              timeSectionEnLabel: obj.timeSectionEnLabel,
              timeSectionScLabel: obj.timeSectionScLabel,
              startTime: obj.startTime,
              endTime: obj.endTime,
              timeSectionDescription: obj.timeSectionDescription,
              tableType: obj.tableType,
              tableTypeTcLabel: obj.tableTypeTcLabel,
              tableTypeEnLabel: obj.tableTypeEnLabel,
              tableTypeScLabel: obj.tableTypeScLabel,
              status: obj.status,
              tableTypeForegroundColor: obj.tableTypeForegroundColor,
              tableTypeBackgroundColor: obj.tableTypeBackgroundColor,
              ticketType: obj.ticketType,
              ticketTypeEnLabel: obj.ticketTypeEnLabel,
              ticketTypeScLabel: obj.ticketTypeScLabel,
              ticketTypeTcLabel: obj.ticketTypeTcLabel,
              ticketTypeBackgroundColor: obj.ticketTypeBackgroundColor,
              ticketTypeForegroundColor: obj.ticketTypeForegroundColor,
            }
          })
          const newProfile = _.cloneDeep(savedData)
          newProfile.refTemplateTimeSectionList = newTimeSectionList
          newProfile.refTemplateCode = payload.templateCode
          newProfile.timeSectionList = null
          newProfile.tableTypeColorConfigList = payload.tableTypeColorConfigList
          newProfile.defaultTableTypeColorConfigList = payload.defaultTableTypeColorConfigList
          setDispatchProfile(newProfile)
          setQueueTemplateId(payload.id)
        },
        undefined,
        store
      )
    } else if (queueTemplateId === null) {
      const newProfile = _.cloneDeep(savedData)
      newProfile.refTemplateTimeSectionList = null
      newProfile.refTemplateCode = null
      newProfile.timeSectionList = null
      setDispatchProfile(newProfile)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queueTemplateId])

  return (
    <>
      <TempTextInput
        inputSetting={{
          InputLabelProps: { shrink: true },
          className: 'edit-create-input',
          label: { resource: resource, key: 'refTemplateCode' },
          value: savedData.refTemplateCode,
          onClickEndAdornmentIconButton: createEditType === 'create' ? onOpenDrawer : savedData.refTemplateCode && onOpenDrawer,
          disabled: createEditType === 'create' ? true : !savedData.refTemplateCode,
          clear: false,
          helperTextIcon: true,
          helperText: validation.getErrorMessages(validationConstants.KEY_REF_TEMPLATE_CODE),
          FormHelperTextProps: { className: helperTextStylesClasses.root },
        }}
        handleChange={onChangeRefTemplateCode}
      />
      {openDrawer && (
        <Drawer classes={{ paper: 'drawer-margin-left' }} anchor="right" open={openDrawer} onClose={onCloseDrawer}>
          <QueueTemplateList editBtn={false} createBtn={false} btnList={false} newFilter={{ id: restUrlId }} onClickListRow={(item) => onClickListRow(item)} />
        </Drawer>
      )}
    </>
  )
}

export default GenericGetRefTemplateCode
