import { useState, useEffect } from "react";
import { useStore } from "react-redux";
import { useParams } from "react-router-dom";

import _ from "lodash";

import { Card } from '@mui/material';

import { ListDisplay } from '../../Component/ListDisplay/ListDisplay';

import { constants } from '../../Constants/constants';
import { getList } from "../../helper/baseFetch";

export const PaymentList = () => {
  const store = useStore();
  const params = useParams();
  const [paymentList, setPaymentList] = useState([]);
  const [ready, setReady] = useState(false);
  const resource = "RestaurantPayment";

  const merchantUserTitleList = [
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "serviceType",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "systemGroupName",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "paymentConfigName",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "paymentCode",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "cardType",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "sequence",
      sort: false
    }, 
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "status",
      sort: true
    }, 
  ];
  const listFilters = [
    {
      className: "filter-input",
      label: {resource: resource, key: "serviceType"},
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.RESTAURANT_PAYMENT.SERVICE_TYPE,
      name: "serviceType",
      size: constants.SIZE.SMALL
    },
    {
      className: "filter-input",
      label: {resource: resource, key: "systemGroupName"},
      clear: true,
      value: '',
      name: "systemGroupName",
      size: constants.SIZE.SMALL
    },
  ];

  const [param, setParam] = useState({
    path: "",
    page: 0,
    rowsPerPage: 10,
    sort: 'updateTimestamp',
    direction: 'DESC',
    listFilters: listFilters,
    count: 0,
  })

  function onChangeParam(value) {
    value.count = value.count +1;
    setParam(value);
  }

  function onChangeListFilters(list) {
    let tempParam = _.cloneDeep(param);
    tempParam.listFilters = list;
    tempParam.page = 0;
    onChangeParam(tempParam);
  }

  function onChangeListSort(sortItem) {
    let tempParam = _.cloneDeep(param);
    tempParam.sort = sortItem;
    tempParam.direction = tempParam.direction === "DESC" ? "ASC" : "DESC";
    onChangeParam(tempParam);
  }

  function onChangeListPage(page) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = page;
    onChangeParam(tempParam);
  }

  function onChangeListRowsPerPage(rowsPerPage) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = 0;
    tempParam.rowsPerPage = rowsPerPage;
    onChangeParam(tempParam);
  }

  useEffect(() => {
    let tempParam = _.cloneDeep(param);
    const filter = {};
    tempParam.listFilters.forEach((filterItem) => {
      if (filterItem.value !== '' && filterItem.value !== undefined) {
        filter[filterItem.name] = filterItem.value;
      }
    })

    getList(constants.PATH.SHOP.PAYMENT.PAYMENT_CONFIG_MAP, { sort: { field: tempParam.sort, order: tempParam.direction }, filter: {...filter, restUrlId: params.id}, pagination: { page: tempParam.page + 1, perPage: tempParam.rowsPerPage } }, (payload) => {
        setPaymentList(payload);
        setReady(true)
      }, undefined, store);

  }, [param.count]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <Card className="card-wrapper overflow">
      <ListDisplay
        page={param.page}
        rowsPerPage={param.rowsPerPage}
        onChangeListSort={onChangeListSort}
        resource={resource}
        dataList={paymentList}
        sort={param.sort}
        direction={param.direction}
        listTitles={merchantUserTitleList}
        listFilters={param.listFilters}
        idParam= {params.id}
        onChangeListPage={onChangeListPage}
        onChangeListRowsPerPage={onChangeListRowsPerPage}
        onChangeListFilters={onChangeListFilters}
        redirectPath={"/" + constants.PATH.SHOP.PAYMENT.PAYMENT_CONFIG_MAP + "/"}
        createBtn={true}
        editBtn={true}
      />
    </Card>
  )
};