import { districtValidationConstants } from '../Constants/validationConstants';

class DistrictValidation {

  version;
  errorMap;
  districtErrorMap;
  valid;

  constructor() {

    if (!DistrictValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.districtErrorMap = new Map();
      DistrictValidation.instance = this;
    }
    return DistrictValidation.instance;
  }

  validateEditDistrict(district) {
    this.resetErrorMessages();
    this.handleValidateEditDistrict(district);
    this.version++;
  }

  validateCreateDistrict(district) {
    this.resetErrorMessages();
    this.handleValidateCreateDistrict(district);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.districtErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key, uniqueCode) {
    if (!uniqueCode) {
      return (this.districtErrorMap.has(key)) ? this.districtErrorMap.get(key) : [];
    }
  }

  handleValidateEditDistrict(district) {
    if (!district.enName) {
      this.setDistrictErrorMapMessages(districtValidationConstants.KEY_EN_NAME, "Missing EN Name");
    }
    if (!district.tcName) {
      this.setDistrictErrorMapMessages(districtValidationConstants.KEY_TC_NAME, "Missing TC Name");
    }
    if (!district.scName) {
      this.setDistrictErrorMapMessages(districtValidationConstants.KEY_SC_NAME, "Missing SC Name");
    }
    if (!district.areaId && district.areaId !== 0) {
      this.setDistrictErrorMapMessages(districtValidationConstants.KEY_AREA_ID, "Missing Area ID");
    }
    if (!district.districtLevel && district.districtLevel !== 0) {
      this.setDistrictErrorMapMessages(districtValidationConstants.KEY_DISTRICT_LEVEL, "Missing District Level");
    } else if (district.districtLevel < 0 || !Number.isInteger(Number(district.districtLevel))) {
      this.setDistrictErrorMapMessages(districtValidationConstants.KEY_DISTRICT_LEVEL, "Invalid District Level");
    }
    if (!district.menuChiOrder && district.menuChiOrder !== 0) {
      this.setDistrictErrorMapMessages(districtValidationConstants.KEY_MENU_CHI_ORDER, "Missing Menu Chi Order");
    } else if (district.menuChiOrder < 0 || !Number.isInteger(Number(district.menuChiOrder))) {
      this.setDistrictErrorMapMessages(districtValidationConstants.KEY_MENU_CHI_ORDER, "Invalid Menu Chi Order");
    }
    if (!district.latitude && district.latitude !== 0) {
      this.setDistrictErrorMapMessages(districtValidationConstants.KEY_LATITUDE, "Missing Latitude");
    } else if (!(typeof district.latitude === 'number' && !Number.isNaN(district.latitude) && !Number.isInteger(district.latitude)) && district.latitude !== 0 ) {
      this.setDistrictErrorMapMessages(districtValidationConstants.KEY_LATITUDE, "Invalid Latitude");
    }
    if (!district.longitude && district.longitude !== 0) {
      this.setDistrictErrorMapMessages(districtValidationConstants.KEY_LONGITUDE, "Missing Longitude");
    } else if (!(typeof district.longitude === 'number' && !Number.isNaN(district.longitude) && !Number.isInteger(district.longitude)) && district.longitude !== 0) {
      this.setDistrictErrorMapMessages(districtValidationConstants.KEY_LONGITUDE, "Invalid Longitude");
    }
    if (!district.status) {
      this.setDistrictErrorMapMessages(districtValidationConstants.KEY_STATUS, "Missing Status");
    }
  }

  handleValidateCreateDistrict(district) {
    if (!district.enName) {
      this.setDistrictErrorMapMessages(districtValidationConstants.KEY_EN_NAME, "Missing EN Name");
    }
    if (!district.tcName) {
      this.setDistrictErrorMapMessages(districtValidationConstants.KEY_TC_NAME, "Missing TC Name");
    }
    if (!district.scName) {
      this.setDistrictErrorMapMessages(districtValidationConstants.KEY_SC_NAME, "Missing SC Name");
    }
    if (!district.areaId && district.areaId !== 0) {
      this.setDistrictErrorMapMessages(districtValidationConstants.KEY_AREA_ID, "Missing Area ID");
    }
    if (!district.districtLevel && district.districtLevel !== 0) {
      this.setDistrictErrorMapMessages(districtValidationConstants.KEY_DISTRICT_LEVEL, "Missing District Level");
    } else if (district.districtLevel < 0 || !Number.isInteger(Number(district.districtLevel))) {
      this.setDistrictErrorMapMessages(districtValidationConstants.KEY_DISTRICT_LEVEL, "Invalid District Level");
    }
    if (!district.menuChiOrder && district.menuChiOrder !== 0) {
      this.setDistrictErrorMapMessages(districtValidationConstants.KEY_MENU_CHI_ORDER, "Missing Menu Chi Order");
    } else if (!(typeof district.latitude === 'number' && !Number.isNaN(district.latitude) && !Number.isInteger(district.latitude)) && district.latitude !== 0 ) {
      this.setDistrictErrorMapMessages(districtValidationConstants.KEY_LATITUDE, "Invalid Latitude");
    }
    if (!district.latitude && district.latitude !== 0) {
      this.setDistrictErrorMapMessages(districtValidationConstants.KEY_LATITUDE, "Missing Latitude");
    } else if (!(typeof district.latitude === 'number' && !Number.isNaN(district.latitude) && !Number.isInteger(district.latitude)) && district.latitude !== 0 ) {
      this.setDistrictErrorMapMessages(districtValidationConstants.KEY_LATITUDE, "Invalid Latitude");
    }
    if (!district.longitude && district.longitude !== 0) {
      this.setDistrictErrorMapMessages(districtValidationConstants.KEY_LONGITUDE, "Missing Longitude");
    } else if (!(typeof district.longitude === 'number' && !Number.isNaN(district.longitude) && !Number.isInteger(district.longitude)) && district.longitude !== 0) {
      this.setDistrictErrorMapMessages(districtValidationConstants.KEY_LONGITUDE, "Invalid Longitude");
    }
  }


  setDistrictErrorMapMessages(key, errorMessages, valid = false) {
    this.districtErrorMap.set(key, errorMessages);
    // console.log("districtErrorMap", this.districtErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new DistrictValidation();
export default validation;