import { MERCHANT_USER_GROUP } from './types';
import { constants } from '../Constants/constants';
import _ from "lodash";

export const setMerchantUserGroupDefault = () => {
    return {
        type: MERCHANT_USER_GROUP.SET_MERCHANT_USER_GROUP_DEFAULT,
    }
}

export const setMerchantUserGroup = (merchantUserGroup) => {
    return {
        type: MERCHANT_USER_GROUP.SET_MERCHANT_USER_GROUP,
        merchantUserGroup: merchantUserGroup
    }
}

export const setMerchantUserGroupValue = (draftType, orignalProduct, key, value, index) => {
    const merchantUserGroup = _.cloneDeep(orignalProduct);

    switch (draftType) {
        case "emails":
            merchantUserGroup.emailList[key] = value;
            break;
        case "settlementConfig":
            merchantUserGroup.settlementConfigList[index][key] = value;
            break;
        default:
            merchantUserGroup[draftType] = value;
    }
    return {
        type: MERCHANT_USER_GROUP.SET_MERCHANT_USER_GROUP_VALUE,
        merchantUserGroup: merchantUserGroup
    }
}

export const addUserGroupEmail = (orignalProduct) => {
    const merchantUserGroup = _.cloneDeep(orignalProduct);
    merchantUserGroup.emailList? merchantUserGroup.emailList.push(null):merchantUserGroup.emailList=[null];
    return {
        type: MERCHANT_USER_GROUP.SET_MERCHANT_USER_GROUP_VALUE,
        merchantUserGroup: merchantUserGroup
    }
}

export const removeUserGroupEmail = (orignalProduct, removeIndex) => {
    const merchantUserGroup = _.cloneDeep(orignalProduct);
    merchantUserGroup.emailList = merchantUserGroup.emailList.filter((_,index)=>index!==removeIndex);
    return {
        type: MERCHANT_USER_GROUP.SET_MERCHANT_USER_GROUP_VALUE,
        merchantUserGroup: merchantUserGroup
    }
}

export const addUserGroupSettlementConfig = (orignalProduct) => {
    const merchantUserGroup = _.cloneDeep(orignalProduct);
    merchantUserGroup.settlementConfigList? 
    merchantUserGroup.settlementConfigList.push({...constants.MERCHANT_USER_GROUP_SETTLEMENT_CONFIG_INPUT})
    :merchantUserGroup.settlementConfigList=[{...constants.MERCHANT_USER_GROUP_SETTLEMENT_CONFIG_INPUT}];
    return {
        type: MERCHANT_USER_GROUP.SET_MERCHANT_USER_GROUP_VALUE,
        merchantUserGroup: merchantUserGroup
    }
}

export const removeUserGroupSettlementConfig = (orignalProduct, removeIndex) => {
    const merchantUserGroup = _.cloneDeep(orignalProduct);
    merchantUserGroup.settlementConfigList = merchantUserGroup.settlementConfigList.filter((_,index)=>index!==removeIndex);
    return {
        type: MERCHANT_USER_GROUP.SET_MERCHANT_USER_GROUP_VALUE,
        merchantUserGroup: merchantUserGroup
    }
}