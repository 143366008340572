import _ from "lodash";
import { BRAND_GROUP } from '../actions/types';
import { constants } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        BrandGroup: _.cloneDeep(constants.BRAND_GROUP_INPUT)
    }
}

const BrandGroupReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case BRAND_GROUP.SET_BRAND_GROUP_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case BRAND_GROUP.SET_BRAND_GROUP:
        case BRAND_GROUP.SET_BRAND_GROUP_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    BrandGroup: action.BrandGroup
                }
            })
        default:
            return state
    }
}

export default BrandGroupReducer