import { PRODUCT_MAP } from './types';
import { sify } from "chinese-conv";
import _ from "lodash";

export const setProductMapDefault = () => {
    return {
        type: PRODUCT_MAP.SET_PRODUCT_MAP_DEFAULT,
    }
}

export const setProductMap = (productMap) => {
    return {
        type: PRODUCT_MAP.SET_PRODUCT_MAP,
        productMap: productMap
    }
}

export const setProductMapValue = (draftType, orignalProduct, key, value, index) => {
    const productMap = _.cloneDeep(orignalProduct);
    switch (draftType) {
        case "CHANGE_TC_NAME_AND_SC_NAME":
            productMap.tcName = value;
            productMap.scName = sify(value);
            break;
        default:
            productMap[draftType] = value;
    }
    return {
        type: PRODUCT_MAP.SET_PRODUCT_MAP_VALUE,
        productMap: productMap
    }
}
