import { MERCHANT_INFO } from './types';
import _ from "lodash";

export const setMerchantInfoDefault = () => {
    return {
        type: MERCHANT_INFO.SET_MERCHANT_INFO_DEFAULT,
    }
}

export const setMerchantInfo = (merchantInfo) => {
    return {
        type: MERCHANT_INFO.SET_MERCHANT_INFO,
        merchantInfo: merchantInfo
    }
}

export const setMerchantInfoValue = (draftType, orignalProduct, key, value, index) => {
    const merchantInfo = _.cloneDeep(orignalProduct);
    switch (draftType) {
        case "CHANGE_BANK":
            merchantInfo.bankCode = value.bankCode;
            merchantInfo.bankName = value.bankName;
            break;
        default:
            merchantInfo[draftType] = value;
    }
    return {
        type: MERCHANT_INFO.SET_MERCHANT_INFO_VALUE,
        merchantInfo: merchantInfo
    }
}
