import _ from 'lodash'
import { COUNTER_PROFILE } from '../actions/types'
import { constants } from '../Constants/constants'

const INITIAL_STATE = {
  ui: {
    counterProfile: _.cloneDeep(constants.COUNTER_PROFILE_INPUT),
  },
}

const counterProfileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case COUNTER_PROFILE.SET_COUNTER_PROFILE_DEFAULT:
      return Object.assign({}, INITIAL_STATE)
    case COUNTER_PROFILE.SET_COUNTER_PROFILE:
    case COUNTER_PROFILE.SET_COUNTER_PROFILE_VALUE:
      return Object.assign(
        {},
        {
          ...state,
          ui: {
            ...state.ui,
            counterProfile: action.counterProfile,
          },
        }
      )
    default:
      return state
  }
}

export default counterProfileReducer
