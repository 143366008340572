export const DEVICE_TYPE_NAME_MAP = {
  pr: 'PR',
  STAFF_PR: 'STAFF_PR',
  kiosk: 'KIOSK',
  tv: 'TV',
  counterKiosk: 'COUNTER_KIOSK',
  counterTv: 'COUNTER_TV',
  pickupTV: 'PICKUP_TV',
  campaignQueue: 'CAMPAIGN_QUEUE',
  tailorMade: 'TAILOR_MADE',
  mallKiosk: 'MALL_KIOSK',
  slideshowPlayer: 'SLIDESHOW_PLAYER',
  fallback: 'FALLBACK',
  mallTv: 'MALL_TV',
}
