import { useState, useEffect } from "react";
import { constants } from '../../../Constants/constants';
import { useSelector, useDispatch, useStore } from "react-redux";
import _ from "lodash";

import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { Drawer } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ClearIcon from '@mui/icons-material/Clear';
import CancelIcon from '@mui/icons-material/Cancel';

import ProductCreateValidation from "../../../validations/productCreateValidation";
import { productValidationConstants } from '../../../Constants/validationConstants';
import { BaseWrapper } from "../../BaseWrapper";
import { setProductValue } from "../../../actions/productCreateAction";
import { ProductExistingRedeemGroupList } from "./ProductExistingRedeemGroupList";
import { setLoading } from "../../../actions/systemAction";
import { getRedeemGroup } from "./ProductExistingRedeemGroupList";
import { TempBtn } from "../../Input/TempBtn"
import { getString, permissionsCheck } from "../../../helper/util";

import "../../../styles/productDraftCreate.scss";

export const ProductCreateRedeemGroupList = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const savedProduct = useSelector(state => state.product.ui.product)
  const [showRedeemGroupPanel, setShowRedeemGroupPanel] = useState(false);
  const [disableInput, setDisableInput] = useState(false);
  const [hiddenInput, setHiddenInput] = useState(false);
  const [selectedRedeemGroup, setSelectedRedeemGroup] = useState(savedProduct.draft.existingRedeemGroupList);
  const [redeemGroupIdList, setredeemGroupIdList] = useState([]);

  const lang = useSelector(state => state.lang.ui.lang);
  const theme = useSelector(state => state.theme.ui.theme);

  const permission = localStorage.getItem(constants.LOCAL_STORAGE_KEY.ROLE);

  useEffect(() => {
    if (permissionsCheck(constants.PERMISSION_KEY.REDEEM_GROUP_LIST_FINANCE)) {
      setDisableInput(true);
      setHiddenInput(true);
    } else {
      setDisableInput(false);
      setHiddenInput(false);
    }

  }, [permission])// eslint-disable-line react-hooks/exhaustive-deps

  const handleRedeemGroupClick = (redeemGroup) => {
    dispatch(setLoading(true));
    setredeemGroupIdList(savedProduct.draft.existingRedeemGroupList && savedProduct.draft.existingRedeemGroupList.length > 0 ? savedProduct.draft.existingRedeemGroupList.map((item) => { return item.id }) : null);
    setShowRedeemGroupPanel(true);
    dispatch(setLoading(false));
  };

  const handleRedeemGroupCloseClick = () => {
    dispatch(setLoading(true));
    setredeemGroupIdList([]);
    setSelectedRedeemGroup(savedProduct.draft.existingRedeemGroupList);
    setShowRedeemGroupPanel(false);
    dispatch(setLoading(false));
  };

  const handleRedeemGroupSubmit = () => {
    dispatch(setLoading(true));
    setShowRedeemGroupPanel(false);
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.DRAFT, savedProduct, "existingRedeemGroupList", selectedRedeemGroup));
    dispatch(setLoading(false));
  };

  function createRedeemGroup() {
    let tempDraft = _.cloneDeep(savedProduct.draft);
    let tempRedeemGroup = _.cloneDeep(constants.REDEEM_GROUP);
    let tempGroupCode = _.cloneDeep(savedProduct.draft.codeNumber);
    dispatch(setLoading(true));
    tempRedeemGroup.redeemGroupCode = "RedeemGroup_" + tempGroupCode;
    tempGroupCode = tempGroupCode + 1;
    tempDraft.codeNumber = tempGroupCode + 1;
    tempDraft.redeemGroup = tempRedeemGroup;
    tempDraft.redeemGroupList.push(tempRedeemGroup);
    dispatch(setProductValue("", savedProduct, "", tempDraft));
    dispatch(setLoading(false));
  }

  const dalogBottomBtnList = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "submit" },
      disabled: false,
      onClick: handleRedeemGroupSubmit,
      className: "margin-left-16",
      icon: <AddIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "close" },
      disabled: false,
      onClick: handleRedeemGroupCloseClick,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  return (
    <BaseWrapper className="list-wrapper redeem-group-list-wrapper">
      <div className="array-list-wrapper product-array-list-wrapper">
        <div className="sub-title">{getString(lang, "productCreateRedeemGroupList", "listName", theme)} : </div>
        {!hiddenInput &&
          <div className="step-content-content-btn">
            <TempBtn
              btnSetting=
              {{
                onClick: handleRedeemGroupClick,
                variant: constants.STYLE.VARIANT.CONTAINED,
                color: constants.STYLE.PRIMARY,
                icon: <PlaylistAddIcon />,
                label: { resource: "productCreateRedeemGroupList", key: "addExistingRedeemGroup" },
                disabled: disableInput,
              }}
            />
            <TempBtn
              btnSetting=
              {{
                onClick: createRedeemGroup,
                variant: constants.STYLE.VARIANT.CONTAINED,
                color: constants.STYLE.PRIMARY,
                icon: <AddIcon />,
                label: { key: "create" },
                disabled: disableInput,
                className: "btn-margin"
              }}
            />
          </div>
        }
      </div>
      {ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_REDEEM_GROUP_LIST).map((error) => { return error }) &&
        ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_REDEEM_GROUP_LIST).map((error) => { return error }).length > 0 &&
        <div className="error-message">
          <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
          {ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_REDEEM_GROUP_LIST).map((error) => { return error })}
        </div>
      }
      <Drawer
        classes={{ paper: "drawer-margin-left" }}
        anchor="right"
        open={showRedeemGroupPanel}
        onClose={handleRedeemGroupCloseClick}
      >
        <ProductExistingRedeemGroupList
          editBtn={false}
          createBtn={false}
          mutipleSelect={true}
          setSelectedIdList={setredeemGroupIdList}
          selectedIdList={redeemGroupIdList}
          setPreviewSelectedList={setSelectedRedeemGroup}
          previewSelectedList={selectedRedeemGroup}
          newFilter={savedProduct.draft.existingRedeemGroupList && savedProduct.draft.existingRedeemGroupList.length > 0 ? { selected: { column: 'id', valueList: savedProduct.draft.existingRedeemGroupList.map((item) => { return item.id }).reverse() } } : null}
          dialogFooterBtn={
            !hiddenInput &&
            <div className="edit-footer-wrapper toolbar">
              {dalogBottomBtnList.map((btnSetting, index) => {
                return <TempBtn key={index} btnSetting={btnSetting} />
              })}
            </div>
          }
        />
      </Drawer>
      <TableContainer component={Paper} className="step-content-content redeem-group-content">
        {savedProduct.draft.redeemGroupList && savedProduct.draft.redeemGroupList.map((item, index) =>
          <div key={item.redeemGroupCode} className={savedProduct.draft.redeemGroup && savedProduct.draft.redeemGroup.redeemGroupCode === item.redeemGroupCode ? "product-create-list-item selected-product" : "product-create-list-item"} onClick={(e) => {
            dispatch(setLoading(true));
            e.stopPropagation();
            dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.DRAFT, savedProduct, "redeemGroup", item));
            dispatch(setLoading(false));
          }}>
            <div className="code">
              <div>{item.redeemGroupCode}</div>
            </div>
            <div className="content">
              <div className="top">
                <div className="name">{item.codeDescription}</div>
                {!hiddenInput &&
                  <TempBtn
                    btnSetting=
                    {{
                      onClick: (e) => {
                        e.stopPropagation();
                        dispatch(setLoading(true));
                        let tempDraft = _.cloneDeep(savedProduct.draft);
                        tempDraft.productList.forEach((item) => {
                          if (item.redeemGroupCode === tempDraft.redeemGroupList[index].redeemGroupCode) {
                            return item.redeemGroupCode = null
                          }
                        });
                        if (tempDraft.product && tempDraft.product.redeemGroupCode === tempDraft.redeemGroupList[index].redeemGroupCode) {
                          tempDraft.product.redeemGroupCode = null
                        }
                        if (tempDraft.redeemGroup.redeemGroupCode === tempDraft.redeemGroupList[index].redeemGroupCode) {
                          tempDraft.redeemGroup = {};
                        }
                        tempDraft.redeemGroupList = tempDraft.redeemGroupList.filter((item, redeemGroupListIndex) => redeemGroupListIndex !== index)
                        dispatch(setProductValue("", savedProduct, "", tempDraft, 0, "delete"));
                        dispatch(setLoading(false));
                      },
                      color: constants.STYLE.PRIMARY,
                      label: { key: "deleteBtn" },
                      icon: <DeleteIcon />,
                      disabled: disableInput,
                      className: "delete-button"
                    }}
                  />
                }
              </div>
              {ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_REDEEM_GROUP_ERROR, item.redeemGroupCode).map((error) => { return error }) &&
                ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_REDEEM_GROUP_ERROR, item.redeemGroupCode).map((error) => { return error }).length > 0 &&
                <div className="error-message">
                  <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                  {ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_REDEEM_GROUP_ERROR, item.redeemGroupCode).map((error) => { return error })}
                </div>
              }
            </div>
          </div>
        )}
        {savedProduct.draft.existingRedeemGroupList && savedProduct.draft.existingRedeemGroupList.map((item, index) =>
          <div key={item.redeemGroupCode} className={savedProduct.draft.redeemGroup.redeemGroupCode === item.redeemGroupCode ? "product-create-list-item selected-product" : "product-create-list-item"}
            onClick={async () => {
              const data = await getRedeemGroup(item.id, store);
              data.redeemDisable = true;
              dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.DRAFT, savedProduct, "redeemGroup", data));
            }}>
            <div className="code">
              <div>{item.redeemGroupCode}</div>
            </div>
            <div className="content">
              <div className="top">
                <div className="name">{item.codeDescription}</div>
                <div className="existing">(Existing)</div>
                {!hiddenInput &&
                  <TempBtn
                    btnSetting=
                    {{
                      onClick: (e) => {
                        e.stopPropagation();
                        dispatch(setLoading(true));
                        let newExistingRedeemGroupList = _.cloneDeep(savedProduct.draft.existingRedeemGroupList.filter((item, existingRedeemGroupListIndex) => { return existingRedeemGroupListIndex !== index }))
                        setSelectedRedeemGroup(newExistingRedeemGroupList);
                        dispatch(setProductValue("EXISTING_REDEEM_GROUP", savedProduct, "",
                          {
                            redeemGroup: savedProduct.draft.redeemGroupList.length > 0 ? savedProduct.draft.redeemGroupList[0] : newExistingRedeemGroupList > 0 ? newExistingRedeemGroupList[0] : {},
                            existingRedeemGroupList: newExistingRedeemGroupList
                          }
                        ));
                        dispatch(setLoading(false));
                      },
                      color: constants.STYLE.PRIMARY,
                      label: { key: "deleteBtn" },
                      icon: <DeleteIcon />,
                      disabled: disableInput,
                      className: "delete-button"
                    }}
                  />
                }
              </div>
              {ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_REDEEM_GROUP_ERROR, item.redeemGroupCode).map((error) => { return error }) &&
                ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_REDEEM_GROUP_ERROR, item.redeemGroupCode).map((error) => { return error }).length > 0 &&
                <div className="error-message">
                  <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                  {ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_REDEEM_GROUP_ERROR, item.redeemGroupCode).map((error) => { return error })}
                </div>
              }
            </div>
            {ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_REDEEM_GROUP_ERROR, item.redeemGroupCode).map((error) => { return error }) &&
              ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_REDEEM_GROUP_ERROR, item.redeemGroupCode).map((error) => { return error }).length > 0 &&
              <div className="error-message">
                <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                {ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_REDEEM_GROUP_ERROR, item.redeemGroupCode).map((error) => { return error })}
              </div>
            }
          </div>
        )}
      </TableContainer>
    </BaseWrapper>
  )
};