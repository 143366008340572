import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector, useStore } from 'react-redux'
import _ from 'lodash'

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CancelIcon from '@mui/icons-material/Cancel'
import { makeStyles } from '@mui/styles'

import { constants } from '../../Constants/constants'
import { getOne, put } from '../../helper/baseFetch'
import { getScDisabled } from '../../helper/util'
import { setTnC, setTnCValue } from '../../actions/tncAction'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { TempCustomCheckBox } from '../../Component/Custom/TempCustomCheckBox'
import { TempTextInput } from '../../Component/Input/TempTextInput'
import { TempAutoSelectInput } from '../../Component/Input/TempAutoSelectInput'
import { DialogWrapper } from '../../Component/DialogWrapper'
import TnCValidation from '../../validations/tncValidation'
import { tncValidationConstants } from '../../Constants/validationConstants'
import useRestaurant from '../../hooks/Restaurant/useRestaurant'

const helperTextStyles = makeStyles((theme) => ({
  root: {
    color: 'red !important',
  },
}))

export const TnCEdit = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const store = useStore()
  const history = useHistory()
  const [ready, setReady] = useState(false)
  const [clear, setClear] = useState(true)
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  const resource = 'TnC'
  const savedTnC = useSelector((state) => state.tnc.ui.tnc)
  // const [tncInputList, setTnCInputList] = useState(_.cloneDeep(savedTnC));
  const helperTextStylesClasses = helperTextStyles()
  // eslint-disable-next-line no-unused-vars
  const [errorVersion, setErrorVersion] = useState(TnCValidation.getVersion())

  const tabs = [
    {
      label: { resource: resource, key: 'info' },
      tabIndex: constants.TNC_EDIT_TAB.INFO,
    },
  ]

  const fields = [
    {
      className: 'edit-create-input',
      variant: constants.STYLE.VARIANT.STANDARD,
      label: { resource: resource, key: 'type' },
      value: savedTnC.type,
      name: 'type',
      isEndOfLine: false,
      disabled: true,
      required: true,
      helperTextIcon: true,
      helperText: TnCValidation.getErrorMessages(tncValidationConstants.KEY_TYPE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'status' },
      value: savedTnC.status,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.TNC.STATUS,
      name: 'status',
      isEndOfLine: true,
      disableClearable: true,
      required: true,
      helperTextIcon: true,
      helperText: TnCValidation.getErrorMessages(tncValidationConstants.KEY_STATUS),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'filterKey' },
      value: savedTnC.filterKey,
      name: 'filterKey',
      clear: true,
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'enDescription' },
      value: savedTnC.enDescription,
      name: 'enDescription',
      custom: true,
      customName: { resource: resource, key: 'descriptionCustomize' },
      customValue: savedTnC.scDescriptionDisable,
      isEndOfLine: false,
      multiline: true,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'tcDescription' },
      value: savedTnC.tcDescription,
      name: 'tcDescription',
      translateName: savedTnC.scDescriptionDisable ? ['scDescription'] : null,
      // inputList: tncInputList,
      // setInputList: setTnCInputList,
      isEndOfLine: false,
      multiline: true,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'scDescription' },
      value: savedTnC.scDescription,
      name: 'scDescription',
      // inputList: tncInputList,
      // setInputList: setTnCInputList,
      isEndOfLine: true,
      disabled: savedTnC.scDescriptionDisable,
      multiline: true,
    },
  ]
  const content = [
    {
      tab: (
        <>
          {TnCValidation.getErrorMessages(tncValidationConstants.KEY_REST_URL_ID) &&
            TnCValidation.getErrorMessages(tncValidationConstants.KEY_REST_URL_ID).length > 0 && (
              <div className="error-message">
                <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                {TnCValidation.getErrorMessages(tncValidationConstants.KEY_REST_URL_ID)}
              </div>
            )}
          {fields.map((inputSetting, index) => {
            let content
            switch (inputSetting.type) {
              case constants.TYPE.SELECT:
                content = <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
                break
              default:
                content = <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
            }
            return (
              <div className="edit-display-wrapper" key={index}>
                {inputSetting.custom && (
                  <TempCustomCheckBox
                    value={inputSetting.customValue}
                    className="customize-btn"
                    customName={inputSetting.customName}
                    onSelectCheckbox={onSelectCheckbox}
                  />
                )}
                {content}
                {inputSetting.isEndOfLine && <div></div>}
              </div>
            )
          })}
        </>
      ),
      btn: true,
    },
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'save' },
      onClick: onClickSave,
      className: 'margin-left-16',
      icon: <SaveIcon />,
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'clear' },
      disabled: false,
      onClick: onClickClear,
      className: 'margin-left-16',
      icon: <ClearIcon />,
    },
  ]

  function onSelectCheckbox(value, customName) {
    switch (customName.key) {
      case 'descriptionCustomize':
        dispatch(setTnCValue('scDescriptionDisable', savedTnC, '', value))
        break
      default:
    }
  }

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push(
      '/' +
        constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT +
        '/' +
        savedTnC.restUrlId +
        '/' +
        constants.RESTAURANT_TAB.INFO +
        '/' +
        constants.RESTAURANT_SUB_TAB.TNC
    )
  }

  function onChangeInput(name, e) {
    let changedValue = e ? e : null
    if (name === 'tcDescription' && savedTnC.scDescriptionDisable === true) {
      dispatch(setTnCValue('CHANGE_TC_DESCRIPTION_AND_SC_DESCRIPTION', savedTnC, '', changedValue))
    } else {
      dispatch(setTnCValue(name, savedTnC, '', changedValue))
    }
  }

  function onClickSave() {
    TnCValidation.validateEditTnC(savedTnC)
    if (TnCValidation.isValid()) {
      put(
        constants.PATH.SHOP.TNC + '/' + params.id,
        savedTnC,
        (payload) => {
          setDialog({
            open: true,
            onClose: onClickBackToEdit,
            dialogContentText: { key: 'editSuccess' },
            dialogActions: [
              { label: { key: 'backEdit' }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
              { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
            ],
          })
          setClear(false)
        },
        undefined,
        store
      )
      TnCValidation.resetErrorMessages()
    } else {
      setErrorVersion(TnCValidation.getVersion())
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    TnCValidation.resetErrorMessages()
    if (clear === true) {
      getOne(
        constants.PATH.SHOP.TNC + '/' + params.id,
        undefined,
        (payload) => {
          payload.scDescriptionDisable = getScDisabled(payload.tcDescription, payload.scDescription)
          dispatch(setTnC(payload))
          // setTnCInputList(_.cloneDeep(payload))
          setReady(true)
          setClear(false)
        },
        undefined,
        store
      )
    }
  }, [clear]) // eslint-disable-line react-hooks/exhaustive-deps

  const { getRestaurantIdAndName } = useRestaurant()
  const mapCode = getRestaurantIdAndName()

  return (
    ready && (
      <>
        <EditCreateDisplay
          code={savedTnC && savedTnC.id ? savedTnC.id : ''}
          resource={resource}
          tabs={tabs}
          content={content}
          onChangeInput={onChangeInput}
          bottomBtns={bottomBtns}
          mapName={{ resource: 'restaurant', key: 'restUrlId' }}
          mapCode={mapCode}
          display="edit"
        />
        {dialog && dialog.open && <DialogWrapper dialogInfo={dialog} />}
      </>
    )
  )
}
