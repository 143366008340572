import { useDispatch, useSelector } from 'react-redux'
import { sify } from 'chinese-conv'
import _ from 'lodash'

import { Card } from '@mui/material'

import { constants } from '../../Constants/constants'
import {
  setQueueTemplate,
  setQueueTemplateTableTypeGroupedSection,
} from '../../actions/queueTemplateAction'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { TempCustomCheckBox } from '../../Component/Custom/TempCustomCheckBox'
import { getContent } from '../../Component/EditCreateDisplay/EditUtil'

const resource = 'groupedSection'

export const QueueTemplateGroupedSection = () => {
  const dispatch = useDispatch()
  const savedQueueTemplate = useSelector((state) => state.queueTemplate.ui.queueTemplate)
  const savedGroupedSection = useSelector((state) => state.queueTemplate.ui.groupedSection)

  const tempQueueTemplateCreateList = [
    {
      className: 'edit-create-input',
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'groupedSection' },
      value: savedGroupedSection.groupedSection,
      disabled: true,
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'groupedSectionEnLabel' },
      value: savedGroupedSection.groupedSectionEnLabel,
      name: 'groupedSectionEnLabel',
      custom: true,
      customName: { resource: resource, key: 'isAutoTranslateGroupedSectionLabel' },
      customValue: savedGroupedSection.disableGroupedSection,
      isEndOfLine: false,
      multiline: true,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'groupedSectionTcLabel' },
      value: savedGroupedSection.groupedSectionTcLabel,
      name: 'groupedSectionTcLabel',
      isEndOfLine: false,
      multiline: true,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'groupedSectionScLabel' },
      value: savedGroupedSection.groupedSectionScLabel,
      name: 'groupedSectionScLabel',
      isEndOfLine: true,
      disabled: savedGroupedSection.disableGroupedSection,
      multiline: true,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'groupedSectionEnDescription' },
      value: savedGroupedSection.groupedSectionEnDescription,
      name: 'groupedSectionEnDescription',
      custom: true,
      customName: { resource: resource, key: 'isAutoTranslateGroupedSectionDescription' },
      customValue: savedGroupedSection.disableGroupedSectionDescription,
      isEndOfLine: false,
      multiline: true,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'groupedSectionTcDescription' },
      value: savedGroupedSection.groupedSectionTcDescription,
      name: 'groupedSectionTcDescription',
      isEndOfLine: false,
      multiline: true,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'groupedSectionScDescription' },
      value: savedGroupedSection.groupedSectionScDescription,
      name: 'groupedSectionScDescription',
      isEndOfLine: true,
      disabled: savedGroupedSection.disableGroupedSectionDescription,
      multiline: true,
    },
  ]

  const content = [
    {
      tab: (
        <>
          <Card className="card-wrapper">
            {tempQueueTemplateCreateList.map((inputSetting, index) => {
              let content = getContent(inputSetting, index, onChangeInput)
              return (
                <div className="edit-display-wrapper" key={index}>
                  {inputSetting.custom && (
                    <TempCustomCheckBox
                      className="customize-btn"
                      value={inputSetting.customValue}
                      customName={inputSetting.customName}
                      onSelectCheckbox={onSelectCheckbox}
                    />
                  )}
                  {content}
                  {inputSetting.isEndOfLine && <div></div>}
                </div>
              )
            })}
          </Card>
        </>
      ),
      btn: false,
    },
  ]

  function onSelectCheckbox(value, customName, index) {
    const tempQueueTemplate = _.cloneDeep(savedQueueTemplate)
    const newGroupedSection = _.cloneDeep(savedGroupedSection)
    switch (customName.key) {
      case 'isAutoTranslateGroupedSectionLabel':
        newGroupedSection.disableGroupedSection = value
        if (value) {
          newGroupedSection.groupedSectionScLabel = newGroupedSection.groupedSectionTcLabel
            ? sify(newGroupedSection.groupedSectionTcLabel)
            : newGroupedSection.groupedSectionTcLabel
        }
        break
      case 'isAutoTranslateGroupedSectionDescription':
        newGroupedSection.disableGroupedSectionDescription = value
        if (value) {
          newGroupedSection.groupedSectionScDescription = newGroupedSection.groupedSectionTcDescription
            ? sify(newGroupedSection.groupedSectionTcDescription)
            : newGroupedSection.groupedSectionTcDescription
        }
        break
      default:
    }
    const tempTimeSectionIndex = newGroupedSection.currentTimeSectionIndex
    tempQueueTemplate[tempTimeSectionIndex].tableTypeList.forEach((tableType) => {
      if (tableType.groupedSection === newGroupedSection.groupedSection) {
        tableType.groupedSection = newGroupedSection.groupedSection
        tableType.groupedSectionEnDescription = newGroupedSection.groupedSectionEnDescription
        tableType.groupedSectionEnLabel = newGroupedSection.groupedSectionEnLabel
        tableType.groupedSectionScDescription = newGroupedSection.groupedSectionScDescription
        tableType.groupedSectionScLabel = newGroupedSection.groupedSectionScLabel
        tableType.groupedSectionTcDescription = newGroupedSection.groupedSectionTcDescription
        tableType.groupedSectionTcLabel = newGroupedSection.groupedSectionTcLabel
      }
    })

    dispatch(setQueueTemplateTableTypeGroupedSection(newGroupedSection))
    dispatch(setQueueTemplate(tempQueueTemplate))

  }

  function onChangeInput(name, e, index) {
    const tempQueueTemplate = _.cloneDeep(savedQueueTemplate)
    const newGroupedSection = _.cloneDeep(savedGroupedSection)
    switch (name) {
      case 'groupedSectionTcLabel':
        newGroupedSection[`${name}`] = e
        if (newGroupedSection.disableGroupedSection) {
          newGroupedSection.groupedSectionScLabel = newGroupedSection.groupedSectionTcLabel
            ? sify(newGroupedSection.groupedSectionTcLabel)
            : newGroupedSection.groupedSectionTcLabel
        }
        break
      case 'groupedSectionTcDescription':
        newGroupedSection[`${name}`] = e
        if (newGroupedSection.disableGroupedSectionDescription) {
          newGroupedSection.groupedSectionScDescription = newGroupedSection.groupedSectionTcDescription
            ? sify(newGroupedSection.groupedSectionTcDescription)
            : newGroupedSection.groupedSectionTcDescription
        }
        break
      default:
        newGroupedSection[`${name}`] = e
    }

    const tempTimeSectionIndex = newGroupedSection.currentTimeSectionIndex
    tempQueueTemplate[tempTimeSectionIndex].tableTypeList.forEach((tableType) => {
      if (tableType.groupedSection === newGroupedSection.groupedSection) {
        tableType.groupedSection = newGroupedSection.groupedSection
        tableType.groupedSectionEnDescription = newGroupedSection.groupedSectionEnDescription
        tableType.groupedSectionEnLabel = newGroupedSection.groupedSectionEnLabel
        tableType.groupedSectionScDescription = newGroupedSection.groupedSectionScDescription
        tableType.groupedSectionScLabel = newGroupedSection.groupedSectionScLabel
        tableType.groupedSectionTcDescription = newGroupedSection.groupedSectionTcDescription
        tableType.groupedSectionTcLabel = newGroupedSection.groupedSectionTcLabel
      }
    })

    dispatch(setQueueTemplateTableTypeGroupedSection(newGroupedSection))
    dispatch(setQueueTemplate(tempQueueTemplate))
    dispatch(setQueueTemplateTableTypeGroupedSection(newGroupedSection))
  }

  return (
    <div>
      <EditCreateDisplay
        code={savedGroupedSection && savedGroupedSection.groupedSection ? savedGroupedSection.groupedSection : ''}
        resource={resource}
        tabs={false}
        content={content}
        onChangeInput={onChangeInput}
        display={'edit'}
      />
    </div>
  )
}
