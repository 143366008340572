import React from 'react'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useSelector } from 'react-redux'
import { getString } from '../../helper/util';

export const TempRadioGroup = ({inputSetting, handleChange}) => {

  const lang = useSelector((state) => state.lang.ui.lang)
  const theme = useSelector((state) => state.theme.ui.theme)

  return (
    <FormControl>
    <FormLabel id="demo-row-radio-buttons-group-label">{getString(
        lang,
        inputSetting.label.resource ? inputSetting.label.resource : '',
        inputSetting.label.key ? inputSetting.label.key : '',
        theme
      )}</FormLabel>
    <RadioGroup
      row
      aria-labelledby="demo-row-radio-buttons-group-label"
      name="row-radio-buttons-group"
      onChange={(e)=>handleChange(inputSetting.name, e.target.value)}
      value={inputSetting.value}
    >
      {
        inputSetting.radioArray?.map((radio)=><FormControlLabel key={radio.value} value={radio.value} control={<Radio />} label={radio.name} />)
      }
    </RadioGroup>
  </FormControl>
  )
}
