
import { useState, useEffect } from "react";
import { useSelector, useStore } from "react-redux";

import { Drawer } from "@mui/material";
import Paper from '@mui/material/Paper';
import ClearIcon from '@mui/icons-material/Clear';

import { constants } from '../../../Constants/constants';
import { getList } from "../../../helper/baseFetch";
import { generateTableCellToMultiLanguageName } from "../../../helper/util";
import { TempBtn } from "../../../Component/Input/TempBtn";
import { SubTabEditCreateDisplay } from '../../../Component/EditCreateDisplay/SubTabEditCreateDisplay';
import { RestaurantPaymentConfigList } from "./RestaurantPaymentConfigList";
import { RestaurantPaymentTypeList } from "./RestaurantPaymentTypeList";
import { createPaymentCodeFieldDisplaySetting } from "../UIContentFactory/createContentDisplaySetting";
import { createContentList } from "../UIContentFactory/createContentList";

const LOCAL_CONSTANT = {
  PAYMENT_CODE: 'paymentCode',
  CARD_TYPE: 'cardType',
  WECHAT: "WECHAT_PAY",
  CASH: "CASH",
  GULUFPS: "FPS",
}

const initialParam ={
  path: "",
    page: 0,
    rowsPerPage: 10,
    sort: 'updateTimestamp',
    direction: 'DESC',
    count: 0,
}

const resource = "RestaurantPayment";

export const PaymentCodeField = ({ onChangeInput }) =>{

  const savedRestaurantPayment = useSelector(state => state.restaurantPayment.ui.restaurantPayment);
  const store = useStore();
  const [drawerType, setDrawerType] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [initialPaymentTypeList, setInitialPaymentTypeList] = useState([]);

  function onClickCloseDrawer() {
    setDrawerOpen(false);
    setDrawerType(null);
    handleDrawer();
  }

  const handleOnClickDrawer=(type)=>{
    setDrawerType(type);
    setDrawerOpen(true);
    handleDrawer();
  }

  const onClickListRow=(data, type)=> {
    onChangeInput(type, data)    
    onClickCloseDrawer();
  }

  const handleDrawer=()=> {
    switch (drawerType) {
      case LOCAL_CONSTANT.PAYMENT_CODE:
        return <RestaurantPaymentConfigDrawer 
          onClickListRow={onClickListRow} 
          onClickCloseDrawer={onClickCloseDrawer} 
          drawerType={drawerType}/>
      case LOCAL_CONSTANT.CARD_TYPE:
        return <RestaurantPaymentTypeDrawer 
          onClickListRow={onClickListRow} 
          onClickCloseDrawer={onClickCloseDrawer} 
          initialPaymentTypeList={initialPaymentTypeList}
          drawerType={drawerType}/>
      default:
        return null;
    }
  }

  useEffect(() => {
    
    if(!savedRestaurantPayment.paymentCode){
      return;
    }

    getList(constants.PATH.SHOP.PAYMENT.PAYMENT_TYPE, { sort: { field: initialParam.sort, order: initialParam.direction }, filter: { paymentCode: savedRestaurantPayment.paymentCode}, pagination: { page: initialParam.page + 1, perPage: initialParam.rowsPerPage } }, (payload) => {
      payload.forEach((item)=> item.name = generateTableCellToMultiLanguageName(item.tcName, item.enName, item.scName))
      setInitialPaymentTypeList(payload.filter((item)=>item.cardType !== null));
    }, undefined, store);
  
  }, [savedRestaurantPayment.paymentCode])

  const content = createContentList(createPaymentCodeFieldDisplaySetting(resource, savedRestaurantPayment, handleOnClickDrawer, initialPaymentTypeList), onChangeInput);

  return (
    <>
      <SubTabEditCreateDisplay
        code={""}
        resource={resource}
        tabs={false}
        content={content}
        onChangeInput={onChangeInput}
        display={"none"}
      />
      <Drawer
        classes={{ paper: "drawer-margin-left" }}
        anchor="right"
        open={drawerOpen}
        onClose={onClickCloseDrawer}
      >
        {handleDrawer()}
      </Drawer>
    </>  
  )
}

const RestaurantPaymentConfigDrawer = ({onClickListRow, onClickCloseDrawer, drawerType}) =>{

  return (
    <Paper>
      <RestaurantPaymentConfigList
        editBtn={false}
        createBtn={false}
        onClickListRow={(item) => onClickListRow(item, drawerType)}
        dialogFooterBtn={<>
          <TempBtn btnSetting={{
            variant: constants.STYLE.VARIANT.CONTAINED,
            color: constants.STYLE.PRIMARY,
            onClick: onClickCloseDrawer,
            icon: <ClearIcon />,
            label: { key: "close" },
          }} />
        </>
        }
      />
    </Paper>
  )
}

const RestaurantPaymentTypeDrawer = ({onClickListRow, onClickCloseDrawer, drawerType, initialPaymentTypeList}) =>{

  return (
    <Paper>
      <RestaurantPaymentTypeList
        editBtn={false}
        createBtn={false}
        onClickListRow={(item) => onClickListRow(item.cardType, drawerType)}
        initialList = {initialPaymentTypeList}
        dialogFooterBtn={<>
          <TempBtn btnSetting={{
            variant: constants.STYLE.VARIANT.CONTAINED,
            color: constants.STYLE.PRIMARY,
            onClick: onClickCloseDrawer,
            icon: <ClearIcon />,
            label: { key: "close" },
          }} />
        </>
        }
      />
    </Paper>
  )
}