
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from '@mui/styles';

import { constants } from '../../../Constants/constants';
import { setMerchantShopValue } from "../../../actions/merchantShopCreateAction";
import { EditCreateDisplay } from '../../EditCreateDisplay/EditCreateDisplay';
import { ProductBrandCode } from "../../ProductCreate/Product/ProductBrandCode";

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const BrandCreate = ({ onCancel }) => {
  const dispatch = useDispatch();
  const [clear, setClear] = useState(false);
  const resource = "Brand";
  const savedMerchantShop = useSelector(state => state.merchantShop.ui.merchantShop);
  const helperTextStylesClasses = helperTextStyles();

  const tempBrandEditList = [
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      type: constants.TYPE.FUNCTION,
      value:
        <>
          <ProductBrandCode savedData={savedMerchantShop.draft.brandCode ? savedMerchantShop.draft.brandCode : ""} helperTextStylesClasses={helperTextStylesClasses} />
        </>,
      isEndOfLine: true,
    },
  ]
  const content = [
    {
      tab: tempBrandEditList.map((inputSetting, index) => {
        return <div className="edit-display-wrapper" key={index}>
          {inputSetting.value}
          {inputSetting.isEndOfLine && <div></div>}
        </div>
      }),
      btn: false
    }
  ]

  function onChangeInput(name, e) {
    dispatch(setMerchantShopValue("DRAFT", savedMerchantShop, name, e));
  }

  useEffect(() => {
    // let tempInput = _.cloneDeep(constants.BRAND_INPUT);
    // delete tempInput.groupId;
    // dispatch(setBrand(tempInput));
    setClear(false)
    // BrandValidation.resetErrorMessages();
  }, [clear === true]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <EditCreateDisplay
        code=""
        resource={resource}
        tabs={false}
        content={content}
        onChangeInput={onChangeInput}
        display="none"
      />
    </>
  )
};
