export const enMessages = {
  serverError: 'Server error',
  loginError: 'Please Login',

  //Menu
  shopMenu: 'Shop',
  approveMenu: 'Approval',
  takeawayMenu: 'Takeaway',
  productMenu: 'Product',
  restaurantTag: 'Restaurant Tag',
  productCreateMenuAdmin: 'Create',
  productCreateMenuFinance: 'Product Drafts',
  merchantMenu: 'Merchant',
  tools: 'Tools',
  configuration: 'Configuration',
  template: 'Template',
  brand: 'Brand',
  location: 'Location',
  changePassword: 'Change Password',

  //warning
  selectWarning: 'Please select restaurant!',
  warning: 'WARNING: ',
  warningContent: 'You are in a restricted area, all actions will be monitored!',

  //btn
  login: 'Login',
  create: 'Create',
  back: 'Back',
  next: 'Next',
  saveAsDraft: 'Save as Draft',
  approve: 'Approve',
  unpublish: 'Unpublish',
  publish: 'Publish',
  finishBtn: 'Finish',
  submit: 'Submit',
  remove: 'Remove',
  applyToAllRelatedShop: 'Apply To All Related Shop',
  applyToAll: 'Apply To All',
  save: 'Save',
  search: 'Search',
  close: 'Close',
  cancelBtn: 'Cancel',
  clear: 'Clear',
  Clone: 'Clone',
  clearCache: 'Clear Cache',
  clearRedisCache: 'Clear Redis Cache',
  clearGuluWebSiteDetailCache: ' Clear GULU Web Site Detail',
  add: 'Add',
  edit: 'Edit',
  rejectBtn: 'Reject',
  select: 'Select',
  confirm: 'Confirm',
  deleteBtn: 'Delete',
  resetPassword: 'Reset Password',
  reset: 'Reset',
  resetAccountNameAndPassword: 'Reset Account Name & Password',
  preview: 'Preview',

  //dialog
  backEdit: 'Continue editing',
  backList: 'Back to listing',
  backCreate: 'Back to Create',
  backClone: 'Back to Clone',
  goToMapList: 'Go To Map List',
  goToMapCreate: 'Go To Map Create',
  dialogTitle: 'Info',
  editSuccess: 'Update successful!',
  editFailure: 'Update failure!',
  createSuccess: 'Create successful!',
  createFailure: 'Create failure!',
  cloneSuccess: 'Clone successful! Please press refresh button to refresh the list! (**Searchable has been set as false!)',
  cloneFailure: 'Clone failure!',
  addSuccess: 'Added successful!',
  addFailure: 'Added failure!',
  clearSuccess: 'Clear successful!',
  clearFailure: 'Clear failure!',
  clearCacheSuccess: 'Clear cache successful!',
  clearCacheFailure: 'Clear cache failure!',
  resetSuccess: 'Reset successful!',
  resetFailure: 'Reset failure!',
  previewSuccess: 'Preview successful!',
  previewFailure: 'Preview failure!',
  testingSuccess: 'Testing successful!',
  testingFailure: 'Testing failure!',
  approveSuccess: 'Approve successful!',
  approveFailure: 'Approve failure!',
  rejectSuccess: 'Reject successful!',
  rejectFailure: 'Reject failure!',
  paymentSuccess: 'Payment successful!',
  paymentFailure: 'Payment failure!',
  deleteSuccess: 'Delete successful!',
  deleteFailure: 'Delete failure!',
  applySuccess: 'Apply successful!',
  applyFailure: 'Apply failure!',

  //CustomizenName
  nameCustomize: 'Name (Auto Translate TC to SC)',
  descriptionCustomize: 'Description (Auto Translate TC to SC)',
  multiActionParameters: 'Multiple Language Parameters',

  //Time
  lastModified: 'Last modified: ',
  updateTimestamp: 'Update Time',
  lastLoginTimestamp: 'Last Login Time',
  resetUpdateTimestamp: 'Last Reset Time',

  //other
  sales: 'Sales',
  true: 'true',
  false: 'false',
  pleaseEnter: 'Please Enter',
  applyToMap: 'Apply To Map',
  apply: 'Apply',
  downloadQRCode: 'Download QR',
  zhHK: '繁中',
  sc: '簡中',
  en: 'English',

  //select
  active: 'A (Active)',
  deactive: 'D (Deactive)',
  pending: 'P (Pending)',
  merchant: 'M (Merchant)',
  overWritten: 'O (Overwritten)',
  reject: 'R (Reject)',
  removed: 'R (Removed)',
  N: 'N (Deactive)',
  complete: 'C (Complete)',
  a_assigned: 'A (Assigned)',
  delete: 'D (Delete)',
  inactive: 'I (Inactive)',
  processing: 'P (Processing)',
  available: 'A (Available)',
  cancel: 'C (Cancel)',
  finish: 'F (Finish)',
  s_checkIn: 'S (Check In)',
  reminder: 'M (Reminder)',
  sentOut: 'O (Sent Out)',
  hold: 'H (Hold)',
  expired: 'E (Expired)',
  void: 'V (Void)',
  accept: 'A (Accept)',
  s_assigned: 'S (Assigned)',
  waiting: 'W (Waiting)',
  hold_release: 'L (Hold Release)',
  maxims_cancel: 'MC (Maxims Cancel)',
  c_checkIn: 'C (Check In)',
  all: 'ALL',
  tobeapprove: 'TBA (To Be Approve)',
  yes: 'Yes',
  no: 'No',

  resource: {
    login: {
      title: 'Please Login!',
      username: 'Username',
      password: 'Password',
    },

    restaurant: {
      mainTitle: 'Restaurant',
      restUrlId: 'Rest Url ID',
      status: 'Status',
      restRefId: 'Ref ID',
      enName: 'En Name',
      tcName: 'Tc Name',
      scName: 'Sc Name',
      salesName: 'Sales Name',
      salesCreateTimestamp: 'Sales Create Timestamp',
      startDisplayTimestamp: 'Start Display Timestamp',
      //restaurantList
      address: 'Address',
      imageUrl: 'imageUrl',
      refID: 'Ref ID',
      name: 'Name',
      salesCreateStartDate: 'Sales Create Start Date',
      salesCreateEndDate: 'Sales Create End Date',
      //restaurantClone
      newRestUrlID: 'New Rest Url ID',
      cloneTitle: 'Clone Restaurant',
      slideshow: 'Slideshow',
      banner: 'Banner',
      systemGroup: 'System Group',
      payment: 'Payment',
      systemGroupGorilla: 'System Group Gorilla',
      merchantUserCreate: 'Merchant User Create',
      //restaurantEdit
      info: 'Info',
      qrCode: 'QR Code',
      queue: 'Queue',
      reservation: 'Reservation',
      pickup: 'Pickup',
      merchant: 'Merchant',
      media: 'Media',
      product: 'Product',
      select_tag: 'Select Tag',
      restaurantName: 'Restaurant Name',
      weChatEName: 'WeChat En Name',
      weChatTcName: 'WeChat Tc Name',
      weChatScName: 'WeChat Sc Name',
      eSearchingName: 'E Searching Name',
      tcSearchingName: 'Tc Searching Name',
      scSearchingName: 'Sc Searching Name',
      information: 'Information',
      brandCode: 'Brand',
      editBrandCode: 'Edit Brand',
      addBrandCode: 'ADD Brand',
      clearBrandCode: 'Clear Brand',
      editBrandAndBrandGroupID: 'Edit Brand And Group ID',
      groupID: 'Group',
      editGroupID: 'Edit Group',
      addGroupID: 'ADD Group',
      restaurantType: 'Restaurant Type',
      phoneAddress: 'Phone And Address',
      phone: 'Phone',
      district: 'District',
      addDistrictID: 'Add District',
      tcAddress: 'Tc Address',
      enAddress: 'En Address',
      scAddress: 'Sc Address',
      description: 'Description',
      enTicketMessage: 'En Ticket Message',
      tcTicketMessage: 'Tc Ticket Message',
      scTicketMessage: 'Sc Ticket Message',
      enOpening: 'En Opening',
      tcOpening: 'Tc Opening',
      scOpening: 'Sc Opening',
      config: 'Configuration',
      paymentMethod: 'Payment Method',
      avgSpend: 'Avg Spend',
      enLastOrderTime: 'En LastOrderTime',
      tcLastOrderTime: 'Tc LastOrderTime',
      scLastOrderTime: 'Sc LastOrderTime',
      enDescription: 'En Description',
      tcDescription: 'Tc Description',
      scDescription: 'Sc Description',
      aigensId: "Aigens ID(Maxim's)",
      searchable: 'Searchable',
      theme: 'Theme',
      showBanner: 'Show Banner',
      nameCustomize: 'Name (Auto Translate TC to SC)',
      weChatNameCustomize: 'WeChat Name (Same as Name)',
      searchingNameCustomize: 'Searching Name (Auto Translate TC to SC)',
      ticketMessageCustomize: 'Ticket Message (Auto Translate TC to SC)',
      openingCustomize: 'Opening (Auto Translate TC to SC)',
      lastOrderTimeCustomize: 'Last Order Time (Auto Translate TC to SC)',
      descriptionCustomize: 'Description (Auto Translate TC to SC)',
      addressCustomize: 'Address (Auto Translate TC to SC)',
      image: 'Image',
      googleMap: 'Google Map',
      uploadImage: 'Upload Image',
      relatedPictures: 'Related pictures',
      dropFile: 'Drop your file here',
      retaurantBannerList: 'Restaurant Banner List',
      campaignDescription: 'Display Message',
      tnc: 'Terms & Conditions',
      editTitle: 'Restaurant Edit',
      salesAndContact: 'Sales and Contact Person',
      contact: 'Contact',
      contactPerson: 'Contact Person',
      jobTitle: 'Job Title',
      folderMapList: 'Folder Map List (Restaurant)',
      addFolderMap: 'Add Restaurant',
      urlGenerator: 'Url Generator',
      website: 'Website',
      specificLang: 'Language (EN/TC/SC only work above GULU 4.2.11, Do NOT add if you need wechat scan)',
      universalLink: 'Open App / Website',
      defaultService: 'Default Service',
      generate: 'Generate',
      url: 'Url',
    },

    //Map
    Map: {
      mainTitle: 'Map',
      addressEdit: 'Address Edit',
      chineseAddressSave: 'Chinese Address (Save Version):',
      chineseAddress: 'Chinese Address:',
      engAddressSave: 'En Address (Save Version):',
      engAddress: 'En Address:',
      lat: 'Latitude:',
      lng: 'Longitude:',
      zoom: 'Zoom',
      search: 'Searching(Address):',
      reset: 'Reset',
    },

    //RestaurantMedia
    RestaurantMedia: {
      mainTitle: 'Media',
      type: 'Type',
      cosFileUrl: 'Media',
      fileType: 'File Type',
      displaySequence: 'Display Sequence',
      status: 'Status',
      image: 'Image',
      cosFileEnUrl: 'En Image',
      cosFileTcUrl: 'Tc Image',
      cosFileScUrl: 'Sc Image',
      tcImage: 'Tc Image',
      enImage: 'En Image',
      scImage: 'Sc Image',
      editTitle: 'Media Edit',
      createTitle: 'Media Create',
      info: 'Info',
    },

    RestaurantBlackout: {
      mainTitle: 'Blackout Period',
      type: 'Type',
      startTimestamp: 'Start Timestamp',
      endTimestamp: 'End Timestamp',
      refCode: 'Refcode',
      status: 'Status',
      info: 'Info',
      editTitle: 'Blackout Period Edit',
      createTitle: 'Blackout Period Create',
    },

    RestaurantSlideshow: {
      mainTitle: 'Slideshow',
      restUrlId: 'Rest Url Id',
      restRefId: 'Ref ID',
      address: 'Restaurant Address',
      type: 'Type',
      mediaUrl: 'Media',
      media: 'Media',
      startDisplayTimestamp: 'Start Display Timestamp',
      endDisplayTimestamp: 'End Display Timestamp',
      sequence: 'Sequence',
      status: 'Status',
      editTitle: 'Slideshow Edit',
      createTitle: 'Slideshow Create',
      info: 'Info',
      id: 'ID',
      name: 'Name',
      showExpired: 'Show Expired',
      deactivate: 'Delete',
      pending: 'Pending',
      active: 'Active',
      showDefaultSlideshow: 'Show Default Slideshow',
      addRestaurantMap: 'Add Restaurant Map',
      enName: 'En Name',
      tcName: 'Tc Name',
      scName: 'Sc Name',
    },

    //urlMap
    UrlMap: {
      mainTitle: 'Url Map',
      type: 'Type',
      mapCode: 'Map Code',
      mapValue: 'Map Value',
      agentType: 'Agent Type',
      url: 'URL',
      serviceType: 'Service Type',
      parameter: 'Parameter',
      status: 'Status',
      restUrlIdQRCode: 'Rest Url ID (反掃用)',
      actionType: 'Action Type',
      action: 'Action',
      weChat: 'WeChat',
      productCode: 'Product Code',
      categoryCode: 'Category Code',
      brandCode: 'Brand Code',
      folderCode: 'Folder Code',
      folderType: 'Folder Type',
      restUrlId: 'Rest Url Id',
      pixelName: 'Pixel Name',
      haveRef: 'Pixel Have Ref',
      from: 'From',
      title: 'Title',
      query: 'Query',
      refId: 'Ref Id',
      ticketId: 'Ticket Id',
      infoId: 'Info Id',
      groupCode: 'Group Code',
      ecouponId: 'ECoupon Id',
      code: 'Code',
      channel: 'Channel',
      name: 'Name',
      tcName: 'Tc Name',
      enName: 'En Name',
      scName: 'Sc Name',
      productChannel: 'Product Channel',
      productEnName: 'Product En Name',
      productTcName: 'Product Tc Name',
      productScName: 'Product Sc Name',
      productStatus: 'Product Status',
      editTitle: 'Url Map Edit',
      createTitle: 'Url Map Create',
      info: 'Info',
      productParent: 'Brand/ Category',
      eCouponParent: 'Brand/ Category',
      ecouponCode: 'E-Coupon ',
      customUrl: 'Custom URL',
      restaurantUrlType: 'Restaurant URL Type',
    },

    //status
    Status: {
      mainTitle: 'Status',
      orderStatus: 'Order Status',
      queueStatus: 'Queue Status',
      queueVisible: 'Queue Visible',
      queueSpringboardAvailable: 'Queue Springboard Available',
      queueWebTvAvailable: 'Queue Web TV Available',
      queueTicketDeletable: 'Queue Ticket Deletable',
      reserveStatus: 'Reservation Status',
      reservationVisible: 'Reservation Visible',
      takeawayStatus: 'Takeaway Status',
      takeawayVisible: 'Takeaway Visible',
      takeawayDeliveryStatus: 'Takeaway Delivery Status',
      takeawayPickupStatus: 'Takeaway Pickup Status',
      takeawayPreorderStatus: 'Takeaway PreOrder Status',
      wxTakeawayStatus: 'wx Takeaway Status',
      wxProductStatus: 'wx Product Status',
      menuStatus: 'Menu Status',
      menuVisible: 'Menu Visible',
      ecouponDistributeStatus: 'Ecoupon Distribute Status',
      banquetStatus: 'Banquet Status',
      banquetVisible: 'Banquet Visible',
      havePreorder: 'Have Preorder',
      autoSwitchTimeSection: 'Auto Switch Time Section',
      adminLiteStatus: 'Admin Lite Status',
      vipStatus: 'VIP Status',
      turboStatus: 'Turbo Status',
      bookmarkStatus: 'Bookmark Status',
      reviewStatus: 'Review Status',
      socialPhotoStatus: 'Social Photo Status',
      shareRestStatus: 'Restaurant Share Status',
      gpsRequired: 'GPS Required',
      backList: 'Back to Restaurant List',
      editTitle: 'Status Edit',
      retaurantBannerList: 'Restaurant Banner List',
      info: 'Info',
      restaurant: 'Restaurant',
      reserve: 'Reservation',
      queue: 'Queue',
      takeaway: 'Takeaway',
      product: 'Product',
      banquet: 'Banquet',
      other: 'Other',
      queueWebAvailable: 'Queue Web Available',
      pickup: 'Pickup',
      pickupVisible: 'Pickup Visible',
      guest: 'Guest',
      guestReservationStatus: 'Guest Reservation Status',
      guestTicketStatus: 'Guest Ticket Status',
      notifier: 'Notifier',
      email: 'Email',
      sms: 'SMS',
      whatsapp: 'Whatsapp',
      reservationWebAvailable: 'Reservation Web Available',
      queueWebReadOnly: 'Queue Web Read Only',
      queueWebOfficialReadOnly: 'Queue GULU Web Read Only',
      pickupStatus: 'Pickup Status',
    },

    QueueConfig: {
      mainTitle: 'Queue Config',
      showTableNo: 'Show Table No',
      timeSectionQuota: 'Time Section Concurrent Quota',
      rollTagShiftValue: 'Roll Tag Shift Value',
      ticketExpiredMinute: 'Ticket Expired Minute',
      repeatQueue: 'Repeat Queue',
      checksumExpiryLimit: 'Checksum Expiry Limit',
      queueSteps: 'Queue Steps',
      addQueueSteps: 'Add Queue Steps',
      removeQueueSteps: 'Remove Queue Steps',
      campaign: 'Campaign',
      campaignQueue: 'Campaign Queue',
      tagSequenceDisplay: 'Tag Sequence Display',
      genFromTemplate: 'Gen From Template',
      campaignStartTimestamp: 'Campaign Start Timestamp',
      campaignEndTimestamp: 'Campaign End Timestamp',
      preparationDay: 'Preparation Day',
      delay: 'Delay',
      delayEnable: 'Delay Enable',
      delayMaxMinute: 'Delay Max Minute',
      delayReminderAnswerSecond: 'Delay Reminder Answer Second',
      delayReminderTriggerSecond: 'Delay Reminder Trigger Second',
      releaseQuotaOnDelete: 'Release Quota On Delete',
      counterProfileCode: 'Default Counter Profile Code',
      requiredPass: 'Pass Required',
      limitDistance: 'Limit Distance',
      queuePeriod: 'Queue Period',
      customData: 'Custom Data',
      email: 'Email',
      mobile: 'Mobile',
      personalLimitType: 'Personal Limit Type',
      queueType: 'Queue Type',
      displayType: 'Display Type',
      counterTagType: 'Counter Tag Type',
      deliveryType: 'Delivery Type',
      requiredOtp: 'Required OTP',
      redisSequence: 'Redis Sequence',
      quickQueueGroupName: 'Quick Queue Group Name',
      quotaType: 'Quota Type',
      rollTagType: 'Roll Tag Type',
      inputCheckInSize: 'Input Check In Size',
      minCheckInSize: 'Min Check In Size',
      maxCheckInSize: 'Max Check In Size',
      showTableType: 'Show Table Type',
      checkInType: 'Check In Type',
      eventCheckInType: 'Event Check In Type',
      reset: 'Reset Current Time Section',
      resetConfrim: 'Are you confirm to reset current time section?',
      editTitle: 'Queue Config Edit',
      info: 'Info',
      dailyResetTime: 'Daily Reset Time',
      campaignType: 'Campaign Type',
      addResourcesSteps: 'Add Resources Steps',
      resourcesSteps: 'Resources Steps',
      autoTimeType: 'Auto Time Type',
      checkOutType: 'Check Out Type',
      checkInBeforeMinute: 'Check In Before Minute',
      checkInLateMinute: 'Check In Late Minute',
      webShare: 'Web Share',
      mobileShare: 'Mobile Share',
      pleaseEnter: 'Please Enter',
      enterPassCode: 'to Reset Current Time Section',
      passCode: 'PassCode',
      dailyReset: 'Daily Reset',
      resetQueueStatus: 'Reset Queue Status',
      transferable: 'Transferable',
      useTransferableTicketLabel: 'Use Transferable Ticket Label',
      display: 'Display',
      quota: 'Quota',
      checkInCheckOut: 'Check In/ Check Out',
      others: 'Others',
      autoRequestTicketStatus: 'Auto Request Ticket Status',
      autoTask: 'Auto Task',
      autoSwitchTimeSection: 'Auto Switch Time Section',
      maxTicketPerSection: 'Max Ticket Per Section',
      limitTicketInMinute: 'Limit Ticket In Minute',
    },

    PrintConfig: {
      mainTitle: 'Print Config',
      printTemplateCode: 'Print Template Code',
      deviceType: 'Device Type',
      ticketRemarks: 'Ticket Tnc',
      printQrcodeType: 'Print Qrcode Type',
      logoImage: 'Logo Image',
      mallLogoImage: 'Mall Logo Image',
      mallKeypadButtonImage: 'Mall Keypad Button Image',
      headerImage: 'Header Image',
      footerImage: 'Footer Image',
      rightLargeImage: 'Right Large Image',
      rightSmallImage: 'Right Small Image',
      qrcodeImage: 'QR code Image',
      portraitRectangleImage: 'Portrait Rectangle Image',
      portraitSquareImage: 'Portrait Square Image',
      landscapeRectangleImage: 'Landscape Rectangle Image',
      landscapeSquareImage: 'Landscape Square Image',
      portraitHeaderImage: 'Portrait Header Image',
      portraitFooterImage: 'Portrait Footer Image',
      landscapeHeaderImage: 'Landscape Header Image',
      landscapeFooterImage: 'Landscape Footer Image',
      printTemplateSection: 'Print Template Section',
      kioskSection: 'Kiosk Section',
      shopAppSection: 'Shop App Version 1.1.8 (46)+',
      displayQrcodeUrl: 'Display QR Code URL',
      qrCodePreview: 'Display QR Code Preview',
      printTemplateType: 'Print Template Type',
      shortTicketTnc: 'Short Ticket Tnc (Max 500 Characters)',
      shortPrintTemplateCode: 'Short Print Template Code',
      service: 'Service',
      from: 'From',
      addMoreParam: 'Add More Param To URL',
    },

    QueueTemplate: {
      mainTitle: 'Queue Template',
      timeSectionList: 'Time Section List',
      timeSection: 'Time Section',
      templateCode: 'Template Code',
      description: 'Description',
      status: 'Status',
      id: 'ID',
      timeSectionId: 'Time Section ID',
      tableType: 'Table Type',
      label: 'Label',
      addRestaurant: 'Add Restaurant List',
      addQueueTemplateMap: 'Add Queue Template Map',
      resetCurrentTimeSection: 'Reset Current Time Section',
      editTitle: 'Queue Template Edit',
      addQueueTemplate: 'Add Time Section',
      autoOrderTimeSection: 'Order Time Section',
      autoOrderTableType: 'Order Table Type',
      createTitle: 'Queue Template Create',
      info: 'Info',
      createQueueTemplate: 'Create Ticket Time Section',
      cloneQueueTemplate: 'Clone Queue Template: ',
      applyQueueTemplate: 'Apply Queue Template: ',
    },

    TimeSection: {
      mainTitle: 'Time Section',
      title: 'Time Section',
      timeSectionList: 'Time Section',
      timeSectionId: 'Time Section ID',
      groupedSection: 'Grouped Section',
      type: 'Type',
      timeSectionDescription: 'Time Section Description',
      startTime: 'Start Time',
      endTime: 'End Time',
      timeSectionEnLabel: 'Time Section En Label',
      timeSectionTcLabel: 'Time Section Tc Label',
      timeSectionScLabel: 'Time Section Sc Label',
      isAutoTranslateTimeSectionLabel: 'Time Section Label (Auto Translate TC to SC)',
      status: 'Status',
      editTitle: 'Time Section Edit',
      createTitle: 'Time Section Create',
      info: 'Info',
      defaultTimeSectionSetting: 'Default Time Section Setting',
      isSyncTimeFieldsWithAllTableTypes: 'Sync Time Fields With All Table Types',
      isSyncRequestTicketTimeFieldsWithAllTableTypes: 'Sync Request Ticket Time Fields With All Table Types',
      kioskRequestTicketStartTime: 'Kiosk Request Ticket Start Time',
      mobileRequestTicketStartTime: 'Mobile Request Ticket Start Time',
      kioskRequestTicketEndTime: 'Kiosk Request Ticket End Time',
      mobileRequestTicketEndTime: 'Mobile Request Ticket End Time',
    },

    TableType: {
      mainTitle: 'Table Type',
      title: 'Table Type',
      status: 'Status',
      defaultStatus: 'Default Status',
      tableTypeList: 'Table Type List',
      tableType: 'Table Type',
      parentTableType: 'Parent Table Type',
      tableTypeEnLabel: 'Table Type En Label',
      tableTypeTcLabel: 'Table Type Tc Label',
      tableTypeScLabel: 'Table Type Sc Label',
      isAutoTranslateTableTypeScLabel: 'Table Type Label (Auto Translate TC to SC)',
      tableTypeMobileEnLabel: 'Table Type Mobile En Label',
      tableTypeMobileTcLabel: 'Table Type Mobile Tc Label',
      tableTypeMobileScLabel: 'Table Type Mobile Sc Label',
      isAutoTranslateTableTypeMobileScLabel: 'Table Type Mobile Label (Auto Translate TC to SC)',
      tableTypeMobileBackgroundColor: 'Mobile BackgroundColor',
      tableTypeMobileForegroundColor: 'Mobile Foreground Color',
      applyTableTypeBackgroundColor: 'Apply Default Color Set',
      applyTableTypeMobileBackgroundColor: 'Apply Default Mobile Color Set',
      minSize: 'Min Size',
      maxSize: 'Max Size',
      sizeSelectable: 'Size Selectable',
      ticketType: 'Ticket Type',
      ticketTypeEnLabel: 'Ticket Type En Label',
      ticketTypeTcLabel: 'Ticket Type Tc Label',
      ticketTypeScLabel: 'Ticket Type Sc Label',
      nextRemind: 'Next Remind',
      dailyQuota: 'Daily Quota',
      personalQuota: 'Personal Quota',
      mobileDisplay: 'Mobile Get Ticket Display',
      mobileReadOnly: 'Mobile Read Only',
      mobileTagDisplay: 'Mobile Tag Display',
      kioskDisplay: 'Kiosk Get Ticket Display',
      kioskTagDisplay: 'Kiosk Tag Display',
      kioskReadOnly: 'Kiosk Read Only',
      editTitle: 'Table Type Edit',
      createTitle: 'Table Type Create',
      info: 'Info',
      // Reservation
      groupedSection: 'Grouped Section',
      reservationType: 'Reservation Type',
      autoAcceptFlag: 'AutoAccept Flag',
      autoRejectFlag: 'autoRejectFlag',
      limitMinute: 'Limit Minute',
      reminderMinute: 'Reminder Minute',
      quota: 'Quota',
      cutOffHour: 'Cut Off Hour',
      cutOffMinute: 'Cut Off Minute',
      cutOffTime: 'Cut Off Time',
      startTime: 'Start Time',
      endTime: 'End Time',
      type: 'Type',
      timeSectionDescription: 'Time Section Description',
      isSyncTimeFieldsWithAllTableTypes: 'Sync Time Fields With All Table Types',
      isSyncRequestTicketTimeFieldsWithAllTableTypes: 'Sync Request Ticket Time Fields With All Table Types',
      queueType: 'Queue Type',
      displayType: 'Display Type',
      rollTagType: 'Roll Tag Type',
      tableTypeBackgroundColor: 'Table Type Background Color',
      tableTypeForegroundColor: 'Table Type Foreground Color',
      tableTypeTicketEnLabel: 'Table Type Ticket En Label',
      tableTypeTicketTcLabel: 'Table Type Ticket Tc Label',
      tableTypeTicketScLabel: 'Table Type Ticket Sc Label',
      isAutoTranslateTableTypeTicketScLabel: 'Table Type Ticket Label (Auto Translate TC to SC)',
      groupedSectionEnLabel: 'Grouped Section En Label',
      groupedSectionTcLabel: 'Grouped Section Tc Label',
      groupedSectionScLabel: 'Grouped Section Sc Label',
      isAutoTranslateGroupedSectionLabel: 'Grouped Section Label (Auto Translate TC to SC)',
      groupedSectionEnDescription: 'Grouped Section En Description',
      groupedSectionTcDescription: 'Grouped Section Tc Description',
      groupedSectionScDescription: 'Grouped Section Sc Description',
      isAutoTranslateGroupedSectionDescription: 'Grouped Section Description (Auto Translate TC to SC)',
      displaySequence: 'Display Sequence',
      voiceCode: 'Voice Code',
      kioskRequestTicketStartTime: 'Kiosk Request Ticket Start Time',
      mobileRequestTicketStartTime: 'Mobile Request Ticket Start Time',
      kioskRequestTicketEndTime: 'Kiosk Request Ticket End Time',
      mobileRequestTicketEndTime: 'Mobile Request Ticket End Time',
    },
    groupedSection: {
      editTitle: 'Grouped Section Edit',
      groupedSection: 'Grouped Section',
      groupedSectionEnLabel: 'Grouped Section En Label',
      groupedSectionTcLabel: 'Grouped Section Tc Label',
      groupedSectionScLabel: 'Grouped Section Sc Label',
      isAutoTranslateGroupedSectionLabel: 'Grouped Section Label (Auto Translate TC to SC)',
      groupedSectionEnDescription: 'Grouped Section En Description',
      groupedSectionTcDescription: 'Grouped Section Tc Description',
      groupedSectionScDescription: 'Grouped Section Sc Description',
      isAutoTranslateGroupedSectionDescription: 'Grouped Section Description (Auto Translate TC to SC)',
    },
    TicketType: {
      mainTitle: 'Ticket Type',
      ticketType: 'Ticket Type Code',
      name: 'Name',
      tcName: 'Tc Name',
      enName: 'En Name',
      scName: 'Sc Name',
      status: 'Status',
      editTitle: 'Ticket Type Edit',
      createTitle: 'Ticket Type Create',
      info: 'Info',
      foregroundColor: 'Foreground Color',
      backgroundColor: 'Background Color',
      mobileImage: 'Mobile Image',
      kioskImage: 'Kiosk Image',
      ticketTypeMapList: 'Ticket Type Map List',
    },

    TicketTypeMapList: {
      mainTitle: 'Ticket Type Map List',
      queueTemplateCode: 'Queue Template Code',
      restUrlId: 'Rest Url Id',
      restName: 'Restaurant Name',
      queueTemplateTimeSectionLabel: 'Queue Template Time Section Label',
      queueTemplateTableTypeLabel: 'Queue Template Table Type Label',
      queueTemplateTimeSectionId: 'Queue Template Time Section Id',
      queueTemplateTableType: 'Queue Template Table Type',
    },

    QueueTemplateMap: {
      mainTitle: 'Queue Template Map List',
      type: 'Type',
      addQueueTemplateMap: 'Add Queue Template Map List',
      timeSectionList: 'Time Section List',
      timeSection: 'Time Section',
      templateCode: 'Template Code',
      templateDescription: 'Template Description',
      dayOfWeek: 'Day Of Week',
      description: 'Description',
      startDate: 'Start Date',
      endDate: 'End Date',
      date: 'Date',
      status: 'Status',
      id: 'ID',
      timeSectionId: 'Time Section ID',
      tableType: 'Table Type',
      label: 'Label',
      editTitle: 'Queue Template Map List Edit',
      addQueueTemplate: 'Add Ticket Time Section',
      createTitle: 'Queue Template Map List Create',
      info: 'Info',
      createQueueTemplate: 'Create Ticket Time Section',
      Mon: 'Mon',
      Tue: 'Tue',
      Wed: 'Wed',
      Thu: 'Thu',
      Fri: 'Fri',
      Sat: 'Sat',
      Sun: 'Sun',
      PH: 'PH',
      selectAll: 'Select All',
      clearAll: 'Clear All',
      weekday: 'Weekday',
      holidays: 'Holidays',
      dateRangeListTitle: 'Date Range List',
      dayOfWeekListTitle: 'Day of Week List',
      queueTemplateForToday: "Today's Queue Template",
    },
    QueueRejectReason: {
      mainTitle: 'Queue Reject Reason',
      reasonCode: 'Reason Code',
      reason: 'Reason',
      status: 'Status',
      id: 'id',
      enReason: 'En Reason',
      tcReason: 'Tc Reason',
      scReason: 'Sc Reason',
      info: 'Info',
      reasonCustomize: 'Reason (Auto Translate TC to SC)',
      editTitle: 'Queue Reject Reason Edit',
      createTitle: 'Queue Reject Reason Create',
    },
    ReservationConfig: {
      mainTitle: 'Reservation Config',
      remarksEnMessage: 'Remarks En Message',
      remarksTcMessage: 'Remarks Tc Message',
      remarksScMessage: 'Remarks Sc Message',
      remarksMessageCustomize: 'Remarks Message (Auto Translate TC to SC)',
      disableEnMessage: 'Disable En Message',
      disableTcMessage: 'Disable Tc Message',
      disableScMessage: 'Disable Sc Message',
      disableMessageCustomize: 'Disable Message (Auto Translate TC to SC)',
      reminderEnMessage: 'Reminder En Message',
      reminderTcMessage: 'Reminder Tc Message',
      reminderScMessage: 'Reminder Sc Message',
      reminderMessageCustomize: 'Reminder Message (Auto Translate TC to SC)',
      noTimeSectionEnMessage: 'No Time Section En Message',
      noTimeSectionTcMessage: 'No Time Section Tc Message',
      noTimeSectionScMessage: 'No Time Section Sc Message',
      noTimeSectionMessageCustomize: 'No Time Section Message (Auto Translate TC to SC)',
      afterDay: 'After Day',
      reserveDay: 'Reserve Day',
      timeInterval: 'Time Interval',
      haveConfirm: 'Have Confirm',
      showTableNumber: 'Show Table Number',
      maxConcurrentReservation: 'Max Concurrent Reservation',
      showRemarks: 'Show Remarks',
      haveCharge: 'Have Charge',
      chargeType: 'Charge Type',
      chargeByDefault: 'Charge By Default',
      chargeCode: 'Charge Code',
      defaultCharge: 'Default Charge',
      chargeEnDescription: 'Charge En Description',
      chargeTcDescription: 'Charge Tc Description',
      chargeScDescription: 'Charge Sc Description',
      chargeDescriptionCustomize: 'Charge Description (Auto Translate TC to SC)',
      startTimestamp: 'Start Timestamp',
      expiredTimestamp: 'Expired Timestamp',
      notificationEmail: 'Notification Email',
      sendQrcodeToEmail: 'Send Qrcode To Email',
      reserveStatus: 'Reserve Status',
      emailError: 'Please enter correct email format!',
      defaultChargeError: 'Please enter correct default charge format!',
      message: 'Message',
      charge: 'Charge',
      other: 'Other',
      deliveryType: 'Delivery Type',
      requiredOtp: 'Required OTP',
      mode: 'Mode',
      webShare: 'Web Share',
      editable: 'Editable',
      checkInBeforeMinute: 'Check In Before Minute',
      checkInLateMinute: 'Check In Late Minute',
      webMultiReserve: 'Web Multi Reserve',
      groupedSectionLimit: 'Grouped Section Limit',
    },
    ReservationRejectReason: {
      mainTitle: 'Reservation Reject Reason',
      reasonCode: 'Reason Code',
      reason: 'Reason',
      status: 'Status',
      id: 'id',
      enReason: 'En Reason',
      tcReason: 'Tc Reason',
      scReason: 'Sc Reason',
      info: 'Info',
      reasonCustomize: 'Reason (Auto Translate TC to SC)',
      editTitle: 'Reservation Reject Reason Edit',
      createTitle: 'Reservation Reject Reason Create',
    },
    ReservationTemplate: {
      mainTitle: 'Reservation Template',
      timeSectionList: 'Time Section List',
      timeSection: 'Time Section',
      templateCode: 'Template Code',
      description: 'Description',
      inUse: 'In Use',
      status: 'Status',
      effectiveDate: 'Effective Date',
      id: 'ID',
      timeSectionId: 'Time Section ID',
      tableType: 'Table Type',
      label: 'Label',
      addRestaurant: 'Add Restaurant List',
      addReservationTemplateMap: 'Add Reservation Template Map',
      resetCurrentTimeSection: 'Reset Current Time Section',
      editTitle: 'Reservation Template Edit',
      addReservationTemplate: 'Add Ticket Time Section',
      createTitle: 'Reservation Template Create',
      info: 'Info',
      createReservationTemplate: 'Create Ticket Time Section',
      cloneReservationTemplate: 'Clone Reservation Template: ',
      applyReservationTemplate: 'Apply Reservation Template: ',
    },

    ReservationTemplateMap: {
      mainTitle: 'Reservation Template Map List',
      type: 'Type',
      addReservationTemplateMap: 'Add Reservation Template Map List',
      timeSectionList: 'Time Section List',
      timeSection: 'Time Section',
      templateCode: 'Template Code',
      templateDescription: 'Template Description',
      dayOfWeek: 'Day Of Week',
      description: 'Description',
      startDate: 'Start Date',
      endDate: 'End Date',
      date: 'Date',
      status: 'Status',
      id: 'ID',
      timeSectionId: 'Time Section ID',
      tableType: 'Table Type',
      label: 'Label',
      editTitle: 'Reservation Template Map List Edit',
      addReservationTemplate: 'Add Ticket Time Section',
      createTitle: 'Reservation Template Map List Create',
      info: 'Info',
      createReservationTemplate: 'Create Ticket Time Section',
    },

    ChargeDate: {
      mainTitle: 'Charge Date',
      chargeCode: 'Charge Code',
      date: 'Date',
      addChargeDate: 'Add Charge Date',
      startDate: 'Start Date',
      endDate: 'End Date',
      id: 'ID',
      editTitle: 'Charge Date Map Edit',
      createTitle: 'Charge Date Map Create',
      info: 'Info',
    },

    AdminDevice: {
      mainTitle: 'Admin Device',
      troubleShooting: 'Trouble Shooting',
      //AdminDeviceList
      deviceId: 'Device ID',
      devicenName: 'Device Name',
      activationCode: 'Activation Code',
      deviceType: 'Device Type',
      activationStatus: 'Activation Status',
      deactivate: 'Deactivate',
      deactivateSuccess: 'Deactivate successful!',
      adminDeviceListTitle: 'Admin Device List:',
      addAdminDeviceTitle: 'Add Admin Device:',
      //AdminDeviceAdd
      kioskLabel: '2 (Kiosk)',
      prLabel: '3 (PR)',
      tvLabel: '5 (TV)',
      backList: 'Back to Restaurant List',
      //AdminDevice Edit
      info: 'Info',
      editTitle: 'Admin Device Edit',
      reportStatus: 'Report',
      takeawayStatus: 'Takeaways',
      queueStatus: 'Queue',
      preorderStatus: 'Pre Order',
      tagStatus: 'Tag',
      reservationStatus: 'Reserve',
      banquetStatus: 'Banquet',
      ecouponStatus: 'Ecoupon',
      menuStatus: 'Menu',
      ecouponDistributeStatus: 'Ecoupon Distribute',
      createTitle: 'Admin Device Create',
      confirmDeactivate: 'Confirm to deactivate the Admin Device?',
    },

    CampaignDescription: {
      mainTitle: 'Display Message',
      //CampaignDescriptionList
      type: 'Type',
      messageCode: 'Message Code',
      title: 'Title',
      enTitle: 'En Title',
      tcTitle: 'Tc Title',
      scTitle: 'Sc Title',
      sequence: 'Sequence',
      status: 'Status',
      message: 'Message',
      tcMessage: 'Tc Message',
      enMessage: 'En Message',
      scMessage: 'Sc Message',
      titleCustomize: 'Title (Auto Translate TC to SC)',
      messageCustomize: 'Message (Auto Translate TC to SC)',
      restUrlId: 'Rest Url ID',
      info: 'Info',
      editTitle: 'Display Message Edit',
      createTitle: 'Display Message Create',
    },

    TnC: {
      mainTitle: 'Terms & Conditions',
      //Terms & ConditionsList
      type: 'Type',
      enDescription: 'En Description',
      tcDescription: 'Tc Description',
      scDescription: 'Sc Description',
      status: 'Status',
      descriptionCustomize: 'Description (Auto Translate TC to SC)',
      restUrlId: 'Rest Url ID',
      info: 'Info',
      editTitle: 'Terms & Conditions Edit',
      createTitle: 'Terms & Conditions Create',
      filterKey: 'Filter Key',
    },

    EmailTemplate: {
      mainTitle: 'Email Template',
      //EmailTemplateList
      restUrlId: 'Rest Url Id',
      type: 'Type',
      receiver: 'Receiver',
      subject: 'Subject',
      enSubject: 'EN Subject',
      tcSubject: 'TC Subject',
      scSubject: 'SC Subject',
      subjectCustomize: 'Subject (Auto Translate TC to SC)',
      status: 'Status',
      htmlContent: 'HTML Content',
      htmlEnContent: 'HTML EN Content',
      htmlTcContent: 'HTML TC Content',
      htmlScContent: 'HTML SC Content',
      htmlContentCustomize: 'HTML Content (Auto Translate TC to SC)',
      plainTextContent: 'Plain Text Content',
      plainTextEnContent: 'Plain Text EN Content',
      plainTextTcContent: 'Plain Text TC Content',
      plainTextScContent: 'Plain Text SC Content',
      plainTextContentCustomize: 'Plain Text Content (Auto Translate TC to SC)',
      addReceiver: 'Add Receiver',
      removeReceiver: 'Remove Receiver',
      info: 'Info',
      editTitle: 'Email Template Edit',
      createTitle: 'Email Template Create',
    },

    PrintTemplate: {
      mainTitle: 'Print Template',
      //EmailTemplateList
      id: 'Id',
      code: 'Code',
      type: 'Type',
      status: 'Status',
      templateLabels: 'Template Labels',
      template: 'Template',
      value: 'Value',
      addTemplate: 'Add Template',
      addText: 'Add Text',
      addDateFormat: 'Add Date Format',
      addTemplateLabel: 'Add Template Label',
      labelTitle: 'Label Title',
      labelEn: 'En Label',
      labelTc: 'TC Label',
      labelSc: 'Sc Label',
      year: 'Year',
      month: 'Month',
      date: 'Date',
      hour: 'Hour',
      minute: 'Minute',
      seconds: 'Seconds',
      timeFormat: 'Time Format',
      time: 'Time',
      week: 'Week',
      middleSymbol: 'Middle Symbol',
      withBracket: 'With Bracket',
      info: 'Info',
      editTitle: 'Print Template Edit',
      createTitle: 'Print Template Create',
      addLabel: 'Add Label',
    },

    restaurant_map: {
      mainTitle: 'Restaurant Map List',
      //restaurant_map
      tagCode: 'Tag Code',
      name: 'Restaurant Name',
      restName: 'Restaurant Name',
      restUrlId: 'Rest Url ID',
      status: 'Restaurant Map Status',
      enName: 'Restaurant En Name',
      tcName: 'Restaurant Tc Name',
      scName: 'Restaurant Sc Name',
      orderSequence: 'Order Sequence',
      restaurantMapList: 'Restaurant Map List',
      addRestaurantMap: 'Add Restaurant Map List',
      restEnName: 'Restaurant En Name',
      restTcName: 'Restaurant Tc Name',
      restScName: 'Restaurant Sc Name',
      startTimestamp: 'Start Timestamp',
      endTimestamp: 'End Timestamp',
      sequence: 'Sequence',
      nameCustomize: 'Name (Auto Translate TC to SC)',
      tcPictures: 'Tc Pictures',
      enPictures: 'En Pictures',
      scPictures: 'Sc Pictures',
      editTitle: 'Restaurant Map List Edit',
      info: 'Info',
      createTitle: 'Restaurant Map List Create',
    },

    restaurant_category: {
      mainTitle: 'Category',
      //restaurantCategoryList
      tagCode: 'Tag Code',
      name: 'Name',
      restName: 'Restaurant Name',
      restUrlId: 'Rest Url ID',
      status: 'Status',
      enName: 'En Name',
      tcName: 'Tc Name',
      scName: 'Sc Name',
      orderSequence: 'Order Sequence',
      restaurantMapList: 'Restaurant Map List',
      addRestaurantMap: 'Add Restaurant Map List',
      restEnName: 'Rest En Name',
      restTcName: 'Rest Tc Name',
      restScName: 'Rest Sc Name',
      startTimestamp: 'Start Timestamp',
      endTimestamp: 'End Timestamp',
      sequence: 'Sequence',
      nameCustomize: 'Name (Auto Translate TC to SC)',
      tcPictures: 'Tc Pictures',
      enPictures: 'En Pictures',
      scPictures: 'Sc Pictures',
      editTitle: 'Category Edit',
      info: 'Info',
      createTitle: 'Category Create',
    },

    restaurant_cuisine: {
      mainTitle: 'Cuisine',
      //restaurantCuisineList
      tagCode: 'Tag Code',
      name: 'Name',
      restName: 'Restaurant Name',
      restUrlId: 'Rest Url ID',
      status: 'Status',
      enName: 'En Name',
      tcName: 'Tc Name',
      scName: 'Sc Name',
      orderSequence: 'Order Sequence',
      restaurantMapList: 'Restaurant Map List',
      addRestaurantMap: 'Add Restaurant Map List',
      restEnName: 'Rest En Name',
      restTcName: 'Rest Tc Name',
      restScName: 'Rest Sc Name',
      startTimestamp: 'Start Timestamp',
      endTimestamp: 'End Timestamp',
      sequence: 'Sequence',
      nameCustomize: 'Name (Auto Translate TC to SC)',
      tcPictures: 'Tc Pictures',
      enPictures: 'En Pictures',
      scPictures: 'Sc Pictures',
      editTitle: 'Cuisine Edit',
      info: 'Info',
      createTitle: 'Cuisine Create',
    },

    restaurant_keyword: {
      mainTitle: 'Keyword',
      //restaurantKeywordList
      tagCode: 'Tag Code',
      name: 'Name',
      restName: 'Restaurant Name',
      restUrlId: 'Rest Url ID',
      status: 'Status',
      enName: 'En Name',
      tcName: 'Tc Name',
      scName: 'Sc Name',
      orderSequence: 'Order Sequence',
      restaurantMapList: 'Restaurant Map List',
      addRestaurantMap: 'Add Restaurant Map List',
      restEnName: 'Rest En Name',
      restTcName: 'Rest Tc Name',
      restScName: 'Rest Sc Name',
      startTimestamp: 'Start Timestamp',
      endTimestamp: 'End Timestamp',
      sequence: 'Sequence',
      nameCustomize: 'Name (Auto Translate TC to SC)',
      tcPictures: 'Tc Pictures',
      enPictures: 'En Pictures',
      scPictures: 'Sc Pictures',
      editTitle: 'Keyword Edit',
      info: 'Info',
      createTitle: 'Keyword Create',
    },

    BrandGroup: {
      mainTitle: 'Group',
      groupId: 'Group ID',
      enName: 'En Group Name',
      tcName: 'Tc Group Name',
      scName: 'Sc Group Name',
      status: 'Status',
      update_timestamp: 'Update Timestamp',
      //BrandGroupList
      BrandGroupListTitle: 'Group List:',
      addBrandGroupTitle: 'Add Group:',
      group_name: 'Group Name',
      //BrandGroup Edit
      editTitle: 'Group Edit',
      nameCustomize: 'Name (Auto Translate TC to SC)',
      createTitle: 'Group Create',
      info: 'Info',
    },

    Brand: {
      mainTitle: 'Brand',
      brandCode: 'Brand Code',
      enName: 'En Brand Name',
      tcName: 'Tc Brand Name',
      scName: 'Sc Brand Name',
      enDescription: 'En Description',
      tcDescription: 'Tc Description',
      scDescription: 'Sc Description',
      descriptionCustomize: 'Description (Auto Translate TC to SC)',
      groupId: 'Group ID',
      groupEnName: 'En Group Name',
      groupTcName: 'Tc Group Name',
      groupScName: 'Sc Group Name',
      logoImage: 'Logo Image',
      uploadImage: 'Upload Image',
      imageList: 'Image List',
      type: 'Type',
      cosFileUrl: 'Media',
      fileType: 'File Type',
      displaySequence: 'Display Sequence',
      status: 'Status',
      update_timestamp: 'Update Timestamp',
      //BrandList
      BrandListTitle: 'Brand List:',
      addBrandTitle: 'Add Brand:',
      brand_name: 'Brand Name',
      //Brand Edit
      editTitle: 'Brand Edit',
      nameCustomize: 'Name (Auto Translate TC to SC)',
      createTitle: 'Brand Create',
      info: 'Info',
      restaurantMap: 'Restaurant Map List',
    },

    BrandProduct: {
      mainTitle: 'Brand Product',
      productCode: 'Product Code',
      type: 'Type',
      name: 'Name',
      channel: 'Channel',
      merchantProduct: 'Merchant Product',
      status: 'Status',
    },

    Folder: {
      mainTitle: 'Folder',
      folderId: 'Folder Id',
      folderCode: 'Folder Code',
      type: 'Folder Type',
      name: 'Folder Name',
      enName: 'Folder En Name',
      tcName: 'Folder Tc Name',
      scName: 'Folder Sc Name',
      enDescriptionHtml: 'En Description Html',
      tcDescriptionHtml: 'Tc Description Html',
      scDescriptionHtml: 'Sc Description Html',
      descriptionCustomize: 'Description (Auto Translate TC to SC)',
      tcImage: 'Tc Image',
      enImage: 'En Image',
      scImage: 'Sc Image',
      status: 'Folder Status',
      editTitle: 'Folder Edit',
      folderMapList: 'Folder Map List',
      bannerList: 'Banner List',
      folderBannerList: 'Folder Banner List',
      landmarkOrder: 'Landmark Order',
      createTitle: 'Folder Create',
      info: 'Info',
      addFolderMap: 'Add Folder',
      showHidden: 'Show Hidden',
    },

    FolderMap: {
      mainTitle: 'Folder Map List',
      folderId: 'Folder Map Id',
      refCode: 'Folder Map Ref Code',
      type: 'Folder Map Type',
      name: 'Folder/Restaurant Name',
      refEnName: 'Folder/Restaurant En Name',
      refTcName: 'Folder/Restaurant Tc Name',
      refScName: 'Folder/Restaurant Sc Name',
      sequence: 'Sequence',
      status: 'Folder Map Status',
      editTitle: 'Folder Map List Edit',
      createTitle: 'Folder Map List Create',
      info: 'Info',
    },

    FolderBanner: {
      mainTitle: 'Banner',
      id: 'ID',
      refCode: 'Ref Code',
      tcImage: 'Banner Tc Image',
      enImage: 'Banner En Image',
      scImage: 'Banner Sc Image',
      enName: 'En Name',
      tcName: 'Tc Name',
      scName: 'Sc Name',
      name: 'Banner Name',
      type: 'Banner Type',
      webViewType: 'Type',
      actionType: 'Action Type',
      action: 'Action',
      productCode: 'Product Code',
      folderCode: 'Folder Code',
      folderType: 'Folder Type',
      restUrlId: 'Rest Url Id',
      actionParameters: 'Action Parameters',
      addParameter: 'Add Parameter',
      otherParameterName: 'Other Parameter Name',
      otherParameterValue: 'Other Parameter Value',
      startDisplayDate: 'Start Display Date',
      endDisplayDate: 'End Display Date',
      sequence: 'Sequence',
      status: 'Banner Status',
      pixelName: 'Pixel Name',
      haveRef: 'Pixel Have Ref',
      imageError: '**Banners size are not equal!**',
      from: 'From',
      title: 'Title',
      query: 'Query',
      editTitle: 'Banner Edit',
      createTitle: 'Banner Create',
      info: 'Info',
      addFolderBanner: 'Add Folder Banner',
    },

    Landing: {
      mainTitle: 'Landing',
      landingBannerList: 'Landing Banner List',
    },

    Ticket: {
      mainTitle: 'Ticket',
      ticketBannerList: 'Ticket Banner List',
    },

    Slideshow: {
      mainTitle: 'Slideshow',
      all_slideshow: 'Slideshow',
      slideshow_list: 'All Slideshow',
    },

    ConfigMap: {
      mainTitle: 'Config Map List',
      serviceType: 'Service Type',
      ticketStatus: 'Ticket Status',
      restUrlId: 'Rest Url Id',
      clearRestUrlId: 'Clear Rest Url Id',
      restName: 'Restaurant Name',
      restTcName: 'Restaurant Tc Name',
      restEnName: 'Restaurant En Name',
      restScName: 'Restaurant Sc Name',
      channel: 'Channel',
      actionType: 'Action Type',
      startDisplayDate: 'Start Display Date',
      endDisplayDate: 'End Display Date',
      status: 'Status',
      editTitle: 'Config Map Edit',
      configMapList: 'Config Map List',
      createTitle: 'Config Map List Create ',
      addConfigMap: 'Add Config Map',
      info: 'Info',
      addRestaurant: 'Add Restaurant',
    },

    Banner: {
      mainTitle: 'Banner',
      id: 'ID',
      bannerId: 'Banner Id',
      refCode: 'Ref Code',
      tcImage: 'Banner Tc Image',
      enImage: 'Banner En Image',
      scImage: 'Banner Sc Image',
      enName: 'En Name',
      tcName: 'Tc Name',
      scName: 'Sc Name',
      name: 'Name',
      type: 'Banner Type',
      webViewType: 'Type',
      actionType: 'Action Type',
      action: 'Action',
      productCode: 'Product Code',
      categoryCode: 'Category Code',
      brandCode: 'Brand Code',
      folderCode: 'Folder Code',
      folderType: 'Folder Type',
      restUrlId: 'Rest Url Id',
      actionParameters: 'Action Parameters',
      addParameter: 'Add Parameter',
      otherParameterName: 'Other Parameter Name',
      otherParameterValue: 'Other Parameter Value',
      startDisplayDate: 'Start Display Date',
      endDisplayDate: 'End Display Date',
      sequence: 'Sequence',
      status: 'Banner Status',
      pixelName: 'Pixel Name',
      haveRef: 'Pixel Have Ref',
      imageError: '**Banners size are not equal!**',
      from: 'From',
      title: 'Title',
      query: 'Query',
      editTitle: 'Banner Edit',
      createTitle: 'Banner Create',
      info: 'Info',
      addBanner: 'Add Banner',
      productParent: 'Product Brand/ Category',
      eCouponParent: 'E-Coupon Brand/ Category',
      ecouponCode: 'E-Coupon ',
      deactivate: 'Delete',
      pending: 'Pending',
      active: 'Active',
    },

    MerchantConfig: {
      mainTitle: 'Merchant Config',
      queue: 'Queue',
      queueRemindResetQuota: 'Queue Remind Reset Quota',
      queueChannelStatusControl: 'Queue Channel Status Control',
      queueQuickCheckIn: 'Queue Quick Check In',
      product: 'Product',
      publishProduct: 'Publish Product',
      createProduct: 'Create Product',
      sendProduct: 'Send Product',
      counterCallEnable: 'Counter Call Enable',
      queueRejectTicketEnable: 'Queue Reject Ticket Enable',
      queueStatusPerQueueControl: 'Queue Status Per Queue Control',
      queueMultipleStatusControl: 'Queue Multiple Status Control',
      queueDisplayControl: 'Queue Display Control',
      slideshow: 'Slideshow',
      guluSlideshowEnable: 'Gulu Slideshow Enable',
      device: 'Device',
      customPassword: 'Custom Password',
      shopAppSoundSpeed: 'Shop App Sound Speed',
      voiceCode: 'Voice Code',
      clearSoundFiles: 'Clear Sound Files',
      preorderProduct: 'Pre-order Product',
    },
    MerchantDevice: {
      mainTitle: 'Device',
      deviceType: 'Device Type',
      deviceName: 'Device Name',
      appVersion: 'App Version',
      activationStatus: 'Activation Status',
      info: 'Info',
      editTitle: 'Device Edit',
      appId: 'App ID',
      deviceIp: 'Device IP',
      deviceModel: 'Device Model',
      manufacturer: 'Manufacturer',
      os: 'OS',
      osId: 'OS ID',
      pushNotificationToken: 'Push Notification Token',
      deviceLog: 'Device Log',
    },
    DeviceLog: {
      mainTitle: 'Device Log',
      uploadTimestamp: 'Upload Timestamp',
      appVersion: 'App Version',
      osVersion: 'OS Version',
      status: 'Status',
      fileUrl: 'File URL',
    },
    QueueProfile: {
      mainTitle: 'Queue Profile',
      profileCode: 'Profile Code',
      tcName: 'Tc name',
      enName: 'En name',
      scName: 'Sc name',
      name: 'name',
      createTitle: 'Queue Profile Create',
      refTemplateCode: 'Template Code',
      timeSectionId: 'Time Section Id',
      tableType: 'Table Type',
      timeSectionList: 'Time Section List',
      status: 'Status',
      editTitle: 'Queue Profile Edit',
    },

    QueueTransferProfile: {
      mainTitle: 'Queue Transfer Profile',
      profileCode: 'Profile Code',
      tcName: 'Tc name',
      enName: 'En name',
      scName: 'Sc name',
      name: 'name',
      createTitle: 'Queue Transfer Profile Create',
      refTemplateCode: 'Template Code',
      timeSectionId: 'Time Section Id',
      tableType: 'Table Type',
      timeSectionList: 'Time Section List',
      status: 'Status',
      editTitle: 'Queue Transfer Profile Edit',
    },

    SoundTemplate: {
      mainTitle: 'Sound Template',
      soundTemplateCode: 'Sound Template Code',
      name: 'Name',
      createTitle: 'Sound Template Create',
      editTitle: 'Sound Template Edit',
      status: 'Status',
      restUrlId: 'Rest Url Id',
      code: 'Code',
    },

    Counter: {
      mainTitle: 'Counter',
      createTitle: 'Counter Create',
      editTitle: 'Counter Edit',
      name: 'Name',
      soundTemplateCode: 'Sound Template Code',
      status: 'Status',
      soundTemplateCodeRestUrlId: 'Sound Template Code Rest Url Id',
      enDescription: 'En Description',
      tcDescription: 'Tc Description',
      scDescription: 'Sc Description',
    },

    CounterProfile: {
      mainTitle: 'Counter Profile',
      profileCode: 'Profile Code',
      tcName: 'Tc name',
      enName: 'En name',
      scName: 'Sc name',
      name: 'name',
      createTitle: 'Counter Profile Create',
      refTemplateCode: 'Template Code',
      timeSectionId: 'Time Section Id',
      tableType: 'Table Type',
      timeSectionList: 'Time Section List',
      status: 'Status',
      editTitle: 'Counter Profile Edit',
      refCounterList: 'Ref Counter List',
      counterSequence: 'Counter Sequence',
      selectCounter: 'Select Counter',
      addCounter: 'Add Counter',
    },

    MerchantUser: {
      mainTitle: 'Merchant User',
      userRole: 'User Role',
      username: 'Username',
      password: 'Password',
      newPassword: 'New Password',
      email: 'Email',
      channel: 'Channel',
      enabled: 'Enabled',
      enableMultiLogin: 'Enable Multi Login',
      restUrlIdList: 'RestUrlId List',
      permission: 'Permission',
      reportPermission: 'Report',
      deviceManagementPermission: 'Device Management',
      featurePermission: 'Feature',
      restaurantManagementPermission: 'Restaurant Management',
      userGroupManagementPermission: 'User Group Management',
      read: 'Read',
      write: 'Write',
      editTitle: 'Merchant User Edit',
      info: 'Info',
      createTitle: 'Merchant User Create',
      emailError: 'Please enter correct email format!',
      usernameUpperCaseError: 'Upper case letter is not support!',
      usernameLengthError: 'Username length should be smaller than 45 letter!',
      privilegeAttribute: 'Privilege Attribute',
      privilegeRestUrlId: 'Privilege Rest Url Id',
      privilegeGroupCode: 'Privilege Group Code',
      merchantRole: 'Merchant Role',
      changeUsername: 'Change Username',
    },

    MerchantSalesUser: {
      mainTitle: 'Merchant Sales User',
      username: 'Username',
      password: 'Password',
      info: 'Info',
      createTitle: 'Merchant Sales User Create',
      usernameUpperCaseError: 'Upper case letter is not support!',
      usernameLengthError: 'Username length should be smaller than 45 letter!',
    },

    MerchantDraft: {
      mainTitle: 'Merchant Create',
      //merchantDraftList
      draftName: 'Draft Name',
      status: 'Status',
      draftVersion: 'Draft Version',
      name: 'Name',
      enName: 'En Name',
      tcName: 'Tc Name',
      scName: 'Sc Name',
      nameCustomize: 'Name (Auto Translate TC to SC)',
      tcAddress: 'Tc Address',
      enAddress: 'En Address',
      scAddress: 'Sc Address',
      addressCustomize: 'Address (Auto Translate TC to SC)',
      phoneAddress: 'Phone And Address',
      phone: 'Phone',
      shopLogo: 'Logo Image',
      shopFrontImage: 'Shop Front Image',
      shopFrontImageList: 'Shop Front Image List',
      br: 'BR',
      planCode: 'Plan Code',
      servicePlan: 'Service Plan',
      shopInfo: 'Shop Info',
      image: 'Image',
      uploadImage: 'Upload Image',
      enterDraftName: 'Enter Draft Name',
      merchantUserList: 'Merchant User List',
      userGroupCode: 'User Group Code',
      userCreate: 'User Create',
      addUser: 'Add User',
      id: 'ID',
      userRole: 'User Role',
      merchantRole: 'Merchant Role',
    },

    Area: {
      mainTitle: 'Area',
      areaId: 'Area Id',
      name: 'Area Name',
      enName: 'Area En Name',
      tcName: 'Area Tc Name',
      scName: 'Area Sc Name',
      status: 'Area Status',
      editTitle: 'Area Edit',
      info: 'Info',
      districtList: 'District List',
      landmarkOrder: 'Landmark Order',
      createTitle: 'Area Create',
    },

    RestaurantDistrict: {
      mainTitle: 'District',
      hk: 'Hong Kong Island (1)',
      kln: 'Kowloon (2)',
      nt: 'New Territories (3)',
      island: 'Outlying Islands (4)',
      area: 'Area',
      areaId: 'Area Id',
      name: 'District Name',
      status: 'District Status',
      districtStatus: 'District Status',
      districtId: 'District ID',
      enName: 'District En Name',
      tcName: 'District Tc Name',
      scName: 'District Sc Name',
      districtTcName: 'District Tc Name',
      districtEnName: 'District En Name',
      districtScName: 'District Sc Name',
      areaTcName: 'Area Tc Name',
      areaEnName: 'Area En Name',
      areaScName: 'Area Sc Name',
      addDistrictMap: 'Add District Map List',
      //RestaurantDistrictList
      RestaurantDistrictListTitle: 'Restaurant District List:',
      addRestaurantDistrictTitle: 'Add Restaurant District:',
      district_name: 'District Name',
      //RestaurantDistrict Edit
      editTitle: 'District Edit',
      info: 'Info',
      restaurantMapList: 'Restaurant Map List',
      landMarkMapList: 'LandMark Map List',
      districtLevel: 'District Level',
      menuChiOrder: 'Menu Chi Order',
      latitude: 'Latitude',
      longitude: 'Longitude',
      nameCustomize: 'Name (Auto Translate TC to SC)',
      createTitle: 'District Create',
    },

    //RestaurantDistrictRestMap
    RestaurantDistrictRestMap: {
      mainTitle: 'Restaurant District Restaurant Map List',
      restUrlId: 'Rest Url ID',
      restRefId: 'Ref ID',
      name: 'Restaurant Name',
      restTcName: 'Restaurant Tc Name',
      restEnName: 'Restaurant En Name',
      restScName: 'Restaurant Sc Name',
      address: 'Restaurant Address',
      restStatus: 'Restaurant Status',
    },

    //RestaurantLandmark
    RestaurantLandmark: {
      mainTitle: 'Landmark',
      landmarkId: 'Landmark Id',
      tcName: 'Landmark Tc Name',
      enName: 'Landmark En Name',
      scName: 'Landmark Sc Name',
      landmarkTcName: 'Landmark Tc Name',
      landmarkEnName: 'Landmark En Name',
      landmarkScName: 'Landmark Sc Name',
      name: 'Landmark Name',
      status: 'Landmark Status',
      districtList: 'District List',
      restaurantList: 'Restaurant List',
      editTitle: 'Landmark Edit',
      info: 'Info',
      landmarkOrder: 'Landmark Order',
      favorite: 'Favorite',
      createTitle: 'Landmark Create',
    },

    //RestaurantDistrictMap
    RestaurantDistrictMap: {
      mainTitle: 'District Map List',
      districtId: 'District ID',
      enName: 'District En Name',
      tcName: 'District Tc Name',
      scName: 'District Sc Name',
      districtstatus: 'District Status',
      addDistrictMap: 'Add District Map List',
      status: 'Status',
      //RestaurantDistrict Edit
      editTitle: 'District Map List Edit',
      info: 'Info',
      createTitle: 'District Map List Create',
    },

    //UploadImage
    UploadImage: {
      mainTitle: 'Upload Image',
      imageAdd: 'Add Image',
      image: 'Image',
      recordId: 'Record ID',
      tablenName: 'Table Name',
      fieldName: 'Field Name',
      pressUploadImage: 'Press to Upload Image',
      relatedPictures: 'Related pictures',
      dropFile: 'Drop your file here',
      Adv: 'Advance',
    },

    //push
    Push: {
      mainTitle: 'Push',
      name: 'Name',
      messageTitle: 'Message Title',
      message: 'Message',
      messageTitleCustomize: 'Message Title (Auto Translate TC to SC)',
      messageCustomize: 'Message (Auto Translate TC to SC)',
      tcMessageTitle: 'Tc Message Title',
      enMessageTitle: 'En Message Title',
      scMessageTitle: 'Sc Message Title',
      tcMessage: 'Tc Message',
      enMessage: 'En Message',
      scMessage: 'Sc Message',
      type: 'Type',
      actionType: 'Action Type',
      actionParameters: 'Action Parameters',
      addParameter: 'Add Parameter',
      otherParameterName: 'Other Parameter Name',
      otherParameterValue: 'Other Parameter Value',
      filterParameters: 'Filter Parameters',
      startDeliveryDate: 'Start Delivery Date',
      endDeliveryDate: 'End Delivery Date',
      deliveryDate: 'Delivery Date',
      deliveryDateWarning: 'As Delivery Date is earlier than Today Date, delivery will be set as now. Do you still confirm to publish?',
      messageGen: 'Message Loading',
      sendWarning: 'Should set one of the field as true!',
      notification: 'Send to Notification',
      inbox: 'Send to Inbox',
      enterMessageContent: 'Behaviour',
      messageContent: 'Behaviour',
      enterParameter: 'Parameter',
      query: 'Query',
      gender: 'Gender',
      female: 'Female',
      male: 'Male',
      all: 'ALL',
      os: 'OS',
      iOS: 'iOS',
      Android: 'Android',
      GMS: 'GMS (Google)',
      HMS: 'HMS (Huawei)',
      ageGroupList: 'Age',
      addMobile: 'Add Mobile',
      mobileList: 'Mobile',
      enterservice: 'Type',
      ticket: 'Ticket',
      takeaway: 'Takeaway',
      reservation: 'Reservation',
      social_media: 'Social Media',
      start_date: 'Start Date',
      end_date: 'End Date',
      consume: 'Consume',
      times: 'Times',
      Header: 'Information',
      parameterHeader: 'Condition',
      parameter: 'Parameter',
      promotion: 'Promotion',
      webView: 'WebView',
      deepLink: 'DeepLink',
      AdsCode: 'Ads Code',
      website: 'Website',
      restaurant: 'Restaurant',
      search: 'Search Page',
      brandCode: 'Brand Code',
      folderEngName: 'Folder En Name',
      folderTCName: 'Folder Tc Name',
      folder: 'Folder',
      folderName: 'Folder Name',
      folderCode: 'Folder Code',
      folderType: 'Folder Type',
      restUrlId: 'Rest Url Id',
      pixelName: 'Pixel Name',
      haveRef: 'Pixel Have Ref',
      categoryCode: 'Category Code',
      productCat: 'Product Category',
      productCatName: 'Product Category Name',
      productCatEngName: 'Product Category En Name',
      productCatTCName: 'Product Category Tc Name',
      productCatCode: 'Product Category Code',
      productDetail: 'Product Detail',
      productDetailName: 'Product Detail Name',
      productDetailEngName: 'Product Detail En Name',
      productDetailTCName: 'Product Detail Tc Name',
      productCode: 'Product Detail Code',
      ECoupon: 'ECoupon',
      ECouponTitle: 'ECoupon Title',
      ECouponEngTitle: 'ECoupon En Title',
      ECouponTCTitle: 'ECoupon Tc Title',
      ECouponCode: 'ECoupon Code',
      from: 'From',
      action: 'Action',
      systemMessage: 'System Message',
      status: 'Status',
      testing: 'Testing',
      draft: 'Draft',
      scheduled: 'Scheduled',
      sending: 'Sending',
      finished: 'Finished',
      sql: 'SQL',
      sqlButton: 'Preview',
      Adv: 'Advance',
      total: 'Total',
      title: 'Title',
      editTitle: 'Push Edit',
      ClearCat: 'Clear Product Category',
      ClearDetail: 'Clear Product Detail',
      createTitle: 'Push Create',
      info: 'Info',
      addBanner: 'Add Banner',
      productParent: 'Product Brand/ Category',
      eCouponParent: 'E-Coupon Brand/ Category',
      ecouponCode: 'E-Coupon ',
    },

    //Select Tag
    SelectTag: {
      mainTitle: 'Select Tag',
      name: 'Name',
      messageTitle: 'Message Title',
      message: 'Message',
      messageTitleCustomize: 'Message Title (Auto Translate TC to SC)',
      messageCustomize: 'Message (Auto Translate TC to SC)',
      tcMessageTitle: 'Tc Message Title',
      enMessageTitle: 'En Message Title',
      scMessageTitle: 'Sc Message Title',
      tcMessage: 'Tc Message',
      enMessage: 'En Message',
      scMessage: 'Sc Message',
      tag_code: 'Tag Code',
      select_tag_name: 'Select Tag Name',
      enName: 'En Name',
      tcName: 'Tc Name',
      scName: 'Sc Name',
      editTitle: 'Select Tag Edit',
      createTitle: 'Select Tag Create',
      info: 'Info',
      tag_name: 'Tag Name',
      tagCode: 'Tag Code',
      addSelectTag: 'Add Select Tag',
      type: 'Type',
      status: 'Status',
    },

    SelectTagConfig: {
      mainTitle: 'Select Tag Config',
      createTitle: 'Select Tag Create',
      editTitle: 'Select Tag Edit',
      tag_name: 'Tag Name',
      tagEnName: 'En Name',
      tagTcName: 'Tc Name',
      tagScName: 'Sc Name',
      tagCode: 'Tag Code',
      status: 'Status',
      tag_code: 'Tag Code',
      select_tag_name: 'Select Tag Name',
      sequence: 'Sequence',
      restUrlId: 'Rest Url ID',
      id: 'ID',
      type: 'Type',
      info: 'Info',
      addSelectTag: 'Add Select Tag',
    },

    //LandingSection
    LandingSection: {
      mainTitle: 'Landing Section Edit',
      editTitle: 'Landing Section Edit',
      createTitle: 'Landing Section Create',
      title: 'title',
      info: 'Info',
      serviceType: 'Service Type',
      mainSection: 'Main Section',
      sectionType: 'Section Type',
      tcTitle: 'Tc Title',
      enTitle: 'En Title',
      scTitle: 'Sc Title',
      titleColor: 'Title Color',
      startDisplayTimestamp: 'Start Display Time',
      endDisplayTimestamp: 'End Display Time',
      sequence: 'Sequence',
      status: 'Status',
      action: 'Action Type',
      displayActionType: 'Action Type',
      actionParameters: 'Action Parameters',
      itemType: 'Item Type',
      tcSubTitle: 'Tc Sub Title',
      enSubTitle: 'En Sub Title',
      scSubTitle: 'Sc Sub Title',
      serviceTypeList: 'Service Type List',
      foregroundColor: 'Foreground Color',
      backgroundColor: 'Background Color',
      addItem: 'Add Item',
      addSection: 'Add Section',
      url: 'URL',
      addParameter: 'Add Parameter',
      type: 'Type',
      query: 'Query',
      name: 'Name',
      folderType: 'Folder Type',
      brandCode: 'Brand Code',
      categoryCode: 'Category Code',
      productParent: 'Product Parent',
      ecouponCode: 'Ecoupon Code',
      couponCode: 'Coupon Code',
      folderCode: 'Folder Code',
      showCategoryList: 'Show Category List',
      productCode: 'Product Code',
      restUrlId: 'restUrl Id',
      from: 'From',
      showCategoryBrandList: 'Show Category/Brand List',
      refId: 'Ref Id',
      subAction: 'Action',
      ticketId: 'Ticket Id',
      infoId: 'Info Id',
      groupCode: 'Group Code',
      code: 'Code',
      nameCustomize: 'Name (Auto Translate TC to SC)',
      nameCustomizeSubTitle: 'Name (Auto Translate TC to SC)',
      tcImage: 'Tc Image',
      enImage: 'En Image',
      scImage: 'Sc Image',
      channel: 'Channel',
      sequenceAfter: 'Sequence (Insert After)',
      more: 'More',
      freeCollect: 'Free Collect',
      youtubeCode: 'Youtube Code',
      tagCode: 'Tag Code',
      copyDataFrom: 'Copy Data From',
      publishing: 'View Only Publishing Item',
    },

    brandApprove: {
      mainTitle: 'Brand Approve',
      restUrlId: 'Rest Url ID',
      name: 'Brand Name',
      enName: 'En Name',
      tcName: 'Tc Name',
      scName: 'Sc Name',
      enDescription: 'En Description',
      tcDescription: 'Tc Description',
      scDescription: 'Sc Description',
      logoImage: 'Logo Image',
      imageList: 'Image List',
      status: 'Status',
      rejectReason: 'Reject Reason',
      rejectReasonTip: '(Please fill in the reject reason before pressing the reject button!)',
      editTitle: 'Brand Approve Edit',
      createTitle: 'Brand Approve Create',
      info: 'Info',
    },

    restaurantApprove: {
      mainTitle: 'Restaurant',
      channel: 'Channel',
      restUrlId: 'Rest Url ID',
      email: 'E-mail',
      salesName: 'Sales Name',
      salesCreateTimestamp: 'Sales Create Timestamp',
      approvalStatus: 'Approval Status',
      approvalDate: 'Latest Approval Date',
      name: 'Restaurant Name',
      address: 'Address',
      uploadedBankInfo: 'Bank Info Uploaded',
      uploadedBr: 'Br Image Uploaded',
      imageUrl: 'imageUrl',
      refID: 'Ref ID',
      uploadedContractSignature: 'Uploaded Contract Signature',
      updateTimestamp: 'Update Time',
      //restaurantApproveEdit
      restaurantInfo: 'Restaurant Info',
      salesInfo: 'Sales Info',
      bank: 'Bank',
      bankAccount: 'Bank Account',
      bankAccountName: 'Bank Account Name',
      bankCode: 'Bank Code',
      bankContact: 'Bank Contact',
      bankContactPerson: 'Bank Contact Person',
      bankName: 'Bank Name',
      image: 'Document Media Images (Press Image to Zoom)',
      otherDocument: 'Other Document (Press to Zoom)',
      contact: 'Contact',
      contactPerson: 'Contact Person',
      jobTitle: 'Job Title',
      enName: 'E Name',
      tcName: 'Tc Name',
      scName: 'Sc Name',
      tcAddress: 'Tc Address',
      enAddress: 'En Address',
      scAddress: 'Sc Address',
      rejectReason: 'Reject Reason',
      rejectReasonTip: '(Please fill in the reject reason before pressing the reject button!)',
      merchantInfo: 'Merchant Info',
      editTitle: 'Restaurant Approve Edit',
      createTitle: 'Restaurant Approve Create',
      info: 'Info',
    },

    payment: {
      mainTitle: 'Payment',
      channel: 'Channel',
      restUrlId: 'Rest Url ID',
      email: 'E-mail',
      tranRef: 'TranRef',
      tranDate: 'TranDate',
      planCode: 'Plan Code',
      paymentCode: 'Payment Code',
      paymentDate: 'Payment Date',
      status: 'TranStatus',
      approvalStatus: 'Approval Status',
      cheque: 'Cheque',
      bankAccountNumber: 'Bank Account Number',
      chequeNumber: 'Cheque Number',
      name: 'Restaurant Name',
      sale: 'SALES',
      self: 'SELF',
      hsbc: 'HSBC',
      //PaymentEdit
      editTitle: 'Payment Edit',
      order: 'Order',
      plan: 'Plan',
      restaurant: 'Restaurant',
      restEnName: 'Rest En Name',
      restTcName: 'Rest Tc Name',
      restScName: 'Rest Sc Name',
      orderChargePrice: 'Order Charge Price',
      bankTcName: 'Bank Tc Name',
      bankEnName: 'Bank En Name',
      bankScName: 'Bank Sc Name',
      bankCode: 'Bank Code',
      bankInDate: 'Bank In Date',
      planPeriodType: 'Plan Period Type',
      planTargetDate: 'Plan Target Date',
      planEnName: 'Plan EnName',
      planTcName: 'Plan TcName',
      planScName: 'Plan ScName',
      planPeriod: 'Plan Period',
      expiryDate: 'Expiry Date',
      planEcouponPublishLimit: 'Plan Ecoupon Publish Limit',
      contractToPdf: 'Export Contract to PDF',
      //PaymentCreate
      createTitle: 'Payment Create',
      info: 'Info',
      servicePlan: 'Service Plan',
      paymentMethod: 'Payment Method',
      cash: 'Cash',
      price: 'Price',
      issueChequeBank: 'Issue Cheque Bank',
      chequeImage: 'Cheque Image',
    },

    uploadPayment: {
      mainTitle: 'Upload Payment',
      //uploadPaymentList
      restTcName: 'Restaurant Name',
      restUrlId: 'Rest Url ID',
      tranRef: 'TranRef',
      tranDate: 'Bank TranDate',
      chequeNumber: 'Cheque Number',
      matchStatus: 'Match Status',
      approvalStatus: 'Approval Status',
    },

    salesRestaurant: {
      mainTitle: 'Restaurant',
      //salesRestaurantList
      name: 'Restaurant Name',
      tcName: 'Restaurant Name',
      logoImage: 'Logo Image',
      shopFrontImage: 'Shop Front Image',
      hkId: 'HKId Card',
      br: 'BR',
      authorisationLetter: 'Authorisation Letter',
      bankInfoSignature: 'Bank Info Signature',
      contractSignature: 'Contract Signature',
      otherDocuments: 'Documents',
      restUrlId: 'Rest Url ID',
      address: 'Address',
      tcAddress: 'Address',
      salesCreateTimestamp: 'Sales Create Timestamp',
      salesName: 'Sales Name',
      approvalStatus: 'Approval Status',
      paymentStatus: 'Payment Status',
      uploadedContractSignature: 'Uploaded Contract Signature',
      updateTimestamp: 'Update Time',
    },

    salesFrontImage: {
      mainTitle: 'Shop Front Gallery',
      //salesFrontImageList
      name: 'Restaurant Name',
    },

    salesRackProduct: {
      mainTitle: 'Product',
      //salesRestaurantList
      restUrlId: 'Rest Url ID',
      restName: 'Restaurant Name',
      restTcName: 'Restaurant Name',
      productCode: 'Product Code',
      productName: 'Product Name',
      productTcName: 'Product Name',
      salesCreateTimestamp: 'Sales Create Timestamp',
      salesName: 'Sales Name',
      startDisplayTimestamp: 'Start Display Timestamp',
      endDisplayTimestamp: 'End Display Timestamp',
      published: 'Published',
      updateTimestamp: 'Update Time',
    },

    restaurantApprovalSalesRackProduct: {
      mainTitle: 'Merchant Products',
      //salesRestaurantList
      restUrlId: 'Rest Url ID',
      restTcName: 'Restaurant Name',
      restName: 'Restaurant Name',
      productCode: 'Product Code',
      productTcName: 'Product Name',
      productName: 'Product Name',
      salesCreateTimestamp: 'Sales Create Timestamp',
      salesName: 'Sales Name',
      startDisplayTimestamp: 'Start Display Timestamp',
      endDisplayTimestamp: 'End Display Timestamp',
      status: 'Status',
      updateTimestamp: 'Update Time',
    },

    productCreateDraft: {
      mainTitle: 'Product Draft',
      //productCreateDraftList
      listName: 'List Name',
      draftName: 'Draft Name',
      status: 'Status',
      draftVersion: 'Draft Version',
    },

    productCreateList: {
      mainTitle: 'Product List',
      //productCreateList
      userGroup: 'User Group',
      productCat: 'Product Cat',
      redeemGroup: 'Redeem Group',
      productDetail: 'Product Detail',
      lotInventory: 'Lot Inventory',
      enterDraftName: 'Enter Draft Name',
    },

    productCreateUserGroupList: {
      mainTitle: 'User Group',
      //ProductCreateUserGroupList
      listName: 'User Group List',
      groupCode: 'Group Code',
      groupDesc: 'Group Desc',
      clearUserGroup: 'Clear User Group',
    },

    productCreateProductCategoryList: {
      mainTitle: 'Product Category',
      menuName: 'Category',
      channel: 'Channel',
      categoryType: 'Category Type',
      typeList: 'Category Type',
      categoryChannel: 'Channel',
      displaySequence: 'Display Sequence',
      codeDescription: 'Category Description',
      categoryCodeDescription: 'Category Description',
      status: 'Status',
      categoryCode: 'Category Code',
      name: 'Category Name',
      tcName: 'Tc Name',
      enName: 'En Name',
      scName: 'Sc Name',
      tcDescription: 'Tc Description',
      enDescription: 'En Description',
      scDescription: 'Sc Description',
      //ProductCreateProductCategoryList
      createnName: 'Product Category Create',
      refCategoryCode: 'Ref Category Code',
      handleClearRefCategoryCode: 'Clear Ref Category Code',
      startDisplayTimestamp: 'Start Display Timestamp',
      endDisplayTimestamp: 'End Display Timestamp',
      imageList: 'Image List',
      uploadImage: 'Upload Image',
      type: 'Type',
      autoDisplaySequence: 'Auto Display Sequence',
      categoryInfoNotFind:
        'Draft Version is not correct so category info is not found! If need to review the category info, please press the category from the list again!',
      addProduct: 'Add Product',
      productMapList: 'Product Map List',
      productCode: 'Product Code',
      productChannel: 'Product Channel',
      productEnName: 'Product En Name',
      productTcName: 'Product Tc Name',
      productScName: 'Product Sc Name',
      editName: 'Product Category',
      editTitle: 'Product Category Edit',
      createTitle: 'Product Category Create',
      info: 'Info',
    },

    ProductMapList: {
      mainTitle: 'Product Map List',
      productCode: 'Product Code',
      productType: 'Product Type',
      productChannel: 'Product Channel',
      productName: 'Product Name',
      productEnName: 'Product En Name',
      productTcName: 'Product Tc Name',
      productScName: 'Product Sc Name',
      productStatus: 'Product Status',
      displaySequence: 'Display Sequence',
      addProductMap: 'Add Product Map',
      status: 'Status',
      editTitle: 'Product Map List Edit',
      info: 'Info',
      createTitle: 'Product Map List Create',
    },

    productCreateRedeemGroupList: {
      mainTitle: 'Redeem Group',
      codeDescription: 'Redeem Group Description',
      redeemGroupCode: 'Redeem Group Code',
      status: 'Status',
      //ProductCreateRedeemGroupList
      listName: 'Redeem Group List',
      addExistingRedeemGroup: 'Add Existing Redeem Group',
      title: 'Redeem Group',
      info: 'Info',
      createnName: 'Redeem Group Create',
      selectLocation: 'Select Location',
      selectDate: 'Select Date',
      selectTime: 'Select Time',
      type: 'Redeem Type',
      startRedeemTimestamp: 'Start Redeem Timestamp',
      endRedeemTimestamp: 'End Redeem Timestamp',
      startAfterDay: 'Start After Day (*For pre-order use)',
      endAfterDay: 'End After Day',
      redeemLocationList: 'Redeem Location List',
      total: 'Total',
      addRedeemLocation: 'Add Redeem Location',
      restUrlId: 'Rest Url Id',
      restTcName: 'Restaurant Tc Name',
      restEnName: 'Restaurant En Name',
      restScName: 'Restaurant Sc Name',
      startTime: 'Start Time',
      endTime: 'End Time',
      interval: 'Interval',
      selectAllRedeemLocation: 'Select All Redeem Location',
    },

    productCreateProductList: {
      mainTitle: 'Product',
      categoryType: 'Category Type',
      channel: 'Channel',
      name: 'Name',
      status: 'Status',
      type: 'Type',
      productCode: 'Product Code',
      tcName: 'Tc Name',
      enName: 'En Name',
      scName: 'Sc Name',
      productChannel: 'Product Channel',
      productEnName: 'Product En Name',
      productTcName: 'Product Tc Name',
      productScName: 'Product Sc Name',
      productStatus: 'Product Status',
      groupEmails: 'Group Emails',
      merchantProduct: 'Merchant Product',
      //ProductCreateProductpList
      listName: 'Product List',
      all: 'All',
      eCoupon: 'ECoupon',
      preOrder: 'PreOrder',
      package: 'Package',
      packageSubItem: 'Package Sub Item',
      freeEcouponItem: 'Free Ecoupon Item',
      publishStatus: 'Publish Status',
      addMezzofyProduct: 'Add Mezzofy Product',
      mezzofyCouponID: 'Mezzofy Coupon ID',
      productSystemGroupListTitle: 'Product System Group List',
      createnName: 'Product Create',
      product: 'Product',
      nameCustomize: 'Name (Auto Translate TC to SC)',
      redeemGroupCode: 'Redeem Group Code',
      withoutRedeemGroupCode: 'There is no suitable Redeem group',
      brandCode: 'Brand',
      systemGroup: 'System Group',
      description: 'Description',
      tcDescription: 'Tc Description',
      enDescription: 'EnDescription',
      scDescription: 'ScDescription',
      descriptionCustomize: 'Description (Auto Translate TC to SC)',
      tcTac: 'Tc Tac',
      enTac: 'En Tac',
      scTac: 'Sc Tac',
      tacCustomize: 'Tac (Auto Translate TC to SC)',
      tcRedeemDescription: 'Tc Redeem Description',
      enRedeemDescription: 'En Redeem Description',
      scRedeemDescription: 'Sc Redeem Description',
      redeemDescriptionCustomize: 'Redeem Description (Auto Translate TC to SC)',
      tcDiscountDescription: 'Tc Discount Description',
      enDiscountDescription: 'En Discount Description',
      scDiscountDescription: 'Sc Discount Description',
      discountDescriptionCustomize: 'Discount Description (Auto Translate TC to SC)',
      tcTag: 'Tc Tag',
      enTag: 'En Tag',
      scTag: 'Sc Tag',
      display: 'Display',
      startDisplayTimestamp: 'Start Display Timestamp',
      endDisplayTimestamp: 'End Display Timestamp',
      displaySequence: 'Display Sequence',
      sellingInfo: 'Product Selling Info',
      originalPrice: 'Original Price',
      sellingPrice: 'Selling Price',
      personLimit: 'Person Limit',
      quantityPerOrder: 'Quantity Per Order',
      dailyQuota: 'Daily Quota',
      bufferRedeemDay: 'Buffer Redeem Day',
      selfRedeem: 'Self Redeem',
      selfRefund: 'Self Refund',
      searchable: 'Searchable',
      shareable: 'Shareable',
      quantity: 'Quantity',
      packageCashCoupon: 'Package Cash Coupon',
      requireFillInContactPerson: 'Require Fill In Contact Person',
      requireFillInMobileNumber: 'Require Fill In Mobile Number',
      requireFillInEmail: 'Require Fill In Email',
      addSubProductMap: 'Add Sub Product Map List',
      subProductMapList: 'Sub Product Map List',
      subProductMap: 'Sub Product Map',
      distributeMinute: 'Distribute Minute',
      categoryMapList: 'Category Map List',
      addCategory: 'Add Category',
      categoryCode: 'Category Code',
      codeDescription: 'Category Description',
      categoryName: 'Category Name',
      categoryEnName: 'Category En Name',
      categoryTcName: 'Category Tc Name',
      categoryScName: 'Category Sc Name',
      imageList: 'Image List',
      uploadImage: 'Upload Image',
      autoDisplaySequence: 'Auto Display Sequence',
      settlementConfig: 'Settlement Config',
      addBlackoutPeriod: 'Add Blackout Period',
      blackoutPeriodList: 'Blackout Period List',
      restUrlId: 'Rest Url Id',
      startTimestamp: 'Start Timestamp',
      endTimestamp: 'End Timestamp',
      attributeList: 'Attribute List',
      addAttributeList: 'Add Attribute',
      key: 'Attribute Key',
      attributeType: 'Attribute Type',
      criteria: 'Criteria',
      min: 'Min',
      max: 'Max',
      optionList: 'Option List',
      code: 'Option Code',
      addOptionList: 'Add Option',
      tcValue: 'Tc Value',
      enValue: 'En Value',
      scValue: 'Sc Value',
      valueCustomize: 'Value (Auto Translate TC to SC)',
      defaultValue: 'Default Value',
      typeChange: 'Type Change',
      typeChangeMessage: 'All the input data of this product will be clear out, do you want to continue to change the type?',
      editName: 'Product',
      info: 'Information',
    },

    productCreateSystemGroupList: {
      mainTitle: 'System Group',
      name: 'Name',
      groupEmails: 'Group Emails',
      info: 'Info',
      editTitle: 'System Group Edit',
      addEmail: 'Add Email',
      removeEmail: 'Remove Email',
      createTitle: 'System Group Create',
      restaurantList: 'Restaurant List',
      restUrlId: 'restUrlId',
      enName: 'Restaurant En Name',
      tcName: 'Restaurant Tc Name',
      scName: 'Restaurant Sc Name',
      addRestaurant: 'Add Restaurant',
      restRefId: 'Ref ID',
      address: 'Address',
      systemUser: 'System User',
      addRestaurantList: 'Add Restaurant List',
      RestaurantList: 'Restaurant List',
      systemGroupInfo: 'System Group Info',
    },

    systemUser: {
      mainTitle: 'System User',
      editTitle: 'System User Edit',
      username: 'Username',
      userRole: 'User Role',
      restUrlId: 'Rest Url Id',
      privilegeAttribute: 'Privilege Attribute',
      status: 'Status',
      info: 'Info',
      newPassword: 'New Password',
      email: 'E-mail',
      apiGroup: 'API Group',
      password: 'Password',
      createTitle: 'System User Create',
      systemGroupId: 'System Group Id',
    },

    productCreateProductLotList: {
      mainTitle: 'Product Lot Inventory',
      //ProductCreateProductLotList
      listName: 'Product Lot Inventory List',
      addLotInventory: 'Add Lot Inventory',
      lotCode: 'Lot code',
      quantity: 'Quantity',
      type: 'Type',
      uploadLotFile: 'Upload Lot File',
      mezzofyCouponID: 'Mezzofy Coupon ID',
      mezzofyCouponCode: 'Mezzofy Coupon Code',
      mezzofyCouponQuantitySync: 'Mezzofy Coupon Quantity Sync',
      mezzofySubItemWarning: 'Mezzofy does not include Sub Item Lot Inventory!',
      massCode: 'Mass Code',
      tcName: 'Tc Name',
      enName: 'En Name',
      scName: 'Sc Name',
      remainingQuota: 'Remaining Quota',
      totalRemainingQuota: 'Total Remaining Quota',
    },

    contactUs: {
      mainTitle: 'Contact Us',
      topic: 'Topic',
      caseCode: 'Case Code',
      contactPerson: 'Contact Person',
      contactNumber: 'Contact Number',
      email: 'Email',
      refId: 'Ref ID',
      messageType: 'Channel',
      tagListDisplay: 'Tags',
      tagList: 'Tags',
      previewMessage: 'Message Preview',
      appMemberId: 'App Member ID',
      appVersion: 'App Version',
      osVersion: 'OS Version',
      orderId: 'Order ID',
      companyType: 'Company Type',
      restaurantName: 'Restaurant Name',
      message: 'Message',
      mediaList: 'Media',
      remarks: 'Remarks',
      status: 'Status',
      editTitle: 'Contact Us Edit',
      info: 'Info',
      createTimestamp: 'Date',
      tagInputLabel: 'Add Tags',
    },

    imageWarning: {
      mainTitle: 'Image Size:',
      weChat: '4BY3 = IMAGE (480 X 360)',
      icon: 'ICON = SQUARE IMAGE (500 X 500)',
      detail: 'DETAIL = BANNER IMAGE (500 x 500)',
    },

    MerchantUserGroup: {
      mainTitle: 'Merchant User Group',
      groupCode: 'Group Code',
      groupDesc: 'Group Description',
      editTitle: 'Merchant User Group Edit',
      info: 'Info',
      restaurantMap: 'Restaurant Map List',
      name: 'name',
      RestaurantList: 'Restaurant List',

      address: 'Address',
      restUrlId: 'restUrlId',
      groupEmails: 'Group Emails',
      addEmail: 'Add Email',
      removeEmail: 'Remove Email',
      createTitle: 'Merchant User Group Create',
      restaurantList: 'Restaurant List',
      enName: 'Restaurant En Name',
      tcName: 'Restaurant Tc Name',
      scName: 'Restaurant Sc Name',
      addRestaurant: 'Add Restaurant',
      systemUser: 'System User',
      addRestaurantList: 'Add Restaurant List',
      systemGroupInfo: 'System Group Info',
      emails: 'Email',
      addSettlementConfigList: 'Add Settlement Config List',
      SettlementConfigList: 'Settlement Config List',

      serviceType: 'Service Type',
      description: 'Description',
      profile: 'Profile',
      alipayHkRate: 'AlipayHk Rate',
      wechatPayRate: 'WechatPay Rate',
      stripeRate: 'Stripe Rate',
      yedRate: 'Yed Rate',
      mpayRate: 'Mpay Rate',
      guluRate: 'Gulu Rate',
      saleSettlementProportion: 'Sale Settlement Proportion',
      redemptionSettlementProportion: 'Redemption Settlement Proportion',
      expiredSettlementRate: 'Expired Settlement Rate',
      startTimestamp: 'Start Timestamp',
      endTimestamp: 'End Timestamp',
    },

    restaurantMap: {
      restRefId: 'Ref ID',
      address: 'Address',
      restUrlId: 'restUrlId',
      name: 'name',
    },

    RestaurantPayment: {
      mainTitle: 'Payment',
      serviceType: 'Service Type',
      systemGroupName: 'System Group Name',
      paymentConfigName: 'Payment Config Name',
      paymentCode: 'Payment Code',
      cardType: 'Card Type',
      sequence: 'Sequence',
      status: 'Status',
      editTitle: 'Payment Edit',
      info: 'Info',
      restUrlId: 'Rest Url Id',
      createTitle: 'Payment Create',
    },

    RestaurantPaymentConfigList: {
      systemGroupName: 'System Group Name',
      name: 'Name',
      paymentCode: 'Payment Code',
      merchantId: 'merchantId',
      paymentState: 'paymentState',
    },

    RestaurantPaymentTypeList: {
      paymentCode: 'Payment Code',
      cardType: 'Card Type',
      name: 'Name',
    },

    SystemGroupField: {
      systemGroupId: 'System Group Id',
      systemGroupName: 'System Group Name',
    },

    password: {
      newPassword: 'New Password',
      enterAgainPassword: 'Enter New Password Again',
    },

    tools: {
      mainTitle: 'Tools',
      qrCodeGenerator: 'GULU QR Code Generator',
      qrCodeUrl: 'QR Code Url',
      errorCorrectionLevel: 'Error Correction Level',
      cornersSquareOptions: 'Corner Square Options',
      cornersDotOptions: 'Corner dot Options',
      dotsOptions: 'Dots Options',
      width: 'Width',
      height: 'Height',
      image: 'Image',
      wholeQrCodeColor: 'Qr Code Color',
      logo: 'Logo',
      download: 'Download',
      downloadFormat: 'Download Format',
    },

    Staff: {
      mainTitle: 'Staff',
      username: 'Username',
      id: 'Id',
      editTitle: 'Staff Edit',
      info: 'info',
      staffRole: 'Staff Role',
      refCode: 'Ref Code',
      surname: 'Surname',
      firstName: 'First Name',
      displayName: 'Display Name',
      email: 'Email',
      countryCode: 'Country Code',
      mobile: 'Mobile',
      createTitle: 'Staff Create',
      password: 'Password',
      newPassword: 'New Password',
      queueProfileCode: 'Queue Profile Code',
      queueTransferProfileCode: 'Queue Transfer Profile Code',
      counterProfileCode: 'Counter Profile Code',
      syncNameFieldsWithUsername: 'Sync Name Fields With Username',
      enabled: 'Show Enabled Staff',
      changeUsername: 'Change Username',
    },

    RestaurantMap: {
      restUrlId: 'Rest Url Id',
      name: 'Name',
      restaurantStatus: 'Restaurant Status',
      mainTitle: 'Restaurant List',
      enName: 'En Name',
      scName: 'Sc Name',
      tcName: 'Tc Name',
      createTitle: 'Restaurant Map Create',
      editTitle: 'Restaurant Map Edit',
      landmarkId: 'Landmark Id',
      addRestaurantMapList: 'Add Restaurant Map List',
      status: 'Status',
    },

    Pickup: {
      mainTitle: 'Pickup',
      editTitle: 'Pickup Edit',
      createTitle: 'Pickup Create',
      restUrlId: 'RestUrlId',
      typeCode: 'Type Code',
      name: 'Name',
      status: 'Status',
      info: 'Info',
      enTypeName: 'En Type Name',
      tcTypeName: 'Tc Type Name',
      scTypeName: 'Sc Type Name',
      sequence: 'Sequence',
      reverseSequence: 'Reverse Sequence',
      foregroundColor: 'ForegroundColor',
      backgroundColor: 'BackgroundColor',
      soundTemplateCode: 'Sound Template Code',
      voiceCode: 'Voice Code',
    },
  },
}
