import { useState } from "react";
import { useSelector } from "react-redux";
// import _ from "lodash";

import { Drawer } from "@mui/material";
import Paper from '@mui/material/Paper';
import ClearIcon from '@mui/icons-material/Clear';

import { constants, SYSTEM_USER } from "../../../../../../Constants/constants";
import { getContent } from "../../../../../EditCreateDisplay/EditUtil";
import { helperTextStyles } from "../../../../../../helper/util";
import { SubTabEditCreateDisplay } from "../../../../../EditCreateDisplay/SubTabEditCreateDisplay";
import { TempBtn } from "../../../../../Input/TempBtn";
import { RestaurantList } from "../../../../../../Page/Restaurant/RestaurantList";
import { systemUserValidationConstants } from "../../../../../../Constants/validationConstants";
import SystemUserValidation from "../../../../../../validations/systemUserValidation";

const LOCAL_CONSTANT = {
  REST_URL_ID: "restUrlId",
}

export const SystemUserRestField = ({onChangeInput}) =>{
  const resource = "systemUser";
  const savedSystemUser = useSelector(state => state.systemUser.ui.systemUser);
  // const lang = useSelector(state => state.lang.ui.lang);
  // const theme = useSelector(state => state.theme.ui.theme);
  // const [errorVersion, setErrorVersion] = useState(MerchantUserValidation.getVersion());
  const helperTextStylesClasses = helperTextStyles();
  const [drawerType, setDrawerType] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  function onClickCloseDrawer() {
    setDrawerOpen(false);
  }

  const handleOnClickDrawer=(type)=>{
    setDrawerType(type);
    setDrawerOpen(true);
    handleDrawer();
  }

  const onClickListRow=(data, type)=> {
    onChangeInput(type, data)    
    onClickCloseDrawer();
  }

  const handleDrawer=()=> {
    let drawer;
    switch (drawerType) {
      case LOCAL_CONSTANT.REST_URL_ID:
        drawer =
          <Paper>
            <RestaurantList
              editBtn={false}
              createBtn={false}
              btnList={false}
              onClickListRow={(item) => onClickListRow(item.restUrlId, drawerType)}
              dialogFooterBtn={<>
                <TempBtn btnSetting={{
                  variant: constants.STYLE.VARIANT.CONTAINED,
                  color: constants.STYLE.PRIMARY,
                  onClick: onClickCloseDrawer,
                  icon: <ClearIcon />,
                  label: { key: "close" },
                }} />
              </>
              }
            />
          </Paper>
        break;
      default:
        break;
  }
  return drawer;
}

  const tempEditList = [
    {
      className: `edit-create-input ${savedSystemUser.privilegeAttribute === SYSTEM_USER.PRIVILEGE_ATTRIBUTE.G? "display-none": ""}`, 
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: LOCAL_CONSTANT.REST_URL_ID },
      value: savedSystemUser.restUrlId,
      name: LOCAL_CONSTANT.REST_URL_ID,
      disabled: true,
      isEndOfLine: false,
      required: true,
      onClickEndAdornmentIconButton: ()=>handleOnClickDrawer(LOCAL_CONSTANT.REST_URL_ID),
      helperTextIcon: true,
      helperText: SystemUserValidation.getErrorMessages(systemUserValidationConstants.KEY_REST_URL_ID),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
  ]

  const content = [
    {
      tab: tempEditList.map((inputSetting, index) => {
        let content = getContent(inputSetting, index, onChangeInput)
        return <div className="edit-display-wrapper" key={index}>
          {content}
          {inputSetting.isEndOfLine && <div></div>}
        </div>
      }),
      btn: false
    },
  ]

  return (
    <>
      <SubTabEditCreateDisplay
        code={""}
        resource={resource}
        tabs={false}
        content={content}
        onChangeInput={onChangeInput}
        display={"none"}
      />
      <Drawer
        classes={{ paper: "drawer-margin-left" }}
        anchor="right"
        open={drawerOpen}
        onClose={onClickCloseDrawer}
      >
        {handleDrawer()}
      </Drawer>
    </>  
  )
}