import { folderMapValidationConstants } from '../Constants/validationConstants';

class FolderMapValidation {

  version;
  errorMap;
  folderMapErrorMap;
  valid;

  constructor() {

    if (!FolderMapValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.folderMapErrorMap = new Map();
      FolderMapValidation.instance = this;
    }
    return FolderMapValidation.instance;
  }

  validateEditFolderMap(folderMap) {
    this.resetErrorMessages();
    this.handleValidateEditFolderMap(folderMap);
    this.version++;
  }

  validateCreateFolderMap(folderMap) {
    this.resetErrorMessages();
    this.handleValidateCreateFolderMap(folderMap);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.folderMapErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key, uniqueCode) {
    if (!uniqueCode) {
      return (this.folderMapErrorMap.has(key)) ? this.folderMapErrorMap.get(key) : [];
    } else {
      if (this.folderMapErrorMap.has(uniqueCode) && this.folderMapErrorMap.get(uniqueCode).has(key)) {
        return this.folderMapErrorMap.get(uniqueCode).get(key);
      }
      return [];
    }
  }

  handleValidateEditFolderMap(folderMap) {
    if (!folderMap.sequence && folderMap.sequence !== 0) {
      this.setFolderMapErrorMapMessages(folderMapValidationConstants.KEY_SEQUENCE, "Missing Sequence");
    } else if (folderMap.sequence < 0 || !Number.isInteger(Number(folderMap.sequence))) {
      this.setFolderMapErrorMapMessages(folderMapValidationConstants.KEY_SEQUENCE, "Invalid Sequence");
    }
    if (!folderMap.status) {
      this.setFolderMapErrorMapMessages(folderMapValidationConstants.KEY_STATUS, "Missing Status");
    }
  }

  handleValidateCreateFolderMap(folderMap) {
    if (folderMap.length === 0) {
      this.setFolderMapErrorMapMessages(folderMapValidationConstants.KEY_FOLDER_MAP_ERROR, "Please add at least one restaurant/folder!");
    } else {
      folderMap.map((item) => {
        const folderMapErrorMap = new Map();
        if (!item.sequence && item.sequence !== 0) {
          folderMapErrorMap.set(folderMapValidationConstants.KEY_SEQUENCE, "Missing Sequence");
        } else if (item.sequence < 0 || !Number.isInteger(Number(item.sequence))) {
          folderMapErrorMap.set(folderMapValidationConstants.KEY_SEQUENCE, "Invalid Sequence");
        }
        if (folderMapErrorMap.size > 0) {
          folderMapErrorMap.set(folderMapValidationConstants.KEY_FOLDER_MAP_ERROR, ["Has error(s)"]);
          this.setFolderMapErrorMapMessages(item.refCode, folderMapErrorMap);
        }
        return item
      })
    }
  }


  setFolderMapErrorMapMessages(key, errorMessages, valid = false) {
    this.folderMapErrorMap.set(key, errorMessages);
    // console.log("folderMapErrorMap", this.folderMapErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new FolderMapValidation();
export default validation;