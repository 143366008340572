import React, { useState } from 'react'
import { SubTabEditCreateDisplay } from '../../Component/EditCreateDisplay/SubTabEditCreateDisplay'
import { getContent } from '../../Component/EditCreateDisplay/EditUtil'
import { Drawer } from '@mui/material'
import { constants } from '../../Constants/constants'
import { TempBtn } from '../../Component/Input/TempBtn'
import Paper from '@mui/material/Paper'
import ClearIcon from '@mui/icons-material/Clear'
import CounterProfileList from '../CounterProfile/CounterProfileList'

export const CounterProfileSelectField = ({ resource, resourceKey, value, onChangeInput, restUrlId }) => {
  const [drawerOpen, setDrawerOpen] = useState(false)

  function onClickCloseDrawer() {
    setDrawerOpen(false)
  }

  const onClickListRow = (data) => {
    onChangeInput(resourceKey, data)
    onClickCloseDrawer()
  }

  const temSoundInfoVlice = [
    {
      className: `edit-create-input`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: resourceKey },
      value: value,
      name: resourceKey,
      disabled: true,
      isEndOfLine: true,
      required: false,
      onClickEndAdornmentIconButton: () => setDrawerOpen(true),
      clear: true,
    },
  ]

  const content = [
    {
      tab: temSoundInfoVlice.map((inputSetting, index) => {
        let content = getContent(inputSetting, index, onChangeInput)
        return (
          <div className="edit-display-wrapper" key={index}>
            {content}
            {inputSetting.isEndOfLine && <div></div>}
          </div>
        )
      }),
      btn: false,
    },
  ]

  return (
    <>
      <SubTabEditCreateDisplay
        code={''}
        resource={resource}
        tabs={false}
        content={content}
        onChangeInput={onChangeInput}
        display={'none'}
      />
      <Drawer classes={{ paper: 'drawer-margin-left' }} anchor="right" open={drawerOpen} onClose={onClickCloseDrawer}>
        <Paper>
          <CounterProfileList
            editBtn={false}
            createBtn={false}
            newFilter={{ id: restUrlId }}
            onClickListRow={(item) => onClickListRow(item.profileCode)}
            dialogFooterBtn={
              <>
                <TempBtn
                  btnSetting={{
                    variant: constants.STYLE.VARIANT.CONTAINED,
                    color: constants.STYLE.PRIMARY,
                    onClick: onClickCloseDrawer,
                    icon: <ClearIcon />,
                    label: { key: 'close' },
                  }}
                />
              </>
            }
          />
        </Paper>
      </Drawer>
    </>
  )
}
