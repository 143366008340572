import { constants } from '../../Constants/constants'
import { useParams } from 'react-router-dom'
import { getString } from '../../helper/util'
import { useSelector, useStore } from 'react-redux'
import { useEffect, useState } from 'react'
import { getOne } from '../../helper/baseFetch'

const resource = 'QueueTemplateMap'

export const QueueTemplateMapUpperSection = () => {
  const params = useParams()
  const lang = useSelector((state) => state.lang.ui.lang)
  const store = useStore()
  const [templateForToday, setTemplateForToday] = useState(null)

  useEffect(() => {
    getOne(
      constants.PATH.SHOP.QUEUE.QUEUE_TEMPLATE_TODAY_TEMPLATE + '/' + params.id,
      undefined,
      (payload) => {
        setTemplateForToday(payload)
      },
      undefined,
      store
    )
  }, [])

  return (
    <div>
      <div className="title">
        <div>
        {
          templateForToday &&
          <div>{getString(lang, resource, "queueTemplateForToday")} : <a className="code-title" href={`/shop/queue/queue_template/${templateForToday.templateId}/0`}>{`${templateForToday.templateCode} (${templateForToday.type})`}</a></div>
        }
        </div>
      </div>
    </div>
  )
}
