import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Card } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { constants, BANNER_ACTION } from '../../Constants/constants';
import { setBanner } from "../../actions/bannerAction";
import { SubTabEditCreateDisplay } from '../../Component/EditCreateDisplay/SubTabEditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempAutoSelectInput } from "../../Component/Input/TempAutoSelectInput";
import { TempCustomCheckBox } from "../../Component/Custom/TempCustomCheckBox";
import { TempBtn } from "../../Component/Input/TempBtn"
import BannerValidation from "../../validations/bannerValidation";
import { bannerValidationConstants } from '../../Constants/validationConstants';

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const BannerCreateAction = ({ onChangeInput, handleParameters, drawerOpen, setDrawerOpen, drawerIndex, setDrawerIndex, drawerType, setDrawerType }) => {
  const dispatch = useDispatch();
  const savedBanner = useSelector(state => state.banner.ui.banner);
  const helperTextStylesClasses = helperTextStyles();

  const resource = "Banner";

  const tabs = [
    {
      label: { resource: resource, key: "enImage" },
      tabIndex: constants.BANNER_SUB_TAB.BANNER_EN_IMAGE
    },
    {
      label: { resource: resource, key: "tcImage" },
      tabIndex: constants.BANNER_SUB_TAB.BANNER_TC_IMAGE
    },
    {
      label: { resource: resource, key: "scImage" },
      tabIndex: constants.BANNER_SUB_TAB.BANNER_SC_IMAGE
    },
  ]

  const tabMultiContentList = [
    {
      tab: handleActionCard("enActionParameters", "enParameters", "enOtherParameters", 0),
      btn: false
    },
    {
      tab: handleActionCard("tcActionParameters", "tcParameters", "tcOtherParameters", 1),
      btn: false
    },
    {
      tab: handleActionCard("scActionParameters", "scParameters", "scOtherParameters", 2),
      btn: false
    },
  ]

  const content = [
    {
      tab: handleActionCard("tcActionParameters", "tcParameters", "tcOtherParameters"),
      btn: false
    },
  ]

  function handleActionCard(actionParametersType, parametersType, otherParametersType, parametersIndex) {
    let lang = "";
    switch (parametersIndex) {
      case 0:
        lang = "en";
        break;
      case 1:
        lang = "tc";
        break;
      case 2:
        lang = "sc";
        break;
      default:
        lang = "tc";
    }
    const tempBannerCreateList = [
      {
        className: `full-size-input ${savedBanner.parametersHidden ? "display-none" : ""}`,
        variant: constants.STYLE.VARIANT.STANDARD,
        size: constants.SIZE.SMALL,
        label: { resource: resource, key: "actionParameters" },
        value: savedBanner[`${actionParametersType}`],
        name: actionParametersType,
        multiline: true,
        isEndOfLine: false,
        disabled: true,
        required: true,
      },
      {
        className: `edit-create-input ${savedBanner.parametersHidden || savedBanner.actionType === BANNER_ACTION.SEARCH ? "display-none" : ""}`,
        size: constants.SIZE.SMALL,
        label: savedBanner.inputName ? { resource: resource, key: savedBanner.inputName } : { key: "" },
        value: savedBanner[`${parametersType}`] && savedBanner[`${parametersType}`][savedBanner.inputName],
        onClickEndAdornmentIconButton: savedBanner.parametersDisable ? () => handleOnClickBannerDrawer(savedBanner.actionType, parametersIndex) : null,
        name: "url",
        isEndOfLine: false,
        disabled: savedBanner.parametersDisable,
        required: true,
        helperTextIcon: true,
        helperText: BannerValidation.getErrorMessages(bannerValidationConstants.KEY_PARAMETERS, savedBanner.customId ? savedBanner.customId : "", lang),
        FormHelperTextProps: { className: helperTextStylesClasses.root },
      },
      //restaurant
      {
        className: `edit-create-input ${savedBanner.actionType !== BANNER_ACTION.RESTAURANT ? "display-none" : ""}`,
        size: constants.SIZE.SMALL,
        label: { resource: resource, key: "action" },
        value: savedBanner[`${parametersType}`] && savedBanner[`${parametersType}`]["action"],
        type: constants.TYPE.SELECT,
        selectValue: constants.CHOICES.BANNER.RESTAURANT_ACTION,
        name: "action",
        isEndOfLine: false,
      },
      //PRODUCT && E_COUPON
      {
        className: `edit-create-input ${((savedBanner.actionType !== BANNER_ACTION.PRODUCT&&savedBanner.actionType !== BANNER_ACTION.E_COUPON) || !savedBanner[`${parametersType}`][savedBanner.inputName]) ? "display-none" : ""}`,
        size: constants.SIZE.SMALL,
        label: { resource: resource, key: "productParent" },
        value: savedBanner[`${parametersType}`] && savedBanner[`${parametersType}`]["productParent"],
        name: "productParent",
        type: constants.TYPE.SELECT,
        selectValue: constants.CHOICES.BANNER.PRODUCT_PARENT_TYPE,
        isEndOfLine: false,
        required: false,
      },
      {
        className: `edit-create-input ${((savedBanner.actionType !== BANNER_ACTION.PRODUCT&&savedBanner.actionType !== BANNER_ACTION.E_COUPON) || !savedBanner[`${parametersType}`]["productParent"]|| savedBanner[`${parametersType}`]["productParent"]!=="CATEGORY") ? "display-none" : ""}`,
        size: constants.SIZE.SMALL,
        label: { resource: resource, key: "categoryCode" },
        value: savedBanner[`${parametersType}`] && savedBanner[`${parametersType}`]["categoryCode"],
        onClickEndAdornmentIconButton: savedBanner.parametersDisable ? 
        () => handleOnClickBannerDrawer(BANNER_ACTION.PRODUCT_COUPON_CATEGORY_MAP, parametersIndex) 
        : null,
        name: "categoryCode",
        isEndOfLine: false,
        disabled: savedBanner.parametersDisable,
        required: (savedBanner[`${parametersType}`] && savedBanner[`${parametersType}`]["productParent"])?true:false, 
        helperTextIcon: true,
        helperText: BannerValidation.getErrorMessages(bannerValidationConstants.KEY_CHILDCODE, savedBanner.customId ? savedBanner.customId : "", lang),
        FormHelperTextProps: { className: helperTextStylesClasses.root },
      },
      {
        className: `edit-create-input ${((savedBanner.actionType !== BANNER_ACTION.PRODUCT&&savedBanner.actionType !== BANNER_ACTION.E_COUPON) || !savedBanner[`${parametersType}`]["productParent"]|| savedBanner[`${parametersType}`]["productParent"]!=="BRAND") ? "display-none" : ""}`,
        size: constants.SIZE.SMALL,
        label: { resource: resource, key: "brandCode" },
        value: savedBanner[`${parametersType}`] && savedBanner[`${parametersType}`]["brandCode"],
        onClickEndAdornmentIconButton: savedBanner.parametersDisable ? 
        () => handleOnClickBannerDrawer(BANNER_ACTION.PRODUCT_COUPON_BRAND_MAP, parametersIndex)
        : null,
        name: "brandCode",
        isEndOfLine: false,
        disabled: savedBanner.parametersDisable,
        required: (savedBanner[`${parametersType}`] && savedBanner[`${parametersType}`]["productParent"])?true:false,
        helperTextIcon: true,
        helperText: BannerValidation.getErrorMessages(bannerValidationConstants.KEY_CHILDCODE, savedBanner.customId ? savedBanner.customId : "", lang),
        FormHelperTextProps: { className: helperTextStylesClasses.root },
      },
      //pixel
      // {
      //   className: `edit-create-input ${savedBanner.actionType !== BANNER_ACTION.PIXEL ? "display-none" : ""}`,
      //   size: constants.SIZE.SMALL,
      //   label: { resource: resource, key: "pixelName" },
      //   value: savedBanner.parameters && savedBanner.parameters.name,
      //   name: "pixelName",
      //   isEndOfLine: false,
      //   required: true,
      //   helperTextIcon: true,
      //   helperText: BannerValidation.getErrorMessages(bannerValidationConstants.KEY_PIXEL_NAME, savedBanner.customId ? savedBanner.customId : "", lang),
      //   FormHelperTextProps: { className: helperTextStylesClasses.root },
      // },
      // {
      //   className: `edit-create-input ${savedBanner.actionType !== BANNER_ACTION.PIXEL ? "display-none" : ""}`,
      //   size: constants.SIZE.SMALL,
      //   label: { resource: resource, key: "haveRef" },
      //   value: savedBanner.parameters && savedBanner.parameters.haveRef,
      //   type: constants.TYPE.SELECT,
      //   selectValue: constants.CHOICES.BANNER.HAVE_REF,
      //   name: "haveRef",
      //   isEndOfLine: false,
      //   required: true,
      //   helperTextIcon: true,
      //   helperText: BannerValidation.getErrorMessages(bannerValidationConstants.KEY_PIXEL_HAVE_REF, savedBanner.customId ? savedBanner.customId : "", lang),
      //   FormHelperTextProps: { className: helperTextStylesClasses.root },
      // },
      //folder
      {
        className: `edit-create-input ${savedBanner.actionType !== BANNER_ACTION.FOLDER ? "display-none" : ""}`,
        size: constants.SIZE.SMALL,
        label: { resource: resource, key: "folderType" },
        value: savedBanner[`${parametersType}`] && savedBanner[`${parametersType}`]["folderType"],
        type: constants.TYPE.SELECT,
        selectValue: constants.CHOICES.BANNER.FOLDER_TYPE,
        name: "folderType",
        isEndOfLine: false,
        // required: true,
        // helperTextIcon: true,
        // helperText: BannerValidation.getErrorMessages(bannerValidationConstants.KEY_FOLDER_TYPE, savedBanner.customId ? savedBanner.customId : "", lang),
        // FormHelperTextProps: { className: helperTextStylesClasses.root },
      },
      //search
      {
        className: `edit-create-input ${savedBanner.actionType !== BANNER_ACTION.SEARCH ? "display-none" : ""}`,
        size: constants.SIZE.SMALL,
        label: { resource: resource, key: "name" },
        value: savedBanner[`${parametersType}`] && savedBanner[`${parametersType}`]["name"],
        name: "name",
        isEndOfLine: false,
      },
      {
        className: `edit-create-input ${savedBanner.actionType !== BANNER_ACTION.SEARCH ? "display-none" : ""}`,
        size: constants.SIZE.SMALL,
        label: { resource: resource, key: "query" },
        value: savedBanner[`${parametersType}`] && savedBanner[`${parametersType}`]["query"],
        type: constants.TYPE.SELECT,
        selectValue: constants.CHOICES.BANNER.QUERY,
        name: "query",
        isEndOfLine: false,
        // required: true,
        // helperTextIcon: true,
        // helperText: BannerValidation.getErrorMessages(bannerValidationConstants.KEY_FOLDER_TYPE, savedBanner.customId ? savedBanner.customId : "", lang),
        // FormHelperTextProps: { className: helperTextStylesClasses.root },
      },
      //web-view
      {
        className: `edit-create-input ${(savedBanner.actionType !== BANNER_ACTION.WEB_VIEW&&savedBanner.actionType !== BANNER_ACTION.MERCHANT_WEB_VIEW) ? "display-none" : ""}`,
        size: constants.SIZE.SMALL,
        label: { resource: resource, key: "title" },
        value: savedBanner[`${parametersType}`] && savedBanner[`${parametersType}`]["title"],
        name: "title",
        clear: true,
        isEndOfLine: false
      },
      {
        className: `edit-create-input ${(savedBanner.actionType !== BANNER_ACTION.WEB_VIEW&&savedBanner.actionType !== BANNER_ACTION.MERCHANT_WEB_VIEW) ? "display-none" : ""}`,
        size: constants.SIZE.SMALL,
        label: { resource: resource, key: "webViewType" },
        value: savedBanner[`${parametersType}`] && savedBanner[`${parametersType}`].type,
        type: constants.TYPE.SELECT,
        selectValue: constants.CHOICES.URL_MAP.WEB_TYPE,
        name: "webViewType",
        isEndOfLine: false,
      },
      //from
      {
        className: `edit-create-input ${savedBanner.actionType === BANNER_ACTION.NONE ? "display-none" : ""}`,
        size: constants.SIZE.SMALL,
        label: { resource: resource, key: "from" },
        value: savedBanner[`${parametersType}`] && savedBanner[`${parametersType}`]["from"],
        type: constants.TYPE.SELECT,
        selectValue: constants.CHOICES.URL_MAP.FROM,
        freeSolo: true,
        name: "from",
        isEndOfLine: false
      },
      {
        variant: constants.STYLE.VARIANT.CONTAINED,
        color: constants.STYLE.PRIMARY,
        label: { resource: resource, key: "addParameter" },
        disabled: false,
        onClick: () => handleAddParameter(parametersIndex),
        name: "handleAddParameter",
        type: constants.TYPE.BUTTON,
        icon: <AddIcon />,
      }
    ]
    return <>
      {tempBannerCreateList.map((inputSetting, index) => {
        let content;
        switch (inputSetting.type) {
          case constants.TYPE.SELECT:
            content =
              <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} index={parametersIndex} />
            break;
          case constants.TYPE.BUTTON:
            content = <div className={savedBanner.parametersHidden ? "display-none" : ""}>
              <TempBtn btnSetting={inputSetting} index={parametersIndex} />
              {
                savedBanner[`${otherParametersType}`] && savedBanner[`${otherParametersType}`].map((otherParameter, otherParameterIndex) => {
                  let tempParameterList = [
                    {
                      className: `edit-create-input`,
                      size: constants.SIZE.SMALL,
                      label: { resource: resource, key: "otherParameterName" },
                      value: otherParameter.key,
                      name: "key",
                      isEndOfLine: false,
                      required: true,
                    },
                    {
                      className: `edit-create-input`,
                      size: constants.SIZE.SMALL,
                      label: { resource: resource, key: "otherParameterValue" },
                      value: otherParameter.value,
                      name: "value",
                      isEndOfLine: false,
                      required: true,
                    },
                  ]
                  return <Card key={otherParameterIndex}>
                    {tempParameterList.map((tempParameter, index) => {
                      return <TempTextInput key={index} inputSetting={tempParameter} handleChange={handleNewParameterChange} index={{ otherParameterIndex: otherParameterIndex, parametersIndex: parametersIndex }} />
                    })}
                    <div className="delete-icon-row">
                      <TempBtn btnSetting={{
                        className: "edit-create-btn",
                        variant: constants.STYLE.VARIANT.CONTAINED,
                        color: constants.STYLE.PRIMARY,
                        onClick: () => handleOtherParameterDelete(otherParameterIndex, parametersIndex),
                        label: { key: "deleteBtn" },
                        icon: <DeleteIcon />,
                      }} />
                    </div>
                  </Card>
                })
              }
            </div>
            break;
          default:
            content =
              <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} index={parametersIndex} />
        }
        return <div className="edit-display-wrapper" key={index}>
          {inputSetting.custom && <TempCustomCheckBox className="customize-btn" value={inputSetting.customValue} customName={inputSetting.customName} index={parametersIndex} />}
          {content}
          {inputSetting.isEndOfLine && <div></div>}
        </div>
      })}
    </>
  }

  const handleOnClickBannerDrawer = (actionType, index) => {
    setDrawerType(actionType);
    switch (actionType) {
      case BANNER_ACTION.PRODUCT:
      case BANNER_ACTION.PRODUCT_CATEGORY:
      case BANNER_ACTION.PRODUCT_BRAND:
      case BANNER_ACTION.E_COUPON:
      case BANNER_ACTION.E_COUPON_BRAND:
      case BANNER_ACTION.E_COUPON_CATEGORY:
      case BANNER_ACTION.FOLDER:
      case BANNER_ACTION.RESTAURANT:
      case BANNER_ACTION.PRODUCT_COUPON_CATEGORY_MAP:
      case BANNER_ACTION.PRODUCT_COUPON_BRAND_MAP:
        setDrawerOpen(true);
        setDrawerIndex(index);
        break;
      default:
        setDrawerOpen(false);
        break;
    }
  }

  function handleAddParameter(index) {
    let tempsavedBanner = _.cloneDeep(savedBanner);
    let newParameter = [];
    switch (index) {
      case 0:
        newParameter = _.cloneDeep(tempsavedBanner.enOtherParameters ? tempsavedBanner.enOtherParameters : []);
        newParameter.push({
          key: "",
          value: ""
        });
        tempsavedBanner.enOtherParameters = newParameter;
        break;
      case 1:
        newParameter = _.cloneDeep(tempsavedBanner.tcOtherParameters ? tempsavedBanner.tcOtherParameters : []);
        newParameter.push({
          key: "",
          value: ""
        });
        tempsavedBanner.tcOtherParameters = newParameter;
        break;
      case 2:
        newParameter = _.cloneDeep(tempsavedBanner.scOtherParameters ? tempsavedBanner.scOtherParameters : []);
        newParameter.push({
          key: "",
          value: ""
        });
        tempsavedBanner.scOtherParameters = newParameter;
        break;
      default:
        newParameter = _.cloneDeep(tempsavedBanner.tcOtherParameters ? tempsavedBanner.tcOtherParameters : []);
        newParameter.push({
          key: "",
          value: ""
        });
        tempsavedBanner.enOtherParameters = newParameter;
        tempsavedBanner.tcOtherParameters = newParameter;
        tempsavedBanner.scOtherParameters = newParameter;
    }
    dispatch(setBanner(tempsavedBanner));
  }

  function handleNewParameterChange(name, e, index) {
    let tempsavedBanner = _.cloneDeep(savedBanner);
    switch (index.parametersIndex) {
      case 0:
        tempsavedBanner.enOtherParameters[index.otherParameterIndex][`${name}`] = e;
        break;
      case 1:
        tempsavedBanner.tcOtherParameters[index.otherParameterIndex][`${name}`] = e;
        break;
      case 2:
        tempsavedBanner.scOtherParameters[index.otherParameterIndex][`${name}`] = e;
        break;
      default:
        tempsavedBanner.enOtherParameters[index.otherParameterIndex][`${name}`] = e;
        tempsavedBanner.tcOtherParameters[index.otherParameterIndex][`${name}`] = e;
        tempsavedBanner.scOtherParameters[index.otherParameterIndex][`${name}`] = e;
    }
    dispatch(setBanner(tempsavedBanner));
  }

  function handleOtherParameterDelete(deleteIndex, parametersIndex) {
    let tempsavedBanner = _.cloneDeep(savedBanner);
    switch (parametersIndex) {
      case 0:
        tempsavedBanner.enOtherParameters = tempsavedBanner.enOtherParameters.filter((item, index) => index !== deleteIndex);
        tempsavedBanner = handleParameters(tempsavedBanner, "en");
        break;
      case 1:
        tempsavedBanner.tcOtherParameters = tempsavedBanner.tcOtherParameters.filter((item, index) => index !== deleteIndex);
        tempsavedBanner = handleParameters(tempsavedBanner, "tc");
        break;
      case 2:
        tempsavedBanner.scOtherParameters = tempsavedBanner.scOtherParameters.filter((item, index) => index !== deleteIndex);
        tempsavedBanner = handleParameters(tempsavedBanner, "sc");
        break;
      default:
        tempsavedBanner.enOtherParameters = tempsavedBanner.enOtherParameters.filter((item, index) => index !== deleteIndex);
        tempsavedBanner.tcOtherParameters = tempsavedBanner.tcOtherParameters.filter((item, index) => index !== deleteIndex);
        tempsavedBanner.scOtherParameters = tempsavedBanner.scOtherParameters.filter((item, index) => index !== deleteIndex);
        tempsavedBanner = handleParameters(tempsavedBanner);
    }
    dispatch(setBanner(tempsavedBanner));
  }

  return (
    <div className="sub-tab-wrapper-margin-32">
      <SubTabEditCreateDisplay
        code={""}
        resource={resource}
        tabs={savedBanner.multiActionParameters ? tabs : false}
        content={savedBanner.multiActionParameters ? tabMultiContentList : content}
        onChangeInput={onChangeInput}
        display={"none"}
      />
    </div>
  )
};