import { useState, useEffect } from "react";
import { constants, PRINT_TEMPLATE_TYPE } from '../../Constants/constants';
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import WarningIcon from '@mui/icons-material/Warning';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import CancelIcon from '@mui/icons-material/Cancel';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { makeStyles } from '@mui/styles';
import { BaseWrapper } from "../../Component/BaseWrapper";
import { setTemplate, setTemplateList, setTemplateLabelList, setTemplateLabel, setCustomIdNumber } from "../../actions/printTemplateAction";
import { setLoading } from "../../actions/systemAction";
import { TempBtn } from "../../Component/Input/TempBtn";
import PrintTemplateValidation from "../../validations/printTemplateValidation";
import { printTemplateValidationConstants } from '../../Constants/validationConstants';
import { getString } from "../../helper/util";

import "../../styles/printTemplate.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "darkgray",
    boxShadow: "none",
  },
  tab: {
    color: "#ffffff",
  },
  tabError: {
    border: "5px solid red",
  },
  tabWrapper: {

    height: 'calc(100% - 100px)',
    overflow: 'auto',

    '& .MuiBox-root': {
      padding: '0px',
    },
  },
}));


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={4}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const TemplateAndTemplateLabelList = ({ resource, setSelectedType, savedTemplateList }) => {
  const dispatch = useDispatch();
  const [tab, setTab] = useState(0);
  const savedTemplateLabelList = useSelector(state => state.printTemplate.ui.templateLabelList);
  const savedTemplate = useSelector(state => state.printTemplate.ui.template)
  const savedTemplateLabel = useSelector(state => state.printTemplate.ui.templateLabel)
  const customIdNumber = useSelector(state => state.printTemplate.ui.customIdNumber)
  const lang = useSelector(state => state.lang.ui.lang);
  const theme = useSelector(state => state.theme.ui.theme);
  const classes = useStyles();
  const [fontSize, setFontSize] = useState([]);
  const [errorPage, setErrorPage] = useState([]);

  const handleTabChange = async (event, newValue) => {
    dispatch(setLoading(true));
    setTab(newValue);
    dispatch(setLoading(false));
  };

  function handleAddPrintTemplateList() {
    const newTemplateInput = _.cloneDeep(constants.TEMPLATE_LIST_INPUT);
    const tempTemplateList = _.cloneDeep(savedTemplateList);
    let tempCustomIdNumber = _.cloneDeep(customIdNumber);
    newTemplateInput.customId = "New_Template_" + tempCustomIdNumber;
    dispatch(setCustomIdNumber(tempCustomIdNumber + 1));
    tempTemplateList.push(newTemplateInput);
    dispatch(setTemplateList(tempTemplateList));
    dispatch(setTemplate(newTemplateInput));
    setSelectedType("template");
    PrintTemplateValidation.validatePrintTempTemplate(tempTemplateList);
  }

  function handleEditPrintTemplate(item) {
    let newData = _.cloneDeep(savedTemplateList.find((tempTemplateValue) => tempTemplateValue.customId === item.customId));
    dispatch(setTemplate(newData));
    setSelectedType("template");
  }

  function handleAddPrintTemplateLabelList() {
    const newTemplateLabelInput = _.cloneDeep(constants.TEMPLATE_LABEL_LIST_INPUT);
    const tempTemplateList = _.cloneDeep(savedTemplateLabelList);
    let tempCustomIdNumber = _.cloneDeep(customIdNumber);
    newTemplateLabelInput.customId = "New_Template_" + tempCustomIdNumber;
    dispatch(setCustomIdNumber(tempCustomIdNumber + 1));
    tempTemplateList.push(newTemplateLabelInput);
    dispatch(setTemplateLabelList(tempTemplateList));
    dispatch(setTemplateLabel(newTemplateLabelInput));
    setSelectedType("templateLabel");
    PrintTemplateValidation.validatePrintTempTemplateLabel(tempTemplateList);
  }

  function handleEditPrintTemplateLabel(item) {
    let newData = _.cloneDeep(savedTemplateLabelList.find((tempTemplateLabelValue) => tempTemplateLabelValue.customId === item.customId));
    dispatch(setTemplateLabel(newData));
    setSelectedType("templateLabel");
  }

  function handleDeleteTemplateList(item) {
    const tempTemplateList = _.cloneDeep(savedTemplateList);
    dispatch(setTemplateList(tempTemplateList.filter((template) => item.customId !== template.customId)));
    PrintTemplateValidation.validatePrintTempTemplate(tempTemplateList.filter((template) => item.customId !== template.customId));
    const tempTemplate = _.cloneDeep(savedTemplate);
    if (tempTemplate.customId === item.customId) {
      const newTemplateInput = _.cloneDeep(constants.TEMPLATE_LIST_INPUT);
      dispatch(setTemplate(newTemplateInput));
    }
    setSelectedType("");
  }

  function handleDeleteTemplateLabelList(item) {
    const tempTemplateLabelList = _.cloneDeep(savedTemplateLabelList);
    dispatch(setTemplateLabelList(tempTemplateLabelList.filter((templateLabel) => item.customId !== templateLabel.customId)));
    PrintTemplateValidation.validatePrintTempTemplateLabel(tempTemplateLabelList.filter((templateLabel) => item.customId !== templateLabel.customId));
    const tempTemplateLabel = _.cloneDeep(savedTemplateLabel);
    if (tempTemplateLabel.customId === item.customId) {
      const newTemplateLabelInput = _.cloneDeep(constants.TEMPLATE_LABEL_LIST_INPUT);
      dispatch(setTemplateLabel(newTemplateLabelInput));
    }
    setSelectedType("");
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const tempTemplateList = _.cloneDeep(savedTemplateList);
    const [reorderedItem] = tempTemplateList.splice(result.source.index, 1);
    tempTemplateList.splice(result.destination.index, 0, reorderedItem);
    dispatch(setTemplateList(tempTemplateList));
    PrintTemplateValidation.validatePrintTempTemplate(tempTemplateList);
  }

  useEffect(() => {
    let tempFontSize = [];
    savedTemplateList.map((savedTemplateValue, index) => {
      if (savedTemplateValue.type === PRINT_TEMPLATE_TYPE.FONT_SIZE) {
        switch (savedTemplateValue.value) {
          case 101:
            tempFontSize.push({ value: 10 + "px", index: index })
            break;
          case 102:
            tempFontSize.push({ value: 12 + "px", index: index })
            break;
          default:
            tempFontSize.push({ value: savedTemplateValue.value * 14 + "px", index: index })
        }
        setFontSize(tempFontSize);
      };
      if (savedTemplateValue.type === PRINT_TEMPLATE_TYPE.FONT_SIZE_DIRECT) {
        tempFontSize.push({ value: savedTemplateValue.value + "px", index: index })
        setFontSize(tempFontSize);
      };
      return savedTemplateValue;
    })
  }, [savedTemplateList]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let error = [];
    if (PrintTemplateValidation.getErrorMessages(printTemplateValidationConstants.KEY_PRINT_TEMPLATE_ERROR).size > 0 ||
      PrintTemplateValidation.templateErrorMap.size > 0) {
      error.push(true)
    } else {
      error.push(false)
    };
    if (PrintTemplateValidation.templateLabelErrorMap.size > 0) {
      error.push(true)
    } else {
      error.push(false)
    };
    setErrorPage(error);
  }, [PrintTemplateValidation.getVersion(), savedTemplateList, savedTemplateLabelList]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="queue-template-content-wrapper">
      <BaseWrapper>
        <Paper className={classes.root} position="static" color="default">
          <Tabs value={tab} onChange={handleTabChange} aria-label="simple tabs example">
            <Tab className={errorPage[0] ? classes.tabError : classes.tab} key={0} label={getString(lang, resource, "template", theme)} {...a11yProps(0)} />
            <Tab className={errorPage[1] ? classes.tabError : classes.tab} key={1} label={getString(lang, resource, "templateLabels", theme)} {...a11yProps(1)} />
          </Tabs>
        </Paper>
        <TabPanel className={classes.tabWrapper} value={tab} key={0} index={0}>
          <div className="queue-template-array-list-wrapper">
            <div className="sub-title">{getString(lang, resource, "template", theme)} : </div>
            <>
              <TempBtn
                btnSetting=
                {{
                  className: "margin-left-16",
                  onClick: () => { handleAddPrintTemplateList() },
                  variant: constants.STYLE.VARIANT.CONTAINED,
                  color: constants.STYLE.PRIMARY,
                  label: { resource: resource, key: "addTemplate" },
                  icon: <AddIcon />,
                }}
              >
              </TempBtn>
            </>
          </div>
          {PrintTemplateValidation.getErrorMessages(printTemplateValidationConstants.KEY_PRINT_TEMPLATE_ERROR) &&
            PrintTemplateValidation.getErrorMessages(printTemplateValidationConstants.KEY_PRINT_TEMPLATE_ERROR).length > 0 &&
            <div className="error-message">
              <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
              {PrintTemplateValidation.getErrorMessages(printTemplateValidationConstants.KEY_PRINT_TEMPLATE_ERROR)}
            </div>
          }
          <div className="queue-template-content-content">
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="templateValue">
                {(provided) => {
                  return <div className="queue-template-tab-background" {...provided.droppableProps} ref={provided.innerRef}>
                    {savedTemplateList.map((savedTemplateValue, index) => {
                      let tempFontSize = "";
                      fontSize.map((fontSizeValue) => {
                        if (fontSizeValue.index < index) {
                          tempFontSize = fontSizeValue.value
                        };
                        return fontSizeValue;
                      })
                      let list = "";
                      list =
                        <Draggable key={savedTemplateValue + index} draggableId={savedTemplateValue + index} index={index}>
                          {(provided) => {
                            return <div key={index}
                              className={
                                savedTemplateValue.customId === savedTemplate.customId ?
                                  "queue-template-list-item queue-template-selected-product"
                                  : PrintTemplateValidation.getErrorMessages(printTemplateValidationConstants.KEY_TEMPLATE_ERROR, savedTemplateValue.customId)
                                    && PrintTemplateValidation.getErrorMessages(printTemplateValidationConstants.KEY_TEMPLATE_ERROR, savedTemplateValue.customId).length > 0
                                    ? "queue-template-list-item queue-template-error"
                                    : "queue-template-list-item"}
                              onClick={(e) => {
                                dispatch(setLoading(true));
                                e.stopPropagation();
                                handleEditPrintTemplate(savedTemplateValue);
                                dispatch(setLoading(false));
                              }}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div className="content-icon"><SwapVertIcon color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.LARGE} /></div>
                              <div className="content-info">
                                <div className="code code-with-warning">
                                  <div>
                                    {getString(lang, resource, "id", theme) + ": " + savedTemplateValue.customId}
                                  </div>
                                  {constants.CHOICES.PRINT_TEMPLATE.TEMPLATE_TYPE.find((templateType) => templateType.value === savedTemplateValue.type) &&
                                    constants.CHOICES.PRINT_TEMPLATE.TEMPLATE_TYPE.find((templateType) => templateType.value === savedTemplateValue.type).tooltip &&
                                    <Tooltip
                                      className="special-button-margin-left"
                                      title={<span style={{ whiteSpace: 'pre-line', fontSize: '12px', }}>
                                        {constants.CHOICES.PRINT_TEMPLATE.TEMPLATE_TYPE.find((templateType) => templateType.value === savedTemplateValue.type) &&
                                          constants.CHOICES.PRINT_TEMPLATE.TEMPLATE_TYPE.find((templateType) => templateType.value === savedTemplateValue.type).tooltip}
                                      </span>}
                                      arrow>
                                      <IconButton style={{ padding: 0, color: '#fce903' }} size={constants.SIZE.SMALL}><WarningIcon /></IconButton>
                                    </Tooltip>
                                  }
                                </div>
                                <div className="content">
                                  <div className="top">
                                    <div className="content-information">
                                      <div className="name">
                                        {constants.CHOICES.PRINT_TEMPLATE.TEMPLATE_TYPE.find((templateType) => templateType.value === savedTemplateValue.type) &&
                                          constants.CHOICES.PRINT_TEMPLATE.TEMPLATE_TYPE.find((templateType) => templateType.value === savedTemplateValue.type).name}
                                      </div>
                                      <div className="font-size">
                                        {tempFontSize && (savedTemplateValue.type === PRINT_TEMPLATE_TYPE.PRINT_TEXT
                                          || savedTemplateValue.type === PRINT_TEMPLATE_TYPE.PRINT_TEXT_AS_IMAGE
                                          || savedTemplateValue.type === PRINT_TEMPLATE_TYPE.PRINT_TEXT_AS_IMAGE_NO_LINE_WRAP) &&
                                          <div className="hint">(Font Size: {tempFontSize})</div>
                                        }
                                      </div>
                                        <PrinteTemplatePreview template={savedTemplateValue} />
                                    </div>
                                    <div className="delete-button">
                                      <TempBtn
                                        btnSetting=
                                        {{
                                          className: "delete-button",
                                          onClick: (e) => {
                                            dispatch(setLoading(true));
                                            handleDeleteTemplateList(savedTemplateValue, index);
                                            dispatch(setLoading(false));
                                          },
                                          label: { key: "deleteBtn" },
                                          color: constants.STYLE.PRIMARY,
                                          variant: constants.STYLE.VARIANT.CONTAINED,
                                          icon: <DeleteIcon />,
                                        }}
                                      >
                                      </TempBtn>
                                    </div>
                                  </div>
                                  {PrintTemplateValidation.getErrorMessages(printTemplateValidationConstants.KEY_TEMPLATE_ERROR, savedTemplateValue.customId).map((error) => { return error }) &&
                                    PrintTemplateValidation.getErrorMessages(printTemplateValidationConstants.KEY_TEMPLATE_ERROR, savedTemplateValue.customId).map((error) => { return error }).length > 0 &&
                                    <div className="error-message">
                                      <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                                      {PrintTemplateValidation.getErrorMessages(printTemplateValidationConstants.KEY_TEMPLATE_ERROR, savedTemplateValue.customId).map((error) => { return error })}
                                    </div>
                                  }
                                </div>
                              </div>
                            </div>
                          }}
                        </Draggable>
                      return list;
                    })}
                  </div>
                }}
              </Droppable>
            </DragDropContext>
          </div>
        </TabPanel>
        <TabPanel className={classes.tabWrapper} value={tab} key={1} index={1}>
          <div className="queue-template-array-list-wrapper">
            <div className="sub-title">{getString(lang, resource, "templateLabels", theme)} : </div>
            <>
              <TempBtn
                btnSetting=
                {{
                  className: "margin-left-16",
                  onClick: () => { handleAddPrintTemplateLabelList() },
                  variant: constants.STYLE.VARIANT.CONTAINED,
                  color: constants.STYLE.PRIMARY,
                  label: { resource: resource, key: "addTemplateLabel" },
                  icon: <AddIcon />,
                }}
              >
              </TempBtn>
            </>
          </div>
          <div className="queue-template-content-content">
            <div className="queue-template-tab-background">
              {savedTemplateLabelList.map((savedTemplateLabelValue, index) => {
                let list = "";
                list =
                  <div key={index}
                    className={
                      savedTemplateLabelValue.customId === savedTemplateLabel.customId ?
                        "queue-template-list-item queue-template-selected-product"
                        : PrintTemplateValidation.getErrorMessages(printTemplateValidationConstants.KEY_TEMPLATE_LABEL_ERROR, savedTemplateLabelValue.customId)
                          && PrintTemplateValidation.getErrorMessages(printTemplateValidationConstants.KEY_TEMPLATE_LABEL_ERROR, savedTemplateLabelValue.customId).length > 0
                          ? "queue-template-list-item queue-template-error"
                          : "queue-template-list-item"}
                    onClick={(e) => {
                      dispatch(setLoading(true));
                      e.stopPropagation();
                      handleEditPrintTemplateLabel(savedTemplateLabelValue);
                      dispatch(setLoading(false));
                    }}
                  >
                    <div className="code code-with-warning">
                      <div>{getString(lang, resource, "id", theme) + ": " + savedTemplateLabelValue.customId}</div>
                    </div>
                    <div className="content">
                      <div className="top">
                        <div className="name">label_{savedTemplateLabelValue.labelTitle}</div>
                        <div className="delete-button">
                          <TempBtn
                            btnSetting=
                            {{
                              className: "delete-button",
                              onClick: (e) => {
                                dispatch(setLoading(true));
                                handleDeleteTemplateLabelList(savedTemplateLabelValue, index);
                                dispatch(setLoading(false));
                              },
                              color: constants.STYLE.PRIMARY,
                              variant: constants.STYLE.VARIANT.CONTAINED,
                              label: { key: "deleteBtn" },
                              icon: <DeleteIcon />,
                            }}
                          >
                          </TempBtn>
                        </div>
                      </div>
                      {PrintTemplateValidation.getErrorMessages(printTemplateValidationConstants.KEY_TEMPLATE_LABEL_ERROR, savedTemplateLabelValue.customId).map((error) => { return error }) &&
                        PrintTemplateValidation.getErrorMessages(printTemplateValidationConstants.KEY_TEMPLATE_LABEL_ERROR, savedTemplateLabelValue.customId).map((error) => { return error }).length > 0 &&
                        <div className="error-message">
                          <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                          {PrintTemplateValidation.getErrorMessages(printTemplateValidationConstants.KEY_TEMPLATE_LABEL_ERROR, savedTemplateLabelValue.customId).map((error) => { return error })}
                        </div>
                      }
                    </div>
                  </div>
                return list;
              })}
            </div>
          </div>
        </TabPanel>
      </BaseWrapper>
    </div >
  )
};

const PrinteTemplatePreview = ({template}) => {

  try{

    switch(template.type) {
      case PRINT_TEMPLATE_TYPE.ALIGNMENT:
        return <div className="hint-clip-text">{getLabel(template.value, constants.CHOICES.PRINT_TEMPLATE.TEMPLATE_TYPE_2)}</div>
      case "PDF":
      case PRINT_TEMPLATE_TYPE.FONT_SIZE:
      case PRINT_TEMPLATE_TYPE.FONT_STYLE:
      case PRINT_TEMPLATE_TYPE.LOCALE:
      case PRINT_TEMPLATE_TYPE.FONT_SIZE_DIRECT:
      case PRINT_TEMPLATE_TYPE.DATE_FORMAT:
      case PRINT_TEMPLATE_TYPE.LINE_FEED:
      case PRINT_TEMPLATE_TYPE.LOGO:
      case PRINT_TEMPLATE_TYPE.HEADER:  
      case PRINT_TEMPLATE_TYPE.FOOTER:
      case PRINT_TEMPLATE_TYPE.TERMS_AND_CONDITIONS:
        return null;
      default:
        return <div className="hint-clip-text">{template.value.map((item)=>item==='${remarks}'?'${tnc}':item).join(', ')}</div>
    }

  }catch(e) {
    return null
  }

}

const getLabel = (selectedValue, choices) => {

  if(!choices || selectedValue === null) return null;

  let target = choices.find((item)=>item.value === selectedValue);

  if(!target) return null;

  return target.name;

}