import { MERCHANT_USER } from './types';
import _ from "lodash";

const LOCAL_CONSTANT = {
    PRIVILEGE_ATTERIBUTE: "privilegeAttribute",
    GROUP: "GROUP",
}

export const setMerchantUserDefault = () => {
    return {
        type: MERCHANT_USER.SET_MERCHANT_USER_DEFAULT,
    }
}

export const setMerchantUser = (merchantUser) => {
    return {
        type: MERCHANT_USER.SET_MERCHANT_USER,
        merchantUser: merchantUser
    }
}

export const setMerchantUserValue = (draftType, orignalProduct, key, value, index) => {
    const merchantUser = _.cloneDeep(orignalProduct);
    merchantUser[draftType] = value;

    if(draftType===LOCAL_CONSTANT.PRIVILEGE_ATTERIBUTE && value === LOCAL_CONSTANT.GROUP){
        merchantUser.privilegeRestUrlId = null;
    }
    
    return {
        type: MERCHANT_USER.SET_MERCHANT_USER_VALUE,
        merchantUser: merchantUser
    }
}
