import { RESTAURANT_BLACKOUT } from './types';
import _ from "lodash";

export const setRestaurantBlackoutDefault = () => {
    return {
        type: RESTAURANT_BLACKOUT.SET_RESTAURANT_BLACKOUT_DEFAULT,
    }
}

export const setRestaurantBlackout = (restaurantBlackout) => {
    return {
        type: RESTAURANT_BLACKOUT.SET_RESTAURANT_BLACKOUT,
        restaurantBlackout: restaurantBlackout
    }
}

export const setRestaurantBlackoutValue = (draftType, orignalProduct, key, value, index) => {
    const restaurantBlackout = _.cloneDeep(orignalProduct);
    switch (draftType) {
        default:
            restaurantBlackout[draftType] = value;
    }
    return {
        type: RESTAURANT_BLACKOUT.SET_RESTAURANT_BLACKOUT_VALUE,
        restaurantBlackout: restaurantBlackout
    }
}
