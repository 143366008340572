import { landmarkDistrictMapValidationConstants } from '../Constants/validationConstants';

class LandmarkDistrictMapValidation {

  version;
  errorMap;
  landmarkDistrictMapErrorMap;
  valid;

  constructor() {

    if (!LandmarkDistrictMapValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.landmarkDistrictMapErrorMap = new Map();
      LandmarkDistrictMapValidation.instance = this;
    }
    return LandmarkDistrictMapValidation.instance;
  }

  validateEditLandmarkDistrictMap(landmarkDistrictMap) {
    this.resetErrorMessages();
    this.handleValidateEditLandmarkDistrictMap(landmarkDistrictMap);
    this.version++;
  }

  validateCreateLandmarkDistrictMap(landmarkDistrictMap) {
    this.resetErrorMessages();
    this.handleValidateCreateLandmarkDistrictMap(landmarkDistrictMap);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.landmarkDistrictMapErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key, uniqueCode) {
    if (!uniqueCode) {
      return (this.landmarkDistrictMapErrorMap.has(key)) ? this.landmarkDistrictMapErrorMap.get(key) : [];
    }
  }

  handleValidateEditLandmarkDistrictMap(landmarkDistrictMap) {
    if (!landmarkDistrictMap.status) {
      this.setLandmarkDistrictMapErrorMapMessages(landmarkDistrictMapValidationConstants.KEY_STATUS, "Missing Status");
    }
  }

  handleValidateCreateLandmarkDistrictMap(landmarkDistrictMap) {
    if (landmarkDistrictMap.length === 0) {
      this.setLandmarkDistrictMapErrorMapMessages(landmarkDistrictMapValidationConstants.KEY_LANDMARK_DISTRICT_MAP_ERROR, "Please add at least one district!");
    }
  }


  setLandmarkDistrictMapErrorMapMessages(key, errorMessages, valid = false) {
    this.landmarkDistrictMapErrorMap.set(key, errorMessages);
    // console.log("landmarkDistrictMapErrorMap", this.landmarkDistrictMapErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new LandmarkDistrictMapValidation();
export default validation;