import { RESTAURANT_PAYMENT } from './types';
import _ from "lodash";

export const setRestaurantPaymentDefault = () => {
    return {
        type: RESTAURANT_PAYMENT.SET_RESTAURANT_PAYMENT_DEFAULT,
    }
}

export const setRestaurantPayment = (restaurantPayment) => {
    return {
        type: RESTAURANT_PAYMENT.SET_RESTAURANT_PAYMENT,
        restaurantPayment: restaurantPayment
    }
}

export const setRestaurantPaymentValue = (draftType, orignalProduct, key, value, index) => {
    const restaurantPayment = _.cloneDeep(orignalProduct);
    switch (draftType) {
        default:
            restaurantPayment[draftType] = value;
    }
    return {
        type: RESTAURANT_PAYMENT.SET_RESTAURANT_PAYMENT_VALUE,
        restaurantPayment: restaurantPayment
    }
}