import { BANNER_ACTION, BANNER_TYPE } from '../Constants/constants'
import { bannerValidationConstants } from '../Constants/validationConstants'

class BannerValidation {
  version
  errorMap
  bannerErrorMap
  valid

  constructor() {
    if (!BannerValidation.instance) {
      this.version = 0
      this.valid = true
      this.errorMap = new Map()
      this.bannerErrorMap = new Map()
      BannerValidation.instance = this
    }
    return BannerValidation.instance
  }

  validateEditBanner(banner) {
    this.resetErrorMessages()
    this.handleValidateEditBanner(banner)
    this.version++
  }

  validateCreateBanner(banner, bannerNotRequired) {
    this.resetErrorMessages()
    this.handleValidateCreateBanner(banner, bannerNotRequired)
    this.version++
  }

  resetErrorMessages(type) {
    //console.log(this.bannerErrorMap)

    switch (type) {
      case bannerValidationConstants.KEY_PARAMETERS_EN:
        this.bannerErrorMap.delete(bannerValidationConstants.KEY_PARAMETERS_EN)
        break
      case bannerValidationConstants.KEY_PARAMETERS_TC:
        this.bannerErrorMap.delete(bannerValidationConstants.KEY_PARAMETERS_TC)
        break
      case bannerValidationConstants.KEY_PARAMETERS_SC:
        this.bannerErrorMap.delete(bannerValidationConstants.KEY_PARAMETERS_SC)
        break
      case bannerValidationConstants.KEY_PARAMETERS:
        this.bannerErrorMap.delete(bannerValidationConstants.KEY_PARAMETERS_EN)
        this.bannerErrorMap.delete(bannerValidationConstants.KEY_PARAMETERS_TC)
        this.bannerErrorMap.delete(bannerValidationConstants.KEY_PARAMETERS_SC)
        break
      case bannerValidationConstants.KEY_CHILDCODE_EN:
        this.bannerErrorMap.delete(bannerValidationConstants.KEY_CHILDCODE_EN)
        break
      case bannerValidationConstants.KEY_CHILDCODE_TC:
        this.bannerErrorMap.delete(bannerValidationConstants.KEY_CHILDCODE_TC)
        break
      case bannerValidationConstants.KEY_CHILDCODE_SC:
        this.bannerErrorMap.delete(bannerValidationConstants.KEY_CHILDCODE_SC)
        break
      case bannerValidationConstants.KEY_CHILDCODE:
        this.bannerErrorMap.delete(bannerValidationConstants.KEY_CHILDCODE_EN)
        this.bannerErrorMap.delete(bannerValidationConstants.KEY_CHILDCODE_TC)
        this.bannerErrorMap.delete(bannerValidationConstants.KEY_CHILDCODE_SC)
        break
      default:
        this.errorMap = new Map()
        this.bannerErrorMap = new Map()
        this.valid = true
        break
    }
  }

  getVersion() {
    return this.version
  }

  isValid() {
    return this.valid
  }

  getErrorMessages(key, uniqueCode, lang) {
    switch (lang) {
      case 'en':
        key = key + '_EN'
        break
      case 'tc':
        key = key + '_TC'
        break
      case 'sc':
        key = key + '_SC'
        break
      default:
    }
    if (!uniqueCode && uniqueCode !== 0) {
      return this.bannerErrorMap.has(key) ? this.bannerErrorMap.get(key) : []
    } else {
      if (this.bannerErrorMap.has(uniqueCode) && this.bannerErrorMap.get(uniqueCode).has(key)) {
        return this.bannerErrorMap.get(uniqueCode).get(key)
      }
      return []
    }
  }

  handleValidateEditBanner(banner) {
    //console.log(banner)
    if (!banner.type) {
      this.setBannerErrorMapMessages(bannerValidationConstants.KEY_TYPE, 'Missing Type')
    }
    if (banner.type && (banner.type === BANNER_TYPE.FOLDER || banner.type === BANNER_TYPE.RESTAURANT) && !banner.refCode) {
      this.setBannerErrorMapMessages(bannerValidationConstants.KEY_REF_CODE, 'Missing Ref Code')
    }
    if (!banner.startDisplayDate) {
      this.setBannerErrorMapMessages(bannerValidationConstants.KEY_START_DISPLAY_DATE, 'Missing Start Display Date')
    }
    if (!banner.endDisplayDate) {
      this.setBannerErrorMapMessages(bannerValidationConstants.KEY_END_DISPLAY_DATE, 'Missing End Display Date')
    }
    if (!banner.tcImage || (banner.tcImage && !banner.tcImage.cosFileUrl)) {
      this.setBannerErrorMapMessages(bannerValidationConstants.KEY_TC_IMAGE, 'Missing TC Image')
    }
    if (!banner.scImage || (banner.scImage && !banner.scImage.cosFileUrl)) {
      this.setBannerErrorMapMessages(bannerValidationConstants.KEY_SC_IMAGE, 'Missing SC Image')
    }
    if (!banner.enImage || (banner.enImage && !banner.enImage.cosFileUrl)) {
      this.setBannerErrorMapMessages(bannerValidationConstants.KEY_EN_IMAGE, 'Missing EN Image')
    }
    if (banner.tcImage && banner.scImage && banner.enImage) {
      if (
        banner.tcImage.width !== banner.enImage.width ||
        banner.tcImage.width !== banner.scImage.width ||
        banner.tcImage.height !== banner.enImage.height ||
        banner.tcImage.height !== banner.scImage.height
      ) {
        this.setBannerErrorMapMessages(bannerValidationConstants.KEY_TC_IMAGE, '**Banners size are not equal!**')
      }
    }
    if (!banner.sequence && banner.sequence !== 0) {
      this.setBannerErrorMapMessages(bannerValidationConstants.KEY_SEQUENCE, 'Missing Sequence')
    } else if (banner.sequence < 0 || !Number.isInteger(Number(banner.sequence))) {
      this.setBannerErrorMapMessages(bannerValidationConstants.KEY_SEQUENCE, 'Invalid Sequence')
    }
    if (!banner.status) {
      this.setBannerErrorMapMessages(bannerValidationConstants.KEY_STATUS, 'Missing Status')
    }
    if (!banner.actionType) {
      this.setBannerErrorMapMessages(bannerValidationConstants.KEY_ACTION_TYPE, 'Missing Action Type')
    }
    if (banner.actionType !== BANNER_ACTION.NONE  && banner.actionType !== BANNER_ACTION.DOWNLOAD) {
      if (!banner.enParameters[banner.inputName]) {
        this.setBannerErrorMapMessages(bannerValidationConstants.KEY_PARAMETERS_EN, 'Invalid Parameters')
      }
      if (!banner.tcParameters[banner.inputName]) {
        this.setBannerErrorMapMessages(bannerValidationConstants.KEY_PARAMETERS_TC, 'Invalid Parameters')
      }
      if (!banner.scParameters[banner.inputName]) {
        this.setBannerErrorMapMessages(bannerValidationConstants.KEY_PARAMETERS_SC, 'Invalid Parameters')
      }
    }
    switch (banner.actionType) {
      // case BANNER_ACTION.CATEGORY_PRODUCT:
      // case BANNER_ACTION.CATEGORY_PRODUCT_MAP:
      // case BANNER_ACTION.BRAND_PRODUCT:
      // case BANNER_ACTION.BRAND_PRODUCT_MAP:
      //   // if (!banner.parameters[banner.inputName] || !banner.parameters["productCode"]) {
      //   //   this.setBannerErrorMapMessages(bannerValidationConstants.KEY_PRODUCT_CODE, "Missing Product Code");
      //   // }
      //   if (!banner.enParameters[banner.inputName] || !banner.enParameters["productCode"]) {
      //     this.setBannerErrorMapMessages(bannerValidationConstants.KEY_PRODUCT_CODE_EN, "Missing Product Code");
      //   }
      //   if (!banner.tcParameters[banner.inputName] || !banner.tcParameters["productCode"]) {
      //     this.setBannerErrorMapMessages(bannerValidationConstants.KEY_PRODUCT_CODE_TC, "Missing Product Code");
      //   }
      //   if (!banner.scParameters[banner.inputName] || !banner.scParameters["productCode"]) {
      //     this.setBannerErrorMapMessages(bannerValidationConstants.KEY_PRODUCT_CODE_SC, "Missing Product Code");
      //   }
      //   break;
      // case BANNER_ACTION.PIXEL:
      //   // if (!banner.parameters[banner.inputName] || !banner.parameters["name"]) {
      //   //   this.setBannerErrorMapMessages(bannerValidationConstants.KEY_PIXEL_NAME, "Missing Pixel Name");
      //   // }
      //   if (!banner.enParameters[banner.inputName] || !banner.enParameters["name"]) {
      //     this.setBannerErrorMapMessages(bannerValidationConstants.KEY_PIXEL_NAME_EN, "Missing Pixel Name");
      //   }
      //   if (!banner.tcParameters[banner.inputName] || !banner.tcParameters["name"]) {
      //     this.setBannerErrorMapMessages(bannerValidationConstants.KEY_PIXEL_NAME_TC, "Missing Pixel Name");
      //   }
      //   if (!banner.scParameters[banner.inputName] || !banner.scParameters["name"]) {
      //     this.setBannerErrorMapMessages(bannerValidationConstants.KEY_PIXEL_NAME_SC, "Missing Pixel Name");
      //   }
      //   // if (banner.parameters["haveRef"] !== true && banner.parameters["haveRef"] !== false) {
      //   //   this.setBannerErrorMapMessages(bannerValidationConstants.KEY_PIXEL_HAVE_REF, "Missing Pixel Have Ref");
      //   // }
      //   if (!banner.enParameters["haveRef"] !== true || !banner.enParameters["haveRef"] !== false) {
      //     this.setBannerErrorMapMessages(bannerValidationConstants.KEY_PIXEL_HAVE_REF_EN, "Missing Pixel Have Ref");
      //   }
      //   if (!banner.tcParameters["haveRef"] !== true || !banner.tcParameters["haveRef"] !== false) {
      //     this.setBannerErrorMapMessages(bannerValidationConstants.KEY_PIXEL_HAVE_REF_TC, "Missing Pixel Have Ref");
      //   }
      //   if (!banner.scParameters["haveRef"] !== true || !banner.scParameters["haveRef"] !== false) {
      //     this.setBannerErrorMapMessages(bannerValidationConstants.KEY_PIXEL_HAVE_REF_SC, "Missing Pixel Have Ref");
      //   }
      //   break;
      // case BANNER_ACTION.FOLDER:
      //   // if (!banner.parameters[banner.inputName] || !banner.parameters["folderType"]) {
      //   //   this.setBannerErrorMapMessages(bannerValidationConstants.KEY_FOLDER_TYPE, "Missing Folder Type");
      //   // }
      //   // if (!banner.enParameters[banner.inputName] || !banner.enParameters["folderType"]) {
      //   //   this.setBannerErrorMapMessages(bannerValidationConstants.KEY_FOLDER_TYPE_EN, "Missing Folder Type");
      //   // }
      //   // if (!banner.tcParameters[banner.inputName] || !banner.tcParameters["folderType"]) {
      //   //   this.setBannerErrorMapMessages(bannerValidationConstants.KEY_FOLDER_TYPE_TC, "Missing Folder Type");
      //   // }
      //   // if (!banner.scParameters[banner.inputName] || !banner.scParameters["folderType"]) {
      //   //   this.setBannerErrorMapMessages(bannerValidationConstants.KEY_FOLDER_TYPE_SC, "Missing Folder Type");
      //   // }
      //   break;
      case BANNER_ACTION.WEB_VIEW:
      case BANNER_ACTION.LINK:
        // if (!banner.parameters[banner.inputName] || !banner.parameters[banner.inputName].match("^https?://+?[-a-zA-Z0-9@:%_+.~#?&//=]+")) {
        //   this.setBannerErrorMapMessages(bannerValidationConstants.KEY_PARAMETERS, "Invalid Parameters");
        // }
        if (!banner.enParameters[banner.inputName] || !banner.enParameters[banner.inputName].match('^https?://+?[-a-zA-Z0-9@:%_+.~#?&//=]+')) {
          this.setBannerErrorMapMessages(bannerValidationConstants.KEY_PARAMETERS_EN, 'Missing Parameters')
        }
        if (!banner.tcParameters[banner.inputName] || !banner.tcParameters[banner.inputName].match('^https?://+?[-a-zA-Z0-9@:%_+.~#?&//=]+')) {
          this.setBannerErrorMapMessages(bannerValidationConstants.KEY_PARAMETERS_TC, 'Missing Parameters')
        }
        if (!banner.scParameters[banner.inputName] || !banner.scParameters[banner.inputName].match('^https?://+?[-a-zA-Z0-9@:%_+.~#?&//=]+')) {
          this.setBannerErrorMapMessages(bannerValidationConstants.KEY_PARAMETERS_SC, 'Missing Parameters')
        }
        break
      case BANNER_ACTION.PRODUCT:
      case BANNER_ACTION.E_COUPON:
        //check all?
        if (!banner.enParameters[banner.inputName] || !banner.enParameters[banner.inputName]) {
          this.setBannerErrorMapMessages(bannerValidationConstants.KEY_PARAMETERS_EN, 'Missing Code')
        }
        if (!banner.tcParameters[banner.inputName] || !banner.tcParameters[banner.inputName]) {
          this.setBannerErrorMapMessages(bannerValidationConstants.KEY_PARAMETERS_TC, 'Missing Code')
        }
        if (!banner.scParameters[banner.inputName] || !banner.scParameters[banner.inputName]) {
          this.setBannerErrorMapMessages(bannerValidationConstants.KEY_PARAMETERS_SC, 'Missing Code')
        }

        if (banner.enParameters.productParent) {
          if (
            (banner.enParameters.productParent === 'BRAND' && !banner.enParameters.brandCode) ||
            (banner.enParameters.productParent === 'CATEGORY' && !banner.enParameters.categoryCode)
          ) {
            this.setBannerErrorMapMessages(bannerValidationConstants.KEY_CHILDCODE_EN, 'Missing Code')
          }
        }

        if (banner.tcParameters.productParent) {
          if (
            (banner.tcParameters.productParent === 'BRAND' && !banner.tcParameters.brandCode) ||
            (banner.tcParameters.productParent === 'CATEGORY' && !banner.tcParameters.categoryCode)
          ) {
            this.setBannerErrorMapMessages(bannerValidationConstants.KEY_CHILDCODE_TC, 'Missing Code')
          }
        }

        if (banner.scParameters.productParent) {
          if (
            (banner.scParameters.productParent === 'BRAND' && !banner.scParameters.brandCode) ||
            (banner.scParameters.productParent === 'CATEGORY' && !banner.scParameters.categoryCode)
          ) {
            this.setBannerErrorMapMessages(bannerValidationConstants.KEY_CHILDCODE_SC, 'Missing Code')
          }
        }
        break
      default:
    }
  }

  handleValidateCreateBanner(banner, bannerNotRequired) {
    if (banner.length === 0) {
      if (!bannerNotRequired) {
        this.setBannerErrorMapMessages(bannerValidationConstants.KEY_BANNER_ERROR, 'Please add at least one banner!')
      }
    } else {
      banner.map((item) => {
        const bannerErrorMap = new Map()
        if (!item.type) {
          bannerErrorMap.set(bannerValidationConstants.KEY_TYPE, 'Missing Type')
        }
        if (item.type && (item.type === BANNER_TYPE.FOLDER || item.type === BANNER_TYPE.RESTAURANT) && !item.refCode) {
          bannerErrorMap.set(bannerValidationConstants.KEY_REF_CODE, 'Missing Ref Code')
        }
        if (!item.startDisplayDate) {
          bannerErrorMap.set(bannerValidationConstants.KEY_START_DISPLAY_DATE, 'Missing Start Display Date')
        }
        if (!item.endDisplayDate) {
          bannerErrorMap.set(bannerValidationConstants.KEY_END_DISPLAY_DATE, 'Missing End Display Date')
        }
        if (!item.tcImage || (item.tcImage && !item.tcImage.cosFileUrl)) {
          bannerErrorMap.set(bannerValidationConstants.KEY_TC_IMAGE, 'Missing TC Image')
        }
        if (!item.scImage || (item.scImage && !item.scImage.cosFileUrl)) {
          bannerErrorMap.set(bannerValidationConstants.KEY_SC_IMAGE, 'Missing SC Image')
        }
        if (!item.enImage || (item.enImage && !item.enImage.cosFileUrl)) {
          bannerErrorMap.set(bannerValidationConstants.KEY_EN_IMAGE, 'Missing EN Image')
        }
        if (item.tcImage && item.scImage && item.enImage) {
          if (
            item.tcImage.width !== item.enImage.width ||
            item.tcImage.width !== item.scImage.width ||
            item.tcImage.height !== item.enImage.height ||
            item.tcImage.height !== item.scImage.height
          ) {
            bannerErrorMap.set(bannerValidationConstants.KEY_TC_IMAGE, '**Banners size are not equal!**')
          }
        }
        if (!item.sequence && item.sequence !== 0) {
          bannerErrorMap.set(bannerValidationConstants.KEY_SEQUENCE, 'Missing Sequence')
        } else if (item.sequence < 0 || !Number.isInteger(Number(item.sequence))) {
          bannerErrorMap.set(bannerValidationConstants.KEY_SEQUENCE, 'Invalid Sequence')
        }
        if (!item.actionType) {
          bannerErrorMap.set(bannerValidationConstants.KEY_ACTION_TYPE, 'Missing Action Type')
        }
        // if (item.actionType !== BANNER_ACTION.NONE && !item.parameters[item.inputName]) {
        //   bannerErrorMap.set(bannerValidationConstants.KEY_PARAMETERS, "Missing Parameters");
        // }
        if (item.actionType !== BANNER_ACTION.NONE && item.actionType !== BANNER_ACTION.DOWNLOAD ) {
          if (!item.enParameters[item.inputName]) {
            bannerErrorMap.set(bannerValidationConstants.KEY_PARAMETERS_EN, 'Invalid Parameters')
          }
          if (!item.tcParameters[item.inputName]) {
            bannerErrorMap.set(bannerValidationConstants.KEY_PARAMETERS_TC, 'Invalid Parameters')
          }
          if (!item.scParameters[item.inputName]) {
            bannerErrorMap.set(bannerValidationConstants.KEY_PARAMETERS_SC, 'Invalid Parameters')
          }
        }
        switch (item.actionType) {
          //   case BANNER_ACTION.CATEGORY_PRODUCT:
          //   case BANNER_ACTION.CATEGORY_PRODUCT_MAP:
          //   case BANNER_ACTION.BRAND_PRODUCT:
          //   case BANNER_ACTION.BRAND_PRODUCT_MAP:
          //     // if (!item.parameters[item.inputName] || !item.parameters["productCode"]) {
          //     //   bannerErrorMap.set(bannerValidationConstants.KEY_PRODUCT_CODE, "Missing Product Code");
          //     // }
          //     if (!item.enParameters[item.inputName] || !item.enParameters["productCode"]) {
          //       bannerErrorMap.set(bannerValidationConstants.KEY_PRODUCT_CODE_EN, "Missing Product Code");
          //     }
          //     if (!item.tcParameters[item.inputName] || !item.tcParameters["productCode"]) {
          //       bannerErrorMap.set(bannerValidationConstants.KEY_PRODUCT_CODE_TC, "Missing Product Code");
          //     }
          //     if (!item.scParameters[item.inputName] || !item.scParameters["productCode"]) {
          //       bannerErrorMap.set(bannerValidationConstants.KEY_PRODUCT_CODE_SC, "Missing Product Code");
          //     }
          //     break;
          // case BANNER_ACTION.PIXEL:
          //   // if (!item.parameters[item.inputName] || !item.parameters["name"]) {
          //   //   bannerErrorMap.set(bannerValidationConstants.KEY_PIXEL_NAME, "Missing Pixel Name");
          //   // }
          //   if (!item.enParameters[item.inputName] || !item.enParameters["name"]) {
          //     bannerErrorMap.set(bannerValidationConstants.KEY_PIXEL_NAME_EN, "Missing Pixel Name");
          //   }
          //   if (!item.tcParameters[item.inputName] || !item.tcParameters["name"]) {
          //     bannerErrorMap.set(bannerValidationConstants.KEY_PIXEL_NAME_TC, "Missing Pixel Name");
          //   }
          //   if (!item.scParameters[item.inputName] || !item.scParameters["name"]) {
          //     bannerErrorMap.set(bannerValidationConstants.KEY_PIXEL_NAME_SC, "Missing Pixel Name");
          //   }
          //   // if (item.parameters["haveRef"] !== true && item.parameters["haveRef"] !== false) {
          //   //   bannerErrorMap.set(bannerValidationConstants.KEY_PIXEL_HAVE_REF, "Missing Pixel Have Ref");
          //   // }
          //   if (!item.enParameters["haveRef"] !== true || !item.enParameters["haveRef"] !== false) {
          //     bannerErrorMap.set(bannerValidationConstants.KEY_PIXEL_HAVE_REF_EN, "Missing Pixel Have Ref");
          //   }
          //   if (!item.tcParameters["haveRef"] !== true || !item.tcParameters["haveRef"] !== false) {
          //     bannerErrorMap.set(bannerValidationConstants.KEY_PIXEL_HAVE_REF_TC, "Missing Pixel Have Ref");
          //   }
          //   if (!item.scParameters["haveRef"] !== true || !item.scParameters["haveRef"] !== false) {
          //     bannerErrorMap.set(bannerValidationConstants.KEY_PIXEL_HAVE_REF_SC, "Missing Pixel Have Ref");
          //   }
          //   break;
          // case BANNER_ACTION.FOLDER:
          //   // if (!item.parameters[item.inputName] || !item.parameters["folderType"]) {
          //   //   bannerErrorMap.set(bannerValidationConstants.KEY_FOLDER_TYPE, "Missing Folder Type");
          //   // }
          //   if (!item.enParameters[item.inputName] || !item.enParameters["folderType"]) {
          //     bannerErrorMap.set(bannerValidationConstants.KEY_FOLDER_TYPE_EN, "Missing Folder Type");
          //   }
          //   if (!item.tcParameters[item.inputName] || !item.tcParameters["folderType"]) {
          //     bannerErrorMap.set(bannerValidationConstants.KEY_FOLDER_TYPE_TC, "Missing Folder Type");
          //   }
          //   if (!item.scParameters[item.inputName] || !item.scParameters["folderType"]) {
          //     bannerErrorMap.set(bannerValidationConstants.KEY_FOLDER_TYPE_SC, "Missing Folder Type");
          //   }
          //   break;
          case BANNER_ACTION.WEB_VIEW:
          case BANNER_ACTION.LINK:
            // if (!item.parameters[item.inputName] || !item.parameters[item.inputName].match("^https?://+?[-a-zA-Z0-9@:%_+.~#?&//=]+")) {
            //   bannerErrorMap.set(bannerValidationConstants.KEY_PARAMETERS, "Invalid Parameters");
            // }
            if (!item.enParameters[item.inputName] || !item.enParameters[item.inputName].match('^https?://+?[-a-zA-Z0-9@:%_+.~#?&//=]+')) {
              bannerErrorMap.set(bannerValidationConstants.KEY_PARAMETERS_EN, 'Invalid Parameters')
            }
            if (!item.tcParameters[item.inputName] || !item.tcParameters[item.inputName].match('^https?://+?[-a-zA-Z0-9@:%_+.~#?&//=]+')) {
              bannerErrorMap.set(bannerValidationConstants.KEY_PARAMETERS_TC, 'Invalid Parameters')
            }
            if (!item.scParameters[item.inputName] || !item.scParameters[item.inputName].match('^https?://+?[-a-zA-Z0-9@:%_+.~#?&//=]+')) {
              bannerErrorMap.set(bannerValidationConstants.KEY_PARAMETERS_SC, 'Invalid Parameters')
            }
            break
          case BANNER_ACTION.PRODUCT:
          case BANNER_ACTION.E_COUPON:
            if (item.enParameters.productParent) {
              if (
                (item.enParameters.productParent === 'BRAND' && !item.enParameters.brandCode) ||
                (item.enParameters.productParent === 'CATEGORY' && !item.enParameters.categoryCode)
              ) {
                bannerErrorMap.set(bannerValidationConstants.KEY_CHILDCODE_EN, 'Missing Code')
              }
            }

            if (item.tcParameters.productParent) {
              if (
                (item.tcParameters.productParent === 'BRAND' && !item.tcParameters.brandCode) ||
                (item.tcParameters.productParent === 'CATEGORY' && !item.tcParameters.categoryCode)
              ) {
                bannerErrorMap.set(bannerValidationConstants.KEY_CHILDCODE_TC, 'Missing Code')
              }
            }

            if (item.scParameters.productParent) {
              if (
                (item.scParameters.productParent === 'BRAND' && !item.scParameters.brandCode) ||
                (item.scParameters.productParent === 'CATEGORY' && !item.scParameters.categoryCode)
              ) {
                bannerErrorMap.set(bannerValidationConstants.KEY_CHILDCODE_SC, 'Missing Code')
              }
            }
            break
          default:
        }

        if (item.configMapList) {
          for (let i = 0; i < item.configMapList.length; i++) {
            if (!item.configMapList[i].startDisplayDate) {
              bannerErrorMap.set(`${i}-${bannerValidationConstants.KEY_CONFIG_MAP_START_DISPLAY_DATE}`, 'Missing Start Display Date')
            }

            if (item.configMapList[i].startDisplayDate && item.startDisplayDate > item.configMapList[i].startDisplayDate) {
              bannerErrorMap.set(`${i}-${bannerValidationConstants.KEY_CONFIG_MAP_START_DISPLAY_DATE}`, 'Start Display Date is out of range!')
            }

            if (!item.configMapList[i].endDisplayDate) {
              bannerErrorMap.set(`${i}-${bannerValidationConstants.KEY_CONFIG_MAP_END_DISPLAY_DATE}`, 'Missing End Display Date')
            }

            if (item.configMapList[i].endDisplayDate && item.endDisplayDate < item.configMapList[i].endDisplayDate) {
              bannerErrorMap.set(`${i}-${bannerValidationConstants.KEY_CONFIG_MAP_END_DISPLAY_DATE}`, 'End Display Date is out of range!')
            }
          }
        }

        if (bannerErrorMap.size > 0) {
          bannerErrorMap.set(bannerValidationConstants.KEY_BANNER_ERROR, ['Has error(s)'])
          this.setBannerErrorMapMessages(item.customId, bannerErrorMap)
        }
        return item
      })
    }
  }

  setBannerErrorMapMessages(key, errorMessages, valid = false) {
    this.bannerErrorMap.set(key, errorMessages)
    // console.log('bannerErrorMap', this.bannerErrorMap)
    if (!valid) {
      this.valid = false
    }
  }
}

const validation = new BannerValidation()
export default validation
