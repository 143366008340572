import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector, useStore } from 'react-redux'
import moment from 'moment'
import { sify } from 'chinese-conv'
import _ from 'lodash'

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CachedIcon from '@mui/icons-material/Cached'

import { constants } from '../../Constants/constants'
import { getOne, put } from '../../helper/baseFetch'
import { setRestaurant, setRestaurantValue } from '../../actions/restaurantAction'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { TempTextInput } from '../../Component/Input/TempTextInput'
import { TempAutoSelectInput } from '../../Component/Input/TempAutoSelectInput'
import { TempCustomCheckBox } from '../../Component/Custom/TempCustomCheckBox'
import { BrandEditBtn } from '../../Component/Brand/BrandEditBtn'
import { GetRestaurantDistrictId } from '../../Component/RestaurantDistrict/GetDistrictId'
import { Aside } from '../../Component/Restaurant/Aside'
import { getString, getScDisabled } from '../../helper/util'
import { DialogWrapper } from '../../Component/DialogWrapper'

export const RestaurantInfo = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const store = useStore()
  const history = useHistory()
  const [ready, setReady] = useState(false)
  const [clear, setClear] = useState(true)
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  const resource = 'restaurant'
  const savedRestaurant = useSelector((state) => state.restaurant.ui.restaurant)
  const [restaurantInputList, setRestaurantInputList] = useState(_.cloneDeep(savedRestaurant))
  const lang = useSelector((state) => state.lang.ui.lang)
  const theme = useSelector((state) => state.theme.ui.theme)

  const fields = [
    {
      type: constants.TYPE.TITLE,
      value: { resource: resource, key: 'name' },
      className: 'first-sub-title',
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'enName' },
      value: savedRestaurant.enName,
      name: 'enName',
      custom: true,
      customName: { resource: resource, key: 'nameCustomize' },
      customValue: savedRestaurant.scNameDisable,
      copyName: savedRestaurant.scSNameDisable ? ['enSName'] : null,
      inputList: restaurantInputList,
      setInputList: setRestaurantInputList,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'tcName' },
      value: savedRestaurant.tcName,
      name: 'tcName',
      translateName: savedRestaurant.scNameDisable ? (savedRestaurant.scSNameDisable ? ['scName', 'scSName'] : ['scName']) : null,
      copyName: savedRestaurant.scSNameDisable ? ['tcSName'] : null,
      inputList: restaurantInputList,
      setInputList: setRestaurantInputList,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'scName' },
      value: savedRestaurant.scName,
      name: 'scName',
      copyName: savedRestaurant.scSNameDisable ? ['scSName'] : null,
      inputList: restaurantInputList,
      setInputList: setRestaurantInputList,
      isEndOfLine: true,
      disabled: savedRestaurant.scNameDisable,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'weChatEName' },
      value: savedRestaurant.enSName,
      name: 'enSName',
      isEndOfLine: false,
      custom: true,
      customName: { resource: resource, key: 'weChatNameCustomize' },
      customValue: savedRestaurant.scSNameDisable,
      inputList: restaurantInputList,
      setInputList: setRestaurantInputList,
      disabled: savedRestaurant.scSNameDisable,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'weChatTcName' },
      value: savedRestaurant.tcSName,
      name: 'tcSName',
      inputList: restaurantInputList,
      setInputList: setRestaurantInputList,
      isEndOfLine: false,
      disabled: savedRestaurant.scSNameDisable,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'weChatScName' },
      value: savedRestaurant.scSName,
      name: 'scSName',
      inputList: restaurantInputList,
      setInputList: setRestaurantInputList,
      isEndOfLine: true,
      disabled: savedRestaurant.scSNameDisable,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'eSearchingName' },
      value: savedRestaurant.enSearchName,
      name: 'enSearchName',
      custom: true,
      customName: { resource: resource, key: 'searchingNameCustomize' },
      customValue: savedRestaurant.scSearchNameDisable,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'tcSearchingName' },
      value: savedRestaurant.tcSearchName,
      name: 'tcSearchName',
      translateName: savedRestaurant.scSearchNameDisable ? ['scSearchingName'] : null,
      inputList: restaurantInputList,
      setInputList: setRestaurantInputList,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'scSearchingName' },
      value: savedRestaurant.scSearchName,
      name: 'scSearchName',
      inputList: restaurantInputList,
      setInputList: setRestaurantInputList,
      isEndOfLine: true,
      disabled: savedRestaurant.scSearchNameDisable,
    },
    {
      type: constants.TYPE.TITLE,
      value: { resource: resource, key: 'phoneAddress' },
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'phone' },
      value: savedRestaurant.phone,
      name: 'phone',
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      type: constants.TYPE.FUNCTION,
      value: (
        <>
          <GetRestaurantDistrictId savedData={savedRestaurant} sendDistrictId={handleDistrictId} />
        </>
      ),
      isEndOfLine: true,
      disabled: true,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'enAddress' },
      value: savedRestaurant.enAddress,
      name: 'enAddress',
      custom: true,
      customName: { resource: resource, key: 'addressCustomize' },
      customValue: savedRestaurant.scAddressDisable,
      multiline: true,
      isEndOfLine: false,
      // disabled: true,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'tcAddress' },
      value: savedRestaurant.tcAddress,
      name: 'tcAddress',
      translateName: savedRestaurant.scAddressDisable ? ['scAddress'] : null,
      inputList: restaurantInputList,
      setInputList: setRestaurantInputList,
      multiline: true,
      isEndOfLine: false,
      // disabled: true,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'scAddress' },
      value: savedRestaurant.scAddress,
      name: 'scAddress',
      inputList: restaurantInputList,
      setInputList: setRestaurantInputList,
      multiline: true,
      isEndOfLine: true,
      // disabled: true,
      disabled: savedRestaurant.scAddressDisable,
    },
    {
      type: constants.TYPE.TITLE,
      value: { resource: resource, key: 'brandCode' },
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      type: constants.TYPE.FUNCTION,
      value: (
        <>
          <BrandEditBtn savedData={savedRestaurant} sendBrand={handleBrand} handleClearButtonClick={handleClearBrandAndBrandGroup} />
        </>
      ),
      isEndOfLine: true,
      disabled: true,
    },
    {
      type: constants.TYPE.TITLE,
      value: { resource: resource, key: 'description' },
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'enDescription' },
      value: savedRestaurant.enDescription,
      name: 'enDescription',
      custom: true,
      customName: { resource: resource, key: 'descriptionCustomize' },
      customValue: savedRestaurant.scDescriptionDisable,
      multiline: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'tcDescription' },
      value: savedRestaurant.tcDescription,
      name: 'tcDescription',
      translateName: savedRestaurant.scDescriptionDisable ? ['scDescription'] : null,
      inputList: restaurantInputList,
      setInputList: setRestaurantInputList,
      multiline: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'scDescription' },
      value: savedRestaurant.scDescription,
      name: 'scDescription',
      inputList: restaurantInputList,
      setInputList: setRestaurantInputList,
      multiline: true,
      isEndOfLine: true,
      disabled: savedRestaurant.scDescriptionDisable,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'enTicketMessage' },
      value: savedRestaurant.enTicketMessage,
      name: 'enTicketMessage',
      custom: true,
      customName: { resource: resource, key: 'ticketMessageCustomize' },
      customValue: savedRestaurant.scTicketMessageDisable,
      multiline: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'tcTicketMessage' },
      value: savedRestaurant.tcTicketMessage,
      name: 'tcTicketMessage',
      translateName: savedRestaurant.scTicketMessageDisable ? ['scTicketMessage'] : null,
      inputList: restaurantInputList,
      setInputList: setRestaurantInputList,
      multiline: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'scTicketMessage' },
      value: savedRestaurant.scTicketMessage,
      name: 'scTicketMessage',
      inputList: restaurantInputList,
      setInputList: setRestaurantInputList,
      multiline: true,
      isEndOfLine: true,
      disabled: savedRestaurant.scTicketMessageDisable,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'enOpening' },
      value: savedRestaurant.enOpening,
      name: 'enOpening',
      custom: true,
      customName: { resource: resource, key: 'openingCustomize' },
      customValue: savedRestaurant.scOpeningDisable,
      multiline: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'tcOpening' },
      value: savedRestaurant.tcOpening,
      name: 'tcOpening',
      translateName: savedRestaurant.scOpeningDisable ? ['scOpening'] : null,
      inputList: restaurantInputList,
      setInputList: setRestaurantInputList,
      multiline: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'scOpening' },
      value: savedRestaurant.scOpening,
      name: 'scOpening',
      inputList: restaurantInputList,
      setInputList: setRestaurantInputList,
      multiline: true,
      isEndOfLine: true,
      disabled: savedRestaurant.scOpeningDisable,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'enLastOrderTime' },
      value: savedRestaurant.enLastOrderTime,
      name: 'enLastOrderTime',
      custom: true,
      customName: { resource: resource, key: 'lastOrderTimeCustomize' },
      customValue: savedRestaurant.scLastOrderTimeDisable,
      multiline: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'tcLastOrderTime' },
      value: savedRestaurant.tcLastOrderTime,
      name: 'tcLastOrderTime',
      translateName: savedRestaurant.scLastOrderTimeDisable ? ['scLastOrderTime'] : null,
      inputList: restaurantInputList,
      setInputList: setRestaurantInputList,
      multiline: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'scLastOrderTime' },
      value: savedRestaurant.scLastOrderTime,
      name: 'scLastOrderTime',
      inputList: restaurantInputList,
      setInputList: setRestaurantInputList,
      multiline: true,
      isEndOfLine: true,
      disabled: savedRestaurant.scLastOrderTimeDisable,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'avgSpend' },
      value: savedRestaurant.avgSpend,
      name: 'avgSpend',
      isEndOfLine: false,
    },
    {
      type: constants.TYPE.TITLE,
      value: { resource: resource, key: 'config' },
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'restRefId' },
      value: savedRestaurant.restRefId,
      name: 'restRefId',
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'aigensId' },
      value: savedRestaurant.aigensId,
      name: 'aigensId',
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'paymentMethod' },
      value: savedRestaurant.paymentMethod,
      name: 'paymentMethod',
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'restaurantType' },
      value: savedRestaurant.restType,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.RESTAURANT.REST_TYPE,
      name: 'restType',
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'searchable' },
      value: savedRestaurant.searchable,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.RESTAURANT.SEARCHABLE,
      name: 'searchable',
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'theme' },
      value: savedRestaurant.theme,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.RESTAURANT.THEME,
      name: 'theme',
      freeSolo: true,
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'showBanner' },
      value: savedRestaurant.showBanner,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.RESTAURANT.SHOW_BANNER,
      name: 'showBanner',
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'status' },
      value: savedRestaurant.status,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.RESTAURANT.STATUS,
      name: 'status',
      isEndOfLine: false,
    },
    {
      type: constants.TYPE.TITLE,
      value: { resource: resource, key: 'salesAndContact' },
    },
    {
      className: 'edit-create-input',
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'salesName' },
      value: savedRestaurant.salesName,
      name: 'salesName',
      isEndOfLine: false,
      disabled: true,
    },
    {
      className: 'edit-create-input',
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'salesCreateTimestamp' },
      value: savedRestaurant.salesCreateTimestamp ? moment(savedRestaurant.salesCreateTimestamp).format('YYYY-MM-DD HH:mm:ss') : '',
      name: 'salesCreateTimestamp',
      multiline: true,
      isEndOfLine: true,
      disabled: true,
    },
    {
      className: 'edit-create-input',
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'contact' },
      value: savedRestaurant.contact,
      name: 'contact',
      isEndOfLine: false,
      disabled: true,
    },
    {
      className: 'edit-create-input',
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'contactPerson' },
      value: savedRestaurant.contactPerson,
      name: 'contactPerson',
      isEndOfLine: false,
      disabled: true,
    },
    {
      className: 'edit-create-input',
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'jobTitle' },
      value: savedRestaurant.jobTitle,
      name: 'jobTitle',
      isEndOfLine: true,
      disabled: true,
    },
  ]
  const content = [
    {
      tab: (
        <div className="aside-wrapper">
          <Aside handleSaveImage={handleSaveImage} handleAddress={handleAddress} />
          <div className="aside-right">
            {fields.map((inputSetting, index) => {
              let content = ''
              switch (inputSetting.type) {
                case constants.TYPE.SELECT:
                  content = <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
                  break
                case constants.TYPE.FUNCTION:
                  content = inputSetting.value
                  break
                case constants.TYPE.TITLE:
                  content = (
                    <div className={`${inputSetting.className + ' sub-title'}`}>
                      {getString(lang, inputSetting.value.resource, inputSetting.value.key, theme)}
                    </div>
                  )
                  break
                default:
                  content = <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
              }
              return (
                <div className="edit-display-wrapper" key={index}>
                  {inputSetting.custom && (
                    <TempCustomCheckBox
                      className="customize-btn"
                      value={inputSetting.customValue}
                      customName={inputSetting.customName}
                      onSelectCheckbox={onSelectCheckbox}
                    />
                  )}
                  {content}
                  {inputSetting.isEndOfLine && <div></div>}
                </div>
              )
            })}
          </div>
        </div>
      ),
      btn: true,
    },
  ]

  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'save' },
      onClick: onClickSave,
      className: 'margin-left-16',
      icon: <SaveIcon />,
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'clear' },
      disabled: false,
      onClick: onClickClear,
      className: 'margin-left-16',
      icon: <ClearIcon />,
    },
    {
      className: 'margin-left-16',
      type: constants.TYPE.TEXT,
      label: { key: 'updateTimestamp' },
      value: ': ' + moment(savedRestaurant.updateTimestampForDisplay).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      className: 'special-button special-button-margin-left',
      variant: constants.STYLE.VARIANT.CONTAINED,
      icon: <CachedIcon />,
      label: { key: 'clearCache' },
      onClick: handleCache,
    },
    {
      className: 'special-button',
      variant: constants.STYLE.VARIANT.CONTAINED,
      icon: <CachedIcon />,
      label: { key: 'clearGuluWebSiteDetailCache' },
      onClick: handleGuluWebSiteDetailCache,
    },
  ]

  function onSelectCheckbox(value, customName) {
    switch (customName.key) {
      case 'nameCustomize':
        dispatch(setRestaurantValue('scNameDisable', savedRestaurant, '', value))
        break
      case 'weChatNameCustomize':
        dispatch(setRestaurantValue('scSNameDisable', savedRestaurant, '', value))
        break
      case 'searchingNameCustomize':
        dispatch(setRestaurantValue('scSearchNameDisable', savedRestaurant, '', value))
        break
      case 'ticketMessageCustomize':
        dispatch(setRestaurantValue('scTicketMessageDisable', savedRestaurant, '', value))
        break
      case 'openingCustomize':
        dispatch(setRestaurantValue('scOpeningDisable', savedRestaurant, '', value))
        break
      case 'lastOrderTimeCustomize':
        dispatch(setRestaurantValue('scLastOrderTimeDisable', savedRestaurant, '', value))
        break
      case 'descriptionCustomize':
        dispatch(setRestaurantValue('scDescriptionDisable', savedRestaurant, '', value))
        break
      case 'addressCustomize':
        dispatch(setRestaurantValue('scAddressDisable', savedRestaurant, '', value))
        break
      default:
    }
  }

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push('/' + constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT)
  }

  function onChangeInput(name, e) {
    if (name === 'tcName') {
      if (savedRestaurant.scNameDisable === true && savedRestaurant.scSNameDisable === true) {
        dispatch(setRestaurantValue('CHANGE_TC_NAME_AND_SC_NAME', savedRestaurant, '', e))
      } else if (savedRestaurant.scSNameDisable === true) {
        dispatch(setRestaurantValue('CHANGE_TC_WECHAT_NAME_AND_SC_WECHAT_NAME', savedRestaurant, '', e))
      } else if (savedRestaurant.scNameDisable === true) {
        dispatch(setRestaurantValue('CHANGE_TC_NAME_AND_SC_NAME_WITHOUT_WECHAT', savedRestaurant, '', e))
      } else {
        dispatch(setRestaurantValue(name, savedRestaurant, '', e))
      }
    } else if (name === 'enName' && savedRestaurant.scSNameDisable === true) {
      dispatch(setRestaurantValue('CHANGE_EN_NAME', savedRestaurant, '', e))
    } else if (name === 'tcSearchName' && savedRestaurant.scSearchNameDisable === true) {
      dispatch(setRestaurantValue('CHANGE_TC_SEARCHING_NAME_AND_SC_SEARCHING_NAME', savedRestaurant, '', e))
    } else if (name === 'tcTicketMessage' && savedRestaurant.scTicketMessageDisable === true) {
      dispatch(setRestaurantValue('CHANGE_TC_TICKET_MESSAGE_AND_SC_TICKET_MESSAGE', savedRestaurant, '', e))
    } else if (name === 'tcOpening' && savedRestaurant.scOpeningDisable === true) {
      dispatch(setRestaurantValue('CHANGE_TC_OPENING_AND_SC_OPENING', savedRestaurant, '', e))
    } else if (name === 'tcLastOrderTime' && savedRestaurant.scLastOrderTimeDisable === true) {
      dispatch(setRestaurantValue('CHANGE_TC_LAST_ORDER_TIME_AND_SC_LAST_ORDER_TIME', savedRestaurant, '', e))
    } else if (name === 'tcDescription' && savedRestaurant.scDescriptionDisable === true) {
      dispatch(setRestaurantValue('CHANGE_TC_DESCRIPTION_AND_SC_DESCRIPTION', savedRestaurant, '', e))
    } else if (name === 'tcAddress' && savedRestaurant.scAddressDisable === true) {
      dispatch(setRestaurantValue('CHANGE_TC_ADDRESS_AND_SC_ADDRESS', savedRestaurant, '', e))
    } else {
      dispatch(setRestaurantValue(name, savedRestaurant, '', e))
    }
  }
  function handleSaveImage(image) {
    dispatch(
      setRestaurantValue('CHANGE_IMAGE', savedRestaurant, '', {
        picture: image.picture,
        thumbnail: image.thumbnail,
      })
    )
  }

  function handleClearBrandAndBrandGroup() {
    dispatch(
      setRestaurantValue('CHANGE_BRAND', savedRestaurant, '', {
        brandCode: '',
        brandTcName: '',
        brandEnName: '',
        brandScName: '',
      })
    )
  }

  function handleBrand(data) {
    dispatch(
      setRestaurantValue('CHANGE_BRAND', savedRestaurant, '', {
        brandCode: data.brandCode,
        brandTcName: data.brandTcName,
        brandEnName: data.brandEnName,
        brandScName: data.brandScName,
      })
    )
  }

  function handleAddress(x, y, z, tcAddress, enAddress) {
    dispatch(
      setRestaurantValue('CHANGE_ADDRESS', savedRestaurant, '', {
        coorX: x,
        coorY: y,
        coorZ: z,
        tcAddress: tcAddress,
        enAddress: enAddress,
      })
    )
  }

  function handleDistrictId(district) {
    dispatch(
      setRestaurantValue('CHANGE_DISTRICT', savedRestaurant, '', {
        districtId: district.districtId,
        districtTcName: district.tcName,
        districtEnName: district.enName,
        districtScName: district.scName,
      })
    )
  }

  function onClickSave() {
    put(
      constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT + '/' + params.id,
      savedRestaurant,
      () => {
        setDialog({
          open: true,
          onClose: onClickBackToEdit,
          dialogContentText: { key: 'editSuccess' },
          dialogActions: [
            { label: { key: 'backEdit' }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
            { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
          ],
        })
        setClear(false)
      },
      undefined,
      store
    )
  }

  function onClickClear() {
    setClear(true)
  }

  function handleCache() {
    put(
      constants.PATH.SHOP.CLEAR_RESTAURANT_CACHE + '/' + params.id,
      {},
      (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToEdit,
          dialogContentText: { key: 'clearCacheSuccess' },
          dialogActions: [
            { label: { key: 'backEdit' }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
            { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
          ],
        })
        setClear(false)
      },
      undefined,
      store
    )
  }

  function handleGuluWebSiteDetailCache() {
    put(
      constants.PATH.SHOP.CLEAR_RESTAURANT_REDIS_CACHE + '/' + params.id,
      {},
      (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToEdit,
          dialogContentText: { key: 'clearCacheSuccess' },
          dialogActions: [
            { label: { key: 'backEdit' }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
            { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
          ],
        })
        setClear(false)
      },
      undefined,
      store
    )
  }

  useEffect(() => {
    if (clear === true) {
      getOne(
        constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT + '/' + params.id,
        undefined,
        (payload) => {
          payload.scNameDisable = getScDisabled(payload.tcName, payload.scName)
          payload.scSearchNameDisable = getScDisabled(payload.tcSearchName, payload.scSearchName)
          payload.scAddressDisable = getScDisabled(payload.tcAddress, payload.scAddress)
          payload.scTicketMessageDisable = getScDisabled(payload.tcTicketMessage, payload.scTicketMessage)
          payload.scOpeningDisable = getScDisabled(payload.tcOpening, payload.scOpening)
          payload.scLastOrderTimeDisable = getScDisabled(payload.tcLastOrderTime, payload.scLastOrderTime)
          payload.scDescriptionDisable = getScDisabled(payload.tcDescription, payload.scDescription)
          payload.updateTimestampForDisplay = payload.updateTimestamp
          delete payload.updateTimestamp

          if (
            (!payload.tcSName && !payload.scSName) ||
            (payload.enSName &&
              payload.enSName === payload.enName &&
              payload.tcName &&
              payload.tcName &&
              payload.tcSName === payload.tcName &&
              sify(payload.tcName) === payload.scSName)
          ) {
            payload.scSNameDisable = true
          } else {
            payload.scSNameDisable = false
          }

          dispatch(setRestaurant(_.cloneDeep(payload)))
          setRestaurantInputList(_.cloneDeep(payload))
          setReady(true)
          setClear(false)
        },
        undefined,
        store
      )
    }
  }, [clear]) // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(()=> {
  //   console.log('savedRestaurant',savedRestaurant)
  // }, [savedRestaurant])

  return (
    ready && (
      <>
        <EditCreateDisplay
          resource={resource}
          tabs={false}
          content={content}
          onChangeInput={onChangeInput}
          bottomBtns={bottomBtns}
          display="none"
        />
        {dialog && dialog.open && <DialogWrapper dialogInfo={dialog} />}
      </>
    )
  )
}
