import React, { useState } from 'react'
import { constants } from '../../Constants/constants'
import { Drawer } from '@mui/material'
import Paper from '@mui/material/Paper'
import { TempBtn } from '../../Component/Input/TempBtn'
import ClearIcon from '@mui/icons-material/Clear'
import AddIcon from '@mui/icons-material/Add'
import { RestaurantList } from '../Restaurant/RestaurantList'
import { RestaurantField } from './RestaurantField'

export const AddRestaurantButton = ({ resource, idx, restUrlMap = [], onChangeInput }) => {
  const [selectedMap, setSelectedMap] = useState(new Map(restUrlMap.map((i) => [i.restUrlId, i]))) //map
  const [drawerOpen, setDrawerOpen] = useState(false)

  function onClickCloseDrawer() {
    setDrawerOpen(false)
  }

  const handleRestUrlId = () => {
    onChangeInput(
      'restUrlMap',
      Array.from(selectedMap, ([name, value]) => ({ restUrlId: value.restUrlId, enName: value.enName, scName: value.scName, tcName: value.tcName })),
      idx
    )
    onClickCloseDrawer()
  }

  const handleDelete = (restUrlId) => {
    selectedMap.delete(restUrlId)
    handleRestUrlId()
  }

  return (
    <>
      <TempBtn
        btnSetting={{
          onClick: () => setDrawerOpen(true),
          variant: constants.STYLE.VARIANT.CONTAINED,
          color: constants.STYLE.PRIMARY,
          icon: <AddIcon />,
          label: { resource: resource, key: 'addRestaurant' },
        }}
      ></TempBtn>
      {restUrlMap.map((restaurant) => (
        <RestaurantField
          key={restaurant.restUrlId}
          resource={resource}
          restaurant={restaurant}
          handleDelete={handleDelete}
          onChangeInput={onChangeInput}
        />
      ))}
      <Drawer classes={{ paper: 'drawer-margin-left' }} anchor="right" open={drawerOpen} onClose={onClickCloseDrawer}>
        <Paper>
          <RestaurantList
            editBtn={false}
            createBtn={false}
            btnList={false}
            newFilter={
              selectedMap && selectedMap.size > 0
                ? { selected: { column: 'restUrlId', valueList: Array.from(selectedMap, ([name, value]) => name) } }
                : null
            }
            selectMap={selectedMap}
            setSelectMap={setSelectedMap}
            mutipleSelect={true}
            dialogFooterBtn={
              <div className="get-push-card-wrapper">
                <TempBtn
                  btnSetting={{
                    className: 'get-push-card-cancel-btn margin-right-16',
                    variant: constants.STYLE.VARIANT.CONTAINED,
                    color: constants.STYLE.PRIMARY,
                    onClick: handleRestUrlId,
                    icon: <AddIcon />,
                    label: { key: 'submit' },
                  }}
                ></TempBtn>
                <TempBtn
                  btnSetting={{
                    className: 'get-push-card-cancel-btn margin-right-16',
                    variant: constants.STYLE.VARIANT.CONTAINED,
                    color: constants.STYLE.PRIMARY,
                    onClick: onClickCloseDrawer,
                    icon: <ClearIcon />,
                    label: { key: 'close' },
                  }}
                ></TempBtn>
              </div>
            }
          />
        </Paper>
      </Drawer>
    </>
  )
}
