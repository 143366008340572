import { Card } from '@mui/material'
import React from 'react'
import DeleteIcon from '@mui/icons-material/Delete'

import { TempBtn } from '../../../Component/Input/TempBtn'
import { constants } from '../../../Constants/constants'
import { TempTextInput } from '../../../Component/Input/TempTextInput'

export const RestaurantInfoCard = ({ shop, onDeleteRestaurantMapCard, resource }) => {
  const content = [
    {
      className: 'edit-create-input',
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'restUrlId' },
      value: shop.restUrlId,
      name: 'restUrlId',
      isEndOfLine: true,
      disabled: true,
    },
    {
      className: 'edit-create-input',
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'enName' },
      value: shop.enName,
      name: 'enName',
      isEndOfLine: false,
      disabled: true,
    },
    {
      className: 'edit-create-input',
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'tcName' },
      value: shop.tcName,
      name: 'tcName',
      isEndOfLine: false,
      disabled: true,
    },
    {
      className: 'edit-create-input',
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'scName' },
      value: shop.scName,
      name: 'scName',
      isEndOfLine: true,
      disabled: true,
    },
  ]

  return (
    <Card className="image-list">
      <div className="delete-icon">
        <TempBtn
          btnSetting={{
            className: 'edit-create-btn',
            variant: constants.STYLE.VARIANT.CONTAINED,
            color: constants.STYLE.PRIMARY,
            label: { key: 'deleteBtn' },
            onClick: () => onDeleteRestaurantMapCard(shop.restUrlId),
            icon: <DeleteIcon />,
          }}
        />
      </div>
      {content.map((inputSetting, idx) => (
        <div className="edit-display-wrapper" key={idx}>
          <TempTextInput inputSetting={inputSetting} />
          {inputSetting.isEndOfLine && <div></div>}
        </div>
      ))}
    </Card>
  )
}
