import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { Card } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';

import { setProductValue } from "../../../actions/productCreateAction";
import { constants } from '../../../Constants/constants';
import ProductCreateValidation from "../../../validations/productCreateValidation";
import { productValidationConstants } from '../../../Constants/validationConstants';
import { setLoading } from "../../../actions/systemAction";
import { TempTextInput } from "../../Input/TempTextInput";
import { TempAutoSelectInput } from "../../Input/TempAutoSelectInput";
import { TempBtn } from "../../Input/TempBtn";
import { getString } from "../../../helper/util";

export const SubProductMap = ({ hiddenInput, disableInput, helperTextStylesClasses }) => {
  const dispatch = useDispatch();
  const savedProduct = useSelector(state => state.product.ui.product)
  const lang = useSelector(state => state.lang.ui.lang);
  const theme = useSelector(state => state.theme.ui.theme);

  const handleAddSubProductMapList = async () => {
    let tempCodeNumber = _.cloneDeep(savedProduct.draft.codeNumber);
    const tempSubProductMapList = await processAddSubProductMap(tempCodeNumber);
    let tempDraft = _.cloneDeep(savedProduct.draft);
    tempDraft.product.subProductMapList = tempSubProductMapList.tempSubProductMapList;
    tempDraft.codeNumber = tempSubProductMapList.tempCodeNumber + 1;
    dispatch(setProductValue("", savedProduct, "", tempDraft));
  }

  const processAddSubProductMap = (tempCodeNumber) => {
    return new Promise((resolve) => {
      let tempSubProductMapList = _.cloneDeep(savedProduct.draft.product.subProductMapList);
      tempSubProductMapList.push({
        subProductMapValiCode: "Sub_Product_Map_" + Number(tempCodeNumber),
        productCode: "",
        distributeMinute: 0,
        quantity: 0
      })
      tempCodeNumber = tempCodeNumber + 1;
      resolve({ tempSubProductMapList: tempSubProductMapList, tempCodeNumber: tempCodeNumber });
    })
  }

  const handleDeleteSubProductMap = (subProductMapIndex) => {
    dispatch(setLoading(true));
    let tempSubProductMapList = _.cloneDeep(savedProduct.draft.product.subProductMapList);
    tempSubProductMapList.splice(subProductMapIndex, 1);
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, "subProductMapList", tempSubProductMapList));
    dispatch(setLoading(false));
  }

  function handleSubProductMapProductCode(name, productCode, subProductMapIndex) {
    let tempSubProductMapList = _.cloneDeep(savedProduct.draft.product.subProductMapList);
    tempSubProductMapList.map((item, index) => {
      if (subProductMapIndex === index) {
        if (typeof productCode === "object") {
          item.productCode = productCode.productCode
          item.subProductTcName = productCode.tcName
        } else {
          item.productCode = productCode
        }
      }
      return {};
    })
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, "subProductMapList", tempSubProductMapList));
  }

  function handleSubProductMapDistributeMinute(name, distributeMinute, subProductMapIndex) {
    let tempSubProductMapList = _.cloneDeep(savedProduct.draft.product.subProductMapList);
    tempSubProductMapList.map((item, index) => {
      if (subProductMapIndex === index) {
        item.distributeMinute = Number(distributeMinute)
      }
      return {};
    })
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, "subProductMapList", tempSubProductMapList));
  }

  function handleSubProductMapQuantity(name, quantity, subProductMapIndex) {
    let tempSubProductMapList = _.cloneDeep(savedProduct.draft.product.subProductMapList);
    tempSubProductMapList.map((item, index) => {
      if (subProductMapIndex === index) {
        item.quantity = Number(quantity)
      }
      return {};
    })
    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, "subProductMapList", tempSubProductMapList));

  }

  return savedProduct.draft.product.type === constants.PRODUCT_TYPE.PACKAGE_ITEM &&
    <Card className="image-list">
      <div className="array-list-wrapper">
        <h3>{getString(lang, "productCreateProductList", "subProductMapList", theme)}</h3>
        {!hiddenInput &&
          <TempBtn btnSetting={{
            className: "button-on-top",
            variant: constants.STYLE.VARIANT.CONTAINED,
            color: constants.STYLE.PRIMARY,
            onClick: handleAddSubProductMapList,
            label: { resource: "productCreateProductList", key: "addSubProductMap" },
            disabled: savedProduct.draft.product.id ? true : disableInput,
            icon: <AddIcon />
          }} />
        }
      </div>
      {ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_SUB_PRODUCT_MAP_LIST, savedProduct.draft.product.productCode).map((error) => { return error }) &&
        ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_SUB_PRODUCT_MAP_LIST, savedProduct.draft.product.productCode).map((error) => { return error }).length > 0 &&
        <div className="error-message">
          <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
          {ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_SUB_PRODUCT_MAP_LIST, savedProduct.draft.product.productCode).map((error) => { return error })}
        </div>
      }
      {savedProduct.draft.product.subProductMapList.map((item, subProductMapListIndex) => (
        <Card key={subProductMapListIndex} className="image-list">
          {!item.id &&
            !hiddenInput &&
            <div className="deleteIcon">
              <TempBtn btnSetting={{
                className: "edit-create-btn",
                variant: constants.STYLE.VARIANT.CONTAINED,
                color: constants.STYLE.PRIMARY,
                onClick: () => handleDeleteSubProductMap(subProductMapListIndex),
                icon: <DeleteIcon />,
                disabled: disableInput,
                label: { key: "deleteBtn" },
              }} />
            </div>
          }
          <div>
            {!savedProduct.draft.product.id ?
              <TempAutoSelectInput
                inputSetting={{
                  className: "edit-create-input",
                  size: constants.SIZE.SMALL,
                  variant: constants.STYLE.VARIANT.OUTLINED,
                  type: constants.TYPE.SELECT,
                  selectValue: !savedProduct.draft.product.id ? savedProduct.draft.subProductList.map((subProductListItem, index) => (
                    { name: subProductListItem.productCode + "  (" + subProductListItem.tcName + ")", value: subProductListItem.productCode }
                  )) : "",
                  label: { resource: "productCreateProductList", key: "productCode" },
                  value: savedProduct.draft.product.id ? item.productCode + "  (" + item.subProductTcName + ")" : item.productCode,
                  disabled: savedProduct.draft.product.id ? true : disableInput,
                  helperTextIcon: true,
                  helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_SUB_PRODUCT_MAP_LIST_PRODUCT_CODE, savedProduct.draft.product.productCode, item.subProductMapValiCode).map((error) => { return error }),
                  FormHelperTextProps: { className: helperTextStylesClasses.root },
                }}
                handleChange={handleSubProductMapProductCode}
                index={subProductMapListIndex}
              />
              : <TempTextInput
                inputSetting={{
                  className: "edit-create-input",
                  size: constants.SIZE.SMALL,
                  variant: disableInput ? constants.STYLE.VARIANT.STANDARD : null,
                  label: { resource: "productCreateProductList", key: "productCode" },
                  value: savedProduct.draft.product.id ? item.productCode + "  (" + item.subProductTcName + ")" : item.productCode,
                  disabled: savedProduct.draft.product.id ? true : disableInput,
                  helperTextIcon: true,
                  helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_SUB_PRODUCT_MAP_LIST_PRODUCT_CODE, savedProduct.draft.product.productCode, item.subProductMapValiCode).map((error) => { return error }),
                  FormHelperTextProps: { className: helperTextStylesClasses.root },
                }}
                handleChange={handleSubProductMapProductCode}
                index={subProductMapListIndex}
              />
            }
          </div>
          <div>
            <TempTextInput
              inputSetting={{
                className: "edit-create-input",
                size: constants.SIZE.SMALL,
                variant: disableInput ? constants.STYLE.VARIANT.STANDARD : null,
                type: constants.TYPE.NUMBER,
                min: 0,
                label: { resource: "productCreateProductList", key: "distributeMinute" },
                value: item.distributeMinute,
                disabled: savedProduct.draft.product.id ? true : disableInput,
              }}
              handleChange={handleSubProductMapDistributeMinute}
              index={subProductMapListIndex}
            />
            <TempTextInput
              inputSetting={{
                className: "edit-create-input",
                size: constants.SIZE.SMALL,
                variant: disableInput ? constants.STYLE.VARIANT.STANDARD : null,
                type: constants.TYPE.NUMBER,
                min: 0,
                label: { resource: "productCreateProductList", key: "quantity" },
                value: item.quantity,
                disabled: savedProduct.draft.product.id ? true : disableInput,
                helperTextIcon: true,
                helperText: ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_SUB_PRODUCT_MAP_LIST_QUANTITY, savedProduct.draft.product.productCode, item.subProductMapValiCode).map((error) => { return error }),
                FormHelperTextProps: { className: helperTextStylesClasses.root }
              }}
              handleChange={handleSubProductMapQuantity}
              index={subProductMapListIndex}
            />
          </div>
        </Card>
      ))}
    </Card>
};