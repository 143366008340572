import { EMAIL_TEMPLATE } from './types';
import _ from "lodash";
import { sify } from "chinese-conv";

export const setEmailTemplateDefault = () => {
    return {
        type: EMAIL_TEMPLATE.SET_EMAIL_TEMPLATE_DEFAULT,
    }
}

export const setEmailTemplate = (emailTemplate) => {
    return {
        type: EMAIL_TEMPLATE.SET_EMAIL_TEMPLATE,
        emailTemplate: emailTemplate
    }
}

export const setEmailTemplateValue = (draftType, orignalProduct, key, value, index) => {
    const emailTemplate = _.cloneDeep(orignalProduct);
    switch (draftType) {
        case "CHANGE_TC_NAME_AND_SC_SUBJECT":
            emailTemplate.tcSubject = value;
            emailTemplate.scSubject = sify(value);
            break;
        case "CHANGE_TC_NAME_AND_SC_HTML_CONTENT":
            emailTemplate.htmlTcContent = value;
            emailTemplate.htmlScContent = sify(value);
            break;
        case "CHANGE_TC_NAME_AND_SC_PLAIN_TEXT_CONTENT":
            emailTemplate.plainTextTcContent = value;
            emailTemplate.plainTextScContent = sify(value);
            break;
        default:
            emailTemplate[draftType] = value;
    }
    return {
        type: EMAIL_TEMPLATE.SET_EMAIL_TEMPLATE_VALUE,
        emailTemplate: emailTemplate
    }
}
