// import SplitPane from 'react-split-pane';
import { Allotment } from 'allotment'
import 'allotment/dist/style.css'
import { QueueTemplateMapDateRangeList } from './QueueTemplateMapDateRangeList'
import { QueueTemplateMapUpperSection } from './QueueTemplateMapUpperSection'
import { QueueTemplateMapWeekdayList } from './QueueTemplateMapWeekdayList'
import { Card } from '@mui/material'

export const QueueTempateMapListWrapper = () => {
  return (
    <>
      <Card className="card-wrapper overflow">
        <div>
          <QueueTemplateMapUpperSection />
        </div>
        <Allotment defaultSizes={[100, 200]}>
          <QueueTemplateMapWeekdayList />
          <QueueTemplateMapDateRangeList />
        </Allotment>
      </Card>
    </>
  )
}
