import { brandGroupValidationConstants } from '../Constants/validationConstants';

class BrandGroupValidation {

  version;
  errorMap;
  brandGroupErrorMap;
  valid;

  constructor() {

    if (!BrandGroupValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.brandGroupErrorMap = new Map();
      BrandGroupValidation.instance = this;
    }
    return BrandGroupValidation.instance;
  }

  validateEditBrandGroup(brandGroup) {
    this.resetErrorMessages();
    this.handleValidateEditBrandGroup(brandGroup);
    this.version++;
  }

  validateCreateBrandGroup(brandGroup) {
    this.resetErrorMessages();
    this.handleValidateCreateBrandGroup(brandGroup);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.brandGroupErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key, uniqueCode) {
    if (!uniqueCode) {
      return (this.brandGroupErrorMap.has(key)) ? this.brandGroupErrorMap.get(key) : [];
    }
  }

  handleValidateEditBrandGroup(brandGroup) {
    if (!brandGroup.enName) {
      this.setBrandGroupErrorMapMessages(brandGroupValidationConstants.KEY_EN_NAME, "Missing EN Name");
    }
    if (!brandGroup.tcName) {
      this.setBrandGroupErrorMapMessages(brandGroupValidationConstants.KEY_TC_NAME, "Missing TC Name");
    }
    if (!brandGroup.scName) {
      this.setBrandGroupErrorMapMessages(brandGroupValidationConstants.KEY_SC_NAME, "Missing SC Name");
    }
    if (!brandGroup.status) {
      this.setBrandGroupErrorMapMessages(brandGroupValidationConstants.KEY_STATUS, "Missing Status");
    }
  }

  handleValidateCreateBrandGroup(brandGroup) {
    if (!brandGroup.enName) {
      this.setBrandGroupErrorMapMessages(brandGroupValidationConstants.KEY_EN_NAME, "Missing EN Name");
    }
    if (!brandGroup.tcName) {
      this.setBrandGroupErrorMapMessages(brandGroupValidationConstants.KEY_TC_NAME, "Missing TC Name");
    }
    if (!brandGroup.scName) {
      this.setBrandGroupErrorMapMessages(brandGroupValidationConstants.KEY_SC_NAME, "Missing SC Name");
    }
  }


  setBrandGroupErrorMapMessages(key, errorMessages, valid = false) {
    this.brandGroupErrorMap.set(key, errorMessages);
    // console.log("brandGroupErrorMap", this.brandGroupErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new BrandGroupValidation();
export default validation;