import { PRINT_TEMPLATE_TYPE } from '../Constants/constants';
import { printTemplateValidationConstants } from '../Constants/validationConstants';

class PrintTemplateValidation {

  version;
  errorMap;
  printTemplateErrorMap;
  templateErrorMap;
  templateLabelErrorMap;
  valid;

  constructor() {

    if (!PrintTemplateValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.printTemplateErrorMap = new Map();
      this.templateErrorMap = new Map();
      this.templateLabelErrorMap = new Map();
      PrintTemplateValidation.instance = this;
    }
    return PrintTemplateValidation.instance;
  }

  validatePrintTemp(printTemplate, template, templateLabel) {
    this.resetErrorMessages();
    this.validatePrintTemplate(printTemplate, template);
    this.validateTemplate(template);
    this.validateTemplateLabel(templateLabel);
    this.version++;
  }

  validatePrintTempTemplate(template) {
    this.templateErrorMap = new Map();
    this.valid = true;
    this.validateTemplate(template);
    this.version++;
  }

  validatePrintTempTemplateLabel(templateLabel) {
    this.templateLabelErrorMap = new Map();
    this.valid = true;
    this.validateTemplateLabel(templateLabel);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.printTemplateErrorMap = new Map();
    this.templateErrorMap = new Map();
    this.templateLabelErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key, uniqueCode) {
    if (!uniqueCode) {
      return (this.printTemplateErrorMap.has(key)) ? this.printTemplateErrorMap.get(key) : [];
    } else {
      switch (key) {
        case printTemplateValidationConstants.KEY_TEMPLATE_ERROR:
        case printTemplateValidationConstants.KEY_TEMPLATE_TYPE:
        case printTemplateValidationConstants.KEY_TEMPLATE_VALUE:
        case printTemplateValidationConstants.KEY_TEMPLATE_VALUE_DATE_MIDDLE_SYMBOL:
        case printTemplateValidationConstants.KEY_TEMPLATE_VALUE_TIME_BRACKET:
        case printTemplateValidationConstants.KEY_TEMPLATE_VALUE_TIME_FORMAT:
        case printTemplateValidationConstants.KEY_TEMPLATE_VALUE_DATE_FORMAT:
          if (this.templateErrorMap.has(uniqueCode) && this.templateErrorMap.get(uniqueCode).has(key)) {
            return this.templateErrorMap.get(uniqueCode).get(key);
          }
          return [];
        case printTemplateValidationConstants.KEY_TEMPLATE_LABEL_ERROR:
        case printTemplateValidationConstants.KEY_TEMPLATE_LABEL_TITLE:
        case printTemplateValidationConstants.KEY_TEMPLATE_LABEL_EN:
        case printTemplateValidationConstants.KEY_TEMPLATE_LABEL_TC:
        case printTemplateValidationConstants.KEY_TEMPLATE_LABEL_SC:
          if (this.templateLabelErrorMap.has(uniqueCode) && this.templateLabelErrorMap.get(uniqueCode).has(key)) {
            return this.templateLabelErrorMap.get(uniqueCode).get(key);
          }
          return [];
        default:
          return [];
      }
    }
  }

  validatePrintTemplate(printTemplate, template) {
    if (!printTemplate.code) {
      this.setPrintTemplateErrorMapMessages(printTemplateValidationConstants.KEY_CODE, "Missing Code");
    } else if (!printTemplate.code.match("^([A-Z0-9])+((_[A-Z0-9]+)+)?$")) {
      this.setPrintTemplateErrorMapMessages(printTemplateValidationConstants.KEY_CODE, "Invalid Code (Format: A-Z0-9_)");
    }

    if (!printTemplate.type) {
      this.setPrintTemplateErrorMapMessages(printTemplateValidationConstants.KEY_TYPE, "Missing Type");
    }

    if (template.length <= 0) {
      this.setPrintTemplateErrorMapMessages(printTemplateValidationConstants.KEY_PRINT_TEMPLATE_ERROR, "Please add at least one template");
    }
  }

  validateTemplate(template) {
    let fontSizeType = "";
    template.map((item) => {
      if (item.type === PRINT_TEMPLATE_TYPE.FONT_SIZE || item.type === PRINT_TEMPLATE_TYPE.FONT_SIZE_DIRECT) {
        fontSizeType = item.type;
      }
      const templateMap = new Map();
      if (!item.type && item.type !== PRINT_TEMPLATE_TYPE.FONT_SIZE) {
        templateMap.set(printTemplateValidationConstants.KEY_TEMPLATE_TYPE, ['Missing Template Type']);
      } else if (!item.type && item.type !== 0) {
        templateMap.set(printTemplateValidationConstants.KEY_TEMPLATE_TYPE, ['Invalid Template Type']);
      }
      if (!item.value && item.value !== 0) {
        templateMap.set(printTemplateValidationConstants.KEY_TEMPLATE_VALUE, ['Missing Template Value']);
      } else if ((item.type === PRINT_TEMPLATE_TYPE.FONT_SIZE || item.type === PRINT_TEMPLATE_TYPE.ALIGNMENT || item.type === PRINT_TEMPLATE_TYPE.FONT_SIZE_DIRECT || item.type === PRINT_TEMPLATE_TYPE.FONT_STYLE || item.type === PRINT_TEMPLATE_TYPE.LINE_FEED) && !Number.isInteger(item.value)) {
        templateMap.set(printTemplateValidationConstants.KEY_TEMPLATE_VALUE, ['Missing Template Value']);
      } else if ((item.type === PRINT_TEMPLATE_TYPE.PRINT_TEXT || item.type === PRINT_TEMPLATE_TYPE.PRINT_IMAGE || item.type === PRINT_TEMPLATE_TYPE.PRINT_QR_CODE || item.type === PRINT_TEMPLATE_TYPE.PRINT_BAR_CODE || item.type === PRINT_TEMPLATE_TYPE.PRINT_TEXT_AS_IMAGE || item.type === PRINT_TEMPLATE_TYPE.PRINT_TEXT_AS_IMAGE_NO_LINE_WRAP || item.type === PRINT_TEMPLATE_TYPE.DATE_FORMAT) && item.value.length <= 0) {
        templateMap.set(printTemplateValidationConstants.KEY_TEMPLATE_VALUE, ['Missing Template Value']);
      } else if ((item.type === PRINT_TEMPLATE_TYPE.LINE_FEED) && item.value <=0 ){
        templateMap.set(printTemplateValidationConstants.KEY_TEMPLATE_VALUE, ['Positive Number is required']);
      }
      if ((item.type === PRINT_TEMPLATE_TYPE.PRINT_TEXT_AS_IMAGE || item.type === PRINT_TEMPLATE_TYPE.PRINT_TEXT_AS_IMAGE_NO_LINE_WRAP)
        && fontSizeType !== PRINT_TEMPLATE_TYPE.FONT_SIZE_DIRECT) {
        templateMap.set(printTemplateValidationConstants.KEY_TEMPLATE_TYPE, ['Please add Custom Font Size before this type.']);
      }
      if (item.type === PRINT_TEMPLATE_TYPE.DATE_FORMAT && item.value) {
        let valueList = [];
        item.value.map((type13) => {
          type13.value.map((type13Value) => {
            if (type13Value.value) {
              valueList.push(true)
            }
            return type13Value;
          })
          if (type13.type === "date") {
            let dateList = [];
            type13.value.forEach((date) => {
              if (date.value) {
                dateList.push(true)
              }
            })
            if (dateList.length > 1 && !type13.middleSymbol) {
              templateMap.set(printTemplateValidationConstants.KEY_TEMPLATE_VALUE_DATE_MIDDLE_SYMBOL, ['Missing Middle Symbol']);
            } else if (dateList.length <= 1 && type13.middleSymbol) {
              templateMap.set(printTemplateValidationConstants.KEY_TEMPLATE_VALUE_DATE_MIDDLE_SYMBOL, ['You do not need to choose the Middle Symbol']);
            }
          }
          if (type13.type === "time") {
            let timeList = [];
            type13.value.forEach((time) => {
              if (time.value) {
                timeList.push(true)
              }
            })
            if (timeList.length > 1 && !type13.bracket && type13.bracket !== false) {
              templateMap.set(printTemplateValidationConstants.KEY_TEMPLATE_VALUE_TIME_BRACKET, ['Please choose with bracket or not!']);
            } else if (timeList.length === 0 && type13.bracket === true) {
              templateMap.set(printTemplateValidationConstants.KEY_TEMPLATE_VALUE_TIME_BRACKET, ['Time is not inclued so with bracket should not be true!']);
            }

            let hour = "";
            let timeFormat = "";
            type13.value.forEach((date) => {
              if (date.type === "hour") {
                hour = date.value;
              }
              if (date.type === "timeFormat") {
                timeFormat = date.value;
              }
            });
            if ((hour === "HH" || hour === "H") && timeFormat) {
              templateMap.set(printTemplateValidationConstants.KEY_TEMPLATE_VALUE_TIME_FORMAT, ['24 hours should not include AM/PM']);
            } else if ((hour === "hh" || hour === "h") && !timeFormat) {
              templateMap.set(printTemplateValidationConstants.KEY_TEMPLATE_VALUE_TIME_FORMAT, ['Missing time format (AM/PM)']);
            }
          }
          return type13;
        })
        if (valueList.length === 0) {
          templateMap.set(printTemplateValidationConstants.KEY_TEMPLATE_VALUE_DATE_FORMAT, ['Missing Value']);
        }
      }

      if(item.type === PRINT_TEMPLATE_TYPE.TERMS_AND_CONDITIONS && !item.value.includes('${remarks}')) {
        templateMap.set(printTemplateValidationConstants.KEY_TEMPLATE_VALUE, ['TnC is missing']);
      }

      if(item.type === PRINT_TEMPLATE_TYPE.LOGO && !item.value.includes('${logo}')) {
        templateMap.set(printTemplateValidationConstants.KEY_TEMPLATE_VALUE, ['logo is missing']);
      }

      if(item.type === PRINT_TEMPLATE_TYPE.HEADER && !item.value.includes('${header}')) {
        templateMap.set(printTemplateValidationConstants.KEY_TEMPLATE_VALUE, ['Header is missing']);
      }

      if(item.type === PRINT_TEMPLATE_TYPE.FOOTER && !item.value.includes('${footer}')) {
        templateMap.set(printTemplateValidationConstants.KEY_TEMPLATE_VALUE, ['Footer is missing']);
      }
      
      if (templateMap.size > 0) {
        templateMap.set(printTemplateValidationConstants.KEY_TEMPLATE_ERROR, ["Has error(s)"]);
        this.setTemplateErrorMapMessages(item.customId, templateMap);
      }
      return item
    })
  }

  validateTemplateLabel(templateLabel) {
    templateLabel.map((item) => {
      const templateLabelMap = new Map();
      if (!item.labelTitle) {
        templateLabelMap.set(printTemplateValidationConstants.KEY_TEMPLATE_LABEL_TITLE, ['Missing Template Label Title']);
      } else if (!item.labelTitle.match("^([a-zA-Z0-9])+(_[a-zA-Z0-9]+)?$")) {
        templateLabelMap.set(printTemplateValidationConstants.KEY_TEMPLATE_LABEL_TITLE, ['Invalid Template Label Title (Format: a-zA-Z0-9_)']);
      }
      if (!item.labelEn) {
        templateLabelMap.set(printTemplateValidationConstants.KEY_TEMPLATE_LABEL_EN, ['Missing Template En Label']);
      }
      if (!item.labelTc) {
        templateLabelMap.set(printTemplateValidationConstants.KEY_TEMPLATE_LABEL_TC, ['Missing Template Tc Label']);
      }
      if (!item.labelSc) {
        templateLabelMap.set(printTemplateValidationConstants.KEY_TEMPLATE_LABEL_SC, ['Missing Template Sc Label']);
      }
      if (templateLabelMap.size > 0) {
        templateLabelMap.set(printTemplateValidationConstants.KEY_TEMPLATE_LABEL_ERROR, ["Has error(s)"]);
        this.setTemplateLabelErrorMapMessages(item.customId, templateLabelMap);
      }
      return item
    })
  }


  setPrintTemplateErrorMapMessages(key, errorMessages, valid = false) {
    this.printTemplateErrorMap.set(key, errorMessages);
    // console.log("printTemplateErrorMap", this.printTemplateErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

  setTemplateErrorMapMessages(key, errorMessages, valid = false) {
    this.templateErrorMap.set(key, errorMessages);
    // console.log("templateErrorMap", this.templateErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

  setTemplateLabelErrorMapMessages(key, errorMessages, valid = false) {
    this.templateLabelErrorMap.set(key, errorMessages);
    // console.log("templateLabelErrorMap", this.templateLabelErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new PrintTemplateValidation();
export default validation;