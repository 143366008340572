import _ from "lodash";
import { TNC } from '../actions/types';
import { constants } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        tnc: _.cloneDeep(constants.TNC_INPUT)
    }
}

const tncReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TNC.SET_TNC_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case TNC.SET_TNC:
        case TNC.SET_TNC_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    tnc: action.tnc
                }
            })
        default:
            return state
    }
}

export default tncReducer