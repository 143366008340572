import { folderValidationConstants } from '../Constants/validationConstants';

class FolderValidation {

  version;
  errorMap;
  folderErrorMap;
  valid;

  constructor() {

    if (!FolderValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.folderErrorMap = new Map();
      FolderValidation.instance = this;
    }
    return FolderValidation.instance;
  }

  validateEditFolder(folder) {
    this.resetErrorMessages();
    this.handleValidateEditFolder(folder);
    this.version++;
  }

  validateCreateFolder(folder) {
    this.resetErrorMessages();
    this.handleValidateCreateFolder(folder);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.folderErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key, uniqueCode) {
    if (!uniqueCode) {
      return (this.folderErrorMap.has(key)) ? this.folderErrorMap.get(key) : [];
    }
  }

  handleValidateEditFolder(folder) {
    if (!folder.enName) {
      this.setFolderErrorMapMessages(folderValidationConstants.KEY_EN_NAME, "Missing EN Name");
    }
    if (!folder.tcName) {
      this.setFolderErrorMapMessages(folderValidationConstants.KEY_TC_NAME, "Missing TC Name");
    }
    if (!folder.scName) {
      this.setFolderErrorMapMessages(folderValidationConstants.KEY_SC_NAME, "Missing SC Name");
    }
    if (!folder.tcImage.cosFileUrl) {
      this.setFolderErrorMapMessages(folderValidationConstants.KEY_TC_IMAGE, "Missing TC Image");
    }
    if (!folder.scImage.cosFileUrl) {
      this.setFolderErrorMapMessages(folderValidationConstants.KEY_SC_IMAGE, "Missing SC Image");
    }
    if (!folder.enImage.cosFileUrl) {
      this.setFolderErrorMapMessages(folderValidationConstants.KEY_EN_IMAGE, "Missing EN Image");
    }
    if (!folder.status) {
      this.setFolderErrorMapMessages(folderValidationConstants.KEY_STATUS, "Missing Status");
    }
  }

  handleValidateCreateFolder(folder) {
    const folderCodeRegex = /^[A-Z\d_]{1,}$/
    if (!folder.folderCode) {
      this.setFolderErrorMapMessages(folderValidationConstants.KEY_FOLDER_CODE, "Missing Username");
    } else if (!folder.folderCode.match(folderCodeRegex)) {
      this.setFolderErrorMapMessages(folderValidationConstants.KEY_FOLDER_CODE, "Invalid Folder Code (Format: A-Z0-9_)");
    } else if (folder.folderCode.length > 20) {
      this.setFolderErrorMapMessages(folderValidationConstants.KEY_FOLDER_CODE, "Username length should be smaller than 20 letter!");
    }
    if (!folder.enName) {
      this.setFolderErrorMapMessages(folderValidationConstants.KEY_EN_NAME, "Missing EN Name");
    }
    if (!folder.tcName) {
      this.setFolderErrorMapMessages(folderValidationConstants.KEY_TC_NAME, "Missing TC Name");
    }
    if (!folder.scName) {
      this.setFolderErrorMapMessages(folderValidationConstants.KEY_SC_NAME, "Missing SC Name");
    }
    if (!folder.tcImage || !folder.tcImage.cosFileUrl) {
      this.setFolderErrorMapMessages(folderValidationConstants.KEY_TC_IMAGE, "Missing TC Image");
    }
    if (!folder.scImage || !folder.scImage.cosFileUrl) {
      this.setFolderErrorMapMessages(folderValidationConstants.KEY_SC_IMAGE, "Missing SC Image");
    }
    if (!folder.enImage || !folder.enImage.cosFileUrl) {
      this.setFolderErrorMapMessages(folderValidationConstants.KEY_EN_IMAGE, "Missing EN Image");
    }
  }


  setFolderErrorMapMessages(key, errorMessages, valid = false) {
    this.folderErrorMap.set(key, errorMessages);
    // console.log("folderErrorMap", this.folderErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new FolderValidation();
export default validation;