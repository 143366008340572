import { useState, useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import _ from 'lodash'

import { Card } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import CancelIcon from '@mui/icons-material/Cancel'

import { getOne } from '../../../helper/baseFetch'
import { setProductValue } from '../../../actions/productCreateAction'
import { constants, IMAGE_TYPE } from '../../../Constants/constants'
import { getProduct } from './getProduct'
import { ProductInfo } from './ProductInfo'
import { ImageList } from '../../UploadImage/ImageList'
import { ProductBlackoutPeriod } from './ProductBlackoutPeriod'
import { ProductAttribute } from './ProductAttribute'
import { BaseWrapper } from '../../BaseWrapper'
import { TempBtn } from '../../Input/TempBtn'
import { getProductStepError } from '../getProductStepError'
import { getString, permissionsCheck, getScDisabled } from '../../../helper/util'

import ProductCreateValidation from '../../../validations/productCreateValidation'
import { productValidationConstants } from '../../../Constants/validationConstants'
import useProduct from '../../../hooks/Product/useProduct'

const helperTextStyles = makeStyles((theme) => ({
  root: {
    color: 'red !important',
  },
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box p={4}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export const ProductCreateProductCreateEdit = ({ isEmbeded = false }) => {
  const dispatch = useDispatch()
  const store = useStore()
  const savedProduct = useSelector((state) => state.product.ui.product)
  const [ready, setReady] = useState(false)
  const helperTextStylesClasses = helperTextStyles()
  const { getProductName } = useProduct()

  // const [settlementConfigList, setSettlementConfig] = useState([]);

  const [value, setValue] = useState(0)

  const [disableInput, setDisableInput] = useState(false)
  const [hiddenInput, setHiddenInput] = useState(false)

  const [selectableRedeemGroupRedeemLocationList, setSelectableRedeemGroupRedeemLocationList] = useState([])

  const permission = localStorage.getItem(constants.LOCAL_STORAGE_KEY.ROLE)

  const [errorVersion, setErrorVersion] = useState(ProductCreateValidation.getVersion())

  const lang = useSelector((state) => state.lang.ui.lang)
  const theme = useSelector((state) => state.theme.ui.theme)

  useEffect(() => {
    if (
      permissionsCheck(constants.PERMISSION_KEY.PRODUCT_CREATE_EDIT_FINANCE) &&
      !permissionsCheck(constants.PERMISSION_KEY.PRODUCT_CREATE_SYSTEM_ADMIN)
    ) {
      setDisableInput(true)
      setHiddenInput(true)
    } else if (savedProduct.draft.product.status === constants.PRODUCT_EDIT_STATUS.ACTIVE) {
      setDisableInput(true)
      setHiddenInput(false)
    } else if (savedProduct.draft.product.merchantProduct === true) {
      setDisableInput(true)
      setHiddenInput(false)
    } else {
      setDisableInput(false)
      setHiddenInput(false)
    }
  }, [permission, savedProduct.draft.product && savedProduct.draft.product.status]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setValue(constants.PRODUCT_TAB.INFO)
  }, [savedProduct.draft.product && savedProduct.draft.product.productCode]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getProductStepError(dispatch)
  }, [errorVersion]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // getProductStepError(dispatch);
  }, [savedProduct.draft.product === null]) // eslint-disable-line react-hooks/exhaustive-deps

  const getExistingRedeemGroupLocationList = () => {
    return new Promise((resolve) => {
      getOne(
        constants.PATH.PRODUCT.REDEEM_LOCATION + '/' + savedProduct.draft.product.redeemGroupCode,
        undefined,
        (payload) => {
          resolve(
            payload.map((redeemLocation) => {
              return { restUrlId: redeemLocation.restUrlId, restTcName: redeemLocation.restTcName }
            })
          )
        },
        undefined,
        store
      )
    })
  }

  const handleTabChange = async (event, newValue) => {
    if (newValue === constants.PRODUCT_TAB.BLACKOUT_PERIOD_LIST && savedProduct.draft.product && savedProduct.draft.product.redeemGroupCode) {
      let tempLocationList = []
      tempLocationList = tempLocationList.concat(await getExistingRedeemGroupLocationList())
      setSelectableRedeemGroupRedeemLocationList(tempLocationList)
    }
    setValue(newValue)
  }

  function handleSubmit() {
    let tempDraft = _.cloneDeep(savedProduct.draft)
    dispatch(setProductValue('', savedProduct, '', tempDraft))
    ProductCreateValidation.validateDraft(tempDraft)
    setErrorVersion(ProductCreateValidation.getVersion())
  }

  function onClickClear() {
    if (!savedProduct.draft.product.id) {
      let orignalProductCode
      if (savedProduct.draft.product.type === constants.PRODUCT_TYPE.PACKAGE_SUB_ITEM) {
        orignalProductCode = savedProduct.draft.subProductList.find((item) => item.productCode === savedProduct.draft.product.productCode)
      } else {
        orignalProductCode = savedProduct.draft.productList.find((item) => item.productCode === savedProduct.draft.product.productCode)
      }
      if (orignalProductCode === undefined) {
        dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.DRAFT, savedProduct, 'product', _.cloneDeep(constants.PRODUCT)))
      } else {
        dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.DRAFT, savedProduct, 'product', orignalProductCode))
      }
    } else {
      edit(savedProduct.draft.product.id)
    }
  }

  const edit = async (productCode) => {
    const data = await getProduct(productCode, store)
    let tempSavedProduct = _.cloneDeep(savedProduct.draft)
    tempSavedProduct.product = data
    tempSavedProduct.lotInventory = data.lotInventoryList
    tempSavedProduct.lotInventorySet = true
    if (tempSavedProduct.product.attributeList.length > 0) {
      tempSavedProduct.product.attributeList.map((attribute, attributeIndex) => {
        if (attribute.optionList && attribute.optionList.length > 0) {
          attribute.optionList.map((optionItem, optionIndex) => {
            optionItem.optionValiCode = 'Existing_Option_' + optionIndex
            optionItem.scValueDisable = getScDisabled(optionItem.tcValue, optionItem.scValue)
            return {}
          })
        }
        return {}
      })
    }
    dispatch(setProductValue('', savedProduct, '', tempSavedProduct))
  }

  const selectableRedeemGroupList = []

  if (savedProduct.draft.product) {
    const tempRedeemGroupList = savedProduct.draft.existingRedeemGroupList
      ? savedProduct.draft.redeemGroupList.concat(savedProduct.draft.existingRedeemGroupList)
      : savedProduct.draft.redeemGroupList
    tempRedeemGroupList.forEach((item, index) => {
      if (
        (savedProduct.draft.product.type === constants.PRODUCT_TYPE.PREORDER_ITEM && item.selectLocation) ||
        (savedProduct.draft.product.type !== constants.PRODUCT_TYPE.PREORDER_ITEM && !item.selectLocation)
      ) {
        selectableRedeemGroupList.push(
          <MenuItem key={index} value={item.redeemGroupCode}>
            {item.redeemGroupCode + '   ' + item.codeDescription}
          </MenuItem>
        )
      }
    })
  }

  useEffect(() => {
    if (!savedProduct.draft.product || (savedProduct.draft.product && !savedProduct.draft.product.productCode)) {
      setReady(false)
    } else {
      setReady(true)
    }
  }, [savedProduct.draft.productList, savedProduct.draft.product, savedProduct.draft.product.productCode]) // eslint-disable-line react-hooks/exhaustive-deps

  const productName = getProductName()

  // Product Info (Not in Product Create)

  return (
    ready && (
      <BaseWrapper>
        <div className="step-content-content-wrapper">
          <div className="sub-title margin-left-16">
            {(!savedProduct.draft.product.id
              ? getString(lang, 'productCreateProductList', 'createnName', theme)
              : getString(lang, 'productCreateProductList', 'editName', theme)) + ': '}
            <div className="code-title">
              {savedProduct.draft.product.productCode} - {productName}
            </div>
          </div>
          <Paper position="static" color="default">
            <Tabs value={value} onChange={handleTabChange} aria-label="simple tabs example">
              <Tab label={getString(lang, 'productCreateProductList', 'product', theme)} {...a11yProps(constants.PRODUCT_TAB.INFO)} />
              <Tab label={getString(lang, 'productCreateProductList', 'imageList', theme)} {...a11yProps(constants.PRODUCT_TAB.IMAGE_LIST)} />
              <Tab
                label={getString(lang, 'productCreateProductList', 'blackoutPeriodList', theme)}
                {...a11yProps(constants.PRODUCT_TAB.BLACKOUT_PERIOD_LIST)}
              />
              {savedProduct.draft.product.type === constants.PRODUCT_TYPE.PREORDER_ITEM && (
                <Tab
                  label={getString(lang, 'productCreateProductList', 'attributeList', theme)}
                  {...a11yProps(constants.PRODUCT_TAB.ATTRIBUTE_LIST)}
                />
              )}
            </Tabs>
          </Paper>
          <Card className={!savedProduct.draft.product.id ? 'step-content-content' : ''}>
            <TabPanel value={value} index={constants.PRODUCT_TAB.INFO}>
              <ProductInfo hiddenInput={hiddenInput} disableInput={disableInput} helperTextStylesClasses={helperTextStylesClasses} isEmbeded={isEmbeded} />
            </TabPanel>
            <TabPanel value={value} index={constants.PRODUCT_TAB.IMAGE_LIST}>
              {ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_PRODUCT_IMAGE_LIST,
                savedProduct.draft.product.productCode
              ).map((error) => {
                return error
              }) &&
                ProductCreateValidation.getErrorMessages(
                  productValidationConstants.KEY_PRODUCT_IMAGE_LIST,
                  savedProduct.draft.product.productCode
                ).map((error) => {
                  return error
                }).length > 0 && (
                  <div className="error-message">
                    <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                    {ProductCreateValidation.getErrorMessages(
                      productValidationConstants.KEY_PRODUCT_IMAGE_LIST,
                      savedProduct.draft.product.productCode
                    ).map((error) => {
                      return error
                    })}
                  </div>
                )}
              <ImageList
                uploadPath={constants.PATH.PRODUCT.UPLOAD_PRODUCT_IMAGE}
                resource={'productCreateProductList'}
                type={IMAGE_TYPE.PRODUCT}
                typeList={constants.CHOICES.PRODUCT_CREATE.CREATE_PRODUCT.PRODUCT_IMAGE_TYPE}
                savedData={savedProduct}
                hiddenInput={hiddenInput}
                disableInput={disableInput}
                helperTextStylesClasses={helperTextStylesClasses}
                validation={ProductCreateValidation}
                validationConstants={productValidationConstants}
              />
            </TabPanel>
            <div>
              <TabPanel value={value} index={constants.PRODUCT_TAB.BLACKOUT_PERIOD_LIST}>
                <ProductBlackoutPeriod
                  hiddenInput={hiddenInput}
                  disableInput={disableInput}
                  selectableRedeemGroupRedeemLocationList={selectableRedeemGroupRedeemLocationList}
                  helperTextStylesClasses={helperTextStylesClasses}
                />
              </TabPanel>
            </div>
            {savedProduct.draft.product.type === constants.PRODUCT_TYPE.PREORDER_ITEM && (
              <TabPanel value={value} index={constants.PRODUCT_TAB.ATTRIBUTE_LIST}>
                <ProductAttribute hiddenInput={hiddenInput} disableInput={disableInput} helperTextStylesClasses={helperTextStylesClasses} />
              </TabPanel>
            )}
            {savedProduct.draft.product.id ? (
              !hiddenInput && (
                <div className="cat-create-card-padding table-head">
                  {!savedProduct.draft.product.id && (
                    <TempBtn
                      btnSetting={{
                        variant: constants.STYLE.VARIANT.CONTAINED,
                        color: constants.STYLE.PRIMARY,
                        onClick: handleSubmit,
                        label: { key: 'save' },
                        disabled: disableInput,
                        icon: <SaveIcon />,
                      }}
                    />
                  )}
                  <TempBtn
                    btnSetting={{
                      className: 'btn-margin',
                      variant: constants.STYLE.VARIANT.CONTAINED,
                      color: constants.STYLE.PRIMARY,
                      onClick: onClickClear,
                      label: { key: 'clear' },
                      disabled: disableInput,
                      icon: <ClearIcon />,
                    }}
                  />
                </div>
              )
            ) : (
              <></>
            )}
          </Card>
        </div>
      </BaseWrapper>
    )
  )
}
