import { useState } from 'react'
import { getContent } from '../../Component/EditCreateDisplay/EditUtil'
import { SubTabEditCreateDisplay } from '../../Component/EditCreateDisplay/SubTabEditCreateDisplay'
import { Drawer } from '@mui/material'
import { PickupSoundTemplateList } from './PickupSoundTemplateList'

export const TypeCodeField = ({
  value,
  resource,
  name = 'typeCode',
  onChangeInput,
  disableClearable,
  clear,
  errorText,
}) => {
  const [openDrawer, setOpenDrawer] = useState(false)

  function onCloseDrawer() {
    setOpenDrawer(false)
  }

  const onOpenDrawer = () => {
    setOpenDrawer(true)
  }

  const onClickListRow = (e) => {
    onChangeInput(name, e)
    onCloseDrawer()
  }

  const fields = [
    {
      className: 'edit-create-input',
      label: { resource: resource, key: name },
      value: value,
      name: name,
      isEndOfLine: false,
      disabled: true,
      disableClearable: disableClearable,
      clear: clear,
      onClickEndAdornmentIconButton: () => onOpenDrawer(),
      helperTextIcon: true,
      helperText: errorText,
    },
  ]

  const content = [
    {
      tab: fields.map((inputSetting, idx) => {
        let content = getContent(inputSetting, idx, onChangeInput)
        return (
          <div className="edit-display-wrapper" key={idx}>
            {content}
            {inputSetting.isEndOfLine && <div></div>}
          </div>
        )
      }),
      btn: false,
    },
  ]

  return (
    <>
      <SubTabEditCreateDisplay
        code={''}
        resource={resource}
        tabs={false}
        content={content}
        onChangeInput={onChangeInput}
        display={'none'}
      />
      <Drawer classes={{ paper: 'drawer-margin-left' }} anchor="right" open={openDrawer} onClose={onCloseDrawer}>
        <PickupSoundTemplateList editBtn={false} createBtn={false} btnList={false} onClickListRow={(item) => onClickListRow(item)} />
      </Drawer>
    </>
  )
}
