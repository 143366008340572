import { systemGroupValidationConstants } from '../Constants/validationConstants';
import { validateEmail } from '../helper/util';

class SystemGroupValidation {

  version;
  errorMap;
  systemGroupErrorMap;
  valid;

  constructor() {

    if (!SystemGroupValidation.instance) {
      this.version = 0;
      this.valid = true;
      this.errorMap = new Map();
      this.systemGroupErrorMap = new Map();
      SystemGroupValidation.instance = this;
    }
    return SystemGroupValidation.instance;
  }

  validateEditSystemGroup(systemGroup) {
    this.resetErrorMessages();
    this.handleValidateEditSystemGroup(systemGroup);
    this.version++;
  }

  resetErrorMessages() {
    this.errorMap = new Map();
    this.systemGroupErrorMap = new Map();
    this.valid = true;
  }

  getVersion() {
    return this.version;
  }

  isValid() {
    return this.valid;
  }

  getErrorMessages(key) {
    return this.systemGroupErrorMap.get(key)
  }

  handleValidateEditSystemGroup(systemGroup) {
    // if (!brand.groupId) {
    //   this.setBrandErrorMapMessages(brandValidationConstants.KEY_GROUP_ID, "Missing Group ID");
    // }
    if (!systemGroup.name) {
      this.setSystemGroupErrorMapMessages(systemGroupValidationConstants.KEY_NAME, "Missing Name");
    }
    if (systemGroup.name?.length >100) {
      this.setSystemGroupErrorMapMessages(systemGroupValidationConstants.KEY_NAME, "Name Too Long");
    }
    
    if (systemGroup.groupEmailList) {
      for(let i=0; i<systemGroup.groupEmailList.length; i++){
        if(!validateEmail(systemGroup.groupEmailList[i])){
          this.setSystemGroupErrorMapMessages(`${i}-${systemGroupValidationConstants.KEY_GROUP_EMAILS}`, "Invalid Email");
        }
      }
    }
  }

  setSystemGroupErrorMapMessages(key, errorMessages, valid = false) {
    this.systemGroupErrorMap.set(key, errorMessages);
    // console.log("brandErrorMap", this.brandErrorMap)
    if (!valid) {
      this.valid = false;
    }
  }

}

const validation = new SystemGroupValidation();
export default validation;