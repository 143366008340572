import { Switch, Route } from 'react-router-dom';
import { useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";

import { Login } from "./Login";
import { constants } from './Constants/constants';

const RoutesForLogin = () => {
    const history = useHistory();
    const location = useLocation();
    const token = localStorage.getItem(constants.LOCAL_STORAGE_KEY.TOKEN);

    useEffect(() => {
        if (!token) {
            history.push("/login");
        }
    }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <Switch>
            <Route exact path={"/login"} component={Login} />,
        </Switch>
    )
}

export default RoutesForLogin;